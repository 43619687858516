import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import PriceListVersion from '../interfaces/output/priceListVersions';
import PriceListVersionApi from '../api/priceListVersionApi';
import { clonePriceListVersionError, clonePriceListVersionSuccess, fetchPriceListVersionByIdError, fetchPriceListVersionByIdSuccess, updatePriceListVersionError, updatePriceListVersionStatusError,
    updatePriceListVersionStatusSuccess, updatePriceListVersionSuccess } from '../reducers/priceListVersionReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchPriceListVersion(action: PayloadAction<string>) {
    const priceListVersionId = action.payload;
    try {
        const response: PriceListVersion = yield call(PriceListVersionApi.fetchPriceListVersionById, priceListVersionId);

        yield put(fetchPriceListVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListVersionByIdError(error as HttpErrorResponse));
    }
}

function* doUpdatePriceListVersionStatus(action: PayloadAction<PriceListVersion>) {
    const priceListVersion = action.payload;

    try {
        const response: PriceListVersion = yield call(PriceListVersionApi.updatePriceListVersionStatus, priceListVersion);

        yield put(updatePriceListVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriceListVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdatePriceListVersion(action: PayloadAction<PriceListVersion>) {
    const priceListVersion = action.payload;

    try {
        const response: PriceListVersion = yield call(PriceListVersionApi.updatePriceListVersion, priceListVersion);

        yield put(updatePriceListVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriceListVersionError(error as HttpErrorResponse));
    }
}

function* doClonePriceListVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: PriceListVersion = yield call(PriceListVersionApi.clonePriceListVersion, toVersionId, fromVersionId);

        yield put(clonePriceListVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(clonePriceListVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('pricelistversion/fetchPriceListVersionById', doFetchPriceListVersion),
    takeLatest('pricelistversion/updatePriceListVersionStatus', doUpdatePriceListVersionStatus),
    takeLatest('pricelistversion/updatePriceListVersion', doUpdatePriceListVersion),
    takeLatest('pricelistversion/clonePriceListVersion', doClonePriceListVersion)
];