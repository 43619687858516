import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import FactorSetOut from '../interfaces/output/factorSetOut';
import Pagination from '../interfaces/common/pagination';
import FactorSetType from '../interfaces/output/factorSetType';
import DepartureFilters from '../components/factorSets/departure/departureFilter';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetIn from '../interfaces/input/factorSetIn';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetDepartureApi {
    static fetchFactorSetDepartureById = async(factorSetId: number, versionId?: number): Promise<FactorSetOut> => {
        const versionQueryString = versionId ? { versionId: versionId } : {};
        const url = urlcat('', `factorsets/departure/${factorSetId}`, versionQueryString);
        
        const { data } = await http.get<FactorSetOut>(url);

        return data;
    };

    static fetchFactorSetDepartures = async(paging: Paging, filters: DepartureFilters): Promise<Array<FactorSetOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsets/departure');

        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static fetchFactorSetDepartureTypes = async(): Promise<Pagination<FactorSetType>> => {
        const { data } = await http.get<Pagination<FactorSetType>>('/departuretypes');

        return data;
    };

    static fetchFactorSetDepartureVersions = async(factorSetId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/departure/${factorSetId}/versions`);

        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static createFactorSetDeparture = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSetDeparture: FactorSetIn = {
            name: factorSet?.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            noFactors: true,
            default: factorSet?.default ?? false
        };

        const { data } = await http.post<FactorSetIn, FactorSetOut>('factorsets/departure', newFactorSetDeparture);

        return data;
    }

    static createDepartureVersion = async(factorSet: FactorSetOut): Promise<FactorSetVersionOut> => {
        const url = `/factorsets/departure/${factorSet.id}/versions`;

        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSet.version?.validFromDate,
            validToDate: factorSet.version?.validToDate,
            statusId: 1 //set status to active
        };
        
        const { data } = await http.post<FactorSetVersionIn, FactorSetVersionOut>(url, newVersion);

        return data;
    }

    static updateFactorSetDeparture = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSetDeparture: FactorSetIn = {
            name: factorSet.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            default: factorSet?.default ?? false
        };
        
        const { data } = await http.put<FactorSetIn, FactorSetOut>(`factorsets/departure/${factorSet.id}`, newFactorSetDeparture);

        return data;
    }

    static deactivateFactorSetDeparture = async(id: number): Promise<FactorSetOut> => {
        const { data } = await http.delete<FactorSetOut>(`factorsets/departure/${id}`);

        return data;
    }
}