/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import Pagination from '../../../interfaces/common/pagination';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import CancellationLineFilters from './line/cancellationLineFilters';
import FactorSetCancellationLineOutput from '../../../interfaces/output/factorSetCancellationLineOut';
import ViewFactorSetCancellation from './viewFactorSetCancellation';
import ViewFactorSetCancellationLines from './line/cancellationLineList';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import {
    deactivateFactorSetCancellation, fetchFactorSetCancellationById, getFactorSetCancellation, isDeletingCancellation, isLoadingFactorSetCancellation, resetFactorSetCancellation,
    resetFactorSetCancellations
} from '../../../reducers/factorSetCancellationReducer';
import { fetchCancellationTypes, getCancellationTypes } from '../../../reducers/cancellationTypeReducer';
import Loader from '../../common/widgets/loader';
import FactorsVersionsList from '../factorsVersionList';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import {
    deactivateFactorSetCancellationLine, fetchCancellationVersionLines, getCancellationLines, isCreatingCancellationLine, isDeletingCancellationLine, isLoadingFactorSetCancellationLines,
    isUpdatingCancellationLine,
    resetCancellationVersionLines
} from '../../../reducers/cancellationLineReducer';
import { fetchCancellationVersions, getFactorSetCancellationVersions, isLoadingFactorSetCancellationVersions } from '../../../reducers/factorSetCancellationVersionReducer';
import CancellationType from '../../../interfaces/output/cancellationType';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../reducers/unitTypeReducer';
import UnitType from '../../../interfaces/output/unitType';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IFactorSetCancellationDetailsProps {
    classes: any;
    theme: Theme;
    fetchFactorSetCancellationById: any;
    fetchCancellationVersionLines: any;
    deactivateFactorSetCancellation: any;
    deactivateFactorSetCancellationLine: any;
    fetchCancellationTypes: any;
    fetchCancellationVersions: any;
    resetFactorSetCancellation: () => any;
    resetFactorSetCancellations: () => any;
    resetCancellationVersionLines: () => any;
    factorSetCancellation: FactorSetOut;
    match: any;
    cancellationLines: Pagination<FactorSetCancellationLineOutput>;
    factorVersions: Pagination<FactorSetVersionOut>;
    cancellationTypes: Pagination<CancellationType>;
    history: any;
    location: any;
    isUpdatingCancellation: boolean;
    isDeletingCancellation: boolean;
    isDeletingLine: boolean;
    isCreatingCancellation: boolean;
    isLoading: boolean;
    isLoadingFactorSetCancellation: boolean;
    isLoadingFactorSetCancellationLines: boolean;
    isLoadingFactorSetVersions: boolean;
    fetchUnitTypes: any;
    isLoadingUnitTypes: boolean;
    unitTypes: Pagination<UnitType>;
}

interface IFactorSetCancellationDetailsState {
    open: boolean;
    factorSetCancellation: FactorSetOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverPaginationLines: Paging;
    sortModelLines: GridSortModel;
    sortModelVersions: GridSortModel;
    factorSetCancellationColumnVisibilityMode: GridColumnVisibilityModel;
    factorsVersionLinesColumnVisibilityMode: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetCancellationById: (id: number, versionId: number) => dispatch(fetchFactorSetCancellationById({
        factorSetId: id,
        versionId
    })),
    fetchCancellationVersionLines: (paging: Paging, filters: CancellationLineFilters) => dispatch(fetchCancellationVersionLines({
        paging,
        filters
    })),
    fetchCancellationVersions: (id: number, paging: Paging) => dispatch(fetchCancellationVersions({
        id,
        paging
    })),
    deactivateFactorSetCancellation: (id: number) => dispatch(deactivateFactorSetCancellation(id)),
    deactivateFactorSetCancellationLine: (id: number) => dispatch(deactivateFactorSetCancellationLine(id)),
    fetchCancellationTypes: (paging: Paging) => dispatch(fetchCancellationTypes({ paging })),
    resetFactorSetCancellation: () => dispatch(resetFactorSetCancellation()),
    resetFactorSetCancellations: () => dispatch(resetFactorSetCancellations()),
    resetCancellationVersionLines: () => dispatch(resetCancellationVersionLines()),
    fetchUnitTypes: (paging: Paging) => dispatch(fetchUnitTypes({ paging }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetCancellation: getFactorSetCancellation(store),
        cancellationLines: getCancellationLines(store),
        factorVersions: getFactorSetCancellationVersions(store),
        cancellationTypes: getCancellationTypes(store),
        unitTypes: getUnitTypes(store),
        isUpdatingCancellation: isUpdatingCancellationLine(store),
        isDeletingLine: isDeletingCancellationLine(store),
        isDeletingCancellation: isDeletingCancellation(store),
        isCreatingCancellation: isCreatingCancellationLine(store),
        isLoadingFactorSetCancellationLines: isLoadingFactorSetCancellationLines(store),
        isLoadingFactorSetVersions: isLoadingFactorSetCancellationVersions(store),
        isLoadingFactorSetCancellation: isLoadingFactorSetCancellation(store),
        isLoadingUnitTypes: isLoadingUnitTypes(store),
        isLoading: isLoadingFactorSetCancellation(store) || isUpdatingCancellationLine(store) || isDeletingCancellationLine(store) || isCreatingCancellationLine(store) ||
            isLoadingFactorSetCancellationLines(store) || isLoadingUnitTypes(store)

    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('FACTOR_SET_CANCELLATION'),
    properties: LanguageUtils.createMessage('Properties'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    versions: LanguageUtils.createMessage('Versions'),
    factors: LanguageUtils.createMessage('lines')
};

class FactorSetCancellationDetails extends Component<IFactorSetCancellationDetailsProps, IFactorSetCancellationDetailsState> {
    factorsVersionListRef: any;
    factorsVersionLineListRef: any;

    constructor(props: IFactorSetCancellationDetailsProps) {
        super(props);

        this.factorsVersionLineListRef = React.createRef();
        this.factorsVersionListRef = React.createRef();
        this.state = {
            open: true,
            factorSetCancellation: {} as FactorSetOut,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            serverPaginationLines: PageUtils.getDefaultPaging(),
            sortModelLines: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelVersions: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            factorSetCancellationColumnVisibilityMode: {},
            factorsVersionLinesColumnVisibilityMode: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetCancellationById } = this.props;
        const { cancellationId, factorSetVersionId } = this.props.match.params;
        const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;

        fetchFactorSetCancellationById(cancellationId, versionId);
    }

    componentWillUnmount() {
        const { resetFactorSetCancellation, resetFactorSetCancellations, resetCancellationVersionLines } = this.props;

        resetFactorSetCancellation();
        resetFactorSetCancellations();
        resetCancellationVersionLines();
    }

    componentDidUpdate(prevProps: IFactorSetCancellationDetailsProps) {
        const { factorSetCancellation, isUpdatingCancellation, isDeletingLine, isCreatingCancellation, isLoadingFactorSetCancellation, isDeletingCancellation, history,
            fetchFactorSetCancellationById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { cancellationId, factorSetVersionId } = this.props.match.params;

        if(factorSetCancellation !== prevProps.factorSetCancellation) {
            this.setState({ factorSetCancellation });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.factorSetVersionId !== factorSetVersionId) {
                const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;
                fetchFactorSetCancellationById(cancellationId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingFactorSetCancellation && prevProps.isLoadingFactorSetCancellation ||
            !isUpdatingCancellation && prevProps.isUpdatingCancellation ||
            !isDeletingLine && prevProps.isDeletingLine ||
            !isCreatingCancellation && prevProps.isCreatingCancellation)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingCancellation && prevProps.isDeletingCancellation) {
            history.push(`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_CANCELLATION}`);
        }
    }

    isHiddenCategory = (): boolean => {
        const { factorSetCancellation } = this.state;

        return factorSetCancellation.version?.id ? false : true;
    }

    configureDrawer = (props: IFactorSetCancellationDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.FACTOR_SETS}/cancellation/${props.match.params.cancellationId}/${props.match.params.factorSetVersionId}`;

        return ImmutableList([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/properties`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.versions,
            additionalMargin: true,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.factors,
            icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
            url: `${baseUrl}/lines`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/lines`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchCancellationVersionLines, factorSetCancellation, fetchCancellationVersions, fetchCancellationTypes, fetchUnitTypes } = this.props;
        const { cancellationId } = this.props.match.params;
        const { serverPagination, serverPaginationLines } = this.state;

        const lineFilters = {
            name: undefined,
            cancellationTypeId: null,
            factorSetVersionId: factorSetCancellation.version?.id
        } as CancellationLineFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverPagination,
            page: newPaging.page
        };

        const newPaginationLines = {
            ...serverPaginationLines,
            page: newPaging.page
        };
        
        if(selectedMenuItem?.id === 2) {
            fetchCancellationVersions(cancellationId, newPagination);
        }

        if(selectedMenuItem?.id === 3 && factorSetCancellation.version?.id) {
            fetchCancellationVersionLines(newPaginationLines, lineFilters);
            fetchCancellationTypes(newPagination);
            fetchUnitTypes(newPagination);
        }
    }

    getSelectedMenuItem = (state: IFactorSetCancellationDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateFactorSet = () => {
        const { factorSetCancellation } = this.state;
        const { deactivateFactorSetCancellation } = this.props;

        deactivateFactorSetCancellation(factorSetCancellation.id);
    }

    deactivateFactorSetLine = () => {
        const { selectedRow } = this.state;
        const { deactivateFactorSetCancellationLine } = this.props;

        deactivateFactorSetCancellationLine(selectedRow);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow, factorSetCancellation } = this.state;
        const { cancellationLines, cancellationTypes, unitTypes } = this.props;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { cancellationId } = this.props.match.params;

        const filteredTypes = cancellationTypes.content.filter(ct => (!cancellationLines.content.find(cl => cl.cancellationType?.id === ct.id && !cl.unitType))
            || unitTypes.content.find(ut => !cancellationLines.content.find(cl => cl.cancellationType?.id === ct.id && cl.unitType?.id === ut?.id)));

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: factorSetCancellation.version?.id ? `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/${factorSetCancellation.version?.id}/edit` :
                            `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateFactorSet
                    }
                ];
            }

            case 2: { //cancellation versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 3: { //lines
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/version/${factorSetCancellation.version?.id}/add/line`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !factorSetCancellation.version?.id || filteredTypes.length === 0
                        }
                    ];
                }

                if(factorSetCancellation.version) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/cancellation/${cancellationId}/version/${factorSetCancellation.version?.id}/cancellationLine/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateFactorSetLine
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }

    _onSortChangeLines = (newModel: GridSortModel) => {
        const { fetchCancellationVersionLines } = this.props;
        const { sortModelLines, serverPaginationLines, factorSetCancellation } = this.state;

        const lineFilters = {
            name: undefined,
            cancellationTypeId: null,
            factorSetVersionId: factorSetCancellation.version?.id
        } as CancellationLineFilters;

        if(JSON.stringify(sortModelLines) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationLines,
                sort: newModel
            };
            this.setState({
                sortModelLines: newModel,
                serverPaginationLines: newPagination
            });

            fetchCancellationVersionLines(newPagination, lineFilters);
        }
    }

    _onSortChangeVersions = (newModel: GridSortModel) => {
        const { fetchCancellationVersions } = this.props;
        const { factorSetCancellation, serverPagination, sortModelVersions } = this.state;

        if(JSON.stringify(sortModelVersions) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelVersions: newModel,
                serverPagination: newPagination
            });

            fetchCancellationVersions(factorSetCancellation.id, newPagination);
        }
    }

    render() {
        const { classes, isLoadingFactorSetCancellation, isLoadingFactorSetCancellationLines, cancellationLines, isDeletingLine, isDeletingCancellation, isUpdatingCancellation,
            isLoadingFactorSetVersions, factorVersions, fetchCancellationVersions, fetchCancellationVersionLines } = this.props;
        const { factorSetCancellation, menuItems, serverPagination,serverPaginationLines, factorSetCancellationColumnVisibilityMode, factorsVersionLinesColumnVisibilityMode } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingFactorSetCancellation || isDeletingCancellation}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.FactorSets}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: factorSetCancellation.name,
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                            <span className={classes.values}> {factorSetCancellation?.version?.versionNumber ?? '-'} </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                            {factorSetCancellation?.version?.validFromDate || factorSetCancellation?.version?.validToDate ? (
                                <span className={classes.values}>
                                    <NullableFormattedDate value={factorSetCancellation?.version?.validFromDate} /> -
                                    {factorSetCancellation?.version?.validToDate &&
                                        <> <NullableFormattedDate value={factorSetCancellation?.version?.validToDate} /></>}
                                </span>
                            )
                                : '-'} </span>
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId/properties`}>
                            <Loader isLoading={isUpdatingCancellation || isDeletingCancellation}>
                                <ViewFactorSetCancellation factorSetCancellation={factorSetCancellation} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId/lines`}>
                            <ViewFactorSetCancellationLines isLoading={isLoadingFactorSetCancellationLines || isDeletingLine}
                                rows={cancellationLines}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationLines,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const lineFilters = {
                                        name: undefined,
                                        cancellationTypeId: null,
                                        factorSetVersionId: factorSetCancellation.version?.id
                                    } as CancellationLineFilters;
                                    this.setState({ serverPaginationLines: newPage });
                                    fetchCancellationVersionLines(newPage, lineFilters);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeLines(sortModel)}
                                columnVisibilityModel={factorSetCancellationColumnVisibilityMode}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorSetCancellationColumnVisibilityMode: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/cancellation/:cancellationId/:factorSetVersionId/versions`}>
                            <FactorsVersionsList
                                type={factorSetCancellation.type?.code}
                                isLoading={isLoadingFactorSetVersions}
                                ref={this.factorsVersionListRef}
                                rows={factorVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchCancellationVersions(factorSetCancellation.id, newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeVersions(sortModel)}
                                columnVisibilityModel={factorsVersionLinesColumnVisibilityMode}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorsVersionLinesColumnVisibilityMode: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetCancellationDetails));