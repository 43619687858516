import { Autocomplete, Grid, TextField } from '@mui/material';
import { FormControl, Button } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import GenericTextField from '../common/widgets/genericTextField';
import Agreement from '../../interfaces/output/agreement';
import { fetchAgreements, getAgreements } from '../../reducers/agreementsReducer';
import { fetchCustomers, getCustomers, isLoadingCustomers } from '../../reducers/customerReducer';
import { fetchCorporates, getCorporates } from '../../reducers/customerCorporateReducer';
import CustomerFilters from '../../interfaces/output/filters';
import AgreementFilters from '../../interfaces/filters/agreementFilters';
import Customer from '../../interfaces/output/customer';
import SimulationFilters from '../../interfaces/output/simulationFilters';
import CustomerCorporate from '../../interfaces/output/customerCorporate';

interface IFilterProps {
    filters: SimulationFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    name: LanguageUtils.createMessage('Simulation name'),
    agreementFrom: LanguageUtils.createMessage('Agreement from'),
    agreementTo: LanguageUtils.createMessage('Agreement to'),
    customer: LanguageUtils.createMessage('Customer'),
    corporate: LanguageUtils.createMessage('Customer corporate'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function SimulationFiltersComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const [inputValue, setInputValue] = React.useState('');
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const agreements = useSelector(getAgreements).content;
    const customers = useSelector(getCustomers).content;
    const corporates = useSelector(getCorporates).content;
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const customerFilters = {
        name: inputValue
    } as CustomerFilters;
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(fetchAgreements({ paging,
            filters: {} as AgreementFilters }));
        dispatch(fetchCorporates({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.simulationName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Agreement>
                            options={agreements}
                            value={filters.agreementId1}
                            onChange={(obj: Agreement | null) => onChange('agreementId1', obj?.id)}
                            placeholder={messages.agreementFrom}
                            compareFn={(o: Agreement) => o.id === filters.agreementId1}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Agreement>
                            options={agreements}
                            value={filters.agreementId2}
                            onChange={(obj: Agreement | null) => onChange('agreementId2', obj?.id)}
                            placeholder={messages.agreementTo}
                            compareFn={(o: Agreement) => o.id === filters.agreementId2}
                        />
                    </Grid>
                    <Grid item xs>
                        <Autocomplete
                            value={customers.find(customer => customer.id === filters.customerId)}
                            onChange={(e, value: any) => onChange('customer', value.id)}
                            id="controllable-states-demo"
                            getOptionLabel={(option: Customer) => option.name}
                            filterSelectedOptions
                            inputValue={inputValue}
                            onInputChange={(event, newInputvalue: any) => {
                                setInputValue(newInputvalue);
                                if(newInputvalue.length > 2) {
                                    dispatch(fetchCustomers({ paging,
                                        filters: customerFilters,
                                        throttle: true }));
                                }
                            }}
                            filterOptions={(x) => x}
                            options={customers}
                            loading={propsIsLoadingCustomers}
                            renderInput={(params) => <TextField {...params} label={formatMessage(messages.customer)} variant="standard" />}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<CustomerCorporate>
                            options={corporates}
                            value={filters.customerCorporateId}
                            onChange={(obj: CustomerCorporate | null) => onChange('customerCorporateId', obj?.id)}
                            placeholder={messages.corporate}
                            compareFn={(o: CustomerCorporate) => o.id === filters.customerCorporateId}
                        />
                    </Grid>
                    <Grid item xs />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}