import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SurchargeRateUnit from '../interfaces/output/surchargeRateUnit';

export type SurchargeRateUnitState = {
    surchargeRateUnit: SurchargeRateUnit;
    isLoading: boolean;
    surchargeRateUnits: Pagination<SurchargeRateUnit>;
    error: HttpErrorResponse | undefined;
};

const initialState: SurchargeRateUnitState = {
    surchargeRateUnit: {} as SurchargeRateUnit,
    surchargeRateUnits: { content: [] as Array<SurchargeRateUnit> } as Pagination<SurchargeRateUnit>,
    isLoading: false,
    error: undefined
};

export const surchargeRateUnitSlice = createSlice({
    name: 'surchargeRateUnit',

    initialState,
    reducers: {
        fetchSurchargeRateUnits: (state: SurchargeRateUnitState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchSurchargeRateUnitsSuccess: (state: SurchargeRateUnitState, action: PayloadAction<Pagination<SurchargeRateUnit>>) => {
            state.isLoading = false;
            state.surchargeRateUnits = action.payload;
        },
        fetchSurchargeRateUnitsError: (state: SurchargeRateUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchSurchargeRateUnitById: (state: SurchargeRateUnitState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchSurchargeRateUnitByIdSuccess: (state: SurchargeRateUnitState, action: PayloadAction<SurchargeRateUnit>) => {
            state.isLoading = false;
            state.surchargeRateUnit = action.payload;
        },
        fetchSurchargeRateUnitByIdError: (state: SurchargeRateUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchSurchargeRateUnitById, fetchSurchargeRateUnitByIdError, fetchSurchargeRateUnitByIdSuccess,
    fetchSurchargeRateUnits, fetchSurchargeRateUnitsError, fetchSurchargeRateUnitsSuccess } = surchargeRateUnitSlice.actions;

export const isLoadingSurchargeRateUnit = (state: RootState): boolean => state.surchargeRateUnitReducer.isLoading;
export const getSurchargeRateUnits = (state: RootState): Pagination<SurchargeRateUnit> => state.surchargeRateUnitReducer.surchargeRateUnits;
export const getSurchargeRateUnit = (state: RootState): SurchargeRateUnit => state.surchargeRateUnitReducer.surchargeRateUnit;

export default surchargeRateUnitSlice.reducer;