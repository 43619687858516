import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import ServiceArena from '../interfaces/output/serviceArena';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import ServiceArenaFilters from '../interfaces/filters/serviceArenaFilters';

export default class ServiceArenaApi {
    static fetchServicesArena = async(paging: Paging, filters: ServiceArenaFilters): Promise<Pagination<ServiceArena>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/servicearena');

        const { data } = await http.get<Pagination<ServiceArena>>(url);

        return data;
    };
}
