import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Product from '../interfaces/output/product';
import ProductFilters from '../interfaces/output/productFilters';
import ProductDynamics from '../interfaces/output/productDynamics';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import LanguageUtils from '../utils/LanguageUtils';

export default class ProductApi {
    static fetchProductById = async(productId: string): Promise<Product> => {
        const { data } = await http.get<Product>(`products/${productId}`);

        return data;
    };

    static fetchProductDynamicsById = async(id: number): Promise<Pagination<ProductDynamics>> => {
        const { data } = await http.get<Pagination<ProductDynamics>>(`productdynamicsids/${id}`);

        return data;
    };

    static fetchProductDynamicsByProductId = async(productId: number, paging: Paging): Promise<Pagination<ProductDynamics>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `products/${productId}/dynamicsIds`);

        const { data } = await http.get<Pagination<ProductDynamics>>(url);

        return data;
    };

    static fetchProducts = async(paging: Paging, filters: ProductFilters): Promise<Array<Product>> => {

        const usedLanguage = LanguageUtils.getLanguage();

        if(usedLanguage === 'nb-NO') {
            filters = {
                ...filters,
                name_NO: filters.name,
                name: ''
            } as ProductFilters;
        }

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/products');

        const { data } = await http.get<Array<Product>>(url);

        return data;
    };

    static createProduct = async(product: Product): Promise<Product> => {
        const newProduct = {
            name: product.name_ENG,
            distanceKm: product.distanceKm,
            distanceDiesel: product.distanceDiesel,
            distanceEl: product.distanceEl,
            fromTerminalId: product.fromTerminal?.id,
            toTerminalId: product.toTerminal?.id,
            productArenaId: product.productArena?.id,
            unitGroupId: product.unitGroup?.id,
            productTypeId: product.productType?.id,
            nameNo: product.name_NO
        };

        const { data } = await http.post<any, Product>('products', newProduct);

        return data;
    }

    static updateProduct = async(product: Product): Promise<Product> => {
        const newProduct = {
            name: product.name_ENG,
            distanceKm: product.distanceKm,
            distanceDiesel: product.distanceDiesel,
            distanceEl: product.distanceEl,
            fromTerminalId: product.fromTerminal?.id,
            toTerminalId: product.toTerminal?.id,
            productArenaId: product.productArena?.id,
            unitGroupId: product.unitGroup?.id,
            productTypeId: product.productType?.id,
            nameNo: product.name_NO
        };

        const { data } = await http.put<any, Product>(`products/${product.id}`, newProduct);

        return data;
    }

    static updateProductDynamics = async(dynamics: ProductDynamics): Promise<ProductDynamics> => {
        const newProductDynamics = {
            dynamicsId: dynamics?.dynamicsId,
            d365VatId: dynamics?.d365Vat?.id,
            d365ActivityId: dynamics?.d365Activity?.id,
            d365AccountId: dynamics?.d365Account?.id,
            d365ResponsibilityCenterId: dynamics?.d365ResponsibilityCenter?.id,
            d365ObjectId: dynamics?.d365Object?.id,
            d365SpecId: dynamics?.d365Spec?.id,
            d365InternalTradeId: dynamics?.d365InternalTrade?.id
        };

        const { data } = await http.put<any, ProductDynamics>(`productdynamicsids/${dynamics.id}`, newProductDynamics);

        return data;
    }

    static deactivateProductDynamics = async(productDynamicsId: number): Promise<ProductDynamics> => {
        const { data } = await http.delete<any, ProductDynamics>(`productdynamicsids/${productDynamicsId}`);

        return data;
    }

    static createProductDynamics = async(dynamics: ProductDynamics): Promise<ProductDynamics> => {
        const newProduct = {
            dynamicsId: dynamics?.dynamicsId,
            d365VatId: dynamics?.d365Vat?.id,
            d365ActivityId: dynamics?.d365Activity?.id,
            d365AccountId: dynamics?.d365Account?.id,
            d365ResponsibilityCenterId: dynamics?.d365ResponsibilityCenter?.id,
            d365ObjectId: dynamics?.d365Object?.id,
            d365SpecId: dynamics?.d365Spec?.id,
            d365InternalTradeId: dynamics?.d365InternalTrade?.id
        };

        const { data } = await http.post<any, ProductDynamics>(`products/${dynamics.productId}/dynamicsId`, newProduct);

        return data;
    }

    static deactivateProduct = async(product: Product): Promise<Product> => {
        const newProduct = {
            name: product.name,
            distanceKm: product.distanceKm,
            distanceDiesel: product.distanceDiesel,
            distanceEl: product.distanceEl,
            fromTerminalId: product.fromTerminal?.id,
            toTerminalId: product.toTerminal?.id,
            productArenaId: product.productArena?.id,
            unitGroupId: product.unitGroup?.id,
            productTypeId: product.productType?.id
        };

        const { data } = await http.put<any, Product>(`products/${product.id}`, newProduct);

        return data;
    }
}