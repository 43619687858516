import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
//import urlcat from 'urlcat';
import ResponsibilityCenter from '../interfaces/output/responsibilityCenter';
import ResponsibilityCenterFilters from '../interfaces/filters/responsibilityCenterFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ResponsibilityCenterApi {
    static fetchResponsibilityCenterById = async(responsibilityCenterId: string): Promise<ResponsibilityCenter> => {
        const { data } = await http.get<ResponsibilityCenter>(`/d365responsibilitycenter/${responsibilityCenterId}`);

        return data;
    };

    static fetchResponsibilitiesCenter = async(paging: Paging, filters?: ResponsibilityCenterFilters): Promise<Pagination<ResponsibilityCenter>> => {
        //const searchTerm = filters?.searchTerm;

        // const url = urlcat('', '/d365responsibilitycenter', {
        //     ...paging,
        //     searchTerm
        // });

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/d365responsibilitycenter');

        const { data } = await http.get<Pagination<ResponsibilityCenter>>(url);

        return data;
    };
    static createResponsibilityCenter = async(responsabilityCenter: ResponsibilityCenter): Promise<ResponsibilityCenter> => {
        const newResponsibilityCenter = {
            name: responsabilityCenter.name,
            code: responsabilityCenter.code
        };

        const { data } = await http.post<any, ResponsibilityCenter>('d365responsibilitycenter', newResponsibilityCenter);

        return data;
    }

    static updateResponsibilityCenter = async(responsabilityCenter: ResponsibilityCenter): Promise<ResponsibilityCenter> => {
        const newResponsibilityCenter = {
            name: responsabilityCenter.name,
            code: responsabilityCenter.code
        };

        const { data } = await http.put<any, ResponsibilityCenter>(`d365responsibilitycenter/${responsabilityCenter.id}`, newResponsibilityCenter);

        return data;
    }
}