import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import CustomerCorporate from '../../interfaces/output/customerCorporate';
import LanguageUtils from '../../utils/LanguageUtils';
import { useIntl } from 'react-intl';

interface ICorporatesListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    corporates?: Pagination<CustomerCorporate>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    name: LanguageUtils.createMessage('Corporate name')
};

const CorporatesList = forwardRef((props: ICorporatesListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, corporates,
        onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'name',
                headerName: intl.formatMessage(messages.name),
                width: 400,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params).name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || corporates} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            ref={dataGridRef} checkSelection={checkSelection} onPageChange={onPageChange}
            onSortModelChange={onSortModelChange} columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default CorporatesList;