import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ResponsibilityCenterApi from '../api/responsibilityCenterApi';
import {
    createResponsibilityCenterError,
    createResponsibilityCenterSuccess,
    fetchResponsibilitiesCenterError, fetchResponsibilitiesCenterSuccess,
    fetchResponsibilityCenterByIdError, fetchResponsibilityCenterByIdSuccess,
    updateResponsibilityCenterError,
    updateResponsibilityCenterSuccess
} from '../reducers/responsibilityCenterReducer';
import ResponsibilityCenter from '../interfaces/output/responsibilityCenter';
import ResponsibilityCenterFilters from '../interfaces/filters/responsibilityCenterFilters';

function* doFetchResponsibilityCenter(action: PayloadAction<string>) {
    const responsibilityCenterId = action.payload;
    try {
        const response: ResponsibilityCenter = yield call(ResponsibilityCenterApi.fetchResponsibilityCenterById, responsibilityCenterId);
        yield put(fetchResponsibilityCenterByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchResponsibilityCenterByIdError(error as HttpErrorResponse));
    }
}

function* doFetchResponsibilitiesCenter(action: PayloadAction<{ paging: Paging, filters?: ResponsibilityCenterFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ResponsibilityCenter> = yield call(ResponsibilityCenterApi.fetchResponsibilitiesCenter, paging, filters);
        yield put(fetchResponsibilitiesCenterSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchResponsibilitiesCenterError(error as HttpErrorResponse));
    }
}

function* doCreateResponsibilityCenter(action: PayloadAction<ResponsibilityCenter>) {
    const responsibilityCenter = action.payload;

    try {
        const response: ResponsibilityCenter = yield call(ResponsibilityCenterApi.createResponsibilityCenter, responsibilityCenter);

        yield put(createResponsibilityCenterSuccess(response));
    }

    catch (error: unknown) {
        yield put(createResponsibilityCenterError(error as HttpErrorResponse));
    }
}

function* doUpdateResponsibilityCenter(action: PayloadAction<ResponsibilityCenter>) {
    const responsibilityCenter = action.payload;

    try {
        const response: ResponsibilityCenter = yield call(ResponsibilityCenterApi.updateResponsibilityCenter, responsibilityCenter);

        yield put(updateResponsibilityCenterSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateResponsibilityCenterError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('responsibilityCenter/fetchResponsibilityCenterById', doFetchResponsibilityCenter),
    takeLatest('responsibilityCenter/fetchResponsibilitiesCenter', doFetchResponsibilitiesCenter),
    takeLatest('responsibilityCenter/createResponsibilityCenter', doCreateResponsibilityCenter),
    takeLatest('responsibilityCenter/updateResponsibilityCenter', doUpdateResponsibilityCenter)];

