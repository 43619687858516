import { put, takeLatest, call } from 'redux-saga/effects';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { fetchUnitTypeByIdError, fetchUnitTypeByIdSuccess, fetchUnitTypesError, fetchUnitTypesSuccess } from '../reducers/unitTypeReducer';
import UnitTypeApi from '../api/unitTypeApi';
import UnitType from '../interfaces/output/unitType';
import { PayloadAction } from '@reduxjs/toolkit';
import Pagination from '../interfaces/common/pagination';

function* doFetchUnitType(action: PayloadAction<string>) {
    const unitGroupId = action.payload;
    try {
        const response: UnitType = yield call(UnitTypeApi.fetchUnitType, unitGroupId);
        yield put(fetchUnitTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchUnitTypes() {
    try {
        const response: Pagination<UnitType> = yield call(UnitTypeApi.fetchUnitTypes);
        yield put(fetchUnitTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('unitType/fetchUnitTypeById', doFetchUnitType),
    takeLatest('unitType/fetchUnitTypes', doFetchUnitTypes)
];

