import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import AgreementVersionSpecialPriceFilters from '../interfaces/filters/agreementVersionSpecialPriceFilters';
import AgreementVersionSpecialPriceApi from '../api/agreementVersionSpecialPriceApi';
import AgreementVersionSpecialPriceOut from '../interfaces/output/agreementVersionSpecialPriceOut';
import { createAgreementVersionSpecialPriceByIdError, createAgreementVersionSpecialPriceByIdSuccess, deactivateAgreementVersionSpecialPriceByIdError,
    deactivateAgreementVersionSpecialPriceByIdSuccess, fetchAgreementVersionSpecialPriceByIdError, fetchAgreementVersionSpecialPriceByIdSuccess, fetchAgreementVersionSpecialPricesError,
    fetchAgreementVersionSpecialPricesSuccess, updateAgreementVersionSpecialPriceByIdError, updateAgreementVersionSpecialPriceByIdSuccess } from '../reducers/agreementVersionSpecialPriceReducer';

function* doFetchAgreementVersionSpecialPrice(action: PayloadAction<number>) {
    const specialPriceId = action.payload;
    try {
        const response: AgreementVersionSpecialPriceOut = yield call(AgreementVersionSpecialPriceApi.fetchAgreementVersionSpecialPriceById, specialPriceId);

        yield put(fetchAgreementVersionSpecialPriceByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionSpecialPriceByIdError(error as HttpErrorResponse));
    }
}

function* doFetchAgreementVersionSpecialPrices(action: PayloadAction<{ paging: Paging, filters: AgreementVersionSpecialPriceFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<AgreementVersionSpecialPriceOut> = yield call(AgreementVersionSpecialPriceApi.fetchAgreementVersionSpecialPrices, paging, filters);
        yield put(fetchAgreementVersionSpecialPricesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionSpecialPricesError(error as HttpErrorResponse));
    }
}

function* doCreateAgreementVersionSpecialPrice(action: PayloadAction<AgreementVersionSpecialPriceOut>) {
    const specialPrice = action.payload;

    try {
        const response: AgreementVersionSpecialPriceOut = yield call(AgreementVersionSpecialPriceApi.createAgreementVersionSpecialPrice, specialPrice);

        yield put(createAgreementVersionSpecialPriceByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(createAgreementVersionSpecialPriceByIdError(error as HttpErrorResponse));
    }
}

function* doUpdateAgreementVersionSpecialPrice(action: PayloadAction<AgreementVersionSpecialPriceOut>) {
    const specialPrice = action.payload;

    try {
        const response: AgreementVersionSpecialPriceOut = yield call(AgreementVersionSpecialPriceApi.updateAgreementVersionSpecialPrice, specialPrice);

        yield put(updateAgreementVersionSpecialPriceByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementVersionSpecialPriceByIdError(error as HttpErrorResponse));
    }
}

function* doDeactivateAgreementVersionSpecialPrice(action: PayloadAction<number>) {
    const specialPriceId = action.payload;
    
    try {
        const response: AgreementVersionSpecialPriceOut = yield call(AgreementVersionSpecialPriceApi.deactivateAgreementVersionSpecialPrice, specialPriceId);

        yield put(deactivateAgreementVersionSpecialPriceByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateAgreementVersionSpecialPriceByIdError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('agreementVersionSpecialPrice/fetchAgreementVersionSpecialPriceById', doFetchAgreementVersionSpecialPrice),
    takeLatest('agreementVersionSpecialPrice/fetchAgreementVersionSpecialPrices', doFetchAgreementVersionSpecialPrices),
    takeLatest('agreementVersionSpecialPrice/updateAgreementVersionSpecialPriceById', doUpdateAgreementVersionSpecialPrice),
    takeLatest('agreementVersionSpecialPrice/createAgreementVersionSpecialPriceById', doCreateAgreementVersionSpecialPrice),
    takeLatest('agreementVersionSpecialPrice/deactivateAgreementVersionSpecialPriceById', doDeactivateAgreementVersionSpecialPrice)
];