import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetCancellationLineOutput from '../interfaces/output/factorSetCancellationLineOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import CancellationLineFilters from '../components/factorSets/cancellation/line/cancellationLineFilters';

export type CancellationLineState = {
    factorSetCancellationLine: FactorSetCancellationLineOutput;
    factorSetVersionLines: Pagination<FactorSetCancellationLineOutput>;
    isLoadingCancellationLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: CancellationLineState = {
    factorSetCancellationLine: {} as FactorSetCancellationLineOutput,
    factorSetVersionLines: { content: [] as Array<FactorSetCancellationLineOutput> } as Pagination<FactorSetCancellationLineOutput>,
    isLoadingCancellationLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const cancellationLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetCancellationLineById: (state: CancellationLineState, _action: PayloadAction<number>) => {
            state.isLoadingCancellationLine = true;
        },
        fetchFactorSetCancellationLineByIdSuccess: (state: CancellationLineState, action: PayloadAction<FactorSetCancellationLineOutput>) => {
            state.isLoadingCancellationLine = false;
            state.factorSetCancellationLine = action.payload;
        },
        fetchFactorSetCancellationLineByIdError: (state: CancellationLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCancellationLine = false;
            state.error = action.payload;
        },
        fetchCancellationVersionLines: (state: CancellationLineState, _action: PayloadAction<{paging: Paging, filters: CancellationLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchCancellationVersionLinesSuccess: (state: CancellationLineState, action: PayloadAction<Pagination<FactorSetCancellationLineOutput>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchCancellationVersionLinesError: (state: CancellationLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createCancellationVersionLine: (state: CancellationLineState, _action: PayloadAction<FactorSetCancellationLineOutput>) => {
            state.isCreating = true;
        },
        createCancellationVersionLineSuccess: (state: CancellationLineState, action: PayloadAction<FactorSetCancellationLineOutput>) => {
            state.isCreating = false;
            state.factorSetCancellationLine = action.payload;
        },
        createCancellationVersionLineError: (state: CancellationLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetCancellationLine: (state: CancellationLineState, _action: PayloadAction<FactorSetCancellationLineOutput>) => {
            state.isUpdating = true;
        },
        updateFactorSetCancellationLineSuccess: (state: CancellationLineState, action: PayloadAction<FactorSetCancellationLineOutput>) => {
            state.isUpdating = false;
            state.factorSetCancellationLine = action.payload;
        },
        updateFactorSetCancellationLineError: (state: CancellationLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetCancellationLine: (state: CancellationLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetCancellationLineSuccess: (state: CancellationLineState, action: PayloadAction<FactorSetCancellationLineOutput>) => {
            state.isDeleting = false;
            state.factorSetCancellationLine = action.payload;
        },
        deactivateFactorSetCancellationLineError: (state: CancellationLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetCancellationLine: (state: CancellationLineState) => {
            state.factorSetCancellationLine = initialState.factorSetCancellationLine;
        },
        resetCancellationVersionLines: (state: CancellationLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetCancellationLineById, resetCancellationVersionLines, fetchFactorSetCancellationLineByIdSuccess, fetchFactorSetCancellationLineByIdError,
    updateFactorSetCancellationLine, updateFactorSetCancellationLineSuccess, updateFactorSetCancellationLineError, deactivateFactorSetCancellationLine, deactivateFactorSetCancellationLineError,
    deactivateFactorSetCancellationLineSuccess, fetchCancellationVersionLines, fetchCancellationVersionLinesSuccess, fetchCancellationVersionLinesError, createCancellationVersionLine,
    createCancellationVersionLineSuccess, createCancellationVersionLineError, resetFactorSetCancellationLine } = cancellationLineSlice.actions;

export const isLoadingCancellationLine = (state: RootState): boolean => state.cancellationLineReducer.isLoadingCancellationLine;
export const isLoadingFactorSetCancellationLines = (state: RootState): boolean => state.cancellationLineReducer.isLoadingLines;
export const isUpdatingCancellationLine = (state: RootState): boolean => state.cancellationLineReducer.isUpdating;
export const isDeletingCancellationLine = (state: RootState): boolean => state.cancellationLineReducer.isDeleting;
export const getFactorSetCancellationLine = (state: RootState): FactorSetCancellationLineOutput => state.cancellationLineReducer.factorSetCancellationLine;
export const getCancellationLines = (state: RootState): Pagination<FactorSetCancellationLineOutput> => state.cancellationLineReducer.factorSetVersionLines;
export const isCreatingCancellationLine = (state: RootState): boolean => state.cancellationLineReducer.isCreating;

export default cancellationLineSlice.reducer;