import { put, takeLatest, call } from 'redux-saga/effects';
import GTSApi from '../api/gtsApi';
import { fetchGtsOperationImportError, fetchGtsOperationImportSuccess, fetchGtsOperationPreviewError,
    fetchGtsOperationPreviewSuccess, fetchGtsOperationRawError, fetchGtsOperationRawSuccess } from '../reducers/gtsReducer';
import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchGtsOperationRaw(action: PayloadAction<string>) {
    const gtsId = action.payload;
    try {
        const response: CustomerOrder = yield call(GTSApi.fetchGtsOperationRaw, gtsId);
        yield put(fetchGtsOperationRawSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOperationRawError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOperationPreview(action: PayloadAction<string>) {
    const gtsId = action.payload;
    try {
        const response: GtsOrder = yield call(GTSApi.fetchGtsOperationPreview, gtsId);
        yield put(fetchGtsOperationPreviewSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOperationPreviewError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOperationImport(action: PayloadAction<string>) {
    const gtsId = action.payload;
    try {
        const response: GtsOrder = yield call(GTSApi.fetchGtsOperationImport, gtsId);
        yield put(fetchGtsOperationImportSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOperationImportError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('gts/fetchGtsOperationRaw', doFetchGtsOperationRaw),
    takeLatest('gts/fetchGtsOperationPreview', doFetchGtsOperationPreview),
    takeLatest('gts/fetchGtsOperationImport', doFetchGtsOperationImport)
];

