import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import UnitGroup from '../interfaces/output/unitGroup';
import UnitSubGroup from '../interfaces/output/unitSubGroup';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class UnitGroupApi {
    static fetchUnitGroupById = async(unitGroupId: string): Promise<UnitGroup> => {
        const { data } = await http.get<UnitGroup>(`unitgroup/${unitGroupId}`);

        return data;
    };

    static fetchUnitGroups = async(paging: Paging): Promise<Pagination<UnitGroup>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/unitgroup');
        const { data } = await http.get<Pagination<UnitGroup>>(url);

        return data;
    };

    static fetchUnitGroupSubgroups = async(groupId: number, paging: Paging): Promise<Array<UnitSubGroup>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/unitgroup/${groupId}/subgroups`);
        const { data } = await http.get<Array<UnitSubGroup>>(url);

        return data;
    };

    static createSubgroupsForUnitGroup = async(subgroup: UnitSubGroup): Promise<UnitSubGroup> => {
        const url = `/unitgroup/${subgroup.unitGroupId}/subgroups`;

        const newSubgroup = {
            name: subgroup?.name,
            code: subgroup?.code
        };

        const { data } = await http.post<any, UnitSubGroup>(url, newSubgroup);

        return data;
    }
}
