import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetWeightVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetWeightVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerWeightVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchWeightVersionById: (state: FactorSetWeightVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchWeightVersionByIdSuccess: (state: FactorSetWeightVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchWeightVersionByIdError: (state: FactorSetWeightVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchWeightVersions: (state: FactorSetWeightVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchWeightVersionsSuccess: (state: FactorSetWeightVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchWeightVersionsError: (state: FactorSetWeightVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updateWeightVersionStatus: (state: FactorSetWeightVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updateWeightVersionStatusSuccess: (state: FactorSetWeightVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updateWeightVersionStatusError: (state: FactorSetWeightVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updateWeightVersion: (state: FactorSetWeightVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updateWeightVersionSuccess: (state: FactorSetWeightVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updateWeightVersionError: (state: FactorSetWeightVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneWeightVersion: (state: FactorSetWeightVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        cloneWeightVersionSuccess: (state: FactorSetWeightVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        cloneWeightVersionError: (state: FactorSetWeightVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetWeightVersion: (state: FactorSetWeightVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetWeightVersions: (state: FactorSetWeightVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchWeightVersionById, fetchWeightVersionByIdSuccess, fetchWeightVersionByIdError, updateWeightVersionStatus, updateWeightVersionStatusSuccess,
    updateWeightVersionStatusError, cloneWeightVersion, updateWeightVersion, cloneWeightVersionSuccess, fetchWeightVersions, fetchWeightVersionsSuccess,
    fetchWeightVersionsError, updateWeightVersionSuccess, updateWeightVersionError, resetWeightVersion, cloneWeightVersionError, resetWeightVersions } = customerWeightVersionSlice.actions;

export const isLoadingWeightVersion = (state: RootState): boolean => state.factorSetWeightVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetWeightVersionReducer.isCloningVersion;
export const isUpdatingWeightVersionStatus = (state: RootState): boolean => state.factorSetWeightVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetWeightVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetWeightVersionReducer.isUpdatingVersion;
export const getWeightVersion = (state: RootState): FactorSetVersionOut => state.factorSetWeightVersionReducer.factorSetVersion;
export const isLoadingFactorSetWeightVersions = (state: RootState): boolean => state.factorSetWeightVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetWeightVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetWeightVersionReducer.factorSetVersions;

export default customerWeightVersionSlice.reducer;