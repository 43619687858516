import { http } from '../utils/http';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import GTSOrderUnitService from '../interfaces/output/gtsOrderUnitService';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class GTSOrderUnitServiceApi {
    static fetchGtsOrderUnitService = async(unitServiceId: string): Promise<GTSOrderUnitService> => {
        const { data } = await http.get<GTSOrderUnitService>(`staginggtsorderunitservicelines/${unitServiceId}`);

        return data;
    };

    static fetchGtsOrderUnitServices = async(paging: Paging, filters: GtsOrderUnitFilters): Promise<Array<GTSOrderUnitService>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/staginggtsorderunitservicelines');

        const { data } = await http.get<Array<GTSOrderUnitService>>(url);

        return data;
    };

    static updateGtsOrderUnitService = async(unitService: GTSOrderUnitService): Promise<GTSOrderUnitService> => {
        const newGood = {
            serviceTimestamp: unitService.serviceTimestamp,
            place: unitService.place,
            quantity: unitService.quantity,
            serviceType: unitService.serviceType
        };
        
        const { data } = await http.put<any, GTSOrderUnitService>(`staginggtsorderunitservicelines/${unitService.id}`, newGood);

        return data;
    }

    static deactivateGtsOrderUnitService = async(unitId: number): Promise<GTSOrderUnitService> => {
        const { data } = await http.delete<GTSOrderUnitService>(`staginggtsorderunitservicelines/${unitId}`);

        return data;
    }
}
