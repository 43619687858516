import { TextFieldProps, TextField } from '@mui/material';
import React, { FC, forwardRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

type Props = {
  onChange: (name: string | undefined, value: string) => void;
  prefix?: string;
  suffix?: string;
  name?: string;
};

type InputProps = TextFieldProps & {
  prefix?: string;
  suffix?: string;
  label?: MessageDescriptor;
  name?: string;
};

const NumberFormatCustom = forwardRef<NumberFormat<number>, Props>(
    function NumberFormatCustom(props, ref) {
        const { onChange, name, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange(name, values.value);
                }}
                thousandSeparator
                isNumericString
            />
        );
    }
);

export const NumericalInput: FC<InputProps> = ({
    className,
    prefix = '',
    suffix = '',
    inputProps,
    label = undefined,
    name = '',
    ...props
}) => (
    <TextField
        {...props}
        className={className}
        label={label ? useIntl().formatMessage(label) : ''}
        variant="standard"
        inputProps={{ ...inputProps,
            name,
            prefix,
            suffix }}
        InputProps={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: NumberFormatCustom as any
        }}
    />
);