import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import GTSOrderUnitServiceApi from '../api/unitServiceApi';
import GTSOrderUnitService from '../interfaces/output/gtsOrderUnitService';
import { deactivateGtsOrderUnitServicesError, deactivateGtsOrderUnitServicesSuccess, fetchGTSOrderUnitServicesByIdError, fetchGTSOrderUnitServicesByIdSuccess, fetchGTSOrderUnitServicesError,
    fetchGTSOrderUnitServicesSuccess, updateGTSOrderUnitServicesError, updateGTSOrderUnitServicesSuccess } from '../reducers/gtsUnitServiceReducer';

function* doFetchGtsOrderUnitService(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: GTSOrderUnitService = yield call(GTSOrderUnitServiceApi.fetchGtsOrderUnitService, id);
        yield put(fetchGTSOrderUnitServicesByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGTSOrderUnitServicesByIdError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOrderUnitsServices(action: PayloadAction<{ paging: Paging, filters: GtsOrderUnitFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<GTSOrderUnitService> = yield call(GTSOrderUnitServiceApi.fetchGtsOrderUnitServices, paging, filters);
        yield put(fetchGTSOrderUnitServicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGTSOrderUnitServicesError(error as HttpErrorResponse));
    }
}

function* doUpdateGtsOrderUnitService(action: PayloadAction<GTSOrderUnitService>) {
    const gtsOrderUnit = action.payload;

    try {
        const response: GTSOrderUnitService = yield call(GTSOrderUnitServiceApi.updateGtsOrderUnitService, gtsOrderUnit);

        yield put(updateGTSOrderUnitServicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateGTSOrderUnitServicesError(error as HttpErrorResponse));
    }
}

function* doDeactivateGtsOrderUnitService(action: PayloadAction<number>) {
    const unitId = action.payload;
    
    try {
        const response: GTSOrderUnitService = yield call(GTSOrderUnitServiceApi.deactivateGtsOrderUnitService, unitId);

        yield put(deactivateGtsOrderUnitServicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateGtsOrderUnitServicesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('unitService/fetchGTSOrderUnitServicesById', doFetchGtsOrderUnitService),
    takeLatest('unitService/fetchGTSOrderUnitServices', doFetchGtsOrderUnitsServices),
    takeLatest('unitService/updateGTSOrderUnitServices', doUpdateGtsOrderUnitService),
    takeLatest('unitService/deactivateGtsOrderUnitServices', doDeactivateGtsOrderUnitService)
];

