import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import LoadedFlag from '../interfaces/output/loadedFlag';
import { RootState } from '../setup';

export type LoadedFlagState = {
    loadedFlag: LoadedFlag;
    loadedFlags: Pagination<LoadedFlag>;
    error: HttpErrorResponse | undefined;
    isLoadingFlags: boolean;
    isLoadingFlag: boolean;
};

const initialState: LoadedFlagState = {
    loadedFlag: {} as LoadedFlag,
    loadedFlags: { content: [] as Array<LoadedFlag> } as Pagination<LoadedFlag>,
    error: undefined,
    isLoadingFlags: false,
    isLoadingFlag: false
};

export const loadedFlagSlice = createSlice({
    name: 'loadedFlag',

    initialState,
    reducers: {
        fetchLoadedFlags: (state: LoadedFlagState, _action: PayloadAction<{paging: Paging, includeDeleted: boolean}>) => {
            state.isLoadingFlags = true;
        },
        fetchLoadedFlagsSuccess: (state: LoadedFlagState, action: PayloadAction<Pagination<LoadedFlag>>) => {
            state.isLoadingFlags = false;
            state.loadedFlags = action.payload;
        },
        fetchLoadedFlagsError: (state: LoadedFlagState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFlags = false;
            state.error = action.payload;
        },
        fetchLoadedFlagById: (state: LoadedFlagState, _action: PayloadAction<number>) => {
            state.isLoadingFlag = true;
        },
        fetchLoadedFlagByIdSuccess: (state: LoadedFlagState, action: PayloadAction<LoadedFlag>) => {
            state.isLoadingFlag = false;
            state.loadedFlag = action.payload;
        },
        fetchLoadedFlagByIdError: (state: LoadedFlagState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFlag = false;
        }
    }
});

export const { fetchLoadedFlags, fetchLoadedFlagsSuccess, fetchLoadedFlagsError, fetchLoadedFlagById, fetchLoadedFlagByIdSuccess, fetchLoadedFlagByIdError } = loadedFlagSlice.actions;

export const getIsLoadingLoadedFlag = (state: RootState): boolean => state.loadedFlagReducer.isLoadingFlag;
export const getIsLoadingLoadedFlags = (state: RootState): boolean => state.loadedFlagReducer.isLoadingFlags;
export const getLoadedFlags = (state: RootState): Pagination<LoadedFlag> => state.loadedFlagReducer.loadedFlags;
export const getLoadedFlag = (state: RootState): LoadedFlag => state.loadedFlagReducer.loadedFlag;

export default loadedFlagSlice.reducer;