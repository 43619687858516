import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../utils/tableUtils';
import { useIntl } from 'react-intl';
import DisplayStatus from '../common/displayStatus';
import LanguageUtils from '../../utils/LanguageUtils';
import FactorSetTypesConstants from '../../constants/factorSetTypesConstants';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomLink from '../common/customLink';
import CustomStringLink from '../common/customStringLink';

interface IFactorsVersionsListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    hiddenColumns?: Array<string>;
    applyRowFn?: any;
    disableLinks?: boolean;
    type?: string;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    versionNumber: LanguageUtils.createMessage('Version number'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    status: LanguageUtils.createMessage('Status')
};

const FactorsVersionsList = forwardRef((props: IFactorsVersionsListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, disableLinks, type, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const handleFactorSetTypes = (): any => {
        switch (type) {
            case FactorSetTypesConstants.WEIGHT:
            { return `${UrlConstants.FACTOR_SET_WEIGHT}`; }
            case FactorSetTypesConstants.CANCELLATION:
            { return `${UrlConstants.FACTOR_SET_CANCELLATION}`; }
            case FactorSetTypesConstants.PRIORTY:
            { return `${UrlConstants.FACTOR_SET_PRIORITY}`; }
            case FactorSetTypesConstants.DEPARTURE:
            { return `${UrlConstants.FACTOR_SET_DEPARTURE}`; }
            case FactorSetTypesConstants.HOURS:
            { return `${UrlConstants.FACTOR_SET_HOURS}`; }
            case FactorSetTypesConstants.DANGEROUS_GOODS:
            { return `${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}`; }
            case FactorSetTypesConstants.CALENDAR:
            { return `${UrlConstants.FACTOR_SET_CALENDAR}`; }
        }

        return '';
    };

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            {
                field: 'versionNumber',
                headerName: intl.formatMessage(messages.versionNumber),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.FACTOR_SETS}/${handleFactorSetTypes()}/${cellValues.row.factorSetId}/${applyRowFn(cellValues)?.id}`}
                        value={applyRowFn(cellValues).versionNumber} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('versionNumber', hiddenColumns)
            },
            {
                field: 'validFromDate',
                headerName: intl.formatMessage(messages.validFromDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.FACTOR_SETS}/${handleFactorSetTypes()}/${params?.row?.factorSetId}/${applyRowFn(params)?.id}`}>
                        <NullableFormattedDate value={applyRowFn(params).validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: 'validToDate',
                headerName: intl.formatMessage(messages.validToDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.FACTOR_SETS}/${handleFactorSetTypes()}/${params?.row?.factorSetId}/${applyRowFn(params)?.id}`}>
                        <NullableFormattedDate value={applyRowFn(params).validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            },
            {
                field: 'status',
                headerName: intl.formatMessage(messages.status),
                flex: 1,
                align: 'center',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.FACTOR_SETS}/${handleFactorSetTypes()}/${params?.row?.factorSetId}/${applyRowFn(params)?.id}`}>
                        <DisplayStatus status={applyRowFn(params)?.status} inheritProps />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange} columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
});

export default FactorsVersionsList;