import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderStatus from '../interfaces/output/orderStatus';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type OrderStatusState = {
    orderStatus: OrderStatus;
    isLoading: boolean;
    orderStatuses: Pagination<OrderStatus>;
    error: HttpErrorResponse | undefined;
};

const initialState: OrderStatusState = {
    orderStatus: {} as OrderStatus,
    orderStatuses: { content: [] as Array<OrderStatus> } as Pagination<OrderStatus>,
    isLoading: false,
    error: undefined
};

export const orderStatusSlice = createSlice({
    name: 'orderStatus',

    initialState,
    reducers: {
        fetchOrderStatuses: (state: OrderStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchOrderStatusesSuccess: (state: OrderStatusState, action: PayloadAction<Pagination<OrderStatus>>) => {
            state.isLoading = false;
            state.orderStatuses = action.payload;
        },
        fetchOrderStatusesError: (state: OrderStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchOrderStatusById: (state: OrderStatusState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchOrderStatusByIdSuccess: (state: OrderStatusState, action: PayloadAction<OrderStatus>) => {
            state.isLoading = false;
            state.orderStatus = action.payload;
        },
        fetchOrderStatusByIdError: (state: OrderStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchOrderStatuses, fetchOrderStatusesSuccess, fetchOrderStatusesError, fetchOrderStatusById, fetchOrderStatusByIdSuccess, fetchOrderStatusByIdError } = orderStatusSlice.actions;

export const isLoadingOrderStatuses = (state: RootState): boolean => state.orderStatusReducer.isLoading;
export const getOrderStatuses = (state: RootState): Pagination<OrderStatus> => state.orderStatusReducer.orderStatuses;
export const getOrderStatus = (state: RootState): OrderStatus => state.orderStatusReducer.orderStatus;

export default orderStatusSlice.reducer;