import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { fetchProductArenaTypeByIdError, fetchProductArenaTypeByIdSuccess, fetchProductArenaTypesError, fetchProductArenaTypesSuccess } from '../reducers/productArenaTypeReducer';
import ProductArenaTypeApi from '../api/productArenaTypeApi';
import ProductArenaType from '../interfaces/output/productArenaType';
import ProductArenaTypeFilters from '../interfaces/filters/productArenaTypeFilters';

function* doFetchProductArenaType(action: PayloadAction<string>) {
    const productArenaId = action.payload;
    try {
        const response: ProductArenaType = yield call(ProductArenaTypeApi.fetchProductArenaType, productArenaId);
        yield put(fetchProductArenaTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductArenaTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductArenaTypes(action: PayloadAction<{ paging: Paging, filters?: ProductArenaTypeFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ProductArenaType> = yield call(ProductArenaTypeApi.fetchProductArenaTypes, paging, filters);
        yield put(fetchProductArenaTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductArenaTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('productArenaType/fetchProductArenaType', doFetchProductArenaType),
    takeLatest('productArenaType/fetchProductArenaTypes', doFetchProductArenaTypes)];

