class TableUtils {
    static hideColumn(nameField: string, hiddenColumns?: Array<string>): boolean {
        if(!hiddenColumns) {
            return false;
        }

        return hiddenColumns.includes(nameField);
    }
}

export default TableUtils;