import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Paging from '../../../interfaces/common/paging';
import { fetchPriceListVersions, getPriceListVersions, isLoadingPriceListVersions } from '../../../reducers/priceListReducer';
import { ensure } from '../../../utils/arrayUtils';
import PricelistVersionList from '../../pricelists/pricelistVersion/pricelistVersionList';
import PricelistVersionProduct from '../../../interfaces/output/pricelistVersionProduct';
import WorkflowUtils from '../../../utils/workflowUtils';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddPricelistVersionProductStep3Props {
    onChange: (pricelistVersionProduct: PricelistVersionProduct) => void;
    pricelistVersionProduct: PricelistVersionProduct
}

const AddPricelistVersionProductStep3 = forwardRef((props: AddPricelistVersionProductStep3Props, ref: any): JSX.Element => {
    const { onChange, pricelistVersionProduct } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const pricelistVersions = useSelector(getPriceListVersions);
    const propsIsLoadingPriceListVersions = useSelector(isLoadingPriceListVersions);
    const priceListId = pricelistVersionProduct.pricelist?.id;
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        dispatch(fetchPriceListVersions({ id: ensure(priceListId),
            paging: PageUtils.getDefaultPaging() }));
        
    }, [dispatch]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchPriceListVersions({
                paging: newServerPagination,
                id: ensure(priceListId)
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <PricelistVersionList
                    isLoading={propsIsLoadingPriceListVersions}
                    rows={pricelistVersions}
                    secondPrefix={'pricelist_'}
                    hiddenColumns={['productArena', 'basePrice']}
                    checkSelection={(value: number) => onChange({ ...pricelistVersionProduct,
                        pricelist: { ...pricelistVersionProduct.pricelist,
                            version: {
                                ...pricelistVersionProduct.pricelist?.version,
                                id: value
                            } } })}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchPriceListVersions({
                            paging: newPage,
                            id: ensure(priceListId)
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddPricelistVersionProductStep3;