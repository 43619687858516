import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Country from '../interfaces/output/countryOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import Language from '../interfaces/output/language';

export type LanguageState = {
    language: Language;
    isLoading: boolean;
    languages: Pagination<Language>;
    error: HttpErrorResponse | undefined;
};

const initialState: LanguageState = {
    language: {} as Language,
    languages: { content: [] as Array<Language> } as Pagination<Language>,
    isLoading: false,
    error: undefined
};

export const languageSlice = createSlice({
    name: 'language',

    initialState,
    reducers: {
        fetchLanguages: (state: LanguageState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchLanguagesSuccess: (state: LanguageState, action: PayloadAction<Pagination<Language>>) => {
            state.isLoading = false;
            state.languages = action.payload;
        },
        fetchLanguagesError: (state: LanguageState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchLanguageById: (state: LanguageState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchLanguageByIdSuccess: (state: LanguageState, action: PayloadAction<Language>) => {
            state.isLoading = false;
            state.language = action.payload;
        },
        fetchLanguageByIdError: (state: LanguageState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchLanguages, fetchLanguagesSuccess, fetchLanguagesError, fetchLanguageById, fetchLanguageByIdSuccess, fetchLanguageByIdError } = languageSlice.actions;

export const isLoadingLanguage = (state: RootState): boolean => state.languageReducer.isLoading;
export const getLanguages = (state: RootState): Pagination<Language> => state.languageReducer.languages;
export const getLanguage = (state: RootState): Language => state.languageReducer.language;

export default languageSlice.reducer;