import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import FactorSetOut from '../interfaces/output/factorSetOut';
import Pagination from '../interfaces/common/pagination';
import FactorSetHoursLineOutput from '../interfaces/output/factorSetHoursLineOut';
import HoursLineFilters from '../components/factorSets/hours/line/hoursLineFilters';
import FactorSetHoursLineInput from '../interfaces/input/factorSetHoursLineIn';
import FactorSetIn from '../interfaces/input/factorSetIn';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetHoursApi {
    static fetchFactorSetHourById = async(factorSetId: number, versionId?: number): Promise<FactorSetOut> => {
        const versionQueryString = versionId ? { versionId: versionId } : {};
        const url = urlcat('', `factorsets/hours/${factorSetId}`, versionQueryString);
        
        const { data } = await http.get<FactorSetOut>(url);

        return data;
    };

    static fetchFactorSetHours = async(paging: Paging, filters: HoursLineFilters): Promise<Array<FactorSetOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsets/hours');

        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static fetchFactorSetHoursLines = async(factorSetId: number, paging: Paging, filters: HoursLineFilters): Promise<Pagination<FactorSetHoursLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, `factorsets/hours/${factorSetId}/lines`);
        const { data } = await http.get<Pagination<FactorSetHoursLineOutput>>(url);

        return data;
    };

    static createFactorSetHour = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSet: FactorSetIn = {
            name: factorSet?.name,
            productTypeId: factorSet.productType?.id,
            noFactors: true,
            default: factorSet?.default ?? false,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id
        };

        const { data } = await http.post<FactorSetIn, FactorSetOut>('factorsets/hours', newFactorSet);

        return data;
    }

    static createHoursVersion = async(factorSet: FactorSetOut): Promise<FactorSetVersionOut> => {
        const url = `/factorsets/hours/${factorSet.id}/versions`;

        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSet.version?.validFromDate,
            validToDate: factorSet.version?.validToDate,
            statusId: 1 //set status to active
        };
        
        const { data } = await http.post<FactorSetVersionIn, FactorSetVersionOut>(url, newVersion);

        return data;
    }

    static createFactorSetHoursLine = async(factorSetLine: FactorSetHoursLineOutput): Promise<FactorSetHoursLineOutput> => {
        const newLine: FactorSetHoursLineInput = {
            name: factorSetLine.name,
            fromHours: factorSetLine.fromHours,
            toHours: factorSetLine.toHours,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            monday: factorSetLine.monday,
            tuesday:	factorSetLine.tuesday,
            wednesday:	factorSetLine.wednesday,
            thursday:	factorSetLine.thursday,
            friday:	factorSetLine.friday,
            saturday:	factorSetLine.saturday,
            sunday:	factorSetLine.sunday
        };

        const { data } = await http.post<FactorSetHoursLineInput, FactorSetHoursLineOutput>(`factorsets/hours/${factorSetLine.factorSetId}/lines`, newLine);

        return data;
    }

    static updateFactorSetHour = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSet: FactorSetIn = {
            name: factorSet.name,
            default: factorSet?.default ?? false,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id
        };
        
        const { data } = await http.put<FactorSetIn, FactorSetOut>(`factorsets/hours/${factorSet.id}`, newFactorSet);

        return data;
    }

    static deactivateFactorSetHour = async(id: number): Promise<FactorSetOut> => {
        const { data } = await http.delete<FactorSetOut>(`factorsets/hours/${id}`);

        return data;
    }
}