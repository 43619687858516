import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivatePriorityVersionStatusError, deactivatePriorityVersionStatusSuccess, fetchPriorityVersionStatusByIdError, fetchPriorityVersionStatusByIdSuccess,
    fetchPriorityVersionStatusesError, fetchPriorityVersionStatusesSuccess, updatePriorityVersionStatusError, updatePriorityVersionStatusSuccess } from '../reducers/priorityVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchPriorityVersionStatus(action: PayloadAction<string>) {
    const priorityVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, priorityVersionStatusId);
    
        yield put(fetchPriorityVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriorityVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPriorityVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchPriorityVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriorityVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdatePriorityVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const priorityVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, priorityVersionStatus);

        yield put(updatePriorityVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriorityVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivatePriorityVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const priorityVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, priorityVersionStatus);

        yield put(deactivatePriorityVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivatePriorityVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('priorityVersionStatus/fetchPriorityVersionStatusById', doFetchPriorityVersionStatus),
    takeLatest('priorityVersionStatus/fetchPriorityVersionStatuses', doFetchPriorityVersionStatuses),
    takeLatest('priorityVersionStatus/updatePriorityVersionStatus', doUpdatePriorityVersionStatus),
    takeLatest('priorityVersionStatus/deactivatePriorityVersionStatus', doDeactivatePriorityVersionStatus)
];