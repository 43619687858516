import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetDepartureVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetDepartureVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerDepartureVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchDepartureVersionById: (state: FactorSetDepartureVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchDepartureVersionByIdSuccess: (state: FactorSetDepartureVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchDepartureVersionByIdError: (state: FactorSetDepartureVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchDepartureVersions: (state: FactorSetDepartureVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchDepartureVersionsSuccess: (state: FactorSetDepartureVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchDepartureVersionsError: (state: FactorSetDepartureVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updateDepartureVersionStatus: (state: FactorSetDepartureVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updateDepartureVersionStatusSuccess: (state: FactorSetDepartureVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updateDepartureVersionStatusError: (state: FactorSetDepartureVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updateDepartureVersion: (state: FactorSetDepartureVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updateDepartureVersionSuccess: (state: FactorSetDepartureVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updateDepartureVersionError: (state: FactorSetDepartureVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneDepartureVersion: (state: FactorSetDepartureVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        cloneDepartureVersionSuccess: (state: FactorSetDepartureVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        cloneDepartureVersionError: (state: FactorSetDepartureVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetDepartureVersion: (state: FactorSetDepartureVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetDepartureVersions: (state: FactorSetDepartureVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchDepartureVersionById, fetchDepartureVersionByIdSuccess, fetchDepartureVersionByIdError, updateDepartureVersionStatus, updateDepartureVersionStatusSuccess,
    updateDepartureVersionStatusError, cloneDepartureVersion, updateDepartureVersion, cloneDepartureVersionSuccess, fetchDepartureVersions, fetchDepartureVersionsSuccess, fetchDepartureVersionsError,
    updateDepartureVersionSuccess, updateDepartureVersionError, resetDepartureVersion, cloneDepartureVersionError, resetDepartureVersions } = customerDepartureVersionSlice.actions;

export const isLoadingDepartureVersion = (state: RootState): boolean => state.factorSetDepartureVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetDepartureVersionReducer.isCloningVersion;
export const isUpdatingDepartureVersionStatus = (state: RootState): boolean => state.factorSetDepartureVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetDepartureVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetDepartureVersionReducer.isUpdatingVersion;
export const getDepartureVersion = (state: RootState): FactorSetVersionOut => state.factorSetDepartureVersionReducer.factorSetVersion;
export const isLoadingFactorSetDepartureVersions = (state: RootState): boolean => state.factorSetDepartureVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetDepartureVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetDepartureVersionReducer.factorSetVersions;

export default customerDepartureVersionSlice.reducer;