import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ResponsabilityCenter from '../interfaces/output/responsibilityCenter';
import ResponsabilityCenterFilters from '../interfaces/filters/responsibilityCenterFilters';
import ResponsibilityCenter from '../interfaces/output/responsibilityCenter';

export type ResponsibilityCenterState = {
    responsibilityCenter: ResponsibilityCenter;
    isLoading: boolean;
    responsibilitiesCenter: Pagination<ResponsibilityCenter>;
    error: HttpErrorResponse | undefined;
    isCreating: boolean;
    isUpdating: boolean;
};

const initialState: ResponsibilityCenterState = {
    responsibilityCenter: {} as ResponsabilityCenter,
    responsibilitiesCenter: { content: [] as Array<ResponsabilityCenter> } as Pagination<ResponsabilityCenter>,
    isLoading: false,
    error: undefined,
    isCreating: false,
    isUpdating: false
};

export const responsibilityCenterSlice = createSlice({
    name: 'responsibilityCenter',
    initialState,
    reducers: {
        fetchResponsibilitiesCenter: (state: ResponsibilityCenterState, _action: PayloadAction<{ paging: Paging, filters?: ResponsabilityCenterFilters }>) => {
            state.isLoading = true;
        },
        fetchResponsibilitiesCenterSuccess: (state: ResponsibilityCenterState, action: PayloadAction<Pagination<ResponsabilityCenter>>) => {
            state.isLoading = false;
            state.responsibilitiesCenter = action.payload;
        },
        fetchResponsibilitiesCenterError: (state: ResponsibilityCenterState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchResponsibilityCenterById: (state: ResponsibilityCenterState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchResponsibilityCenterByIdSuccess: (state: ResponsibilityCenterState, action: PayloadAction<ResponsabilityCenter>) => {
            state.isLoading = false;
            state.responsibilityCenter = action.payload;
        },
        fetchResponsibilityCenterByIdError: (state: ResponsibilityCenterState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createResponsibilityCenter: (state: ResponsibilityCenterState, _action: PayloadAction<ResponsabilityCenter>) => {
            state.isCreating = true;
        },
        createResponsibilityCenterSuccess: (state: ResponsibilityCenterState, action: PayloadAction<ResponsabilityCenter>) => {
            state.isCreating = false;
            state.responsibilityCenter = action.payload;
        },
        createResponsibilityCenterError: (state: ResponsibilityCenterState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateResponsibilityCenter: (state: ResponsibilityCenterState, _action: PayloadAction<ResponsabilityCenter>) => {
            state.isUpdating = true;
        },
        updateResponsibilityCenterSuccess: (state: ResponsibilityCenterState, action: PayloadAction<ResponsabilityCenter>) => {
            state.isUpdating = false;
            state.responsibilityCenter = action.payload;
        },
        updateResponsibilityCenterError: (state: ResponsibilityCenterState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetResponsibilitiesCenter: (state: ResponsibilityCenterState) => {
            state.responsibilitiesCenter = initialState.responsibilitiesCenter;
        },
        resetResponsibilityCenter: (state: ResponsibilityCenterState) => {
            state.responsibilityCenter = initialState.responsibilityCenter;
        }
    }
});

export const { fetchResponsibilitiesCenter, fetchResponsibilitiesCenterSuccess, fetchResponsibilitiesCenterError,
    fetchResponsibilityCenterById, fetchResponsibilityCenterByIdError, fetchResponsibilityCenterByIdSuccess,
    createResponsibilityCenter, createResponsibilityCenterError, createResponsibilityCenterSuccess,
    updateResponsibilityCenter, updateResponsibilityCenterError, updateResponsibilityCenterSuccess,
    resetResponsibilitiesCenter, resetResponsibilityCenter } = responsibilityCenterSlice.actions;

export const isLoadingResponsibilityCenter = (state: RootState): boolean => state.responsibilityCenterReducer.isLoading;
export const getResponsibilitiesCenter = (state: RootState): Pagination<ResponsabilityCenter> => state.responsibilityCenterReducer.responsibilitiesCenter;
export const getResponsibilityCenter = (state: RootState): ResponsabilityCenter => state.responsibilityCenterReducer.responsibilityCenter;
export const isCreatingResponsibilityCenter = (state: RootState): boolean => state.responsibilityCenterReducer.isCreating;
export const isUpdatingResponsibilityCenter = (state: RootState): boolean => state.responsibilityCenterReducer.isUpdating;

export default responsibilityCenterSlice.reducer;