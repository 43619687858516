import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetGtsOrderUnit } from '../../reducers/gtsOrderUnitReducer';
import GtsOrderUnitForm from './gtsOrderUnitForm';
import { FormType } from '../../constants/constants';

interface IEditOrderUnitProps {
    classes: any;
    theme: Theme;
    match: any;
    resetGtsOrderUnit: any;
    steps: Array<number>;
    gtsOrderId?: number;
    gtsOrderUnitId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetGtsOrderUnit: () => dispatch(resetGtsOrderUnit())
});

const mapStoreToProps = (store: RootState, ownProps: IEditOrderUnitProps) => {
    return {
        gtsOrderUnitId: ownProps.match.params?.gtsOrderUnitId ? Number(ownProps.match.params?.gtsOrderUnitId) : undefined,
        gtsOrderId: ownProps.match.params?.gtsOrderId ? Number(ownProps.match.params?.gtsOrderId) : undefined
    };
};

class EditOrderUnit extends Component<IEditOrderUnitProps> {

    componentWillUnmount() {
        const { resetGtsOrderUnit } = this.props;

        resetGtsOrderUnit();
    }

    render() {
        const { classes, steps, gtsOrderUnitId, type, gtsOrderId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <GtsOrderUnitForm gtsOrderUnitId={gtsOrderUnitId} steps={steps} type={type} gtsOrderId={gtsOrderId} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(EditOrderUnit));