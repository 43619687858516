/* eslint-disable @typescript-eslint/no-unused-vars */
import RoleOut from '../interfaces/output/roleOut';

function hasRoles(componentRoles: Array<string>, roles: Array<RoleOut>): boolean {
    const hasRole = componentRoles.some((componentRole: string) => {

        return !!roles.find((role: any) => role?.code === componentRole);
    });

    return hasRole;
}

export { hasRoles };