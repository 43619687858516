import React, { forwardRef, useEffect } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FactorSetCalendarLineOutput from '../../../../interfaces/output/factorSetCalendarLineOut';
import PageUtils from '../../../../utils/pageUtils';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { isLoadingFactorSetCalendar } from '../../../../reducers/factorSetCalendarReducer';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import CalendarLineFilters from '../line/calendarLineFilters';
import GenericTextField from '../../../common/widgets/genericTextField';
import { fetchCalendarVersionLines, isCreatingCalendarLine, isLoadingCalendarLine, isUpdatingCalendarLine } from '../../../../reducers/calendarLineReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';
import FactorSetCalendarLineStep1Validation from '../line/validations/factorSetCalendarLineStep1Validation';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitType from '../../../../interfaces/output/unitType';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import Currency from '../../../../interfaces/output/currency';
import { ensure } from '../../../../utils/arrayUtils';
import GenericDatePicker from '../../../common/widgets/genericDatePicker';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';

interface CalendarLineFormStep1Props {
    calendarLine: FactorSetCalendarLineOutput;
    factorSetVersionId?: number;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    CALENDAR_TYPE: 'calendarType',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge',
    VALID_FROM: 'validFrom',
    VALID_TO: 'validTo'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    calendarTypes: LanguageUtils.createMessage('Calendar types'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    unittype: LanguageUtils.createMessage('Unit type'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    calendarTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_CALENDAR_TYPE_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT'),
    validFromHelptext: LanguageUtils.createMessage('FACTOR_SET_VALID_FROM_HELPTEXT'),
    validToHelpText: LanguageUtils.createMessage('FACTOR_SET_VALID_TO_HELPTEXT'),
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to')
};

const CalendarLineFormStep1 = forwardRef((props: CalendarLineFormStep1Props, ref: any): JSX.Element => {
    const { calendarLine, onChange, factorSetVersionId, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingCalendarLine), useSelector(isLoadingCalendarLine), useSelector(isUpdatingCalendarLine),
        useSelector(isLoadingFactorSetCalendar)];
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const prevCalendarLine = WorkflowUtils.usePrevious<FactorSetCalendarLineOutput>(calendarLine);
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCalendarVersionLines({
            paging,
            filters: { factorSetVersionId: factorSetVersionId } as CalendarLineFilters }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(calendarLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(calendarLine.unitType?.unitGroupId),
                paging }));
            if(calendarLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(calendarLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevCalendarLine) {
            if(calendarLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(calendarLine.unitType?.unitGroupId),
                    paging }));
                if(prevCalendarLine.id === calendarLine.id) {
                    onChange('unitType', { ...calendarLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                }
            }
            else {
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [calendarLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevCalendarLine) {
            if(calendarLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(calendarLine.unitType?.unitSubGroupId),
                    paging }));
                if(prevCalendarLine.id === calendarLine.id) {
                    onChange('unitType', { ...calendarLine.unitType,
                        id: null });
                }
            }
            else {
                dispatch(resetUnitTypes());
            }
        }
    }, [calendarLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!calendarLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetCalendarLineStep1Validation.validateName(calendarLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = calendarLine.surcharge ? FactorSetCalendarLineStep1Validation.validateInputNumber(calendarLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetCalendarLineStep1Validation.validateInputNumber(calendarLine.factor);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = calendarLine.surcharge ? FactorSetCalendarLineStep1Validation.validateInputNumber(calendarLine.surchargeCurrency?.id) : true;
                break;
            }
            case ATTRIBUTES.VALID_FROM: {
                isValid = FactorSetCalendarLineStep1Validation.validateRequiredDate(calendarLine.fromDate) &&
                FactorSetCalendarLineStep1Validation.validateDateFormat(calendarLine.fromDate) &&
                FactorSetCalendarLineStep1Validation.validateInputDateStart(calendarLine);
                break;
            }
            case ATTRIBUTES.VALID_TO: {
                isValid = FactorSetCalendarLineStep1Validation.validateInputDateEnd(calendarLine) &&
                FactorSetCalendarLineStep1Validation.validateDateFormat(calendarLine.toDate);
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={calendarLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={calendarLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === calendarLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...calendarLine.unitType,
                                unitGroupId: obj?.id })}
                            placeholder={messages.unitGroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={calendarLine.unitType?.id}
                            compareFn={(o: UnitSubGroup) => o.id === calendarLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...calendarLine.unitType,
                                unitSubGroupId: obj?.id })}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={calendarLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={calendarLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === calendarLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...calendarLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unittype}
                            isLoading={calendarLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={calendarLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="fromDate"
                            label={messages.validFrom}
                            value={calendarLine?.fromDate}
                            onChange={onChange}
                            type="start"
                            error={setValidationState(ATTRIBUTES.VALID_FROM)}
                            required
                            helperText={messages.validFromHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="toDate"
                            label={messages.validTo}
                            value={calendarLine?.toDate}
                            onChange={onChange}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALID_TO)}
                            helperText={messages.validToHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        type="number"
                        label={messages.factor}
                        value={calendarLine.factor}
                        onChange={onChange}
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        type="number"
                        label={messages.surcharge}
                        value={calendarLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={calendarLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={calendarLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default CalendarLineFormStep1;