import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Theme } from '@mui/material';
import Pagination from '../../../interfaces/common/pagination';
import TransitDiscount from '../../../interfaces/output/transitDiscount';
import DataGridComponent from '../../common/dataGrid';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import UrlConstants from '../../../constants/UrlConstants';
import CustomStringLink from '../../common/customStringLink';

interface IAgreementVersionDiscountsProps {
    classes?: any;
    theme?: Theme;
    agreementVersionDiscounts: Pagination<TransitDiscount>;
    checkSelection: any;
    isLoading?: boolean;
    onPageChange?: any;
    applyRowFn?: any;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    productArenaFrom: LanguageUtils.createMessage('Product arena from'),
    productArenaTo: LanguageUtils.createMessage('Product arena to'),
    transitDiscount: LanguageUtils.createMessage('Transit discount')
};

const ViewAgreementVersionDiscounts = forwardRef((props: IAgreementVersionDiscountsProps, ref:any): JSX.Element => {
    const { agreementVersionDiscounts, checkSelection, onPageChange, isLoading, onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            {
                field: 'productArenaFrom',
                headerName: intl.formatMessage(messages.productArenaFrom),
                width: 250,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.productArenaFrom?.name} />;
                }
            },
            {
                field: 'productArenaTo',
                headerName: intl.formatMessage(messages.productArenaTo),
                width: 250,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${applyRowFn(params).id}`} value={params.row?.productArenaTo?.name ?? '-'} />;
                }
            },
            {
                field: 'transitDiscount',
                headerName: intl.formatMessage(messages.transitDiscount),
                width: 250,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${applyRowFn(params).id}`} value={`${params.row?.transitDiscount}` ?? '-'} />;
                }
            }
        ];
    };

    return (
        <DataGridComponent
            rows={agreementVersionDiscounts}
            columns={generateColumns()} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        />
    );
});
export default ViewAgreementVersionDiscounts;