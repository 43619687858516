import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetPriorityLineOutput from '../interfaces/output/factorSetPriorityLineOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import PriorityLineFilters from '../components/factorSets/priority/line/priorityLineFilters';

export type PriorityLineState = {
    factorSetPriorityLine: FactorSetPriorityLineOutput;
    factorSetVersionLines: Pagination<FactorSetPriorityLineOutput>;
    isLoadingPriorityLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: PriorityLineState = {
    factorSetPriorityLine: {} as FactorSetPriorityLineOutput,
    factorSetVersionLines: { content: [] as Array<FactorSetPriorityLineOutput> } as Pagination<FactorSetPriorityLineOutput>,
    isLoadingPriorityLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const priorityLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetPriorityLineById: (state: PriorityLineState, _action: PayloadAction<number>) => {
            state.isLoadingPriorityLine = true;
        },
        fetchFactorSetPriorityLineByIdSuccess: (state: PriorityLineState, action: PayloadAction<FactorSetPriorityLineOutput>) => {
            state.isLoadingPriorityLine = false;
            state.factorSetPriorityLine = action.payload;
        },
        fetchFactorSetPriorityLineByIdError: (state: PriorityLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPriorityLine = false;
            state.error = action.payload;
        },
        fetchPriorityVersionLines: (state: PriorityLineState, _action: PayloadAction<{paging: Paging, filters: PriorityLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchPriorityVersionLinesSuccess: (state: PriorityLineState, action: PayloadAction<Pagination<FactorSetPriorityLineOutput>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchPriorityVersionLinesError: (state: PriorityLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createPriorityVersionLine: (state: PriorityLineState, _action: PayloadAction<FactorSetPriorityLineOutput>) => {
            state.isCreating = true;
        },
        createPriorityVersionLineSuccess: (state: PriorityLineState, action: PayloadAction<FactorSetPriorityLineOutput>) => {
            state.isCreating = false;
            state.factorSetPriorityLine = action.payload;
        },
        createPriorityVersionLineError: (state: PriorityLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetPriorityLine: (state: PriorityLineState, _action: PayloadAction<FactorSetPriorityLineOutput>) => {
            state.isUpdating = true;
        },
        updateFactorSetPriorityLineSuccess: (state: PriorityLineState, action: PayloadAction<FactorSetPriorityLineOutput>) => {
            state.isUpdating = false;
            state.factorSetPriorityLine = action.payload;
        },
        updateFactorSetPriorityLineError: (state: PriorityLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetPriorityLine: (state: PriorityLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetPriorityLineSuccess: (state: PriorityLineState, action: PayloadAction<FactorSetPriorityLineOutput>) => {
            state.isDeleting = false;
            state.factorSetPriorityLine = action.payload;
        },
        deactivateFactorSetPriorityLineError: (state: PriorityLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetPriorityLine: (state: PriorityLineState) => {
            state.factorSetPriorityLine = initialState.factorSetPriorityLine;
        },
        resetPriorityVersionLines: (state: PriorityLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetPriorityLineById, resetPriorityVersionLines, fetchFactorSetPriorityLineByIdSuccess, fetchFactorSetPriorityLineByIdError,
    updateFactorSetPriorityLine, updateFactorSetPriorityLineSuccess, updateFactorSetPriorityLineError, deactivateFactorSetPriorityLine, deactivateFactorSetPriorityLineError,
    deactivateFactorSetPriorityLineSuccess, fetchPriorityVersionLines, fetchPriorityVersionLinesSuccess, fetchPriorityVersionLinesError, createPriorityVersionLine,
    createPriorityVersionLineSuccess, createPriorityVersionLineError, resetFactorSetPriorityLine } = priorityLineSlice.actions;

export const isLoadingPriorityLine = (state: RootState): boolean => state.priorityLineReducer.isLoadingPriorityLine;
export const isLoadingFactorSetPriorityLines = (state: RootState): boolean => state.priorityLineReducer.isLoadingLines;
export const isUpdatingPriorityLine = (state: RootState): boolean => state.priorityLineReducer.isUpdating;
export const isDeletingPriorityLine = (state: RootState): boolean => state.priorityLineReducer.isDeleting;
export const getFactorSetPriorityLine = (state: RootState): FactorSetPriorityLineOutput => state.priorityLineReducer.factorSetPriorityLine;
export const getPriorityLines = (state: RootState): Pagination<FactorSetPriorityLineOutput> => state.priorityLineReducer.factorSetVersionLines;
export const isCreatingPriorityLine = (state: RootState): boolean => state.priorityLineReducer.isCreating;

export default priorityLineSlice.reducer;