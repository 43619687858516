import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl, ToggleButtonGroup, ToggleButton, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import { fetchFactorSetWeights, getFactorSetWeights, isLoadingFactorSetWeights } from '../../../reducers/factorSetWeightReducer';
import { fetchFactorSetDepartures, getFactorSetDepartures, isLoadingFactorSetDepartures } from '../../../reducers/factorSetDepartureReducer';
import { fetchFactorSetPriorities, getFactorSetPriorities, isLoadingFactorSetPriorities } from '../../../reducers/factorSetPriorityReducer';
import PriceList from '../../../interfaces/output/priceList';
import { isCreatingPriceList, isCreatingPricelistVersion, isLoadingPriceList } from '../../../reducers/priceListReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import { isUpdatingPriceListStatus, isUpdatingPricelistVersion } from '../../../reducers/priceListVersionReducer';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import { fetchFactorSetHours, getFactorSetHours, isLoadingFactorSetHours } from '../../../reducers/factorSetHoursReducer';
import { fetchFactorSetCancellations, getFactorSetCancellations, isLoadingFactorSetCancellations } from '../../../reducers/factorSetCancellationReducer';
import { fetchPriceListVersionStatuses, getPriceListVersionStatuses, isLoadingPriceListVersionStatus } from '../../../reducers/pricelistVersionStatusReducer';
import PricelistVersionStatus from '../../../interfaces/output/pricelistVersionStatus';
import { fetchFactorSetCalendars, getFactorSetCalendars } from '../../../reducers/factorSetCalendarReducer';
import { fetchFactorSetDangerousGoods, getFactorSetDangerousGoods } from '../../../reducers/factorSetDangerousGoodsReducer';
import { useIntl } from 'react-intl';
import PricelistVersionStep1Validation from '../validation/pricelistVersionStep1Validation';

interface AddPriceListVersionStep1Props {
    priceList: PriceList;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    factorSetWeight: LanguageUtils.createMessage('Factor set weight'),
    factorSetPriority: LanguageUtils.createMessage('Factor set priority'),
    factorSetDeparture: LanguageUtils.createMessage('Factor set departure'),
    factorSetHours: LanguageUtils.createMessage('Factor set hours'),
    factorSetCancellation: LanguageUtils.createMessage('Factor set cancellation'),
    factorSetDangerousGoods: LanguageUtils.createMessage('Factor set dangerous goods'),
    factorSetCalendars: LanguageUtils.createMessage('Factor set calendar'),
    status: LanguageUtils.createMessage('Status'),
    onHold: LanguageUtils.createMessage('On hold'),
    fromDateHelpText: LanguageUtils.createMessage('PRICE_LIST_FROM_DATE_HELPTEXT'),
    toDateHelpText: LanguageUtils.createMessage('PRICE_LIST_TO_DATE_HELPTEXT'),
    weightHelpText: LanguageUtils.createMessage('PRICE_LIST_WEIGHT_HELPTEXT'),
    priorityHelpText: LanguageUtils.createMessage('PRICE_LIST_PRIORITY_HELPTEXT'),
    departureHelpText: LanguageUtils.createMessage('PRICE_LIST_DEPARTURE_HELPTEXT'),
    hoursHelpText: LanguageUtils.createMessage('PRICE_LIST_HOURS_HELPTEXT'),
    cancellationHelpText: LanguageUtils.createMessage('PRICE_LIST_CANCELLATION_HELPTEXT'),
    calendarHelpText: LanguageUtils.createMessage('PRICE_LIST_CALENDAR_HELPTEXT'),
    dangerousGoodsHelpText: LanguageUtils.createMessage('PRICE_LIST_DANGEROUS_GOODS_HELPTEXT'),
    onHoldHelpText: LanguageUtils.createMessage('PRICE_LIST_ON_HOLD_HELPTEXT'),
    versionStatusHelpText: LanguageUtils.createMessage('PRICE_LIST_VERSION_STATUS_HELPTEXT')
};

const ATTRIBUTES = {
    FROM_DATE: 'fromDate',
    TO_DATE: 'toDate',
    WEIGHT: 'weight',
    STATUS: 'status'
};

const AddPriceListVersionStep1 = forwardRef((props: AddPriceListVersionStep1Props, ref: any): JSX.Element => {
    const { priceList, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const factorSetWeights = useSelector(getFactorSetWeights).content;
    const factorSetDepartures = useSelector(getFactorSetDepartures).content;
    const factorSetPriorities = useSelector(getFactorSetPriorities).content;
    const factorSetCancellations = useSelector(getFactorSetCancellations).content;
    const factorSetCalendars = useSelector(getFactorSetCalendars).content;
    const factorSetDangerousGoods = useSelector(getFactorSetDangerousGoods).content;
    const priceListVersionStatuses = useSelector(getPriceListVersionStatuses).content;
    const factorSetHours = useSelector(getFactorSetHours).content;
    const isLoadingObjects = [useSelector(isCreatingPriceList), useSelector(isLoadingFactorSetDepartures), useSelector(isLoadingFactorSetWeights), useSelector(isLoadingFactorSetCancellations),
        useSelector(isLoadingFactorSetPriorities), useSelector(isCreatingPricelistVersion), useSelector(isUpdatingPricelistVersion), useSelector(isLoadingFactorSetHours),
        useSelector(isLoadingPriceList), useSelector(isLoadingPriceListVersionStatus), useSelector(isUpdatingPriceListStatus)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
    const productTypeId = priceList.productType?.id;
    const productArenaId = priceList.productArena?.id;
    const intl = useIntl();

    useEffect(() => {
        dispatch(fetchPriceListVersionStatuses({ paging }));
        dispatch(fetchFactorSetWeights({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
        dispatch(fetchFactorSetDepartures({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
        dispatch(fetchFactorSetPriorities({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
        dispatch(fetchFactorSetCancellations({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
        dispatch(fetchFactorSetHours({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
        dispatch(fetchFactorSetCalendars({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
        dispatch(fetchFactorSetDangerousGoods({ paging,
            filters: {
                productTypeId: productTypeId ? productTypeId : null,
                productArenaId: productArenaId } }));
    }, [dispatch, productTypeId, productArenaId]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.FROM_DATE: {
                isValid = PricelistVersionStep1Validation.validateRequiredDate(priceList.version?.validFromDate) &&
                PricelistVersionStep1Validation.validateInputDateStart(priceList);
                break;
            }
            case ATTRIBUTES.TO_DATE: {
                isValid = PricelistVersionStep1Validation.validateInputDateEnd(priceList);
                break;
            }
            case ATTRIBUTES.WEIGHT: {
                isValid = PricelistVersionStep1Validation.validateInputNumber(priceList.version?.factorSetWeight?.id);
                break;
            }
            case ATTRIBUTES.STATUS: {
                isValid = PricelistVersionStep1Validation.validateInputNumber(priceList.version?.status?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={1} mt={2} mb={1}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFromDate}
                            value={priceList.version?.validFromDate}
                            onChange={onChange}
                            type="start"
                            error={setValidationState(ATTRIBUTES.FROM_DATE)}
                            required
                            helperText={messages.fromDateHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validToDate}
                            value={priceList.version?.validToDate}
                            onChange={onChange}
                            type="end"
                            error={setValidationState(ATTRIBUTES.TO_DATE)}
                            helperText={messages.toDateHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetWeights}
                            value={priceList.version?.factorSetWeight}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetWeight', obj)}
                            placeholder={messages.factorSetWeight}
                            error={setValidationState(ATTRIBUTES.WEIGHT)}
                            required
                            helperText={messages.weightHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetPriorities}
                            value={priceList.version?.factorSetPriority}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetPriority', obj)}
                            placeholder={messages.factorSetPriority}
                            helperText={messages.priorityHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetDepartures}
                            value={priceList.version?.factorSetDeparture}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetDeparture', obj)}
                            placeholder={messages.factorSetDeparture}
                            helperText={messages.departureHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetHours}
                            value={priceList.version?.factorSetHours}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetHours', obj)}
                            placeholder={messages.factorSetHours}
                            helperText={messages.hoursHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetCancellations}
                            value={priceList.version?.factorSetCancellation}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetCancellation', obj)}
                            placeholder={messages.factorSetCancellation}
                            helperText={messages.cancellationHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetCalendars}
                            value={priceList.version?.factorSetCalendar}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetCalendar', obj)}
                            placeholder={messages.factorSetCalendars}
                            helperText={messages.calendarHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetOut>
                            options={factorSetDangerousGoods}
                            value={priceList.version?.factorSetDangerousGoods}
                            onChange={(obj: FactorSetOut | null) => onChange('factorSetDangerousGoods', obj)}
                            placeholder={messages.factorSetDangerousGoods}
                            helperText={messages.dangerousGoodsHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PricelistVersionStatus>
                            options={priceListVersionStatuses}
                            value={priceList.version?.status}
                            onChange={(obj: PricelistVersionStatus | null) => onChange('status', obj)}
                            placeholder={messages.status}
                            error={setValidationState(ATTRIBUTES.STATUS)}
                            helperText={messages.versionStatusHelpText}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6} >
                    <FormControlLabel label={intl.formatMessage(messages.onHold)} labelPlacement="start" style={{ marginLeft: 0,
                        verticalAlign: 'middle',
                        marginTop: 10 }}
                    control={<ToggleButtonGroup
                        color="primary"
                        value={priceList.version?.onHold}
                        exclusive
                        onChange={(e, value: any) => onChange('onHold', value)}
                        style={{ marginLeft: 10 }}
                    >
                        <ToggleButton size="small" value={true}>Yes</ToggleButton>
                        <ToggleButton size="small" value={false}>No</ToggleButton>
                    </ToggleButtonGroup>}/>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddPriceListVersionStep1;