import { List } from 'immutable';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range'),
    invalidDateFormat: LanguageUtils.createMessage('Invalid date format')
};

export default class FactorSetStep2Validation extends ValidationUtils {

    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validateInputDateStart(factorSet: FactorSetOut): boolean {
        const dateStart = factorSet.version?.validFromDate;
        const dateTo = factorSet.version?.validToDate;
        
        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(factorSet: FactorSetOut): boolean {
        const dateStart = factorSet.version?.validFromDate;
        const dateTo = factorSet.version?.validToDate;
        
        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateFactorSetForm(factorSet: FactorSetOut): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { version } = factorSet;

        const requiredFields = this.validateRequiredDate(version?.validFromDate) &&
        this.validateRequiredStringWithMaxLength(version?.status?.name);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = factorSet.version?.validToDate;
        const validDates = endDate ? this.validateInputDateStart(factorSet) &&
        this.validateInputDateEnd(factorSet) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        const formatedDate = this.validateDateFormat(version?.validFromDate) &&
        this.validateDateFormat(version?.validToDate);

        if(!formatedDate) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDateFormat);
        }

        return validationModel;
    }
}
