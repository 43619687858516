import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import PriceListApi from '../api/pricelistApi';
import { createPriceListError, createPriceListSuccess, createPriceListVersionError, createPriceListVersionSuccess,
    fetchPriceListByIdError, fetchPriceListByIdSuccess, fetchPriceListsError, fetchPriceListsSuccess,
    fetchPriceListVersionsError, fetchPriceListVersionsSuccess, updatePriceListError,
    updatePriceListSuccess } from '../reducers/priceListReducer';
import PriceList from '../interfaces/output/priceList';
import PriceListFilters from '../interfaces/output/priceListFilters';
import PriceListVersion from '../interfaces/output/priceListVersions';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchPriceList(action: PayloadAction<{ id: number, priceListVersionId?: number } >) {
    const { id, priceListVersionId } = action.payload;

    try {
        const response: PriceList = yield call(PriceListApi.fetchPriceListById, id, priceListVersionId);
        yield put(fetchPriceListByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPriceLists(action: PayloadAction<{ paging: Paging, filters: PriceListFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<PriceList> = yield call(PriceListApi.fetchPriceLists, paging, filters);
        yield put(fetchPriceListsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListsError(error as HttpErrorResponse));
    }
}

function* doFetchPriceListVersions(action: PayloadAction<{id: string, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<PriceListVersion> = yield call(PriceListApi.fetchPriceListVersions, id, paging);
        yield put(fetchPriceListVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListVersionsError(error as HttpErrorResponse));
    }
}

function* doCreatePriceList(action: PayloadAction<PriceList>) {
    const priceList = action.payload;

    try {
        const response: PriceList = yield call(PriceListApi.createPriceList, priceList);

        yield put(createPriceListSuccess(response));
    }

    catch (error: unknown) {
        yield put(createPriceListError(error as HttpErrorResponse));
    }
}

function* doCreatePriceListVersion(action: PayloadAction<PriceList>) {
    const priceList = action.payload;
    try {
        const response: PriceListVersion = yield call(PriceListApi.createPriceListVersion, priceList);
        const newPriceList = { ...priceList,
            version: response } as PriceList;

        yield put(createPriceListVersionSuccess(newPriceList));
    }

    catch (error: unknown) {
        yield put(createPriceListVersionError(error as HttpErrorResponse));
    }
}

function* doUpdatePriceList(action: PayloadAction<PriceList>) {
    const priceList = action.payload;

    try {
        const response: PriceList = yield call(PriceListApi.updatePriceList, priceList);

        yield put(updatePriceListSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriceListError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('pricelist/fetchPriceListById', doFetchPriceList),
    takeLatest('pricelist/fetchPriceListVersions', doFetchPriceListVersions),
    takeLatest('pricelist/fetchPriceLists', doFetchPriceLists),
    takeLatest('pricelist/createPriceList', doCreatePriceList),
    takeLatest('pricelist/createPriceListVersion', doCreatePriceListVersion),
    takeLatest('pricelist/updatePriceList', doUpdatePriceList)
];

