import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type DepartureVersionStatusState = {
    departureVersionStatus: FactorSetVersionStatusOut;
    departureVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: DepartureVersionStatusState = {
    departureVersionStatus: {} as FactorSetVersionStatusOut,
    departureVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const departureVersionStatusSlice = createSlice({
    name: 'departureVersionStatus',

    initialState,
    reducers: {
        fetchDepartureVersionStatuses: (state: DepartureVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchDepartureVersionStatusesSuccess: (state: DepartureVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.departureVersionStatuses = action.payload;
        },
        fetchDepartureVersionStatusesError: (state: DepartureVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchDepartureVersionStatusById: (state: DepartureVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchDepartureVersionStatusByIdSuccess: (state: DepartureVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.departureVersionStatus = action.payload;
        },
        fetchDepartureVersionStatusByIdError: (state: DepartureVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateDepartureVersionStatus: (state: DepartureVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updateDepartureVersionStatusSuccess: (state: DepartureVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.departureVersionStatus = action.payload;
        },
        updateDepartureVersionStatusError: (state: DepartureVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateDepartureVersionStatus: (state: DepartureVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivateDepartureVersionStatusSuccess: (state: DepartureVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.departureVersionStatus = action.payload;
        },
        deactivateDepartureVersionStatusError: (state: DepartureVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetDepartureVersionStatuses: (state: DepartureVersionStatusState) => {
            state.departureVersionStatuses = initialState.departureVersionStatuses;
        }
    }
});

export const { fetchDepartureVersionStatusById, fetchDepartureVersionStatusByIdSuccess, fetchDepartureVersionStatusByIdError, updateDepartureVersionStatus,
    updateDepartureVersionStatusSuccess, updateDepartureVersionStatusError, fetchDepartureVersionStatuses, fetchDepartureVersionStatusesSuccess,
    fetchDepartureVersionStatusesError, deactivateDepartureVersionStatus, deactivateDepartureVersionStatusSuccess, deactivateDepartureVersionStatusError,
    resetDepartureVersionStatuses } = departureVersionStatusSlice.actions;

export const isLoadingDepartureVersionStatus = (state: RootState): boolean => state.departureVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.departureVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.departureVersionStatusReducer.isDeleted;
export const getDepartureVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.departureVersionStatusReducer.departureVersionStatus;
export const getDepartureVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.departureVersionStatusReducer.departureVersionStatuses;

export default departureVersionStatusSlice.reducer;