import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetDepartureLineOutput from '../interfaces/output/factorSetDepartureLineOut';
import DepartureLineFilters from '../components/factorSets/departure/line/departureLineFilters';
import FactorSetDepartureLineInput from '../interfaces/input/factorSetDepartureLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class DepartureLineApi {
    static fetchFactorSetDepartureLineById = async(lineId: number): Promise<FactorSetDepartureLineOutput> => {
        const { data } = await http.get<FactorSetDepartureLineOutput>(`factorsetdeparturelines/${lineId}`);

        return data;
    };

    static fetchFactorSetDepartureLines = async(paging: Paging, filters: DepartureLineFilters): Promise<Pagination<FactorSetDepartureLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, 'factorsetdeparturelines');
        const { data } = await http.get<Pagination<FactorSetDepartureLineOutput>>(url);

        return data;
    };

    static createFactorSetDepartureLine = async(factorSetLine: FactorSetDepartureLineOutput): Promise<FactorSetDepartureLineOutput> => {
        const newFactorSetDeparture: FactorSetDepartureLineInput = {
            name: factorSetLine.name,
            departureTypeId: factorSetLine.departureType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            unitTypeId: factorSetLine.unitType?.id,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };

        const { data } = await http.post<FactorSetDepartureLineInput, FactorSetDepartureLineOutput>('factorsetdeparturelines', newFactorSetDeparture);

        return data;
    }

    static updateFactorSetDepartureLine = async(factorSetLine: FactorSetDepartureLineOutput): Promise<FactorSetDepartureLineOutput> => {
        const newLine: FactorSetDepartureLineInput = {
            name: factorSetLine.name,
            departureTypeId: factorSetLine.departureType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            unitTypeId: factorSetLine.unitType?.id,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };
        
        const { data } = await http.put<FactorSetDepartureLineInput, FactorSetDepartureLineOutput>(`factorsetdeparturelines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetDepartureLine = async(lineId: number): Promise<FactorSetDepartureLineOutput> => {
        const { data } = await http.delete<FactorSetDepartureLineOutput>(`factorsetdeparturelines/${lineId}`);

        return data;
    }
}