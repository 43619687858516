import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Theme } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import DataGridComponent from './dataGrid';
import TableUtils from '../../utils/tableUtils';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import TruncatedStringTooltip from './truncatedStringTooltip';

interface CustomerReportListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    gtsOrderNumber: LanguageUtils.createMessage('Gts order number'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    serviceDescription: LanguageUtils.createMessage('Service description'),
    salesOrderNumber: LanguageUtils.createMessage('Sales order number'),
    creditReason: LanguageUtils.createMessage('Credit reason'),
    grossPrice: LanguageUtils.createMessage('Gross price'),
    creditedSalesOrderNumber: LanguageUtils.createMessage('Credited sales order number'),
    currency: LanguageUtils.createMessage('Currency')
};

const CustomerReportList = forwardRef((props: CustomerReportListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));
    const intl = useIntl();

    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'gtsOrderNumber',
                headerName: `${intl.formatMessage(messages.gtsOrderNumber)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).gtsOrderNumber} />,
                hide: hiddenColumns && TableUtils.hideColumn('gtsOrderNumber', hiddenColumns)
            },
            {
                field: 'unitNo',
                headerName: `${intl.formatMessage(messages.unitNo)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).unitNo} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitNo', hiddenColumns)
            },
            {
                field: 'serviceDescription',
                headerName: `${intl.formatMessage(messages.serviceDescription)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).serviceDescription} />,
                hide: hiddenColumns && TableUtils.hideColumn('serviceDescription', hiddenColumns)
            },
            {
                field: 'salesOrderNumber',
                headerName: `${intl.formatMessage(messages.salesOrderNumber)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).salesOrderNumber} />,
                hide: hiddenColumns && TableUtils.hideColumn('salesOrderNumber', hiddenColumns)
            },
            {
                field: 'grossPrice',
                headerName: `${intl.formatMessage(messages.grossPrice)}`,
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).grossPrice} />,
                hide: hiddenColumns && TableUtils.hideColumn('grossPrice', hiddenColumns)
            },
            {
                field: 'currencyCode',
                headerName: `${intl.formatMessage(messages.currency)}`,
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params)?.currencyCode ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('currencyCode', hiddenColumns)
            },
            {
                field: 'creditedSalesOrderNumber',
                headerName: `${intl.formatMessage(messages.creditedSalesOrderNumber)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).creditedSalesOrderNumber} />,
                hide: hiddenColumns && TableUtils.hideColumn('creditedSalesOrderNumber', hiddenColumns)
            },
            {
                field: 'creditReason',
                headerName: `${intl.formatMessage(messages.creditReason)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(cellValues).creditReason} />,
                hide: hiddenColumns && TableUtils.hideColumn('creditReason', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} getRowId={(row) => row.pk_CustomerReport + Math.random()}
            multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps}
            isLoading={isLoading} ref={dataGridRef} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        />
    );
});

export default CustomerReportList;
