import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isLoadingUnitServiceProducts } from '../../../reducers/unitServiceReducer';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { isUpdatingOrderUnitServiceProduct } from '../../../reducers/orderUnitServiceProductReducer';
import { fetchOUSPStatuses, getOUSPStatuses, isLoadingOUSPServiceStatuses } from '../../../reducers/ouspStatusReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import OUSPStatus from '../../../interfaces/output/ouspStatus';

interface AddOrderUnitServiceProductStep5Props {
    orderUnitServiceProduct: OrderUnitServiceProduct;
    onChange: any;
}

const messages = {
    serviceStatus: LanguageUtils.createMessage('OUS status')
};

const AddOrderUnitServiceProductStep5 = forwardRef((props: AddOrderUnitServiceProductStep5Props, ref: any): JSX.Element => {
    const { orderUnitServiceProduct, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const serviceStatuses = useSelector(getOUSPStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingOUSPServiceStatuses), useSelector(isUpdatingOrderUnitServiceProduct), useSelector(isLoadingUnitServiceProducts)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchOUSPStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<OUSPStatus>
                            options={serviceStatuses}
                            value={orderUnitServiceProduct.ouspStatus}
                            onChange={(obj: OUSPStatus | null) => onChange('ouspStatus', obj)}
                            placeholder={messages.serviceStatus}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddOrderUnitServiceProductStep5;