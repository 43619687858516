import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceFrequency from '../interfaces/output/invoiceFrequency';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type InvoiceFrequencyState = {
    invoiceFrequency: InvoiceFrequency;
    isLoading: boolean;
    invoicesFrequency: Pagination<InvoiceFrequency>;
    error: HttpErrorResponse | undefined;
};

const initialState: InvoiceFrequencyState = {
    invoiceFrequency: {} as InvoiceFrequency,
    invoicesFrequency: { content: [] as Array<InvoiceFrequency> } as Pagination<InvoiceFrequency>,
    isLoading: false,
    error: undefined
};

export const invoiceFrequencySlice = createSlice({
    name: 'invoiceFrequency',

    initialState,
    reducers: {
        fetchInvoiceFrequencies: (state: InvoiceFrequencyState) => {
            state.isLoading = true;
        },
        fetchInvoiceFrequenciesSuccess: (state: InvoiceFrequencyState, action: PayloadAction<Pagination<InvoiceFrequency>>) => {
            state.isLoading = false;
            state.invoicesFrequency = action.payload;
        },
        fetchInvoiceFrequenciesError: (state: InvoiceFrequencyState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchInvoiceFrequencyById: (state: InvoiceFrequencyState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchInvoiceFrequencyByIdSuccess: (state: InvoiceFrequencyState, action: PayloadAction<InvoiceFrequency>) => {
            state.isLoading = false;
            state.invoiceFrequency = action.payload;
        },
        fetchInvoiceFrequencyByIdError: (state: InvoiceFrequencyState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchInvoiceFrequencies, fetchInvoiceFrequenciesSuccess, fetchInvoiceFrequenciesError, fetchInvoiceFrequencyById, fetchInvoiceFrequencyByIdSuccess,
    fetchInvoiceFrequencyByIdError } = invoiceFrequencySlice.actions;

export const isLoadingInvoiceFrequency = (state: RootState): boolean => state.invoiceFrequencyReducer.isLoading;
export const getInvoicesFrequency = (state: RootState): Pagination<InvoiceFrequency> => state.invoiceFrequencyReducer.invoicesFrequency;
export const getInvoiceFrequency = (state: RootState): InvoiceFrequency => state.invoiceFrequencyReducer.invoiceFrequency;

export default invoiceFrequencySlice.reducer;