import base64 from 'base-64';

const LOCAL_STORAGE_CONSTANTS = {
    AUTH_TOKEN: 'auth-token',
    AUTH_EXP: 'auth-exp'
};

const LOCAL_STORAGE_PAGINATION_CONSTANTS = {
    AGREEMENT_VERSION_PAGINATION: 'agreement-version-pagination'
};

const LOCAL_STORAGE_OBJECT_IDS = {
    AGREEMENT_ID: 'agreement-id'
};

const saveItem = (item: string, value: unknown): void => {
    const serializedValue = JSON.stringify(value);

    localStorage.setItem(base64.encode(item), base64.encode(serializedValue));
};

const getItem = <T>(itemKey: string): T | null => {
    const item = localStorage.getItem(base64.encode(itemKey));
    if(item) {
        const itemDecode = base64.decode(item);
        if(itemDecode !== null && itemDecode !== undefined) {
            return JSON.parse(base64.decode(item));
        }
        else { return null; }
    }
    else { return null; }
};

const clearItem = (key: string): void => {
    localStorage.removeItem(base64.encode(key));
};

export default {
    saveItem,
    getItem,
    clearItem,
    LOCAL_STORAGE_CONSTANTS,
    LOCAL_STORAGE_PAGINATION_CONSTANTS,
    LOCAL_STORAGE_OBJECT_IDS
};
