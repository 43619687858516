import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import Agreement from '../../interfaces/output/agreement';
import { resetAgreement } from '../../reducers/agreementsReducer';
import AddAgreementForm from './addAgreementForm';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../constants/constants';

interface IAddAgreementProps {
    classes: any;
    theme: Theme;
    match: any;
    resetAgreement: any;
    steps: Array<number>;
    agreementId?: number;
    agreementVersionId?: number;
    type: FormType;
}

interface IAddAgreementState {
    agreement: Agreement;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetAgreement: () => dispatch(resetAgreement())
});

const mapStoreToProps = (store: RootState, ownProps: IAddAgreementProps) => {
    return {
        agreementVersionId: ownProps.match.params?.agreementVersionId ? Number(ownProps.match.params?.agreementVersionId) : undefined,
        agreementId: ownProps.match.params?.agreementId ? Number(ownProps.match.params?.agreementId) : undefined
    };
};

class AddAgreement extends Component<IAddAgreementProps, IAddAgreementState> {
    componentWillUnmount() {
        const { resetAgreement } = this.props;

        resetAgreement();
    }

    render() {
        const { classes, steps, agreementId, agreementVersionId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddAgreementForm agreementId={agreementId} agreementVersionId={agreementVersionId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddAgreement));