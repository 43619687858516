import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import FactorSetPriorityLineOutput from '../../../../interfaces/output/factorSetPriorityLineOut';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import PriorityLineFormStep1 from './priorityLineFormStep1';
import { createPriorityVersionLine, fetchFactorSetPriorityLineById, getFactorSetPriorityLine, isCreatingPriorityLine, isUpdatingPriorityLine, updateFactorSetPriorityLine }
    from '../../../../reducers/priorityLineReducer';
import FactorSetPriorityLineStep1Validation from '../line/validation/factorSetPriorityLineStep1Validation';

interface IAddFactorSetPriorityLineFormProps {
    priorityLineId?: number;
    factorSetPriorityId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set priority line'),
    add: LanguageUtils.createMessage('Add factor set priority line')
};

export default function AddFactorSetPriorityLineForm(props: IAddFactorSetPriorityLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { priorityLineId, factorSetPriorityId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [priorityLine, setPriorityLine] = React.useState<FactorSetPriorityLineOutput>({ factorSetId: factorSetPriorityId,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetPriorityLineOutput);
    const propsPriorityLine = useSelector(getFactorSetPriorityLine);
    const propsIsUpdatingPriorityLine = useSelector(isUpdatingPriorityLine);
    const prevIsUpdatingPriorityLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriorityLine);
    const propsIsCreatingPriorityLine = useSelector(isCreatingPriorityLine);
    const prevIsCreatingPriorityLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingPriorityLine);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingPriorityLine === true && !propsIsUpdatingPriorityLine) || (prevIsCreatingPriorityLine === true && !propsIsCreatingPriorityLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingPriorityLine, propsIsUpdatingPriorityLine]);
    
    useEffect(() => {
        if(priorityLineId) {
            dispatch(fetchFactorSetPriorityLineById(priorityLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsPriorityLine.id) {
            setPriorityLine(propsPriorityLine);
        }

    }, [propsIsUpdatingPriorityLine, propsPriorityLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...priorityLine } as FactorSetPriorityLineOutput;
        (newLine as any)[attribute] = value;

        setPriorityLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <PriorityLineFormStep1 onChange={onChange} priorityLine={priorityLine} factorSetVersionId={factorSetVersionId} />,
            validationFn: () => FactorSetPriorityLineStep1Validation.validateFactorSetForm(priorityLine),
            onNext: () => {
                if(!priorityLine.id) {
                    dispatch(createPriorityVersionLine(priorityLine));
                }
                else {
                    dispatch(updateFactorSetPriorityLine(priorityLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddLine:
                return priorityLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/priority/${factorSetPriorityId}/${priorityLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/priority/${factorSetPriorityId}/latest/lines`;
            case FormType.EditLine:
                return priorityLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/priority/${factorSetPriorityId}/${priorityLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/priority/${factorSetPriorityId}/latest/lines`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  