import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import Terminal from '../../interfaces/output/terminal';
import ServiceType from '../../interfaces/output/serviceType';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import SurchargeType from '../../interfaces/output/surchargeType';
import { fetchSurchargeTypes, getSurchargeTypes } from '../../reducers/surchargeTypeReducer';
import SurchargeRateFilters from '../../interfaces/filters/surchargeRateFilters';
import { fetchSurchargeAreas, getSurchargeAreas } from '../../reducers/surchargeAreaReducer';
import { fetchSurchargeRateUnits, getSurchargeRateUnits } from '../../reducers/surchargeRateUnitReducer';
import SurchargeArea from '../../interfaces/output/surchargeArea';
import SurchargeRateUnit from '../../interfaces/output/surchargeRateUnit';
import ActionRenderer from '../common/actionRenderer';
import Action from '../../interfaces/common/action';
import GenericDatePicker from '../common/widgets/genericDatePicker';

interface IFilterProps {
    filters: SurchargeRateFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    surchargeType: LanguageUtils.createMessage('Surcharge Type'),
    surchargeRateUnit: LanguageUtils.createMessage('Surcharge Rate Unit'),
    surchargeArea: LanguageUtils.createMessage('Surcharge Area'),
    validFromDate: LanguageUtils.createMessage('Valid from date'),
    validToDate: LanguageUtils.createMessage('Valid to date'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function SurchargeRateFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const surchargeAreas = useSelector(getSurchargeAreas).content;
    const surchargeRateUnits = useSelector(getSurchargeRateUnits).content;
    const surchargeTypes = useSelector(getSurchargeTypes).content;

    useEffect(() => {
        dispatch(fetchSurchargeAreas({ paging }));
        dispatch(fetchSurchargeRateUnits({ paging }));
        dispatch(fetchSurchargeTypes({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <GenericAutocomplete<SurchargeType>
                            options={surchargeTypes}
                            value={filters.surchargeTypeId}
                            onChange={(obj: ServiceType | null) => onChange('surchargeTypeId', obj?.id)}
                            placeholder={messages.surchargeType}
                            compareFn={(o: SurchargeType) => o.id === filters.surchargeTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<SurchargeArea>
                            options={surchargeAreas}
                            value={filters.surchargeAreaId}
                            onChange={(obj: Terminal | null) => onChange('surchargeAreaId', obj?.id)}
                            placeholder={messages.surchargeArea}
                            compareFn={(o: Terminal) => o.id === filters.surchargeAreaId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<SurchargeRateUnit>
                            options={surchargeRateUnits}
                            value={filters.surchargeRateUnitId}
                            onChange={(obj: Terminal | null) => onChange('surchargeRateUnitId', obj?.id)}
                            placeholder={messages.surchargeRateUnit}
                            compareFn={(o: Terminal) => o.id === filters.surchargeRateUnitId}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericDatePicker
                                name="validFromDate"
                                label={messages.validFromDate}
                                value={filters.validFromDate}
                                onChange={onChange}
                                type="start"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericDatePicker
                                name="validToDate"
                                label={messages.validToDate}
                                value={filters.validToDate}
                                onChange={onChange}
                                type="end"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}