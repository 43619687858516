import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetCalendarLineOutput from '../interfaces/output/factorSetCalendarLineOut';
import CalendarLineFilters from '../components/factorSets/calendar/line/calendarLineFilters';
import FactorSetCalendarLineInput from '../interfaces/input/factorSetCalendarLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class CalendarLineApi {
    static fetchFactorSetCalendarLineById = async(lineId: number): Promise<FactorSetCalendarLineOutput> => {
        const { data } = await http.get<FactorSetCalendarLineOutput>(`factorsetcalendarlines/${lineId}`);

        return data;
    };

    static fetchFactorSetCalendarLines = async(paging: Paging, filters: CalendarLineFilters): Promise<Pagination<FactorSetCalendarLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, 'factorsetcalendarlines');

        const { data } = await http.get<Pagination<FactorSetCalendarLineOutput>>(url);

        return data;
    };

    static createFactorSetCalendarLine = async(factorSetLine: FactorSetCalendarLineOutput): Promise<FactorSetCalendarLineOutput> => {
        const newFactorSetCalendar: FactorSetCalendarLineInput = {
            name: factorSetLine.name,
            fromDate: factorSetLine.fromDate,
            toDate: factorSetLine.toDate,
            unitTypeId: factorSetLine.unitType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };

        const { data } = await http.post<FactorSetCalendarLineInput, FactorSetCalendarLineOutput>('factorsetcalendarlines', newFactorSetCalendar);

        return data;
    }

    static updateFactorSetCalendarLine = async(factorSetLine: FactorSetCalendarLineOutput): Promise<FactorSetCalendarLineOutput> => {
        const newLine: FactorSetCalendarLineInput = {
            name: factorSetLine.name,
            fromDate: factorSetLine.fromDate,
            toDate: factorSetLine.toDate,
            unitTypeId: factorSetLine.unitType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };
        
        const { data } = await http.put<FactorSetCalendarLineInput, FactorSetCalendarLineOutput>(`factorsetcalendarlines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetCalendarLine = async(lineId: number): Promise<FactorSetCalendarLineOutput> => {
        const { data } = await http.delete<FactorSetCalendarLineOutput>(`factorsetcalendarlines/${lineId}`);

        return data;
    }
}