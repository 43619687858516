import { http } from '../utils/http';
import PriceListVersion from '../interfaces/output/priceListVersions';

export default class PriceListVersionApi {
    static fetchPriceListVersionById = async(priceListVersionId: string): Promise<PriceListVersion> => {
        const { data } = await http.get<PriceListVersion>(`pricelistversions/${priceListVersionId}`);

        return data;
    };

    static updatePriceListVersionStatus = async(priceListVersion: PriceListVersion): Promise<PriceListVersion> => {
        
        const { data } = await http.put<any, PriceListVersion>(`pricelistversions/${priceListVersion.id}/status/${priceListVersion.status?.id}`);

        return data;
    }

    static updatePriceListVersion = async(priceListVersion: PriceListVersion): Promise<PriceListVersion> => {
        const newVersion = {
            validFromDate: priceListVersion.validFromDate,
            validToDate: priceListVersion.validToDate,
            onHold: priceListVersion.onHold,
            statusId: priceListVersion.status?.id,
            factorSetWeightId: priceListVersion.factorSetWeight?.id,
            factorSetPriorityId: priceListVersion.factorSetPriority?.id,
            factorSetDepartureId: priceListVersion.factorSetDeparture?.id,
            factorSetCancellationId: priceListVersion.factorSetCancellation?.id,
            factorSetHoursId: priceListVersion.factorSetHours?.id,
            factorSetCalendarId: priceListVersion.factorSetCalendar?.id,
            factorSetDangerousGoodsId: priceListVersion.factorSetDangerousGoods?.id
        };
        
        const { data } = await http.put<any, PriceListVersion>(`pricelistversions/${priceListVersion.id}`, newVersion);

        return data;
    }

    static clonePriceListVersion = async(toVersionId: number, fromVersionId: number): Promise<PriceListVersion> => {
        
        const { data } = await http.put<any, PriceListVersion>(`pricelistversions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}
