import { List } from 'immutable';
import inputLengthConstants from '../../../../../constants/inputLengthConstants';
import FactorSetCalendarLineOutput from '../../../../../interfaces/output/factorSetCalendarLineOut';
import { ensure } from '../../../../../utils/arrayUtils';
import LanguageUtils from '../../../../../utils/LanguageUtils';
import ValidationModel from '../../../../../utils/validationModel';
import ValidationUtils from '../../../../../utils/validationUtils';

const inputsMaxLengths = {
    name: inputLengthConstants.String60
};

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range'),
    invalidDateFormat: LanguageUtils.createMessage('Invalid date format')
};

export default class FactorSetCalendarLineStep1Validation extends ValidationUtils {
    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validateInputDateStart(factorSetLine: FactorSetCalendarLineOutput): boolean {
        const dateStart = factorSetLine.fromDate;
        const dateTo = factorSetLine.toDate;
        
        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(factorSetLine: FactorSetCalendarLineOutput): boolean {
        const dateStart = factorSetLine.fromDate;
        const dateTo = factorSetLine.toDate;
        
        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateFactorSetForm(factorSetLine: FactorSetCalendarLineOutput): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            unitType,
            factor,
            surcharge,
            surchargeCurrency
        } = factorSetLine;

        const requiredFields =
            this.validateName(name) &&
            this.validateInputNumber(unitType?.id, { required: surcharge ? true : false }) &&
            this.validateInputNumber(factor) &&
            this.validateInputNumber(surchargeCurrency?.id, { required: surcharge ? true : false });

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = factorSetLine.toDate;
        const validDates = endDate ? this.validateInputDateStart(factorSetLine) &&
        this.validateInputDateEnd(factorSetLine) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        const formatedDate = this.validateDateFormat(factorSetLine.fromDate) &&
        this.validateDateFormat(factorSetLine.toDate);

        if(!formatedDate) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDateFormat);
        }

        return validationModel;
    }

    static validateName = (inputString?: string): boolean => {
        return this.validateInputStringLength(inputString, {
            inputLength: inputsMaxLengths.name
        });
    }
}
