import React, { forwardRef, useEffect } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LanguageUtils from '../../../utils/LanguageUtils';
import Loader from '../../common/widgets/loader';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { fetchCurrencies, getCurrencies } from '../../../reducers/currencyReducer';
import PageUtils from '../../../utils/pageUtils';
import { isCreatingUnitServiceProduct, isLoadingUnitServiceProduct } from '../../../reducers/orderUnitServiceProductReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Currency from '../../../interfaces/output/currency';
import GenericTextField from '../../common/widgets/genericTextField';
import WorkflowUtils from '../../../utils/workflowUtils';

interface AddOrderUnitServiceProductStep4Props {
    orderUnitServiceProduct: OrderUnitServiceProduct;
    onChange: any;
}

const messages = {
    amount: LanguageUtils.createMessage('Amount'),
    productPrice: LanguageUtils.createMessage('Product price'),
    currency: LanguageUtils.createMessage('Currency'),
    none: LanguageUtils.createMessage('None')
};

const AddOrderUnitServiceProductStep4 = forwardRef((props: AddOrderUnitServiceProductStep4Props, ref: any): JSX.Element => {
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const isLoadingObjects = [useSelector(isCreatingUnitServiceProduct), useSelector(isLoadingUnitServiceProduct)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const currencies = useSelector(getCurrencies).content;
    WorkflowUtils.setHandle(ref, null);
    
    const { orderUnitServiceProduct, onChange } = props;

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            type="number"
                            name="amount"
                            label={messages.amount}
                            value={orderUnitServiceProduct.amount}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            type="number"
                            name="productPrice"
                            label={messages.productPrice}
                            value={orderUnitServiceProduct.productPrice}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={orderUnitServiceProduct.currency}
                            onChange={(obj: Currency | null) => onChange('currency', obj)}
                            placeholder={messages.currency}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddOrderUnitServiceProductStep4;