import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import PriceList from '../../../interfaces/output/priceList';
import { isCreatingPriceList, isLoadingPriceList, isUpdatingPriceList } from '../../../reducers/priceListReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../reducers/unitGroupReducer';
import { fetchSellers, getSellers, isLoadingSellers } from '../../../reducers/sellerReducer';
import { ensure } from '../../../utils/arrayUtils';
import { fetchPriceListTypes, getPriceListTypes, isLoadingPriceListTypes } from '../../../reducers/pricelistTypeReducer';
import { fetchProductArenas, getProductArenas, isLoadingProductArenas, resetProductArenas } from '../../../reducers/productArenaReducer';
import { fetchCurrencies, getCurrencies, isLoadingCurrency } from '../../../reducers/currencyReducer';
import { fetchProductTypes, getProductTypes, isLoadingProductTypes } from '../../../reducers/productTypesReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import UnitSubGroup from '../../../interfaces/output/unitSubGroup';
import UnitGroup from '../../../interfaces/output/unitGroup';
import PriceListType from '../../../interfaces/output/priceListType';
import ProductArena from '../../../interfaces/output/productArena';
import ProductType from '../../../interfaces/output/productType';
import Currency from '../../../interfaces/output/currency';
import Seller from '../../../interfaces/output/seller';
import GenericTextField from '../../common/widgets/genericTextField';
import PricingComponentTypeOut from '../../../interfaces/output/pricingComponentTypeOut';
import { fetchPricingComponentTypes, getPricingComponentTypes, isLoadingPricingComponentTypes, resetPricingComponentTypes } from '../../../reducers/pricingComponentTypeReducer';
import PricelistStep1Validation from '../validation/pricelistStep1Validation';

interface AddPriceListVersionStep1Props {
    priceList: PriceList;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('Price list name'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    revenueThisYear: LanguageUtils.createMessage('Revenue this year'),
    revenueLastYear: LanguageUtils.createMessage('Revenue last year'),
    discount: LanguageUtils.createMessage('Transit discount'),
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to'),
    productType: LanguageUtils.createMessage('Product type'),
    priceListType: LanguageUtils.createMessage('Price list type'),
    productArena: LanguageUtils.createMessage('Product arena'),
    subGroup: LanguageUtils.createMessage('Subgroup'),
    seller: LanguageUtils.createMessage('Seller'),
    currency: LanguageUtils.createMessage('Currency'),
    pricingComponentType: LanguageUtils.createMessage('Pricing component type'),
    nameHelpText: LanguageUtils.createMessage('PRICE_LIST_NAME_HELPTEXT'),
    currencyHelpText: LanguageUtils.createMessage('PRICE_LIST_CURRENCY_HELPTEXT'),
    sellerHelpText: LanguageUtils.createMessage('PRICE_LIST_SELLER_HELPTEXT'),
    typeHelpText: LanguageUtils.createMessage('PRICE_LIST_TYPE_HELPTEXT'),
    unitGroupHelpText: LanguageUtils.createMessage('PRICE_LIST_UNIT_GROUP_HELPTEXT'),
    unitSubGroupelpText: LanguageUtils.createMessage('PRICE_LIST_UNIT_SUB_GROUP_HELPTEXT'),
    productArenaHelpText: LanguageUtils.createMessage('PRICE_LIST_PRODUCT_ARENA_HELPTEXT')
};

const ATTRIBUTES = {
    NAME: 'name',
    CURRENCY: 'currency',
    SELLER: 'seller',
    PRICE_LIST_TYPE: 'priceListType',
    UNIT_GROUP: 'unitGroup',
    UNIT_SUB_GROUP: 'unitSubGroup',
    PRODUCT_ARENA: 'productArena',
    PRODUCT_TYPE: 'producType',
    PRICING_COMPONENT_TYPE: 'pricingComponentType'
};

const AddPriceListVersionStep1 = forwardRef((props: AddPriceListVersionStep1Props, ref: any): JSX.Element => {
    const { priceList, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const unitGroups = useSelector(getUnitGroups).content;
    const unitSubGroups = useSelector(getUnitSubGroups).content;
    const sellers = useSelector(getSellers).content;
    const types = useSelector(getPriceListTypes).content;
    const productArenas = useSelector(getProductArenas).content;
    const productTypes = useSelector(getProductTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const pricingComponents = useSelector(getPricingComponentTypes).content;
    const propsIsLoadingSubGroups = useSelector(isLoadingUnitSubgroups);
    const propsIsLoadingProductArenas = useSelector(isLoadingProductArenas);
    const propsIsLoadingPricingComponentTypes = useSelector(isLoadingPricingComponentTypes);
    const isLoadingObjects = [useSelector(isCreatingPriceList), useSelector(isUpdatingPriceList), useSelector(isLoadingPriceList), useSelector(isLoadingUnitGroups), useSelector(isLoadingSellers),
        useSelector(isLoadingCurrency), useSelector(isLoadingPriceListTypes), useSelector(isLoadingProductTypes)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
    const prevPricelistGroupId = WorkflowUtils.usePrevious<number | undefined>(priceList?.unitGroup?.id);
    const prevPricelistProductTypeId = WorkflowUtils.usePrevious<number | undefined>(priceList?.productType?.id);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchSellers({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchPriceListTypes({ paging }));
        dispatch(fetchProductTypes({ paging }));
    }, [dispatch]);

    useEffect(() => {
        if(priceList?.unitGroup?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(priceList?.unitGroup?.id),
                paging }));
            if(prevPricelistGroupId) {
                onChange('unitSubGroup', null);
            }
        }
        else {
            dispatch(resetSubgroups());
        }
    }, [priceList?.unitGroup?.id]);

    useEffect(() => {
        if(priceList?.productType?.id) {
            dispatch(fetchProductArenas({ filters: { productTypeId: ensure(priceList?.productType?.id) },
                paging }));
            dispatch(fetchPricingComponentTypes({ filters: { productTypeId: ensure(priceList?.productType?.id) },
                paging }));
            if(prevPricelistProductTypeId) {
                onChange('productArena', null);
                onChange('pricingComponentType', null);
            }
        }
        else {
            dispatch(resetProductArenas());
            dispatch(resetPricingComponentTypes());
        }
    }, [priceList?.productType?.id]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = PricelistStep1Validation.validateName(priceList.name);
                break;
            }
            case ATTRIBUTES.CURRENCY: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.currency?.id);
                break;
            }
            case ATTRIBUTES.PRICE_LIST_TYPE: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.type?.id);
                break;
            }
            case ATTRIBUTES.PRODUCT_ARENA: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.productArena?.id);
                break;
            }
            case ATTRIBUTES.UNIT_GROUP: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.unitGroup?.id);
                break;
            }
            case ATTRIBUTES.UNIT_SUB_GROUP: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.unitSubGroup?.id);
                break;
            }
            case ATTRIBUTES.SELLER: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.seller?.id);
                break;
            }
            case ATTRIBUTES.PRODUCT_TYPE: {
                isValid = PricelistStep1Validation.validateInputNumber(priceList.productType?.id);
                break;
            }

        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={1} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        required
                        value={priceList.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        helperText={messages.nameHelpText}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={priceList.currency}
                            onChange={(obj: Currency | null) => onChange('currency', obj)}
                            placeholder={messages.currency}
                            error={setValidationState(ATTRIBUTES.CURRENCY)}
                            required
                            helperText={messages.currencyHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Seller>
                            options={sellers}
                            value={priceList.seller}
                            onChange={(obj: Seller | null) => onChange('seller', obj)}
                            placeholder={messages.seller}
                            error={setValidationState(ATTRIBUTES.SELLER)}
                            required
                            helperText={messages.sellerHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PriceListType>
                            options={types}
                            value={priceList.type}
                            onChange={(obj: PriceListType | null) => onChange('type', obj)}
                            placeholder={messages.priceListType}
                            error={setValidationState(ATTRIBUTES.PRICE_LIST_TYPE)}
                            required
                            helperText={messages.typeHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={priceList.unitGroup}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                            placeholder={messages.unitGroup}
                            error={setValidationState(ATTRIBUTES.UNIT_GROUP)}
                            required
                            helperText={messages.unitGroupHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={unitSubGroups}
                            value={priceList.unitSubGroup}
                            isLoading={propsIsLoadingSubGroups}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitSubGroup', obj)}
                            placeholder={messages.subGroup}
                            error={setValidationState(ATTRIBUTES.UNIT_SUB_GROUP)}
                            required
                            helperText={messages.unitSubGroupelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ProductType>
                            options={productTypes}
                            value={priceList.productType}
                            onChange={(obj: ProductType | null) => onChange('productType', obj)}
                            placeholder={messages.productType}
                            error={setValidationState(ATTRIBUTES.PRODUCT_TYPE)}
                            required
                            helperText={messages.productType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ProductArena>
                            options={productArenas}
                            value={priceList.productArena}
                            onChange={(obj: ProductArena | null) => onChange('productArena', obj)}
                            placeholder={messages.productArena}
                            error={setValidationState(ATTRIBUTES.PRODUCT_ARENA)}
                            required
                            helperText={messages.productArenaHelpText}
                            isLoading={propsIsLoadingProductArenas}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PricingComponentTypeOut>
                            options={pricingComponents}
                            value={priceList.pricingComponentType}
                            onChange={(obj: PricingComponentTypeOut | null) => onChange('pricingComponentType', obj)}
                            isLoading={propsIsLoadingPricingComponentTypes}
                            placeholder={messages.pricingComponentType}
                            error={setValidationState(ATTRIBUTES.PRICING_COMPONENT_TYPE)}
                            required
                            helperText={messages.pricingComponentType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddPriceListVersionStep1;