import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import SimulationProductFilters from '../interfaces/filters/simulationProductFilters';
import SimulationProductOut from '../interfaces/output/simulationProductOut';
import SimulationProductIn from '../interfaces/input/simulationProductIn';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class SimulationProductApi {
    static fetchSimulationProductById = async(simulationProductId: string): Promise<SimulationProductOut> => {
        const { data } = await http.get<SimulationProductOut>(`simulationproduct/${simulationProductId}`);

        return data;
    };

    static fetchSimulationProducts = async(paging: Paging, filters: SimulationProductFilters): Promise<Pagination<SimulationProductOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/simulationProduct');
        const { data } = await http.get<Pagination<SimulationProductOut>>(url);

        return data;
    };

    static createSimulationProduct = async(simulationProduct: SimulationProductOut): Promise<SimulationProductOut> => {
        const newSimulationProduct: SimulationProductIn = {
            cancellationTypeId: simulationProduct.cancellationType?.id,
            departureTypeId: simulationProduct.departureType?.id,
            grossWeightKg: simulationProduct.grossWeightKg,
            netWeightKg: simulationProduct.netWeightKg,
            orderId: simulationProduct.order?.id,
            orderUnitServiceProductId: simulationProduct.orderUnitServiceProduct?.id,
            price1: simulationProduct.price1,
            price2: simulationProduct.price2,
            priceListId1: simulationProduct.priceListId1?.id,
            priceListId2: simulationProduct.priceListId2?.id,
            priceListOriginalId: simulationProduct.priceListOriginal?.id,
            priceOriginal: simulationProduct.priceOriginal,
            priorityTypeId: simulationProduct.priorityType?.id,
            productId: simulationProduct.product?.id,
            simulationId: simulationProduct.simulation?.id,
            unitTypeId: simulationProduct.unitType?.id
        };

        const { data } = await http.post<SimulationProductIn, SimulationProductOut>('simulationProduct', newSimulationProduct);

        return data;
    }

    static updateSimulationProduct = async(simulationProduct: SimulationProductOut): Promise<SimulationProductOut> => {
        const newSimulationProduct: SimulationProductIn = {
            cancellationTypeId: simulationProduct.cancellationType?.id,
            departureTypeId: simulationProduct.departureType?.id,
            grossWeightKg: simulationProduct.grossWeightKg,
            netWeightKg: simulationProduct.netWeightKg,
            orderId: simulationProduct.order?.id,
            orderUnitServiceProductId: simulationProduct.orderUnitServiceProduct?.id,
            price1: simulationProduct.price1,
            price2: simulationProduct.price2,
            priceListId1: simulationProduct.priceListId1?.id,
            priceListId2: simulationProduct.priceListId2?.id,
            priceListOriginalId: simulationProduct.priceListOriginal?.id,
            priceOriginal: simulationProduct.priceOriginal,
            priorityTypeId: simulationProduct.priorityType?.id,
            productId: simulationProduct.product?.id,
            simulationId: simulationProduct.simulation?.id,
            unitTypeId: simulationProduct.unitType?.id
        };
        
        const { data } = await http.put<SimulationProductIn, SimulationProductOut>(`simulationproduct/${simulationProduct.id}`, newSimulationProduct);

        return data;
    }

    static deleteSimulationProduct = async(simulationProduct: SimulationProductOut): Promise<SimulationProductOut> => {
        const { data } = await http.delete<SimulationProductOut>(`simulationproduct/${simulationProduct.id}`);

        return data;
    }
}