import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import AgreementVersionSpecialPriceOut from '../interfaces/output/agreementVersionSpecialPriceOut';
import AgreementVersionSpecialPriceFilters from '../interfaces/filters/agreementVersionSpecialPriceFilters';
import AgreementVersionSpecialPriceIn from '../interfaces/input/agreementVersionSpecialPriceIn';
import moment from 'moment';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class AgreementVersionSpecialPriceApi {
    static fetchAgreementVersionSpecialPriceById = async(specialPriceId: number): Promise<AgreementVersionSpecialPriceOut> => {
        const { data } = await http.get<AgreementVersionSpecialPriceOut>(`agreementversionspecialprice/${specialPriceId}`);

        return data;
    };

    static fetchAgreementVersionSpecialPrices = async(paging: Paging, filters: AgreementVersionSpecialPriceFilters): Promise<Array<AgreementVersionSpecialPriceOut>> => {
        const formattedFilters = { ...filters,
            validOnThisDate: filters.validOnThisDate ? moment(filters.validOnThisDate).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/agreementversionspecialprice');

        const { data } = await http.get<Array<AgreementVersionSpecialPriceOut>>(url);

        return data;
    };

    static updateAgreementVersionSpecialPrice = async(specialPrice: AgreementVersionSpecialPriceOut): Promise<AgreementVersionSpecialPriceOut> => {
        const newSpecialPrice: AgreementVersionSpecialPriceIn = {
            agreementVersionId: specialPrice.agreementVersion?.id,
            validFromDate: specialPrice.validFromDate,
            validToDate:	specialPrice.validToDate,
            grossWeightKgFrom: specialPrice.grossWeightKgFrom,
            grossWeightKgTo: specialPrice.grossWeightKgTo,
            loadedFlagId: specialPrice.loadedFlag?.id,
            price: specialPrice.price,
            terminalFromId: specialPrice.terminalFrom?.id,
            terminalToId: specialPrice.terminalTo?.id,
            departureTypeId: specialPrice.departureType?.id,
            unitTypeId: specialPrice.unitType?.id,
            priorityTypeId: specialPrice.priorityType?.id
        };
        
        const { data } = await http.put<AgreementVersionSpecialPriceIn, AgreementVersionSpecialPriceOut>(`agreementversionspecialprice/${specialPrice.id}`, newSpecialPrice);

        return data;
    }

    static createAgreementVersionSpecialPrice = async(specialPrice: AgreementVersionSpecialPriceOut): Promise<AgreementVersionSpecialPriceOut> => {
        const newSpecialPrice: AgreementVersionSpecialPriceIn = {
            agreementVersionId: specialPrice.agreementVersion?.id,
            validFromDate: specialPrice.validFromDate,
            validToDate:	specialPrice.validToDate,
            grossWeightKgFrom: specialPrice.grossWeightKgFrom,
            grossWeightKgTo: specialPrice.grossWeightKgTo,
            loadedFlagId: specialPrice.loadedFlag?.id,
            price: specialPrice.price,
            terminalFromId: specialPrice.terminalFrom?.id,
            terminalToId: specialPrice.terminalTo?.id,
            departureTypeId: specialPrice.departureType?.id,
            unitTypeId: specialPrice.unitType?.id,
            priorityTypeId: specialPrice.priorityType?.id
        };
        
        const { data } = await http.post<AgreementVersionSpecialPriceIn, AgreementVersionSpecialPriceOut>('agreementversionspecialprice', newSpecialPrice);

        return data;
    }

    static deactivateAgreementVersionSpecialPrice = async(specialPriceId: number): Promise<AgreementVersionSpecialPriceOut> => {
        const { data } = await http.delete<AgreementVersionSpecialPriceOut>(`agreementversionspecialprice/${specialPriceId}`);

        return data;
    }
}