import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AgreementFilters from '../../interfaces/filters/agreementFilters';
import Paging from '../../interfaces/common/paging';
import { fetchAgreements, getAgreement, getAgreements, isLoadingAgreements, resetAgreements } from '../../reducers/agreementsReducer';
import { RootState } from '../../setup';
import UrlConstants from '../../constants/UrlConstants';
import Agreement from '../../interfaces/output/agreement';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Filters from '../common/agreementFilters';
import { fetchUnitGroups, getUnitGroups } from '../../reducers/unitGroupReducer';
import UnitGroup from '../../interfaces/output/unitGroup';
import Seller from '../../interfaces/output/seller';
import { fetchSellers, getSellers } from '../../reducers/sellerReducer';
import MainLayout from '../common/widgets/mainLayout';
import AgreementList from './agreementList';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import { getUserDetails } from '../../reducers/userReducer';
import UserOut from '../../interfaces/output/userOut';

interface IAgreementsProps {
    agreements: Pagination<Agreement>;
    agreement: Agreement;
    sellers: Pagination<Seller>;
    unitGroups: Pagination<UnitGroup>;
    user: UserOut;
    classes: any;
    fetchAgreements: any;
    fetchUnitGroups: any;
    fetchSellers: any;
    isLoading: boolean;
    onChange: () => void;
    resetAgreements: () => void;
}

interface IAgreementsState {
    searchTerm: string;
    filters: AgreementFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    agreements: LanguageUtils.createMessage('Agreements'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAgreements: (paging: Paging, filters: AgreementFilters) => dispatch(fetchAgreements({
        paging,
        filters
    })),
    fetchUnitGroups: (paging: Paging) => dispatch(fetchUnitGroups({ paging })),
    fetchSellers: (paging: Paging) => dispatch(fetchSellers({ paging })),
    resetAgreements: () => dispatch(resetAgreements())
});

const mapStoreToProps = (store: RootState) => {
    return {
        agreements: getAgreements(store),
        agreement: getAgreement(store),
        isLoading: isLoadingAgreements(store),
        sellers: getSellers(store),
        unitGroups: getUnitGroups(store),
        user: getUserDetails(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class Agreements extends Component<IAgreementsProps, IAgreementsState> {
    agreementListRef: any;

    constructor(props: IAgreementsProps) {
        super(props);
        this.agreementListRef = React.createRef();
        this.state = {
            searchTerm: '',
            filters: {
                name: '',
                active: true,
                sellerId: props.user.sellerId || null
            } as AgreementFilters,
            paging: PageUtils.getDefaultPaging([{
                field: 'name',
                sort: 'asc'
            } as GridSortItem]),
            menuItem: {
                id: 1,
                name: messages.agreements,
                icon: <FontAwesomeIcon icon="handshake" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],

            columnVisibilityModel: {
                priorityRank: false,
                versions_defaultTransitDiscount: false,
                validity: false,
                versions_validFromDate: false,
                versions_validToDate: false
            }
        };
    }

    componentDidMount() {
        const { fetchAgreements, fetchUnitGroups, fetchSellers } = this.props;
        const { paging, filters } = this.state;

        fetchAgreements(paging, filters);
        fetchUnitGroups(paging);
        fetchSellers(paging);
    }

    componentWillUnmount() {
        const { resetAgreements } = this.props;
        resetAgreements();
    }

    applyFilters = () => {
        const { fetchAgreements } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchAgreements(newPagination, filters);

        if(this.agreementListRef !== null && this.agreementListRef !== undefined &&
            this.agreementListRef.current !== null && this.agreementListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.agreementListRef.current.resetDataGridPage();
        }

    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as AgreementFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();

        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchAgreements } = this.props;

        const newFilter = { ...filters } as AgreementFilters;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchAgreements(newPagination, newFilter);
    }

    setDefaultState = () => {
        const { fetchAgreements } = this.props;
        const { paging } = this.state;

        const newFilters = {
            name: '',
            active: true
        } as AgreementFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        if(this.agreementListRef !== null && this.agreementListRef !== undefined &&
            this.agreementListRef.current !== null && this.agreementListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.agreementListRef.current.resetDataGridPage();
        }

        fetchAgreements(newPagination, newFilters);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchAgreements } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchAgreements(newPaging, filters);
           
        }
    }

    render() {
        const { classes, agreements, fetchAgreements, isLoading } = this.props;
        const { paging, filters, menuItem, searchTerm, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.AGREEMENTS}/add`
                                    }]}
                                >
                                    <Filters applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <AgreementList rows={agreements} isLoading={isLoading} hiddenColumns={['priorityRank', 'discount', 'relation', 'validFromDate', 'validToDate']}
                                    ref={this.agreementListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchAgreements(newPage, filters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Agreements));