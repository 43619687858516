import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import DangerousGoodsGroupApi from '../api/dangerousGoodsGroupApi';
import DangerousGoodsGroup from '../interfaces/output/dangerousGoodsGroup';
import { fetchDangerousGoodsGroupsByIdError, fetchDangerousGoodsGroupsByIdSuccess, fetchDangerousGoodsGroupsError, fetchDangerousGoodsGroupsSuccess, updateDangerousGoodsGroupsError,
    updateDangerousGoodsGroupsSuccess } from '../reducers/dangerousGoodsGroupReducer';

function* doFetchDangerousGoodsGroup(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: DangerousGoodsGroup = yield call(DangerousGoodsGroupApi.fetchDangerousGoodsGroup, id);
        yield put(fetchDangerousGoodsGroupsByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDangerousGoodsGroupsByIdError(error as HttpErrorResponse));
    }
}

function* doFetchDangerousGoodsGroups(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<DangerousGoodsGroup> = yield call(DangerousGoodsGroupApi.fetchDangerousGoodsGroups, paging);
        yield put(fetchDangerousGoodsGroupsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDangerousGoodsGroupsError(error as HttpErrorResponse));
    }
}

function* doUpdateDangerousGoodsGroup(action: PayloadAction<DangerousGoodsGroup>) {
    const orderUnit = action.payload;

    try {
        const response: DangerousGoodsGroup = yield call(DangerousGoodsGroupApi.updateDangerousGoodsGroup, orderUnit);

        yield put(updateDangerousGoodsGroupsSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDangerousGoodsGroupsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('dangerousGoodsGroup/fetchDangerousGoodsGroupsById', doFetchDangerousGoodsGroup),
    takeLatest('dangerousGoodsGroup/fetchDangerousGoodsGroups', doFetchDangerousGoodsGroups),
    takeLatest('dangerousGoodsGroup/updateDangerousGoodsGroups', doUpdateDangerousGoodsGroup)
];

