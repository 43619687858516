import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Agreement from '../interfaces/output/agreement';
import AgreementFilters from '../interfaces/filters/agreementFilters';
import AgreementVersion from '../interfaces/output/agreementVersion';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type AgreementState = {
    agreement: Agreement;
    isLoadingAgreements: boolean;
    isLoadingAgreementVersions: boolean;
    isLoadingAgreement: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isCreatingVersion: boolean;
    isExporting: boolean;
    agreements: Pagination<Agreement>;
    agreementVersions: Pagination<AgreementVersion>;
    error: HttpErrorResponse | undefined;
};

const initialState: AgreementState = {
    agreement: {} as Agreement,
    agreements: { content: [] as Array<Agreement> } as Pagination<Agreement>,
    agreementVersions: { content: [] as Array<AgreementVersion> } as Pagination<AgreementVersion>,
    isLoadingAgreements: false,
    isLoadingAgreementVersions: false,
    isLoadingAgreement: false,
    isUpdating: false,
    isCreating: false,
    isCreatingVersion: false,
    isRemoving: false,
    isDeleting: false,
    isExporting: false,
    error: undefined
};

export const agreementSlice = createSlice({
    name: 'agreement',

    initialState,
    reducers: {
        fetchAgreements: (state: AgreementState, _action: PayloadAction<{paging: Paging, filters: AgreementFilters}>) => {
            state.isLoadingAgreements = true;
        },
        fetchAgreementsSuccess: (state: AgreementState, action: PayloadAction<Pagination<Agreement>>) => {
            state.isLoadingAgreements = false;
            state.agreements = action.payload;
        },
        fetchAgreementsError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingAgreements = false;
            state.error = action.payload;
        },
        fetchAgreementVersions: (state: AgreementState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingAgreementVersions = true;
        },
        fetchAgreementVersionsSuccess: (state: AgreementState, action: PayloadAction<Pagination<AgreementVersion>>) => {
            state.isLoadingAgreementVersions = false;
            state.agreementVersions = action.payload;
        },
        fetchAgreementVersionsError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingAgreementVersions = false;
            state.error = action.payload;
        },
        fetchAgreementById: (state: AgreementState, _action: PayloadAction<{id: number, agreementVersionId?: number }>) => {
            state.isLoadingAgreement = true;
        },
        fetchAgreementByIdSuccess: (state: AgreementState, action: PayloadAction<Agreement>) => {
            state.isLoadingAgreement = false;
            state.agreement = action.payload;
        },
        fetchAgreementByIdError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingAgreement = false;
            state.error = action.payload;
        },
        createAgreement: (state: AgreementState, _action: PayloadAction<Agreement>) => {
            state.isCreating = true;
        },
        createAgreementSuccess: (state: AgreementState, action: PayloadAction<Agreement>) => {
            state.isCreating = false;
            state.agreement = action.payload;
        },
        createAgreementError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createAgreementVersion: (state: AgreementState, _action: PayloadAction<Agreement>) => {
            state.isCreatingVersion = true;
        },
        createAgreementVersionSuccess: (state: AgreementState, action: PayloadAction<Agreement>) => {
            state.isCreatingVersion = false;
            state.agreement = action.payload;
        },
        createAgreementVersionError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateAgreement: (state: AgreementState, _action: PayloadAction<Agreement>) => {
            state.isUpdating = true;
        },
        updateAgreementSuccess: (state: AgreementState, action: PayloadAction<Agreement>) => {
            state.isUpdating = false;
            state.agreement = action.payload;
        },
        updateAgreementError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteAgreement: (state: AgreementState, _action: PayloadAction<Agreement>) => {
            state.isDeleting = true;
        },
        deleteAgreementSuccess: (state: AgreementState, action: PayloadAction<Agreement>) => {
            state.isDeleting = false;
            state.agreement = action.payload;
        },
        deleteAgreementError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetAgreements: (state: AgreementState) => {
            state.agreements = initialState.agreements;
        },
        resetAgreement: (state: AgreementState) => {
            state.agreement = initialState.agreement;
        },
        resetAgreementVersions: (state: AgreementState) => {
            state.agreementVersions = initialState.agreementVersions;
        },
        exportAgreement: (state: AgreementState, _action: PayloadAction<{id: number, forDate: Date}>) => {
            state.isExporting = true;
        },
        exportAgreementSuccess: (state: AgreementState) => {
            state.isExporting = false;
        },
        exportAgreementError: (state: AgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExporting = false;
            state.error = action.payload;
        }
    }
});

export const { fetchAgreements, fetchAgreementsSuccess, fetchAgreementsError, fetchAgreementVersions, fetchAgreementVersionsSuccess, fetchAgreementVersionsError,
    fetchAgreementById, fetchAgreementByIdSuccess, fetchAgreementByIdError, createAgreement, createAgreementSuccess, createAgreementError, createAgreementVersion,
    createAgreementVersionError, createAgreementVersionSuccess, updateAgreement, updateAgreementSuccess, updateAgreementError, deleteAgreement, deleteAgreementSuccess,
    deleteAgreementError, resetAgreements, resetAgreement, resetAgreementVersions, exportAgreement, exportAgreementSuccess, exportAgreementError } = agreementSlice.actions;

export const isLoadingAgreement = (state: RootState): boolean => state.agreementReducer.isLoadingAgreement;
export const isLoadingAgreements = (state: RootState): boolean => state.agreementReducer.isLoadingAgreements;
export const isLoadingAgreementVersions = (state: RootState): boolean => state.agreementReducer.isLoadingAgreementVersions;
export const isRemoving = (state: RootState): boolean => state.agreementReducer.isRemoving;
export const isUpdatingAgreement = (state: RootState): boolean => state.agreementReducer.isUpdating;
export const isDeletingAgreement = (state: RootState): boolean => state.agreementReducer.isDeleting;
export const isCreating = (state: RootState): boolean => state.agreementReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.agreementReducer.isCreatingVersion;
export const getAgreements = (state: RootState): Pagination<Agreement> => state.agreementReducer.agreements;
export const getAgreementVersions = (state: RootState): Pagination<AgreementVersion> => state.agreementReducer.agreementVersions;
export const getAgreement = (state: RootState): Agreement => state.agreementReducer.agreement;
export const isExportingAgreement = (state: RootState): boolean => state.agreementReducer.isExporting;

export default agreementSlice.reducer;