import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetIn from '../interfaces/input/factorSetIn';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import DepartureFilters from '../components/factorSets/departure/departureFilter';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetDangerousGoodsApi {
    static fetchFactorSetDangerousGoodsById = async(factorSetId: number, versionId?: number): Promise<FactorSetOut> => {
        const versionQueryString = versionId ? { versionId: versionId } : {};
        const url = urlcat('', `factorsets/dangerousgoods/${factorSetId}`, versionQueryString);
        
        const { data } = await http.get<FactorSetOut>(url);

        return data;
    };

    static fetchFactorSetDangerousGoods = async(paging: Paging, filters: DepartureFilters): Promise<Array<FactorSetOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsets/dangerousgoods');
        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static fetchFactorSetDangerousGoodsVersions = async(factorSetId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/dangerousgoods/${factorSetId}/versions`);
        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static createFactorSetDangerousGoods = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSetDangerousGoods: FactorSetIn = {
            name: factorSet?.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            noFactors: true,
            default: factorSet?.default ?? false
        };

        const { data } = await http.post<FactorSetIn, FactorSetOut>('factorsets/dangerousgoods', newFactorSetDangerousGoods);

        return data;
    }

    static createDangerousGoodsVersion = async(factorSet: FactorSetOut): Promise<FactorSetVersionOut> => {
        const url = `/factorsets/dangerousgoods/${factorSet.id}/versions`;

        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSet.version?.validFromDate,
            validToDate: factorSet.version?.validToDate,
            statusId: 1 //set status to active
        };
        
        const { data } = await http.post<FactorSetVersionIn, FactorSetVersionOut>(url, newVersion);

        return data;
    }

    static updateFactorSetDangerousGoods = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSetDangerousGoods: FactorSetIn = {
            name: factorSet.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            default: factorSet?.default ?? false
        };
        
        const { data } = await http.put<FactorSetIn, FactorSetOut>(`factorsets/dangerousgoods/${factorSet.id}`, newFactorSetDangerousGoods);

        return data;
    }

    static deactivateFactorSetDangerousGoods = async(id: number): Promise<FactorSetOut> => {
        const { data } = await http.delete<FactorSetOut>(`factorsets/dangerousgoods/${id}`);

        return data;
    }
}