import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import PricingComponentTypeOut from '../interfaces/output/pricingComponentTypeOut';
import PricingComponentTypeIn from '../interfaces/input/pricingComponentTypeIn';
import Paging from '../interfaces/common/paging';
import PricingComponentTypeFilters from '../interfaces/filters/pricingComponentTypeFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PricingComponentTypeApi {
    static fetchPricingComponentTypeById = async(pricingcomponentId: string): Promise<PricingComponentTypeOut> => {
        const { data } = await http.get<PricingComponentTypeOut>(`pricingcomponent/${pricingcomponentId}`);

        return data;
    };

    static fetchPricingComponentTypes = async(paging: Paging, filters?: PricingComponentTypeFilters): Promise<Pagination<PricingComponentTypeOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/pricingcomponent');

        const { data } = await http.get<Pagination<PricingComponentTypeOut>>(url);

        return data;
    };

    static createPricingComponentType = async(pricingcomponent: PricingComponentTypeOut): Promise<PricingComponentTypeOut> => {
        const newFactorSetCalendar: PricingComponentTypeIn = {
            name: pricingcomponent.name,
            code: pricingcomponent.code,
            nameNo: pricingcomponent.nameNo
        };

        const { data } = await http.post<PricingComponentTypeIn, PricingComponentTypeOut>('pricingcomponent', newFactorSetCalendar);

        return data;
    }

    static updatePricingComponentType = async(pricingcomponent: PricingComponentTypeOut): Promise<PricingComponentTypeOut> => {
        const newLine: PricingComponentTypeIn = {
            name: pricingcomponent.name,
            code: pricingcomponent.code,
            nameNo: pricingcomponent.nameNo
        };
        
        const { data } = await http.put<PricingComponentTypeIn, PricingComponentTypeOut>(`pricingcomponent/${pricingcomponent.id}`, newLine);

        return data;
    }

    static deactivatePricingComponentType = async(pricingcomponentId: number): Promise<PricingComponentTypeOut> => {
        const { data } = await http.delete<PricingComponentTypeOut>(`pricingcomponent/${pricingcomponentId}`);

        return data;
    }
}
