import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Status from '../interfaces/output/customerStatus';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type StatusState = {
    status: Status;
    isLoading: boolean;
    statuses: Pagination<Status>;
    error: HttpErrorResponse | undefined;
};

const initialState: StatusState = {
    status: {} as Status,
    statuses: {} as Pagination<Status>,
    isLoading: false,
    error: undefined
};

export const statusSlice = createSlice({
    name: 'status',

    initialState,
    reducers: {
        fetchStatuses: (state: StatusState) => {
            state.isLoading = true;
        },
        fetchStatusesSuccess: (state: StatusState, action: PayloadAction<Pagination<Status>>) => {
            state.isLoading = false;
            state.statuses = action.payload;
        },
        fetchStatusesError: (state: StatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchStatusById: (state: StatusState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchStatusByIdSuccess: (state: StatusState, action: PayloadAction<Status>) => {
            state.isLoading = false;
            state.status = action.payload;
        },
        fetchStatusByIdError: (state: StatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        resetStatus: (state: StatusState) => {
            state.statuses = initialState.statuses;
        }
    }
});

export const { fetchStatuses, fetchStatusesSuccess, fetchStatusesError, fetchStatusById, fetchStatusByIdSuccess, fetchStatusByIdError, resetStatus } = statusSlice.actions;

export const isLoadingStatuses = (state: RootState): boolean => state.statusReducer.isLoading;
export const getStatuses = (state: RootState): Pagination<Status> => state.statusReducer.statuses;
export const getStatus = (state: RootState): Status => state.statusReducer.status;

export default statusSlice.reducer;