import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import ProductArena from '../interfaces/output/productArena';
import ProductArenaApi from '../api/productArenaApi';
import { createProductArenaError, createProductArenaSuccess, fetchProductArenaByIdError,
    fetchProductArenaByIdSuccess, fetchProductArenasError, fetchProductArenasSuccess, updateProductArenaError,
    updateProductArenaSuccess } from '../reducers/productArenaReducer';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ProductArenaFilters from '../interfaces/filters/productArenaFilters';

function* doFetchProductArena(action: PayloadAction<string>) {
    const productArenaId = action.payload;
    try {
        const response: ProductArena = yield call(ProductArenaApi.fetchProductArena, productArenaId);
        yield put(fetchProductArenaByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductArenaByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductArenas(action: PayloadAction<{ paging: Paging, filters?: ProductArenaFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ProductArena> = yield call(ProductArenaApi.fetchProductArenas, paging, filters);
        yield put(fetchProductArenasSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductArenasError(error as HttpErrorResponse));
    }
}

function* doCreateProductArena(action: PayloadAction<ProductArena>) {
    const productArena = action.payload;

    try {
        const response: ProductArena = yield call(ProductArenaApi.createProductArena, productArena);

        yield put(createProductArenaSuccess(response));
    }

    catch (error: unknown) {
        yield put(createProductArenaError(error as HttpErrorResponse));
    }
}

function* doUpdateProductArena(action: PayloadAction<ProductArena>) {
    const productArena = action.payload;

    try {
        const response: ProductArena = yield call(ProductArenaApi.updateProductArena, productArena);

        yield put(updateProductArenaSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateProductArenaError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('productArena/fetchProductArenaById', doFetchProductArena),
    takeLatest('productArena/fetchProductArenas', doFetchProductArenas),
    takeLatest('productArena/createProductArena', doCreateProductArena),
    takeLatest('productArena/updateProductArena', doUpdateProductArena)];

