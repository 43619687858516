import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import PriorityFilters from '../components/factorSets/priority/priorityFilter';
import FactorSetOut from '../interfaces/output/factorSetOut';
import PriorityLineFilters from '../components/factorSets/priority/line/priorityLineFilters';
import FactorSetPriorityLineOutput from '../interfaces/output/factorSetPriorityLineOut';
import Pagination from '../interfaces/common/pagination';
import FactorSetIn from '../interfaces/input/factorSetIn';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetPriorityApi {
    static fetchFactorSetPriorityById = async(factorSetId: number, versionId?: number): Promise<FactorSetOut> => {
        const versionQueryString = versionId ? { versionId: versionId } : {};
        const url = urlcat('', `factorsets/priority/${factorSetId}`, versionQueryString);
        
        const { data } = await http.get<FactorSetOut>(url);

        return data;
    };

    static fetchFactorSetPriorities = async(paging: Paging, filters: PriorityFilters): Promise<Array<FactorSetOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsets/priority');
       
        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static fetchFactorSetPriorityLines = async(factorSetPriorityId: number, paging: Paging, filters: PriorityLineFilters): Promise<Pagination<FactorSetPriorityLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, `factorsets/priority/${factorSetPriorityId}/lines`);
       
        const { data } = await http.get<Pagination<FactorSetPriorityLineOutput>>(url);

        return data;
    };

    static createFactorSetPriority = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSetPriority = {
            name: factorSet?.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            noFactors: true,
            default: factorSet?.default ?? false
        };

        const { data } = await http.post<any, FactorSetOut>('factorsets/priority', newFactorSetPriority);

        return data;
    }
    
    static createPriorityVersion = async(factorSet: FactorSetOut): Promise<FactorSetVersionOut> => {
        const url = `/factorsets/priority/${factorSet.id}/versions`;

        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSet.version?.validFromDate,
            validToDate: factorSet.version?.validToDate,
            statusId: 1 //set status to active
        };
        
        const { data } = await http.post<FactorSetVersionIn, FactorSetVersionOut>(url, newVersion);

        return data;
    }

    static updateFactorSetPriority = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSetPriority: FactorSetIn = {
            name: factorSet.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            default: factorSet?.default ?? false
        };
        
        const { data } = await http.put<FactorSetIn, FactorSetOut>(`factorsets/priority/${factorSet.id}`, newFactorSetPriority);

        return data;
    }

    static deactivateFactorSetPriority = async(id: number): Promise<FactorSetOut> => {
        const { data } = await http.delete<FactorSetOut>(`factorsets/priority/${id}`);

        return data;
    }
}