import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import CancellationType from '../interfaces/output/cancellationType';

export default class CancellationTypeApi {
    static fetchCancellationTypes = async(): Promise<Pagination<CancellationType>> => {
        const { data } = await http.get<Pagination<CancellationType>>('/cancellationtypes');

        return data;
    };
}
