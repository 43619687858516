import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import IMenuItem from '../../interfaces/common/menuItem';
import LanguageUtils from '../../utils/LanguageUtils';
import UrlConstants from '../../constants/UrlConstants';
import PageUtils from '../../utils/pageUtils';
import MainLayout from '../common/widgets/mainLayout';
import { ObjectType } from '../../constants/constants';
import { RootState } from '../../setup';
import { deactivateAgreementVersionTransitDiscountById, fetchAgreementVersionTransitDiscountById, getAgreementVersionTransitDiscount, isDeletingAgreementTransitDiscount,
    isLoadingAgreementVersionTransitDiscount,
    isUpdatingAgreementTransitDiscount } from '../../reducers/agreementVersionTransitDiscountReducer';
import TransitDiscount from '../../interfaces/output/transitDiscount';
import { fetchAgreementVersionById, getAgreementVersion, isLoadingAgreementVersion } from '../../reducers/agreementVersionReducer';
import AgreementVersion from '../../interfaces/output/agreementVersion';
import { fetchAgreementById, getAgreement, isLoadingAgreement } from '../../reducers/agreementsReducer';
import Agreement from '../../interfaces/output/agreement';
import ViewAgreementVersionTransitDiscount from './viewAgreementVersionTransitDiscount';

interface IAgreementVersionTransitDiscountDetailsProps {
    classes: any;
    theme: Theme;
    fetchAgreementVersionTransitDiscountById: any;
    deactivateAgreementVersionTransitDiscountById: any;
    fetchAgreementById: any;
    fetchAgreementVersionById: any;
    agreementVersion: AgreementVersion;
    agreement: Agreement;
    agreementVersionTransitDiscount: TransitDiscount;
    match: any;
    history: any;
    location: any;
    isUpdatingAgreementTransitDiscount: boolean;
    isDeletingAgreementTransitDiscount: boolean;
    isLoadingAgreementVersionTransitDiscount: boolean;
    isLoadingAgreement: boolean;
    isLoadingAgreementVersion: boolean;
}

interface IAgreementVersionTransitDiscountDetailsState {
    agreementVersionTransitDiscount: TransitDiscount;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAgreementVersionTransitDiscountById: (id: number) => dispatch(fetchAgreementVersionTransitDiscountById(id)),
    deactivateAgreementVersionTransitDiscountById: (id: number) => dispatch(deactivateAgreementVersionTransitDiscountById(id)),
    fetchAgreementVersionById: (id: number) => dispatch(fetchAgreementVersionById(id)),
    fetchAgreementById: (id: number, agreementVersionId: number) => dispatch(fetchAgreementById({ id,
        agreementVersionId }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        agreementVersion: getAgreementVersion(store),
        agreement: getAgreement(store),
        agreementVersionTransitDiscount: getAgreementVersionTransitDiscount(store),
        isUpdatingAgreementTransitDiscount: isUpdatingAgreementTransitDiscount(store),
        isDeletingAgreementTransitDiscount: isDeletingAgreementTransitDiscount(store),
        isLoading: isLoadingAgreementVersionTransitDiscount(store) || isDeletingAgreementTransitDiscount(store) || isUpdatingAgreementTransitDiscount(store),
        isLoadingAgreementVersionTransitDiscount: isLoadingAgreementVersionTransitDiscount(store),
        isLoadingAgreement: isLoadingAgreement(store) || isLoadingAgreementVersion(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('TRANSIT_DISCOUNT'),
    properties: LanguageUtils.createMessage('Properties')
};

class AgreementVersionTransitDiscountDetails extends Component<IAgreementVersionTransitDiscountDetailsProps, IAgreementVersionTransitDiscountDetailsState> {
    constructor(props: IAgreementVersionTransitDiscountDetailsProps) {
        super(props);

        this.state = {
            agreementVersionTransitDiscount: {} as TransitDiscount,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${props.match.params.agreementVersionTransitDiscountId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${props.match.params.agreementVersionTransitDiscountId}/properties`, props.location.pathname)
            }]),
            selectedRow: undefined
        };
    }

    componentDidMount() {
        const { fetchAgreementVersionTransitDiscountById } = this.props;
        const { agreementVersionTransitDiscountId } = this.props.match.params;

        if(agreementVersionTransitDiscountId) {
            fetchAgreementVersionTransitDiscountById(agreementVersionTransitDiscountId);
        }
    }

    componentDidUpdate(prevProps: IAgreementVersionTransitDiscountDetailsProps) {
        const { agreementVersionTransitDiscount, fetchAgreementVersionById, fetchAgreementById, agreementVersion } = this.props;

        if(agreementVersionTransitDiscount !== prevProps.agreementVersionTransitDiscount) {
            if(agreementVersionTransitDiscount.agreementVersionId) {
                fetchAgreementVersionById(agreementVersionTransitDiscount.agreementVersionId);
            }
           
            this.setState({ agreementVersionTransitDiscount });
        }

        if(agreementVersion !== prevProps.agreementVersion) {
            if(agreementVersion.agreementId && agreementVersion.id) {
                fetchAgreementById(agreementVersion.agreementId, agreementVersion.id);
            }
        }
    }

    deactivateAgreementVersionTransitDiscount = () => {
        const { agreementVersionTransitDiscount } = this.state;
        const { deactivateAgreementVersionTransitDiscountById } = this.props;

        deactivateAgreementVersionTransitDiscountById(agreementVersionTransitDiscount.id);
    }

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { agreementVersionTransitDiscountId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/${agreementVersionTransitDiscountId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateAgreementVersionTransitDiscount
                    }
                ];
            }

            default: {
                return [];
            }
        }
    
    }

    render() {
        const { classes, isLoadingAgreementVersionTransitDiscount, agreement, isLoadingAgreement } = this.props;
        const { agreementVersionTransitDiscount, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingAgreementVersionTransitDiscount || isLoadingAgreement}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.AgreementVersionTransitDiscount}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    icon: <FontAwesomeIcon icon="hand-holding-usd" size="1x" /> } as IMenuItem}
                routes={
                    [{
                        name: `${agreement.name} version ${agreement.version?.id}`,
                        url: `${UrlConstants.AGREEMENTS}/${agreement.id}/${agreement.version?.id}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT}/:agreementVersionTransitDiscountId/properties`}>
                            <ViewAgreementVersionTransitDiscount agreementVersionTransitDiscount={agreementVersionTransitDiscount} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AgreementVersionTransitDiscountDetails));