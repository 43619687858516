import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Agreement from '../../interfaces/output/agreement';
import Pagination from '../../interfaces/common/pagination';
import DisplayStatus from '../common/displayStatus';
import { StatusCodes, StatusConstants } from '../../constants/statusConstants';
import Status from '../../interfaces/output/status';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomLink from '../common/customLink';
import CustomStringLink from '../common/customStringLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';

interface IAgreementListProps {
    classes?: any;
    theme?: Theme;
    agreements?: Pagination<Agreement>;
    checkSelection?: any;
    multipleSelection?: boolean;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    defaultSelectionModel?: Array<string | number | undefined>;
    disableLinks?: boolean;
    isCustomerAgreement?: boolean;
    onSortModelChange?: any;
    prefix?: string;
    secondPrefix?: string;
    isFromPriceList?: boolean;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
}

const messages = {
    name: LanguageUtils.createMessage('Agreement name'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    discount: LanguageUtils.createMessage('Discount'),
    seller: LanguageUtils.createMessage('Seller'),
    currency: LanguageUtils.createMessage('Currency'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    priorityRank: LanguageUtils.createMessage('Priority rank'),
    status: LanguageUtils.createMessage('Status'),
    validity: LanguageUtils.createMessage('Validity'),
    revenueThisYear: LanguageUtils.createMessage('Revenue this year'),
    revenueLastYear: LanguageUtils.createMessage('Revenue last year')
};

const AgreementList = forwardRef((props: IAgreementListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, agreements, multipleSelection, defaultSelectionModel, disableLinks,
        onSortModelChange, prefix, secondPrefix, isCustomerAgreement, isFromPriceList, columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.CUSTOMER_AGREEMENTS_MAIN);
        }
    }, []);

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const currentVersion = (params: any) => {
        return applyRowFn(params)?.currentVersion?.id || 'latest';
    };

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        let x = [
            {
                field: isFromPriceList ? 'agreementVersion_agreement_name' : prefix ? `${prefix}name` : 'name',
                headerName: intl.formatMessage(messages.name),
                sortable: true,
                flex: 2,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(cellValues)?.id}/${currentVersion(cellValues)}`} value={applyRowFn(cellValues)?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: isCustomerAgreement ? 'validFromDate' : secondPrefix ? `${secondPrefix}validFromDate` : 'versions_validFromDate',
                headerName: intl.formatMessage(messages.validFromDate),
                flex: 0.8,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}>
                        <NullableFormattedDate value={isCustomerAgreement ? params?.row?.validFromDate : applyRowFn(params)?.currentVersion?.validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: isCustomerAgreement ? 'validToDate' : secondPrefix ? `${secondPrefix}validToDate` : 'versions_validToDate',
                headerName: intl.formatMessage(messages.validToDate),
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}>
                        <NullableFormattedDate value={isCustomerAgreement ? params?.row?.validToDate : applyRowFn(params)?.currentVersion?.validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}seller_name` : 'seller_name',
                headerName: intl.formatMessage(messages.seller),
                flex: 2,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`} value={applyRowFn(params)?.seller?.name ?? '-'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('seller', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}unitGroup_name` : 'unitGroup_name',
                headerName: intl.formatMessage(messages.unitGroup),
                flex: 2,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`} value={applyRowFn(params)?.unitGroup?.name ?? '-'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitGroup', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}currency_name` : 'currency_name',
                headerName: intl.formatMessage(messages.currency),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.currency?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('currency', hiddenColumns)
            },
            {
                field: isFromPriceList ? 'discount' : prefix ? `${prefix}versions_defaultTransitDiscount` : 'versions_defaultTransitDiscount',
                headerName: intl.formatMessage(messages.discount),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                        value={isFromPriceList ? params.row?.discount ?? '-'
                            : applyRowFn(params)?.currentVersion?.defaultTransitDiscount ? `${applyRowFn(params)?.currentVersion?.defaultTransitDiscount}%` : '-'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('discount', hiddenColumns)
            },
            {
                field: isFromPriceList ? 'agreementVersion_agreement_revenueThisYear' : prefix ? `${prefix}revenueThisYear` : 'revenueThisYear',
                headerName: intl.formatMessage(messages.revenueThisYear),
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}>
                        {applyRowFn(params)?.revenueThisYear &&
                            intl.formatNumber(ensure(Math.round(applyRowFn(params)?.revenueThisYear)), {
                                maximumFractionDigits: 0
                            }) || '-'}
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('revenueThisYear', hiddenColumns)
            },
            {
                field: isFromPriceList ? 'agreementVersion_agreement_revenueLastYear' : prefix ? `${prefix}revenueLastYear` : 'revenueLastYear',
                headerName: intl.formatMessage(messages.revenueLastYear),
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}>
                    {applyRowFn(params)?.revenueLastYear &&
                        intl.formatNumber(ensure(Math.round(applyRowFn(params)?.revenueLastYear)), {
                            maximumFractionDigits: 0
                        }) || '-'
                    }
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('revenueLastYear', hiddenColumns)
            },
            {
                field: secondPrefix ? `${secondPrefix}status` : prefix ? `${prefix}active` : 'active',
                headerName: intl.formatMessage(messages.status),
                flex: 0.8,
                align: 'center',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}>
                        <DisplayStatus
                            status={{ code: applyRowFn(params)?.active ? StatusConstants.Active : StatusConstants.Inactive } as Status} inheritProps />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}validity` : 'validity',
                headerName: intl.formatMessage(messages.validity),
                flex: 0.8,
                align: 'center',
                headerAlign: 'left',
                sortable: false,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}>
                        <DisplayStatus
                            status={{
                                code: params?.row.validFromDate && params?.row.validFromDate > new Date() ? StatusCodes.Future : params?.row.validToDate && params?.row.validToDate < new Date() ?
                                    StatusCodes.Expired : StatusCodes.Valid
                            } as Status} inheritProps />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('relation', hiddenColumns)
            }
        ] as GridColDef[];
        if(isCustomerAgreement || isFromPriceList) {
            x = [
                ...x,
                {
                    field: 'priorityRank',
                    headerName: intl.formatMessage(messages.priorityRank),
                    flex: 1,
                    align: 'left',
                    headerAlign: 'left',
                    sortable: true,
                    renderCell: (params: GridRenderCellParams) => {
                        return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                            link={`/${UrlConstants.AGREEMENTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`} value={params.row?.priorityRank ?? '-'} />;
                    },
                    hide: hiddenColumns && TableUtils.hideColumn('priorityRank', hiddenColumns)
                }];
        }

        return x;
    }

    return (
        <DataGridComponent rows={rows || agreements} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection} onPageChange={onPageChange}
            defaultSelectionModel={defaultSelectionModel as GridSelectionModel}
            multipleSelection={multipleSelection} ref={dataGridRef} onSortModelChange={onSortModelChange}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange} columnVisibilityModel={columnVisibilityModel} />
    );
});

export default AgreementList;