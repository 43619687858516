import { List } from 'immutable';
import AgreementVersionSpecialPrice from '../../../interfaces/output/agreementVersionSpecialPriceOut';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range'),
    invalidDateFormat: LanguageUtils.createMessage('Invalid date format')
};

export default class AgreementVersionSpecialPriceStep1Validation extends ValidationUtils {

    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validateInputDateStart(agreementVersionSpecialPrice: AgreementVersionSpecialPrice): boolean {
        const dateStart = agreementVersionSpecialPrice.validFromDate;
        const dateTo = agreementVersionSpecialPrice.validToDate;

        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(agreementVersionSpecialPrice: AgreementVersionSpecialPrice): boolean {
        const dateStart = agreementVersionSpecialPrice.validFromDate;
        const dateTo = agreementVersionSpecialPrice.validToDate;

        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateAgreementVersionSpecialPriceForm(agreementVersionSpecialPrice: AgreementVersionSpecialPrice): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const { validFromDate,
            validToDate,
            grossWeightKgFrom,
            grossWeightKgTo,
            price,
            terminalFrom,
            terminalTo,
            unitType
        } = agreementVersionSpecialPrice;

        const requiredFields =
            this.validateRequiredDate(validFromDate) &&
            this.validateRequiredDate(validToDate) &&
            this.validateInputNumber(grossWeightKgFrom) &&
            this.validateInputNumber(grossWeightKgTo) &&
            this.validateInputNumber(price) &&
            this.validateInputNumber(terminalFrom?.id) &&
            this.validateInputNumber(terminalTo?.id) &&
            this.validateInputNumber(unitType?.unitGroupId) &&
            this.validateInputNumber(unitType?.unitSubGroupId) &&
            this.validateInputNumber(unitType?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = agreementVersionSpecialPrice.validToDate;
        const validDates = endDate ? this.validateInputDateStart(agreementVersionSpecialPrice) &&
            this.validateInputDateEnd(agreementVersionSpecialPrice) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        const formatedDate = this.validateDateFormat(validFromDate) &&
        this.validateDateFormat(validToDate);

        if(!formatedDate) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDateFormat);
        }

        return validationModel;
    }
}
