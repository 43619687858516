import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
// import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Paging from '../../../interfaces/common/paging';
import { fetchServices, getServices, isLoadingServices } from '../../../reducers/serviceReducer';
import ServicesList from '../../services/servicesList';
import ServiceFilters from '../../../interfaces/output/serviceFilters';
import OrderUnitService from '../../../interfaces/output/orderUnitService';
import WorkflowUtils from '../../../utils/workflowUtils';
import Order from '../../../interfaces/output/order';
import UnitServicesFilterComponent from './orderUnitServiceFiltersComponent';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddOrderUnitServiceStep1Props {
    orderUnitService: OrderUnitService;
    order: Order;
    onChange: any;
}

const AddOrderUnitServiceStep1 = forwardRef((props: AddOrderUnitServiceStep1Props, ref: any): JSX.Element => {
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const { orderUnitService, onChange, order } = props;
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [filters, setFilters] = React.useState<ServiceFilters>({});
    const services = useSelector(getServices);
    const isLoadingServiceList = useSelector(isLoadingServices);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    WorkflowUtils.setHandle(ref, null);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        serviceNumber: false,
        unitGroup: false,
        status: false,
        importExportCode: false
    });

    useEffect(() => {
        dispatch(fetchServices({
            paging,
            filters: {
                serviceStatusId: 4,
                unitGroupId: order.unitGroup?.id
            } as ServiceFilters
        }));
    }, [dispatch]);

    const applyFilters = () => {
        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };
        dispatch(fetchServices({
            paging: newServerPagination,
            filters: { ...filters }
        }));

        setPaging(newServerPagination);
    };

    const setDefaultState = () => {
        const newFilters = {
            serviceStatusId: 4,
            unitGroupId: order.unitGroup?.id
        } as ServiceFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        }as GridSortItem] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };
        setPaging(newServerPagination);
        setFilters(newFilters);

        dispatch(fetchServices({
            paging: newServerPagination,
            filters: newFilters
        }));
    };

    const onChangeFilters = (attribute: string, value: any) => {

        const newFilter = { ...filters } as ServiceFilters;
        (newFilter as any)[attribute] = value;

        setFilters(newFilter);
    };

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };
            
            dispatch(fetchServices({
                paging: newPaging,
                filters: { ...filters }
            }));
    
            setPaging(newPaging);
            setSortModel(newModel);
           
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                {/* <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchServices({
                        paging: paging,
                        filters: { serviceDescription: searchTerm } as ServiceFilters
                    }));
                }}
                > */}
                <UnitServicesFilterComponent applyFilters={applyFilters} setDefaultState={setDefaultState} filters={filters} onChange={onChangeFilters} />
                {/* </QuickSearchToolbar> */}
            </Grid>
            <Grid item xs={12}>
                <ServicesList
                    isLoading={isLoadingServiceList}
                    rows={services}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (event: any) => setSearchTerm(event.target.value),
                            clearSearch: () => setSearchTerm('')
                        }
                    }}
                    disableLinks
                    hiddenColumns={['serviceNumber', 'unitGroup', 'status', 'importExportCode']}
                    checkSelection={(value: number) => onChange('service', {
                        ...orderUnitService.service,
                        id: value
                    })}
                    defaultSelectionModel={orderUnitService?.service?.id ? [orderUnitService?.service?.id] : undefined}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchServices({
                            paging: newPage,
                            filters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddOrderUnitServiceStep1;