import { http } from '../utils/http';
import CustomerGroup from '../interfaces/output/customerGroup';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class CustomerGroupApi {
    static fetchCustomerGroup = async(groupId: string): Promise<CustomerGroup> => {
        const { data } = await http.get<CustomerGroup>(`customergroup/${groupId}`);

        return data;
    };

    static fetchCustomerGroups = async(paging: Paging): Promise<Pagination<CustomerGroup>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/customergroup');

        const { data } = await http.get<Pagination<CustomerGroup>>(url);

        return data;
    };
}
