import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetType from '../interfaces/output/factorSetType';
import DepartureFilters from '../components/factorSets/departure/departureFilter';

export type FactorSetDangerousGoodsState = {
    factorSetDangerousGood: FactorSetOut;
    factorSetDangerousGoods: Pagination<FactorSetOut>;
    isLoadingFactorSetDangerousGood: boolean;
    isLoadingFactorSetDangerousGoods: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetDangerousGoodsState = {
    factorSetDangerousGood: {} as FactorSetOut,
    factorSetDangerousGoods: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    isLoadingFactorSetDangerousGood: false,
    isLoadingFactorSetDangerousGoods: false,
    isUpdating: false,
    isRemoving: false,
    isDeleted: false,
    isCreating: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetDangerousGoodsSlice = createSlice({
    name: 'factorSetDangerousGoods',

    initialState,
    reducers: {
        fetchFactorSetDangerousGoods: (state: FactorSetDangerousGoodsState, action: PayloadAction<{paging: Paging, filters?: DepartureFilters}>) => {
            state.isLoadingFactorSetDangerousGoods = true;
        },
        fetchFactorSetDangerousGoodsSuccess: (state: FactorSetDangerousGoodsState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingFactorSetDangerousGoods = false;
            state.factorSetDangerousGoods = action.payload;
        },
        fetchFactorSetDangerousGoodsError: (state: FactorSetDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetDangerousGoods = false;
            state.error = action.payload;
        },
        fetchFactorSetDangerousGoodsById: (state: FactorSetDangerousGoodsState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetDangerousGood = true;
        },
        fetchFactorSetDangerousGoodsByIdSuccess: (state: FactorSetDangerousGoodsState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetDangerousGood = false;
            state.factorSetDangerousGood = action.payload;
        },
        fetchFactorSetDangerousGoodsByIdError: (state: FactorSetDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetDangerousGood = false;
            state.error = action.payload;
        },
        createFactorSetDangerousGoods: (state: FactorSetDangerousGoodsState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetDangerousGoodsSuccess: (state: FactorSetDangerousGoodsState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetDangerousGood = action.payload;
        },
        createFactorSetDangerousGoodsError: (state: FactorSetDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createDangerousGoodsVersion: (state: FactorSetDangerousGoodsState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createDangerousGoodsVersionSuccess: (state: FactorSetDangerousGoodsState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetDangerousGood = action.payload;
        },
        createDangerousGoodsVersionError: (state: FactorSetDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateFactorSetDangerousGoods: (state: FactorSetDangerousGoodsState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetDangerousGoodsSuccess: (state: FactorSetDangerousGoodsState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetDangerousGood = action.payload;
        },
        updateFactorSetDangerousGoodsError: (state: FactorSetDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetDangerousGoods: (state: FactorSetDangerousGoodsState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateFactorSetDangerousGoodsSuccess: (state: FactorSetDangerousGoodsState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleted = false;
            state.factorSetDangerousGood = action.payload;
        },
        deactivateFactorSetDangerousGoodsError: (state: FactorSetDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetFactorSetDangerousGoods: (state: FactorSetDangerousGoodsState) => {
            state.factorSetDangerousGoods = initialState.factorSetDangerousGoods;
        },
        resetFactorSetDangerousGood: (state: FactorSetDangerousGoodsState) => {
            state.factorSetDangerousGood = initialState.factorSetDangerousGood;
        }
    }
});

export const {
    fetchFactorSetDangerousGoodsById, fetchFactorSetDangerousGoodsByIdSuccess, fetchFactorSetDangerousGoodsByIdError, fetchFactorSetDangerousGoods,
    fetchFactorSetDangerousGoodsError, fetchFactorSetDangerousGoodsSuccess,
    createFactorSetDangerousGoods, createFactorSetDangerousGoodsError, createFactorSetDangerousGoodsSuccess, updateFactorSetDangerousGoods,
    updateFactorSetDangerousGoodsError, updateFactorSetDangerousGoodsSuccess,
    deactivateFactorSetDangerousGoods, deactivateFactorSetDangerousGoodsError, deactivateFactorSetDangerousGoodsSuccess, resetFactorSetDangerousGoods,
    createDangerousGoodsVersion, createDangerousGoodsVersionSuccess, createDangerousGoodsVersionError, resetFactorSetDangerousGood }
    = factorSetDangerousGoodsSlice.actions;

export const isLoadingFactorSetDangerousGood = (state: RootState): boolean => state.factorSetDangerousGoodsReducer.isLoadingFactorSetDangerousGood;
export const isLoadingFactorSetDangerousGoods = (state: RootState): boolean => state.factorSetDangerousGoodsReducer.isLoadingFactorSetDangerousGoods;
export const isUpdatingDangerousGoods = (state: RootState): boolean => state.factorSetDangerousGoodsReducer.isUpdating;
export const isDeletingDangerousGoods = (state: RootState): boolean => state.factorSetDangerousGoodsReducer.isDeleted;
export const isCreatingDangerousGoods = (state: RootState): boolean => state.factorSetDangerousGoodsReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetDangerousGoodsReducer.isCreatingVersion;
export const getFactorSetDangerousGoods = (state: RootState): Pagination<FactorSetOut> => state.factorSetDangerousGoodsReducer.factorSetDangerousGoods;
export const getFactorSetDangerousGood = (state: RootState): FactorSetOut => state.factorSetDangerousGoodsReducer.factorSetDangerousGood;

export default factorSetDangerousGoodsSlice.reducer;