import { http } from '../utils/http';
import CustomerAgreement from '../interfaces/output/customerAgreement';
import CustomerAgreementFilters from '../interfaces/filters/customerAgreementFilters';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class CustomerAgreementApi {
    static fetchCustomerAgreementById = async(customerAgreementId: string): Promise<CustomerAgreement> => {
        const { data } = await http.get<CustomerAgreement>(`/customeragreement/${customerAgreementId}`);

        return data;
    };

    static fetchCustomerAgreements = async( paging: Paging, filters: CustomerAgreementFilters): Promise<Array<CustomerAgreement>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/customeragreement');
        const { data } = await http.get<Array<CustomerAgreement>>(url);
    
        return data;
    };

    static promoteCustomerAgreement = async(customerAgreementId: string): Promise<CustomerAgreement> => {
        const { data } = await http.put<any, CustomerAgreement>(`/customeragreement/${customerAgreementId}/promote`);

        return data;
    };

    static demoteCustomerAgreement = async(customerAgreementId: string): Promise<CustomerAgreement> => {
        const { data } = await http.put<any, CustomerAgreement>(`/customeragreement/${customerAgreementId}/demote`);

        return data;
    };

    static deactivateCustomerAgreement = async(customerAgreementId: string): Promise<CustomerAgreement> => {
        const { data } = await http.delete<any, CustomerAgreement>(`/customeragreement/${customerAgreementId}`);

        return data;
    };

    static createCustomerAgreement = async(customerAgreement: CustomerAgreement): Promise<CustomerAgreement> => {
        const newCustomerAgreement = {
            validFromDate: customerAgreement?.validFromDate,
            validToDate: customerAgreement?.validToDate,
            priorityRank: customerAgreement?.priorityRank,
            customerId: customerAgreement?.customer?.id,
            agreementId: customerAgreement?.agreement?.id
        };

        const { data } = await http.post<any, CustomerAgreement>('customeragreement', newCustomerAgreement);

        return data;
    }

    static updateCustomerAgreement = async(customerAgreement: CustomerAgreement): Promise<CustomerAgreement> => {
        const newCustomerAgreement = {
            validFromDate: customerAgreement?.validFromDate,
            validToDate: customerAgreement?.validToDate,
            priorityRank: customerAgreement?.priorityRank
        };

        const { data } = await http.put<any, CustomerAgreement>(`customeragreement/${customerAgreement.id}`, newCustomerAgreement);

        return data;
    }
}
