import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormControl, Grid } from '@mui/material';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import Agreement from '../../../interfaces/output/agreement';
import { useSelector } from 'react-redux';
import { isCreating, isCreatingVersion, isLoadingAgreement, isUpdatingAgreement } from '../../../reducers/agreementsReducer';
import { isUpdatingVersion } from '../../../reducers/agreementVersionReducer';
import GenericTextField from '../../common/widgets/genericTextField';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import AgreemenStep2Validation from '../validations/agreementStep2Validation';

interface AddAgreementStep1Props {
    agreement: Agreement;
    onChangeVersion: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    VALID_FROM: 'validFromDate',
    VALID_TO: 'validToDate'
};

const messages = {
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to'),
    discount: LanguageUtils.createMessage('Transit discount')
};

const AddAgreementStep2 = forwardRef((props: AddAgreementStep1Props, ref: any): JSX.Element => {
    const { agreement, onChangeVersion, showValidationError } = props;
    const isLoadingObjects = [useSelector(isLoadingAgreement), useSelector(isCreatingVersion), useSelector(isUpdatingVersion),
        useSelector(isCreating), useSelector(isUpdatingAgreement)] as Array<boolean>;
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.VALID_FROM: {
                isValid = AgreemenStep2Validation.validateRequiredDate(agreement?.version?.validFromDate) &&
                    AgreemenStep2Validation.validateDateFormat(agreement?.version?.validFromDate) &&
                    AgreemenStep2Validation.validateInputDateStart(agreement?.version);
                break;
            }
            case ATTRIBUTES.VALID_TO: {
                isValid = AgreemenStep2Validation.validateRequiredDate(agreement?.version?.validToDate) &&
                    AgreemenStep2Validation.validateDateFormat(agreement?.version?.validFromDate) &&
                    AgreemenStep2Validation.validateInputDateEnd(agreement?.version);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFrom}
                            value={agreement?.version?.validFromDate}
                            onChange={onChangeVersion}
                            type="start"
                            error={setValidationState(ATTRIBUTES.VALID_FROM)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validTo}
                            value={agreement?.version?.validToDate}
                            onChange={onChangeVersion}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALID_TO)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="defaultTransitDiscount"
                        type="number"
                        label={messages.discount}
                        value={agreement?.version?.defaultTransitDiscount}
                        onChange={onChangeVersion}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddAgreementStep2;