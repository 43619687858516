import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import TableUtils from '../../../utils/tableUtils';
import HelptextConstantsEnum from '../../../constants/helptextConstantsEnum';
import DataGridComponent from '../../common/dataGrid';
import TruncatedStringTooltip from '../../common/truncatedStringTooltip';

interface OrdersNotYetInvoicedListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    priceListName: LanguageUtils.createMessage('Price List name'),
    fromTerminalName: LanguageUtils.createMessage('Terminal from'),
    toTerminalName: LanguageUtils.createMessage('Terminal to'),
    unitTypeName: LanguageUtils.createMessage('Unit Type'),
    fromWeightKg: LanguageUtils.createMessage('From weight kg'),
    toWeightKg: LanguageUtils.createMessage('To weight kg'),
    departureTypeName: LanguageUtils.createMessage('Departure Type'),
    priorityTypeName: LanguageUtils.createMessage('Priority Type'),
    calculatedPrice: LanguageUtils.createMessage('Calculated price'),
    currencyCode: LanguageUtils.createMessage('Currency')
};

const CalculatedPriceList = forwardRef((props: OrdersNotYetInvoicedListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_CALCULATED_PRICE);
        }
    }, []);
  
    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'priceListName',
                headerName: `${intl.formatMessage(messages.priceListName)}`,
                flex: 0.7,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <TruncatedStringTooltip value={applyRowFn(cellValues)?.priceListName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('priceListName', hiddenColumns)
            },
            {
                field: 'fromTerminalName',
                headerName: intl.formatMessage(messages.fromTerminalName),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.fromTerminalName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromTerminalName', hiddenColumns)
            },
            {
                field: 'toTerminalName',
                headerName: intl.formatMessage(messages.toTerminalName),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.toTerminalName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toTerminalName', hiddenColumns)
            },
            {
                field: 'unitTypeName',
                headerName: intl.formatMessage(messages.unitTypeName),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.unitTypeName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitTypeName', hiddenColumns)
            },
            {
                field: 'fromWeightKg',
                headerName: intl.formatMessage(messages.fromWeightKg),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.fromWeightKg} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromWeightKg', hiddenColumns)
            },
            {
                field: 'toWeightKg',
                headerName: intl.formatMessage(messages.toWeightKg),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.toWeightKg} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toWeightKg', hiddenColumns)
            },
            {
                field: 'departureTypeName',
                headerName: intl.formatMessage(messages.departureTypeName),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.departureTypeName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('departureTypeName', hiddenColumns)
            },
            {
                field: 'priorityTypeName',
                headerName: intl.formatMessage(messages.priorityTypeName),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.priorityTypeName} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('priorityTypeName', hiddenColumns)
            },
            {
                field: 'currencyCode',
                headerName: intl.formatMessage(messages.currencyCode),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.currencyCode} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('currencyCode', hiddenColumns)
            },
            {
                field: 'calculatedPrice',
                headerName: intl.formatMessage(messages.calculatedPrice),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <TruncatedStringTooltip value={applyRowFn(params)?.calculatedPrice} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('calculatedPrice', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.priceListName + row?.calculatedPrice + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default CalculatedPriceList;
