import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import AddFactorSetPriorityLineForm from './addFactorSetPriorityLineForm';
import { FormType } from '../../../../constants/constants';
import { resetFactorSetPriorityLine, resetPriorityVersionLines } from '../../../../reducers/priorityLineReducer';

interface IAddFactorSetLineProps {
    classes: any;
    theme: Theme;
    match: any;
    priorityLineId?: number;
    factorSetVersionId?: number;
    factorSetPriorityId?: number;
    resetFactorSetPriorityLine: any;
    resetPriorityVersionLines: any;
    type: FormType;
    steps: Array<number>;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetFactorSetPriorityLine: () => dispatch(resetFactorSetPriorityLine()),
    resetPriorityVersionLines: () => dispatch(resetPriorityVersionLines())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetLineProps) => {
    return {
        priorityLineId: ownProps.match.params?.priorityLineId ? Number(ownProps.match.params?.priorityLineId) : undefined,
        factorSetPriorityId: ownProps.match.params?.priorityId ? Number(ownProps.match.params?.priorityId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetPriorityLine extends Component<IAddFactorSetLineProps> {

    componentWillUnmount() {
        const { resetFactorSetPriorityLine, resetPriorityVersionLines } = this.props;

        resetFactorSetPriorityLine();
        resetPriorityVersionLines();
    }

    render() {
        const { classes, steps, priorityLineId, factorSetPriorityId, type, factorSetVersionId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddFactorSetPriorityLineForm priorityLineId={priorityLineId} factorSetPriorityId={factorSetPriorityId} steps={steps} type={type} factorSetVersionId={factorSetVersionId}
                        />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetPriorityLine));