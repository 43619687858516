import PaymentDeadlineApi from '../api/paymentDeadlineApi';
import { fetchPaymentDeadlineByIdError, fetchPaymentDeadlineByIdSuccess, fetchPaymentDeadlinesError, fetchPaymentDeadlinesSuccess } from '../reducers/paymentDeadlineReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import PaymentDeadline from '../interfaces/output/paymentDeadline';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchPaymentDeadlineById(action: PayloadAction<string>) {
    const deadlineId = action.payload;
    try {
        const response: PaymentDeadline = yield call(PaymentDeadlineApi.fetchPaymentDeadlineById, deadlineId);
        yield put(fetchPaymentDeadlineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPaymentDeadlineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPaymentDeadlines() {
    try {
        const response: Pagination<PaymentDeadline> = yield call(PaymentDeadlineApi.fetchPaymentDeadlines);
        yield put(fetchPaymentDeadlinesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPaymentDeadlinesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('deadline/fetchPaymentDeadlineById', doFetchPaymentDeadlineById),
    takeLatest('deadline/fetchPaymentDeadlines', doFetchPaymentDeadlines)];

