import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import { http } from '../utils/http';

export default class GTSApi {
    static fetchGtsOperationRaw = async(gtsId: string): Promise<CustomerOrder> => {
        const { data } = await http.get<CustomerOrder>(`gtsoperations//customerorders/${gtsId}/raw`);

        return data;
    };

    static fetchGtsOperationPreview = async(gtsId: string): Promise<GtsOrder> => {
        const { data } = await http.get<GtsOrder>(`gtsoperations//customerorders/${gtsId}/preview`);

        return data;
    };

    static fetchGtsOperationImport = async(gtsId: string): Promise<GtsOrder> => {
        const { data } = await http.get<GtsOrder>(`gtsoperations//customerorders/${gtsId}/import`);

        return data;
    };
}
