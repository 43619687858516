import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import GenericTextField from '../common/widgets/genericTextField';
import WorkflowUtils from '../../utils/workflowUtils';
import GTSOrderUnitDangerousGoods from '../../interfaces/output/gtsOrderUnitDangerousGoods';
import { isLoadingDangerousGood, isUpdatingGtsOrderUnitDangerousGood } from '../../reducers/gtsDangerousGoodsReducer';
interface GtsOrderUnitServiceStep1Props {
    gtsDangerousGoods: GTSOrderUnitDangerousGoods;
    onChange: any;
}

const messages = {
    uunr: LanguageUtils.createMessage('Uunr'),
    grossAmount: LanguageUtils.createMessage('Gross amount'),
    netAmount: LanguageUtils.createMessage('Net amount'),
    measuringUnit: LanguageUtils.createMessage('Measuring unit'),
    dangerousGoodsCode: LanguageUtils.createMessage('Dangerous goods code'),
    packagingGroup: LanguageUtils.createMessage('Packaging group')
};

const GtsOrderUnitDangerosGoodsFormStep1 = forwardRef((props: GtsOrderUnitServiceStep1Props, ref: any): JSX.Element => {
    const isLoadingObjects = [useSelector(isUpdatingGtsOrderUnitDangerousGood), useSelector(isLoadingDangerousGood)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const { gtsDangerousGoods, onChange } = props;
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="uunr"
                        label={messages.uunr}
                        value={gtsDangerousGoods.uunr}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="grossAmount"
                        label={messages.grossAmount}
                        value={gtsDangerousGoods.grossAmount}
                        type="number"
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="netAmmount"
                        label={messages.netAmount}
                        value={gtsDangerousGoods.netAmmount}
                        type="number"
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="measuringUnit"
                        label={messages.measuringUnit}
                        value={gtsDangerousGoods.measuringUnit}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="dangerousGoodsCode"
                        label={messages.dangerousGoodsCode}
                        value={gtsDangerousGoods.dangerousGoodsCode}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="packagingGroup"
                        label={messages.packagingGroup}
                        value={gtsDangerousGoods.packagingGroup}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default GtsOrderUnitDangerosGoodsFormStep1;