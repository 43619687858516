import { List } from 'immutable';

/**@description Removes duplicates from an list based on a property
 * @param {list} list The list that we should remove items
 * @param {object} propertyToCompare The property on the items in the list that we should compare with
 */

function removeDuplicates<T>(list: List<T>, propertyToCompare: string): List<T> {
    let newList = List<T>();
    list.map((item: any) => (!newList.find((i: any) => i[propertyToCompare] && item[propertyToCompare] && i[propertyToCompare] === item[propertyToCompare]) ? (newList = newList.push(item)) : null));
        
    return newList;
}

export { removeDuplicates };
