import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import GtsOrder from '../interfaces/output/gtsOrder';
import GtsOrderApi from '../api/gtsOrderApi';
import {
    createGtsOrderError, createGtsOrderSuccess, fetchGtsOrderByIdError, fetchGtsOrderByIdSuccess, fetchGtsOrdersError, fetchGtsOrdersSuccess, processGtsOrderError,
    processGtsOrderStagingError, processGtsOrderStagingSuccess, processGtsOrderSuccess,
    updateGtsOrderError, updateGtsOrderSuccess
} from '../reducers/gtsOrderReducer';
import GtsOrderFilters from '../interfaces/filters/gtsOrderFilters';
import ImportGtsOrderFilters from '../interfaces/filters/importGtsOrderFilters';

function* doFetchGtsOrder(action: PayloadAction<string>) {
    const gtsOrderId = action.payload;

    try {
        const response: GtsOrder = yield call(GtsOrderApi.fetchGtsOrderById, gtsOrderId);
        yield put(fetchGtsOrderByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderByIdError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOrders(action: PayloadAction<{ paging: Paging, filters: GtsOrderFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<GtsOrder> = yield call(GtsOrderApi.fetchGtsOrders, paging, filters);
        yield put(fetchGtsOrdersSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrdersError(error as HttpErrorResponse));
    }
}

function* doUpdateGtsOrder(action: PayloadAction<GtsOrder>) {
    const gtsOrder = action.payload;

    try {
        const response: GtsOrder = yield call(GtsOrderApi.updateGtsOrder, gtsOrder);

        yield put(updateGtsOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateGtsOrderError(error as HttpErrorResponse));
    }
}

function* doProcessGtsOrder(action: PayloadAction<{ orderNumber: string, filters: ImportGtsOrderFilters}>) {
    const { orderNumber, filters } = action.payload;

    try {
        const response: GtsOrder = yield call(GtsOrderApi.processGtsOrder, orderNumber, filters);

        yield put(processGtsOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(processGtsOrderError(error as HttpErrorResponse));
    }
}

function* doProcessGtsOrderStaging(action: PayloadAction<string>) {
    const orderNumber = action.payload;

    try {
        const response: GtsOrder = yield call(GtsOrderApi.processGtsOrderStaging, orderNumber);

        yield put(processGtsOrderStagingSuccess(response));
    }

    catch (error: unknown) {
        yield put(processGtsOrderStagingError(error as HttpErrorResponse));
    }
}

function* doCreateGtsOrder(action: PayloadAction<string>) {
    const orderNumber = action.payload;

    try {
        const response: GtsOrder = yield call(GtsOrderApi.createGtsOrder, orderNumber);

        yield put(createGtsOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(createGtsOrderError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('gtsOrder/fetchGtsOrderById', doFetchGtsOrder),
    takeLatest('gtsOrder/fetchGtsOrders', doFetchGtsOrders),
    takeLatest('gtsOrder/updateGtsOrder', doUpdateGtsOrder),
    takeLatest('gtsOrder/processGtsOrder', doProcessGtsOrder),
    takeLatest('gtsOrder/processGtsOrderStaging', doProcessGtsOrderStaging),
    takeLatest('gtsOrder/createGtsOrder', doCreateGtsOrder)
];

