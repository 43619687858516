import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Paper } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Paging from '../../interfaces/common/paging';
import Pagination from '../../interfaces/common/pagination';
import OrderUnit from '../../interfaces/output/orderUnit';
import OrderUnitService from '../../interfaces/output/orderUnitService';
import {
    creditOrderUnit, fetchOrderUnitById, fetchServiceByUnitId, getOrderUnit, getUnitServices, isCrediting, isLoadingOrderUnit, isLoadingOrderUnitServices, isUpdatingOrderUnit, resetOrderUnit,
    updateOrderUnit
} from '../../reducers/orderUnitReducer';
import ViewOrderUnit from './viewOrderUnit';
import UnitServiceList from './orderUnitServiceList';
import LanguageUtils from '../../utils/LanguageUtils';
import { ensure } from '../../utils/arrayUtils';
import { deactivateOrderUnitService, isDeletingUnitService } from '../../reducers/unitServiceReducer';
import { fetchOrderById, getOrder, isLoadingOrder } from '../../reducers/orderReducer';
import Order from '../../interfaces/output/order';
import { FormattedMessage } from 'react-intl';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AddCreditModal from '../common/addCreditModel';
import { fetchCustomerReport, getCustomerReport, isLoadingCustomerReport } from '../../reducers/invoiceReducer';
import CustomerReport from '../../interfaces/output/customerReport';
import DetailedHistoryFilter from '../../interfaces/filters/detailedHistoryFilter';
import DetailedHistoryFilterComponent from '../common/detailedHistoryFilterComponent';
import CustomerReportList from '../common/customerReportList';

interface IOrderUnitDetailsProps {
    classes: any;
    theme: Theme;
    fetchOrderUnitById: any;
    fetchServiceByUnitId: any;
    updateOrderUnit: any;
    fetchOrderById: any;
    creditOrderUnit: any;
    orderUnit: OrderUnit;
    unitServices: Pagination<OrderUnitService>;
    order: Order;
    match: any;
    history: any;
    location: any;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoading: boolean;
    isLoadingOrderUnit: boolean;
    isLoadingOrderUnitServices: boolean;
    isCrediting: boolean;
    resetOrderUnit: () => void;
    isDeletingUnitService: boolean;
    isLoadingOrder: boolean;
    deactivateOrderUnitService: (orderUnitId: number) => void;
    customerReport: Pagination<CustomerReport>;
    isLoadingCustomerReport: boolean;
    fetchCustomerReport: (filters: DetailedHistoryFilter, paging: Paging) => void;
}

interface IOrderUnitDetailsState {
    orderUnit: OrderUnit;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    showAddForm: boolean;
    pagingCustomerReport: Paging;
    sortModelCustomerReport: GridSortModel;
    detailedHistoryFilter: DetailedHistoryFilter;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrderUnitById: (id: number) => dispatch(fetchOrderUnitById(id)),
    updateOrderUnit: (gtsorder: OrderUnit) => dispatch(updateOrderUnit(gtsorder)),
    fetchServiceByUnitId: (unitId: number, paging: Paging) => dispatch(fetchServiceByUnitId({
        unitId,
        paging
    })),
    resetOrderUnit: () => dispatch(resetOrderUnit()),
    deactivateOrderUnitService: (orderUnitId: number) => dispatch(deactivateOrderUnitService(orderUnitId)),
    fetchOrderById: (orderId: number) => dispatch(fetchOrderById(orderId)),
    creditOrderUnit: (id: number, createNewCredit: boolean, creditReason: string) => dispatch(creditOrderUnit({
        id,
        createNewCredit,
        creditReason
    })),
    fetchCustomerReport: (filter: DetailedHistoryFilter, paging: Paging) => dispatch(fetchCustomerReport({
        filter,
        paging
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        orderUnit: getOrderUnit(store),
        order: getOrder(store),
        isUpdating: isUpdatingOrderUnit(store),
        unitServices: getUnitServices(store),
        isLoadingOrderUnit: isLoadingOrderUnit(store),
        isLoadingOrder: isLoadingOrder(store),
        isLoadingOrderUnitServices: isLoadingOrderUnitServices(store),
        isDeletingUnitService: isDeletingUnitService(store),
        isCrediting: isCrediting(store),
        isLoadingCustomerReport: isLoadingCustomerReport(store),
        customerReport: getCustomerReport(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    noTerminal: { fontWeight: 'lighter' }
});

const messages = {
    add: LanguageUtils.createMessage('Add'),
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Deactivate'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    subname: LanguageUtils.createMessage('ORDER_UNIT'),
    properties: LanguageUtils.createMessage('Properties'),
    services: LanguageUtils.createMessage('Services'),
    noTerminal: LanguageUtils.createMessage('NO_SPECIFIED_TERMINAL'),
    credit: LanguageUtils.createMessage('Credit'),
    detailedHistory: LanguageUtils.createMessage('Detailed history')
};

class OrderUnitDetails extends Component<IOrderUnitDetailsProps, IOrderUnitDetailsState> {
    customerReportListRef: any;
    constructor(props: IOrderUnitDetailsProps) {
        super(props);
        this.customerReportListRef = React.createRef();

        this.state = {
            orderUnit: {} as OrderUnit,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.services,
                icon: <FontAwesomeIcon icon="dolly" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/services`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/services`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.detailedHistory,
                icon: <FontAwesomeIcon icon={['fab', 'microsoft']} size="1x" transform="grow-4" />,
                url: `/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/customerReport`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNITS}/${props.match.params.orderUnitId}/customerReport`, props.location.pathname)
            }]),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
                currency: false,
                serviceEndDate: false
            },
            showAddForm: false,
            pagingCustomerReport: PageUtils.getDefaultPaging(),
            sortModelCustomerReport: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            detailedHistoryFilter: {
            } as DetailedHistoryFilter
        };
    }

    componentDidMount() {
        const { fetchOrderUnitById, fetchCustomerReport } = this.props;
        const { orderUnitId } = this.props.match.params;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;

        fetchOrderUnitById(orderUnitId);

        const filter = {
            ...detailedHistoryFilter,
            orderUnitId: orderUnitId
        } as DetailedHistoryFilter;
        fetchCustomerReport(filter, pagingCustomerReport);

    }

    componentDidUpdate(prevProps: IOrderUnitDetailsProps) {
        const { orderUnit, isLoadingOrderUnit, isDeletingUnitService, fetchOrderById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(orderUnit !== prevProps.orderUnit) {
            if(orderUnit.orderId) {
                fetchOrderById(orderUnit.orderId);
            }

            this.setState({ orderUnit });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (isLoadingOrderUnit === false && prevProps.isLoadingOrderUnit === true) || (!isDeletingUnitService && prevProps.isDeletingUnitService)) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetOrderUnit } = this.props;
        resetOrderUnit();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchServiceByUnitId } = this.props;
        const { orderUnit } = this.props;

        const paging = PageUtils.getDefaultPaging();

        if(selectedMenuItem?.id === 2) {
            fetchServiceByUnitId(orderUnit.id, paging);
        }
    }

    getSelectedMenuItem = (state: IOrderUnitDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateOrderUnitService = () => {
        const { selectedRow } = this.state;
        const { deactivateOrderUnitService } = this.props;

        deactivateOrderUnitService(ensure(selectedRow));
    }

    // creditOrderUnit = () => {
    //     const { orderUnitId } = this.props.match.params;
    //     const { creditOrderUnit } = this.props;

    //     creditOrderUnit(orderUnitId, true);
    // };

    handleMenuItems = () => {
        const { menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { orderUnitId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.ORDER_UNITS}/${orderUnitId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    // {
                    //     href: `${UrlConstants.ORDER_UNITS}/${orderUnitId}/status`,
                    //     icon: 'retweet',
                    //     text: messages.changeStatus
                    // },
                    {
                        icon: 'credit-card',
                        text: messages.credit,
                        onClick: this.toggleAddModal
                    }
                ];
            }

            case 2: { //services
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.ORDER_UNITS}/${orderUnitId}/orderunitservice/add`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICES}/${selectedRow}/status`,
                        icon: 'retweet',
                        text: messages.changeStatus
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    _onSortModelChange = (newModel: GridSortModel) => {
        const { fetchServiceByUnitId } = this.props;
        const { orderUnit, serverPagination, sortModel } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                serverPagination: newPagination
            });

            fetchServiceByUnitId(orderUnit.id, newPagination);
        }
    }

    applyDetaildHistoryFilters = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;
        const { orderUnitId } = this.props.match.params;

        fetchOrderUnitById(orderUnitId);

        const filter = {
            ...detailedHistoryFilter,
            orderUnitId: orderUnitId
        } as DetailedHistoryFilter;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };

        this.setState({
            detailedHistoryFilter: filter,
            pagingCustomerReport: newPagination
        });

        fetchCustomerReport(filter, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    setDetaildHistoryFiltersDefaultState = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport } = this.state;
        const { orderUnitId } = this.props.match.params;

        const newFilters = {
            orderUnitId: orderUnitId
        } as DetailedHistoryFilter;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            pagingCustomerReport: newPagination,
            detailedHistoryFilter: newFilters
        });

        fetchCustomerReport(newFilters, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    onDetaildHistoryFiltersChange = (attribute: string, value: any) => {
        const { detailedHistoryFilter, pagingCustomerReport } = this.state;

        const newFilter = { ...detailedHistoryFilter } as DetailedHistoryFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };
        this.setState({
            detailedHistoryFilter: newFilter,
            pagingCustomerReport: newPagination
        });
    };

    _onSortChangeCustomerReport = (newModel: GridSortModel) => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, sortModelCustomerReport, detailedHistoryFilter } = this.state;
        const { orderUnitId } = this.props.match.params;

        const newFilters = {
            ...detailedHistoryFilter,
            orderUnitId: orderUnitId
        } as DetailedHistoryFilter;

        if(JSON.stringify(sortModelCustomerReport) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...pagingCustomerReport,
                sort: newModel
            };
            this.setState({
                sortModelCustomerReport: newModel,
                pagingCustomerReport: newPagination,
                detailedHistoryFilter: newFilters
            });

            fetchCustomerReport(newFilters, newPagination);
        }
    }

    noTerminalSpan = <span className={this.props.classes.noTerminal}> <FormattedMessage {...messages.noTerminal} /> </span>;

    toggleAddModal = () => {
        this.setState({ showAddForm: !this.state.showAddForm });
    }

    render() {
        const { classes, isLoadingOrderUnit, unitServices, isLoadingOrder, isLoadingOrderUnitServices, isDeletingUnitService, order, fetchServiceByUnitId,
            fetchCustomerReport, isLoadingCustomerReport, customerReport } = this.props;
        const { orderUnit, menuItems, serverPagination, columnVisibilityModel, showAddForm, pagingCustomerReport, detailedHistoryFilter } = this.state;
        const { orderUnitId } = this.props.match.params;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingOrderUnit || isLoadingOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.OrderUnit}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: <>{orderUnit.unitNo} {order.fromTerminal?.name || this.noTerminalSpan} -
                        {order.toTerminal?.name || this.noTerminalSpan}</>,
                    icon: <FontAwesomeIcon icon="box" size="1x" />
                } as IMenuItem}
                routes={
                    [{
                        name: order.orderNumber,
                        url: `${UrlConstants.ORDERS}/${order.id}`
                    }]
                }
            >
                <Paper>
                    <Container className={classes.container} maxWidth="xl">
                        <Switch>
                            <Route path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId/properties`}>
                                <ViewOrderUnit orderUnit={orderUnit} />
                            </Route>
                            <Route path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId/services`}>
                                <UnitServiceList isLoading={isLoadingOrderUnitServices || isDeletingUnitService}
                                    prefix={'service_'}
                                    secondPrefix={'orderUnit_'}
                                    hiddenColumns={['currency', 'serviceEndDate', 'discount', 'status', 'relation']}
                                    rows={unitServices}
                                    checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...serverPagination,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchServiceByUnitId(orderUnit.id, newPage);
                                        this.setState({ serverPagination: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Route>
                            <Route path={`/${UrlConstants.ORDER_UNITS}/:orderUnitId/customerReport`}>
                                <>
                                    <DetailedHistoryFilterComponent applyFilters={this.applyDetaildHistoryFilters}
                                        setDefaultState={this.setDetaildHistoryFiltersDefaultState} filters={detailedHistoryFilter} onChange={this.onDetaildHistoryFiltersChange} />
                                    <CustomerReportList isLoading={isLoadingCustomerReport}
                                        rows={customerReport}
                                        ref={this.customerReportListRef}
                                        onPageChange={(nextPage: number, nextSize: number) => {
                                            const newPage = {
                                                ...pagingCustomerReport,
                                                page: nextPage,
                                                size: nextSize
                                            };
                                            const newFilter = {
                                                ...detailedHistoryFilter,
                                                orderUnitId: orderUnitId
                                            } as DetailedHistoryFilter;

                                            fetchCustomerReport(newFilter,newPage);

                                            this.setState({
                                                pagingCustomerReport: newPage,
                                                detailedHistoryFilter: newFilter
                                            });
                                        }}
                                        onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeCustomerReport(sortModel)}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                            this.setState({
                                                columnVisibilityModel: newModel
                                            })
                                        }
                                    />
                                </>
                            </Route>
                        </Switch>
                    </Container>
                    <AddCreditModal show={showAddForm} onClose={this.toggleAddModal} id={orderUnitId} createCredit={creditOrderUnit} />
                </Paper>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrderUnitDetails));