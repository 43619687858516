import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Theme } from '@mui/material';
import React from 'react';
import TableUtils from '../../../../utils/tableUtils';
import DataGridComponent from '../../../common/dataGrid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../../utils/LanguageUtils';
import TruncatedStringTooltip from '../../../common/truncatedStringTooltip';

interface IFactorSetsProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    factor: LanguageUtils.createMessage('Factor'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    unitType: LanguageUtils.createMessage('Unit type'),
    all: LanguageUtils.createMessage('All')
};

export default function ViewFactorSetPriorityLines(props: IFactorSetsProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            {
                field: 'name',
                headerName: intl.formatMessage(messages.name),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: 'priorityType',
                headerName: intl.formatMessage(messages.priorityType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <><TruncatedStringTooltip value={`${applyRowFn(params).priorityType?.name ?? ''} - ${applyRowFn(params).priorityType?.code ?? ''}`} /></>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('priorityType', hiddenColumns)
            },
            {
                field: 'unitType',
                headerName: intl.formatMessage(messages.unitType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    if(applyRowFn(params).unitType) {
                        return <><TruncatedStringTooltip value={`${applyRowFn(params).unitType?.name} - ${applyRowFn(params).unitType?.code}`} /></>;
                    }

                    return <><TruncatedStringTooltip value={intl.formatMessage(messages.all)} /></>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitType', hiddenColumns)
            },
            {
                field: 'factor',
                headerName: intl.formatMessage(messages.factor),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).factor ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factor', hiddenColumns)
            },
            {
                field: 'surcharge',
                headerName: intl.formatMessage(messages.surcharge),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).surcharge ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('surcharge', hiddenColumns)
            },
            {
                field: 'surchargeCurrency',
                headerName: intl.formatMessage(messages.surchargeCurrency),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).surchargeCurrency?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('surchargeCurrency', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
}
