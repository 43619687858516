import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import FactorSetWeightApi from '../api/factorSetWeightApi';
import { createFactorSetWeightError, createFactorSetWeightSuccess, deactivateFactorSetWeightError,
    deactivateFactorSetWeightSuccess, fetchFactorSetWeightsError, fetchFactorSetWeightsSuccess,
    fetchFactorSetWeightByIdError, fetchFactorSetWeightByIdSuccess, updateFactorSetWeightError, updateFactorSetWeightSuccess, createWeightVersionSuccess, createWeightVersionError }
    from '../reducers/factorSetWeightReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import WeightFilters from '../components/factorSets/weight/weightFilters';

function* doFetchFactorSetWeight(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {
    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetWeightApi.fetchFactorSetWeightById, factorSetId, versionId);
    
        yield put(fetchFactorSetWeightByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetWeightByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetWeights(action: PayloadAction<{ paging: Paging, filters: WeightFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetWeightApi.fetchFactorSetWeights, paging, filters);

        yield put(fetchFactorSetWeightsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetWeightsError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetWeight(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetWeightApi.createFactorSetWeight, factorSet);

        yield put(createFactorSetWeightSuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetWeightError(error as HttpErrorResponse));
    }
}

function* doCreateWeightVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetWeightApi.createWeightVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createWeightVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createWeightVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetWeight(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetWeightApi.updateFactorSetWeight, factorSet);

        yield put(updateFactorSetWeightSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetWeightError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetWeight(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetWeightApi.deactivateFactorSetWeight, id);

        yield put(deactivateFactorSetWeightSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetWeightError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetWeight/fetchFactorSetWeightById', doFetchFactorSetWeight),
    takeLatest('factorSetWeight/fetchFactorSetWeights', doFetchFactorSetWeights),
    takeLatest('factorSetWeight/createFactorSetWeight', doCreateFactorSetWeight),
    takeLatest('factorSetWeight/createWeightVersion', doCreateWeightVersion),
    takeLatest('factorSetWeight/updateFactorSetWeight', doUpdateFactorSetWeight),
    takeLatest('factorSetWeight/deactivateFactorSetWeight', doDeactivateFactorSetWeight)
];