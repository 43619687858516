import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import Service from '../../../interfaces/output/service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnitGroups, getUnitGroups, isLoadingUnitGroups } from '../../../reducers/unitGroupReducer';
import { fetchTerminals, getTerminals, isLoadingTerminal } from '../../../reducers/terminalReducer';
import { fetchServiceStatuses } from '../../../reducers/serviceStatusReducer';
import { fetchImportExportCodes, getImportExportCodes, isLoadingImportExportCodes } from '../../../reducers/importExportCodeReducer';
import { fetchServiceTypes, getServiceTypes, isLoadingServiceTypes } from '../../../reducers/serviceTypeReducer';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import { isCreatingService, isLoadingService, isUpdatingService } from '../../../reducers/serviceReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import UnitGroup from '../../../interfaces/output/unitGroup';
import ImportExportCode from '../../../interfaces/output/importExportCode';
import Terminal from '../../../interfaces/output/terminal';
import ServiceType from '../../../interfaces/output/serviceType';
import GenericTextField from '../../common/widgets/genericTextField';
import ServiceStep1Validation from './validations/serviceStep1Validation';

interface AddServiceStep1Props {
    service: Service;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    FROM_TERMINAL: 'fromTerminal',
    TO_TERMINAL: 'toTerminal',
    TYPE: 'type'
};

const messages = {
    unitGroup: LanguageUtils.createMessage('Unit group'),
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to'),
    serviceType: LanguageUtils.createMessage('Service type'),
    serviceAlternative: LanguageUtils.createMessage('Service alternative'),
    importExport: LanguageUtils.createMessage('Service import export')
};

const AddServiceStep1 = forwardRef((props: AddServiceStep1Props, ref: any): JSX.Element => {
    const { service, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const terminals = useSelector(getTerminals).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const importExportCodes = useSelector(getImportExportCodes).content;
    const serviceTypes = useSelector(getServiceTypes).content;
    const isLoadingObjects = [useSelector(isCreatingService), useSelector(isLoadingService), useSelector(isLoadingUnitGroups), useSelector(isLoadingTerminal),
        useSelector(isLoadingServiceTypes), useSelector(isLoadingImportExportCodes), useSelector(isUpdatingService)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchServiceStatuses({ paging }));
        dispatch(fetchServiceTypes({ paging }));
        dispatch(fetchImportExportCodes({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.FROM_TERMINAL: {
                isValid = ServiceStep1Validation.validateInputNumber(service?.fromTerminal?.id);
                break;
            }
            case ATTRIBUTES.TO_TERMINAL: {
                isValid = ServiceStep1Validation.validateInputNumber(service?.toTerminal?.id);
                break;
            }
            case ATTRIBUTES.TYPE: {
                isValid = ServiceStep1Validation.validateInputNumber(service.type?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="serviceAlternative"
                        label={messages.serviceAlternative}
                        value={service.serviceAlternative}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={service.unitGroup}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                            placeholder={messages.unitGroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<ImportExportCode>
                        options={importExportCodes}
                        value={service.importExportCode}
                        onChange={(obj: ImportExportCode | null) => onChange('importExportCode', obj)}
                        placeholder={messages.importExport}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={service.fromTerminal}
                            onChange={(obj: Terminal | null) => onChange('fromTerminal', obj)}
                            placeholder={messages.terminalFrom}
                            error={setValidationState(ATTRIBUTES.FROM_TERMINAL)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={service.toTerminal}
                            onChange={(obj: Terminal | null) => onChange('toTerminal', obj)}
                            placeholder={messages.terminalTo}
                            error={setValidationState(ATTRIBUTES.TO_TERMINAL)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ServiceType>
                            options={serviceTypes}
                            value={service.type}
                            onChange={(obj: ServiceType | null) => onChange('type', obj)}
                            placeholder={messages.serviceType}
                            error={setValidationState(ATTRIBUTES.TYPE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddServiceStep1;