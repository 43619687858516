import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import TableUtils from '../../utils/tableUtils';
import UrlConstants from '../../constants/UrlConstants';
import CustomStringLink from '../common/customStringLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import DataGridComponent from '../common/dataGrid';

interface ResponsibilitiesCenterListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    code: LanguageUtils.createMessage('Code')
};

const ResponsibilitiesCenterList = forwardRef((props: ResponsibilitiesCenterListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.RESPONSIBILITIES_CENTER);
        }
    }, []);
  
    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'name',
                headerName: `${intl.formatMessage(messages.name)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).name} link={`/#/${UrlConstants.RESPONSIBILITIES_CENTER}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: 'code',
                headerName: `${intl.formatMessage(messages.code)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).code} link={`/#/${UrlConstants.RESPONSIBILITIES_CENTER}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('code', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ResponsibilitiesCenterList;
