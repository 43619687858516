import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import PriorityFilters from '../components/factorSets/priority/priorityFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';

export type FactorSetHoursState = {
    factorSetHour: FactorSetOut;
    factorSetHours: Pagination<FactorSetOut>;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoadingFactorSetHour: boolean;
    isLoadingFactorSetHours: boolean;
    isLoadingFactorSetVersions: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetHoursState = {
    factorSetHour: {} as FactorSetOut,
    factorSetHours: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoadingFactorSetVersions: false,
    isLoadingFactorSetHour: false,
    isLoadingFactorSetHours: false,
    isUpdating: false,
    isRemoving: false,
    isDeleting: false,
    isCreating: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetHoursSlice = createSlice({
    name: 'factorSetHours',

    initialState,
    reducers: {
        fetchFactorSetHours: (state: FactorSetHoursState, action: PayloadAction<{paging: Paging, filters?: PriorityFilters}>) => {
            state.isLoadingFactorSetHours = true;
        },
        fetchFactorSetHoursSuccess: (state: FactorSetHoursState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingFactorSetHours = false;
            state.factorSetHours = action.payload;
        },
        fetchFactorSetHoursError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetHours = false;
            state.error = action.payload;
        },
        fetchFactorSetHourById: (state: FactorSetHoursState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetHour = true;
        },
        fetchFactorSetHourByIdSuccess: (state: FactorSetHoursState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetHour = false;
            state.factorSetHour = action.payload;
        },
        fetchFactorSetHourByIdError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetHour = false;
            state.error = action.payload;
        },
        fetchHourVersions: (state: FactorSetHoursState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchHourVersionsSuccess: (state: FactorSetHoursState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchHourVersionsError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        createFactorSetHour: (state: FactorSetHoursState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetHourSuccess: (state: FactorSetHoursState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetHour = action.payload;
        },
        createFactorSetHourError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createHoursVersion: (state: FactorSetHoursState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createHoursVersionSuccess: (state: FactorSetHoursState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetHour = action.payload;
        },
        createHoursVersionError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateFactorSetHour: (state: FactorSetHoursState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetHourSuccess: (state: FactorSetHoursState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetHour = action.payload;
        },
        updateFactorSetHourError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetHour: (state: FactorSetHoursState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetHourSuccess: (state: FactorSetHoursState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleting = false;
            state.factorSetHour = action.payload;
        },
        deactivateFactorSetHourError: (state: FactorSetHoursState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetHours: (state: FactorSetHoursState) => {
            state.factorSetHours = initialState.factorSetHours;
        },
        resetFactorSetHour: (state: FactorSetHoursState) => {
            state.factorSetHour = initialState.factorSetHour;
        }
    }
});

export const { fetchFactorSetHourById, fetchFactorSetHourByIdSuccess, fetchFactorSetHourByIdError, fetchFactorSetHours, fetchFactorSetHoursError, fetchFactorSetHoursSuccess,
    createFactorSetHour, createFactorSetHourError , createFactorSetHourSuccess, updateFactorSetHour, updateFactorSetHourError, updateFactorSetHourSuccess,
    deactivateFactorSetHour, deactivateFactorSetHourError, deactivateFactorSetHourSuccess, resetFactorSetHours, resetFactorSetHour, createHoursVersion, createHoursVersionSuccess,
    createHoursVersionError } = factorSetHoursSlice.actions;

export const isLoadingFactorSetHour = (state: RootState): boolean => state.factorSetHoursReducer.isLoadingFactorSetHour;
export const isLoadingFactorSetHours = (state: RootState): boolean => state.factorSetHoursReducer.isLoadingFactorSetHours;
export const isUpdatingHour = (state: RootState): boolean => state.factorSetHoursReducer.isUpdating;
export const isDeletingHour = (state: RootState): boolean => state.factorSetHoursReducer.isDeleting;
export const isCreatingHour = (state: RootState): boolean => state.factorSetHoursReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetHoursReducer.isCreatingVersion;
export const getFactorSetHours = (state: RootState): Pagination<FactorSetOut> => state.factorSetHoursReducer.factorSetHours;
export const getFactorSetHour = (state: RootState): FactorSetOut => state.factorSetHoursReducer.factorSetHour;
export const isLoadingFactorSetVersions = (state: RootState): boolean => state.factorSetHoursReducer.isLoadingFactorSetVersions;

export default factorSetHoursSlice.reducer;