import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import DepartureTypeApi from '../api/departureTypeApi';
import { fetchDepartureTypesError, fetchDepartureTypesSuccess } from '../reducers/departureTypeReducer';

function* doFetchDepartureTypes() {
    try {
        const response: Pagination<DepartureTypeApi> = yield call(DepartureTypeApi.fetchDepartureTypes);
        yield put(fetchDepartureTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDepartureTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('departureType/fetchDepartureTypes', doFetchDepartureTypes)
];

