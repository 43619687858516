import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import TableUtils from '../../utils/tableUtils';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import AgreementVersionSpecialPriceFilters from '../../interfaces/filters/agreementVersionSpecialPriceFilters';
import Currency from '../../interfaces/output/currency';
import Terminal from '../../interfaces/output/terminal';
import DepartureType from '../../interfaces/output/departureType';
import PriorityType from '../../interfaces/output/priorityType';
import UnitType from '../../interfaces/output/unitType';
import { fetchUnitTypes, getUnitTypes } from '../../reducers/unitTypeReducer';
import { fetchCurrencies, getCurrencies } from '../../reducers/currencyReducer';
import { fetchDepartureTypes, getDepartureTypes } from '../../reducers/departureTypeReducer';
import { fetchPriorityTypes, getPriorityTypes } from '../../reducers/priorityTypeReducer';
import { fetchTerminals, getTerminals } from '../../reducers/terminalReducer';
import GenericTextField from '../common/widgets/genericTextField';

interface IFilterProps {
    filters: AgreementVersionSpecialPriceFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    hiddenColumns?: Array<string>;
}

const messages = {
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    departureType: LanguageUtils.createMessage('Departure type'),
    validOnThisDate: LanguageUtils.createMessage('Valid on this date'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    currency: LanguageUtils.createMessage('Currency'),
    grossWeightKgFrom: LanguageUtils.createMessage('FROM_WEIGHT_KG'),
    grossWeightKgTo: LanguageUtils.createMessage('TO_WEIGHT_KG'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function AgreementVersionSpecialPriceFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, hiddenColumns } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const departureTypes = useSelector(getDepartureTypes).content;
    const priorityTypes = useSelector(getPriorityTypes).content;
    const terminals = useSelector(getTerminals).content;

    useEffect(() => {
        dispatch(fetchUnitTypes({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchDepartureTypes({ paging }));
        dispatch(fetchPriorityTypes({ paging }));
        dispatch(fetchTerminals({ paging }));
    }, [dispatch]);
    
    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <GenericAutocomplete<UnitType>
                            options={unitTypes}
                            value={filters.unitTypeId}
                            onChange={(obj: UnitType | null) => onChange('unitTypeId', obj?.id)}
                            placeholder={messages.unitType}
                            compareFn={(o: UnitType) => o.id === filters.unitTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<PriorityType>
                            options={priorityTypes}
                            value={filters.priorityId}
                            onChange={(obj: PriorityType | null) => onChange('priorityId', obj?.id)}
                            placeholder={messages.priorityType}
                            compareFn={(o: PriorityType) => o.id === filters.priorityId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<DepartureType>
                            options={departureTypes}
                            value={filters.departureTypeId}
                            onChange={(obj: DepartureType | null) => onChange('departureTypeId', obj?.id)}
                            placeholder={messages.departureType}
                            compareFn={(o: DepartureType) => o.id === filters.departureTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={filters.currencyId}
                            onChange={(obj: Currency | null) => onChange('currencyId', obj?.id)}
                            placeholder={messages.currency}
                            compareFn={(o: Currency) => o.id === filters.currencyId}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericDatePicker
                                name="validOnThisDate"
                                label={messages.validOnThisDate}
                                value={filters?.validOnThisDate}
                                onChange={onChange}
                                type="start"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="grossWeightKgFrom"
                                label={messages.grossWeightKgFrom}
                                onChange={onChange}
                                value={filters.grossWeightKgFrom}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="grossWeightKgTo"
                                label={messages.grossWeightKgTo}
                                onChange={onChange}
                                value={filters.grossWeightKgTo}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        {!TableUtils.hideColumn('fromTerminalId', hiddenColumns) &&
                            <GenericAutocomplete<Terminal>
                                options={terminals}
                                value={filters.terminalFromId}
                                onChange={(obj: Terminal | null) => onChange('terminalFromId', obj?.id)}
                                placeholder={messages.fromTerminal}
                                compareFn={(o: Terminal) => o.id === filters.terminalFromId}
                            />
                        }
                    </Grid>
                    {!TableUtils.hideColumn('toTerminalId', hiddenColumns) &&
                        <Grid item xs>
                            <GenericAutocomplete<Terminal>
                                options={terminals}
                                value={filters.terminalToId}
                                onChange={(obj: Terminal | null) => onChange('terminalToId', obj?.id)}
                                placeholder={messages.toTerminal}
                                compareFn={(o: Terminal) => o.id === filters.terminalToId}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}