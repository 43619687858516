import { List } from 'immutable';
import SurchargeRate from '../../../../interfaces/output/surchargeRate';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import ValidationModel from '../../../../utils/validationModel';
import { ensure } from '../../../../utils/arrayUtils';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range')
};

export default class SurchargeRateStep1Validation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateInputDateStart(surchargeRate: SurchargeRate): boolean {
        const dateStart = surchargeRate.validFromDate;
        const dateTo = surchargeRate.validToDate;

        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(surchargeRate: SurchargeRate): boolean {
        const dateStart = surchargeRate.validFromDate;
        const dateTo = surchargeRate.validToDate;

        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validateSurchargeRateForm(surchargeRate: SurchargeRate): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { validFromDate,
            validToDate,
            surchargeArea,
            surchargeType,
            surchargeRateUnit,
            rate_Eur,
            rate_Nok
        } = surchargeRate;

        const requiredFields =
            this.validateRequiredDate(validFromDate) &&
            this.validateRequiredDate(validToDate) &&
            this.validateInputNumber(surchargeArea?.id) &&
            this.validateInputNumber(surchargeType?.id) &&
            this.validateInputNumber(surchargeRateUnit?.id) &&
            this.validateInputNumber(rate_Eur) &&
            this.validateInputNumber(rate_Nok);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = surchargeRate.validToDate;
        const validDates = endDate ? this.validateInputDateStart(surchargeRate) &&
            this.validateInputDateEnd(surchargeRate) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        return validationModel;
    }
}
