import React, { useEffect } from 'react';
import AddProductStep1 from './add/addProductStep1';
import AddProductStep2 from './add/addProductStep2';
import { useDispatch, useSelector } from 'react-redux';
import { createProduct, fetchProductById, getProduct, isCreatingProduct, isLoadingProduct, isUpdatingProduct, updateProduct } from '../../reducers/productReducer';
import GenericStepper from '../common/widgets/genericStepper';
import GenericStep from '../../interfaces/common/genericStep';
import Product from '../../interfaces/output/product';
import UrlConstants from '../../constants/UrlConstants';
import WorkflowUtils from '../../utils/workflowUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';
import ProductStep1Validation from '../products/validations/ProductStep1Validation';

interface IAddProductFormProps {
    productId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit product'),
    add: LanguageUtils.createMessage('Add product')
};

export default function AddProductForm(props: IAddProductFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { productId, steps, type } = props;
    const [product, setProduct] = React.useState<Product>({} as Product);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsProduct = useSelector(getProduct);
    const propsIsLoadingProduct = useSelector(isLoadingProduct);
    const prevIsLoadingProduct = WorkflowUtils.usePrevious<boolean>(propsIsLoadingProduct);
    const propsIsUpdatingProduct = useSelector(isUpdatingProduct);
    const prevIsUpdatingProduct = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingProduct);
    const propsIsCreatingProduct = useSelector(isCreatingProduct);
    const prevIsCreatingProduct = WorkflowUtils.usePrevious<boolean>(propsIsCreatingProduct);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 2:
                if((prevIsUpdatingProduct === true && !propsIsUpdatingProduct) || (prevIsCreatingProduct && !propsIsCreatingProduct)) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }

        return () => setRedirectCondition(false);
    }, [propsIsCreatingProduct, propsIsUpdatingProduct]);

    useEffect(() => {
        if(productId) {
            dispatch(fetchProductById(productId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(!propsIsLoadingProduct && prevIsLoadingProduct) {
            setProduct(propsProduct);
        }

    }, [propsIsLoadingProduct]);

    const onChange = (attribute: string, value: any) => {
        const newProduct = { ...product } as Product;
        (newProduct as any)[attribute] = value;

        setProduct(newProduct);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <AddProductStep1 onChange={onChange} product={product} />,
            validationFn: () => ProductStep1Validation.validateOrderForm(product)
        } as GenericStep,
        {
            id: 2,
            content: <AddProductStep2 onChange={onChange} product={product} />,
            onNext: () => {
                if(!product.id) {
                    dispatch(createProduct(product));
                }
                else {
                    dispatch(updateProduct(product));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.PRODUCTS}/${propsProduct.id}`}
        />
    );
}
