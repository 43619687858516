import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetCancellationLineOutput from '../interfaces/output/factorSetCancellationLineOut';
import FactorSetCancellationLineInput from '../interfaces/input/factorSetCancellationLineIn';
import CancellationLineFilters from '../components/factorSets/cancellation/line/cancellationLineFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class CancellationLineApi {
    static fetchFactorSetCancellationLineById = async(lineId: number): Promise<FactorSetCancellationLineOutput> => {
        const { data } = await http.get<FactorSetCancellationLineOutput>(`factorsetcancellationlines/${lineId}`);

        return data;
    };

    static fetchFactorSetCancellationLines = async(paging: Paging, filters: CancellationLineFilters): Promise<Pagination<FactorSetCancellationLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, 'factorsetcancellationlines');

        const { data } = await http.get<Pagination<FactorSetCancellationLineOutput>>(url);

        return data;
    };

    static createFactorSetCancellationLine = async(factorSetLine: FactorSetCancellationLineOutput): Promise<FactorSetCancellationLineOutput> => {
        const newFactorSetCancellation: FactorSetCancellationLineInput = {
            name: factorSetLine.name,
            cancellationTypeId: factorSetLine.cancellationType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            unitTypeId: factorSetLine.unitType?.id,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };

        const { data } = await http.post<FactorSetCancellationLineInput, FactorSetCancellationLineOutput>('factorsetcancellationlines', newFactorSetCancellation);

        return data;
    }

    static updateFactorSetCancellationLine = async(factorSetLine: FactorSetCancellationLineOutput): Promise<FactorSetCancellationLineOutput> => {
        const newLine: FactorSetCancellationLineInput = {
            name: factorSetLine.name,
            cancellationTypeId: factorSetLine.cancellationType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            unitTypeId: factorSetLine.unitType?.id,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };
        
        const { data } = await http.put<FactorSetCancellationLineInput, FactorSetCancellationLineOutput>(`factorsetcancellationlines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetCancellationLine = async(lineId: number): Promise<FactorSetCancellationLineOutput> => {
        const { data } = await http.delete<FactorSetCancellationLineOutput>(`factorsetcancellationlines/${lineId}`);

        return data;
    }
}