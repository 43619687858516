import { List } from 'immutable';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';

const messages = {
    requiredFields: LanguageUtils.createMessage('Agreement is required')
};

export default class AgreementSelectionValidation {
    static validateSelectedAgreement(customerAgreementIds: List<number>): boolean {
        return customerAgreementIds.size > 0;
    }

    static validateCustomerAgreementForm(customerAgreementIds: List<number>): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const requiredFields = AgreementSelectionValidation.validateSelectedAgreement(customerAgreementIds);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
