import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/widgets/loader';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import PageUtils from '../../../../utils/pageUtils';
import FactorSetVersionStatusOut from '../../../../interfaces/output/factorSetVersionStatusOut';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import { fetchWeightVersionStatuses, getWeightVersionStatuses, isLoadingWeightVersionStatus } from '../../../../reducers/weightVersionStatusReducer';
import { isUpdatingWeightVersionStatus } from '../../../../reducers/factorSetWeightVersionReducer';
import { isLoadingFactorSetWeight } from '../../../../reducers/factorSetWeightReducer';

interface AddWeightStep5Props {
    factorSet: FactorSetOut;
    onChangeVersion: any;
}

const messages = {
    status: LanguageUtils.createMessage('Status')
};

const AddWeightVersionStep3 = forwardRef((props: AddWeightStep5Props, ref: any): JSX.Element => {
    const { factorSet, onChangeVersion } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const weightVersionStatuses = useSelector(getWeightVersionStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingWeightVersionStatus), useSelector(isLoadingFactorSetWeight), useSelector(isUpdatingWeightVersionStatus)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchWeightVersionStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetVersionStatusOut>
                            options={weightVersionStatuses}
                            value={factorSet.version?.status}
                            onChange={(obj: FactorSetVersionStatusOut | null) => onChangeVersion('status', obj)}
                            placeholder={messages.status}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddWeightVersionStep3;