import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import Currency from '../interfaces/output/currency';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class CurrencyApi {
    static fetchCurrencyById = async(currencyId: string): Promise<Currency> => {
        const { data } = await http.get<Currency>(`/currencies/${currencyId}`);

        return data;
    };

    static fetchCurencies = async(paging: Paging): Promise<Pagination<Currency>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/currencies');

        const { data } = await http.get<Pagination<Currency>>(url);

        return data;
    };
}
