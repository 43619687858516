import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { RootState } from '../setup';
import UserStatusOut from '../interfaces/output/userStatusOut';

export type UserStatusState = {
    isLoading: boolean;
    userStatus: UserStatusOut;
    userStatuses: Pagination<UserStatusOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: UserStatusState = {
    userStatus: {} as UserStatusOut,
    userStatuses: { content: [] as Array<UserStatusOut> } as Pagination<UserStatusOut>,
    isLoading: false,
    error: undefined
};

export const userStatusSlice = createSlice({
    name: 'userStatus',

    initialState,
    reducers: {
        fetchUserStatusById: (state: UserStatusState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchUserStatusByIdSuccess: (state: UserStatusState, action: PayloadAction<UserStatusOut>) => {
            state.isLoading = false;
            state.userStatus = action.payload;
        },
        fetchUserStatusByIdError: (state: UserStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchUserStatuses: (state: UserStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchUserStatusesSuccess: (state: UserStatusState, action: PayloadAction<Pagination<UserStatusOut>>) => {
            state.isLoading = false;
            state.userStatuses = action.payload;
        },
        fetchUserStatusesError: (state: UserStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchUserStatusById, fetchUserStatusByIdSuccess, fetchUserStatusByIdError, fetchUserStatuses, fetchUserStatusesSuccess, fetchUserStatusesError } = userStatusSlice.actions;

export const isLoadingUserStatuses = (state: RootState): boolean => state.userStatusReducer.isLoading;
export const getUserStatus = (state: RootState): UserStatusOut => state.userStatusReducer.userStatus;
export const getUserStatuses = (state: RootState): Pagination<UserStatusOut> => state.userStatusReducer.userStatuses;

export default userStatusSlice.reducer;