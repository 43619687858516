import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { fetchSellers, getSellers, isLoadingSellers } from '../../../reducers/sellerReducer';
import { isCreating, isLoadingAgreement, isUpdatingAgreement } from '../../../reducers/agreementsReducer';
import Agreement from '../../../interfaces/output/agreement';
import Seller from '../../../interfaces/output/seller';
import Currency from '../../../interfaces/output/currency';
import { fetchUnitGroups, getUnitGroups, isLoadingUnitGroups } from '../../../reducers/unitGroupReducer';
import { fetchCurrencies, getCurrencies, isLoadingCurrency } from '../../../reducers/currencyReducer';
import UnitGroup from '../../../interfaces/output/unitGroup';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import GenericTextField from '../../common/widgets/genericTextField';
import AgreementStep1Validation from '../validations/agreementStep1Validation';

interface AddAgreementStep1Props {
    agreement: Agreement;
    onChange: any;
    showValidationError?: boolean
}

const messages = {
    corporate: LanguageUtils.createMessage('Agreement corporate'),
    seller: LanguageUtils.createMessage('Seller'),
    name: LanguageUtils.createMessage('Name'),
    group: LanguageUtils.createMessage('Unit group'),
    currency: LanguageUtils.createMessage('Currency'),
    currencyHelpText: LanguageUtils.createMessage('PRICE_LIST_CURRENCY_HELPTEXT')
};

const AddAgreementStep1 = forwardRef((props: AddAgreementStep1Props, ref: any): JSX.Element => {
    const { agreement, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const unitGroups = useSelector(getUnitGroups).content;
    const currencies = useSelector(getCurrencies).content;
    const sellers = useSelector(getSellers).content;
    const isLoadingObjects = [useSelector(isCreating), useSelector(isLoadingAgreement), useSelector(isLoadingSellers), useSelector(isUpdatingAgreement),
        useSelector(isLoadingUnitGroups), useSelector(isLoadingCurrency)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchSellers({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    const ATTRIBUTES = {
        NAME: 'name'
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = AgreementStep1Validation.validateInputStringLength(agreement.name);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="name"
                            label={messages.name}
                            value={agreement.name}
                            onChange={onChange}
                            required
                            error={setValidationState(ATTRIBUTES.NAME)}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={agreement.unitGroup}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                            placeholder={messages.group}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Seller>
                            options={sellers}
                            value={agreement.seller}
                            onChange={(obj: Seller | null) => onChange('seller', obj)}
                            placeholder={messages.seller}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={agreement.currency}
                            onChange={(obj: Currency | null) => onChange('currency', obj)}
                            placeholder={messages.currency}
                            required
                            helperText={messages.currencyHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddAgreementStep1;