import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../../constants/UrlConstants';
import { RootState } from '../../../setup';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactorSetsFiltersComponent from './../factorSetsFiltersComponent';
import DepartureFilters from './departureFilter';
import { fetchFactorSetDepartures, getFactorSetDepartures, isLoadingFactorSetDepartures } from '../../../reducers/factorSetDepartureReducer';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import DepartureList from './../departure/departureList';
import LanguageUtils from '../../../utils/LanguageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import PageUtils from '../../../utils/pageUtils';

interface IFactorSetDeparturesProps {
    factorSetDepartures: Pagination<FactorSetOut>;
    classes: any;
    fetchFactorSetDepartures: any;
    isLoading: boolean;
    onChange: () => void;
}

interface IFactorSetDeparturesState {
    searchTerm: string;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    filters: DepartureFilters;
    columnVisibilityMode: GridColumnVisibilityModel;}

const messages = {
    factorSetDepartures: LanguageUtils.createMessage('Factor set departures'),
    add: LanguageUtils.createMessage('Add')
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetDepartures: (paging: Paging, filters: DepartureFilters) => dispatch(fetchFactorSetDepartures({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetDepartures: getFactorSetDepartures(store),
        isLoading: isLoadingFactorSetDepartures(store)
    };
};

class FactorSetDepartures extends Component<IFactorSetDeparturesProps, IFactorSetDeparturesState> {
    departureListRef: any;
    constructor(props: IFactorSetDeparturesProps) {
        super(props);
        this.departureListRef = React.createRef();

        this.state = {
            searchTerm: '',
            paging: PageUtils.getDefaultPaging(),
            filters: {
                name: ''
                // default: true
            } as DepartureFilters,
            menuItem: {
                id: 1,
                name: messages.factorSetDepartures,
                icon: <FontAwesomeIcon icon="calendar-check" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityMode: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetDepartures } = this.props;
        const { paging, filters } = this.state;

        fetchFactorSetDepartures(paging, filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as DepartureFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchFactorSetDepartures } = this.props;
        const { paging, filters } = this.state;
        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({ paging: newPagination });

        fetchFactorSetDepartures(newPagination, filters);

        if(this.departureListRef !== null && this.departureListRef !== undefined &&
            this.departureListRef.current !== null && this.departureListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.departureListRef.current.resetDataGridPage();
        }
    }

    setDefaultState = () => {
        const { fetchFactorSetDepartures } = this.props;
        const { paging } = this.state;

        const newFilters = {
            name: ''
            // default: true
        } as DepartureFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchFactorSetDepartures(newPagination, newFilters);

        if(this.departureListRef !== null && this.departureListRef !== undefined &&
            this.departureListRef.current !== null && this.departureListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.departureListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchFactorSetDepartures } = this.props;

        const newFilter = { ...filters } as DepartureFilters;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchFactorSetDepartures(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchFactorSetDepartures } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });
            fetchFactorSetDepartures(newPagination, filters);
        }
    }

    render() {
        const { classes, factorSetDepartures, fetchFactorSetDepartures, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityMode } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.FACTOR_SETS}/departure/add`
                                    }]}
                                >
                                    <FactorSetsFiltersComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <DepartureList rows={factorSetDepartures} isLoading={isLoading}
                                    ref={this.departureListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchFactorSetDepartures(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityMode}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityMode: newModel
                                        })
                                    }

                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetDepartures));