import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import InvoiceFormatOutput from '../interfaces/output/invoiceFormatOutput';

export type InvoiceFormatState = {
    invoiceFormat: InvoiceFormatOutput;
    isLoading: boolean;
    invoiceFormats: Pagination<InvoiceFormatOutput>;
    error: HttpErrorResponse | undefined;
};

const initialState: InvoiceFormatState = {
    invoiceFormat: {} as InvoiceFormatOutput,
    invoiceFormats: { content: [] as Array<InvoiceFormatOutput> } as Pagination<InvoiceFormatOutput>,
    isLoading: false,
    error: undefined
};

export const invoiceFormatSlice = createSlice({
    name: 'invoiceFormat',

    initialState,
    reducers: {
        fetchInvoiceFormats: (state: InvoiceFormatState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchInvoiceFormatsSuccess: (state: InvoiceFormatState, action: PayloadAction<Pagination<InvoiceFormatOutput>>) => {
            state.isLoading = false;
            state.invoiceFormats = action.payload;
        },
        fetchInvoiceFormatsError: (state: InvoiceFormatState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchInvoiceFormatById: (state: InvoiceFormatState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchInvoiceFormatByIdSuccess: (state: InvoiceFormatState, action: PayloadAction<InvoiceFormatOutput>) => {
            state.isLoading = false;
            state.invoiceFormat = action.payload;
        },
        fetchInvoiceFormatByIdError: (state: InvoiceFormatState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchInvoiceFormats, fetchInvoiceFormatsSuccess, fetchInvoiceFormatsError, fetchInvoiceFormatById, fetchInvoiceFormatByIdError, fetchInvoiceFormatByIdSuccess }
= invoiceFormatSlice.actions;

export const isLoadingInvoiceFormats = (state: RootState): boolean => state.invoiceFormatReducer.isLoading;
export const getInvoiceFormats = (state: RootState): Pagination<InvoiceFormatOutput> => state.invoiceFormatReducer.invoiceFormats;
export const getInvoiceFormat = (state: RootState): InvoiceFormatOutput => state.invoiceFormatReducer.invoiceFormat;

export default invoiceFormatSlice.reducer;