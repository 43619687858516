import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SurchargeType from '../interfaces/output/surchargeType';

export type SurchargeTypeState = {
    surchargeType: SurchargeType;
    isLoading: boolean;
    surchargeTypes: Pagination<SurchargeType>;
    error: HttpErrorResponse | undefined;
};

const initialState: SurchargeTypeState = {
    surchargeType: {} as SurchargeType,
    surchargeTypes: { content: [] as Array<SurchargeType> } as Pagination<SurchargeType>,
    isLoading: false,
    error: undefined
};

export const surchargeTypeSlice = createSlice({
    name: 'surchargeType',

    initialState,
    reducers: {
        fetchSurchargeTypes: (state: SurchargeTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchSurchargeTypesSuccess: (state: SurchargeTypeState, action: PayloadAction<Pagination<SurchargeType>>) => {
            state.isLoading = false;
            state.surchargeTypes = action.payload;
        },
        fetchSurchargeTypesError: (state: SurchargeTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchSurchargeTypeById: (state: SurchargeTypeState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchSurchargeTypeByIdSuccess: (state: SurchargeTypeState, action: PayloadAction<SurchargeType>) => {
            state.isLoading = false;
            state.surchargeType = action.payload;
        },
        fetchSurchargeTypeByIdError: (state: SurchargeTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchSurchargeTypeById, fetchSurchargeTypeByIdError, fetchSurchargeTypeByIdSuccess,
    fetchSurchargeTypes, fetchSurchargeTypesError, fetchSurchargeTypesSuccess } = surchargeTypeSlice.actions;

export const isLoadingSurchargeType = (state: RootState): boolean => state.surchargeTypeReducer.isLoading;
export const getSurchargeTypes = (state: RootState): Pagination<SurchargeType> => state.surchargeTypeReducer.surchargeTypes;
export const getSurchargeType = (state: RootState): SurchargeType => state.surchargeTypeReducer.surchargeType;

export default surchargeTypeSlice.reducer;