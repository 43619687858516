import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import Paging from '../../interfaces/common/paging';
import { List } from 'immutable';
import { fetchCustomers, getCustomers, isLoadingCustomers } from '../../reducers/customerReducer';
import CustomerFilters from '../../interfaces/output/filters';
import CustomerList from '../customers/customerList';
import WorkflowUtils from '../../utils/workflowUtils';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddCustomerAgreementStep2Props {
    customerAgreements: List<number>;
    onChange: any;
}

const AddCustomerAgreementStep2 = forwardRef((props: AddCustomerAgreementStep2Props, ref: any): JSX.Element => {
    const { onChange } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const customers = useSelector(getCustomers);
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    useEffect(() => {
        dispatch(fetchCustomers({ paging,
            filters: {} as CustomerFilters }));
    }, [dispatch]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        country: false,
        priorityRank: false,
        status: false,
        revenueThisYear: false,
        revenueLastYear: false
    });
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    
    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchCustomers({
                paging: newServerPagination,
                filters: { name: searchTerm } as CustomerFilters
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };
    
    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchCustomers({
                        paging: paging,
                        filters: { name: searchTerm } as CustomerFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <CustomerList
                    multipleSelection
                    isLoading={propsIsLoadingCustomers}
                    rows={customers}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (event: any) => setSearchTerm(event.target.value),
                            clearSearch: () => setSearchTerm('')
                        }
                    }}
                    hiddenColumns={['country', 'priorityRank', 'status', 'revenueThisYear', 'revenueLastYear']}
                    checkSelection={(value: number) => onChange(value)}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchCustomers({
                            paging: newPage,
                            filters: { name: searchTerm } as CustomerFilters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddCustomerAgreementStep2;