import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import FactorSetCalendarLineOut from '../interfaces/output/factorSetCalendarLineOut';
import FactorSetCalendarLineIn from '../interfaces/input/factorSetCalendarLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetCalendarVersionApi {
    static fetchFactorSetCalendarVersionById = async(factorSetVersionId: string): Promise<FactorSetVersionOut> => {
        const { data } = await http.get<FactorSetVersionOut>(`factorsets/calendar/versions/${factorSetVersionId}`);

        return data;
    };

    static fetchFactorSetCalendarVersions = async(factorId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/calendar/${factorId}/versions`);
        const { data } = await http.get<Array<FactorSetVersionOut>>(url);

        return data;
    };

    static createFactorSetCalendarVersionLines= async(versionLine: FactorSetCalendarLineOut): Promise<FactorSetCalendarLineOut> => {
        const newLine: FactorSetCalendarLineIn = {
            name: versionLine.name,
            fromDate: versionLine.fromDate,
            toDate: versionLine.toDate,
            unitTypeId: versionLine.unitType?.id,
            factor: versionLine.factor,
            surcharge: versionLine.surcharge
        };

        const { data } = await http.post<FactorSetCalendarLineIn, FactorSetCalendarLineOut>( `factorsets/calendar/versions/${versionLine.factorSetVersionId}/lines`, newLine);

        return data;
    }

    static updateFactorSetCalendarVersionStatus = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/calendar/versions/${factorSetVersion.id}/status/${factorSetVersion.status?.id}`);

        return data;
    }

    static updateFactorSetCalendarVersion = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSetVersion.validFromDate,
            validToDate: factorSetVersion.validToDate,
            statusId: factorSetVersion.status?.id
        };
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/calendar/versions/${factorSetVersion.id}`, newVersion);

        return data;
    }

    static cloneFactorSetCalendarVersion = async(toVersionId: number, fromVersionId: number): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/calendar/versions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}