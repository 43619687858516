import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import ProductType from '../interfaces/output/productType';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type ProductTypeState = {
    productType: ProductType;
    productTypes: Pagination<ProductType>;
    isLoading: boolean;
    isCreating: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: ProductTypeState = {
    productType: {} as ProductType,
    productTypes: { content: [] as Array<ProductType> } as Pagination<ProductType>,
    isLoading: false,
    isCreating: false,
    error: undefined
};

export const productTypeSlice = createSlice({
    name: 'productType',

    initialState,
    reducers: {
        fetchProductTypes: (state: ProductTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchProductTypesSuccess: (state: ProductTypeState, action: PayloadAction<Pagination<ProductType>>) => {
            state.isLoading = false;
            state.productTypes = action.payload;
        },
        fetchProductTypesError: (state: ProductTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchProductTypeById: (state: ProductTypeState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchProductTypeByIdSuccess: (state: ProductTypeState, action: PayloadAction<ProductType>) => {
            state.isLoading = false;
            state.productType = action.payload;
        },
        fetchProductTypeByIdError: (state: ProductTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createProductType: (state: ProductTypeState, _action: PayloadAction<ProductType>) => {
            state.isCreating = true;
        },
        createProductTypeSuccess: (state: ProductTypeState, action: PayloadAction<ProductType>) => {
            state.isCreating = false;
            state.productType = action.payload;
        },
        createProductTypeError: (state: ProductTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        }
    }
});

export const { fetchProductTypes, fetchProductTypesSuccess, fetchProductTypesError, fetchProductTypeById, fetchProductTypeByIdSuccess, fetchProductTypeByIdError,
    createProductType, createProductTypeSuccess, createProductTypeError } = productTypeSlice.actions;

export const isLoadingProductTypes = (state: RootState): boolean => state.productTypeReducer.isLoading;
export const isCreating = (state: RootState): boolean => state.productTypeReducer.isCreating;
export const getProductTypes = (state: RootState): Pagination<ProductType> => state.productTypeReducer.productTypes;
export const getProductType = (state: RootState): ProductType => state.productTypeReducer.productType;

export default productTypeSlice.reducer;