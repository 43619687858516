import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Paper } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import OrderUnitService from '../../interfaces/output/orderUnitService';
import {
    creditOrderUnitService, fetchOrderUnitServicesById, fetchProductsByOrderUnitServiceId, getOrderUnitService, getUnitServiceProducts, isCrediting, isLoadingUnitService,
    isLoadingUnitServiceProducts, resetOrderUnitService
} from '../../reducers/unitServiceReducer';
import ViewOrderUnitService from './viewOrderUnitService';
import OrderUnitServiceProduct from '../../interfaces/output/orderUnitServiceProduct';
import Pagination from '../../interfaces/common/pagination';
import LanguageUtils from '../../utils/LanguageUtils';
import Paging from '../../interfaces/common/paging';
import OrderUnitServiceProductsList from './orderUnitServiceProducts/orderUnitServiceProductList';
import { fetchOrderById, getOrder, isLoadingOrder } from '../../reducers/orderReducer';
import Order from '../../interfaces/output/order';
import { StatusCodes } from '../../constants/statusConstants';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AddCreditModal from '../common/addCreditModel';
import CustomerReport from '../../interfaces/output/customerReport';
import { fetchCustomerReport, getCustomerReport, isLoadingCustomerReport } from '../../reducers/invoiceReducer';
import DetailedHistoryFilter from '../../interfaces/filters/detailedHistoryFilter';
import DetailedHistoryFilterComponent from '../common/detailedHistoryFilterComponent';
import CustomerReportList from '../common/customerReportList';

interface IOrderUnitServiceDetailsProps {
    classes: any;
    theme: Theme;
    fetchOrderUnitServicesById: any;
    creditOrderUnitService: any;
    resetOrderUnitService: any;
    orderUnitService: OrderUnitService;
    orderUnitServiceProducts: Pagination<OrderUnitServiceProduct>;
    match: any;
    history: any;
    location: any;
    fetchProductsByOrderUnitServiceId: any;
    isLoadingUnitService: boolean;
    fetchOrderById: any;
    isLoadingOrder: boolean;
    isLoadingUnitServiceProducts: boolean;
    isCrediting: boolean;
    order: Order;
    customerReport: Pagination<CustomerReport>;
    isLoadingCustomerReport: boolean;
    fetchCustomerReport: (filters: DetailedHistoryFilter, paging: Paging) => void;
}

interface IOrderUnitServiceDetailsState {
    orderUnitService: OrderUnitService;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    showAddForm: boolean;
    pagingCustomerReport: Paging;
    sortModelCustomerReport: GridSortModel;
    detailedHistoryFilter: DetailedHistoryFilter;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrderUnitServicesById: (id: number) => dispatch(fetchOrderUnitServicesById(id)),
    fetchProductsByOrderUnitServiceId: (unitServiceId: number, paging: Paging) => dispatch(fetchProductsByOrderUnitServiceId({
        id: unitServiceId,
        paging
    })),
    fetchOrderById: (orderId: number) => dispatch(fetchOrderById(orderId)),
    resetOrderUnitService: () => dispatch(resetOrderUnitService()),
    creditOrderUnitService: (id: number, createNewCredit: boolean, creditReason: string) => dispatch(creditOrderUnitService({
        id,
        createNewCredit,
        creditReason
    })),
    fetchCustomerReport: (filter: DetailedHistoryFilter, paging: Paging) => dispatch(fetchCustomerReport({
        filter,
        paging
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        order: getOrder(store),
        orderUnitService: getOrderUnitService(store),
        orderUnitServiceProducts: getUnitServiceProducts(store),
        isLoadingUnitService: isLoadingUnitService(store),
        isLoadingUnitServiceProducts: isLoadingUnitServiceProducts(store),
        isLoadingOrder: isLoadingOrder(store),
        isCrediting: isCrediting(store),
        isLoadingCustomerReport: isLoadingCustomerReport(store),
        customerReport: getCustomerReport(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

const messages = {
    add: LanguageUtils.createMessage('Add'),
    edit: LanguageUtils.createMessage('Edit'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    subname: LanguageUtils.createMessage('ORDER_UNIT_SERVICE'),
    properties: LanguageUtils.createMessage('Properties'),
    products: LanguageUtils.createMessage('Products'),
    credit: LanguageUtils.createMessage('Credit'),
    detailedHistory: LanguageUtils.createMessage('Detailed history')
};

class OrderUnitServiceDetails extends Component<IOrderUnitServiceDetailsProps, IOrderUnitServiceDetailsState> {
    customerReportListRef: any;
    constructor(props: IOrderUnitServiceDetailsProps) {
        super(props);
        this.customerReportListRef = React.createRef();

        this.state = {
            orderUnitService: {} as OrderUnitService,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.products,
                icon: <FontAwesomeIcon icon="cubes" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/products`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/products`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.detailedHistory,
                icon: <FontAwesomeIcon icon={['fab', 'microsoft']} size="1x" transform="grow-4" />,
                url: `/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/customerReport`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNIT_SERVICES}/${props.match.params.orderUnitServiceId}/customerReport`, props.location.pathname)
            }
            ]),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {},
            showAddForm: false,
            pagingCustomerReport: PageUtils.getDefaultPaging(),
            sortModelCustomerReport: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            detailedHistoryFilter: {
            } as DetailedHistoryFilter
        };
    }

    componentDidMount() {
        const { fetchOrderUnitServicesById, fetchCustomerReport } = this.props;
        const { orderUnitServiceId } = this.props.match.params;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;

        fetchOrderUnitServicesById(orderUnitServiceId);

        const filter = {
            ...detailedHistoryFilter,
            orderUnitServiceId: orderUnitServiceId
        } as DetailedHistoryFilter;
        fetchCustomerReport(filter, pagingCustomerReport);
    }

    componentWillUnmount() {
        const { resetOrderUnitService } = this.props;

        resetOrderUnitService();
    }

    componentDidUpdate(prevProps: IOrderUnitServiceDetailsProps) {
        const { orderUnitService, isLoadingUnitService, fetchOrderById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(orderUnitService !== prevProps.orderUnitService) {
            this.setState({ orderUnitService });
            if(orderUnitService.orderUnit?.orderId) {
                fetchOrderById(orderUnitService.orderUnit.orderId);
            }
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && isLoadingUnitService === false && prevProps.isLoadingUnitService === true) {
            this.loadData(selectedMenuItem);
        }
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchProductsByOrderUnitServiceId } = this.props;
        const { orderUnitServiceId } = this.props.match.params;
        const paging = PageUtils.getDefaultPaging();

        if(selectedMenuItem?.id === 2) {
            fetchProductsByOrderUnitServiceId(orderUnitServiceId, paging);
        }
    }
    // creditOrderUnitService = () => {
    //     const { orderUnitServiceId } = this.props.match.params;
    //     const { creditOrderUnitService } = this.props;

    //     creditOrderUnitService(orderUnitServiceId, true);
    // };

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { orderUnitServiceId } = this.props.match.params;
        const { orderUnitService } = this.props;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceId}/edit`,
                        icon: 'edit',
                        text: messages.edit,
                        isHidden: (orderUnitService?.ousStatus?.code === StatusCodes.Invoiced || orderUnitService?.ousStatus?.code === StatusCodes.Credited)
                    },
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceId}/status`,
                        icon: 'retweet',
                        text: messages.changeStatus
                    },
                    {
                        icon: 'credit-card',
                        text: messages.credit,
                        onClick: this.toggleAddModal
                    }
                ];
            }

            case 2: { //services
                return [
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceId}/orderunitserviceproduct/add`,
                        icon: 'plus',
                        text: messages.add
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    _onSortModelChange = (newModel: GridSortModel) => {
        const { fetchProductsByOrderUnitServiceId } = this.props;
        const { serverPagination, sortModel } = this.state;
        const { orderUnitServiceId } = this.props.match.params;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                serverPagination: newPagination
            });

            fetchProductsByOrderUnitServiceId(orderUnitServiceId, newPagination);
        }
    }

    getSelectedMenuItem = (state: IOrderUnitServiceDetailsState) => state.menuItems.find(item => item.isSelected);

    applyDetaildHistoryFilters = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;
        const { orderUnitServiceId } = this.props.match.params;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };
        const filter = {
            ...detailedHistoryFilter,
            orderUnitServiceId: orderUnitServiceId
        } as DetailedHistoryFilter;

        this.setState({
            detailedHistoryFilter: filter,
            pagingCustomerReport: newPagination
        });

        fetchCustomerReport(filter, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    setDetaildHistoryFiltersDefaultState = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport } = this.state;
        const { orderUnitServiceId } = this.props.match.params;

        const newFilters = {
            orderUnitServiceId: orderUnitServiceId
        } as DetailedHistoryFilter;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            pagingCustomerReport: newPagination,
            detailedHistoryFilter: newFilters
        });

        fetchCustomerReport(newFilters, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    onDetaildHistoryFiltersChange = (attribute: string, value: any) => {
        const { detailedHistoryFilter, pagingCustomerReport } = this.state;

        const newFilter = { ...detailedHistoryFilter } as DetailedHistoryFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };
        this.setState({
            detailedHistoryFilter: newFilter,
            pagingCustomerReport: newPagination
        });
    };

    _onSortChangeCustomerReport = (newModel: GridSortModel) => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, sortModelCustomerReport, detailedHistoryFilter } = this.state;
        const { orderUnitServiceId } = this.props.match.params;

        const newFilters = {
            ...detailedHistoryFilter,
            orderUnitServiceId: orderUnitServiceId
        } as DetailedHistoryFilter;

        if(JSON.stringify(sortModelCustomerReport) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...pagingCustomerReport,
                sort: newModel
            };
            this.setState({
                sortModelCustomerReport: newModel,
                pagingCustomerReport: newPagination,
                detailedHistoryFilter: newFilters
            });

            fetchCustomerReport(newFilters, newPagination);
        }
    }

    toggleAddModal = () => {
        this.setState({ showAddForm: !this.state.showAddForm });
    }

    render() {
        const { classes, orderUnitService, isLoadingUnitService, isLoadingUnitServiceProducts, orderUnitServiceProducts, order,
            isLoadingOrder, fetchProductsByOrderUnitServiceId, fetchCustomerReport, isLoadingCustomerReport, customerReport } = this.props;
        const { menuItems, serverPagination, columnVisibilityModel, showAddForm, detailedHistoryFilter, pagingCustomerReport } = this.state;
        const { orderUnitServiceId } = this.props.match.params;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingUnitService || isLoadingOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.OrderUnitService}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: orderUnitService.service?.type?.name,
                    icon: <FontAwesomeIcon icon="dolly" size="1x" />
                } as IMenuItem}
                routes={
                    [{
                        name: order.orderNumber,
                        url: `${UrlConstants.ORDERS}/${order.id}`
                    }, {
                        name: orderUnitService.orderUnit?.unitNo,
                        url: `${UrlConstants.ORDER_UNITS}/${orderUnitService.orderUnit?.id}`
                    }]
                }
            >
                <Paper>
                    <Container className={classes.container} maxWidth="xl">
                        <Switch>
                            <Route path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId/properties`}>
                                <ViewOrderUnitService orderUnitService={orderUnitService} />
                            </Route>
                            <Route path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId/products`}>
                                <OrderUnitServiceProductsList isLoading={isLoadingUnitServiceProducts}
                                    rows={orderUnitServiceProducts}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...serverPagination,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchProductsByOrderUnitServiceId(orderUnitServiceId, newPage);
                                        this.setState({ serverPagination: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Route>
                            <Route path={`/${UrlConstants.ORDER_UNIT_SERVICES}/:orderUnitServiceId/customerReport`}>
                                <>
                                    <DetailedHistoryFilterComponent applyFilters={this.applyDetaildHistoryFilters}
                                        setDefaultState={this.setDetaildHistoryFiltersDefaultState} filters={detailedHistoryFilter} onChange={this.onDetaildHistoryFiltersChange} />
                                    <CustomerReportList isLoading={isLoadingCustomerReport}
                                        rows={customerReport}
                                        ref={this.customerReportListRef}
                                        onPageChange={(nextPage: number, nextSize: number) => {
                                            const newPage = {
                                                ...pagingCustomerReport,
                                                page: nextPage,
                                                size: nextSize
                                            };
                                            const newFilter = {
                                                ...detailedHistoryFilter,
                                                orderUnitServiceId: orderUnitServiceId
                                            } as DetailedHistoryFilter;

                                            fetchCustomerReport(newFilter,newPage);

                                            this.setState({
                                                pagingCustomerReport: newPage,
                                                detailedHistoryFilter: newFilter
                                            });
                                        }}
                                        onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeCustomerReport(sortModel)}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                            this.setState({
                                                columnVisibilityModel: newModel
                                            })
                                        }
                                    />
                                </>
                            </Route>
                        </Switch>
                    </Container>
                    <AddCreditModal show={showAddForm} onClose={this.toggleAddModal} id={orderUnitServiceId} createCredit={creditOrderUnitService} />
                </Paper>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrderUnitServiceDetails));