import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetCancellationVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetCancellationVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerCancellationVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchCancellationVersionById: (state: FactorSetCancellationVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchCancellationVersionByIdSuccess: (state: FactorSetCancellationVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchCancellationVersionByIdError: (state: FactorSetCancellationVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCancellationVersions: (state: FactorSetCancellationVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchCancellationVersionsSuccess: (state: FactorSetCancellationVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchCancellationVersionsError: (state: FactorSetCancellationVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updateCancellationVersionStatus: (state: FactorSetCancellationVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updateCancellationVersionStatusSuccess: (state: FactorSetCancellationVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updateCancellationVersionStatusError: (state: FactorSetCancellationVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updateCancellationVersion: (state: FactorSetCancellationVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updateCancellationVersionSuccess: (state: FactorSetCancellationVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updateCancellationVersionError: (state: FactorSetCancellationVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneCancellationVersion: (state: FactorSetCancellationVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        cloneCancellationVersionSuccess: (state: FactorSetCancellationVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        cloneCancellationVersionError: (state: FactorSetCancellationVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetCancellationVersion: (state: FactorSetCancellationVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetCancellationVersions: (state: FactorSetCancellationVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchCancellationVersionById, fetchCancellationVersionByIdSuccess, fetchCancellationVersionByIdError, updateCancellationVersionStatus, updateCancellationVersionStatusSuccess,
    updateCancellationVersionStatusError, cloneCancellationVersion, updateCancellationVersion, cloneCancellationVersionSuccess, fetchCancellationVersions, fetchCancellationVersionsSuccess,
    fetchCancellationVersionsError, updateCancellationVersionSuccess, updateCancellationVersionError, resetCancellationVersion, cloneCancellationVersionError,
    resetCancellationVersions }
    = customerCancellationVersionSlice.actions;

export const isLoadingCancellationVersion = (state: RootState): boolean => state.factorSetCancellationVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetCancellationVersionReducer.isCloningVersion;
export const isUpdatingCancellationVersionStatus = (state: RootState): boolean => state.factorSetCancellationVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetCancellationVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetCancellationVersionReducer.isUpdatingVersion;
export const getCancellationVersion = (state: RootState): FactorSetVersionOut => state.factorSetCancellationVersionReducer.factorSetVersion;
export const isLoadingFactorSetCancellationVersions = (state: RootState): boolean => state.factorSetCancellationVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetCancellationVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetCancellationVersionReducer.factorSetVersions;

export default customerCancellationVersionSlice.reducer;