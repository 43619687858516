import inputLengthConstants from '../constants/inputLengthConstants';

class NumberUtils {
    static isNullOrWhitespace(input: number | undefined): boolean {
        if(input === null) {
            return true;
        }
        if(input === undefined || input.toString().trim().length === 0) {
            return true;
        }

        const number = Number(input);
        const validationResult = !isNaN(number) ? !(number >= 0 && number <= inputLengthConstants.StringMax) : true;

        return validationResult;
    }
}

export default NumberUtils;
