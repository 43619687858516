import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceOut from '../interfaces/output/invoiceOut';
import InvoiceLineOut from '../interfaces/output/invoiceLineOut';
import InvoiceFilters from '../interfaces/filters/invoiceFilters';
import InvoiceLineFiltersIn from '../interfaces/filters/invoiceLineFilters';
import InvoiceApi from '../api/invoiceApi';
import {
    createInvoiceError, createInvoiceSuccess, creditInvoiceError, creditInvoiceSuccess, deactivateInvoiceError, deactivateInvoiceSuccess, exportInvoicesError, exportInvoicesSuccess,
    fetchInvoiceByIdError, fetchInvoiceByIdSuccess, fetchInvoiceLinesError, fetchInvoiceLinesSuccess, fetchInvoicesError, fetchInvoicesSuccess, sendInvoiceEmailError, sendInvoiceEmailSuccess,
    updateInvoiceError, updateInvoiceSuccess, exportInvoicesPdfError, exportInvoicesPdfSuccess, fetchCustomerReportSuccess, fetchCustomerReportError
} from '../reducers/invoiceReducer';
import FileSaver from 'file-saver';
import CustomerReport from '../interfaces/output/customerReport';
import DetailedHistoryFilter from '../interfaces/filters/detailedHistoryFilter';

function* doFetchInvoice(action: PayloadAction<number>) {
    const invoiceId = action.payload;

    try {
        const response: InvoiceOut = yield call(InvoiceApi.fetchInvoiceById, invoiceId);

        yield put(fetchInvoiceByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceByIdError(error as HttpErrorResponse));
    }
}

function* doFetchInvoices(action: PayloadAction<{ paging: Paging, filters: InvoiceFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<InvoiceOut> = yield call(InvoiceApi.fetchInvoices, paging, filters);

        yield put(fetchInvoicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoicesError(error as HttpErrorResponse));
    }
}

function* doFetchInvoiceLines(action: PayloadAction<{ invoiceId: number, paging: Paging, filters: InvoiceLineFiltersIn }>) {
    const { invoiceId, paging, filters } = action.payload;

    try {
        const response: Pagination<InvoiceLineOut> = yield call(InvoiceApi.fetchInvoiceLines, invoiceId, paging, filters);

        yield put(fetchInvoiceLinesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceLinesError(error as HttpErrorResponse));
    }
}

function* doCreateInvoice(action: PayloadAction<InvoiceOut>) {
    const factorSet = action.payload;

    try {
        const response: InvoiceOut = yield call(InvoiceApi.createInvoice, factorSet);

        yield put(createInvoiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(createInvoiceError(error as HttpErrorResponse));
    }
}

function* doUpdateInvoice(action: PayloadAction<InvoiceOut>) {
    const factorSet = action.payload;

    try {
        const response: InvoiceOut = yield call(InvoiceApi.updateInvoice, factorSet);

        yield put(updateInvoiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateInvoiceError(error as HttpErrorResponse));
    }
}

function* doDeactivateInvoice(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: InvoiceOut = yield call(InvoiceApi.deactivateInvoice, id);

        yield put(deactivateInvoiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateInvoiceError(error as HttpErrorResponse));
    }
}

function* doCreditInvoice(action: PayloadAction<{ id: number, createNewCredit: boolean, creditReason: string }>) {
    const { id, createNewCredit, creditReason } = action.payload;

    try {
        const response: InvoiceOut = yield call(InvoiceApi.creditInvoice, id, createNewCredit, creditReason);

        yield put(creditInvoiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(creditInvoiceError(error as HttpErrorResponse));
    }
}

function* doExportInvoice(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: Blob = yield call(InvoiceApi.exportInvoice, id);
        FileSaver.saveAs(response, 'invoice-download.csv');

        yield put(exportInvoicesSuccess());
    }

    catch (error: unknown) {
        yield put(exportInvoicesError(error as HttpErrorResponse));
    }
}

function* doSendInvoiceEmail(action: PayloadAction<{id:number, emailAddress: string| undefined}>) {
    const { id, emailAddress } = action.payload;

    try {
        yield call(InvoiceApi.sendInvoiceEmail, id, emailAddress);

        yield put(sendInvoiceEmailSuccess());
    }

    catch (error: unknown) {
        yield put(sendInvoiceEmailError(error as HttpErrorResponse));
    }
}

function* doExportInvoicePdf(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: Blob = yield call(InvoiceApi.exportInvoicePdf, id);
        FileSaver.saveAs(response, 'invoice-download.pdf');

        yield put(exportInvoicesPdfSuccess());
    }

    catch (error: unknown) {
        yield put(exportInvoicesPdfError(error as HttpErrorResponse));
    }
}

function* doFetchCustomerReport(action: PayloadAction<{ filter: DetailedHistoryFilter, paging: Paging}>) {
    const { filter, paging } = action.payload;

    try {
        const response: Pagination<CustomerReport> = yield call(InvoiceApi.fetchCustomerReport, filter, paging);

        yield put(fetchCustomerReportSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerReportError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoice/fetchInvoiceById', doFetchInvoice),
    takeLatest('invoice/fetchInvoices', doFetchInvoices),
    takeLatest('invoice/fetchInvoiceLines', doFetchInvoiceLines),
    takeLatest('invoice/createInvoice', doCreateInvoice),
    takeLatest('invoice/updateInvoice', doUpdateInvoice),
    takeLatest('invoice/deactivateInvoice', doDeactivateInvoice),
    takeLatest('invoice/creditInvoice', doCreditInvoice),
    takeLatest('invoice/exportInvoices', doExportInvoice),
    takeLatest('invoice/sendInvoiceEmail', doSendInvoiceEmail),
    takeLatest('invoice/exportInvoicesPdf', doExportInvoicePdf),
    takeLatest('invoice/fetchCustomerReport', doFetchCustomerReport)
];