import Paging from '../interfaces/common/paging';
import LanguageUtils from './LanguageUtils';
import { GridSortItem } from '@mui/x-data-grid';

class PageUtils {
    static isPageSelected = (name: string | undefined, url: string): boolean => name !== undefined && url.indexOf(name) !== -1;

    static getDefaultPaging = (sort: Array<GridSortItem> = []): Paging => {
        return {
            page: 0,
            size: 10,
            sort
        } as Paging;
    }

    static getCustomPaging = (custom_size: number, sort: Array<GridSortItem> = []): Paging => {
        return {
            page: 0,
            size: custom_size,
            sort: sort
        } as Paging;
    }

    static getMinPaging = (): Paging => {
        return {
            page: 0,
            size: 5,
            sort: []
        } as Paging;
    }

    static getMaxPaging = (sort: Array<GridSortItem> = []): Paging => {
        return {
            page: 0,
            size: 500,
            sort: sort
        };
    }

    static getStepNames = (): Array<any> => {
        return Array.from(Array(10).keys()).map((item) => LanguageUtils.createMessage(`Step ${item}`));
    }
}

export default PageUtils;