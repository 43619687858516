import { List } from 'immutable';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import ValidationModel from '../../../../utils/validationModel';
import { ensure } from '../../../../utils/arrayUtils';
import ProductArena from '../../../../interfaces/output/productArena';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class ProductArenaStep1Validation extends ValidationUtils {
        
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validatProductArena(productArena: ProductArena): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { nameEng,
            code,
            unitGroup,
            productArenaType
        } = productArena;

        const requiredFields =
            this.validateInputString(nameEng) &&
            this.validateInputString(code) &&
            this.validateInputNumber(unitGroup?.id) &&
            this.validateInputNumber(productArenaType?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
