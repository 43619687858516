import AgreementVersionStatusApi from '../api/agreementVersionStatusApi';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import AgreementVersionStatus from '../interfaces/output/agreementVersionStatus';
import { deactivateAgreementVersionStatusError, deactivateAgreementVersionStatusSuccess, fetchAgreementVersionStatusByIdError,
    fetchAgreementVersionStatusByIdSuccess, fetchAgreementVersionStatusesError, fetchAgreementVersionStatusesSuccess,
    updateAgreementVersionStatusError, updateAgreementVersionStatusSuccess } from '../reducers/agreementVersionStatusReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchAgreementVersionStatus(action: PayloadAction<string>) {
    const agreementVersionStatusId = action.payload;
    try {
        const response: AgreementVersionStatus = yield call(AgreementVersionStatusApi.fetchAgreementVersionStatusById, agreementVersionStatusId);
    
        yield put(fetchAgreementVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchAgreementVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<AgreementVersionStatus> = yield call(AgreementVersionStatusApi.fetchAgreementVersionStatuses, paging);
    
        yield put(fetchAgreementVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateAgreementVersionStatus(action: PayloadAction<AgreementVersionStatus>) {
    const agreementVersionStatus = action.payload;

    try {
        const response: AgreementVersionStatus = yield call(AgreementVersionStatusApi.updateAgreementVersionStatus, agreementVersionStatus);

        yield put(updateAgreementVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateAgreementVersionStatus(action: PayloadAction<AgreementVersionStatus>) {
    const agreementVersionStatus = action.payload;
    
    try {
        const response: AgreementVersionStatus = yield call(AgreementVersionStatusApi.deactivateAgreementVersionStatus, agreementVersionStatus);

        yield put(deactivateAgreementVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateAgreementVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('agreementVersionStatus/fetchAgreementVersionStatusById', doFetchAgreementVersionStatus),
    takeLatest('agreementVersionStatus/fetchAgreementVersionStatuses', doFetchAgreementVersionStatuses),
    takeLatest('agreementVersionStatus/updateAgreementVersionStatus', doUpdateAgreementVersionStatus),
    takeLatest('agreementVersionStatus/deactivateAgreementVersionStatus', doDeactivateAgreementVersionStatus)
];