import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import TableUtils from '../../utils/tableUtils';
import DataGridComponent from '../common/dataGrid';
import DisplayStatus from '../common/displayStatus';
import UrlConstants from '../../constants/UrlConstants';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomStringLink from '../common/customStringLink';
import CustomLink from '../common/customLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';

interface InvoiceListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
}

const messages = {
    invoiceNumber: LanguageUtils.createMessage('Invoice number'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    customerName: LanguageUtils.createMessage('Customer'),
    status: LanguageUtils.createMessage('Status'),
    invoiceDate: LanguageUtils.createMessage('Invoice date'),
    paymentDeadline: LanguageUtils.createMessage('Payment deadline'),
    currency: LanguageUtils.createMessage('Currency'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    d365_invoiceNumber: LanguageUtils.createMessage('D365 Invoice number')
};

const InvoiceList = forwardRef((props: InvoiceListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.CUSTOMER_INVOICES_MAIN);
        }
    }, []);

    const intl = useIntl();

    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'invoiceNo',
                headerName: `${intl.formatMessage(messages.invoiceNumber)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).invoiceNumber} link={`/#/${UrlConstants.INVOICES}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('invoiceNumber', hiddenColumns)
            },
            {
                field: 'd365_invoiceNumber',
                headerName: `${intl.formatMessage(messages.d365_invoiceNumber)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).d365_InvoiceNumber ?? '-'} link={`/#/${UrlConstants.INVOICES}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('d365_invoiceNumber', hiddenColumns)
            },
            {
                field: 'invoiceType_name',
                headerName: `${intl.formatMessage(messages.invoiceType)}`,
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).invoiceType?.name ?? '-'}
                    link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('invoiceType', hiddenColumns)
            },
            {
                field: 'customerName',
                headerName: `${intl.formatMessage(messages.customerName)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.customer?.name ?? '-'}
                    link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('customerName', hiddenColumns)
            },
            {
                field: 'invoiceStatus',
                headerName: `${intl.formatMessage(messages.status)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`}>
                    <DisplayStatus status={applyRowFn(params)?.invoiceStatus} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            {
                field: 'invoiceDate',
                headerName: `${intl.formatMessage(messages.invoiceDate)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`}>
                    <NullableFormattedDate value={applyRowFn(params)?.invoiceDate} />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('invoiceDate', hiddenColumns)
            },
            {
                field: 'paymentDeadline',
                headerName: `${intl.formatMessage(messages.paymentDeadline)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink value={applyRowFn(params).paymentDeadline?.name ?? '-'} link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('paymentDeadline', hiddenColumns)
            },
            {
                field: 'currency_name',
                headerName: `${intl.formatMessage(messages.currency)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).currency?.name ?? '-'}
                    link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('currency_name', hiddenColumns)
            },
            {
                field: 'totalPrice',
                headerName: `${intl.formatMessage(messages.totalPrice)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).totalPrice ?? '-'}
                    link={`/#/${UrlConstants.INVOICES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('totalPrice', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default InvoiceList;
