import InvoiceSeparationApi from '../api/invoiceSeparationApi';
import { fetchInvoiceSeparationByIdError, fetchInvoiceSeparationByIdSuccess, fetchInvoiceSeparationsError, fetchInvoiceSeparationsSuccess } from '../reducers/invoiceSeparationReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import InvoiceSeparation from '../interfaces/output/invoiceSeparation';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchInvoiceSeparationById(action: PayloadAction<string>) {
    const invoiceId = action.payload;
    try {
        const response: InvoiceSeparation = yield call(InvoiceSeparationApi.fetchInvoiceSeparationById, invoiceId);
        yield put(fetchInvoiceSeparationByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceSeparationByIdError(error as HttpErrorResponse));
    }
}

function* doFetchInvoicesSeparation() {
    try {
        const response: Pagination<InvoiceSeparation> = yield call(InvoiceSeparationApi.fetchInvoiceSeparations);
        yield put(fetchInvoiceSeparationsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceSeparationsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoiceSeparation/fetchInvoiceSeparationById', doFetchInvoiceSeparationById),
    takeLatest('invoiceSeparation/fetchInvoiceSeparations', doFetchInvoicesSeparation)];

