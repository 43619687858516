import Customer from '../../interfaces/output/customer';
import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import ViewCustomer from './viewCustomer';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { fetchCustomerById, getCustomer, isDeletingCustomer, isLoadingCustomer, resetCustomer, processCreditInvoices } from '../../reducers/customerReducer';
import IMenuItem from '../../interfaces/common/menuItem';
import CustomerAgreementFilters from '../../interfaces/filters/customerAgreementFilters';
import Paging from '../../interfaces/common/paging';
import {
    deactivateCustomerAgreement, demoteCustomerAgreement, fetchCustomerAgreements, getCustomerAgreements, isCreatingCustomerAgreement, isDeletingCustomerAgreement, isLoadingCustomerAgreement,
    isUpdatingCustomerAgreement, promoteCustomerAgreement, resetCustomerAgreements
} from '../../reducers/customerAgreementReducer';
import CustomerAgreement from '../../interfaces/output/customerAgreement';
import Pagination from '../../interfaces/common/pagination';
import { getAgreement } from '../../reducers/agreementsReducer';
import Agreement from '../../interfaces/output/agreement';
import MainLayout from '../common/widgets/mainLayout';
import Action from '../../interfaces/common/action';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import AgreementList from '../agreements/agreementList';
import OrderFilters from '../../interfaces/filters/orderFilters';
import { fetchOrders, getOrders, isLoadingOrders } from '../../reducers/orderReducer';
import OrdersList from '../orders/ordersList';
import Order from '../../interfaces/output/order';
import LanguageUtils from '../../utils/LanguageUtils';
import OrdersFilterComponent from '../orders/ordersFilterComponent';
import CustomerAgreementFiltersComponent from '../common/customerAgreementsFilter';
import Loader from '../common/widgets/loader';
import { fetchInvoices, getInvoices, isLoadingInvoices } from '../../reducers/invoiceReducer';
import InvoiceFilters from '../../interfaces/filters/invoiceFilters';
import InvoiceOut from '../../interfaces/output/invoiceOut';
import InvoiceFilterComponent from '../invoices/invoiceFilterComponent';
import InvoiceList from '../invoices/invoiceList';
import { fetchHelptextById, getHelptext } from '../../reducers/helptextReducer';
import HelptextOut from '../../interfaces/output/helptextOut';
import DateTimeUtils from '../../utils/dateTimeUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IViewCustomerDetailsProps {
    classes: any;
    theme: Theme;
    fetchCustomerById: any;
    customer: Customer;
    agreement: Agreement;
    helptext: HelptextOut;
    match: any;
    customerAgreements: Pagination<CustomerAgreement>;
    orders: Pagination<Order>;
    invoices: Pagination<InvoiceOut>;
    fetchCustomerAgreements: any;
    fetchHelptextById: any;
    history: any;
    location: any;
    promoteCustomerAgreement: any;
    demoteCustomerAgreement: any;
    deactivateCustomerAgreement: any;
    fetchInvoices: any;
    fetchOrders: any;
    isUpdatingCustomerAgreement: boolean;
    isDeletingCustomerAgreement: boolean;
    isCreatingCustomerAgreement: boolean;
    isLoading: boolean;
    isLoadingOrders: boolean;
    isLoadingCustomerAgreement: boolean;
    isLoadingCustomer: boolean;
    isDeletingCustomer: boolean;
    isLoadingInvoices: boolean;
    resetCustomerAgreements: () => void;
    resetCustomer: () => void;
    processCreditInvoices: any;
}

interface IViewCustomerDetailsState {
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverCustomerPagination: Paging;
    serverAgreementPagination: Paging;
    serverInvoicePagination: Paging;
    orderFilter: OrderFilters;
    invoiceFilter: InvoiceFilters;
    customerAgreementFilters: CustomerAgreementFilters;
    sortModelOrders: GridSortModel;
    sortModelAgreements: GridSortModel;
    sortModelInvoices: GridSortModel;
    columnAgreementVisibilityModel: GridColumnVisibilityModel;
    columnOrderVisibilityModel: GridColumnVisibilityModel;
    columnInvoiceVisibilityModel: GridColumnVisibilityModel;
    componentId? : string;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCustomerById: (id: number) => dispatch(fetchCustomerById(id)),
    fetchCustomerAgreements: (paging: Paging, filters: CustomerAgreementFilters) => dispatch(fetchCustomerAgreements({
        paging,
        filters
    })),
    promoteCustomerAgreement: (id: number) => dispatch(promoteCustomerAgreement(id)),
    demoteCustomerAgreement: (id: number) => dispatch(demoteCustomerAgreement(id)),
    deactivateCustomerAgreement: (id: number) => dispatch(deactivateCustomerAgreement(id)),
    resetCustomerAgreements: () => dispatch(resetCustomerAgreements()),
    resetCustomer: () => dispatch(resetCustomer()),
    fetchOrders: (paging: Paging, filters: OrderFilters) => dispatch(fetchOrders({
        paging,
        filters
    })),
    fetchInvoices: (paging: Paging, filters: InvoiceFilters) => dispatch(fetchInvoices({
        paging,
        filters
    })),
    fetchHelptextById: (id: string) => dispatch(fetchHelptextById(id)),
    processCreditInvoices: (id: number) => dispatch(processCreditInvoices(id))
});

const mapStoreToProps = (store: RootState) => {
    return {
        customer: getCustomer(store),
        helptext: getHelptext(store),
        customerAgreements: getCustomerAgreements(store),
        invoices: getInvoices(store),
        orders: getOrders(store),
        agreement: getAgreement(store),
        isUpdatingCustomerAgreement: isUpdatingCustomerAgreement(store),
        isDeletingCustomerAgreement: isDeletingCustomerAgreement(store),
        isCreatingCustomerAgreement: isCreatingCustomerAgreement(store),
        isLoadingCustomer: isLoadingCustomer(store),
        isDeletingCustomer: isDeletingCustomer(store),
        isLoadingOrders: isLoadingOrders(store),
        isLoadingInvoices: isLoadingInvoices(store),
        isLoading: isLoadingCustomerAgreement(store) || isUpdatingCustomerAgreement(store) || isDeletingCustomerAgreement(store) || isCreatingCustomerAgreement(store),
        isLoadingCustomerAgreement: isLoadingCustomerAgreement(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    filter: LanguageUtils.createMessage('Filter'),
    delete: LanguageUtils.createMessage('Delete'),
    promote: LanguageUtils.createMessage('Promote'),
    demote: LanguageUtils.createMessage('Demote'),
    customer: LanguageUtils.createMessage('Customer'),
    properties: LanguageUtils.createMessage('Properties'),
    agreements: LanguageUtils.createMessage('Agreements'),
    orders: LanguageUtils.createMessage('Orders'),
    invoices: LanguageUtils.createMessage('Invoices'),
    processCredit: LanguageUtils.createMessage('Process credit')
};

class ViewCustomerDetails extends Component<IViewCustomerDetailsProps, IViewCustomerDetailsState> {
    customerAgreementListRef: any;
    orderListRef: any;
    invoiceListRef: any;

    constructor(props: IViewCustomerDetailsProps) {
        super(props);
        const { customerId } = props.match.params;
        const now = new Date();
        this.customerAgreementListRef = React.createRef();

        this.state = {
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.agreements,
                icon: <FontAwesomeIcon icon="handshake" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/agreements`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/agreements`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.orders,
                icon: <FontAwesomeIcon icon="file-contract" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/orders`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/orders`, props.location.pathname)
            },
            {
                id: 4,
                name: messages.invoices,
                icon: <FontAwesomeIcon icon="file-invoice" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/invoices`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.CUSTOMERS}/${props.match.params.customerId}/invoices`, props.location.pathname)
            }]),
            serverCustomerPagination: PageUtils.getDefaultPaging(),
            serverAgreementPagination: PageUtils.getDefaultPaging(),
            serverInvoicePagination: PageUtils.getDefaultPaging(),
            orderFilter: {
                orderStatusIds: [],
                fromDate: DateTimeUtils.dateTimeGMTStart(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)),
                toDate: DateTimeUtils.dateTimeGMTEnd(now),
                customerId
            },
            customerAgreementFilters: {
                customerId,
                agreementId: null,
                validity: 1
            },
            invoiceFilter: {
                customerId
            },
            sortModelAgreements: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelOrders: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelInvoices: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnAgreementVisibilityModel: {
                agreement_revenueThisYear: false,
                agreement_revenueLastYear: false,
                agreement_versions_defaultTransitDiscount: false,
                agreement_active: false,
                agreement_validity: false
            },
            columnOrderVisibilityModel: {
                customer: false
            },
            columnInvoiceVisibilityModel: {
                customer: false
            },
            componentId: undefined
        };
    }

    componentDidMount() {
        const { fetchCustomerById } = this.props;
        const { customerId } = this.props.match.params;

        fetchCustomerById(customerId);
    }

    componentDidUpdate(prevProps: IViewCustomerDetailsProps) {
        const { isUpdatingCustomerAgreement, isDeletingCustomerAgreement, isCreatingCustomerAgreement, isLoadingCustomer } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingCustomer && prevProps.isLoadingCustomer ||
            !isUpdatingCustomerAgreement && prevProps.isUpdatingCustomerAgreement ||
            !isDeletingCustomerAgreement && prevProps.isDeletingCustomerAgreement ||
            !isCreatingCustomerAgreement && prevProps.isCreatingCustomerAgreement)) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetCustomerAgreements, resetCustomer } = this.props;
        resetCustomerAgreements();
        resetCustomer();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchCustomerAgreements, fetchOrders, fetchInvoices } = this.props;
        const { orderFilter, customerAgreementFilters, invoiceFilter } = this.state;

        const paging = PageUtils.getDefaultPaging();
        this.setState({ serverCustomerPagination: paging });
        this.setState({ serverAgreementPagination: paging });
        this.setState({ serverInvoicePagination: paging });

        if(selectedMenuItem?.id === 2) {
            fetchCustomerAgreements(paging, customerAgreementFilters);
        }

        if(selectedMenuItem?.id === 3) {
            fetchOrders(paging, orderFilter);
        }

        if(selectedMenuItem?.id === 4) {
            fetchInvoices(paging, invoiceFilter);
        }
    };

    applyInvoiceFilters = () => {
        const { fetchInvoices } = this.props;
        const { serverInvoicePagination, invoiceFilter } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverInvoicePagination,
            page: newPaging.page
        };
        this.setState({
            serverInvoicePagination: newPagination
        });

        fetchInvoices(newPagination, invoiceFilter);

        if(this.invoiceListRef !== null && this.invoiceListRef !== undefined &&
            this.invoiceListRef.current !== null && this.invoiceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.invoiceListRef.current.resetDataGridPage();
        }
    };

    setInvoiceFiltersDefaultState = () => {
        const { fetchInvoices, customer } = this.props;
        const { serverInvoicePagination } = this.state;

        const newFilters = {
            customerId: customer.id
        } as InvoiceFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverInvoicePagination,
            page: newPaging.page
        };

        this.setState({
            serverInvoicePagination: newPagination,
            invoiceFilter: newFilters
        });

        fetchInvoices(newPagination, newFilters);

        if(this.invoiceListRef !== null && this.invoiceListRef !== undefined &&
            this.invoiceListRef.current !== null && this.invoiceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.invoiceListRef.current.resetDataGridPage();
        }
    };

    onInvoiceFiltersChange = (attribute: string, value: any) => {
        const { invoiceFilter, serverInvoicePagination } = this.state;

        const newFilter = { ...invoiceFilter } as InvoiceFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverInvoicePagination,
            page: newPaging.page
        };

        this.setState({
            invoiceFilter: newFilter,
            serverInvoicePagination: newPagination
        });
    };

    applyOrderFilters = () => {
        const { fetchOrders } = this.props;
        const { serverCustomerPagination, orderFilter } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverCustomerPagination,
            page: newPaging.page
        };

        this.setState({
            serverCustomerPagination: newPagination
        });

        fetchOrders(newPagination, orderFilter);

        if(this.orderListRef !== null && this.orderListRef !== undefined &&
            this.orderListRef.current !== null && this.orderListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.orderListRef.current.resetDataGridPage();
        }
    };

    setOrderFiltersDefaultState = () => {
        const { fetchOrders, customer } = this.props;
        const { serverCustomerPagination } = this.state;

        const newFilters = {
            orderStatusIds: [],
            customerId: customer.id
        } as OrderFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverCustomerPagination,
            page: newPaging.page
        };

        this.setState({
            serverCustomerPagination: newPagination,
            orderFilter: newFilters
        });

        fetchOrders(newPagination, newFilters);

        if(this.orderListRef !== null && this.orderListRef !== undefined &&
            this.orderListRef.current !== null && this.orderListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.orderListRef.current.resetDataGridPage();
        }
    };

    onOrderFiltersChange = (attribute: string, value: any) => {
        const { orderFilter, serverCustomerPagination } = this.state;

        const newFilter = { ...orderFilter } as OrderFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverCustomerPagination,
            page: newPaging.page
        };
        this.setState({
            orderFilter: newFilter,
            serverCustomerPagination: newPagination
        });
    };

    onCustomerAgreementFilterChange = (attribute: string, value: any) => {
        const { customerAgreementFilters, serverAgreementPagination } = this.state;

        const newFilter = { ...customerAgreementFilters } as CustomerAgreementFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverAgreementPagination,
            page: newPaging.page
        };

        this.setState({
            customerAgreementFilters: newFilter,
            serverAgreementPagination: newPagination
        });
    };

    getSelectedMenuItem = (state: IViewCustomerDetailsState) => state.menuItems.find(item => item.isSelected);

    processCreditInvoice = () => {
        const { processCreditInvoices } = this.props;
        const { customerId } = this.props.match.params;

        processCreditInvoices(customerId);
    }
    promoteCustomerAgreement = () => {
        const { promoteCustomerAgreement } = this.props;
        const { selectedRow } = this.state;

        promoteCustomerAgreement(selectedRow);
    };

    demoteCustomerAgreement = () => {
        const { selectedRow } = this.state;
        const { demoteCustomerAgreement } = this.props;

        demoteCustomerAgreement(selectedRow);
    };

    deactivateCustomerAgreement = () => {
        const { selectedRow } = this.state;
        const { deactivateCustomerAgreement } = this.props;

        deactivateCustomerAgreement(selectedRow);
    };

    handleMenuItems = (): Array<Action> => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { customer } = this.props;
        const { customerId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.CUSTOMERS}/${customer.id}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'credit-card',
                        text: messages.processCredit,
                        onClick: this.processCreditInvoice
                    }
                ];
            }

            case 3: { // orders
                return [
                    {
                        href: `${UrlConstants.CUSTOMERS}/${customerId}/add/order`,
                        icon: 'plus',
                        text: messages.add
                    }
                ];
            }

            case 4: { // invoices
                return [
                    {
                        href: `${UrlConstants.CUSTOMERS}/${customerId}/add/invoice`,
                        icon: 'plus',
                        text: messages.add
                    }
                ];
            }

            default: {
                return [];
            }
        }
    };

    setDefaultState = () => {
        const { fetchCustomerAgreements, customer } = this.props;
        const { serverAgreementPagination } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverAgreementPagination,
            page: newPaging.page
        };

        const newFilters = {
            customerId: customer.id
        } as CustomerAgreementFilters;

        this.setState({
            serverAgreementPagination: newPagination,
            customerAgreementFilters: newFilters
        });

        fetchCustomerAgreements(newPagination, newFilters);

        if(this.customerAgreementListRef !== null && this.customerAgreementListRef !== undefined &&
            this.customerAgreementListRef.current !== null && this.customerAgreementListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerAgreementListRef.current.resetDataGridPage();
        }

    };

    applyFilters = () => {
        const { fetchCustomerAgreements } = this.props;
        const { serverAgreementPagination, customerAgreementFilters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverAgreementPagination,
            page: newPaging.page
        };
        this.setState({
            serverAgreementPagination: newPagination
        });

        fetchCustomerAgreements(newPagination, customerAgreementFilters);

        if(this.customerAgreementListRef !== null && this.customerAgreementListRef !== undefined &&
            this.customerAgreementListRef.current !== null && this.customerAgreementListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerAgreementListRef.current.resetDataGridPage();
        }
    };

    _onSortChangeAgreements = (newModel: GridSortModel) => {
        const { fetchCustomerAgreements,customer } = this.props;
        const { sortModelAgreements, serverAgreementPagination, customerAgreementFilters } = this.state;

        if(JSON.stringify(sortModelAgreements) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverAgreementPagination,
                sort: newModel
            };
            this.setState({
                sortModelAgreements: newModel,
                serverAgreementPagination: newPagination
            });

            fetchCustomerAgreements(
                newPagination,
                 {
                     ...customerAgreementFilters,
                     customerId: customer.id
                 } as CustomerAgreementFilters
            );
        }
    }

    _onSortChangeOrders = (newModel: GridSortModel) => {
        const { fetchOrders, customer } = this.props;
        const { serverCustomerPagination, sortModelOrders, orderFilter } = this.state;

        if(JSON.stringify(sortModelOrders) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverCustomerPagination,
                sort: newModel
            };
            this.setState({
                sortModelOrders: newModel,
                serverCustomerPagination: newPagination
            });

            fetchOrders(
                newPagination,
                {
                    ...orderFilter,
                    customerId: customer.id
                } as OrderFilters
            );
        }
    }

    _onSortChangeInvoices = (newModel: GridSortModel) => {
        const { fetchInvoices, customer } = this.props;
        const { serverInvoicePagination, sortModelInvoices, invoiceFilter } = this.state;

        if(JSON.stringify(sortModelInvoices) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverInvoicePagination,
                sort: newModel
            };
            this.setState({
                sortModelInvoices: newModel,
                serverInvoicePagination: newPagination
            });

            fetchInvoices(
                newPagination,
                {
                    ...invoiceFilter,
                    customerId: customer.id } as InvoiceFilters
            );
        }
    }

    setCurrentComponentId = (value: any) => {
        this.setState({
            componentId: value
        });
    };

    render() {
        const { classes, customerAgreements, isLoadingCustomerAgreement, isLoadingCustomer, isDeletingCustomer, isLoadingOrders, fetchOrders, orders, customer, fetchCustomerAgreements,
            history, invoices, fetchInvoices, isLoadingInvoices, helptext } = this.props;
        const { menuItems, serverCustomerPagination, serverAgreementPagination, serverInvoicePagination, orderFilter, customerAgreementFilters, selectedRow, invoiceFilter,
            columnAgreementVisibilityModel, columnOrderVisibilityModel, columnInvoiceVisibilityModel, componentId } = this.state;
        const { customerId } = this.props.match.params;

        return (
            <MainLayout actions={this.handleMenuItems()}
                helptext={helptext}
                isLoading={isLoadingCustomer}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Customer}
                componentId={componentId}
                menuItem={{
                    id: 1,
                    subname: messages.customer,
                    value: `${customer.name} - ${customer.gtsId}`,
                    icon: <FontAwesomeIcon icon="user" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.CUSTOMERS}/:customerId/properties`}>
                            <Loader isLoading={isDeletingCustomer}>
                                <ViewCustomer customer={customer} setCurrentComponentId={this.setCurrentComponentId}/>
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.CUSTOMERS}/:agreementId/agreements`}>
                            <CustomerAgreementFiltersComponent filters={customerAgreementFilters} setDefaultState={this.setDefaultState} applyFilters={this.applyFilters}
                                onChange={this.onCustomerAgreementFilterChange}
                                actions={[
                                    {
                                        text: messages.add,
                                        icon: 'plus',
                                        isHidden: !!selectedRow,
                                        onClick: () => history.push(`/${UrlConstants.CUSTOMERS}/${customerId}/add/customeragreement`)
                                    },
                                    {
                                        icon: 'angle-up',
                                        text: messages.promote,
                                        isHidden: !selectedRow,
                                        onClick: this.promoteCustomerAgreement
                                    },
                                    {
                                        icon: 'angle-down',
                                        text: messages.demote,
                                        isHidden: !selectedRow,
                                        onClick: this.demoteCustomerAgreement
                                    },
                                    {
                                        href: `${UrlConstants.CUSTOMERS}/${customerId}/customeragreement/${selectedRow}/edit`,
                                        icon: 'edit',
                                        isHidden: !selectedRow,
                                        text: messages.edit
                                    },
                                    {
                                        icon: 'ban',
                                        text: messages.delete,
                                        isHidden: !selectedRow,
                                        onClick: this.deactivateCustomerAgreement
                                    }
                                ]} />
                            <AgreementList isLoading={isLoadingCustomerAgreement} prefix={'agreement_'}
                                setCurrentComponentId={this.setCurrentComponentId}
                                isCustomerAgreement={true}
                                rows={customerAgreements} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                applyRowFn={(cellValues: any) => cellValues.row.agreement}
                                ref={this.customerAgreementListRef}
                                hiddenColumns={['revenueThisYear', 'revenueLastYear', 'discount', 'status', 'relation']}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverAgreementPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchCustomerAgreements(
                                        newPage,
                                        {
                                            ...customerAgreementFilters,
                                            customerId: customer.id } as CustomerAgreementFilters
                                    );
                                    this.setState({ serverAgreementPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeAgreements(sortModel)}
                                columnVisibilityModel={columnAgreementVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnAgreementVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.CUSTOMERS}/:customerId/orders`}>
                            <>
                                <OrdersFilterComponent
                                    applyFilters={this.applyOrderFilters} setDefaultState={this.setOrderFiltersDefaultState} filters={orderFilter} onChange={this.onOrderFiltersChange}
                                    hiddenColumns={['hasErrors', 'customer', 'errorTypeId', 'vatCodeId']} />
                                <OrdersList isLoading={isLoadingOrders} rows={orders}
                                    setCurrentComponentId={this.setCurrentComponentId}
                                    hiddenColumns={['customer']}
                                    ref={this.orderListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...serverCustomerPagination,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchOrders(
                                            newPage,
                                            {
                                                ...orderFilter,
                                                customerId: customer.id
                                            } as OrderFilters
                                        );
                                        this.setState({ serverCustomerPagination: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeOrders(sortModel)}
                                    columnVisibilityModel={columnOrderVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnOrderVisibilityModel: newModel
                                        })
                                    }
                                />
                            </>
                        </Route>
                        <Route path={`/${UrlConstants.CUSTOMERS}/:customerId/invoices`}>
                            <>
                                <InvoiceFilterComponent
                                    applyFilters={this.applyInvoiceFilters} setDefaultState={this.setInvoiceFiltersDefaultState} filters={invoiceFilter} onChange={this.onInvoiceFiltersChange}
                                    hiddenColumns={['customer']} />
                                <InvoiceList isLoading={isLoadingInvoices} rows={invoices}
                                    setCurrentComponentId={this.setCurrentComponentId}
                                    hiddenColumns={['customer']}
                                    ref={this.invoiceListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...serverInvoicePagination,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchInvoices(
                                            newPage,
                                            {
                                                ...invoiceFilter,
                                                customerId: customer.id } as InvoiceFilters
                                        );
                                        this.setState({ serverInvoicePagination: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeInvoices(sortModel)}
                                    columnVisibilityModel={columnInvoiceVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnInvoiceVisibilityModel: newModel
                                        })
                                    }
                                />
                            </>
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ViewCustomerDetails));