import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme, Card } from '@mui/material';
import PriceList from '../../interfaces/output/priceList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import UrlConstants from '../../constants/UrlConstants';
import { Link } from 'react-router-dom';
import AccordionSummary from '../common/accordionSummary';
import DisplayStatus from '../common/displayStatus';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface IViewPriceListProps {
    classes?: any;
    theme?: Theme;
    priceList: PriceList;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        link: {
            textDecoration: 'none',
            color: `${theme.palette.info.main}!important`,
            fontSize: '12px!important'
        },
        linkItemGrid: {
            marginLeft: '10px!important'
        }
    }
    )
);

const messages = {
    details: LanguageUtils.createMessage('Price list details'),
    subGroup: LanguageUtils.createMessage('Subgroup'),
    group: LanguageUtils.createMessage('Unit group'),
    currency: LanguageUtils.createMessage('Currency'),
    priceListType: LanguageUtils.createMessage('Price list type'),
    name: LanguageUtils.createMessage('Name'),
    seller: LanguageUtils.createMessage('Seller'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    status: LanguageUtils.createMessage('Status'),
    versionFrom: LanguageUtils.createMessage('Valid from'),
    versionNumber: LanguageUtils.createMessage('Version number'),
    version: LanguageUtils.createMessage('Version'),
    versionTo: LanguageUtils.createMessage('Valid to'),
    versionInProgress: LanguageUtils.createMessage('Version in progress'),
    noVersionInProgress: LanguageUtils.createMessage('No version in progress'),
    latestVersion: LanguageUtils.createMessage('Latest version'),
    noActiveVersion: LanguageUtils.createMessage('No version active'),
    productArena: LanguageUtils.createMessage('Product arena'),
    productType: LanguageUtils.createMessage('Product type'),
    factors: LanguageUtils.createMessage('Factors'),
    factorSetCancellation: LanguageUtils.createMessage('Factor set cancellation'),
    weightFactor: LanguageUtils.createMessage('Factor set weight'),
    factorSetPriority: LanguageUtils.createMessage('Factor set priority'),
    factorSetDeparture: LanguageUtils.createMessage('Factor set departure'),
    factorSetHours: LanguageUtils.createMessage('Factor set hours'),
    factorSetCalendar: LanguageUtils.createMessage('Factor set calendar'),
    factorSetDangerousGoods: LanguageUtils.createMessage('Factor set dangerous goods')
};

const currentVersion = (params: any) => {
    return params?.currentVersion?.id || 'latest';
};

const urlLinkForFactorSet = (factorSetType: string, factorSet: any) => {
    return `/${UrlConstants.FACTOR_SETS}/${factorSetType}/${factorSet?.id}/${currentVersion(factorSet)}/properties`;
};

export default function ViewPriceList(props: IViewPriceListProps): JSX.Element {
    const classes = styles();
    const { priceList } = props;
    const intl = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />} aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}>
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.name} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12}>
                                    <Typography gutterBottom>{priceList.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.seller} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.seller?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.productType} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.productType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.group} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.unitGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.subGroup} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.unitSubGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.productArena} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.productArena?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priceListType} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.type?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.currency} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{priceList.currency?.name || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}>Version</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid container>
                                    {priceList.version &&
                                        <>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.versionNumber} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Typography gutterBottom>{priceList.version?.versionNumber || '-'}</Typography>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.versionFrom} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Typography gutterBottom><NullableFormattedDate value={priceList.version?.validFromDate} /></Typography>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.versionTo} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Typography gutterBottom><NullableFormattedDate value={priceList.version?.validToDate} /></Typography>
                                            </Grid>
                                            <Grid item sm={3} xs={3} className={classes.gridItem}>
                                                <Typography gutterBottom>Status: </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <DisplayStatus status={priceList.version?.status} />
                                            </Grid>
                                        </>
                                    }

                                    <Grid item sm={3} xs={12} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.versionInProgress} />: </Typography>
                                    </Grid>
                                    <Grid item sm={!priceList.inProgressVersion ? 6 : 2} xs={12} >
                                        <Grid container>
                                            <Grid item sm={!priceList.inProgressVersion ? 5 : 1}>
                                                <Typography gutterBottom>
                                                    {priceList.inProgressVersion ? priceList.inProgressVersion.versionNumber : intl.formatMessage(messages.noVersionInProgress)}
                                                </Typography>
                                            </Grid>
                                            {priceList.inProgressVersion && priceList.inProgressVersion?.id !== priceList.version?.id &&
                                                <Grid item sm={1} ml={1}>
                                                    <Link to={`/${UrlConstants.PRICE_LISTS}/${priceList.id}/${priceList.inProgressVersion.id}/properties`}
                                                        title="Go to versions" className={classes.link}>
                                                        <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                    </Link>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={!priceList.inProgressVersion ? 3 : 7} xs={12} />
                                    <Grid item sm={3} xs={12} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.latestVersion} />: </Typography>
                                    </Grid>
                                    <Grid item sm={!priceList.currentVersion ? 6 : 2} xs={12} >
                                        <Grid container>
                                            <Grid item sm={!priceList.currentVersion ? 5 : 1}>
                                                <Typography gutterBottom>{priceList.currentVersion ? priceList.currentVersion.versionNumber : intl.formatMessage(messages.noActiveVersion)}</Typography>
                                            </Grid>
                                            {priceList.currentVersion && priceList.currentVersion?.id !== priceList.version?.id &&
                                                <Grid item sm={1} ml={1} >
                                                    <Link to={`/${UrlConstants.PRICE_LISTS}/${priceList.id}/${priceList.currentVersion.id}/properties`}
                                                        title="Go to versions" className={classes.link}>
                                                        <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                    </Link>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={!priceList.currentVersion ? 4 : 7} xs={12} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {priceList.version &&
                        <>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    className={classes.accordion}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    aria-label="expand"
                                >
                                    <Typography className={classes.heading}>Factors</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid container>

                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.weightFactor} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Grid>
                                                        <Typography gutterBottom>{priceList.version?.factorSetWeight?.name || '-'}</Typography>
                                                    </Grid>
                                                    {priceList.version?.factorSetWeight && priceList.version?.factorSetWeight?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_WEIGHT, priceList.version.factorSetWeight)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.factorSetDeparture} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Typography gutterBottom>{priceList.version?.factorSetDeparture?.name || '-'}</Typography>
                                                    {priceList.version?.factorSetDeparture && priceList.version?.factorSetDeparture?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_DEPARTURE, priceList.version.factorSetDeparture)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.factorSetPriority} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Typography gutterBottom>{priceList.version?.factorSetPriority?.name || '-'}</Typography>
                                                    {priceList.version?.factorSetPriority && priceList.version?.factorSetPriority?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_PRIORITY, priceList.version.factorSetPriority)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.factorSetCancellation} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Typography gutterBottom>{priceList.version?.factorSetCancellation?.name || '-'}</Typography>
                                                    {priceList.version?.factorSetCancellation && priceList.version?.factorSetCancellation?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_CANCELLATION, priceList.version.factorSetCancellation)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.factorSetHours} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Typography gutterBottom>{priceList.version?.factorSetHours?.name || '-'}</Typography>
                                                    {priceList.version?.factorSetHours && priceList.version?.factorSetHours?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_HOURS, priceList.version.factorSetHours)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.factorSetDangerousGoods} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Typography gutterBottom>{priceList.version?.factorSetDangerousGoods?.name || '-'}</Typography>
                                                    {priceList.version?.factorSetDangerousGoods && priceList.version?.factorSetDangerousGoods?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_DANGEROUS_GOODS, priceList.version.factorSetDangerousGoods)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={3} xs={12} className={classes.gridItem}>
                                                <Typography gutterBottom><FormattedMessage {...messages.factorSetCalendar} />: </Typography>
                                            </Grid>
                                            <Grid item sm={9} xs={12} >
                                                <Grid container>
                                                    <Typography gutterBottom>{priceList.version?.factorSetCalendar?.name || '-'}</Typography>
                                                    {priceList.version?.factorSetCalendar && priceList.version?.factorSetCalendar?.id &&
                                                        <Grid item className={classes.linkItemGrid}>
                                                            <Link
                                                                to={urlLinkForFactorSet(UrlConstants.FACTOR_SET_CALENDAR, priceList.version.factorSetCalendar)}
                                                                title="Go to factor set"
                                                                className={classes.link}>
                                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                            </Link>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                </CardContent>
            </Card>
        </Grid>
    </Grid >
    );
}