import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { PriceListVersionOptions } from '../../../constants/priceListVersionOptions';
import WorkflowUtils from '../../../utils/workflowUtils';

const messages = {
    option1: LanguageUtils.createMessage('ADD_PRICE_LIST_VERSION_OPTION_1'),
    option2: LanguageUtils.createMessage('ADD_PRICE_LIST_VERSION_OPTION_2'),
    option3: LanguageUtils.createMessage('ADD_PRICE_LIST_VERSION_OPTION_3'),
    chooseOption: LanguageUtils.createMessage('CHOOSE_OPTION')
};

interface AddPriceListVersionStep2props {
    value: string;
    onChange: any;
}

const AddPriceListVersionStep2 = forwardRef((props: AddPriceListVersionStep2props, ref: any): JSX.Element => {
    const { value, onChange } = props;
    const { formatMessage } = useIntl();
    WorkflowUtils.setHandle(ref, null);

    return (
        <Grid container spacing={1} mt={2}>
            <Grid item xs={4} />
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <FormLabel id="demo-controlled-radio-buttons-group"><FormattedMessage {...messages.chooseOption} />:</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                    >
                        <FormControlLabel
                            value="inherit-from-active"
                            control={<Radio checked={value === PriceListVersionOptions.InheritFromActive} />}
                            label={formatMessage(messages.option1)} />
                        <FormControlLabel value="inherit-from-another" control={<Radio checked={value === PriceListVersionOptions.InheritFromAnother} />}
                            label={formatMessage(messages.option2)} />
                        <FormControlLabel value="blank-version" control={<Radio checked={value === PriceListVersionOptions.BlankVersion} />}
                            label={formatMessage(messages.option3)} />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={2} />
        </Grid>
    );
});

export default AddPriceListVersionStep2;