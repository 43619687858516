import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import PriceListType from '../interfaces/output/priceListType';
import PriceListTypeApi from '../api/priceListTypeApi';
import { createPriceListTypeError, createPriceListTypeSuccess, deletePriceListTypeError, deletePriceListTypeSuccess,
    fetchPriceListTypeByIdError, fetchPriceListTypeByIdSuccess, fetchPriceListTypesError, fetchPriceListTypesSuccess,
    updatePriceListTypeError, updatePriceListTypeSuccess } from '../reducers/pricelistTypeReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchPricelistType(action: PayloadAction<string>) {
    const pricelisttypeId = action.payload;
    try {
        const response: PriceListType = yield call(PriceListTypeApi.fetchPriceListTypeById, pricelisttypeId);
        yield put(fetchPriceListTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPricelistTypes(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<PriceListType> = yield call(PriceListTypeApi.fetchPriceListTypes, paging);
        yield put(fetchPriceListTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListTypesError(error as HttpErrorResponse));
    }
}

function* doUpdatePriceListType(action: PayloadAction<PriceListType>) {
    const priceListType = action.payload;

    try {
        const response: PriceListType = yield call(PriceListTypeApi.updatePriceListType, priceListType);

        yield put(updatePriceListTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriceListTypeError(error as HttpErrorResponse));
    }
}

function* doCreatePriceListType(action: PayloadAction<PriceListType>) {
    const priceListType = action.payload;

    try {
        const response: PriceListType = yield call(PriceListTypeApi.createPriceListType, priceListType);

        yield put(createPriceListTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(createPriceListTypeError(error as HttpErrorResponse));
    }
}

function* doDeactivatePriceListType(action: PayloadAction<PriceListType>) {
    const priceListType = action.payload;
    
    try {
        const response: PriceListType = yield call(PriceListTypeApi.deactivatePriceListType, priceListType);

        yield put(deletePriceListTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(deletePriceListTypeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('pricelisttype/fetchPriceListTypeById', doFetchPricelistType),
    takeLatest('pricelisttype/fetchPriceListTypes', doFetchPricelistTypes),
    takeLatest('pricelisttype/updatePriceListType', doUpdatePriceListType),
    takeLatest('pricelisttype/createPriceListType', doCreatePriceListType),
    takeLatest('pricelisttype/deletePriceListType', doDeactivatePriceListType)
];

