import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import UrlConstants from '../../constants/UrlConstants';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import GtsOrder from '../../interfaces/output/gtsOrder';
import GtsOrderFilters from '../../interfaces/filters/gtsOrderFilters';
import { fetchGtsOrders, getGtsOrder, getGtsOrders, isCreatingGtsOrder, isLoadingGtsOrders, resetGtsOrders } from '../../reducers/gtsOrderReducer';
import GtsOrdersList from './ordersList';
import GtsOrderFilterComponent from './gtsOrderFilterComponent';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import AddGtsOrderModal from './add/addGtsOrderModal';
import HttpErrorResponse from '../../interfaces/common/httpErrorResponse';
import { getError } from '../../reducers/errorReducer';
import DateTimeUtils from '../../utils/dateTimeUtils';

interface IGtsOrdersProps {
    gtsOrders: Pagination<GtsOrder>;
    gtsOrder: GtsOrder;
    classes: any;
    fetchGtsOrders: any;
    isLoading: boolean;
    clearSearch: () => void;
    onChange: () => void;
    resetGtsOrders: () => void;
    isCreatingGtsOrder: boolean;
    history: any;
    error?: HttpErrorResponse;
}

interface IGtsOrdersState {
    searchTerm: string;
    filters: GtsOrderFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    showAddForm: boolean;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    gtsOrders: LanguageUtils.createMessage('Gts orders'),
    importAll: LanguageUtils.createMessage('Import all'),
    add: LanguageUtils.createMessage('Add Gts Order')
    //importAllFromStaging: LanguageUtils.createMessage('Import all from staging')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGtsOrders: (paging: Paging, filters: GtsOrderFilters) => dispatch(fetchGtsOrders({
        paging,
        filters
    })),
    resetGtsOrders: () => dispatch(resetGtsOrders())
});

const mapStoreToProps = (store: RootState) => {
    return {
        gtsOrders: getGtsOrders(store),
        gtsOrder: getGtsOrder(store),
        isCreatingGtsOrder: isCreatingGtsOrder(store),
        error: getError(store),
        isLoading: isLoadingGtsOrders(store) || isCreatingGtsOrder(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class GtsOrders extends Component<IGtsOrdersProps, IGtsOrdersState> {
    gtsOrderListRef: any;

    constructor(props: IGtsOrdersProps) {
        super(props);
        const now = new Date();
        this.gtsOrderListRef = React.createRef();
        this.state = {
            searchTerm: '',
            filters: {
                orderNumber: '',
                hasErrors: undefined,
                fromDate: DateTimeUtils.dateTimeGMTStart(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)),
                toDate: DateTimeUtils.dateTimeGMTEnd(now)
            } as GtsOrderFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.gtsOrders,
                icon: <FontAwesomeIcon icon="receipt" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            showAddForm: false,
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchGtsOrders } = this.props;
        const { paging, filters } = this.state;

        fetchGtsOrders(paging, filters);
    }

    componentDidUpdate(prevProps: IGtsOrdersProps) {
        const { isCreatingGtsOrder, history, gtsOrder, error } = this.props;
        if(prevProps.isCreatingGtsOrder && !isCreatingGtsOrder && !error) {
            history.push(`/${UrlConstants.GTS_ORDERS}/${gtsOrder.id}`);
        }
    }

    componentWillUnmount() {
        const { resetGtsOrders } = this.props;
        resetGtsOrders();
    }

    applyFilters = () => {
        const { fetchGtsOrders } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getCustomPaging(paging.size);
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination
        });

        fetchGtsOrders(newPagination, filters);

        if(this.gtsOrderListRef !== null && this.gtsOrderListRef !== undefined &&
            this.gtsOrderListRef.current !== null && this.gtsOrderListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.gtsOrderListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as GtsOrderFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getCustomPaging(paging.size);
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchGtsOrders } = this.props;

        const newFilter = { ...filters } as GtsOrderFilters;
        (newFilter as any)['orderNumber'] = value;

        const newPaging = PageUtils.getCustomPaging(paging.size);
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchGtsOrders(newPagination, newFilter);
    }

    setDefaultState = () => {
        const { fetchGtsOrders } = this.props;
        const { paging } = this.state;
        // const now = new Date();
        const newFilters = {
            orderNumber: '',
            hasErrors: undefined
            // fromDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
            // toDate: now
        } as GtsOrderFilters;

        const newSortModel = [{
            field: 'orderNumber',
            sort: 'asc' as GridSortDirection
        }] as GridSortModel;

        const newPaging = PageUtils.getCustomPaging(paging.size);
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        if(this.gtsOrderListRef !== null && this.gtsOrderListRef !== undefined &&
            this.gtsOrderListRef.current !== null && this.gtsOrderListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.gtsOrderListRef.current.resetDataGridPage();
        }

        fetchGtsOrders(newPagination, newFilters);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchGtsOrders } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchGtsOrders(newPaging, filters);
        }
    }

    toggleAddModal = () => {
        this.setState({ showAddForm: !this.state.showAddForm });
    }

    getActions = () => {
        return [
            {
                text: messages.add,
                icon: 'plus',
                onClick: this.toggleAddModal
            },
            {
                text: messages.importAll,
                icon: 'file-import',
                href: `${UrlConstants.GTS_ORDERS}/import`
            }
        ];
    }

    render() {
        const { classes, gtsOrders, fetchGtsOrders, isLoading } = this.props;
        const { paging, filters, menuItem, searchTerm, showAddForm, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={this.getActions()}
                                >
                                    <GtsOrderFilterComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <GtsOrdersList rows={gtsOrders} isLoading={isLoading}
                                    ref={this.gtsOrderListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchGtsOrders(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                                <AddGtsOrderModal show={showAddForm} onClose={this.toggleAddModal} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(GtsOrders));