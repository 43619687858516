import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import CancellationLineApi from '../api/cancellationLineApi';
import FactorSetCancellationLineOutput from '../interfaces/output/factorSetCancellationLineOut';
import CancellationLineFilters from '../components/factorSets/cancellation/line/cancellationLineFilters';
import { createCancellationVersionLineError, createCancellationVersionLineSuccess, deactivateFactorSetCancellationLineError, deactivateFactorSetCancellationLineSuccess,
    fetchCancellationVersionLinesError, fetchCancellationVersionLinesSuccess, fetchFactorSetCancellationLineByIdError, fetchFactorSetCancellationLineByIdSuccess, updateFactorSetCancellationLineError,
    updateFactorSetCancellationLineSuccess } from '../reducers/cancellationLineReducer';

function* doFetchFactorSetCancellationLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetCancellationLineOutput = yield call(CancellationLineApi.fetchFactorSetCancellationLineById, lineId);
        yield put(fetchFactorSetCancellationLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetCancellationLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetCancellationLines(action: PayloadAction<{paging: Paging, filters: CancellationLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetCancellationLineOutput> = yield call(CancellationLineApi.fetchFactorSetCancellationLines, paging, filters);
    
        yield put(fetchCancellationVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchCancellationVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetCancellationLine(action: PayloadAction<FactorSetCancellationLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetCancellationLineOutput = yield call(CancellationLineApi.createFactorSetCancellationLine, line);

        yield put(createCancellationVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCancellationVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCancellationLine(action: PayloadAction<FactorSetCancellationLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetCancellationLineOutput = yield call(CancellationLineApi.updateFactorSetCancellationLine, line);

        yield put(updateFactorSetCancellationLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetCancellationLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetCancellationLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetCancellationLineOutput = yield call(CancellationLineApi.deactivateFactorSetCancellationLine, lineId);

        yield put(deactivateFactorSetCancellationLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetCancellationLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetCancellationLineById', doFetchFactorSetCancellationLineById),
    takeLatest('factorSetLine/fetchCancellationVersionLines', doFetchFactorSetCancellationLines),
    takeLatest('factorSetLine/createCancellationVersionLine', doCreateFactorSetCancellationLine),
    takeLatest('factorSetLine/updateFactorSetCancellationLine', doUpdateFactorSetCancellationLine),
    takeLatest('factorSetLine/deactivateFactorSetCancellationLine', doDeactivateFactorSetCancellationLine)
];