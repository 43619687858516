import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowIdGetter, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../utils/tableUtils';
import Product from '../../interfaces/output/product';
import { ensure } from '../../utils/arrayUtils';
import Pagination from '../../interfaces/common/pagination';
import ServiceProduct from '../../interfaces/output/serviceProduct';
import PricelistVersionProduct from '../../interfaces/output/pricelistVersionProduct';
import LanguageUtils from '../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import CustomStringLink from '../common/customStringLink';

interface IProductListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: Pagination<Product> | Pagination<ServiceProduct> | Pagination<PricelistVersionProduct>;
    hiddenColumns?: Array<string>;
    applyRowFn?: any;
    selection?: number;
    disableLinks?: boolean;
    additionalColumns?: GridColDef[];
    getRowId?: GridRowIdGetter;
    isRowSelectable?: any;
    onSortModelChange?: any;
    prefix?: string;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    isInPriceListDetails?: boolean;
    isAServiceProduct?: boolean;
    showServiceProductAmount?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('PRODUCT_NAME'),
    productType: LanguageUtils.createMessage('Product type'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    productArena: LanguageUtils.createMessage('Product arena'),
    basePrice: LanguageUtils.createMessage('Base price'),
    onHold: LanguageUtils.createMessage('On hold'),
    sequenceNumber: LanguageUtils.createMessage('Sequence number'),
    amount: LanguageUtils.createMessage('Amount')
};

const defaultProps: IProductListProps = {
    additionalColumns: [] as GridColDef[],
    isInPriceListDetails: false,
    isAServiceProduct: false,
    showServiceProductAmount: false
};

const ProductList = forwardRef((props: IProductListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, additionalColumns, getRowId, disableLinks, isRowSelectable, onSortModelChange, prefix,
        columnVisibilityModel, onColumnVisibilityModelChange, isInPriceListDetails, isAServiceProduct, showServiceProductAmount } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));
    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();
        let x = [
            ...ensure(additionalColumns)
        ] as GridColDef[];
        if(isAServiceProduct) {
            x = [
                ...x,
                {
                    field: 'sequenceNumber',
                    headerName: intl.formatMessage(messages.sequenceNumber),
                    flex: 1,
                    align: 'left',
                    headerAlign: 'left',
                    sortable: true,
                    renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={params.row.sequenceNumber ?? '-'} />,
                    hide: hiddenColumns && TableUtils.hideColumn('sequenceNumber', hiddenColumns)
                }];
        }
        x = [
            ...x,
            {
                field: prefix ? `${prefix}name` : 'name',
                headerName: intl.formatMessage(messages.name),
                flex: 2,
                sortable: true,
                renderCell: (cellValues: any) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.PRODUCTS}/${applyRowFn(cellValues)?.id}`} value={applyRowFn(cellValues).name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}productType_name` : 'productType_name',
                headerName: intl.formatMessage(messages.productType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={applyRowFn(params).productType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('productType', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}unitGroup_name` : 'unitGroup_name',
                headerName: intl.formatMessage(messages.unitGroup),
                flex: 2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={applyRowFn(params).unitGroup?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitGroup', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}productArena_name` : 'productArena_name',
                headerName: intl.formatMessage(messages.productArena),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={applyRowFn(params).productArena?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('productArena', hiddenColumns)
            }

            // {
            //     field: 'onHold',
            //     headerName: intl.formatMessage(messages.onHold),
            //     flex: 1,
            //     align: 'left',
            //     headerAlign: 'left',
            //     sortable: true,
            //     renderCell: (params: GridRenderCellParams<PricelistVersionProduct>) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
            //         link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={params.row.onHold ?? '-'} />,
            //     hide: hiddenColumns && TableUtils.hideColumn('onHold', hiddenColumns)
            // }
        ];
        if(showServiceProductAmount) {
            x = [
                ...x,
                {
                    field: 'amount',
                    headerName: intl.formatMessage(messages.amount),
                    flex: 1,
                    align: 'left',
                    headerAlign: 'left',
                    sortable: true,
                    renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={params.row.amount ?? '-'} />,
                    hide: hiddenColumns && TableUtils.hideColumn('amount', hiddenColumns)
                }];
        }
        if(isInPriceListDetails) {
            x = [
                ...x,
                {
                    field: 'basePrice',
                    headerName: intl.formatMessage(messages.basePrice),
                    flex: 1,
                    align: 'left',
                    headerAlign: 'left',
                    sortable: isInPriceListDetails,
                    renderCell: (params: GridRenderCellParams<PricelistVersionProduct>) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.PRODUCTS}/${applyRowFn(params)?.id}`} value={params.row.basePrice ?? '-'} />,
                    hide: hiddenColumns && TableUtils.hideColumn('basePrice', hiddenColumns)
                }];
        }

        return x;
    };

    return (
        <DataGridComponent getRowId={getRowId} rows={rows} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} isRowSelectable={isRowSelectable} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

ProductList.defaultProps = defaultProps;

export default ProductList;