import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceOut from '../interfaces/output/invoiceOut';
import InvoiceLineOut from '../interfaces/output/invoiceLineOut';
import InvoiceFilters from '../interfaces/filters/invoiceFilters';
import InvoiceLineFiltersIn from '../interfaces/filters/invoiceLineFilters';
import CustomerReport from '../interfaces/output/customerReport';
import DetailedHistoryFilter from '../interfaces/filters/detailedHistoryFilter';

export type InvoiceState = {
    invoice: InvoiceOut;
    invoiceLine: InvoiceLineOut;
    invoices: Pagination<InvoiceOut>;
    invoiceLines: Pagination<InvoiceLineOut>;
    isLoadingInvoice: boolean;
    isLoadingInvoices: boolean;
    isLoadingInvoiceLines: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCrediting: boolean;
    isCreating: boolean;
    isExporting: boolean;
    isSending: boolean;
    error: HttpErrorResponse | undefined;
    isExportingPdf: boolean;
    customerReport: Pagination<CustomerReport>;
    isLoadingCustomerReport: boolean;
};

const initialState: InvoiceState = {
    invoice: {} as InvoiceOut,
    invoiceLine: {} as InvoiceLineOut,
    invoices: { content: [] as Array<InvoiceOut> } as Pagination<InvoiceOut>,
    invoiceLines: { content: [] as Array<InvoiceLineOut> } as Pagination<InvoiceLineOut>,
    isLoadingInvoice: false,
    isLoadingInvoices: false,
    isLoadingInvoiceLines: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    isCrediting: false,
    isExporting: false,
    isSending: false,
    error: undefined,
    isExportingPdf: false,
    customerReport: { content: [] as Array<CustomerReport> } as Pagination<CustomerReport>,
    isLoadingCustomerReport: false
};

export const invoiceSlice = createSlice({
    name: 'invoice',

    initialState,
    reducers: {
        fetchInvoices: (state: InvoiceState, action: PayloadAction<{ paging: Paging, filters?: InvoiceFilters }>) => {
            state.isLoadingInvoices = true;
        },
        fetchInvoicesSuccess: (state: InvoiceState, action: PayloadAction<Pagination<InvoiceOut>>) => {
            state.isLoadingInvoices = false;
            state.invoices = action.payload;
        },
        fetchInvoicesError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingInvoices = false;
            state.error = action.payload;
        },
        fetchInvoiceById: (state: InvoiceState, _action: PayloadAction<number>) => {
            state.isLoadingInvoice = true;
        },
        fetchInvoiceByIdSuccess: (state: InvoiceState, action: PayloadAction<InvoiceOut>) => {
            state.isLoadingInvoice = false;
            state.invoice = action.payload;
        },
        fetchInvoiceByIdError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingInvoice = false;
            state.error = action.payload;
        },
        fetchInvoiceLines: (state: InvoiceState, _action: PayloadAction<{ invoiceId: number, paging: Paging, filters: InvoiceLineFiltersIn }>) => {
            state.isLoadingInvoiceLines = true;
        },
        fetchInvoiceLinesSuccess: (state: InvoiceState, action: PayloadAction<Pagination<InvoiceLineOut>>) => {
            state.isLoadingInvoiceLines = false;
            state.invoiceLines = action.payload;
        },
        fetchInvoiceLinesError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingInvoiceLines = false;
            state.error = action.payload;
        },
        createInvoice: (state: InvoiceState, _action: PayloadAction<InvoiceOut>) => {
            state.isCreating = true;
        },
        createInvoiceSuccess: (state: InvoiceState, action: PayloadAction<InvoiceOut>) => {
            state.isCreating = false;
            state.invoice = action.payload;
        },
        createInvoiceError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateInvoice: (state: InvoiceState, _action: PayloadAction<InvoiceOut>) => {
            state.isUpdating = true;
        },
        updateInvoiceSuccess: (state: InvoiceState, action: PayloadAction<InvoiceOut>) => {
            state.isUpdating = false;
            state.invoice = action.payload;
        },
        updateInvoiceError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateInvoice: (state: InvoiceState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateInvoiceSuccess: (state: InvoiceState, action: PayloadAction<InvoiceOut>) => {
            state.isDeleted = false;
            state.invoice = action.payload;
        },
        deactivateInvoiceError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        creditInvoice: (state: InvoiceState, _action: PayloadAction<{ id: number, createNewCredit: boolean, creditReason: string }>) => {
            state.isCrediting = true;
        },
        creditInvoiceSuccess: (state: InvoiceState, action: PayloadAction<InvoiceOut>) => {
            state.isCrediting = false;
        },
        creditInvoiceError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCrediting = false;
            state.error = action.payload;
        },
        exportInvoices: (state: InvoiceState, _action: PayloadAction<number>) => {
            state.isExporting = true;
        },
        exportInvoicesSuccess: (state: InvoiceState) => {
            state.isExporting = false;
        },
        exportInvoicesError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExporting = false;
            state.error = action.payload;
        },
        sendInvoiceEmail: (state: InvoiceState, _action: PayloadAction<{id: number, emailAddress: string| undefined}>) => {
            state.isSending = true;
        },
        sendInvoiceEmailSuccess: (state: InvoiceState) => {
            state.isSending = false;
        },
        sendInvoiceEmailError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isSending = false;
            state.error = action.payload;
        },
        resetInvoices: (state: InvoiceState) => {
            state.invoices = initialState.invoices;
        },
        exportInvoicesPdf: (state: InvoiceState, _action: PayloadAction<number>) => {
            state.isExportingPdf = true;
        },
        exportInvoicesPdfSuccess: (state: InvoiceState) => {
            state.isExportingPdf = false;
        },
        exportInvoicesPdfError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportingPdf = false;
            state.error = action.payload;
        },
        fetchCustomerReport: (state: InvoiceState, _action: PayloadAction<{ filter: DetailedHistoryFilter, paging: Paging }>) => {
            state.isLoadingCustomerReport = true;
        },
        fetchCustomerReportSuccess: (state: InvoiceState, action: PayloadAction<Pagination<CustomerReport>>) => {
            state.isLoadingCustomerReport = false;
            state.customerReport = action.payload;
        },
        fetchCustomerReportError: (state: InvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCustomerReport = false;
            state.error = action.payload;
        }
    }
});

export const {
    fetchInvoices, fetchInvoicesSuccess, fetchInvoicesError, fetchInvoiceById, fetchInvoiceByIdSuccess, fetchInvoiceByIdError, fetchInvoiceLines, fetchInvoiceLinesError, fetchInvoiceLinesSuccess,
    createInvoice, createInvoiceError, createInvoiceSuccess, updateInvoice, updateInvoiceError, updateInvoiceSuccess,
    deactivateInvoice, deactivateInvoiceError, deactivateInvoiceSuccess, resetInvoices, creditInvoice, creditInvoiceSuccess, creditInvoiceError, exportInvoices,
    exportInvoicesSuccess, exportInvoicesError, sendInvoiceEmail, sendInvoiceEmailSuccess, sendInvoiceEmailError, exportInvoicesPdf,
    exportInvoicesPdfSuccess, exportInvoicesPdfError, fetchCustomerReport, fetchCustomerReportError, fetchCustomerReportSuccess } = invoiceSlice.actions;

export const isLoadingInvoice = (state: RootState): boolean => state.invoiceReducer.isLoadingInvoice;
export const isLoadingInvoices = (state: RootState): boolean => state.invoiceReducer.isLoadingInvoices;
export const isLoadingInvoiceLines = (state: RootState): boolean => state.invoiceReducer.isLoadingInvoiceLines;
export const isUpdatingInvoice = (state: RootState): boolean => state.invoiceReducer.isUpdating;
export const isDeletingInvoice = (state: RootState): boolean => state.invoiceReducer.isDeleted;
export const isCreatingInvoice = (state: RootState): boolean => state.invoiceReducer.isCreating;
export const getInvoiceLines = (state: RootState): Pagination<InvoiceLineOut> => state.invoiceReducer.invoiceLines;
export const getInvoices = (state: RootState): Pagination<InvoiceOut> => state.invoiceReducer.invoices;
export const getInvoice = (state: RootState): InvoiceOut => state.invoiceReducer.invoice;
export const isCrediting = (state: RootState): boolean => state.invoiceReducer.isCrediting;
export const isSending = (state: RootState): boolean => state.invoiceReducer.isSending;
export const isExporting = (state: RootState): boolean => state.invoiceReducer.isExporting;
export const isExportingPdf = (state: RootState): boolean => state.invoiceReducer.isExportingPdf;
export const getCustomerReport = (state: RootState): Pagination<CustomerReport> => state.invoiceReducer.customerReport;
export const isLoadingCustomerReport = (state: RootState): boolean => state.invoiceReducer.isLoadingCustomerReport;

export default invoiceSlice.reducer;