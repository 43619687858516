import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import AgreementVersionPricelist from '../interfaces/output/agreementVersionPricelist';
import AgreementVersionPricelistApi from '../api/agreementVersionPriceListApi';
import { createAgreementVersionPricelistsError, createAgreementVersionPricelistsSuccess, deactivateAgreementVersionPricelistsError,
    deactivateAgreementVersionPricelistsSuccess, demoteAgreementVersionPricelistsError, demoteAgreementVersionPricelistsSuccess,
    fetchAgreementVersionPricelistsByIdError, fetchAgreementVersionPricelistsByIdSuccess, fetchAgreementVersionPricelistsError,
    fetchAgreementVersionPricelistsSuccess,promoteAgreementVersionPricelistsError, promoteAgreementVersionPricelistsSuccess,
    updateAgreementVersionPricelistError, updateAgreementVersionPricelistSuccess } from '../reducers/agreementVersionPriceListReducer';
import AgreementVersionPriceListsFilters from '../interfaces/filters/agreementVersionPriceListsFilters';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchAgreementVersionPricelist(action: PayloadAction<number>) {
    const agreementVersionPricelistId = action.payload;
    try {
        const response: AgreementVersionPricelist = yield call(AgreementVersionPricelistApi.fetchAgreementVersionPricelistById, agreementVersionPricelistId);
        yield put(fetchAgreementVersionPricelistsByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionPricelistsByIdError(error as HttpErrorResponse));
    }
}

function* doFetchAgreementVersionPricelists(action: PayloadAction<{ paging: Paging, filters: AgreementVersionPriceListsFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<AgreementVersionPricelist> = yield call(AgreementVersionPricelistApi.fetchAgreementVersionPricelists, paging, filters);

        yield put(fetchAgreementVersionPricelistsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionPricelistsError(error as HttpErrorResponse));
    }
}

function* promoteAgreementVersionPricelist(action: PayloadAction<string>) {
    const agreementVersionPricelistId = action.payload;
    try {
        const response: AgreementVersionPricelist = yield call(AgreementVersionPricelistApi.promoteAgreementVersionPricelist, agreementVersionPricelistId);
        yield put(promoteAgreementVersionPricelistsSuccess(response));
    }

    catch (error: unknown) {
        yield put(promoteAgreementVersionPricelistsError(error as HttpErrorResponse));
    }
}

function* demoteAgreementVersionPricelist(action: PayloadAction<string>) {
    const agreementVersionPricelistId = action.payload;
    try {
        const response: AgreementVersionPricelist = yield call(AgreementVersionPricelistApi.demoteAgreementVersionPricelist, agreementVersionPricelistId);
        yield put(demoteAgreementVersionPricelistsSuccess(response));
    }

    catch (error: unknown) {
        yield put(demoteAgreementVersionPricelistsError(error as HttpErrorResponse));
    }
}

function* deactivateAgreementVersionPricelist(action: PayloadAction<string>) {
    const agreementVersionPricelistId = action.payload;
    try {
        const response: AgreementVersionPricelist = yield call(AgreementVersionPricelistApi.deactivateAgreementVersionPricelist, agreementVersionPricelistId);
        yield put(deactivateAgreementVersionPricelistsSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateAgreementVersionPricelistsError(error as HttpErrorResponse));
    }
}

function* createAgreementVersionPricelist(action: PayloadAction<AgreementVersionPricelist>) {
    const agreementVersionPricelist = action.payload;
    try {
        const response: AgreementVersionPricelist = yield call(AgreementVersionPricelistApi.createAgreementVersionPricelist, agreementVersionPricelist);
        yield put(createAgreementVersionPricelistsSuccess(response));
    }

    catch (error: unknown) {
        yield put(createAgreementVersionPricelistsError(error as HttpErrorResponse));
    }
}

function* updateAgreementVersionPricelist(action: PayloadAction<AgreementVersionPricelist>) {
    const agreementVersionPricelist = action.payload;
    try {
        const response: AgreementVersionPricelist = yield call(AgreementVersionPricelistApi.updateAgreementVersionPricelist, agreementVersionPricelist);
        yield put(updateAgreementVersionPricelistSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementVersionPricelistError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('agreementVersionPriceList/fetchAgreementVersionPricelistsById', doFetchAgreementVersionPricelist),
    takeLatest('agreementVersionPriceList/fetchAgreementVersionPricelists', doFetchAgreementVersionPricelists),
    takeLatest('agreementVersionPriceList/promoteAgreementVersionPricelists', promoteAgreementVersionPricelist),
    takeLatest('agreementVersionPriceList/demoteAgreementVersionPricelists', demoteAgreementVersionPricelist),
    takeLatest('agreementVersionPriceList/deactivateAgreementVersionPricelists', deactivateAgreementVersionPricelist),
    takeLatest('agreementVersionPriceList/createAgreementVersionPricelists', createAgreementVersionPricelist),
    takeLatest('agreementVersionPriceList/updateAgreementVersionPricelist', updateAgreementVersionPricelist)
];