import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceSeparation from '../interfaces/output/invoiceSeparation';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type InvoiceSeparationState = {
    invoiceSeparation: InvoiceSeparation;
    isLoading: boolean;
    invoicesSeparation: Pagination<InvoiceSeparation>;
    error: HttpErrorResponse | undefined;
};

const initialState: InvoiceSeparationState = {
    invoiceSeparation: {} as InvoiceSeparation,
    invoicesSeparation: { content: [] as Array<InvoiceSeparation> } as Pagination<InvoiceSeparation>,
    isLoading: false,
    error: undefined
};

export const invoiceSeparationSlice = createSlice({
    name: 'invoiceSeparation',

    initialState,
    reducers: {
        fetchInvoiceSeparations: (state: InvoiceSeparationState) => {
            state.isLoading = true;
        },
        fetchInvoiceSeparationsSuccess: (state: InvoiceSeparationState, action: PayloadAction<Pagination<InvoiceSeparation>>) => {
            state.isLoading = false;
            state.invoicesSeparation = action.payload;
        },
        fetchInvoiceSeparationsError: (state: InvoiceSeparationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchInvoiceSeparationById: (state: InvoiceSeparationState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchInvoiceSeparationByIdSuccess: (state: InvoiceSeparationState, action: PayloadAction<InvoiceSeparation>) => {
            state.isLoading = false;
            state.invoiceSeparation = action.payload;
        },
        fetchInvoiceSeparationByIdError: (state: InvoiceSeparationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchInvoiceSeparations, fetchInvoiceSeparationsSuccess, fetchInvoiceSeparationsError, fetchInvoiceSeparationById, fetchInvoiceSeparationByIdSuccess,
    fetchInvoiceSeparationByIdError } = invoiceSeparationSlice.actions;

export const isLoadingInvoicesSeparation = (state: RootState): boolean => state.invoiceSeparationReducer.isLoading;
export const getInvoicesSeparation = (state: RootState): Pagination<InvoiceSeparation> => state.invoiceSeparationReducer.invoicesSeparation;
export const getInvoiceSeparation = (state: RootState): InvoiceSeparation => state.invoiceSeparationReducer.invoiceSeparation;

export default invoiceSeparationSlice.reducer;