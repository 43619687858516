import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderUnitService from '../interfaces/output/orderUnitService';
import { createOrderUnitServiceError, createOrderUnitServiceSuccess, creditOrderUnitServiceError, creditOrderUnitServiceSuccess, deactivateOrderUnitServiceError,
    deactivateOrderUnitServiceSuccess, fetchOrderUnitServicesByIdError, fetchOrderUnitServicesByIdSuccess, fetchProductsByOrderUnitServiceIdError, fetchProductsByOrderUnitServiceIdSuccess,
    updateOrderUnitServicesError, updateOrderUnitServicesSuccess } from '../reducers/unitServiceReducer';
import OrderUnitServiceApi from '../api/orderUnitServiceApi';
import OrderUnitServiceProduct from '../interfaces/output/orderUnitServiceProduct';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchOrderUnitService(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: OrderUnitService = yield call(OrderUnitServiceApi.fetchOrderUnitService, id);
        yield put(fetchOrderUnitServicesByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrderUnitServicesByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductsByOrderUnitServiceId(action: PayloadAction<{paging: Paging, id: number}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<OrderUnitServiceProduct> = yield call(OrderUnitServiceApi.fetchProductsByOrderUnitServiceId, id, paging);
    
        yield put(fetchProductsByOrderUnitServiceIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductsByOrderUnitServiceIdError(error as HttpErrorResponse));
    }
}

function* doCreateOrderUnitService(action: PayloadAction<OrderUnitService>) {
    const service = action.payload;

    try {
        const response: OrderUnitService = yield call(OrderUnitServiceApi.createOrderUnitService, service);

        yield put(createOrderUnitServiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(createOrderUnitServiceError(error as HttpErrorResponse));
    }
}

function* doUpdateOrderUnitService(action: PayloadAction<OrderUnitService>) {
    const unitService = action.payload;

    try {
        const response: OrderUnitService = yield call(OrderUnitServiceApi.updateOrderUnitService, unitService);

        yield put(updateOrderUnitServicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateOrderUnitServicesError(error as HttpErrorResponse));
    }
}

function* doDeactivateUnitService(action: PayloadAction<number>) {
    const unitServiceId = action.payload;
    
    try {
        const response: OrderUnitService = yield call(OrderUnitServiceApi.deactivateOrderUnitService, unitServiceId);

        yield put(deactivateOrderUnitServiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateOrderUnitServiceError(error as HttpErrorResponse));
    }
}

function* doCreditOrderUnitService(action: PayloadAction<{id: number, createNewCredit: boolean, creditReason: string }>) {
    const { id, createNewCredit, creditReason } = action.payload;

    try {
        const response: OrderUnitServiceProduct = yield call(OrderUnitServiceApi.creditOrderUnitService, id, createNewCredit, creditReason);
    
        yield put(creditOrderUnitServiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(creditOrderUnitServiceError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('orderUnitService/fetchOrderUnitServicesById', doFetchOrderUnitService),
    takeLatest('orderUnitService/createOrderUnitService', doCreateOrderUnitService),
    takeLatest('orderUnitService/updateOrderUnitServices', doUpdateOrderUnitService),
    takeLatest('orderUnitService/fetchProductsByOrderUnitServiceId', doFetchProductsByOrderUnitServiceId),
    takeLatest('orderUnitService/deactivateOrderUnitService', doDeactivateUnitService),
    takeLatest('orderUnitService/creditOrderUnitService', doCreditOrderUnitService)
];

