import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import UserRoleApi from '../api/userRoleApi';
import RoleOut from '../interfaces/output/roleOut';
import { fetchUserRoleByIdError, fetchUserRoleByIdSuccess, fetchUserRolesError, fetchUserRolesSuccess } from '../reducers/userRoleReducer';

function* doFetchUserRole(action: PayloadAction<string>) {
    const id = action.payload;
    try {
        const response: RoleOut = yield call(UserRoleApi.fetchUserRoleById, id);
        yield put(fetchUserRoleByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUserRoleByIdError(error as HttpErrorResponse));
    }
}

function* doFetchUserRoles() {
    try {
        const response: Pagination<RoleOut> = yield call(UserRoleApi.fetchUserRoles);
        yield put(fetchUserRolesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUserRolesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('userRole/fetchUserRoleById', doFetchUserRole),
    takeLatest('userRole/fetchUserRoles', doFetchUserRoles)];

