import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type DangerousGoodVersionStatusState = {
    dangerousGoodVersionStatus: FactorSetVersionStatusOut;
    dangerousGoodVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: DangerousGoodVersionStatusState = {
    dangerousGoodVersionStatus: {} as FactorSetVersionStatusOut,
    dangerousGoodVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const dangerousGoodVersionStatusSlice = createSlice({
    name: 'dangerousGoodVersionStatus',

    initialState,
    reducers: {
        fetchDangerousGoodVersionStatuses: (state: DangerousGoodVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchDangerousGoodVersionStatusesSuccess: (state: DangerousGoodVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.dangerousGoodVersionStatuses = action.payload;
        },
        fetchDangerousGoodVersionStatusesError: (state: DangerousGoodVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchDangerousGoodVersionStatusById: (state: DangerousGoodVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchDangerousGoodVersionStatusByIdSuccess: (state: DangerousGoodVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.dangerousGoodVersionStatus = action.payload;
        },
        fetchDangerousGoodVersionStatusByIdError: (state: DangerousGoodVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateDangerousGoodVersionStatus: (state: DangerousGoodVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updateDangerousGoodVersionStatusSuccess: (state: DangerousGoodVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.dangerousGoodVersionStatus = action.payload;
        },
        updateDangerousGoodVersionStatusError: (state: DangerousGoodVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateDangerousGoodVersionStatus: (state: DangerousGoodVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivateDangerousGoodVersionStatusSuccess: (state: DangerousGoodVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.dangerousGoodVersionStatus = action.payload;
        },
        deactivateDangerousGoodVersionStatusError: (state: DangerousGoodVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetDangerousGoodVersionStatuses: (state: DangerousGoodVersionStatusState) => {
            state.dangerousGoodVersionStatuses = initialState.dangerousGoodVersionStatuses;
        }
    }
});

export const { fetchDangerousGoodVersionStatusById, fetchDangerousGoodVersionStatusByIdSuccess, fetchDangerousGoodVersionStatusByIdError, updateDangerousGoodVersionStatus,
    updateDangerousGoodVersionStatusSuccess, updateDangerousGoodVersionStatusError, fetchDangerousGoodVersionStatuses, fetchDangerousGoodVersionStatusesSuccess,
    fetchDangerousGoodVersionStatusesError, deactivateDangerousGoodVersionStatus, deactivateDangerousGoodVersionStatusSuccess, deactivateDangerousGoodVersionStatusError,
    resetDangerousGoodVersionStatuses } = dangerousGoodVersionStatusSlice.actions;

export const isLoadingDangerousGoodVersionStatus = (state: RootState): boolean => state.dangerousGoodVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.dangerousGoodVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.dangerousGoodVersionStatusReducer.isDeleted;
export const getDangerousGoodVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.dangerousGoodVersionStatusReducer.dangerousGoodVersionStatus;
export const getDangerousGoodVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.dangerousGoodVersionStatusReducer.dangerousGoodVersionStatuses;

export default dangerousGoodVersionStatusSlice.reducer;