import { List } from 'immutable';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';
import { PriceListVersionOptions } from '../../../constants/priceListVersionOptions';

const messages = {
    noActiveVersion: LanguageUtils.createMessage('No active version error')
};

export default class PricelistVersionStep2Validation extends ValidationUtils {

    static validatePricelistForm(value: string, idActiveVersion: number | undefined): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        if(!idActiveVersion && value === PriceListVersionOptions.InheritFromActive) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.noActiveVersion);
        }

        return validationModel;
    }
}
