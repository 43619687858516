import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import SurchargeApi from '../api/surchargeApi';
import OrderUnitServiceProductSurchargeOut from '../interfaces/output/orderUnitServiceProductSurchargeOut';
import { createSurchargeError, createSurchargeSuccess, deleteSurchargeError, deleteSurchargeSuccess, fetchSurchargeByIdError, fetchSurchargeByIdSuccess, updateSurchargeError, updateSurchargeSuccess }
    from '../reducers/surchargeReducer';

function* doFetchSurcharge(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: OrderUnitServiceProductSurchargeOut = yield call(SurchargeApi.fetchSurcharge, id);
        yield put(fetchSurchargeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeByIdError(error as HttpErrorResponse));
    }
}

function* doCreateSurcharge(action: PayloadAction<OrderUnitServiceProductSurchargeOut>) {
    const service = action.payload;

    try {
        const response: OrderUnitServiceProductSurchargeOut = yield call(SurchargeApi.createSurcharge, service);

        yield put(createSurchargeSuccess(response));
    }

    catch (error: unknown) {
        yield put(createSurchargeError(error as HttpErrorResponse));
    }
}

function* doUpdateSurcharge(action: PayloadAction<OrderUnitServiceProductSurchargeOut>) {
    const unitService = action.payload;

    try {
        const response: OrderUnitServiceProductSurchargeOut = yield call(SurchargeApi.updateSurcharge, unitService);

        yield put(updateSurchargeSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateSurchargeError(error as HttpErrorResponse));
    }
}

function* doDeleteSurcharge(action: PayloadAction<number>) {
    const unitServiceId = action.payload;
    
    try {
        const response: OrderUnitServiceProductSurchargeOut = yield call(SurchargeApi.deactivateSurcharge, unitServiceId);

        yield put(deleteSurchargeSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteSurchargeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('surcharge/fetchSurchargeById', doFetchSurcharge),
    takeLatest('surcharge/createSurcharge', doCreateSurcharge),
    takeLatest('surcharge/updateSurcharge', doUpdateSurcharge),
    takeLatest('surcharge/deleteSurcharge', doDeleteSurcharge)
];

