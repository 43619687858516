import * as React from 'react';
import { List, ListItem, Drawer, ListItemIcon, ListItemText, IconButton, Theme, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import IMenuItem from '../../interfaces/common/menuItem';
import { useHistory } from 'react-router';
import { List as ImmutableList } from 'immutable';
import { ObjectType } from '../../constants/constants';
import { useIntl } from 'react-intl';

const drawerWidth = 250;
const drawerWidthSmall = 60;

interface ICollapsibleDrawer {
    menuItems?: ImmutableList<IMenuItem>;
    setOpen: (open: boolean) => void;
    open: boolean;
    objectType?: ObjectType;
}

interface ICollapsibleDrawerStyle {
    borderColor: string;
}

const useStyles = makeStyles<Theme, ICollapsibleDrawerStyle>((theme: Theme) => ({
    drawerContainer: {
        overflow: 'auto'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerSmall: {
        width: drawerWidthSmall,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: theme.spacing(16),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    drawerPaperSmall: {
        width: drawerWidthSmall,
        marginTop: theme.spacing(16),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    arrowButton: {
        marginTop: theme.spacing(2.6),
        '&.hover': {
            backgroundColor: 'none'
        },
        '&.active': {
            backgroundColor: 'none'
        }
    },
    drawerHeader:
        {
            display: 'flex',
            alignItems: 'center',
            ...theme.mixins.toolbar
        },
    drawerHeaderClosed: {
        justifyContent: 'flex-end'
    },
    drawerHeaderOpened: {
        justifyContent: 'flex-start'
    },
    openButton: {
        float: 'left'
    },
    firstCategory: (props: ICollapsibleDrawerStyle) => ({
        marginTop: `${theme.spacing(7)}!important`,
        border: '1px #ececec solid !important',
        '&.Mui-selected.MuiButtonBase-root.MuiListItem-root': {
            // backgroundColor: theme.palette.common.white,
            backgroundColor: `${props.borderColor}`,
            color: theme.palette.common.white,
            borderRadius: '5%'
        },
        icon: {
            color: theme.palette.common.white
        }
    }),
    secondCategory: (props: ICollapsibleDrawerStyle) => ({
        border: '1px #ececec solid !important',
        '&.Mui-selected.MuiButtonBase-root.MuiListItem-root': {
            // backgroundColor: theme.palette.common.white,
            backgroundColor: `${props.borderColor}!important`,
            color: theme.palette.common.white,
            borderRadius: '5%'
        }
    }),
    icon: {
        color: `${theme.palette.common.white}!important`
    }
}));

export default function CollapsibleDrawer(props: ICollapsibleDrawer) : JSX.Element {
    const { menuItems, setOpen, open } = props;
    const { palette } = useTheme();
    const { formatMessage } = useIntl();

    const getBorderColor = () : string => {
        const { objectType } = props;
        switch (objectType) {
            case ObjectType.Agreement:
                return palette.agreement.main;
            case ObjectType.Customer:
                return palette.customer.main;
            case ObjectType.FactorSets:
                return palette.factorSet.main;
            case ObjectType.PriceList:
                return palette.priceList.main;
            case ObjectType.AgreementVersionSpecialPrice:
                return palette.specialPrice.main;
            case ObjectType.AgreementVersionTransitDiscount:
                return palette.discount.main;
            case ObjectType.Product:
                return palette.product.main;
            case ObjectType.Order:
                return palette.order.main;
            case ObjectType.OrderUnit:
                return palette.order.main;
            case ObjectType.OrderUnitService:
                return palette.order.main;
            case ObjectType.OrderUnitServiceProduct:
                return palette.order.main;
            case ObjectType.OrderUnitServiceProductSurcharge:
                return palette.order.main;
            case ObjectType.GtsOrder:
                return palette.gtsOrder.main;
            case ObjectType.GtsOrderUnit:
                return palette.gtsOrder.main;
            case ObjectType.GtsOrderUnitDangerousGoods:
                return palette.gtsOrder.main;
            case ObjectType.GtsOrderUnitService:
                return palette.gtsOrder.main;
            case ObjectType.GtsOrderUnitDepot:
                return palette.gtsOrder.main;
            case ObjectType.Service:
                return palette.service.main;
            case ObjectType.Invoice:
                return palette.invoice.main;
            case ObjectType.InvoiceLine:
                return palette.invoiceLines.main;
            case ObjectType.Simulation:
                return palette.simulation.main;
            case ObjectType.SurchargeRate:
                return palette.surchargeRate.main;
            case ObjectType.User:
                return palette.user.main;
            case ObjectType.Terminal:
                return palette.terminal.main;
            case ObjectType.ProductArena:
                return palette.productArena.main;
            case ObjectType.ResponsibilityCenter:
                return palette.responsibilityCenter.main;
            default:
                return '';
        }
    };
    const classes = useStyles({ borderColor: getBorderColor() } as ICollapsibleDrawerStyle);

    const history = useHistory();
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const onSelect = (menuItem: IMenuItem) => {
        if(menuItem.url) {
            history.push(menuItem.url);
        }
    };
    
    return (
        <Drawer
            className={open ? classes.drawer : classes.drawerSmall}
            variant="persistent"
            anchor="left"
            open
            classes={{ paper: open ? classes.drawerPaper : classes.drawerPaperSmall }}
        >
            <div className={classes.drawerContainer} />
            <List>
                {menuItems?.filter((menuItem: IMenuItem) => (!menuItem.isHidden || menuItem.isHidden() === false)).map((menuItem: IMenuItem) => (
                    <ListItem button key={menuItem.id} selected={menuItem.isSelected === true} onClick={() => onSelect(menuItem)}
                        className={menuItem.additionalMargin ? classes.firstCategory : classes.secondCategory}
                    >
                        <ListItemIcon className={menuItem.isSelected ? classes.icon : ''}>{menuItem.icon}</ListItemIcon>
                        <ListItemText primary={formatMessage(menuItem.name)} />
                    </ListItem>
                ))}
            </List>
            <div className={open ? clsx(classes.drawerHeaderClosed, classes.drawerHeader) : classes.drawerHeader}>
                {open ?
                    <IconButton onClick={handleDrawerToggle}>
                        <ChevronLeftIcon className={classes.arrowButton} />
                    </IconButton>
                    :
                    <IconButton onClick={handleDrawerToggle}>
                        <ChevronRightIcon className={clsx(classes.arrowButton, classes.openButton)} />
                    </IconButton>}
            </div>
        </Drawer>
    );
}