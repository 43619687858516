import { http } from '../utils/http';
import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';
import Order from '../interfaces/output/order';
import OrderUnit from '../interfaces/output/orderUnit';
import OrderFilters from '../interfaces/filters/orderFilters';
import Pagination from '../interfaces/common/pagination';
import { OrderStatusId } from '../constants/statusConstants';
import moment from 'moment';
import OrderUnitService from '../interfaces/output/orderUnitService';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OrderApi {
    static fetchOrderById = async(orderId: string): Promise<Order> => {
        const { data } = await http.get<Order>(`orders/${orderId}`);

        return data;
    };

    static fetchOrders = async(paging: Paging, filters: OrderFilters): Promise<Array<Order>> => {
        const formattedFilters = {
            ...filters,
            fromDate: filters.fromDate ? moment(filters.fromDate).toISOString() : undefined,
            toDate: filters.toDate ? moment(filters.toDate).toISOString() : undefined,
            hasErrors: !filters.hasErrors ? undefined : filters.hasErrors
        };
        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/orders');

        const { data } = await http.get<Array<Order>>(url);

        return data;
    };

    static fetchUnitsByOrderId = async(orderId: number, paging: Paging): Promise<Pagination<OrderUnit>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `orders/${orderId}/units`);
        const { data } = await http.get<Pagination<OrderUnit>>(url);

        return data;
    };

    static fetchUnitServicesByOrderId = async(orderId: number, paging: Paging): Promise<Pagination<OrderUnitService>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `orders/${orderId}/unitservices`);
        const { data } = await http.get<Pagination<OrderUnitService>>(url);

        return data;
    };

    static updateOrder = async(order: Order): Promise<Order> => {
        const newOrder = {
            orderNumber: order.orderNumber,
            orderDate: order.orderDate,
            customerId: order.customer?.id,
            unitGroupId: order.unitGroup?.id,
            statusId: order.status?.id,
            invoiceTypeId: order.invoiceType?.id,
            basedOnOrderId: order.basedOnOrderId,
            timeReceivedFromGTS: order.timeReceivedFromGTS,
            importExportCodeId: order.importExportCode?.id,
            vatCodeId: order.vatCode?.id,
            fromTerminalId: order.fromTerminal?.id,
            toTerminalId: order.toTerminal?.id
        };

        const { data } = await http.put<any, Order>(`orders/${order.id}`, newOrder);

        return data;
    }

    static createOrder = async(order: Order): Promise<Order> => {
        const newOrder = {
            orderNumber: order.orderNumber,
            orderDate: order.orderDate,
            customerId: order.customer?.id,
            unitGroupId: order.unitGroup?.id,
            statusId: OrderStatusId.InProgress,
            invoiceTypeId: order.invoiceType?.id,
            basedOnOrderId: order.basedOnOrderId,
            timeReceivedFromGTS: order.timeReceivedFromGTS,
            importExportCodeId: order.importExportCode?.id,
            vatCodeId: order.vatCode?.id,
            fromTerminalId: order.fromTerminal?.id,
            toTerminalId: order.toTerminal?.id
        };

        const { data } = await http.post<any, Order>('orders', newOrder);

        return data;
    }

    static deactivateOrder = async(order: Order): Promise<Order> => {
        const newOrder = {
            orderNumber: order.orderNumber,
            orderDate: order.orderDate,
            customerId: order.customer?.id,
            unitGroupId: order.unitGroup?.id,
            statusId: order.status?.id,
            invoiceTypeId: order.invoiceType?.id,
            basedOnOrderId: order.basedOnOrderId,
            timeReceivedFromGTS: order.timeReceivedFromGTS,
            importExportCodeId: order.importExportCode?.id,
            vatCodeId: order.vatCode?.id,
            romTerminalId: order.fromTerminal?.id,
            toTerminalId: order.toTerminal?.id
        };

        const { data } = await http.put<any, Order>(`orders/${order.id}`, newOrder);

        return data;
    }

    static creditOrder = async(id: number, createNewCredit: boolean, creditReason: string): Promise<Order> => {
        const url = urlcat('', '/orders/credit', {
            orderId: id,
            newInvoice: createNewCredit,
            creditReason
        });

        const { data } = await http.put<any, Order>(url);

        return data;
    }

    static findOrderPrice = async(orderId: number): Promise<number> => {
        const url = `orders/findOrderPrice?orderId=${orderId}`;

        const { data } = await http.put<any, number>(url);

        return data;
    }

    static duplicateOrder = async(orderId: number): Promise<number> => {
        const url = `orders/duplicateOrder?orderId=${orderId}`;

        const { data } = await http.put<any, number>(url);

        return data;
    }
}
