import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import OUSStatus from '../interfaces/output/ousStatus';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OusStatusApi {
    static fetchOUSStatusById = async(id: string): Promise<OUSStatus> => {
        const { data } = await http.get<OUSStatus>(`/ousstatus/${id}`);

        return data;
    };

    static fetchOUSStatuses = async(paging: Paging): Promise<Pagination<OUSStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/ousstatus');
        const { data } = await http.get<Pagination<OUSStatus>>(url);

        return data;
    };

    static createOUSStatus = async(ousStatus: OUSStatus): Promise<OUSStatus> => {
        const newOUSStatus = {
            id: ousStatus.id,
            name: ousStatus.name,
            code: ousStatus.code
        };

        const { data } = await http.post<any, OUSStatus>('ousstatus', newOUSStatus);

        return data;
    };

    static updateOUSStatus = async(ousStatus: OUSStatus): Promise<OUSStatus> => {
        const newOUSStatus = {
            id: ousStatus.id,
            name: ousStatus.name,
            code: ousStatus.code
        };
        
        const { data } = await http.put<any, OUSStatus>(`ousstatus/${ousStatus.id}`, newOUSStatus);

        return data;
    };

    static deleteOUSStatus = async(ousStatus: OUSStatus): Promise<OUSStatus> => {
        const { data } = await http.delete<OUSStatus>(`ousstatus/${ousStatus.id}`);

        return data;
    };
}
