import React, { forwardRef } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { Grid, InputAdornment } from '@mui/material';
import Loader from '../common/widgets/loader';
import WorkflowUtils from '../../utils/workflowUtils';
import { useSelector } from 'react-redux';
import GenericTextField from '../common/widgets/genericTextField';
import AgreementVersionPricelist from '../../interfaces/output/agreementVersionPricelist';
import { isCreatingAgreementPricelist, isLoadingAgreementVersionPriceList, isUpdatingAgreementPricelist } from '../../reducers/agreementVersionPriceListReducer';

interface AddAgreementStep1Props {
    agreementVersionPricelist: AgreementVersionPricelist;
    onChange: any;
}

const messages = {
    discount: LanguageUtils.createMessage('Discount')
};

const AddAgreementVersionPricelistStep3 = forwardRef((props: AddAgreementStep1Props, ref: any): JSX.Element => {
    const { agreementVersionPricelist, onChange } = props;
    const isLoadingObjects = [useSelector(isLoadingAgreementVersionPriceList), useSelector(isCreatingAgreementPricelist), useSelector(isUpdatingAgreementPricelist)] as Array<boolean>;
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="discount"
                        type="number"
                        label={messages.discount}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        value={agreementVersionPricelist?.discount}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddAgreementVersionPricelistStep3;