import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { Card, CardContent } from '@mui/material';
import AddCustomerAgreementForm from './addCustomerAgreementForm';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { resetCustomers } from '../../reducers/customerReducer';
import { resetAgreements } from '../../reducers/agreementsReducer';
import { FormType } from '../../constants/constants';

interface IAddCustomerAgreementProps {
    classes: any;
    theme: Theme;
    match: any;
    resetCustomers: any;
    resetAgreements: any;
    steps: Array<number>;
    customerId?: number;
    agreementId?: number;
    customerAgreementId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetCustomers: () => dispatch(resetCustomers()),
    resetAgreements: () => dispatch(resetAgreements())
});

const mapStoreToProps = (store: RootState, ownProps: IAddCustomerAgreementProps) => {
    return {
        customerId: ownProps.match.params?.customerId ? Number(ownProps.match.params?.customerId) : undefined,
        agreementId: ownProps.match.params?.agreementId ? Number(ownProps.match.params?.agreementId) : undefined,
        customerAgreementId: ownProps.match.params?.customerAgreementId ? Number(ownProps.match.params?.customerAgreementId) : undefined
    };
};

class AddCustomerAgreement extends Component<IAddCustomerAgreementProps> {

    componentWillUnmount() {
        const { resetCustomers, resetAgreements } = this.props;

        resetCustomers();
        resetAgreements();
    }

    render() {
        const { classes, steps, customerId, agreementId, customerAgreementId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddCustomerAgreementForm customerId={customerId} agreementId={agreementId} customerAgreementId={customerAgreementId} steps={steps} type={type}
                        />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddCustomerAgreement));