import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme, Button } from '@mui/material';
import Customer from '../../interfaces/output/customer';
import { Card } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import DisplayStatus from '../common/displayStatus';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import { useDispatch, useSelector } from 'react-redux';
import { isSyncingCustomer, syncCustomerGts } from '../../reducers/customerReducer';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';

interface IViewCustomerProps {
    classes?: any;
    theme?: Theme;
    customer: Customer;
    setCurrentComponentId?: any;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Customer details'),
    name: LanguageUtils.createMessage('Name'),
    corporate: LanguageUtils.createMessage('Customer corporate'),
    group: LanguageUtils.createMessage('Group'),
    country: LanguageUtils.createMessage('Country'),
    status: LanguageUtils.createMessage('Status'),
    invoicing: LanguageUtils.createMessage('Invoicing'),
    frequency: LanguageUtils.createMessage('Invoice frequency'),
    separation: LanguageUtils.createMessage('Invoice separation'),
    emailInvoice: LanguageUtils.createMessage('Email invoice'),
    paymentDeadline: LanguageUtils.createMessage('Payment deadline'),
    visibleInGTS: LanguageUtils.createMessage('Visible in gts'),
    gtsId: LanguageUtils.createMessage('GTS customer number'),
    financeDetails: LanguageUtils.createMessage('Finance details'),
    dynamicsId: LanguageUtils.createMessage('Dynamics id'),
    vatId: LanguageUtils.createMessage('Vat id'),
    vatCode: LanguageUtils.createMessage('Vat code'),
    days: LanguageUtils.createMessage('Days'),
    GTS: LanguageUtils.createMessage('Gts'),
    segment: LanguageUtils.createMessage('Segment'),
    seller: LanguageUtils.createMessage('Seller'),
    lastSynced: LanguageUtils.createMessage('Last synced'),
    sync: LanguageUtils.createMessage('Sync'),
    emailCsv: LanguageUtils.createMessage('Emails Csv'),
    language: LanguageUtils.createMessage('Language'),
    sendPDF: LanguageUtils.createMessage('Send PDF'),
    sendCSV: LanguageUtils.createMessage('Send CSV'),
    nextInvoiceDate: LanguageUtils.createMessage('Next invoice date'),
    lastInvoiceDate: LanguageUtils.createMessage('last invoice date'),
    unitGroup: LanguageUtils.createMessage('Customer Unit group'),
    hideFromKundefront: LanguageUtils.createMessage('Hide from kundefront')

};

export default function ViewCustomer(props: IViewCustomerProps): JSX.Element {
    const classes = styles();
    const { customer, setCurrentComponentId } = props;
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const isSyncingGts = useSelector(isSyncingCustomer);
    const syncGts = () => {
        dispatch(syncCustomerGts(ensure(customer.id)));
    };

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.CUSTOMER_PROPERTIES_MAIN);
        }
    }, []);

    return (
        <Grid container alignItems="flex-start">
            <Grid item md={12}>
                <Card className={classes.root} >
                    <CardContent className={classes.cardContent}>
                        <Accordion expanded>
                            <AccordionSummary expandIcon={<span />}
                                aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                            >
                                <Typography className={classes.heading}>{formatMessage(messages.details)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.name} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.corporate} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.corporate?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.group} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.group?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.segment} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.segment?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.seller} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.seller?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.unitGroup} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.unitGroup?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.country} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.country?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.language} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.language?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.hideFromKundefront} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.hideFromKundefront?.toString() || '-'}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                className={classes.accordion}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                aria-label="expand"
                            >
                                <Typography className={classes.heading}><FormattedMessage {...messages.invoicing} /></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.frequency} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <Typography gutterBottom>{customer.invoiceFrequency?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.separation} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.invoiceSeparation?.name || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.emailInvoice} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.emailAddressInvoice || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.sendPDF} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.specificationAttachPDF?.toString()}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.emailCsv} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.emailAddressCSV || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.sendCSV} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.specificationAttachCSV?.toString()}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.paymentDeadline} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.paymentDeadline?.days} <FormattedMessage {...messages.days} /></Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.nextInvoiceDate} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>
                                            <NullableFormattedDate value={customer.nextInvoiceDate} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.lastInvoiceDate} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>
                                            <NullableFormattedDate value={customer.lastInvoiceDate} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                className={classes.accordion}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                aria-label="expand"
                            >
                                <Typography className={classes.heading}><FormattedMessage {...messages.GTS} /></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.gtsId} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9} >
                                        <Typography gutterBottom>{customer.gtsId || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.status} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <DisplayStatus status={customer.status} />
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.visibleInGTS} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.visibleInGTS?.toString()}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.lastSynced} />: </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom>
                                            <NullableFormattedDate value={customer.lastSyncWithGTS} />
                                            <Button sx={{ marginLeft: 5 }} color="info"
                                                variant="contained"
                                                onClick={syncGts}
                                                title={formatMessage(messages.sync)}
                                                disabled={isSyncingGts}
                                            >
                                                <FontAwesomeIcon icon="sync" spin={isSyncingGts} />
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                className={classes.accordion}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                aria-label="expand"
                            >
                                <Typography className={classes.heading}><FormattedMessage {...messages.financeDetails} /></Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.dynamicsId} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.dynamicsId || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.vatId} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.vatId || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={3} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.vatCode} />: </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography gutterBottom>{customer.vatCode?.name || '-'}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}