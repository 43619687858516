import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import OrderUnitStatus from '../interfaces/output/orderUnitStatus';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class UnitStatusApi {
    static fetchUnitStatusById = async(id: string): Promise<OrderUnitStatus> => {
        const { data } = await http.get<OrderUnitStatus>(`/orderunitstatus/${id}`);

        return data;
    };

    static fetchUnitStatuses = async(paging: Paging): Promise<Pagination<OrderUnitStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/orderunitstatus');

        const { data } = await http.get<Pagination<OrderUnitStatus>>(url);

        return data;
    };

    static createUnitStatus = async(orderunitstatus: OrderUnitStatus): Promise<OrderUnitStatus> => {
        const newOUSStatus = {
            id: orderunitstatus.id,
            name: orderunitstatus.name,
            code: orderunitstatus.code
        };

        const { data } = await http.post<any, OrderUnitStatus>('orderunitstatus', newOUSStatus);

        return data;
    };

    static updateUnitStatus = async(orderunitstatus: OrderUnitStatus): Promise<OrderUnitStatus> => {
        const newOUSStatus = {
            id: orderunitstatus.id,
            name: orderunitstatus.name,
            code: orderunitstatus.code
        };
        
        const { data } = await http.put<any, OrderUnitStatus>(`orderunitstatus/${orderunitstatus.id}`, newOUSStatus);

        return data;
    };

    static deleteUnitStatus = async(orderunitstatus: OrderUnitStatus): Promise<OrderUnitStatus> => {
        const { data } = await http.delete<OrderUnitStatus>(`orderunitstatus/${orderunitstatus.id}`);

        return data;
    };
}
