import React, { forwardRef } from 'react';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import LanguageUtils from '../../../utils/LanguageUtils';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isCreatingGtsOrder, isLoadingGtsOrder, isUpdatingGtsOrder } from '../../../reducers/gtsOrderReducer';
import GtsOrder from '../../../interfaces/output/gtsOrder';
import GenericTextField from '../../common/widgets/genericTextField';
import Customer from '../../../interfaces/output/customer';
import { fetchCustomers, getCustomers, isLoadingCustomers } from '../../../reducers/customerReducer';
import CustomerFilters from '../../../interfaces/output/filters';
import PageUtils from '../../../utils/pageUtils';
import { useIntl } from 'react-intl';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import AddGtsOrderStep1Validation from './validations/addGtsOrderStep1Validation';

interface AddOrderStep1Props {
    gtsOrder: GtsOrder;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    ORDER_DATE: 'orderDate'
};

const messages = {
    orderNo: LanguageUtils.createMessage('Order number'),
    customerNo: LanguageUtils.createMessage('GTS_CUSTOMER_NUMBER'),
    customer: LanguageUtils.createMessage('Customer'),
    unitGroupCode: LanguageUtils.createMessage('Unit group code'),
    orderDate: LanguageUtils.createMessage('Order date')
};

const AddGtsOrderStep1 = forwardRef((props: AddOrderStep1Props, ref: any): JSX.Element => {
    const { gtsOrder, onChange, showValidationError } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const customers = useSelector(getCustomers).content;
    const [inputValue, setInputValue] = React.useState('');
    const customerFilters = {
        customerNoName: inputValue
    } as CustomerFilters;
    const isLoadingObjects = [useSelector(isCreatingGtsOrder), useSelector(isLoadingGtsOrder), useSelector(isUpdatingGtsOrder)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    WorkflowUtils.setHandle(ref, null);
    const { formatMessage } = useIntl();

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.ORDER_DATE: {
                isValid = AddGtsOrderStep1Validation.validateDateFormat(gtsOrder.orderDate);
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth disabled>
                        <GenericTextField<string>
                            name="customerNo"
                            label={messages.customerNo}
                            value={gtsOrder.customerNumber}
                            disabled
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="orderNumber"
                            label={messages.orderNo}
                            value={gtsOrder.orderNumber}
                            onChange={onChange}
                            type="number"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={gtsOrder?.customer}
                            onChange={(e, value: any) => onChange('customer', value)}
                            id="controllable-states-demo"
                            getOptionLabel={(option: Customer) => option.customerNoName}
                            filterSelectedOptions
                            inputValue={inputValue}
                            onInputChange={(event, newInputvalue: any) => {
                                setInputValue(newInputvalue);
                                if(newInputvalue.length > 2) {
                                    dispatch(fetchCustomers({ paging,
                                        filters: { ...customerFilters,
                                            customerNoName: newInputvalue },
                                        throttle: true }));
                                }
                            }}
                            filterOptions={(x) => x}
                            options={customers}
                            loading={propsIsLoadingCustomers}
                            renderInput={(params) => <TextField {...params} label={formatMessage(messages.customer)} variant="standard" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="orderDate"
                            label={messages.orderDate}
                            value={gtsOrder?.orderDate}
                            error={setValidationState(ATTRIBUTES.ORDER_DATE)}
                            onChange={onChange}
                            type="end"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="unitGroupCode"
                            label={messages.unitGroupCode}
                            value={gtsOrder.unitGroupCode}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddGtsOrderStep1;