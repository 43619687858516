import { http } from '../utils/http';
import Status from '../interfaces/output/customerStatus';
import Pagination from '../interfaces/common/pagination';

export default class StatusApi {
    static fetchStatusById = async(statusId: string): Promise<Status> => {
        const { data } = await http.get<Status>(`customerstatus/${statusId}`);

        return data;
    };

    static fetchStatuses = async(): Promise<Pagination<Status>> => {
        const { data } = await http.get<Pagination<Status>>('/customerstatus');

        return data;
    };
}
