import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import Service from '../interfaces/output/service';
import ServiceApi from '../api/serviceApi';
import { createServiceError, createServiceSuccess, deleteServiceError, deleteServiceSuccess,
    fetchServiceByIdError, fetchServiceByIdSuccess, fetchServiceOverviewPdfError, fetchServiceOverviewPdfSuccess, fetchServicesError, fetchServicesSuccess,
    updateServiceError, updateServiceSuccess } from '../reducers/serviceReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ServiceFilters from '../interfaces/output/serviceFilters';
import ServiceOverviewFilters from '../interfaces/filters/serviceOverviewPdfFilter';
import FileSaver from 'file-saver';

function* doFetchService(action: PayloadAction<string>) {
    const serviceId = action.payload;
    try {
        const response: Service = yield call(ServiceApi.fetchServiceById, serviceId);
        yield put(fetchServiceByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceByIdError(error as HttpErrorResponse));
    }
}

function* doFetchServices(action: PayloadAction<{ paging: Paging, filters: ServiceFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<Service> = yield call(ServiceApi.fetchServices, paging, filters);
        yield put(fetchServicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServicesError(error as HttpErrorResponse));
    }
}

function* doUpdateService(action: PayloadAction<Service>) {
    const service = action.payload;

    try {
        const response: Service = yield call(ServiceApi.updateService, service);

        yield put(updateServiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateServiceError(error as HttpErrorResponse));
    }
}

function* doCreateService(action: PayloadAction<Service>) {
    const service = action.payload;

    try {
        const response: Service = yield call(ServiceApi.createService, service);

        yield put(createServiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(createServiceError(error as HttpErrorResponse));
    }
}

function* doDeleteService(action: PayloadAction<Service>) {
    const service = action.payload;
    
    try {
        const response: Service = yield call(ServiceApi.deleteService, service);
        yield put(deleteServiceSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteServiceError(error as HttpErrorResponse));
    }
}

function* doFetchServiceOverview(action: PayloadAction<{filters: ServiceOverviewFilters}>) {
    const { filters } = action.payload;

    try {
        const response: Blob = yield call(ServiceApi.fetchServiceOverview, filters);
        FileSaver.saveAs(response, 'service-overview.pdf');

        yield put(fetchServiceOverviewPdfSuccess());
    }

    catch (error: unknown) {
        yield put(fetchServiceOverviewPdfError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('service/fetchServiceById', doFetchService),
    takeLatest('service/fetchServices', doFetchServices),
    takeLatest('service/updateService', doUpdateService),
    takeLatest('service/createService', doCreateService),
    takeLatest('service/deleteService', doDeleteService),
    takeLatest('service/fetchServiceOverviewPdf', doFetchServiceOverview)
];