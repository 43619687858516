import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetPriorityLineOutput from '../interfaces/output/factorSetPriorityLineOut';
import FactorSetPriorityLineInput from '../interfaces/input/factorSetPriorityLineIn';
import PriorityLineFilters from '../components/factorSets/priority/line/priorityLineFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PriorityLineApi {
    static fetchFactorSetPriorityLineById = async(lineId: number): Promise<FactorSetPriorityLineOutput> => {
        const { data } = await http.get<FactorSetPriorityLineOutput>(`factorsetprioritylines/${lineId}`);

        return data;
    };

    static fetchFactorSetPriorityLines = async(paging: Paging, filters: PriorityLineFilters): Promise<Pagination<FactorSetPriorityLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsetprioritylines');

        const { data } = await http.get<Pagination<FactorSetPriorityLineOutput>>(url);

        return data;
    };

    static createFactorSetPriorityLine = async(factorSetLine: FactorSetPriorityLineOutput): Promise<FactorSetPriorityLineOutput> => {
        const newFactorSetPriority: FactorSetPriorityLineInput = {
            name: factorSetLine.name,
            priorityTypeId: factorSetLine.priorityType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            unitTypeId: factorSetLine.unitType?.id,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };

        const { data } = await http.post<FactorSetPriorityLineInput, FactorSetPriorityLineOutput>('factorsetprioritylines', newFactorSetPriority);

        return data;
    }

    static updateFactorSetPriorityLine = async(factorSetLine: FactorSetPriorityLineOutput): Promise<FactorSetPriorityLineOutput> => {
        const newLine: FactorSetPriorityLineInput = {
            name: factorSetLine.name,
            priorityTypeId: factorSetLine.priorityType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            unitTypeId: factorSetLine.unitType?.id,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };
        
        const { data } = await http.put<FactorSetPriorityLineInput, FactorSetPriorityLineOutput>(`factorsetprioritylines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetPriorityLine = async(lineId: number): Promise<FactorSetPriorityLineOutput> => {
        const { data } = await http.delete<FactorSetPriorityLineOutput>(`factorsetprioritylines/${lineId}`);

        return data;
    }
}