import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import SurchargeRateApi from '../api/surchargeRateApi';
import { createSurchargeRateError, createSurchargeRateSuccess, deleteSurchargeRateError,
    deleteSurchargeRateSuccess, fetchSurchargeRateByIdError, fetchSurchargeRateByIdSuccess,
    fetchSurchargeRatesError, fetchSurchargeRatesSuccess, updateSurchargeRateError, updateSurchargeRateSuccess } from '../reducers/surchargeRateReducer';
import SurchargeRate from '../interfaces/output/surchargeRate';
import SurchargeRateFilters from '../interfaces/filters/surchargeRateFilters';

function* doFetcSurchargeRate(action: PayloadAction<string>) {
    const surchargeRateId = action.payload;
    try {
        const response: SurchargeRate = yield call(SurchargeRateApi.fetchSurcharteRateById, surchargeRateId);
        yield put(fetchSurchargeRateByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeRateByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSurchargeRates(action: PayloadAction<{ paging: Paging, filters: SurchargeRateFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<SurchargeRate> = yield call(SurchargeRateApi.fetchSurchargeRates, paging, filters);
        yield put(fetchSurchargeRatesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeRatesError(error as HttpErrorResponse));
    }
}

function* doUpdateSurchargeRate(action: PayloadAction<SurchargeRate>) {
    const surchargeRate = action.payload;

    try {
        const response: SurchargeRate = yield call(SurchargeRateApi.updateSurchargeRate, surchargeRate);

        yield put(updateSurchargeRateSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateSurchargeRateError(error as HttpErrorResponse));
    }
}

function* doCreateSurchargeRate(action: PayloadAction<SurchargeRate>) {
    const surchargeRate = action.payload;

    try {
        const response: SurchargeRate = yield call(SurchargeRateApi.createSurchargeRate, surchargeRate);

        yield put(createSurchargeRateSuccess(response));
    }

    catch (error: unknown) {
        yield put(createSurchargeRateError(error as HttpErrorResponse));
    }
}

function* doDeleteSurchargeRate(action: PayloadAction<SurchargeRate>) {
    const surchargeRate = action.payload;
    
    try {
        const response: SurchargeRate = yield call(SurchargeRateApi.deleteSurchargeRate, surchargeRate);

        yield put(deleteSurchargeRateSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteSurchargeRateError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('surchargeRate/fetchSurchargeRateById', doFetcSurchargeRate),
    takeLatest('surchargeRate/fetchSurchargeRates', doFetchSurchargeRates),
    takeLatest('surchargeRate/updateSurchargeRate', doUpdateSurchargeRate),
    takeLatest('surchargeRate/createSurchargeRate', doCreateSurchargeRate),
    takeLatest('surchargeRate/deleteSurchargeRate', doDeleteSurchargeRate)
];