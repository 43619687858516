import React, { useEffect } from 'react';
import AddPriceListStep1 from './pricelist/addPriceListStep1';
import { useDispatch, useSelector } from 'react-redux';
import {
    createPriceList, createPriceListVersion, fetchPriceListById, getPriceList, getPriceLists, isCreatingPriceList, isCreatingPricelistVersion, isUpdatingPriceList,
    updatePriceList, fetchPriceLists, isLoadingPriceLists
} from '../../reducers/priceListReducer';
import GenericStepper from '../common/widgets/genericStepper';
import GenericStep from '../../interfaces/common/genericStep';
import PriceList from '../../interfaces/output/priceList';
import UrlConstants from '../../constants/UrlConstants';
import WorkflowUtils from '../../utils/workflowUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';
import AddPriceListVersionStep1 from './pricelistVersion/addPriceListVersionStep1';
import { updatePriceListVersion } from '../../reducers/priceListVersionReducer';
import PriceListVersion from '../../interfaces/output/priceListVersions';
import PricelistStep1Validation from './validation/pricelistStep1Validation';
import PricelistVersionStep1Validation from './validation/pricelistVersionStep1Validation';
import PageUtils from '../../utils/pageUtils';
import PriceListsFilters from '../../interfaces/output/priceListFilters';
import Pagination from '../../interfaces/common/pagination';

interface IAddPriceListFormProps {
    priceListId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit price list'),
    add: LanguageUtils.createMessage('Add price list'),
    changeStatus: LanguageUtils.createMessage('Change priceList status'),
    changePriceListProducts: LanguageUtils.createMessage('Edit priceList products')
};

export default function AddPriceListForm(props: IAddPriceListFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { priceListId, steps, type } = props;
    const [priceList, setPriceList] = React.useState<PriceList>({
        unitGroup: { id: 1 },
        productType: { id: 1 },
        pricingComponentType: { id: 1 }
    } as PriceList);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsPriceList = useSelector(getPriceList);
    const propsIsUpdatingPriceList = useSelector(isUpdatingPriceList);
    const prevIsUpdatingPriceList = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriceList);
    const propsIsCreatingPriceList = useSelector(isCreatingPriceList);
    const prevIsCreatingPriceList = WorkflowUtils.usePrevious<boolean>(propsIsCreatingPriceList);
    const propsIsCreatingPricelistVersion = useSelector(isCreatingPricelistVersion);
    const prevIsCreatingPricelistVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingPricelistVersion);
    const propsIsLoadingPriceLists = useSelector(isLoadingPriceLists);
    const prevIsLoadingPriceLists = WorkflowUtils.usePrevious<boolean>(propsIsLoadingPriceLists);
    const propsPriceLists = useSelector(getPriceLists);
    const [priceLists, setPriceLists] = React.useState<Pagination<PriceList>>();

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingPriceList && !propsIsUpdatingPriceList) || (prevIsCreatingPriceList && !propsIsCreatingPriceList)) {
                    setRedirectCondition(true);
                }
                break;

            case 2:
                if(prevIsCreatingPricelistVersion === true && !propsIsCreatingPricelistVersion) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }

        return () => setRedirectCondition(false);
    }, [propsIsCreatingPriceList, propsIsUpdatingPriceList, propsIsCreatingPricelistVersion]);

    useEffect(() => {
        if(priceListId) {
            dispatch(fetchPriceListById({ id: priceListId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(propsPriceList.id) {
            setPriceList(propsPriceList);
        }
    }, [propsIsUpdatingPriceList, propsPriceList.id]);

    useEffect(() => {
        if(prevIsLoadingPriceLists === true && !propsIsLoadingPriceLists) {
            setPriceLists(propsPriceLists);
        }
    }, [propsIsLoadingPriceLists]);

    const onChange = (attribute: string, value: any, isVersion = false) => {
        const newPriceList = { ...priceList } as PriceList;
        if(isVersion) {
            const newPriceListVersion = { ...priceList.version } as PriceListVersion;
            (newPriceListVersion as any)[attribute] = value;
            newPriceList.version = newPriceListVersion;
        }
        else {
            (newPriceList as any)[attribute] = value;
        }

        setPriceList(newPriceList);
    };

    const fetchPriceListsDelay = () => {
        if(priceList?.name !== null || priceList?.name !== undefined || priceList?.name !== '') {
            const newFilters = { exactMatchName: priceList?.name } as PriceListsFilters;
            const newPaging = PageUtils.getDefaultPaging();

            dispatch(fetchPriceLists({
                paging: newPaging,
                filters: newFilters
            }));
        }
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => fetchPriceListsDelay(), 300);

        return () => clearTimeout(timeOutId);
    }, [priceList?.name]);

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <AddPriceListStep1 onChange={onChange} priceList={priceList}/>,
            validationFn: () => PricelistStep1Validation.validatePricelistForm(priceList, priceLists),
            onNext: () => {
                if(!priceList.id) {
                    dispatch(createPriceList(priceList));
                }
                else {
                    dispatch(updatePriceList(priceList));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddPriceListVersionStep1 onChange={(attribute: string, value: any) => onChange(attribute, value, true)} priceList={priceList} />,
            validationFn: () => PricelistVersionStep1Validation.validatePricelistForm(priceList),
            onNext: () => {
                if(!priceList.version?.id) {
                    dispatch(createPriceListVersion(priceList));
                }
                else {
                    dispatch(updatePriceListVersion(priceList.version));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.PRICE_LISTS}/${priceList.id}/latest`;

            case FormType.Edit:
                return priceList.version?.id ? `/${UrlConstants.PRICE_LISTS}/${priceList.id}/${priceList.version?.id}` : `/${UrlConstants.PRICE_LISTS}/${priceList.id}/latest`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
