import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import PageUtils from '../../../utils/pageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import Terminal from '../../../interfaces/output/terminal';
import { isCreatingTerminal } from '../../../reducers/terminalReducer';
import { fetchCountries, getCountries, isLoadingCountry } from '../../../reducers/countryReducer';
import { fetchTerminalOperators, getTerminalOperators, isLoadingTerminalOperator } from '../../../reducers/terminalOperatorReducer';
import TerminalStep1Validation from './validations/terminalStep1Validation';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Country from '../../../interfaces/output/countryOut';
import TerminalOperator from '../../../interfaces/output/terminalOperator';
import { useIntl } from 'react-intl';
import PriceListTerminal from '../../../interfaces/output/priceListTerminal';
import { fetchPriceListTerminals, getPriceListTerminals, isLoadingPriceListTerminal } from '../../../reducers/priceListTerminalReducer';
// import { useIntl } from 'react-intl';

interface ITerminalStep1Props {
    terminal: Terminal;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    NAME: 'name',
    GTS_TERMINAL_NO: 'gtsTerminalNo',
    GTS_TERMINAL_CODE: 'gtsTerminalCode',
    COUNTRY: 'country',
    OPERATOR: 'operator',
    DISTRIBUTION_RESPONSABILITY_CENTER: 'distributionResponsabilityCenter',
    TERMINAL_RESPONSABILITY_CENTER: 'terminalResponsabilityCenter',
    DEPOT_SERVICE: 'depotService',
    //CLUSTER_TERMINAL_ID: 'clusterTerminalId',
    PRICE_LIST_TERMINAL_ID: 'pricelistTerminalId'
};

const messages = {
    name: LanguageUtils.createMessage('Name'),
    gtsTerminalNo: LanguageUtils.createMessage('Gts terminal number'),
    gtsTerminalCode: LanguageUtils.createMessage('Gts terminal code'),
    country: LanguageUtils.createMessage('Country'),
    terminalOperator: LanguageUtils.createMessage('Terminal Operator'),
    distributionResponsabilityCenter: LanguageUtils.createMessage('Distribution responsability center'),
    terminalResponsabilityCenter: LanguageUtils.createMessage('Terminal responsability center'),
    depotService: LanguageUtils.createMessage('Depot service'),
    //clusterTerminaId: LanguageUtils.createMessage('Cluster terminal Id'),
    priceListTerminal: LanguageUtils.createMessage('Price list terminal')
};

const TerminalFormStep1 = forwardRef((props: ITerminalStep1Props, ref: any): JSX.Element => {
    const { terminal, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingTerminal), useSelector(isLoadingCountry),
        useSelector(isLoadingTerminalOperator), useSelector(isLoadingPriceListTerminal)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const terminalOperators = useSelector(getTerminalOperators).content;
    const countries = useSelector(getCountries).content;
    const priceListTerminals = useSelector(getPriceListTerminals).content;

    const intl = useIntl();
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCountries({ paging }));
        dispatch(fetchTerminalOperators({ paging }));
        dispatch(fetchPriceListTerminals({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.NAME: {
                isValid = TerminalStep1Validation.validateInputString(terminal.name);
                break;
            }
            case ATTRIBUTES.GTS_TERMINAL_NO: {
                isValid = TerminalStep1Validation.validateInputNumber(terminal.gtsTerminalNo);
                break;
            }
            case ATTRIBUTES.GTS_TERMINAL_CODE: {
                isValid = TerminalStep1Validation.validateInputString(terminal.gtsTerminalCode);
                break;
            }
            case ATTRIBUTES.OPERATOR: {
                isValid = TerminalStep1Validation.validateInputNumber(terminal.operator?.id);
                break;
            }
            case ATTRIBUTES.COUNTRY: {
                isValid = TerminalStep1Validation.validateInputNumber(terminal.country?.id);
                break;
            }
            case ATTRIBUTES.PRICE_LIST_TERMINAL_ID: {
                isValid = TerminalStep1Validation.validateInputNumber(terminal.pricelistTerminalId?.terminalId);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="name"
                        label={messages.name}
                        value={terminal.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="gtsTerminalNo"
                        label={messages.gtsTerminalNo}
                        value={terminal.gtsTerminalNo}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.GTS_TERMINAL_NO)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="gtsTerminalCode"
                        label={messages.gtsTerminalCode}
                        value={terminal.gtsTerminalCode}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.GTS_TERMINAL_CODE)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<Country>
                        options={countries}
                        value={terminal.country}
                        onChange={(obj: Country | null) => onChange('country', obj)}
                        placeholder={messages.country}
                        error={setValidationState(ATTRIBUTES.COUNTRY)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<TerminalOperator>
                        options={terminalOperators}
                        value={terminal.operator}
                        onChange={(obj: Terminal | null) => onChange('operator', obj)}
                        placeholder={messages.terminalOperator}
                        error={setValidationState(ATTRIBUTES.OPERATOR)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="distributionResponsabilityCenterCode"
                        label={messages.distributionResponsabilityCenter}
                        value={terminal.distributionResponsabilityCenterCode ?? terminal.distributionResponsabilityCenter?.code}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="terminalResponsabilityCenterCode"
                        label={messages.terminalResponsabilityCenter}
                        value={terminal.terminalResponsabilityCenterCode ?? terminal.terminalResponsabilityCenter?.code}
                        onChange={onChange}
                    />
                </Grid>
                {/* <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="clusterTerminaId"
                        label={messages.clusterTerminaId}
                        value={terminal.clusterTerminaId}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.CLUSTER_TERMINAL_ID)}
                        required
                    />
                </Grid> */}
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!terminal.depotServices}
                                onChange={(e: any, value: boolean) => onChange('depotServices', value)}
                            />}
                            label={intl.formatMessage(messages.depotService)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<PriceListTerminal>
                        options={priceListTerminals}
                        value={terminal.pricelistTerminalId}
                        onChange={(obj: PriceListTerminal | null) => onChange('pricelistTerminalId', obj)}
                        getOptionLabel={(option: PriceListTerminal) => option.terminalName || ''}
                        placeholder={messages.priceListTerminal}
                        error={setValidationState(ATTRIBUTES.PRICE_LIST_TERMINAL_ID)}
                        compareFn={(o: PriceListTerminal) => o.terminalId === terminal.pricelistTerminalId?.terminalId}
                        required
                    />
                </Grid>
            </Grid>
        </Loader >
    );
});

export default TerminalFormStep1;