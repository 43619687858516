import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import GtsOrderUnitApi from '../api/gtsOrderUnitApi';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import GTSOrderUnit from '../interfaces/output/gtsOrderUnit';
import { deactivateGtsOrderUnitError, deactivateGtsOrderUnitSuccess, fetchGtsOrderUnitByIdError, fetchGtsOrderUnitByIdSuccess, fetchGtsOrderUnitsError, fetchGtsOrderUnitsSuccess,
    updateGtsOrderUnitError, updateGtsOrderUnitSuccess } from '../reducers/gtsOrderUnitReducer';

function* doFetchGtsOrderUnit(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: GTSOrderUnit = yield call(GtsOrderUnitApi.fetchGtsOrderUnit, id);
        yield put(fetchGtsOrderUnitByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderUnitByIdError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOrderUnits(action: PayloadAction<{ paging: Paging, filters: GtsOrderUnitFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<GTSOrderUnit> = yield call(GtsOrderUnitApi.fetchGtsOrderUnits, paging, filters);
        yield put(fetchGtsOrderUnitsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderUnitsError(error as HttpErrorResponse));
    }
}

function* doUpdateGtsOrderUnits(action: PayloadAction<GTSOrderUnit>) {
    const gtsOrderUnit = action.payload;

    try {
        const response: GTSOrderUnit = yield call(GtsOrderUnitApi.updateGtsOrderUnit, gtsOrderUnit);

        yield put(updateGtsOrderUnitSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateGtsOrderUnitError(error as HttpErrorResponse));
    }
}

function* doDeactivateGtsOrderUnit(action: PayloadAction<number>) {
    const unitId = action.payload;
    
    try {
        const response: GTSOrderUnit = yield call(GtsOrderUnitApi.deactivateGtsOrderUnit, unitId);

        yield put(deactivateGtsOrderUnitSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateGtsOrderUnitError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('gtsOrderUnit/fetchGtsOrderUnitById', doFetchGtsOrderUnit),
    takeLatest('gtsOrderUnit/fetchGtsOrderUnits', doFetchGtsOrderUnits),
    takeLatest('gtsOrderUnit/updateGtsOrderUnit', doUpdateGtsOrderUnits),
    takeLatest('gtsOrderUnit/deactivateGtsOrderUnit', doDeactivateGtsOrderUnit)
];

