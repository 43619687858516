import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Paging from '../../../interfaces/common/paging';
import { ensure } from '../../../utils/arrayUtils';
import WorkflowUtils from '../../../utils/workflowUtils';
import { fetchPriceListVersions, getPriceListVersions, isLoadingPriceListVersions } from '../../../reducers/priceListReducer';
import PricelistVersionList from './pricelistVersionList';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

const messages = {
    back: LanguageUtils.createMessage('Back'),
    add: LanguageUtils.createMessage('Add price list version')
};

interface AddPriceListVersionStep3Props {
    priceListId?: number;
    selectedPriceListVersionId?: number;
    onChange?: any;
}

const AddPriceListVersionStep3 = forwardRef((props: AddPriceListVersionStep3Props, ref: any): JSX.Element => {
    const { priceListId, selectedPriceListVersionId, onChange } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const pricelistVersions = useSelector(getPriceListVersions);
    const propsLoadingPriceListVersions = useSelector(isLoadingPriceListVersions);
    WorkflowUtils.setHandle(ref, null);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        basePrice: false,
        onHold: false
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);

    useEffect(() => {
        dispatch(fetchPriceListVersions({
            id: ensure(priceListId),
            paging: paging
        }));
    }, [dispatch]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchPriceListVersions({
                paging: newServerPagination,
                id: ensure(priceListId)
            }));
            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={1} mt={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    <FormattedMessage {...messages.add} />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <PricelistVersionList
                    isLoading={propsLoadingPriceListVersions}
                    rows={pricelistVersions}
                    hiddenColumns={['basePrice', 'onHold']}
                    checkSelection={(value: number | undefined) => onChange(value)}
                    defaultSelectionModel={selectedPriceListVersionId ? [selectedPriceListVersionId] : undefined}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchPriceListVersions({
                            paging: newPage,
                            id: ensure(priceListId)
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddPriceListVersionStep3;