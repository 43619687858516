import { List } from 'immutable';
import InputLengthConstants from '../../../constants/inputLengthConstants';
import Customer from '../../../interfaces/output/customer';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils, { ValidationConfig } from '../../../utils/validationUtils';

const inputsMaxLengths = {
    name: InputLengthConstants.String200
};

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    wrongFormat: LanguageUtils.createMessage('Wrong email format')
};

export default class CustomerStep1Validation {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return ValidationUtils.validateInputStringLength(inputString, options);
    }

    static validateEmail(email?: string, multipleEmails = false): boolean {
        return ValidationUtils.validateEmail(email, multipleEmails);
    }

    static validateCustomerForm(customer: Customer): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            corporate,
            seller,
            segment,
            country,
            emailAddressInvoice,
            emailAddressCSV
        } = customer;

        const requiredFields =
            CustomerStep1Validation.validateInputString(name, {
                required: true,
                inputLength: inputsMaxLengths.name
            }) &&
            CustomerStep1Validation.validateInputString(corporate?.name) &&
            CustomerStep1Validation.validateInputString(seller?.name) &&
            CustomerStep1Validation.validateInputString(segment?.name) &&
            CustomerStep1Validation.validateInputString(country?.name) &&
            CustomerStep1Validation.validateInputString(emailAddressInvoice);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const acceptedFormat = CustomerStep1Validation.validateEmail(emailAddressInvoice) &&
            (emailAddressCSV ? CustomerStep1Validation.validateEmail(emailAddressCSV, true) : true);
        if(!acceptedFormat) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.wrongFormat);
        }

        return validationModel;
    }
}