import React from 'react';
import { FormControl, Button, Grid } from '@mui/material';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import GenericTextField from '../../common/widgets/genericTextField';
import Action from '../../../interfaces/common/action';
import ActionRenderer from '../../common/actionRenderer';
import InvoiceLineFiltersIn from '../../../interfaces/filters/invoiceLineFilters';

interface IFilterProps {
    filters?: InvoiceLineFiltersIn;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    hiddenColumns?: Array<string>;
    actions?: Array<Action>;
}

const messages = {
    orderNumber: LanguageUtils.createMessage('Order'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    serviceDescription: LanguageUtils.createMessage('Service description'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function InvoiceLineFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;

    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number | undefined | null>
                                name="orderNumber"
                                label={messages.orderNumber}
                                onChange={onChange}
                                value={filters?.orderNumber}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number | undefined | null>
                                name="unitNo"
                                label={messages.unitNo}
                                onChange={onChange}
                                value={filters?.unitNo}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string | undefined | null>
                                name="serviceDescription"
                                label={messages.serviceDescription}
                                onChange={onChange}
                                value={filters?.serviceDescription}
                            />
                        </FormControl>
                    </Grid>
                    <ActionRenderer actions={actions} />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}