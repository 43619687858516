import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import AccordionSummary from '../../common/accordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { ensure } from '../../../utils/arrayUtils';
import PageUtils from '../../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSurchargesByOrderUnitServiceProductId, getSurcharges, isLoadingUnitServiceProductSurcharges } from '../../../reducers/orderUnitServiceProductReducer';
import Paging from '../../../interfaces/common/paging';
import SurchargesList from '../../surcharges/surchargesList';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IViewOrderUnitServiceProductProps {
    classes?: any;
    theme?: Theme;
    orderUnitServiceProduct: OrderUnitServiceProduct;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Order unit service product details'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    product: LanguageUtils.createMessage('Product'),
    productPrice: LanguageUtils.createMessage('Product price'),
    amount: LanguageUtils.createMessage('Amount'),
    agreement: LanguageUtils.createMessage('Agreement'),
    calculatedPrice: LanguageUtils.createMessage('Calculated price'),
    pricelist: LanguageUtils.createMessage('Price list'),
    agreementVersion: LanguageUtils.createMessage('Agreement version'),
    pricelistVersion: LanguageUtils.createMessage('Price list version'),
    currency: LanguageUtils.createMessage('Currency'),
    surcharges: LanguageUtils.createMessage('SURCHARGES'),
    overriddenPrice: LanguageUtils.createMessage('Overridden price')
};

export default function ViewOrderUnitServiceProduct(props: IViewOrderUnitServiceProductProps): JSX.Element {
    const classes = styles();
    const { orderUnitServiceProduct } = props;
    const pagination = PageUtils.getDefaultPaging();
    const intl = useIntl();
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const propsIsLoadingUnitServiceProductSurcharges = useSelector(isLoadingUnitServiceProductSurcharges);
    const propsOrderUnitServiceProductSurcharges = useSelector(getSurcharges);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({ });

    useEffect(() => {
        if(orderUnitServiceProduct.id) {
            dispatch(fetchSurchargesByOrderUnitServiceProductId({
                paging: paging,
                id: ensure(orderUnitServiceProduct.id)
            }));
        }
    }, [orderUnitServiceProduct.id]);

    const _onSortModelChange = (newModel: GridSortModel) => {
        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };

            setPaging(newPagination);
            setSortModel(newModel);

            dispatch(fetchSurchargesByOrderUnitServiceProductId({
                id: ensure(orderUnitServiceProduct.id),
                paging: newPagination
            }));
        }
    };

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.orderUnitService?.orderUnit?.unitNo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.product} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.product?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.productPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.productPrice !== undefined && orderUnitServiceProduct.productPrice !== null &&
                                        intl.formatNumber(ensure(Math.round(orderUnitServiceProduct.productPrice)), {
                                            maximumFractionDigits: 0
                                        }) || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.calculatedPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.calculatedPrice || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.overriddenPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.overriddenPrice || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.amount} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.amount || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.agreement} />: </Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography gutterBottom>{orderUnitServiceProduct.agreement?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.agreementVersion} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.agreement?.version?.versionNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.pricelist} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.pricelist?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.pricelistVersion} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.pricelist?.version?.versionNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.currency} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitServiceProduct.currency?.name || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.surcharges} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <SurchargesList isLoading={propsIsLoadingUnitServiceProductSurcharges} rows={propsOrderUnitServiceProductSurcharges}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...pagination,
                                            page: nextPage,
                                            size: nextSize
                                        };

                                        dispatch(fetchSurchargesByOrderUnitServiceProductId({
                                            id: ensure(orderUnitServiceProduct.id),
                                            paging: newPage
                                        }));
                                        setPaging(newPage);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}