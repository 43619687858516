import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderFilters from '../interfaces/filters/orderFilters';
import OrderApi from '../api/orderApi';
import { createOrderError, createOrderSuccess, creditOrderError, creditOrderSuccess, duplicateOrderError, duplicateOrderSuccess, fetchOrderByIdError, fetchOrderByIdSuccess,
    fetchOrdersError, fetchOrdersSuccess,
    fetchUnitsByOrderIdError, fetchUnitsByOrderIdSuccess,
    fetchUnitServicesByOrderIdError,
    fetchUnitServicesByOrderIdSuccess,
    findOrderPriceError,
    findOrderPriceSuccess,
    updateOrderError, updateOrderSuccess } from '../reducers/orderReducer';
import Order from '../interfaces/output/order';
import OrderUnit from '../interfaces/output/orderUnit';
import OrderUnitService from '../interfaces/output/orderUnitService';

function* doFetchOrder(action: PayloadAction<string>) {
    const orderId = action.payload;

    try {
        const response: Order = yield call(OrderApi.fetchOrderById, orderId);
        yield put(fetchOrderByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrderByIdError(error as HttpErrorResponse));
    }
}

function* doUnitsByOrderId(action: PayloadAction<{paging: Paging, id: number}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<OrderUnit> = yield call(OrderApi.fetchUnitsByOrderId, id, paging);
    
        yield put(fetchUnitsByOrderIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitsByOrderIdError(error as HttpErrorResponse));
    }
}

function* doFetchUnitServicesByOrderId(action: PayloadAction<{paging: Paging, id: number}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<OrderUnitService> = yield call(OrderApi.fetchUnitServicesByOrderId, id, paging);
    
        yield put(fetchUnitServicesByOrderIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitServicesByOrderIdError(error as HttpErrorResponse));
    }
}

function* doFetchOrders(action: PayloadAction<{ paging: Paging, filters: OrderFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<Order> = yield call(OrderApi.fetchOrders, paging, filters);
        yield put(fetchOrdersSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersError(error as HttpErrorResponse));
    }
}

function* doCreateOrder(action: PayloadAction<Order>) {
    const order = action.payload;

    try {
        const response: Order = yield call(OrderApi.createOrder, order);

        yield put(createOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(createOrderError(error as HttpErrorResponse));
    }
}

function* doUpdateOrder(action: PayloadAction<Order>) {
    const gtsOrder = action.payload;

    try {
        const response: Order = yield call(OrderApi.updateOrder, gtsOrder);

        yield put(updateOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateOrderError(error as HttpErrorResponse));
    }
}

function* doCreditOrder(action: PayloadAction<{id: number, createNewCredit: boolean, creditReason: string }>) {
    const { id, createNewCredit, creditReason } = action.payload;

    try {
        const response: Order = yield call(OrderApi.creditOrder, id, createNewCredit, creditReason);
    
        yield put(creditOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(creditOrderError(error as HttpErrorResponse));
    }
}

function* doFindOrderPrice(action: PayloadAction<number>) {
    const orderId = action.payload;

    try {
        const response: number = yield call(OrderApi.findOrderPrice, orderId);
    
        yield put(findOrderPriceSuccess(response));
    }

    catch (error: unknown) {
        yield put(findOrderPriceError(error as HttpErrorResponse));
    }
}

function* doDuplicateOrder(action: PayloadAction<number>) {
    const orderId = action.payload;

    try {
        const response: number = yield call(OrderApi.duplicateOrder, orderId);
    
        yield put(duplicateOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(duplicateOrderError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('order/fetchOrderById', doFetchOrder),
    takeLatest('order/fetchUnitsByOrderId', doUnitsByOrderId),
    takeLatest('order/fetchUnitServicesByOrderId', doFetchUnitServicesByOrderId),
    takeLatest('order/fetchOrders', doFetchOrders),
    takeLatest('order/createOrder', doCreateOrder),
    takeLatest('order/updateOrder', doUpdateOrder),
    takeLatest('order/creditOrder', doCreditOrder),
    takeLatest('order/findOrderPrice', doFindOrderPrice),
    takeLatest('order/duplicateOrder', doDuplicateOrder)
];

