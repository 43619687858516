import React from 'react';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import MuiAccordionSummary, {
    AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface IViewGtsOrderUnitProps {
    classes?: any;
    theme?: Theme;
    gtsOrderUnit: GTSOrderUnit;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }: any) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)!important'
            : 'rgba(0, 0, 0, .03)!important',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const messages = {
    details: LanguageUtils.createMessage('Gts order unit details'),
    orderNo: LanguageUtils.createMessage('Order number'),
    gtsCustomerNo: LanguageUtils.createMessage('Gts customer number'),
    receivedFromGTS: LanguageUtils.createMessage('Received from gts'),
    errorMessage: LanguageUtils.createMessage('Error message'),
    trainNo: LanguageUtils.createMessage('Train number'),
    netWeight: LanguageUtils.createMessage('Net weight'),
    grossWeight: LanguageUtils.createMessage('Gross weight kg'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    departureTime: LanguageUtils.createMessage('Departure time'),
    departureCode: LanguageUtils.createMessage('Departure code'),
    gtsUnitType: LanguageUtils.createMessage('GTS unit type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    cancellationCode: LanguageUtils.createMessage('Cancellation code'),
    unitNo: LanguageUtils.createMessage('Unit number')
};

export default function ViewGtsOrderUnit(props: IViewGtsOrderUnitProps): JSX.Element {
    const classes = styles();
    const { gtsOrderUnit } = props;
    const intl = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.orderNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.orderNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.unitNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priorityType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.priorityType || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.gtsUnitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.gtsUnitType || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.unitType || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.netWeight} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.netWeightKg !== undefined && gtsOrderUnit.netWeightKg !== null &&
                                        intl.formatNumber(ensure(Math.round(gtsOrderUnit.netWeightKg)), {
                                            maximumFractionDigits: 0
                                        }) || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.grossWeight} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.grossWeightKg !== undefined && gtsOrderUnit.grossWeightKg !== null &&
                                        intl.formatNumber(ensure(Math.round(gtsOrderUnit.grossWeightKg)), {
                                            maximumFractionDigits: 0
                                        }) || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.trainNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography gutterBottom>{gtsOrderUnit.trainNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureTime} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>
                                        {<NullableFormattedDate value={gtsOrderUnit.departureTime} />}&nbsp;
                                        {gtsOrderUnit.departureTime ? <FormattedTime hour="numeric" minute="numeric" value={gtsOrderUnit.departureTime} /> : ' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureCode} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.departureTypeCode || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.cancellationCode} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.cancellationCode || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.receivedFromGTS} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={gtsOrderUnit.receivedFromGTS} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.errorMessage} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{gtsOrderUnit.errorMessage || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}