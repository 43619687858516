import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Service from '../interfaces/output/service';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ServiceFilters from '../interfaces/output/serviceFilters';
import ServiceOverviewFilters from '../interfaces/filters/serviceOverviewPdfFilter';

export type ServiceState = {
    service: Service;
    isLoadingService: boolean;
    isLoadingServices: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    services: Pagination<Service>;
    error: HttpErrorResponse | undefined;
    isLoadingServiceOverview: boolean;
};

const initialState: ServiceState = {
    service: {} as Service,
    services: { content: [] as Array<Service> } as Pagination<Service>,
    isLoadingService: false,
    isLoadingServices: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined,
    isLoadingServiceOverview: false
};

export const serviceSlice = createSlice({
    name: 'service',

    initialState,
    reducers: {
        fetchServices: (state: ServiceState, _action: PayloadAction<{paging: Paging, filters: ServiceFilters}>) => {
            state.isLoadingServices = true;
        },
        fetchServicesSuccess: (state: ServiceState, action: PayloadAction<Pagination<Service>>) => {
            state.isLoadingServices = false;
            state.services = action.payload;
        },
        fetchServicesError: (state: ServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServices = false;
            state.error = action.payload;
        },
        fetchServiceById: (state: ServiceState, _action: PayloadAction<number>) => {
            state.isLoadingService = true;
        },
        fetchServiceByIdSuccess: (state: ServiceState, action: PayloadAction<Service>) => {
            state.isLoadingService = false;
            state.service = action.payload;
        },
        fetchServiceByIdError: (state: ServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingService = false;
            state.error = action.payload;
        },
        createService: (state: ServiceState, _action: PayloadAction<Service>) => {
            state.isCreating = true;
        },
        createServiceSuccess: (state: ServiceState, action: PayloadAction<Service>) => {
            state.isCreating = false;
            state.service = action.payload;
        },
        createServiceError: (state: ServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateService: (state: ServiceState, _action: PayloadAction<Service>) => {
            state.isUpdating = true;
        },
        updateServiceSuccess: (state: ServiceState, action: PayloadAction<Service>) => {
            state.isUpdating = false;
            state.service = action.payload;
        },
        updateServiceError: (state: ServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteService: (state: ServiceState, _action: PayloadAction<Service>) => {
            state.isDeleted = true;
        },
        deleteServiceSuccess: (state: ServiceState, action: PayloadAction<Service>) => {
            state.isDeleted = false;
            state.service = action.payload;
        },
        deleteServiceError: (state: ServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetServices: (state: ServiceState) => {
            state.services = initialState.services;
        },
        resetService: (state: ServiceState) => {
            state.service = initialState.service;
        },
        fetchServiceOverviewPdf: (state: ServiceState, _action: PayloadAction<{filters: ServiceOverviewFilters}>) => {
            state.isLoadingServiceOverview = true;
        },
        fetchServiceOverviewPdfSuccess: (state: ServiceState) => {
            state.isLoadingServiceOverview = false;
            //state.services = action.payload;
        },
        fetchServiceOverviewPdfError: (state: ServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceOverview = false;
            state.error = action.payload;
        }
    }
});

export const { fetchServices, fetchServicesSuccess, fetchServicesError, fetchServiceById, fetchServiceByIdSuccess, fetchServiceByIdError,
    createService, createServiceSuccess, createServiceError, updateService, updateServiceSuccess, updateServiceError, deleteService,
    deleteServiceSuccess, deleteServiceError, resetServices, resetService, fetchServiceOverviewPdf, fetchServiceOverviewPdfSuccess, fetchServiceOverviewPdfError } = serviceSlice.actions;

export const isLoadingService = (state: RootState): boolean => state.serviceReducer.isLoadingService;
export const isLoadingServices = (state: RootState): boolean => state.serviceReducer.isLoadingServices;
export const isUpdatingService = (state: RootState): boolean => state.serviceReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.serviceReducer.isDeleted;
export const isCreatingService = (state: RootState): boolean => state.serviceReducer.isCreating;
export const getServices = (state: RootState): Pagination<Service> => state.serviceReducer.services;
export const getService = (state: RootState): Service => state.serviceReducer.service;
export const isLoadingServiceOverview = (state: RootState): boolean => state.serviceReducer.isLoadingServiceOverview;

export default serviceSlice.reducer;