import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import PriceListTerminal from '../interfaces/output/priceListTerminal';

export default class PriceListTerminalApi {
    static fetchPriceListTerminalById = async(priceListTerminalId: string): Promise<PriceListTerminal> => {
        const { data } = await http.get<PriceListTerminal>(`/pricelistterminal/${priceListTerminalId}`);

        return data;
    };

    static fetchPriceListTerminals = async(paging: Paging): Promise<Pagination<PriceListTerminal>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/pricelistterminal');

        const { data } = await http.get<Pagination<PriceListTerminal>>(url);

        return data;
    };
}