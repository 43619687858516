import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import ServiceType from '../interfaces/output/serviceType';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type ServiceTypeState = {
    serviceType: ServiceType;
    isLoadingServiceType: boolean;
    isLoadingServiceTypes: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    serviceTypes: Pagination<ServiceType>;
    error: HttpErrorResponse | undefined;
};

const initialState: ServiceTypeState = {
    serviceType: {} as ServiceType,
    serviceTypes: { content: [] as Array<ServiceType> } as Pagination<ServiceType>,
    isLoadingServiceType: false,
    isLoadingServiceTypes: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const serviceTypeSlice = createSlice({
    name: 'serviceType',

    initialState,
    reducers: {
        fetchServiceTypes: (state: ServiceTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingServiceTypes = true;
        },
        fetchServiceTypesSuccess: (state: ServiceTypeState, action: PayloadAction<Pagination<ServiceType>>) => {
            state.isLoadingServiceTypes = false;
            state.serviceTypes = action.payload;
        },
        fetchServiceTypesError: (state: ServiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceTypes = false;
            state.error = action.payload;
        },
        fetchServiceTypeById: (state: ServiceTypeState, _action: PayloadAction<number>) => {
            state.isLoadingServiceType = true;
        },
        fetchServiceTypeByIdSuccess: (state: ServiceTypeState, action: PayloadAction<ServiceType>) => {
            state.isLoadingServiceType = false;
            state.serviceType = action.payload;
        },
        fetchServiceTypeByIdError: (state: ServiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceType = false;
            state.error = action.payload;
        },
        createServiceType: (state: ServiceTypeState, _action: PayloadAction<ServiceType>) => {
            state.isCreating = true;
        },
        createServiceTypeSuccess: (state: ServiceTypeState, action: PayloadAction<ServiceType>) => {
            state.isCreating = false;
            state.serviceType = action.payload;
        },
        createServiceTypeError: (state: ServiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateServiceType: (state: ServiceTypeState, _action: PayloadAction<ServiceType>) => {
            state.isUpdating = true;
        },
        updateServiceTypeSuccess: (state: ServiceTypeState, action: PayloadAction<ServiceType>) => {
            state.isUpdating = false;
            state.serviceType = action.payload;
        },
        updateServiceTypeError: (state: ServiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteServiceType: (state: ServiceTypeState, _action: PayloadAction<ServiceType>) => {
            state.isDeleted = true;
        },
        deleteServiceTypeSuccess: (state: ServiceTypeState, action: PayloadAction<ServiceType>) => {
            state.isDeleted = false;
            state.serviceType = action.payload;
        },
        deleteServiceTypeError: (state: ServiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        }
    }
});

export const { fetchServiceTypes, fetchServiceTypesSuccess, fetchServiceTypesError, fetchServiceTypeById, fetchServiceTypeByIdSuccess, fetchServiceTypeByIdError,
    createServiceType, createServiceTypeSuccess, createServiceTypeError, updateServiceType, updateServiceTypeSuccess, updateServiceTypeError, deleteServiceType,
    deleteServiceTypeSuccess, deleteServiceTypeError } = serviceTypeSlice.actions;

export const isLoadingServiceType = (state: RootState): boolean => state.serviceTypeReducer.isLoadingServiceType;
export const isLoadingServiceTypes = (state: RootState): boolean => state.serviceTypeReducer.isLoadingServiceTypes;
export const isUpdating = (state: RootState): boolean => state.serviceTypeReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.serviceTypeReducer.isDeleted;
export const isCreating = (state: RootState): boolean => state.serviceTypeReducer.isCreating;
export const getServiceTypes = (state: RootState): Pagination<ServiceType> => state.serviceTypeReducer.serviceTypes;
export const getServiceType = (state: RootState): ServiceType => state.serviceTypeReducer.serviceType;

export default serviceTypeSlice.reducer;