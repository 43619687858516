import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import FactorSetHoursLineOut from '../interfaces/output/factorSetHoursLineOut';
import FactorSetHoursLineIn from '../interfaces/input/factorSetHoursLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetHoursVersionApi {
    static fetchFactorSetHoursVersionById = async(factorSetVersionId: string): Promise<FactorSetVersionOut> => {
        const { data } = await http.get<FactorSetVersionOut>(`factorsets/hours/versions/${factorSetVersionId}`);

        return data;
    };

    static fetchFactorSetHoursVersions = async(factorId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/hours/${factorId}/versions`);

        const { data } = await http.get<Array<FactorSetVersionOut>>(url);

        return data;
    };

    static createFactorSetHoursVersionLines= async(versionLine: FactorSetHoursLineOut): Promise<FactorSetHoursLineOut> => {
        const newLine: FactorSetHoursLineIn = {
            name: versionLine.name,
            fromHours: versionLine.fromHours,
            toHours: versionLine.toHours,
            factor: versionLine.factor,
            surcharge: versionLine.surcharge,
            monday: versionLine.monday,
            sunday: versionLine.sunday,
            tuesday: versionLine.tuesday,
            wednesday: versionLine.wednesday,
            thursday: versionLine.thursday,
            friday: versionLine.friday,
            saturday: versionLine.saturday
        };

        const { data } = await http.post<FactorSetHoursLineIn, FactorSetHoursLineOut>( `factorsets/hours/versions/${versionLine.factorSetVersionId}/lines`, newLine);

        return data;
    }

    static updateFactorSetHoursVersionStatus = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/hours/versions/${factorSetVersion.id}/status/${factorSetVersion.status?.id}`);

        return data;
    }

    static updateFactorSetHoursVersion = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSetVersion.validFromDate,
            validToDate: factorSetVersion.validToDate,
            statusId: factorSetVersion.status?.id
        };
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/hours/versions/${factorSetVersion.id}`, newVersion);

        return data;
    }

    static cloneFactorSetHoursVersion = async(toVersionId: number, fromVersionId: number): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/hours/versions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}