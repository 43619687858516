import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetCalendarVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetCalendarVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerCalendarVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchCalendarVersionById: (state: FactorSetCalendarVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchCalendarVersionByIdSuccess: (state: FactorSetCalendarVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchCalendarVersionByIdError: (state: FactorSetCalendarVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCalendarVersions: (state: FactorSetCalendarVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchCalendarVersionsSuccess: (state: FactorSetCalendarVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchCalendarVersionsError: (state: FactorSetCalendarVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updateCalendarVersionStatus: (state: FactorSetCalendarVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updateCalendarVersionStatusSuccess: (state: FactorSetCalendarVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updateCalendarVersionStatusError: (state: FactorSetCalendarVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updateCalendarVersion: (state: FactorSetCalendarVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updateCalendarVersionSuccess: (state: FactorSetCalendarVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updateCalendarVersionError: (state: FactorSetCalendarVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneCalendarVersion: (state: FactorSetCalendarVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        cloneCalendarVersionSuccess: (state: FactorSetCalendarVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        cloneCalendarVersionError: (state: FactorSetCalendarVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetCalendarVersion: (state: FactorSetCalendarVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetCalendarVersions: (state: FactorSetCalendarVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchCalendarVersionById, fetchCalendarVersionByIdSuccess, fetchCalendarVersionByIdError, updateCalendarVersionStatus, updateCalendarVersionStatusSuccess,
    updateCalendarVersionStatusError, cloneCalendarVersion, updateCalendarVersion, cloneCalendarVersionSuccess, fetchCalendarVersions, fetchCalendarVersionsSuccess, fetchCalendarVersionsError,
    updateCalendarVersionSuccess, updateCalendarVersionError, resetCalendarVersion, cloneCalendarVersionError, resetCalendarVersions } = customerCalendarVersionSlice.actions;

export const isLoadingCalendarVersion = (state: RootState): boolean => state.factorSetCalendarVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetCalendarVersionReducer.isCloningVersion;
export const isUpdatingCalendarVersionStatus = (state: RootState): boolean => state.factorSetCalendarVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetCalendarVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetCalendarVersionReducer.isUpdatingVersion;
export const getCalendarVersion = (state: RootState): FactorSetVersionOut => state.factorSetCalendarVersionReducer.factorSetVersion;
export const isLoadingFactorSetCalendarVersions = (state: RootState): boolean => state.factorSetCalendarVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetCalendarVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetCalendarVersionReducer.factorSetVersions;

export default customerCalendarVersionSlice.reducer;