import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import SurchargeRate from '../../../interfaces/output/surchargeRate';
import { createSurchargeRate, fetchSurchargeRateById, getSurchargeRate, isCreatingSurchargeRate,
    isLoadingSurchargeRate, isUpdatingSurchargeRate, updateSurchargeRate } from '../../../reducers/surchargeRateReducer';
import SurchargeRateFormStep1 from './surchargeRateFormStep1';
import SurchargeRateStep1Validation from './validations/surchargeRateStep1Validation';

interface ISurchargeRateFormProps {
    surchargeRateId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit surcharge rate'),
    add: LanguageUtils.createMessage('Add surcharge rate')
};

export default function SurchargeRateForm(props: ISurchargeRateFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { surchargeRateId, steps, type } = props;
    const [surchargeRate, setSurchargeRate] = React.useState<SurchargeRate>({ id: surchargeRateId } as SurchargeRate);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsSurchargeRate = useSelector(getSurchargeRate);
    const propsIsUpdatingSurchargeRate = useSelector(isUpdatingSurchargeRate);
    const prevIsUpdatingSurchargeRate = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingSurchargeRate);
    const propsIsCreatingSurchargeRate = useSelector(isCreatingSurchargeRate);
    const prevIsCreatingnSurchargeRate = WorkflowUtils.usePrevious<boolean>(propsIsCreatingSurchargeRate);
    const propsIsLoadingSurchargeRate = useSelector(isLoadingSurchargeRate);
    const prevIsLoadingSurchargeRate = WorkflowUtils.usePrevious<boolean>(propsIsLoadingSurchargeRate);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingSurchargeRate === true && !propsIsUpdatingSurchargeRate) || (prevIsCreatingnSurchargeRate && !propsIsLoadingSurchargeRate)) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingSurchargeRate, propsIsUpdatingSurchargeRate]);
    
    useEffect(() => {
        if(surchargeRateId) {
            dispatch(fetchSurchargeRateById(surchargeRateId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(prevIsLoadingSurchargeRate && !propsIsLoadingSurchargeRate) {
            setSurchargeRate(propsSurchargeRate);
        }

    }, [propsIsLoadingSurchargeRate]);

    const onChange = (attribute: string, value: any) => {
        const newSurchargeRate = { ...surchargeRate } as SurchargeRate;
        (newSurchargeRate as any)[attribute] = value;

        setSurchargeRate(newSurchargeRate);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <SurchargeRateFormStep1 onChange={onChange} surchargeRate={surchargeRate} />,
            validationFn: () => SurchargeRateStep1Validation.validateSurchargeRateForm(surchargeRate),
            onNext: () => {
                if(!surchargeRate.id) {
                    dispatch(createSurchargeRate(surchargeRate));
                }
                else {
                    dispatch(updateSurchargeRate(surchargeRate));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.SURCHARGE_RATES}`;
            
            case FormType.Edit:
                return `/${UrlConstants.SURCHARGE_RATES}/${surchargeRate.id}`;
            
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  