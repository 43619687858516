import LanguageApi from '../api/languageApi';
import { put, takeLatest, call } from 'redux-saga/effects';
import Country from '../interfaces/output/countryOut';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import Language from '../interfaces/output/language';
import { fetchLanguageByIdError, fetchLanguageByIdSuccess, fetchLanguagesError, fetchLanguagesSuccess } from '../reducers/languageReducer';

function* doFetchLanguage(action: PayloadAction<string>) {
    const languageId = action.payload;
    try {
        const response: Language = yield call(LanguageApi.fetchLanguageById, languageId);
        yield put(fetchLanguageByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchLanguageByIdError(error as HttpErrorResponse));
    }
}

function* doFetchLanguages() {
    try {
        const response: Pagination<Country> = yield call(LanguageApi.fetchLanguages);
        yield put(fetchLanguagesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchLanguagesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('language/fetchLanguageById', doFetchLanguage),
    takeLatest('language/fetchLanguages', doFetchLanguages)
];

