import React, { forwardRef, useEffect } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LanguageUtils from '../../../utils/LanguageUtils';
import Loader from '../../common/widgets/loader';
import PageUtils from '../../../utils/pageUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import GenericTextField from '../../common/widgets/genericTextField';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isCreatingSurcharge, isUpdatingSurcharge } from '../../../reducers/surchargeReducer';
import { fetchPricingComponentTypes, getPricingComponentTypes } from '../../../reducers/pricingComponentTypeReducer';
import PricingComponentTypeOut from '../../../interfaces/output/pricingComponentTypeOut';
import OrderUnitServiceProductSurchargeOut from '../../../interfaces/output/orderUnitServiceProductSurchargeOut';
import AddOrderUnitServiceProductSurchargeStep1Validation from './validation/addOrderUnitServiceProductSurchargeStep1Validation';

interface AddOrderUnitServiceProductSurchargeStep1Props {
    orderUnitServiceProductSurcharge: OrderUnitServiceProductSurchargeOut;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    surchargeAmount: LanguageUtils.createMessage('Amount'),
    pricingComponentType: LanguageUtils.createMessage('Pricing component type')
};

const ATTRIBUTES = {
    PRICING_COMPONENT_TYPE: 'pricingComponentType'
};

const AddOrderUnitServiceProductSurchargeStep1 = forwardRef((props: AddOrderUnitServiceProductSurchargeStep1Props, ref: any): JSX.Element => {
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const isLoadingObjects = [useSelector(isCreatingSurcharge), useSelector(isUpdatingSurcharge)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const pricingComponentTypes = useSelector(getPricingComponentTypes).content;
    WorkflowUtils.setHandle(ref, null);
    
    const { orderUnitServiceProductSurcharge, onChange , showValidationError } = props;

    useEffect(() => {
        dispatch(fetchPricingComponentTypes({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.PRICING_COMPONENT_TYPE: {
                isValid = AddOrderUnitServiceProductSurchargeStep1Validation.validateInputString(orderUnitServiceProductSurcharge?.pricingComponentType?.name);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            type="number"
                            name="surchargeAmount"
                            label={messages.surchargeAmount}
                            value={orderUnitServiceProductSurcharge.surchargeAmount}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PricingComponentTypeOut>
                            options={pricingComponentTypes}
                            value={orderUnitServiceProductSurcharge.pricingComponentType}
                            onChange={(obj: PricingComponentTypeOut | null) => onChange('pricingComponentType', obj)}
                            placeholder={messages.pricingComponentType}
                            required
                            error={setValidationState(ATTRIBUTES.PRICING_COMPONENT_TYPE)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddOrderUnitServiceProductSurchargeStep1;