import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import PriorityType from '../interfaces/output/priorityType';

export default class PriorityTypeApi {
    static fetchPriorityTypes = async(): Promise<Pagination<PriorityType>> => {
        const { data } = await http.get<Pagination<PriorityType>>('/prioritytypes');

        return data;
    };
}
