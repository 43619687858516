import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import DisplayStatus from '../common/displayStatus';
import InvoiceOut from '../../interfaces/output/invoiceOut';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface IViewInvoiceProps {
    classes?: any;
    theme?: Theme;
    invoice: InvoiceOut;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Invoice details'),
    invoiceNumber: LanguageUtils.createMessage('Invoice number'),
    customer: LanguageUtils.createMessage('Customer'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    invoiceFormat: LanguageUtils.createMessage('Invoice format'),
    status: LanguageUtils.createMessage('Status'),
    invoiceDate: LanguageUtils.createMessage('Invoice date'),
    paymentDeadline: LanguageUtils.createMessage('Payment deadline'),
    currency: LanguageUtils.createMessage('Currency'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    d365_invoiceNumber: LanguageUtils.createMessage('D365 Invoice number')
};

export default function ViewInvoice(props: IViewInvoiceProps): JSX.Element {
    const classes = styles();
    const { invoice } = props;

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.invoiceNumber} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.invoiceNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.d365_invoiceNumber} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.d365_InvoiceNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.customer} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.customer?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.invoiceType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.invoiceType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.invoiceFormat} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.invoiceFormat?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.invoiceDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={invoice.invoiceDate} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.paymentDeadline} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.paymentDeadline?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.status} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <DisplayStatus status={invoice.invoiceStatus} />
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.currency} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.currency?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.totalPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoice.totalPrice || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}