import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import Service from '../interfaces/output/service';
import ServiceFilters from '../interfaces/output/serviceFilters';
import { ServiceStatusId } from '../constants/statusConstants';

import SortFormatterUtils from '../utils/sortFormatterUtils';
import ServiceOverviewFilters from '../interfaces/filters/serviceOverviewPdfFilter';
import urlcat from 'urlcat';
import { ContentTypeConstants } from '../constants/browserConstants';

export default class ServiceApi {
    static fetchServiceById = async(pricelistTypeId: string): Promise<Service> => {
        const { data } = await http.get<Service>(`/services/${pricelistTypeId}`);

        return data;
    };

    static fetchServices = async(paging: Paging, filters: ServiceFilters): Promise<Pagination<Service>> => {
       
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/services');

        const { data } = await http.get<Pagination<Service>>(url);

        return data;
    };

    static fetchServiceOverview = async(filters: ServiceOverviewFilters): Promise<Blob> => {
       
        const url = urlcat('', '/services/report/pdf', filters);

        const { data } = await http.getFile<Blob>(url, ContentTypeConstants.pdf);

        return data;
    };

    static createService = async(service: Service): Promise<Service> => {
        const newService = {
            id: service.id,
            serviceTypeId: service.type?.id,
            fromTerminalId: service.fromTerminal?.id,
            toTerminalId: service.toTerminal?.id,
            importExportCodeId: service.importExportCode?.id,
            unitGroupId: service.unitGroup?.id,
            serviceAlternative: service.serviceAlternative,
            serviceStatusId: ServiceStatusId.InProgress
        };

        const { data } = await http.post<any, Service>('services', newService);

        return data;
    };

    static updateService = async(service: Service): Promise<Service> => {
        const newService = {
            id: service.id,
            serviceTypeId: service.type?.id,
            fromTerminalId: service.fromTerminal?.id,
            toTerminalId: service.toTerminal?.id,
            importExportCodeId: service.importExportCode?.id,
            unitGroupId: service.unitGroup?.id,
            serviceAlternative: service.serviceAlternative,
            serviceStatusId: service.status?.id
        };
        
        const { data } = await http.put<any, Service>(`services/${service.id}`, newService);

        return data;
    };

    static deleteService = async(service: Service): Promise<Service> => {
        const { data } = await http.delete<any, Service>(`services/${service.id}`);

        return data;
    };
}
