import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import PaymentDeadline from '../interfaces/output/paymentDeadline';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type PaymentDeadlineState = {
    deadline: PaymentDeadline;
    isLoading: boolean;
    deadlines: Pagination<PaymentDeadline>;
    error: HttpErrorResponse | undefined;
};

const initialState: PaymentDeadlineState = {
    deadline: {} as PaymentDeadline,
    deadlines: { content: [] as Array<PaymentDeadline> } as Pagination<PaymentDeadline>,
    isLoading: false,
    error: undefined
};

export const deadlineSlice = createSlice({
    name: 'deadline',

    initialState,
    reducers: {
        fetchPaymentDeadlines: (state: PaymentDeadlineState) => {
            state.isLoading = true;
        },
        fetchPaymentDeadlinesSuccess: (state: PaymentDeadlineState, action: PayloadAction<Pagination<PaymentDeadline>>) => {
            state.isLoading = false;
            state.deadlines = action.payload;
        },
        fetchPaymentDeadlinesError: (state: PaymentDeadlineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchPaymentDeadlineById: (state: PaymentDeadlineState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchPaymentDeadlineByIdSuccess: (state: PaymentDeadlineState, action: PayloadAction<PaymentDeadline>) => {
            state.isLoading = false;
            state.deadline = action.payload;
        },
        fetchPaymentDeadlineByIdError: (state: PaymentDeadlineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchPaymentDeadlines, fetchPaymentDeadlinesSuccess, fetchPaymentDeadlinesError, fetchPaymentDeadlineById,
    fetchPaymentDeadlineByIdSuccess, fetchPaymentDeadlineByIdError } = deadlineSlice.actions;

export const isLoadingPaymentDeadline = (state: RootState): boolean => state.deadlineReducer.isLoading;
export const getPaymentDeadlines = (state: RootState): Pagination<PaymentDeadline> => state.deadlineReducer.deadlines;
export const getPaymentDeadline = (state: RootState): PaymentDeadline => state.deadlineReducer.deadline;

export default deadlineSlice.reducer;