import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import ProductArena from '../interfaces/output/productArena';
import Paging from '../interfaces/common/paging';
import ProductArenaFilters from '../interfaces/filters/productArenaFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ProductArenaApi {
    static fetchProductArena = async(productArenaId: string): Promise<ProductArena> => {
        const { data } = await http.get<ProductArena>(`productarena/${productArenaId}`);

        if(data.terminals !== undefined && data.terminals !== null) {
            data.terminalIds = data.terminals?.map(({ id }) => id as number);
        }

        return data;
    };

    static fetchProductArenas = async(paging: Paging, filters?: ProductArenaFilters): Promise<Pagination<ProductArena>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/productarena');

        const { data } = await http.get<Pagination<ProductArena>>(url);

        return data;
    };

    static createProductArena = async(productArena: ProductArena): Promise<ProductArena> => {
        const newProductArena = {
            name: productArena.nameEng,
            nameNo: productArena.nameNo,
            code: productArena.code,
            productArenaTypeId: productArena.productArenaType?.id,
            unitGroupId: productArena.productArenaType?.id,
            terminals: productArena?.terminalIds,
            d365ResponsibilityCenter: productArena.d365ResponsibilityCenterCode ?? productArena.d365ResponsibilityCenter?.code
        };

        const { data } = await http.post<any, ProductArena>('productarena', newProductArena);

        return data;
    }

    static updateProductArena = async(productArena: ProductArena): Promise<ProductArena> => {
        const newProductArena = {
            name: productArena.nameEng,
            nameNo: productArena.nameNo,
            code: productArena.code,
            productArenaTypeId: productArena.productArenaType?.id,
            unitGroupId: productArena.productArenaType?.id,
            terminals: productArena?.terminalIds,
            d365ResponsibilityCenter: productArena.d365ResponsibilityCenterCode ?? productArena.d365ResponsibilityCenter?.code
        };

        const { data } = await http.put<any, ProductArena>(`productarena/${productArena.id}`, newProductArena);

        return data;
    }
}