import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetCancellationVersionApi from '../api/factorSetCancellationVersionApi';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { cloneCancellationVersionError, cloneCancellationVersionSuccess, fetchCancellationVersionByIdError,
    fetchCancellationVersionByIdSuccess, fetchCancellationVersionsError, fetchCancellationVersionsSuccess, updateCancellationVersionError, updateCancellationVersionStatusError,
    updateCancellationVersionStatusSuccess, updateCancellationVersionSuccess } from '../reducers/factorSetCancellationVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchFactorSetCancellationVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetCancellationVersionApi.fetchFactorSetCancellationVersionById, agreementVersionId);

        yield put(fetchCancellationVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetCancellationVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetCancellationVersionApi.fetchFactorSetCancellationVersions, id, paging);
        yield put(fetchCancellationVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCancellationVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetCancellationVersionApi.updateFactorSetCancellationVersionStatus, agreementVersion);

        yield put(updateCancellationVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCancellationVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCancellationVersion(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetCancellationVersionApi.updateFactorSetCancellationVersion, agreementVersion);

        yield put(updateCancellationVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCancellationVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetCancellationVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetCancellationVersionApi.cloneFactorSetCancellationVersion, toVersionId, fromVersionId);

        yield put(cloneCancellationVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneCancellationVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchCancellationVersionById', doFetchFactorSetCancellationVersion),
    takeLatest('factorSetVersion/fetchCancellationVersions', doFetchFactorSetCancellationVersions),
    takeLatest('factorSetVersion/updateCancellationVersionStatus', doUpdateFactorSetCancellationVersionStatus),
    takeLatest('factorSetVersion/updateCancellationVersion', doUpdateFactorSetCancellationVersion),
    takeLatest('factorSetVersion/cloneCancellationVersion', doCloneFactorSetCancellationVersion)
];