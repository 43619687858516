import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import GTSOrderUnit from '../interfaces/output/gtsOrderUnit';
import GTSOrderUnitDangerousGoods from '../interfaces/output/gtsOrderUnitDangerousGoods';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type GTSOrderUnitDangerousGoodsState = {
    dangerousGood: GTSOrderUnitDangerousGoods;
    dangerousGoods: Pagination<GTSOrderUnitDangerousGoods>;
    isLoadingDangerousGoods: boolean;
    isLoadingDangerousGood: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: GTSOrderUnitDangerousGoodsState = {
    dangerousGood: {} as GTSOrderUnitDangerousGoods,
    dangerousGoods: { content: [] as Array<GTSOrderUnitDangerousGoods> } as Pagination<GTSOrderUnitDangerousGoods>,
    isLoadingDangerousGoods: false,
    isLoadingDangerousGood: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const dangerousGoodsSlice = createSlice({
    name: 'dangerousGoods',

    initialState,
    reducers: {
        fetchGtsOrderUnitDangerousGoods: (state: GTSOrderUnitDangerousGoodsState, _action: PayloadAction<{paging: Paging, filters: GtsOrderUnitFilters}>) => {
            state.isLoadingDangerousGoods = true;
        },
        fetchGtsOrderUnitDangerousGoodsSuccess: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<Pagination<GTSOrderUnitDangerousGoods>>) => {
            state.isLoadingDangerousGoods = false;
            state.dangerousGoods = action.payload;
        },
        fetchGtsOrderUnitDangerousGoodsError: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDangerousGoods = false;
            state.error = action.payload;
        },
        fetchGtsOrderUnitDangerousGoodsById: (state: GTSOrderUnitDangerousGoodsState, _action: PayloadAction<number>) => {
            state.isLoadingDangerousGood = true;
        },
        fetchGtsOrderUnitDangerousGoodsByIdSuccess: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<GTSOrderUnitDangerousGoods>) => {
            state.isLoadingDangerousGood = false;
            state.dangerousGood = action.payload;
        },
        fetchGtsOrderUnitDangerousGoodsByIdError: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDangerousGood = false;
            state.error = action.payload;
        },
        updateGtsOrderUnitDangerousGoods: (state: GTSOrderUnitDangerousGoodsState, _action: PayloadAction<GTSOrderUnitDangerousGoods>) => {
            state.isUpdating = true;
        },
        updateGtsOrderUnitDangerousGoodsSuccess: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<GTSOrderUnitDangerousGoods>) => {
            state.isUpdating = false;
            state.dangerousGood = action.payload;
        },
        updateGtsOrderUnitDangerousGoodsError: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateGtsOrderUnitDangerousGoods: (state: GTSOrderUnitDangerousGoodsState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateGtsOrderUnitDangerousGoodsSuccess: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<GTSOrderUnitDangerousGoods>) => {
            state.isDeleted = false;
            state.dangerousGood = action.payload;
        },
        deactivateGtsOrderUnitDangerousGoodsError: (state: GTSOrderUnitDangerousGoodsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetGtsOrderUnitsDangerousGoods: (state: GTSOrderUnitDangerousGoodsState) => {
            state.dangerousGoods = initialState.dangerousGoods;
        },
        resetGtsOrderUnitDangerousGoods: (state: GTSOrderUnitDangerousGoodsState) => {
            state.dangerousGood = initialState.dangerousGood;
        }
    }
});

export const { fetchGtsOrderUnitDangerousGoods, fetchGtsOrderUnitDangerousGoodsSuccess, fetchGtsOrderUnitDangerousGoodsError, fetchGtsOrderUnitDangerousGoodsById,
    fetchGtsOrderUnitDangerousGoodsByIdSuccess, fetchGtsOrderUnitDangerousGoodsByIdError, updateGtsOrderUnitDangerousGoods, updateGtsOrderUnitDangerousGoodsSuccess,
    resetGtsOrderUnitsDangerousGoods, resetGtsOrderUnitDangerousGoods, updateGtsOrderUnitDangerousGoodsError, deactivateGtsOrderUnitDangerousGoods, deactivateGtsOrderUnitDangerousGoodsSuccess,
    deactivateGtsOrderUnitDangerousGoodsError } = dangerousGoodsSlice.actions;

export const isLoadingDangerousGoods = (state: RootState): boolean => state.gtsDangerousGoodsReducer.isLoadingDangerousGoods;
export const isLoadingDangerousGood = (state: RootState): boolean => state.gtsDangerousGoodsReducer.isLoadingDangerousGood;
export const getGtsOrderUnitDangerousGoods = (state: RootState): Pagination<GTSOrderUnitDangerousGoods> => state.gtsDangerousGoodsReducer.dangerousGoods;
export const getGtsOrderUnitDangerousGood = (state: RootState): GTSOrderUnitDangerousGoods => state.gtsDangerousGoodsReducer.dangerousGood;
export const isUpdatingGtsOrderUnitDangerousGood = (state: RootState): boolean => state.gtsDangerousGoodsReducer.isUpdating;
export const isDeletingGtsOrderUnitDangerousGoods = (state: RootState): boolean => state.gtsOrderUnitReducer.isDeleted;

export default dangerousGoodsSlice.reducer;