const FactorSetTypesConstants = {
    CANCELLATION: 'CANCEL',
    PRIORTY: 'PRIORITY',
    WEIGHT: 'WEIGHT',
    DEPARTURE: 'DEPARTURE',
    HOURS: 'HOURS',
    DANGEROUS_GOODS: 'ADR',
    CALENDAR: 'CALENDAR'
};
    
export default FactorSetTypesConstants;