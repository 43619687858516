import { List } from 'immutable';
import OrderUnit from '../../../interfaces/output/orderUnit';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class OrderUnitStep1Validation extends ValidationUtils {

    static validateOrderUnitForm(orderUnit: OrderUnit): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const { unitNo,
            departureType,
            priorityType,
            grossWeightKg,
            unitType,
            cancellationType
        } = orderUnit;

        const requiredFields =
        this.validateInputStringLength(unitNo) &&
        this.validateInputNumber(priorityType?.id) &&
        this.validateInputNumber(grossWeightKg) &&
        this.validateInputNumber(unitType?.unitGroupId) &&
        this.validateInputNumber(unitType?.unitSubGroupId) &&
        this.validateInputNumber(cancellationType?.id) &&
        this.validateInputNumber(unitType?.id) &&
        this.validateInputNumber(departureType?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
