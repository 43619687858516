import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import GTSOrderUnitDepotApi from '../api/gtsOrderUnitDepotLinesApi';
import { deactivateGtsOrderUnitDepotError, deactivateGtsOrderUnitDepotSuccess, fetchGtsOrderUnitDepotByIdError, fetchGtsOrderUnitDepotByIdSuccess, fetchGtsOrderUnitsDepotError,
    fetchGtsOrderUnitsDepotSuccess, updateGtsOrderUnitDepotError, updateGtsOrderUnitDepotSuccess } from '../reducers/gtsOrderUnitDepotLinesReducer';
import GTSOrderUnitDepot from '../interfaces/output/gtsOrderUnitDepot';

function* doFetchGtsOrderUnitDepot(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: GTSOrderUnitDepot = yield call(GTSOrderUnitDepotApi.fetchGtsOrderUnitDepot, id);
        yield put(fetchGtsOrderUnitDepotByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderUnitDepotByIdError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOrderUnitsDepot(action: PayloadAction<{ paging: Paging, filters: GtsOrderUnitFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<GTSOrderUnitDepot> = yield call(GTSOrderUnitDepotApi.fetchGtsOrderUnitsDepot, paging, filters);
        yield put(fetchGtsOrderUnitsDepotSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderUnitsDepotError(error as HttpErrorResponse));
    }
}

function* doUpdateGtsOrderUnitdepot(action: PayloadAction<GTSOrderUnitDepot>) {
    const gtsOrderUnit = action.payload;

    try {
        const response: GTSOrderUnitDepot = yield call(GTSOrderUnitDepotApi.updateGtsOrderUnitDepot, gtsOrderUnit);

        yield put(updateGtsOrderUnitDepotSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateGtsOrderUnitDepotError(error as HttpErrorResponse));
    }
}

function* doDeactivateGtsOrderUnitDepot(action: PayloadAction<number>) {
    const unitId = action.payload;
    
    try {
        const response: GTSOrderUnitDepot = yield call(GTSOrderUnitDepotApi.deactivateGtsOrderUnitDepot, unitId);

        yield put(deactivateGtsOrderUnitDepotSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateGtsOrderUnitDepotError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('gtsOrderUnitDepotLines/fetchGtsOrderUnitDepotById', doFetchGtsOrderUnitDepot),
    takeLatest('gtsOrderUnitDepotLines/fetchGtsOrderUnitsDepot', doFetchGtsOrderUnitsDepot),
    takeLatest('gtsOrderUnitDepotLines/updateGtsOrderUnitDepot', doUpdateGtsOrderUnitdepot),
    takeLatest('gtsOrderUnitDepotLines/deactivateGtsOrderUnitDepot', doDeactivateGtsOrderUnitDepot)
];

