import { createUnitGroupSubgroupError, createUnitGroupSubgroupSuccess, fetchUnitGroupByIdError,
    fetchUnitGroupByIdSuccess, fetchUnitGroupsError, fetchUnitGroupsSuccess, fetchUnitGroupSubgroupsError,
    fetchUnitGroupSubgroupsSuccess } from '../reducers/unitGroupReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import UnitGroupApi from '../api/unitGroupApi';
import UnitGroup from '../interfaces/output/unitGroup';
import UnitSubGroup from '../interfaces/output/unitSubGroup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchUnitGroup(action: PayloadAction<string>) {
    const unitgroupId = action.payload;
    try {
        const response: UnitGroup = yield call(UnitGroupApi.fetchUnitGroupById, unitgroupId);
        yield put(fetchUnitGroupByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitGroupByIdError(error as HttpErrorResponse));
    }
}

function* doFetchUnitGroups(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<UnitGroup> = yield call(UnitGroupApi.fetchUnitGroups, paging);
        yield put(fetchUnitGroupsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitGroupsError(error as HttpErrorResponse));
    }
}

function* doFetchUnitGroupSubgroups(action: PayloadAction<{groupId: number, paging: Paging}>) {
    const { groupId, paging } = action.payload;

    try {
        const response: Pagination<UnitSubGroup> = yield call(UnitGroupApi.fetchUnitGroupSubgroups, groupId, paging);
        yield put(fetchUnitGroupSubgroupsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitGroupSubgroupsError(error as HttpErrorResponse));
    }
}

function* doCreateSubgroup(action: PayloadAction<UnitSubGroup>) {
    const subgroup = action.payload;

    try {
        const response: UnitSubGroup = yield call(UnitGroupApi.createSubgroupsForUnitGroup, subgroup);

        yield put(createUnitGroupSubgroupSuccess(response));
    }

    catch (error: unknown) {
        yield put(createUnitGroupSubgroupError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('unitGroup/fetchUnitGroupById', doFetchUnitGroup),
    takeLatest('unitGroup/fetchUnitGroups', doFetchUnitGroups),
    takeLatest('unitGroup/fetchUnitGroupSubgroups', doFetchUnitGroupSubgroups),
    takeLatest('unitGroup/createUnitGroupSubgroup', doCreateSubgroup)
];

