import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivateDepartureVersionStatusError, deactivateDepartureVersionStatusSuccess, fetchDepartureVersionStatusByIdError, fetchDepartureVersionStatusByIdSuccess,
    fetchDepartureVersionStatusesError, fetchDepartureVersionStatusesSuccess, updateDepartureVersionStatusError, updateDepartureVersionStatusSuccess } from '../reducers/departureVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchDepartureVersionStatus(action: PayloadAction<string>) {
    const departureVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, departureVersionStatusId);
    
        yield put(fetchDepartureVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDepartureVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchDepartureVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchDepartureVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDepartureVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateDepartureVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const departureVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, departureVersionStatus);

        yield put(updateDepartureVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDepartureVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateDepartureVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const departureVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, departureVersionStatus);

        yield put(deactivateDepartureVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateDepartureVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('departureVersionStatus/fetchDepartureVersionStatusById', doFetchDepartureVersionStatus),
    takeLatest('departureVersionStatus/fetchDepartureVersionStatuses', doFetchDepartureVersionStatuses),
    takeLatest('departureVersionStatus/updateDepartureVersionStatus', doUpdateDepartureVersionStatus),
    takeLatest('departureVersionStatus/deactivateDepartureVersionStatus', doDeactivateDepartureVersionStatus)
];