import { http } from '../utils/http';
import OrderUnitService from '../interfaces/output/orderUnitService';
import Pagination from '../interfaces/common/pagination';
import OrderUnitServiceProduct from '../interfaces/output/orderUnitServiceProduct';
import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OrderUnitServiceApi {
    static fetchOrderUnitService = async(unitServiceId: string): Promise<OrderUnitService> => {
        const { data } = await http.get<OrderUnitService>(`orderunitservices/${unitServiceId}`);

        return data;
    };

    static fetchProductsByOrderUnitServiceId = async(unitServiceId: number, paging: Paging): Promise<Pagination<OrderUnitServiceProduct>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `orderunitservices/${unitServiceId}/products`);
        const { data } = await http.get<Pagination<OrderUnitServiceProduct>>(url);

        return data;
    };

    static createOrderUnitService = async(unitService: OrderUnitService): Promise<OrderUnitService> => {
        const newUnitService = {
            serviceId: unitService?.service?.id,
            serviceDate: unitService?.serviceDate,
            ousStatusId: unitService?.ousStatus?.id,
            serviceEndDate: unitService?.serviceEndDate,
            amount: unitService?.amount,
            totalPrice: unitService?.totalPrice,
            calculatedPrice: unitService?.calculatedPrice,
            priceFreight: unitService?.priceFreight,
            priceTerminalServices: unitService?.priceTerminalServices,
            feesDangerousGoods: unitService?.feesDangerousGoods,
            feesRailwayTrack: unitService?.feesRailwayTrack,
            feesCancellation: unitService?.feesCancellation,
            feesOther: unitService?.feesOther,
            currencyId: unitService?.currency?.id,
            ousStatusComment: unitService?.ousStatusComment
        };
        
        const { data } = await http.post<any, OrderUnitService>(`orderunits/${unitService.orderUnit?.id}/services`, newUnitService);

        return data;
    }

    static updateOrderUnitService = async(unitService: OrderUnitService): Promise<OrderUnitService> => {
        const newGood = {
            serviceId: unitService?.service?.id,
            serviceDate: unitService.serviceDate,
            ousStatusId: unitService.ousStatus?.id,
            serviceEndDate: unitService.serviceEndDate,
            amount: unitService.amount,
            totalPrice: unitService.totalPrice,
            calculatedPrice: unitService.calculatedPrice,
            manualPrice: unitService.manualPrice,
            priceFreight: unitService.priceFreight,
            priceTerminalServices: unitService.priceTerminalServices,
            feesDangerousGoods: unitService.feesDangerousGoods,
            feesRailwayTrack: unitService.feesRailwayTrack,
            feesCancellation: unitService.feesCancellation,
            feesOther: unitService.feesOther,
            currencyId: unitService.currency?.id,
            ousStatusComment: unitService?.ousStatusComment
        };
        
        const { data } = await http.put<any, OrderUnitService>(`orderunitservices/${unitService.id}`, newGood);

        return data;
    }

    static deactivateOrderUnitService = async(unitServiceId: number): Promise<OrderUnitService> => {
        const { data } = await http.delete<OrderUnitService>(`orderunitservices/${unitServiceId}`);

        return data;
    }

    static creditOrderUnitService = async(id: number, createNewCredit: boolean, creditReason: string): Promise<OrderUnitService> => {
        const url = urlcat('', '/orderunitservices/credit', {
            orderUnitServiceId: id,
            newInvoice: createNewCredit,
            creditReason
        });

        const { data } = await http.put<any, OrderUnitService>(url);

        return data;
    }
}
