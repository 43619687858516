/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import Pagination from '../../../interfaces/common/pagination';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import HoursLineFilters from './line/hoursLineFilters';
import FactorSetHoursLineOutput from '../../../interfaces/output/factorSetHoursLineOut';
import ViewFactorSetHours from './viewFactorSetHours';
import ViewFactorSetHoursLines from './line/hoursLineList';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import { deactivateFactorSetHour, fetchFactorSetHourById, getFactorSetHour, isDeletingHour, isLoadingFactorSetHour, resetFactorSetHour, resetFactorSetHours }
    from '../../../reducers/factorSetHoursReducer';
import Loader from '../../common/widgets/loader';
import FactorsVersionsList from '../factorsVersionList';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import { deactivateFactorSetHoursLine, fetchHoursVersionLines, getHoursLines, isCreatingHoursLine, isDeletingHoursLine, isLoadingFactorSetHoursLines, isUpdatingHoursLine, resetHoursVersionLines }
    from '../../../reducers/hoursLineReducer';
import { fetchHoursVersions, getFactorSetHoursVersions, isLoadingFactorSetHoursVersions } from '../../../reducers/factorSetHoursVersionReducer';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IFactorSetHoursDetailsProps {
    classes: any;
    theme: Theme;
    fetchFactorSetHourById: any;
    fetchHoursVersionLines: any;
    deactivateFactorSetHour: any;
    deactivateFactorSetHoursLine: any;
    fetchHoursVersions: any;
    resetFactorSetHour: () => any;
    resetHoursVersionLines: () => any;
    resetFactorSetHours: () => any;
    factorSetHours: FactorSetOut;
    match: any;
    hoursLines: Pagination<FactorSetHoursLineOutput>;
    factorVersions: Pagination<FactorSetVersionOut>;
    history: any;
    location: any;
    isUpdatingHours: boolean;
    isDeletingHour: boolean;
    isDeletingLine: boolean;
    isCreatingHours: boolean;
    isLoading: boolean;
    isLoadingFactorSetHour: boolean;
    isLoadingFactorSetHoursLines: boolean;
    isLoadingFactorSetVersions: boolean;
}

interface IFactorSetHoursDetailsState {
    open: boolean;
    factorSetHours: FactorSetOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverPaginationLines: Paging;
    sortModelLines: GridSortModel;
    sortModelVersions: GridSortModel;
    factorSetHoursColumnVisibilityModel: GridColumnVisibilityModel;
    factorsVersionLinesColumnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetHourById: (id: number, versionId: number) => dispatch(fetchFactorSetHourById({ factorSetId: id,
        versionId })),
    fetchHoursVersionLines: (paging: Paging, filters: HoursLineFilters) => dispatch(fetchHoursVersionLines({
        paging,
        filters
    })),
    fetchHoursVersions: (id: number, paging: Paging) => dispatch(fetchHoursVersions({
        id,
        paging
    })),
    deactivateFactorSetHour: (id: number) => dispatch(deactivateFactorSetHour(id)),
    deactivateFactorSetHoursLine: (id: number) => dispatch(deactivateFactorSetHoursLine(id)),
    resetFactorSetHour: () => dispatch(resetFactorSetHour()),
    resetFactorSetHours: () => dispatch(resetFactorSetHours()),
    resetHoursVersionLines: () => dispatch(resetHoursVersionLines())
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetHours: getFactorSetHour(store),
        hoursLines: getHoursLines(store),
        factorVersions: getFactorSetHoursVersions(store),
        isUpdatingHours: isUpdatingHoursLine(store),
        isDeletingLine: isDeletingHoursLine(store),
        isDeletingHour: isDeletingHour(store),
        isCreatingHours: isCreatingHoursLine(store),
        isLoadingFactorSetHoursLines: isLoadingFactorSetHoursLines(store),
        isLoadingFactorSetVersions: isLoadingFactorSetHoursVersions(store),
        isLoading: isLoadingFactorSetHour(store) || isUpdatingHoursLine(store) || isDeletingHoursLine(store) || isCreatingHoursLine(store) || isLoadingFactorSetHoursLines(store),
        isLoadingFactorSetHour: isLoadingFactorSetHour(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('FACTOR_SET_HOURS'),
    properties: LanguageUtils.createMessage('Properties'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    versions: LanguageUtils.createMessage('Versions'),
    factors: LanguageUtils.createMessage('lines')
};

class FactorSetHoursDetails extends Component<IFactorSetHoursDetailsProps, IFactorSetHoursDetailsState> {
    constructor(props: IFactorSetHoursDetailsProps) {
        super(props);

        this.state = {
            open: true,
            factorSetHours: {} as FactorSetOut,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            serverPaginationLines: PageUtils.getDefaultPaging(),
            sortModelLines: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelVersions: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            factorSetHoursColumnVisibilityModel: {},
            factorsVersionLinesColumnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetHourById } = this.props;
        const { hoursId, factorSetVersionId } = this.props.match.params;
        const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;

        fetchFactorSetHourById(hoursId, versionId);
    }
    
    componentWillUnmount() {
        const { resetFactorSetHour, resetFactorSetHours, resetHoursVersionLines } = this.props;

        resetFactorSetHour();
        resetFactorSetHours();
        resetHoursVersionLines();
    }

    componentDidUpdate(prevProps: IFactorSetHoursDetailsProps) {
        const { factorSetHours, isUpdatingHours, isDeletingLine, isCreatingHours, isLoadingFactorSetHour, isDeletingHour, history, fetchFactorSetHourById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { factorSetVersionId, hoursId } = this.props.match.params;

        if(factorSetHours !== prevProps.factorSetHours) {
            this.setState({ factorSetHours });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ menuItems: this.configureDrawer(this.props),
                selectedRow: undefined }, () => this.loadData(this.getSelectedMenuItem(this.state)));
                
            if(prevProps.match.params.factorSetVersionId !== factorSetVersionId) {
                const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;
                fetchFactorSetHourById(hoursId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingFactorSetHour && prevProps.isLoadingFactorSetHour ||
            !isUpdatingHours && prevProps.isUpdatingHours ||
            !isDeletingLine && prevProps.isDeletingLine ||
            !isCreatingHours && prevProps.isCreatingHours)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingHour && prevProps.isDeletingHour) {
            history.push(`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DEPARTURE}`);
        }
    }

    isHiddenCategory = (): boolean => {
        const { factorSetHours } = this.state;
        
        return factorSetHours.version?.id ? false : true;
    }
    
    configureDrawer = (props: IFactorSetHoursDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.FACTOR_SETS}/hours/${props.match.params.hoursId}/${props.match.params.factorSetVersionId}`;
        
        return ImmutableList([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/properties`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.versions,
            additionalMargin: true,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.factors,
            icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
            url: `${baseUrl}/lines`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/lines`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchHoursVersionLines, factorSetHours, fetchHoursVersions } = this.props;
        const { hoursId } = this.props.match.params;
        const { serverPagination, serverPaginationLines } = this.state;
 
        const lineFilters = {
            name: undefined,
            hoursTypeId: null,
            factorSetVersionId: factorSetHours.version?.id
        } as HoursLineFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverPagination,
            page: newPaging.page
        };

        const newPaginationLines = {
            ...serverPaginationLines,
            page: newPaging.page
        };

        if(selectedMenuItem?.id === 2) {
            fetchHoursVersions(hoursId, newPagination);
        }
        
        if(selectedMenuItem?.id === 3 && factorSetHours.version?.id) {
            fetchHoursVersionLines(newPagination, lineFilters);
        }
    }

    getSelectedMenuItem = (state: IFactorSetHoursDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateFactorSet = () => {
        const { factorSetHours } = this.state;
        const { deactivateFactorSetHour } = this.props;

        deactivateFactorSetHour(factorSetHours.id);
    }

    deactivateFactorSetLine = () => {
        const { selectedRow } = this.state;
        const { deactivateFactorSetHoursLine } = this.props;

        deactivateFactorSetHoursLine(selectedRow);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow, factorSetHours } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { hoursId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: factorSetHours.version?.id ? `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/${factorSetHours.version?.id}/edit` :
                            `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateFactorSet
                    }
                ];
            }

            case 2: { //hours versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }
                
                return [{
                    href: `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 3: { //lines
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/version/${factorSetHours.version?.id}/add/line`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !factorSetHours.version?.id
                        }
                    ];
                }
                
                if(factorSetHours.version) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/hours/${hoursId}/version/${factorSetHours.version?.id}/hoursLine/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateFactorSetLine
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }
    _onSortChangeLines = (newModel: GridSortModel) => {
        const { fetchHoursVersionLines } = this.props;
        const { sortModelLines, serverPaginationLines, factorSetHours } = this.state;

        const lineFilters = {
            name: undefined,
            hoursTypeId: null,
            factorSetVersionId: factorSetHours.version?.id
        } as HoursLineFilters;

        if(JSON.stringify(sortModelLines) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationLines,
                sort: newModel
            };
            this.setState({
                sortModelLines: newModel,
                serverPaginationLines: newPagination
            });

            fetchHoursVersionLines(newPagination, lineFilters);
        }
    }

    _onSortChangeVersions = (newModel: GridSortModel) => {
        const { fetchHoursVersions } = this.props;
        const { factorSetHours, serverPagination, sortModelVersions } = this.state;

        if(JSON.stringify(sortModelVersions) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelVersions: newModel,
                serverPagination: newPagination
            });

            fetchHoursVersions(factorSetHours.id, newPagination);
        }
    }

    render() {
        const { classes, isLoadingFactorSetHour, isLoadingFactorSetHoursLines, hoursLines, isDeletingLine, isDeletingHour, isUpdatingHours,
            isLoadingFactorSetVersions, factorVersions, fetchHoursVersions, fetchHoursVersionLines } = this.props;
        const { factorSetHours, menuItems, serverPagination, serverPaginationLines, factorSetHoursColumnVisibilityModel, factorsVersionLinesColumnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingFactorSetHour || isDeletingHour}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.FactorSets}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    value: factorSetHours.name,
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" /> } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                            <span className={classes.values}> {factorSetHours?.version?.versionNumber ?? '-'} </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                            {factorSetHours?.version?.validFromDate || factorSetHours?.version?.validToDate ? (
                                <span className={classes.values}>
                                    <NullableFormattedDate value={factorSetHours?.version?.validFromDate} /> -
                                    {factorSetHours?.version?.validToDate &&
                                                     <> <NullableFormattedDate value={factorSetHours?.version?.validToDate} /></>}
                                </span>
                            )
                                : '-'} </span>
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId/properties`}>
                            <Loader isLoading={isUpdatingHours || isDeletingHour}>
                                <ViewFactorSetHours factorSetHours={factorSetHours} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId/lines`}>
                            <ViewFactorSetHoursLines isLoading={isLoadingFactorSetHoursLines || isDeletingLine}
                                rows={hoursLines}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationLines,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const lineFilters = {
                                        name: undefined,
                                        hoursTypeId: null,
                                        factorSetVersionId: factorSetHours.version?.id
                                    } as HoursLineFilters;

                                    this.setState({ serverPaginationLines: newPage });
                                    fetchHoursVersionLines(newPage, lineFilters);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeLines(sortModel)}
                                columnVisibilityModel={factorSetHoursColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorSetHoursColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/hours/:hoursId/:factorSetVersionId/versions`}>
                            <FactorsVersionsList
                                type={factorSetHours.type?.code}
                                isLoading={isLoadingFactorSetVersions}
                                rows={factorVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchHoursVersions(factorSetHours.id, newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeVersions(sortModel)}
                                columnVisibilityModel={factorsVersionLinesColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorsVersionLinesColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetHoursDetails));