import React from 'react';
import MuiAccordionSummary, {
    AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }: any) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, .05)!important'
          : 'rgba(0, 0, 0, .03)!important',
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

export default AccordionSummary;