import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../../constants/UrlConstants';
import DataGridComponent from '../../common/dataGrid';
import TableUtils from '../../../utils/tableUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import CustomStringLink from '../../common/customStringLink';

interface IDangerousGoodsListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    disableLinks?: boolean;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    name: LanguageUtils.createMessage('Factor set name'),
    productArena: LanguageUtils.createMessage('Product arena'),
    productType: LanguageUtils.createMessage('Product type'),
    unitSubGroup: LanguageUtils.createMessage('Subgroup'),
    isDefault: LanguageUtils.createMessage('Is default')
};

const DangerousGoodsList = forwardRef((props: IDangerousGoodsListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, disableLinks, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        const currentVersion = (params: any) => {
            return applyRowFn(params)?.currentVersion?.id || 'latest';
        };

        return [
            {
                field: 'name',
                headerName: intl.formatMessage(messages.name),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/${applyRowFn(cellValues)?.id}/${currentVersion(cellValues)}`}
                        value={applyRowFn(cellValues)?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: 'productArena_name',
                headerName: intl.formatMessage(messages.productArena),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params).productArena?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('productArena', hiddenColumns)
            },
            {
                field: 'productType_name',
                headerName: intl.formatMessage(messages.productType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params).productType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('productType', hiddenColumns)
            },
            {
                field: 'unitSubGroup_name',
                headerName: intl.formatMessage(messages.unitSubGroup),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params).unitSubGroup?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitSubGroup', hiddenColumns)
            },
            {
                field: 'default',
                headerName: intl.formatMessage(messages.isDefault),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: false,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.FACTOR_SETS}/dangerousgoods/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params).default === true ? 'Yes' : 'No'} />,
                hide: hiddenColumns && TableUtils.hideColumn('default', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default DangerousGoodsList;