import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import PageUtils from '../../../utils/pageUtils';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import { fetchServiceTypes, getServiceTypes } from '../../../reducers/serviceTypeReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Terminal from '../../../interfaces/output/terminal';
import ServiceType from '../../../interfaces/output/serviceType';
import ServiceFilters from '../../../interfaces/output/serviceFilters';

interface IFilterProps {
    filters: ServiceFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    serviceType: LanguageUtils.createMessage('Service type'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function UnitServicesFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const terminals = useSelector(getTerminals).content;
    const serviceTypes = useSelector(getServiceTypes).content;

    useEffect(() => {
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchServiceTypes({ paging }));
    }, [dispatch]);
    
    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.fromTerminalId}
                            onChange={(obj: Terminal | null) => onChange('fromTerminalId', obj?.id)}
                            placeholder={messages.fromTerminal}
                            compareFn={(o: Terminal) => o.id === filters.fromTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toTerminalId}
                            onChange={(obj: Terminal | null) => onChange('toTerminalId', obj?.id)}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal) => o.id === filters.toTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ServiceType>
                            options={serviceTypes}
                            value={filters.serviceTypeId}
                            onChange={(obj: ServiceType | null) => onChange('serviceTypeId', obj?.id)}
                            placeholder={messages.serviceType}
                            compareFn={(o: ServiceType) => o.id === filters.serviceTypeId}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}