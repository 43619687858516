import { List } from 'immutable';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import ValidationModel from '../../../../utils/validationModel';
import { ensure } from '../../../../utils/arrayUtils';
import Terminal from '../../../../interfaces/output/terminal';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class TerminalStep1Validation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateEmail(email?: string, multipleEmails = false): boolean {
        return ValidationUtils.validateEmail(email, multipleEmails);
    }

    static validateUserForm(terminal: Terminal): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            gtsTerminalCode,
            gtsTerminalNo,
            operator,
            country
        } = terminal;

        const requiredFields =
            this.validateInputString(name) &&
            this.validateInputString(gtsTerminalCode) &&
            this.validateInputNumber(gtsTerminalNo) &&
            this.validateInputNumber(operator?.id) &&
            this.validateInputNumber(country?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
