import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import FontAwesomeIcon from '../../../utils/FontAwesomeIcons';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import InvoiceLineOut from '../../../interfaces/output/invoiceLineOut';
import {
    deactivateInvoiceLine, fetchInvoiceLineById, getInvoiceLine, isDeletingInvoiceLine, isLoadingInvoiceLine,
    isUpdatingInvoiceLine
} from '../../../reducers/invoiceLineReducer';
import ViewInvoiceLine from './viewInvoiceLine';

interface IInvoiceLineDetailsProps {
    classes: any;
    theme: Theme;
    fetchInvoiceLineById: any;
    deactivateInvoiceLine: any;
    invoiceLine: InvoiceLineOut;
    match: any;
    history: any;
    location: any;
    isUpdatingInvoiceLine: boolean;
    isDeletingInvoiceLine: boolean;
    isLoading: boolean;
    isLoadingInvoiceLine: boolean;
}

interface IInvoiceLineDetailsState {
    open: boolean;
    invoiceLine: InvoiceLineOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    paging: Paging;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchInvoiceLineById: (invoiceLineId: number) => dispatch(fetchInvoiceLineById(invoiceLineId)),
    deactivateInvoiceLine: (id: number) => dispatch(deactivateInvoiceLine(id))
});

const mapStoreToProps = (store: RootState) => {
    return {
        invoiceLine: getInvoiceLine(store),
        isUpdatingInvoiceLine: isUpdatingInvoiceLine(store),
        isDeletingInvoiceLine: isDeletingInvoiceLine(store),
        isLoading: isLoadingInvoiceLine(store) || isDeletingInvoiceLine(store),
        isLoadingInvoiceLine: isLoadingInvoiceLine(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('INVOICE_LINES'),
    properties: LanguageUtils.createMessage('Properties'),
    lines: LanguageUtils.createMessage('InvoiceLine lines')
};

class InvoiceLineDetails extends Component<IInvoiceLineDetailsProps, IInvoiceLineDetailsState> {
    constructor(props: IInvoiceLineDetailsProps) {
        super(props);
        this.state = {
            open: true,
            invoiceLine: {} as InvoiceLineOut,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.INVOICE_LINES}/${props.match.params.invoiceLineId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.INVOICE_LINES}/${props.match.params.invoiceLineId}/properties`, props.location.pathname)
            }]),
            selectedRow: undefined,
            paging: PageUtils.getDefaultPaging()
        };
    }

    componentDidMount() {
        const { fetchInvoiceLineById } = this.props;
        const { invoiceLineId } = this.props.match.params;

        fetchInvoiceLineById(invoiceLineId);
    }

    componentDidUpdate(prevProps: IInvoiceLineDetailsProps) {
        const { invoiceLine, isUpdatingInvoiceLine, isDeletingInvoiceLine, isLoadingInvoiceLine, history } = this.props;

        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(invoiceLine !== prevProps.invoiceLine) {
            this.setState({ invoiceLine });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingInvoiceLine && prevProps.isLoadingInvoiceLine ||
            !isUpdatingInvoiceLine && prevProps.isUpdatingInvoiceLine)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingInvoiceLine && prevProps.isDeletingInvoiceLine) {
            history.push(`/${UrlConstants.INVOICES}/${prevProps?.invoiceLine?.invoice?.id}/lines`);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadData = (selectedMenuItem: IMenuItem | undefined) => { return; }

    getSelectedMenuItem = (state: IInvoiceLineDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateInvoiceLine = () => {
        const { invoiceLine } = this.state;
        const { deactivateInvoiceLine } = this.props;

        deactivateInvoiceLine(invoiceLine.id);
    }

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        // const { invoiceLineId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    // {
                    //     href: `${UrlConstants.INVOICE_LINES}/${invoiceLineId}/edit`,
                    //     icon: 'edit',
                    //     text: messages.edit
                    // },
                    // {
                    //     icon: 'ban',
                    //     text: messages.delete,
                    //     onClick: this.deactivateInvoiceLine
                    // }
                ];
            }

            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, isLoadingInvoiceLine, isDeletingInvoiceLine } = this.props;
        const { invoiceLine, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingInvoiceLine || isDeletingInvoiceLine}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.InvoiceLine}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: invoiceLine.customerReference,
                    icon: <FontAwesomeIcon icon="file-invoice" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.INVOICE_LINES}/:invoiceLineId/properties`}>
                            <ViewInvoiceLine invoiceLine={invoiceLine} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(InvoiceLineDetails));