import React from 'react';
import TruncatedStringTooltip from './truncatedStringTooltip';
import CustomLink from './customLink';

interface ICustomStringLink {
    value: any;
    link: any
    disabled?: any;
    shouldUseHistory?: boolean;
}

export default function CustomStringLink(props: ICustomStringLink): JSX.Element {
    const { value, link, disabled, shouldUseHistory } = props;

    return (
        <CustomLink link={link} disabled={disabled} shouldUseHistory={shouldUseHistory}>
            <TruncatedStringTooltip value={value} />
        </CustomLink>
    );
}