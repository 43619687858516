import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import TransitDiscount from '../interfaces/output/transitDiscount';
import AgreementVersionTransitDiscountApi from '../api/agreementVersionTransitDiscountApi';
import { deactivateAgreementVersionTransitDiscountByIdError, deactivateAgreementVersionTransitDiscountByIdSuccess, fetchAgreementVersionTransitDiscountByIdError,
    fetchAgreementVersionTransitDiscountByIdSuccess, updateAgreementVersionTransitDiscountByIdError, updateAgreementVersionTransitDiscountByIdSuccess }
    from '../reducers/agreementVersionTransitDiscountReducer';

function* doFetchAgreementVersionTransitDiscount(action: PayloadAction<number>) {
    const discountId = action.payload;
    try {
        const response: TransitDiscount = yield call(AgreementVersionTransitDiscountApi.fetchAgreementVersionTransitDiscountById, discountId);

        yield put(fetchAgreementVersionTransitDiscountByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionTransitDiscountByIdError(error as HttpErrorResponse));
    }
}

function* doUpdateAgreementVersionTransitDiscount(action: PayloadAction<TransitDiscount>) {
    const specialPrice = action.payload;

    try {
        const response: TransitDiscount = yield call(AgreementVersionTransitDiscountApi.updateAgreementVersionTransitDiscount, specialPrice);

        yield put(updateAgreementVersionTransitDiscountByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementVersionTransitDiscountByIdError(error as HttpErrorResponse));
    }
}

function* doDeactivateAgreementVersionTransitDiscount(action: PayloadAction<number>) {
    const discountId = action.payload;
    
    try {
        const response: TransitDiscount = yield call(AgreementVersionTransitDiscountApi.deactivateAgreementVersionTransitDiscount, discountId);

        yield put(deactivateAgreementVersionTransitDiscountByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateAgreementVersionTransitDiscountByIdError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('agreementVersionTransitDiscount/fetchAgreementVersionTransitDiscountById', doFetchAgreementVersionTransitDiscount),
    takeLatest('agreementVersionTransitDiscount/updateAgreementVersionTransitDiscountById', doUpdateAgreementVersionTransitDiscount),
    takeLatest('agreementVersionTransitDiscount/deactivateAgreementVersionTransitDiscountById', doDeactivateAgreementVersionTransitDiscount)
];