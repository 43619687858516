import React, { useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericStepper from '../../common/widgets/genericStepper';
import UrlConstants from '../../../constants/UrlConstants';
import GenericStep from '../../../interfaces/common/genericStep';
import { FormType } from '../../../constants/constants';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { fetchOrderUnitServiceProductById, getOrderUnitServiceProduct, isUpdatingOrderUnitServiceProduct, updateOrderUnitServiceProduct, createOrderUnitServiceProduct, isCreatingUnitServiceProduct }
    from '../../../reducers/orderUnitServiceProductReducer';
import AddOrderUnitServiceProductStep1 from './orderUnitServiceProductStep1';
import AddOrderUnitServiceProductStep2 from './orderUnitServiceProductStep2';
import AddOrderUnitServiceProductStep3 from './orderUnitServiceProductStep3';
import AddOrderUnitServiceProductStep4 from './orderUnitServiceProductStep4';
import AddOrderUnitServiceProductStep5 from './orderUnitServiceProductStep5';
import AddOrderUnitServiceProductStep6 from './orderUnitServiceProductStep6';
import { OrderStatusId } from '../../../constants/statusConstants';

interface IAddOrderUnitServiceProductFormProps {
    orderUnitServiceId?: number;
    orderUnitServiceProductId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    editUnitServiceProduct: LanguageUtils.createMessage('Edit order unit service product'),
    addUnitServiceProduct: LanguageUtils.createMessage('Add order unit service product'),
    changeStatus: LanguageUtils.createMessage('Change service status'),
    changeOverriddentPrice: LanguageUtils.createMessage('Change overridden price')
};

export default function AddOrderUnitServiceProductForm(props: IAddOrderUnitServiceProductFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { orderUnitServiceId, orderUnitServiceProductId, steps, type } = props;
    const propsOrderUnitServiceProduct = useSelector(getOrderUnitServiceProduct);
    const [orderUnitServiceProduct, setOrderUnitServiceProduct] = React.useState<OrderUnitServiceProduct>({ orderUnitService: { id: orderUnitServiceId } } as OrderUnitServiceProduct);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsCreatingUnitServiceProduct = useSelector(isCreatingUnitServiceProduct);
    const prevIsCreatingUnitServiceProduct = WorkflowUtils.usePrevious<boolean>(propsIsCreatingUnitServiceProduct);
    const propsIsUpdatingOrderUnitServiceProduct = useSelector(isUpdatingOrderUnitServiceProduct);
    const prevIsUpdatingOrderUnitServiceProduct = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrderUnitServiceProduct);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 4:
            case 5:
            case 6:
                if(prevIsUpdatingOrderUnitServiceProduct === true && !propsIsUpdatingOrderUnitServiceProduct || prevIsCreatingUnitServiceProduct === true && !propsIsCreatingUnitServiceProduct) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingUnitServiceProduct, propsIsUpdatingOrderUnitServiceProduct]);
    
    useEffect(() => {
        if(orderUnitServiceProductId) {
            dispatch(fetchOrderUnitServiceProductById(orderUnitServiceProductId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsOrderUnitServiceProduct.id) {
            setOrderUnitServiceProduct(propsOrderUnitServiceProduct);
        }

    }, [propsIsUpdatingOrderUnitServiceProduct, propsOrderUnitServiceProduct.id]);

    const onChangeUnitServiceProduct = (attribute: string, value: any) => {

        const newOrderUnitServiceProduct = { ...orderUnitServiceProduct } as OrderUnitServiceProduct;
        (newOrderUnitServiceProduct as any)[attribute] = value;

        setOrderUnitServiceProduct(newOrderUnitServiceProduct);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: 'Select product from list',
            xl: true,
            content: <AddOrderUnitServiceProductStep1 onChange={onChangeUnitServiceProduct} orderUnitServiceProduct={orderUnitServiceProduct} />
        } as GenericStep,
        {
            id: 2,
            name: 'Select agreement from list',
            xl: true,
            content: <AddOrderUnitServiceProductStep2 onChange={onChangeUnitServiceProduct} />
        } as GenericStep,
        {
            id: 3,
            name: 'Select price list from list',
            xl: true,
            content: <AddOrderUnitServiceProductStep3 onChange={onChangeUnitServiceProduct} />
        } as GenericStep,
        {
            id: 4,
            name: 'Insert order unit service product details',
            content: <AddOrderUnitServiceProductStep4 orderUnitServiceProduct={orderUnitServiceProduct} onChange={onChangeUnitServiceProduct} />,
            onNext: () => {
                if(!orderUnitServiceProductId) {
                    dispatch(createOrderUnitServiceProduct({ ...orderUnitServiceProduct,
                        ouspStatus: { id: OrderStatusId.InProgress } }));
                }
                else {
                    dispatch(updateOrderUnitServiceProduct(orderUnitServiceProduct));
                }
            }
        } as GenericStep,
        {
            id: 5,
            name: 'Change service product status',
            content: <AddOrderUnitServiceProductStep5 onChange={onChangeUnitServiceProduct} orderUnitServiceProduct={orderUnitServiceProduct} />,
            onNext: () => {
                dispatch(updateOrderUnitServiceProduct(orderUnitServiceProduct));
            }
        } as GenericStep,
        {
            id: 6,
            name: 'Change service product ovverriden price',
            content: <AddOrderUnitServiceProductStep6 onChange={onChangeUnitServiceProduct} orderUnitServiceProduct={orderUnitServiceProduct} />,
            onNext: () => {
                dispatch(updateOrderUnitServiceProduct(orderUnitServiceProduct));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddUnitServiceProduct:
                return messages.addUnitServiceProduct;
            case FormType.EditUnitServiceProduct:
                return messages.editUnitServiceProduct;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            case FormType.ChangeOverriddenPrice:
                return messages.changeOverriddentPrice;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddUnitServiceProduct:
                return `/${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceId}/products`;
            case FormType.EditUnitServiceProduct:
                return `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}`;
            case FormType.ChangeStatus:
                return `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}`;
            case FormType.ChangeOverriddenPrice:
                return `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  