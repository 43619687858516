import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HelptextOut from '../interfaces/output/helptextOut';

export type HelptextState = {
    helptext: HelptextOut;
    isLoading: boolean;
    isLoadingHelptext: boolean;
    helptexts: Pagination<HelptextOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: HelptextState = {
    helptext: {} as HelptextOut,
    helptexts: { content: [] as Array<HelptextOut> } as Pagination<HelptextOut>,
    isLoading: false,
    isLoadingHelptext: false,
    error: undefined
};

export const helptextSlice = createSlice({
    name: 'helptext',

    initialState,
    reducers: {
        fetchHelptexts: (state: HelptextState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchHelptextsSuccess: (state: HelptextState, action: PayloadAction<Pagination<HelptextOut>>) => {
            state.isLoading = false;
            state.helptexts = action.payload;
        },
        fetchHelptextsError: (state: HelptextState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchHelptextById: (state: HelptextState, _action: PayloadAction<string>) => {
            state.isLoadingHelptext = true;
        },
        fetchHelptextByIdSuccess: (state: HelptextState, action: PayloadAction<HelptextOut>) => {
            state.isLoadingHelptext = false;
            state.helptext = action.payload;
        },
        fetchHelptextByIdError: (state: HelptextState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingHelptext = false;
            state.error = action.payload;
        }
    }
});

export const { fetchHelptexts, fetchHelptextsSuccess, fetchHelptextsError, fetchHelptextById, fetchHelptextByIdSuccess, fetchHelptextByIdError }
    = helptextSlice.actions;

export const isLoadingHelptext = (state: RootState): boolean => state.helptextReducer.isLoadingHelptext;
export const isLoadingHelptexts = (state: RootState): boolean => state.helptextReducer.isLoading;
export const getHelptexts = (state: RootState): Pagination<HelptextOut> => state.helptextReducer.helptexts;
export const getHelptext = (state: RootState): HelptextOut => state.helptextReducer.helptext;

export default helptextSlice.reducer;