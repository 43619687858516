import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import UserFilter from '../interfaces/filters/terminaleFilters';
import UserOut from '../interfaces/output/userOut';
import { RootState } from '../setup';

export type UserState = {
    user: UserOut;
    users: Pagination<UserOut>;
    error: HttpErrorResponse | undefined;
    userDetails: UserOut;
    isLoading: boolean;
    isLoadingUsers: boolean;
    isLoadingUser: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
};

const initialState: UserState = {
    user: {} as UserOut,
    users: { content: [] as Array<UserOut> } as Pagination<UserOut>,
    error: undefined,
    userDetails: {} as UserOut,
    isLoading: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isLoadingUsers: false,
    isLoadingUser: false
};

export const userLoginSlice = createSlice({
    name: 'userLogin',

    initialState,
    reducers: {
        userLogout: (state: UserState) => {
            state.isLoading = false;
            state.userDetails = initialState.userDetails;
        },
        fetchUsers: (state: UserState, _action: PayloadAction<{ paging: Paging, filters: UserFilter }>) => {
            state.isLoadingUsers = true;
        },
        fetchUsersSuccess: (state: UserState, action: PayloadAction<Pagination<UserOut>>) => {
            state.isLoadingUsers = false;
            state.users = action.payload;
        },
        fetchUsersError: (state: UserState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUsers = false;
            state.error = action.payload;
        },
        fetchUserById: (state: UserState, _action: PayloadAction<number>) => {
            state.isLoadingUser = true;
        },
        fetchUserByIdSuccess: (state: UserState, action: PayloadAction<UserOut>) => {
            state.isLoadingUser = false;
            state.user = action.payload;
        },
        fetchUserByIdError: (state: UserState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUser = false;
        },
        fetchUserDetails: (state: UserState, action: PayloadAction<boolean>) => {
            state.isLoadingUser = true;
        },
        fetchUserDetailsSuccess: (state: UserState, action: PayloadAction<UserOut>) => {
            state.isLoadingUser = false;
            state.userDetails = action.payload;
        },
        fetchUserDetailsError: (state: UserState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUser = false;
            state.error = action.payload;
        },
        createUser: (state: UserState, _action: PayloadAction<UserOut>) => {
            state.isCreating = true;
        },
        createUserSuccess: (state: UserState, action: PayloadAction<UserOut>) => {
            state.isCreating = false;
            state.user = action.payload;
        },
        createUserError: (state: UserState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateUser: (state: UserState, _action: PayloadAction<UserOut>) => {
            state.isUpdating = true;
        },
        updateUserSuccess: (state: UserState, action: PayloadAction<UserOut>) => {
            state.isUpdating = false;
            state.user = action.payload;
        },
        updateUserError: (state: UserState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteUser: (state: UserState, _action: PayloadAction<UserOut>) => {
            state.isDeleting = true;
        },
        deleteUserSuccess: (state: UserState, action: PayloadAction<UserOut>) => {
            state.isDeleting = false;
            state.user = action.payload;
        },
        deleteUserError: (state: UserState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetUsers: (state: UserState) => {
            state.users = initialState.users;
        },
        resetUser: (state: UserState) => {
            state.user = initialState.user;
        }
    }
});

export const { userLogout, fetchUsers, fetchUsersSuccess, fetchUsersError, fetchUserById, fetchUserByIdSuccess, fetchUserByIdError, resetUsers, resetUser,
    fetchUserDetails, fetchUserDetailsSuccess, fetchUserDetailsError, createUser, createUserError, createUserSuccess, deleteUser, deleteUserError,
    deleteUserSuccess, updateUser, updateUserError, updateUserSuccess } = userLoginSlice.actions;

export const getIsLoading = (state: RootState): boolean => state.userLoginReducer.isLoading;
export const getIsLoadingUser = (state: RootState): boolean => state.userLoginReducer.isLoadingUser;
export const getIsLoadingUsers = (state: RootState): boolean => state.userLoginReducer.isLoadingUsers;
export const isUpdatingUser = (state: RootState): boolean => state.userLoginReducer.isUpdating;
export const isDeletingUser = (state: RootState): boolean => state.userLoginReducer.isDeleting;
export const isCreatingUser = (state: RootState): boolean => state.userLoginReducer.isCreating;
export const getUsers = (state: RootState): Pagination<UserOut> => state.userLoginReducer.users;
export const getUser = (state: RootState): UserOut => state.userLoginReducer.user;
export const getUserDetails = (state: RootState): UserOut => state.userLoginReducer.userDetails;

export default userLoginSlice.reducer;