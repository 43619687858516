import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AgreementVersionPricelist from '../interfaces/output/agreementVersionPricelist';
import AgreementVersionPriceListsFilters from '../interfaces/filters/agreementVersionPriceListsFilters';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type AgreementVersionPriceListState = {
    agreementVersionPriceList: AgreementVersionPricelist;
    isLoadingAgreementVersionPriceList: boolean;
    isLoadingAgreementVersionPriceLists: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    agreementVersionPriceLists: Pagination<AgreementVersionPricelist>;
    error: HttpErrorResponse | undefined;
};

const initialState: AgreementVersionPriceListState = {
    agreementVersionPriceList: {} as AgreementVersionPricelist,
    agreementVersionPriceLists: { content: [] as Array<AgreementVersionPricelist> } as Pagination<AgreementVersionPricelist>,
    isLoadingAgreementVersionPriceList: false,
    isLoadingAgreementVersionPriceLists: false,
    isDeleting: false,
    isUpdating: false,
    isCreating: false,
    error: undefined
};

export const agreementVersionPricelistSlice = createSlice({
    name: 'agreementVersionPriceList',

    initialState,
    reducers: {
        fetchAgreementVersionPricelists: (state: AgreementVersionPriceListState, _action: PayloadAction<{paging: Paging, filters: AgreementVersionPriceListsFilters}>) => {
            state.isLoadingAgreementVersionPriceLists = true;
        },
        fetchAgreementVersionPricelistsSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<Pagination<AgreementVersionPricelist>>) => {
            state.isLoadingAgreementVersionPriceLists = false;
            state.agreementVersionPriceLists = action.payload;
        },
        fetchAgreementVersionPricelistsError: (state: AgreementVersionPriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingAgreementVersionPriceLists = false;
            state.error = action.payload;
        },
        fetchAgreementVersionPricelistsById: (state: AgreementVersionPriceListState, _action: PayloadAction<number>) => {
            state.isLoadingAgreementVersionPriceList = true;
        },
        fetchAgreementVersionPricelistsByIdSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<AgreementVersionPricelist>) => {
            state.isLoadingAgreementVersionPriceList = false;
            state.agreementVersionPriceList = action.payload;
        },
        fetchAgreementVersionPricelistsByIdError: (state: AgreementVersionPriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingAgreementVersionPriceList = false;
            state.error = action.payload;
        },
        promoteAgreementVersionPricelists: (state: AgreementVersionPriceListState, _action: PayloadAction<number>) => {
            state.isUpdating = true;
        },
        promoteAgreementVersionPricelistsSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<AgreementVersionPricelist>) => {
            state.isUpdating = false;
            state.agreementVersionPriceList = action.payload;
        },
        promoteAgreementVersionPricelistsError: (state: AgreementVersionPriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        demoteAgreementVersionPricelists: (state: AgreementVersionPriceListState, _action: PayloadAction<number>) => {
            state.isUpdating = true;
        },
        demoteAgreementVersionPricelistsSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<AgreementVersionPricelist>) => {
            state.isUpdating = false;
            state.agreementVersionPriceList = action.payload;
        },
        demoteAgreementVersionPricelistsError: (state: AgreementVersionPriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateAgreementVersionPricelists: (state: AgreementVersionPriceListState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateAgreementVersionPricelistsSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<AgreementVersionPricelist>) => {
            state.isDeleting = false;
            state.agreementVersionPriceList = action.payload;
        },
        deactivateAgreementVersionPricelistsError: (state: AgreementVersionPriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        createAgreementVersionPricelists: (state: AgreementVersionPriceListState, _action: PayloadAction<AgreementVersionPricelist>) => {
            state.isCreating = true;
        },
        createAgreementVersionPricelistsSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<AgreementVersionPricelist>) => {
            state.isCreating = false;
            state.agreementVersionPriceList = action.payload;
        },
        createAgreementVersionPricelistsError: (state: AgreementVersionPriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateAgreementVersionPricelist: (state: AgreementVersionPriceListState, _action: PayloadAction<AgreementVersionPricelist>) => {
            state.isUpdating = true;
        },
        updateAgreementVersionPricelistSuccess: (state: AgreementVersionPriceListState, action: PayloadAction<AgreementVersionPricelist>) => {
            state.isUpdating = false;
            state.agreementVersionPriceList = action.payload;
        },
        updateAgreementVersionPricelistError: (state: AgreementVersionPriceListState, action) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetAgreementVersionPricelists: (state: AgreementVersionPriceListState) => {
            state.agreementVersionPriceLists = initialState.agreementVersionPriceLists;
        }
    }
});

export const { fetchAgreementVersionPricelists, fetchAgreementVersionPricelistsSuccess, fetchAgreementVersionPricelistsError, fetchAgreementVersionPricelistsById,
    fetchAgreementVersionPricelistsByIdSuccess, fetchAgreementVersionPricelistsByIdError, promoteAgreementVersionPricelists, promoteAgreementVersionPricelistsSuccess,
    promoteAgreementVersionPricelistsError, demoteAgreementVersionPricelists, demoteAgreementVersionPricelistsSuccess, demoteAgreementVersionPricelistsError, deactivateAgreementVersionPricelists,
    deactivateAgreementVersionPricelistsSuccess, deactivateAgreementVersionPricelistsError, createAgreementVersionPricelists, createAgreementVersionPricelistsSuccess,
    createAgreementVersionPricelistsError, updateAgreementVersionPricelist, updateAgreementVersionPricelistSuccess, updateAgreementVersionPricelistError,
    resetAgreementVersionPricelists } = agreementVersionPricelistSlice.actions;

export const isLoadingAgreementVersionPriceList = (state: RootState): boolean => state.agreementVersionPricelistReducer.isLoadingAgreementVersionPriceList;
export const isLoadingAgreementVersionPriceLists = (state: RootState): boolean => state.agreementVersionPricelistReducer.isLoadingAgreementVersionPriceLists;
export const isDeletingAgreementPricelist = (state: RootState): boolean => state.agreementVersionPricelistReducer.isDeleting;
export const isUpdatingAgreementPricelist = (state: RootState): boolean => state.agreementVersionPricelistReducer.isUpdating;
export const isCreatingAgreementPricelist = (state: RootState): boolean => state.agreementVersionPricelistReducer.isCreating;
export const getAgreementVersionPriceLists = (state: RootState): Pagination<AgreementVersionPricelist> => state.agreementVersionPricelistReducer.agreementVersionPriceLists;
export const getAgreementVersionPriceList = (state: RootState): AgreementVersionPricelist => state.agreementVersionPricelistReducer.agreementVersionPriceList;

export default agreementVersionPricelistSlice.reducer;