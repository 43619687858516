const TableHeadersConstants = {
    AGREEMENT: 'Agreement',
    ORDER: 'Order',
    ORDER_UNIT: 'OrderUnit',
    ORDER_UNIT_SERVICE: 'OrderUnitService',
    ORDER_UNIT_SERVICE_PRODUCT: 'OrderUnitServiceProduct',
    CUSTOMER: 'Customer',
    INVOICE: 'Invoice',
    PRICE_LIST: 'PriceList',
    PRODUCT: 'Product',
    SERVICE: 'Service',
    GTS_ORDER: 'Interface_GTS_Order',
    GTS_ORDER_UNIT: 'Interface_GTS_OrderUnit',
    GTS_ORDER_UNIT_DANGEROUS_GOODS: 'Interface_GTS_OrderUnitDangerousGoods',
    GTS_ORDER_UNIT_DEPOT: 'Interface_GTS_OrderUnitDepot',
    GTS_ORDER_UNIT_SERVICE: 'Interface_GTS_OrderUnitService'
};
export default TableHeadersConstants;