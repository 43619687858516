import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type CalendarVersionStatusState = {
    calendarVersionStatus: FactorSetVersionStatusOut;
    calendarVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: CalendarVersionStatusState = {
    calendarVersionStatus: {} as FactorSetVersionStatusOut,
    calendarVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const calendarVersionStatusSlice = createSlice({
    name: 'calendarVersionStatus',

    initialState,
    reducers: {
        fetchCalendarVersionStatuses: (state: CalendarVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchCalendarVersionStatusesSuccess: (state: CalendarVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.calendarVersionStatuses = action.payload;
        },
        fetchCalendarVersionStatusesError: (state: CalendarVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCalendarVersionStatusById: (state: CalendarVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchCalendarVersionStatusByIdSuccess: (state: CalendarVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.calendarVersionStatus = action.payload;
        },
        fetchCalendarVersionStatusByIdError: (state: CalendarVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateCalendarVersionStatus: (state: CalendarVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updateCalendarVersionStatusSuccess: (state: CalendarVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.calendarVersionStatus = action.payload;
        },
        updateCalendarVersionStatusError: (state: CalendarVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateCalendarVersionStatus: (state: CalendarVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivateCalendarVersionStatusSuccess: (state: CalendarVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.calendarVersionStatus = action.payload;
        },
        deactivateCalendarVersionStatusError: (state: CalendarVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetCalendarVersionStatuses: (state: CalendarVersionStatusState) => {
            state.calendarVersionStatuses = initialState.calendarVersionStatuses;
        }
    }
});

export const { fetchCalendarVersionStatusById, fetchCalendarVersionStatusByIdSuccess, fetchCalendarVersionStatusByIdError, updateCalendarVersionStatus,
    updateCalendarVersionStatusSuccess, updateCalendarVersionStatusError, fetchCalendarVersionStatuses, fetchCalendarVersionStatusesSuccess,
    fetchCalendarVersionStatusesError, deactivateCalendarVersionStatus, deactivateCalendarVersionStatusSuccess, deactivateCalendarVersionStatusError,
    resetCalendarVersionStatuses } = calendarVersionStatusSlice.actions;

export const isLoadingCalendarVersionStatus = (state: RootState): boolean => state.calendarVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.calendarVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.calendarVersionStatusReducer.isDeleted;
export const getCalendarVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.calendarVersionStatusReducer.calendarVersionStatus;
export const getCalendarVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.calendarVersionStatusReducer.calendarVersionStatuses;

export default calendarVersionStatusSlice.reducer;