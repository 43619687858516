import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import ServiceType from '../interfaces/output/serviceType';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ServiceTypeApi {
    static fetchServiceTypeById = async(id: string): Promise<ServiceType> => {
        const { data } = await http.get<ServiceType>(`/servicetypes/${id}`);

        return data;
    };

    static fetchServiceTypes = async(paging: Paging): Promise<Pagination<ServiceType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/servicetypes');

        const { data } = await http.get<Pagination<ServiceType>>(url);

        return data;
    };

    static createServiceType = async(serviceType: ServiceType): Promise<ServiceType> => {
        const newServiceType = {
            id: serviceType.id,
            name: serviceType.name,
            code: serviceType.code
        };

        const { data } = await http.post<any, ServiceType>('servicetypes', newServiceType);

        return data;
    };

    static updateServiceType = async(serviceType: ServiceType): Promise<ServiceType> => {
        const newServiceType = {
            id: serviceType.id,
            name: serviceType.name,
            code: serviceType.code
        };
        
        const { data } = await http.put<any, ServiceType>(`servicetypes/${serviceType.id}`, newServiceType);

        return data;
    };

    static deleteServiceType = async(service: ServiceType): Promise<ServiceType> => {
        const { data } = await http.delete<any, ServiceType>(`servicetypes/${service.id}`);

        return data;
    };
}
