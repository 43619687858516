import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import SurchargeRate from '../interfaces/output/surchargeRate';
import Paging from '../interfaces/common/paging';
import SurchargeRateFilters from '../interfaces/filters/surchargeRateFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import moment from 'moment';

export default class SurchargeRateApi {
    static fetchSurcharteRateById = async(surcharteRateId: string): Promise<SurchargeRate> => {
        const { data } = await http.get<SurchargeRate>(`surchargerate/${surcharteRateId}`);

        return data;
    };

    static fetchSurchargeRates = async(paging: Paging, filters: SurchargeRateFilters): Promise<Pagination<SurchargeRate>> => {
       
        const formattedFilters = {
            ...filters,
            validFromDate: filters.validFromDate ? moment(filters.validFromDate).toISOString() : undefined,
            validToDate: filters.validToDate ? moment(filters.validToDate).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/surchargerate');

        const { data } = await http.get<Pagination<SurchargeRate>>(url);

        return data;
    };

    static createSurchargeRate = async(surchargeRate: SurchargeRate): Promise<SurchargeRate> => {
        const newSurchargeRate = {
            validFromDate: surchargeRate.validFromDate,
            validToDate: surchargeRate.validToDate,
            surchargeTypeId: surchargeRate.surchargeType?.id,
            surchargeAreaId: surchargeRate.surchargeArea?.id,
            surchargeRateUnitId: surchargeRate?.surchargeRateUnit?.id,
            rate_Nok: surchargeRate?.rate_Nok,
            rate_Eur: surchargeRate?.rate_Eur
        };

        const { data } = await http.post<any, SurchargeRate>('surchargerate', newSurchargeRate);

        return data;
    };

    static updateSurchargeRate = async(surchargeRate: SurchargeRate): Promise<SurchargeRate> => {
        const newSurchargeRate = {
            validFromDate: surchargeRate.validFromDate,
            validToDate: surchargeRate.validToDate,
            surchargeTypeId: surchargeRate.surchargeType?.id,
            surchargeAreaId: surchargeRate.surchargeArea?.id,
            surchargeRateUnitId: surchargeRate?.surchargeRateUnit?.id,
            rate_Nok: surchargeRate?.rate_Nok,
            rate_Eur: surchargeRate?.rate_Eur
        };
        
        const { data } = await http.put<any, SurchargeRate>(`surchargerate/${surchargeRate.id}`, newSurchargeRate);

        return data;
    };

    static deleteSurchargeRate = async(surchargeRate: SurchargeRate): Promise<SurchargeRate> => {
        const { data } = await http.delete<any, SurchargeRate>(`surchargerate/${surchargeRate.id}`);

        return data;
    };
}