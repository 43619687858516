import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import GtsOrder from '../../interfaces/output/gtsOrder';
import { fetchGtsOrderById, getGtsOrder, isCreatingGtsOrder, isLoadingGtsOrder, isUpdatingGtsOrder, resetGtsOrder, updateGtsOrder } from '../../reducers/gtsOrderReducer';
import Paging from '../../interfaces/common/paging';
import GtsOrderUnitFilters from '../../interfaces/filters/gtsOrderUnitFilters';
import { deactivateGtsOrderUnit, fetchGtsOrderUnits, getGtsOrderUnits, isDeletingGtsOrderUnit, isLoadingGtsOrderUnits } from '../../reducers/gtsOrderUnitReducer';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import Pagination from '../../interfaces/common/pagination';
import ViewGtsOrder from './viewGtsOrder';
import GtsOrderUnitsList from '../gtsOrderUnits/orderUnitList';
import LanguageUtils from '../../utils/LanguageUtils';
import { ensure } from '../../utils/arrayUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IGtsOrderDetailsProps {
    classes: any;
    theme: Theme;
    fetchGtsOrderById: any;
    fetchGtsOrderUnits: any;
    updateGtsOrder: any;
    deactivateGtsOrderUnit: any;
    gtsOrder: GtsOrder;
    gtsOrderUnits: Pagination<GTSOrderUnit>;
    match: any;
    history: any;
    location: any;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoading: boolean;
    isDeletingGtsOrderUnit: boolean;
    isLoadingGtsOrder: boolean;
    isLoadingGtsOrderUnits: boolean;
    resetGtsOrder: () => void;
}

interface IGtsOrderDetailsState {
    gtsOrder: GtsOrder;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    gtsOrderUnitsPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGtsOrderById: (gtsOrderId: number) => dispatch(fetchGtsOrderById(gtsOrderId)),
    updateGtsOrder: (gtsorder: GtsOrder) => dispatch(updateGtsOrder(gtsorder)),
    deactivateGtsOrderUnit: (orderUnitId: number) => dispatch(deactivateGtsOrderUnit(orderUnitId)),
    fetchGtsOrderUnits: (paging: Paging, filters: GtsOrderUnitFilters) => dispatch(fetchGtsOrderUnits({
        paging,
        filters
    })),
    resetGtsOrder: () => dispatch(resetGtsOrder())
});

const mapStoreToProps = (store: RootState) => {
    return {
        gtsOrder: getGtsOrder(store),
        isUpdating: isUpdatingGtsOrder(store),
        isCreating: isCreatingGtsOrder(store),
        gtsOrderUnits: getGtsOrderUnits(store),
        isLoadingGtsOrder: isLoadingGtsOrder(store),
        isLoadingGtsOrderUnits: isLoadingGtsOrderUnits(store),
        isDeletingGtsOrderUnit: isDeletingGtsOrderUnit(store),
        isLoading: isUpdatingGtsOrder(store) || isCreatingGtsOrder(store) || isLoadingGtsOrderUnits(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('GTS_ORDER'),
    properties: LanguageUtils.createMessage('Properties'),
    orderUnits: LanguageUtils.createMessage('Order units')
};

class GtsOrderDetails extends Component<IGtsOrderDetailsProps, IGtsOrderDetailsState> {
    gtsOrderUnitsListRef: any;

    constructor(props: IGtsOrderDetailsProps) {
        super(props);

        this.gtsOrderUnitsListRef = React.createRef();
        this.state = {
            gtsOrder: {} as GtsOrder,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.GTS_ORDERS}/${props.match.params.gtsOrderId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_ORDERS}/${props.match.params.gtsOrderId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.orderUnits,
                icon: <FontAwesomeIcon icon="box" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.GTS_ORDERS}/${props.match.params.gtsOrderId}/orderUnits`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_ORDERS}/${props.match.params.gtsOrderId}/orderUnits`, props.location.pathname)
            }
            ]),
            selectedRow: undefined,
            gtsOrderUnitsPagination: PageUtils.getDefaultPaging(),
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchGtsOrderById } = this.props;
        const { gtsOrderId } = this.props.match.params;

        fetchGtsOrderById(gtsOrderId);
    }

    componentDidUpdate(prevProps: IGtsOrderDetailsProps) {
        const { gtsOrder, isLoadingGtsOrder, fetchGtsOrderUnits, isDeletingGtsOrderUnit } = this.props;
        const { gtsOrderUnitsPagination } = this.state;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const unitFilters = {
            orderNumber: gtsOrder.orderNumber
            // hasErrors: true
        } as GtsOrderUnitFilters;

        if(isDeletingGtsOrderUnit === false && prevProps.isDeletingGtsOrderUnit === true) {
            fetchGtsOrderUnits(gtsOrderUnitsPagination, unitFilters);
        }

        if(gtsOrder !== prevProps.gtsOrder) {
            this.setState({ gtsOrder });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && isLoadingGtsOrder === false && prevProps.isLoadingGtsOrder === true) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetGtsOrder } = this.props;
        resetGtsOrder();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchGtsOrderUnits, gtsOrder } = this.props;
        const { gtsOrderUnitsPagination } = this.state;

        const unitFilters = {
            orderNumber: gtsOrder.orderNumber
            // hasErrors: true
        } as GtsOrderUnitFilters;

        if(selectedMenuItem?.id === 2) {
            fetchGtsOrderUnits(gtsOrderUnitsPagination, unitFilters);
        }
    }

    deactivateGtsOrderUnit = () => {
        const { selectedRow } = this.state;
        const { deactivateGtsOrderUnit } = this.props;

        deactivateGtsOrderUnit(ensure(selectedRow));
    }

    getSelectedMenuItem = (state: IGtsOrderDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { gtsOrderId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.GTS_ORDERS}/${gtsOrderId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }

            case 2: { //gts order units
                if(selectedRow) {
                    return [
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateGtsOrderUnit
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }

    _onSortModelChange = (newModel: GridSortModel) => {
        const { fetchGtsOrderUnits } = this.props;
        const { gtsOrderUnitsPagination, sortModel, gtsOrder } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...gtsOrderUnitsPagination,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                gtsOrderUnitsPagination: newPagination
            });

            fetchGtsOrderUnits(
                newPagination,
                {
                    orderNumber: gtsOrder.orderNumber
                } as GtsOrderUnitFilters
            );
        }
    }

    render() {
        const { classes, gtsOrderUnits, isLoadingGtsOrderUnits, isLoadingGtsOrder, fetchGtsOrderUnits } = this.props;
        const { gtsOrder, menuItems, gtsOrderUnitsPagination, columnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingGtsOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.GtsOrder}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: gtsOrder.orderNumber,
                    icon: <FontAwesomeIcon icon="receipt" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.GTS_ORDERS}/:gtsOrderId/properties`}>
                            <ViewGtsOrder gtsOrder={gtsOrder} />
                        </Route>
                        <Route path={`/${UrlConstants.GTS_ORDERS}/:gtsOrderId/orderUnits`}>
                            <GtsOrderUnitsList isLoading={isLoadingGtsOrderUnits} rows={gtsOrderUnits} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                ref={this.gtsOrderUnitsListRef}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...gtsOrderUnitsPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchGtsOrderUnits(
                                        newPage,
                                        {
                                            orderNumber: gtsOrder.orderNumber
                                        } as GtsOrderUnitFilters
                                    );
                                    this.setState({ gtsOrderUnitsPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(GtsOrderDetails));