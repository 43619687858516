import { http } from '../utils/http';
import DashboardMessageType from '../interfaces/output/dashboardMessageType';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';

export default class DashboardMessageTypeApi {
    static fetchDashboardMessageTypes = async( paging: Paging, searchTerm: string): Promise<Array<DashboardMessageType>> => {
        const url = urlcat('', '/dashboardmessagetypes', {
            ...paging,
            searchTerm
        });
        const { data } = await http.get<Array<DashboardMessageType>>(url);
    
        return data;
    };
}