import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import UrlConstants from '../../constants/UrlConstants';
import Pagination from '../../interfaces/common/pagination';
import DataGridComponent from '../common/dataGrid';
import PriceList from '../../interfaces/output/priceList';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../utils/tableUtils';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import CustomStringLink from '../common/customStringLink';

interface IPricelistListProps {
    classes?: any;
    theme?: Theme;
    pricelists?: Pagination<PriceList>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    hiddenColumns?: Array<string>;
    applyRowFn?: any;
    defaultSelectionModel?: Array<string | number | undefined>;
    disableLinks?: boolean;
    onSortModelChange?: any;
    prefix?: string;
    secondPrefix?: string;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    isInAgreementDetails?: boolean
}

const messages = {
    name: LanguageUtils.createMessage('Price list name'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    discount: LanguageUtils.createMessage('Discount'),
    seller: LanguageUtils.createMessage('Seller'),
    currency: LanguageUtils.createMessage('Currency'),
    type: LanguageUtils.createMessage('Type'),
    unitSubGroup: LanguageUtils.createMessage('Subgroup'),
    productArena: LanguageUtils.createMessage('Product arena'),
    productType: LanguageUtils.createMessage('Product type'),
    pricingComponentType: LanguageUtils.createMessage('Pricing component type')
};

const defaultProps: IPricelistListProps = {
    isInAgreementDetails: false
};

const PricelistList = forwardRef((props: IPricelistListProps, ref: any): JSX.Element => {
    const { pricelists, componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, disableLinks,
        onSortModelChange, prefix, secondPrefix, columnVisibilityModel, onColumnVisibilityModelChange, isInAgreementDetails } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const currentVersion = (params: any) => {
        return applyRowFn(params)?.currentVersion?.id || 'latest';
    };

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();
        let x = [
            {
                field: secondPrefix ? `${secondPrefix}name` : 'name',
                headerName: intl.formatMessage(messages.name),
                flex: 1.5,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(cellValues)?.id}/${currentVersion(cellValues)}`}
                        value={applyRowFn(cellValues)?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}seller_name` : 'seller_name',
                headerName: intl.formatMessage(messages.seller),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.seller?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('seller', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}productType_name` : 'productType_name',
                headerName: intl.formatMessage(messages.productType),
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.productType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('productType', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}unitSubGroup_name` : 'unitSubGroup_name',
                headerName: intl.formatMessage(messages.unitSubGroup),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.unitSubGroup?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitSubGroup', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}productArena_name` : 'productArena_name',
                headerName: intl.formatMessage(messages.productArena),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.productArena?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('productArena', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}type_name` : 'type_name',
                headerName: intl.formatMessage(messages.type),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.type?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('type', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}pricingComponentType_name` : 'pricingComponentType_name',
                headerName: intl.formatMessage(messages.pricingComponentType),
                flex: 1.2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.pricingComponentType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('pricingComponentType', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}pricingComponentType_name` : 'currency_name',
                headerName: intl.formatMessage(messages.currency),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                    value={applyRowFn(params)?.currency?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('currency', hiddenColumns)
            }
        ] as GridColDef[];
        if(isInAgreementDetails) {
            x = [...x,
                {
                    field: 'discount',
                    headerName: intl.formatMessage(messages.discount),
                    flex: 0.8,
                    align: 'left',
                    headerAlign: 'left',
                    sortable: true,
                    renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.PRICE_LISTS}/${applyRowFn(params)?.id}/${currentVersion(params)}`}
                        value={params?.row?.discount ?? '-'} />,
                    hide: hiddenColumns && TableUtils.hideColumn('discount', hiddenColumns)
                }];
        }

        return x;
    };

    return (
        <DataGridComponent rows={rows || pricelists} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

PricelistList.defaultProps = defaultProps;

export default PricelistList;