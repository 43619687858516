import React, { forwardRef } from 'react';
import { Grid, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { useSelector } from 'react-redux';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import GenericTextField from '../common/widgets/genericTextField';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import WorkflowUtils from '../../utils/workflowUtils';
import GTSOrderUnitDepot from '../../interfaces/output/gtsOrderUnitDepot';
import { useIntl } from 'react-intl';
import { isLoadingGtsOrderUnitDepot, isUpdatingGtsOrderUnitDepot } from '../../reducers/gtsOrderUnitDepotLinesReducer';

interface GtsOrderUnitServiceStep1Props {
    orderUnitDepot: GTSOrderUnitDepot;
    onChange: any;
}

const messages = {
    depotFrom: LanguageUtils.createMessage('Depot from'),
    depotTo: LanguageUtils.createMessage('Depot to'),
    depotName: LanguageUtils.createMessage('Depot name'),
    place: LanguageUtils.createMessage('Place'),
    freeDepot: LanguageUtils.createMessage('Depot free')
};

const GtsorderUnitDepotFormStep1 = forwardRef((props: GtsOrderUnitServiceStep1Props, ref: any): JSX.Element => {
    const isLoadingObjects = [useSelector(isUpdatingGtsOrderUnitDepot), useSelector(isLoadingGtsOrderUnitDepot)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const intl = useIntl();
    const { orderUnitDepot, onChange } = props;
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="depotFrom"
                            label={messages.depotFrom}
                            value={orderUnitDepot?.depotFrom}
                            onChange={onChange}
                            type="start"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="depotTo"
                            label={messages.depotTo}
                            value={orderUnitDepot?.depotTo}
                            onChange={onChange}
                            type="end"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="place"
                            label={messages.place}
                            value={orderUnitDepot.place}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="depotName"
                            label={messages.depotName}
                            value={orderUnitDepot.depotName}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!orderUnitDepot.freeDepot}
                                onChange={(e: any, value: boolean) => onChange('freeDepot', value)}
                            />}
                            label={intl.formatMessage(messages.freeDepot)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default GtsorderUnitDepotFormStep1;