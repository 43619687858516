import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { store } from './setup/store';
import LanguageUtils from './utils/LanguageUtils';
import AppRouter from './router';
import React from 'react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import nbLocale from 'date-fns/locale/nb';

const dateFormats = {
    full: 'EEEE d. MMMM y',
    long: 'd. MMMM y',
    medium: 'd. MMM y',
    short: 'dd.MM.yyyy'
};

const nbLocaleWithFullYear = {
    ...nbLocale,
    formatLong: {
        ...nbLocale.formatLong,
        date: LanguageUtils.customBuildFormatLongFn({
            formats: dateFormats,
            defaultWidth: 'short'
        })
    }
};

const localeMap = {
    en: enLocale,
    nb: nbLocaleWithFullYear
};

const usedLanguage = LanguageUtils.getLanguage();
const simpleFormLanguage = LanguageUtils.getSimpleFormLanguage(usedLanguage);

const render = (Component: any) => {
    ReactDom.render(
        <div>
            <div className="main">
                <Provider store={(store)}>
                    <IntlProvider key={usedLanguage} locale={simpleFormLanguage} messages={LanguageUtils.getMessages(usedLanguage)}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[simpleFormLanguage]}>
                            <Component />
                        </LocalizationProvider>
                    </IntlProvider>
                </Provider>
            </div>
        </div>,
        document.getElementById('root')
    );
};

render(AppRouter);

export { store };