import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import OrderStatus from '../interfaces/output/orderStatus';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OrderStatusApi {
    static fetchOrderStatusById = async(orderStatusId: string): Promise<OrderStatus> => {
        const { data } = await http.get<OrderStatus>(`orderstatus/${orderStatusId}`);

        return data;
    };

    static fetchOrderStatuses = async(paging: Paging): Promise<Pagination<OrderStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/orderstatus');

        const { data } = await http.get<Pagination<OrderStatus>>(url);

        return data;
    };
}
