import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import AgreementVersion from '../interfaces/output/agreementVersion';
import { cloneAgreementVersionError, cloneAgreementVersionSuccess, createAgreementVersionTransitDiscountError, createAgreementVersionTransitDiscountSuccess,
    fetchAgreementVersionByIdError, fetchAgreementVersionByIdSuccess, fetchAgreementVersionTransitDiscountsError,
    fetchAgreementVersionTransitDiscountsSuccess, updateAgreementVersionError, updateAgreementVersionStatusError, updateAgreementVersionStatusSuccess, updateAgreementVersionSuccess }
    from '../reducers/agreementVersionReducer';
import AgreementVersionApi from '../api/agreementVersionApi';
import TransitDiscount from '../interfaces/output/transitDiscount';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchAgreementVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: AgreementVersion = yield call(AgreementVersionApi.fetchAgreementVersionById, agreementVersionId);

        yield put(fetchAgreementVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchAgreementVersionTransitDiscounts(action: PayloadAction<{agreementVersionId: string, paging: Paging}>) {
    const { agreementVersionId, paging } = action.payload;

    try {
        const response: Pagination<TransitDiscount> = yield call(AgreementVersionApi.fetchAgreementVersionTransitDiscounts, agreementVersionId, paging);

        yield put(fetchAgreementVersionTransitDiscountsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionTransitDiscountsError(error as HttpErrorResponse));
    }
}

function* doCreateAgreementVersionTransitDiscount(action: PayloadAction<TransitDiscount>) {
    const agreementDiscount = action.payload;

    try {
        const response: TransitDiscount = yield call(AgreementVersionApi.createAgreementVersionTransitDiscount, agreementDiscount);

        yield put(createAgreementVersionTransitDiscountSuccess(response));
    }

    catch (error: unknown) {
        yield put(createAgreementVersionTransitDiscountError(error as HttpErrorResponse));
    }
}

function* doUpdateAgreementVersionStatus(action: PayloadAction<AgreementVersion>) {
    const agreementVersion = action.payload;

    try {
        const response: AgreementVersion = yield call(AgreementVersionApi.updateAgreementVersionStatus, agreementVersion);

        yield put(updateAgreementVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateAgreementVersion(action: PayloadAction<AgreementVersion>) {
    const agreementVersion = action.payload;

    try {
        const response: AgreementVersion = yield call(AgreementVersionApi.updateAgreementVersion, agreementVersion);

        yield put(updateAgreementVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementVersionError(error as HttpErrorResponse));
    }
}

function* doCloneAgreementVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number, percentAdjustmentSpecialPrices: number, percentAdjustmentTransitDiscount: number}>) {
    const { toVersionId, fromVersionId, percentAdjustmentSpecialPrices, percentAdjustmentTransitDiscount } = action.payload;
    try {
        const response: AgreementVersion = yield call(AgreementVersionApi.cloneAgreementVersion, toVersionId, fromVersionId, percentAdjustmentSpecialPrices, percentAdjustmentTransitDiscount);

        yield put(cloneAgreementVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneAgreementVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('agreementVersion/fetchAgreementVersionById', doFetchAgreementVersion),
    takeLatest('agreementVersion/fetchAgreementVersionTransitDiscounts', doFetchAgreementVersionTransitDiscounts),
    takeLatest('agreementVersion/updateAgreementVersionStatus', doUpdateAgreementVersionStatus),
    takeLatest('agreementVersion/updateAgreementVersion', doUpdateAgreementVersion),
    takeLatest('agreementVersion/createAgreementVersionTransitDiscount', doCreateAgreementVersionTransitDiscount),
    takeLatest('agreementVersion/cloneAgreementVersion', doCloneAgreementVersion)
];