import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import FactorSetDangerousGoodsLineIn from '../interfaces/input/factorSetDangerousGoodsLineIn';
import FactorSetDangerousGoodsLineOut from '../interfaces/output/factorSetDangerousGoodsLineOut';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetDangerousGoodVersionApi {
    static fetchFactorSetDangerousGoodVersionById = async(factorSetVersionId: string): Promise<FactorSetVersionOut> => {
        const { data } = await http.get<FactorSetVersionOut>(`factorsets/dangerousgoods/versions/${factorSetVersionId}`);

        return data;
    };

    static fetchFactorSetDangerousGoodVersions = async(factorId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/dangerousgoods/${factorId}/versions`);

        const { data } = await http.get<Array<FactorSetVersionOut>>(url);

        return data;
    };

    static createFactorSetDangerousGoodVersionLines= async(versionLine: FactorSetDangerousGoodsLineOut): Promise<FactorSetDangerousGoodsLineOut> => {
        const newLine: FactorSetDangerousGoodsLineIn = {
            name: versionLine.name,
            dangerousGoodsTypeId: versionLine.dangerousGoodsType?.id,
            unitTypeId: versionLine.unitType?.id,
            factor: versionLine.factor,
            surcharge: versionLine.surcharge
        };

        const { data } = await http.post<FactorSetDangerousGoodsLineIn, FactorSetDangerousGoodsLineOut>( `factorsets/dangerousgoods/versions/${versionLine.factorSetVersionId}/lines`, newLine);

        return data;
    }

    static updateFactorSetDangerousGoodVersionStatus = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/dangerousgoods/versions/${factorSetVersion.id}/status/${factorSetVersion.status?.id}`);

        return data;
    }

    static updateFactorSetDangerousGoodVersion = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSetVersion.validFromDate,
            validToDate: factorSetVersion.validToDate,
            statusId: factorSetVersion.status?.id
        };
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/dangerousgoods/versions/${factorSetVersion.id}`, newVersion);

        return data;
    }

    static cloneFactorSetDangerousGoodVersion = async(toVersionId: number, fromVersionId: number): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/dangerousgoods/versions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}