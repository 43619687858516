import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import DangerousGoodsGroup from '../interfaces/output/dangerousGoodsGroup';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type DangerousGoodsGroupState = {
    dangerousGoodsGroup: DangerousGoodsGroup;
    dangerousGoodsGroups: Pagination<DangerousGoodsGroup>;
    isLoadingDangerousGoodsGroups: boolean;
    isLoadingDangerousGoodsGroup: boolean;
    isUpdating: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: DangerousGoodsGroupState = {
    dangerousGoodsGroup: {} as DangerousGoodsGroup,
    dangerousGoodsGroups: { content: [] as Array<DangerousGoodsGroup> } as Pagination<DangerousGoodsGroup>,
    isLoadingDangerousGoodsGroups: false,
    isLoadingDangerousGoodsGroup: false,
    isUpdating: false,
    error: undefined
};

export const dangerousGoodsSlice = createSlice({
    name: 'dangerousGoodsGroup',

    initialState,
    reducers: {
        fetchDangerousGoodsGroups: (state: DangerousGoodsGroupState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingDangerousGoodsGroups = true;
        },
        fetchDangerousGoodsGroupsSuccess: (state: DangerousGoodsGroupState, action: PayloadAction<Pagination<DangerousGoodsGroup>>) => {
            state.isLoadingDangerousGoodsGroups = false;
            state.dangerousGoodsGroups = action.payload;
        },
        fetchDangerousGoodsGroupsError: (state: DangerousGoodsGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDangerousGoodsGroups = false;
            state.error = action.payload;
        },
        fetchDangerousGoodsGroupsById: (state: DangerousGoodsGroupState, _action: PayloadAction<number>) => {
            state.isLoadingDangerousGoodsGroup = true;
        },
        fetchDangerousGoodsGroupsByIdSuccess: (state: DangerousGoodsGroupState, action: PayloadAction<DangerousGoodsGroup>) => {
            state.isLoadingDangerousGoodsGroup = false;
            state.dangerousGoodsGroup = action.payload;
        },
        fetchDangerousGoodsGroupsByIdError: (state: DangerousGoodsGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDangerousGoodsGroup = false;
            state.error = action.payload;
        },
        updateDangerousGoodsGroups: (state: DangerousGoodsGroupState, _action: PayloadAction<DangerousGoodsGroup>) => {
            state.isUpdating = true;
        },
        updateDangerousGoodsGroupsSuccess: (state: DangerousGoodsGroupState, action: PayloadAction<DangerousGoodsGroup>) => {
            state.isUpdating = false;
            state.dangerousGoodsGroup = action.payload;
        },
        updateDangerousGoodsGroupsError: (state: DangerousGoodsGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetDangerousGoodsGroups: (state: DangerousGoodsGroupState) => {
            state.dangerousGoodsGroups = initialState.dangerousGoodsGroups;
        },
        resetDangerousGoodsGroup: (state: DangerousGoodsGroupState) => {
            state.dangerousGoodsGroup = initialState.dangerousGoodsGroup;
        }
    }
});

export const { fetchDangerousGoodsGroups, fetchDangerousGoodsGroupsSuccess, fetchDangerousGoodsGroupsError, fetchDangerousGoodsGroupsById, fetchDangerousGoodsGroupsByIdSuccess,
    fetchDangerousGoodsGroupsByIdError, updateDangerousGoodsGroups, updateDangerousGoodsGroupsSuccess, updateDangerousGoodsGroupsError, resetDangerousGoodsGroups, resetDangerousGoodsGroup }
     = dangerousGoodsSlice.actions;

export const isLoadingDangerousGoodsGroups = (state: RootState): boolean => state.dangerousGoodsGroupReducer.isLoadingDangerousGoodsGroups;
export const isLoadingDangerousGoodsGroup = (state: RootState): boolean => state.dangerousGoodsGroupReducer.isLoadingDangerousGoodsGroup;
export const getDangerousGoodsGroups = (state: RootState): Pagination<DangerousGoodsGroup> => state.dangerousGoodsGroupReducer.dangerousGoodsGroups;
export const getDangerousGoodsGroup = (state: RootState): DangerousGoodsGroup => state.dangerousGoodsGroupReducer.dangerousGoodsGroup;
export const isUpdatingDangerousGoodsGroup = (state: RootState): boolean => state.dangerousGoodsGroupReducer.isUpdating;

export default dangerousGoodsSlice.reducer;