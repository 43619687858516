class DateTimeUtils {
    static dateTimeGMTStart(input: Date | undefined): Date {
        if(typeof input === 'undefined' || input === null) {
            input = new Date();
        }
        const dateNow = input;
        dateNow.setHours(0, 0, 0, 0);

        dateNow.setTime(dateNow.getTime() - dateNow.getTimezoneOffset() * 60 * 1000);
        input.setHours(dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds());

        return input;
    }

    static dateTimeGMTEnd(input: Date | undefined): Date {
        if(typeof input === 'undefined' || input === null) {
            input = new Date();
        }
        const dateNow = input;
        dateNow.setHours(23, 59, 59);
        dateNow.setTime(dateNow.getTime() - dateNow.getTimezoneOffset() * 60 * 1000);
        input.setHours(dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds());

        return input;
    }
}

export default DateTimeUtils;