import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import { isLoadingGtsOrderUnit, isUpdatingGtsOrderUnit } from '../../reducers/gtsOrderUnitReducer';
import { useSelector } from 'react-redux';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import GenericTextField from '../common/widgets/genericTextField';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import WorkflowUtils from '../../utils/workflowUtils';

interface AddGtsOrderUnitStep2Props {
    gtsOrderUnit: GTSOrderUnit;
    onChange: any;
}

const messages = {
    loadedFlag: LanguageUtils.createMessage('Loaded flag'),
    cancellationCode: LanguageUtils.createMessage('Cancellation code'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    teu: LanguageUtils.createMessage('TEU'),
    customerReference: LanguageUtils.createMessage('Customer reference'),
    departureTime: LanguageUtils.createMessage('Departure time'),
    departureTypeCode: LanguageUtils.createMessage('Departure type code'),
    fromPlace: LanguageUtils.createMessage('From place'),
    toPlace: LanguageUtils.createMessage('To place')
};

const AddGtsOrderUnitStep2 = forwardRef((props: AddGtsOrderUnitStep2Props, ref: any): JSX.Element => {
    const { formatMessage } = useIntl();
    const isLoadingObjects = [useSelector(isLoadingGtsOrderUnit), useSelector(isUpdatingGtsOrderUnit)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const { gtsOrderUnit, onChange } = props;
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>

                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!gtsOrderUnit.loadedFlag}
                                onChange={(e: any, value: boolean) => onChange('loadedFlag', value)}
                            />}
                            label={formatMessage(messages.loadedFlag)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="cancellationCode"
                            label={messages.cancellationCode}
                            value={gtsOrderUnit.cancellationCode}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="priorityType"
                            label={messages.priorityType}
                            value={gtsOrderUnit.priorityType}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="teu"
                            type="number"
                            label={messages.teu}
                            value={gtsOrderUnit.teu}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="customerReference"
                            label={messages.customerReference}
                            value={gtsOrderUnit.customerReference}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="departureTime"
                            label={messages.departureTime}
                            value={gtsOrderUnit?.departureTime}
                            onChange={onChange}
                            type="now"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="departureTypeCode"
                            label={messages.departureTypeCode}
                            value={gtsOrderUnit.departureTypeCode}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="fromPlace"
                            label={messages.fromPlace}
                            value={gtsOrderUnit.fromPlace}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="toPlace"
                            label={messages.toPlace}
                            value={gtsOrderUnit.toPlace}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddGtsOrderUnitStep2;