import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import AgreementApi from '../api/agreementApi';
import { createAgreementError, createAgreementSuccess, createAgreementVersionError,
    createAgreementVersionSuccess, deleteAgreementError, deleteAgreementSuccess, exportAgreementError, exportAgreementSuccess, fetchAgreementByIdError, fetchAgreementByIdSuccess, fetchAgreementsError,
    fetchAgreementsSuccess, fetchAgreementVersionsError, fetchAgreementVersionsSuccess, updateAgreementError,
    updateAgreementSuccess } from '../reducers/agreementsReducer';
import Agreement from '../interfaces/output/agreement';
import AgreementFilters from '../interfaces/filters/agreementFilters';
import AgreementVersion from '../interfaces/output/agreementVersion';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FileSaver from 'file-saver';

function* doFetchAgreement(action: PayloadAction<{ id: number, agreementVersionId?: number } >) {
    const { id, agreementVersionId } = action.payload;

    try {
        const response: Agreement = yield call(AgreementApi.fetchAgreementById, id, agreementVersionId);
        yield put(fetchAgreementByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementByIdError(error as HttpErrorResponse));
    }
}

function* doFetchAgreements(action: PayloadAction<{ paging: Paging, filters: AgreementFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<Agreement> = yield call(AgreementApi.fetchAgreements, paging, filters);
        yield put(fetchAgreementsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementsError(error as HttpErrorResponse));
    }
}

function* doFetchAgreementVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<AgreementVersion> = yield call(AgreementApi.fetchAgreementVersions, id, paging);
        yield put(fetchAgreementVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAgreementVersionsError(error as HttpErrorResponse));
    }
}

function* doCreateAgreement(action: PayloadAction<Agreement>) {
    const agreement = action.payload;

    try {
        const response: Agreement = yield call(AgreementApi.createAgreement, agreement);

        yield put(createAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(createAgreementError(error as HttpErrorResponse));
    }
}

function* doCreateAgreementVersion(action: PayloadAction<Agreement>) {
    const agreement = action.payload;
    try {
        const response: AgreementVersion = yield call(AgreementApi.createAgreementVersion, agreement);
        const newAgreement = { ...agreement,
            version: response } as Agreement;

        yield put(createAgreementVersionSuccess(newAgreement));
    }

    catch (error: unknown) {
        yield put(createAgreementVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateAgreement(action: PayloadAction<Agreement>) {
    const agreement = action.payload;

    try {
        const response: Agreement = yield call(AgreementApi.updateAgreement, agreement);

        yield put(updateAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateAgreementError(error as HttpErrorResponse));
    }
}

function* doDeactivateAgreement(action: PayloadAction<Agreement>) {
    const agreement = action.payload;
    
    try {
        const response: Agreement = yield call(AgreementApi.deactivateAgreement, agreement);

        yield put(deleteAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteAgreementError(error as HttpErrorResponse));
    }
}

function* doExportAgreement(action: PayloadAction<{id: number, forDate: Date}>) {
    const { id, forDate } = action.payload;
    try {
        const response: Blob = yield call(AgreementApi.exportAgreement, id, forDate);
        FileSaver.saveAs(response, 'pdf-download.pdf');

        yield put(exportAgreementSuccess());
    }

    catch (error: unknown) {
        yield put(exportAgreementError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('agreement/fetchAgreementById', doFetchAgreement),
    takeLatest('agreement/fetchAgreementVersions', doFetchAgreementVersions),
    takeLatest('agreement/fetchAgreements', doFetchAgreements),
    takeLatest('agreement/createAgreement', doCreateAgreement),
    takeLatest('agreement/createAgreementVersion', doCreateAgreementVersion),
    takeLatest('agreement/updateAgreement', doUpdateAgreement),
    takeLatest('agreement/deleteAgreement', doDeactivateAgreement),
    takeLatest('agreement/exportAgreement', doExportAgreement)
];

