import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import AddOrderUnitServiceForm from './addOrderUnitServicesForm';
import { resetOrderUnitService } from '../../../reducers/unitServiceReducer';

interface IAddOrderUnitServiceProps {
    classes: any;
    theme: Theme;
    match: any;
    resetOrderUnitService: any;
    steps: Array<number>;
    orderUnitServiceId?: number;
    orderUnitId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetOrderUnitService: () => dispatch(resetOrderUnitService())
});

const mapStoreToProps = (store: RootState, ownProps: IAddOrderUnitServiceProps) => {
    return {
        orderUnitServiceId: ownProps.match.params?.orderUnitServiceId ? Number(ownProps.match.params?.orderUnitServiceId) : undefined,
        orderUnitId: ownProps.match.params?.orderUnitId ? Number(ownProps.match.params?.orderUnitId) : undefined
    };
};

class AddOrderUnitService extends Component<IAddOrderUnitServiceProps> {
    componentWillUnmount() {
        const { resetOrderUnitService } = this.props;

        resetOrderUnitService();
    }

    render() {
        const { classes, steps, orderUnitServiceId, orderUnitId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddOrderUnitServiceForm orderUnitServiceId={orderUnitServiceId} orderUnitId={orderUnitId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddOrderUnitService));