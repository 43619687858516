import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import FactorSetWeightLineOut from '../interfaces/output/factorSetWeightLineOut';
import FactorSetWeightLineIn from '../interfaces/input/factorSetWeightLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetWeightVersionApi {
    static fetchFactorSetWeightVersionById = async(factorSetVersionId: string): Promise<FactorSetVersionOut> => {
        const { data } = await http.get<FactorSetVersionOut>(`factorsets/weight/versions/${factorSetVersionId}`);

        return data;
    };

    static fetchFactorSetWeightVersions = async(factorId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/weight/${factorId}/versions`);
        const { data } = await http.get<Array<FactorSetVersionOut>>(url);

        return data;
    };

    static createFactorSetWeightVersionLines= async(versionLine: FactorSetWeightLineOut): Promise<FactorSetWeightLineOut> => {
        const newLine: FactorSetWeightLineIn = {
            name: versionLine.name,
            fromWeightKg: versionLine.fromWeightKg,
            toWeightKg: versionLine.toWeightKg,
            factor: versionLine.factor,
            surcharge: versionLine.surcharge,
            unitTypeId: versionLine.unitType?.id
        };

        const { data } = await http.post<FactorSetWeightLineIn, FactorSetWeightLineOut>( `factorsets/weight/versions/${versionLine.factorSetVersionId}/lines`, newLine);

        return data;
    }

    static updateFactorSetWeightVersionStatus = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/weight/versions/${factorSetVersion.id}/status/${factorSetVersion.status?.id}`);

        return data;
    }

    static updateFactorSetWeightVersion = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSetVersion.validFromDate,
            validToDate: factorSetVersion.validToDate,
            statusId: factorSetVersion.status?.id
        };
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/weight/versions/${factorSetVersion.id}`, newVersion);

        return data;
    }

    static cloneFactorSetWeightVersion = async(toVersionId: number, fromVersionId: number): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/weight/versions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}