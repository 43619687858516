/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import Pagination from '../../../interfaces/common/pagination';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import WeightLineFilters from './line/weightLineFilters';
import FactorSetWeightLineOutput from '../../../interfaces/output/factorSetWeightLineOut';
import ViewFactorSetWeight from './viewFactorSetWeight';
import ViewFactorSetWeightLines from './line/weightLinesList';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import { deactivateFactorSetWeight, fetchFactorSetWeightById, getFactorSetWeight, isDeletingWeight, isLoadingFactorSetWeight, resetFactorSetWeight, resetFactorSetWeights }
    from '../../../reducers/factorSetWeightReducer';
import { isLoadingFactorSetVersions } from '../../../reducers/factorSetWeightReducer';
import Loader from '../../common/widgets/loader';
import FactorsVersionsList from '../factorsVersionList';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import {
    deactivateFactorSetWeightLine, fetchWeightVersionLines, getWeightLines, isCreatingWeightLine, isDeletingWeightLine, isLoadingFactorSetWeightLines, isUpdatingWeightLine,
    resetWeightVersionLines
} from '../../../reducers/weightLineReducer';
import { fetchWeightVersions, getFactorSetWeightVersions } from '../../../reducers/factorSetWeightVersionReducer';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IFactorSetWeightDetailsProps {
    classes: any;
    theme: Theme;
    fetchFactorSetWeightById: any;
    fetchWeightVersionLines: any;
    deactivateFactorSetWeight: any;
    deactivateFactorSetWeightLine: any;
    fetchWeightVersions: any;
    resetFactorSetWeight: () => any;
    resetFactorSetWeights: () => any;
    resetWeightVersionLines: () => any;
    factorSetWeight: FactorSetOut;
    match: any;
    weightLines: Pagination<FactorSetWeightLineOutput>;
    factorVersions: Pagination<FactorSetVersionOut>;
    history: any;
    location: any;
    isUpdatingWeight: boolean;
    isDeletingWeight: boolean;
    isDeletingLine: boolean;
    isCreatingWeight: boolean;
    isLoading: boolean;
    isLoadingFactorSetWeight: boolean;
    isLoadingFactorSetWeightLines: boolean;
    isLoadingFactorSetVersions: boolean;
}

interface IFactorSetWeightDetailsState {
    open: boolean;
    factorSetWeight: FactorSetOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverPaginationLines: Paging;
    sortModelLines: GridSortModel;
    sortModelVersions: GridSortModel;
    factorSetWeightColumnVisibilityModel: GridColumnVisibilityModel;
    factorsVersionLinesColumnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetWeightById: (id: number, versionId: number) => dispatch(fetchFactorSetWeightById({
        factorSetId: id,
        versionId
    })),
    fetchWeightVersionLines: (paging: Paging, filters: WeightLineFilters) => dispatch(fetchWeightVersionLines({
        paging,
        filters
    })),
    fetchWeightVersions: (id: number, paging: Paging) => dispatch(fetchWeightVersions({
        id,
        paging
    })),
    deactivateFactorSetWeight: (id: number) => dispatch(deactivateFactorSetWeight(id)),
    deactivateFactorSetWeightLine: (id: number) => dispatch(deactivateFactorSetWeightLine(id)),
    resetFactorSetWeight: () => dispatch(resetFactorSetWeight()),
    resetFactorSetWeights: () => dispatch(resetFactorSetWeights()),
    resetWeightVersionLines: () => dispatch(resetWeightVersionLines())
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetWeight: getFactorSetWeight(store),
        weightLines: getWeightLines(store),
        factorVersions: getFactorSetWeightVersions(store),
        isUpdatingWeight: isUpdatingWeightLine(store),
        isDeletingLine: isDeletingWeightLine(store),
        isDeletingWeight: isDeletingWeight(store),
        isCreatingWeight: isCreatingWeightLine(store),
        isLoadingFactorSetWeightLines: isLoadingFactorSetWeightLines(store),
        isLoadingFactorSetVersions: isLoadingFactorSetVersions(store),
        isLoading: isLoadingFactorSetWeight(store) || isUpdatingWeightLine(store) || isDeletingWeightLine(store) || isCreatingWeightLine(store) || isLoadingFactorSetWeightLines(store),
        isLoadingFactorSetWeight: isLoadingFactorSetWeight(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('FACTOR_SET_WEIGHT'),
    properties: LanguageUtils.createMessage('Properties'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    versions: LanguageUtils.createMessage('Versions'),
    factors: LanguageUtils.createMessage('lines')
};

class FactorSetWeightDetails extends Component<IFactorSetWeightDetailsProps, IFactorSetWeightDetailsState> {
    constructor(props: IFactorSetWeightDetailsProps) {
        super(props);

        this.state = {
            open: true,
            factorSetWeight: {} as FactorSetOut,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            serverPaginationLines: PageUtils.getDefaultPaging(),
            sortModelLines: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelVersions: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            factorSetWeightColumnVisibilityModel: {},
            factorsVersionLinesColumnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetWeightById } = this.props;
        const { weightId, factorSetVersionId } = this.props.match.params;
        const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;

        fetchFactorSetWeightById(weightId, versionId);
    }

    componentWillUnmount() {
        const { resetFactorSetWeight, resetFactorSetWeights, resetWeightVersionLines } = this.props;

        resetFactorSetWeight();
        resetFactorSetWeights();
        resetWeightVersionLines();
    }

    componentDidUpdate(prevProps: IFactorSetWeightDetailsProps) {
        const { factorSetWeight, isUpdatingWeight, isDeletingLine, isCreatingWeight, isLoadingFactorSetWeight, isDeletingWeight, history, fetchFactorSetWeightById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { factorSetVersionId, weightId } = this.props.match.params;

        if(factorSetWeight !== prevProps.factorSetWeight) {
            this.setState({ factorSetWeight });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.factorSetVersionId !== factorSetVersionId) {
                const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;
                fetchFactorSetWeightById(weightId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingFactorSetWeight && prevProps.isLoadingFactorSetWeight ||
            !isUpdatingWeight && prevProps.isUpdatingWeight ||
            !isDeletingLine && prevProps.isDeletingLine ||
            !isCreatingWeight && prevProps.isCreatingWeight)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingWeight && prevProps.isDeletingWeight) {
            history.push(`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DEPARTURE}`);
        }
    }

    isHiddenCategory = (): boolean => {
        const { factorSetWeight } = this.state;

        return factorSetWeight.version?.id ? false : true;
    }

    configureDrawer = (props: IFactorSetWeightDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.FACTOR_SETS}/weight/${props.match.params.weightId}/${props.match.params.factorSetVersionId}`;

        return ImmutableList([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/properties`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.versions,
            additionalMargin: true,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.factors,
            icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
            url: `${baseUrl}/lines`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/lines`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchWeightVersionLines, factorSetWeight, fetchWeightVersions } = this.props;
        const { weightId } = this.props.match.params;
        const { serverPagination, serverPaginationLines } = this.state;

        const lineFilters = {
            name: undefined,
            weightTypeId: null,
            factorSetVersionId: factorSetWeight.version?.id
        } as WeightLineFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverPagination,
            page: newPaging.page
        };

        const newPaginationLines = {
            ...serverPaginationLines,
            page: newPaging.page
        };

        if(selectedMenuItem?.id === 2) {
            fetchWeightVersions(weightId, newPagination);
        }

        if(selectedMenuItem?.id === 3 && factorSetWeight.version?.id) {
            fetchWeightVersionLines(newPaginationLines, lineFilters);
        }
    }

    getSelectedMenuItem = (state: IFactorSetWeightDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateFactorSet = () => {
        const { factorSetWeight } = this.state;
        const { deactivateFactorSetWeight } = this.props;

        deactivateFactorSetWeight(factorSetWeight.id);
    }

    deactivateFactorSetLine = () => {
        const { selectedRow } = this.state;
        const { deactivateFactorSetWeightLine } = this.props;

        deactivateFactorSetWeightLine(selectedRow);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow, factorSetWeight } = this.state;
        const { weightLines } = this.props;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { weightId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: factorSetWeight.version?.id ? `${UrlConstants.FACTOR_SETS}/weight/${weightId}/${factorSetWeight.version?.id}/edit` :
                            `${UrlConstants.FACTOR_SETS}/weight/${weightId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateFactorSet
                    }
                ];
            }

            case 2: { //weight versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/weight/${weightId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.FACTOR_SETS}/weight/${weightId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.FACTOR_SETS}/weight/${weightId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 3: { //lines
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/weight/${weightId}/version/${factorSetWeight.version?.id}/add/line`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !factorSetWeight.version?.id
                        }
                    ];
                }

                if(factorSetWeight.version) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/weight/${weightId}/version/${factorSetWeight.version?.id}/weightLine/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateFactorSetLine
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }
    _onSortChangeLines = (newModel: GridSortModel) => {
        const { fetchWeightVersionLines } = this.props;
        const { sortModelLines, serverPaginationLines, factorSetWeight } = this.state;

        const lineFilters = {
            name: undefined,
            weightTypeId: null,
            factorSetVersionId: factorSetWeight.version?.id
        } as WeightLineFilters;

        if(JSON.stringify(sortModelLines) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationLines,
                sort: newModel
            };
            this.setState({
                sortModelLines: newModel,
                serverPaginationLines: newPagination
            });

            fetchWeightVersionLines(newPagination, lineFilters);
        }
    }

    _onSortChangeVersions = (newModel: GridSortModel) => {
        const { fetchWeightVersions } = this.props;
        const { factorSetWeight, serverPagination, sortModelVersions } = this.state;

        if(JSON.stringify(sortModelVersions) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelVersions: newModel,
                serverPagination: newPagination
            });

            fetchWeightVersions(factorSetWeight.id, newPagination);
        }
    }

    render() {
        const { classes, isLoadingFactorSetWeight, isLoadingFactorSetWeightLines, weightLines, isDeletingLine, isDeletingWeight, isUpdatingWeight,
            isLoadingFactorSetVersions, factorVersions, fetchWeightVersions, fetchWeightVersionLines } = this.props;
        const { factorSetWeight, menuItems, serverPagination, serverPaginationLines, factorSetWeightColumnVisibilityModel, factorsVersionLinesColumnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingFactorSetWeight || isDeletingWeight}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.FactorSets}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: factorSetWeight.name,
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                            <span className={classes.values}> {factorSetWeight?.version?.versionNumber ?? '-'} </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                            {factorSetWeight?.version?.validFromDate || factorSetWeight?.version?.validToDate ? (
                                <span className={classes.values}>
                                    <NullableFormattedDate value={factorSetWeight?.version?.validFromDate} /> -
                                    {factorSetWeight?.version?.validToDate &&
                                        <> <NullableFormattedDate value={factorSetWeight?.version?.validToDate} /></>}
                                </span>
                            )
                                : '-'} </span>
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId/properties`}>
                            <Loader isLoading={isUpdatingWeight || isDeletingWeight}>
                                <ViewFactorSetWeight factorSetWeight={factorSetWeight} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId/lines`}>
                            <ViewFactorSetWeightLines isLoading={isLoadingFactorSetWeightLines || isDeletingLine}
                                rows={weightLines}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationLines,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const lineFilters = {
                                        name: undefined,
                                        weightTypeId: null,
                                        factorSetVersionId: factorSetWeight.version?.id
                                    } as WeightLineFilters;

                                    this.setState({ serverPaginationLines: newPage });
                                    fetchWeightVersionLines(newPage, lineFilters);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeLines(sortModel)}
                                columnVisibilityModel={factorSetWeightColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorSetWeightColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/weight/:weightId/:factorSetVersionId/versions`}>
                            <FactorsVersionsList
                                type={factorSetWeight.type?.code}
                                isLoading={isLoadingFactorSetVersions}
                                rows={factorVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchWeightVersions(factorSetWeight.id, newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeVersions(sortModel)}
                                columnVisibilityModel={factorsVersionLinesColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorsVersionLinesColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetWeightDetails));