import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MessageDescriptor, useIntl } from 'react-intl';
import PageUtils from '../../../utils/pageUtils';
import Product from '../../../interfaces/output/product';
import ProductFilters from '../../../interfaces/output/productFilters';
import { fetchProducts, getProducts, isLoadingProducts, resetProducts } from '../../../reducers/productReducer';

interface IProductPickerProps {
    value?: Product;
    onChange: any;
    placeholder: MessageDescriptor;
}

const ProductPicker = (props: IProductPickerProps): JSX.Element => {
    const { value, onChange, placeholder } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const [inputValue, setInputValue] = React.useState('');
    const propsIsLoadingProducts = useSelector(isLoadingProducts);
    const products = useSelector(getProducts).content;

    return (
        <Autocomplete
            value={value || null}
            onChange={(e, value: any) => onChange(value)}
            getOptionLabel={(option: Product) => option.name || ''}
            inputValue={inputValue}
            onInputChange={(event, newInputvalue: any) => {
                setInputValue(newInputvalue);
                if(!newInputvalue) {
                    onChange(null);
                    dispatch(resetProducts());
                }
                if(newInputvalue.length > 2) {
                    dispatch(fetchProducts({ paging,
                        filters: {
                            name: newInputvalue
                        } as ProductFilters }));
                }
            }}
            filterOptions={(x) => x}
            options={products}
            loading={propsIsLoadingProducts}
            isOptionEqualToValue={(option: Product, value: Product) => option.id === value.id}
            renderInput={(params) => <>
                <TextField {...params} variant="standard" label={placeholder ? formatMessage(placeholder) : ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {propsIsLoadingProducts ? <CircularProgress color="inherit" size={20} sx={{ position: 'absolute',
                                    right: 20 }}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }} />
            </>}
        />
    );
};
  
export default ProductPicker;