/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const InputLengthConstants_150 = 150;
class StringUtils {
    static isNullOrWhitespace(input: string | undefined) {
        if(typeof input === 'undefined' || input === null) {
            return true;
        }
        if(typeof input !== 'string') {
            return false;
        }

        return input.replace(/\s/g, '').length < 1;
    }

    static contains(haystack: string, needle: string) {
        return haystack.toUpperCase().indexOf(needle.toUpperCase()) > -1;
    }

    static isGuid(input: string) {
        if(StringUtils.isNullOrWhitespace(input)) {
            return false;
        }

        const regex = new RegExp('^({{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}}{0,1})$');

        return regex.test(input);
    }

    static camelize(str: string) {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
                return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
            })
            .replace(/\s+/g, '');
    }

    static jsonParse(input: string) {
        try {
            return JSON.parse(input);
        }
        catch (e) {
            return undefined;
        }
    }
    static trimAnInput(input: string, nrOfCharacters: number) {
        if(!!input && input.length > 0 && input.length > nrOfCharacters) {
            return `${input.slice(0, nrOfCharacters)}...`;
        }
        else {
            return input;
        }
    }

    static createComposedKey(firstParam: string, secondParam: string, separator = ';') {
        if(firstParam !== undefined && firstParam !== null && secondParam !== undefined && secondParam !== null) {
            return `${firstParam}${separator}${secondParam}`;
        }
        else {
            return null;
        }
    }
}

export default StringUtils;
