import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import TransitDiscount from '../interfaces/output/transitDiscount';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { deleteTransitDiscountError, deleteTransitDiscountSuccess, fetchTransitDiscountByIdError, fetchTransitDiscountByIdSuccess, updateTransitDiscountError,
    updateTransitDiscountSuccess } from '../reducers/transitDiscountReducer';
import TransitDiscountApi from '../api/transitDiscountApi';

function* doFetchTransitDiscount(action: PayloadAction<string>) {
    const discountId = action.payload;
    try {
        const response: TransitDiscount = yield call(TransitDiscountApi.fetchTransitDiscountById, discountId);

        yield put(fetchTransitDiscountByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchTransitDiscountByIdError(error as HttpErrorResponse));
    }
}

function* doUpdateTransitDiscount(action: PayloadAction<TransitDiscount>) {
    const agreementVersion = action.payload;

    try {
        const response: TransitDiscount = yield call(TransitDiscountApi.updateTransitDiscount, agreementVersion);

        yield put(updateTransitDiscountSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateTransitDiscountError(error as HttpErrorResponse));
    }
}

function* doDeleteTransitDiscount(action: PayloadAction<number>) {
    const discountId = action.payload;
    
    try {
        const response: TransitDiscount = yield call(TransitDiscountApi.deleteTransitDiscount, discountId);

        yield put(deleteTransitDiscountSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteTransitDiscountError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('transitDiscount/fetchTransitDiscountById', doFetchTransitDiscount),
    takeLatest('transitDiscount/updateTransitDiscount', doUpdateTransitDiscount),
    takeLatest('transitDiscount/deleteTransitDiscount', doDeleteTransitDiscount)
];