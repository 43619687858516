import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { rootReducer, RootState } from './index';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../setup/sagas';
// import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

const devtools = (process.env.NODE_ENV === 'development' && typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) || (() => (noop: any) => noop);

export const browserHistory = createBrowserHistory();
export const routerMiddleware = createRouterMiddleware(browserHistory);
export const sagaMiddleware = createSagaMiddleware();

function configureStore(initialState?: RootState): any {
    const middlewares = [routerMiddleware, sagaMiddleware];
    const enhancers = [applyMiddleware(...middlewares), devtools()];
    const enhancer = compose(...enhancers);

    const store = createStore(rootReducer, initialState as any, enhancer as any);
    // Create hook for async sagas
    sagaMiddleware.run(rootSaga);

    return store;
}

export const store = configureStore();