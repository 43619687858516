import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PriorityType from '../interfaces/output/priorityType';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type PriorityTypeState = {
    isLoading: boolean;
    priorityTypes: Pagination<PriorityType>;
    error: HttpErrorResponse | undefined;
};

const initialState: PriorityTypeState = {
    priorityTypes: { content: [] as Array<PriorityType> } as Pagination<PriorityType>,
    isLoading: false,
    error: undefined
};

export const priorityTypeSlice = createSlice({
    name: 'priorityType',

    initialState,
    reducers: {
        fetchPriorityTypes: (state: PriorityTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchPriorityTypesSuccess: (state: PriorityTypeState, action: PayloadAction<Pagination<PriorityType>>) => {
            state.isLoading = false;
            state.priorityTypes = action.payload;
        },
        fetchPriorityTypesError: (state: PriorityTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchPriorityTypes, fetchPriorityTypesSuccess, fetchPriorityTypesError } = priorityTypeSlice.actions;

export const isLoadingPriorityTypes = (state: RootState): boolean => state.priorityTypeReducer.isLoading;
export const getPriorityTypes = (state: RootState): Pagination<PriorityType> => state.priorityTypeReducer.priorityTypes;

export default priorityTypeSlice.reducer;