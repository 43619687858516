import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Avatar, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import DataGridComponent from '../../../common/dataGrid';
import TableUtils from '../../../../utils/tableUtils';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../../utils/LanguageUtils';
import FactorSetHoursLineOutput from '../../../../interfaces/output/factorSetHoursLineOut';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment from 'moment';
import TruncatedStringTooltip from '../../../common/truncatedStringTooltip';

interface IFactorSetsProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    factor: LanguageUtils.createMessage('Factor'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    fromHours: LanguageUtils.createMessage('From hours'),
    toHours: LanguageUtils.createMessage('To hours'),
    weekday: LanguageUtils.createMessage('Weekday'),
    unitType: LanguageUtils.createMessage('Unit type'),
    all: LanguageUtils.createMessage('All'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency')
};

export default function FactorSetHoursLinesList(props: IFactorSetsProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;
    const intl = useIntl();
    const theme = useTheme();

    const displayWeekDays = (hoursLine: FactorSetHoursLineOutput) => {
        const arrayDays = [hoursLine.monday, hoursLine.tuesday, hoursLine.wednesday, hoursLine.thursday, hoursLine.friday, hoursLine.saturday, hoursLine.sunday];

        return <> {arrayDays.map((day: any, index: number) => {
            const weekDays = [...moment.weekdaysMin().slice(1), moment.weekdaysMin()[0]];

            return <Avatar variant="square" sx={{
                bgcolor: day ? theme.palette.primary.main : theme.palette.lightGray.main,
                width: 25,
                height: 25,
                marginRight: '6px'
            }}>
                <Typography variant="subtitle2"> {weekDays[index]}</Typography>
            </Avatar>;
        })}
        </>;
    };

    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'name',
                headerName: `${intl.formatMessage(messages.name)}`,
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: 'fromHours',
                headerName: `${intl.formatMessage(messages.fromHours)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params).fromHours ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('fromHours', hiddenColumns)
            },
            {
                field: 'toHours',
                headerName: `${intl.formatMessage(messages.toHours)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params).toHours ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('toHours', hiddenColumns)
            },

            {
                field: 'weekday',
                headerName: `${intl.formatMessage(messages.weekday)}`,
                flex: 2.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => displayWeekDays(applyRowFn(params)),
                hide: hiddenColumns && TableUtils.hideColumn('weekday', hiddenColumns)
            },
            {
                field: 'unitType',
                headerName: intl.formatMessage(messages.unitType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    if(applyRowFn(params).unitType) {
                        return <><TruncatedStringTooltip value={`${applyRowFn(params).unitType?.name ?? ''} - ${applyRowFn(params).unitType?.code ?? ''}`} /></>;
                    }

                    return <><TruncatedStringTooltip value={intl.formatMessage(messages.all)} /></>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitType', hiddenColumns)
            },
            {
                field: 'factor',
                headerName: `${intl.formatMessage(messages.factor)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).factor ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('factor', hiddenColumns)
            },
            {
                field: 'surcharge',
                headerName: `${intl.formatMessage(messages.surcharge)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).surcharge ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('surcharge', hiddenColumns)
            },
            {
                field: 'surchargeCurrency',
                headerName: intl.formatMessage(messages.surchargeCurrency),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <TruncatedStringTooltip value={applyRowFn(params).surchargeCurrency?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('surchargeCurrency', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
}
