import { List } from 'immutable';
import PriceList from '../../../interfaces/output/priceList';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils, { ValidationConfig } from '../../../utils/validationUtils';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range')
};

export default class PricelistVersionStep1Validation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateInputDateStart(pricelist: PriceList): boolean {
        const dateStart = pricelist.version?.validFromDate;
        const dateTo = pricelist.version?.validToDate;
        
        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(pricelist: PriceList): boolean {
        const dateStart = pricelist.version?.validFromDate;
        const dateTo = pricelist.version?.validToDate;
        
        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validatePricelistForm(pricelist: PriceList): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { version
        } = pricelist;

        const requiredFields =
            this.validateInputStringLength(version?.factorSetWeight?.name) &&
            this.validateInputNumber(version?.status?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = pricelist.version?.validFromDate;
        const validDates = endDate ? PricelistVersionStep1Validation.validateInputDateStart(pricelist) &&
        PricelistVersionStep1Validation.validateInputDateEnd(pricelist) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        return validationModel;
    }
}
