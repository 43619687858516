import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import PricelistUnitTypeOut from '../interfaces/output/pricelistUnitType';
import PricelistUnitTypeIn from '../interfaces/input/pricelistUnitTypeIn';

export default class PriceListUnitTypeApi {
    static fetchPriceListUnitTypeById = async(pricingcomponentId: string): Promise<PricelistUnitTypeOut> => {
        const { data } = await http.get<PricelistUnitTypeOut>(`pricelistunittype/${pricingcomponentId}`);

        return data;
    };

    static fetchPriceListUnitTypes = async(): Promise<Pagination<PricelistUnitTypeOut>> => {
        const { data } = await http.get<Pagination<PricelistUnitTypeOut>>('/pricelistunittype');

        return data;
    };

    static createPriceListUnitType = async(pricelistunittype: PricelistUnitTypeOut): Promise<PricelistUnitTypeOut> => {
        const newFactorSetCalendar: PricelistUnitTypeIn = {
            pricelistId: pricelistunittype.pricelist?.id,
            unitTypeId: pricelistunittype.unitType?.id
        };

        const { data } = await http.post<PricelistUnitTypeIn, PricelistUnitTypeOut>('pricelistunittype', newFactorSetCalendar);

        return data;
    }

    static updatePriceListUnitType = async(pricelistunittype: PricelistUnitTypeOut): Promise<PricelistUnitTypeOut> => {
        const newLine: PricelistUnitTypeIn = {
            pricelistId: pricelistunittype.pricelist?.id,
            unitTypeId: pricelistunittype.unitType?.id
        };
        
        const { data } = await http.put<PricelistUnitTypeIn, PricelistUnitTypeOut>(`pricelistunittype/${pricelistunittype.id}`, newLine);

        return data;
    }

    static deactivatePriceListUnitType = async(pricingcomponentId: number): Promise<PricelistUnitTypeOut> => {
        const { data } = await http.delete<PricelistUnitTypeOut>(`pricelistunittype/${pricingcomponentId}`);

        return data;
    }
}
