import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';
import LoadedFlag from '../interfaces/output/loadedFlag';
import { http } from '../utils/http';

export default class LoadedFlagApi {
    static fetchLoadedFlagById = async(loadedFlagId: number): Promise<LoadedFlag> => {
        const { data } = await http.get<LoadedFlag>(`loadedflag/${loadedFlagId}`);

        return data;
    };

    static fetchLoadedFlags = async(paging: Paging, includeDeleted: boolean): Promise<Array<LoadedFlag>> => {
        const url = urlcat('', '/loadedflag', {
            ...paging,
            includeDeleted
        });
        const { data } = await http.get<Array<LoadedFlag>>(url);

        return data;
    };
}
