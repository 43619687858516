import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../constants/constants';
import GtsOrderUnitDepotForm from './gtsOrderUnitDepotForm';
import { resetGtsOrderUnitDepot } from '../../reducers/gtsOrderUnitDepotLinesReducer';

interface IAddGtsOrderUnitDepotFormProps {
    classes: any;
    theme: Theme;
    match: any;
    resetGtsOrderUnitDepot: any;
    steps: Array<number>;
    gtsOrderUnitId?: number;
    type: FormType;
    depotId?: number;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetGtsOrderUnitDepot: () => dispatch(resetGtsOrderUnitDepot())
});

const mapStoreToProps = (store: RootState, ownProps: IAddGtsOrderUnitDepotFormProps) => {
    return {
        depotId: ownProps.match.params?.depotId ? Number(ownProps.match.params?.depotId) : undefined,
        gtsOrderUnitId: ownProps.match.params?.gtsOrderUnitId ? Number(ownProps.match.params?.gtsOrderUnitId) : undefined
    };
};

class AddGtsOrderUnitDepot extends Component<IAddGtsOrderUnitDepotFormProps> {

    componentWillUnmount() {
        const { resetGtsOrderUnitDepot } = this.props;

        resetGtsOrderUnitDepot();
    }

    render() {
        const { classes, steps, gtsOrderUnitId, depotId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <GtsOrderUnitDepotForm gtsOrderUnitId={gtsOrderUnitId} depotId={depotId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddGtsOrderUnitDepot));