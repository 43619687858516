import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../../constants/UrlConstants';
import { RootState } from '../../../setup';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactorSetsFiltersComponent from './../factorSetsFiltersComponent';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import LanguageUtils from '../../../utils/LanguageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import PageUtils from '../../../utils/pageUtils';
import CalendarFilter from './calendarFilter';
import { fetchFactorSetCalendars, getFactorSetCalendars, isLoadingFactorSetCalendars } from '../../../reducers/factorSetCalendarReducer';
import CalendarList from './calendarList';

interface IFactorSetCalendarProps {
    factorSetCalendars: Pagination<FactorSetOut>;
    classes: any;
    fetchFactorSetCalendars: any;
    isLoading: boolean;
    onChange: () => void;
}

interface IFactorSetCalendarState {
    searchTerm: string;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    filters: CalendarFilter;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    factorSetCalendars: LanguageUtils.createMessage('Factor set calendar'),
    add: LanguageUtils.createMessage('Add')
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetCalendars: (paging: Paging, filters: CalendarFilter) => dispatch(fetchFactorSetCalendars({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetCalendars: getFactorSetCalendars(store),
        isLoading: isLoadingFactorSetCalendars(store)
    };
};

class FactorSetCalendar extends Component<IFactorSetCalendarProps, IFactorSetCalendarState> {
    calendarListRef: any;

    constructor(props: IFactorSetCalendarProps) {
        super(props);
        this.calendarListRef = React.createRef();

        this.state = {
            searchTerm: '',
            paging: PageUtils.getDefaultPaging(),
            filters: {
                name: ''
                // default: true
            } as CalendarFilter,
            menuItem: {
                id: 1,
                name: messages.factorSetCalendars,
                icon: <FontAwesomeIcon icon="calendar-check" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetCalendars } = this.props;
        const { paging, filters } = this.state;

        fetchFactorSetCalendars(paging, filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as CalendarFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchFactorSetCalendars } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchFactorSetCalendars(newPagination, filters);

        if(this.calendarListRef !== null && this.calendarListRef !== undefined &&
            this.calendarListRef.current !== null && this.calendarListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.calendarListRef.current.resetDataGridPage();
        }

    }

    setDefaultState = () => {
        const { fetchFactorSetCalendars } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'name',
            sort: 'asc'
        }] as GridSortModel;

        const newFilters = {
            name: ''
            // default: true
        } as CalendarFilter;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchFactorSetCalendars(newPagination, newFilters);

        if(this.calendarListRef !== null && this.calendarListRef !== undefined &&
            this.calendarListRef.current !== null && this.calendarListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.calendarListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchFactorSetCalendars } = this.props;

        const newFilter = { ...filters } as CalendarFilter;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchFactorSetCalendars(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchFactorSetCalendars } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });
            fetchFactorSetCalendars(newPagination, filters);
        }
    }

    render() {
        const { classes, factorSetCalendars, fetchFactorSetCalendars, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.FACTOR_SETS}/calendar/add`
                                    }]}
                                >
                                    <FactorSetsFiltersComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <CalendarList rows={factorSetCalendars}
                                    isLoading={isLoading}
                                    ref={this.calendarListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchFactorSetCalendars(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetCalendar));