import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import PriceListType from '../interfaces/output/priceListType';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type PriceListTypeState = {
    priceListType: PriceListType;
    isLoadingPriceListType: boolean;
    isLoadingPriceListTypes: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    priceListTypes: Pagination<PriceListType>;
    error: HttpErrorResponse | undefined;
};

const initialState: PriceListTypeState = {
    priceListType: {} as PriceListType,
    priceListTypes: { content: [] as Array<PriceListType> } as Pagination<PriceListType>,
    isLoadingPriceListType: false,
    isLoadingPriceListTypes: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const priceListTypeSlice = createSlice({
    name: 'pricelisttype',

    initialState,
    reducers: {
        fetchPriceListTypes: (state: PriceListTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingPriceListTypes = true;
        },
        fetchPriceListTypesSuccess: (state: PriceListTypeState, action: PayloadAction<Pagination<PriceListType>>) => {
            state.isLoadingPriceListTypes = false;
            state.priceListTypes = action.payload;
        },
        fetchPriceListTypesError: (state: PriceListTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPriceListTypes = false;
            state.error = action.payload;
        },
        fetchPriceListTypeById: (state: PriceListTypeState, _action: PayloadAction<number>) => {
            state.isLoadingPriceListType = true;
        },
        fetchPriceListTypeByIdSuccess: (state: PriceListTypeState, action: PayloadAction<PriceListType>) => {
            state.isLoadingPriceListType = false;
            state.priceListType = action.payload;
        },
        fetchPriceListTypeByIdError: (state: PriceListTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPriceListType = false;
            state.error = action.payload;
        },
        createPriceListType: (state: PriceListTypeState, _action: PayloadAction<PriceListType>) => {
            state.isCreating = true;
        },
        createPriceListTypeSuccess: (state: PriceListTypeState, action: PayloadAction<PriceListType>) => {
            state.isCreating = false;
            state.priceListType = action.payload;
        },
        createPriceListTypeError: (state: PriceListTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updatePriceListType: (state: PriceListTypeState, _action: PayloadAction<PriceListType>) => {
            state.isUpdating = true;
        },
        updatePriceListTypeSuccess: (state: PriceListTypeState, action: PayloadAction<PriceListType>) => {
            state.isUpdating = false;
            state.priceListType = action.payload;
        },
        updatePriceListTypeError: (state: PriceListTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deletePriceListType: (state: PriceListTypeState, _action: PayloadAction<PriceListType>) => {
            state.isDeleted = true;
        },
        deletePriceListTypeSuccess: (state: PriceListTypeState, action: PayloadAction<PriceListType>) => {
            state.isDeleted = false;
            state.priceListType = action.payload;
        },
        deletePriceListTypeError: (state: PriceListTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        }
    }
});

export const { fetchPriceListTypes, fetchPriceListTypesSuccess, fetchPriceListTypesError, fetchPriceListTypeById, fetchPriceListTypeByIdSuccess, fetchPriceListTypeByIdError,
    createPriceListType, createPriceListTypeSuccess, createPriceListTypeError, updatePriceListType, updatePriceListTypeSuccess, updatePriceListTypeError, deletePriceListType,
    deletePriceListTypeSuccess, deletePriceListTypeError } = priceListTypeSlice.actions;

export const isLoadingPriceListType = (state: RootState): boolean => state.pricelistTypeReducer.isLoadingPriceListType;
export const isLoadingPriceListTypes = (state: RootState): boolean => state.pricelistTypeReducer.isLoadingPriceListTypes;
export const isUpdating = (state: RootState): boolean => state.pricelistTypeReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.pricelistTypeReducer.isDeleted;
export const isCreating = (state: RootState): boolean => state.pricelistTypeReducer.isCreating;
export const getPriceListTypes = (state: RootState): Pagination<PriceListType> => state.pricelistTypeReducer.priceListTypes;
export const getPriceListType = (state: RootState): PriceListType => state.pricelistTypeReducer.priceListType;

export default priceListTypeSlice.reducer;