import { Fab, Grid, Button, styled } from '@mui/material';
import React from 'react';
import Action from '../../interfaces/common/action';
import { useIntl } from 'react-intl';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { ensure } from '../../utils/arrayUtils';
import Information from '../../interfaces/common/information';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface IActionRenderer {
    actions?: Array<Action>;
    information?: Information;
}

const ActionRenderer = (props: IActionRenderer) : JSX.Element => {
    const { actions, information } = props;
    const { formatMessage } = useIntl();
    const history = useHistory();

    if(!actions || !actions.length) {
        return <span />;
    }

    const _onClick = (action: Action) => {
        if(action.href) {
            history.push(`/${ensure(action.href)}`);
        }

        if(!action.onClick) {
            return () => { return 0; };
        }

        return action.onClick();
    };
    
    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: '500'
        }
    });

    // eslint-disable-next-line no-console
    console.log(actions);
      
    return (
        <>
            {information && (
                <CustomWidthTooltip title={formatMessage(information.text)} >
                    <Fab size="small" color="info">
                        <FontAwesomeIcon icon={information.icon as IconProp} size="1x" transform="shrink-2" />
                    </Fab>
                </CustomWidthTooltip>
            )}
            {actions?.filter(a => !a.isHidden).map((action: Action, index: number) =>
                <Grid item pl={1} key={index}>
                    {!action.button ?
                        <Fab color={!action.active ? 'primary' : 'secondary'} aria-label={formatMessage(action.text)} size="small"
                            disabled={!!action.disabled} onClick={() => _onClick(action)} title={formatMessage(action.text)}>
                            <FontAwesomeIcon icon={action.icon as IconProp} size="1x" />
                        </Fab>
                        :
                        <Button variant="text" color="info" onClick={() => _onClick(action)}>
                            <FontAwesomeIcon icon={action.icon as IconProp} size="2x" />
                        </Button>
                    }
                </Grid>)}
        </>
    );
};

export default ActionRenderer;