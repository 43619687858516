import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import SimulationOut from '../interfaces/output/simulationOut';
import SimulationApi from '../api/simulationApi';
import { createSimulationError, createSimulationSuccess, deleteSimulationError, deleteSimulationSuccess,
    fetchSimulationByIdError, fetchSimulationByIdSuccess, fetchSimulationsError, fetchSimulationsSuccess,
    updateSimulationError, updateSimulationSuccess } from '../reducers/simulationReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import SimulationFilters from '../interfaces/output/simulationFilters';

function* doFetchSimulation(action: PayloadAction<string>) {
    const simulationId = action.payload;
    try {
        const response: SimulationOut = yield call(SimulationApi.fetchSimulationById, simulationId);
        yield put(fetchSimulationByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSimulations(action: PayloadAction<{ paging: Paging, filters: SimulationFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<SimulationOut> = yield call(SimulationApi.fetchSimulations, paging, filters);
        yield put(fetchSimulationsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationsError(error as HttpErrorResponse));
    }
}

function* doUpdateSimulation(action: PayloadAction<SimulationOut>) {
    const simulation = action.payload;

    try {
        const response: SimulationOut = yield call(SimulationApi.updateSimulation, simulation);

        yield put(updateSimulationSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateSimulationError(error as HttpErrorResponse));
    }
}

function* doCreateSimulation(action: PayloadAction<SimulationOut>) {
    const simulation = action.payload;

    try {
        const response: SimulationOut = yield call(SimulationApi.createSimulation, simulation);

        yield put(createSimulationSuccess(response));
    }

    catch (error: unknown) {
        yield put(createSimulationError(error as HttpErrorResponse));
    }
}

function* doDeleteSimulation(action: PayloadAction<SimulationOut>) {
    const simulation = action.payload;
    
    try {
        const response: SimulationOut = yield call(SimulationApi.deleteSimulation, simulation);

        yield put(deleteSimulationSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteSimulationError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('simulation/fetchSimulationById', doFetchSimulation),
    takeLatest('simulation/fetchSimulations', doFetchSimulations),
    takeLatest('simulation/updateSimulation', doUpdateSimulation),
    takeLatest('simulation/createSimulation', doCreateSimulation),
    takeLatest('simulation/deleteSimulation', doDeleteSimulation)
];