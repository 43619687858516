import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetHoursVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetHoursVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerHoursVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchHoursVersionById: (state: FactorSetHoursVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchHoursVersionByIdSuccess: (state: FactorSetHoursVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchHoursVersionByIdError: (state: FactorSetHoursVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchHoursVersions: (state: FactorSetHoursVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchHoursVersionsSuccess: (state: FactorSetHoursVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchHoursVersionsError: (state: FactorSetHoursVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updateHoursVersionStatus: (state: FactorSetHoursVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updateHoursVersionStatusSuccess: (state: FactorSetHoursVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updateHoursVersionStatusError: (state: FactorSetHoursVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updateHoursVersion: (state: FactorSetHoursVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updateHoursVersionSuccess: (state: FactorSetHoursVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updateHoursVersionError: (state: FactorSetHoursVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneHoursVersion: (state: FactorSetHoursVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        cloneHoursVersionSuccess: (state: FactorSetHoursVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        cloneHoursVersionError: (state: FactorSetHoursVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetHoursVersion: (state: FactorSetHoursVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetHoursVersions: (state: FactorSetHoursVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchHoursVersionById, fetchHoursVersionByIdSuccess, fetchHoursVersionByIdError, updateHoursVersionStatus, updateHoursVersionStatusSuccess,
    updateHoursVersionStatusError, cloneHoursVersion, updateHoursVersion, cloneHoursVersionSuccess, fetchHoursVersions, fetchHoursVersionsSuccess, fetchHoursVersionsError,
    updateHoursVersionSuccess, updateHoursVersionError, resetHoursVersion, cloneHoursVersionError, resetHoursVersions } = customerHoursVersionSlice.actions;

export const isLoadingHoursVersion = (state: RootState): boolean => state.factorSetHoursVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetHoursVersionReducer.isCloningVersion;
export const isUpdatingHoursVersionStatus = (state: RootState): boolean => state.factorSetHoursVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetHoursVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetHoursVersionReducer.isUpdatingVersion;
export const getHoursVersion = (state: RootState): FactorSetVersionOut => state.factorSetHoursVersionReducer.factorSetVersion;
export const isLoadingFactorSetHoursVersions = (state: RootState): boolean => state.factorSetHoursVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetHoursVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetHoursVersionReducer.factorSetVersions;

export default customerHoursVersionSlice.reducer;