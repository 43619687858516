import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import OrderUnitServiceProductSurchargeOut from '../interfaces/output/orderUnitServiceProductSurchargeOut';

export type SurchargeState = {
    surcharge: OrderUnitServiceProductSurchargeOut;
    isLoadingSurcharge: boolean;
    isLoadingSurcharges: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isUpdatingBulk: boolean;
    surcharges: Pagination<OrderUnitServiceProductSurchargeOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: SurchargeState = {
    surcharge: {} as OrderUnitServiceProductSurchargeOut,
    surcharges: { content: [] as Array<OrderUnitServiceProductSurchargeOut> } as Pagination<OrderUnitServiceProductSurchargeOut>,
    isLoadingSurcharge: false,
    isLoadingSurcharges: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isUpdatingBulk: false,
    error: undefined
};

export const surchargeReducer = createSlice({
    name: 'surcharge',

    initialState,
    reducers: {
        fetchSurcharges: (state: SurchargeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingSurcharges = true;
        },
        fetchSurchargesSuccess: (state: SurchargeState, action: PayloadAction<Pagination<OrderUnitServiceProductSurchargeOut>>) => {
            state.isLoadingSurcharges = false;
            state.surcharges = action.payload;
        },
        fetchSurchargesError: (state: SurchargeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSurcharges = false;
            state.error = action.payload;
        },
        fetchSurchargeById: (state: SurchargeState, _action: PayloadAction<number>) => {
            state.isLoadingSurcharge = true;
        },
        fetchSurchargeByIdSuccess: (state: SurchargeState, action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isLoadingSurcharge = false;
            state.surcharge = action.payload;
        },
        fetchSurchargeByIdError: (state: SurchargeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSurcharge = false;
            state.error = action.payload;
        },
        createSurcharge: (state: SurchargeState, _action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isCreating = true;
        },
        createSurchargeSuccess: (state: SurchargeState, action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isCreating = false;
            state.surcharge = action.payload;
        },
        createSurchargeError: (state: SurchargeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateSurcharge: (state: SurchargeState, _action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isUpdating = true;
        },
        updateSurchargeSuccess: (state: SurchargeState, action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isUpdating = false;
            state.surcharge = action.payload;
        },
        updateSurchargeError: (state: SurchargeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteSurcharge: (state: SurchargeState, _action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isDeleting = true;
        },
        deleteSurchargeSuccess: (state: SurchargeState, action: PayloadAction<OrderUnitServiceProductSurchargeOut>) => {
            state.isDeleting = false;
            state.surcharge = action.payload;
        },
        deleteSurchargeError: (state: SurchargeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetSurcharges: (state: SurchargeState) => {
            state.surcharges = initialState.surcharges;
        },
        resetSurcharge: (state: SurchargeState) => {
            state.surcharge = initialState.surcharge;
        }
    }
});

export const { fetchSurcharges, fetchSurchargesSuccess, fetchSurchargesError, fetchSurchargeById, fetchSurchargeByIdSuccess, fetchSurchargeByIdError,
    createSurcharge, createSurchargeSuccess, createSurchargeError, updateSurcharge, updateSurchargeSuccess, updateSurchargeError, deleteSurcharge,
    deleteSurchargeSuccess, deleteSurchargeError, resetSurcharges, resetSurcharge } = surchargeReducer.actions;

export const isLoadingSurcharge = (state: RootState): boolean => state.surchargeReducer.isLoadingSurcharge;
export const isLoadingSurcharges = (state: RootState): boolean => state.surchargeReducer.isLoadingSurcharges;
export const isUpdatingSurcharge = (state: RootState): boolean => state.surchargeReducer.isUpdating;
export const isDeletingSurcharge = (state: RootState): boolean => state.surchargeReducer.isDeleting;
export const isCreatingSurcharge = (state: RootState): boolean => state.surchargeReducer.isCreating;
export const getSurcharges = (state: RootState): Pagination<OrderUnitServiceProductSurchargeOut> => state.surchargeReducer.surcharges;
export const getSurcharge = (state: RootState): OrderUnitServiceProductSurchargeOut => state.surchargeReducer.surcharge;

export default surchargeReducer.reducer;