import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import OrderUnitServiceProduct from '../interfaces/output/orderUnitServiceProduct';
import OrderUnitServiceProductSurchargeOut from '../interfaces/output/orderUnitServiceProductSurchargeOut';
import { RootState } from '../setup';

export type UnitServiceProductState = {
    orderUnitServiceProduct: OrderUnitServiceProduct;
    unitServiceProductSurcharges: Pagination<OrderUnitServiceProductSurchargeOut>;
    isLoadingUnitServiceProduct: boolean;
    isCreatingUnitServiceProduct: boolean;
    isLoadingUnitServiceProductSurcharges: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: UnitServiceProductState = {
    orderUnitServiceProduct: {} as OrderUnitServiceProduct,
    unitServiceProductSurcharges: { content: [] as Array<OrderUnitServiceProductSurchargeOut> } as Pagination<OrderUnitServiceProductSurchargeOut>,
    isLoadingUnitServiceProduct: false,
    isCreatingUnitServiceProduct: false,
    isLoadingUnitServiceProductSurcharges: false,
    isUpdating: false,
    isDeleting: false,
    error: undefined
};

export const orderUnitServiceProductSlice = createSlice({
    name: 'orderUnitServiceProduct',

    initialState,
    reducers: {
        fetchOrderUnitServiceProductById: (state: UnitServiceProductState, _action: PayloadAction<number>) => {
            state.isLoadingUnitServiceProduct = true;
        },
        fetchOrderUnitServiceProductByIdSuccess: (state: UnitServiceProductState, action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isLoadingUnitServiceProduct = false;
            state.orderUnitServiceProduct = action.payload;
        },
        fetchOrderUnitServiceProductByIdError: (state: UnitServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitServiceProduct = false;
            state.error = action.payload;
        },
        fetchSurchargesByOrderUnitServiceProductId: (state: UnitServiceProductState, _action: PayloadAction<{paging: Paging, id: number}>) => {
            state.isLoadingUnitServiceProductSurcharges = true;
        },
        fetchSurchargesByOrderUnitServiceProductIdSuccess: (state: UnitServiceProductState, action: PayloadAction<Pagination<OrderUnitServiceProductSurchargeOut>>) => {
            state.isLoadingUnitServiceProductSurcharges = false;
            state.unitServiceProductSurcharges = action.payload;
        },
        fetchSurchargesByOrderUnitServiceProductIdError: (state: UnitServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitServiceProductSurcharges = false;
            state.error = action.payload;
        },
        createOrderUnitServiceProduct: (state: UnitServiceProductState, _action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isCreatingUnitServiceProduct = true;
        },
        createOrderUnitServiceProductSuccess: (state: UnitServiceProductState, action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isCreatingUnitServiceProduct = false;
            state.orderUnitServiceProduct = action.payload;
        },
        createOrderUnitServiceProductError: (state: UnitServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingUnitServiceProduct = false;
            state.error = action.payload;
        },
        updateOrderUnitServiceProduct: (state: UnitServiceProductState, _action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isUpdating = true;
        },
        updateOrderUnitServiceProductSuccess: (state: UnitServiceProductState, action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isUpdating = false;
            state.orderUnitServiceProduct = action.payload;
        },
        updateOrderUnitServiceProductError: (state: UnitServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateOrderUnitServiceProduct: (state: UnitServiceProductState, _action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isDeleting = true;
        },
        deactivateOrderUnitServiceProductSuccess: (state: UnitServiceProductState, action: PayloadAction<OrderUnitServiceProduct>) => {
            state.isDeleting = false;
            state.orderUnitServiceProduct = action.payload;
        },
        deactivateOrderUnitServiceProductError: (state: UnitServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetOrderUnitServiceProduct: (state: UnitServiceProductState) => {
            state.orderUnitServiceProduct = initialState.orderUnitServiceProduct;
        }
    }
});

export const { fetchOrderUnitServiceProductById, fetchOrderUnitServiceProductByIdSuccess, fetchOrderUnitServiceProductByIdError, updateOrderUnitServiceProduct, updateOrderUnitServiceProductSuccess,
    updateOrderUnitServiceProductError, deactivateOrderUnitServiceProduct, deactivateOrderUnitServiceProductError, deactivateOrderUnitServiceProductSuccess, resetOrderUnitServiceProduct,
    createOrderUnitServiceProduct, createOrderUnitServiceProductSuccess, createOrderUnitServiceProductError, fetchSurchargesByOrderUnitServiceProductId,
    fetchSurchargesByOrderUnitServiceProductIdSuccess, fetchSurchargesByOrderUnitServiceProductIdError } = orderUnitServiceProductSlice.actions;

export const isLoadingUnitServiceProduct = (state: RootState): boolean => state.orderUnitServiceProductReducer.isLoadingUnitServiceProduct;
export const isDeletingUnitServiceProduct = (state: RootState): boolean => state.orderUnitServiceProductReducer.isDeleting;
export const isLoadingUnitServiceProductSurcharges = (state: RootState): boolean => state.orderUnitServiceProductReducer.isLoadingUnitServiceProductSurcharges;
export const getOrderUnitServiceProduct = (state: RootState): OrderUnitServiceProduct => state.orderUnitServiceProductReducer.orderUnitServiceProduct;
export const isUpdatingOrderUnitServiceProduct = (state: RootState): boolean => state.orderUnitServiceProductReducer.isUpdating;
export const isCreatingUnitServiceProduct = (state: RootState): boolean => state.orderUnitServiceProductReducer.isCreatingUnitServiceProduct;
export const getSurcharges = (state: RootState): Pagination<OrderUnitServiceProductSurchargeOut> => state.orderUnitServiceProductReducer.unitServiceProductSurcharges;

export default orderUnitServiceProductSlice.reducer;