import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useSelector } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import { Grid } from '@mui/material';
import Loader from '../../common/widgets/loader';
import GenericTextField from '../../common/widgets/genericTextField';
import ResponsibilityCenter from '../../../interfaces/output/responsibilityCenter';
import { isCreatingResponsibilityCenter, isLoadingResponsibilityCenter } from '../../../reducers/responsibilityCenterReducer';
import ResponsibilityCenterStep1Validation from './validation/responsibilityCenterStep1Validation';
// import { useIntl } from 'react-intl';

interface IResponsibilityCenterStep1Props {
    responsibilityCenter: ResponsibilityCenter;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    NAME: 'name',
    CODE: 'code'
};

const messages = {
    name: LanguageUtils.createMessage('Name'),
    code: LanguageUtils.createMessage('Code')
};

const ResponsibilityCenterFormStep1 = forwardRef((props: IResponsibilityCenterStep1Props, ref: any): JSX.Element => {
    const { responsibilityCenter, onChange, showValidationError } = props;
    const isLoadingObjects = [useSelector(isCreatingResponsibilityCenter), useSelector(isLoadingResponsibilityCenter)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);

    WorkflowUtils.setHandle(ref, null);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.NAME: {
                isValid = ResponsibilityCenterStep1Validation.validateInputString(responsibilityCenter.name);
                break;
            }
            case ATTRIBUTES.CODE: {
                isValid = ResponsibilityCenterStep1Validation.validateInputString(responsibilityCenter.code);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="name"
                        label={messages.name}
                        value={responsibilityCenter.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="code"
                        label={messages.code}
                        value={responsibilityCenter.code}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.CODE)}
                        required
                    />
                </Grid>
            </Grid>
        </Loader >
    );
});

export default ResponsibilityCenterFormStep1;