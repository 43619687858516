/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Alert, Grid, FormControl } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import GenericTextField from '../../common/widgets/genericTextField';
//import { ensure } from '../../utils/arrayUtils';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isSending, isLoadingInvoice } from '../../../reducers/invoiceReducer';
import LanguageUtils from '../../../utils/LanguageUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Terminal from '../../../interfaces/output/terminal';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import { fetchServiceTypes } from '../../../reducers/serviceTypeReducer';
import PageUtils from '../../../utils/pageUtils';
import ServiceOverviewFilters from '../../../interfaces/filters/serviceOverviewPdfFilter';
import { fetchLanguages, getLanguages } from '../../../reducers/languageReducer';
import Language from '../../../interfaces/output/language';
import { fetchServiceArenaTypes, getServiceArenaTypes } from '../../../reducers/serviceArenaTypeReducer';
import { fetchServicesArena, getServicesArena } from '../../../reducers/serviceArenaReducer';
import ServiceArenaType from '../../../interfaces/output/serviceArenaType';
import ServiceArena from '../../../interfaces/output/serviceArena';
import ServiceArenaFilters from '../../../interfaces/filters/serviceArenaFilters';

const messages = {
    cancel: LanguageUtils.createMessage('Cancel'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    serviceType: LanguageUtils.createMessage('Service arena type'),
    title: LanguageUtils.createMessage('Service overview filters'),
    generatePdf: LanguageUtils.createMessage('Generate Pdf'),
    wrongFormat: LanguageUtils.createMessage('Wrong format'),
    language: LanguageUtils.createMessage('Language'),
    serviceDescription: LanguageUtils.createMessage('Service description'),
    serviceArenaName: LanguageUtils.createMessage('Service arena name')
};

interface ExportInvoiceProps {
    open: boolean;
    setOpenModal: (open: boolean) => void;
    filters: ServiceOverviewFilters,
    onChange: any,
    generatePdf: any
}

export default function ServiceOverviewPdfFilter(props: ExportInvoiceProps): JSX.Element {
    const { setOpenModal, open, filters, onChange, generatePdf } = props;
    const [showValidationError, setShowValidationError] = React.useState<boolean | undefined>(false);
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const propsIsSending = useSelector(isSending);
    const propsIsLoadingInvoice = useSelector(isLoadingInvoice);
    const isLoading = propsIsSending || propsIsLoadingInvoice;
    const prevIsSending = WorkflowUtils.usePrevious<boolean>(propsIsSending);
    const terminals = useSelector(getTerminals).content;
    const languages = useSelector(getLanguages).content;
    const servicesArena = useSelector(getServicesArena).content;
    const serviceArenaTypes = useSelector(getServiceArenaTypes).content;

    useEffect(() => {
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchLanguages({ paging }));
        dispatch(fetchServicesArena({
            paging,
            filters: {} as ServiceArenaFilters
        }));
        dispatch(fetchServiceArenaTypes({ paging }));
    }, [dispatch]);

    const exportPdf = () => {
        generatePdf();
        setOpenModal(false);
    };

    const onLocalChange = (attribute: string, value: any, attributeId: string, valueId: any) => {
        if(attributeId === 'serviceArenaTypeId') {
            if(value !== undefined) {
                dispatch(fetchServicesArena({
                    paging,
                    filters: {
                        serviceArenaTypeId: valueId
                    } as ServiceArenaFilters
                }));
            }
            else {
                dispatch(fetchServicesArena({
                    paging,
                    filters: {
                    } as ServiceArenaFilters
                }));
            }
        }
        onChange(attribute, value);
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={close}>
            <Loader isLoading={isLoading}>
                <>
                    <DialogTitle><FormattedMessage {...messages.title} /></DialogTitle>
                    <DialogContent>
                        <Grid container p={2} direction="column">
                            <Grid item>
                                <Grid container spacing={2} alignItems="flex-end">
                                    <Grid item xs>
                                        <FormControl fullWidth>
                                            <GenericTextField<string>
                                                name="serviceDescription"
                                                label={messages.serviceDescription}
                                                onChange={onChange}
                                                value={filters.serviceDescription}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs>
                                        <GenericAutocomplete<Language>
                                            options={languages}
                                            value={filters.languageId}
                                            onChange={(obj: Language | null) => onChange('languageId', obj?.id)}
                                            placeholder={messages.language}
                                            compareFn={(o: Language) => o.id === filters.languageId}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <GenericAutocomplete<ServiceArenaType>
                                            options={serviceArenaTypes}
                                            value={filters.serviceArenaTypeName}
                                            onChange={(obj: ServiceArenaType | null) =>
                                                onLocalChange('serviceArenaTypeName', filters?.languageId === 2 ? obj?.name : obj?.nameNo, 'serviceArenaTypeId', obj?.id)}
                                            placeholder={messages.serviceType}
                                            compareFn={(o: ServiceArenaType) => (filters?.languageId === 2 ? o.name : o.nameNo) === filters.serviceArenaTypeName}
                                            getOptionLabel={(option: ServiceArenaType) => filters?.languageId === 2 ? option?.name : option?.nameNo}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <GenericAutocomplete<ServiceArena>
                                            options={servicesArena}
                                            value={filters.serviceArenaName}
                                            onChange={(obj: ServiceArena | null) => onChange('serviceArenaName', filters?.languageId === 2 ? obj?.name : obj?.nameNo)}
                                            placeholder={messages.serviceArenaName}
                                            compareFn={(o: ServiceArena) => (filters?.languageId === 2 ? o.name : o.nameNo) === filters.serviceArenaName}
                                            getOptionLabel={(option: ServiceArenaType) => filters?.languageId === 2 ? option?.name : option?.nameNo}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <GenericAutocomplete<Terminal>
                                            options={terminals}
                                            value={filters.fromServiceTerminal}
                                            onChange={(obj: Terminal | null) => onChange('fromServiceTerminal', obj?.name)}
                                            placeholder={messages.fromTerminal}
                                            compareFn={(o: Terminal) => o.name === filters.fromServiceTerminal}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <GenericAutocomplete<Terminal>
                                            options={terminals}
                                            value={filters.toServiceTerminal}
                                            onChange={(obj: Terminal | null) => onChange('toServiceTerminal', obj?.name)}
                                            placeholder={messages.toTerminal}
                                            compareFn={(o: Terminal) => o.name === filters.toServiceTerminal}
                                        />
                                    </Grid>
                                </Grid>
                                {showValidationError &&
                                    <Alert severity="error">
                                        <FormattedMessage {...messages.wrongFormat} />
                                    </Alert>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenModal(false); setShowValidationError(false); }}><FormattedMessage {...messages.cancel} /></Button>
                        <Button onClick={exportPdf }><FormattedMessage {...messages.generatePdf} /></Button>
                    </DialogActions>
                </>
            </Loader >

        </Dialog >
    );
}
