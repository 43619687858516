import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import Order from '../../interfaces/output/order';
import WorkflowUtils from '../../utils/workflowUtils';
import { createOrder, fetchOrderById, getOrder, isCreatingOrder, isLoadingOrder, isUpdatingOrder, updateOrder, fetchOrders } from '../../reducers/orderReducer';
import OrderUnit from '../../interfaces/output/orderUnit';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import { OrderStatusId } from '../../constants/statusConstants';
import AddOrderStep1 from './orderStep1';
import AddOrderStep2 from './orderStep2';
import { fetchOrderUnitById, getOrderUnit, isUpdatingOrderUnit, updateOrderUnit, isCreatingUnits, createUnit } from '../../reducers/orderUnitReducer';
import { FormType } from '../../constants/constants';
import Customer from '../../interfaces/output/customer';
import AddOrderUnitStep1 from './orderUnitStep1';
import OrderUnitStep1Validation from '../orderUnits/validations/orderUnitStep1Validation';
import OrderStepValidation from '../orders/validations/orderStepValidation';
//import Pagination from '../../interfaces/common/pagination';
import PageUtils from '../../utils/pageUtils';
import OrderFilters from '../../interfaces/filters/orderFilters';

interface IAddOrderFormProps {
    orderId?: number;
    orderUnitId?: number;
    orderUnitServiceId?: number;
    customerId?: number;
    orderUnitServiceProductId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit order'),
    add: LanguageUtils.createMessage('Add order'),
    editUnit: LanguageUtils.createMessage('Edit order unit'),
    addUnit: LanguageUtils.createMessage('Add order unit'),
    changeStatus: LanguageUtils.createMessage('Change service status')
};

export default function AddOrderForm(props: IAddOrderFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { orderId, orderUnitId, customerId, steps, type } = props;
    const defaultCustomer = customerId ? { id: customerId } as Customer : null;
    const [order, setOrder] = React.useState<Order>({ customer: defaultCustomer as Customer } as Order);
    const [orderUnit, setOrderUnit] = React.useState<OrderUnit>({ orderId: orderId } as OrderUnit);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [isRepeating, setIsRepeating] = React.useState<boolean>(false);
    const propsOrder = useSelector(getOrder);
    const propsOrderUnit = useSelector(getOrderUnit);
    const propsIsCreatingUnit = useSelector(isCreatingUnits);
    const propsIsUpdatingOrderUnit = useSelector(isUpdatingOrderUnit);
    const propsIsCreatingOrder = useSelector(isCreatingOrder);
    const propsIsUpdatingOrder = useSelector(isUpdatingOrder);
    const propsIsLoadingOrder = useSelector(isLoadingOrder);
    const prevIsCreatingUnit = WorkflowUtils.usePrevious<boolean>(propsIsCreatingUnit);
    const prevIsUpdatingOrderUnit = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrderUnit);
    const prevIsCreatingOrder = WorkflowUtils.usePrevious<boolean>(propsIsCreatingOrder);
    const prevIsUpdatingOrder = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrder);
    const prevIsLoadingOrder = WorkflowUtils.usePrevious<boolean>(propsIsLoadingOrder);
    //const propsIsLoadingOrders = useSelector(isLoadingOrders);
    // const prevIsLoadingOrders = WorkflowUtils.usePrevious<boolean>(propsIsLoadingOrders);
    // const propsOrders = useSelector(getOrders);
    // const [orders, setOrders] = React.useState<Pagination<Order>>();

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        if(!isRepeating) {
            switch (lastStep) {
                case 1:
                    if((prevIsUpdatingOrder === true && !propsIsUpdatingOrder) || (prevIsCreatingOrder === true && !propsIsCreatingOrder)) {
                        setRedirectCondition(true);
                    }
                    break;

                case 2:
                    if((prevIsCreatingUnit === true && !propsIsCreatingUnit) || (prevIsUpdatingOrderUnit === true && !propsIsUpdatingOrderUnit)) {
                        setRedirectCondition(true);
                    }
                    break;

                case 3:
                    if(prevIsUpdatingOrderUnit === true && !propsIsUpdatingOrderUnit) {
                        setRedirectCondition(true);
                    }
                    break;

                default:
            }
        }

        return () => setRedirectCondition(false);
    }, [propsIsCreatingUnit, propsIsUpdatingOrder, propsIsCreatingOrder, propsIsUpdatingOrderUnit]);

    useEffect(() => {
        if(orderId) {
            dispatch(fetchOrderById(orderId));
        }
        if(orderUnitId) {
            dispatch(fetchOrderUnitById(orderUnitId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsUpdatingOrder && !propsIsLoadingOrder || prevIsLoadingOrder && !propsIsLoadingOrder || prevIsCreatingOrder && !propsIsCreatingOrder) {
            setOrder(propsOrder);
        }

    }, [propsIsUpdatingOrder, propsIsLoadingOrder, propsIsCreatingOrder]);

    useEffect(() => {
        if(propsOrderUnit.id) {
            setOrderUnit(propsOrderUnit);
        }

    }, [propsOrderUnit.id]);

    // useEffect(() => {
    //     if(prevIsLoadingOrders === true && !propsIsLoadingOrders) {
    //         setOrders(propsOrders);
    //     }
    // }, [propsIsLoadingOrders]);

    const onChange = (attribute: string, value: any) => {
        const newOrder = { ...order } as Order;
        (newOrder as any)[attribute] = value;
        setOrder(newOrder);
    };

    const onChangeUnit = (attribute: string, value: any) => {

        const newOrderUnit = { ...orderUnit } as OrderUnit;
        (newOrderUnit as any)[attribute] = value;

        setOrderUnit(newOrderUnit);
    };

    const fetchOrdersDelay = () => {
        if(order?.orderNumber !== null || order?.orderNumber !== undefined || order?.orderNumber !== '') {
            const newFilters = { exactMatchOrderNumber: order?.orderNumber } as OrderFilters;
            const newPaging = PageUtils.getDefaultPaging();

            dispatch(fetchOrders({
                paging: newPaging,
                filters: newFilters
            }));
        }
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => fetchOrdersDelay(), 300);

        return () => clearTimeout(timeOutId);
    }, [order?.orderNumber]);

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <AddOrderStep1 onChange={onChange} order={order} defaultCustomer={!!customerId} />,
            validationFn: () => OrderStepValidation.validateOrderForm(order),
            onNext: () => {
                if(!order.id) {
                    dispatch(createOrder(order));
                }
                else {
                    dispatch(updateOrder(order));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddOrderStep2 orderUnit={orderUnit} onChange={onChangeUnit} />,
            validationFn: () => OrderUnitStep1Validation.validateOrderUnitForm(orderUnit),
            onRepeat: () => {
                setIsRepeating(true);
                setOrderUnit({ orderId: orderId } as OrderUnit);
            },
            isRepeatable: true,
            onNext: () => {
                setIsRepeating(false);
                if(!orderUnitId) {
                    dispatch(createUnit({
                        ...orderUnit,
                        orderId: propsOrder.id
                    }));

                    // update order only if the first step is not included in the form => used for order unit creation directly from units/properties where we don't need order update.
                    if(steps.includes(1)) {
                        dispatch(updateOrder({
                            ...order,
                            status: { id: propsOrder.status?.id ?? OrderStatusId.Ready }
                        }));
                    }
                }
                else {
                    dispatch(updateOrderUnit(orderUnit));
                }
            }
        } as GenericStep,
        {
            id: 3,
            name: 'Change unit status',
            content: <AddOrderUnitStep1 onChange={onChangeUnit} orderUnit={orderUnit} />,
            onNext: () => {
                dispatch(updateOrderUnit(orderUnit));
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddUnit:
                return messages.addUnit;
            case FormType.EditUnit:
                return messages.editUnit;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): any => {
        switch (type) {
            case FormType.Add:
                if(customerId) {
                    return `/${UrlConstants.CUSTOMERS}/${customerId}/orders`;
                }

                return `/${UrlConstants.ORDERS}/${order.id}/properties`;
            case FormType.Edit:
                return `/${UrlConstants.ORDERS}/${orderId}`;
            case FormType.AddUnit:
                return `/${UrlConstants.ORDERS}/${orderId}/units`;
            case FormType.EditUnit:
                return `/${UrlConstants.ORDER_UNITS}/${orderUnitId}`;
            case FormType.ChangeStatus:
                return `/${UrlConstants.ORDER_UNITS}/${orderUnitId}`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
