import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import { LoadedFlagStatus } from '../../constants/statusConstants';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomLink from '../common/customLink';
import CustomStringLink from '../common/customStringLink';

interface IAgreementVersionSpecialPriceListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to'),
    agreementVersion: LanguageUtils.createMessage('Agreement version'),
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to'),
    price: LanguageUtils.createMessage('Price'),
    departureType: LanguageUtils.createMessage('Departure type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    lastUpdate: LanguageUtils.createMessage('Last update'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    currency: LanguageUtils.createMessage('Currency'),
    grossWeightKgFrom: LanguageUtils.createMessage('Gross weight kg from'),
    grossWeightKgTo: LanguageUtils.createMessage('Gross weight kg to'),
    loadedFlag: LanguageUtils.createMessage('Loaded flag'),
    loaded: LanguageUtils.createMessage('Loaded'),
    empty: LanguageUtils.createMessage('Empty')
};

const AgreementVersionSpecialPriceList = forwardRef((props: IAgreementVersionSpecialPriceListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'validFromDate',
                headerName: intl.formatMessage(messages.validFromDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(cellValues).id}`} >
                        <NullableFormattedDate value={cellValues.row?.validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: 'validToDate',
                headerName: intl.formatMessage(messages.validToDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} >
                        <NullableFormattedDate value={params.row?.validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            },
            {
                field: 'terminalFrom',
                headerName: intl.formatMessage(messages.terminalFrom),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} value={applyRowFn(params)?.terminalFrom?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('terminalFrom', hiddenColumns)
            },
            {
                field: 'terminalTo',
                headerName: intl.formatMessage(messages.terminalTo),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} value={applyRowFn(params)?.terminalTo?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('terminalTo', hiddenColumns)
            },
            {
                field: 'unitType',
                headerName: intl.formatMessage(messages.unitType),
                flex: 1.2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`}
                        value={`${applyRowFn(params).unitType?.name ?? ''} - ${applyRowFn(params).unitType?.code ?? ''}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitType', hiddenColumns)
            },
            {
                field: 'kgFrom',
                headerName: intl.formatMessage(messages.grossWeightKgFrom),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} value={applyRowFn(params)?.grossWeightKgFrom ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('grossWeightKgFrom', hiddenColumns)
            },
            {
                field: 'kgTo',
                headerName: intl.formatMessage(messages.grossWeightKgTo),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} value={applyRowFn(params)?.grossWeightKgTo ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('grossWeightKgTo', hiddenColumns)
            },
            {
                field: 'loadedFlag',
                headerName: intl.formatMessage(messages.loadedFlag),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} >
                        {applyRowFn(params)?.loadedFlag?.code === LoadedFlagStatus.Loaded ?
                            <FormattedMessage {...messages.loaded} /> :
                            applyRowFn(params)?.loadedFlag?.code === LoadedFlagStatus.Empty ?
                                <FormattedMessage {...messages.empty} /> : '-'}
                    </CustomLink >,
                hide: hiddenColumns && TableUtils.hideColumn('loadedFlag', hiddenColumns)
            },
            {
                field: 'departureType',
                headerName: intl.formatMessage(messages.departureType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`}
                        value={`${applyRowFn(params).departureType?.name ?? ''} - ${applyRowFn(params).departureType?.code ?? ''}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('departureType', hiddenColumns)
            },
            {
                field: 'priorityType',
                headerName: intl.formatMessage(messages.priorityType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`}
                        value={`${applyRowFn(params).priorityType?.name ?? ''} - ${applyRowFn(params).priorityType?.code ?? ''}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('priorityType', hiddenColumns)
            },
            {
                field: 'price',
                headerName: intl.formatMessage(messages.price),
                flex: 0.5,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`}
                        value={applyRowFn(params)?.price &&
                            intl.formatNumber(ensure(Math.round(applyRowFn(params)?.price)), {
                                maximumFractionDigits: 0
                            }) || '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('price', hiddenColumns)
            },
            {
                field: 'currency',
                headerName: intl.formatMessage(messages.currency),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${applyRowFn(params).id}`} value={applyRowFn(params)?.agreement?.currency?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('currency', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
});
export default AgreementVersionSpecialPriceList;