import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { FormType } from '../../constants/constants';
import Customer from '../../interfaces/output/customer';
import { createCustomer, fetchCustomerById, getCustomer, isCreatingCustomer, isUpdatingCustomer, updateCustomer } from '../../reducers/customerReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import AddCustomerStep1 from './addCustomerStep1';
import AddCustomerStep2 from './addCustomerStep2';
import AddCustomerStep3 from './addCustomerStep3';
import CustomerStep1Validation from './validation/customerStep1Validation';
import CustomerStep2Validation from './validation/customerStep2Validation';
import RoleConstants from '../../constants/roleConstants';
import { getUserDetails } from '../../reducers/userReducer';
import { hasRoles } from '../../utils/roleUtils';

interface IAddCustomerProps {
    customerId?: number;
    steps: Array<number>;
    type: FormType;
    componentRoles: Array<string>;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit customer'),
    add: LanguageUtils.createMessage('Add customer')
};

export default function AddCustomerForm(props: IAddCustomerProps): JSX.Element {
    const dispatch = useDispatch();
    const { customerId, steps, type } = props;
    const [customer, setCustomer] = React.useState<Customer>({ visibleInGTS: false } as Customer);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsCustomer = useSelector(getCustomer);
    const prevCustomer = WorkflowUtils.usePrevious<Customer>(propsCustomer);
    const propsUser = useSelector(getUserDetails);
    const propsIsUpdatingCustomer = useSelector(isUpdatingCustomer);
    const prevIsUpdatingCustomer = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCustomer);
    const propsIsCreatingCustomer = useSelector(isCreatingCustomer);
    const prevIsCreatingCustomer = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCustomer);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
            case 3:
                if(prevIsCreatingCustomer === true && !propsIsCreatingCustomer || prevIsUpdatingCustomer === true && !propsIsUpdatingCustomer) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingCustomer, propsIsCreatingCustomer]);

    useEffect(() => {
        if(customerId) {
            dispatch(fetchCustomerById(customerId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevCustomer && propsCustomer.id) {
            setCustomer(propsCustomer);
        }

    }, [propsCustomer.id]);

    const onChange = (attribute: string, value: any) => {
        const newCustomer = { ...customer } as Customer;
        (newCustomer as any)[attribute] = value;

        setCustomer(newCustomer);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            name: 'Enter customer details',
            validationFn: () => CustomerStep1Validation.validateCustomerForm(customer),
            content: <AddCustomerStep1 onChange={onChange} customer={customer} />,
            onNext: () => {
                if(!hasRoles([RoleConstants.Roles.ROLE_FINANCIAL], propsUser.roles)) {
                    if(!customer.id) {
                        dispatch(createCustomer(customer));
                    }
                    else {
                        dispatch(updateCustomer(customer));
                    }
                }
            }
        } as GenericStep,
        {
            id: 2,
            name: 'Enter finance details',
            validationFn: () => CustomerStep2Validation.validateCustomerForm(customer),
            content: <AddCustomerStep2 onChange={onChange} customer={customer} />,
            excludeIf: !hasRoles([RoleConstants.Roles.ROLE_FINANCIAL, RoleConstants.Roles.ROLE_ADMIN], propsUser.roles)
        } as GenericStep,
        {
            id: 3,
            name: 'Enter ids',
            content: <AddCustomerStep3 onChange={onChange} customer={customer} />,
            excludeIf: !hasRoles([RoleConstants.Roles.ROLE_FINANCIAL, RoleConstants.Roles.ROLE_ADMIN], propsUser.roles),
            onNext: () => {
                if(!customer.id) {
                    dispatch(createCustomer(customer));
                }
                else {
                    dispatch(updateCustomer(customer));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.CUSTOMERS}/${customer.id}`}
        />
    );
}
