import React from 'react';
import { Portal } from '@mui/base';
import { createStyles, makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';

interface IFilterComponent {
    renderFilter: (container: React.MutableRefObject<null> | null) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            background: 'transparent',
            border: 'none'
        },
        root: {
            display: 'flex'
        }
    })
);

export default function FilterComponent(props: IFilterComponent): JSX.Element {
    const classes = useStyles();
    const [show, setShow] = React.useState(true);
    const container = React.useRef(null);

    const handleClick = () => {
        setShow(!show);
        const ref = !show ? container : null;
        props.renderFilter(ref);
    };

    return (
        <div className={classes.root}>
            <button type="button" className={classes.button} onClick={handleClick}>
                <FilterListIcon />
            </button>
            <div>
                {show ? (
                    <Portal container={container.current} />
                ) : null}
            </div>
        </div>
    );
}