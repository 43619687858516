import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import AgreementVersionSpecialPriceOut from '../../../interfaces/output/agreementVersionSpecialPriceOut';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import DepartureType from '../../../interfaces/output/departureType';
import UnitType from '../../../interfaces/output/unitType';
import PriorityType from '../../../interfaces/output/priorityType';
import { fetchFactorSetDepartureTypes, getFactorSetDepartureTypes } from '../../../reducers/factorSetDepartureReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../reducers/unitSubgroupReducer';
import PageUtils from '../../../utils/pageUtils';
import { isCreatingAgreementVersionSpecialPrice, isLoadingAgreementVersionSpecialPrice, isUpdatingAgreementSpecialPrice } from '../../../reducers/agreementVersionSpecialPriceReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../reducers/unitGroupReducer';
import { ensure } from '../../../utils/arrayUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Terminal from '../../../interfaces/output/terminal';
import UnitSubGroup from '../../../interfaces/output/unitSubGroup';
import UnitGroup from '../../../interfaces/output/unitGroup';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../reducers/unitTypeReducer';
import { fetchPriorityTypes, getPriorityTypes } from '../../../reducers/priorityTypeReducer';
import LoadedFlag from '../../../interfaces/output/loadedFlag';
import { fetchLoadedFlags, getLoadedFlags } from '../../../reducers/loadedFlagReducer';
import AgreementVersionSpecialPriceStep1Validation from '../validations/AgreementVersionSpecialPriceStep1Validation';

interface AddAgreementVersionSpecialPriceFormStep1Props {
    agreementVersionSpecialPrice: AgreementVersionSpecialPriceOut;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    VALID_FROM: 'validFromDate',
    VALID_TO: 'validToDate',
    GROSS_WEIGHT_FROM: 'grossWeightKgFrom',
    GROSS_WEIGHT_TO: 'grossWeightKgFrom',
    PRICE: 'price',
    TERMINAL_FROM: 'terminaFrom',
    TERMINAL_TO: 'terminalTo',
    UNIT_TYPE: 'unitType',
    UNIT_GROUP: 'unitGroup',
    UNIT_SUBGROUP: 'unitSubGroup'
};

const messages = {
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    grossWeightKgFrom: LanguageUtils.createMessage('Gross weight kg from'),
    grossWeightKgTo: LanguageUtils.createMessage('Gross weight kg to'),
    loadedFlag: LanguageUtils.createMessage('Loaded flag'),
    price: LanguageUtils.createMessage('Price'),
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to'),
    departureType: LanguageUtils.createMessage('Departure type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    unitgroup: LanguageUtils.createMessage('Unit group'),
    subgroup: LanguageUtils.createMessage('Subgroup')
};

const AgreementVersionSpecialPriceFormStep1 = forwardRef((props: AddAgreementVersionSpecialPriceFormStep1Props, ref: any): JSX.Element => {
    const { agreementVersionSpecialPrice, onChange, showValidationError } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const terminals = useSelector(getTerminals).content;
    const flags = useSelector(getLoadedFlags).content;
    const departureTypes = useSelector(getFactorSetDepartureTypes).content;
    const priorityTypes = useSelector(getPriorityTypes).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const isLoadingObjects = [useSelector(isUpdatingAgreementSpecialPrice), useSelector(isLoadingAgreementVersionSpecialPrice), useSelector(isCreatingAgreementVersionSpecialPrice)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
    const prevAgreementVersionSpecialPrice = WorkflowUtils.usePrevious<AgreementVersionSpecialPriceOut>(agreementVersionSpecialPrice);

    useEffect(() => {
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchLoadedFlags({
            paging,
            includeDeleted: true
        }));
        dispatch(fetchFactorSetDepartureTypes({ paging }));
        dispatch(fetchPriorityTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(agreementVersionSpecialPrice?.id) {
            dispatch(fetchUnitGroupSubgroups({
                groupId: ensure(agreementVersionSpecialPrice.unitType?.unitGroupId),
                paging
            }));
            if(agreementVersionSpecialPrice.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({
                    id: ensure(agreementVersionSpecialPrice.unitType?.unitSubGroupId),
                    paging
                }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevAgreementVersionSpecialPrice) {
            if(agreementVersionSpecialPrice?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({
                    groupId: ensure(agreementVersionSpecialPrice?.unitType?.unitGroupId),
                    paging
                }));
                // onChange('unitType', {
                //     ...agreementVersionSpecialPrice.unitType,
                //     unitSubGroupId: null,
                //     id: null
                // });
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [agreementVersionSpecialPrice?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevAgreementVersionSpecialPrice) {
            if(agreementVersionSpecialPrice?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({
                    id: ensure(agreementVersionSpecialPrice?.unitType?.unitSubGroupId),
                    paging
                }));
                // onChange('unitType', {
                //     ...agreementVersionSpecialPrice.unitType,
                //     id: null
                // });
                dispatch(resetUnitTypes());
            }
        }
    }, [agreementVersionSpecialPrice?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!agreementVersionSpecialPrice?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
           
            case ATTRIBUTES.VALID_FROM: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateRequiredDate(agreementVersionSpecialPrice.validFromDate) &&
                    AgreementVersionSpecialPriceStep1Validation.validateDateFormat(agreementVersionSpecialPrice.validFromDate) &&
                    AgreementVersionSpecialPriceStep1Validation.validateInputDateStart(agreementVersionSpecialPrice);
                break;
            }
            case ATTRIBUTES.VALID_TO: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateRequiredDate(agreementVersionSpecialPrice.validFromDate) &&
                    AgreementVersionSpecialPriceStep1Validation.validateDateFormat(agreementVersionSpecialPrice.validFromDate) &&
                    AgreementVersionSpecialPriceStep1Validation.validateInputDateEnd(agreementVersionSpecialPrice);
                break;
            }
            case ATTRIBUTES.GROSS_WEIGHT_FROM: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice?.grossWeightKgFrom);
                break;
            }
            case ATTRIBUTES.GROSS_WEIGHT_TO: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice?.grossWeightKgTo);
                break;
            }
            case ATTRIBUTES.PRICE: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice?.price);
                break;
            }
            case ATTRIBUTES.TERMINAL_FROM: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice?.terminalFrom?.id);
                break;
            }
            case ATTRIBUTES.TERMINAL_TO: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice?.terminalTo?.id);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice.unitType?.id);
                break;
            }
            case ATTRIBUTES.UNIT_GROUP: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice.unitType?.unitGroupId);
                break;
            }
            case ATTRIBUTES.UNIT_SUBGROUP: {
                isValid = AgreementVersionSpecialPriceStep1Validation.validateInputNumber(agreementVersionSpecialPrice.unitType?.unitSubGroupId);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFromDate}
                            value={agreementVersionSpecialPrice?.validFromDate}
                            onChange={onChange}
                            type="start"
                            error={setValidationState(ATTRIBUTES.VALID_FROM)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validToDate}
                            value={agreementVersionSpecialPrice?.validToDate}
                            onChange={onChange}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALID_TO)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="grossWeightKgFrom"
                            label={messages.grossWeightKgFrom}
                            value={agreementVersionSpecialPrice?.grossWeightKgFrom}
                            onChange={onChange}
                            type="number"
                            error={setValidationState(ATTRIBUTES.GROSS_WEIGHT_FROM)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="grossWeightKgTo"
                            label={messages.grossWeightKgTo}
                            value={agreementVersionSpecialPrice?.grossWeightKgTo}
                            onChange={onChange}
                            type="number"
                            error={setValidationState(ATTRIBUTES.GROSS_WEIGHT_TO)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<LoadedFlag>
                            options={flags}
                            value={agreementVersionSpecialPrice.loadedFlag}
                            onChange={(obj: LoadedFlag | null) => onChange('loadedFlag', obj)}
                            placeholder={messages.loadedFlag}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="price"
                            label={messages.price}
                            value={agreementVersionSpecialPrice?.price}
                            onChange={onChange}
                            type="number"
                            error={setValidationState(ATTRIBUTES.PRICE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<Terminal>
                        options={terminals}
                        value={agreementVersionSpecialPrice.terminalFrom}
                        onChange={(obj: Terminal | null) => onChange('terminalFrom', obj)}
                        placeholder={messages.terminalFrom}
                        error={setValidationState(ATTRIBUTES.TERMINAL_FROM)}
                        required
                    />
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={agreementVersionSpecialPrice.terminalTo}
                            onChange={(obj: Terminal | null) => onChange('terminalTo', obj)}
                            placeholder={messages.terminalTo}
                            error={setValidationState(ATTRIBUTES.TERMINAL_TO)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<DepartureType>
                            options={departureTypes}
                            value={agreementVersionSpecialPrice.departureType}
                            onChange={(obj: DepartureType | null) => onChange('departureType', obj)}
                            placeholder={messages.departureType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PriorityType>
                            options={priorityTypes}
                            value={agreementVersionSpecialPrice.priorityType}
                            onChange={(obj: PriorityType | null) => onChange('priorityType', obj)}
                            placeholder={messages.priorityType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={agreementVersionSpecialPrice.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === agreementVersionSpecialPrice.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', {
                                ...agreementVersionSpecialPrice.unitType,
                                unitGroupId: obj?.id,
                                unitSubGroupId: null,
                                id: null }, 'unitGroupId')}
                            placeholder={messages.unitgroup}
                            error={setValidationState(ATTRIBUTES.UNIT_GROUP)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={agreementVersionSpecialPrice.unitType?.unitSubGroupId}
                            compareFn={(o: UnitSubGroup) => o.id === agreementVersionSpecialPrice.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', {
                                ...agreementVersionSpecialPrice.unitType,
                                unitSubGroupId: obj?.id,
                                id: null }, 'unitSubGroupId')}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            error={setValidationState(ATTRIBUTES.UNIT_SUBGROUP)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={agreementVersionSpecialPrice.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={agreementVersionSpecialPrice.unitType}
                            compareFn={(o: UnitType) => o.id === agreementVersionSpecialPrice.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', obj)}
                            placeholder={messages.unitType}
                            isLoading={agreementVersionSpecialPrice.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AgreementVersionSpecialPriceFormStep1;