import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetDangerousGoodVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetDangerousGoodVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerDangerousGoodVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchDangerousGoodVersionById: (state: FactorSetDangerousGoodVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchDangerousGoodVersionByIdSuccess: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchDangerousGoodVersionByIdError: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchDangerousGoodVersions: (state: FactorSetDangerousGoodVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchDangerousGoodVersionsSuccess: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchDangerousGoodVersionsError: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updateDangerousGoodVersionStatus: (state: FactorSetDangerousGoodVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updateDangerousGoodVersionStatusSuccess: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updateDangerousGoodVersionStatusError: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updateDangerousGoodVersion: (state: FactorSetDangerousGoodVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updateDangerousGoodVersionSuccess: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updateDangerousGoodVersionError: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneDangerousGoodVersion: (state: FactorSetDangerousGoodVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        cloneDangerousGoodVersionSuccess: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        cloneDangerousGoodVersionError: (state: FactorSetDangerousGoodVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetDangerousGoodVersion: (state: FactorSetDangerousGoodVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetDangerousGoodVersions: (state: FactorSetDangerousGoodVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchDangerousGoodVersionById, fetchDangerousGoodVersionByIdSuccess, fetchDangerousGoodVersionByIdError, updateDangerousGoodVersionStatus, updateDangerousGoodVersionStatusSuccess,
    updateDangerousGoodVersionStatusError, cloneDangerousGoodVersion, updateDangerousGoodVersion, cloneDangerousGoodVersionSuccess, fetchDangerousGoodVersions, fetchDangerousGoodVersionsSuccess,
    fetchDangerousGoodVersionsError, updateDangerousGoodVersionSuccess, updateDangerousGoodVersionError, resetDangerousGoodVersion, cloneDangerousGoodVersionError, resetDangerousGoodVersions }
    = customerDangerousGoodVersionSlice.actions;

export const isLoadingDangerousGoodVersion = (state: RootState): boolean => state.factorSetDangerousGoodVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetDangerousGoodVersionReducer.isCloningVersion;
export const isUpdatingDangerousGoodVersionStatus = (state: RootState): boolean => state.factorSetDangerousGoodVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetDangerousGoodVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetDangerousGoodVersionReducer.isUpdatingVersion;
export const getDangerousGoodVersion = (state: RootState): FactorSetVersionOut => state.factorSetDangerousGoodVersionReducer.factorSetVersion;
export const isLoadingFactorSetDangerousGoodVersions = (state: RootState): boolean => state.factorSetDangerousGoodVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetDangerousGoodVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetDangerousGoodVersionReducer.factorSetVersions;

export default customerDangerousGoodVersionSlice.reducer;