import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import D365VAT from '../interfaces/output/d365VAT';
import D365Account from '../interfaces/output/d365Account';
import D365Activity from '../interfaces/output/d365Activity';
import D365Object from '../interfaces/output/d365Object';
import D365ResponsibilityCenter from '../interfaces/output/d365ResponsibilityCenter';
import D365Spec from '../interfaces/output/d365Spec';
import D365InternalTrade from '../interfaces/output/d365InternalTrade';
import DynamicsApi from '../api/dynamicsApi';
import { fetchAccountsError, fetchAccountsSuccess, fetchActivitiesError, fetchActivitiesSuccess,
    fetchObjectsError, fetchObjectsSuccess, fetchResponsibilitiesError, fetchResponsibilitiesSuccess, fetchSpecsError, fetchSpecsSuccess,
    fetchTradesError,
    fetchTradesSuccess, fetchVatsError, fetchVatsSuccess } from '../reducers/dynamicsReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchVats(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365VAT> = yield call(DynamicsApi.fetchVats, paging);
        yield put(fetchVatsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchVatsError(error as HttpErrorResponse));
    }
}

function* doFetchAccounts(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365Account> = yield call(DynamicsApi.fetchAccounts, paging);
        yield put(fetchAccountsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchAccountsError(error as HttpErrorResponse));
    }
}

function* doFetchActivities(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365Activity> = yield call(DynamicsApi.fetchActivities, paging);
        yield put(fetchActivitiesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchActivitiesError(error as HttpErrorResponse));
    }
}

function* doFetchObjects(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365Object> = yield call(DynamicsApi.fetchObjects, paging);
        yield put(fetchObjectsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchObjectsError(error as HttpErrorResponse));
    }
}

function* doFetchSResponsibilities(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365ResponsibilityCenter> = yield call(DynamicsApi.fetchResponsibilities, paging);
        yield put(fetchResponsibilitiesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchResponsibilitiesError(error as HttpErrorResponse));
    }
}

function* doFetchSpecs(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365Spec> = yield call(DynamicsApi.fetchSpecs, paging);
        yield put(fetchSpecsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSpecsError(error as HttpErrorResponse));
    }
}

function* doFetchTrades(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<D365InternalTrade> = yield call(DynamicsApi.fetchTrades, paging);
        yield put(fetchTradesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchTradesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('dynamics/fetchAccounts', doFetchAccounts),
    takeLatest('dynamics/fetchActivities', doFetchActivities),
    takeLatest('dynamics/fetchResponsibilities', doFetchSResponsibilities),
    takeLatest('dynamics/fetchObjects', doFetchObjects),
    takeLatest('dynamics/fetchSpecs', doFetchSpecs),
    takeLatest('dynamics/fetchTrades', doFetchTrades),
    takeLatest('dynamics/fetchVats', doFetchVats)
];

