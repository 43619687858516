import React, { useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericStepper from '../../common/widgets/genericStepper';
import UrlConstants from '../../../constants/UrlConstants';
import GenericStep from '../../../interfaces/common/genericStep';
import { FormType } from '../../../constants/constants';
import { createSurcharge, fetchSurchargeById, getSurcharge, isCreatingSurcharge, isUpdatingSurcharge, updateSurcharge } from '../../../reducers/surchargeReducer';
import OrderUnitServiceProductSurchargeOut from '../../../interfaces/output/orderUnitServiceProductSurchargeOut';
import AddOrderUnitServiceProductSurchargeStep1 from './addOrderUnitServiceProductSurchargeStep1';
import AddOrderUnitServiceProductSurchargeStep1Validation from './validation/addOrderUnitServiceProductSurchargeStep1Validation';

interface IAddOrderUnitServiceProductSurchargeFormProps {
    orderUnitServiceProductSurchargeId?: number;
    orderUnitServiceProductId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    editUnitServiceProduct: LanguageUtils.createMessage('Edit order unit service product surcharge'),
    addUnitServiceProduct: LanguageUtils.createMessage('Add order unit service product surcharge')
};

export default function AddOrderUnitServiceProductSurchargeForm(props: IAddOrderUnitServiceProductSurchargeFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { orderUnitServiceProductSurchargeId, orderUnitServiceProductId, steps, type } = props;
    const propsOrderUnitServiceProductSurcharge = useSelector(getSurcharge);
    const [orderUnitServiceProductSurcharge, setOrderUnitServiceProduct] = React.useState<OrderUnitServiceProductSurchargeOut>({
        orderUnitServiceProduct: { id: orderUnitServiceProductId } } as OrderUnitServiceProductSurchargeOut);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsCreatingUnitServiceProductSurcharge = useSelector(isCreatingSurcharge);
    const prevIsCreatingUnitServiceProductSurcharge = WorkflowUtils.usePrevious<boolean>(propsIsCreatingUnitServiceProductSurcharge);
    const propsIsUpdatingOrderUnitServiceProductSurcharge = useSelector(isUpdatingSurcharge);
    const prevIsUpdatingOrderUnitServiceProductSurcharge = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrderUnitServiceProductSurcharge);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if(prevIsUpdatingOrderUnitServiceProductSurcharge === true && !propsIsUpdatingOrderUnitServiceProductSurcharge ||
                     prevIsCreatingUnitServiceProductSurcharge === true && !propsIsCreatingUnitServiceProductSurcharge) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingUnitServiceProductSurcharge, propsIsUpdatingOrderUnitServiceProductSurcharge]);
    
    useEffect(() => {
        if(orderUnitServiceProductSurchargeId) {
            dispatch(fetchSurchargeById(orderUnitServiceProductSurchargeId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsOrderUnitServiceProductSurcharge.id) {
            setOrderUnitServiceProduct(propsOrderUnitServiceProductSurcharge);
        }

    }, [propsIsUpdatingOrderUnitServiceProductSurcharge, propsOrderUnitServiceProductSurcharge.id]);

    const onChangeUnitServiceProductSurcharge = (attribute: string, value: any) => {

        const newOrderUnitServiceProductSurcharge = { ...orderUnitServiceProductSurcharge } as OrderUnitServiceProductSurchargeOut;
        (newOrderUnitServiceProductSurcharge as any)[attribute] = value;

        setOrderUnitServiceProduct(newOrderUnitServiceProductSurcharge);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: 'Insert order unit service product surcharge details',
            content: <AddOrderUnitServiceProductSurchargeStep1 orderUnitServiceProductSurcharge={orderUnitServiceProductSurcharge} onChange={onChangeUnitServiceProductSurcharge} />,
            validationFn: () => AddOrderUnitServiceProductSurchargeStep1Validation.validateOrderUnitServiceProductSurchargeStep1Form(orderUnitServiceProductSurcharge),
            onNext: () => {
                if(!orderUnitServiceProductSurchargeId) {
                    dispatch(createSurcharge({ ...orderUnitServiceProductSurcharge,
                        pricelistVersion: propsOrderUnitServiceProductSurcharge.orderUnitServiceProduct?.pricelist?.version }));
                }
                else {
                    dispatch(updateSurcharge(orderUnitServiceProductSurcharge));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddUnitServiceProductSurcharge:
                return messages.addUnitServiceProduct;
            case FormType.EditUnitServiceProductSurcharge:
                return messages.editUnitServiceProduct;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddUnitServiceProductSurcharge:
                return `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}/surcharges`;
            case FormType.EditUnitServiceProductSurcharge:
                return `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${orderUnitServiceProductSurchargeId}`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  