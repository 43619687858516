import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import GtsOrder from '../../interfaces/output/gtsOrder';
import Paging from '../../interfaces/common/paging';
import GtsOrderUnitFilters from '../../interfaces/filters/gtsOrderUnitFilters';
import Pagination from '../../interfaces/common/pagination';
import { deactivateGtsOrderUnitDepot, fetchGtsOrderUnitsDepot, getGtsOrderUnitsDepot, isDeletingGtsOrderUnitDepot, isLoadingGtsOrderUnitsDepot } from '../../reducers/gtsOrderUnitDepotLinesReducer';
import GTSOrderUnitDepot from '../../interfaces/output/gtsOrderUnitDepot';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import { fetchGtsOrderUnitById, getGtsOrderUnit, isLoadingGtsOrderUnit, isUpdatingGtsOrderUnit, resetGtsOrderUnit, updateGtsOrderUnit } from '../../reducers/gtsOrderUnitReducer';
import ViewGtsOrderUnit from './viewOrderUnit';
import { deactivateGtsOrderUnitDangerousGoods, fetchGtsOrderUnitDangerousGoods, getGtsOrderUnitDangerousGoods, isDeletingGtsOrderUnitDangerousGoods, isLoadingDangerousGoods }
    from '../../reducers/gtsDangerousGoodsReducer';
import GTSOrderUnitDangerousGoods from '../../interfaces/output/gtsOrderUnitDangerousGoods';
import { deactivateGtsOrderUnitServices, fetchGTSOrderUnitServices, getGtsOrderUnitServices, isDeletingGtsOrderUnitService, isLoadingUnitServices } from '../../reducers/gtsUnitServiceReducer';
import GTSOrderUnitService from '../../interfaces/output/gtsOrderUnitService';
import OrderUnitDangerousGoodsList from './dangerousGoodsList';
import OrderUnitDepotList from './orderUnitDepotList';
import OrderUnitServiceList from './unitServiceList';
import LanguageUtils from '../../utils/LanguageUtils';
import { ensure } from '../../utils/arrayUtils';
import { fetchGtsOrderById, getGtsOrder, isLoadingGtsOrder } from '../../reducers/gtsOrderReducer';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IGTSOrderUnitDetailsProps {
    classes: any;
    theme: Theme;
    fetchGtsOrderUnitById: any;
    fetchGtsOrderUnitsDepot: any;
    fetchGtsOrderUnitDangerousGoods: any;
    fetchGTSOrderUnitServices: any;
    updateGtsOrderUnit: any;
    deactivateGtsOrderUnitDangerousGoods: any;
    fetchGtsOrderById: any;
    deactivateGtsOrderUnitServices: any;
    deactivateGtsOrderUnitDepot: any;
    gtsOrderUnit: GTSOrderUnit;
    gtsOrder: GtsOrder;
    gtsOrderUnitsDepot: Pagination<GTSOrderUnitDepot>;
    gtsDangerousGoods: Pagination<GTSOrderUnitDangerousGoods>;
    gtsUnitServices: Pagination<GTSOrderUnitService>;
    match: any;
    history: any;
    location: any;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoading: boolean;
    isLoadingGtsOrderUnit: boolean;
    isLoadingGtsOrder: boolean;
    isLoadingGtsOrderUnitsDepot: boolean;
    isLoadingDangerousGoods: boolean;
    isDeletingGtsOrderUnitService: boolean;
    isDeletingGtsOrderUnitDangerousGoods: boolean;
    isDeletingGtsOrderUnitDepot: boolean;
    isLoadingUnitServices: boolean;
    resetGtsOrderUnit: () => void;
}

interface IGTSOrderUnitDetailsState {
    gtsOrderUnit: GTSOrderUnit;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPaginationUnitDGoods: Paging;
    serverPaginationUnitDepot: Paging;
    serverPaginationUnitService: Paging;
    sortModelDangerousGoods: GridSortModel;
    sortModelUnitService: GridSortModel;
    sortModelDepot: GridSortModel;
    dGColumnVisibilityModel: GridColumnVisibilityModel;
    depotColumnVisibilityModel: GridColumnVisibilityModel;
    serviceColumnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGtsOrderUnitById: (id: number) => dispatch(fetchGtsOrderUnitById(id)),
    updateGtsOrderUnit: (gtsorder: GtsOrder) => dispatch(updateGtsOrderUnit(gtsorder)),
    deactivateGtsOrderUnitDangerousGoods: (orderUnitId: number) => dispatch(deactivateGtsOrderUnitDangerousGoods(orderUnitId)),
    deactivateGtsOrderUnitServices: (orderUnitId: number) => dispatch(deactivateGtsOrderUnitServices(orderUnitId)),
    deactivateGtsOrderUnitDepot: (orderUnitId: number) => dispatch(deactivateGtsOrderUnitDepot(orderUnitId)),
    fetchGtsOrderUnitsDepot: (paging: Paging, filters: GtsOrderUnitFilters) => dispatch(fetchGtsOrderUnitsDepot({
        paging,
        filters
    })),
    fetchGtsOrderUnitDangerousGoods: (paging: Paging, filters: GtsOrderUnitFilters) => dispatch(fetchGtsOrderUnitDangerousGoods({
        paging,
        filters
    })),
    fetchGTSOrderUnitServices: (paging: Paging, filters: GtsOrderUnitFilters) => dispatch(fetchGTSOrderUnitServices({
        paging,
        filters
    })),
    resetGtsOrderUnit: () => dispatch(resetGtsOrderUnit()),
    fetchGtsOrderById: (orderId: number) => dispatch(fetchGtsOrderById(orderId))
});

const mapStoreToProps = (store: RootState) => {
    return {
        gtsOrderUnit: getGtsOrderUnit(store),
        isUpdating: isUpdatingGtsOrderUnit(store),
        gtsOrderUnitsDepot: getGtsOrderUnitsDepot(store),
        gtsDangerousGoods: getGtsOrderUnitDangerousGoods(store),
        gtsUnitServices: getGtsOrderUnitServices(store),
        gtsOrder: getGtsOrder(store),
        isLoadingGtsOrderUnit: isLoadingGtsOrderUnit(store),
        isLoadingGtsOrderUnitsDepot: isLoadingGtsOrderUnitsDepot(store),
        isLoadingDangerousGoods: isLoadingDangerousGoods(store),
        isLoadingUnitServices: isLoadingUnitServices(store),
        isDeletingGtsOrderUnitService: isDeletingGtsOrderUnitService(store),
        isDeletingGtsOrderUnitDangerousGoods: isDeletingGtsOrderUnitDangerousGoods(store),
        isDeletingGtsOrderUnitDepot: isDeletingGtsOrderUnitDepot(store),
        isLoading: isUpdatingGtsOrderUnit(store) || isLoadingGtsOrderUnitsDepot(store) || isLoadingDangerousGoods(store) || isLoadingUnitServices(store),
        isLoadingGtsOrder: isLoadingGtsOrder(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('GTS_ORDER_UNIT'),
    properties: LanguageUtils.createMessage('Properties'),
    dangerousGoods: LanguageUtils.createMessage('Dangerous goods'),
    depot: LanguageUtils.createMessage('Depot'),
    unitService: LanguageUtils.createMessage('Unit service')
};

class GTSOrderUnitDetails extends Component<IGTSOrderUnitDetailsProps, IGTSOrderUnitDetailsState> {
    constructor(props: IGTSOrderUnitDetailsProps) {
        super(props);

        this.state = {
            gtsOrderUnit: {} as GTSOrderUnit,
            serverPaginationUnitDepot: PageUtils.getDefaultPaging(),
            serverPaginationUnitDGoods: PageUtils.getDefaultPaging(),
            serverPaginationUnitService: PageUtils.getDefaultPaging(),
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.dangerousGoods,
                additionalMargin: true,
                icon: <FontAwesomeIcon icon="skull-crossbones" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/dangerousgoods`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/dangerousgoods`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.depot,
                icon: <FontAwesomeIcon icon="boxes" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/depot`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/depot`, props.location.pathname)
            },
            {
                id: 4,
                name: messages.unitService,
                icon: <FontAwesomeIcon icon="dolly" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/unitservice`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_ORDER_UNITS}/${props.match.params.gtsOrderUnitId}/unitservice`, props.location.pathname)
            }
            ]),
            selectedRow: undefined,
            sortModelDangerousGoods: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelUnitService: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelDepot: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            dGColumnVisibilityModel: {},
            depotColumnVisibilityModel: {},
            serviceColumnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchGtsOrderUnitById } = this.props;
        const { gtsOrderUnitId } = this.props.match.params;

        fetchGtsOrderUnitById(gtsOrderUnitId);
    }

    componentDidUpdate(prevProps: IGTSOrderUnitDetailsProps) {
        const { gtsOrderUnit, isLoadingGtsOrderUnit, isDeletingGtsOrderUnitService, fetchGTSOrderUnitServices, isDeletingGtsOrderUnitDangerousGoods, fetchGtsOrderUnitDangerousGoods,
            isDeletingGtsOrderUnitDepot, fetchGtsOrderUnitsDepot, fetchGtsOrderById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const unitFilters = {
            orderNumber: gtsOrderUnit.orderNumber,
            hasErrors: true
        } as GtsOrderUnitFilters;

        if(isDeletingGtsOrderUnitService === false && prevProps.isDeletingGtsOrderUnitService === true) {
            fetchGTSOrderUnitServices(PageUtils.getDefaultPaging(), unitFilters);
        }

        if(isDeletingGtsOrderUnitDangerousGoods === false && prevProps.isDeletingGtsOrderUnitDangerousGoods === true) {
            fetchGtsOrderUnitDangerousGoods(PageUtils.getDefaultPaging(), unitFilters);
        }

        if(isDeletingGtsOrderUnitDepot === false && prevProps.isDeletingGtsOrderUnitDepot === true) {
            fetchGtsOrderUnitsDepot(PageUtils.getDefaultPaging(), unitFilters);
        }

        if(gtsOrderUnit !== prevProps.gtsOrderUnit) {
            if(gtsOrderUnit.gtsOrderId) {
                fetchGtsOrderById(gtsOrderUnit.gtsOrderId);
            }

            this.setState({ gtsOrderUnit });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && isLoadingGtsOrderUnit === false && prevProps.isLoadingGtsOrderUnit === true) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetGtsOrderUnit } = this.props;
        resetGtsOrderUnit();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchGtsOrderUnitsDepot, fetchGtsOrderUnitDangerousGoods, fetchGTSOrderUnitServices } = this.props;
        const { gtsOrderUnit } = this.props;
        const { serverPaginationUnitDGoods, serverPaginationUnitDepot, serverPaginationUnitService } = this.state;

        const unitFilters = {
            orderNumber: gtsOrderUnit.orderNumber,
            hasErrors: true
        } as GtsOrderUnitFilters;

        const specificUnitFilters = {
            logicalUnitId: gtsOrderUnit.logicalUnitId,
            hasErrors: true
        } as GtsOrderUnitFilters;

        const paging = PageUtils.getDefaultPaging();
        const newPaginationUnitDGoods = {
            ...serverPaginationUnitDGoods,
            page: paging.page
        };
        const newPaginationUnitDepot = {
            ...serverPaginationUnitDepot,
            page: paging.page
        };
        const newPaginationUnitService = {
            ...serverPaginationUnitService,
            page: paging.page
        };

        if(selectedMenuItem?.id === 2) {
            fetchGtsOrderUnitDangerousGoods(newPaginationUnitDGoods, unitFilters);
        }

        if(selectedMenuItem?.id === 3) {
            fetchGtsOrderUnitsDepot(newPaginationUnitDepot, unitFilters);
        }

        if(selectedMenuItem?.id === 4) {
            fetchGTSOrderUnitServices(newPaginationUnitService, specificUnitFilters);
        }
    }

    deactivateGtsOrderUnitDepot = () => {
        const { selectedRow } = this.state;
        const { deactivateGtsOrderUnitDepot } = this.props;

        deactivateGtsOrderUnitDepot(ensure(selectedRow));
    }

    deactivateGtsOrderUnitService = () => {
        const { selectedRow } = this.state;
        const { deactivateGtsOrderUnitServices } = this.props;

        deactivateGtsOrderUnitServices(ensure(selectedRow));
    }

    deactivateGtsOrderUnitDangerousGoods = () => {
        const { selectedRow } = this.state;
        const { deactivateGtsOrderUnitDangerousGoods } = this.props;

        deactivateGtsOrderUnitDangerousGoods(ensure(selectedRow));
    }

    getSelectedMenuItem = (state: IGTSOrderUnitDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { gtsOrderUnitId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }

            case 2: { //dangerous goods
                if(!selectedRow) {
                    return [];
                }

                // return [
                //     {
                //         href: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitId}/dangerousgoods/${selectedRow}/edit`,
                //         icon: 'edit',
                //         text: messages.edit
                //     }
                // ];
                return [
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateGtsOrderUnitDangerousGoods
                    }
                ];
            }

            case 3: { //depot
                if(!selectedRow) {
                    return [];
                }

                // return [
                //     {
                //         href: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitId}/depot/${selectedRow}/edit`,
                //         icon: 'edit',
                //         text: messages.edit
                //     }
                // ];
                return [
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateGtsOrderUnitDepot
                    }
                ];
            }

            case 4: { //unitservice
                if(!selectedRow) {
                    return [];
                }

                // return [
                //     {
                //         href: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitId}/unitservice/${selectedRow}/edit`,
                //         icon: 'edit',
                //         text: messages.edit
                //     }
                // ];
                return [
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateGtsOrderUnitService
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    _onSortModelDangerousGoodsChange = (newModel: GridSortModel) => {
        const { fetchGtsOrderUnitDangerousGoods } = this.props;
        const { serverPaginationUnitDGoods, sortModelDangerousGoods, gtsOrderUnit } = this.state;

        const unitFilters = {
            orderNumber: gtsOrderUnit.orderNumber,
            hasErrors: true
        } as GtsOrderUnitFilters;

        if(JSON.stringify(sortModelDangerousGoods) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationUnitDGoods,
                sort: newModel
            };
            this.setState({
                sortModelDangerousGoods: newModel,
                serverPaginationUnitDGoods: newPagination
            });

            fetchGtsOrderUnitDangerousGoods(newPagination, unitFilters);
        }
    }

    _onSortModelDepotChange = (newModel: GridSortModel) => {
        const { fetchGtsOrderUnitsDepot } = this.props;
        const { serverPaginationUnitDepot, sortModelDepot, gtsOrderUnit } = this.state;

        const unitFilters = {
            orderNumber: gtsOrderUnit.orderNumber,
            hasErrors: true
        } as GtsOrderUnitFilters;

        if(JSON.stringify(sortModelDepot) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationUnitDepot,
                sort: newModel
            };
            this.setState({
                sortModelDepot: newModel,
                serverPaginationUnitDepot: newPagination
            });

            fetchGtsOrderUnitsDepot(newPagination, unitFilters );
        }
    }

    _onSortModelServiceChange = (newModel: GridSortModel) => {
        const { fetchGTSOrderUnitServices } = this.props;
        const { sortModelUnitService, serverPaginationUnitService, gtsOrderUnit } = this.state;

        const unitFilters = {
            logicalUnitId: gtsOrderUnit.logicalUnitId,
            hasErrors: true
        } as GtsOrderUnitFilters;

        if(JSON.stringify(sortModelUnitService) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationUnitService,
                sort: newModel
            };
            this.setState({
                sortModelUnitService: newModel,
                serverPaginationUnitService: newPagination
            });

            fetchGTSOrderUnitServices(newPagination, unitFilters);
        }
    }

    render() {
        const { classes, gtsOrderUnitsDepot, isLoadingGtsOrderUnitsDepot, isLoadingGtsOrderUnit, gtsDangerousGoods, isLoadingDangerousGoods, fetchGtsOrderUnitDangerousGoods,
            gtsUnitServices, isLoadingUnitServices, fetchGtsOrderUnitsDepot, fetchGTSOrderUnitServices, isLoadingGtsOrder, gtsOrder } = this.props;
        const { gtsOrderUnit, menuItems, serverPaginationUnitDGoods, serverPaginationUnitDepot, serverPaginationUnitService,
            dGColumnVisibilityModel, depotColumnVisibilityModel, serviceColumnVisibilityModel } = this.state;
        const unitFilters = {
            orderNumber: gtsOrderUnit.orderNumber,
            hasErrors: true
        } as GtsOrderUnitFilters;
        const specificUnitFilters = {
            logicalUnitId: gtsOrderUnit.logicalUnitId,
            hasErrors: true
        } as GtsOrderUnitFilters;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingGtsOrderUnit || isLoadingGtsOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.GtsOrderUnit}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: `${gtsOrderUnit.orderNumber} - ${gtsOrderUnit.unitNumber}`,
                    icon: <FontAwesomeIcon icon="box" size="1x" />
                } as IMenuItem}
                routes={
                    [{
                        name: gtsOrder.orderNumber,
                        url: `${UrlConstants.GTS_ORDERS}/${gtsOrder.id}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/properties`}>
                            <ViewGtsOrderUnit gtsOrderUnit={gtsOrderUnit} />
                        </Route>
                        <Route path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/dangerousgoods`}>
                            <OrderUnitDangerousGoodsList isLoading={isLoadingDangerousGoods} rows={gtsDangerousGoods}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationUnitDGoods,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    this.setState({ serverPaginationUnitDGoods: newPage });
                                    fetchGtsOrderUnitDangerousGoods(unitFilters, newPage);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelDangerousGoodsChange(sortModel)}
                                columnVisibilityModel={dGColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        dGColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/depot`}>
                            <OrderUnitDepotList isLoading={isLoadingGtsOrderUnitsDepot} rows={gtsOrderUnitsDepot}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationUnitDepot,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    this.setState({ serverPaginationUnitDepot: newPage });
                                    fetchGtsOrderUnitsDepot(unitFilters, newPage);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelDepotChange(sortModel)}
                                columnVisibilityModel={depotColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        depotColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.GTS_ORDER_UNITS}/:gtsOrderUnitId/unitservice`}>
                            <OrderUnitServiceList isLoading={isLoadingUnitServices} rows={gtsUnitServices}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationUnitService,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    this.setState({ serverPaginationUnitService: newPage });
                                    fetchGTSOrderUnitServices(specificUnitFilters, newPage);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelServiceChange(sortModel)}
                                columnVisibilityModel={serviceColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        serviceColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(GTSOrderUnitDetails));