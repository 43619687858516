import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import ServiceStatus from '../interfaces/output/serviceStatus';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ServiceStatusApi {
    static fetchServiceStatusById = async(id: string): Promise<ServiceStatus> => {
        const { data } = await http.get<ServiceStatus>(`/servicestatus/${id}`);

        return data;
    };

    static fetchServiceStatuses = async(paging: Paging): Promise<Pagination<ServiceStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/servicestatus');

        const { data } = await http.get<Pagination<ServiceStatus>>(url);

        return data;
    };

    static createServiceStatus = async(serviceStatus: ServiceStatus): Promise<ServiceStatus> => {
        const newServiceStatus = {
            id: serviceStatus.id,
            name: serviceStatus.name,
            code: serviceStatus.code
        };

        const { data } = await http.post<any, ServiceStatus>('servicestatus', newServiceStatus);

        return data;
    };

    static updateServiceStatus = async(serviceStatus: ServiceStatus): Promise<ServiceStatus> => {
        const newServiceStatus = {
            id: serviceStatus.id,
            name: serviceStatus.name,
            code: serviceStatus.code
        };
        
        const { data } = await http.put<any, ServiceStatus>(`servicestatus/${serviceStatus.id}`, newServiceStatus);

        return data;
    };

    static deleteServiceStatus = async(serviceStatus: ServiceStatus): Promise<ServiceStatus> => {
        const { data } = await http.put<any, ServiceStatus>(`servicestatus/${serviceStatus.id}`);

        return data;
    };
}
