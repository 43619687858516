import React, { forwardRef, useEffect, useMemo } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FactorSetDangerousGoodsLineOutput from '../../../../interfaces/output/factorSetDangerousGoodsLineOut';
import PageUtils from '../../../../utils/pageUtils';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { isLoadingFactorSetDangerousGoods } from '../../../../reducers/factorSetDangerousGoodsReducer';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import FactorSetType from '../../../../interfaces/output/factorSetType';
import DangerousGoodsLineFilters from '../line/dangerousGoodsLineFilters';
import GenericTextField from '../../../common/widgets/genericTextField';
import { fetchDangerousGoodVersionLines, getDangerousGoodLines, isCreatingDangerousGoodLine, isLoadingDangerousGoodLine, isUpdatingDangerousGoodLine }
    from '../../../../reducers/dangerousGoodsLineReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';
import FactorSetDangerousGoodsLineStep1Validation from '../line/validations/factorSetDangerousGoodsLineStep1Validation';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitType from '../../../../interfaces/output/unitType';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import Currency from '../../../../interfaces/output/currency';
import { ensure } from '../../../../utils/arrayUtils';
import { fetchDangerousGoodsGroups, getDangerousGoodsGroups } from '../../../../reducers/dangerousGoodsGroupReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';

interface DangerousGoodsLineFormStep1Props {
    dangerousGoodsLine: FactorSetDangerousGoodsLineOutput;
    factorSetVersionId?: number;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    DEPARTURE_TYPE: 'dangerousGoodsType',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    dangerousgoodsgroup: LanguageUtils.createMessage('Dangerous goods group'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    unittype: LanguageUtils.createMessage('Unit type'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    dangerousGoodsTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_DEPARTURE_TYPE_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT')
};

const DangerousGoodsLineFormStep1 = forwardRef((props: DangerousGoodsLineFormStep1Props, ref: any): JSX.Element => {
    const { dangerousGoodsLine, onChange, factorSetVersionId, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingDangerousGoodLine), useSelector(isLoadingDangerousGoodLine), useSelector(isUpdatingDangerousGoodLine),
        useSelector(isLoadingFactorSetDangerousGoods)];
    const dangerousGoodsTypes = useSelector(getDangerousGoodsGroups).content;
    const dangerousGoodsLines = useSelector(getDangerousGoodLines).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const prevDangerousGoodsLine = WorkflowUtils.usePrevious<FactorSetDangerousGoodsLineOutput>(dangerousGoodsLine);
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchDangerousGoodsGroups({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchDangerousGoodVersionLines({
            paging,
            filters: { factorSetVersionId: factorSetVersionId } as DangerousGoodsLineFilters }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(dangerousGoodsLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(dangerousGoodsLine.unitType?.unitGroupId),
                paging }));
            if(dangerousGoodsLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(dangerousGoodsLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevDangerousGoodsLine) {
            if(dangerousGoodsLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(dangerousGoodsLine.unitType?.unitGroupId),
                    paging }));
                if(prevDangerousGoodsLine.id === dangerousGoodsLine.id) {
                    onChange('unitType', { ...dangerousGoodsLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                }
            }
            else {
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [dangerousGoodsLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevDangerousGoodsLine) {
            if(dangerousGoodsLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(dangerousGoodsLine.unitType?.unitSubGroupId),
                    paging }));
                if(prevDangerousGoodsLine.id === dangerousGoodsLine.id) {
                    onChange('unitType', { ...dangerousGoodsLine.unitType,
                        id: null });
                }
            }
            else {
                dispatch(resetUnitTypes());
            }
        }
    }, [dangerousGoodsLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!dangerousGoodsLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const filteredTypes = () => {
        return useMemo(() => {
            return dangerousGoodsTypes.filter(dt => (!dangerousGoodsLine.unitType?.id && (!dangerousGoodsLines.find(dl => dl.dangerousGoodsType?.id === dt.id && !dl.unitType)))
        || !dangerousGoodsLines.find(dl => dl.id !== dangerousGoodsLine?.id && dl.dangerousGoodsType?.id === dt.id && dl.unitType?.id === dangerousGoodsLine.unitType?.id));
        }, [dangerousGoodsTypes, dangerousGoodsLine, dangerousGoodsLines]);
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetDangerousGoodsLineStep1Validation.validateName(dangerousGoodsLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = dangerousGoodsLine.surcharge ? FactorSetDangerousGoodsLineStep1Validation.validateInputNumber(dangerousGoodsLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetDangerousGoodsLineStep1Validation.validateInputNumber(dangerousGoodsLine.factor);
                break;
            }
            case ATTRIBUTES.DEPARTURE_TYPE: {
                isValid = FactorSetDangerousGoodsLineStep1Validation.validateRequiredNumberWithMaxLength(dangerousGoodsLine.dangerousGoodsType?.id);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = dangerousGoodsLine.surcharge ? FactorSetDangerousGoodsLineStep1Validation.validateInputNumber(dangerousGoodsLine.surchargeCurrency?.id) : true;
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={dangerousGoodsLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetType>
                            options={filteredTypes()}
                            value={dangerousGoodsLine.dangerousGoodsType}
                            onChange={(obj: FactorSetType | null) => onChange('dangerousGoodsType', obj)}
                            placeholder={messages.dangerousgoodsgroup}
                            error={setValidationState(ATTRIBUTES.DEPARTURE_TYPE)}
                            required
                            helperText={messages.dangerousGoodsTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={dangerousGoodsLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === dangerousGoodsLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...dangerousGoodsLine.unitType,
                                unitGroupId: obj?.id })}
                            placeholder={messages.unitGroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={dangerousGoodsLine.unitType?.id}
                            compareFn={(o: UnitSubGroup) => o.id === dangerousGoodsLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...dangerousGoodsLine.unitType,
                                unitSubGroupId: obj?.id })}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={dangerousGoodsLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={dangerousGoodsLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === dangerousGoodsLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...dangerousGoodsLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unittype}
                            isLoading={dangerousGoodsLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={dangerousGoodsLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        type="number"
                        label={messages.factor}
                        value={dangerousGoodsLine.factor}
                        onChange={onChange}
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        type="number"
                        label={messages.surcharge}
                        value={dangerousGoodsLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={dangerousGoodsLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={dangerousGoodsLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default DangerousGoodsLineFormStep1;