import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderUnitServiceProduct from '../interfaces/output/orderUnitServiceProduct';
import OrderUnitServiceProductApi from '../api/orderUnitServiceProductApi';
import { createOrderUnitServiceProductError, createOrderUnitServiceProductSuccess, deactivateOrderUnitServiceProductError, deactivateOrderUnitServiceProductSuccess,
    fetchOrderUnitServiceProductByIdError, fetchOrderUnitServiceProductByIdSuccess, fetchSurchargesByOrderUnitServiceProductIdError, fetchSurchargesByOrderUnitServiceProductIdSuccess,
    updateOrderUnitServiceProductError, updateOrderUnitServiceProductSuccess } from '../reducers/orderUnitServiceProductReducer';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import OrderUnitServiceProductSurchargeOut from '../interfaces/output/orderUnitServiceProductSurchargeOut';

function* doFetchOrderUnitServiceProduct(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: OrderUnitServiceProduct = yield call(OrderUnitServiceProductApi.fetchOrderUnitServiceProduct, id);
        yield put(fetchOrderUnitServiceProductByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrderUnitServiceProductByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductSurchargesByOrderUnitServiceProductId(action: PayloadAction<{paging: Paging, id: number}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<OrderUnitServiceProductSurchargeOut> = yield call(OrderUnitServiceProductApi.fetchProductSurchargesByOrderUnitServiceProductId, id, paging);
    
        yield put(fetchSurchargesByOrderUnitServiceProductIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargesByOrderUnitServiceProductIdError(error as HttpErrorResponse));
    }
}

function* doCreateUnitServiceProduct(action: PayloadAction<OrderUnitServiceProduct>) {
    const unitServiceProduct = action.payload;

    try {
        const response: OrderUnitServiceProduct = yield call(OrderUnitServiceProductApi.createOrderUnitServiceProduct, unitServiceProduct);

        yield put(createOrderUnitServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(createOrderUnitServiceProductError(error as HttpErrorResponse));
    }
}

function* doUpdateOrderUnitServiceProduct(action: PayloadAction<OrderUnitServiceProduct>) {
    const unitService = action.payload;

    try {
        const response: OrderUnitServiceProduct = yield call(OrderUnitServiceProductApi.updateOrderUnitServiceProduct, unitService);

        yield put(updateOrderUnitServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateOrderUnitServiceProductError(error as HttpErrorResponse));
    }
}

function* doDeactivateUnitServiceProduct(action: PayloadAction<number>) {
    const unitServiceId = action.payload;
    
    try {
        const response: OrderUnitServiceProduct = yield call(OrderUnitServiceProductApi.deactivateOrderUnitServiceProduct, unitServiceId);

        yield put(deactivateOrderUnitServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateOrderUnitServiceProductError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('orderUnitServiceProduct/fetchOrderUnitServiceProductById', doFetchOrderUnitServiceProduct),
    takeLatest('orderUnitServiceProduct/fetchSurchargesByOrderUnitServiceProductId', doFetchProductSurchargesByOrderUnitServiceProductId),
    takeLatest('orderUnitServiceProduct/createOrderUnitServiceProduct', doCreateUnitServiceProduct),
    takeLatest('orderUnitServiceProduct/updateOrderUnitServiceProduct', doUpdateOrderUnitServiceProduct),
    takeLatest('orderUnitServiceProduct/deactivateOrderUnitServiceProduct', doDeactivateUnitServiceProduct)
];

