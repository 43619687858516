import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchDashboardMessageTypesSuccess, fetchDashboardMessageTypesError } from '../reducers/dashboardMessageTypeReducer';
import Pagination from '../interfaces/common/pagination';
import DashboardMessageType from '../interfaces/output/dashboardMessageType';
import DashboardMessageTypeApi from '../api/dashboardMessageTypeApi';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchDashboardMessageTypes(action: PayloadAction<{ paging: Paging, searchTerm: string }>) {
    const { paging, searchTerm } = action.payload;

    try {
        const response: Pagination<DashboardMessageType> = yield call(DashboardMessageTypeApi.fetchDashboardMessageTypes, paging, searchTerm);

        yield put(fetchDashboardMessageTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDashboardMessageTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('dashboardMessageType/fetchDashboardMessageTypes', doFetchDashboardMessageTypes)
];

