import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import InvoiceStatusOut from '../interfaces/output/invoiceStatusOut';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class InvoiceStatusApi {
    static fetchInvoiceStatusById = async(statusId: string): Promise<InvoiceStatusOut> => {
        const { data } = await http.get<InvoiceStatusOut>(`invoicestatus/${statusId}`);

        return data;
    };

    static fetchInvoiceStatuses = async(paging: Paging): Promise<Pagination<InvoiceStatusOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/invoicestatus');

        const { data } = await http.get<Pagination<InvoiceStatusOut>>(url);

        return data;
    };
}
