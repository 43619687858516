import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../../constants/UrlConstants';
import DataGridComponent from '../../common/dataGrid';
import TableUtils from '../../../utils/tableUtils';
import Pagination from '../../../interfaces/common/pagination';
import OrderUnit from '../../../interfaces/output/orderUnit';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import CustomStringLink from '../../common/customStringLink';

interface IOrderUnitListProps {
    classes?: any;
    orderUnits?: Pagination<OrderUnit>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    orderUnit: LanguageUtils.createMessage('ORDER_UNIT'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    departureType: LanguageUtils.createMessage('Departure type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    grossWeightKg: LanguageUtils.createMessage('Gross weight kg'),
    dangerousGoodsGroup: LanguageUtils.createMessage('Dangerous goods group'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    totalPrice: LanguageUtils.createMessage('Total price')
};

const OrderUnitList = forwardRef((props: IOrderUnitListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, orderUnits, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'id',
                headerName: intl.formatMessage(messages.orderUnit),
                flex: 0.8,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.id} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitId', hiddenColumns)
            },
            {
                field: 'unitNo',
                headerName: intl.formatMessage(messages.unitNo),
                flex: 0.8,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.unitNo || 'N/A'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitNo', hiddenColumns)
            },
            {
                field: 'unitType',
                headerName: intl.formatMessage(messages.unitType),
                flex: 1.5,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.unitType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitType', hiddenColumns)
            },
            {
                field: 'departureType',
                headerName: intl.formatMessage(messages.departureType),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.departureType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('departureType', hiddenColumns)
            },
            {
                field: 'grossWeightKg',
                headerName: intl.formatMessage(messages.grossWeightKg),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(params).id}`} value={params.row?.grossWeightKg ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('grossWeight', hiddenColumns)
            },
            {
                field: 'dangerousGoodsGroup',
                headerName: intl.formatMessage(messages.dangerousGoodsGroup),
                flex: 2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.dangerousGoodsGroup?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('dangerousGoodsGroup', hiddenColumns)
            },
            {
                field: 'cancellationType',
                headerName: intl.formatMessage(messages.cancellationType),
                flex: 2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.cancellationType?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('cancellationType', hiddenColumns)
            },
            {
                field: 'orderUnitTotalPrice',
                headerName: intl.formatMessage(messages.totalPrice),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.ORDER_UNITS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.orderUnitTotalPrice ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('totalPrice', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || orderUnits} columns={generateColumns()} componentsProps={componentsProps}
            isLoading={isLoading} checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default OrderUnitList;