import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../common/widgets/genericStepper';
import GenericStep from '../../interfaces/common/genericStep';
import { createServiceProduct, fetchServiceProductById, getServiceProduct, isUpdating, updateServiceProduct } from '../../reducers/serviceProductReducer';
import UrlConstants from '../../constants/UrlConstants';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';
import ServiceProduct from '../../interfaces/output/serviceProduct';
import AddServiceProductStep1 from './add/addServiceProductStep1';
import WorkflowUtils from '../../utils/workflowUtils';

interface IAddServiceFormProps {
    serviceProductId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    editServiceProduct: LanguageUtils.createMessage('Edit service product')
};

export default function AddServiceProductForm(props: IAddServiceFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { serviceProductId, steps, type } = props;
    const [serviceProduct, setServiceProduct] = React.useState<ServiceProduct>({} as ServiceProduct);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsServiceProduct = useSelector(getServiceProduct);
    const propsIsUpdating = useSelector(isUpdating);
    const prevIsUpdating = WorkflowUtils.usePrevious<boolean>(propsIsUpdating);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if(prevIsUpdating === true && !propsIsUpdating) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdating]);
    
    useEffect(() => {
        if(serviceProductId) {
            dispatch(fetchServiceProductById(serviceProductId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsServiceProduct.id) {
            setServiceProduct(propsServiceProduct);
        }

    }, [propsIsUpdating, propsServiceProduct.id]);

    const onChange = (attribute: string, value: any) => {
        const newServiceProduct = { ...serviceProduct } as ServiceProduct;
        (newServiceProduct as any)[attribute] = value;

        setServiceProduct(newServiceProduct);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddServiceProductStep1 onChange={onChange} serviceProduct={serviceProduct} />,
            onNext: () => {
                if(!serviceProduct.id) {
                    dispatch(createServiceProduct(serviceProduct));
                }
                else {
                    dispatch(updateServiceProduct(serviceProduct));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Edit:
                return messages.editServiceProduct;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.SERVICES}/${serviceProduct.service?.id}/products`}
        />
    );
}
  