import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import Order from '../../interfaces/output/order';
import LanguageUtils from '../../utils/LanguageUtils';
import DisplayStatus from '../common/displayStatus';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomStringLink from '../common/customStringLink';
import CustomLink from '../common/customLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';

interface IOrdersListProps {
    classes?: any;
    orders?: Pagination<Order>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
}

const messages = {
    orderNo: LanguageUtils.createMessage('ORDER_NUMBER'),
    customer: LanguageUtils.createMessage('Customer'),
    unitGroup: LanguageUtils.createMessage('UNIT_GROUP_SHORT'),
    orderDate: LanguageUtils.createMessage('Order date'),
    status: LanguageUtils.createMessage('ORDER_STATUS'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    loadUnits: LanguageUtils.createMessage('Load units'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    errorMessage: LanguageUtils.createMessage('Error message'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    id: LanguageUtils.createMessage('Internal number'),
    basedOnOrderNumber: LanguageUtils.createMessage('Based on order number')
};
const OrdersList = forwardRef((props: IOrdersListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, orders, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.CUSTOMER_ORDERS_MAIN);
        }
    },[]);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'id',
                headerName: intl.formatMessage(messages.id),
                flex: 0.7,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.id} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('id', hiddenColumns)
            },
            {
                field: 'orderNumber',
                headerName: intl.formatMessage(messages.orderNo),
                flex: 0.7,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.orderNumber} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderNumber', hiddenColumns)
            },
            {
                field: 'customer_name',
                headerName: intl.formatMessage(messages.customer),
                flex: 2.5,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.customer?.customerNoName ?? '-'}
                    link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('customer', hiddenColumns)
            },
            {
                field: 'orderDate',
                headerName: intl.formatMessage(messages.orderDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return (<CustomLink link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.orderDate} />
                    </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderDate', hiddenColumns)
            },
            {
                field: 'loadUnits',
                headerName: intl.formatMessage(messages.loadUnits),
                flex: 0.3,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.orderUnitCounter ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('loadUnits', hiddenColumns)
            },
            {
                field: 'sumTotalPrice',
                headerName: intl.formatMessage(messages.totalPrice),
                flex: 0.6,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.sumTotalPrice ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('sumTotalPrice', hiddenColumns)
            },
            {
                field: 'unitGroup',
                headerName: intl.formatMessage(messages.unitGroup),
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.unitGroup?.shortCode ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitGroup', hiddenColumns)
            },
            {
                field: 'fromTerminalId_name',
                headerName: intl.formatMessage(messages.fromTerminal),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.fromTerminal?.name ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('fromTerminal', hiddenColumns)
            },
            {
                field: 'toTerminalId_name',
                headerName: intl.formatMessage(messages.toTerminal),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.toTerminal?.name ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('toTerminal', hiddenColumns)
            },
            {
                field: 'status',
                headerName: intl.formatMessage(messages.status),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`}>
                    <DisplayStatus status={applyRowFn(params)?.status} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            {
                field: 'invoiceType',
                headerName: intl.formatMessage(messages.invoiceType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.invoiceType?.name ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('invoiceType', hiddenColumns)
            },
            {
                field: 'basedOnOrderNumber',
                headerName: intl.formatMessage(messages.basedOnOrderNumber),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.basedOnOrderNumber ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('basedOnOrderNumber', hiddenColumns)
            },
            {
                field: 'errorMessage',
                headerName: intl.formatMessage(messages.errorMessage),
                flex: 2.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.errorMessage ?? '-'} link={`/#/${UrlConstants.ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('errorMessage', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || orders} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default OrdersList;