import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AgreementVersionStatus from '../interfaces/output/agreementVersionStatus';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type AgreementVersionStatusState = {
    agreementVersionStatus: AgreementVersionStatus;
    agreementVersionStatuses: Pagination<AgreementVersionStatus>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: AgreementVersionStatusState = {
    agreementVersionStatus: {} as AgreementVersionStatus,
    agreementVersionStatuses: { content: [] as Array<AgreementVersionStatus> } as Pagination<AgreementVersionStatus>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const customerAgreementVersionStatusSlice = createSlice({
    name: 'agreementVersionStatus',

    initialState,
    reducers: {
        fetchAgreementVersionStatuses: (state: AgreementVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchAgreementVersionStatusesSuccess: (state: AgreementVersionStatusState, action: PayloadAction<Pagination<AgreementVersionStatus>>) => {
            state.isLoading = false;
            state.agreementVersionStatuses = action.payload;
        },
        fetchAgreementVersionStatusesError: (state: AgreementVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchAgreementVersionStatusById: (state: AgreementVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchAgreementVersionStatusByIdSuccess: (state: AgreementVersionStatusState, action: PayloadAction<AgreementVersionStatus>) => {
            state.isLoading = false;
            state.agreementVersionStatus = action.payload;
        },
        fetchAgreementVersionStatusByIdError: (state: AgreementVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateAgreementVersionStatus: (state: AgreementVersionStatusState, _action: PayloadAction<AgreementVersionStatus>) => {
            state.isUpdating = true;
        },
        updateAgreementVersionStatusSuccess: (state: AgreementVersionStatusState, action: PayloadAction<AgreementVersionStatus>) => {
            state.isUpdating = false;
            state.agreementVersionStatus = action.payload;
        },
        updateAgreementVersionStatusError: (state: AgreementVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateAgreementVersionStatus: (state: AgreementVersionStatusState, _action: PayloadAction<AgreementVersionStatus>) => {
            state.isDeleted = true;
        },
        deactivateAgreementVersionStatusSuccess: (state: AgreementVersionStatusState, action: PayloadAction<AgreementVersionStatus>) => {
            state.isDeleted = false;
            state.agreementVersionStatus = action.payload;
        },
        deactivateAgreementVersionStatusError: (state: AgreementVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetAgreementVersionStatuses: (state: AgreementVersionStatusState) => {
            state.agreementVersionStatuses = initialState.agreementVersionStatuses;
        }
    }
});

export const { fetchAgreementVersionStatusById, fetchAgreementVersionStatusByIdSuccess, fetchAgreementVersionStatusByIdError, updateAgreementVersionStatus,
    updateAgreementVersionStatusSuccess, updateAgreementVersionStatusError, fetchAgreementVersionStatuses, fetchAgreementVersionStatusesSuccess,
    fetchAgreementVersionStatusesError, deactivateAgreementVersionStatus, deactivateAgreementVersionStatusSuccess, deactivateAgreementVersionStatusError,
    resetAgreementVersionStatuses } = customerAgreementVersionStatusSlice.actions;

export const isLoadingAgreementVersionStatus = (state: RootState): boolean => state.agreementVersionStatusReducer.isLoading;
export const isUpdating = (state: RootState): boolean => state.agreementVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.agreementVersionStatusReducer.isDeleted;
export const getAgreementVersionStatus = (state: RootState): AgreementVersionStatus => state.agreementVersionStatusReducer.agreementVersionStatus;
export const getAgreementVersionStatuses = (state: RootState): Pagination<AgreementVersionStatus> => state.agreementVersionStatusReducer.agreementVersionStatuses;

export default customerAgreementVersionStatusSlice.reducer;