import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import PriceListTerminalApi from '../api/priceListTerminalApi';
import PriceListTerminal from '../interfaces/output/priceListTerminal';
import { fetchPriceListTerminalByIdError, fetchPriceListTerminalByIdSuccess, fetchPriceListTerminalsError, fetchPriceListTerminalsSuccess } from '../reducers/priceListTerminalReducer';

function* doFetchPriceListTerminal(action: PayloadAction<string>) {
    const priceListTerminalId = action.payload;
    try {
        const response: PriceListTerminal = yield call(PriceListTerminalApi.fetchPriceListTerminalById, priceListTerminalId);
        yield put(fetchPriceListTerminalByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListTerminalByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPriceListTerminals(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<PriceListTerminal> = yield call(PriceListTerminalApi.fetchPriceListTerminals, paging);
        yield put(fetchPriceListTerminalsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListTerminalsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('priceListTerminal/fetchPriceListTerminalById', doFetchPriceListTerminal),
    takeLatest('priceListTerminal/fetchPriceListTerminals', doFetchPriceListTerminals)];

