/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, ListItemText, ListItemIcon, ListItem, Divider, List, Drawer, Box, Fab } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHelptextById, getHelptext } from '../../reducers/helptextReducer';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import { useEffect } from 'react';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import { useLocation } from 'react-router-dom';

const drawerWidth = 250;
const drawerWidthSmall = 70;

interface IHelptextExtendableDrawer {
    setOpen: (open: boolean) => any;
    open: boolean;
    objectType?: ObjectType;
    componentId?: string|undefined;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    drawerContainer: {
        overflow: 'auto'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerSmall: {
        width: drawerWidthSmall,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: theme.spacing(16),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    drawerPaperSmall: {
        width: drawerWidthSmall,
        marginTop: theme.spacing(16),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    hideOverflow: {
        overflow: 'hidden'
    }
}));

export default function HelptextExtendableDrawer(props: IHelptextExtendableDrawer): JSX.Element {
    const { setOpen, open, componentId } = props;
    const dispatch = useDispatch();
    const helptext = useSelector(getHelptext);
    const location = useLocation();

    useEffect(() => {
        dispatch(fetchHelptextById(getHelptexts()));
    }, [dispatch, componentId]);

    const getHelptexts = (): string => {
        const { objectType, componentId } = props;
        // eslint-disable-next-line no-console
        console.log(location.pathname);
        switch (objectType) {
            case ObjectType.Customer:
            {
                switch (componentId) {
                    case HelptextConstantsEnum.CUSTOMER_PROPERTIES_MAIN:
                        return HelptextConstantsEnum.CUSTOMER_PROPERTIES_MAIN;
                    case HelptextConstantsEnum.CUSTOMER_CUSTOMERS_MAIN:
                        return HelptextConstantsEnum.CUSTOMER_CUSTOMERS_MAIN;
                    case HelptextConstantsEnum.CUSTOMER_AGREEMENTS_MAIN:
                        return HelptextConstantsEnum.CUSTOMER_AGREEMENTS_MAIN;
                    case HelptextConstantsEnum.CUSTOMER_INVOICES_MAIN:
                        return HelptextConstantsEnum.CUSTOMER_ORDERS_MAIN;
                    case HelptextConstantsEnum.CUSTOMER_ORDERS_MAIN:
                        return HelptextConstantsEnum.CUSTOMER_ORDERS_MAIN;
                }

                return '';
            }
            case ObjectType.Agreement:
                return HelptextConstantsEnum.CUSTOMER_AGREEMENTS_MAIN;
            case ObjectType.Order:
                return HelptextConstantsEnum.CUSTOMER_ORDERS_MAIN;
            default:
                return '';
        }
    };
    const classes = useStyles();

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const list = () => (
        <Box>
            <List>
                <ListItem>
                    <ListItemIcon title="Info" onClick={handleDrawerToggle}>
                        <Fab size="small" style={{
                            background: 'transparent',
                            boxShadow: 'none'
                        }}>
                            <FontAwesomeIcon icon="question" />
                        </Fab>
                    </ListItemIcon>
                </ListItem>
            </List>
            {open && <Divider />}
            <List>
                <ListItem key={helptext.id}>
                    <ListItemText hidden={!open} secondary={helptext.text} style={{
                        whiteSpace: 'pre-line'
                    }} />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <>
                <Drawer
                    className={open ? classes.drawer : classes.drawerSmall}
                    classes={{ paper: open ? classes.drawerPaper : classes.drawerPaperSmall }}
                    anchor="right"
                    variant="persistent"
                    open
                    onClose={handleDrawerToggle}
                >
                    <div className={classes.drawerContainer} />
                    <div className={classes.hideOverflow}>
                        {list()}
                    </div>
                </Drawer>
            </>
        </div>
    );
}
