import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import { fetchAgreementVersions, getAgreementVersions, isLoadingAgreementVersions } from '../../../reducers/agreementsReducer';
import Paging from '../../../interfaces/common/paging';
import AgreementVersionsList from '../agreementVersion/agreementVersionsList';
import { ensure } from '../../../utils/arrayUtils';
import WorkflowUtils from '../../../utils/workflowUtils';
import Pagination from '../../../interfaces/common/pagination';
import AgreementVersion from '../../../interfaces/output/agreementVersion';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddAgreementVersionStep3Props {
    agreementId?: number;
    selectedAgreementVersionId?: number;
    onChange: (x: number | undefined) => void;
}

export interface AddAgreementVersionStep3State {
    selectedAgreementVersionId?: number;
}

const AddAgreementVersionStep3 = forwardRef((props: AddAgreementVersionStep3Props, ref: any): JSX.Element => {
    const { agreementId ,selectedAgreementVersionId, onChange } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    //const [selectedAgreementVersionId, setSelectedAgreementVersionId] = React.useState<number>();
    const [agreementVersions, setAgreementVersions] = React.useState<Pagination<AgreementVersion>>({ content: [] as Array<AgreementVersion> } as Pagination<AgreementVersion>);
    const dispatch = useDispatch();
    const propsAgreementVersions = useSelector(getAgreementVersions);
    const propsLoadingAgreementVersions = useSelector(isLoadingAgreementVersions);
    const prevIsLoadingAgreementVersions = WorkflowUtils.usePrevious<boolean>(propsLoadingAgreementVersions);
    WorkflowUtils.setHandle<AddAgreementVersionStep3State>(ref, { selectedAgreementVersionId } as AddAgreementVersionStep3State );
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);

    useEffect(() => {
        dispatch(fetchAgreementVersions({
            id: ensure(agreementId),
            paging: paging
        }));
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingAgreementVersions === true && !propsLoadingAgreementVersions) {
            setAgreementVersions(propsAgreementVersions);
        }
    }, [propsLoadingAgreementVersions]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };
          
            dispatch(fetchAgreementVersions({
                id: ensure(agreementId),
                paging: newServerPagination
            }));
            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };
        
    return (
        <Loader isLoading={propsLoadingAgreementVersions}>
            <Grid container spacing={1} mt={2}>
                <Grid item xs={12}>
                    <AgreementVersionsList
                        isLoading={propsLoadingAgreementVersions}
                        rows={agreementVersions}
                        checkSelection={(value: number) => onChange(value)}
                        onPageChange={(nextPage: number, nextSize: number) => {
                            const newPage = {
                                ...paging,
                                page: nextPage,
                                size: nextSize
                            };
                            dispatch(fetchAgreementVersions({
                                id: ensure(agreementId),
                                paging: newPage
                            }));
                            setPaging(newPage);
                        }}
                        disableLinks
                        onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                    />
                </Grid>
            </Grid>
        </Loader>
    );
});
  
export default AddAgreementVersionStep3;