import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isLoadingUnitServiceProducts } from '../../../reducers/unitServiceReducer';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { isUpdatingOrderUnitServiceProduct } from '../../../reducers/orderUnitServiceProductReducer';
import GenericTextField from '../../common/widgets/genericTextField';

interface AddOrderUnitServiceProductStep6Props {
    orderUnitServiceProduct: OrderUnitServiceProduct;
    onChange: any;
}

const messages = {
    overriddenPrice: LanguageUtils.createMessage('Overridden Price')
};

const AddOrderUnitServiceProductStep6 = forwardRef((props: AddOrderUnitServiceProductStep6Props, ref: any): JSX.Element => {
    const { orderUnitServiceProduct, onChange } = props;
    const isLoadingObjects = [useSelector(isUpdatingOrderUnitServiceProduct), useSelector(isLoadingUnitServiceProducts)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            type="number"
                            name="overriddenPrice"
                            label={messages.overriddenPrice}
                            value={orderUnitServiceProduct.overriddenPrice}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddOrderUnitServiceProductStep6;