import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridRenderCellParams, GridValueGetterParams, GridSelectionModel, GridColumnVisibilityModel } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import Service from '../../interfaces/output/service';
import LanguageUtils from '../../utils/LanguageUtils';
import DisplayStatus from '../common/displayStatus';
import CustomStringLink from '../common/customStringLink';
import CustomLink from '../common/customLink';

interface IServicesListProps {
    classes?: any;
    services?: Pagination<Service>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    disableLinks?: boolean;
    defaultSelectionModel?: Array<string | number | undefined>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to'),
    serviceType: LanguageUtils.createMessage('Service type'),
    status: LanguageUtils.createMessage('Status'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    importExport: LanguageUtils.createMessage('Service import export'),
    serviceAlternative: LanguageUtils.createMessage('Service alternative')
};

const ServicesList = forwardRef((props: IServicesListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, services, disableLinks, defaultSelectionModel, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'type_name',
                headerName: intl.formatMessage(messages.serviceType),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.SERVICES}/${cellValues.row.id}`} value={applyRowFn(cellValues)?.type?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('type', hiddenColumns)
            },
            {
                field: 'fromTerminalId_name',
                headerName: intl.formatMessage(messages.terminalFrom),
                flex: 2,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.SERVICES}/${params.row.id}`} value={applyRowFn(params)?.fromTerminal?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('fromTerminal', hiddenColumns)
            },
            {
                field: 'toTerminalId_name',
                headerName: intl.formatMessage(messages.terminalTo),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.SERVICES}/${params.row.id}`} value={applyRowFn(params)?.toTerminal?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('toTerminal', hiddenColumns)
            },
            {
                field: 'unitGroup_name',
                headerName: intl.formatMessage(messages.unitGroup),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.SERVICES}/${params.row.id}`} value={applyRowFn(params)?.unitGroup?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitGroup', hiddenColumns)
            },
            {
                field: 'status_name',
                headerName: intl.formatMessage(messages.status),
                flex: 0.7,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.SERVICES}/${params.row.id}`}>
                    <DisplayStatus status={applyRowFn(params)?.status} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            {
                field: 'importExportCode',
                headerName: intl.formatMessage(messages.importExport),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.SERVICES}/${params.row.id}`} value={applyRowFn(params)?.importExportCode?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('importExportCode', hiddenColumns)
            },
            {
                field: 'serviceAlternative',
                headerName: intl.formatMessage(messages.serviceAlternative),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.SERVICES}/${params.row.id}`} value={applyRowFn(params)?.serviceAlternative ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('serviceAlternative', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || services} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} defaultSelectionModel={defaultSelectionModel as GridSelectionModel}
            onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
});

export default ServicesList;