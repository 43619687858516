import React from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import UrlConstants from '../../constants/UrlConstants';
import TableUtils from '../../utils/tableUtils';
import DataGridComponent from '../common/dataGrid';
import CustomStringLink from '../common/customStringLink';

interface ISurchargesListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    pricingComponentType: LanguageUtils.createMessage('Pricing component type'),
    surchargeAmount: LanguageUtils.createMessage('Amount'),
    pricelistVersion: LanguageUtils.createMessage('Price list version')
};

export default function SurchargesList(props: ISurchargesListProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'pricingComponentType',
                headerName: intl.formatMessage(messages.pricingComponentType),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${applyRowFn(cellValues).id}`}
                        value={applyRowFn(cellValues)?.pricingComponentType?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('pricingComponentType', hiddenColumns)
            },
            {
                field: 'surchargeAmount',
                headerName: intl.formatMessage(messages.surchargeAmount),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${applyRowFn(params).id}`} value={applyRowFn(params)?.surchargeAmount ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('surchargeAmount', hiddenColumns)
            },
            {
                field: 'pricelistVersion',
                headerName: intl.formatMessage(messages.pricelistVersion),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${applyRowFn(params).id}`} value={applyRowFn(params)?.pricelistVersion?.versionNumber ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('pricelistVersion', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
}