import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import AccordionSummary from '../../common/accordionSummary';
import InvoiceLineOut from '../../../interfaces/output/invoiceLineOut';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UrlConstants from '../../../constants/UrlConstants';
import { Link } from 'react-router-dom';

interface IViewInvoiceLineProps {
    classes?: any;
    theme?: Theme;
    invoiceLine: InvoiceLineOut;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        },
        link: {
            textDecoration: 'none',
            color: `${theme.palette.info.main}!important`,
            fontSize: '12px!important'
        },
        linkItemGrid: {
            marginLeft: '10px!important'
        }
    })
);

const messages = {
    invoiceNumber: LanguageUtils.createMessage('Invoice number'),
    details: LanguageUtils.createMessage('Invoice line details'),
    product: LanguageUtils.createMessage('Product'),
    service: LanguageUtils.createMessage('Service'),
    order: LanguageUtils.createMessage('Order'),
    departureType: LanguageUtils.createMessage('Departure type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    customer: LanguageUtils.createMessage('Customer'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    customerReference: LanguageUtils.createMessage('Customer reference'),
    dangerousgoodsgroup: LanguageUtils.createMessage('Dangerous goods group'),
    vatCode: LanguageUtils.createMessage('Vat code'),
    productDynamicsId: LanguageUtils.createMessage('Dynamics id'),
    invoiceLinePrice: LanguageUtils.createMessage('Invoice line price'),
    weightKg: LanguageUtils.createMessage('Weight kg'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    unitType: LanguageUtils.createMessage('Unit type'),
    serviceDate: LanguageUtils.createMessage('Service date'),
    serviceDescription: LanguageUtils.createMessage('Service description'),
    dangerousGoods: LanguageUtils.createMessage('Dangerous goods'),
    cancellationCode: LanguageUtils.createMessage('Cancellation code'),
    weightTons: LanguageUtils.createMessage('Weight Tons'),
    freightPrice: LanguageUtils.createMessage('Freight Price'),
    railwayTrackSurcharge: LanguageUtils.createMessage('Railway track surcharge'),
    electricitySurcharge: LanguageUtils.createMessage('Electricity surcharge'),
    dieselSurcharge: LanguageUtils.createMessage('Diesel surcharge'),
    distributionPrice: LanguageUtils.createMessage('Distribution'),
    otherSurcharges: LanguageUtils.createMessage('Other surcharges'),
    lineId: LanguageUtils.createMessage('Line Id'),
    goToOrder: LanguageUtils.createMessage('Go to order')

};

export default function ViewInvoiceLine(props: IViewInvoiceLineProps): JSX.Element {
    const classes = styles();
    const { invoiceLine } = props;
    const { formatMessage } = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.invoiceNumber} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.invoice?.invoiceNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.order} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography gutterBottom>{invoiceLine.order?.orderNumber || '-'}</Typography>
                                        </Grid>
                                        {invoiceLine.order && invoiceLine.order.id && invoiceLine.order.orderNumber &&
                                        <Grid item className={classes.linkItemGrid}>
                                            <Link to={`/${UrlConstants.ORDERS}/${invoiceLine.order?.id}/properties`} title={formatMessage(messages.goToOrder)} className={classes.link}>
                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                            </Link>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.unitNo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.unitDescription || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.customerReference} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.customerReference || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>
                                        {<NullableFormattedDate value={invoiceLine.serviceDate} />}&nbsp;
                                        {invoiceLine.serviceDate ? <FormattedTime hour="numeric" minute="numeric" value={invoiceLine.serviceDate} /> : ' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceDescription} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.serviceDescription || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.departureTypeName || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priorityType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.priorityTypeName || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.dangerousGoods} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.dangerousGoodsDescription || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.cancellationCode} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.cancellationCode || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.weightTons} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.weightTons || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.invoiceLinePrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.price || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.freightPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.freightPrice || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.railwayTrackSurcharge} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.railwayTrackSurcharge || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.electricitySurcharge} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.electricitySurcharge || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.dieselSurcharge} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.dieselSurcharge || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.distributionPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.distributionPrice || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.otherSurcharges} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.otherSurcharges || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.lineId} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{invoiceLine.id || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}