import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import FactorSetDepartureLineOutput from '../../../../interfaces/output/factorSetDepartureLineOut';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import DepartureLineFormStep1 from './departureLineFormStep1';
import { createDepartureVersionLine, fetchFactorSetDepartureLineById, getFactorSetDepartureLine, isCreatingDepartureLine, isUpdatingDepartureLine, updateFactorSetDepartureLine }
    from '../../../../reducers/departureLineReducer';
import FactorSetDepartureLineStep1Validation from '../line/validations/factorSetDepartureLineStep1Validation';

interface IAddFactorSetDepartureLineFormProps {
    departureLineId?: number;
    factorSetDepartureId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set departure line'),
    add: LanguageUtils.createMessage('Add factor set departure line')
};

export default function AddFactorSetDepartureLineForm(props: IAddFactorSetDepartureLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { departureLineId, factorSetDepartureId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [departureLine, setDepartureLine] = React.useState<FactorSetDepartureLineOutput>({ factorSetId: factorSetDepartureId,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetDepartureLineOutput);
    const propsDepartureLine = useSelector(getFactorSetDepartureLine);
    const propsIsUpdatingDepartureLine = useSelector(isUpdatingDepartureLine);
    const prevIsUpdatingDepartureLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingDepartureLine);
    const propsIsCreatingDepartureLine = useSelector(isCreatingDepartureLine);
    const prevIsCreatingDepartureLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingDepartureLine);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingDepartureLine === true && !propsIsUpdatingDepartureLine) || (prevIsCreatingDepartureLine === true && !propsIsCreatingDepartureLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingDepartureLine, propsIsUpdatingDepartureLine]);
    
    useEffect(() => {
        if(departureLineId) {
            dispatch(fetchFactorSetDepartureLineById(departureLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsDepartureLine.id) {
            setDepartureLine(propsDepartureLine);
        }

    }, [propsIsUpdatingDepartureLine, propsDepartureLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...departureLine } as FactorSetDepartureLineOutput;
        (newLine as any)[attribute] = value;

        setDepartureLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <DepartureLineFormStep1 onChange={onChange} departureLine={departureLine} factorSetVersionId={factorSetVersionId} />,
            validationFn: () => FactorSetDepartureLineStep1Validation.validateFactorSetForm(departureLine),
            onNext: () => {
                if(!departureLine.id) {
                    dispatch(createDepartureVersionLine(departureLine));
                }
                else {
                    dispatch(updateFactorSetDepartureLine(departureLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddLine:
                return departureLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/departure/${factorSetDepartureId}/${departureLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/departure/${factorSetDepartureId}/latest/lines`;
            case FormType.EditLine:
                return departureLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/departure/${factorSetDepartureId}/${departureLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/departure/${factorSetDepartureId}/latest/lines`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  