import { List } from 'immutable';
import { ensure } from '../../../../utils/arrayUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationModel from '../../../../utils/validationModel';
import ValidationUtils from '../../../../utils/validationUtils';
import GtsOrder from '../../../../interfaces/output/gtsOrder';

const messages = {
    invalidDateFormat: LanguageUtils.createMessage('Invalid date format')
};

export default class AddGtsOrderStep1Validation extends ValidationUtils {

    static validateAddGtsOrderStep1Form(gtsOrder: GtsOrder): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const {
            orderDate
        } = gtsOrder;

        const formatedDate = this.validateDateFormat(orderDate);
        if(!formatedDate) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDateFormat);
        }

        return validationModel;
    }
}
