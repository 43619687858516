import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import UserOut from '../../../interfaces/output/userOut';
import { createUser, fetchUserById, getIsLoadingUser, getUser, isCreatingUser, isUpdatingUser, updateUser } from '../../../reducers/userReducer';
import UserFormStep1 from './userFormStep1';
import UserStep1Validation from './validations/userStep1Validation';

interface IUserFormProps {
    userId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit user'),
    add: LanguageUtils.createMessage('Add user')
};

export default function UserForm(props: IUserFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { userId, steps, type } = props;
    const [user, setUser] = React.useState<UserOut>({ id: userId } as UserOut);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsUser = useSelector(getUser);
    const propsIsUpdatingUser = useSelector(isUpdatingUser);
    const prevIsUpdatingUser = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingUser);
    const propsIsCreatingUser = useSelector(isCreatingUser);
    const prevIsCreatingnUser = WorkflowUtils.usePrevious<boolean>(propsIsCreatingUser);
    const propsIsLoadingUser = useSelector(getIsLoadingUser);
    const prevIsLoadingUser = WorkflowUtils.usePrevious<boolean>(propsIsLoadingUser);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingUser === true && !propsIsUpdatingUser) || (prevIsCreatingnUser && !propsIsLoadingUser)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingUser, propsIsCreatingUser]);

    useEffect(() => {
        if(userId) {
            dispatch(fetchUserById(userId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingUser && !propsIsLoadingUser) {
            setUser(propsUser);
        }

    }, [propsIsLoadingUser]);

    const onChange = (attribute: string, value: any) => {
        const newUser = { ...user } as UserOut;
        (newUser as any)[attribute] = value;

        setUser(newUser);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <UserFormStep1 onChange={onChange} user={user} />,
            validationFn: () => UserStep1Validation.validateUserForm(user),
            onNext: () => {
                if(!user.id) {
                    dispatch(createUser(user));
                }
                else {
                    dispatch(updateUser(user));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.USERS}`;

            case FormType.Edit:
                return `/${UrlConstants.USERS}/${user.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  