import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetWeightLineOutput from '../interfaces/output/factorSetWeightLineOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import WeightLineFilters from '../components/factorSets/weight/line/weightLineFilters';

export type WeightLineState = {
    factorSetWeightLine: FactorSetWeightLineOutput;
    factorSetVersionLines: Pagination<FactorSetWeightLineOutput>;
    isLoadingWeightLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: WeightLineState = {
    factorSetWeightLine: {} as FactorSetWeightLineOutput,
    factorSetVersionLines: { content: [] as Array<FactorSetWeightLineOutput> } as Pagination<FactorSetWeightLineOutput>,
    isLoadingWeightLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const weightLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetWeightLineById: (state: WeightLineState, _action: PayloadAction<number>) => {
            state.isLoadingWeightLine = true;
        },
        fetchFactorSetWeightLineByIdSuccess: (state: WeightLineState, action: PayloadAction<FactorSetWeightLineOutput>) => {
            state.isLoadingWeightLine = false;
            state.factorSetWeightLine = action.payload;
        },
        fetchFactorSetWeightLineByIdError: (state: WeightLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingWeightLine = false;
            state.error = action.payload;
        },
        fetchWeightVersionLines: (state: WeightLineState, _action: PayloadAction<{paging: Paging, filters: WeightLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchWeightVersionLinesSuccess: (state: WeightLineState, action: PayloadAction<Pagination<FactorSetWeightLineOutput>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchWeightVersionLinesError: (state: WeightLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createWeightVersionLine: (state: WeightLineState, _action: PayloadAction<FactorSetWeightLineOutput>) => {
            state.isCreating = true;
        },
        createWeightVersionLineSuccess: (state: WeightLineState, action: PayloadAction<FactorSetWeightLineOutput>) => {
            state.isCreating = false;
            state.factorSetWeightLine = action.payload;
        },
        createWeightVersionLineError: (state: WeightLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetWeightLine: (state: WeightLineState, _action: PayloadAction<FactorSetWeightLineOutput>) => {
            state.isUpdating = true;
        },
        updateFactorSetWeightLineSuccess: (state: WeightLineState, action: PayloadAction<FactorSetWeightLineOutput>) => {
            state.isUpdating = false;
            state.factorSetWeightLine = action.payload;
        },
        updateFactorSetWeightLineError: (state: WeightLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetWeightLine: (state: WeightLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetWeightLineSuccess: (state: WeightLineState, action: PayloadAction<FactorSetWeightLineOutput>) => {
            state.isDeleting = false;
            state.factorSetWeightLine = action.payload;
        },
        deactivateFactorSetWeightLineError: (state: WeightLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetWeightLine: (state: WeightLineState) => {
            state.factorSetWeightLine = initialState.factorSetWeightLine;
        },
        resetWeightVersionLines: (state: WeightLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetWeightLineById, resetWeightVersionLines, fetchFactorSetWeightLineByIdSuccess, fetchFactorSetWeightLineByIdError,
    updateFactorSetWeightLine, updateFactorSetWeightLineSuccess, updateFactorSetWeightLineError, deactivateFactorSetWeightLine, deactivateFactorSetWeightLineError,
    deactivateFactorSetWeightLineSuccess, fetchWeightVersionLines, fetchWeightVersionLinesSuccess, fetchWeightVersionLinesError, createWeightVersionLine,
    createWeightVersionLineSuccess, createWeightVersionLineError, resetFactorSetWeightLine } = weightLineSlice.actions;

export const isLoadingWeightLine = (state: RootState): boolean => state.weightLineReducer.isLoadingWeightLine;
export const isLoadingFactorSetWeightLines = (state: RootState): boolean => state.weightLineReducer.isLoadingLines;
export const isUpdatingWeightLine = (state: RootState): boolean => state.weightLineReducer.isUpdating;
export const isDeletingWeightLine = (state: RootState): boolean => state.weightLineReducer.isDeleting;
export const getFactorSetWeightLine = (state: RootState): FactorSetWeightLineOutput => state.weightLineReducer.factorSetWeightLine;
export const getWeightLines = (state: RootState): Pagination<FactorSetWeightLineOutput> => state.weightLineReducer.factorSetVersionLines;
export const isCreatingWeightLine = (state: RootState): boolean => state.weightLineReducer.isCreating;

export default weightLineSlice.reducer;