import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import { fetchGTSOrderUnitServicesById, getGtsOrderUnitService, isLoadingUnitService } from '../../reducers/gtsUnitServiceReducer';
import GTSOrderUnitService from '../../interfaces/output/gtsOrderUnitService';
import ViewGtsOrderUnitService from './viewUnitService';
import LanguageUtils from '../../utils/LanguageUtils';
import { fetchGtsOrderById, getGtsOrder, isLoadingGtsOrder } from '../../reducers/gtsOrderReducer';
import GtsOrder from '../../interfaces/output/gtsOrder';

interface IUnitServiceDetailsProps {
    classes: any;
    theme: Theme;
    fetchGTSOrderUnitServicesById: any;
    fetchGtsOrderById: any;
    gtsOrderUnitService: GTSOrderUnitService;
    gtsOrder: GtsOrder;
    match: any;
    history: any;
    location: any;
    isLoadingUnitService: boolean;
    isLoadingGtsOrder: boolean;
}

interface IUnitServiceDetailsState {
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGTSOrderUnitServicesById: (id: number) => dispatch(fetchGTSOrderUnitServicesById(id)),
    fetchGtsOrderById: (orderId: number) => dispatch(fetchGtsOrderById(orderId))

});

const mapStoreToProps = (store: RootState) => {
    return {
        gtsOrderUnitService: getGtsOrderUnitService(store),
        isLoadingUnitService: isLoadingUnitService(store),
        isLoadingGtsOrder: isLoadingGtsOrder(store),
        gtsOrder: getGtsOrder(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    subname: LanguageUtils.createMessage('GTS_ORDER_UNIT_SERVICE_SUBNAME'),
    properties: LanguageUtils.createMessage('Properties')
};

class UnitServiceDetails extends Component<IUnitServiceDetailsProps, IUnitServiceDetailsState> {
    constructor(props: IUnitServiceDetailsProps) {
        super(props);

        this.state = {
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.GTS_UNIT_SERVICES}/${props.match.params.unitServiceId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.GTS_UNIT_SERVICES}/${props.match.params.unitServiceId}/properties`, props.location.pathname)
            }]),
            selectedRow: undefined
        };
    }

    componentDidMount() {
        const { fetchGTSOrderUnitServicesById } = this.props;
        const { unitServiceId } = this.props.match.params;

        fetchGTSOrderUnitServicesById(unitServiceId);
    }

    componentDidUpdate(prevProps: IUnitServiceDetailsProps) {
        const { gtsOrderUnitService, fetchGtsOrderById } = this.props;

        if(gtsOrderUnitService !== prevProps.gtsOrderUnitService) {
            if(gtsOrderUnitService.gtsOrderId) {
                fetchGtsOrderById(gtsOrderUnitService.gtsOrderId);
            }
        }
    }

    getSelectedMenuItem = (state: IUnitServiceDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { unitServiceId } = this.props.match.params;
        
        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.GTS_UNIT_SERVICES}/${unitServiceId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }
            
            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, gtsOrderUnitService, isLoadingUnitService, gtsOrder } = this.props;
        const { menuItems } = this.state;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingUnitService}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.GtsOrderUnitService}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    value: gtsOrderUnitService.orderNumber,
                    icon: <FontAwesomeIcon icon="dolly" size="1x" /> } as IMenuItem
                }
                routes={
                    [{
                        name: gtsOrder.orderNumber,
                        url: `${UrlConstants.GTS_ORDERS}/${gtsOrder.id}`
                    },{
                        name: gtsOrderUnitService.logicalUnitId,
                        url: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitService.gtsOrderUnitId}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.GTS_UNIT_SERVICES}/:unitServiceId/properties`}>
                            <ViewGtsOrderUnitService gtsOrderUnitService={gtsOrderUnitService} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(UnitServiceDetails));