import { List } from 'immutable';
import Product from '../../../interfaces/output/product';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class ProductStep1Validation extends ValidationUtils {

    static validateOrderForm(product: Product): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const { name_ENG
        } = product;

        const requiredFields =
            this.validateInputStringLength(name_ENG);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
