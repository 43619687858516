import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetProductState } from '../../reducers/productReducer';
import AddPricelistVersionProductForm from './addPricelistVersionProductForm';
import { FormType } from '../../constants/constants';
import { resetPriceListState } from '../../reducers/priceListReducer';

interface IAddPricelistVersionProductProps {
    classes: any;
    theme: Theme;
    match: any;
    resetProductState: any;
    resetPriceListState: any;
    steps: Array<number>;
    priceListId?: number;
    productId?: number;
    priceListVersionId?: number;
    id?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetPriceListState: () => dispatch(resetPriceListState()),
    resetProductState: () => dispatch(resetProductState())
});

const mapStoreToProps = (store: RootState, ownProps: IAddPricelistVersionProductProps) => {
    return {
        priceListId: ownProps.match.params?.priceListId ? Number(ownProps.match.params?.priceListId) : undefined,
        productId: ownProps.match.params?.productId ? Number(ownProps.match.params?.productId) : undefined,
        priceListVersionId: ownProps.match.params?.priceListVersionId ? Number(ownProps.match.params?.priceListVersionId) : undefined,
        id: ownProps.match.params?.id ? Number(ownProps.match.params?.id) : undefined
    };
};

class AddPricelistVersionProduct extends Component<IAddPricelistVersionProductProps> {
    componentWillUnmount() {
        const { resetPriceListState, resetProductState } = this.props;

        resetPriceListState();
        resetProductState();
    }

    render() {
        const { classes, steps, priceListId, priceListVersionId, productId, type, id } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddPricelistVersionProductForm priceListId={priceListId} productId={productId} steps={steps} type={type} id={id} priceListVersionId={priceListVersionId} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddPricelistVersionProduct));