import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivateCalendarVersionStatusError, deactivateCalendarVersionStatusSuccess, fetchCalendarVersionStatusByIdError, fetchCalendarVersionStatusByIdSuccess,
    fetchCalendarVersionStatusesError, fetchCalendarVersionStatusesSuccess, updateCalendarVersionStatusError, updateCalendarVersionStatusSuccess }
    from '../reducers/calendarVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchCalendarVersionStatus(action: PayloadAction<string>) {
    const calendarVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, calendarVersionStatusId);
    
        yield put(fetchCalendarVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalendarVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCalendarVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchCalendarVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalendarVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateCalendarVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const calendarVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, calendarVersionStatus);

        yield put(updateCalendarVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCalendarVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateCalendarVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const calendarVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, calendarVersionStatus);

        yield put(deactivateCalendarVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateCalendarVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('calendarVersionStatus/fetchCalendarVersionStatusById', doFetchCalendarVersionStatus),
    takeLatest('calendarVersionStatus/fetchCalendarVersionStatuses', doFetchCalendarVersionStatuses),
    takeLatest('calendarVersionStatus/updateCalendarVersionStatus', doUpdateCalendarVersionStatus),
    takeLatest('calendarVersionStatus/deactivateCalendarVersionStatus', doDeactivateCalendarVersionStatus)
];