import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerSegment from '../interfaces/output/customerSegment';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CustomerSegmentState = {
    segment: CustomerSegment;
    isLoading: boolean;
    segments: Pagination<CustomerSegment>;
    error: HttpErrorResponse | undefined;
};

const initialState: CustomerSegmentState = {
    segment: {} as CustomerSegment,
    segments: { content: [] as Array<CustomerSegment> } as Pagination<CustomerSegment>,
    isLoading: false,
    error: undefined
};

export const segmentSlice = createSlice({
    name: 'segment',

    initialState,
    reducers: {
        fetchSegments: (state: CustomerSegmentState) => {
            state.isLoading = true;
        },
        fetchSegmentsSuccess: (state: CustomerSegmentState, action: PayloadAction<Pagination<CustomerSegment>>) => {
            state.isLoading = false;
            state.segments = action.payload;
        },
        fetchSegmentsError: (state: CustomerSegmentState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchSegmentById: (state: CustomerSegmentState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchSegmentByIdSuccess: (state: CustomerSegmentState, action: PayloadAction<CustomerSegment>) => {
            state.isLoading = false;
            state.segment = action.payload;
        },
        fetchSegmentByIdError: (state: CustomerSegmentState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchSegments, fetchSegmentsSuccess, fetchSegmentsError, fetchSegmentById, fetchSegmentByIdSuccess, fetchSegmentByIdError } = segmentSlice.actions;

export const isLoadingSegment = (state: RootState): boolean => state.segmentReducer.isLoading;
export const getSegments = (state: RootState): Pagination<CustomerSegment> => state.segmentReducer.segments;
export const getSegment = (state: RootState): CustomerSegment => state.segmentReducer.segment;

export default segmentSlice.reducer;