import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import UrlConstants from '../../constants/UrlConstants';
import UserFilters from '../../interfaces/filters/userFilters';
import { fetchTerminalsWithFilters, getTerminals, isLoadingTerminal, resetTerminal } from '../../reducers/terminalReducer';
import Terminal from '../../interfaces/output/terminal';
import TerminalFilters from '../../interfaces/filters/terminaleFilters';
import TerminalFilterComponent from './terminalFilterComponent';
import TerminalList from './terminalList';
interface ITerminalsProps {
    terminals: Pagination<Terminal>;
    classes: any;
    fetchTerminals : any;
    isLoading: boolean;
    onChange: () => void;
    resetTerminal: () => void;
    fetchImportExportCodes: any;
}

interface ITerminalsState {
    filters: TerminalFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    terminals: LanguageUtils.createMessage('Terminals'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchTerminals: (paging: Paging, filters: UserFilters) => dispatch(fetchTerminalsWithFilters({
        paging,
        filters
    })),
    // fetchUserStatus: (paging: Paging) => dispatch(fetchUserStatuses({ paging })),
    resetTerminal: () => dispatch(resetTerminal())

});

const mapStoreToProps = (store: RootState) => {
    return {
        terminals: getTerminals(store),
        isLoading: isLoadingTerminal(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class Terminals extends Component<ITerminalsProps, ITerminalsState> {
    terminalsListRef: any;
    constructor(props: ITerminalsProps) {
        super(props);
        this.terminalsListRef = React.createRef();

        this.state = {
            filters: {} as TerminalFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.terminals,
                icon: <FontAwesomeIcon icon="train" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchTerminals } = this.props;
        const { paging, filters } = this.state;

        fetchTerminals(paging, filters);
    }

    componentWillUnmount() {
        const { resetTerminal } = this.props;
        resetTerminal();
    }

    applyFilters = () => {
        const { fetchTerminals } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchTerminals(newPagination, filters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as TerminalFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchTerminals } = this.props;
        const { paging } = this.state;

        const newFilters = {
        } as TerminalFilters;

        const newSortModel = [{
            field: 'id',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchTerminals(newPagination, newFilters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchTerminals } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchTerminals(newPaging, filters);

        }
    }

    render() {
        const { classes, terminals, fetchTerminals, isLoading } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <TerminalFilterComponent
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.TERMINALS}/add`
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <TerminalList rows={terminals} isLoading={isLoading}
                                    ref={this.terminalsListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchTerminals(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Terminals));