import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import Terminal from '../../interfaces/output/terminal';
import ProductArenaList from '../productArenas/productArenaList';
import { fetchProductArenas, getProductArenas, isLoadingProductArenas } from '../../reducers/productArenaReducer';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import Paging from '../../interfaces/common/paging';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface IViewSurchargeRateProps {
    classes?: any;
    theme?: Theme;
    terminal: Terminal;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Terminal details'),
    name: LanguageUtils.createMessage('Name'),
    gtsTerminalNo: LanguageUtils.createMessage('Gts terminal number'),
    gtsTerminalCode: LanguageUtils.createMessage('Gts terminal code'),
    country: LanguageUtils.createMessage('Country'),
    operator: LanguageUtils.createMessage('Terminal operator'),
    distributionResponsabilityCenter: LanguageUtils.createMessage('Distribution responsability center'),
    terminalResponsabilityCenter: LanguageUtils.createMessage('Terminal responsability center'),
    depotService: LanguageUtils.createMessage('Depot service'),
    clusterTerminaId: LanguageUtils.createMessage('Cluster terminal id'),
    priceListTerminal: LanguageUtils.createMessage('Price list terminal'),
    productArenas: LanguageUtils.createMessage('Product Arenas')
};

export default function ViewTerminal(props: IViewSurchargeRateProps): JSX.Element {
    const classes = styles();
    const { terminal } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isLoadingProductArenas)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const propsProductArenas = useSelector(getProductArenas);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: 'name',
        sort: 'asc' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        if(terminal.id) {
            dispatch(fetchProductArenas({
                paging: paging,
                filters: {
                    terminalId: terminal.id
                }
            }));
        }
    }, [terminal.id]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };

            setPaging(newPagination);
            setSortModel(newModel);

            dispatch(fetchProductArenas({
                filters: {
                    terminalId: terminal.id
                },
                paging: newPagination
            }));
        }
    };
    
    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.name} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.gtsTerminalNo} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.gtsTerminalNo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.gtsTerminalCode} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.gtsTerminalCode || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.country} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.country?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.operator} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.operator?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.terminalResponsabilityCenter} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.terminalResponsabilityCenter?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.distributionResponsabilityCenter} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.distributionResponsabilityCenter?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.depotService} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.depotServices?.toString() || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.clusterTerminaId} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.clusterTerminalId || '-'}</Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priceListTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography gutterBottom>{terminal.pricelistTerminalId?.terminalName || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.productArenas} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <ProductArenaList isLoading={isLoading} rows={propsProductArenas}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        dispatch(fetchProductArenas({
                                            filters: {
                                                terminalId: terminal.id
                                            },
                                            paging: newPage
                                        }));
                                        setPaging(newPage);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}