import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import PageUtils from '../../../utils/pageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import UserOut from '../../../interfaces/output/userOut';
import { getIsLoadingUser, isCreatingUser, isUpdatingUser } from '../../../reducers/userReducer';
import { fetchUserStatuses, getUserStatuses, isLoadingUserStatuses } from '../../../reducers/userStatusReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import UserStatusOut from '../../../interfaces/output/userStatusOut';
// import { fetchSellers, getSellers } from '../../../reducers/sellerReducer';
import UserStep1Validation from './validations/userStep1Validation';
import { fetchUserRoles, getUserRoles, isLoadingUserRoles } from '../../../reducers/userRoleReducer';
import { Grid } from '@mui/material';
import RoleOut from '../../../interfaces/output/roleOut';

interface IUserStep1Props {
    user: UserOut;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    NAME: 'name',
    SSO_ID: 'ssoId',
    STATUS: 'status',
    ROLES: 'roles',
    SELLER: 'seller'

};

const messages = {
    name: LanguageUtils.createMessage('Name'),
    ssoId: LanguageUtils.createMessage('Sso id'),
    status: LanguageUtils.createMessage('Status'),
    sellerId: LanguageUtils.createMessage('Seller'),
    roles: LanguageUtils.createMessage('Roles')
};

const UserFormStep1 = forwardRef((props: IUserStep1Props, ref: any): JSX.Element => {
    const { user, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingUser), useSelector(getIsLoadingUser), useSelector(isLoadingUserStatuses),
        useSelector(isUpdatingUser), useSelector(isLoadingUserRoles)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const userStatuses = useSelector(getUserStatuses).content;
    // const sellers = useSelector(getSellers).content;
    const userRoles = useSelector(getUserRoles).content;
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchUserStatuses({ paging }));
        // dispatch(fetchSellers({ paging }));
        dispatch(fetchUserRoles({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.NAME: {
                isValid = UserStep1Validation.validateInputString(user.name);
                break;
            }
            case ATTRIBUTES.SSO_ID: {
                isValid = UserStep1Validation.validateInputString(user.ssoId) &&
                UserStep1Validation.validateEmail(user.ssoId);
                break;
            }
            case ATTRIBUTES.STATUS: {
                isValid = UserStep1Validation.validateInputNumber(user.status?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="name"
                        label={messages.name}
                        value={user.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="ssoId"
                        label={messages.ssoId}
                        value={user.ssoId}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.SSO_ID)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<UserStatusOut>
                        options={userStatuses}
                        value={user.status}
                        onChange={(obj: UserStatusOut | null) => onChange('status', obj)}
                        placeholder={messages.status}
                        error={setValidationState(ATTRIBUTES.STATUS)}
                        required
                    />
                </Grid>
                {/* <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<Seller>
                        options={sellers}
                        value={user.seller}
                        onChange={(obj: Seller | null) => onChange('seller', obj)}
                        placeholder={messages.sellerId}
                        error={setValidationState(ATTRIBUTES.STATUS)}
                    />
                </Grid> */}
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs>
                    <GenericAutocomplete<RoleOut>
                        options={userRoles}
                        value={user?.userRolesIds}
                        onMultipleChange={(obj: RoleOut[]) => onChange('userRolesIds', obj.map(o => o.id))}
                        placeholder={messages.roles}
                        compareFn={(o: RoleOut, id: number) => o.id === id}
                        getOptionLabel={(option: RoleOut) => option.name || ''}
                        multiple
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader >
    );
});

export default UserFormStep1;