import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';

export type ErrorState = {
    error: HttpErrorResponse | undefined;
};

const initialState: ErrorState = {
    error: undefined
};

export const errorSlice = createSlice({
    name: 'error',

    initialState,
    reducers: {
        throwError: (state: ErrorState, action: PayloadAction<HttpErrorResponse>) => {
            state.error = action.payload;
        },
        hideError: (state: ErrorState) => {
            state.error = undefined;
        }
    }
});

export const { throwError, hideError } = errorSlice.actions;

export const showError = (state: RootState): boolean => !!state.errorReducer.error;
export const getError = (state: RootState): HttpErrorResponse | undefined => state.errorReducer.error;

export default errorSlice.reducer;