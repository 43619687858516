import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceFormatApi from '../api/invoiceFormatApi';
import InvoiceFormatOutput from '../interfaces/output/invoiceFormatOutput';
import { fetchInvoiceFormatByIdError, fetchInvoiceFormatByIdSuccess, fetchInvoiceFormatsError, fetchInvoiceFormatsSuccess } from '../reducers/invoiceFormatReducer';

function* doFetchSeller(action: PayloadAction<string>) {
    const sellerId = action.payload;
    try {
        const response: InvoiceFormatOutput = yield call(InvoiceFormatApi.fetchInvoiceFormatById, sellerId);
        yield put(fetchInvoiceFormatByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceFormatByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSellers(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<InvoiceFormatOutput> = yield call(InvoiceFormatApi.fetchInvoiceFormats, paging);
        yield put(fetchInvoiceFormatsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceFormatsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoiceFormat/fetchInvoiceFormatById', doFetchSeller),
    takeLatest('invoiceFormat/fetchInvoiceFormats', doFetchSellers)];

