import React, { useEffect } from 'react';
import { FormControl, Button, Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import ProductType from '../../interfaces/output/productType';
import WeightFilters from './weight/weightFilters';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import { fetchProductTypes, getProductTypes, isLoadingProductTypes } from '../../reducers/productTypesReducer';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import TableUtils from '../../utils/tableUtils';
import UnitGroup from '../../interfaces/output/unitGroup';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../reducers/unitGroupReducer';
import ProductArena from '../../interfaces/output/productArena';
import { fetchProductArenas, getProductArenas, isLoadingProductArenas } from '../../reducers/productArenaReducer';
import GenericTextField from '../common/widgets/genericTextField';
import UnitSubGroup from '../../interfaces/output/unitSubGroup';
// import FactorSetFiltersValidation from './validations/filterValidations';

const messages = {
    name: LanguageUtils.createMessage('Factor set name'),
    productType: LanguageUtils.createMessage('Product type'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    productArena: LanguageUtils.createMessage('Product arena'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

interface IFilterProps {
    filters: WeightFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    hiddenColumns?: Array<string>;
}

export default function FactorSetsFiltersComponent(props: IFilterProps): JSX.Element {
    const { filters, applyFilters, hiddenColumns } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();

    const productTypes = useSelector(getProductTypes).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const unitSubGroups = useSelector(getUnitSubGroups).content;
    const productArenas = useSelector(getProductArenas).content;
    const propsIsLoadingSubGroups = useSelector(isLoadingUnitSubgroups);

    useEffect(() => {
        dispatch(fetchProductTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchProductArenas({ paging }));
    }, [dispatch]);

    const onChange = (attribute: string, value: any) => {
        if(attribute === 'unitGroupId') {
            if(value !== undefined) {
                dispatch(fetchUnitGroupSubgroups({
                    groupId: value,
                    paging
                }));
            }
            else {
                dispatch(resetSubgroups());
            }
        }
        props.onChange(attribute, value);
    };

    const setDefaultState = () => {
        dispatch(resetSubgroups());

        props.setDefaultState();
    };
    
    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            // error={FactorSetFiltersValidation.validateInputString(filters.name)}
                            />
                        </FormControl>
                    </Grid>
                    {!TableUtils.hideColumn('productType', hiddenColumns) &&
                        <Grid item xs>
                            <GenericAutocomplete<ProductType>
                                options={productTypes}
                                value={filters.productTypeId}
                                onChange={(obj: ProductType | null) => onChange('productTypeId', obj?.id)}
                                placeholder={messages.productType}
                                compareFn={(o: ProductType) => o.id === filters.productTypeId}
                                isLoading={useSelector(isLoadingProductTypes)}
                            />
                        </Grid>
                    }
                    <Grid item xs>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={filters.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroupId', obj?.id)}
                            placeholder={messages.unitGroup}
                            compareFn={(o: UnitGroup) => o.id === filters.unitGroupId}
                            isLoading={useSelector(isLoadingUnitGroups)}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitSubGroup>
                            options={unitSubGroups}
                            value={filters.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitSubGroupId', obj?.id)}
                            placeholder={messages.subgroup}
                            isLoading={propsIsLoadingSubGroups}
                            compareFn={(o: UnitSubGroup) => o.id === filters.unitSubGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericAutocomplete<ProductArena>
                                options={productArenas}
                                value={filters.productArenaId}
                                onChange={(obj: ProductArena | null) => onChange('productArenaId', obj?.id)}
                                placeholder={messages.productArena}
                                compareFn={(o: ProductArena) => o.id === filters.productArenaId}
                                isLoading={useSelector(isLoadingProductArenas)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs alignSelf={'flex-end'}>
                        <ToggleButtonGroup
                            color="primary"
                            value={filters.default}
                            exclusive
                            onChange={(e, value: any) => onChange('default', value)}
                        >
                            <ToggleButton size="small" value={true}>Default</ToggleButton>
                            <ToggleButton size="small" value={false}>Not Default</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}