import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import SurchargeRateUnit from '../interfaces/output/surchargeRateUnit';
import SurchargeRateUnitApi from '../api/surchargeRateUnit';
import { fetchSurchargeRateUnitByIdError, fetchSurchargeRateUnitByIdSuccess, fetchSurchargeRateUnitsError, fetchSurchargeRateUnitsSuccess } from '../reducers/surchargeRateUnitReducer';
import Paging from '../interfaces/common/paging';

function* doFetchSurchargeRateUnit(action: PayloadAction<string>) {
    const surchargeRateUnitId = action.payload;
    try {
        const response: SurchargeRateUnit = yield call(SurchargeRateUnitApi.fetchSurchargeRateUnitById, surchargeRateUnitId);
        yield put(fetchSurchargeRateUnitByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeRateUnitByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSurchargeRateUnits(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;
    try {
        const response: Pagination<SurchargeRateUnit> = yield call(SurchargeRateUnitApi.fetchSurchargeRateUnits, paging);
        yield put(fetchSurchargeRateUnitsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeRateUnitsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('surchargeRateUnit/fetchSurchargeRateUnitById', doFetchSurchargeRateUnit),
    takeLatest('surchargeRateUnit/fetchSurchargeRateUnits', doFetchSurchargeRateUnits)
];

