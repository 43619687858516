import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import GTSOrderUnit from '../interfaces/output/gtsOrderUnit';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import GTSOrderUnitService from '../interfaces/output/gtsOrderUnitService';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type GtsUnitServiceState = {
    unitService: GTSOrderUnitService;
    unitServices: Pagination<GTSOrderUnitService>;
    isLoadingUnitServices: boolean;
    isLoadingUnitService: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: GtsUnitServiceState = {
    unitService: {} as GTSOrderUnitService,
    unitServices: { content: [] as Array<GTSOrderUnitService> } as Pagination<GTSOrderUnitService>,
    isLoadingUnitServices: false,
    isLoadingUnitService: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const unitServiceSlice = createSlice({
    name: 'unitService',

    initialState,
    reducers: {
        fetchGTSOrderUnitServices: (state: GtsUnitServiceState, _action: PayloadAction<{paging: Paging, filters: GtsOrderUnitFilters}>) => {
            state.isLoadingUnitServices = true;
        },
        fetchGTSOrderUnitServicesSuccess: (state: GtsUnitServiceState, action: PayloadAction<Pagination<GTSOrderUnitService>>) => {
            state.isLoadingUnitServices = false;
            state.unitServices = action.payload;
        },
        fetchGTSOrderUnitServicesError: (state: GtsUnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitServices = false;
            state.error = action.payload;
        },
        fetchGTSOrderUnitServicesById: (state: GtsUnitServiceState, _action: PayloadAction<number>) => {
            state.isLoadingUnitService = true;
        },
        fetchGTSOrderUnitServicesByIdSuccess: (state: GtsUnitServiceState, action: PayloadAction<GTSOrderUnitService>) => {
            state.isLoadingUnitService = false;
            state.unitService = action.payload;
        },
        fetchGTSOrderUnitServicesByIdError: (state: GtsUnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitService = false;
            state.error = action.payload;
        },
        updateGTSOrderUnitServices: (state: GtsUnitServiceState, _action: PayloadAction<GTSOrderUnitService>) => {
            state.isUpdating = true;
        },
        updateGTSOrderUnitServicesSuccess: (state: GtsUnitServiceState, action: PayloadAction<GTSOrderUnitService>) => {
            state.isUpdating = false;
            state.unitService = action.payload;
        },
        updateGTSOrderUnitServicesError: (state: GtsUnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateGtsOrderUnitServices: (state: GtsUnitServiceState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateGtsOrderUnitServicesSuccess: (state: GtsUnitServiceState, action: PayloadAction<GTSOrderUnitService>) => {
            state.isDeleted = false;
            state.unitService = action.payload;
        },
        deactivateGtsOrderUnitServicesError: (state: GtsUnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetGTSOrderUnitServices: (state: GtsUnitServiceState) => {
            state.unitServices = initialState.unitServices;
        },
        resetGTSOrderUnitService: (state: GtsUnitServiceState) => {
            state.unitService = initialState.unitService;
        }
    }
});

export const { fetchGTSOrderUnitServices, fetchGTSOrderUnitServicesSuccess, fetchGTSOrderUnitServicesError, fetchGTSOrderUnitServicesById, deactivateGtsOrderUnitServices,
    fetchGTSOrderUnitServicesByIdError, fetchGTSOrderUnitServicesByIdSuccess, updateGTSOrderUnitServices, updateGTSOrderUnitServicesSuccess, deactivateGtsOrderUnitServicesSuccess,
    updateGTSOrderUnitServicesError, resetGTSOrderUnitService, resetGTSOrderUnitServices, deactivateGtsOrderUnitServicesError } = unitServiceSlice.actions;

export const isLoadingUnitServices = (state: RootState): boolean => state.gtsUnitServiceReducer.isLoadingUnitServices;
export const isLoadingUnitService = (state: RootState): boolean => state.gtsUnitServiceReducer.isLoadingUnitService;
export const getGtsOrderUnitServices = (state: RootState): Pagination<GTSOrderUnitService> => state.gtsUnitServiceReducer.unitServices;
export const getGtsOrderUnitService = (state: RootState): GTSOrderUnitService => state.gtsUnitServiceReducer.unitService;
export const isUpdatingGtsOrderUnitService = (state: RootState): boolean => state.gtsUnitServiceReducer.isUpdating;
export const isDeletingGtsOrderUnitService = (state: RootState): boolean => state.gtsOrderUnitReducer.isDeleted;

export default unitServiceSlice.reducer;