import React, { useEffect } from 'react';
import AddFactorSetCalendarStep1 from './addFactorSetCalendarStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createFactorSetCalendar, createCalendarVersion, fetchFactorSetCalendarById, getFactorSetCalendar, isCreatingCalendar, isCreatingVersion, isUpdatingCalendar,
    updateFactorSetCalendar } from '../../../../reducers/factorSetCalendarReducer';
import { cloneCalendarVersion, isCloningVersion, isUpdatingCalendarVersionStatus,
    isUpdatingVersion, updateCalendarVersion,
    updateCalendarVersionStatus } from '../../../../reducers/factorSetCalendarVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddCalendarStep2 from './addFactorSetCalendarStep2';
import AddCalendarVersionStep3 from './addFactorSetCalendarStep3';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';

interface IAddFactorSetFormProps {
    factorSetCalendarId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set calendar'),
    add: LanguageUtils.createMessage('Add factor set calendar'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetCalendarForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetCalendarId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetCalendarId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetCalendar = useSelector(getFactorSetCalendar);
    const prevFactorSetCalendar = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetCalendar);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingCalendar);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingCalendar);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingCalendarVersion = useSelector(isCreatingVersion);
    const prevIsCreatingCalendarVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCalendarVersion);
    const propsIsUpdatingCalendarVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingCalendarVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCalendarVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingCalendarStatus = useSelector(isUpdatingCalendarVersionStatus);
    const prevIsUpdatingCalendarStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCalendarStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingCalendarVersion === true && !propsIsCreatingCalendarVersion || prevIsUpdatingCalendarVersion === true && !propsIsUpdatingCalendarVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingCalendarStatus === true && !propsIsUpdatingCalendarStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingCalendarStatus, propsIsUpdatingCalendarVersion, propsIsCreatingCalendarVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetCalendarId) {
            dispatch(fetchFactorSetCalendarById({ factorSetId: factorSetCalendarId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetCalendar && propsFactorSetCalendar.id) {
            setFactorSet(propsFactorSetCalendar);
        }
    }, [propsFactorSetCalendar.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newCalendarVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newCalendarVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newCalendarVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetCalendarStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetCalendar(factorSet));
                }
                else {
                    dispatch(updateFactorSetCalendar(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddCalendarStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createCalendarVersion(factorSet));
                }
                else {
                    dispatch(updateCalendarVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.Calendar} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(cloneCalendarVersion({ toVersionId: ensure(propsFactorSetCalendar.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddCalendarVersionStep3 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updateCalendarVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/calendar/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/calendar/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/calendar/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/calendar/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/calendar/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/calendar/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  