import { http } from '../utils/http';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import GTSOrderUnitDangerousGoods from '../interfaces/output/gtsOrderUnitDangerousGoods';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class GTSOrderUnitDangerousGoodsApi {
    static fetchGtsOrderUnitDangerousGoods = async(dangerousGoodsId: string): Promise<GTSOrderUnitDangerousGoods> => {
        const { data } = await http.get<GTSOrderUnitDangerousGoods>(`staginggtsorderunitdangerousgoodslines/${dangerousGoodsId}`);

        return data;
    };

    static fetchGtsOrderUnitsDangerousGoods = async(paging: Paging, filters: GtsOrderUnitFilters): Promise<Array<GTSOrderUnitDangerousGoods>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/staginggtsorderunitdangerousgoodslines');

        const { data } = await http.get<Array<GTSOrderUnitDangerousGoods>>(url);

        return data;
    };

    static updateGtsOrderUnitDangerousGoods = async(dangerousGoods: GTSOrderUnitDangerousGoods): Promise<GTSOrderUnitDangerousGoods> => {
        const newGood = {
            uunr: dangerousGoods.uunr,
            grossAmount: dangerousGoods.grossAmount,
            netAmmount: dangerousGoods.netAmmount,
            measuringUnit: dangerousGoods.measuringUnit,
            dangerousGoodsCode: dangerousGoods.dangerousGoodsCode,
            packagingGroup: dangerousGoods.packagingGroup
        };
        
        const { data } = await http.put<any, GTSOrderUnitDangerousGoods>(`staginggtsorderunitdangerousgoodslines/${dangerousGoods.id}`, newGood);

        return data;
    }

    static deactivateGtsOrderUnitDangerousGoods = async(unitId: number): Promise<GTSOrderUnitDangerousGoods> => {

        const { data } = await http.delete<GTSOrderUnitDangerousGoods>(`staginggtsorderunitdangerousgoodslines/${unitId}`);

        return data;
    }
}
