import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import DangerousGoodsGroup from '../interfaces/output/dangerousGoodsGroup';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class DangerousGoodsGroupApi {
    static fetchDangerousGoodsGroup = async(dangerousGoodsId: string): Promise<DangerousGoodsGroup> => {
        const { data } = await http.get<DangerousGoodsGroup>(`dangerousgoodsgroups/${dangerousGoodsId}`);

        return data;
    };

    static fetchDangerousGoodsGroups = async(paging: Paging): Promise<Array<DangerousGoodsGroup>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/dangerousgoodsgroups');

        const { data } = await http.get<Array<DangerousGoodsGroup>>(url);

        return data;
    };

    static updateDangerousGoodsGroup = async(dangerousGoods: DangerousGoodsGroup): Promise<DangerousGoodsGroup> => {
        const newGood = {
            code: dangerousGoods.code,
            name: dangerousGoods.name
        };
        
        const { data } = await http.put<any, DangerousGoodsGroup>(`dangerousgoodsgroups/${dangerousGoods.id}`, newGood);

        return data;
    }
}
