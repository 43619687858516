import React, { forwardRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Grid, FormControl, Autocomplete, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import Loader from '../common/widgets/loader';
import { fetchCustomerById, fetchCustomers, getCustomer, getCustomers, isLoadingCustomer, isLoadingCustomers, resetCustomers } from '../../reducers/customerReducer';
import { fetchInvoiceTypes, getInvoiceTypes, isLoadingInvoiceTypes } from '../../reducers/invoiceTypeReducer';
import LanguageUtils from '../../utils/LanguageUtils';
import Order from '../../interfaces/output/order';
import { isCreatingOrder, isLoadingOrder, isUpdatingOrder } from '../../reducers/orderReducer';
import CustomerFilters from '../../interfaces/output/filters';
import { fetchUnitGroups, getUnitGroups, isLoadingUnitGroups } from '../../reducers/unitGroupReducer';
import Customer from '../../interfaces/output/customer';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import UnitGroup from '../../interfaces/output/unitGroup';
import InvoiceType from '../../interfaces/output/invoiceType';
import GenericTextField from '../common/widgets/genericTextField';
import GenericDatePicker from '../common/widgets/genericDatePicker';
// import OrderStatus from '../../interfaces/output/orderStatus';
import OrderStepValidation from '../orders/validations/orderStepValidation';
import { fetchOrderStatuses } from '../../reducers/orderStatusReducer';
import { fetchTerminals, getTerminals, isLoadingTerminal } from '../../reducers/terminalReducer';
import Terminal from '../../interfaces/output/terminal';

interface AddOrderStep1Props {
    order: Order;
    onChange: any;
    defaultCustomer: boolean;
    showValidationError?: boolean
}

const messages = {
    customer: LanguageUtils.createMessage('Customer'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    status: LanguageUtils.createMessage('Status'),
    orderNumber: LanguageUtils.createMessage('Order number'),
    orderDate: LanguageUtils.createMessage('Order date'),
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to')
};

const ATTRIBUTES = {
    ORDER_NUMBER: 'orderNumber'
};

const AddOrderStep1 = forwardRef((props: AddOrderStep1Props, ref: any): JSX.Element => {
    const { order, onChange, defaultCustomer, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const intl = useIntl();
    const dispatch = useDispatch();
    const terminals = useSelector(getTerminals).content;
    const customers = useSelector(getCustomers).content;
    const [inputValue, setInputValue] = React.useState('');
    const invoiceTypes = useSelector(getInvoiceTypes).content;
    //const statuses = useSelector(getOrderStatuses).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const propsIsLoadingCustomer = useSelector(isLoadingCustomer);
    const isLoadingObjects = [useSelector(isCreatingOrder), useSelector(isLoadingOrder), useSelector(isLoadingInvoiceTypes),
        useSelector(isUpdatingOrder), useSelector(isLoadingUnitGroups), useSelector(isLoadingCustomer),useSelector(isLoadingTerminal)];
    const customer = useSelector(getCustomer);
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
    const prevIsLoadingCustomer = WorkflowUtils.usePrevious<boolean>(propsIsLoadingCustomer);
    const customerFilters = {
        name: inputValue
    } as CustomerFilters;

    useEffect(() => {
        dispatch(fetchInvoiceTypes({ paging }));
        dispatch(fetchOrderStatuses({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchUnitGroups({ paging }));

        if(defaultCustomer && order?.customer?.id) {
            dispatch(fetchCustomerById(order?.customer?.id));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingCustomer && !propsIsLoadingCustomer) {
            onChange('customer', customer);
        }
    }, [propsIsLoadingCustomer]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.ORDER_NUMBER: {
                isValid = OrderStepValidation.validateInputStringLength(order.orderNumber);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="orderNumber"
                            label={messages.orderNumber}
                            value={order.orderNumber}
                            onChange={onChange}
                            type="number"
                            required
                            error={setValidationState(ATTRIBUTES.ORDER_NUMBER)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="orderDate"
                            type="now"
                            label={messages.orderDate}
                            value={order?.orderDate}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={order?.customer}
                            disabled={defaultCustomer}
                            onChange={(e, value: any) => {
                                onChange('customer', value);
                            }}
                            getOptionLabel={(option: Customer) => option.name}
                            filterSelectedOptions
                            inputValue={inputValue}
                            onInputChange={(event, newInputvalue: any) => {
                                setInputValue(newInputvalue);
                                if(!newInputvalue) {
                                    onChange(null);
                                    dispatch(resetCustomers());
                                }
                                if(newInputvalue.length > 2) {
                                    dispatch(resetCustomers());
                                    dispatch(fetchCustomers({
                                        paging,
                                        filters: {
                                            ...customerFilters,
                                            name: newInputvalue
                                        },
                                        throttle: true
                                    }));
                                }
                            }}
                            isOptionEqualToValue={(option: Customer, value: Customer) => option.id === value.id}
                            filterOptions={(x) => x}
                            options={customers}
                            loading={propsIsLoadingCustomers}
                            renderInput={(params) => <TextField {...params} label={intl.formatMessage(messages.customer)} variant="standard" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={order.unitGroup}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                            placeholder={messages.unitGroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={order.fromTerminal}
                            onChange={(obj: Terminal | null) => onChange('fromTerminal', obj)}
                            placeholder={messages.terminalFrom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={order.toTerminal}
                            onChange={(obj: Terminal | null) => onChange('toTerminal', obj)}
                            placeholder={messages.terminalTo}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<InvoiceType>
                        options={invoiceTypes}
                        value={order.invoiceType}
                        onChange={(obj: InvoiceType | null) => onChange('invoiceType', obj)}
                        placeholder={messages.invoiceType}
                    />
                </Grid>
                {/* <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<OrderStatus>
                        options={statuses}
                        value={order.status}
                        onChange={(obj: OrderStatus | null) => onChange('status', obj)}
                        placeholder={messages.status}
                    />
                </Grid>
                <Grid item xs={3} /> */}
            </Grid>
        </Loader>
    );
});

export default AddOrderStep1;