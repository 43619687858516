import React, { useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericStepper from '../../common/widgets/genericStepper';
import UrlConstants from '../../../constants/UrlConstants';
import GenericStep from '../../../interfaces/common/genericStep';
import { FormType } from '../../../constants/constants';
import { fetchOrderUnitServicesById, getOrderUnitService, isUpdatingOrderUnitService, updateOrderUnitServices, createOrderUnitService, isCreatingUnitService, resetOrderUnitService,
    isLoadingUnitService } from '../../../reducers/unitServiceReducer';
import OrderUnitService from '../../../interfaces/output/orderUnitService';
import AddOrderUnitServiceStep1 from './addOrderUnitServiceStep1';
import AddOrderUnitServiceStep2 from './addOrderUnitServiceStep2';
import { OrderStatusId } from '../../../constants/statusConstants';
import AddOrderUnitServiceStep3 from './addOrderUnitServiceStep3';
import { fetchOrderById, getOrder } from '../../../reducers/orderReducer';
import { ensure } from '../../../utils/arrayUtils';
import OrderUnitServiceStep2Validation from './validations/orderUnitServiceStep2Validation';
import DateTimeUtils from '../../../utils/dateTimeUtils';

interface IAddOrderUnitServiceFormProps {
    orderUnitServiceId?: number;
    orderUnitId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    editUnitService: LanguageUtils.createMessage('Edit order unit service'),
    addUnitService: LanguageUtils.createMessage('Add order unit service'),
    changeStatus: LanguageUtils.createMessage('Change service status')
};

export default function AddOrderUnitServiceForm(props: IAddOrderUnitServiceFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { orderUnitServiceId, orderUnitId, steps, type } = props;
    const propsOrderUnitService = useSelector(getOrderUnitService);
    const [orderUnitService, setOrderUnitService] = React.useState<OrderUnitService>({ orderUnit: { id: orderUnitId },
        amount: 1 } as OrderUnitService);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsCreatingUnitService = useSelector(isCreatingUnitService);
    const prevIsCreatingUnitService = WorkflowUtils.usePrevious<boolean>(propsIsCreatingUnitService);
    const propsIsUpdatingOrderUnitService = useSelector(isUpdatingOrderUnitService);
    const order = useSelector(getOrder);
    const prevIsUpdatingOrderUnitService = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrderUnitService);
    const propsIsLoadingOrderUnitService = useSelector(isLoadingUnitService);
    const prevIsLoadingOrderUnitService = WorkflowUtils.usePrevious<boolean>(propsIsLoadingOrderUnitService);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 2:
                if(prevIsCreatingUnitService === true && !propsIsCreatingUnitService || prevIsUpdatingOrderUnitService === true && !propsIsUpdatingOrderUnitService) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsUpdatingOrderUnitService === true && !propsIsUpdatingOrderUnitService) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingUnitService, propsIsUpdatingOrderUnitService]);

    useEffect(() => {
        if(prevIsLoadingOrderUnitService === true && !propsIsLoadingOrderUnitService) { dispatch(fetchOrderById(ensure(propsOrderUnitService.orderUnit?.orderId))); }
    }, [propsIsLoadingOrderUnitService]);
    
    useEffect(() => {
        if(orderUnitServiceId) {
            dispatch(fetchOrderUnitServicesById(orderUnitServiceId));
        }
        else {
            dispatch(resetOrderUnitService());
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(!propsIsLoadingOrderUnitService && prevIsLoadingOrderUnitService) {
            setOrderUnitService(propsOrderUnitService);
        }

    }, [propsIsLoadingOrderUnitService]);

    const onChangeUnitService = (attribute: string, value: any) => {

        const newOrderUnitService = { ...orderUnitService } as OrderUnitService;
        (newOrderUnitService as any)[attribute] = value;

        setOrderUnitService(newOrderUnitService);
    };

    const now = new Date();

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: 'Select service from list',
            xl: true,
            content: <AddOrderUnitServiceStep1 onChange={onChangeUnitService} orderUnitService={orderUnitService} order={order} />
        } as GenericStep,
        {
            id: 2,
            name: 'Insert details for order unit service',
            content: <AddOrderUnitServiceStep2 onChange={onChangeUnitService} orderUnitService={orderUnitService} />,
            validationFn: () => OrderUnitServiceStep2Validation.validateUnitServiceForm(orderUnitService),
            onNext: () => {
                if(!orderUnitServiceId) {
                    dispatch(createOrderUnitService({ ...orderUnitService,
                        ousStatus: { id: OrderStatusId.InProgress },
                        serviceDate: orderUnitService.serviceDate || DateTimeUtils.dateTimeGMTStart(now),
                        serviceEndDate: orderUnitService.serviceEndDate || DateTimeUtils.dateTimeGMTEnd(now) }));
                }
                else {
                    dispatch(updateOrderUnitServices(orderUnitService));
                }
            }
        } as GenericStep,
        {
            id: 3,
            name: 'Change service status',
            content: <AddOrderUnitServiceStep3 onChange={onChangeUnitService} orderUnitService={orderUnitService} />,
            onNext: () => {
                dispatch(updateOrderUnitServices(orderUnitService));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddUnitService:
                return messages.addUnitService;
            case FormType.EditUnitService:
                return messages.editUnitService;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddUnitService:
                return `/${UrlConstants.ORDER_UNITS}/${orderUnitId}/services`;
            case FormType.EditUnitService:
                return `/${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceId}`;
            case FormType.ChangeStatus:
                return `/${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceId}`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  