import { http } from '../utils/http';
import DashboardMessage from '../interfaces/output/dashboardMessage';
import DashboardMessageFilters from '../interfaces/filters/dashboardMessageFilters';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import { ContentTypeConstants } from '../constants/browserConstants';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class DashboardMessageApi {
    static fetchDashboardMessages = async(paging: Paging, filters: DashboardMessageFilters): Promise<Array<DashboardMessage>> => {
       
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/dashboardmessages');

        const { data } = await http.get<Array<DashboardMessage>>(url);

        return data;
    };

    static exportDashboardMessages = async(filters: DashboardMessageFilters): Promise<Blob> => {
        const url = urlcat('', '/dashboardmessages/pdf', filters);
        const { data } = await http.getFile<Blob>(url, ContentTypeConstants.pdf);

        return data;
    };
}