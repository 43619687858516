import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import PriceList from '../../../interfaces/output/priceList';
import { isLoadingPriceList } from '../../../reducers/priceListReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import { NumericalInput } from '../../common/numericalInput';

interface AddPriceListVersionStep5Props {
    priceList: PriceList;
    onChange: any;
}

const messages = {
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    percentage: LanguageUtils.createMessage('Percentage')
};

const AddPriceListVersionStep5 = forwardRef((props: AddPriceListVersionStep5Props, ref: any): JSX.Element => {
    const { priceList, onChange } = props;
    const isLoadingObjects = [useSelector(isLoadingPriceList)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={1} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFromDate}
                            value={priceList.version?.validFromDate}
                            onChange={onChange}
                            type="start"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validToDate}
                            value={priceList.version?.validToDate}
                            onChange={onChange}
                            type="end"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <NumericalInput
                            name="adjustWithPercentage"
                            value={priceList.version?.adjustWithPercentage}
                            onChange={onChange}
                            label={messages.percentage}
                            suffix={'%'}/>
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddPriceListVersionStep5;