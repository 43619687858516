import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import ProductArena from '../interfaces/output/productArena';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ProductArenaFilters from '../interfaces/filters/productArenaFilters';
import ProductArenaType from '../interfaces/output/productArenaType';

export type ProductArenaTypeState = {
    productArenaType: ProductArenaType;
    isLoading: boolean;
    productArenaTypes: Pagination<ProductArenaType>;
    error: HttpErrorResponse | undefined;
};

const initialState: ProductArenaTypeState = {
    productArenaType: {} as ProductArenaType,
    productArenaTypes: { content: [] as Array<ProductArenaType> } as Pagination<ProductArenaType>,
    isLoading: false,
    error: undefined
};

export const productArenaTypeSlice = createSlice({
    name: 'productArenaType',

    initialState,
    reducers: {
        fetchProductArenaTypes: (state: ProductArenaTypeState, _action: PayloadAction<{ paging: Paging, filters?: ProductArenaFilters }>) => {
            state.isLoading = true;
        },
        fetchProductArenaTypesSuccess: (state: ProductArenaTypeState, action: PayloadAction<Pagination<ProductArenaType>>) => {
            state.isLoading = false;
            state.productArenaTypes = action.payload;
        },
        fetchProductArenaTypesError: (state: ProductArenaTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchProductArenaTypeById: (state: ProductArenaTypeState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchProductArenaTypeByIdSuccess: (state: ProductArenaTypeState, action: PayloadAction<ProductArenaType>) => {
            state.isLoading = false;
            state.productArenaType = action.payload;
        },
        fetchProductArenaTypeByIdError: (state: ProductArenaTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        resetProductArenaTypes: (state: ProductArenaTypeState) => {
            state.productArenaTypes = initialState.productArenaTypes;
        }
    }
});

export const { fetchProductArenaTypeById, fetchProductArenaTypeByIdError, fetchProductArenaTypeByIdSuccess,
    fetchProductArenaTypes, fetchProductArenaTypesError, fetchProductArenaTypesSuccess, resetProductArenaTypes }
    = productArenaTypeSlice.actions;

export const isLoadingProductArenaTypes = (state: RootState): boolean => state.productArenaTypeReducer.isLoading;
export const getProductArenaTypes = (state: RootState): Pagination<ProductArenaType> => state.productArenaTypeReducer.productArenaTypes;
export const getProductArenaType = (state: RootState): ProductArenaType => state.productArenaTypeReducer.productArenaType;

export default productArenaTypeSlice.reducer;