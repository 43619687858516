import React, { Component, ErrorInfo, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HttpErrorResponse from '../../interfaces/common/httpErrorResponse';
import { getError, hideError, showError } from '../../reducers/errorReducer';
import { RootState } from '../../setup';
import ModalError from './modalError';

interface ErrorBoundaryProps {
    children: ReactNode;
    showError: boolean;
    error: HttpErrorResponse | undefined | any;
    hideError: () => void;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: string;
    details?: string;
    errorMessage?: string;
}

const mapStoreToProps = (store: RootState) => {
    return {
        showError: showError(store),
        error: getError(store)
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideError: () => dispatch(hideError())
});

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        hasError: false,
        error: '',
        details: ''
    };

    static getDerivedStateFromError(error: any): ErrorBoundaryState {
        return {
            hasError: true,
            error: error.toString()
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // eslint-disable-next-line no-console
        console.error('Uncaught error:', error, errorInfo);
    }

    async componentDidUpdate(prevProps: ErrorBoundaryProps) {
        const { showError, error } = this.props;
        let errorMessage = undefined;
        if(prevProps.showError !== showError) {
            if(error?.data instanceof Blob) {
                errorMessage = await error.data.text();
                this.setState({
                    errorMessage
                });
            }
            else if(error?.data instanceof Object) {
                if(error.data.errors) {
                    errorMessage = error.data?.errors.join(' ,');
                }
                else {
                    errorMessage = await error.data?.error;
                }
                this.setState({
                    errorMessage
                });
            }
            this.setState({
                hasError: showError,
                error: errorMessage !== undefined ? errorMessage : error?.data,
                details: error?.statusText
            });
        }
    }

    _hideError = () => {
        const { hideError } = this.props;
        hideError();
        this.setState({
            error: '',
            hasError: false
        });
    }

    render(): ReactNode {
        const { hasError, error, details } = this.state;

        return (<>
            <ModalError onClose={this._hideError} message={error} details={details} show={hasError} />
            {this.props.children}
        </>);
    }
}

export default connect(mapStoreToProps, mapDispatchToProps)(ErrorBoundary);