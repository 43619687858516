import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../../constants/UrlConstants';
import { RootState } from '../../../setup';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactorSetsFiltersComponent from '../factorSetsFiltersComponent';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import LanguageUtils from '../../../utils/LanguageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import PageUtils from '../../../utils/pageUtils';
import HoursFilter from './hoursFilter';
import { fetchFactorSetHours, getFactorSetHours, isLoadingFactorSetHours } from '../../../reducers/factorSetHoursReducer';
import HoursList from './hoursList';

interface IFactorSetHoursProps {
    factorSetHours: Pagination<FactorSetOut>;
    classes: any;
    fetchFactorSetHours: any;
    isLoading: boolean;
    onChange: () => void;
}

interface IFactorSetHoursState {
    searchTerm: string;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    filters: HoursFilter;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    factorSetHours: LanguageUtils.createMessage('Factor set hours'),
    add: LanguageUtils.createMessage('Add')
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetHours: (paging: Paging, filters: HoursFilter) => dispatch(fetchFactorSetHours({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetHours: getFactorSetHours(store),
        isLoading: isLoadingFactorSetHours(store)
    };
};

class FactorSetHours extends Component<IFactorSetHoursProps, IFactorSetHoursState> {
    hoursListRef: any;
    constructor(props: IFactorSetHoursProps) {
        super(props);
        this.hoursListRef = React.createRef();

        this.state = {
            searchTerm: '',
            paging: PageUtils.getDefaultPaging(),
            filters: {
                name: ''
            } as HoursFilter,
            menuItem: {
                id: 1,
                name: messages.factorSetHours,
                icon: <FontAwesomeIcon icon="clock" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetHours } = this.props;
        const { paging, filters } = this.state;

        fetchFactorSetHours(paging, filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as HoursFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchFactorSetHours } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({ paging: newPagination });

        fetchFactorSetHours(newPagination, filters);

        if(this.hoursListRef !== null && this.hoursListRef !== undefined &&
            this.hoursListRef.current !== null && this.hoursListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.hoursListRef.current.resetDataGridPage();
        }
    }

    setDefaultState = () => {
        const { fetchFactorSetHours } = this.props;
        const { paging } = this.state;

        const newSortModel = [{
            field: 'name',
            sort: 'asc'
        }] as GridSortModel;

        const newFilters = { name: '' } as HoursFilter;
        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchFactorSetHours(newPagination, newFilters);

        if(this.hoursListRef !== null && this.hoursListRef !== undefined &&
            this.hoursListRef.current !== null && this.hoursListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.hoursListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchFactorSetHours } = this.props;

        const newFilter = { ...filters } as HoursFilter;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchFactorSetHours(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchFactorSetHours } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });
            fetchFactorSetHours(newPagination, filters);
        }
    }

    render() {
        const { classes, factorSetHours, fetchFactorSetHours, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.FACTOR_SETS}/hours/add`
                                    }]}
                                >
                                    <FactorSetsFiltersComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <HoursList rows={factorSetHours} isLoading={isLoading}
                                    ref={this.hoursListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchFactorSetHours(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                    
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetHours));