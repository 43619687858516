import { GridSortItem } from '@mui/x-data-grid';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class SortFormatterUtils {
    static sortForServer(inputs: GridSortItem[]): string {

        let sort = '';
        inputs.forEach((input) => {
            sort = `${input.field},${input.sort}&`;

        });

        return sort;

    }

    static addSortingToUrl(paging: Paging, filters: any, url: string): string {
        const newsort = SortFormatterUtils.sortForServer(paging.sort);
        const newPaging = {
            ...paging,
            sort: ''
        };
        let sortUrl;
        if(filters != null) {
            sortUrl = urlcat('', url, {
                ...filters,
                ...newPaging
            });
        }
        else {
            sortUrl = urlcat('', url, {
                ...newPaging
            });
        }

        return `${sortUrl}${newsort}`;
    }
}

export default SortFormatterUtils;