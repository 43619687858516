import React from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../../constants/UrlConstants';
import DataGridComponent from '../../common/dataGrid';
import TableUtils from '../../../utils/tableUtils';
import Pagination from '../../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import DisplayStatus from '../../common/displayStatus';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { ensure } from '../../../utils/arrayUtils';
import CustomLink from '../../common/customLink';
import CustomStringLink from '../../common/customStringLink';

interface IServicesListProps {
    classes?: any;
    orderUnitServiceProducts?: Pagination<OrderUnitServiceProduct>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    product: LanguageUtils.createMessage('Product'),
    orderUnitService: LanguageUtils.createMessage('Service type'),
    agreement: LanguageUtils.createMessage('Agreement'),
    pricelist: LanguageUtils.createMessage('Price list'),
    currency: LanguageUtils.createMessage('Currency'),
    productPrice: LanguageUtils.createMessage('Product price'),
    ouspStatus: LanguageUtils.createMessage('Ousp status')
};

export default function OrderUnitServiceProductsList(props: IServicesListProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, orderUnitServiceProducts,
        onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'product',
                headerName: intl.formatMessage(messages.product),
                flex: 2,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.product?.name} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('product', hiddenColumns)
            },
            {
                field: 'orderUnitService',
                headerName: intl.formatMessage(messages.orderUnitService),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.orderUnitService?.service?.type?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('orderUnitService', hiddenColumns)
            },
            {
                field: 'agreement',
                headerName: intl.formatMessage(messages.agreement),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.agreement?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('agreement', hiddenColumns)
            },
            {
                field: 'pricelist',
                headerName: intl.formatMessage(messages.pricelist),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.pricelist?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('pricelist', hiddenColumns)
            },
            {
                field: 'currency',
                headerName: intl.formatMessage(messages.currency),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.currency?.code ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('currency', hiddenColumns)
            },
            {
                field: 'productPrice',
                headerName: intl.formatMessage(messages.productPrice),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(params).id}`}>
                        {applyRowFn(params)?.productPrice !== undefined && applyRowFn(params)?.productPrice !== null &&
                            intl.formatNumber(ensure(Math.round(applyRowFn(params)?.productPrice)), {
                                maximumFractionDigits: 0
                            }) || '-'}
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('productPrice', hiddenColumns)
            },
            {
                field: 'ouspStatus',
                headerName: intl.formatMessage(messages.ouspStatus),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${applyRowFn(params).id}`}>
                        <DisplayStatus status={applyRowFn(params)?.ouspStatus} inheritProps />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('ouspStatus', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || orderUnitServiceProducts} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
}