import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import GTSOrderUnitDangerousGoodsApi from '../api/dangerousGoodsApi';
import GTSOrderUnitDangerousGoods from '../interfaces/output/gtsOrderUnitDangerousGoods';
import { deactivateGtsOrderUnitDangerousGoodsError, deactivateGtsOrderUnitDangerousGoodsSuccess, fetchGtsOrderUnitDangerousGoodsByIdError, fetchGtsOrderUnitDangerousGoodsByIdSuccess,
    fetchGtsOrderUnitDangerousGoodsError, fetchGtsOrderUnitDangerousGoodsSuccess, updateGtsOrderUnitDangerousGoodsError, updateGtsOrderUnitDangerousGoodsSuccess }
    from '../reducers/gtsDangerousGoodsReducer';

function* doFetchGtsOrderUnitDangerousGoods(action: PayloadAction<string>) {
    const id = action.payload;

    try {
        const response: GTSOrderUnitDangerousGoods = yield call(GTSOrderUnitDangerousGoodsApi.fetchGtsOrderUnitDangerousGoods, id);
        yield put(fetchGtsOrderUnitDangerousGoodsByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderUnitDangerousGoodsByIdError(error as HttpErrorResponse));
    }
}

function* doFetchGtsOrderUnitsDangerousGoods(action: PayloadAction<{ paging: Paging, filters: GtsOrderUnitFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<GTSOrderUnitDangerousGoods> = yield call(GTSOrderUnitDangerousGoodsApi.fetchGtsOrderUnitsDangerousGoods, paging, filters);
        yield put(fetchGtsOrderUnitDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchGtsOrderUnitDangerousGoodsError(error as HttpErrorResponse));
    }
}

function* doUpdateGtsOrderUnitDangerousGoods(action: PayloadAction<GTSOrderUnitDangerousGoods>) {
    const gtsOrderUnit = action.payload;

    try {
        const response: GTSOrderUnitDangerousGoods = yield call(GTSOrderUnitDangerousGoodsApi.updateGtsOrderUnitDangerousGoods, gtsOrderUnit);

        yield put(updateGtsOrderUnitDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateGtsOrderUnitDangerousGoodsError(error as HttpErrorResponse));
    }
}

function* doDeactivateGtsOrderUnitDangerousGoods(action: PayloadAction<number>) {
    const unitId = action.payload;
    
    try {
        const response: GTSOrderUnitDangerousGoods = yield call(GTSOrderUnitDangerousGoodsApi.deactivateGtsOrderUnitDangerousGoods, unitId);

        yield put(deactivateGtsOrderUnitDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateGtsOrderUnitDangerousGoodsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('dangerousGoods/fetchGtsOrderUnitDangerousGoodsById', doFetchGtsOrderUnitDangerousGoods),
    takeLatest('dangerousGoods/fetchGtsOrderUnitDangerousGoods', doFetchGtsOrderUnitsDangerousGoods),
    takeLatest('dangerousGoods/updateGtsOrderUnitDangerousGoods', doUpdateGtsOrderUnitDangerousGoods),
    takeLatest('dangerousGoods/deactivateGtsOrderUnitDangerousGoods', doDeactivateGtsOrderUnitDangerousGoods)
];

