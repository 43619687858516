import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import FactorSetOut from '../interfaces/output/factorSetOut';
import Pagination from '../interfaces/common/pagination';
import FactorSetCancellationLineOutput from '../interfaces/output/factorSetCancellationLineOut';
import CancellationLineFilters from '../components/factorSets/cancellation/line/cancellationLineFilters';
import FactorSetCancellationLineInput from '../interfaces/input/factorSetCancellationLineIn';
import FactorSetIn from '../interfaces/input/factorSetIn';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetCancellationsApi {
    static fetchFactorSetCancellationById = async(factorSetId: number, versionId?: number): Promise<FactorSetOut> => {
        const versionQueryString = versionId ? { versionId: versionId } : {};
        const url = urlcat('', `factorsets/cancellation/${factorSetId}`, versionQueryString);
        
        const { data } = await http.get<FactorSetOut>(url);
        
        return data;
    };

    static fetchFactorSetCancellations = async(paging: Paging, filters: CancellationLineFilters): Promise<Array<FactorSetOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsets/cancellation');

        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static fetchFactorSetCancellationsLines = async(factorSetId: number, paging: Paging, filters: CancellationLineFilters): Promise<Pagination<FactorSetCancellationLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, `factorsets/cancellation/${factorSetId}/lines`);

        const { data } = await http.get<Pagination<FactorSetCancellationLineOutput>>(url);

        return data;
    };

    static fetchFactorSetCancellationVersions = async(factorSetId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/cancellation/${factorSetId}/versions`);

        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static createFactorSetCancellation = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSet: FactorSetIn = {
            name: factorSet?.name,
            noFactors: true,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            default: factorSet?.default ?? false
        };

        const { data } = await http.post<FactorSetIn, FactorSetOut>('factorsets/cancellation', newFactorSet);

        return data;
    }

    static createFactorSetCancellationsLine = async(factorSetLine: FactorSetCancellationLineOutput): Promise<FactorSetCancellationLineOutput> => {
        const newLine: FactorSetCancellationLineInput = {
            name: factorSetLine.name,
            unitTypeId: factorSetLine.unitType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            cancellationTypeId: factorSetLine.cancellationType?.id
        };

        const { data } = await http.post<FactorSetCancellationLineInput, FactorSetCancellationLineOutput>(`factorsets/cancellation/${factorSetLine.factorSetId}/lines`, newLine);

        return data;
    }

    static createCancellationVersion = async(factorSet: FactorSetOut): Promise<FactorSetVersionOut> => {
        const url = `/factorsets/cancellation/${factorSet.id}/versions`;

        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSet.version?.validFromDate,
            validToDate: factorSet.version?.validToDate,
            statusId: 1 //set status to active
        };
        
        const { data } = await http.post<FactorSetVersionIn, FactorSetVersionOut>(url, newVersion);

        return data;
    }

    static updateFactorSetCancellation = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSet: FactorSetIn = {
            name: factorSet.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            default: factorSet?.default ?? false
        };
        
        const { data } = await http.put<FactorSetIn, FactorSetOut>(`factorsets/cancellation/${factorSet.id}`, newFactorSet);

        return data;
    }

    static deactivateFactorSetCancellation = async(id: number): Promise<FactorSetOut> => {
        const { data } = await http.delete<FactorSetOut>(`factorsets/cancellation/${id}`);

        return data;
    }
}