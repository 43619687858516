import nbMessages from '../locale/nb-no.json';
import enMessages from '../locale/en-us.json';
import { MessageDescriptor } from 'react-intl';

const DEFAULT_LANGUAGE = 'en-US';

const messages: any = {
    'nb-NO': nbMessages,
    'en-US': enMessages
};

const languageMap: any = {
    'no': 'nb-NO',
    'nb': 'nb-NO',
    'no-nb': 'nb-NO',
    'nb-no': 'nb-NO',
    'nn-no': 'nb-NO',
    'en': 'en-US',
    'en-us': 'en-US'
};

let language : 'en-US' | 'nb-NO' | undefined = undefined;

class LanguageUtils {
    static getLanguage(): 'en-US' | 'nb-NO' {
        if(!language) {
            let currentLanguage = DEFAULT_LANGUAGE;

            // All other vendors
            if(navigator.languages) {
                currentLanguage = navigator.languages[0];
            }
            else if(navigator.language) {
                currentLanguage = navigator.language;
            }
            // IE
            else if((navigator as any).browserLanguage) {
                currentLanguage = (navigator as any).browserLanguage;
            }
            
            language = LanguageUtils.getSupportedLanguage(currentLanguage);
        }

        return language;
    }

    static getLanguageAcceptString(): string {
        const language = this.getLanguage();

        return language;
    }

    static getSimpleFormLanguage(code: string): 'en' | 'nb' {
        if(typeof code === 'string') {
            const dash = code.indexOf('-');

            if(dash === -1) {
                return code as any;
            }
            else {
                const chunks = code.split('-');

                return chunks[0] as any;
            }
        }
        
        throw new Error();
    }

    static getSupportedLanguage(code: string): 'nb-NO' | 'en-US' {
        if(typeof code === 'string') {
            code = code.toLowerCase();
            
            if(languageMap[code]) {
                return languageMap[code];
            }
        }

        return DEFAULT_LANGUAGE;
    }

    static getResourceFormLanguage(): any {
        const regex = /(.*)[_](.*)/;
        const language = this.getLanguage();
        let m;
        
        if((m = regex.exec(language.replace('-','_'))) !== null) {
            return `${m[1]}_${m[2].toUpperCase()}`;
        }

        return undefined;
    }

    static getMessages(code: string): any {
        return messages[code];
    }

    static createMessage(defaultText: string, id?: string, description?: string): MessageDescriptor {
        const correctId = id ? id : defaultText.toUpperCase().replace(/\s/g, '_');

        return {
            defaultMessage: `${defaultText} (need translation for id="${correctId}")`,
            description: description ? description : defaultText,
            id: correctId
        };
    }

    static getDefaultSortLanguage(): string {
        return languageMap['no'];
    }

    static compareTextUsingDefaultLanguage(stringOne: string, stringTwo: string): number {
        const language = LanguageUtils.getDefaultSortLanguage();

        return stringOne.localeCompare(stringTwo, language);
    }

    static customBuildFormatLongFn(args: { [0]?: any, length? : any, formats: any, defaultWidth: string }): any {
        return () => {
            const options = args.length > 0 && args[0] !== undefined ? args[0] : {};

            const width = options.width ? String(options.width) : args.defaultWidth;
            const format = args.formats[width] || args.formats[args.defaultWidth];

            return format;
        };
    }
}

export default LanguageUtils;