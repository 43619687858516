import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type WeightVersionStatusState = {
    weightVersionStatus: FactorSetVersionStatusOut;
    weightVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: WeightVersionStatusState = {
    weightVersionStatus: {} as FactorSetVersionStatusOut,
    weightVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const weightVersionStatusSlice = createSlice({
    name: 'weightVersionStatus',

    initialState,
    reducers: {
        fetchWeightVersionStatuses: (state: WeightVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchWeightVersionStatusesSuccess: (state: WeightVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.weightVersionStatuses = action.payload;
        },
        fetchWeightVersionStatusesError: (state: WeightVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchWeightVersionStatusById: (state: WeightVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchWeightVersionStatusByIdSuccess: (state: WeightVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.weightVersionStatus = action.payload;
        },
        fetchWeightVersionStatusByIdError: (state: WeightVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateWeightVersionStatus: (state: WeightVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updateWeightVersionStatusSuccess: (state: WeightVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.weightVersionStatus = action.payload;
        },
        updateWeightVersionStatusError: (state: WeightVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateWeightVersionStatus: (state: WeightVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivateWeightVersionStatusSuccess: (state: WeightVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.weightVersionStatus = action.payload;
        },
        deactivateWeightVersionStatusError: (state: WeightVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetWeightVersionStatuses: (state: WeightVersionStatusState) => {
            state.weightVersionStatuses = initialState.weightVersionStatuses;
        }
    }
});

export const { fetchWeightVersionStatusById, fetchWeightVersionStatusByIdSuccess, fetchWeightVersionStatusByIdError, updateWeightVersionStatus,
    updateWeightVersionStatusSuccess, updateWeightVersionStatusError, fetchWeightVersionStatuses, fetchWeightVersionStatusesSuccess,
    fetchWeightVersionStatusesError, deactivateWeightVersionStatus, deactivateWeightVersionStatusSuccess, deactivateWeightVersionStatusError,
    resetWeightVersionStatuses } = weightVersionStatusSlice.actions;

export const isLoadingWeightVersionStatus = (state: RootState): boolean => state.weightVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.weightVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.weightVersionStatusReducer.isDeleted;
export const getWeightVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.weightVersionStatusReducer.weightVersionStatus;
export const getWeightVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.weightVersionStatusReducer.weightVersionStatuses;

export default weightVersionStatusSlice.reducer;