import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import Terminal from '../interfaces/output/terminal';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import TerminalFilters from '../interfaces/filters/terminaleFilters';

export default class TerminalApi {
    static fetchTerminalById = async(terminalId: string): Promise<Terminal> => {
        const { data } = await http.get<Terminal>(`/terminals/${terminalId}`);

        return data;
    };

    static fetchTerminals = async(paging: Paging, filters: TerminalFilters): Promise<Pagination<Terminal>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/terminals');

        const { data } = await http.get<Pagination<Terminal>>(url);

        return data;
    };

    static createTerminal = async(terminal: Terminal): Promise<Terminal> => {
        const newterminal = {
            name: terminal.name,
            gtsTerminalNo: terminal.gtsTerminalNo,
            gtsTerminalCode: terminal.gtsTerminalCode,
            distributionResponsabilityCenter: terminal.distributionResponsabilityCenterCode ?? terminal.distributionResponsabilityCenter?.code,
            terminalResponsabilityCenter: terminal.terminalResponsabilityCenterCode ?? terminal.terminalResponsabilityCenter?.code,
            countryId: terminal.country?.id,
            operatorId: terminal.operator?.id,
            depotServices: terminal.depotServices ?? false,
            pricelistTerminalId: terminal.pricelistTerminalId?.terminalId
        };

        const { data } = await http.post<any, Terminal>('terminals', newterminal);

        return data;
    }

    static updateTerminal = async(terminal: Terminal): Promise<Terminal> => {
        const newterminal = {
            name: terminal.name,
            gtsTerminalNo: terminal.gtsTerminalNo,
            gtsTerminalCode: terminal.gtsTerminalCode,
            distributionResponsabilityCenter: terminal.distributionResponsabilityCenterCode ?? terminal.distributionResponsabilityCenter?.code,
            terminalResponsabilityCenter: terminal.terminalResponsabilityCenterCode ?? terminal.terminalResponsabilityCenter?.code,
            countryId: terminal.country?.id,
            operatorId: terminal.operator?.id,
            pricelistTerminalId: terminal.pricelistTerminalId?.terminalId,
            depotServices: terminal.depotServices ?? false
        };

        const { data } = await http.put<any, Terminal>(`terminals/${terminal.id}`, newterminal);

        return data;
    }

    static deleteTerminal = async(terminalId: number): Promise<Terminal> => {
        const { data } = await http.delete<any, Terminal>(`terminals/${terminalId}`);

        return data;
    };
}
