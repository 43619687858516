import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import PriorityFilters from '../components/factorSets/priority/priorityFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetType from '../interfaces/output/factorSetType';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';

export type FactorSetPriorityState = {
    factorSetPriority: FactorSetOut;
    factorSetPriorities: Pagination<FactorSetOut>;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoadingFactorSetPriority: boolean;
    isLoadingFactorSetPriorities: boolean;
    isLoadingFactorSetVersions: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetPriorityState = {
    factorSetPriority: {} as FactorSetOut,
    factorSetPriorities: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoadingFactorSetPriority: false,
    isLoadingFactorSetPriorities: false,
    isLoadingFactorSetVersions: false,
    isUpdating: false,
    isRemoving: false,
    isDeleting: false,
    isCreating: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetPrioritySlice = createSlice({
    name: 'factorSetPriority',

    initialState,
    reducers: {
        fetchFactorSetPriorities: (state: FactorSetPriorityState, action: PayloadAction<{paging: Paging, filters?: PriorityFilters}>) => {
            state.isLoadingFactorSetPriorities = true;
        },
        fetchFactorSetPrioritiesSuccess: (state: FactorSetPriorityState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingFactorSetPriorities = false;
            state.factorSetPriorities = action.payload;
        },
        fetchFactorSetPrioritiesError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetPriorities = false;
            state.error = action.payload;
        },
        fetchFactorSetPriorityById: (state: FactorSetPriorityState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetPriority = true;
        },
        fetchFactorSetPriorityByIdSuccess: (state: FactorSetPriorityState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetPriority = false;
            state.factorSetPriority = action.payload;
        },
        fetchFactorSetPriorityByIdError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetPriority = false;
            state.error = action.payload;
        },
        fetchPriorityVersions: (state: FactorSetPriorityState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchPriorityVersionsSuccess: (state: FactorSetPriorityState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchPriorityVersionsError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        createPriorityVersion: (state: FactorSetPriorityState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createPriorityVersionSuccess: (state: FactorSetPriorityState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetPriority = action.payload;
        },
        createPriorityVersionError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        createFactorSetPriority: (state: FactorSetPriorityState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetPrioritySuccess: (state: FactorSetPriorityState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetPriority = action.payload;
        },
        createFactorSetPriorityError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetPriority: (state: FactorSetPriorityState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetPrioritySuccess: (state: FactorSetPriorityState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetPriority = action.payload;
        },
        updateFactorSetPriorityError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetPriority: (state: FactorSetPriorityState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetPrioritySuccess: (state: FactorSetPriorityState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleting = false;
            state.factorSetPriority = action.payload;
        },
        deactivateFactorSetPriorityError: (state: FactorSetPriorityState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetPriorities: (state: FactorSetPriorityState) => {
            state.factorSetPriorities = initialState.factorSetPriorities;
        },
        resetFactorSetPriority: (state: FactorSetPriorityState) => {
            state.factorSetPriority = initialState.factorSetPriority;
        }
    }
});

export const { fetchFactorSetPriorityById, fetchFactorSetPriorityByIdSuccess, fetchFactorSetPriorityByIdError, fetchFactorSetPriorities, fetchFactorSetPrioritiesError, fetchFactorSetPrioritiesSuccess,
    createFactorSetPriority, createFactorSetPriorityError , createFactorSetPrioritySuccess, updateFactorSetPriority, updateFactorSetPriorityError, updateFactorSetPrioritySuccess,
    deactivateFactorSetPriority, deactivateFactorSetPriorityError, deactivateFactorSetPrioritySuccess, resetFactorSetPriorities, createPriorityVersion, createPriorityVersionSuccess,
    createPriorityVersionError, resetFactorSetPriority } = factorSetPrioritySlice.actions;

export const isLoadingFactorSetPriority = (state: RootState): boolean => state.factorSetPriorityReducer.isLoadingFactorSetPriority;
export const isLoadingFactorSetPriorities = (state: RootState): boolean => state.factorSetPriorityReducer.isLoadingFactorSetPriorities;
export const isUpdatingPriority = (state: RootState): boolean => state.factorSetPriorityReducer.isUpdating;
export const isDeletingPriority = (state: RootState): boolean => state.factorSetPriorityReducer.isDeleting;
export const isCreatingPriority = (state: RootState): boolean => state.factorSetPriorityReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetPriorityReducer.isCreatingVersion;
export const getFactorSetPriorities = (state: RootState): Pagination<FactorSetOut> => state.factorSetPriorityReducer.factorSetPriorities;
export const getFactorSetPriority = (state: RootState): FactorSetOut => state.factorSetPriorityReducer.factorSetPriority;
export const isLoadingFactorSetVersions = (state: RootState): boolean => state.factorSetPriorityReducer.isLoadingFactorSetVersions;

export default factorSetPrioritySlice.reducer;