import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Seller from '../interfaces/output/seller';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type SellerState = {
    seller: Seller;
    isLoading: boolean;
    sellers: Pagination<Seller>;
    error: HttpErrorResponse | undefined;
};

const initialState: SellerState = {
    seller: {} as Seller,
    sellers: { content: [] as Array<Seller> } as Pagination<Seller>,
    isLoading: false,
    error: undefined
};

export const sellerSlice = createSlice({
    name: 'seller',

    initialState,
    reducers: {
        fetchSellers: (state: SellerState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchSellersSuccess: (state: SellerState, action: PayloadAction<Pagination<Seller>>) => {
            state.isLoading = false;
            state.sellers = action.payload;
        },
        fetchSellersError: (state: SellerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchSellerById: (state: SellerState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchSellerByIdSuccess: (state: SellerState, action: PayloadAction<Seller>) => {
            state.isLoading = false;
            state.seller = action.payload;
        },
        fetchSellerByIdError: (state: SellerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchSellers, fetchSellersSuccess, fetchSellersError, fetchSellerById, fetchSellerByIdSuccess, fetchSellerByIdError } = sellerSlice.actions;

export const isLoadingSellers = (state: RootState): boolean => state.sellerReducer.isLoading;
export const getSellers = (state: RootState): Pagination<Seller> => state.sellerReducer.sellers;
export const getSeller = (state: RootState): Seller => state.sellerReducer.seller;

export default sellerSlice.reducer;