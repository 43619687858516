import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import SimulationOrderOut from '../interfaces/output/simulationOrderOut';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SimulationOrderFilters from '../interfaces/filters/simulationOrderFilters';

export type SimulationOrderState = {
    simulationOrder: SimulationOrderOut;
    isLoadingSimulationOrder: boolean;
    isLoadingSimulationOrders: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    simulationOrders: Pagination<SimulationOrderOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: SimulationOrderState = {
    simulationOrder: {} as SimulationOrderOut,
    simulationOrders: { content: [] as Array<SimulationOrderOut> } as Pagination<SimulationOrderOut>,
    isLoadingSimulationOrder: false,
    isLoadingSimulationOrders: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const simulationOrderSlice = createSlice({
    name: 'simulationOrder',

    initialState,
    reducers: {
        fetchSimulationOrders: (state: SimulationOrderState, _action: PayloadAction<{paging: Paging, filters: SimulationOrderFilters}>) => {
            state.isLoadingSimulationOrders = true;
        },
        fetchSimulationOrdersSuccess: (state: SimulationOrderState, action: PayloadAction<Pagination<SimulationOrderOut>>) => {
            state.isLoadingSimulationOrders = false;
            state.simulationOrders = action.payload;
        },
        fetchSimulationOrdersError: (state: SimulationOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulationOrders = false;
            state.error = action.payload;
        },
        fetchSimulationOrderById: (state: SimulationOrderState, _action: PayloadAction<number>) => {
            state.isLoadingSimulationOrder = true;
        },
        fetchSimulationOrderByIdSuccess: (state: SimulationOrderState, action: PayloadAction<SimulationOrderOut>) => {
            state.isLoadingSimulationOrder = false;
            state.simulationOrder = action.payload;
        },
        fetchSimulationOrderByIdError: (state: SimulationOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulationOrder = false;
            state.error = action.payload;
        },
        createSimulationOrder: (state: SimulationOrderState, _action: PayloadAction<SimulationOrderOut>) => {
            state.isCreating = true;
        },
        createSimulationOrderSuccess: (state: SimulationOrderState, action: PayloadAction<SimulationOrderOut>) => {
            state.isCreating = false;
            state.simulationOrder = action.payload;
        },
        createSimulationOrderError: (state: SimulationOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateSimulationOrder: (state: SimulationOrderState, _action: PayloadAction<SimulationOrderOut>) => {
            state.isUpdating = true;
        },
        updateSimulationOrderSuccess: (state: SimulationOrderState, action: PayloadAction<SimulationOrderOut>) => {
            state.isUpdating = false;
            state.simulationOrder = action.payload;
        },
        updateSimulationOrderError: (state: SimulationOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteSimulationOrder: (state: SimulationOrderState, _action: PayloadAction<SimulationOrderOut>) => {
            state.isDeleting = true;
        },
        deleteSimulationOrderSuccess: (state: SimulationOrderState, action: PayloadAction<SimulationOrderOut>) => {
            state.isDeleting = false;
            state.simulationOrder = action.payload;
        },
        deleteSimulationOrderError: (state: SimulationOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetSimulationOrders: (state: SimulationOrderState) => {
            state.simulationOrders = initialState.simulationOrders;
        },
        resetSimulationOrder: (state: SimulationOrderState) => {
            state.simulationOrder = initialState.simulationOrder;
        }
    }
});

export const { fetchSimulationOrders, fetchSimulationOrdersSuccess, fetchSimulationOrdersError, fetchSimulationOrderById, fetchSimulationOrderByIdSuccess, fetchSimulationOrderByIdError,
    createSimulationOrder, createSimulationOrderSuccess, createSimulationOrderError, updateSimulationOrder, updateSimulationOrderSuccess, updateSimulationOrderError, deleteSimulationOrder,
    deleteSimulationOrderSuccess, deleteSimulationOrderError, resetSimulationOrders, resetSimulationOrder } = simulationOrderSlice.actions;

export const isLoadingSimulationOrder = (state: RootState): boolean => state.simulationOrderReducer.isLoadingSimulationOrder;
export const isLoadingSimulationOrders = (state: RootState): boolean => state.simulationOrderReducer.isLoadingSimulationOrders;
export const isUpdatingSimulationOrder = (state: RootState): boolean => state.simulationOrderReducer.isUpdating;
export const isDeletingSimulationOrder = (state: RootState): boolean => state.simulationOrderReducer.isDeleting;
export const isCreatingSimulationOrder = (state: RootState): boolean => state.simulationOrderReducer.isCreating;
export const getSimulationOrders = (state: RootState): Pagination<SimulationOrderOut> => state.simulationOrderReducer.simulationOrders;
export const getSimulationOrder = (state: RootState): SimulationOrderOut => state.simulationOrderReducer.simulationOrder;

export default simulationOrderSlice.reducer;