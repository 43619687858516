import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type HoursVersionStatusState = {
    hoursVersionStatus: FactorSetVersionStatusOut;
    hoursVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: HoursVersionStatusState = {
    hoursVersionStatus: {} as FactorSetVersionStatusOut,
    hoursVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const hoursVersionStatusSlice = createSlice({
    name: 'hoursVersionStatus',

    initialState,
    reducers: {
        fetchHoursVersionStatuses: (state: HoursVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchHoursVersionStatusesSuccess: (state: HoursVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.hoursVersionStatuses = action.payload;
        },
        fetchHoursVersionStatusesError: (state: HoursVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchHoursVersionStatusById: (state: HoursVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchHoursVersionStatusByIdSuccess: (state: HoursVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.hoursVersionStatus = action.payload;
        },
        fetchHoursVersionStatusByIdError: (state: HoursVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateHoursVersionStatus: (state: HoursVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updateHoursVersionStatusSuccess: (state: HoursVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.hoursVersionStatus = action.payload;
        },
        updateHoursVersionStatusError: (state: HoursVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateHoursVersionStatus: (state: HoursVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivateHoursVersionStatusSuccess: (state: HoursVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.hoursVersionStatus = action.payload;
        },
        deactivateHoursVersionStatusError: (state: HoursVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetHoursVersionStatuses: (state: HoursVersionStatusState) => {
            state.hoursVersionStatuses = initialState.hoursVersionStatuses;
        }
    }
});

export const { fetchHoursVersionStatusById, fetchHoursVersionStatusByIdSuccess, fetchHoursVersionStatusByIdError, updateHoursVersionStatus,
    updateHoursVersionStatusSuccess, updateHoursVersionStatusError, fetchHoursVersionStatuses, fetchHoursVersionStatusesSuccess,
    fetchHoursVersionStatusesError, deactivateHoursVersionStatus, deactivateHoursVersionStatusSuccess, deactivateHoursVersionStatusError,
    resetHoursVersionStatuses } = hoursVersionStatusSlice.actions;

export const isLoadingHoursVersionStatus = (state: RootState): boolean => state.hoursVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.hoursVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.hoursVersionStatusReducer.isDeleted;
export const getHoursVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.hoursVersionStatusReducer.hoursVersionStatus;
export const getHoursVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.hoursVersionStatusReducer.hoursVersionStatuses;

export default hoursVersionStatusSlice.reducer;