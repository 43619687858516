import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import SimulationOut from '../interfaces/output/simulationOut';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SimulationFilters from '../interfaces/output/simulationFilters';

export type SimulationState = {
    simulation: SimulationOut;
    isLoadingSimulation: boolean;
    isLoadingSimulations: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    simulations: Pagination<SimulationOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: SimulationState = {
    simulation: {} as SimulationOut,
    simulations: { content: [] as Array<SimulationOut> } as Pagination<SimulationOut>,
    isLoadingSimulation: false,
    isLoadingSimulations: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const simulationSlice = createSlice({
    name: 'simulation',

    initialState,
    reducers: {
        fetchSimulations: (state: SimulationState, _action: PayloadAction<{paging: Paging, filters: SimulationFilters}>) => {
            state.isLoadingSimulations = true;
        },
        fetchSimulationsSuccess: (state: SimulationState, action: PayloadAction<Pagination<SimulationOut>>) => {
            state.isLoadingSimulations = false;
            state.simulations = action.payload;
        },
        fetchSimulationsError: (state: SimulationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulations = false;
            state.error = action.payload;
        },
        fetchSimulationById: (state: SimulationState, _action: PayloadAction<number>) => {
            state.isLoadingSimulation = true;
        },
        fetchSimulationByIdSuccess: (state: SimulationState, action: PayloadAction<SimulationOut>) => {
            state.isLoadingSimulation = false;
            state.simulation = action.payload;
        },
        fetchSimulationByIdError: (state: SimulationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulation = false;
            state.error = action.payload;
        },
        createSimulation: (state: SimulationState, _action: PayloadAction<SimulationOut>) => {
            state.isCreating = true;
        },
        createSimulationSuccess: (state: SimulationState, action: PayloadAction<SimulationOut>) => {
            state.isCreating = false;
            state.simulation = action.payload;
        },
        createSimulationError: (state: SimulationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateSimulation: (state: SimulationState, _action: PayloadAction<SimulationOut>) => {
            state.isUpdating = true;
        },
        updateSimulationSuccess: (state: SimulationState, action: PayloadAction<SimulationOut>) => {
            state.isUpdating = false;
            state.simulation = action.payload;
        },
        updateSimulationError: (state: SimulationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteSimulation: (state: SimulationState, _action: PayloadAction<SimulationOut>) => {
            state.isDeleting = true;
        },
        deleteSimulationSuccess: (state: SimulationState, action: PayloadAction<SimulationOut>) => {
            state.isDeleting = false;
            state.simulation = action.payload;
        },
        deleteSimulationError: (state: SimulationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetSimulations: (state: SimulationState) => {
            state.simulations = initialState.simulations;
        },
        resetSimulation: (state: SimulationState) => {
            state.simulation = initialState.simulation;
        }
    }
});

export const { fetchSimulations, fetchSimulationsSuccess, fetchSimulationsError, fetchSimulationById, fetchSimulationByIdSuccess, fetchSimulationByIdError,
    createSimulation, createSimulationSuccess, createSimulationError, updateSimulation, updateSimulationSuccess, updateSimulationError, deleteSimulation,
    deleteSimulationSuccess, deleteSimulationError, resetSimulations, resetSimulation } = simulationSlice.actions;

export const isLoadingSimulation = (state: RootState): boolean => state.simulationReducer.isLoadingSimulation;
export const isLoadingSimulations = (state: RootState): boolean => state.simulationReducer.isLoadingSimulations;
export const isUpdatingSimulation = (state: RootState): boolean => state.simulationReducer.isUpdating;
export const isDeletingSimulation = (state: RootState): boolean => state.simulationReducer.isDeleting;
export const isCreatingSimulation = (state: RootState): boolean => state.simulationReducer.isCreating;
export const getSimulations = (state: RootState): Pagination<SimulationOut> => state.simulationReducer.simulations;
export const getSimulation = (state: RootState): SimulationOut => state.simulationReducer.simulation;

export default simulationSlice.reducer;