import React, { forwardRef, useEffect } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import WorkflowUtils from '../../../../utils/workflowUtils';
import FactorSetHoursLineOutput from '../../../../interfaces/output/factorSetHoursLineOut';
import GenericTextField from '../../../common/widgets/genericTextField';
import { isCreatingHoursLine, isLoadingHoursLine, isUpdatingHoursLine } from '../../../../reducers/hoursLineReducer';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitType from '../../../../interfaces/output/unitType';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import { useIntl } from 'react-intl';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';
import Currency from '../../../../interfaces/output/currency';
import FactorSetHoursLineStep1Validation from '../line/validations/factorSetHoursLineStep1Validation';
import PageUtils from '../../../../utils/pageUtils';
import { ensure } from '../../../../utils/arrayUtils';
import LabelWithHelptext from '../../../common/widgets/labelWithHelptext';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';

interface HoursLineFormStep1Props {
    hoursLine: FactorSetHoursLineOutput;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    FROM_HOURS: 'fromHours',
    TO_HOURS: 'toHours',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    fromHours: LanguageUtils.createMessage('From hours'),
    toHours: LanguageUtils.createMessage('To hours'),
    monday: LanguageUtils.createMessage('Monday'),
    tuesday: LanguageUtils.createMessage('Tuesday'),
    wednesday: LanguageUtils.createMessage('Wednesday'),
    thursday: LanguageUtils.createMessage('Thursday'),
    friday: LanguageUtils.createMessage('Friday'),
    saturday: LanguageUtils.createMessage('Saturday'),
    sunday: LanguageUtils.createMessage('Sunday'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    unittype: LanguageUtils.createMessage('Unit type'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    fromHoursHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FROM_HOUR_HELPTEXT'),
    toHoursHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_TO_HOUR_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT'),
    mondayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_MONDAY_HELPTEXT'),
    tuesdayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_TUESDAY_HELPTEXT'),
    wednesdayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_WEDNESDAY_HELPTEXT'),
    thursdayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_THURSDAY_HELPTEXT'),
    fridayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FRIDAY_HELPTEXT'),
    saturdayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SATURDAY_HELPTEXT'),
    sundayHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SUNDAY_HELPTEXT')
};

const AddHoursLineFormStep1 = forwardRef((props: HoursLineFormStep1Props, ref: any): JSX.Element => {
    const { hoursLine, onChange, showValidationError } = props;
    const intl = useIntl();
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const prevHoursLine = WorkflowUtils.usePrevious<FactorSetHoursLineOutput>(hoursLine);
    const isLoadingObjects = [useSelector(isCreatingHoursLine), useSelector(isLoadingHoursLine), useSelector(isUpdatingHoursLine)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(hoursLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(hoursLine.unitType?.unitGroupId),
                paging }));
            if(hoursLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(hoursLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevHoursLine) {
            if(hoursLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(hoursLine.unitType?.unitGroupId),
                    paging }));
                if(prevHoursLine.id === hoursLine.id) {
                    onChange('unitType', { ...hoursLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                }
            }
            else {
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [hoursLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevHoursLine) {
            if(hoursLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(hoursLine.unitType?.unitSubGroupId),
                    paging }));
                if(prevHoursLine.id === hoursLine.id) {
                    onChange('unitType', { ...hoursLine.unitType,
                        id: null });
                }
            }
            else {
                dispatch(resetUnitTypes());
            }
        }
    }, [hoursLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!hoursLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetHoursLineStep1Validation.validateName(hoursLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = hoursLine.surcharge ? FactorSetHoursLineStep1Validation.validateInputNumber(hoursLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetHoursLineStep1Validation.validateInputNumber(hoursLine.factor);
                break;
            }
            case ATTRIBUTES.FROM_HOURS: {
                isValid = FactorSetHoursLineStep1Validation.validateInputStringLength(hoursLine.fromHours);
                break;
            }
            case ATTRIBUTES.TO_HOURS: {
                isValid = FactorSetHoursLineStep1Validation.validateInputStringLength(hoursLine.toHours);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = hoursLine.surcharge ? FactorSetHoursLineStep1Validation.validateInputNumber(hoursLine.surchargeCurrency?.id) : true;
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={hoursLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="fromHours"
                        label={messages.fromHours}
                        type="time"
                        value={hoursLine.fromHours}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.FROM_HOURS)}
                        required
                        helperText={messages.fromHoursHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="toHours"
                        label={messages.toHours}
                        type="time"
                        value={hoursLine.toHours}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.TO_HOURS)}
                        required
                        helperText={messages.toHoursHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={<Checkbox checked={hoursLine.monday} inputProps={{ 'aria-label': 'controlled' }}
                            onChange={(e: any, value: boolean) => onChange('monday', value)} />}
                        label={<LabelWithHelptext message={messages.monday} helptext={messages.mondayHelptext} />} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Checkbox checked={hoursLine.tuesday}
                        onChange={(e: any, value: boolean) => onChange('tuesday', value)} inputProps={{ 'aria-label': 'controlled' }} />} label={intl.formatMessage(messages.tuesday)} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Checkbox checked={hoursLine.wednesday}
                        onChange={(e: any, value: boolean) => onChange('wednesday', value)} inputProps={{ 'aria-label': 'controlled' }} />} label={intl.formatMessage(messages.wednesday)} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Checkbox checked={hoursLine.thursday}
                        onChange={(e: any, value: boolean) => onChange('thursday', value)} inputProps={{ 'aria-label': 'controlled' }} />} label={intl.formatMessage(messages.thursday)} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Checkbox checked={hoursLine.friday}
                        onChange={(e: any, value: boolean) => onChange('friday', value)} inputProps={{ 'aria-label': 'controlled' }} />} label={intl.formatMessage(messages.friday)} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Checkbox checked={hoursLine.saturday}
                        onChange={(e: any, value: boolean) => onChange('saturday', value)} inputProps={{ 'aria-label': 'controlled' }} />} label={intl.formatMessage(messages.saturday)} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Checkbox checked={hoursLine.sunday}
                        onChange={(e: any, value: boolean) => onChange('sunday', value)} inputProps={{ 'aria-label': 'controlled' }} />} label={intl.formatMessage(messages.sunday)} />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={hoursLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === hoursLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...hoursLine.unitType,
                                unitGroupId: obj?.id })}
                            placeholder={messages.unitGroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={hoursLine.unitType?.id}
                            compareFn={(o: UnitSubGroup) => o.id === hoursLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...hoursLine.unitType,
                                unitSubGroupId: obj?.id })}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={hoursLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={hoursLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === hoursLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...hoursLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unittype}
                            isLoading={hoursLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={hoursLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        type="number"
                        label={messages.factor}
                        value={hoursLine.factor}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.FACTOR)}
                        required
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        type="number"
                        label={messages.surcharge}
                        value={hoursLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={hoursLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={hoursLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddHoursLineFormStep1;