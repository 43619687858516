import React, { useEffect } from 'react';
import AddFactorSetCancellationStep1 from './addFactorSetCancellationStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createFactorSetCancellation, fetchFactorSetCancellationById, getFactorSetCancellation, isCreatingCancellation, isUpdatingCancellation,
    updateFactorSetCancellation, isCreatingVersion, createCancellationVersion } from '../../../../reducers/factorSetCancellationReducer';
import { cloneCancellationVersion, isCloningVersion, isUpdatingCancellationVersionStatus,
    isUpdatingVersion, updateCancellationVersion,
    updateCancellationVersionStatus } from '../../../../reducers/factorSetCancellationVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddCancellationStep2 from './addFactorSetCancellationStep2';
import AddCancellationVersionStep3 from './addFactorSetCancellationStep3';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';

interface IAddFactorSetFormProps {
    factorSetCancellationId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set cancellation'),
    add: LanguageUtils.createMessage('Add factor set cancellation'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set version status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetCancellationForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetCancellationId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetCancellationId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetCancellation = useSelector(getFactorSetCancellation);
    const prevFactorSetCancellation = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetCancellation);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingCancellation);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingCancellation);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingCancellationVersion = useSelector(isCreatingVersion);
    const prevIsCreatingCancellationVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCancellationVersion);
    const propsIsUpdatingCancellationVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingCancellationVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCancellationVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingCancellationStatus = useSelector(isUpdatingCancellationVersionStatus);
    const prevIsUpdatingCancellationStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCancellationStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingCancellationVersion === true && !propsIsCreatingCancellationVersion || prevIsUpdatingCancellationVersion === true && !propsIsUpdatingCancellationVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingCancellationStatus === true && !propsIsUpdatingCancellationStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingCancellationStatus, propsIsUpdatingCancellationVersion, propsIsCreatingCancellationVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetCancellationId) {
            dispatch(fetchFactorSetCancellationById({ factorSetId: factorSetCancellationId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetCancellation && propsFactorSetCancellation.id) {
            setFactorSet(propsFactorSetCancellation);
        }

    }, [propsFactorSetCancellation.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newCancellationVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newCancellationVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newCancellationVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetCancellationStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetCancellation(factorSet));
                }
                else {
                    dispatch(updateFactorSetCancellation(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddCancellationStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createCancellationVersion(factorSet));
                }
                else {
                    dispatch(updateCancellationVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.Cancellation} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(cloneCancellationVersion({ toVersionId: ensure(propsFactorSetCancellation.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddCancellationVersionStep3 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updateCancellationVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  