import SegmentApi from '../api/segmentApi';
import { fetchSegmentsSuccess, fetchSegmentByIdSuccess, fetchSegmentByIdError, fetchSegmentsError } from '../reducers/segmentReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import CustomerSegment from '../interfaces/output/customerSegment';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchSegmentById(action: PayloadAction<string>) {
    const segmentId = action.payload;
    try {
        const response: CustomerSegment = yield call(SegmentApi.fetchSegmentById, segmentId);
        yield put(fetchSegmentByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSegmentByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSegments() {
    try {
        const response: Pagination<CustomerSegment> = yield call(SegmentApi.fetchSegments);
        yield put(fetchSegmentsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSegmentsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('segment/fetchSegmentById', doFetchSegmentById),
    takeLatest('segment/fetchSegments', doFetchSegments)];

