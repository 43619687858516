import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { resetPriceLists } from '../../reducers/priceListReducer';
import { resetAgreements } from '../../reducers/agreementsReducer';
import AddAgreementVersionPricelistForm from './addAgreementVersionPricelistFrom';
import { FormType } from '../../constants/constants';

interface IAddAgreementVersionPricelistProps {
    classes: any;
    theme: Theme;
    match: any;
    resetAgreements: any;
    resetPriceLists: any;
    steps: Array<number>;
    type: FormType;
    priceListId?: number;
    agreementId?: number;
    agreementVersionPricelistId?: number;
    priceListVersionId?: number;
    agreementVersionId?: number;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetPriceLists: () => dispatch(resetPriceLists()),
    resetAgreements: () => dispatch(resetAgreements())
});

const mapStoreToProps = (store: RootState, ownProps: IAddAgreementVersionPricelistProps) => {
    return {
        agreementId: ownProps.match.params?.agreementId ? Number(ownProps.match.params?.agreementId) : undefined,
        priceListId: ownProps.match.params?.priceListId ? Number(ownProps.match.params?.priceListId) : undefined,
        agreementVersionPricelistId: ownProps.match.params?.agreementVersionPricelistId ? Number(ownProps.match.params?.agreementVersionPricelistId) : undefined,
        agreementVersionId: ownProps.match.params?.agreementVersionId ? Number(ownProps.match.params?.agreementVersionId) : undefined,
        priceListVersionId: ownProps.match.params?.priceListVersionId ? Number(ownProps.match.params?.priceListVersionId) : undefined
    };
};

class AddAgreementVersionPricelist extends Component<IAddAgreementVersionPricelistProps> {

    componentWillUnmount() {
        const { resetPriceLists, resetAgreements } = this.props;

        resetAgreements();
        resetPriceLists();
    }

    render() {
        const { classes, steps, priceListId, agreementId, type, agreementVersionId, priceListVersionId, agreementVersionPricelistId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddAgreementVersionPricelistForm priceListId={priceListId} agreementId={agreementId} steps={steps} type={type}
                            agreementVersionId={agreementVersionId} priceListVersionId={priceListVersionId} agreementVersionPricelistId={agreementVersionPricelistId} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddAgreementVersionPricelist));