import CountryApi from '../api/countryApi';
import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchCountriesError, fetchCountriesSuccess, fetchCountryByIdError, fetchCountryByIdSuccess } from '../reducers/countryReducer';
import Country from '../interfaces/output/countryOut';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchCountry(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: Country = yield call(CountryApi.fetchCountryById, customerId);
        yield put(fetchCountryByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCountryByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCountries() {
    try {
        const response: Pagination<Country> = yield call(CountryApi.fetchCountries);
        yield put(fetchCountriesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCountriesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('country/fetchCountryById', doFetchCountry),
    takeLatest('country/fetchCountries', doFetchCountries)
];

