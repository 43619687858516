import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Theme } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import DataGridComponent from '../../common/dataGrid';
import TableUtils from '../../../utils/tableUtils';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import UrlConstants from '../../../constants/UrlConstants';
import CustomStringLink from '../../common/customStringLink';
import CustomLink from '../../common/customLink';
import NullableFormattedDate from '../../common/nullableFormattedDate';

interface InvoiceLineListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    product: LanguageUtils.createMessage('Product'),
    service: LanguageUtils.createMessage('Service'),
    order: LanguageUtils.createMessage('Order'),
    agreementVersion: LanguageUtils.createMessage('Agreement version'),
    pricelistVersion: LanguageUtils.createMessage('Price list version'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    productDynamicsId: LanguageUtils.createMessage('Dynamics id'),
    departureType: LanguageUtils.createMessage('Departure type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    // currency: LanguageUtils.createMessage('Currency'),
    invoiceLinePrice: LanguageUtils.createMessage('Invoice line price'),
    serviceDate: LanguageUtils.createMessage('Service date'),
    serviceDescription: LanguageUtils.createMessage('Service description'),
    cancellationCode: LanguageUtils.createMessage('Cancellation code'),
    unitNo: LanguageUtils.createMessage('Unit number')
};
const InvoiceLinesList = forwardRef((props: InvoiceLineListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;
    const intl = useIntl();

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'order_orderNumber',
                headerName: `${intl.formatMessage(messages.order)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params).order?.orderNumber ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('order', hiddenColumns)
            },
            {
                field: 'unitNo',
                headerName: `${intl.formatMessage(messages.unitNo)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params).unitNo ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitNo', hiddenColumns)
            },
            {
                field: 'serviceDate',
                headerName: `${intl.formatMessage(messages.serviceDate)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.serviceDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceDate', hiddenColumns)
            },
            // {
            //     field: 'currency_name',
            //     headerName: `${intl.formatMessage(messages.currency)}`,
            //     flex: 1,
            //     align: 'left',
            //     headerAlign: 'left',
            //     sortable: true,
            //     renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}
            //         value={applyRowFn(params).currency?.name ?? '-'} />,
            //     hide: hiddenColumns && TableUtils.hideColumn('currency_name', hiddenColumns)
            // },
            {
                field: 'price',
                headerName: `${intl.formatMessage(messages.invoiceLinePrice)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params).price ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('price', hiddenColumns)
            },
            {
                field: 'serviceDescription',
                headerName: `${intl.formatMessage(messages.serviceDescription)}`,
                flex: 3,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params).serviceDescription ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('serviceDescription', hiddenColumns)
            },
            {
                field: 'cancellationCode',
                headerName: `${intl.formatMessage(messages.cancellationCode)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.INVOICE_LINES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params).cancellationCode ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('cancellationCode', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            ref={dataGridRef} checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default InvoiceLinesList;
