import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetDepartureVersionApi from '../api/factorSetDepartureVersionApi';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { cloneDepartureVersionError, cloneDepartureVersionSuccess, fetchDepartureVersionByIdError,
    fetchDepartureVersionByIdSuccess, fetchDepartureVersionsError, fetchDepartureVersionsSuccess, updateDepartureVersionError, updateDepartureVersionStatusError,
    updateDepartureVersionStatusSuccess, updateDepartureVersionSuccess } from '../reducers/factorSetDepartureVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchFactorSetDepartureVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetDepartureVersionApi.fetchFactorSetDepartureVersionById, agreementVersionId);

        yield put(fetchDepartureVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDepartureVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDepartureVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetDepartureVersionApi.fetchFactorSetDepartureVersions, id, paging);
        yield put(fetchDepartureVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDepartureVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDepartureVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetDepartureVersionApi.updateFactorSetDepartureVersionStatus, agreementVersion);

        yield put(updateDepartureVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDepartureVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDepartureVersion(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetDepartureVersionApi.updateFactorSetDepartureVersion, agreementVersion);

        yield put(updateDepartureVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDepartureVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetDepartureVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetDepartureVersionApi.cloneFactorSetDepartureVersion, toVersionId, fromVersionId);

        yield put(cloneDepartureVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneDepartureVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchDepartureVersionById', doFetchFactorSetDepartureVersion),
    takeLatest('factorSetVersion/fetchDepartureVersions', doFetchFactorSetDepartureVersions),
    takeLatest('factorSetVersion/updateDepartureVersionStatus', doUpdateFactorSetDepartureVersionStatus),
    takeLatest('factorSetVersion/updateDepartureVersion', doUpdateFactorSetDepartureVersion),
    takeLatest('factorSetVersion/cloneDepartureVersion', doCloneFactorSetDepartureVersion)
];