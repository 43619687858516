import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type PriorityVersionStatusState = {
    priorityVersionStatus: FactorSetVersionStatusOut;
    priorityVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: PriorityVersionStatusState = {
    priorityVersionStatus: {} as FactorSetVersionStatusOut,
    priorityVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const priorityVersionStatusSlice = createSlice({
    name: 'priorityVersionStatus',

    initialState,
    reducers: {
        fetchPriorityVersionStatuses: (state: PriorityVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchPriorityVersionStatusesSuccess: (state: PriorityVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.priorityVersionStatuses = action.payload;
        },
        fetchPriorityVersionStatusesError: (state: PriorityVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchPriorityVersionStatusById: (state: PriorityVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchPriorityVersionStatusByIdSuccess: (state: PriorityVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.priorityVersionStatus = action.payload;
        },
        fetchPriorityVersionStatusByIdError: (state: PriorityVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updatePriorityVersionStatus: (state: PriorityVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updatePriorityVersionStatusSuccess: (state: PriorityVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.priorityVersionStatus = action.payload;
        },
        updatePriorityVersionStatusError: (state: PriorityVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivatePriorityVersionStatus: (state: PriorityVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivatePriorityVersionStatusSuccess: (state: PriorityVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.priorityVersionStatus = action.payload;
        },
        deactivatePriorityVersionStatusError: (state: PriorityVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetPriorityVersionStatuses: (state: PriorityVersionStatusState) => {
            state.priorityVersionStatuses = initialState.priorityVersionStatuses;
        }
    }
});

export const { fetchPriorityVersionStatusById, fetchPriorityVersionStatusByIdSuccess, fetchPriorityVersionStatusByIdError, updatePriorityVersionStatus,
    updatePriorityVersionStatusSuccess, updatePriorityVersionStatusError, fetchPriorityVersionStatuses, fetchPriorityVersionStatusesSuccess,
    fetchPriorityVersionStatusesError, deactivatePriorityVersionStatus, deactivatePriorityVersionStatusSuccess, deactivatePriorityVersionStatusError,
    resetPriorityVersionStatuses } = priorityVersionStatusSlice.actions;

export const isLoadingPriorityVersionStatus = (state: RootState): boolean => state.priorityVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.priorityVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.priorityVersionStatusReducer.isDeleted;
export const getPriorityVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.priorityVersionStatusReducer.priorityVersionStatus;
export const getPriorityVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.priorityVersionStatusReducer.priorityVersionStatuses;

export default priorityVersionStatusSlice.reducer;