import { List } from 'immutable';
import Agreement from '../../../interfaces/output/agreement';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class AgreementStep1Validation extends ValidationUtils {

    static validateAgreementForm(agreement: Agreement): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const { name
        } = agreement;

        const requiredFields =
            this.validateInputStringLength(name);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}