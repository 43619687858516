import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AgreementVersion from '../interfaces/output/agreementVersion';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import TransitDiscount from '../interfaces/output/transitDiscount';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type AgreementVersionState = {
    agreementVersion: AgreementVersion;
    transitDiscounts: Pagination<TransitDiscount>;
    transitDiscount: TransitDiscount;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCreatingDiscount: boolean;
    isCloningVersion: boolean;
    error: HttpErrorResponse | undefined;isLoadingDiscounts: boolean;
};

const initialState: AgreementVersionState = {
    agreementVersion: {} as AgreementVersion,
    transitDiscounts: { content: [] as Array<TransitDiscount> } as Pagination<TransitDiscount>,
    transitDiscount: {} as TransitDiscount,
    isLoading: false,
    isLoadingDiscounts: false,
    isCreatingDiscount: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    error: undefined
};

export const customerAgreementVersionSlice = createSlice({
    name: 'agreementVersion',

    initialState,
    reducers: {
        fetchAgreementVersionById: (state: AgreementVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchAgreementVersionByIdSuccess: (state: AgreementVersionState, action: PayloadAction<AgreementVersion>) => {
            state.isLoading = false;
            state.agreementVersion = action.payload;
        },
        fetchAgreementVersionByIdError: (state: AgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchAgreementVersionTransitDiscounts: (state: AgreementVersionState, _action: PayloadAction<{agreementVersionId: string, paging: Paging}>) => {
            state.isLoadingDiscounts = true;
        },
        fetchAgreementVersionTransitDiscountsSuccess: (state: AgreementVersionState, action: PayloadAction<Pagination<TransitDiscount>>) => {
            state.isLoadingDiscounts = false;
            state.transitDiscounts = action.payload;
        },
        fetchAgreementVersionTransitDiscountsError: (state: AgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDiscounts = false;
            state.error = action.payload;
        },
        updateAgreementVersionStatus: (state: AgreementVersionState, _action: PayloadAction<AgreementVersion>) => {
            state.isUpdatingStatus = true;
        },
        updateAgreementVersionStatusSuccess: (state: AgreementVersionState, action: PayloadAction<AgreementVersion>) => {
            state.isUpdatingStatus = false;
            state.agreementVersion = action.payload;
        },
        updateAgreementVersionStatusError: (state: AgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        createAgreementVersionTransitDiscount: (state: AgreementVersionState, _action: PayloadAction<TransitDiscount>) => {
            state.isCreatingDiscount = true;
        },
        createAgreementVersionTransitDiscountSuccess: (state: AgreementVersionState, action: PayloadAction<TransitDiscount>) => {
            state.isCreatingDiscount = false;
            state.transitDiscount = action.payload;
        },
        createAgreementVersionTransitDiscountError: (state: AgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingDiscount = false;
            state.error = action.payload;
        },
        updateAgreementVersion: (state: AgreementVersionState, _action: PayloadAction<AgreementVersion>) => {
            state.isUpdatingVersion = true;
        },
        updateAgreementVersionSuccess: (state: AgreementVersionState, action: PayloadAction<AgreementVersion>) => {
            state.isUpdatingVersion = false;
            state.agreementVersion = action.payload;
        },
        updateAgreementVersionError: (state: AgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        cloneAgreementVersion: (state: AgreementVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number,
             percentAdjustmentSpecialPrices: number, percentAdjustmentTransitDiscount: number }>) => {
            state.isCloningVersion = true;
        },
        cloneAgreementVersionSuccess: (state: AgreementVersionState, action: PayloadAction<AgreementVersion>) => {
            state.isCloningVersion = false;
            state.agreementVersion = action.payload;
        },
        cloneAgreementVersionError: (state: AgreementVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetAgreementVersion: (state: AgreementVersionState) => {
            state.agreementVersion = initialState.agreementVersion;
        },
        resetAgreementVersionTransitDiscounts: (state: AgreementVersionState) => {
            state.transitDiscounts = initialState.transitDiscounts;
        }
    }
});

export const { fetchAgreementVersionById, fetchAgreementVersionByIdSuccess, fetchAgreementVersionByIdError, updateAgreementVersionStatus, updateAgreementVersionStatusSuccess,
    updateAgreementVersionStatusError, fetchAgreementVersionTransitDiscounts, fetchAgreementVersionTransitDiscountsSuccess, fetchAgreementVersionTransitDiscountsError, cloneAgreementVersion,
    createAgreementVersionTransitDiscount, createAgreementVersionTransitDiscountSuccess, createAgreementVersionTransitDiscountError, updateAgreementVersion, cloneAgreementVersionSuccess,
    updateAgreementVersionSuccess, updateAgreementVersionError, resetAgreementVersion, resetAgreementVersionTransitDiscounts, cloneAgreementVersionError } = customerAgreementVersionSlice.actions;

export const isLoadingAgreementVersion = (state: RootState): boolean => state.agreementVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.agreementVersionReducer.isCloningVersion;
export const isUpdatingAgreementStatus = (state: RootState): boolean => state.agreementVersionReducer.isUpdatingStatus;
export const isLoadingDiscounts = (state: RootState): boolean => state.agreementVersionReducer.isLoadingDiscounts;
export const isUpdating = (state: RootState): boolean => state.agreementVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.agreementVersionReducer.isUpdatingVersion;
export const isCreatingDiscount = (state: RootState): boolean => state.agreementVersionReducer.isCreatingDiscount;
export const getAgreementVersion = (state: RootState): AgreementVersion => state.agreementVersionReducer.agreementVersion;
export const getAgreementVersionDiscounts = (state: RootState): Pagination<TransitDiscount> => state.agreementVersionReducer.transitDiscounts;
export const getAgreementVersionDiscount = (state: RootState): TransitDiscount => state.agreementVersionReducer.transitDiscount;

export default customerAgreementVersionSlice.reducer;