import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Terminal from '../interfaces/output/terminal';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import TerminalFilters from '../interfaces/filters/terminaleFilters';
import PriceListTerminal from '../interfaces/output/priceListTerminal';

export type TerminalState = {
    terminal: Terminal;
    isLoading: boolean;
    isCreating: boolean;
    terminals: Pagination<Terminal>;
    error: HttpErrorResponse | undefined;
    isUpdating: boolean;
    isDeleted: boolean;
};

const initialState: TerminalState = {
    terminal: {} as Terminal,
    terminals: { content: [] as Array<Terminal> } as Pagination<Terminal>,
    isLoading: false,
    isCreating: false,
    error: undefined,
    isUpdating: false,
    isDeleted: false
};

export const terminalSlice = createSlice({
    name: 'terminal',

    initialState,
    reducers: {
        fetchTerminals: (state: TerminalState, _action: PayloadAction<{ paging: Paging }>) => {
            state.isLoading = true;
        },
        fetchTerminalsWithFilters: (state: TerminalState, _action: PayloadAction<{ paging: Paging, filters: TerminalFilters }>) => {
            state.isLoading = true;
        },
        fetchTerminalsSuccess: (state: TerminalState, action: PayloadAction<Pagination<Terminal>>) => {
            state.isLoading = false;
            state.terminals = action.payload;
        },
        fetchTerminalsError: (state: TerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchTerminalById: (state: TerminalState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchTerminalByIdSuccess: (state: TerminalState, action: PayloadAction<Terminal>) => {
            state.isLoading = false;
            state.terminal = action.payload;
        },
        fetchTerminalByIdError: (state: TerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createTerminal: (state: TerminalState, _action: PayloadAction<Terminal>) => {
            state.isCreating = true;
        },
        createTerminalSuccess: (state: TerminalState, action: PayloadAction<Terminal>) => {
            state.isCreating = false;
            state.terminal = action.payload;
        },
        createTerminalError: (state: TerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateTerminal: (state: TerminalState, _action: PayloadAction<Terminal>) => {
            state.isUpdating = true;
        },
        updateTerminalSuccess: (state: TerminalState, action: PayloadAction<Terminal>) => {
            state.isUpdating = false;
            state.terminal = action.payload;
        },
        updateTerminalError: (state: TerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetTerminals: (state: TerminalState) => {
            state.terminals = initialState.terminals;
        },
        resetTerminal: (state: TerminalState) => {
            state.terminal = initialState.terminal;
        },
        deleteTerminal: (state: TerminalState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deleteTerminalSuccess: (state: TerminalState, action: PayloadAction<Terminal>) => {
            state.isDeleted = false;
            state.terminal = action.payload;
        },
        deleteTerminalError: (state: TerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        }
    }
});

export const { fetchTerminals, fetchTerminalsSuccess, fetchTerminalsError, fetchTerminalById, fetchTerminalByIdError, fetchTerminalByIdSuccess, createTerminal,
    createTerminalSuccess, createTerminalError, resetTerminal, resetTerminals, fetchTerminalsWithFilters,
    updateTerminal, updateTerminalError, updateTerminalSuccess, deleteTerminal, deleteTerminalError, deleteTerminalSuccess } = terminalSlice.actions;

export const isLoadingTerminal = (state: RootState): boolean => state.terminalReducer.isLoading;
export const isCreating = (state: RootState): boolean => state.terminalReducer.isCreating;
export const getTerminals = (state: RootState): Pagination<Terminal> => state.terminalReducer.terminals;
export const getTerminal = (state: RootState): Terminal => state.terminalReducer.terminal;
export const isUpdatingTerminal = (state: RootState): boolean => state.terminalReducer.isUpdating;
export const isCreatingTerminal = (state: RootState): boolean => state.terminalReducer.isCreating;
export const isDeleted = (state: RootState): boolean => state.terminalReducer.isDeleted;

export default terminalSlice.reducer;