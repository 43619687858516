import { List } from 'immutable';
import InputLengthConstants from '../../../constants/inputLengthConstants';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const inputsMaxLengths = {
    name: InputLengthConstants.String60
};

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class FactorSetStep1Validation extends ValidationUtils {
    
    static validateFactorSetForm(factorSet: FactorSetOut): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            unitSubGroup,
            productType
        } = factorSet;

        const requiredFields =
            this.validateName(name) &&
            this.validateInputNumber(unitSubGroup?.unitGroupId) &&
            this.validateInputNumber(unitSubGroup?.id) &&
            this.validateInputNumber(productType?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }

    static validateName = (inputString?: string): boolean => {
        return this.validateInputStringLength(inputString, {
            inputLength: inputsMaxLengths.name
        });
    }
}
