import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import OrderUnitStatus from '../interfaces/output/orderUnitStatus';

export type UnitStatusState = {
    unitStatus: OrderUnitStatus;
    unitStatuses: Pagination<OrderUnitStatus>;
    isLoadingUnitStatus: boolean;
    isLoadingUnitStatuses: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: UnitStatusState = {
    unitStatus: {} as OrderUnitStatus,
    unitStatuses: { content: [] as Array<OrderUnitStatus> } as Pagination<OrderUnitStatus>,
    isLoadingUnitStatus: false,
    isLoadingUnitStatuses: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const unitStatusSlice = createSlice({
    name: 'unitStatus',

    initialState,
    reducers: {
        fetchUnitStatuses: (state: UnitStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingUnitStatuses = true;
        },
        fetchUnitStatusesSuccess: (state: UnitStatusState, action: PayloadAction<Pagination<OrderUnitStatus>>) => {
            state.isLoadingUnitStatuses = false;
            state.unitStatuses = action.payload;
        },
        fetchUnitStatusesError: (state: UnitStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitStatuses = false;
            state.error = action.payload;
        },
        fetchUnitStatusById: (state: UnitStatusState, _action: PayloadAction<number>) => {
            state.isLoadingUnitStatus = true;
        },
        fetchUnitStatusByIdSuccess: (state: UnitStatusState, action: PayloadAction<OrderUnitStatus>) => {
            state.isLoadingUnitStatus = false;
            state.unitStatus = action.payload;
        },
        fetchUnitStatusByIdError: (state: UnitStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitStatus = false;
            state.error = action.payload;
        },
        createUnitStatus: (state: UnitStatusState, _action: PayloadAction<OrderUnitStatus>) => {
            state.isCreating = true;
        },
        createUnitStatusSuccess: (state: UnitStatusState, action: PayloadAction<OrderUnitStatus>) => {
            state.isCreating = false;
            state.unitStatus = action.payload;
        },
        createUnitStatusError: (state: UnitStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateUnitStatus: (state: UnitStatusState, _action: PayloadAction<OrderUnitStatus>) => {
            state.isUpdating = true;
        },
        updateUnitStatusSuccess: (state: UnitStatusState, action: PayloadAction<OrderUnitStatus>) => {
            state.isUpdating = false;
            state.unitStatus = action.payload;
        },
        updateUnitStatusError: (state: UnitStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteUnitStatus: (state: UnitStatusState, _action: PayloadAction<OrderUnitStatus>) => {
            state.isDeleted = true;
        },
        deleteUnitStatusSuccess: (state: UnitStatusState, action: PayloadAction<OrderUnitStatus>) => {
            state.isDeleted = false;
            state.unitStatus = action.payload;
        },
        deleteUnitStatusError: (state: UnitStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetUnitStatus: (state: UnitStatusState) => {
            state.unitStatuses = initialState.unitStatuses;
        }
    }
});

export const { fetchUnitStatuses, fetchUnitStatusesSuccess, fetchUnitStatusesError, fetchUnitStatusById, fetchUnitStatusByIdSuccess, fetchUnitStatusByIdError, resetUnitStatus, createUnitStatus,
    createUnitStatusSuccess, createUnitStatusError, updateUnitStatus, updateUnitStatusError, updateUnitStatusSuccess, deleteUnitStatus, deleteUnitStatusError, deleteUnitStatusSuccess }
    = unitStatusSlice.actions;

export const isLoadingUnitStatus = (state: RootState): boolean => state.unitStatusReducer.isLoadingUnitStatus;
export const isLoadingUnitStatuses = (state: RootState): boolean => state.unitStatusReducer.isLoadingUnitStatuses;
export const isUpdating = (state: RootState): boolean => state.unitStatusReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.unitStatusReducer.isDeleted;
export const isCreating = (state: RootState): boolean => state.unitStatusReducer.isCreating;
export const getOrderUnitStatuses = (state: RootState): Pagination<OrderUnitStatus> => state.unitStatusReducer.unitStatuses;
export const getOrderUnitStatus = (state: RootState): OrderUnitStatus => state.unitStatusReducer.unitStatus;

export default unitStatusSlice.reducer;