import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../common/widgets/loader';
import WorkflowUtils from '../../utils/workflowUtils';
import { fetchInvoiceFrequencies, getInvoicesFrequency, isLoadingInvoiceFrequency } from '../../reducers/invoiceFrequencyReducer';
import { fetchPaymentDeadlines, getPaymentDeadlines, isLoadingPaymentDeadline } from '../../reducers/paymentDeadlineReducer';
import { fetchInvoiceSeparations, getInvoicesSeparation, isLoadingInvoicesSeparation } from '../../reducers/invoiceSeparationReducer';
import { isCreatingCustomer, isLoadingCustomer, isUpdatingCustomer } from '../../reducers/customerReducer';
import Customer from '../../interfaces/output/customer';
import InvoiceFrequency from '../../interfaces/output/invoiceFrequency';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import PaymentDeadline from '../../interfaces/output/paymentDeadline';
import InvoiceSeparation from '../../interfaces/output/invoiceSeparation';
import CustomerStep2Validation from './validation/customerStep2Validation';
import GenericDatePicker from '../common/widgets/genericDatePicker';

interface AddCustomerStep2Props {
    customer: Customer;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    invoiceFrequency: LanguageUtils.createMessage('Invoice frequency'),
    paymentDeadline: LanguageUtils.createMessage('Payment deadline'),
    invoiceSeparation: LanguageUtils.createMessage('Invoice separation'),
    invoiceFrequencyHelpText: LanguageUtils.createMessage('Customer invoice frequency help text'),
    paymentDeadlineHelpText: LanguageUtils.createMessage('Customer payment deadline help text'),
    nextInvoiceDate: LanguageUtils.createMessage('Next invoice date')
};

const ATTRIBUTES = {
    FREQUENCY: 'invoiceFrequency',
    SEPARATION: 'invoiceSeparation'
};

const AddCustomerStep2 = forwardRef((props: AddCustomerStep2Props, ref: any): JSX.Element => {
    const { customer, onChange, showValidationError } = props;
    const dispatch = useDispatch();
    const invoicesFrequency = useSelector(getInvoicesFrequency).content;
    const paymentDeadlines = useSelector(getPaymentDeadlines).content;
    const invoiceSeparations = useSelector(getInvoicesSeparation).content;
    const isLoadingObjects = [useSelector(isCreatingCustomer), useSelector(isLoadingCustomer), useSelector(isLoadingPaymentDeadline),
        useSelector(isLoadingInvoicesSeparation), useSelector(isLoadingInvoiceFrequency), useSelector(isUpdatingCustomer)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchInvoiceFrequencies());
        dispatch(fetchPaymentDeadlines());
        dispatch(fetchInvoiceSeparations());
    }, [dispatch]);
        
    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.FREQUENCY: {
                isValid = CustomerStep2Validation.validateInputString(customer.invoiceFrequency?.name);
                break;
            }
            case ATTRIBUTES.SEPARATION: {
                isValid = CustomerStep2Validation.validateInputString(customer.invoiceSeparation?.name);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<InvoiceFrequency>
                            options={invoicesFrequency}
                            value={customer.invoiceFrequency}
                            onChange={(obj: InvoiceFrequency | null) => onChange('invoiceFrequency', obj)}
                            placeholder={messages.invoiceFrequency}
                            error={setValidationState(ATTRIBUTES.FREQUENCY)}
                            required
                            helperText={messages.invoiceFrequencyHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PaymentDeadline>
                            options={paymentDeadlines}
                            value={customer.paymentDeadline}
                            onChange={(obj: PaymentDeadline | null) => onChange('paymentDeadline', obj)}
                            placeholder={messages.paymentDeadline}
                            helperText={messages.paymentDeadlineHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<InvoiceSeparation>
                            options={invoiceSeparations}
                            value={customer.invoiceSeparation}
                            onChange={(obj: InvoiceSeparation | null) => onChange('invoiceSeparation', obj)}
                            placeholder={messages.invoiceSeparation}
                            error={setValidationState(ATTRIBUTES.SEPARATION)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="nextInvoiceDate"
                            label={messages.nextInvoiceDate}
                            value={customer?.nextInvoiceDate}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddCustomerStep2;