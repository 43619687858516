import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';

export type ExportInvoiceState = {
    error: HttpErrorResponse | undefined;
    isExporting: boolean;
};

const initialState: ExportInvoiceState = {
    error: undefined,
    isExporting: false
};

export const exportInvoicesSlice = createSlice({
    name: 'd365Invoice',

    initialState,
    reducers: {
        exportInvoices: (state: ExportInvoiceState, _action: PayloadAction<number>) => {
            state.isExporting = true;
        },
        exportInvoicesSuccess: (state: ExportInvoiceState) => {
            state.isExporting = false;
        },
        exportInvoicesError: (state: ExportInvoiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExporting = false;
            state.error = action.payload;
        }
    }
});

export const { exportInvoices, exportInvoicesSuccess, exportInvoicesError } = exportInvoicesSlice.actions;

export const isExportingInvoices = (state: RootState): boolean => state.exportInvoicesReducer.isExporting;

export default exportInvoicesSlice.reducer;