import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DepartureType from '../interfaces/output/departureType';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type DepartureTypeState = {
    isLoading: boolean;
    departureTypes: Pagination<DepartureType>;
    error: HttpErrorResponse | undefined;
};

const initialState: DepartureTypeState = {
    departureTypes: { content: [] as Array<DepartureType> } as Pagination<DepartureType>,
    isLoading: false,
    error: undefined
};

export const departureTypeSlice = createSlice({
    name: 'departureType',

    initialState,
    reducers: {
        fetchDepartureTypes: (state: DepartureTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchDepartureTypesSuccess: (state: DepartureTypeState, action: PayloadAction<Pagination<DepartureType>>) => {
            state.isLoading = false;
            state.departureTypes = action.payload;
        },
        fetchDepartureTypesError: (state: DepartureTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchDepartureTypes, fetchDepartureTypesSuccess, fetchDepartureTypesError } = departureTypeSlice.actions;

export const isLoadingDepartureTypes = (state: RootState): boolean => state.departureTypeReducer.isLoading;
export const getDepartureTypes = (state: RootState): Pagination<DepartureType> => state.departureTypeReducer.departureTypes;

export default departureTypeSlice.reducer;