import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import CurrencyApi from '../api/currencyApi';
import Currency from '../interfaces/output/currency';
import { fetchCurrenciesError, fetchCurrenciesSuccess, fetchCurrencyByIdError, fetchCurrencyByIdSuccess } from '../reducers/currencyReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchCurrency(action: PayloadAction<string>) {
    const currencyId = action.payload;
    try {
        const response: Currency = yield call(CurrencyApi.fetchCurrencyById, currencyId);
        yield put(fetchCurrencyByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCurrencyByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCurrencies(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<Currency> = yield call(CurrencyApi.fetchCurencies, paging);
        yield put(fetchCurrenciesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCurrenciesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('currency/fetchCurrencyById', doFetchCurrency),
    takeLatest('currency/fetchCurrencies', doFetchCurrencies)];

