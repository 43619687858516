import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../constants/constants';
import GtsOrderUnitServiceForm from './gtsOrderUnitServiceForm';
import { resetGTSOrderUnitService } from '../../reducers/gtsUnitServiceReducer';

interface IAddGtsOrderUnitServiceFormProps {
    classes: any;
    theme: Theme;
    match: any;
    resetGTSOrderUnitService: any;
    steps: Array<number>;
    gtsOrderUnitId?: number;
    type: FormType;
    unitServiceId?: number;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetGTSOrderUnitService: () => dispatch(resetGTSOrderUnitService())
});

const mapStoreToProps = (store: RootState, ownProps: IAddGtsOrderUnitServiceFormProps) => {
    return {
        unitServiceId: ownProps.match.params?.unitServiceId ? Number(ownProps.match.params?.unitServiceId) : undefined,
        gtsOrderUnitId: ownProps.match.params?.gtsOrderUnitId ? Number(ownProps.match.params?.gtsOrderUnitId) : undefined
    };
};

class AddGtsOrderUnitService extends Component<IAddGtsOrderUnitServiceFormProps> {

    componentWillUnmount() {
        const { resetGTSOrderUnitService } = this.props;

        resetGTSOrderUnitService();
    }

    render() {
        const { classes, steps, gtsOrderUnitId, unitServiceId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <GtsOrderUnitServiceForm gtsOrderUnitId={gtsOrderUnitId} unitServiceId={unitServiceId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddGtsOrderUnitService));