import { List } from 'immutable';
import OrderUnitService from '../../../../interfaces/output/orderUnitService';
import { ensure } from '../../../../utils/arrayUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationModel from '../../../../utils/validationModel';
import ValidationUtils from '../../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range')
};

export default class OrderUnitServiceStep2Validation extends ValidationUtils {

    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validateInputDateStart(unitService: OrderUnitService): boolean {
        const dateStart = unitService.serviceDate;
        const dateTo = unitService.serviceEndDate;
        
        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(unitService: OrderUnitService): boolean {
        const dateStart = unitService.serviceDate;
        const dateTo = unitService.serviceEndDate;
        
        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateUnitServiceForm(unitService: OrderUnitService): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { serviceDate,
            amount,
            currency,
            manualPrice
        } = unitService;

        const requiredFields =
            this.validateInputNumber(amount) &&
            this.validateRequiredDate(serviceDate) &&
            this.validateInputNumber(currency?.id, { required: manualPrice ? true : false });

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = unitService.serviceEndDate;
        const validDates = endDate ? this.validateInputDateStart(unitService) &&
        this.validateInputDateEnd(unitService) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        return validationModel;
    }
}
