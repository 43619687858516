import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import OrdersNotYetInvoicedFilters from '../../../interfaces/filters/orderNotYetInvoicedFilters';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchUnitGroups } from '../../../reducers/unitGroupReducer';
import PageUtils from '../../../utils/pageUtils';
import { fetchCustomers, getCustomers } from '../../../reducers/customerReducer';
import CustomerFilters from '../../../interfaces/output/filters';
import Customer from '../../../interfaces/output/customer';
import Currency from '../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../reducers/currencyReducer';
// import { DatePicker } from '@mui/lab';
interface IFilterProps {
    filters: OrdersNotYetInvoicedFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    customerName: LanguageUtils.createMessage('Customer Name'),
    customer: LanguageUtils.createMessage('Customer'),
    departureYear: LanguageUtils.createMessage('Departure year'),
    departureMonth: LanguageUtils.createMessage('Departure month'),
    serviceGroup: LanguageUtils.createMessage('Service group'),
    ousStatus: LanguageUtils.createMessage('Ous status'),
    currency: LanguageUtils.createMessage('Currency'),
    startDate: LanguageUtils.createMessage('from date'),
    endDate: LanguageUtils.createMessage('to date'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function OrdersNotYetInvoicedFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const paging = PageUtils.getMaxPaging();
    const customers = useSelector(getCustomers).content;
    const currencies = useSelector(getCurrencies).content;
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchProductArenaTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchCustomers({
            paging,
            filters: {} as CustomerFilters
        }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <GenericAutocomplete<Customer>
                            options={customers}
                            value={filters.customerId}
                            onChange={(obj: Customer | null) => onChange('customerId', obj?.id)}
                            placeholder={messages.customer}
                            compareFn={(o: Customer) => o.id === filters.customerId}
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="departureYear"
                                label={messages.departureYear}
                                onChange={onChange}
                                value={filters.departureYear}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="departureMonth"
                                label={messages.departureMonth}
                                onChange={onChange}
                                value={filters.departureMonth}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="serviceGroupNameNo"
                                label={messages.serviceGroup}
                                onChange={onChange}
                                value={filters.serviceGroupNameNo}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="ousStatusName"
                                label={messages.ousStatus}
                                onChange={onChange}
                                value={filters.ousStatusName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="fromDepartureDate"
                            label={messages.startDate}
                            value={filters?.fromDepartureDate}
                            onChange={onChange}
                            type="start"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="toDepartureDate"
                            label={messages.endDate}
                            value={filters?.toDepartureDate}
                            onChange={onChange}
                            type="end"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={filters.currencyCode}
                            onChange={(obj: Currency | null) => onChange('currencyCode', obj?.code)}
                            placeholder={messages.currency}
                            compareFn={(o: Currency) => o.code === filters.currencyCode}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}