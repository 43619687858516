/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import { Container, Paper } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import Paging from '../../interfaces/common/paging';
import Pagination from '../../interfaces/common/pagination';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import LanguageUtils from '../../utils/LanguageUtils';
import InvoiceLineOut from '../../interfaces/output/invoiceLineOut';
import InvoiceOut from '../../interfaces/output/invoiceOut';
import {
    creditInvoice, deactivateInvoice, exportInvoices, fetchInvoiceById, fetchInvoiceLines, getInvoice, getInvoiceLines, isCrediting,
    isDeletingInvoice, isLoadingInvoice, isLoadingInvoiceLines,
    isUpdatingInvoice,
    sendInvoiceEmail, exportInvoicesPdf, fetchCustomerReport, isLoadingCustomerReport, getCustomerReport
} from '../../reducers/invoiceReducer';
import InvoiceLineFiltersIn from '../../interfaces/filters/invoiceLineFilters';
import { deactivateInvoiceLine, isDeletingInvoiceLine, isUpdatingInvoiceLine, isCreatingInvoiceLine } from '../../reducers/invoiceLineReducer';
import ViewInvoice from './viewInvoice';
import InvoiceLinesList from './invoiceLines/invoiceLineList';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AddCreditModal from '../common/addCreditModel';
import CustomerReport from '../../interfaces/output/customerReport';
import CustomerReportList from '../common/customerReportList';
import DetailedHistoryFilterComponent from '../common/detailedHistoryFilterComponent';
import DetailedHistoryFilter from '../../interfaces/filters/detailedHistoryFilter';
import InvoiceLineFilterComponent from './invoiceLines/invoiceLineFilterComponent';
import SendInvoiceEmail from '../exports/sendInvoiceEmail';

interface IInvoiceDetailsProps {
    classes: any;
    theme: Theme;
    fetchInvoiceById: any;
    fetchInvoiceLines: any;
    deactivateInvoice: any;
    deactivateInvoiceLine: any;
    exportInvoices: any;
    creditInvoice: any;
    sendInvoiceEmail: any;
    invoice: InvoiceOut;
    match: any;
    invoiceLines: Pagination<InvoiceLineOut>;
    history: any;
    location: any;
    isUpdatingInvoice: boolean;
    isDeletingInvoice: boolean;
    isDeletingInvoiceLine: boolean;
    isCreatingInvoiceLine: boolean;
    isLoading: boolean;
    isLoadingInvoice: boolean;
    isLoadingInvoiceLines: boolean;
    isCrediting: boolean;
    exportInvoicesPdf: any;
    customerReport: Pagination<CustomerReport>;
    isLoadingCustomerReport: boolean;
    fetchCustomerReport: any;
}

interface IInvoiceDetailsState {
    open: boolean;
    invoice: InvoiceOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    paging: Paging;
    lineFilters?: InvoiceLineFiltersIn;
    openModal: boolean;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    showAddForm: boolean;
    pagingCustomerReport: Paging;
    sortModelCustomerReport: GridSortModel;
    detailedHistoryFilter: DetailedHistoryFilter;
    showSendEmailForm: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchInvoiceById: (invoiceId: number) => dispatch(fetchInvoiceById(invoiceId)),
    fetchInvoiceLines: (invoiceId: number, paging: Paging, filters: InvoiceLineFiltersIn) => dispatch(fetchInvoiceLines({
        invoiceId,
        paging,
        filters
    })),
    deactivateInvoice: (id: number) => dispatch(deactivateInvoice(id)),
    deactivateInvoiceLine: (id: number) => dispatch(deactivateInvoiceLine(id)),
    exportInvoices: (id: number) => dispatch(exportInvoices(id)),
    sendInvoiceEmail: (id: number, emailAddress: string|undefined ) => dispatch(sendInvoiceEmail({
        id,
        emailAddress
    })),
    exportInvoicesPdf: (id: number) => dispatch(exportInvoicesPdf(id)),
    fetchCustomerReport: (filter: DetailedHistoryFilter, paging: Paging) => dispatch(fetchCustomerReport({
        filter,
        paging
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        invoice: getInvoice(store),
        invoiceLines: getInvoiceLines(store),
        isUpdatingInvoice: isUpdatingInvoice(store),
        isDeletingInvoice: isDeletingInvoice(store),
        isDeletingInvoiceLine: isDeletingInvoiceLine(store),
        isCreatingInvoiceLine: isCreatingInvoiceLine(store),
        isLoadingInvoiceLines: isLoadingInvoiceLines(store),
        isLoading: isLoadingInvoice(store) || isUpdatingInvoiceLine(store) || isDeletingInvoice(store) || isCreatingInvoiceLine(store) || isLoadingInvoiceLines(store),
        isLoadingInvoice: isLoadingInvoice(store),
        isCrediting: isCrediting(store),
        isLoadingCustomerReport: isLoadingCustomerReport(store),
        customerReport: getCustomerReport(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('INVOICES'),
    properties: LanguageUtils.createMessage('Properties'),
    lines: LanguageUtils.createMessage('Invoice lines'),
    export: LanguageUtils.createMessage('Export'),
    credit: LanguageUtils.createMessage('Credit'),
    sendEmail: LanguageUtils.createMessage('Send email'),
    exportPdf: LanguageUtils.createMessage('Export Pdf'),
    detailedHistory: LanguageUtils.createMessage('Detailed history')
};

class InvoiceDetails extends Component<IInvoiceDetailsProps, IInvoiceDetailsState> {
    customerReportListRef: any;
    invoiceLineListRef: any;
    constructor(props: IInvoiceDetailsProps) {
        super(props);
        this.customerReportListRef = React.createRef();
        this.invoiceLineListRef = React.createRef();
        this.state = {
            open: true,
            invoice: {} as InvoiceOut,
            openModal: false,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.lines,
                icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/lines`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/lines`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.detailedHistory,
                icon: <FontAwesomeIcon icon={['fab', 'microsoft']} size="1x" transform="grow-4" />,
                url: `/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/customerReport`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.INVOICES}/${props.match.params.invoiceId}/customerReport`, props.location.pathname)
            }]),
            selectedRow: undefined,
            paging: PageUtils.getDefaultPaging(),
            lineFilters: {} as InvoiceLineFiltersIn,
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {},
            showAddForm: false,
            pagingCustomerReport: PageUtils.getDefaultPaging(),
            sortModelCustomerReport: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            detailedHistoryFilter: {
            } as DetailedHistoryFilter,
            showSendEmailForm: false
        };
    }

    componentDidMount() {
        const { fetchInvoiceById } = this.props;
        const { invoiceId } = this.props.match.params;

        fetchInvoiceById(invoiceId);
    }

    componentDidUpdate(prevProps: IInvoiceDetailsProps) {
        const { invoice, isUpdatingInvoice, isDeletingInvoice, isCreatingInvoiceLine, isLoadingInvoice, isDeletingInvoiceLine, history } = this.props;

        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(invoice !== prevProps.invoice) {
            const { pagingCustomerReport, detailedHistoryFilter } = this.state;
            const { fetchCustomerReport } = this.props;

            const newPaging = PageUtils.getDefaultPaging();
            const newPagination = {
                ...pagingCustomerReport,
                page: newPaging.page
            };

            const filter = {
                ...detailedHistoryFilter,
                creditedSalesOrderNumber: invoice?.invoiceNumber?.toString()
            } as DetailedHistoryFilter;

            fetchCustomerReport(filter, newPagination);

            this.setState({
                invoice: invoice,
                detailedHistoryFilter: filter
            });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingInvoice && prevProps.isLoadingInvoice ||
            !isUpdatingInvoice && prevProps.isUpdatingInvoice ||
            !isDeletingInvoice && prevProps.isDeletingInvoice ||
            !isCreatingInvoiceLine && prevProps.isCreatingInvoiceLine)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingInvoiceLine && prevProps.isDeletingInvoiceLine) {
            history.push(`/${UrlConstants.INVOICES}`);
        }
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { invoiceId } = this.props.match.params;
        const { fetchInvoiceLines } = this.props;
        const { paging, lineFilters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        if(selectedMenuItem?.id === 2) {
            fetchInvoiceLines(invoiceId, newPagination, lineFilters);
        }
    }

    getSelectedMenuItem = (state: IInvoiceDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateInvoice = () => {
        const { invoice } = this.state;
        const { deactivateInvoice } = this.props;

        deactivateInvoice(invoice.id);
    }

    deactivateInvoiceLine = () => {
        const { selectedRow } = this.state;
        const { deactivateInvoiceLine } = this.props;

        deactivateInvoiceLine(selectedRow);
    }

    exportInvoice = () => {
        const { invoice } = this.state;
        const { exportInvoices } = this.props;

        exportInvoices(invoice.id);
    }

    sendInvoiceEmail = () => {
        const { invoice } = this.state;
        const { sendInvoiceEmail } = this.props;

        sendInvoiceEmail(invoice.id);
    }

    // sendEmail = () => {
    //     this._setOpenModal();
    // }

    // creditInvoice = () => {
    //     const { invoiceId } = this.props.match.params;
    //     const { creditInvoice } = this.props;

    //     creditInvoice(invoiceId);
    // };

    exportInvoicePdf = () => {
        const { invoice } = this.state;
        const { exportInvoicesPdf } = this.props;

        exportInvoicesPdf(invoice.id);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { invoiceId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        text: messages.export,
                        icon: 'download',
                        onClick: this.exportInvoice
                    },
                    {
                        icon: 'file-pdf',
                        text: messages.exportPdf,
                        onClick: this.exportInvoicePdf
                    },
                    // {
                    //     text: messages.sendEmail,
                    //     icon: 'envelope',
                    //     onClick: this.sendInvoiceEmail
                    // },
                    {
                        text: messages.sendEmail,
                        icon: 'envelope',
                        onClick: this.toggleSendEmailModal
                    },
                    // {
                    //     icon: 'ban',
                    //     text: messages.delete,
                    //     onClick: this.deactivateInvoice
                    // },
                    {
                        icon: 'credit-card',
                        text: messages.credit,
                        onClick: this.toggleAddModal
                    }
                ];
            }

            // case 2: { //lines
            //     if(!selectedRow) {
            //         return [
            //             {
            //                 href: `${UrlConstants.INVOICES}/${invoiceId}/add/line`,
            //                 icon: 'plus',
            //                 text: messages.add
            //             }
            //         ];
            //     }

            //     return [
            //         {
            //             href: `${UrlConstants.INVOICE_LINES}/${selectedRow}/edit`,
            //             icon: 'edit',
            //             text: messages.edit
            //         },
            //         {
            //             icon: 'ban',
            //             text: messages.delete,
            //             onClick: this.deactivateInvoiceLine
            //         }
            //     ];
            // }

            default: {
                return [];
            }
        }
    }

    _setOpenModal = () => {
        const { openModal } = this.state;
        this.setState({ openModal: !openModal });
    }

    _onSortChangeInvoiceLines = (newModel: GridSortModel) => {
        const { fetchInvoiceLines } = this.props;
        const { paging, sortModel, lineFilters, invoice } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });

            fetchInvoiceLines(invoice.id, newPagination, lineFilters);
        }
    }

    applyDetaildHistoryFilters = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };

        this.setState({
            pagingCustomerReport: newPagination
        });

        fetchCustomerReport(detailedHistoryFilter, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    setDetaildHistoryFiltersDefaultState = () => {
        const { fetchCustomerReport, invoice } = this.props;
        const { pagingCustomerReport } = this.state;

        const newFilters = {
            creditedSalesOrderNumber: invoice.invoiceNumber.toString()
        } as DetailedHistoryFilter;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            pagingCustomerReport: newPagination,
            detailedHistoryFilter: newFilters
        });

        fetchCustomerReport(newFilters, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    onDetaildHistoryFiltersChange = (attribute: string, value: any) => {
        const { detailedHistoryFilter, pagingCustomerReport } = this.state;

        const newFilter = { ...detailedHistoryFilter } as DetailedHistoryFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };
        this.setState({
            detailedHistoryFilter: newFilter,
            pagingCustomerReport: newPagination
        });
    };

    _onSortChangeCustomerReport = (newModel: GridSortModel) => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, sortModelCustomerReport, invoice, detailedHistoryFilter } = this.state;

        if(JSON.stringify(sortModelCustomerReport) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...pagingCustomerReport,
                sort: newModel
            };
            this.setState({
                sortModelCustomerReport: newModel,
                pagingCustomerReport: newPagination
            });

            fetchCustomerReport(detailedHistoryFilter, newPagination);
        }
    }

    applyInvoiceLineFilters = () => {
        const { fetchInvoiceLines } = this.props;
        const { lineFilters, paging, invoice } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination
        });

        fetchInvoiceLines(invoice.id, newPagination, lineFilters);

        if(this.invoiceLineListRef !== null && this.invoiceLineListRef !== undefined &&
            this.invoiceLineListRef.current !== null && this.invoiceLineListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.invoiceLineListRef.current.resetDataGridPage();
        }
    };

    setInvoiceLineFiltersDefaultState = () => {
        const { fetchInvoiceLines } = this.props;
        const { paging, invoice } = this.state;

        const newFilters = {
        } as InvoiceLineFiltersIn;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination,
            lineFilters: newFilters
        });

        fetchInvoiceLines(invoice.id, newPagination, newFilters);

        if(this.invoiceLineListRef !== null && this.invoiceLineListRef !== undefined &&
            this.invoiceLineListRef.current !== null && this.invoiceLineListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.invoiceLineListRef.current.resetDataGridPage();
        }
    };

    onChangeInvoiceLineFilter = (attribute: string, value: any) => {
        const { lineFilters, paging } = this.state;

        const newFilter = { ...lineFilters } as InvoiceLineFiltersIn;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            lineFilters: newFilter,
            paging: newPagination
        });
    }

    toggleAddModal = () => {
        this.setState({ showAddForm: !this.state.showAddForm });
    }
    toggleSendEmailModal = () => {
        this.setState({ showSendEmailForm: !this.state.showSendEmailForm });
    }

    render() {
        const { classes, isLoadingInvoice, isLoadingInvoiceLines, invoiceLines, fetchInvoiceLines, isDeletingInvoice, isDeletingInvoiceLine,
            isLoadingCustomerReport, customerReport, fetchCustomerReport } = this.props;
        const { invoice, menuItems, paging, lineFilters, openModal, columnVisibilityModel, showAddForm, pagingCustomerReport, detailedHistoryFilter, showSendEmailForm } = this.state;
        const { invoiceId } = this.props.match.params;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingInvoice || isDeletingInvoiceLine}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Invoice}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: invoice.invoiceNumber,
                    icon: <FontAwesomeIcon icon="file-invoice" size="1x" />
                } as IMenuItem}
            >
                <Paper>
                    <Container className={classes.container} maxWidth="xl">
                        <Switch>
                            <Route path={`/${UrlConstants.INVOICES}/:invoiceId/properties`}>
                                <SendInvoiceEmail setOpenModal={this.toggleSendEmailModal} open={showSendEmailForm} invoiceId={invoiceId} emailAddress={invoice?.customer?.emailAddressCSV} />
                                <ViewInvoice invoice={invoice} />
                            </Route>
                            <Route path={`/${UrlConstants.INVOICES}/:invoiceId/lines`}>
                                <>
                                    <InvoiceLineFilterComponent applyFilters={this.applyInvoiceLineFilters}
                                        setDefaultState={this.setInvoiceLineFiltersDefaultState} filters={lineFilters}
                                        onChange={this.onChangeInvoiceLineFilter} />
                                    <InvoiceLinesList isLoading={isLoadingInvoiceLines || isDeletingInvoice}
                                        ref={this.invoiceLineListRef}
                                        rows={invoiceLines}
                                        // checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                        onPageChange={(nextPage: number, nextSize: number) => {
                                            const newPage = {
                                                ...paging,
                                                page: nextPage,
                                                size: nextSize
                                            };
                                            fetchInvoiceLines(invoice.id, newPage, lineFilters);
                                            this.setState({ paging: newPage });
                                        }}
                                        onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeInvoiceLines(sortModel)}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                            this.setState({
                                                columnVisibilityModel: newModel
                                            })
                                        }
                                    />
                                </>
                            </Route>
                            <Route path={`/${UrlConstants.INVOICES}/:invoiceId/customerReport`}>
                                <>
                                    <DetailedHistoryFilterComponent applyFilters={this.applyDetaildHistoryFilters}
                                        setDefaultState={this.setDetaildHistoryFiltersDefaultState}
                                        filters={detailedHistoryFilter} onChange={this.onDetaildHistoryFiltersChange}
                                        disableFilter={true} />
                                    <CustomerReportList isLoading={isLoadingCustomerReport}
                                        rows={customerReport}
                                        ref={this.customerReportListRef}
                                        // checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                        onPageChange={(nextPage: number, nextSize: number) => {
                                            const newPage = {
                                                ...pagingCustomerReport,
                                                page: nextPage,
                                                size: nextSize
                                            };
                                            fetchCustomerReport(
                                                {
                                                    ...detailedHistoryFilter,
                                                    creditedSalesOrderNumber: invoice.invoiceNumber.toString()
                                                } as DetailedHistoryFilter,
                                                newPage);
                                            this.setState({ pagingCustomerReport: newPage });
                                        }}
                                        onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeCustomerReport(sortModel)}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                            this.setState({
                                                columnVisibilityModel: newModel
                                            })
                                        }
                                    />
                                </>
                            </Route>
                        </Switch>
                    </Container>
                    <AddCreditModal show={showAddForm} onClose={this.toggleAddModal} id={invoiceId} createCredit={creditInvoice} />
                    {/* <AddSendEmailModal show={showSendEmailForm} onClose={this.toggleSendEmailModal} id={invoiceId} emailAddress={invoice?.customer?.emailAddressInvoice} /> */}
                </Paper>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(InvoiceDetails));