import { Grid, Theme } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';

interface ILoaderProps {
    isLoading?: boolean;
    children?: JSX.Element;
    classes?: any;
}

const styles = (theme: Theme) => createStyles({
    paddingTop: {
        padding: theme.spacing(8)
    }
});

class LoaderWithoutStyles extends Component<ILoaderProps> {
    constructor(props: ILoaderProps) {
        super(props);
    }

    render(): JSX.Element {
        const { isLoading, children, classes } = this.props;
        
        if(isLoading || !children) {
            return (<Grid container xl className={classes.paddingTop}>
                <Grid item xs={6} />
                <Grid item xs={6} alignContent="center">
                    <CircularProgress color="inherit" />
                </Grid>
            </Grid>);
        }
        else {
            return children;
        }
    }
}

const Loader = withStyles(styles)(LoaderWithoutStyles);

export default Loader;