import { http } from '../utils/http';
import InvoiceSeparation from '../interfaces/output/invoiceSeparation';
import Pagination from '../interfaces/common/pagination';

export default class InvoiceSeparationApi {
    static fetchInvoiceSeparationById = async(invoiceId: string): Promise<InvoiceSeparation> => {
        const { data } = await http.get<InvoiceSeparation>(`/invoiceseparation/${invoiceId}`);

        return data;
    };

    static fetchInvoiceSeparations = async(): Promise<Pagination<InvoiceSeparation>> => {
        const { data } = await http.get<Pagination<InvoiceSeparation>>('/invoiceseparation');

        return data;
    };
}
