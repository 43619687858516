import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import OUSPStatus from '../interfaces/output/ouspStatus';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OuspStatusApi {
    static fetchOUSPStatusById = async(id: string): Promise<OUSPStatus> => {
        const { data } = await http.get<OUSPStatus>(`/ouspstatus/${id}`);

        return data;
    };

    static fetchOUSPStatuses = async(paging: Paging): Promise<Pagination<OUSPStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/ouspstatus');
        const { data } = await http.get<Pagination<OUSPStatus>>(url);

        return data;
    };

    static createOUSPStatus = async(ouspstatus: OUSPStatus): Promise<OUSPStatus> => {
        const newOUSStatus = {
            id: ouspstatus.id,
            name: ouspstatus.name,
            code: ouspstatus.code
        };

        const { data } = await http.post<any, OUSPStatus>('ouspstatus', newOUSStatus);

        return data;
    };

    static updateOUSPStatus = async(ouspstatus: OUSPStatus): Promise<OUSPStatus> => {
        const newOUSStatus = {
            id: ouspstatus.id,
            name: ouspstatus.name,
            code: ouspstatus.code
        };
        
        const { data } = await http.put<any, OUSPStatus>(`ouspstatus/${ouspstatus.id}`, newOUSStatus);

        return data;
    };

    static deleteOUSPStatus = async(ouspstatus: OUSPStatus): Promise<OUSPStatus> => {
        const { data } = await http.delete<OUSPStatus>(`ouspstatus/${ouspstatus.id}`);

        return data;
    };
}
