import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../constants/UrlConstants';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import Paging from '../../interfaces/common/paging';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import SimulationFilters from '../../interfaces/output/simulationFilters';
import { fetchSimulations, getSimulations, isLoadingSimulations } from '../../reducers/simulationReducer';
import SimulationOut from '../../interfaces/output/simulationOut';
import SimulationList from './simulationList';
import SimulationFiltersComponent from './simulationFiltersComponent';

interface ISimulationsProps {
    simulations: Pagination<SimulationOut>;
    classes: any;
    fetch: any;
    isLoading: boolean;
    onChange: () => void;
    fetchSimulations: (paging: Paging, filters: SimulationFilters) => void;
}

interface ISimulationsState {
    searchTerm: string;
    filters: SimulationFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const messages = {
    simulations: LanguageUtils.createMessage('Simulations'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchSimulations: (paging: Paging, filters: SimulationFilters) => dispatch(fetchSimulations({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        simulations: getSimulations(store),
        isLoading: isLoadingSimulations(store)
    };
};

class Simulations extends Component<ISimulationsProps, ISimulationsState> {
    simulationListRef: any;
    constructor(props: ISimulationsProps) {
        super(props);
        this.simulationListRef = React.createRef();

        this.state = {
            searchTerm: '',
            filters: {
                simulationName: ''
            } as SimulationFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.simulations,
                icon: <FontAwesomeIcon icon="chart-bar" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchSimulations } = this.props;
        const { paging, filters } = this.state;

        fetchSimulations(paging, filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters } = this.state;

        const newFilter = { ...filters } as SimulationFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();

        this.setState({
            filters: newFilter,
            paging: newPaging
        });
    }

    applyFilters = () => {
        const { fetchSimulations } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchSimulations(paging, filters);

        if(this.simulationListRef !== null && this.simulationListRef !== undefined &&
            this.simulationListRef.current !== null && this.simulationListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.simulationListRef.current.resetDataGridPage();
        }

    }

    setDefaultState = () => {
        const { fetchSimulations } = this.props;
        const { paging } = this.state;

        const newFilters = {
            simulationName: ''
        } as SimulationFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchSimulations(newPagination, newFilters);

        if(this.simulationListRef !== null && this.simulationListRef !== undefined &&
            this.simulationListRef.current !== null && this.simulationListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.simulationListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchSimulations } = this.props;

        const newFilter = { ...filters } as SimulationFilters;
        (newFilter as any)['simulationName'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchSimulations(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchSimulations } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });

            fetchSimulations(newPagination, filters);
        }
    }

    render() {
        const { classes, simulations, fetchSimulations, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.SIMULATIONS}/add`
                                    }]}
                                >
                                    <SimulationFiltersComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <SimulationList rows={simulations} isLoading={isLoading}
                                    ref={this.simulationListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchSimulations(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Simulations));