import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';

interface ModalError {
    message?: string;
    details?: string;
    onClose: () => void;
    show: boolean;
}
const messages = {
    close: LanguageUtils.createMessage('Close'),
    anErrorHasOccured: LanguageUtils.createMessage('An error has occured')
};

export default function ModalError(props: ModalError) : JSX.Element {
    const { message, details, onClose, show } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={show}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <FormattedMessage {...messages.anErrorHasOccured} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                    <br/>
                    {details}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    <FormattedMessage {...messages.close} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}