import React, { useEffect } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MessageDescriptor, useIntl } from 'react-intl';
import PageUtils from '../../../utils/pageUtils';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import { fetchCancellationVersions, getFactorSetCancellationVersions, isLoadingFactorSetCancellationVersions, resetCancellationVersions } from '../../../reducers/factorSetCancellationVersionReducer';
import { fetchDepartureVersions, getFactorSetDepartureVersions, isLoadingFactorSetDepartureVersions, resetDepartureVersions } from '../../../reducers/factorSetDepartureVersionReducer';
import { fetchPriorityVersions, getFactorSetPriorityVersions, isLoadingFactorSetPriorityVersions, resetPriorityVersions } from '../../../reducers/factorSetPriorityVersionReducer';
import { fetchHoursVersions, getFactorSetHoursVersions, isLoadingFactorSetHoursVersions, resetHoursVersions } from '../../../reducers/factorSetHoursVersionReducer';
import { fetchWeightVersions, getFactorSetWeightVersions, isLoadingFactorSetWeightVersions, resetWeightVersions } from '../../../reducers/factorSetWeightVersionReducer';
import { ensure } from '../../../utils/arrayUtils';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import LanguageUtils from '../../../utils/LanguageUtils';
import { getFactorSetCalendarVersions, isLoadingFactorSetCalendarVersions, resetCalendarVersions } from '../../../reducers/factorSetCalendarVersionReducer';
import { getFactorSetDangerousGoodVersions, isLoadingFactorSetDangerousGoodVersions, resetDangerousGoodVersions } from '../../../reducers/factorSetDangerousGoodsVersionReducer';
import { GridSortItem } from '@mui/x-data-grid';

const messages = {
    version: LanguageUtils.createMessage('Version')
};

export enum FactorSetPickerType {
    Cancellation,
    Weight,
    Priority,
    Departure,
    Hours,
    DangerousGoods,
    Calendar
}

interface IFactorSetPickerProps {
    value?: FactorSetVersionOut;
    onChange: any;
    factorSet: FactorSetOut | undefined;
    placeholder: MessageDescriptor;
    type: FactorSetPickerType;
}

const FactorSetVersionPicker = (props: IFactorSetPickerProps): JSX.Element => {
    const { value, onChange, placeholder, type, factorSet } = props;
    const paging = PageUtils.getMaxPaging([{
        field: 'validFromDate',
        sort: 'desc'
    } as GridSortItem]);
    const dispatch = useDispatch();
    const { formatMessage, formatDate } = useIntl();

    useEffect(() => {
        if(factorSet?.id) {
            fetchFactorSetVersions();
        }
        else {
            onChange();
            resetFactorSets();
        }
    }, [factorSet]);

    const getFactorSets = (): Array<any> => {
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                return useSelector(getFactorSetCancellationVersions).content;
            }

            case FactorSetPickerType.Departure: {
                return useSelector(getFactorSetDepartureVersions).content;
            }
            case FactorSetPickerType.Priority: {
                return useSelector(getFactorSetPriorityVersions).content;
            }
            case FactorSetPickerType.Hours: {
                return useSelector(getFactorSetHoursVersions).content;
            }
            case FactorSetPickerType.Weight: {
                return useSelector(getFactorSetWeightVersions).content;
            }
            case FactorSetPickerType.Calendar: {
                return useSelector(getFactorSetCalendarVersions).content;
            }
            case FactorSetPickerType.DangerousGoods: {
                return useSelector(getFactorSetDangerousGoodVersions).content;
            }
            default: {
                throw Error('Invalid type');
            }
        }
    };
    const getIsLoadingFactorSets = (): boolean => {
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                return useSelector(isLoadingFactorSetCancellationVersions);
            }
            case FactorSetPickerType.Departure: {
                return useSelector(isLoadingFactorSetDepartureVersions);
            }
            case FactorSetPickerType.Priority: {
                return useSelector(isLoadingFactorSetPriorityVersions);
            }
            case FactorSetPickerType.Hours: {
                return useSelector(isLoadingFactorSetHoursVersions);
            }
            case FactorSetPickerType.Weight: {
                return useSelector(isLoadingFactorSetWeightVersions);
            }
            case FactorSetPickerType.Calendar: {
                return useSelector(isLoadingFactorSetCalendarVersions);
            }
            case FactorSetPickerType.DangerousGoods: {
                return useSelector(isLoadingFactorSetDangerousGoodVersions);
            }
            default: {
                throw Error('Invalid type');
            }
        }
    };

    const resetFactorSets = (): void => {
        let fn;
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                fn = resetCancellationVersions;
                break;
            }

            case FactorSetPickerType.Departure: {
                fn = resetDepartureVersions;
                break;
            }
            case FactorSetPickerType.Priority: {
                fn = resetPriorityVersions;
                break;
            }
            case FactorSetPickerType.Hours: {
                fn = resetHoursVersions;
                break;
            }
            case FactorSetPickerType.Weight: {
                fn = resetWeightVersions;
                break;
            }
            case FactorSetPickerType.Calendar: {
                fn = resetCalendarVersions;
                break;
            }
            case FactorSetPickerType.DangerousGoods: {
                fn = resetDangerousGoodVersions;
                break;
            }
            default: {
                throw Error('Invalid type');
            }
        }

        dispatch(fn());
    };

    const fetchFactorSetVersions = () => {
        let fn;
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                fn = fetchCancellationVersions;
                break;
            }

            case FactorSetPickerType.Departure: {
                fn = fetchDepartureVersions;
                break;
            }
            case FactorSetPickerType.Priority: {
                fn = fetchPriorityVersions;
                break;
            }
            case FactorSetPickerType.Hours: {
                fn = fetchHoursVersions;
                break;
            }
            case FactorSetPickerType.Weight: {
                fn = fetchWeightVersions;
                break;
            }
            default: {
                throw Error('Invalid type');
            }
        }

        dispatch(fn(({
            id: ensure(factorSet?.id),
            paging
        })));
    };

    const getOptionLabel = (option: FactorSetVersionOut) => {
        if(!option) {
            return '';
        }

        const validFromDate = option.validFromDate ? formatDate(option.validFromDate) : '';
        const validToDate = option.validToDate ? formatDate(option.validToDate) : '';

        return `${formatMessage(messages.version)} ${option.versionNumber} [${validFromDate} - ${validToDate}]`;
    };

    return (
        <Autocomplete
            value={value || null}
            onChange={(e, value: any) => onChange(value)}
            getOptionLabel={getOptionLabel}
            filterOptions={(x) => x}
            options={getFactorSets()}
            loading={getIsLoadingFactorSets()}
            isOptionEqualToValue={(option: FactorSetOut, value: FactorSetOut) => option.id === value.id}
            renderInput={(params) => <>
                <TextField {...params} variant="standard" label={placeholder ? formatMessage(placeholder) : ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {getIsLoadingFactorSets() ? <CircularProgress color="inherit" size={20} sx={{
                                    position: 'absolute',
                                    right: 20
                                }} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }} />
            </>}
        />
    );
};

export default FactorSetVersionPicker;