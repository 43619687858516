import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetProduct } from '../../reducers/productReducer';
import AddProductForm from './addProductForm';
import { FormType } from '../../constants/constants';

interface IAddProductProps {
    classes: any;
    theme: Theme;
    match: any;
    resetProduct: any;
    steps: Array<number>;
    productId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetProduct: () => dispatch(resetProduct())
});

const mapStoreToProps = (store: RootState, ownProps: IAddProductProps) => {
    return {
        productId: ownProps.match.params?.productId ? Number(ownProps.match.params?.productId) : undefined
    };
};

class AddProduct extends Component<IAddProductProps> {
    componentWillUnmount() {
        const { resetProduct } = this.props;

        resetProduct();
    }

    render() {
        const { classes, steps, productId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddProductForm productId={productId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddProduct));