import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderStatusApi from '../api/orderStatusApi';
import OrderStatus from '../interfaces/output/orderStatus';
import { fetchOrderStatusByIdError, fetchOrderStatusByIdSuccess, fetchOrderStatusesError, fetchOrderStatusesSuccess } from '../reducers/orderStatusReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';

function* doFetchOrderStatus(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: OrderStatus = yield call(OrderStatusApi.fetchOrderStatusById, customerId);
        yield put(fetchOrderStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrderStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchOrderStatuses(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;
    try {
        const response: Pagination<OrderStatus> = yield call(OrderStatusApi.fetchOrderStatuses, paging);
        yield put(fetchOrderStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrderStatusesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('orderStatus/fetchOrderStatusById', doFetchOrderStatus),
    takeLatest('orderStatus/fetchOrderStatuses', doFetchOrderStatuses)
];

