import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Paging from '../../interfaces/common/paging';
import LanguageUtils from '../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import ResponsibilityCenter from '../../interfaces/output/responsibilityCenter';
import { fetchResponsibilityCenterById, getResponsibilityCenter, isLoadingResponsibilityCenter, resetResponsibilityCenter } from '../../reducers/responsibilityCenterReducer';
import ViewResponsibilityCenter from './viewResponsibilityCenter';

interface IResponsibilityCenterDetailsProps {
    classes: any;
    theme: Theme;
    fetchResponsibilityCenterById: any;
    responsibilityCenter: ResponsibilityCenter;
    match: any;
    history: any;
    location: any;
    isLoadingResponsibilityCenter: boolean;
    resetResponsibilityCenter: () => void;
}

interface IResponsibilityCenterDetailsState {
    responsibilityCenter: ResponsibilityCenter;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverProductPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchResponsibilityCenterById: (id: any) => dispatch(fetchResponsibilityCenterById(id)),
    resetResponsibilityCenter: () => dispatch(resetResponsibilityCenter())
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    properties: LanguageUtils.createMessage('Properties'),
    responsibilityCenter: LanguageUtils.createMessage('Responsibility Center')
};

const mapStoreToProps = (store: RootState) => {
    return {
        responsibilityCenter: getResponsibilityCenter(store),
        isLoading: isLoadingResponsibilityCenter(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class ResponsibilityCenterDetails extends Component<IResponsibilityCenterDetailsProps, IResponsibilityCenterDetailsState> {
    constructor(props: IResponsibilityCenterDetailsProps) {
        super(props);

        this.state = {
            responsibilityCenter: {} as ResponsibilityCenter,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.RESPONSIBILITIES_CENTER}/${props.match.params.responsibilityCenterId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.RESPONSIBILITIES_CENTER}/${props.match.params.responsibilityCenterId}/properties`, props.location.pathname)
            }
            ]),
            serverProductPagination: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchResponsibilityCenterById } = this.props;
        const { responsibilityCenterId } = this.props.match.params;

        fetchResponsibilityCenterById(responsibilityCenterId);
    }

    componentDidUpdate(prevProps: IResponsibilityCenterDetailsProps) {
        const { responsibilityCenter } = this.props;

        if(responsibilityCenter !== prevProps.responsibilityCenter) {
            this.setState({ responsibilityCenter });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            });
        }
    }

    componentWillUnmount() {
        resetResponsibilityCenter();
    }

    getSelectedMenuItem = (state: IResponsibilityCenterDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { responsibilityCenterId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.RESPONSIBILITIES_CENTER}/${responsibilityCenterId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, isLoadingResponsibilityCenter } = this.props;
        const { responsibilityCenter, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingResponsibilityCenter}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.ResponsibilityCenter}
                menuItem={{
                    id: 1,
                    subname: messages.responsibilityCenter,
                    value: responsibilityCenter?.id,
                    icon: <FontAwesomeIcon icon="file-invoice-dollar" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.RESPONSIBILITIES_CENTER}/:responsibilityCenterId/properties`}>
                            <ViewResponsibilityCenter responsibilityCenter={responsibilityCenter} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ResponsibilityCenterDetails));