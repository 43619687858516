import { Grid } from '@mui/material';
import React, { forwardRef, useEffect } from 'react';
import Paging from '../../interfaces/common/paging';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import PricelistList from '../pricelists/pricelistList';
import { fetchPriceLists, getPriceLists, isLoadingPriceLists } from '../../reducers/priceListReducer';
import PriceListsFilters from '../../interfaces/output/priceListFilters';
import AgreementVersionPricelist from '../../interfaces/output/agreementVersionPricelist';
import WorkflowUtils from '../../utils/workflowUtils';
import PriceListFilters from '../common/priceListFilters';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import Agreement from '../../interfaces/output/agreement';

interface IAddAgreementVersionPricelistStep2Props {
    pricelistId?: number;
    priceListVersionId?: number;
    agreementVersionPricelist?: AgreementVersionPricelist;
    onChange: (agreementVersionPricelist: AgreementVersionPricelist) => void;
    agreement?: Agreement | undefined | null;
}

const AddAgreementVersionPricelistStep2 = forwardRef((props: IAddAgreementVersionPricelistStep2Props, ref: any): JSX.Element => {
    const { agreementVersionPricelist, onChange, agreement } = props;
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [filters, setFilters] = React.useState<PriceListsFilters>({
        name: '',
        unitGroupId: agreement?.unitGroup?.id ?? 1,
        productTypeId: 1,
        pricingComponentTypeId: 1,
        currencyId: agreement?.currency?.id
    });
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const propsIsLoadingPricelists = useSelector(isLoadingPriceLists);
    const dispatch = useDispatch();
    const propsPriceLists = useSelector(getPriceLists);
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        currency_name: false,
        unitSubGroup_name: false
    });

    useEffect(() => {
        if(agreement !== undefined || agreement !== null) {
            const newFilter = { ...filters } as PriceListsFilters;
            newFilter.currencyId = agreement?.currency?.id;
            newFilter.unitGroupId = agreement?.unitGroup?.id;

            setFilters(newFilter);

            dispatch(fetchPriceLists({
                paging: paging,
                filters: newFilter
            }));
        }
    }, [dispatch, agreement]);

    const applyFilters = () => {
        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };

        dispatch(fetchPriceLists({
            paging: newServerPagination,
            filters: {
                ...filters,
                name: searchTerm
            }
        }));

        setPaging(newServerPagination);
    };

    const onChangeQuickSearch = (value: any) => {

        const newFilter = { ...filters } as PriceListsFilters;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();

        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };

        setFilters(newFilter);
        setPaging(newServerPagination);
        setSearchTerm(value);

        dispatch(fetchPriceLists({
            paging: newServerPagination,
            filters: newFilter
        }));
    };

    const setDefaultState = () => {

        const newFilter = { name: '' } as PriceListsFilters;
        const newPaging = PageUtils.getDefaultPaging();

        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };

        setFilters(newFilter);
        setPaging(newServerPagination);
        setSearchTerm('');

        dispatch(fetchPriceLists({
            paging: newServerPagination,
            filters: newFilter
        }));
    };

    const onChangeFilters = (attribute: string, value: any) => {

        const newFilter = { ...filters } as PriceListsFilters;
        (newFilter as any)[attribute] = value;

        setFilters(newFilter);
    };

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchPriceLists({
                paging: newServerPagination,
                filters: {
                    ...filters,
                    name: searchTerm
                } as PriceListsFilters
            }));
            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={1} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar
                    searchTerm={searchTerm}
                    includeFilters={true}
                    onSearch={onChangeQuickSearch}
                >
                    <PriceListFilters applyFilters={applyFilters} setDefaultState={setDefaultState} filters={filters} onChange={onChangeFilters}/>
                </QuickSearchToolbar>
            </Grid>
            <Grid item xs={12}>
                <PricelistList hiddenColumns={['currency', 'unitSubGroup', 'discount']}
                    isLoading={propsIsLoadingPricelists}
                    rows={propsPriceLists}
                    checkSelection={(value: number) => onChange({
                        ...agreementVersionPricelist,
                        pricelist: {
                            ...agreementVersionPricelist?.pricelist,
                            id: value
                        }
                    })}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchPriceLists({
                            paging: newPage,
                            filters: {
                                ...filters,
                                name: searchTerm
                            } as PriceListsFilters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    disableLinks
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddAgreementVersionPricelistStep2;