/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ErrorBoundary from './components/common/errorBoundary';
import Navbar from './components/common/navbar';
import UserLogin from './auth/login';
import UserRoles from './auth/userRoles';

interface IRootProps {
    children: React.ReactNode;
}

class Root extends React.Component<IRootProps> {
    render(): JSX.Element {
        const { children } = this.props;

        return (
            <UserLogin>
                {/* <UserRoles> */}
                <Navbar />
                <div className="main-content">
                    <ErrorBoundary>
                        <div>{children}</div>
                    </ErrorBoundary>
                </div>
                {/* </UserRoles> */}
            </UserLogin>
        );
    }
}

export default Root;