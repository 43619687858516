import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Status from '../interfaces/output/customerStatus';
import OUSStatus from '../interfaces/output/ousStatus';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type OUSStatusState = {
    ousStatus: OUSStatus;
    ousStatuses: Pagination<OUSStatus>;
    isLoadingOUSServiceStatus: boolean;
    isLoadingOUSServiceStatuses: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: OUSStatusState = {
    ousStatus: {} as OUSStatus,
    ousStatuses: { content: [] as Array<OUSStatus> } as Pagination<OUSStatus>,
    isLoadingOUSServiceStatus: false,
    isLoadingOUSServiceStatuses: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const ousStatusSlice = createSlice({
    name: 'ousStatus',

    initialState,
    reducers: {
        fetchOUSStatuses: (state: OUSStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingOUSServiceStatuses = true;
        },
        fetchOUSStatusesSuccess: (state: OUSStatusState, action: PayloadAction<Pagination<OUSStatus>>) => {
            state.isLoadingOUSServiceStatuses = false;
            state.ousStatuses = action.payload;
        },
        fetchOUSStatusesError: (state: OUSStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOUSServiceStatuses = false;
            state.error = action.payload;
        },
        fetchOUSStatusById: (state: OUSStatusState, _action: PayloadAction<number>) => {
            state.isLoadingOUSServiceStatus = true;
        },
        fetchOUSStatusByIdSuccess: (state: OUSStatusState, action: PayloadAction<OUSStatus>) => {
            state.isLoadingOUSServiceStatus = false;
            state.ousStatus = action.payload;
        },
        fetchOUSStatusByIdError: (state: OUSStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOUSServiceStatus = false;
            state.error = action.payload;
        },
        createOUSStatus: (state: OUSStatusState, _action: PayloadAction<OUSStatus>) => {
            state.isCreating = true;
        },
        createOUSStatusSuccess: (state: OUSStatusState, action: PayloadAction<OUSStatus>) => {
            state.isCreating = false;
            state.ousStatus = action.payload;
        },
        createOUSStatusError: (state: OUSStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateOUSStatus: (state: OUSStatusState, _action: PayloadAction<OUSStatus>) => {
            state.isUpdating = true;
        },
        updateOUSStatusSuccess: (state: OUSStatusState, action: PayloadAction<OUSStatus>) => {
            state.isUpdating = false;
            state.ousStatus = action.payload;
        },
        updateOUSStatusError: (state: OUSStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteOUSStatus: (state: OUSStatusState, _action: PayloadAction<OUSStatus>) => {
            state.isDeleted = true;
        },
        deleteOUSStatusSuccess: (state: OUSStatusState, action: PayloadAction<OUSStatus>) => {
            state.isDeleted = false;
            state.ousStatus = action.payload;
        },
        deleteOUSStatusError: (state: OUSStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetOUSStatus: (state: OUSStatusState) => {
            state.ousStatuses = initialState.ousStatuses;
        }
    }
});

export const { fetchOUSStatuses, fetchOUSStatusesSuccess, fetchOUSStatusesError, fetchOUSStatusById, fetchOUSStatusByIdSuccess, fetchOUSStatusByIdError, resetOUSStatus, createOUSStatus,
    createOUSStatusSuccess, createOUSStatusError, updateOUSStatus, updateOUSStatusError, updateOUSStatusSuccess, deleteOUSStatus, deleteOUSStatusError, deleteOUSStatusSuccess }
    = ousStatusSlice.actions;

export const isLoadingOUSServiceStatus = (state: RootState): boolean => state.ousStatusReducer.isLoadingOUSServiceStatus;
export const isLoadingOUSServiceStatuses = (state: RootState): boolean => state.ousStatusReducer.isLoadingOUSServiceStatuses;
export const isUpdating = (state: RootState): boolean => state.ousStatusReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.ousStatusReducer.isDeleted;
export const isCreating = (state: RootState): boolean => state.ousStatusReducer.isCreating;
export const getOUSStatuses = (state: RootState): Pagination<OUSStatus> => state.ousStatusReducer.ousStatuses;
export const getOUSStatus = (state: RootState): OUSStatus => state.ousStatusReducer.ousStatus;

export default ousStatusSlice.reducer;