import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import PriceList from '../interfaces/output/priceList';
import PriceListFilters from '../interfaces/output/priceListFilters';
import PriceListVersion from '../interfaces/output/priceListVersions';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PricelistApi {
    static fetchPriceListById = async(priceListId: number, priceListVersionId?: number): Promise<PriceList> => {
        const versionQueryString = priceListVersionId ? { versionId: priceListVersionId } : {};
        const url = urlcat('', `pricelists/${priceListId}`, versionQueryString);
        const { data } = await http.get<PriceList>(url);

        return data;
    };

    static fetchPriceLists = async(paging: Paging, filters: PriceListFilters): Promise<Array<PriceList>> => {
       
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/pricelists');
        const { data } = await http.get<Array<PriceList>>(url);

        return data;
    };

    static fetchPriceListVersions = async(priceListId: string, paging: Paging): Promise<Array<PriceListVersion>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/pricelists/${priceListId}/versions`);
        const { data } = await http.get<Array<PriceList>>(url);

        return data;
    };

    static createPriceList = async(priceList: PriceList): Promise<PriceList> => {
        const newPriceList = {
            name: priceList.name,
            sellerId: priceList.seller?.id,
            unitGroupId: priceList.unitGroup?.id,
            typeId: priceList.type?.id,
            unitSubGroupId: priceList.unitSubGroup?.id,
            productArenaId: priceList.productArena?.id,
            productTypeId: priceList.productType?.id,
            pricingComponentTypeId: priceList.pricingComponentType?.id,
            currencyId: priceList.currency?.id
        };

        const { data } = await http.post<any, PriceList>('pricelists', newPriceList);

        return data;
    }

    static createPriceListVersion = async(priceList: PriceList): Promise<PriceListVersion> => {
        const url = `/pricelists/${priceList.id}/versions`;
        const priceListVersion = priceList.version;

        const newPriceListVersion = {
            validFromDate: priceListVersion?.validFromDate,
            validToDate: priceListVersion?.validToDate,
            onHold: true,
            statusId: priceListVersion?.status?.id || 3,
            factorSetWeightId: priceListVersion?.factorSetWeight?.id,
            factorSetPriorityId: priceListVersion?.factorSetPriority?.id,
            factorSetDepartureId: priceListVersion?.factorSetDeparture?.id,
            factorSetCancellationId: priceListVersion?.factorSetCancellation?.id,
            factorSetHoursId: priceListVersion?.factorSetHours?.id,
            factorSetCalendarId: priceListVersion?.factorSetCalendar?.id,
            factorSetDangerousGoodsId: priceListVersion?.factorSetDangerousGoods?.id,
            copyFromVersionId: priceListVersion?.copyFromVersionId,
            adjustWithPercentage: priceListVersion?.adjustWithPercentage
        };

        const { data } = await http.post<any, PriceListVersion>(url, newPriceListVersion);

        return data;
    }

    static updatePriceList = async(priceList: PriceList): Promise<PriceList> => {
        const newPriceList = {
            name: priceList.name,
            currencyId: priceList.currency?.id,
            typeId: priceList.type?.id,
            sellerId: priceList.seller?.id,
            unitGroupId: priceList.unitGroup?.id,
            unitSubGroupId: priceList.unitSubGroup?.id,
            versionId: priceList.version?.id,
            productArenaId: priceList.productArena?.id,
            productTypeId: priceList.productType?.id,
            pricingComponentTypeId: priceList.pricingComponentType?.id
        };
        
        const { data } = await http.put<any, PriceList>(`pricelists/${priceList.id}`, newPriceList);

        return data;
    }

    static deactivatePriceList = async(priceList: PriceList): Promise<PriceList> => {
        const newPriceList = {
            name: priceList.name,
            currencyId: priceList.currency?.id,
            typeId: priceList.type?.id,
            sellerId: priceList.seller?.id,
            unitGroupId: priceList.unitGroup?.id,
            unitSubGroupId: priceList.unitSubGroup?.id,
            versionId: priceList.version?.id,
            productArenaId: priceList.productArena?.id,
            productTypeId: priceList.productType?.id
        };

        const { data } = await http.put<any, PriceList>(`pricelists/${priceList.id}`, newPriceList);

        return data;
    }
}