import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Currency from '../interfaces/output/currency';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CurrencyState = {
    currency: Currency;
    isLoading: boolean;
    currencies: Pagination<Currency>;
    error: HttpErrorResponse | undefined;
};

const initialState: CurrencyState = {
    currency: {} as Currency,
    currencies: { content: [] as Array<Currency> } as Pagination<Currency>,
    isLoading: false,
    error: undefined
};

export const currencySlice = createSlice({
    name: 'currency',

    initialState,
    reducers: {
        fetchCurrencies: (state: CurrencyState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchCurrenciesSuccess: (state: CurrencyState, action: PayloadAction<Pagination<Currency>>) => {
            state.isLoading = false;
            state.currencies = action.payload;
        },
        fetchCurrenciesError: (state: CurrencyState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCurrencyById: (state: CurrencyState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchCurrencyByIdSuccess: (state: CurrencyState, action: PayloadAction<Currency>) => {
            state.isLoading = false;
            state.currency = action.payload;
        },
        fetchCurrencyByIdError: (state: CurrencyState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchCurrencies, fetchCurrenciesSuccess, fetchCurrenciesError, fetchCurrencyById, fetchCurrencyByIdSuccess, fetchCurrencyByIdError } = currencySlice.actions;

export const isLoadingCurrency = (state: RootState): boolean => state.currencyReducer.isLoading;
export const getCurrencies = (state: RootState): Pagination<Currency> => state.currencyReducer.currencies;
export const getCurrency = (state: RootState): Currency => state.currencyReducer.currency;

export default currencySlice.reducer;