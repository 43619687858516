import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import InvoiceOut from '../../../interfaces/output/invoiceOut';
import { createInvoice, fetchInvoiceById, getInvoice, isCreatingInvoice, isLoadingInvoice, isUpdatingInvoice, updateInvoice } from '../../../reducers/invoiceReducer';
import InvoiceFormStep1 from './invoiceFormStep1';
import Customer from '../../../interfaces/output/customer';
import InvoiceStep1Validation from '../validations/invoiceStep1Validation';

interface IInvoiceFormrops {
    invoiceId?: number;
    customerId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit invoice'),
    add: LanguageUtils.createMessage('Add invoice')
};

export default function InvoiceForm(props: IInvoiceFormrops): JSX.Element {
    const dispatch = useDispatch();
    const { invoiceId, steps, type, customerId } = props;
    const defaultCustomer = customerId ? { id: customerId } as Customer : null;
    const [invoice, setInvoice] = React.useState<InvoiceOut>({ customer: defaultCustomer as Customer,
        id: invoiceId } as InvoiceOut);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsInvoice = useSelector(getInvoice);
    const propsIsUpdatingInvoice = useSelector(isUpdatingInvoice);
    const prevIsUpdatingInvoice = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingInvoice);
    const propsIsCreatingInvoice = useSelector(isCreatingInvoice);
    const prevIsCreatingnvoice = WorkflowUtils.usePrevious<boolean>(propsIsCreatingInvoice);
    const propsIsLoadingInvoice = useSelector(isLoadingInvoice);
    const prevIsLoadingInvoice = WorkflowUtils.usePrevious<boolean>(propsIsLoadingInvoice);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingInvoice === true && !propsIsUpdatingInvoice) || (prevIsCreatingnvoice && !propsIsCreatingInvoice)) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingInvoice, propsIsUpdatingInvoice]);
    
    useEffect(() => {
        if(invoiceId) {
            dispatch(fetchInvoiceById(invoiceId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(prevIsLoadingInvoice && !propsIsLoadingInvoice) {
            setInvoice(propsInvoice);
        }

    }, [propsIsLoadingInvoice]);

    const onChange = (attribute: string, value: any) => {
        const newInvoice = { ...invoice } as InvoiceOut;
        (newInvoice as any)[attribute] = value;

        setInvoice(newInvoice);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <InvoiceFormStep1 onChange={onChange} invoice={invoice} defaultCustomer={!!customerId} />,
            validationFn: () => InvoiceStep1Validation.validateOrderForm(invoice),
            onNext: () => {
                if(!invoice.id) {
                    dispatch(createInvoice(invoice));
                }
                else {
                    dispatch(updateInvoice(invoice));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.CUSTOMERS}/${customerId}/invoices`;
            
            case FormType.Edit:
                return `/${UrlConstants.INVOICES}/${invoice.id}`;
            
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  