import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivateHoursVersionStatusError, deactivateHoursVersionStatusSuccess, fetchHoursVersionStatusByIdError, fetchHoursVersionStatusByIdSuccess,
    fetchHoursVersionStatusesError, fetchHoursVersionStatusesSuccess, updateHoursVersionStatusError, updateHoursVersionStatusSuccess } from '../reducers/hoursVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchHoursVersionStatus(action: PayloadAction<string>) {
    const hoursVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, hoursVersionStatusId);
    
        yield put(fetchHoursVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchHoursVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchHoursVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchHoursVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchHoursVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateHoursVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const hoursVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, hoursVersionStatus);

        yield put(updateHoursVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateHoursVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateHoursVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const hoursVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, hoursVersionStatus);

        yield put(deactivateHoursVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateHoursVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('hoursVersionStatus/fetchHoursVersionStatusById', doFetchHoursVersionStatus),
    takeLatest('hoursVersionStatus/fetchHoursVersionStatuses', doFetchHoursVersionStatuses),
    takeLatest('hoursVersionStatus/updateHoursVersionStatus', doUpdateHoursVersionStatus),
    takeLatest('hoursVersionStatus/deactivateHoursVersionStatus', doDeactivateHoursVersionStatus)
];