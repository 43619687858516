import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import CalendarLineApi from '../api/calendarLineApi';
import FactorSetCalendarLineOutput from '../interfaces/output/factorSetCalendarLineOut';
import CalendarLineFilters from '../components/factorSets/calendar/line/calendarLineFilters';
import { createCalendarVersionLineError, createCalendarVersionLineSuccess, deactivateFactorSetCalendarLineError, deactivateFactorSetCalendarLineSuccess, fetchCalendarVersionLinesError,
    fetchCalendarVersionLinesSuccess, fetchFactorSetCalendarLineByIdError, fetchFactorSetCalendarLineByIdSuccess, updateFactorSetCalendarLineError, updateFactorSetCalendarLineSuccess }
    from '../reducers/calendarLineReducer';

function* doFetchFactorSetCalendarLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetCalendarLineOutput = yield call(CalendarLineApi.fetchFactorSetCalendarLineById, lineId);
        yield put(fetchFactorSetCalendarLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetCalendarLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetCalendarLines(action: PayloadAction<{paging: Paging, filters: CalendarLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetCalendarLineOutput> = yield call(CalendarLineApi.fetchFactorSetCalendarLines, paging, filters);
    
        yield put(fetchCalendarVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchCalendarVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetCalendarLine(action: PayloadAction<FactorSetCalendarLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetCalendarLineOutput = yield call(CalendarLineApi.createFactorSetCalendarLine, line);

        yield put(createCalendarVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCalendarVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCalendarLine(action: PayloadAction<FactorSetCalendarLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetCalendarLineOutput = yield call(CalendarLineApi.updateFactorSetCalendarLine, line);

        yield put(updateFactorSetCalendarLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetCalendarLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetCalendarLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetCalendarLineOutput = yield call(CalendarLineApi.deactivateFactorSetCalendarLine, lineId);

        yield put(deactivateFactorSetCalendarLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetCalendarLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetCalendarLineById', doFetchFactorSetCalendarLineById),
    takeLatest('factorSetLine/fetchCalendarVersionLines', doFetchFactorSetCalendarLines),
    takeLatest('factorSetLine/createCalendarVersionLine', doCreateFactorSetCalendarLine),
    takeLatest('factorSetLine/updateFactorSetCalendarLine', doUpdateFactorSetCalendarLine),
    takeLatest('factorSetLine/deactivateFactorSetCalendarLine', doDeactivateFactorSetCalendarLine)
];