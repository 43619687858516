import { put, takeLatest, call, delay, all } from 'redux-saga/effects';
import { fetchCustomerAgreementsSuccess, fetchCustomerAgreementByIdSuccess, promoteCustomerAgreementSuccess,
    demoteCustomerAgreementSuccess, deactivateCustomerAgreementSuccess, createCustomerAgreementSuccess,
    fetchCustomerAgreementByIdError, fetchCustomerAgreementsError, promoteCustomerAgreementError,
    demoteCustomerAgreementError, deactivateCustomerAgreementError, createCustomerAgreementError, updateCustomerAgreementSuccess, updateCustomerAgreementError,
    createCustomerAgreementsBulkSuccess, createCustomerAgreementsBulkError }
    from '../reducers/customerAgreementReducer';
import Pagination from '../interfaces/common/pagination';
import CustomerAgreement from '../interfaces/output/customerAgreement';
import CustomerAgreementApi from '../api/customerAgreementApi';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import CustomerAgreementFilters from '../interfaces/filters/customerAgreementFilters';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { List } from 'immutable';

function* doFetchCustomerAgreement(action: PayloadAction<string>) {
    const customerAgreementId = action.payload;
    try {
        const response: CustomerAgreement = yield call(CustomerAgreementApi.fetchCustomerAgreementById, customerAgreementId);
        yield put(fetchCustomerAgreementByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerAgreementByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCustomerAgreements(action: PayloadAction<{ paging: Paging, filters: CustomerAgreementFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CustomerAgreement> = yield call(CustomerAgreementApi.fetchCustomerAgreements, paging, filters);

        yield put(fetchCustomerAgreementsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerAgreementsError(error as HttpErrorResponse));
    }
}

function* promoteCustomerAgreement(action: PayloadAction<string>) {
    const customerAgreementId = action.payload;
    try {
        const response: CustomerAgreement = yield call(CustomerAgreementApi.promoteCustomerAgreement, customerAgreementId);
        yield put(promoteCustomerAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(promoteCustomerAgreementError(error as HttpErrorResponse));
    }
}

function* demoteCustomerAgreement(action: PayloadAction<string>) {
    const customerAgreementId = action.payload;
    try {
        const response: CustomerAgreement = yield call(CustomerAgreementApi.demoteCustomerAgreement, customerAgreementId);
        yield put(demoteCustomerAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(demoteCustomerAgreementError(error as HttpErrorResponse));
    }
}

function* deactivateCustomerAgreement(action: PayloadAction<string>) {
    const customerAgreementId = action.payload;
    try {
        const response: CustomerAgreement = yield call(CustomerAgreementApi.deactivateCustomerAgreement, customerAgreementId);
        yield put(deactivateCustomerAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateCustomerAgreementError(error as HttpErrorResponse));
    }
}

function* createCustomerAgreement(action: PayloadAction<CustomerAgreement>) {
    const customerAgreement = action.payload;
    try {
        const response: CustomerAgreement = yield call(CustomerAgreementApi.createCustomerAgreement, customerAgreement);
        yield put(createCustomerAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCustomerAgreementError(error as HttpErrorResponse));
    }
}

function* createCustomerAgreementsBulk(action: PayloadAction<{customerAgreements: List<CustomerAgreement>}>) {
    const { customerAgreements } = action.payload;

    try {
        const createCalls = customerAgreements.map(customerAgreement => call(CustomerAgreementApi.createCustomerAgreement, customerAgreement)).toJS();
        if(createCalls) {
            yield all(createCalls);
        }

        //delay to trigger loading
        if(!createCalls.length) {
            yield delay(500);
        }

        yield put(createCustomerAgreementsBulkSuccess());
    }

    catch (error: unknown) {
        yield put(createCustomerAgreementsBulkError(error as HttpErrorResponse));
    }
}

function* updateCustomerAgreement(action: PayloadAction<CustomerAgreement>) {
    const customerAgreement = action.payload;
    try {
        const response: CustomerAgreement = yield call(CustomerAgreementApi.updateCustomerAgreement, customerAgreement);
        yield put(updateCustomerAgreementSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCustomerAgreementError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('customerAgreement/fetchCustomerAgreementById', doFetchCustomerAgreement),
    takeLatest('customerAgreement/fetchCustomerAgreements', doFetchCustomerAgreements),
    takeLatest('customerAgreement/promoteCustomerAgreement', promoteCustomerAgreement),
    takeLatest('customerAgreement/demoteCustomerAgreement', demoteCustomerAgreement),
    takeLatest('customerAgreement/deactivateCustomerAgreement', deactivateCustomerAgreement),
    takeLatest('customerAgreement/createCustomerAgreement', createCustomerAgreement),
    takeLatest('customerAgreement/updateCustomerAgreement', updateCustomerAgreement),
    takeLatest('customerAgreement/createCustomerAgreementsBulk', createCustomerAgreementsBulk)
];

