import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import TableUtils from '../../utils/tableUtils';
import DataGridComponent from '../common/dataGrid';
import UrlConstants from '../../constants/UrlConstants';
import CustomStringLink from '../common/customStringLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import CustomLink from '../common/customLink';
import DisplayStatus from '../common/displayStatus';

interface SurchargRateListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    ssoId: LanguageUtils.createMessage('Sso Id'),
    roles: LanguageUtils.createMessage('Roles'),
    seller: LanguageUtils.createMessage('Seller'),
    status: LanguageUtils.createMessage('Status')
};

const SurchargeRateList = forwardRef((props: SurchargRateListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId, disableLinks } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.USERS);
        }
    }, []);

    function formattingRoles(roles: any) {
        return roles.map((role: { name: any; }) => `${role?.name}`).join(', ');
    }

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'name',
                headerName: `${intl.formatMessage(messages.name)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).name} link={`/#/${UrlConstants.USERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: 'ssoId',
                headerName: `${intl.formatMessage(messages.ssoId)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).ssoId} link={`/#/${UrlConstants.USERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('ssoId', hiddenColumns)
            },
            {
                field: 'status',
                headerName: `${intl.formatMessage(messages.status)}`,
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.USERS}/${applyRowFn(params)?.id}`}>
                    <DisplayStatus useNameAsIdentifier={true} status={applyRowFn(params)?.status} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            {
                field: 'roles',
                headerName: `${intl.formatMessage(messages.roles)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: false,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={formattingRoles(applyRowFn(params)?.roles) ?? '-'}
                    link={`/#/${UrlConstants.USERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('roles', hiddenColumns)
            },
            {
                field: 'seller_name',
                headerName: `${intl.formatMessage(messages.seller)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.seller?.name ?? '-'}
                    link={`/#/${UrlConstants.USERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('seller', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default SurchargeRateList;
