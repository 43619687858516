import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import LanguageUtils from '../../utils/LanguageUtils';
import UrlConstants from '../../constants/UrlConstants';
import PageUtils from '../../utils/pageUtils';
import ResponsibilityCenter from '../../interfaces/output/responsibilityCenter';
import ResponsibilityCenterFilters from '../../interfaces/filters/responsibilityCenterFilters';
import { fetchResponsibilitiesCenter, getResponsibilitiesCenter, isLoadingResponsibilityCenter, resetResponsibilitiesCenter } from '../../reducers/responsibilityCenterReducer';
import ResponsibilitiesCenterList from './responsibilityCenterList';
import ResponsibilitiesCenterFilterComponent from './responsibilitiesCenterFilterComponent';

interface IResponsibilitiesCenterProps {
    responsibilitiesCenter: Pagination<ResponsibilityCenter>;
    classes: any;
    fetchResponsibilitiesCenter : any;
    isLoading: boolean;
    onChange: () => void;
    resetResponsibilitiesCenter: () => void;
    fetch: any;
}

interface IResponsibilitiesCenterState {
    filters: ResponsibilityCenterFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    responsibilitiesCenter: LanguageUtils.createMessage('Responsibilities Center'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchResponsibilitiesCenter: (paging: Paging, filters: ResponsibilityCenterFilters) => dispatch(fetchResponsibilitiesCenter({
        paging,
        filters
    })),
    resetResponsibilitiesCenter: () => dispatch(resetResponsibilitiesCenter())

});

const mapStoreToProps = (store: RootState) => {
    return {
        responsibilitiesCenter: getResponsibilitiesCenter(store),
        isLoading: isLoadingResponsibilityCenter(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class ResponsibilitiesCenter extends Component<IResponsibilitiesCenterProps, IResponsibilitiesCenterState> {
    terminalsListRef: any;
    constructor(props: IResponsibilitiesCenterProps) {
        super(props);
        this.terminalsListRef = React.createRef();

        this.state = {
            filters: {} as ResponsibilityCenterFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.responsibilitiesCenter,
                icon: <FontAwesomeIcon icon="flag" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchResponsibilitiesCenter } = this.props;
        const { paging, filters } = this.state;

        fetchResponsibilitiesCenter(paging, filters);
    }

    componentWillUnmount() {
        const { resetResponsibilitiesCenter } = this.props;
        resetResponsibilitiesCenter();
    }

    applyFilters = () => {
        const { fetchResponsibilitiesCenter } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchResponsibilitiesCenter(newPagination, filters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as ResponsibilityCenterFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchResponsibilitiesCenter } = this.props;
        const { paging } = this.state;

        const newFilters = {
        } as ResponsibilityCenterFilters;

        const newSortModel = [{
            field: 'id',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchResponsibilitiesCenter(newPagination, newFilters);

        if(this.terminalsListRef !== null && this.terminalsListRef !== undefined &&
            this.terminalsListRef.current !== null && this.terminalsListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.terminalsListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchResponsibilitiesCenter } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchResponsibilitiesCenter(newPaging, filters);

        }
    }

    render() {
        const { classes, responsibilitiesCenter, fetchResponsibilitiesCenter, isLoading } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ResponsibilitiesCenterFilterComponent
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.RESPONSIBILITIES_CENTER}/add`
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <ResponsibilitiesCenterList rows={responsibilitiesCenter} isLoading={isLoading}
                                    ref={this.terminalsListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchResponsibilitiesCenter(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ResponsibilitiesCenter));