import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import SimulationStatusOut from '../interfaces/output/simulationStatusOut';
import SimulationStatusIn from '../interfaces/input/simulationStatusIn';
import Pagination from '../interfaces/common/pagination';

export default class SimulationStatusApi {
    static fetchSimulationStatusById = async(simulationStatusId: string): Promise<SimulationStatusOut> => {
        const { data } = await http.get<SimulationStatusOut>(`simulationStatus/${simulationStatusId}`);

        return data;
    };

    static fetchSimulationStatuss = async(paging: Paging, searchTerm: string): Promise<Pagination<SimulationStatusOut>> => {
        const url = urlcat('', '/simulationStatus', {
            ...paging,
            searchTerm
        });
        const { data } = await http.get<Pagination<SimulationStatusOut>>(url);

        return data;
    };

    static createSimulationStatus = async(simulationStatus: SimulationStatusOut): Promise<SimulationStatusOut> => {
        const newSimulationStatus: SimulationStatusIn = {
            code: simulationStatus?.code,
            name: simulationStatus?.name
        };

        const { data } = await http.post<SimulationStatusIn, SimulationStatusOut>('simulationStatus', newSimulationStatus);

        return data;
    }

    static updateSimulationStatus = async(simulationStatus: SimulationStatusOut): Promise<SimulationStatusOut> => {
        const newSimulationStatus: SimulationStatusIn = {
            code: simulationStatus?.code,
            name: simulationStatus?.name
        };
        
        const { data } = await http.put<SimulationStatusIn, SimulationStatusOut>(`simulationStatus/${simulationStatus.id}`, newSimulationStatus);

        return data;
    }

    static deleteSimulationStatus = async(simulationStatus: SimulationStatusOut): Promise<SimulationStatusOut> => {
        const { data } = await http.delete<SimulationStatusOut>(`simulationStatus/${simulationStatus.id}`);

        return data;
    }
}