import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import UserOut from '../interfaces/output/userOut';
import UserFilter from '../interfaces/filters/terminaleFilters';
import UserApi from '../api/userApi';
import { createUserError, createUserSuccess, deleteUserSuccess,
    fetchUserByIdError, fetchUserByIdSuccess, fetchUserDetailsError,
    fetchUserDetailsSuccess, fetchUsersError, fetchUsersSuccess, updateUserError, updateUserSuccess } from '../reducers/userReducer';

function* doFetchUser(action: PayloadAction<number>) {
    const userId = action.payload;

    try {
        const response: UserOut = yield call(UserApi.fetchUserById, userId);
        yield put(fetchUserByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUserByIdError(error as HttpErrorResponse));
    }
}

function* doFetchUserDetails(action: PayloadAction<boolean>) {
    const refresh = action.payload;
    try {
        const response: UserOut = yield call(UserApi.fetchUserDetails, refresh);
        yield put(fetchUserDetailsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUserDetailsError(error as HttpErrorResponse));
    }
}

function* doFetchUsers(action: PayloadAction<{ paging: Paging, filters: UserFilter }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<UserOut> = yield call(UserApi.fetchUsers, paging, filters);
        yield put(fetchUsersSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUsersError(error as HttpErrorResponse));
    }
}

function* doCreateUser(action: PayloadAction<UserOut>) {
    const user = action.payload;

    try {
        const response: UserOut = yield call(UserApi.createUser, user);

        yield put(createUserSuccess(response));
    }

    catch (error: unknown) {
        yield put(createUserError(error as HttpErrorResponse));
    }
}

function* doUpdateUser(action: PayloadAction<UserOut>) {
    const user = action.payload;

    try {
        const response: UserOut = yield call(UserApi.updateUser, user);

        yield put(updateUserSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateUserError(error as HttpErrorResponse));
    }
}

function* doDeleteUser(action: PayloadAction<number>) {
    const userId = action.payload;

    try {
        const response: UserOut = yield call(UserApi.deactivateUser, userId);

        yield put(deleteUserSuccess(response));
    }

    catch (error: unknown) {
        yield put(createUserError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('userLogin/fetchUserById', doFetchUser),
    takeLatest('userLogin/fetchUserDetails', doFetchUserDetails),
    takeLatest('userLogin/fetchUsers', doFetchUsers),
    takeLatest('userLogin/createUser', doCreateUser),
    takeLatest('userLogin/updateUser', doUpdateUser),
    takeLatest('userLogin/deleteUser', doDeleteUser)
];

