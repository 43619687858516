import React, { useEffect } from 'react';
import AddFactorSetPriorityStep1 from './addFactorSetPriorityStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createFactorSetPriority, createPriorityVersion, fetchFactorSetPriorityById, getFactorSetPriority, isCreatingPriority, isCreatingVersion, isUpdatingPriority,
    updateFactorSetPriority } from '../../../../reducers/factorSetPriorityReducer';
import { clonePriorityVersion, isCloningVersion, isUpdatingPriorityVersionStatus,
    isUpdatingVersion, updatePriorityVersion,
    updatePriorityVersionStatus } from '../../../../reducers/factorSetPriorityVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddPriorityStep2 from './addFactorSetPriorityStep2';
import AddPriorityVersionStep3 from './addFactorSetPriorityStep3';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';

interface IAddFactorSetFormProps {
    factorSetPriorityId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set priority'),
    add: LanguageUtils.createMessage('Add factor set priority'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetPriorityForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetPriorityId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetPriorityId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetPriority = useSelector(getFactorSetPriority);
    const prevFactorSetPriority = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetPriority);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingPriority);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingPriority);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingPriorityVersion = useSelector(isCreatingVersion);
    const prevIsCreatingPriorityVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingPriorityVersion);
    const propsIsUpdatingPriorityVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingPriorityVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriorityVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingPriorityStatus = useSelector(isUpdatingPriorityVersionStatus);
    const prevIsUpdatingPriorityStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingPriorityStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingPriorityVersion === true && !propsIsCreatingPriorityVersion || prevIsUpdatingPriorityVersion === true && !propsIsUpdatingPriorityVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingPriorityStatus === true && !propsIsUpdatingPriorityStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingPriorityStatus, propsIsUpdatingPriorityVersion, propsIsCreatingPriorityVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetPriorityId) {
            dispatch(fetchFactorSetPriorityById({ factorSetId: factorSetPriorityId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetPriority && propsFactorSetPriority.id) {
            setFactorSet(propsFactorSetPriority);
        }
    }, [propsFactorSetPriority.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newPriorityVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newPriorityVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newPriorityVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetPriorityStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetPriority(factorSet));
                }
                else {
                    dispatch(updateFactorSetPriority(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddPriorityStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createPriorityVersion(factorSet));
                }
                else {
                    dispatch(updatePriorityVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.Priority} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(clonePriorityVersion({ toVersionId: ensure(propsFactorSetPriority.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddPriorityVersionStep3 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updatePriorityVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/priority/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/priority/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/priority/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/priority/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/priority/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/priority/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  