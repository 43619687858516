import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import { RootState } from '../setup';

export type GTSState = {
    gtsRaw: CustomerOrder;
    gtsPreview: GtsOrder;
    gtsImport: GtsOrder;
    isLoading: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: GTSState = {
    gtsRaw: {} as CustomerOrder,
    gtsPreview: {} as GtsOrder,
    gtsImport: {} as GtsOrder,
    isLoading: false,
    error: undefined
};

export const gtsSlice = createSlice({
    name: 'gts',

    initialState,
    reducers: {
        fetchGtsOperationRaw: (state: GTSState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchGtsOperationRawSuccess: (state: GTSState, action: PayloadAction<CustomerOrder>) => {
            state.isLoading = false;
            state.gtsRaw = action.payload;
        },
        fetchGtsOperationRawError: (state: GTSState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchGtsOperationPreview: (state: GTSState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchGtsOperationPreviewSuccess: (state: GTSState, action: PayloadAction<GtsOrder>) => {
            state.isLoading = false;
            state.gtsPreview = action.payload;
        },
        fetchGtsOperationPreviewError: (state: GTSState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchGtsOperationImport: (state: GTSState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchGtsOperationImportSuccess: (state: GTSState, action: PayloadAction<GtsOrder>) => {
            state.isLoading = false;
            state.gtsImport = action.payload;
        },
        fetchGtsOperationImportError: (state: GTSState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchGtsOperationRaw, fetchGtsOperationRawSuccess, fetchGtsOperationRawError, fetchGtsOperationPreview, fetchGtsOperationPreviewSuccess, fetchGtsOperationPreviewError,
    fetchGtsOperationImport, fetchGtsOperationImportSuccess, fetchGtsOperationImportError } = gtsSlice.actions;

export const isLoadingGts = (state: RootState): boolean => state.gtsReducer.isLoading;
export const getGtsRaw = (state: RootState): CustomerOrder => state.gtsReducer.gtsRaw;
export const getGtsPreview = (state: RootState): GtsOrder => state.gtsReducer.gtsPreview;
export const getGtsImport = (state: RootState): GtsOrder => state.gtsReducer.gtsImport;

export default gtsSlice.reducer;