import React, { forwardRef } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import Loader from '../common/widgets/loader';
import WorkflowUtils from '../../utils/workflowUtils';
import { isUpdatingCustomer, isCreatingCustomer } from '../../reducers/customerReducer';
import Customer from '../../interfaces/output/customer';
import GenericTextField from '../common/widgets/genericTextField';

interface AddCustomerStep3Props {
    customer: Customer;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    vatId: LanguageUtils.createMessage('Vat id'),
    dynamicsId: LanguageUtils.createMessage('Dynamics id')
};

const AddCustomerStep3 = forwardRef((props: AddCustomerStep3Props, ref: any): JSX.Element => {
    const { customer, onChange } = props;
    const isLoadingObjects = [useSelector(isUpdatingCustomer), useSelector(isCreatingCustomer)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="standard" >
                        <GenericTextField<string>
                            name="vatId"
                            label={messages.vatId}
                            value={customer.vatId}
                            onChange={onChange}
                            type="number"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="standard">
                        <GenericTextField<number>
                            name="dynamicsId"
                            label={messages.dynamicsId}
                            type="number"
                            value={customer.dynamicsId}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddCustomerStep3;