import { http } from '../utils/http';
import TransitDiscount from '../interfaces/output/transitDiscount';

export default class TransitDiscountApi {
    static fetchTransitDiscountById = async(discountId: string): Promise<TransitDiscount> => {
        const { data } = await http.get<TransitDiscount>(`agreementversiontransitdiscount/${discountId}`);

        return data;
    };

    static updateTransitDiscount = async(discount: TransitDiscount): Promise<TransitDiscount> => {
        const newVersion = {
            productArenaFromId: discount.productArenaFrom?.id,
            productArenaToId: discount.productArenaTo?.id,
            transitDiscount: discount.transitDiscount
        };
        
        const { data } = await http.put<any, TransitDiscount>(`agreementversiontransitdiscount/${discount.id}`, newVersion);

        return data;
    }

    static deleteTransitDiscount = async(discountId: number): Promise<TransitDiscount> => {
        const { data } = await http.delete<any, TransitDiscount>(`agreementversiontransitdiscount/${discountId}`);

        return data;
    };
}