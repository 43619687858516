import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isLoadingAgreement } from '../../../reducers/agreementsReducer';
import Agreement from '../../../interfaces/output/agreement';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchAgreementVersionStatuses, getAgreementVersionStatuses, isLoadingAgreementVersionStatus } from '../../../reducers/agreementVersionStatusReducer';
import AgreementVersionStatus from '../../../interfaces/output/agreementVersionStatus';
import PageUtils from '../../../utils/pageUtils';
import { isUpdatingAgreementStatus } from '../../../reducers/agreementVersionReducer';

interface AddAgreementStep1Props {
    agreement: Agreement;
    onChangeVersion: any;
}

const messages = {
    status: LanguageUtils.createMessage('Status')
};

const AddAgreementStep3 = forwardRef((props: AddAgreementStep1Props, ref: any): JSX.Element => {
    const { agreement, onChangeVersion } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const agreementVersionStatuses = useSelector(getAgreementVersionStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingAgreementVersionStatus), useSelector(isLoadingAgreement), useSelector(isUpdatingAgreementStatus)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchAgreementVersionStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<AgreementVersionStatus>
                            options={agreementVersionStatuses}
                            value={agreement.version?.status}
                            onChange={(obj: AgreementVersionStatus | null) => onChangeVersion('status', obj)}
                            placeholder={messages.status}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddAgreementStep3;