import React, { forwardRef } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import GenericTextField from '../common/widgets/genericTextField';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import WorkflowUtils from '../../utils/workflowUtils';
import GTSOrderUnitService from '../../interfaces/output/gtsOrderUnitService';
import { isLoadingUnitService, isUpdatingGtsOrderUnitService } from '../../reducers/gtsUnitServiceReducer';

interface GtsOrderUnitServiceStep1Props {
    gtsUnitService: GTSOrderUnitService;
    onChange: any;
}

const messages = {
    serviceTimestamp: LanguageUtils.createMessage('Service timestamp'),
    quantity: LanguageUtils.createMessage('Quantity'),
    serviceType: LanguageUtils.createMessage('Service type'),
    place: LanguageUtils.createMessage('Place')
};

const GtsOrderUnitServiceFormStep1 = forwardRef((props: GtsOrderUnitServiceStep1Props, ref: any): JSX.Element => {
    const isLoadingObjects = [useSelector(isUpdatingGtsOrderUnitService), useSelector(isLoadingUnitService)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const { gtsUnitService, onChange } = props;
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="serviceTimestamp"
                            label={messages.serviceTimestamp}
                            value={gtsUnitService?.serviceTimestamp}
                            onChange={onChange}
                            type="now"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="place"
                            label={messages.place}
                            value={gtsUnitService.place}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            label={messages.quantity}
                            type="number"
                            value={gtsUnitService.quantity}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="serviceType"
                            label={messages.serviceType}
                            value={gtsUnitService.serviceType}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default GtsOrderUnitServiceFormStep1;