/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchCustomers, isLoadingCustomers, getCustomers, resetCustomers } from '../../reducers/customerReducer';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../constants/UrlConstants';
import Customer from '../../interfaces/output/customer';
import { RootState } from '../../setup';
import Filters from '../common/customerFilters';
import Pagination from '../../interfaces/common/pagination';
import CustomerFilters from '../../interfaces/output/filters';
import Paging from '../../interfaces/common/paging';
import { resetCorporates } from '../../reducers/customerCorporateReducer';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerList from './customerList';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import { getUserDetails } from '../../reducers/userReducer';
import UserOut from '../../interfaces/output/userOut';

interface ICustomersProps {
    customers: Pagination<Customer>;
    user: UserOut;
    classes: any;
    fetchCustomers: any;
    isLoading: boolean;
    onChange: () => void;
    resetCustomers: () => void;
    resetCorporates: () => void;
}

interface ICustomerState {
    searchTerm: string;
    filters: CustomerFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const messages = {
    customers: LanguageUtils.createMessage('Customers'),
    add: LanguageUtils.createMessage('Add'),
    corporates: LanguageUtils.createMessage('Customer corporates')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCustomers: (paging: Paging, filters: CustomerFilters) => dispatch(fetchCustomers({
        paging,
        filters
    })),
    resetCustomers: () => dispatch(resetCustomers()),
    resetCorporates: () => dispatch(resetCorporates())
});

const mapStoreToProps = (store: RootState) => {
    return {
        customers: getCustomers(store),
        isLoading: isLoadingCustomers(store),
        user: getUserDetails(store)
    };
};

class Customers extends Component<ICustomersProps, ICustomerState> {
    customerListRef: any;

    constructor(props: ICustomersProps) {
        super(props);
        const now = new Date();
        this.customerListRef = React.createRef();

        this.state = {
            searchTerm: '',
            filters: {
                sellerId: props.user.sellerId || null,
                visibleInGTS: true
            } as CustomerFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.customers,
                icon: <FontAwesomeIcon icon="user-friends" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
                priorityRank: false,
                validity: false
            }
        };
    }

    componentDidMount() {
        const { fetchCustomers } = this.props;
        const { paging, filters } = this.state;

        fetchCustomers(paging, filters);
    }

    componentWillUnmount() {
        const { resetCustomers, resetCorporates } = this.props;
        resetCustomers();
        resetCorporates();
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as CustomerFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchCustomers } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchCustomers(newPagination, filters);

        if(this.customerListRef !== null && this.customerListRef !== undefined &&
            this.customerListRef.current !== null && this.customerListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerListRef.current.resetDataGridPage();
        }
    }

    setDefaultState = () => {
        const { fetchCustomers } = this.props;
        const { paging } = this.state;
        const now = new Date();
        const newFilters = {
            visibleInGTS: true
            // orderFromDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
            // orderToDate: now
        } as CustomerFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };
        this.setState({
            paging: newPagination
        });

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchCustomers(newPagination, newFilters);
        if(this.customerListRef !== null && this.customerListRef !== undefined &&
            this.customerListRef.current !== null && this.customerListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchCustomers } = this.props;

        const newFilter = { ...filters } as CustomerFilters;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchCustomers(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchCustomers } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchCustomers(newPaging, filters);
        }
    }

    onColumnVisibilityModelChange= (newModel: GridColumnVisibilityModel) => {
        this.setState({
            columnVisibilityModel: newModel
        });
    }

    render() {
        const { classes, customers, fetchCustomers, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[
                                        {
                                            text: messages.corporates,
                                            icon: 'user-tie',
                                            href: `${UrlConstants.CORPORATES}`,
                                            button: true
                                        },
                                        {
                                            text: messages.add,
                                            icon: 'plus',
                                            href: `${UrlConstants.CUSTOMERS}/add`
                                        }]}
                                >
                                    <Filters applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <CustomerList rows={customers} isLoading={isLoading} hiddenColumns={['priorityRank', 'relation']}
                                    ref={this.customerListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchCustomers(newPage, filters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.onColumnVisibilityModelChange(newModel)
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Customers));