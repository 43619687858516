import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Paper } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Order from '../../interfaces/output/order';
import { createOrder, creditOrder, fetchOrderById, fetchUnitsByOrderId, findOrderPrice, getOrder,
    getUnits, isCreatingOrder, isCrediting, isLoadingOrder, isLoadingUnits, isUpdatingOrder, resetOrder,
    updateOrder, duplicateOrder }
    from '../../reducers/orderReducer';
import Pagination from '../../interfaces/common/pagination';
import OrderUnit from '../../interfaces/output/orderUnit';
import ViewOrder from './viewOrder';
import { deactivateOrderUnit, getUnitServices, isDeletingOrderUnit } from '../../reducers/orderUnitReducer';
import OrderUnitService from '../../interfaces/output/orderUnitService';
import OrderUnitList from './orderUnit/orderUnitsList';
import Paging from '../../interfaces/common/paging';
import LanguageUtils from '../../utils/LanguageUtils';
import { ensure } from '../../utils/arrayUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import AddCreditModal from '../common/addCreditModel';
import DetailedHistoryFilterComponent from '../common/detailedHistoryFilterComponent';
import DetailedHistoryFilter from '../../interfaces/filters/detailedHistoryFilter';
import CustomerReportList from '../common/customerReportList';
import CustomerReport from '../../interfaces/output/customerReport';
import { fetchCustomerReport, getCustomerReport, isLoadingCustomerReport } from '../../reducers/invoiceReducer';

interface IOrderDetailsProps {
    classes: any;
    theme: Theme;
    fetchOrderById: any;
    fetchUnitsByOrderId: (orderId: number, paging: Paging) => void;
    deactivateOrderUnit: (id: number) => void;
    fetchOrderUnitById: any;
    createOrder: any;
    updateOrder: any;
    creditOrder: any;
    order: Order;
    orderUnits: Pagination<OrderUnit>;
    orderUnit: OrderUnit;
    unitServices: Pagination<OrderUnitService>;
    match: any;
    history: any;
    location: any;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoading: boolean;
    isCrediting: boolean;
    isLoadingUnits: boolean;
    isLoadingOrder: boolean;
    isDeletingOrderUnit: boolean;
    resetOrder: () => void;
    customerReport: Pagination<CustomerReport>;
    isLoadingCustomerReport: boolean;
    fetchCustomerReport: (filters: DetailedHistoryFilter, paging: Paging) => void;
    findOrderPrice: any;
    duplicateOrder: any;
}

interface IOrderDetailsState {
    order: Order;
    orderPagination: Paging;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    showAddForm: boolean;
    pagingCustomerReport: Paging;
    sortModelCustomerReport: GridSortModel;
    detailedHistoryFilter: DetailedHistoryFilter;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrderById: (orderId: number) => dispatch(fetchOrderById(orderId)),
    fetchUnitsByOrderId: (orderId: number, paging: Paging) => dispatch(fetchUnitsByOrderId({
        id: orderId,
        paging
    })),
    updateOrder: (order: Order) => dispatch(updateOrder(order)),
    createOrder: (order: Order) => dispatch(createOrder(order)),
    deactivateOrderUnit: (orderUnitId: number) => dispatch(deactivateOrderUnit(orderUnitId)),
    resetOrder: () => dispatch(resetOrder()),
    fetchCustomerReport: (filter: DetailedHistoryFilter, paging: Paging) => dispatch(fetchCustomerReport({
        filter,
        paging
    })),
    findOrderPrice: (orderId: number) => dispatch(findOrderPrice(orderId)),
    duplicateOrder: (orderId: number) => dispatch(duplicateOrder(orderId))
});

const mapStoreToProps = (store: RootState) => {
    return {
        order: getOrder(store),
        isUpdating: isUpdatingOrder(store),
        isCrediting: isCrediting(store),
        isCreating: isCreatingOrder(store),
        orderUnits: getUnits(store),
        unitServices: getUnitServices(store),
        isLoadingOrder: isLoadingOrder(store),
        isLoadingUnits: isLoadingUnits(store),
        isDeletingOrderUnit: isDeletingOrderUnit(store),
        isLoading: isUpdatingOrder(store) || isCreatingOrder(store) || isLoadingUnits(store) || isCrediting(store),
        isLoadingCustomerReport: isLoadingCustomerReport(store),
        customerReport: getCustomerReport(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('ORDER'),
    properties: LanguageUtils.createMessage('Properties'),
    orderUnits: LanguageUtils.createMessage('Order units'),
    credit: LanguageUtils.createMessage('Credit'),
    detailedHistory: LanguageUtils.createMessage('Detailed history'),
    findOrderPrice: LanguageUtils.createMessage('Calculated price'),
    duplicateOrder: LanguageUtils.createMessage('Duplicate order')
};

class OrderDetails extends Component<IOrderDetailsProps, IOrderDetailsState> {
    orderUnitListRef: any;
    customerReportListRef: any;

    constructor(props: IOrderDetailsProps) {
        super(props);
        this.customerReportListRef = React.createRef();

        this.orderUnitListRef = React.createRef();
        this.state = {
            order: {} as Order,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDERS}/${props.match.params.orderId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDERS}/${props.match.params.orderId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.orderUnits,
                icon: <FontAwesomeIcon icon="box" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDERS}/${props.match.params.orderId}/units`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDERS}/${props.match.params.orderId}/units`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.detailedHistory,
                icon: <FontAwesomeIcon icon={['fab', 'microsoft']} size="1x" transform="grow-4" />,
                url: `/${UrlConstants.ORDERS}/${props.match.params.orderId}/customerReport`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDERS}/${props.match.params.orderId}/customerReport`, props.location.pathname)
            }
            ]),
            orderPagination: PageUtils.getDefaultPaging(),
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {},
            showAddForm: false,
            pagingCustomerReport: PageUtils.getDefaultPaging(),
            sortModelCustomerReport: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            detailedHistoryFilter: {
            } as DetailedHistoryFilter
        };
    }

    componentDidMount() {
        const { fetchOrderById , fetchCustomerReport } = this.props;
        const { orderId } = this.props.match.params;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;

        fetchOrderById(orderId);
        const filter = {
            ...detailedHistoryFilter,
            orderId: orderId
        } as DetailedHistoryFilter;
        fetchCustomerReport(filter, pagingCustomerReport);
    }

    componentDidUpdate(prevProps: IOrderDetailsProps) {
        const { order, isLoadingOrder, isDeletingOrderUnit } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(order !== prevProps.order) {
            this.setState({ order });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && (!isLoadingOrder && prevProps.isLoadingOrder || !isDeletingOrderUnit && prevProps.isDeletingOrderUnit)) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetOrder } = this.props;
        resetOrder();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchUnitsByOrderId } = this.props;
        const { orderId } = this.props.match.params;
        const paging = PageUtils.getDefaultPaging();

        if(selectedMenuItem?.id === 2) {
            fetchUnitsByOrderId(orderId, paging);
        }
    };

    getSelectedMenuItem = (state: IOrderDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateOrderUnit = () => {
        const { selectedRow } = this.state;
        const { deactivateOrderUnit } = this.props;

        deactivateOrderUnit(ensure(selectedRow));
    };

    findOrderPrice = () => {
        const { orderId } = this.props.match.params;
        const { findOrderPrice } = this.props;

        findOrderPrice(orderId);
    };

    duplicateOrder = () => {
        const { orderId } = this.props.match.params;
        const { duplicateOrder } = this.props;

        duplicateOrder(orderId);
    }

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { orderId } = this.props.match.params;
        const { selectedRow } = this.state;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.ORDERS}/${orderId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'credit-card',
                        text: messages.credit,
                        onClick: this.toggleAddModal
                    },
                    {
                        icon: 'tag',
                        text: messages.findOrderPrice,
                        onClick: this.findOrderPrice
                    },
                    {
                        icon: 'plus',
                        text: messages.duplicateOrder,
                        onClick: this.duplicateOrder
                    }
                ];
            }

            case 2: { //order units
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.ORDERS}/${orderId}/orderunit/add`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateOrderUnit
                    }
                ];
            }

            default: {
                return [];
            }
        }
    };

    _onSortModelChange = (newModel: GridSortModel) => {
        const { fetchUnitsByOrderId } = this.props;
        const { orderPagination, sortModel, order } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...orderPagination,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                orderPagination: newPagination
            });

            fetchUnitsByOrderId(
                ensure(order.id),
                newPagination
            );
        }
    }

    applyDetaildHistoryFilters = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, detailedHistoryFilter } = this.state;
        const { orderId } = this.props.match.params;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };

        const filter = {
            ...detailedHistoryFilter,
            orderId: orderId
        } as DetailedHistoryFilter;
        
        this.setState({
            pagingCustomerReport: newPagination,
            detailedHistoryFilter: filter
        });

        fetchCustomerReport(filter, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    setDetaildHistoryFiltersDefaultState = () => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport } = this.state;
        const { orderId } = this.props.match.params;

        const newFilters = {
            orderId: orderId
        } as DetailedHistoryFilter;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        } as GridSortItem];

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            pagingCustomerReport: newPagination,
            detailedHistoryFilter: newFilters
        });

        fetchCustomerReport(newFilters, newPagination);

        if(this.customerReportListRef !== null && this.customerReportListRef !== undefined &&
            this.customerReportListRef.current !== null && this.customerReportListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.customerReportListRef.current.resetDataGridPage();
        }
    };

    onDetaildHistoryFiltersChange = (attribute: string, value: any) => {
        const { detailedHistoryFilter, pagingCustomerReport } = this.state;

        const newFilter = { ...detailedHistoryFilter } as DetailedHistoryFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...pagingCustomerReport,
            page: newPaging.page
        };
        this.setState({
            detailedHistoryFilter: newFilter,
            pagingCustomerReport: newPagination
        });
    };

    _onSortChangeCustomerReport = (newModel: GridSortModel) => {
        const { fetchCustomerReport } = this.props;
        const { pagingCustomerReport, sortModelCustomerReport, detailedHistoryFilter } = this.state;
        const { orderId } = this.props.match.params;

        const newFilters = {
            ...detailedHistoryFilter,
            orderId: orderId
        } as DetailedHistoryFilter;

        if(JSON.stringify(sortModelCustomerReport) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...pagingCustomerReport,
                sort: newModel
            };
            this.setState({
                sortModelCustomerReport: newModel,
                pagingCustomerReport: newPagination,
                detailedHistoryFilter: newFilters
            });

            fetchCustomerReport(newFilters, newPagination);
        }
    }

    toggleAddModal = () => {
        this.setState({ showAddForm: !this.state.showAddForm });
    }

    render() {
        const { classes, orderUnits, isLoadingUnits, isLoadingOrder, fetchUnitsByOrderId, isDeletingOrderUnit,
            fetchCustomerReport, isLoadingCustomerReport, customerReport } = this.props;
        const { order, menuItems, orderPagination, columnVisibilityModel, showAddForm, detailedHistoryFilter, pagingCustomerReport } = this.state;
        const { orderId } = this.props.match.params;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Order}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: order.orderNumber,
                    icon: <FontAwesomeIcon icon="file-contract" size="1x" />
                } as IMenuItem}
            >
                <Paper>
                    <Container className={classes.container} maxWidth="xl">
                        <Switch>
                            <Route path={`/${UrlConstants.ORDERS}/:orderId/properties`}>
                                <ViewOrder order={order} />
                            </Route>
                            <Route path={`/${UrlConstants.ORDERS}/:orderId/units`}>
                                <OrderUnitList isLoading={isLoadingUnits || isDeletingOrderUnit} rows={orderUnits}
                                    checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                    ref={this.orderUnitListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...orderPagination,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchUnitsByOrderId(
                                            ensure(order.id),
                                            newPage
                                        );
                                        this.setState({ orderPagination: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Route>
                            <Route path={`/${UrlConstants.ORDERS}/:orderId/customerReport`}>
                                <>
                                    <DetailedHistoryFilterComponent applyFilters={this.applyDetaildHistoryFilters}
                                        setDefaultState={this.setDetaildHistoryFiltersDefaultState} filters={detailedHistoryFilter} onChange={this.onDetaildHistoryFiltersChange} />
                                    <CustomerReportList isLoading={isLoadingCustomerReport}
                                        rows={customerReport}
                                        ref={this.customerReportListRef}
                                        onPageChange={(nextPage: number, nextSize: number) => {
                                            const newPage = {
                                                ...pagingCustomerReport,
                                                page: nextPage,
                                                size: nextSize
                                            };
                                            const newFilter = {
                                                ...detailedHistoryFilter,
                                                orderId: orderId
                                            } as DetailedHistoryFilter;

                                            fetchCustomerReport(newFilter,newPage);

                                            this.setState({
                                                pagingCustomerReport: newPage,
                                                detailedHistoryFilter: newFilter
                                            });
                                        }}
                                        onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeCustomerReport(sortModel)}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                            this.setState({
                                                columnVisibilityModel: newModel
                                            })
                                        }
                                    />
                                </>
                            </Route>
                        </Switch>
                    </Container>
                    <AddCreditModal show={showAddForm} onClose={this.toggleAddModal} id={orderId} createCredit={creditOrder} />
                </Paper>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrderDetails));