import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import FactorSetDangerousGoodsApi from '../api/factorSetDangerousGoodsApi';
import { createFactorSetDangerousGoodsSuccess, deactivateFactorSetDangerousGoodsSuccess, fetchFactorSetDangerousGoodsSuccess,
    fetchFactorSetDangerousGoodsByIdSuccess, updateFactorSetDangerousGoodsSuccess, createFactorSetDangerousGoodsError,
    deactivateFactorSetDangerousGoodsError, fetchFactorSetDangerousGoodsByIdError, fetchFactorSetDangerousGoodsError,
    updateFactorSetDangerousGoodsError,
    createDangerousGoodsVersionSuccess,
    createDangerousGoodsVersionError }
    from '../reducers/factorSetDangerousGoodsReducer';
import DangerousGoodsFilters from '../components/factorSets/departure/departureFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';

function* doFetchFactorSetDangerousGood(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {
    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDangerousGoodsApi.fetchFactorSetDangerousGoodsById, factorSetId, versionId);
    
        yield put(fetchFactorSetDangerousGoodsByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetDangerousGoodsByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDangerousGoods(action: PayloadAction<{ paging: Paging, filters: DangerousGoodsFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetDangerousGoodsApi.fetchFactorSetDangerousGoods, paging, filters);

        yield put(fetchFactorSetDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetDangerousGoodsError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetDangerousGoods(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDangerousGoodsApi.createFactorSetDangerousGoods, factorSet);

        yield put(createFactorSetDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetDangerousGoodsError(error as HttpErrorResponse));
    }
}

function* doCreateDangerousGoodsVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDangerousGoodsApi.createDangerousGoodsVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createDangerousGoodsVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createDangerousGoodsVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDangerousGoods(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetDangerousGoodsApi.updateFactorSetDangerousGoods, factorSet);

        yield put(updateFactorSetDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetDangerousGoodsError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetDangerousGoods(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetDangerousGoodsApi.deactivateFactorSetDangerousGoods, id);

        yield put(deactivateFactorSetDangerousGoodsSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetDangerousGoodsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetDangerousGoods/fetchFactorSetDangerousGoodsById', doFetchFactorSetDangerousGood),
    takeLatest('factorSetDangerousGoods/fetchFactorSetDangerousGoods', doFetchFactorSetDangerousGoods),
    takeLatest('factorSetDangerousGoods/createFactorSetDangerousGoods', doCreateFactorSetDangerousGoods),
    takeLatest('factorSetDangerousGoods/createDangerousGoodsVersion', doCreateDangerousGoodsVersion),
    takeLatest('factorSetDangerousGoods/updateFactorSetDangerousGoods', doUpdateFactorSetDangerousGoods),
    takeLatest('factorSetDangerousGoods/deactivateFactorSetDangerousGoods', doDeactivateFactorSetDangerousGoods)
];