import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import GenericStepper from '../../common/widgets/genericStepper';
import ResponsibilityCenter from '../../../interfaces/output/responsibilityCenter';
import { createResponsibilityCenter, fetchResponsibilityCenterById, getResponsibilityCenter,
    isCreatingResponsibilityCenter, isLoadingResponsibilityCenter, isUpdatingResponsibilityCenter,
    updateResponsibilityCenter } from '../../../reducers/responsibilityCenterReducer';
import ResponsibilityCenterStep1Validation from './validation/responsibilityCenterStep1Validation';
import ResponsibilityCenterFormStep1 from './responsibilityCenterFormStep1';

interface ITerminalFormProps {
    responsibilityCenterId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit responsibility center'),
    add: LanguageUtils.createMessage('Add responsibility center')
};

export default function ResponsibilityCenterForm(props: ITerminalFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { responsibilityCenterId, steps, type } = props;
    const [responsibilityCenter, setResponsibilityCenter] = React.useState<ResponsibilityCenter>({ id: responsibilityCenterId } as ResponsibilityCenter);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propResponsibilityCenter = useSelector(getResponsibilityCenter);
    const propsIsUpdatingResponsibilityCenter = useSelector(isUpdatingResponsibilityCenter);
    const prevIsUpdatingResponsibilityCenter = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingResponsibilityCenter);
    const propsIsCreatingResponsibilityCenter = useSelector(isCreatingResponsibilityCenter);
    const prevIsCreatingResponsibilityCenter = WorkflowUtils.usePrevious<boolean>(propsIsCreatingResponsibilityCenter);
    const propsIsLoadingResponsibilityCenter = useSelector(isLoadingResponsibilityCenter);
    const prevIsLoadingResponsibilityCenter = WorkflowUtils.usePrevious<boolean>(propsIsLoadingResponsibilityCenter);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingResponsibilityCenter === true && !propsIsUpdatingResponsibilityCenter) || (prevIsCreatingResponsibilityCenter && !propsIsCreatingResponsibilityCenter)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingResponsibilityCenter, propsIsCreatingResponsibilityCenter]);

    useEffect(() => {
        if(responsibilityCenterId) {
            dispatch(fetchResponsibilityCenterById(responsibilityCenterId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingResponsibilityCenter && !propsIsLoadingResponsibilityCenter) {
            setResponsibilityCenter(propResponsibilityCenter);
        }

    }, [propsIsLoadingResponsibilityCenter]);

    const onChange = (attribute: string, value: any) => {
        const newResponsibilityCenter = { ...responsibilityCenter } as ResponsibilityCenter;
        (newResponsibilityCenter as any)[attribute] = value;

        setResponsibilityCenter(newResponsibilityCenter);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <ResponsibilityCenterFormStep1 onChange={onChange} responsibilityCenter={responsibilityCenter} />,
            validationFn: () => ResponsibilityCenterStep1Validation.validateResponsibilityCenter(responsibilityCenter),
            onNext: () => {
                if(!responsibilityCenter.id) {
                    dispatch(createResponsibilityCenter(responsibilityCenter));
                }
                else {
                    dispatch(updateResponsibilityCenter(responsibilityCenter));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.RESPONSIBILITIES_CENTER}`;

            case FormType.Edit:
                return `/${UrlConstants.RESPONSIBILITIES_CENTER}/${responsibilityCenter.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
