import React from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import GTSOrderUnitService from '../../interfaces/output/gtsOrderUnitService';
import LanguageUtils from '../../utils/LanguageUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomStringLink from '../common/customStringLink';
import CustomLink from '../common/customLink';

interface IOrderUnitServiceListProps {
    classes?: any;
    gtsUnitServices?: Pagination<GTSOrderUnitService>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    orderNumber: LanguageUtils.createMessage('Order number'),
    gtsUnitType: LanguageUtils.createMessage('GTS unit type'),
    serviceTimestamp: LanguageUtils.createMessage('Service timestamp'),
    place: LanguageUtils.createMessage('Place'),
    serviceType: LanguageUtils.createMessage('Service type'),
    quantity: LanguageUtils.createMessage('Quantity'),
    receivedFromGTS: LanguageUtils.createMessage('Received from gts')
};

export default function OrderUnitServiceList(props: IOrderUnitServiceListProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, gtsUnitServices, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'orderNumber',
                headerName: intl.formatMessage(messages.orderNumber),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.orderNumber} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderNumber', hiddenColumns)
            },
            {
                field: 'gtsUnitType',
                headerName: intl.formatMessage(messages.gtsUnitType),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.gtsUnitType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('gtsUnitType', hiddenColumns)
            },
            {
                field: 'serviceTimestamp',
                headerName: intl.formatMessage(messages.serviceTimestamp),
                flex: 1,
                sortable: true,
                renderCell: (params: any) => {
                    return <CustomLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.serviceTimestamp} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceTimestamp', hiddenColumns)
            },
            {
                field: 'place',
                headerName: intl.formatMessage(messages.place),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/${applyRowFn(params).id}`} value={applyRowFn(params)?.place ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('place', hiddenColumns)
            },
            {
                field: 'quantity',
                headerName: intl.formatMessage(messages.quantity),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/${applyRowFn(params).id}`} value={applyRowFn(params)?.quantity ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('quantity', hiddenColumns)
            },
            {
                field: 'serviceType',
                headerName: intl.formatMessage(messages.serviceType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.serviceType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('serviceType', hiddenColumns)
            },
            {
                field: 'receivedFromGTS',
                headerName: intl.formatMessage(messages.receivedFromGTS),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <CustomLink link={`/#/${UrlConstants.GTS_UNIT_SERVICES}/cellValuesz`} >
                        <NullableFormattedDate value={params.row?.receivedFromGTS} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('receivedFromGTS', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || gtsUnitServices} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
}