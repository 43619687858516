import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import AddFactorSetCalendarLineForm from './addFactorSetCalendarLineForm';
import { FormType } from '../../../../constants/constants';
import { resetCalendarVersionLines, resetFactorSetCalendarLine } from '../../../../reducers/calendarLineReducer';

interface IAddFactorSetLineProps {
    classes: any;
    theme: Theme;
    match: any;
    calendarLineId?: number;
    factorSetCalendarId?: number;
    factorSetVersionId?: number;
    resetCalendarVersionLines: any;
    resetFactorSetCalendarLine: any;
    type: FormType;
    steps: Array<number>;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetCalendarVersionLines: () => dispatch(resetCalendarVersionLines()),
    resetFactorSetCalendarLine: () => dispatch(resetFactorSetCalendarLine())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetLineProps) => {
    return {
        calendarLineId: ownProps.match.params?.calendarLineId ? Number(ownProps.match.params?.calendarLineId) : undefined,
        factorSetCalendarId: ownProps.match.params?.calendarId ? Number(ownProps.match.params?.calendarId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetCalendarLine extends Component<IAddFactorSetLineProps> {

    componentWillUnmount() {
        const { resetCalendarVersionLines, resetFactorSetCalendarLine } = this.props;

        resetCalendarVersionLines();
        resetFactorSetCalendarLine();
    }

    render() {
        const { classes, steps, calendarLineId, factorSetCalendarId, type, factorSetVersionId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddFactorSetCalendarLineForm calendarLineId={calendarLineId} factorSetCalendarId={factorSetCalendarId} steps={steps} type={type}
                            factorSetVersionId={factorSetVersionId} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetCalendarLine));