import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import Service from '../../../interfaces/output/service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceStatuses, getServiceStatuses, isLoadingServiceStatuses } from '../../../reducers/serviceStatusReducer';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isLoadingService, isUpdatingService } from '../../../reducers/serviceReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import ServiceStatus from '../../../interfaces/output/serviceStatus';

interface AddServiceStep1Props {
    service: Service;
    onChange: any;
}

const messages = {
    serviceStatus: LanguageUtils.createMessage('Service type')
};

const AddServiceStep3 = forwardRef((props: AddServiceStep1Props, ref: any): JSX.Element => {
    const { service, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const serviceStatuses = useSelector(getServiceStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingServiceStatuses), useSelector(isUpdatingService), useSelector(isLoadingService)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchServiceStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ServiceStatus>
                            options={serviceStatuses}
                            value={service.status}
                            onChange={(obj: ServiceStatus | null) => onChange('status', obj)}
                            placeholder={messages.serviceStatus}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddServiceStep3;