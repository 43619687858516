import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import UrlConstants from '../../constants/UrlConstants';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import Order from '../../interfaces/output/order';
import { fetchOrders, getOrder, getOrders, isLoadingOrders, resetOrders } from '../../reducers/orderReducer';
import OrdersList from './ordersList';
import OrdersFilterComponent from './ordersFilterComponent';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import DateTimeUtils from '../../utils/dateTimeUtils';
import OrderFilters from '../../interfaces/filters/orderFilters';

interface IOrdersProps {
    orders: Pagination<Order>;
    classes: any;
    fetchOrders: any;
    isLoading: boolean;
    clearSearch: () => void;
    onChange: () => void;
    resetOrders: () => void;
}

interface IOrdersState {
    searchTerm: string;
    filters: OrderFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    orders: LanguageUtils.createMessage('Orders'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrders: (paging: Paging, filters: OrderFilters) => dispatch(fetchOrders({
        paging,
        filters
    })),
    resetOrders: () => dispatch(resetOrders())
});

const mapStoreToProps = (store: RootState) => {
    return {
        orders: getOrders(store),
        order: getOrder(store),
        isLoading: isLoadingOrders(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class Orders extends Component<IOrdersProps, IOrdersState> {
    ordersListRef: any;

    constructor(props: IOrdersProps) {
        super(props);
        const now = new Date();
        this.ordersListRef = React.createRef();

        this.state = {
            searchTerm: '',
            filters: {
                orderNumber: '',
                hasErrors: false,
                orderStatusIds: [],
                fromDate: DateTimeUtils.dateTimeGMTStart(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)),
                toDate: DateTimeUtils.dateTimeGMTEnd(now)
            } as OrderFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.orders,
                icon: <FontAwesomeIcon icon="file-contract" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchOrders } = this.props;
        const { paging, filters } = this.state;

        fetchOrders(paging, filters);
    }

    componentWillUnmount() {
        const { resetOrders } = this.props;
        resetOrders();
    }

    applyFilters = () => {
        const { fetchOrders } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchOrders(newPagination, filters);

        if(this.ordersListRef !== null && this.ordersListRef !== undefined &&
            this.ordersListRef.current !== null && this.ordersListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.ordersListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as OrderFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            filters: {
                ...newFilter,
                fromDate: newFilter.orderNumber ? undefined : newFilter.fromDate,
                toDate: newFilter.orderNumber ? undefined : newFilter.toDate
            },
            paging: newPagination
        });
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchOrders } = this.props;

        const newFilter = { ...filters } as OrderFilters;
        (newFilter as any)['orderNumber'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchOrders(newPagination, newFilter);
    }

    setDefaultState = () => {
        const { fetchOrders } = this.props;
        const { paging } = this.state;
        // const now = new Date();
        const newFilters = {
            orderNumber: '',
            hasErrors: false,
            orderStatusIds: []
            // fromDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
            // toDate: now
        } as OrderFilters;

        const newSortModel = [{
            field: 'orderNumber',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchOrders(newPagination, newFilters);

        if(this.ordersListRef !== null && this.ordersListRef !== undefined &&
            this.ordersListRef.current !== null && this.ordersListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.ordersListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchOrders } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchOrders(newPaging, filters);
        }
    }

    render() {
        const { classes, orders, fetchOrders, isLoading } = this.props;
        const { paging, filters, menuItem, searchTerm, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.ORDERS}/add`
                                    }]}
                                >
                                    <OrdersFilterComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <OrdersList
                                    rows={orders} isLoading={isLoading}
                                    ref={this.ordersListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchOrders(newPage, filters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Orders));