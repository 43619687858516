import { http } from '../utils/http';
import InvoiceFrequency from '../interfaces/output/invoiceFrequency';
import Pagination from '../interfaces/common/pagination';

export default class InvoiceFrequencyApi {
    static fetchInvoiceFrequencyById = async(invoiceFrequencyId: string): Promise<InvoiceFrequency> => {
        const { data } = await http.get<InvoiceFrequency>(`/invoicefrequency/${invoiceFrequencyId}`);

        return data;
    };

    static fetchInvoicesFrequency = async(): Promise<Pagination<InvoiceFrequency>> => {
        const { data } = await http.get<Pagination<InvoiceFrequency>>('/invoicefrequency');

        return data;
    };
}
