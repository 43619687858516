import React, { forwardRef, useEffect } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import OrderUnit from '../../interfaces/output/orderUnit';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import Loader from '../common/widgets/loader';
import OrderUnitStatus from '../../interfaces/output/orderUnitStatus';
import WorkflowUtils from '../../utils/workflowUtils';
import PageUtils from '../../utils/pageUtils';
import { fetchUnitStatuses, getOrderUnitStatuses, isLoadingUnitStatuses } from '../../reducers/unitStatusReducer';
import { isLoadingOrderUnit, isUpdatingOrderUnit } from '../../reducers/orderUnitReducer';
import LanguageUtils from '../../utils/LanguageUtils';

interface AddOrderUnitStep3Props {
    orderUnit: OrderUnit;
    onChange: any;
}

const messages = {
    status: LanguageUtils.createMessage('Status')
};

const AddOrderUnitStep1 = forwardRef((props: AddOrderUnitStep3Props, ref: any): JSX.Element => {
    const { orderUnit, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const statuses = useSelector(getOrderUnitStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingUnitStatuses), useSelector(isUpdatingOrderUnit), useSelector(isLoadingOrderUnit)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchUnitStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<OrderUnitStatus>
                            options={statuses}
                            value={orderUnit.orderUnitStatus}
                            onChange={(obj: OrderUnitStatus | null) => onChange('orderUnitStatus', obj)}
                            placeholder={messages.status}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddOrderUnitStep1;