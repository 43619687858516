import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import HoursLineApi from '../api/hoursLineApi';
import FactorSetHoursLineOutput from '../interfaces/output/factorSetHoursLineOut';
import HoursLineFilters from '../components/factorSets/hours/line/hoursLineFilters';
import { createHoursVersionLineError, createHoursVersionLineSuccess, deactivateFactorSetHoursLineError, deactivateFactorSetHoursLineSuccess,
    fetchHoursVersionLinesError, fetchHoursVersionLinesSuccess, fetchFactorSetHoursLineByIdError, fetchFactorSetHoursLineByIdSuccess, updateFactorSetHoursLineError,
    updateFactorSetHoursLineSuccess } from '../reducers/hoursLineReducer';

function* doFetchFactorSetHoursLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetHoursLineOutput = yield call(HoursLineApi.fetchFactorSetHoursLineById, lineId);
        yield put(fetchFactorSetHoursLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetHoursLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetHoursLines(action: PayloadAction<{paging: Paging, filters: HoursLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetHoursLineOutput> = yield call(HoursLineApi.fetchFactorSetHoursLines, paging, filters);
    
        yield put(fetchHoursVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchHoursVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetHoursLine(action: PayloadAction<FactorSetHoursLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetHoursLineOutput = yield call(HoursLineApi.createFactorSetHoursLine, line);

        yield put(createHoursVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createHoursVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetHoursLine(action: PayloadAction<FactorSetHoursLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetHoursLineOutput = yield call(HoursLineApi.updateFactorSetHoursLine, line);

        yield put(updateFactorSetHoursLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetHoursLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetHoursLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetHoursLineOutput = yield call(HoursLineApi.deactivateFactorSetHoursLine, lineId);

        yield put(deactivateFactorSetHoursLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetHoursLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetHoursLineById', doFetchFactorSetHoursLineById),
    takeLatest('factorSetLine/fetchHoursVersionLines', doFetchFactorSetHoursLines),
    takeLatest('factorSetLine/createHoursVersionLine', doCreateFactorSetHoursLine),
    takeLatest('factorSetLine/updateFactorSetHoursLine', doUpdateFactorSetHoursLine),
    takeLatest('factorSetLine/deactivateFactorSetHoursLine', doDeactivateFactorSetHoursLine)
];