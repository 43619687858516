/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import Pagination from '../../../interfaces/common/pagination';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import PriorityLineFilters from './line/priorityLineFilters';
import FactorSetPriorityLineOutput from '../../../interfaces/output/factorSetPriorityLineOut';
import ViewFactorSetPriority from './viewFactorSetPriority';
import ViewFactorSetPriorityLines from './line/priorityLinesList';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import { deactivateFactorSetPriority, fetchFactorSetPriorityById, getFactorSetPriority, isDeletingPriority, isLoadingFactorSetPriority, resetFactorSetPriority, resetFactorSetPriorities }
    from '../../../reducers/factorSetPriorityReducer';
import Loader from '../../common/widgets/loader';
import FactorsVersionsList from '../factorsVersionList';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import {
    deactivateFactorSetPriorityLine, fetchPriorityVersionLines, getPriorityLines, isCreatingPriorityLine, isDeletingPriorityLine, isLoadingFactorSetPriorityLines, isUpdatingPriorityLine,
    resetPriorityVersionLines
} from '../../../reducers/priorityLineReducer';
import { fetchPriorityVersions, getFactorSetPriorityVersions, isLoadingFactorSetPriorityVersions } from '../../../reducers/factorSetPriorityVersionReducer';
import PriorityType from '../../../interfaces/output/priorityType';
import { fetchPriorityTypes, getPriorityTypes } from '../../../reducers/priorityTypeReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../reducers/unitTypeReducer';
import UnitType from '../../../interfaces/output/unitType';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IFactorSetPriorityDetailsProps {
    classes: any;
    theme: Theme;
    fetchFactorSetPriorityById: any;
    fetchPriorityVersionLines: any;
    deactivateFactorSetPriority: any;
    deactivateFactorSetPriorityLine: any;
    fetchPriorityTypes: any;
    fetchPriorityVersions: any;
    resetFactorSetPriority: () => any;
    resetFactorSetPriorities: () => any;
    resetPriorityVersionLines: () => any;
    factorSetPriority: FactorSetOut;
    match: any;
    priorityLines: Pagination<FactorSetPriorityLineOutput>;
    factorVersions: Pagination<FactorSetVersionOut>;
    priorityTypes: Pagination<PriorityType>;
    history: any;
    location: any;
    isUpdatingPriority: boolean;
    isDeletingPriority: boolean;
    isDeletingLine: boolean;
    isCreatingPriority: boolean;
    isLoading: boolean;
    isLoadingFactorSetPriority: boolean;
    isLoadingFactorSetPriorityLines: boolean;
    isLoadingFactorSetVersions: boolean;
    fetchUnitTypes: any;
    isLoadingUnitTypes: boolean;
    unitTypes: Pagination<UnitType>;
}

interface IFactorSetPriorityDetailsState {
    open: boolean;
    factorSetPriority: FactorSetOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverPaginationLines: Paging;
    sortModelLines: GridSortModel;
    sortModelVersions: GridSortModel;
    factorSetPriorityColumnVisibilityModel: GridColumnVisibilityModel;
    factorsVersionLinesColumnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetPriorityById: (id: number, versionId: number) => dispatch(fetchFactorSetPriorityById({
        factorSetId: id,
        versionId
    })),
    fetchPriorityVersionLines: (paging: Paging, filters: PriorityLineFilters) => dispatch(fetchPriorityVersionLines({
        paging,
        filters
    })),
    fetchPriorityVersions: (id: number, paging: Paging) => dispatch(fetchPriorityVersions({
        id,
        paging
    })),
    deactivateFactorSetPriority: (id: number) => dispatch(deactivateFactorSetPriority(id)),
    deactivateFactorSetPriorityLine: (id: number) => dispatch(deactivateFactorSetPriorityLine(id)),
    fetchPriorityTypes: (paging: Paging) => dispatch(fetchPriorityTypes({ paging })),
    resetFactorSetPriority: () => dispatch(resetFactorSetPriority()),
    resetFactorSetPriorities: () => dispatch(resetFactorSetPriorities()),
    resetPriorityVersionLines: () => dispatch(resetPriorityVersionLines()),
    fetchUnitTypes: (paging: Paging) => dispatch(fetchUnitTypes({ paging }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetPriority: getFactorSetPriority(store),
        priorityLines: getPriorityLines(store),
        factorVersions: getFactorSetPriorityVersions(store),
        priorityTypes: getPriorityTypes(store),
        unitTypes: getUnitTypes(store),
        isUpdatingPriority: isUpdatingPriorityLine(store),
        isDeletingLine: isDeletingPriorityLine(store),
        isDeletingPriority: isDeletingPriority(store),
        isCreatingPriority: isCreatingPriorityLine(store),
        isLoadingFactorSetPriorityLines: isLoadingFactorSetPriorityLines(store),
        isLoadingFactorSetVersions: isLoadingFactorSetPriorityVersions(store),
        isLoadingFactorSetPriority: isLoadingFactorSetPriority(store),
        isLoadingUnitTypes: isLoadingUnitTypes(store),
        isLoading: isLoadingFactorSetPriority(store) || isUpdatingPriorityLine(store) || isDeletingPriorityLine(store)
            || isCreatingPriorityLine(store) || isLoadingFactorSetPriorityLines(store) || isLoadingUnitTypes(store)

    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('FACTOR_SET_PRIORITY'),
    properties: LanguageUtils.createMessage('Properties'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    versions: LanguageUtils.createMessage('Versions'),
    factors: LanguageUtils.createMessage('lines')
};

class FactorSetPriorityDetails extends Component<IFactorSetPriorityDetailsProps, IFactorSetPriorityDetailsState> {
    constructor(props: IFactorSetPriorityDetailsProps) {
        super(props);

        this.state = {
            open: true,
            factorSetPriority: {} as FactorSetOut,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            serverPaginationLines: PageUtils.getDefaultPaging(),
            sortModelLines: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelVersions: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            factorSetPriorityColumnVisibilityModel: {},
            factorsVersionLinesColumnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetPriorityById } = this.props;
        const { priorityId, factorSetVersionId } = this.props.match.params;
        const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;

        fetchFactorSetPriorityById(priorityId, versionId);
    }

    componentWillUnmount() {
        const { resetFactorSetPriority, resetFactorSetPriorities, resetPriorityVersionLines } = this.props;

        resetFactorSetPriority();
        resetFactorSetPriorities();
        resetPriorityVersionLines();
    }

    componentDidUpdate(prevProps: IFactorSetPriorityDetailsProps) {
        const { factorSetPriority, isUpdatingPriority, isDeletingLine, isCreatingPriority, isLoadingFactorSetPriority, isDeletingPriority, history, fetchFactorSetPriorityById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { priorityId, factorSetVersionId } = this.props.match.params;

        if(factorSetPriority !== prevProps.factorSetPriority) {
            this.setState({ factorSetPriority });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.factorSetVersionId !== factorSetVersionId) {
                const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;
                fetchFactorSetPriorityById(priorityId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingFactorSetPriority && prevProps.isLoadingFactorSetPriority ||
            !isUpdatingPriority && prevProps.isUpdatingPriority ||
            !isDeletingLine && prevProps.isDeletingLine ||
            !isCreatingPriority && prevProps.isCreatingPriority)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingPriority && prevProps.isDeletingPriority) {
            history.push(`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DEPARTURE}`);
        }
    }

    isHiddenCategory = (): boolean => {
        const { factorSetPriority } = this.state;

        return factorSetPriority.version?.id ? false : true;
    }

    configureDrawer = (props: IFactorSetPriorityDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.FACTOR_SETS}/priority/${props.match.params.priorityId}/${props.match.params.factorSetVersionId}`;

        return ImmutableList([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/properties`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.versions,
            additionalMargin: true,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.factors,
            icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
            url: `${baseUrl}/lines`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/lines`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined): void => {
        const { fetchPriorityVersionLines, factorSetPriority, fetchPriorityVersions, fetchPriorityTypes, fetchUnitTypes } = this.props;
        const { priorityId } = this.props.match.params;
        const { serverPagination, serverPaginationLines } = this.state;

        const lineFilters = {
            name: undefined,
            priorityTypeId: null,
            factorSetVersionId: factorSetPriority.version?.id
        } as PriorityLineFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverPagination,
            page: newPaging.page
        };

        const newPaginationLines = {
            ...serverPaginationLines,
            page: newPaging.page
        };

        if(selectedMenuItem?.id === 2) {
            fetchPriorityVersions(priorityId, newPagination);
        }

        if(selectedMenuItem?.id === 3 && factorSetPriority.version?.id) {
            fetchPriorityVersionLines(newPaginationLines, lineFilters);
            fetchPriorityTypes(newPagination);
            fetchUnitTypes(newPagination);
        }
    }

    getSelectedMenuItem = (state: IFactorSetPriorityDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateFactorSet = () => {
        const { factorSetPriority } = this.state;
        const { deactivateFactorSetPriority } = this.props;

        deactivateFactorSetPriority(factorSetPriority.id);
    }

    deactivateFactorSetLine = () => {
        const { selectedRow } = this.state;
        const { deactivateFactorSetPriorityLine } = this.props;

        deactivateFactorSetPriorityLine(selectedRow);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow, factorSetPriority } = this.state;
        const { priorityLines, priorityTypes, unitTypes } = this.props;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { priorityId } = this.props.match.params;

        const filteredTypes = priorityTypes.content.filter(pt => (!priorityLines.content.find(pl => pl.priorityType?.id === pt.id && !pl.unitType))
            || unitTypes.content.find(ut => !priorityLines.content.find(pl => pl.priorityType?.id === pt.id && pl.unitType?.id === ut?.id)));

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: factorSetPriority.version?.id ? `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/${factorSetPriority.version?.id}/edit` :
                            `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateFactorSet
                    }
                ];
            }

            case 2: { //priority versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 3: { //lines
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/version/${factorSetPriority.version?.id}/add/line`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !factorSetPriority.version?.id || filteredTypes.length === 0
                        }
                    ];
                }

                if(factorSetPriority.version) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/priority/${priorityId}/version/${factorSetPriority.version?.id}/priorityLine/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateFactorSetLine
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }

    _onSortChangeLines = (newModel: GridSortModel) => {
        const { fetchPriorityVersionLines } = this.props;
        const { sortModelLines, serverPaginationLines, factorSetPriority } = this.state;

        const lineFilters = {
            name: undefined,
            priorityTypeId: null,
            factorSetVersionId: factorSetPriority.version?.id
        } as PriorityLineFilters;

        if(JSON.stringify(sortModelLines) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationLines,
                sort: newModel
            };
            this.setState({
                sortModelLines: newModel,
                serverPaginationLines: newPagination
            });

            fetchPriorityVersionLines(newPagination, lineFilters);
        }
    }

    _onSortChangeVersions = (newModel: GridSortModel) => {
        const { fetchPriorityVersions } = this.props;
        const { factorSetPriority, serverPagination, sortModelVersions } = this.state;

        if(JSON.stringify(sortModelVersions) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelVersions: newModel,
                serverPagination: newPagination
            });

            fetchPriorityVersions(factorSetPriority.id, newPagination);
        }
    }

    render() {
        const { classes, isLoadingFactorSetPriority, isLoadingFactorSetPriorityLines, priorityLines, isDeletingLine, isDeletingPriority, isUpdatingPriority,
            isLoadingFactorSetVersions, factorVersions, fetchPriorityVersions, fetchPriorityVersionLines } = this.props;
        const { factorSetPriority, menuItems, serverPagination, serverPaginationLines, factorSetPriorityColumnVisibilityModel, factorsVersionLinesColumnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingFactorSetPriority || isDeletingPriority}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.FactorSets}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: factorSetPriority.name,
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                            <span className={classes.values}> {factorSetPriority?.version?.versionNumber ?? '-'} </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                            {factorSetPriority?.version?.validFromDate || factorSetPriority?.version?.validToDate ? (
                                <span className={classes.values}>
                                    <NullableFormattedDate value={factorSetPriority?.version?.validFromDate} /> -
                                    {factorSetPriority?.version?.validToDate &&
                                        <> <NullableFormattedDate value={factorSetPriority?.version?.validToDate} /></>}
                                </span>
                            )
                                : '-'} </span>
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId/properties`}>
                            <Loader isLoading={isUpdatingPriority || isDeletingPriority}>
                                <ViewFactorSetPriority factorSetPriority={factorSetPriority} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId/lines`}>
                            <ViewFactorSetPriorityLines isLoading={isLoadingFactorSetPriorityLines || isDeletingLine}
                                rows={priorityLines}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationLines,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const lineFilters = {
                                        name: undefined,
                                        priorityTypeId: null,
                                        factorSetVersionId: factorSetPriority.version?.id
                                    } as PriorityLineFilters;

                                    this.setState({ serverPaginationLines: newPage });
                                    fetchPriorityVersionLines(newPage, lineFilters);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeLines(sortModel)}
                                columnVisibilityModel={factorSetPriorityColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorSetPriorityColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/priority/:priorityId/:factorSetVersionId/versions`}>
                            <FactorsVersionsList
                                type={factorSetPriority.type?.code}
                                isLoading={isLoadingFactorSetVersions}
                                rows={factorVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchPriorityVersions(factorSetPriority.id, newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeVersions(sortModel)}
                                columnVisibilityModel={factorsVersionLinesColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorsVersionLinesColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetPriorityDetails));