import { List } from 'immutable';
import inputLengthConstants from '../../../../../constants/inputLengthConstants';
import FactorSetWeightLineOutput from '../../../../../interfaces/output/factorSetWeightLineOut';
import { ensure } from '../../../../../utils/arrayUtils';
import LanguageUtils from '../../../../../utils/LanguageUtils';
import ValidationModel from '../../../../../utils/validationModel';
import ValidationUtils from '../../../../../utils/validationUtils';

const inputsMaxLengths = {
    name: inputLengthConstants.String60
};

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    typesWithSameWeights: LanguageUtils.createMessage('Types with same weight')
};

export default class FactorSetWeightLineStep1Validation extends ValidationUtils {

    static validateWeights(factorSetLine: FactorSetWeightLineOutput, factorSetLines: Array<FactorSetWeightLineOutput>): boolean {
        const filteredLines = factorSetLines.filter(line => line.id !== factorSetLine.id);

        if(filteredLines.find(line => line.unitType?.id === factorSetLine.unitType?.id && (line.fromWeightKg === factorSetLine.fromWeightKg || line.toWeightKg === factorSetLine.toWeightKg))) {
            return false;
        }

        return true;
    }

    static validateFactorSetForm(factorSetLine: FactorSetWeightLineOutput, factorSetLines: Array<FactorSetWeightLineOutput>): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            unitType,
            fromWeightKg,
            toWeightKg,
            factor,
            surcharge,
            surchargeCurrency
        } = factorSetLine;

        const requiredFields =
            this.validateName(name) &&
            this.validateInputNumber(unitType?.id, { required: surcharge ? true : false }) &&
            this.validateRequiredNumberWithMaxLength(fromWeightKg) &&
            this.validateRequiredNumberWithMaxLength(toWeightKg) &&
            this.validateInputNumber(factor) &&
            this.validateInputNumber(surchargeCurrency?.id, { required: surcharge ? true : false });

        const validatedWeights = FactorSetWeightLineStep1Validation.validateWeights(factorSetLine, factorSetLines);

        if(!validatedWeights) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.typesWithSameWeights);
        }

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }

    static validateName = (inputString?: string): boolean => {
        return this.validateInputStringLength(inputString, {
            inputLength: inputsMaxLengths.name
        });
    }
}
