import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import FactorSetDepartureLineOut from '../interfaces/output/factorSetDepartureLineOut';
import FactorSetDepartureLineIn from '../interfaces/input/factorSetDepartureLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetDepartureVersionApi {
    static fetchFactorSetDepartureVersionById = async(factorSetVersionId: string): Promise<FactorSetVersionOut> => {
        const { data } = await http.get<FactorSetVersionOut>(`factorsets/departure/versions/${factorSetVersionId}`);

        return data;
    };

    static fetchFactorSetDepartureVersions = async(factorId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/departure/${factorId}/versions`);
        const { data } = await http.get<Array<FactorSetVersionOut>>(url);

        return data;
    };

    static createFactorSetDepartureVersionLines= async(versionLine: FactorSetDepartureLineOut): Promise<FactorSetDepartureLineOut> => {
        const newLine: FactorSetDepartureLineIn = {
            name: versionLine.name,
            departureTypeId: versionLine.departureType?.id,
            factor: versionLine.factor,
            surcharge: versionLine.surcharge
        };

        const { data } = await http.post<FactorSetDepartureLineIn, FactorSetDepartureLineOut>( `factorsets/departure/versions/${versionLine.factorSetVersionId}/lines`, newLine);

        return data;
    }

    static updateFactorSetDepartureVersionStatus = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/departure/versions/${factorSetVersion.id}/status/${factorSetVersion.status?.id}`);

        return data;
    }

    static updateFactorSetDepartureVersion = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSetVersion.validFromDate,
            validToDate: factorSetVersion.validToDate,
            statusId: factorSetVersion.status?.id
        };
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/departure/versions/${factorSetVersion.id}`, newVersion);

        return data;
    }

    static cloneFactorSetDepartureVersion = async(toVersionId: number, fromVersionId: number): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/departure/versions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}