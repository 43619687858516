import { http } from '../utils/http';
import AgreementVersion from '../interfaces/output/agreementVersion';
import TransitDiscount from '../interfaces/output/transitDiscount';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class AgreementVersionApi {
    static fetchAgreementVersionById = async(agreementVersionId: string): Promise<AgreementVersion> => {
        const { data } = await http.get<AgreementVersion>(`agreementversion/${agreementVersionId}`);

        return data;
    };

    static fetchAgreementVersionTransitDiscounts = async(agreementVersionId: string, paging: Paging): Promise<Pagination<TransitDiscount>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `agreementversion/${agreementVersionId}/transitdiscounts`);

        const { data } = await http.get<Pagination<TransitDiscount>>(url);

        return data;
    };

    static createAgreementVersionTransitDiscount = async(agreementDiscount: TransitDiscount): Promise<TransitDiscount> => {
        const newAgreementDiscount = {
            productArenaFromId: agreementDiscount.productArenaFrom?.id,
            productArenaToId: agreementDiscount.productArenaTo?.id,
            transitDiscount: agreementDiscount.transitDiscount
        };

        const { data } = await http.post<any, TransitDiscount>( `agreementversion/${agreementDiscount.agreementVersionId}/transitdiscounts`, newAgreementDiscount);

        return data;
    }

    static updateAgreementVersionStatus = async(agreementVersion: AgreementVersion): Promise<AgreementVersion> => {
        
        const { data } = await http.put<any, AgreementVersion>(`agreementversion/${agreementVersion.id}/status/${agreementVersion.status?.id}`);

        return data;
    }

    static updateAgreementVersion = async(agreementVersion: AgreementVersion): Promise<AgreementVersion> => {
        const newVersion = {
            validFromDate: agreementVersion.validFromDate,
            validToDate: agreementVersion.validToDate,
            statusId: agreementVersion.status?.id,
            defaultTransitDiscount: agreementVersion.defaultTransitDiscount
        };
        
        const { data } = await http.put<any, AgreementVersion>(`agreementversion/${agreementVersion.id}`, newVersion);

        return data;
    }

    static cloneAgreementVersion = async(toVersionId: number, fromVersionId: number,percentAdjustmentSpecialPrices: number, percentAdjustmentTransitDiscount:number ): Promise<AgreementVersion> => {

        const newVersion = {
            percentAdjustmentSpecialPrices: percentAdjustmentSpecialPrices,
            percentAdjustmentTransitDiscount: percentAdjustmentTransitDiscount
        };
        const urlAjustment = `?percentAdjustmentSpecialPrices=${newVersion.percentAdjustmentSpecialPrices}&percentAdjustmentTransitDiscount=${newVersion.percentAdjustmentTransitDiscount}`;
        
        const { data } = await http.put<any, AgreementVersion>(`agreementversion/${toVersionId}/copyFromProcedure/${fromVersionId}${urlAjustment}`);

        return data;
    }
}