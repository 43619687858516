import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import PriceList from '../interfaces/output/priceList';
import PriceListsFilters from '../interfaces/output/priceListFilters';
import PriceListVersion from '../interfaces/output/priceListVersions';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import WorkflowUtils from '../utils/workflowUtils';

export type PriceListState = {
    priceList: PriceList;
    isLoadingPriceList: boolean;
    isLoadingPriceLists: boolean;
    isLoadingPriceListVersions: boolean;
    isCreatingPricelistVersion: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    priceLists: Pagination<PriceList>;
    priceListVersions: Pagination<PriceListVersion>;
    error: HttpErrorResponse | undefined;
};

const initialState: PriceListState = {
    priceList: {} as PriceList,
    priceLists: { content: [] as Array<PriceList> } as Pagination<PriceList>,
    priceListVersions: { content: [] as Array<PriceListVersion> } as Pagination<PriceListVersion>,
    isLoadingPriceList: false,
    isLoadingPriceLists: false,
    isLoadingPriceListVersions: false,
    isCreatingPricelistVersion: false,
    isUpdating: false,
    isRemoving: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const priceListSlice = createSlice({
    name: 'pricelist',

    initialState,
    reducers: {
        fetchPriceLists: (state: PriceListState, _action: PayloadAction<{paging: Paging, filters: PriceListsFilters}>) => {
            state.isLoadingPriceLists = true;
        },
        fetchPriceListsSuccess: (state: PriceListState, action: PayloadAction<Pagination<PriceList>>) => {
            state.isLoadingPriceLists = false;
            state.priceLists = action.payload;
        },
        fetchPriceListsError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPriceLists = false;
            state.error = action.payload;
        },
        fetchPriceListVersions: (state: PriceListState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingPriceListVersions = true;
        },
        fetchPriceListVersionsSuccess: (state: PriceListState, action: PayloadAction<Pagination<PriceListVersion>>) => {
            state.isLoadingPriceListVersions = false;
            state.priceListVersions = action.payload;
        },
        fetchPriceListVersionsError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPriceListVersions = false;
            state.error = action.payload;
        },
        fetchPriceListById: (state: PriceListState, _action: PayloadAction<{id: number, priceListVersionId?: number }>) => {
            state.isLoadingPriceList = true;
        },
        fetchPriceListByIdSuccess: (state: PriceListState, action: PayloadAction<PriceList>) => {
            state.isLoadingPriceList = false;
            state.priceList = action.payload;
        },
        fetchPriceListByIdError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingPriceList = false;
            state.error = action.payload;
        },
        createPriceList: (state: PriceListState, _action: PayloadAction<PriceList>) => {
            state.isCreating = true;
        },
        createPriceListSuccess: (state: PriceListState, action: PayloadAction<PriceList>) => {
            state.isCreating = false;
            state.priceList = action.payload;
        },
        createPriceListError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createPriceListVersion: (state: PriceListState, _action: PayloadAction<PriceList>) => {
            state.isCreatingPricelistVersion = true;
        },
        createPriceListVersionSuccess: (state: PriceListState, action: PayloadAction<PriceList>) => {
            state.isCreatingPricelistVersion = false;
            state.priceList = action.payload;
        },
        createPriceListVersionError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingPricelistVersion = false;
            state.error = action.payload;
        },
        updatePriceList: (state: PriceListState, _action: PayloadAction<PriceList>) => {
            state.isUpdating = true;
        },
        updatePriceListSuccess: (state: PriceListState, action: PayloadAction<PriceList>) => {
            state.isUpdating = false;
            state.priceList = action.payload;
        },
        updatePriceListError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deletePriceList: (state: PriceListState, _action: PayloadAction<PriceList>) => {
            state.isDeleted = true;
        },
        deletePriceListSuccess: (state: PriceListState, action: PayloadAction<PriceList>) => {
            state.isDeleted = false;
            state.priceList = action.payload;
        },
        deletePriceListError: (state: PriceListState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetPriceListState: (state: PriceListState) => state = initialState,
        resetPriceLists: (state: PriceListState) => {
            state.priceLists = initialState.priceLists;
        },
        resetPriceList: (state: PriceListState) => {
            state.priceList = initialState.priceList;
        }
    }
});

export const { fetchPriceLists, fetchPriceListsSuccess, fetchPriceListsError, fetchPriceListVersions, fetchPriceListVersionsSuccess, fetchPriceListVersionsError, fetchPriceListById,
    fetchPriceListByIdSuccess, fetchPriceListByIdError, createPriceList, createPriceListSuccess, createPriceListError, createPriceListVersion, createPriceListVersionSuccess,
    createPriceListVersionError, updatePriceList, updatePriceListSuccess, updatePriceListError, deletePriceList, deletePriceListSuccess, deletePriceListError,
    resetPriceLists, resetPriceList, resetPriceListState } = priceListSlice.actions;

export const isLoadingPriceList = (state: RootState): boolean => state.pricelistReducer.isLoadingPriceList;
export const isLoadingPriceLists = (state: RootState): boolean => state.pricelistReducer.isLoadingPriceLists;
export const isLoadingPriceListVersions = (state: RootState): boolean => state.pricelistReducer.isLoadingPriceListVersions;
export const isRemoving = (state: RootState): boolean => state.pricelistReducer.isRemoving;
export const isUpdatingPriceList = (state: RootState): boolean => state.pricelistReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.pricelistReducer.isDeleted;
export const isCreatingPriceList = (state: RootState): boolean => state.pricelistReducer.isCreating;
export const isCreatingPricelistVersion = (state: RootState): boolean => state.pricelistReducer.isCreatingPricelistVersion;
export const getPriceLists = (state: RootState): Pagination<PriceList> => state.pricelistReducer.priceLists;
export const getPriceList = (state: RootState): PriceList => state.pricelistReducer.priceList;
export const getPriceListVersions = (state: RootState): Pagination<PriceListVersion> => state.pricelistReducer.priceListVersions;

export default priceListSlice.reducer;