import React from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import { FormattedTime, useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomStringLink from '../common/customStringLink';
import CustomLink from '../common/customLink';

interface IOrderUnitDepotListProps {
    classes?: any;
    orderUnits?: Pagination<GTSOrderUnit>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    orderNumber: LanguageUtils.createMessage('Order number'),
    gtsUnitType: LanguageUtils.createMessage('GTS unit type'),
    depotName: LanguageUtils.createMessage('Depot name'),
    depotFrom: LanguageUtils.createMessage('Depot from'),
    depotTo: LanguageUtils.createMessage('Depot to'),
    receivedFromGTS: LanguageUtils.createMessage('Received from GTS'),
    place: LanguageUtils.createMessage('Place')
};

export default function OrderUnitDepotList(props: IOrderUnitDepotListProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, orderUnits, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'depotName',
                headerName: intl.formatMessage(messages.depotName),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.depotName} link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('depotName', hiddenColumns)
            },
            {
                field: 'orderNumber',
                headerName: intl.formatMessage(messages.orderNumber),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.orderNumber} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderNumber', hiddenColumns)
            },
            {
                field: 'gtsUnitType',
                headerName: intl.formatMessage(messages.gtsUnitType),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(params).id}`} value={applyRowFn(params)?.gtsUnitType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('gtsUnitType', hiddenColumns)
            },
            {
                field: 'depotFrom',
                headerName: intl.formatMessage(messages.depotFrom),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(params).id}`}>
                        {(params.row?.depotFrom ?
                            <><NullableFormattedDate value={params.row?.depotFrom} /> <FormattedTime hour="numeric" minute="numeric" value={params.row?.depotFrom} /></> : '-')}
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('depotFrom', hiddenColumns)
            },
            {
                field: 'depotTo',
                headerName: intl.formatMessage(messages.depotTo),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(params).id}`}>{
                        (params.row?.depotTo ?
                            <> <NullableFormattedDate value={params.row?.depotTo} /> <FormattedTime hour="numeric" minute="numeric" value={params.row?.depotTo} /></> : '-')}
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('depotTo', hiddenColumns)
            },
            {
                field: 'place',
                headerName: intl.formatMessage(messages.place),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(params).id}`} value={applyRowFn(params)?.place ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('place', hiddenColumns)
            },
            {
                field: 'receivedFromGTS',
                headerName: intl.formatMessage(messages.receivedFromGTS),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => {
                    return <CustomLink link={`/#/${UrlConstants.UNIT_DEPOT}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.receivedFromGTS} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('receivedFromGTS', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || orderUnits} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
}