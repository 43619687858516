import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import PageUtils from '../../../utils/pageUtils';
import Currency from '../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../reducers/currencyReducer';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import Terminal from '../../../interfaces/output/terminal';
import CalculatedPriceFilters from '../../../interfaces/filters/calculatedPriceFilters';
import { fetchUnitTypes, getUnitTypes } from '../../../reducers/unitTypeReducer';
import { fetchDepartureTypes, getDepartureTypes } from '../../../reducers/departureTypeReducer';
import { fetchPriorityTypes, getPriorityTypes } from '../../../reducers/priorityTypeReducer';
import UnitType from '../../../interfaces/output/unitType';
import PriorityType from '../../../interfaces/output/priorityType';
import DepartureType from '../../../interfaces/output/departureType';
// import { DatePicker } from '@mui/lab';
interface IFilterProps {
    filters: CalculatedPriceFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    priceListName: LanguageUtils.createMessage('Price list name'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    unitType: LanguageUtils.createMessage('Unit type'),
    fromWeightKg: LanguageUtils.createMessage('From weight kg'),
    toWeightKg: LanguageUtils.createMessage('To weight kg'),
    departureTypeName: LanguageUtils.createMessage('Departure type'),
    priorityTypeName: LanguageUtils.createMessage('Priority Type'),
    currency: LanguageUtils.createMessage('Currency'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function CalculatedPriceFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const paging = PageUtils.getMaxPaging();
    const terminals = useSelector(getTerminals).content;
    const currencies = useSelector(getCurrencies).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const departureTypes = useSelector(getDepartureTypes).content;
    const priorityTypes = useSelector(getPriorityTypes).content;
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchProductArenaTypes({ paging }));
        dispatch(fetchUnitTypes({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchDepartureTypes({ paging }));
        dispatch(fetchPriorityTypes({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="priceListName"
                                label={messages.priceListName}
                                onChange={onChange}
                                value={filters.priceListName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.fromTerminalName}
                            onChange={(obj: Terminal | null) => onChange('fromTerminalName', obj?.name)}
                            placeholder={messages.fromTerminal}
                            compareFn={(o: Terminal) => o.name === filters.fromTerminalName}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toTerminalName}
                            onChange={(obj: Terminal | null) => onChange('toTerminalName', obj?.name)}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal) => o.name === filters.toTerminalName}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitType>
                            options={unitTypes}
                            value={filters.unitTypeName}
                            onChange={(obj: UnitType | null) => onChange('unitTypeName', obj?.name)}
                            placeholder={messages.unitType}
                            compareFn={(o: UnitType) => o.name === filters.unitTypeName}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="fromWeightKg"
                                label={messages.fromWeightKg}
                                onChange={onChange}
                                value={filters.fromWeightKg}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="toWeightKg"
                                label={messages.toWeightKg}
                                onChange={onChange}
                                value={filters.toWeightKg}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<DepartureType>
                            options={departureTypes}
                            value={filters.departureTypeName}
                            onChange={(obj: DepartureType | null) => onChange('departureTypeName', obj?.name)}
                            placeholder={messages.departureTypeName}
                            compareFn={(o: DepartureType) => o.name === filters.departureTypeName}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<PriorityType>
                            options={priorityTypes}
                            value={filters.priorityTypeName}
                            onChange={(obj: PriorityType | null) => onChange('priorityTypeName', obj?.name)}
                            placeholder={messages.priorityTypeName}
                            compareFn={(o: PriorityType) => o.name === filters.priorityTypeName}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={filters.currencyCode}
                            onChange={(obj: Currency | null) => onChange('currencyCode', obj?.code)}
                            placeholder={messages.currency}
                            compareFn={(o: Currency) => o.code === filters.currencyCode}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}