import React, { useEffect } from 'react';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormControl, Button } from '@mui/material';
import Seller from '../../interfaces/output/seller';
import Filters from '../../interfaces/filters/agreementFilters';
import UnitGroup from '../../interfaces/output/unitGroup';
import { fetchSellers, getSellers } from '../../reducers/sellerReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnitGroups, getUnitGroups } from '../../reducers/unitGroupReducer';
import PageUtils from '../../utils/pageUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import GenericAutocomplete from './widgets/genericAutocomplete';
import GenericTextField from './widgets/genericTextField';

interface IFilterProps {
    filters: Filters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    seller: LanguageUtils.createMessage('Seller'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function AgreementFiltersComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();

    const sellers = useSelector(getSellers).content;
    const unitGroups = useSelector(getUnitGroups).content;
    
    useEffect(() => {
        dispatch(fetchSellers({ paging }));
        dispatch(fetchUnitGroups({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={filters.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroupId', obj?.id)}
                            placeholder={messages.unitGroup}
                            compareFn={(o: UnitGroup) => o.id === filters.unitGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Seller>
                            options={sellers}
                            value={filters.sellerId}
                            onChange={(obj: Seller | null) => onChange('sellerId', obj?.id)}
                            placeholder={messages.seller}
                            compareFn={(o: Seller) => o.id === filters.sellerId}
                        />
                    </Grid>
                    <Grid item xs alignSelf={'flex-end'}>
                        <ToggleButtonGroup
                            color="primary"
                            value={filters.active}
                            exclusive
                            onChange={(e, value: any) => onChange('active', value)}
                        >
                            <ToggleButton size="small" value={true}>Active</ToggleButton>
                            <ToggleButton size="small" value={false}>Not Active</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}