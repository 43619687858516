import StatusApi from '../api/statusApi';
import { fetchStatusByIdError, fetchStatusByIdSuccess, fetchStatusesError, fetchStatusesSuccess } from '../reducers/statusReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Status from '../interfaces/output/customerStatus';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchStatus(action: PayloadAction<string>) {
    const statusId = action.payload;
    try {
        const response: Status = yield call(StatusApi.fetchStatusById, statusId);
        yield put(fetchStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchStatuses() {
    try {
        const response: Pagination<Status> = yield call(StatusApi.fetchStatuses);
        yield put(fetchStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchStatusesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('status/fetchStatusById', doFetchStatus),
    takeLatest('status/fetchStatuses', doFetchStatuses)];

