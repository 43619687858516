import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetCalendarLineOutput from '../interfaces/output/factorSetCalendarLineOut';
import CalendarLineFilters from '../components/factorSets/calendar/line/calendarLineFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';

export type CalendarLineState = {
    factorSetCalendarLine: FactorSetCalendarLineOutput;
    factorSetVersionLines: Pagination<FactorSetCalendarLineOutput>;
    isLoadingCalendarLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: CalendarLineState = {
    factorSetCalendarLine: {} as FactorSetCalendarLineOutput,
    factorSetVersionLines: { content: [] as Array<FactorSetCalendarLineOutput> } as Pagination<FactorSetCalendarLineOutput>,
    isLoadingCalendarLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const calendarLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetCalendarLineById: (state: CalendarLineState, _action: PayloadAction<number>) => {
            state.isLoadingCalendarLine = true;
        },
        fetchFactorSetCalendarLineByIdSuccess: (state: CalendarLineState, action: PayloadAction<FactorSetCalendarLineOutput>) => {
            state.isLoadingCalendarLine = false;
            state.factorSetCalendarLine = action.payload;
        },
        fetchFactorSetCalendarLineByIdError: (state: CalendarLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCalendarLine = false;
            state.error = action.payload;
        },
        fetchCalendarVersionLines: (state: CalendarLineState, _action: PayloadAction<{paging: Paging, filters: CalendarLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchCalendarVersionLinesSuccess: (state: CalendarLineState, action: PayloadAction<Pagination<FactorSetCalendarLineOutput>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchCalendarVersionLinesError: (state: CalendarLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createCalendarVersionLine: (state: CalendarLineState, _action: PayloadAction<FactorSetCalendarLineOutput>) => {
            state.isCreating = true;
        },
        createCalendarVersionLineSuccess: (state: CalendarLineState, action: PayloadAction<FactorSetCalendarLineOutput>) => {
            state.isCreating = false;
            state.factorSetCalendarLine = action.payload;
        },
        createCalendarVersionLineError: (state: CalendarLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetCalendarLine: (state: CalendarLineState, _action: PayloadAction<FactorSetCalendarLineOutput>) => {
            state.isUpdating = true;
        },
        updateFactorSetCalendarLineSuccess: (state: CalendarLineState, action: PayloadAction<FactorSetCalendarLineOutput>) => {
            state.isUpdating = false;
            state.factorSetCalendarLine = action.payload;
        },
        updateFactorSetCalendarLineError: (state: CalendarLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetCalendarLine: (state: CalendarLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetCalendarLineSuccess: (state: CalendarLineState, action: PayloadAction<FactorSetCalendarLineOutput>) => {
            state.isDeleting = false;
            state.factorSetCalendarLine = action.payload;
        },
        deactivateFactorSetCalendarLineError: (state: CalendarLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetCalendarLine: (state: CalendarLineState) => {
            state.factorSetCalendarLine = initialState.factorSetCalendarLine;
        },
        resetCalendarVersionLines: (state: CalendarLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetCalendarLineById, fetchFactorSetCalendarLineByIdSuccess, fetchFactorSetCalendarLineByIdError, fetchCalendarVersionLines, fetchCalendarVersionLinesError,
    updateFactorSetCalendarLine, updateFactorSetCalendarLineSuccess, updateFactorSetCalendarLineError, deactivateFactorSetCalendarLine, resetCalendarVersionLines,
    deactivateFactorSetCalendarLineError, deactivateFactorSetCalendarLineSuccess, resetFactorSetCalendarLine, fetchCalendarVersionLinesSuccess,
    createCalendarVersionLine, createCalendarVersionLineSuccess, createCalendarVersionLineError } = calendarLineSlice.actions;

export const isLoadingCalendarLine = (state: RootState): boolean => state.calendarLineReducer.isLoadingCalendarLine;
export const isLoadingFactorSetCalendarLines = (state: RootState): boolean => state.calendarLineReducer.isLoadingLines;
export const isUpdatingCalendarLine = (state: RootState): boolean => state.calendarLineReducer.isUpdating;
export const isDeletingCalendarLine = (state: RootState): boolean => state.calendarLineReducer.isDeleting;
export const getFactorSetCalendarLine = (state: RootState): FactorSetCalendarLineOutput => state.calendarLineReducer.factorSetCalendarLine;
export const getCalendarLines = (state: RootState): Pagination<FactorSetCalendarLineOutput> => state.calendarLineReducer.factorSetVersionLines;
export const isCreatingCalendarLine = (state: RootState): boolean => state.calendarLineReducer.isCreating;

export default calendarLineSlice.reducer;