import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import TableUtils from '../../utils/tableUtils';
import DataGridComponent from '../common/dataGrid';
import UrlConstants from '../../constants/UrlConstants';
import CustomStringLink from '../common/customStringLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';
import CustomLink from '../common/customLink';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface SurchargRateListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
}

const messages = {
    id: LanguageUtils.createMessage('Internal number'),
    validFromDate: LanguageUtils.createMessage('Valid from date'),
    validToDate: LanguageUtils.createMessage('Valid to date'),
    rateNok: LanguageUtils.createMessage('Rate NOK'),
    rateEur: LanguageUtils.createMessage('Rate EUR'),
    surchargeType: LanguageUtils.createMessage('Surcharge Type'),
    surchargeArea: LanguageUtils.createMessage('Surcharge Area'),
    surchargeRateUnit: LanguageUtils.createMessage('Surcharge rate unit')
};

const SurchargeRateList = forwardRef((props: SurchargRateListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.SURCHARGE_RATE_MAIN);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'id',
                headerName: `${intl.formatMessage(messages.id)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).id} link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('id', hiddenColumns)
            },
            {
                field: 'validFromDate',
                headerName: `${intl.formatMessage(messages.validFromDate)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(cellValues).id}`}>
                        <NullableFormattedDate value={applyRowFn(cellValues)?.validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: 'validToDate',
                headerName: `${intl.formatMessage(messages.validToDate)}`,
                flex: 0.8,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={applyRowFn(params)?.validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            },
            {
                field: 'rateNok',
                headerName: `${intl.formatMessage(messages.rateNok)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.rate_Nok ?? '-'}
                    link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('rate_Nok', hiddenColumns)
            },
            {
                field: 'rateEur',
                headerName: `${intl.formatMessage(messages.rateEur)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.rate_Eur ?? '-'}
                    link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('rate_Eur', hiddenColumns)
            },
            {
                field: 'surchargeType',
                headerName: `${intl.formatMessage(messages.surchargeType)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink value={applyRowFn(params).surchargeType?.name ?? '-'} link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('surchargeType', hiddenColumns)
            },
            {
                field: 'surchargeArea',
                headerName: `${intl.formatMessage(messages.surchargeArea)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).surchargeArea?.name ?? '-'}
                    link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('surchargeArea', hiddenColumns)
            },
            {
                field: 'surchargeRateUnit',
                headerName: `${intl.formatMessage(messages.surchargeRateUnit)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).surchargeRateUnit?.name ?? '-'}
                    link={`/#/${UrlConstants.SURCHARGE_RATES}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('surchargeRateUnit', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default SurchargeRateList;
