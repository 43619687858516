import React, { useEffect } from 'react';
import AddFactorSetWeightStep1 from './addFactorSetWeightStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createFactorSetWeight, fetchFactorSetWeightById, getFactorSetWeight, isCreatingWeight, isUpdatingWeight,
    updateFactorSetWeight, createWeightVersion, isCreatingVersion } from '../../../../reducers/factorSetWeightReducer';
import { cloneWeightVersion, isCloningVersion, isUpdatingWeightVersionStatus, isUpdatingVersion, updateWeightVersion, updateWeightVersionStatus }
    from '../../../../reducers/factorSetWeightVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddWeightStep2 from './addFactorSetWeightStep2';
import AddWeightVersionStep3 from './addFactorSetWeightStep3';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';

interface IAddFactorSetFormProps {
    factorSetWeightId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set weight'),
    add: LanguageUtils.createMessage('Add factor set weight'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set version status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetWeightForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetWeightId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetWeightId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetWeight = useSelector(getFactorSetWeight);
    const prevFactorSetWeight = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetWeight);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingWeight);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingWeight);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingWeightVersion = useSelector(isCreatingVersion);
    const prevIsCreatingWeightVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingWeightVersion);
    const propsIsUpdatingWeightVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingWeightVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingWeightVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingWeightStatus = useSelector(isUpdatingWeightVersionStatus);
    const prevIsUpdatingWeightStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingWeightStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingWeightVersion === true && !propsIsCreatingWeightVersion || prevIsUpdatingWeightVersion === true && !propsIsUpdatingWeightVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingWeightStatus === true && !propsIsUpdatingWeightStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingWeightStatus, propsIsUpdatingWeightVersion, propsIsCreatingWeightVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetWeightId) {
            dispatch(fetchFactorSetWeightById({ factorSetId: factorSetWeightId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetWeight && propsFactorSetWeight.id) {
            setFactorSet(propsFactorSetWeight);
        }

    }, [propsFactorSetWeight.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newWeightVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newWeightVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newWeightVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetWeightStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetWeight(factorSet));
                }
                else {
                    dispatch(updateFactorSetWeight(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddWeightStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createWeightVersion(factorSet));
                }
                else {
                    dispatch(updateWeightVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.Weight} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(cloneWeightVersion({ toVersionId: ensure(propsFactorSetWeight.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddWeightVersionStep3 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updateWeightVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/weight/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/weight/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/weight/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/weight/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/weight/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/weight/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  