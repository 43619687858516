import { http } from '../utils/http';
import OrderUnitServiceProduct from '../interfaces/output/orderUnitServiceProduct';
import OrderUnitServiceProductSurchargeOut from '../interfaces/output/orderUnitServiceProductSurchargeOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OrderUnitServiceProductApi {
    static fetchOrderUnitServiceProduct = async(unitServiceProductId: string): Promise<OrderUnitServiceProduct> => {
        const { data } = await http.get<OrderUnitServiceProduct>(`orderunitserviceproducts/${unitServiceProductId}`);

        return data;
    };

    static fetchProductSurchargesByOrderUnitServiceProductId = async(unitServiceIProductd: number, paging: Paging): Promise<Pagination<OrderUnitServiceProductSurchargeOut>> => {
      
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `orderunitserviceproducts/${unitServiceIProductd}/surcharge`);

        const { data } = await http.get<Pagination<OrderUnitServiceProductSurchargeOut>>(url);

        return data;
    };

    static createOrderUnitServiceProduct = async(unitServiceProduct: OrderUnitServiceProduct): Promise<OrderUnitServiceProduct> => {
        const newProduct = {
            amount: unitServiceProduct?.amount,
            productId: unitServiceProduct?.product?.id,
            agreementId: unitServiceProduct?.agreement?.id,
            agreementVersionId: unitServiceProduct?.agreement?.version?.id,
            pricelistId: unitServiceProduct?.pricelist?.id,
            pricelistVersionId: unitServiceProduct?.pricelist?.version?.id,
            currencyId: unitServiceProduct?.currency?.id,
            productPrice: unitServiceProduct?.productPrice,
            calculatedPrice: unitServiceProduct?.calculatedPrice,
            overriddenPrice: unitServiceProduct?.overriddenPrice,
            ouspStatusId: unitServiceProduct?.ouspStatus?.id
        };

        const { data } = await http.post<any, OrderUnitServiceProduct>(`orderunitservices/${unitServiceProduct.orderUnitService?.id}/products`, newProduct);

        return data;
    }

    static updateOrderUnitServiceProduct = async(unitServiceProduct: OrderUnitServiceProduct): Promise<OrderUnitServiceProduct> => {
        const newProduct = {
            amount: unitServiceProduct.amount,
            productId: unitServiceProduct.product?.id,
            agreementId: unitServiceProduct.agreement?.id,
            agreementVersionId: unitServiceProduct.agreement?.version?.id,
            pricelistId: unitServiceProduct.pricelist?.id,
            pricelistVersionId: unitServiceProduct.pricelist?.version?.id,
            currencyId: unitServiceProduct.currency?.id,
            productPrice: unitServiceProduct.productPrice,
            calculatedPrice: unitServiceProduct.calculatedPrice,
            overriddenPrice: unitServiceProduct.overriddenPrice,
            ouspStatusId: unitServiceProduct.ouspStatus?.id
        };

        const { data } = await http.put<any, OrderUnitServiceProduct>(`orderunitserviceproducts/${unitServiceProduct.id}`, newProduct);

        return data;
    }
    static deactivateOrderUnitServiceProduct = async(unitServiceProductId: number): Promise<OrderUnitServiceProduct> => {
        const { data } = await http.delete<OrderUnitServiceProduct>(`orderunitserviceproducts/${unitServiceProductId}`);

        return data;
    }
}
