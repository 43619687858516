import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import { fetchInvoiceStatusByIdError, fetchInvoiceStatusByIdSuccess, fetchInvoiceStatusesError, fetchInvoiceStatusesSuccess } from '../reducers/invoiceStatusReducer';
import InvoiceStatusApi from '../api/invoiceStatusApi';
import InvoiceStatusOut from '../interfaces/output/invoiceStatusOut';

function* doFetchOrderStatus(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: InvoiceStatusOut = yield call(InvoiceStatusApi.fetchInvoiceStatusById, customerId);
        yield put(fetchInvoiceStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchOrderStatuses(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;
    try {
        const response: Pagination<InvoiceStatusOut> = yield call(InvoiceStatusApi.fetchInvoiceStatuses, paging);
        yield put(fetchInvoiceStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceStatusesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoiceStatus/fetchInvoiceStatusById', doFetchOrderStatus),
    takeLatest('invoiceStatus/fetchInvoiceStatuses', doFetchOrderStatuses)
];

