import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivateCancellationVersionStatusError, deactivateCancellationVersionStatusSuccess, fetchCancellationVersionStatusByIdError, fetchCancellationVersionStatusByIdSuccess,
    fetchCancellationVersionStatusesError, fetchCancellationVersionStatusesSuccess, updateCancellationVersionStatusError, updateCancellationVersionStatusSuccess }
    from '../reducers/cancellationVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchCancellationVersionStatus(action: PayloadAction<string>) {
    const cancellationVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, cancellationVersionStatusId);
    
        yield put(fetchCancellationVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCancellationVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchCancellationVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateCancellationVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const cancellationVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, cancellationVersionStatus);

        yield put(updateCancellationVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCancellationVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateCancellationVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const cancellationVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, cancellationVersionStatus);

        yield put(deactivateCancellationVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateCancellationVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('cancellationVersionStatus/fetchCancellationVersionStatusById', doFetchCancellationVersionStatus),
    takeLatest('cancellationVersionStatus/fetchCancellationVersionStatuses', doFetchCancellationVersionStatuses),
    takeLatest('cancellationVersionStatus/updateCancellationVersionStatus', doUpdateCancellationVersionStatus),
    takeLatest('cancellationVersionStatus/deactivateCancellationVersionStatus', doDeactivateCancellationVersionStatus)
];