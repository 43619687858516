import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetDepartureLineOutput from '../interfaces/output/factorSetDepartureLineOut';
import DepartureLineFilters from '../components/factorSets/departure/line/departureLineFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';

export type DepartureLineState = {
    factorSetDepartureLine: FactorSetDepartureLineOutput;
    factorSetVersionLines: Pagination<FactorSetDepartureLineOutput>;
    isLoadingDepartureLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: DepartureLineState = {
    factorSetDepartureLine: {} as FactorSetDepartureLineOutput,
    factorSetVersionLines: { content: [] as Array<FactorSetDepartureLineOutput> } as Pagination<FactorSetDepartureLineOutput>,
    isLoadingDepartureLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const departureLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetDepartureLineById: (state: DepartureLineState, _action: PayloadAction<number>) => {
            state.isLoadingDepartureLine = true;
        },
        fetchFactorSetDepartureLineByIdSuccess: (state: DepartureLineState, action: PayloadAction<FactorSetDepartureLineOutput>) => {
            state.isLoadingDepartureLine = false;
            state.factorSetDepartureLine = action.payload;
        },
        fetchFactorSetDepartureLineByIdError: (state: DepartureLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDepartureLine = false;
            state.error = action.payload;
        },
        fetchDepartureVersionLines: (state: DepartureLineState, _action: PayloadAction<{paging: Paging, filters: DepartureLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchDepartureVersionLinesSuccess: (state: DepartureLineState, action: PayloadAction<Pagination<FactorSetDepartureLineOutput>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchDepartureVersionLinesError: (state: DepartureLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createDepartureVersionLine: (state: DepartureLineState, _action: PayloadAction<FactorSetDepartureLineOutput>) => {
            state.isCreating = true;
        },
        createDepartureVersionLineSuccess: (state: DepartureLineState, action: PayloadAction<FactorSetDepartureLineOutput>) => {
            state.isCreating = false;
            state.factorSetDepartureLine = action.payload;
        },
        createDepartureVersionLineError: (state: DepartureLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetDepartureLine: (state: DepartureLineState, _action: PayloadAction<FactorSetDepartureLineOutput>) => {
            state.isUpdating = true;
        },
        updateFactorSetDepartureLineSuccess: (state: DepartureLineState, action: PayloadAction<FactorSetDepartureLineOutput>) => {
            state.isUpdating = false;
            state.factorSetDepartureLine = action.payload;
        },
        updateFactorSetDepartureLineError: (state: DepartureLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetDepartureLine: (state: DepartureLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetDepartureLineSuccess: (state: DepartureLineState, action: PayloadAction<FactorSetDepartureLineOutput>) => {
            state.isDeleting = false;
            state.factorSetDepartureLine = action.payload;
        },
        deactivateFactorSetDepartureLineError: (state: DepartureLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetDepartureLine: (state: DepartureLineState) => {
            state.factorSetDepartureLine = initialState.factorSetDepartureLine;
        },
        resetDepartureVersionLines: (state: DepartureLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetDepartureLineById, fetchFactorSetDepartureLineByIdSuccess, fetchFactorSetDepartureLineByIdError, fetchDepartureVersionLines, fetchDepartureVersionLinesError,
    updateFactorSetDepartureLine, updateFactorSetDepartureLineSuccess, updateFactorSetDepartureLineError, deactivateFactorSetDepartureLine, resetDepartureVersionLines,
    deactivateFactorSetDepartureLineError, deactivateFactorSetDepartureLineSuccess, resetFactorSetDepartureLine, fetchDepartureVersionLinesSuccess,
    createDepartureVersionLine, createDepartureVersionLineSuccess, createDepartureVersionLineError } = departureLineSlice.actions;

export const isLoadingDepartureLine = (state: RootState): boolean => state.departureLineReducer.isLoadingDepartureLine;
export const isLoadingFactorSetDepartureLines = (state: RootState): boolean => state.departureLineReducer.isLoadingLines;
export const isUpdatingDepartureLine = (state: RootState): boolean => state.departureLineReducer.isUpdating;
export const isDeletingDepartureLine = (state: RootState): boolean => state.departureLineReducer.isDeleting;
export const getFactorSetDepartureLine = (state: RootState): FactorSetDepartureLineOutput => state.departureLineReducer.factorSetDepartureLine;
export const getDepartureLines = (state: RootState): Pagination<FactorSetDepartureLineOutput> => state.departureLineReducer.factorSetVersionLines;
export const isCreatingDepartureLine = (state: RootState): boolean => state.departureLineReducer.isCreating;

export default departureLineSlice.reducer;