import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { RootState } from '../setup';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

export type FactorSetPriorityVersionState = {
    factorSetVersion: FactorSetVersionOut;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingStatus: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isLoadingFactorSetVersions: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetPriorityVersionState = {
    factorSetVersion: {} as FactorSetVersionOut,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isCloningVersion: false,
    isUpdatingStatus: false,
    isLoadingFactorSetVersions: false,
    error: undefined
};

export const customerPriorityVersionSlice = createSlice({
    name: 'factorSetVersion',

    initialState,
    reducers: {
        fetchPriorityVersionById: (state: FactorSetPriorityVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchPriorityVersionByIdSuccess: (state: FactorSetPriorityVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isLoading = false;
            state.factorSetVersion = action.payload;
        },
        fetchPriorityVersionByIdError: (state: FactorSetPriorityVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchPriorityVersions: (state: FactorSetPriorityVersionState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchPriorityVersionsSuccess: (state: FactorSetPriorityVersionState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchPriorityVersionsError: (state: FactorSetPriorityVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        updatePriorityVersionStatus: (state: FactorSetPriorityVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = true;
        },
        updatePriorityVersionStatusSuccess: (state: FactorSetPriorityVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingStatus = false;
            state.factorSetVersion = action.payload;
        },
        updatePriorityVersionStatusError: (state: FactorSetPriorityVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updatePriorityVersion: (state: FactorSetPriorityVersionState, _action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = true;
        },
        updatePriorityVersionSuccess: (state: FactorSetPriorityVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isUpdatingVersion = false;
            state.factorSetVersion = action.payload;
        },
        updatePriorityVersionError: (state: FactorSetPriorityVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        clonePriorityVersion: (state: FactorSetPriorityVersionState, _action: PayloadAction<{toVersionId: number, fromVersionId: number}>) => {
            state.isCloningVersion = true;
        },
        clonePriorityVersionSuccess: (state: FactorSetPriorityVersionState, action: PayloadAction<FactorSetVersionOut>) => {
            state.isCloningVersion = false;
            state.factorSetVersion = action.payload;
        },
        clonePriorityVersionError: (state: FactorSetPriorityVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetPriorityVersion: (state: FactorSetPriorityVersionState) => {
            state.factorSetVersion = initialState.factorSetVersion;
        },
        resetPriorityVersions: (state: FactorSetPriorityVersionState) => {
            state.factorSetVersions = initialState.factorSetVersions;
        }
    }
});

export const { fetchPriorityVersionById, fetchPriorityVersionByIdSuccess, fetchPriorityVersionByIdError, updatePriorityVersionStatus, updatePriorityVersionStatusSuccess,
    updatePriorityVersionStatusError, clonePriorityVersion, updatePriorityVersion, clonePriorityVersionSuccess, fetchPriorityVersions, fetchPriorityVersionsSuccess, fetchPriorityVersionsError,
    updatePriorityVersionSuccess, updatePriorityVersionError, resetPriorityVersion, clonePriorityVersionError, resetPriorityVersions } = customerPriorityVersionSlice.actions;

export const isLoadingPriorityVersion = (state: RootState): boolean => state.factorSetPriorityVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.factorSetPriorityVersionReducer.isCloningVersion;
export const isUpdatingPriorityVersionStatus = (state: RootState): boolean => state.factorSetPriorityVersionReducer.isUpdatingStatus;
export const isUpdating = (state: RootState): boolean => state.factorSetPriorityVersionReducer.isUpdating;
export const isUpdatingVersion = (state: RootState): boolean => state.factorSetPriorityVersionReducer.isUpdatingVersion;
export const getPriorityVersion = (state: RootState): FactorSetVersionOut => state.factorSetPriorityVersionReducer.factorSetVersion;
export const isLoadingFactorSetPriorityVersions = (state: RootState): boolean => state.factorSetPriorityVersionReducer.isLoadingFactorSetVersions;
export const getFactorSetPriorityVersions = (state: RootState): Pagination<FactorSetVersionOut> => state.factorSetPriorityVersionReducer.factorSetVersions;

export default customerPriorityVersionSlice.reducer;