import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ImportExportCodeApi from '../api/importExportCodeApi';
import { fetchImportExportCodeByIdError, fetchImportExportCodeByIdSuccess, fetchImportExportCodeError, fetchImportExportCodeSuccess } from '../reducers/importExportCodeReducer';
import ImportExportCode from '../interfaces/output/importExportCode';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchImportExportCodeById(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: ImportExportCode = yield call(ImportExportCodeApi.fetchImportExportCodeById, customerId);
        yield put(fetchImportExportCodeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchImportExportCodeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchImportExportCodes(action: PayloadAction<{ paging: Paging }>) {
    try {
        const { paging } = action.payload;

        const response: Pagination<ImportExportCode> = yield call(ImportExportCodeApi.fetchImportExportCodes, paging);
        yield put(fetchImportExportCodeSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchImportExportCodeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('importExportCode/fetchImportExportCodeById', doFetchImportExportCodeById),
    takeLatest('importExportCode/fetchImportExportCodes', doFetchImportExportCodes)
];

