import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import PricelistVersionProduct from '../interfaces/output/pricelistVersionProduct';
import PricelistVersionProductFilters from '../interfaces/filters/pricelistVersionProductFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PricelistVersionProductApi {
    static fetchPricelistVersionProductById = async(pricelistVersionproductId: string): Promise<PricelistVersionProduct> => {
        const { data } = await http.get<PricelistVersionProduct>(`/pricelistversionproducts/${pricelistVersionproductId}`);

        return data;
    };

    static fetchPricelistVersionProducts = async( paging: Paging, filters: PricelistVersionProductFilters): Promise<Array<PricelistVersionProduct>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/pricelistversionproducts');

        const { data } = await http.get<Array<PricelistVersionProduct>>(url);
    
        return data;
    };

    static createPricelistVersionProduct = async(pricelistVersionproduct: PricelistVersionProduct): Promise<PricelistVersionProduct> => {
        const newPricelistVersionProduct = {
            basePrice: pricelistVersionproduct?.basePrice,
            onHold: pricelistVersionproduct?.onHold,
            pricelistVersionId: pricelistVersionproduct?.pricelist?.version?.id,
            productId: pricelistVersionproduct?.product?.id
        };

        const { data } = await http.post<any, PricelistVersionProduct>('pricelistversionproducts', newPricelistVersionProduct);

        return data;
    }

    static updatePricelistVersionProduct = async(pricelistVersionproduct: PricelistVersionProduct): Promise<PricelistVersionProduct> => {
        const newPricelistVersionProduct = {
            basePrice: pricelistVersionproduct?.basePrice,
            onHold: pricelistVersionproduct?.onHold,
            pricelistVersionId: pricelistVersionproduct?.pricelist?.version?.id,
            productId: pricelistVersionproduct?.product?.id
        };
        
        const { data } = await http.put<any, PricelistVersionProduct>(`pricelistversionproducts/${pricelistVersionproduct.id}`, newPricelistVersionProduct);

        return data;
    }

    static deactivateProductPricelistVersionProduct = async(pricelistVersionproductId: number): Promise<PricelistVersionProduct> => {
        const { data } = await http.delete<PricelistVersionProduct>(`/pricelistversionproducts/${pricelistVersionproductId}`);

        return data;
    };
}
