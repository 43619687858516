import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Product from '../interfaces/output/product';
import ProductFilters from '../interfaces/output/productFilters';
import ProductDynamics from '../interfaces/output/productDynamics';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

export type ProductState = {
    product: Product;
    productDynamics: Pagination<ProductDynamics>;
    productDynamic: ProductDynamics;
    isLoadingProduct: boolean;
    isLoadingProducts: boolean;
    isLoadingProductDynamics: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    isCreatingProductDynamics: boolean;
    isUpdatingProductDynamics: boolean;
    isDeletingProductDynamics: boolean;
    products: Pagination<Product>;
    error: HttpErrorResponse | undefined;
};

const initialState: ProductState = {
    product: {} as Product,
    productDynamics: { content: [] as Array<ProductDynamics> } as Pagination<ProductDynamics>,
    productDynamic: {} as ProductDynamics,
    products: { content: [] as Array<Product> } as Pagination<Product>,
    isLoadingProduct: false,
    isLoadingProducts: false,
    isLoadingProductDynamics: false,
    isUpdatingProductDynamics: false,
    isCreatingProductDynamics: false,
    isDeletingProductDynamics: false,
    isUpdating: false,
    isRemoving: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const productSlice = createSlice({
    name: 'products',

    initialState,
    reducers: {
        fetchProducts: (state: ProductState, action: PayloadAction<{paging: Paging, filters: ProductFilters}>) => {
            state.isLoadingProducts = true;
        },
        fetchProductsSuccess: (state: ProductState, action: PayloadAction<Pagination<Product>>) => {
            state.isLoadingProducts = false;
            state.products = action.payload;
        },
        fetchProductsError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProducts = false;
            state.error = action.payload;
        },
        fetchProductById: (state: ProductState, _action: PayloadAction<number>) => {
            state.isLoadingProduct = true;
        },
        fetchProductByIdSuccess: (state: ProductState, action: PayloadAction<Product>) => {
            state.isLoadingProduct = false;
            state.product = action.payload;
        },
        fetchProductByIdError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProduct = false;
            state.error = action.payload;
        },
        fetchProductDynamicsByProductId: (state: ProductState, _action: PayloadAction<any>) => {
            state.isLoadingProductDynamics = true;
        },
        fetchProductDynamicsByProductIdSuccess: (state: ProductState, action: PayloadAction<Pagination<ProductDynamics>>) => {
            state.isLoadingProductDynamics = false;
            state.productDynamics = action.payload;
        },
        fetchProductDynamicsByProductIdError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProductDynamics = false;
            state.error = action.payload;
        },
        fetchProductDynamicsById: (state: ProductState, _action: PayloadAction<any>) => {
            state.isLoadingProductDynamics = true;
        },
        fetchProductDynamicsByIdSuccess: (state: ProductState, action: PayloadAction<ProductDynamics>) => {
            state.isLoadingProductDynamics = false;
            state.productDynamic = action.payload;
        },
        fetchProductDynamicsByIdError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProductDynamics = false;
            state.error = action.payload;
        },
        createProduct: (state: ProductState, _action: PayloadAction<Product>) => {
            state.isCreating = true;
        },
        createProductSuccess: (state: ProductState, action: PayloadAction<Product>) => {
            state.isCreating = false;
            state.product = action.payload;
        },
        createProductError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateProduct: (state: ProductState, _action: PayloadAction<Product>) => {
            state.isUpdating = true;
        },
        updateProductSuccess: (state: ProductState, action: PayloadAction<Product>) => {
            state.isUpdating = false;
            state.product = action.payload;
        },
        updateProductError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        updateProductDynamics: (state: ProductState, _action: PayloadAction<ProductDynamics>) => {
            state.isUpdatingProductDynamics = true;
        },
        updateProductDynamicsSuccess: (state: ProductState, action: PayloadAction<ProductDynamics>) => {
            state.isUpdatingProductDynamics = false;
            state.productDynamic = action.payload;
        },
        updateProductDynamicsError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingProductDynamics = false;
            state.error = action.payload;
        },
        createProductDynamics: (state: ProductState, _action: PayloadAction<ProductDynamics>) => {
            state.isCreatingProductDynamics = true;
        },
        createProductDynamicsSuccess: (state: ProductState, action: PayloadAction<ProductDynamics>) => {
            state.isCreatingProductDynamics = false;
            state.productDynamic = action.payload;
        },
        createProductDynamicsError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingProductDynamics = false;
            state.error = action.payload;
        },
        deactivateProductDynamics: (state: ProductState, _action: PayloadAction<number>) => {
            state.isDeletingProductDynamics = true;
        },
        deactivateProductDynamicsSuccess: (state: ProductState, action: PayloadAction<ProductDynamics>) => {
            state.isDeletingProductDynamics = false;
            state.productDynamic = action.payload;
        },
        deactivateProductDynamicsError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeletingProductDynamics = false;
            state.error = action.payload;
        },
        deactivateProduct: (state: ProductState, _action: PayloadAction<Product>) => {
            state.isDeleted = true;
        },
        deactivateProductSuccess: (state: ProductState, action: PayloadAction<Product>) => {
            state.isDeleted = false;
            state.product = action.payload;
        },
        deactivateProductError: (state: ProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetProductState: (state: ProductState) => state = initialState,
        resetProducts: (state: ProductState) => {
            state.products = initialState.products;
        },
        resetProduct: (state: ProductState) => {
            state.product = initialState.product;
        }
    }
});

export const { fetchProductById, fetchProductByIdSuccess, fetchProductByIdError, fetchProducts, fetchProductsError, fetchProductsSuccess, createProduct, createProductError
    , createProductSuccess, updateProduct, updateProductError, updateProductSuccess, deactivateProduct, deactivateProductError, deactivateProductSuccess, resetProducts,
    fetchProductDynamicsByProductId, fetchProductDynamicsByProductIdSuccess, fetchProductDynamicsByProductIdError,
    fetchProductDynamicsById, fetchProductDynamicsByIdSuccess, fetchProductDynamicsByIdError, resetProduct,
    updateProductDynamics, updateProductDynamicsError, updateProductDynamicsSuccess, createProductDynamics, createProductDynamicsError, createProductDynamicsSuccess,
    resetProductState, deactivateProductDynamics, deactivateProductDynamicsError, deactivateProductDynamicsSuccess } = productSlice.actions;

export const isLoadingProduct = (state: RootState): boolean => state.productReducer.isLoadingProduct;
export const isLoadingProducts = (state: RootState): boolean => state.productReducer.isLoadingProducts;
export const isLoadingProductDynamics = (state: RootState): boolean => state.productReducer.isLoadingProductDynamics;
export const isRemoving = (state: RootState): boolean => state.productReducer.isRemoving;
export const isUpdatingProduct = (state: RootState): boolean => state.productReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.productReducer.isDeleted;
export const isCreatingProduct = (state: RootState): boolean => state.productReducer.isCreating;
export const getProducts = (state: RootState): Pagination<Product> => state.productReducer.products;
export const getProduct = (state: RootState): Product => state.productReducer.product;
export const getProductDynamics = (state: RootState): Pagination<ProductDynamics> => state.productReducer.productDynamics;
export const getProductDynamic = (state: RootState): ProductDynamics => state.productReducer.productDynamic;
export const isCreatingProductDynamics = (state: RootState): boolean => state.productReducer.isCreatingProductDynamics;
export const isUpdatingProductDynamics = (state: RootState): boolean => state.productReducer.isUpdatingProductDynamics;
export const isDeletingProductDynamics = (state: RootState): boolean => state.productReducer.isDeletingProductDynamics;

export default productSlice.reducer;