import React from 'react';
import { List } from 'immutable';
import { removeDuplicates } from '../../../utils/immutableListUtils';
import { Alert } from '@mui/material';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Props } from 'react-intl/src/components/message';

interface IValidationSummaryProps {
    validationMessages?: List<MessageDescriptor>;
    show?: boolean;
}

const ValidationSummary = (props: IValidationSummaryProps) : JSX.Element => {

    const { validationMessages, show } = props;
    const validationMessagesDistinct = validationMessages ? removeDuplicates(validationMessages, 'id') : List<MessageDescriptor>();

    return (
        <div>
            {show &&
            <Alert severity="error">
                {validationMessagesDistinct.size === 1 ? (
                    <FormattedMessage {...validationMessagesDistinct.get(0)} />
                ) : (
                    <ul className="validation-list">
                        {' '}
                        {validationMessagesDistinct.map((validation: JSX.IntrinsicAttributes & Props<Record<string, React.ReactNode>>, key: React.Key | null | undefined) => (
                            <li key={key}>
                                <FormattedMessage {...validation} />
                            </li>
                        ))}
                    </ul>
                )}
            </Alert>
            }
        </div>
    );
};

export default ValidationSummary;