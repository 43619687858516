import { http } from '../utils/http';
import urlcat from 'urlcat';
import { ContentTypeConstants } from '../constants/browserConstants';

export default class D365InvoiceApi {
    static exportInvoice = async(id: number): Promise<Blob> => {
        const url = urlcat('', `/d365invoices/${id}/csv` );
        const { data } = await http.getFile<Blob>(url, ContentTypeConstants.csv);
    
        return data;
    };
}