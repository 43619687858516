import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import PriceListVersionStatusApi from '../api/pricelistVersionStatusApi';
import PricelistVersionStatus from '../interfaces/output/pricelistVersionStatus';
import { deactivatePriceListVersionStatusError, deactivatePriceListVersionStatusSuccess, fetchPriceListVersionStatusByIdError, fetchPriceListVersionStatusByIdSuccess,
    fetchPriceListVersionStatusesError,
    fetchPriceListVersionStatusesSuccess, updatePriceListVersionStatusError, updatePriceListVersionStatusSuccess }
    from '../reducers/pricelistVersionStatusReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchPriceListVersionStatus(action: PayloadAction<string>) {
    const versionStatusId = action.payload;
    try {
        const response: PricelistVersionStatus = yield call(PriceListVersionStatusApi.fetchPriceListVersionStatusById, versionStatusId);
    
        yield put(fetchPriceListVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPriceListVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<PricelistVersionStatus> = yield call(PriceListVersionStatusApi.fetchPriceListVersionStatuses, paging);
    
        yield put(fetchPriceListVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdatePriceListVersionStatus(action: PayloadAction<PricelistVersionStatus>) {
    const versionStatus = action.payload;

    try {
        const response: PricelistVersionStatus = yield call(PriceListVersionStatusApi.updatePriceListVersionStatus, versionStatus);

        yield put(updatePriceListVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriceListVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivatePriceListVersionStatus(action: PayloadAction<PricelistVersionStatus>) {
    const versionStatus = action.payload;
    
    try {
        const response: PricelistVersionStatus = yield call(PriceListVersionStatusApi.deactivatePriceListVersionStatus, versionStatus);

        yield put(deactivatePriceListVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivatePriceListVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('priceListVersionStatus/fetchPriceListVersionStatusById', doFetchPriceListVersionStatus),
    takeLatest('priceListVersionStatus/fetchPriceListVersionStatuses', doFetchPriceListVersionStatuses),
    takeLatest('priceListVersionStatus/updatePriceListVersionStatus', doUpdatePriceListVersionStatus),
    takeLatest('priceListVersionStatus/deactivatePriceListVersionStatus', doDeactivatePriceListVersionStatus)
];