import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../constants/UrlConstants';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import Paging from '../../interfaces/common/paging';
import CustomerCorporate from '../../interfaces/output/customerCorporate';
import MainLayout from '../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IMenuItem from '../../interfaces/common/menuItem';
import { deleteCorporate, fetchCorporates, fetchCustomerCorporateById, getCorporate, getCorporates, isDeletingCorporate, isLoadingCorporates } from '../../reducers/customerCorporateReducer';
import CorporatesList from './corporatesList';
import LanguageUtils from '../../utils/LanguageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import PageUtils from '../../utils/pageUtils';
import Action from '../../interfaces/common/action';
import { ensure } from '../../utils/arrayUtils';

interface ICorporatesProps {
    corporate: CustomerCorporate;
    corporates: Pagination<CustomerCorporate>;
    classes: any;
    fetchCorporates: any;
    fetchCustomerCorporateById: any;
    isLoading: boolean;
    onChange: () => void;
    deleteCustomerCorporate: (customerCorporate: number) => void;
    isDeleting: boolean;
}

interface ICorporateState {
    searchTerm: string;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    selectedRow?: number;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const messages = {
    corporates: LanguageUtils.createMessage('Corporates'),
    add: LanguageUtils.createMessage('Add'),
    edit: LanguageUtils.createMessage('Edit'),
    delete: LanguageUtils.createMessage('Deactivate')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCustomerCorporateById: (id: number) => dispatch(fetchCustomerCorporateById(id)),
    fetchCorporates: (paging: Paging, searchTerm: string) => dispatch(fetchCorporates({
        paging,
        searchTerm
    })),
    deleteCustomerCorporate: (customerCorporateId: number) => dispatch(deleteCorporate(customerCorporateId))
});

const mapStoreToProps = (store: RootState) => {
    return {
        corporates: getCorporates(store),
        corporate: getCorporate(store),
        isLoading: isLoadingCorporates(store),
        isDeleting: isDeletingCorporate(store)
    };
};

class Corporates extends Component<ICorporatesProps, ICorporateState> {
    corporateListRef: any;

    constructor(props: ICorporatesProps) {
        super(props);
        this.corporateListRef = React.createRef();
        this.state = {
            searchTerm: '',
            selectedRow: undefined,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.corporates,
                icon: <FontAwesomeIcon icon="user-tie" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: ICorporatesProps) {
        const { isDeleting } = this.props;

        if(!isDeleting && prevProps.isDeleting) {
            this.loadData();
        }
    }

    loadData = (): void => {
        const { fetchCorporates } = this.props;
        const { paging, searchTerm } = this.state;

        fetchCorporates(paging, searchTerm);
    }

    onChangeQuickSearch = (value: any): void => {
        const { paging } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination,
            searchTerm: value
        }, () => this.loadData());
    }

    deleteCustomerCorporate = () => {
        const { selectedRow } = this.state;
        const { deleteCustomerCorporate } = this.props;

        deleteCustomerCorporate(ensure(selectedRow));
    }

    _getActions = (): Array<Action> => {
        const { selectedRow } = this.state;
        const actions = [{
            text: messages.add,
            icon: 'plus',
            href: `${UrlConstants.CORPORATES}/add`
        } as Action];

        if(selectedRow) {
            actions.unshift(
                {
                    text: messages.edit,
                    icon: 'edit',
                    href: `${UrlConstants.CORPORATES}/${selectedRow}/edit`
                },
                {
                    icon: 'ban',
                    text: messages.delete,
                    onClick: this.deleteCustomerCorporate
                });
        }

        return actions;
    }
    _onSortModelChange = (newModel: GridSortModel) => {
        const { fetchCorporates } = this.props;
        const { paging, sortModel, searchTerm } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });
            
            this.setState({ paging: newPagination });
            fetchCorporates(newPagination, searchTerm);
        }
    }

    render() {
        const { classes, corporates, fetchCorporates, isLoading, isDeleting } = this.props;
        const { paging, menuItem, searchTerm, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    actions={this._getActions()}
                                />
                                <CorporatesList rows={corporates} isLoading={isLoading || isDeleting}
                                    ref={this.corporateListRef}
                                    checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchCorporates(newPage, searchTerm);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Corporates));