import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import ProductArena from '../interfaces/output/productArena';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ProductArenaFilters from '../interfaces/filters/productArenaFilters';

export type ProductArenaState = {
    productArena: ProductArena;
    isLoading: boolean;
    productArenas: Pagination<ProductArena>;
    error: HttpErrorResponse | undefined;
    isCreating: boolean;
    isUpdating: boolean;
};

const initialState: ProductArenaState = {
    productArena: {} as ProductArena,
    productArenas: { content: [] as Array<ProductArena> } as Pagination<ProductArena>,
    isLoading: false,
    error: undefined,
    isCreating: false,
    isUpdating: false
};

export const productArenaSlice = createSlice({
    name: 'productArena',

    initialState,
    reducers: {
        fetchProductArenas: (state: ProductArenaState, _action: PayloadAction<{ paging: Paging, filters?: ProductArenaFilters }>) => {
            state.isLoading = true;
        },
        fetchProductArenasSuccess: (state: ProductArenaState, action: PayloadAction<Pagination<ProductArena>>) => {
            state.isLoading = false;
            state.productArenas = action.payload;
        },
        fetchProductArenasError: (state: ProductArenaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchProductArenaById: (state: ProductArenaState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchProductArenaByIdSuccess: (state: ProductArenaState, action: PayloadAction<ProductArena>) => {
            state.isLoading = false;
            state.productArena = action.payload;
        },
        fetchProductArenaByIdError: (state: ProductArenaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createProductArena: (state: ProductArenaState, _action: PayloadAction<ProductArena>) => {
            state.isCreating = true;
        },
        createProductArenaSuccess: (state: ProductArenaState, action: PayloadAction<ProductArena>) => {
            state.isCreating = false;
            state.productArena = action.payload;
        },
        createProductArenaError: (state: ProductArenaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateProductArena: (state: ProductArenaState, _action: PayloadAction<ProductArena>) => {
            state.isUpdating = true;
        },
        updateProductArenaSuccess: (state: ProductArenaState, action: PayloadAction<ProductArena>) => {
            state.isUpdating = false;
            state.productArena = action.payload;
        },
        updateProductArenaError: (state: ProductArenaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetProductArenas: (state: ProductArenaState) => {
            state.productArenas = initialState.productArenas;
        },
        resetProductArena: (state: ProductArenaState) => {
            state.productArena = initialState.productArena;
        }
    }
});

export const { fetchProductArenaById, fetchProductArenaByIdSuccess, fetchProductArenaByIdError,
    fetchProductArenas, fetchProductArenasSuccess, fetchProductArenasError, resetProductArenas,
    createProductArena, createProductArenaError, createProductArenaSuccess, updateProductArena,
    updateProductArenaError, updateProductArenaSuccess, resetProductArena }
    = productArenaSlice.actions;

export const isLoadingProductArenas = (state: RootState): boolean => state.productArenaReducer.isLoading;
export const getProductArenas = (state: RootState): Pagination<ProductArena> => state.productArenaReducer.productArenas;
export const getProductArena = (state: RootState): ProductArena => state.productArenaReducer.productArena;
export const isCreatingProductArena = (state: RootState): boolean => state.productArenaReducer.isCreating;
export const isUpdatingProductArena = (state: RootState): boolean => state.productArenaReducer.isUpdating;

export default productArenaSlice.reducer;