import React from 'react';
import { InputAdornment, TextField, TextFieldProps, Theme, Tooltip } from '@mui/material';
import { MessageDescriptor, useIntl } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';
import { HelpOutline } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            borderColor: theme.palette.error.main,
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)'
        }
    })
);

type IGenericTextFieldProps<T> = Pick<TextFieldProps, 'inputProps'> & {
    /**
     * Label used in default comparison - default compareFn
     */
    name: string;
    /**
     * Label used in default comparison - default compareFn
     */
    label?: MessageDescriptor;
    /**
     * Selected value. The value should be included in the options (either found through a function, either direct value)
     */
    value?: T;
    /**
     * OnChange function used for single selection
     */
    onChange: ((key: string, x: T | undefined) => void);
    /**
     * If true, it shows the spinner
     */
    isLoading: boolean;
    /**
     * Placeholder object used by react-intl to display the placeholder
     */
    placeholder: any;
    /**
     *   Boolean mentioning whether the field is required or not.
     */
    required?: boolean;
    /**
        *  Boolean mentioning whether it should have multiselection or not. If true, it should have onMultipleChange property defined.
        */
    fullWidth?: boolean;
    /**
        *  Boolean mentioning whether a certain field has errors or not.
        */
    error?: boolean;

    variant?: 'standard' | 'filled' | 'outlined' | undefined;
    className?: string;
    InputProps?: any;
    type?: string;
    helperText?: MessageDescriptor;
    disabled?: boolean;
    onBlur?:any;
}

const defaultProps: IGenericTextFieldProps<any> = {
    name: '',
    label: undefined,
    required: false,
    error: false,
    fullWidth: true,
    onChange: () => { return; },
    isLoading: false,
    placeholder: undefined,
    variant: 'standard',
    className: '',
    type: 'string',
    helperText: undefined,
    disabled: false,
    onBlur: () => { return; }
};

const GenericTextField = <T,>(props: IGenericTextFieldProps<T>): JSX.Element => {
    const { fullWidth, label, value, onChange, required, name, variant, className, InputProps, type, error, helperText, inputProps, disabled, onBlur } = props;
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const tooltipText = helperText ? formatMessage(helperText) : '';

    return <>
        <TextField
            id={name}
            name={name}
            label={label ? formatMessage(label) : ''}
            className={error ? classes.textField : className}
            error={error}
            fullWidth={fullWidth}
            required={required}
            value={value ?? ''}
            variant={variant}
            InputProps={{
                endAdornment: (
                    helperText && <InputAdornment position="end">
                        <Tooltip title={tooltipText}>
                            <HelpOutline />
                        </Tooltip>
                    </InputAdornment>
                ),
                ...InputProps
            }
            }
            inputProps={inputProps}
            type={type}
            onChange={(e: React.ChangeEvent<any>) => onChange(name, e.target.value)}
            disabled={disabled}
            onBlur={(e: React.FocusEvent<any>) => onBlur(name, e.target.value)}
        />
    </>;
};

GenericTextField.defaultProps = defaultProps;
export default GenericTextField;