import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import DepartureLineApi from '../api/departureLineApi';
import FactorSetDepartureLineOutput from '../interfaces/output/factorSetDepartureLineOut';
import DepartureLineFilters from '../components/factorSets/departure/line/departureLineFilters';
import { createDepartureVersionLineError, createDepartureVersionLineSuccess, deactivateFactorSetDepartureLineError, deactivateFactorSetDepartureLineSuccess, fetchDepartureVersionLinesError,
    fetchDepartureVersionLinesSuccess, fetchFactorSetDepartureLineByIdError, fetchFactorSetDepartureLineByIdSuccess, updateFactorSetDepartureLineError, updateFactorSetDepartureLineSuccess }
    from '../reducers/departureLineReducer';

function* doFetchFactorSetDepartureLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetDepartureLineOutput = yield call(DepartureLineApi.fetchFactorSetDepartureLineById, lineId);
        yield put(fetchFactorSetDepartureLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetDepartureLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDepartureLines(action: PayloadAction<{paging: Paging, filters: DepartureLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetDepartureLineOutput> = yield call(DepartureLineApi.fetchFactorSetDepartureLines, paging, filters);
    
        yield put(fetchDepartureVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchDepartureVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetDepartureLine(action: PayloadAction<FactorSetDepartureLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetDepartureLineOutput = yield call(DepartureLineApi.createFactorSetDepartureLine, line);

        yield put(createDepartureVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createDepartureVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDepartureLine(action: PayloadAction<FactorSetDepartureLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetDepartureLineOutput = yield call(DepartureLineApi.updateFactorSetDepartureLine, line);

        yield put(updateFactorSetDepartureLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetDepartureLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetDepartureLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetDepartureLineOutput = yield call(DepartureLineApi.deactivateFactorSetDepartureLine, lineId);

        yield put(deactivateFactorSetDepartureLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetDepartureLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetDepartureLineById', doFetchFactorSetDepartureLineById),
    takeLatest('factorSetLine/fetchDepartureVersionLines', doFetchFactorSetDepartureLines),
    takeLatest('factorSetLine/createDepartureVersionLine', doCreateFactorSetDepartureLine),
    takeLatest('factorSetLine/updateFactorSetDepartureLine', doUpdateFactorSetDepartureLine),
    takeLatest('factorSetLine/deactivateFactorSetDepartureLine', doDeactivateFactorSetDepartureLine)
];