import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import Pagination from '../interfaces/common/pagination';
import InvoiceOut from '../interfaces/output/invoiceOut';
import InvoiceFilters from '../interfaces/filters/invoiceFilters';
import InvoiceLineFiltersIn from '../interfaces/filters/invoiceLineFilters';
import InvoiceLineOut from '../interfaces/output/invoiceLineOut';
import InvoiceIn from '../interfaces/input/invoiceIn';
import { ContentTypeConstants } from '../constants/browserConstants';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import CustomerReport from '../interfaces/output/customerReport';
import DetailedHistoryFilter from '../interfaces/filters/detailedHistoryFilter';

export default class InvoiceApi {
    static fetchInvoiceById = async(invoiceId: number): Promise<InvoiceOut> => {
        const { data } = await http.get<InvoiceOut>(`invoice/${invoiceId}`);

        return data;
    };

    static fetchInvoices = async(paging: Paging, filters: InvoiceFilters): Promise<Pagination<InvoiceOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/invoice');

        const { data } = await http.get<Pagination<InvoiceOut>>(url);

        return data;
    };

    static fetchInvoiceLines = async(invoiceId: number, paging: Paging, filters: InvoiceLineFiltersIn): Promise<Pagination<InvoiceLineOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, `invoice/${invoiceId}/lines`);
        
        const { data } = await http.get<Pagination<InvoiceLineOut>>(url);

        return data;
    };

    static createInvoice = async(invoice: InvoiceOut): Promise<InvoiceOut> => {
        const newInvoice: InvoiceIn = {
            invoiceNumber: invoice?.invoiceNumber,
            customerId: invoice?.customer.id,
            invoiceTypeId: invoice.invoiceType.id,
            invoiceFormatId: invoice?.invoiceFormat?.id,
            invoiceStatusId: 1,
            invoiceDate: invoice.invoiceDate,
            paymentDeadlineId: invoice.paymentDeadline?.id
        };

        const { data } = await http.post<InvoiceIn, InvoiceOut>('invoice', newInvoice);

        return data;
    }

    static updateInvoice = async(invoice: InvoiceOut): Promise<InvoiceOut> => {
        const newInvoice: InvoiceIn = {
            invoiceNumber: invoice?.invoiceNumber,
            customerId: invoice?.customer.id,
            invoiceTypeId: invoice.invoiceType.id,
            invoiceFormatId: invoice?.invoiceFormat?.id,
            invoiceStatusId: invoice?.invoiceStatus?.id,
            invoiceDate: invoice.invoiceDate,
            paymentDeadlineId: invoice.paymentDeadline?.id
        };
        
        const { data } = await http.put<InvoiceIn, InvoiceOut>(`invoice/${invoice.id}`, newInvoice);

        return data;
    }

    static deactivateInvoice = async(invoiceId: number): Promise<InvoiceOut> => {
        const { data } = await http.delete<InvoiceOut>(`invoice/${invoiceId}`);

        return data;
    }

    static creditInvoice = async(id: number, createNewCredit: boolean, creditReason: string): Promise<InvoiceOut> => {
        const url = urlcat('', '/invoice/credit', {
            id,
            newInvoice: createNewCredit,
            creditReason
        });

        const { data } = await http.put<any, InvoiceOut>(url);

        return data;
    }

    static exportInvoice = async(id: number): Promise<Blob> => {
        const url = urlcat('', `/invoice/${id}/csv` );
        const { data } = await http.getFile<Blob>(url, ContentTypeConstants.csv);
    
        return data;
    };

    static sendInvoiceEmail = async(id: number, emailAddress: string|undefined): Promise<InvoiceOut> => {

        const url = urlcat('', `/invoice/${id}/mailToAddress`, {
            emailAddress
        });
        
        const { data } = await http.put<any, InvoiceOut>(url);
    
        return data;
    };
    
    static exportInvoicePdf = async(id: number): Promise<Blob> => {
        const url = urlcat('', `/invoice/${id}/pdf` );
        const { data } = await http.getFile<Blob>(url, ContentTypeConstants.pdf);
    
        return data;
    };

    static fetchCustomerReport = async(filters: DetailedHistoryFilter, paging: Paging): Promise<Pagination<CustomerReport>> => {
        
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/invoice/credit/customerReport');
        
        const { data } = await http.get<Pagination<CustomerReport>>(url);

        return data;
    };
}