import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Grid, FormControl, FormControlLabel, Typography, Switch } from '@mui/material';
import GenericTextField from './widgets/genericTextField';

interface AddCreditModalProps {
    onClose: () => void;
    show: boolean;
    id: number;
    createCredit: any;
}
const messages = {
    close: LanguageUtils.createMessage('Close'),
    add: LanguageUtils.createMessage('Add'),
    creditReason: LanguageUtils.createMessage('Credit reason'),
    addTitle: LanguageUtils.createMessage('Add credit reason'),
    createNewInvoice: LanguageUtils.createMessage('Create new invoice')

};

export default function AddCreditModal(props: AddCreditModalProps): JSX.Element {
    const { onClose, show, id, createCredit } = props;
    const dispatch = useDispatch();
    const [creditReason, setCreditReason] = React.useState<string | undefined>('');
    const [createNewInvoice, setCreateNewInvoice] = React.useState<boolean>(true);

    const handleSave = () => {
        dispatch(createCredit({
            id: id,
            createNewCredit: createNewInvoice,
            creditReason: creditReason ?? ''
        }
        ));
        handleClose();
    };

    const handleClose = () => {
        onClose();
        setCreditReason('');
        setCreateNewInvoice(true);
    };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <FormattedMessage {...messages.addTitle} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <GenericTextField<string>
                                    label={messages.creditReason}
                                    value={creditReason}
                                    name="creditReason"
                                    onChange={(name: string, value: string | undefined) => setCreditReason(value)}
                                />
                            </FormControl>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={createNewInvoice}
                                        onChange={(e: any, value: any) => setCreateNewInvoice(value)} inputProps={{ 'aria-label': 'controlled' }}
                                        color="primary" />}
                                    label={<Typography><FormattedMessage {...messages.createNewInvoice} /></Typography>} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    <FormattedMessage {...messages.close} />
                </Button>
                <Button autoFocus onClick={handleSave}>
                    <FormattedMessage {...messages.add} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}