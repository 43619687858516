import React, { forwardRef, useEffect, useMemo } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FactorSetDepartureLineOutput from '../../../../interfaces/output/factorSetDepartureLineOut';
import PageUtils from '../../../../utils/pageUtils';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { fetchFactorSetDepartureTypes, getFactorSetDepartureTypes, isLoadingFactorSetDeparture } from '../../../../reducers/factorSetDepartureReducer';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import FactorSetType from '../../../../interfaces/output/factorSetType';
import DepartureLineFilters from '../line/departureLineFilters';
import GenericTextField from '../../../common/widgets/genericTextField';
import { fetchDepartureVersionLines, getDepartureLines, isCreatingDepartureLine, isLoadingDepartureLine, isUpdatingDepartureLine } from '../../../../reducers/departureLineReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';
import FactorSetDepartureLineStep1Validation from '../line/validations/factorSetDepartureLineStep1Validation';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitType from '../../../../interfaces/output/unitType';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import Currency from '../../../../interfaces/output/currency';
import { ensure } from '../../../../utils/arrayUtils';
import { isLoadingDepartureTypes } from '../../../../reducers/departureTypeReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';

interface DepartureLineFormStep1Props {
    departureLine: FactorSetDepartureLineOutput;
    factorSetVersionId?: number;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    DEPARTURE_TYPE: 'departureType',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    departureTypes: LanguageUtils.createMessage('Departure types'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    unittype: LanguageUtils.createMessage('Unit type'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    departureTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_DEPARTURE_TYPE_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT')
};

const DepartureLineFormStep1 = forwardRef((props: DepartureLineFormStep1Props, ref: any): JSX.Element => {
    const { departureLine, onChange, factorSetVersionId, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingDepartureLine), useSelector(isLoadingDepartureLine), useSelector(isLoadingDepartureTypes), useSelector(isUpdatingDepartureLine),
        useSelector(isLoadingFactorSetDeparture)];
    const departureTypes = useSelector(getFactorSetDepartureTypes).content;
    const departureLines = useSelector(getDepartureLines).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const prevDepartureLine = WorkflowUtils.usePrevious<FactorSetDepartureLineOutput>(departureLine);
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchFactorSetDepartureTypes({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchDepartureVersionLines({
            paging,
            filters: { factorSetVersionId: factorSetVersionId } as DepartureLineFilters }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(departureLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(departureLine.unitType?.unitGroupId),
                paging }));
            if(departureLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(departureLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevDepartureLine) {
            if(departureLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(departureLine.unitType?.unitGroupId),
                    paging }));
                if(prevDepartureLine.id === departureLine.id) {
                    onChange('unitType', { ...departureLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                }
            }
            else {
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [departureLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevDepartureLine) {
            if(departureLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(departureLine.unitType?.unitSubGroupId),
                    paging }));
                if(prevDepartureLine.id === departureLine.id) {
                    onChange('unitType', { ...departureLine.unitType,
                        id: null });
                }
            }
        }
    }, [departureLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!departureLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(departureLine?.unitType?.id) {
            dispatch(fetchFactorSetDepartureTypes({ paging }));
        }
    }, [departureLine?.unitType?.id]);

    const filteredTypes = () => {
        return useMemo(() => {
            return departureTypes.filter(dt => (!departureLine.unitType?.id && (!departureLines.find(dl => dl.departureType?.id === dt.id && !dl.unitType)))
        || !departureLines.find(dl => dl.id !== departureLine?.id && dl.departureType?.id === dt.id && dl.unitType?.id === departureLine.unitType?.id));
        }, [departureTypes, departureLine, departureLines]);
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetDepartureLineStep1Validation.validateName(departureLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = departureLine.surcharge ? FactorSetDepartureLineStep1Validation.validateInputNumber(departureLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetDepartureLineStep1Validation.validateInputNumber(departureLine.factor);
                break;
            }
            case ATTRIBUTES.DEPARTURE_TYPE: {
                isValid = FactorSetDepartureLineStep1Validation.validateRequiredNumberWithMaxLength(departureLine.departureType?.id);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = departureLine.surcharge ? FactorSetDepartureLineStep1Validation.validateInputNumber(departureLine.surchargeCurrency?.id) : true;
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={departureLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetType>
                            options={filteredTypes()}
                            value={departureLine.departureType}
                            onChange={(obj: FactorSetType | null) => onChange('departureType', obj)}
                            isLoading={useSelector(isLoadingDepartureTypes)}
                            placeholder={messages.departureTypes}
                            error={setValidationState(ATTRIBUTES.DEPARTURE_TYPE)}
                            required
                            helperText={messages.departureTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={departureLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === departureLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...departureLine.unitType,
                                unitGroupId: obj?.id })}
                            placeholder={messages.unitGroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={departureLine.unitType?.id}
                            compareFn={(o: UnitSubGroup) => o.id === departureLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...departureLine.unitType,
                                unitSubGroupId: obj?.id })}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={departureLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={departureLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === departureLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...departureLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unittype}
                            isLoading={departureLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={departureLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        type="number"
                        label={messages.factor}
                        value={departureLine.factor}
                        onChange={onChange}
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        type="number"
                        label={messages.surcharge}
                        value={departureLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={departureLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={departureLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default DepartureLineFormStep1;