class RoleConstants {
    static get Roles(): { ROLE_PRODUCT: string,
        ROLE_SALES: string,
        ROLE_FINANCIAL: string,
        ROLE_ADMIN: string } {
        return {
            ROLE_PRODUCT: 'ROLE_PRODUCT',
            ROLE_SALES: 'ROLE_SALES',
            ROLE_FINANCIAL: 'ROLE_FINANCIAL',
            ROLE_ADMIN: 'ROLE_ADMIN'
        };
    }
}

export default RoleConstants;