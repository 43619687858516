import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid } from '@mui/material';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericTextField from '../../common/widgets/genericTextField';
import AgreementVersion from '../../../interfaces/output/agreementVersion';

interface AddAgreementVersionStep4Props {
    agreementVersion?: AgreementVersion;
    onChange?: any;
}

const messages = {
    percentAdjustmentSpecialPrices: LanguageUtils.createMessage('Percent adjustment special prices'),
    percentAdjustmentTransitDiscount: LanguageUtils.createMessage('Percent adjustment transit discount') };

const AddAgreementVersionStep4 = forwardRef((props: AddAgreementVersionStep4Props, ref: any): JSX.Element => {
    const { agreementVersion, onChange } = props;
    WorkflowUtils.setHandle(ref, null);
          
    return (
        <Loader>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number | undefined>
                        name="percentAdjustmentSpecialPrices"
                        label={messages.percentAdjustmentSpecialPrices}
                        value={agreementVersion?.percentAdjustmentSpecialPrices}
                        type="number"
                        onChange={onChange}
                    />
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number | undefined>
                        name="percentAdjustmentTransitDiscount"
                        label={messages.percentAdjustmentTransitDiscount}
                        value={agreementVersion?.percentAdjustmentTransitDiscount}
                        type="number"
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddAgreementVersionStep4;