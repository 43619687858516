import { http } from '../utils/http';
import ErrorType from '../interfaces/output/errorType';
import Pagination from '../interfaces/common/pagination';
import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';

export default class ErrorTypeApi {
    static fetchErrorType = async(id: string): Promise<ErrorType> => {
        const { data } = await http.get<ErrorType>(`customergroup/${id}`);

        return data;
    };

    static fetchErrorTypes = async(paging: Paging, searchTerm: string): Promise<Pagination<ErrorType>> => {
        const url = urlcat('', '/errortypes', {
            ...paging,
            searchTerm
        });

        const { data } = await http.get<Pagination<ErrorType>>(url);

        return data;
    };
}
