import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import LanguageUtils from '../../utils/LanguageUtils';
import Loader from '../common/widgets/loader';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import { isLoadingGtsOrderUnit, isUpdatingGtsOrderUnit } from '../../reducers/gtsOrderUnitReducer';
import GenericTextField from '../common/widgets/genericTextField';
import WorkflowUtils from '../../utils/workflowUtils';

interface AddGtsOrderUnitStep1Props {
    gtsOrderUnit: GTSOrderUnit;
    onChange: any;
}

const messages = {
    logicalUnitId: LanguageUtils.createMessage('Logical unit id'),
    gtsUnitType: LanguageUtils.createMessage('Gts unit type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    unitNumber: LanguageUtils.createMessage('Unit number'),
    trainNumber: LanguageUtils.createMessage('Train number'),
    trainId: LanguageUtils.createMessage('Train id'),
    netWeightKg: LanguageUtils.createMessage('Net weight'),
    grossWeightKg: LanguageUtils.createMessage('Gross weight kg')
};

const AddGtsOrderUnitStep1 = forwardRef((props: AddGtsOrderUnitStep1Props, ref: any): JSX.Element => {
    const isLoadingObjects = [useSelector(isLoadingGtsOrderUnit), useSelector(isUpdatingGtsOrderUnit)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const { gtsOrderUnit, onChange } = props;
    WorkflowUtils.setHandle(ref, null);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="logicalUnitId"
                        label={messages.logicalUnitId}
                        value={gtsOrderUnit.logicalUnitId}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="gtsUnitType"
                        label={messages.gtsUnitType}
                        value={gtsOrderUnit.gtsUnitType}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="unitType"
                        label={messages.unitType}
                        value={gtsOrderUnit.unitType}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="unitNumber"
                        label={messages.unitNumber}
                        value={gtsOrderUnit.unitNumber}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="trainNumber"
                        label={messages.trainNumber}
                        value={gtsOrderUnit.trainNumber}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="trainId"
                        label={messages.trainId}
                        value={gtsOrderUnit.trainId}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="netWeightKg"
                        type="number"
                        label={messages.netWeightKg}
                        value={gtsOrderUnit.netWeightKg}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="grossWeightKg"
                        type="number"
                        label={messages.grossWeightKg}
                        value={gtsOrderUnit.grossWeightKg}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </Loader>
    );
});

export default AddGtsOrderUnitStep1;
  