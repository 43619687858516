import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import FactorSetHoursLineOutput from '../../../../interfaces/output/factorSetHoursLineOut';
import HoursLineFormStep1 from './hoursLineFormStep1';
import { createHoursVersionLine, fetchFactorSetHoursLineById, getFactorSetHoursLine, isCreatingHoursLine, isLoadingHoursLine, isUpdatingHoursLine, updateFactorSetHoursLine }
    from '../../../../reducers/hoursLineReducer';
import FactorSetHoursLineStep1Validation from '../line/validations/factorSetHoursLineStep1Validation';

interface IFactorSetHoursLineFormProps {
    hoursLineId?: number;
    factorSetHoursId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set hours line'),
    add: LanguageUtils.createMessage('Add factor set hours line')
};

export default function FactorSetHoursLineForm(props: IFactorSetHoursLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { hoursLineId, factorSetHoursId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [hoursLine, setHoursLine] = React.useState<FactorSetHoursLineOutput>({ factorSetId: factorSetHoursId,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetHoursLineOutput);
    const propsHoursLine = useSelector(getFactorSetHoursLine);
    const propsIsUpdatingHoursLine = useSelector(isUpdatingHoursLine);
    const prevIsUpdatingHoursLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingHoursLine);
    const propsIsCreatingHoursLine = useSelector(isCreatingHoursLine);
    const prevIsCreatingHoursLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingHoursLine);
    const propsIsLoadingHours = useSelector(isLoadingHoursLine);
    const prevIsLoadingHours = WorkflowUtils.usePrevious<boolean>(propsIsLoadingHours);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingHoursLine === true && !propsIsUpdatingHoursLine) || (prevIsCreatingHoursLine === true && !propsIsCreatingHoursLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingHoursLine, propsIsCreatingHoursLine]);
    
    useEffect(() => {
        if(hoursLineId) {
            dispatch(fetchFactorSetHoursLineById(hoursLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(prevIsUpdatingHoursLine && !prevIsLoadingHours || prevIsLoadingHours && !propsIsLoadingHours || prevIsCreatingHoursLine && !propsIsCreatingHoursLine) {
            setHoursLine(propsHoursLine);
        }

    }, [propsIsUpdatingHoursLine, propsIsLoadingHours, propsIsCreatingHoursLine]);

    useEffect(() => {
        if(propsHoursLine.id) {
            setHoursLine(propsHoursLine);
        }

    }, [propsIsUpdatingHoursLine, propsHoursLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...hoursLine } as FactorSetHoursLineOutput;
        (newLine as any)[attribute] = value;

        setHoursLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <HoursLineFormStep1 onChange={onChange} hoursLine={hoursLine} />,
            validationFn: () => FactorSetHoursLineStep1Validation.validateFactorSetForm(hoursLine),
            onNext: () => {
                if(!hoursLine.id) {
                    dispatch(createHoursVersionLine(hoursLine));
                }
                else {
                    dispatch(updateFactorSetHoursLine(hoursLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddLine:
                return hoursLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/hours/${factorSetHoursId}/${hoursLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/hours/${factorSetHoursId}/latest/lines`;
            case FormType.EditLine:
                return hoursLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/hours/${factorSetHoursId}/${hoursLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/hours/${factorSetHoursId}/latest/lines`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  