import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import DangerousGoodsLineApi from '../api/dangerousGoodsLineApi';
import FactorSetDangerousGoodsLineOut from '../interfaces/output/factorSetDangerousGoodsLineOut';
import { createDangerousGoodVersionLineError, createDangerousGoodVersionLineSuccess, deactivateFactorSetDangerousGoodLineError, deactivateFactorSetDangerousGoodLineSuccess,
    fetchDangerousGoodVersionLinesError, fetchDangerousGoodVersionLinesSuccess, fetchFactorSetDangerousGoodLineByIdError, fetchFactorSetDangerousGoodLineByIdSuccess,
    updateFactorSetDangerousGoodLineError, updateFactorSetDangerousGoodLineSuccess } from '../reducers/dangerousGoodsLineReducer';
import DangerousGoodsLineFilters from '../components/factorSets/dangerousGoods/line/dangerousGoodsLineFilters';

function* doFetchFactorSetDangerousGoodsLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetDangerousGoodsLineOut = yield call(DangerousGoodsLineApi.fetchFactorSetDangerousGoodLineById, lineId);
        yield put(fetchFactorSetDangerousGoodLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetDangerousGoodLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDangerousGoodsLines(action: PayloadAction<{paging: Paging, filters: DangerousGoodsLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetDangerousGoodsLineOut> = yield call(DangerousGoodsLineApi.fetchFactorSetDangerousGoodLines, paging, filters);
    
        yield put(fetchDangerousGoodVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchDangerousGoodVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetDangerousGoodsLine(action: PayloadAction<FactorSetDangerousGoodsLineOut>) {
    const line = action.payload;

    try {
        const response: FactorSetDangerousGoodsLineOut = yield call(DangerousGoodsLineApi.createFactorSetDangerousGoodLine, line);

        yield put(createDangerousGoodVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createDangerousGoodVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDangerousGoodsLine(action: PayloadAction<FactorSetDangerousGoodsLineOut>) {
    const line = action.payload;

    try {
        const response: FactorSetDangerousGoodsLineOut = yield call(DangerousGoodsLineApi.updateFactorSetDangerousGoodLine, line);

        yield put(updateFactorSetDangerousGoodLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetDangerousGoodLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetDangerousGoodsLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetDangerousGoodsLineOut = yield call(DangerousGoodsLineApi.deactivateFactorSetDangerousGoodLine, lineId);

        yield put(deactivateFactorSetDangerousGoodLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetDangerousGoodLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetDangerousGoodLineById', doFetchFactorSetDangerousGoodsLineById),
    takeLatest('factorSetLine/fetchDangerousGoodVersionLines', doFetchFactorSetDangerousGoodsLines),
    takeLatest('factorSetLine/createDangerousGoodVersionLine', doCreateFactorSetDangerousGoodsLine),
    takeLatest('factorSetLine/updateFactorSetDangerousGoodLine', doUpdateFactorSetDangerousGoodsLine),
    takeLatest('factorSetLine/deactivateFactorSetDangerousGoodLine', doDeactivateFactorSetDangerousGoodsLine)
];