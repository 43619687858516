import { http } from '../utils/http';
import TransitDiscount from '../interfaces/output/transitDiscount';

export default class AgreementVersionTransitDiscountApi {
    static fetchAgreementVersionTransitDiscountById = async(discountId: number): Promise<TransitDiscount> => {
        const { data } = await http.get<TransitDiscount>(`agreementversiontransitdiscount/${discountId}`);

        return data;
    };
    static updateAgreementVersionTransitDiscount = async(transitDiscount: TransitDiscount): Promise<TransitDiscount> => {
        const newTransitDiscount = {
            productArenaFromId: transitDiscount.productArenaFrom?.id,
            productArenaToId: transitDiscount.productArenaTo?.id,
            transitDiscount: transitDiscount.transitDiscount
        };
        
        const { data } = await http.put<any, TransitDiscount>(`agreementversiontransitdiscount/${transitDiscount.id}`, newTransitDiscount);

        return data;
    }

    static deactivateAgreementVersionTransitDiscount = async(discountId: number): Promise<TransitDiscount> => {
        const { data } = await http.delete<TransitDiscount>(`agreementversiontransitdiscount/${discountId}`);

        return data;
    }
}