import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Paging from '../../interfaces/common/paging';
import LanguageUtils from '../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import UserOut from '../../interfaces/output/userOut';
import { fetchUserById, getIsLoading, getUser, resetUser } from '../../reducers/userReducer';
import ViewUser from './viewUser';

interface IUserDetailsProps {
    classes: any;
    theme: Theme;
    fetchUserById: any;
    user: UserOut;
    match: any;
    history: any;
    location: any;
    isLoadingUser: boolean;
    resetUser: () => void;
}

interface IUserDetailsState {
    user: UserOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverProductPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchUserById: (userId: any) => dispatch(fetchUserById(userId)),
    resetUser: () => dispatch(resetUser())
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    properties: LanguageUtils.createMessage('Properties'),
    user: LanguageUtils.createMessage('User')
};

const mapStoreToProps = (store: RootState) => {
    return {
        user: getUser(store),
        isLoading: getIsLoading(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class UserDetails extends Component<IUserDetailsProps, IUserDetailsState> {
    constructor(props: IUserDetailsProps) {
        super(props);

        this.state = {
            user: {} as UserOut,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.USERS}/${props.match.params.userId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.USERS}/${props.match.params.userId}/properties`, props.location.pathname)
            }
            ]),
            serverProductPagination: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchUserById } = this.props;
        const { userId } = this.props.match.params;

        fetchUserById(userId);
    }

    componentDidUpdate(prevProps: IUserDetailsProps) {
        const { user } = this.props;

        if(user !== prevProps.user) {
            this.setState({ user });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            });
        }
    }

    componentWillUnmount() {
        resetUser();
    }

    getSelectedMenuItem = (state: IUserDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { userId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.USERS}/${userId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, isLoadingUser } = this.props;
        const { user, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingUser}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.User}
                menuItem={{
                    id: 1,
                    subname: messages.user,
                    value: user?.id,
                    icon: <FontAwesomeIcon icon="file-invoice-dollar" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.USERS}/:userId/properties`}>
                            <ViewUser user={user} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(UserDetails));