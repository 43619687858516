import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isLoadingPriceList } from '../../../reducers/priceListReducer';
import PriceList from '../../../interfaces/output/priceList';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchPriceListVersionStatuses, getPriceListVersionStatuses, isLoadingPriceListVersionStatus } from '../../../reducers/pricelistVersionStatusReducer';
import PriceListVersionStatus from '../../../interfaces/output/pricelistVersionStatus';
import PageUtils from '../../../utils/pageUtils';
import { isUpdatingPriceListStatus } from '../../../reducers/priceListVersionReducer';

interface AddPriceListStep1Props {
    priceList: PriceList;
    onChange: any;
}

const messages = {
    status: LanguageUtils.createMessage('Status')
};

const AddPriceListVersionStep4 = forwardRef((props: AddPriceListStep1Props, ref: any): JSX.Element => {
    const { priceList, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const priceListVersionStatuses = useSelector(getPriceListVersionStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingPriceListVersionStatus), useSelector(isLoadingPriceList), useSelector(isUpdatingPriceListStatus)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchPriceListVersionStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PriceListVersionStatus>
                            options={priceListVersionStatuses}
                            value={priceList.version?.status}
                            onChange={(obj: PriceListVersionStatus | null) => onChange('status', obj)}
                            placeholder={messages.status}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddPriceListVersionStep4;