import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import VatCodeApi from '../api/vatApi';
import VatCode from '../interfaces/output/vatCode';
import { fetchVatByIdError, fetchVatByIdSuccess, fetchVatCodesError, fetchVatCodesSuccess } from '../reducers/vatReducer';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchVatCode(action: PayloadAction<string>) {
    const vatId = action.payload;
    try {
        const response: VatCode = yield call(VatCodeApi.fetchVatCodeById, vatId);
        yield put(fetchVatByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchVatByIdError(error as HttpErrorResponse));
    }
}

function* doFetchVatCodes(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<VatCode> = yield call(VatCodeApi.fetchVatCodes, paging);
        yield put(fetchVatCodesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchVatCodesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('vat/fetchVatById', doFetchVatCode),
    takeLatest('vat/fetchVatCodes', doFetchVatCodes)];

