/* eslint-disable no-console */
import { http } from '../utils/http';
import urlcat from 'urlcat';
import localStorageUtils from '../utils/localStorageUtils';
import { IAuthToken } from '../auth/login';

const AUTH_GRANT_TYPE = 'authorization_code';
const REFRESH_GRANT_TYPE = 'refresh_token';
const AUTH_CLIENT_SECRET = '';

export default class AuthApi {
    static getToken = async(code: string): Promise<any> => {
        const url = process.env.REACT_APP_AUTH_TOKEN_ENDPOINT;
        const body = urlcat('', { code: code,
            grant_type: AUTH_GRANT_TYPE,
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
            client_secret: AUTH_CLIENT_SECRET
        });
        const { data } = await http.post<any>('', body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            baseURL: url,
            withCredentials: false });

        return data;
    };

    static refreshToken = async(refreshToken: string, checkDate: boolean): Promise<any> => {
        if(checkDate) {
            const exp = localStorageUtils.getItem<number>(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_EXP);
            const date = new Date(Date.now() - 1000 * 30 ).getDate();
            if(exp && exp > date) {
                return;
            }
        }
        const url = process.env.REACT_APP_AUTH_TOKEN_ENDPOINT;
        const body = urlcat('', { refresh_token: refreshToken,
            grant_type: REFRESH_GRANT_TYPE,
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: AUTH_CLIENT_SECRET
        });
        const { data } = await http.post<any>('', body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            baseURL: url,
            withCredentials: false });

        const token = localStorageUtils.getItem<IAuthToken>(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN);
        const currentDate = new Date();
        localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN, { ...data,
            expiration_date: currentDate.setMinutes(currentDate.getMinutes() + data.expires_in / 60),
            refresh_token: token?.refresh_token } as IAuthToken);
            
        setTimeout(() => {
            AuthApi.refreshToken(data.refresh_token, true);
        }, data.expires_in * 1000);
            
        // eslint-disable-next-line consistent-return
        return data;
    };
}