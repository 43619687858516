import { http } from '../utils/http';
import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';
import HelptextOut from '../interfaces/output/helptextOut';

export default class HelptextApi {
    static fetchHelptextById = async(helptextId: string): Promise<HelptextOut> => {
        const { data } = await http.get<HelptextOut>(`helptext/${helptextId}`);
        // , { withCredentials: true } as AxiosRequestConfig

        return data;
    };

    static fetchHelptexts = async(paging: Paging): Promise<Array<HelptextOut>> => {
        const url = urlcat('', '/helptext', {
            ...paging
        });
        const { data } = await http.get<Array<HelptextOut>>(url);

        return data;
    };
}
