import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import ServiceStatus from '../interfaces/output/serviceStatus';
import ServiceStatusApi from '../api/serviceStatusApi';
import { createServiceStatusError, createServiceStatusSuccess, deleteServiceStatusError, deleteServiceStatusSuccess,
    fetchServiceStatusByIdError, fetchServiceStatusByIdSuccess, fetchServiceStatusesError, fetchServiceStatusesSuccess,
    updateServiceStatusError, updateServiceStatusSuccess } from '../reducers/serviceStatusReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchServiceStatus(action: PayloadAction<string>) {
    const serviceStatusId = action.payload;
    try {
        const response: ServiceStatus = yield call(ServiceStatusApi.fetchServiceStatusById, serviceStatusId);
        yield put(fetchServiceStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchServiceStatuses(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<ServiceStatus> = yield call(ServiceStatusApi.fetchServiceStatuses, paging);
        yield put(fetchServiceStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateServiceStatus(action: PayloadAction<ServiceStatus>) {
    const serviceStatus = action.payload;

    try {
        const response: ServiceStatus = yield call(ServiceStatusApi.updateServiceStatus, serviceStatus);

        yield put(updateServiceStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateServiceStatusError(error as HttpErrorResponse));
    }
}

function* doCreateServiceStatus(action: PayloadAction<ServiceStatus>) {
    const serviceStatus = action.payload;

    try {
        const response: ServiceStatus = yield call(ServiceStatusApi.createServiceStatus, serviceStatus);

        yield put(createServiceStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(createServiceStatusError(error as HttpErrorResponse));
    }
}

function* doDeleteServiceStatus(action: PayloadAction<ServiceStatus>) {
    const serviceStatus = action.payload;
    
    try {
        const response: ServiceStatus = yield call(ServiceStatusApi.deleteServiceStatus, serviceStatus);

        yield put(deleteServiceStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteServiceStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('serviceStatus/fetchServiceStatusById', doFetchServiceStatus),
    takeLatest('serviceStatus/fetchServiceStatuses', doFetchServiceStatuses),
    takeLatest('serviceStatus/updateServiceStatus', doUpdateServiceStatus),
    takeLatest('serviceStatus/createServiceStatus', doCreateServiceStatus),
    takeLatest('serviceStatus/deleteServiceStatus', doDeleteServiceStatus)
];