import PricingComponentTypeApi from '../api/pricingComponentTypeApi';
import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchPricingComponentTypesError, fetchPricingComponentTypesSuccess, fetchPricingComponentTypeByIdError, fetchPricingComponentTypeByIdSuccess, createPricingComponentTypeSuccess,
    createPricingComponentTypeError, updatePricingComponentTypeSuccess, updatePricingComponentTypeError, deactivatePricingComponentTypeSuccess, deactivatePricingComponentTypeError }
    from '../reducers/pricingComponentTypeReducer';
import PricingComponentType from '../interfaces/output/pricingComponentTypeOut';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import PricingComponentTypeFilters from '../interfaces/filters/pricingComponentTypeFilters';
import Paging from '../interfaces/common/paging';

function* doFetchPricingComponentType(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: PricingComponentType = yield call(PricingComponentTypeApi.fetchPricingComponentTypeById, customerId);
        yield put(fetchPricingComponentTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPricingComponentTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPricingComponentTypes(action: PayloadAction<{ paging: Paging, filters?: PricingComponentTypeFilters}>) {
    const { paging, filters } = action.payload;
    try {
        const response: Pagination<PricingComponentType> = yield call(PricingComponentTypeApi.fetchPricingComponentTypes, paging, filters);
        yield put(fetchPricingComponentTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPricingComponentTypesError(error as HttpErrorResponse));
    }
}

function* doCreatePricingComponentType(action: PayloadAction<PricingComponentType>) {
    const pricingcomponent = action.payload;

    try {
        const response: PricingComponentType = yield call(PricingComponentTypeApi.createPricingComponentType, pricingcomponent);

        yield put(createPricingComponentTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(createPricingComponentTypeError(error as HttpErrorResponse));
    }
}

function* doUpdatePricingComponentType(action: PayloadAction<PricingComponentType>) {
    const pricingcomponent = action.payload;

    try {
        const response: PricingComponentType = yield call(PricingComponentTypeApi.updatePricingComponentType, pricingcomponent);

        yield put(updatePricingComponentTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePricingComponentTypeError(error as HttpErrorResponse));
    }
}

function* doDeactivatePricingComponentType(action: PayloadAction<number>) {
    const pricingcomponentId = action.payload;
    
    try {
        const response: PricingComponentType = yield call(PricingComponentTypeApi.deactivatePricingComponentType, pricingcomponentId);

        yield put(deactivatePricingComponentTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivatePricingComponentTypeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('pricingComponentType/fetchPricingComponentTypeById', doFetchPricingComponentType),
    takeLatest('pricingComponentType/fetchPricingComponentTypes', doFetchPricingComponentTypes),
    takeLatest('pricingComponentType/createPricingComponentType', doCreatePricingComponentType),
    takeLatest('pricingComponentType/updatePricingComponentType', doUpdatePricingComponentType),
    takeLatest('pricingComponentType/deactivatePricingComponentType', doDeactivatePricingComponentType)
];

