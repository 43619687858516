import React from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import OrderUnitService from '../../interfaces/output/orderUnitService';
import DisplayStatus from '../common/displayStatus';
import LanguageUtils from '../../utils/LanguageUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomLink from '../common/customLink';
import CustomStringLink from '../common/customStringLink';

interface IOrderUnitServiceListProps {
    classes?: any;
    unitServices?: Pagination<OrderUnitService>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    prefix?:string;
    secondPrefix?:string;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    unitNo: LanguageUtils.createMessage('Unit number'),
    serviceTypeName: LanguageUtils.createMessage('Service type'),
    ousStatus: LanguageUtils.createMessage('OUS status'),
    serviceDate: LanguageUtils.createMessage('Service date'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    amount: LanguageUtils.createMessage('Amount'),
    serviceEndDate: LanguageUtils.createMessage('Service end date'),
    currency: LanguageUtils.createMessage('Currency'),
    ousStatusComment: LanguageUtils.createMessage('Ous status comment')
};

export default function UnitServiceList(props: IOrderUnitServiceListProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, unitServices, onSortModelChange, prefix, secondPrefix,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: secondPrefix ? `${secondPrefix}unitNo` : 'unitNo',
                headerName: intl.formatMessage(messages.unitNo),
                flex: 0.8,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(cellValues).id}`}
                        value={applyRowFn(cellValues)?.orderUnit?.unitNo ?? '-'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('service', hiddenColumns)
            },
            {
                field: 'service',
                headerName: intl.formatMessage(messages.serviceTypeName),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.service?.type?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('service', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}fromTerminal` : 'fromTerminalId_name',
                headerName: intl.formatMessage(messages.fromTerminal),
                flex: 2,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.service?.fromTerminal?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('fromTerminal', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}toTerminal` : 'toTerminalId_name',
                headerName: intl.formatMessage(messages.toTerminal),
                flex: 2,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.service?.toTerminal?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('toTerminal', hiddenColumns)
            },
            {
                field: 'serviceDate',
                headerName: intl.formatMessage(messages.serviceDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return (<CustomLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.serviceDate} />
                    </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceDate', hiddenColumns)
            },
            {
                field: 'serviceEndDate',
                headerName: intl.formatMessage(messages.serviceEndDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return (<CustomLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.serviceEndDate} />
                    </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('serviceEndDate', hiddenColumns)
            },
            {
                field: 'ousStatus',
                headerName: intl.formatMessage(messages.ousStatus),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(params).id}`}>
                        <DisplayStatus status={applyRowFn(params)?.ousStatus} inheritProps />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('ousStatus', hiddenColumns)
            },
            {
                field: 'ousStatusComment',
                headerName: intl.formatMessage(messages.ousStatusComment),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(params).id}`} value={applyRowFn(params)?.ousStatusComment ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('ousStatusComment', hiddenColumns)
            },
            {
                field: 'amount',
                headerName: intl.formatMessage(messages.amount),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/${applyRowFn(params).id}`} value={applyRowFn(params)?.amount ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('amount', hiddenColumns)
            },
            {
                field: 'totalPrice',
                headerName: intl.formatMessage(messages.totalPrice),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}/$${applyRowFn(params).id}`} value={applyRowFn(params)?.totalPrice ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('totalPrice', hiddenColumns)
            },
            {
                field: 'currency',
                headerName: intl.formatMessage(messages.currency),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.ORDER_UNIT_SERVICES}${applyRowFn(params).id}`} value={applyRowFn(params)?.currency?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('currency', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || unitServices} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
}