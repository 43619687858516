import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme, Card } from '@mui/material';
import Agreement from '../../interfaces/output/agreement';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import UrlConstants from '../../constants/UrlConstants';
import FontAwesomeIcon from '../../utils/FontAwesomeIcons';
import AccordionSummary from '../common/accordionSummary';
import DisplayStatus from '../common/displayStatus';
import { StatusConstants } from '../../constants/statusConstants';
import Status from '../../interfaces/output/status';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';

interface IViewAgreementProps {
    classes?: any;
    theme?: Theme;
    agreement: Agreement;
    setCurrentComponentId?: any;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        alignCenter: {
            textAlign: 'center'
        },
        link: {
            textDecoration: 'none',
            color: `${theme.palette.info.main}!important`,
            fontSize: '12px!important'
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Agreement details'),
    name: LanguageUtils.createMessage('Name'),
    seller: LanguageUtils.createMessage('Seller'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    status: LanguageUtils.createMessage('Status'),
    versionFrom: LanguageUtils.createMessage('Valid from'),
    versionNumber: LanguageUtils.createMessage('Version number'),
    version: LanguageUtils.createMessage('Version'),
    versionTo: LanguageUtils.createMessage('Valid to'),
    noVersionsToDisplay: LanguageUtils.createMessage('No versions to display'),
    revenue: LanguageUtils.createMessage('Revenue'),
    revenueThisYear: LanguageUtils.createMessage('Revenue this year'),
    revenueLastYear: LanguageUtils.createMessage('Revenue last year'),
    noRevenuesToDisplay: LanguageUtils.createMessage('No revenues to display'),
    versionInProgress: LanguageUtils.createMessage('Version in progress'),
    noVersionInProgress: LanguageUtils.createMessage('No version in progress'),
    activeVersion: LanguageUtils.createMessage('Active version'),
    transitDiscount: LanguageUtils.createMessage('Transit discount'),
    noActiveVersion: LanguageUtils.createMessage('No version active'),
    currency: LanguageUtils.createMessage('Currency')
};

export default function ViewAgreement(props: IViewAgreementProps): JSX.Element {
    const classes = styles();
    const { agreement, setCurrentComponentId } = props;
    const intl = useIntl();

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.CUSTOMER_AGREEMENTS_MAIN);
        }
    }, []);

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />} aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}>
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.name} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12}>
                                    <Typography gutterBottom>{agreement.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.seller} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{agreement.seller?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitGroup} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>{agreement.unitGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.status} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <DisplayStatus status={{ code: agreement.active ? StatusConstants.Active : StatusConstants.Inactive } as Status} />
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.currency} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12}>
                                    <Typography gutterBottom>{agreement.currency?.name || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}>Version</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {agreement.version &&
                                    <>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.transitDiscount} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom>{agreement.version?.defaultTransitDiscount || '-'}</Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.versionNumber} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom>{agreement.version?.versionNumber || '-'}</Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.versionFrom} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom><NullableFormattedDate value={agreement.version?.validFromDate} /></Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.versionTo} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom><NullableFormattedDate value={agreement.version?.validToDate} /></Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={3} className={classes.gridItem}>
                                            <Typography gutterBottom>Status: </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <DisplayStatus status={agreement.version?.status} />
                                        </Grid>
                                    </>
                                }

                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.versionInProgress} />: </Typography>
                                </Grid>
                                <Grid item sm={!agreement.inProgressVersion ? 6 : 2} xs={12} >
                                    <Grid container>
                                        <Grid item sm={!agreement.inProgressVersion ? 5 : 1}>
                                            <Typography gutterBottom>
                                                {agreement.inProgressVersion ? agreement.inProgressVersion.versionNumber : intl.formatMessage(messages.noVersionInProgress)}
                                            </Typography>
                                        </Grid>
                                        {agreement.inProgressVersion && agreement.inProgressVersion?.id !== agreement.version?.id &&
                                            <Grid item sm={1} ml={1}>
                                                <Link to={`/${UrlConstants.AGREEMENTS}/${agreement.id}/${agreement.inProgressVersion.id}/properties`} title="Go to versions" className={classes.link}>
                                                    <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                </Link>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item sm={!agreement.inProgressVersion ? 3 : 7} xs={12} />
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.activeVersion} />: </Typography>
                                </Grid>
                                <Grid item sm={!agreement.currentVersion ? 6 : 2} xs={12} >
                                    <Grid container>
                                        <Grid item sm={!agreement.currentVersion ? 5 : 1}>
                                            <Typography gutterBottom>{agreement.currentVersion ? agreement.currentVersion.versionNumber : intl.formatMessage(messages.noActiveVersion)}</Typography>
                                        </Grid>
                                        {agreement.currentVersion && agreement.currentVersion?.id !== agreement.version?.id &&
                                            <Grid item sm={1} ml={1}>
                                                <Link to={`/${UrlConstants.AGREEMENTS}/${agreement.id}/${agreement.currentVersion.id}/properties`} title="Go to versions" className={classes.link}>
                                                    <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                </Link>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item sm={!agreement.currentVersion ? 3 : 6} xs={12} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.revenue} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {agreement.revenueLastYear || agreement.revenueThisYear ?
                                <Grid container>
                                    <Grid item sm={3} xs={12} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.revenueThisYear} />: </Typography>
                                    </Grid>
                                    <Grid item sm={9} xs={12} >
                                        <Typography gutterBottom>{agreement.revenueThisYear &&
                                            intl.formatNumber(ensure(Math.round(agreement.revenueThisYear)), {
                                                maximumFractionDigits: 0
                                            }) || '-'}</Typography>
                                    </Grid>
                                    <Grid item sm={3} xs={12} className={classes.gridItem}>
                                        <Typography gutterBottom><FormattedMessage {...messages.revenueLastYear} />: </Typography>
                                    </Grid>
                                    <Grid item sm={9} xs={12} >
                                        <Typography gutterBottom>{agreement.revenueLastYear &&
                                            intl.formatNumber(ensure(Math.round(agreement.revenueLastYear)), {
                                                maximumFractionDigits: 0
                                            }) || '-'}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <div className={classes.alignCenter}>
                                    <Typography variant="caption"><FormattedMessage {...messages.noRevenuesToDisplay} /></Typography>
                                </div>}
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}