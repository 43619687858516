import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import SurchargeType from '../interfaces/output/surchargeType';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class SurchargeTypeApi {
    static fetchSurchargeTypeById = async(surchargeTypeId: string): Promise<SurchargeType> => {
        const { data } = await http.get<SurchargeType>(`surchargetype/${surchargeTypeId}`);

        return data;
    };

    static fetchSurchargeTypes = async(paging: Paging): Promise<Pagination<SurchargeType>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/surchargetype');
        const { data } = await http.get<Pagination<SurchargeType>>(url);

        return data;
    };
}
