/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Bar from './bar';
import IMenuItem from '../../../interfaces/common/menuItem';
import { List } from 'immutable';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import Action from '../../../interfaces/common/action';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Loader from '../../common/widgets/loader';
import CollapsibleDrawer from '../collapsibleDrawer';
import { ObjectType } from '../../../constants/constants';
import ActionRenderer from '../actionRenderer';
import HelptextOut from '../../../interfaces/output/helptextOut';
import HelptextExtendableDrawer from '../helptextExtendableDrawer';
import IBreadCrumbItem from '../../../interfaces/common/breadcrumbItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            overflow: 'auto',
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            })
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginLeft: 0
        },
        contentShiftHelptext: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginRight: 0
        },
        appBarSpacer: theme.mixins.toolbar
    })
);

interface IMainLayoutProps {
    children: JSX.Element;
    menuItems?: List<IMenuItem>;
    className?: any;
    menuItem: IMenuItem;
    actions?: Array<Action>;
    classes?: any;
    additionalElement?: JSX.Element;
    isLoading?: boolean;
    includeDrawer?: boolean;
    objectType?: ObjectType;
    helptext?: HelptextOut;
    routes?: Array<IBreadCrumbItem>;
    componentId?: string;
}

export default function MainLayout(props: IMainLayoutProps): JSX.Element {
    const { children, menuItem, actions, additionalElement, isLoading, menuItems, includeDrawer, objectType, routes, componentId } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(true);
    const [openHelptextDrawer, setOpenHelptextDrawer] = React.useState<boolean>(false);

    const className = clsx(classes.content, {
        [classes.contentShift]: open,
        [classes.contentShiftHelptext]: openHelptextDrawer
    });

    return (
        <Loader isLoading={isLoading}>
            <Grid container direction="column">
                <Grid item>
                    <Bar menuItem={menuItem} additionalElement={additionalElement} routes={routes} />
                </Grid>
                <Grid item>
                    <main>
                        <div className={classes.appBarSpacer} />
                        <div className={classes.appBarSpacer} />
                        <Grid container direction="column">

                            <Grid item className={className}>
                                {includeDrawer && <CollapsibleDrawer menuItems={menuItems} open={open} setOpen={setOpen} objectType={objectType} />}
                                <Grid container direction="column">
                                    <Grid item pt={1} mr={3}>
                                        <Grid container direction="row" justifyContent="flex-end">
                                            <ActionRenderer actions={actions} />
                                        </Grid>
                                    </Grid>
                                    <Grid item >
                                        {children}
                                    </Grid>
                                </Grid>
                                {includeDrawer && <HelptextExtendableDrawer open={openHelptextDrawer} setOpen={setOpenHelptextDrawer} objectType={objectType} componentId={componentId} />}
                            </Grid>
                        </Grid>
                    </main>
                </Grid>
            </Grid>
        </Loader>
    );
}