import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../../constants/constants';
import FactorSetHoursLineForm from './factorSetHoursLineForm';
import { resetFactorSetHoursLine, resetHoursVersionLines } from '../../../../reducers/hoursLineReducer';

interface IAddFactorSetLineProps {
    classes: any;
    theme: Theme;
    match: any;
    hoursLineId?: number;
    factorSetHoursId?: number;
    factorSetVersionId?: number;
    resetFactorSetHoursLine: any;
    resetHoursVersionLines: any;
    type: FormType;
    steps: Array<number>;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetFactorSetHoursLine: () => dispatch(resetFactorSetHoursLine()),
    resetHoursVersionLines: () => dispatch(resetHoursVersionLines())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetLineProps) => {
    return {
        hoursLineId: ownProps.match.params?.hoursLineId ? Number(ownProps.match.params?.hoursLineId) : undefined,
        factorSetHoursId: ownProps.match.params?.hoursId ? Number(ownProps.match.params?.hoursId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetHoursLine extends Component<IAddFactorSetLineProps> {

    componentWillUnmount() {
        const { resetFactorSetHoursLine, resetHoursVersionLines } = this.props;

        resetFactorSetHoursLine();
        resetHoursVersionLines();
    }

    render() {
        const { classes, steps, hoursLineId, factorSetHoursId, type, factorSetVersionId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <FactorSetHoursLineForm hoursLineId={hoursLineId} factorSetHoursId={factorSetHoursId} steps={steps} type={type} factorSetVersionId={factorSetVersionId} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetHoursLine));