import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../constants/constants';
import ImportGtsOrderForm from './importGtsOrderForm';

interface IImportGtsOrderProps {
    classes: any;
    theme: Theme;
    match: any;
    steps: Array<number>;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapDispatchToProps = (dispatch: Dispatch) => ({
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStoreToProps = (store: RootState, ownProps: IImportGtsOrderProps) => {
    return {
    };
};

class ImportGtsOrder extends Component<IImportGtsOrderProps> {
    render() {
        const { classes, steps, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <ImportGtsOrderForm steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ImportGtsOrder));