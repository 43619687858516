import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import SimulationProductOut from '../interfaces/output/simulationProductOut';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SimulationProductFilters from '../interfaces/filters/simulationProductFilters';

export type SimulationProductState = {
    simulationProduct: SimulationProductOut;
    isLoadingSimulationProduct: boolean;
    isLoadingSimulationProducts: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    simulationProducts: Pagination<SimulationProductOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: SimulationProductState = {
    simulationProduct: {} as SimulationProductOut,
    simulationProducts: { content: [] as Array<SimulationProductOut> } as Pagination<SimulationProductOut>,
    isLoadingSimulationProduct: false,
    isLoadingSimulationProducts: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const simulationProductSlice = createSlice({
    name: 'simulationProduct',

    initialState,
    reducers: {
        fetchSimulationProducts: (state: SimulationProductState, _action: PayloadAction<{paging: Paging, filters: SimulationProductFilters}>) => {
            state.isLoadingSimulationProducts = true;
        },
        fetchSimulationProductsSuccess: (state: SimulationProductState, action: PayloadAction<Pagination<SimulationProductOut>>) => {
            state.isLoadingSimulationProducts = false;
            state.simulationProducts = action.payload;
        },
        fetchSimulationProductsError: (state: SimulationProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulationProducts = false;
            state.error = action.payload;
        },
        fetchSimulationProductById: (state: SimulationProductState, _action: PayloadAction<number>) => {
            state.isLoadingSimulationProduct = true;
        },
        fetchSimulationProductByIdSuccess: (state: SimulationProductState, action: PayloadAction<SimulationProductOut>) => {
            state.isLoadingSimulationProduct = false;
            state.simulationProduct = action.payload;
        },
        fetchSimulationProductByIdError: (state: SimulationProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulationProduct = false;
            state.error = action.payload;
        },
        createSimulationProduct: (state: SimulationProductState, _action: PayloadAction<SimulationProductOut>) => {
            state.isCreating = true;
        },
        createSimulationProductSuccess: (state: SimulationProductState, action: PayloadAction<SimulationProductOut>) => {
            state.isCreating = false;
            state.simulationProduct = action.payload;
        },
        createSimulationProductError: (state: SimulationProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateSimulationProduct: (state: SimulationProductState, _action: PayloadAction<SimulationProductOut>) => {
            state.isUpdating = true;
        },
        updateSimulationProductSuccess: (state: SimulationProductState, action: PayloadAction<SimulationProductOut>) => {
            state.isUpdating = false;
            state.simulationProduct = action.payload;
        },
        updateSimulationProductError: (state: SimulationProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteSimulationProduct: (state: SimulationProductState, _action: PayloadAction<SimulationProductOut>) => {
            state.isDeleting = true;
        },
        deleteSimulationProductSuccess: (state: SimulationProductState, action: PayloadAction<SimulationProductOut>) => {
            state.isDeleting = false;
            state.simulationProduct = action.payload;
        },
        deleteSimulationProductError: (state: SimulationProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetSimulationProducts: (state: SimulationProductState) => {
            state.simulationProducts = initialState.simulationProducts;
        },
        resetSimulationProduct: (state: SimulationProductState) => {
            state.simulationProduct = initialState.simulationProduct;
        }
    }
});

export const { fetchSimulationProducts, fetchSimulationProductsSuccess, fetchSimulationProductsError, fetchSimulationProductById, fetchSimulationProductByIdSuccess, fetchSimulationProductByIdError,
    createSimulationProduct, createSimulationProductSuccess, createSimulationProductError, updateSimulationProduct, updateSimulationProductSuccess, updateSimulationProductError,
    deleteSimulationProduct, deleteSimulationProductSuccess, deleteSimulationProductError, resetSimulationProducts, resetSimulationProduct } = simulationProductSlice.actions;

export const isLoadingSimulationProduct = (state: RootState): boolean => state.simulationProductReducer.isLoadingSimulationProduct;
export const isLoadingSimulationProducts = (state: RootState): boolean => state.simulationProductReducer.isLoadingSimulationProducts;
export const isUpdatingSimulationProduct = (state: RootState): boolean => state.simulationProductReducer.isUpdating;
export const isDeletingSimulationProduct = (state: RootState): boolean => state.simulationProductReducer.isDeleting;
export const isCreatingSimulationProduct = (state: RootState): boolean => state.simulationProductReducer.isCreating;
export const getSimulationProducts = (state: RootState): Pagination<SimulationProductOut> => state.simulationProductReducer.simulationProducts;
export const getSimulationProduct = (state: RootState): SimulationProductOut => state.simulationProductReducer.simulationProduct;

export default simulationProductSlice.reducer;