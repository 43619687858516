import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import GtsOrder from '../../interfaces/output/gtsOrder';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomStringLink from '../common/customStringLink';
import CustomLink from '../common/customLink';

interface IGtsOrderListProps {
    classes?: any;
    gtsOrders?: Pagination<GtsOrder>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    orderNo: LanguageUtils.createMessage('Order no'),
    customerName: LanguageUtils.createMessage('Customer'),
    receivedFromGTS: LanguageUtils.createMessage('Received from GTS'),
    unitGroupCode: LanguageUtils.createMessage('Unit group code'),
    errorType: LanguageUtils.createMessage('Error type'),
    orderDate: LanguageUtils.createMessage('Order date')
};

const GtsOrdersList = forwardRef((props: IGtsOrderListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, gtsOrders, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'orderNumber',
                headerName: intl.formatMessage(messages.orderNo),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues)?.orderNumber} link={`/#/${UrlConstants.GTS_ORDERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderNumber', hiddenColumns)
            },
            {
                field: 'customerNumber',
                headerName: intl.formatMessage(messages.customerName),
                flex: 1.2,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(params)?.customer?.customerNoName ?? applyRowFn(params)?.customerNumber}
                        link={`/#/${UrlConstants.GTS_ORDERS}/${applyRowFn(params).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('customer', hiddenColumns)
            },
            {
                field: 'orderDate',
                headerName: intl.formatMessage(messages.orderDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return (<CustomLink link={`/#/${UrlConstants.GTS_ORDERS}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.orderDate} />
                    </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderDate', hiddenColumns)
            },
            {
                field: 'receivedFromGTS',
                headerName: intl.formatMessage(messages.receivedFromGTS),
                flex: 1,
                sortable: true,
                renderCell: (params: any) => {
                    return (<CustomLink link={`/#/${UrlConstants.GTS_ORDERS}/${applyRowFn(params).id}`}>
                        <NullableFormattedDate value={params.row?.receivedFromGTS} />
                    </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('receivedFromGTS', hiddenColumns)
            },
            {
                field: 'unitGroupCode',
                headerName: intl.formatMessage(messages.unitGroupCode),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={params.row?.unitGroupCode ?? '-'} link={`/#/${UrlConstants.GTS_ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitGroupCode', hiddenColumns)
            },
            {
                field: 'errorType',
                headerName: intl.formatMessage(messages.errorType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.errorType?.text ?? '-'} link={`/#/${UrlConstants.GTS_ORDERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('errorType', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || gtsOrders} columns={generateColumns()} componentsProps={componentsProps}
            isLoading={isLoading} checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
});

export default GtsOrdersList;