import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import FactorSetDangerousGoodLineOutput from '../../../../interfaces/output/factorSetDangerousGoodsLineOut';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import DangerousGoodLineFormStep1 from './dangerousGoodsLineFormStep1';
import { createDangerousGoodVersionLine, fetchFactorSetDangerousGoodLineById, getFactorSetDangerousGoodLine, isCreatingDangerousGoodLine, isUpdatingDangerousGoodLine,
    updateFactorSetDangerousGoodLine } from '../../../../reducers/dangerousGoodsLineReducer';
import FactorSetDangerousGoodLineStep1Validation from '../line/validations/factorSetDangerousGoodsLineStep1Validation';

interface IAddFactorSetDangerousGoodLineFormProps {
    dangerousGoodsLineId?: number;
    factorSetDangerousGoodsId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set dangerous goods line'),
    add: LanguageUtils.createMessage('Add factor set dangerous goods line')
};

export default function AddFactorSetDangerousGoodsLineForm(props: IAddFactorSetDangerousGoodLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { dangerousGoodsLineId, factorSetDangerousGoodsId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [dangerousGoodsLine, setDangerousGoodLine] = React.useState<FactorSetDangerousGoodLineOutput>({ factorSetId: factorSetDangerousGoodsId,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetDangerousGoodLineOutput);
    const propsDangerousGoodLine = useSelector(getFactorSetDangerousGoodLine);
    const propsIsUpdatingDangerousGoodLine = useSelector(isUpdatingDangerousGoodLine);
    const prevIsUpdatingDangerousGoodLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingDangerousGoodLine);
    const propsIsCreatingDangerousGoodLine = useSelector(isCreatingDangerousGoodLine);
    const prevIsCreatingDangerousGoodLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingDangerousGoodLine);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingDangerousGoodLine === true && !propsIsUpdatingDangerousGoodLine) || (prevIsCreatingDangerousGoodLine === true && !propsIsCreatingDangerousGoodLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingDangerousGoodLine, propsIsUpdatingDangerousGoodLine]);
    
    useEffect(() => {
        if(dangerousGoodsLineId) {
            dispatch(fetchFactorSetDangerousGoodLineById(dangerousGoodsLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsDangerousGoodLine.id) {
            setDangerousGoodLine(propsDangerousGoodLine);
        }

    }, [propsIsUpdatingDangerousGoodLine, propsDangerousGoodLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...dangerousGoodsLine } as FactorSetDangerousGoodLineOutput;
        (newLine as any)[attribute] = value;

        setDangerousGoodLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <DangerousGoodLineFormStep1 onChange={onChange} dangerousGoodsLine={dangerousGoodsLine} factorSetVersionId={factorSetVersionId} />,
            validationFn: () => FactorSetDangerousGoodLineStep1Validation.validateFactorSetForm(dangerousGoodsLine),
            onNext: () => {
                if(!dangerousGoodsLine.id) {
                    dispatch(createDangerousGoodVersionLine(dangerousGoodsLine));
                }
                else {
                    dispatch(updateFactorSetDangerousGoodLine(dangerousGoodsLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddLine:
                return dangerousGoodsLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSetDangerousGoodsId}/${dangerousGoodsLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSetDangerousGoodsId}/latest/lines`;
            case FormType.EditLine:
                return dangerousGoodsLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSetDangerousGoodsId}/${dangerousGoodsLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSetDangerousGoodsId}/latest/lines`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  