import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import AgreementVersionSpecialPriceOut from '../../interfaces/output/agreementVersionSpecialPriceOut';
import IMenuItem from '../../interfaces/common/menuItem';
import { deactivateAgreementVersionSpecialPriceById, fetchAgreementVersionSpecialPriceById, getAgreementVersionSpecialPrice, isDeletingAgreementSpecialPrice,
    isLoadingAgreementVersionSpecialPrice, isUpdatingAgreementSpecialPrice } from '../../reducers/agreementVersionSpecialPriceReducer';
import LanguageUtils from '../../utils/LanguageUtils';
import UrlConstants from '../../constants/UrlConstants';
import PageUtils from '../../utils/pageUtils';
import MainLayout from '../common/widgets/mainLayout';
import { ObjectType } from '../../constants/constants';
import { RootState } from '../../setup';
import ViewAgreementVersionSpecialPrice from './viewAgreementVersionSpecialPrice';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface IAgreementVersionSpecialPriceDetailsProps {
    classes: any;
    theme: Theme;
    fetchAgreementVersionSpecialPriceById: any;
    deactivateAgreementVersionSpecialPriceById: any;
    agreementVersionSpecialPrice: AgreementVersionSpecialPriceOut;
    match: any;
    history: any;
    location: any;
    isUpdatingAgreementSpecialPrice: boolean;
    isDeletingAgreementSpecialPrice: boolean;
    isLoadingAgreementVersionSpecialPrice: boolean;
}

interface IAgreementVersionSpecialPriceDetailsState {
    agreementVersionSpecialPrice: AgreementVersionSpecialPriceOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchAgreementVersionSpecialPriceById: (id: number) => dispatch(fetchAgreementVersionSpecialPriceById(id)),
    deactivateAgreementVersionSpecialPriceById: (id: number) => dispatch(deactivateAgreementVersionSpecialPriceById(id))
});

const mapStoreToProps = (store: RootState) => {
    return {
        agreementVersionSpecialPrice: getAgreementVersionSpecialPrice(store),
        isUpdatingAgreementSpecialPrice: isUpdatingAgreementSpecialPrice(store),
        isDeletingAgreementSpecialPrice: isDeletingAgreementSpecialPrice(store),
        isLoading: isLoadingAgreementVersionSpecialPrice(store) || isDeletingAgreementSpecialPrice(store) || isUpdatingAgreementSpecialPrice(store),
        isLoadingAgreementVersionSpecialPrice: isLoadingAgreementVersionSpecialPrice(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    subname: LanguageUtils.createMessage('SPECIAL_PRICE'),
    properties: LanguageUtils.createMessage('Properties'),
    factors: LanguageUtils.createMessage('Factors')
};

class AgreementVersionSpecialPriceDetails extends Component<IAgreementVersionSpecialPriceDetailsProps, IAgreementVersionSpecialPriceDetailsState> {
    constructor(props: IAgreementVersionSpecialPriceDetailsProps) {
        super(props);

        this.state = {
            agreementVersionSpecialPrice: {} as AgreementVersionSpecialPriceOut,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${props.match.params.agreementVersionSpecialPriceId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${props.match.params.agreementVersionSpecialPriceId}/properties`, props.location.pathname)
            }]),
            selectedRow: undefined
        };
    }

    componentDidMount() {
        const { fetchAgreementVersionSpecialPriceById } = this.props;
        const { agreementVersionSpecialPriceId } = this.props.match.params;

        if(agreementVersionSpecialPriceId) {
            fetchAgreementVersionSpecialPriceById(agreementVersionSpecialPriceId);
        }
    }

    componentDidUpdate(prevProps: IAgreementVersionSpecialPriceDetailsProps) {
        const { agreementVersionSpecialPrice } = this.props;

        if(agreementVersionSpecialPrice !== prevProps.agreementVersionSpecialPrice) {
            this.setState({ agreementVersionSpecialPrice });
        }
    }

    deactivateAgreementVersionSpecialPrice = () => {
        const { agreementVersionSpecialPrice } = this.state;
        const { deactivateAgreementVersionSpecialPriceById } = this.props;

        deactivateAgreementVersionSpecialPriceById(agreementVersionSpecialPrice.id);
    }

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { agreementVersionSpecialPriceId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/${agreementVersionSpecialPriceId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateAgreementVersionSpecialPrice
                    }
                ];
            }

            default: {
                return [];
            }
        }
    
    }

    render() {
        const { classes, isLoadingAgreementVersionSpecialPrice } = this.props;
        const { agreementVersionSpecialPrice, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingAgreementVersionSpecialPrice}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.AgreementVersionSpecialPrice}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    icon: <FontAwesomeIcon icon="hand-holding-usd" size="1x" /> } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.values}>
                            <NullableFormattedDate value={agreementVersionSpecialPrice?.validFromDate} /> - <NullableFormattedDate value={agreementVersionSpecialPrice?.validToDate} />
                        </span>
                    </strong>
                }
                routes={
                    [{
                        name: `${agreementVersionSpecialPrice.agreement?.name} version ${agreementVersionSpecialPrice.agreement?.version?.id}`,
                        url: `${UrlConstants.AGREEMENTS}/${agreementVersionSpecialPrice.agreement?.id}/${agreementVersionSpecialPrice.agreement?.version?.id}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE}/:agreementVersionSpecialPriceId/properties`}>
                            <ViewAgreementVersionSpecialPrice agreementVersionSpecialPrice={agreementVersionSpecialPrice} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AgreementVersionSpecialPriceDetails));