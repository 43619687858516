import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ImportExportCode from '../interfaces/output/importExportCode';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type ImportExportCodeState = {
    importExportCode: ImportExportCode;
    isLoading: boolean;
    importExportCodes: Pagination<ImportExportCode>;
    error: HttpErrorResponse | undefined;
};

const initialState: ImportExportCodeState = {
    importExportCode: {} as ImportExportCode,
    importExportCodes: { content: [] as Array<ImportExportCode> } as Pagination<ImportExportCode>,
    isLoading: false,
    error: undefined
};

export const importExportCodeSlice = createSlice({
    name: 'importExportCode',

    initialState,
    reducers: {
        fetchImportExportCodes: (state: ImportExportCodeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchImportExportCodeSuccess: (state: ImportExportCodeState, action: PayloadAction<Pagination<ImportExportCode>>) => {
            state.isLoading = false;
            state.importExportCodes = action.payload;
        },
        fetchImportExportCodeError: (state: ImportExportCodeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchImportExportCodeById: (state: ImportExportCodeState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchImportExportCodeByIdSuccess: (state: ImportExportCodeState, action: PayloadAction<ImportExportCode>) => {
            state.isLoading = false;
            state.importExportCode = action.payload;
        },
        fetchImportExportCodeByIdError: (state: ImportExportCodeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchImportExportCodes, fetchImportExportCodeSuccess, fetchImportExportCodeError, fetchImportExportCodeById, fetchImportExportCodeByIdSuccess, fetchImportExportCodeByIdError }
= importExportCodeSlice.actions;

export const isLoadingImportExportCodes = (state: RootState): boolean => state.importExportCodeReducer.isLoading;
export const getImportExportCodes = (state: RootState): Pagination<ImportExportCode> => state.importExportCodeReducer.importExportCodes;
export const getImportExportCode = (state: RootState): ImportExportCode => state.importExportCodeReducer.importExportCode;

export default importExportCodeSlice.reducer;