import React from 'react';
import { Theme } from '@mui/material';
import Pagination from '../../interfaces/common/pagination';
import DataGridComponent from '../common/dataGrid';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../utils/tableUtils';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import ProductDynamics from '../../interfaces/output/productDynamics';

const messages = {
    dynamics: LanguageUtils.createMessage('Dynamics'),
    vatCode: LanguageUtils.createMessage('Vat code'),
    activity: LanguageUtils.createMessage('Dynamics activity'),
    account: LanguageUtils.createMessage('Dynamics account'),
    responsibility: LanguageUtils.createMessage('Dynamics responsibility'),
    object: LanguageUtils.createMessage('Dynamics object'),
    spec: LanguageUtils.createMessage('Dynamics spec'),
    trade: LanguageUtils.createMessage('Dynamics trade')
};

interface IPricelistListProps {
    classes?: any;
    theme?: Theme;
    rows?: any;
    productDynamics?: Pagination<ProductDynamics>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    hiddenColumns?: Array<string>;
    applyRowFn?: any;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

export default function ProductDynamicList(props: IPricelistListProps): JSX.Element {
    const { productDynamics, componentsProps, isLoading, checkSelection, onPageChange, hiddenColumns, rows, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;
    const intl = useIntl();
    const { formatMessage } = intl;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const generateColumns = (): GridColDef[] => {
        return [
            {
                field: 'vatCode',
                headerName: formatMessage(messages.vatCode),
                width: 120,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365Vat?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('vatCode', hiddenColumns)
            },
            {
                field: 'd365Account_name',
                headerName: formatMessage(messages.account),
                flex: 1,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365Account?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('account', hiddenColumns)
            },
            {
                field: 'd365Activity_name',
                headerName: formatMessage(messages.activity),
                flex: 1,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365Activity?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('activity', hiddenColumns)
            },
            {
                field: 'd365Object_name',
                headerName: formatMessage(messages.object),
                flex: 1,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365Object?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('object', hiddenColumns)
            },
            {
                field: 'd365ResponsibilityCenter_name',
                headerName: formatMessage(messages.responsibility),
                flex: 1,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365ResponsibilityCenter?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('responsibility', hiddenColumns)
            },
            {
                field: 'd365Spec_name',
                headerName: formatMessage(messages.spec),
                flex: 1,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365Spec?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('spec', hiddenColumns)
            },
            {
                field: 'd365InternalTrade_name',
                headerName: formatMessage(messages.trade),
                flex: 1,
                sortable: true,
                valueGetter: (params: GridValueGetterParams) => applyRowFn(params)?.d365InternalTrade?.name ?? '-',
                hide: hiddenColumns && TableUtils.hideColumn('trade', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows || productDynamics} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
}