import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetDangerousGoodsLineOut from '../interfaces/output/factorSetDangerousGoodsLineOut';
import FactorSetDangerousGoodsLineIn from '../interfaces/input/factorSetDangerousGoodsLineIn';
import DangerousGoodsLineFilters from '../components/factorSets/dangerousGoods/line/dangerousGoodsLineFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class DangerousGoodLineApi {
    static fetchFactorSetDangerousGoodLineById = async(lineId: number): Promise<FactorSetDangerousGoodsLineOut> => {
        const { data } = await http.get<FactorSetDangerousGoodsLineOut>(`factorsetdangerousgoodslines/${lineId}`);

        return data;
    };

    static fetchFactorSetDangerousGoodLines = async(paging: Paging, filters: DangerousGoodsLineFilters): Promise<Pagination<FactorSetDangerousGoodsLineOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, 'factorsetdangerousgoodslines');

        const { data } = await http.get<Pagination<FactorSetDangerousGoodsLineOut>>(url);

        return data;
    };

    static createFactorSetDangerousGoodLine = async(factorSetLine: FactorSetDangerousGoodsLineOut): Promise<FactorSetDangerousGoodsLineOut> => {
        const newFactorSetDangerousGood: FactorSetDangerousGoodsLineIn = {
            name: factorSetLine.name,
            dangerousGoodsTypeId: factorSetLine.dangerousGoodsType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id,
            unitTypeId: factorSetLine.unitType?.id
        };

        const { data } = await http.post<FactorSetDangerousGoodsLineIn, FactorSetDangerousGoodsLineOut>('factorsetdangerousgoodslines', newFactorSetDangerousGood);

        return data;
    }

    static updateFactorSetDangerousGoodLine = async(factorSetLine: FactorSetDangerousGoodsLineOut): Promise<FactorSetDangerousGoodsLineOut> => {
        const newLine: FactorSetDangerousGoodsLineIn = {
            name: factorSetLine.name,
            dangerousGoodsTypeId: factorSetLine.dangerousGoodsType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id,
            unitTypeId: factorSetLine.unitType?.id
        };
        
        const { data } = await http.put<FactorSetDangerousGoodsLineIn, FactorSetDangerousGoodsLineOut>(`factorsetdangerousgoodslines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetDangerousGoodLine = async(lineId: number): Promise<FactorSetDangerousGoodsLineOut> => {
        const { data } = await http.delete<FactorSetDangerousGoodsLineOut>(`factorsetdangerousgoodslines/${lineId}`);

        return data;
    }
}