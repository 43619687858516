import React, { useEffect } from 'react';
import { FormControl, Grid } from '@mui/material';
import { Button } from '@mui/material';
import Seller from '../../interfaces/output/seller';
import Filters from '../../interfaces/output/priceListFilters';
import UnitGroup from '../../interfaces/output/unitGroup';
import Currency from '../../interfaces/output/currency';
import UnitSubGroup from '../../interfaces/output/unitSubGroup';
import PriceListType from '../../interfaces/output/priceListType';
import ProductArena from '../../interfaces/output/productArena';
import ProductType from '../../interfaces/output/productType';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericAutocomplete from './widgets/genericAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../reducers/unitGroupReducer';
import { fetchSellers, getSellers } from '../../reducers/sellerReducer';
import { fetchCurrencies, getCurrencies } from '../../reducers/currencyReducer';
import { fetchPriceListTypes, getPriceListTypes } from '../../reducers/pricelistTypeReducer';
import { fetchProductTypes, getProductTypes } from '../../reducers/productTypesReducer';
import { fetchProductArenas, getProductArenas } from '../../reducers/productArenaReducer';
import PageUtils from '../../utils/pageUtils';
import { FormattedMessage } from 'react-intl';
import GenericTextField from './widgets/genericTextField';
import { fetchPricingComponentTypes, getPricingComponentTypes } from '../../reducers/pricingComponentTypeReducer';
import PricingComponentTypeOut from '../../interfaces/output/pricingComponentTypeOut';
import { ensure } from '../../utils/arrayUtils';
import Action from '../../interfaces/common/action';
import ActionRenderer from './actionRenderer';

interface IFilterProps {
    filters: Filters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
    hideInAgreementVersionPriceList?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('Price list name'),
    seller: LanguageUtils.createMessage('Seller'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    currency: LanguageUtils.createMessage('Currency'),
    priceListType: LanguageUtils.createMessage('Price list type'),
    productArena: LanguageUtils.createMessage('Product arena'),
    productType: LanguageUtils.createMessage('Product type'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    pricingComponentType: LanguageUtils.createMessage('Pricing component type'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function PriceListFilters(props: IFilterProps): JSX.Element {
    const { filters, applyFilters, actions, hideInAgreementVersionPriceList } = props;
    const propsIsLoadingSubGroups = useSelector(isLoadingUnitSubgroups);
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const sellers = useSelector(getSellers).content;
    const priceListTypes = useSelector(getPriceListTypes).content;
    const productArenas = useSelector(getProductArenas).content;
    const productTypes = useSelector(getProductTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const pricingComponents = useSelector(getPricingComponentTypes).content;

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchSellers({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchPriceListTypes({ paging }));
        dispatch(fetchProductTypes({ paging }));
        dispatch(fetchProductArenas({ paging }));
        dispatch(fetchPricingComponentTypes({ paging }));
        if(filters.unitGroupId) {
            dispatch(fetchUnitGroupSubgroups({
                groupId: ensure(filters.unitGroupId),
                paging
            }));
        }
    }, [dispatch]);

    const onChange = (attribute: string, value: any) => {
        if(attribute === 'unitGroupId') {
            if(value !== undefined) {
                dispatch(fetchUnitGroupSubgroups({
                    groupId: value,
                    paging
                }));
            }
            else {
                dispatch(resetSubgroups());
            }
        }
        props.onChange(attribute, value);
    };

    const setDefaultState = () => {
        dispatch(resetSubgroups());

        props.setDefaultState();
    };

    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={filters.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroupId', obj?.id)}
                            placeholder={messages.unitGroup}
                            compareFn={(o: UnitGroup) => o.id === filters.unitGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={filters.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitSubGroupId', obj?.id)}
                            placeholder={messages.subgroup}
                            isLoading={propsIsLoadingSubGroups}
                            compareFn={(o: UnitSubGroup) => o.id === filters.unitSubGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Seller>
                            options={sellers}
                            value={filters.sellerId}
                            onChange={(obj: Seller | null) => onChange('sellerId', obj?.id)}
                            placeholder={messages.seller}
                            compareFn={(o: Seller) => o.id === filters.sellerId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ProductArena>
                            options={productArenas}
                            value={filters.productArenaId}
                            onChange={(obj: ProductArena | null) => onChange('productArenaId', obj?.id)}
                            placeholder={messages.productArena}
                            compareFn={(o: ProductArena) => o.id === filters.productArenaId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <GenericAutocomplete<ProductType>
                            options={productTypes}
                            value={filters.productTypeId}
                            onChange={(obj: ProductType | null) => onChange('productTypeId', obj?.id)}
                            placeholder={messages.productType}
                            compareFn={(o: ProductType) => o.id === filters.productTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<PricingComponentTypeOut>
                            options={pricingComponents}
                            value={filters.pricingComponentTypeId}
                            onChange={(obj: PricingComponentTypeOut | null) => onChange('pricingComponentTypeId', obj?.id)}
                            placeholder={messages.pricingComponentType}
                            compareFn={(o: PricingComponentTypeOut) => o.id === filters.pricingComponentTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<PriceListType>
                            options={priceListTypes}
                            value={filters.typeId}
                            onChange={(obj: PriceListType | null) => onChange('typeId', obj?.id)}
                            placeholder={messages.priceListType}
                            compareFn={(o: PriceListType) => o.id === filters.typeId}
                        />
                    </Grid>
                    {!hideInAgreementVersionPriceList &&
                        <Grid item xs>
                            <GenericAutocomplete<Currency>
                                options={currencies}
                                value={filters.currencyId}
                                onChange={(obj: Currency | null) => onChange('currencyId', obj?.id)}
                                placeholder={messages.currency}
                                compareFn={(o: Currency) => o.id === filters.currencyId}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

PriceListFilters.defaultProps = {
    hideInAgreementVersionPriceList: false
};