import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import ServiceStatus from '../interfaces/output/serviceStatus';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type ServiceStatusState = {
    serviceStatus: ServiceStatus;
    isLoadingServiceStatus: boolean;
    isLoadingServiceStatuses: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    serviceStatuses: Pagination<ServiceStatus>;
    error: HttpErrorResponse | undefined;
};

const initialState: ServiceStatusState = {
    serviceStatus: {} as ServiceStatus,
    serviceStatuses: { content: [] as Array<ServiceStatus> } as Pagination<ServiceStatus>,
    isLoadingServiceStatus: false,
    isLoadingServiceStatuses: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const serviceStatusSlice = createSlice({
    name: 'serviceStatus',

    initialState,
    reducers: {
        fetchServiceStatuses: (state: ServiceStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingServiceStatuses = true;
        },
        fetchServiceStatusesSuccess: (state: ServiceStatusState, action: PayloadAction<Pagination<ServiceStatus>>) => {
            state.isLoadingServiceStatuses = false;
            state.serviceStatuses = action.payload;
        },
        fetchServiceStatusesError: (state: ServiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceStatuses = false;
            state.error = action.payload;
        },
        fetchServiceStatusById: (state: ServiceStatusState, _action: PayloadAction<number>) => {
            state.isLoadingServiceStatus = true;
        },
        fetchServiceStatusByIdSuccess: (state: ServiceStatusState, action: PayloadAction<ServiceStatus>) => {
            state.isLoadingServiceStatus = false;
            state.serviceStatus = action.payload;
        },
        fetchServiceStatusByIdError: (state: ServiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceStatus = false;
            state.error = action.payload;
        },
        createServiceStatus: (state: ServiceStatusState, _action: PayloadAction<ServiceStatus>) => {
            state.isCreating = true;
        },
        createServiceStatusSuccess: (state: ServiceStatusState, action: PayloadAction<ServiceStatus>) => {
            state.isCreating = false;
            state.serviceStatus = action.payload;
        },
        createServiceStatusError: (state: ServiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateServiceStatus: (state: ServiceStatusState, _action: PayloadAction<ServiceStatus>) => {
            state.isUpdating = true;
        },
        updateServiceStatusSuccess: (state: ServiceStatusState, action: PayloadAction<ServiceStatus>) => {
            state.isUpdating = false;
            state.serviceStatus = action.payload;
        },
        updateServiceStatusError: (state: ServiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteServiceStatus: (state: ServiceStatusState, _action: PayloadAction<ServiceStatus>) => {
            state.isDeleted = true;
        },
        deleteServiceStatusSuccess: (state: ServiceStatusState, action: PayloadAction<ServiceStatus>) => {
            state.isDeleted = false;
            state.serviceStatus = action.payload;
        },
        deleteServiceStatusError: (state: ServiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        }
    }
});

export const { fetchServiceStatuses, fetchServiceStatusesSuccess, fetchServiceStatusesError, fetchServiceStatusById, fetchServiceStatusByIdSuccess, fetchServiceStatusByIdError,
    createServiceStatus, createServiceStatusSuccess, createServiceStatusError, updateServiceStatus, updateServiceStatusSuccess, updateServiceStatusError, deleteServiceStatus,
    deleteServiceStatusSuccess, deleteServiceStatusError } = serviceStatusSlice.actions;

export const isLoadingServiceStatus = (state: RootState): boolean => state.serviceStatusReducer.isLoadingServiceStatus;
export const isLoadingServiceStatuses = (state: RootState): boolean => state.serviceStatusReducer.isLoadingServiceStatuses;
export const isUpdating = (state: RootState): boolean => state.serviceStatusReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.serviceStatusReducer.isDeleted;
export const isCreating = (state: RootState): boolean => state.serviceStatusReducer.isCreating;
export const getServiceStatuses = (state: RootState): Pagination<ServiceStatus> => state.serviceStatusReducer.serviceStatuses;
export const getServiceStatus = (state: RootState): ServiceStatus => state.serviceStatusReducer.serviceStatus;

export default serviceStatusSlice.reducer;