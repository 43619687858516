import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import DashboardMessage from '../../interfaces/output/dashboardMessage';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import DashboardMessageTypeFilters from '../../interfaces/filters/dashboardMessageFilters';
import Paging from '../../interfaces/common/paging';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardMessageList from './dashboardMessageList';
import PageUtils from '../../utils/pageUtils';
import {
    exportDashboardMessages, fetchDashboardMessages, getDashboardMessages, isExportingDashboardMessages, isLoadingDashboardMessages,
    resetDashboardMessages
} from '../../reducers/dashboardMessageReducer';
import LanguageUtils from '../../utils/LanguageUtils';
import DashboardMessageFilters from '../../interfaces/filters/dashboardMessageFilters';
import DashboardFilterComponent from './dashboardFilterComponent';

interface IDashboardProps {
    dashboardMessages: Pagination<DashboardMessage>;
    classes: any;
    fetchDashboardMessages: any;
    isLoading: boolean;
    onChange: () => void;
    resetDashboardMessages: () => void;
    exportDashboardMessages: (filters: DashboardMessageFilters) => void;
}

interface IDashboardState {
    filters: DashboardMessageTypeFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const messages = {
    dashboard: LanguageUtils.createMessage('Dashboard'),
    export: LanguageUtils.createMessage('Export')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchDashboardMessages: (paging: Paging, filters: DashboardMessageTypeFilters) => dispatch(fetchDashboardMessages({
        paging,
        filters
    })),
    resetDashboardMessages: () => dispatch(resetDashboardMessages()),
    exportDashboardMessages: (filters: DashboardMessageTypeFilters) => dispatch(exportDashboardMessages({ filters }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        dashboardMessages: getDashboardMessages(store),
        isLoading: isLoadingDashboardMessages(store) || isExportingDashboardMessages(store)
    };
};

class Dashboard extends Component<IDashboardProps, IDashboardState> {
    databaseMessageListRef: any;

    constructor(props: IDashboardProps) {
        super(props);
        this.databaseMessageListRef = React.createRef();
        this.state = {
            filters: {} as DashboardMessageFilters,
            paging: PageUtils.getDefaultPaging([{
                field: 'messageDateTime',
                sort: 'asc'
            } as GridSortItem]),
            menuItem: {
                id: 1,
                name: messages.dashboard,
                icon: <FontAwesomeIcon icon="comments" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
            }
        };
    }

    componentDidMount() {
        const { fetchDashboardMessages } = this.props;
        const { paging, filters } = this.state;

        fetchDashboardMessages(paging, filters);
    }

    componentWillUnmount() {
        const { resetDashboardMessages } = this.props;
        resetDashboardMessages();
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as DashboardMessageTypeFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchDashboardMessages } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({ paging: newPagination });

        fetchDashboardMessages(newPagination, filters);

        if(this.databaseMessageListRef !== null && this.databaseMessageListRef !== undefined &&
            this.databaseMessageListRef.current !== null && this.databaseMessageListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.databaseMessageListRef.current.resetDataGridPage();
        }
    }

    setDefaultState = () => {
        const { fetchDashboardMessages } = this.props;
        const { paging } = this.state;

        const newFilters = {} as DashboardMessageFilters;

        const newSortModel = [{
            field: '',
            sort: '' as GridSortDirection
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchDashboardMessages(newPagination, newFilters);

        if(this.databaseMessageListRef !== null && this.databaseMessageListRef !== undefined &&
            this.databaseMessageListRef.current !== null && this.databaseMessageListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.databaseMessageListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchDashboardMessages } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchDashboardMessages(newPaging, filters);
        }
    }

    render() {
        const { classes, dashboardMessages, fetchDashboardMessages, isLoading, exportDashboardMessages } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <DashboardFilterComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange}
                                    actions={[{
                                        text: messages.export,
                                        icon: 'download',
                                        onClick: () => exportDashboardMessages(filters)
                                    }]} />
                                <DashboardMessageList rows={dashboardMessages} isLoading={isLoading}
                                    ref={this.databaseMessageListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchDashboardMessages(newPage, filters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout >
        );
    }
}
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Dashboard));