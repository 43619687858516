import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import TransitDiscount from '../interfaces/output/transitDiscount';

export type AgreementVersionTransitDiscountState = {
    agreementVersionTransitDiscount: TransitDiscount;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: AgreementVersionTransitDiscountState = {
    agreementVersionTransitDiscount: {} as TransitDiscount,
    isLoading: false,
    isUpdating: false,
    isDeleting: false,
    error: undefined
};

export const agreementVersionTransitDiscountSlice = createSlice({
    name: 'agreementVersionTransitDiscount',

    initialState,
    reducers: {
        fetchAgreementVersionTransitDiscountById: (state: AgreementVersionTransitDiscountState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchAgreementVersionTransitDiscountByIdSuccess: (state: AgreementVersionTransitDiscountState, action: PayloadAction<TransitDiscount>) => {
            state.isLoading = false;
            state.agreementVersionTransitDiscount = action.payload;
        },
        fetchAgreementVersionTransitDiscountByIdError: (state: AgreementVersionTransitDiscountState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateAgreementVersionTransitDiscountById: (state: AgreementVersionTransitDiscountState, _action: PayloadAction<TransitDiscount>) => {
            state.isUpdating = true;
        },
        updateAgreementVersionTransitDiscountByIdSuccess: (state: AgreementVersionTransitDiscountState, action: PayloadAction<TransitDiscount>) => {
            state.isUpdating = false;
            state.agreementVersionTransitDiscount = action.payload;
        },
        updateAgreementVersionTransitDiscountByIdError: (state: AgreementVersionTransitDiscountState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateAgreementVersionTransitDiscountById: (state: AgreementVersionTransitDiscountState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateAgreementVersionTransitDiscountByIdSuccess: (state: AgreementVersionTransitDiscountState, action: PayloadAction<TransitDiscount>) => {
            state.isDeleting = false;
            state.agreementVersionTransitDiscount = action.payload;
        },
        deactivateAgreementVersionTransitDiscountByIdError: (state: AgreementVersionTransitDiscountState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        }
    }
});

export const { fetchAgreementVersionTransitDiscountById, fetchAgreementVersionTransitDiscountByIdSuccess, fetchAgreementVersionTransitDiscountByIdError,
    updateAgreementVersionTransitDiscountByIdSuccess, updateAgreementVersionTransitDiscountByIdError, deactivateAgreementVersionTransitDiscountById,
    deactivateAgreementVersionTransitDiscountByIdSuccess, deactivateAgreementVersionTransitDiscountByIdError, updateAgreementVersionTransitDiscountById }
    = agreementVersionTransitDiscountSlice.actions;

export const isLoadingAgreementVersionTransitDiscount = (state: RootState): boolean => state.agreementVersionTransitDiscountReducer.isLoading;
export const isUpdatingAgreementTransitDiscount = (state: RootState): boolean => state.agreementVersionTransitDiscountReducer.isUpdating;
export const isDeletingAgreementTransitDiscount = (state: RootState): boolean => state.agreementVersionTransitDiscountReducer.isDeleting;
export const getAgreementVersionTransitDiscount = (state: RootState): TransitDiscount => state.agreementVersionTransitDiscountReducer.agreementVersionTransitDiscount;

export default agreementVersionTransitDiscountSlice.reducer;