import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetType from '../interfaces/output/factorSetType';
import DepartureFilters from '../components/factorSets/departure/departureFilter';

export type FactorSetCalendarState = {
    factorSetCalendar: FactorSetOut;
    factorSetCalendars: Pagination<FactorSetOut>;
    isLoadingFactorSetCalendar: boolean;
    isLoadingFactorSetCalendars: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetCalendarState = {
    factorSetCalendar: {} as FactorSetOut,
    factorSetCalendars: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    isLoadingFactorSetCalendar: false,
    isLoadingFactorSetCalendars: false,
    isUpdating: false,
    isRemoving: false,
    isDeleted: false,
    isCreating: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetCalendarSlice = createSlice({
    name: 'factorSetCalendar',

    initialState,
    reducers: {
        fetchFactorSetCalendars: (state: FactorSetCalendarState, action: PayloadAction<{paging: Paging, filters?: DepartureFilters}>) => {
            state.isLoadingFactorSetCalendars = true;
        },
        fetchFactorSetCalendarsSuccess: (state: FactorSetCalendarState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingFactorSetCalendars = false;
            state.factorSetCalendars = action.payload;
        },
        fetchFactorSetCalendarsError: (state: FactorSetCalendarState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetCalendars = false;
            state.error = action.payload;
        },
        fetchFactorSetCalendarById: (state: FactorSetCalendarState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetCalendar = true;
        },
        fetchFactorSetCalendarByIdSuccess: (state: FactorSetCalendarState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetCalendar = false;
            state.factorSetCalendar = action.payload;
        },
        fetchFactorSetCalendarByIdError: (state: FactorSetCalendarState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetCalendar = false;
            state.error = action.payload;
        },
        createFactorSetCalendar: (state: FactorSetCalendarState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetCalendarSuccess: (state: FactorSetCalendarState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetCalendar = action.payload;
        },
        createFactorSetCalendarError: (state: FactorSetCalendarState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createCalendarVersion: (state: FactorSetCalendarState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createCalendarVersionSuccess: (state: FactorSetCalendarState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetCalendar = action.payload;
        },
        createCalendarVersionError: (state: FactorSetCalendarState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateFactorSetCalendar: (state: FactorSetCalendarState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetCalendarSuccess: (state: FactorSetCalendarState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetCalendar = action.payload;
        },
        updateFactorSetCalendarError: (state: FactorSetCalendarState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetCalendar: (state: FactorSetCalendarState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateFactorSetCalendarSuccess: (state: FactorSetCalendarState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleted = false;
            state.factorSetCalendar = action.payload;
        },
        deactivateFactorSetCalendarError: (state: FactorSetCalendarState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetFactorSetCalendars: (state: FactorSetCalendarState) => {
            state.factorSetCalendars = initialState.factorSetCalendars;
        },
        resetFactorSetCalendar: (state: FactorSetCalendarState) => {
            state.factorSetCalendar = initialState.factorSetCalendar;
        }
    }
});

export const {
    fetchFactorSetCalendarById, fetchFactorSetCalendarByIdSuccess, fetchFactorSetCalendarByIdError, fetchFactorSetCalendars,
    fetchFactorSetCalendarsError, fetchFactorSetCalendarsSuccess,
    createFactorSetCalendar, createFactorSetCalendarError, createFactorSetCalendarSuccess, updateFactorSetCalendar,
    updateFactorSetCalendarError, updateFactorSetCalendarSuccess,
    deactivateFactorSetCalendar, deactivateFactorSetCalendarError, deactivateFactorSetCalendarSuccess, resetFactorSetCalendars, createCalendarVersion, createCalendarVersionSuccess,
    createCalendarVersionError, resetFactorSetCalendar }
    = factorSetCalendarSlice.actions;

export const isLoadingFactorSetCalendar = (state: RootState): boolean => state.factorSetCalendarReducer.isLoadingFactorSetCalendar;
export const isLoadingFactorSetCalendars = (state: RootState): boolean => state.factorSetCalendarReducer.isLoadingFactorSetCalendars;
export const isUpdatingCalendar = (state: RootState): boolean => state.factorSetCalendarReducer.isUpdating;
export const isDeletingCalendar = (state: RootState): boolean => state.factorSetCalendarReducer.isDeleted;
export const isCreatingCalendar = (state: RootState): boolean => state.factorSetCalendarReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetCalendarReducer.isCreatingVersion;
export const getFactorSetCalendars = (state: RootState): Pagination<FactorSetOut> => state.factorSetCalendarReducer.factorSetCalendars;
export const getFactorSetCalendar = (state: RootState): FactorSetOut => state.factorSetCalendarReducer.factorSetCalendar;

export default factorSetCalendarSlice.reducer;