import { http } from '../utils/http';
import Surcharge from '../interfaces/output/orderUnitServiceProductSurchargeOut';
import SurchargeIn from '../interfaces/input/orderUnitServiceProductSurchargeIn';

export default class SurchargeApi {
    static fetchSurcharge = async(unitServiceProductSurchargeId: string): Promise<Surcharge> => {
        const { data } = await http.get<Surcharge>(`orderunitserviceproductsurcharge/${unitServiceProductSurchargeId}`);

        return data;
    };

    static createSurcharge = async(unitServiceProductSurcharge: Surcharge): Promise<Surcharge> => {
        const newSurcharge: SurchargeIn = {
            priceListVersionId: unitServiceProductSurcharge?.pricelistVersion?.id,
            orderUnitServiceProductId: unitServiceProductSurcharge?.orderUnitServiceProduct?.id,
            surchargeAmount: unitServiceProductSurcharge?.surchargeAmount,
            pricingComponentTypeId: unitServiceProductSurcharge?.pricingComponentType?.id
        };
        
        const { data } = await http.post<SurchargeIn, Surcharge>('orderunitserviceproductsurcharge', newSurcharge);

        return data;
    }

    static updateSurcharge = async(unitServiceProductSurcharge: Surcharge): Promise<Surcharge> => {
        const newSurcharge: SurchargeIn = {
            priceListVersionId: unitServiceProductSurcharge?.pricelistVersion?.id,
            orderUnitServiceProductId: unitServiceProductSurcharge?.orderUnitServiceProduct?.id,
            surchargeAmount: unitServiceProductSurcharge?.surchargeAmount,
            pricingComponentTypeId: unitServiceProductSurcharge?.pricingComponentType?.id
        };
        
        const { data } = await http.put<SurchargeIn, Surcharge>(`orderunitserviceproductsurcharge/${unitServiceProductSurcharge.id}`, newSurcharge);

        return data;
    }
    static deactivateSurcharge = async(unitServiceProductSurchargeId: number): Promise<Surcharge> => {
        const { data } = await http.delete<Surcharge>(`orderunitserviceproductsurcharge/${unitServiceProductSurchargeId}`);

        return data;
    }
}
