import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import AgreementVersionPricelist from '../interfaces/output/agreementVersionPricelist';
import AgreementVersionPriceListsFilters from '../interfaces/filters/agreementVersionPriceListsFilters';
import qs from 'qs';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class AgreementVersionPricelistApi {
    static fetchAgreementVersionPricelistById = async(agreementVersionPriceListId: number): Promise<AgreementVersionPricelist> => {
        const { data } = await http.get<AgreementVersionPricelist>(`/agreementversionpricelists/${agreementVersionPriceListId}`);

        return data;
    };

    static fetchAgreementVersionPricelists = async(paging: Paging, filters: AgreementVersionPriceListsFilters): Promise<Array<AgreementVersionPricelist>> => {

        const newpricelistFilters = qs.stringify(filters, { allowDots: true });

        const newsort = SortFormatterUtils.sortForServer(paging.sort);
        const newPaging = {
            ...paging,
            sort: ''
        };
        const sortUrl = urlcat('', '', {
            ...newPaging
        });

        const url = `/agreementversionpricelists?${newpricelistFilters}&${sortUrl}${newsort}`;

        const { data } = await http.get<Array<AgreementVersionPricelist>>(url);

        return data;
    };

    static promoteAgreementVersionPricelist = async(agreementVersionPriceListId: string): Promise<AgreementVersionPricelist> => {
        const { data } = await http.put<any, AgreementVersionPricelist>(`/agreementversionpricelists/${agreementVersionPriceListId}/promote`);

        return data;
    };

    static demoteAgreementVersionPricelist = async(agreementVersionPriceListId: string): Promise<AgreementVersionPricelist> => {
        const { data } = await http.put<any, AgreementVersionPricelist>(`/agreementversionpricelists/${agreementVersionPriceListId}/demote`);

        return data;
    };

    static deactivateAgreementVersionPricelist = async(agreementVersionPriceListId: string): Promise<AgreementVersionPricelist> => {
        const { data } = await http.delete<AgreementVersionPricelist>(`/agreementversionpricelists/${agreementVersionPriceListId}`);

        return data;
    };

    static createAgreementVersionPricelist = async(agreementVersionPriceList: AgreementVersionPricelist): Promise<AgreementVersionPricelist> => {
        const newAgreementVersionPricelist = {
            discount: agreementVersionPriceList?.discount,
            priorityRank: agreementVersionPriceList?.priorityRank,
            agreementVersionId: agreementVersionPriceList?.agreement?.version?.id,
            pricelistId: agreementVersionPriceList?.pricelist?.id
        };

        const { data } = await http.post<any, AgreementVersionPricelist>('agreementversionpricelists', newAgreementVersionPricelist);

        return data;
    }

    static updateAgreementVersionPricelist = async(agreementVersionPriceList: AgreementVersionPricelist): Promise<AgreementVersionPricelist> => {
        const newAgreementVersionPricelist = {
            discount: agreementVersionPriceList?.discount,
            priorityRank: agreementVersionPriceList?.priorityRank,
            agreementVersionId: agreementVersionPriceList?.agreement?.version?.id,
            pricelistId: agreementVersionPriceList?.pricelist?.id
        };

        const { data } = await http.put<any, AgreementVersionPricelist>(`agreementversionpricelists/${agreementVersionPriceList.id}`, newAgreementVersionPricelist);

        return data;
    }
}
