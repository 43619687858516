import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import AddAgreementVersionDiscountForm from './addAgreementVersionDiscountForm';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetAgreementVersionTransitDiscount } from '../../../reducers/transitDiscountReducer';

interface IAddAgreementProps {
    classes: any;
    theme: Theme;
    match: any;
    resetAgreementVersionTransitDiscount: any;
    steps: Array<number>;
    agreementId?: number;
    agreementVersionId?: number;
    discountId?: number;
    agreementVersionTransitDiscountId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetAgreementVersionTransitDiscount: () => dispatch(resetAgreementVersionTransitDiscount())
});

const mapStoreToProps = (store: RootState, ownProps: IAddAgreementProps) => {
    return {
        agreementVersionId: ownProps.match.params?.agreementVersionId ? Number(ownProps.match.params?.agreementVersionId) : undefined,
        agreementId: ownProps.match.params?.agreementId ? Number(ownProps.match.params?.agreementId) : undefined,
        discountId: ownProps.match.params?.discountId ? Number(ownProps.match.params?.discountId) :
            ownProps.match.params?.agreementVersionTransitDiscountId ? Number(ownProps.match.params?.agreementVersionTransitDiscountId) : undefined
    };
};

class AddAgreement extends Component<IAddAgreementProps> {
    componentWillUnmount() {
        const { resetAgreementVersionTransitDiscount } = this.props;

        resetAgreementVersionTransitDiscount();
    }

    render() {
        const { classes, steps, agreementId, agreementVersionId, discountId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddAgreementVersionDiscountForm agreementId={agreementId} agreementVersionId={agreementVersionId} discountId={discountId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddAgreement));