import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../../constants/constants';
import { resetFactorSetCancellations } from '../../../../reducers/factorSetCancellationReducer';
import FactorSetCancellationForm from './factorSetCancellationForm';

interface IAddFactorSetCancellationProps {
    classes: any;
    theme: Theme;
    match: any;
    resetFactorSetCancellations: any;
    steps: Array<number>;
    factorSetCancellationId?: number;
    factorSetVersionId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetFactorSetCancellations: () => dispatch(resetFactorSetCancellations())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetCancellationProps) => {
    return {
        factorSetCancellationId: ownProps.match.params?.cancellationId ? Number(ownProps.match.params?.cancellationId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetCancellation extends Component<IAddFactorSetCancellationProps> {
    componentWillUnmount() {
        const { resetFactorSetCancellations } = this.props;

        resetFactorSetCancellations();
    }

    render() {
        const { classes, steps, factorSetCancellationId, type, factorSetVersionId } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <FactorSetCancellationForm factorSetCancellationId={factorSetCancellationId} steps={steps} type={type} factorSetVersionId={factorSetVersionId} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetCancellation));