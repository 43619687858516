/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import Pagination from '../../../interfaces/common/pagination';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import Loader from '../../common/widgets/loader';
import FactorsVersionsList from '../factorsVersionList';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import FactorSetDangerousGoodsLineOut from '../../../interfaces/output/factorSetDangerousGoodsLineOut';
import DangerousGoodsGroup from '../../../interfaces/output/dangerousGoodsGroup';
import {
    deactivateFactorSetDangerousGoods, fetchFactorSetDangerousGoodsById, getFactorSetDangerousGood, isDeletingDangerousGoods,
    isLoadingFactorSetDangerousGood, isLoadingFactorSetDangerousGoods, resetFactorSetDangerousGood, resetFactorSetDangerousGoods
} from '../../../reducers/factorSetDangerousGoodsReducer';
import {
    deactivateFactorSetDangerousGoodLine, fetchDangerousGoodVersionLines, getDangerousGoodLines, isCreatingDangerousGoodLine, isDeletingDangerousGoodLine,
    isLoadingFactorSetDangerousGoodLines, isUpdatingDangerousGoodLine, resetDangerousGoodVersionLines
} from '../../../reducers/dangerousGoodsLineReducer';
import DangerousGoodsLineFilters from './line/dangerousGoodsLineFilters';
import ViewFactorSetDangerousGoods from './viewFactorSetDangerousGoods';
import ViewFactorSetDangerousGoodsLines from './line/dangerousGoodsLineList';
import { fetchDangerousGoodVersions, getFactorSetDangerousGoodVersions, isLoadingFactorSetDangerousGoodVersions } from '../../../reducers/factorSetDangerousGoodsVersionReducer';
import { fetchDangerousGoodsGroups, getDangerousGoodsGroups } from '../../../reducers/dangerousGoodsGroupReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../reducers/unitTypeReducer';
import UnitType from '../../../interfaces/output/unitType';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IFactorSetDangerousGoodDetailsProps {
    classes: any;
    theme: Theme;
    fetchFactorSetDangerousGoodById: any;
    fetchDangerousGoodVersionLines: any;
    deactivateFactorSetDangerousGood: any;
    deactivateFactorSetDangerousGoodLine: any;
    fetchFactorSetDangerousGoodTypes: any;
    fetchDangerousGoodVersions: any;
    resetFactorSetDangerousGood: () => any;
    resetDangerousGoodVersionLines: () => any;
    resetFactorSetDangerousGoods: () => any;
    factorSetDangerousGood: FactorSetOut;
    match: any;
    dangerousGoodLines: Pagination<FactorSetDangerousGoodsLineOut>;
    factorVersions: Pagination<FactorSetVersionOut>;
    dangerousGoodTypes: Pagination<DangerousGoodsGroup>;
    history: any;
    location: any;
    isUpdatingDangerousGood: boolean;
    isDeletingDangerousGood: boolean;
    isDeletingLine: boolean;
    isCreatingDangerousGood: boolean;
    isLoading: boolean;
    isLoadingFactorSetDangerousGood: boolean;
    isLoadingFactorSetDangerousGoodLines: boolean;
    isLoadingFactorSetVersions: boolean;
    fetchUnitTypes: any;
    isLoadingUnitTypes: boolean;
    unitTypes: Pagination<UnitType>;
}

interface IFactorSetDangerousGoodDetailsState {
    open: boolean;
    factorSetDangerousGood: FactorSetOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverPaginationLines: Paging;
    sortModelDGLines: GridSortModel;
    sortModelDGVersions: GridSortModel;
    factorSetDGColumnVisibilityMode: GridColumnVisibilityModel;
    factorsVersionLinesColumnVisibilityMode: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetDangerousGoodById: (id: number, versionId: number) => dispatch(fetchFactorSetDangerousGoodsById({
        factorSetId: id,
        versionId
    })),
    fetchDangerousGoodVersionLines: (paging: Paging, filters: DangerousGoodsLineFilters) => dispatch(fetchDangerousGoodVersionLines({
        paging,
        filters
    })),
    fetchDangerousGoodVersions: (id: number, paging: Paging) => dispatch(fetchDangerousGoodVersions({
        id,
        paging
    })),
    deactivateFactorSetDangerousGood: (id: number) => dispatch(deactivateFactorSetDangerousGoods(id)),
    deactivateFactorSetDangerousGoodLine: (id: number) => dispatch(deactivateFactorSetDangerousGoodLine(id)),
    fetchFactorSetDangerousGoodTypes: (paging: Paging) => dispatch(fetchDangerousGoodsGroups({ paging })),
    resetFactorSetDangerousGood: () => dispatch(resetFactorSetDangerousGood()),
    resetFactorSetDangerousGoods: () => dispatch(resetFactorSetDangerousGoods()),
    resetDangerousGoodVersionLines: () => dispatch(resetDangerousGoodVersionLines()),
    fetchUnitTypes: (paging: Paging) => dispatch(fetchUnitTypes({ paging }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetDangerousGood: getFactorSetDangerousGood(store),
        dangerousGoodLines: getDangerousGoodLines(store),
        factorVersions: getFactorSetDangerousGoodVersions(store),
        dangerousGoodTypes: getDangerousGoodsGroups(store),
        unitTypes: getUnitTypes(store),
        isUpdatingDangerousGood: isUpdatingDangerousGoodLine(store),
        isDeletingLine: isDeletingDangerousGoodLine(store),
        isDeletingDangerousGood: isDeletingDangerousGoods(store),
        isCreatingDangerousGood: isCreatingDangerousGoodLine(store),
        isLoadingFactorSetDangerousGoodLines: isLoadingFactorSetDangerousGoodLines(store),
        isLoadingFactorSetVersions: isLoadingFactorSetDangerousGoodVersions(store),
        isLoadingFactorSetDangerousGood: isLoadingFactorSetDangerousGood(store),
        isLoadingUnitTypes: isLoadingUnitTypes(store),
        isLoading: isLoadingFactorSetDangerousGoods(store) || isUpdatingDangerousGoodLine(store) || isDeletingDangerousGoodLine(store) || isCreatingDangerousGoodLine(store) ||
            isLoadingFactorSetDangerousGoodLines(store) || isLoadingUnitTypes(store)

    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('FACTOR_SET_DANGEROUS_GOODS'),
    properties: LanguageUtils.createMessage('Properties'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    versions: LanguageUtils.createMessage('Versions'),
    factors: LanguageUtils.createMessage('lines')
};

class FactorSetDangerousGoodDetails extends Component<IFactorSetDangerousGoodDetailsProps, IFactorSetDangerousGoodDetailsState> {
    constructor(props: IFactorSetDangerousGoodDetailsProps) {
        super(props);

        this.state = {
            open: true,
            factorSetDangerousGood: {} as FactorSetOut,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            serverPaginationLines: PageUtils.getDefaultPaging(),
            sortModelDGLines: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelDGVersions: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            factorSetDGColumnVisibilityMode: {},
            factorsVersionLinesColumnVisibilityMode: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetDangerousGoodById } = this.props;
        const { dangerousGoodId, factorSetVersionId } = this.props.match.params;
        const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;

        fetchFactorSetDangerousGoodById(dangerousGoodId, versionId);
    }

    componentWillUnmount() {
        const { resetFactorSetDangerousGood, resetFactorSetDangerousGoods, resetDangerousGoodVersionLines } = this.props;

        resetFactorSetDangerousGood();
        resetFactorSetDangerousGoods();
        resetDangerousGoodVersionLines();
    }

    componentDidUpdate(prevProps: IFactorSetDangerousGoodDetailsProps) {
        const { factorSetDangerousGood, isUpdatingDangerousGood, isDeletingLine, isCreatingDangerousGood, isLoadingFactorSetDangerousGood, isDeletingDangerousGood, history,
            fetchFactorSetDangerousGoodById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { dangerousGoodId, factorSetVersionId } = this.props.match.params;

        if(factorSetDangerousGood !== prevProps.factorSetDangerousGood) {
            this.setState({ factorSetDangerousGood });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.factorSetVersionId !== factorSetVersionId) {
                const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;
                fetchFactorSetDangerousGoodById(dangerousGoodId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingFactorSetDangerousGood && prevProps.isLoadingFactorSetDangerousGood ||
            !isUpdatingDangerousGood && prevProps.isUpdatingDangerousGood ||
            !isDeletingLine && prevProps.isDeletingLine ||
            !isCreatingDangerousGood && prevProps.isCreatingDangerousGood)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingDangerousGood && prevProps.isDeletingDangerousGood) {
            history.push(`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}`);
        }
    }

    isHiddenCategory = (): boolean => {
        const { factorSetDangerousGood } = this.state;

        return factorSetDangerousGood.version?.id ? false : true;
    }

    configureDrawer = (props: IFactorSetDangerousGoodDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${props.match.params.dangerousGoodId}/${props.match.params.factorSetVersionId}`;

        return ImmutableList([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/properties`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.versions,
            additionalMargin: true,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.factors,
            icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
            url: `${baseUrl}/lines`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/lines`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchDangerousGoodVersionLines, factorSetDangerousGood, fetchDangerousGoodVersions, fetchFactorSetDangerousGoodTypes, fetchUnitTypes } = this.props;
        const { dangerousGoodId } = this.props.match.params;
        const { serverPagination, serverPaginationLines } = this.state;

        const lineFilters = {
            name: undefined,
            dangerousGoodTypeId: null,
            factorSetVersionId: factorSetDangerousGood.version?.id
        } as DangerousGoodsLineFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverPagination,
            page: newPaging.page
        };

        const newPaginationLines = {
            ...serverPaginationLines,
            page: newPaging.page
        };

        if(selectedMenuItem?.id === 2) {
            fetchDangerousGoodVersions(dangerousGoodId, newPagination);
        }

        if(selectedMenuItem?.id === 3 && factorSetDangerousGood.version?.id) {
            fetchDangerousGoodVersionLines(newPaginationLines, lineFilters);
            fetchFactorSetDangerousGoodTypes(newPagination);
            fetchUnitTypes(newPagination);
        }
    }

    getSelectedMenuItem = (state: IFactorSetDangerousGoodDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateFactorSet = () => {
        const { factorSetDangerousGood } = this.state;
        const { deactivateFactorSetDangerousGood } = this.props;

        deactivateFactorSetDangerousGood(factorSetDangerousGood.id);
    }

    deactivateFactorSetLine = () => {
        const { selectedRow } = this.state;
        const { deactivateFactorSetDangerousGoodLine } = this.props;

        deactivateFactorSetDangerousGoodLine(selectedRow);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow, factorSetDangerousGood } = this.state;
        const { dangerousGoodLines, dangerousGoodTypes, unitTypes } = this.props;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { dangerousGoodId } = this.props.match.params;

        const filteredTypes = dangerousGoodTypes.content.filter(dt => (!dangerousGoodLines.content.find(dl => dl.dangerousGoodsType?.id === dt.id && !dl.unitType))
            || unitTypes.content.find(ut => !dangerousGoodLines.content.find(dl => dl.dangerousGoodsType?.id === dt.id && dl.unitType?.id === ut?.id)));

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: factorSetDangerousGood.version?.id ?
                            `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/${factorSetDangerousGood.version?.id}/edit` :
                            `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateFactorSet
                    }
                ];
            }

            case 2: { //dangerousGood versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 3: { //lines
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/version/${factorSetDangerousGood.version?.id}/add/line`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !factorSetDangerousGood.version?.id || filteredTypes.length === 0
                        }
                    ];
                }

                if(factorSetDangerousGood.version) {
                    return [
                        {
                            href:
                                // eslint-disable-next-line
                                `${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/${dangerousGoodId}/version/${factorSetDangerousGood.version?.id}/dangerousgoodsLine/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateFactorSetLine
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }

    _onSortChangDGLines = (newModel: GridSortModel) => {
        const { fetchDangerousGoodVersionLines } = this.props;
        const { sortModelDGLines, serverPaginationLines, factorSetDangerousGood } = this.state;

        const lineFilters = {
            name: undefined,
            dangerousGoodTypeId: null,
            factorSetVersionId: factorSetDangerousGood.version?.id
        } as DangerousGoodsLineFilters;

        if(JSON.stringify(sortModelDGLines) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationLines,
                sort: newModel
            };
            this.setState({
                sortModelDGLines: newModel,
                serverPaginationLines: newPagination
            });

            fetchDangerousGoodVersionLines(newPagination, lineFilters);
        }
    }

    _onSortChangeDGVersions = (newModel: GridSortModel) => {
        const { fetchDangerousGoodVersions } = this.props;
        const { factorSetDangerousGood, serverPagination, serverPaginationLines, sortModelDGVersions } = this.state;

        if(JSON.stringify(sortModelDGVersions) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelDGVersions: newModel,
                serverPagination: newPagination
            });

            fetchDangerousGoodVersions(factorSetDangerousGood.id, newPagination);
        }
    }

    render() {
        const { classes, isLoadingFactorSetDangerousGood, isLoadingFactorSetDangerousGoodLines, dangerousGoodLines, isDeletingLine, isDeletingDangerousGood, isUpdatingDangerousGood,
            isLoadingFactorSetVersions, factorVersions, fetchDangerousGoodVersions, fetchDangerousGoodVersionLines } = this.props;
        const { factorSetDangerousGood, menuItems, serverPagination, serverPaginationLines, factorSetDGColumnVisibilityMode, factorsVersionLinesColumnVisibilityMode } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingFactorSetDangerousGood || isDeletingDangerousGood}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.FactorSets}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: factorSetDangerousGood.name,
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                            <span className={classes.values}> {factorSetDangerousGood?.version?.versionNumber ?? '-'} </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                            {factorSetDangerousGood?.version?.validFromDate || factorSetDangerousGood?.version?.validToDate ? (
                                <span className={classes.values}>
                                    <NullableFormattedDate value={factorSetDangerousGood?.version?.validFromDate} /> -
                                    {factorSetDangerousGood?.version?.validToDate &&
                                        <> <NullableFormattedDate value={factorSetDangerousGood?.version?.validToDate} /></>}
                                </span>
                            )
                                : '-'} </span>
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/:dangerousGoodId/:factorSetVersionId/properties`}>
                            <Loader isLoading={isUpdatingDangerousGood || isDeletingDangerousGood}>
                                <ViewFactorSetDangerousGoods factorSetDangerousGoods={factorSetDangerousGood} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/:dangerousGoodId/:factorSetVersionId/lines`}>
                            <ViewFactorSetDangerousGoodsLines isLoading={isLoadingFactorSetDangerousGoodLines || isDeletingLine}
                                rows={dangerousGoodLines}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationLines,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const lineFilters = {
                                        name: undefined,
                                        dangerousGoodTypeId: null,
                                        factorSetVersionId: factorSetDangerousGood.version?.id
                                    } as DangerousGoodsLineFilters;

                                    this.setState({ serverPaginationLines: newPage });
                                    fetchDangerousGoodVersionLines(newPage, lineFilters);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangDGLines(sortModel)}
                                columnVisibilityModel={factorSetDGColumnVisibilityMode}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorSetDGColumnVisibilityMode: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DANGEROUS_GOODS}/:dangerousGoodId/:factorSetVersionId/versions`}>
                            <FactorsVersionsList
                                type={factorSetDangerousGood.type?.code}
                                isLoading={isLoadingFactorSetVersions}
                                rows={factorVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchDangerousGoodVersions(factorSetDangerousGood.id, newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeDGVersions(sortModel)}
                                columnVisibilityModel={factorsVersionLinesColumnVisibilityMode}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorsVersionLinesColumnVisibilityMode: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetDangerousGoodDetails));