import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetHoursApi from '../api/factorSetHoursApi';
import { createFactorSetHourError, createFactorSetHourSuccess, createHoursVersionError, createHoursVersionSuccess, deactivateFactorSetHourError, deactivateFactorSetHourSuccess,
    fetchFactorSetHourByIdError, fetchFactorSetHourByIdSuccess, fetchFactorSetHoursError, fetchFactorSetHoursSuccess,
    updateFactorSetHourError, updateFactorSetHourSuccess } from '../reducers/factorSetHoursReducer';
import HoursFilter from '../components/factorSets/hours/hoursFilter';

function* doFetchFactorSetHour(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {
    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetHoursApi.fetchFactorSetHourById, factorSetId, versionId);
    
        yield put(fetchFactorSetHourByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetHourByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetHours(action: PayloadAction<{ paging: Paging, filters: HoursFilter}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetHoursApi.fetchFactorSetHours, paging, filters);

        yield put(fetchFactorSetHoursSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetHoursError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetHour(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetHoursApi.createFactorSetHour, factorSet);

        yield put(createFactorSetHourSuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetHourError(error as HttpErrorResponse));
    }
}

function* doCreateHoursVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetHoursApi.createHoursVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createHoursVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createHoursVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetHour(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetHoursApi.updateFactorSetHour, factorSet);

        yield put(updateFactorSetHourSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetHourError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetHour(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetHoursApi.deactivateFactorSetHour, id);

        yield put(deactivateFactorSetHourSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetHourError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetHours/fetchFactorSetHourById', doFetchFactorSetHour),
    takeLatest('factorSetHours/fetchFactorSetHours', doFetchFactorSetHours),
    takeLatest('factorSetHours/createFactorSetHour', doCreateFactorSetHour),
    takeLatest('factorSetHours/createHoursVersion', doCreateHoursVersion),
    takeLatest('factorSetHours/updateFactorSetHour', doUpdateFactorSetHour),
    takeLatest('factorSetHours/deactivateFactorSetHour', doDeactivateFactorSetHour)
];