import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerCorporate from '../interfaces/output/customerCorporate';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CustomerCorporateState = {
    corporate: CustomerCorporate;
    isLoading: boolean;
    isCreating: boolean;
    isLoadingCorporate: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    corporates: Pagination<CustomerCorporate>;
    error: HttpErrorResponse | undefined;
};

const initialState: CustomerCorporateState = {
    corporate: {} as CustomerCorporate,
    corporates: { content: [] as Array<CustomerCorporate> } as Pagination<CustomerCorporate>,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isLoadingCorporate: false,
    error: undefined
};

export const customerCorporateSlice = createSlice({
    name: 'customerCorporate',

    initialState,
    reducers: {
        fetchCorporates: (state: CustomerCorporateState, _action: PayloadAction<{paging: Paging, searchTerm?: string}>) => {
            state.isLoading = true;
        },
        fetchCorporatesSuccess: (state: CustomerCorporateState, action: PayloadAction<Pagination<CustomerCorporate>>) => {
            state.isLoading = false;
            state.corporates = action.payload;
        },
        fetchCorporatesError: (state: CustomerCorporateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCustomerCorporateById: (state: CustomerCorporateState, _action: PayloadAction<number>) => {
            state.isLoadingCorporate = true;
        },
        fetchCustomerCorporateByIdSuccess: (state: CustomerCorporateState, action: PayloadAction<CustomerCorporate>) => {
            state.isLoadingCorporate = false;
            state.corporate = action.payload;
        },
        fetchCustomerCorporateByIdError: (state: CustomerCorporateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCorporate = false;
            state.error = action.payload;
        },
        createCorporate: (state: CustomerCorporateState, _action: PayloadAction<CustomerCorporate>) => {
            state.isCreating = true;
        },
        createCorporateSuccess: (state: CustomerCorporateState, action: PayloadAction<CustomerCorporate>) => {
            state.isCreating = false;
            state.corporate = action.payload;
        },
        createCorporateError: (state: CustomerCorporateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateCorporate: (state: CustomerCorporateState, _action: PayloadAction<CustomerCorporate>) => {
            state.isUpdating = true;
        },
        updateCorporateSuccess: (state: CustomerCorporateState, action: PayloadAction<CustomerCorporate>) => {
            state.isUpdating = false;
            state.corporate = action.payload;
        },
        updateCorporateError: (state: CustomerCorporateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteCorporate: (state: CustomerCorporateState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deleteCorporateSuccess: (state: CustomerCorporateState, action: PayloadAction<CustomerCorporate>) => {
            state.isDeleting = false;
            state.corporate = action.payload;
        },
        deleteCorporateError: (state: CustomerCorporateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetCorporates: (state: CustomerCorporateState) => {
            state.corporates = initialState.corporates;
        },
        resetCorporate: (state: CustomerCorporateState) => {
            state.corporate = initialState.corporate;
        }
    }
});

export const { fetchCorporates, fetchCorporatesSuccess, fetchCorporatesError, fetchCustomerCorporateById, createCorporate, createCorporateSuccess, createCorporateError,
    fetchCustomerCorporateByIdSuccess, fetchCustomerCorporateByIdError, resetCorporates, resetCorporate, updateCorporate, updateCorporateError,
    updateCorporateSuccess, deleteCorporateSuccess, deleteCorporateError, deleteCorporate } = customerCorporateSlice.actions;

export const isLoadingCorporates = (state: RootState): boolean => state.customerCorporateReducer.isLoading;
export const isLoadingCorporate = (state: RootState): boolean => state.customerCorporateReducer.isLoadingCorporate;
export const isCreatingCorporate = (state: RootState): boolean => state.customerCorporateReducer.isCreating;
export const isUpdatingCorporate = (state: RootState): boolean => state.customerCorporateReducer.isUpdating;
export const isDeletingCorporate = (state: RootState): boolean => state.customerCorporateReducer.isDeleting;
export const getCorporates = (state: RootState): Pagination<CustomerCorporate> => state.customerCorporateReducer.corporates;
export const getCorporate = (state: RootState): CustomerCorporate => state.customerCorporateReducer.corporate;

export default customerCorporateSlice.reducer;