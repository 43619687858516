import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetCalendarVersionApi from '../api/factorSetCalendarVersionApi';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { cloneCalendarVersionError, cloneCalendarVersionSuccess, fetchCalendarVersionByIdError,
    fetchCalendarVersionByIdSuccess, fetchCalendarVersionsError, fetchCalendarVersionsSuccess, updateCalendarVersionError, updateCalendarVersionStatusError,
    updateCalendarVersionStatusSuccess, updateCalendarVersionSuccess } from '../reducers/factorSetCalendarVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchFactorSetCalendarVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetCalendarVersionApi.fetchFactorSetCalendarVersionById, agreementVersionId);

        yield put(fetchCalendarVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalendarVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetCalendarVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetCalendarVersionApi.fetchFactorSetCalendarVersions, id, paging);
        yield put(fetchCalendarVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalendarVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCalendarVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetCalendarVersionApi.updateFactorSetCalendarVersionStatus, agreementVersion);

        yield put(updateCalendarVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCalendarVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCalendarVersion(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetCalendarVersionApi.updateFactorSetCalendarVersion, agreementVersion);

        yield put(updateCalendarVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCalendarVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetCalendarVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetCalendarVersionApi.cloneFactorSetCalendarVersion, toVersionId, fromVersionId);

        yield put(cloneCalendarVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneCalendarVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchCalendarVersionById', doFetchFactorSetCalendarVersion),
    takeLatest('factorSetVersion/fetchCalendarVersions', doFetchFactorSetCalendarVersions),
    takeLatest('factorSetVersion/updateCalendarVersionStatus', doUpdateFactorSetCalendarVersionStatus),
    takeLatest('factorSetVersion/updateCalendarVersion', doUpdateFactorSetCalendarVersion),
    takeLatest('factorSetVersion/cloneCalendarVersion', doCloneFactorSetCalendarVersion)
];