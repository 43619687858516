import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import DashboardMessageType from '../interfaces/output/dashboardMessageType';
import DashboardMessageTypeFilters from '../interfaces/output/filters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type DashboardMessageTypeState = {
    isLoadingDashboardMessageTypes: boolean;
    dashboardMessageTypes: Pagination<DashboardMessageType>;
    error: HttpErrorResponse | undefined;
};

const initialState: DashboardMessageTypeState = {
    dashboardMessageTypes: { content: [] as Array<DashboardMessageType> } as Pagination<DashboardMessageType>,
    isLoadingDashboardMessageTypes: false,
    error: undefined
};

export const dashboardMessageTypeSlice = createSlice({
    name: 'dashboardMessageType',

    initialState,
    reducers: {
        fetchDashboardMessageTypes: (state: DashboardMessageTypeState, _action: PayloadAction<{paging: Paging, searchTerm?: string}>) => {
            state.isLoadingDashboardMessageTypes = true;
        },
        fetchDashboardMessageTypesSuccess: (state: DashboardMessageTypeState, action: PayloadAction<Pagination<DashboardMessageType>>) => {
            state.isLoadingDashboardMessageTypes = false;
            state.dashboardMessageTypes = action.payload;
        },
        fetchDashboardMessageTypesError: (state: DashboardMessageTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDashboardMessageTypes = false;
            state.error = action.payload;
        }
    }
});

export const { fetchDashboardMessageTypes, fetchDashboardMessageTypesSuccess, fetchDashboardMessageTypesError } = dashboardMessageTypeSlice.actions;

export const isLoadingDashboardMessageTypes = (state: RootState): boolean => state.dashboardMessageTypeReducer.isLoadingDashboardMessageTypes;
export const getDashboardMessageTypes = (state: RootState): Pagination<DashboardMessageType> => state.dashboardMessageTypeReducer.dashboardMessageTypes;

export default dashboardMessageTypeSlice.reducer;