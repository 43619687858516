import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetFactorSetPriorities, resetFactorSetPriority } from '../../../../reducers/factorSetPriorityReducer';
import { FormType } from '../../../../constants/constants';
import AddFactorSetPriorityForm from './addFactorSetPriorityForm';

interface IAddFactorSetPriorityProps {
    classes: any;
    theme: Theme;
    match: any;
    resetFactorSetPriorities: () => any;
    resetFactorSetPriority: () => any;
    steps: Array<number>;
    factorSetPriorityId?: number;
    factorSetVersionId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetFactorSetPriorities: () => dispatch(resetFactorSetPriorities()),
    resetFactorSetPriority: () => dispatch(resetFactorSetPriority())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetPriorityProps) => {
    return {
        factorSetPriorityId: ownProps.match.params?.priorityId ? Number(ownProps.match.params?.priorityId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetPriority extends Component<IAddFactorSetPriorityProps> {
    componentWillUnmount() {
        const { resetFactorSetPriorities, resetFactorSetPriority } = this.props;

        resetFactorSetPriorities();
        resetFactorSetPriority();
    }

    render() {
        const { classes, steps, factorSetPriorityId, type, factorSetVersionId } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddFactorSetPriorityForm factorSetPriorityId={factorSetPriorityId} steps={steps} type={type} factorSetVersionId={factorSetVersionId} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetPriority));