import { Box, Chip, Grid, Theme, Typography } from '@mui/material';
import React from 'react';
import { StatusCodes, StatusConstants } from '../../constants/statusConstants';
import Status from '../../interfaces/output/status';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import LanguageUtils from '../../utils/LanguageUtils';
import { useIntl } from 'react-intl';

const messages = {
    ready: LanguageUtils.createMessage('Ready'),
    invoiced: LanguageUtils.createMessage('Invoiced'),
    credited: LanguageUtils.createMessage('Credited'),
    inprogress: LanguageUtils.createMessage('In progress'),
    onHold: LanguageUtils.createMessage('On hold'),
    failed: LanguageUtils.createMessage('Failed'),
    active: LanguageUtils.createMessage('Active'),
    inactive: LanguageUtils.createMessage('Inactive'),
    deleted: LanguageUtils.createMessage('Deleted'),
    error: LanguageUtils.createMessage('Error'),
    complete: LanguageUtils.createMessage('Complete'),
    valid: LanguageUtils.createMessage('Valid'),
    invalid: LanguageUtils.createMessage('Invalid'),
    expired: LanguageUtils.createMessage('Expired'),
    future: LanguageUtils.createMessage('Future'),
    diabled: LanguageUtils.createMessage('Disabled'),
    true: LanguageUtils.createMessage('True'),
    false: LanguageUtils.createMessage('False'),
    cancelled: LanguageUtils.createMessage('Cancelled'),
    free: LanguageUtils.createMessage('Free'),
    notActive: LanguageUtils.createMessage('Not Active')

};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        status: {
            '&.MuiChip-root': {
                bordeRadius: '50%',
                height: '12px',
                width: '12px',
                '&.active': {
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.common.white
                },
                '&.inactive': {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.common.white
                },
                '&.deleted': {
                    backgroundColor: theme.palette.deleted.main,
                    color: theme.palette.common.white
                },
                '&.error': {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.common.white
                },
                '&.inProgress': {
                    backgroundColor: theme.palette.inProgress.main,
                    color: theme.palette.common.white
                },
                '&.onHold': {
                    backgroundColor: theme.palette.onHold.main,
                    color: theme.palette.common.white
                },
                '&.expired': {
                    backgroundColor: theme.palette.expired.main,
                    color: theme.palette.common.white
                },
                '&.ready': {
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.common.white
                },
                '&.invoiced': {
                    backgroundColor: theme.palette.invoiced.main,
                    color: theme.palette.common.white
                },
                '&.failed': {
                    backgroundColor: theme.palette.expired.main,
                    color: theme.palette.common.white
                },
                '&.complete': {
                    backgroundColor: theme.palette.complete.main,
                    color: theme.palette.common.white
                },
                '&.credited': {
                    backgroundColor: theme.palette.complete.main,
                    color: theme.palette.common.white
                },
                '&.future': {
                    backgroundColor: theme.palette.onHold.main,
                    color: theme.palette.common.white
                },
                '&.disabled': {
                    backgroundColor: theme.palette.expired.main,
                    color: theme.palette.common.white
                },
                '&.false': {
                    backgroundColor: theme.palette.lightGray.main,
                    color: theme.palette.common.white
                },
                '&.free': {
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.common.white
                }
            }
        }
    })
);

interface IDisplayStatus {
    status?: Status;
    useNameAsIdentifier?: boolean;
    inheritProps?: boolean;
}

const DisplayStatus = (props: IDisplayStatus): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();
    const { formatMessage } = intl;
    const { inheritProps } = props;
    const getName = (): string => {
        const { useNameAsIdentifier } = props;
        if(useNameAsIdentifier) {
            return getNameByStatusName();
        }

        return getNameByCode();
    };

    const getNameByCode = (): string => {
        switch (props.status?.code?.toUpperCase()?.toUpperCase()) {
            case StatusCodes.Active:
                return formatMessage(messages.active);
            case StatusCodes.Credited:
                return formatMessage(messages.credited);
            case StatusCodes.Inactive:
                return formatMessage(messages.inactive);
            case StatusCodes.Deleted:
                return formatMessage(messages.deleted);
            case StatusCodes.InProgress:
                return formatMessage(messages.inprogress);
            case StatusCodes.OnHold:
                return formatMessage(messages.onHold);
            case StatusCodes.Ready:
                return formatMessage(messages.ready);
            case StatusCodes.Invoiced:
                return formatMessage(messages.invoiced);
            case StatusCodes.Failed:
                return formatMessage(messages.failed);
            case StatusCodes.Complete:
                return formatMessage(messages.complete);
            case StatusCodes.Error:
                return formatMessage(messages.error);
            case StatusCodes.Valid:
                return formatMessage(messages.valid);
            case StatusCodes.Invalid:
                return formatMessage(messages.invalid);
            case StatusCodes.Expired:
                return formatMessage(messages.expired);
            case StatusCodes.Future:
                return formatMessage(messages.future);
            case StatusCodes.Disabled:
                return formatMessage(messages.diabled);
            case StatusCodes.True:
                return formatMessage(messages.true);
            case StatusCodes.False:
                return formatMessage(messages.false);
            case StatusCodes.Cancelled:
                return formatMessage(messages.cancelled);
            case StatusCodes.Free:
                return formatMessage(messages.free);
            default:
                return '';
        }
    };

    const getNameByStatusName = (): string => {
        switch (props.status?.name) {
            case StatusConstants.Active:
                return formatMessage(messages.active);
            case StatusConstants.Inactive:
            case StatusConstants.NotActive:
                return formatMessage(messages.inactive);
            case StatusConstants.NotActiveUsers:
                return formatMessage(messages.notActive);
            case StatusConstants.Deleted:
                return formatMessage(messages.deleted);
            case StatusConstants.InProgress:
                return formatMessage(messages.inprogress);
            case StatusConstants.OnHold:
                return formatMessage(messages.onHold);
            case StatusConstants.True:
                return formatMessage(messages.true);
            case StatusConstants.False:
                return formatMessage(messages.false);
            default:
                return '';
        }
    };

    const getStatusClass = () => {
        const { useNameAsIdentifier } = props;
        if(useNameAsIdentifier) {
            return getStatusClassByStatusName();
        }

        return getStatusClassByCode();
    };

    const getStatusClassByStatusName = () => {
        switch (props.status?.name) {
            case StatusConstants.Active:
                return { active: true };
            case StatusConstants.Inactive:
            case StatusConstants.NotActive:
                return { inactive: true };
            case StatusConstants.NotActiveUsers:
                return { false: true };
            case StatusConstants.Deleted:
                return { deleted: true };
            case StatusConstants.InProgress:
                return { inProgress: true };
            case StatusConstants.OnHold:
                return { onHold: true };
            case StatusConstants.True:
                return { active: true };
            case StatusConstants.False:
                return { false: true };
            default:
                return {};
        }
    };

    const getStatusClassByCode = () => {
        switch (props.status?.code?.toUpperCase()?.toUpperCase()) {
            case StatusCodes.Active:
                return { active: true };
            case StatusCodes.Credited:
                return { credited: true };
            case StatusCodes.Inactive:
                return { inactive: true };
            case StatusCodes.Deleted:
                return { deleted: true };
            case StatusCodes.InProgress:
                return { inProgress: true };
            case StatusCodes.OnHold:
                return { onHold: true };
            case StatusCodes.Ready:
                return { ready: true };
            case StatusCodes.Invoiced:
                return { invoiced: true };
            case StatusCodes.Failed:
                return { failed: true };
            case StatusCodes.Complete:
                return { complete: true };
            case StatusCodes.Error:
                return { error: true };
            case StatusCodes.Valid:
                return { complete: true };
            case StatusCodes.Invalid:
                return { error: true };
            case StatusCodes.Expired:
                return { expired: true };
            case StatusCodes.Future:
                return { future: true };
            case StatusCodes.Disabled:
                return { disabled: true };
            case StatusCodes.True:
                return { active: true };
            case StatusCodes.False:
                return { false: true };
            case StatusCodes.Free:
                return { free: true };
            default:
                return {};
        }
    };

    return <Grid container>
        <Grid item sm="auto" pr={1} pt={0.1}>
            <Chip size="small" className={clsx(classes.status, getStatusClass())} />
        </Grid>
        <Grid item>
            <Box sx={{
                display: 'inline-flex',
                justifyContent: 'center'
            }}>
                <Typography gutterBottom variant={inheritProps ? 'inherit' : 'body1'}>
                    {getName()}
                </Typography>
            </Box>
        </Grid>
    </Grid>;

};

export default DisplayStatus;