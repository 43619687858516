import { http } from '../utils/http';
import GTSOrderUnit from '../interfaces/output/gtsOrderUnit';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class GtsOrderUnitApi {
    static fetchGtsOrderUnit = async(unitId: string): Promise<GTSOrderUnit> => {
        const { data } = await http.get<GTSOrderUnit>(`staginggtsorderunits/${unitId}`);

        return data;
    };

    static fetchGtsOrderUnits = async(paging: Paging, filters: GtsOrderUnitFilters): Promise<Array<GTSOrderUnit>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/staginggtsorderunits');

        const { data } = await http.get<Array<GTSOrderUnit>>(url);

        return data;
    };

    static updateGtsOrderUnit = async(gtsOrderUnit: GTSOrderUnit): Promise<GTSOrderUnit> => {
        const newUnit = {
            logicalUnitId: gtsOrderUnit.logicalUnitId,
            gtsUnitType: gtsOrderUnit.gtsUnitType,
            unitType: gtsOrderUnit.unitType,
            unitNumber: gtsOrderUnit.unitNumber,
            trainNumber: gtsOrderUnit.trainNumber,
            trainId: gtsOrderUnit.trainId,
            netWeightKg: gtsOrderUnit.netWeightKg,
            grossWeightKg: gtsOrderUnit.grossWeightKg,
            loadedFlagId: gtsOrderUnit.loadedFlag?.id,
            cancellationCode: gtsOrderUnit.cancellationCode,
            priorityType: gtsOrderUnit.priorityType,
            teu: gtsOrderUnit.teu,
            departureTime: gtsOrderUnit.departureTime,
            departureTypeCode: gtsOrderUnit.departureTypeCode,
            fromPlace: gtsOrderUnit.fromPlace,
            toPlace: gtsOrderUnit.toPlace,
            routeAlternative: gtsOrderUnit.routeAlternative,
            errorTypeId: gtsOrderUnit?.errorType?.id,
            errorMessage: gtsOrderUnit.errorMessage
        };
        
        const { data } = await http.put<any, GTSOrderUnit>(`staginggtsorderunits/${gtsOrderUnit.id}`, newUnit);

        return data;
    }

    static deactivateGtsOrderUnit = async(unitId: number): Promise<GTSOrderUnit> => {

        const { data } = await http.delete<GTSOrderUnit>(`staginggtsorderunits/${unitId}`);

        return data;
    }
}
