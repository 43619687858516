import React from 'react';
import { Breadcrumbs, Link, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import clsx from 'clsx';
import { ensure } from '../../../utils/arrayUtils';
import UrlConstants from '../../../constants/UrlConstants';
import { useIntl } from 'react-intl';
import IBreadCrumbItem from '../../../interfaces/common/breadcrumbItem';

const navbarHeight = 65;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            backgroundColor: 'white !important',
            borderBottom: '1px #ececec solid !important',
            borderLeft: 'none',
            marginTop: navbarHeight,
            zIndex: `${theme.zIndex.drawer + 1} !important` as any,
            '&.customerBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.customer.main} !important`
            },
            '&.agreementBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.agreement.main} !important`
            },
            '&.productsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.product.main} !important`
            },
            '&.priceListsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.priceList.main} !important`
            },
            '&.factorSetsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.factorSet.main} !important`
            },
            '&.gtsOrdersBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.gtsOrder.main} !important`
            },
            '&.ordersBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.order.main} !important`
            },
            '&.orderUnitsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.order.main} !important`
            },
            '&.orderUnitServicesBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.order.main} !important`
            },
            '&.orderUnitServiceProductsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.order.main} !important`
            },
            '&.orderUnitServiceProductSurchargesBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.order.main} !important`
            },
            '&.gtsOrderUnitsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.gtsOrder.main} !important`
            },
            '&.gtsOrderUnitServicesBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.gtsOrder.main} !important`
            },
            '&.orderUnitDepotBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.gtsOrder.main} !important`
            },
            '&.orderUnitDangerousGoodsBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.gtsOrder.main} !important`
            },
            '&.serviceBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.service.main} !important`
            },
            '&.specialPriceBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.specialPrice.main} !important`
            },
            '&.discountBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.discount.main} !important`
            },
            '&.dashboardBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.dashboard.main} !important`
            },
            '&.invoiceBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.invoice.main} !important`
            },
            '&.simulationBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.simulation.main} !important`
            },
            '&.invoiceLineBorder': {
                boxShadow: `inset 0px 10px 5px -6px ${theme.palette.invoiceLines.main} !important`
            }
        },
        margin: {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(7)
        },
        value: {
            color: theme.palette.common.black,
            marginLeft: theme.spacing(1)
        },
        name: {
            fontSize: '17px',
            textTransform: 'uppercase'
        },
        subname: {
            fonSize: '8px'
        },
        additionalElement: {
            marginLeft: theme.spacing(2)
        }
    }));

interface IBarProps {
    menuItem: IMenuItem;
    additionalElement?: JSX.Element;
    routes?: Array<IBreadCrumbItem>;
}

export default function Bar(props: IBarProps): JSX.Element {
    const classes = useStyles();
    const { menuItem, additionalElement, routes } = props;
    const location = useLocation();
    const isPath = (path: string, exact = false) => matchPath(ensure(location.pathname), {
        path: `/${path}`,
        exact
    });
    const { formatMessage } = useIntl();
    const subname = menuItem.subname ? formatMessage(menuItem.subname).toUpperCase() : '';

    return (
        <AppBar position="fixed" className={clsx(classes.appBar, {
            customerBorder: isPath(UrlConstants.CUSTOMERS),
            agreementBorder: isPath(UrlConstants.AGREEMENTS),
            productsBorder: isPath(UrlConstants.PRODUCTS),
            priceListsBorder: isPath(UrlConstants.PRICE_LISTS),
            factorSetsBorder: isPath(UrlConstants.FACTOR_SETS),
            gtsOrdersBorder: isPath(UrlConstants.GTS_ORDERS),
            ordersBorder: isPath(UrlConstants.ORDERS),
            orderUnitsBorder: isPath(UrlConstants.ORDER_UNITS),
            orderUnitServicesBorder: isPath(UrlConstants.ORDER_UNIT_SERVICES),
            orderUnitServiceProductsBorder: isPath(UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS),
            orderUnitServiceProductSurchargesBorder: isPath(UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES),
            gtsOrderUnitsBorder: isPath(UrlConstants.GTS_ORDER_UNITS),
            gtsOrderUnitServicesBorder: isPath(UrlConstants.GTS_UNIT_SERVICES),
            orderUnitDepotBorder: isPath(UrlConstants.UNIT_DEPOT),
            orderUnitDangerousGoodsBorder: isPath(UrlConstants.UNIT_DANGEROUS_GOODS),
            serviceBorder: isPath(UrlConstants.SERVICES),
            specialPriceBorder: isPath(UrlConstants.AGREEMENT_VERSION_SPECIAL_PRICE),
            dashboardBorder: isPath(UrlConstants.DASHBOARD, true),
            invoiceBorder: isPath(UrlConstants.INVOICES),
            invoiceLineBorder: isPath(UrlConstants.INVOICE_LINES),
            simulationBorder: isPath(UrlConstants.SIMULATIONS),
            discountBorder: isPath(UrlConstants.AGREEMENT_VERSION_TRANSIT_DISCOUNT)
        })}
        >
            <Toolbar>
                <Grid container justifyContent="space-between">
                    <Grid item xs={12} sm={12} key={menuItem?.id} marginTop={1}>
                        <Typography variant="subtitle2">
                            <span className={classes.margin}>{menuItem?.icon}</span>
                            {menuItem?.name ? <span className={classes.name}>{formatMessage(menuItem.name)} </span> : <span className={classes.subname}>{subname}</span>}
                            <span className={classes.value}>{menuItem?.value}</span>
                            <span className={classes.additionalElement}>{additionalElement}</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} key={menuItem?.id} padding={0} marginLeft={7}>
                        <Breadcrumbs>
                            {routes?.map(route => <Link color="secondary" variant="body2" href={`/#/${route.url}`}>{route.name}</Link>)}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}