import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import UnitSubGroup from '../interfaces/output/unitSubGroup';
import UnitType from '../interfaces/output/unitType';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class UnitSubGroupApi {
    static fetchUnitSubGroupById = async(unitSubgroupId: string): Promise<UnitSubGroup> => {
        const { data } = await http.get<UnitSubGroup>(`/unitsubgroups/${unitSubgroupId}`);

        return data;
    };

    static fetchUnitTypes = async(unitSubgroupId: string, paging: Paging): Promise<Array<UnitType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/unitsubgroups/${unitSubgroupId}/types`);

        const { data } = await http.get<Array<UnitType>>(url);

        return data;
    };
}
