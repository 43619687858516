import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../constants/constants';
import DangerousGoodsForm from './gtsOrderDangerousGoodsForm';
import { resetGtsOrderUnitDangerousGoods } from '../../reducers/gtsDangerousGoodsReducer';

interface IAddGtsOrderUnitDangerousGoodsFormProps {
    classes: any;
    theme: Theme;
    match: any;
    resetGtsOrderUnitDangerousGoods: any;
    steps: Array<number>;
    gtsOrderUnitId?: number;
    type: FormType;
    gtsDangerousGoodsId?: number;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetGtsOrderUnitDangerousGoods: () => dispatch(resetGtsOrderUnitDangerousGoods())
});

const mapStoreToProps = (store: RootState, ownProps: IAddGtsOrderUnitDangerousGoodsFormProps) => {
    return {
        gtsDangerousGoodsId: ownProps.match.params?.gtsDangerousGoodsId ? Number(ownProps.match.params?.gtsDangerousGoodsId) : undefined,
        gtsOrderUnitId: ownProps.match.params?.gtsOrderUnitId ? Number(ownProps.match.params?.gtsOrderUnitId) : undefined
    };
};

class AddGtsOrderUnitDangerousGoods extends Component<IAddGtsOrderUnitDangerousGoodsFormProps> {

    componentWillUnmount() {
        const { resetGtsOrderUnitDangerousGoods } = this.props;

        resetGtsOrderUnitDangerousGoods();
    }

    render() {
        const { classes, steps, gtsOrderUnitId, gtsDangerousGoodsId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <DangerousGoodsForm gtsOrderUnitId={gtsOrderUnitId} gtsDangerousGoodsId={gtsDangerousGoodsId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddGtsOrderUnitDangerousGoods));