import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import FactorSetWeightLineOutput from '../../../../interfaces/output/factorSetWeightLineOut';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import WeightLineFormStep1 from './weightLineFormStep1';
import { createWeightVersionLine, fetchFactorSetWeightLineById, fetchWeightVersionLines, getFactorSetWeightLine, getWeightLines, isCreatingWeightLine, isUpdatingWeightLine, updateFactorSetWeightLine }
    from '../../../../reducers/weightLineReducer';
import FactorSetWeightLineStep1Validation from '../line/validation/factorSetWeightLineStep1Validation';
import PageUtils from '../../../../utils/pageUtils';

interface IAddFactorSetWeightLineFormProps {
    weightLineId?: number;
    factorSetWeightId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set weight line'),
    add: LanguageUtils.createMessage('Add factor set weight line')
};

export default function AddFactorSetWeightLineForm(props: IAddFactorSetWeightLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const { weightLineId, factorSetWeightId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [weightLine, setWeightLine] = React.useState<FactorSetWeightLineOutput>({ factorSetId: factorSetWeightId,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetWeightLineOutput);
    const propsWeightLine = useSelector(getFactorSetWeightLine);
    const propsWeightLines = useSelector(getWeightLines);
    const propsIsUpdatingWeightLine = useSelector(isUpdatingWeightLine);
    const prevIsUpdatingWeightLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingWeightLine);
    const propsIsCreatingWeightLine = useSelector(isCreatingWeightLine);
    const prevIsCreatingWeightLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingWeightLine);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingWeightLine === true && !propsIsUpdatingWeightLine) || (prevIsCreatingWeightLine === true && !propsIsCreatingWeightLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingWeightLine, propsIsUpdatingWeightLine]);
    
    useEffect(() => {
        if(weightLineId) {
            dispatch(fetchFactorSetWeightLineById(weightLineId));
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchWeightVersionLines({ paging,
            filters: { factorSetVersionId } }));
    }, [dispatch]);
    
    useEffect(() => {
        if(propsWeightLine.id) {
            setWeightLine(propsWeightLine);
        }

    }, [propsIsUpdatingWeightLine, propsWeightLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...weightLine } as FactorSetWeightLineOutput;
        (newLine as any)[attribute] = value;

        setWeightLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <WeightLineFormStep1 onChange={onChange} weightLine={weightLine} />,
            validationFn: () => FactorSetWeightLineStep1Validation.validateFactorSetForm(weightLine, propsWeightLines.content),
            onNext: () => {
                if(!weightLine.id) {
                    dispatch(createWeightVersionLine(weightLine));
                }
                else {
                    dispatch(updateFactorSetWeightLine(weightLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={weightLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/weight/${factorSetWeightId}/${weightLine.factorSetVersionId}/lines` :
                `/${UrlConstants.FACTOR_SETS}/weight/${factorSetWeightId}/latest/lines`}
        />
    );
}
  