import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Service from '../../interfaces/output/service';
import { fetchServiceById, getService, isLoadingService, resetService } from '../../reducers/serviceReducer';
import ViewService from './viewService';
import ProductList from '../products/productList';
import { demoteServiceProduct, fetchServiceProducts, getServiceProducts, isLoadingServiceProducts, isUpdating, promoteServiceProduct } from '../../reducers/serviceProductReducer';
import Pagination from '../../interfaces/common/pagination';
import ServiceProduct from '../../interfaces/output/serviceProduct';
import Paging from '../../interfaces/common/paging';
import ServiceProductFilters from '../../interfaces/filters/serviceProductFilters';
import LanguageUtils from '../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IServiceDetailsProps {
    classes: any;
    theme: Theme;
    fetchServiceById: any;
    service: Service;
    match: any;
    history: any;
    location: any;
    isLoadingService: boolean;
    isLoadingServiceProductList: boolean;
    serviceProducts: Pagination<ServiceProduct>;
    resetService: () => void;
    fetchServiceProducts: (paging: Paging, filters: ServiceProductFilters) => void;
    promoteServiceProduct: any;
    deomoteServiceProduct: any;
    isUpdatingServiceProduct: boolean;
}

interface IServiceDetailsState {
    service: Service;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverProductPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchServiceById: (serviceId: number) => dispatch(fetchServiceById(serviceId)),
    resetService: () => dispatch(resetService()),
    fetchServiceProducts: (paging: Paging, filters: ServiceProductFilters) => dispatch(fetchServiceProducts({
        paging,
        filters
    })),
    promoteServiceProduct: (serviceProductId: number) => dispatch(promoteServiceProduct(serviceProductId)),
    deomoteServiceProduct: (serviceProductId: number) => dispatch(demoteServiceProduct(serviceProductId))
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    subname: LanguageUtils.createMessage('Service'),
    properties: LanguageUtils.createMessage('Properties'),
    serviceProducts: LanguageUtils.createMessage('Service products'),
    promote: LanguageUtils.createMessage('Promote'),
    demote: LanguageUtils.createMessage('Demote'),
    editAmount: LanguageUtils.createMessage('Edit amount')
};

const mapStoreToProps = (store: RootState) => {
    return {
        service: getService(store),
        isLoadingService: isLoadingService(store),
        serviceProducts: getServiceProducts(store),
        isLoadingServiceProductList: isLoadingServiceProducts(store),
        isUpdatingServiceProduct: isUpdating(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class ServiceDetails extends Component<IServiceDetailsProps, IServiceDetailsState> {
    constructor(props: IServiceDetailsProps) {
        super(props);

        this.state = {
            service: {} as Service,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.SERVICES}/${props.match.params.serviceId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.SERVICES}/${props.match.params.serviceId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.serviceProducts,
                icon: <FontAwesomeIcon icon="box" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.SERVICES}/${props.match.params.serviceId}/products`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.SERVICES}/${props.match.params.serviceId}/products`, props.location.pathname)
            }
            ]),
            serverProductPagination: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: 'sequenceNumber',
                sort: 'asc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchServiceById } = this.props;
        const { serviceId } = this.props.match.params;

        fetchServiceById(serviceId);
    }

    componentDidUpdate(prevProps: IServiceDetailsProps) {
        const { service, isLoadingService, isUpdatingServiceProduct } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(service !== prevProps.service) {
            this.setState({ service });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && isLoadingService === false && prevProps.isLoadingService === true
            || !isUpdatingServiceProduct && prevProps.isUpdatingServiceProduct) {
            this.loadData(selectedMenuItem);
        }
    }

    componentWillUnmount() {
        const { resetService } = this.props;
        resetService();
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchServiceProducts } = this.props;
        const { serviceId } = this.props.match.params;
        const { serverProductPagination, sortModel } = this.state;

        const newPagination = {
            ...serverProductPagination,
            sort: sortModel
        };

        this.setState({
            sortModel: sortModel,
            serverProductPagination: newPagination
        });

        if(selectedMenuItem?.id === 2) {
            fetchServiceProducts(newPagination, { serviceId } as ServiceProductFilters);
        }
    }

    promoteServiceProduct = () => {
        const { promoteServiceProduct } = this.props;
        const { selectedRow } = this.state;

        promoteServiceProduct(selectedRow);
    }

    demoteServiceProduct = () => {
        const { selectedRow } = this.state;
        const { deomoteServiceProduct } = this.props;

        deomoteServiceProduct(selectedRow);
    }

    getSelectedMenuItem = (state: IServiceDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { serviceId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.SERVICES}/${serviceId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        href: `${UrlConstants.SERVICES}/${serviceId}/status`,
                        icon: 'retweet',
                        text: messages.changeStatus
                    }
                ];
            }

            case 2: { //products
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.SERVICES}/${serviceId}/serviceproducts/edit`,
                            icon: 'edit',
                            text: messages.edit
                        }
                    ];
                }

                return [
                    {
                        icon: 'angle-up',
                        text: messages.promote,
                        isHidden: !selectedRow,
                        onClick: this.promoteServiceProduct
                    },
                    {
                        icon: 'angle-down',
                        text: messages.demote,
                        isHidden: !selectedRow,
                        onClick: this.demoteServiceProduct
                    },
                    {
                        icon: 'edit',
                        text: messages.editAmount,
                        isHidden: !selectedRow,
                        href: `${UrlConstants.SERVICE_PRODUCTS}/${selectedRow}/edit`
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    _onSortModelChange = (newModel: GridSortModel) => {
        const { fetchServiceProducts } = this.props;
        const { service, serverProductPagination, sortModel } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverProductPagination,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                serverProductPagination: newPagination
            });
            fetchServiceProducts(
                newPagination,
                { serviceId: service.id } as ServiceProductFilters
            );
        }
    }

    render() {
        const { classes, isLoadingService, isLoadingServiceProductList, serviceProducts, fetchServiceProducts } = this.props;
        const { service, menuItems, serverProductPagination, columnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingService}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Service}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: service.type?.name,
                    icon: <FontAwesomeIcon icon="concierge-bell" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.SERVICES}/:serviceId/properties`}>
                            <ViewService service={service} />
                        </Route>
                        <Route path={`/${UrlConstants.SERVICES}/:serviceId/products`}>
                            <ProductList
                                prefix={'product_'}
                                isAServiceProduct={true}
                                showServiceProductAmount={true}
                                isLoading={isLoadingServiceProductList}
                                rows={serviceProducts}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                applyRowFn={(cellValues: any) => cellValues.row.product}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverProductPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchServiceProducts(
                                        newPage,
                                        { serviceId: service.id } as ServiceProductFilters
                                    );
                                    this.setState({ serverProductPagination: newPage });
                                }}
                                hiddenColumns={['basePrice', 'onHold']}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ServiceDetails));