import React from 'react';
import { Link } from '@mui/material';
import { useHistory } from 'react-router-dom';

interface ICustomLink {
    children: any;
    link: any;
    disabled?: any;
    shouldUseHistory?: boolean;
}
export default function CustomLink(props: ICustomLink): JSX.Element {
    const { children, link, disabled, shouldUseHistory } = props;

    const history = useHistory();

    const openInNewTab = (link: any) => {
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    const mouseDownHandler = (event: any) => {
        if(event.button === 1) {
            openInNewTab(`#${link}`);
        }
    };

    return (
        <>
            {!shouldUseHistory &&
                <Link width="100%" underline="none" color="secondary" href={link} >
                    {children}
                </Link>
            }
            {!!shouldUseHistory &&
                <Link component={!disabled ? 'button' : 'span'}
                    underline="none" color="secondary" width="100%" align="left"
                    onClick={() => !disabled ? history.push(link) : {}}
                    onContextMenu={() => openInNewTab(`#${link}`)}
                    onMouseDown={mouseDownHandler}>
                    {children}
                </Link>
            }
        </>
    );
}