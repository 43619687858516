import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Customer from '../interfaces/output/customer';
import CustomerFilters from '../interfaces/output/filters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CustomerState = {
    customer: Customer;
    isLoadingCustomer: boolean;
    isLoadingCustomers: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isSyncing: boolean;
    customers: Pagination<Customer>;
    error: HttpErrorResponse | undefined;
    isProcessCredit: boolean;
};

const initialState: CustomerState = {
    customer: {} as Customer,
    customers: { content: [] as Array<Customer> } as Pagination<Customer>,
    isLoadingCustomer: false,
    isLoadingCustomers: false,
    isUpdating: false,
    isRemoving: false,
    isDeleting: false,
    isCreating: false,
    isSyncing: false,
    isProcessCredit: false,
    error: undefined
};

export const customerSlice = createSlice({
    name: 'customer',

    initialState,
    reducers: {
        fetchCustomers: (state: CustomerState, _action: PayloadAction<{paging: Paging, filters: CustomerFilters, throttle?: boolean}>) => {
            state.isLoadingCustomers = true;
        },
        fetchCustomersSuccess: (state: CustomerState, action: PayloadAction<Pagination<Customer>>) => {
            state.isLoadingCustomers = false;
            state.customers = action.payload;
        },
        fetchCustomersError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCustomers = false;
            state.error = action.payload;
        },
        fetchCustomerById: (state: CustomerState, _action: PayloadAction<number>) => {
            state.isLoadingCustomer = true;
        },
        fetchCustomerByIdSuccess: (state: CustomerState, action: PayloadAction<Customer>) => {
            state.isLoadingCustomer = false;
            state.customer = action.payload;
        },
        fetchCustomerByIdError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCustomer = false;
            state.error = action.payload;
        },
        createCustomer: (state: CustomerState, _action: PayloadAction<Customer>) => {
            state.isCreating = true;
        },
        createCustomerSuccess: (state: CustomerState, action: PayloadAction<Customer>) => {
            state.isCreating = false;
            state.customer = action.payload;
        },
        createCustomerError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateCustomer: (state: CustomerState, _action: PayloadAction<Customer>) => {
            state.isUpdating = true;
        },
        updateCustomerSuccess: (state: CustomerState, action: PayloadAction<Customer>) => {
            state.isUpdating = false;
            state.customer = action.payload;
        },
        updateCustomerError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateCustomer: (state: CustomerState, _action: PayloadAction<Customer>) => {
            state.isDeleting = true;
        },
        deactivateCustomerSuccess: (state: CustomerState, action: PayloadAction<Customer>) => {
            state.isDeleting = false;
            state.customer = action.payload;
        },
        deactivateCustomerError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        syncCustomerGts: (state: CustomerState, _action: PayloadAction<number>) => {
            state.isSyncing = true;
        },
        syncCustomerGtsSuccess: (state: CustomerState, action: PayloadAction<Customer>) => {
            state.isSyncing = false;
            state.customer = action.payload;
        },
        syncCustomerGtsError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isSyncing = false;
            state.error = action.payload;
        },
        resetCustomers: (state: CustomerState) => {
            state.customers = initialState.customers;
        },
        resetCustomer: (state: CustomerState) => {
            state.customer = initialState.customer;
        },
        processCreditInvoices: (state: CustomerState, _action: PayloadAction<number>) => {
            state.isProcessCredit = true;
        },
        processCreditInvoicesSuccess: (state: CustomerState, action: PayloadAction<any>) => {
            state.isProcessCredit = false;
        },
        processCreditInvoicesError: (state: CustomerState, action: PayloadAction<HttpErrorResponse>) => {
            state.isProcessCredit = false;
            state.error = action.payload;
        }
    }
});

export const { fetchCustomers, fetchCustomersSuccess, fetchCustomersError, fetchCustomerById, fetchCustomerByIdSuccess, fetchCustomerByIdError, createCustomer, createCustomerSuccess
    , createCustomerError, updateCustomer, updateCustomerSuccess, updateCustomerError, deactivateCustomer, deactivateCustomerSuccess, deactivateCustomerError, resetCustomers,
    resetCustomer, syncCustomerGts, syncCustomerGtsError, syncCustomerGtsSuccess, processCreditInvoices, processCreditInvoicesError, processCreditInvoicesSuccess } = customerSlice.actions;

export const isLoadingCustomer = (state: RootState): boolean => state.customerReducer.isLoadingCustomer;
export const isLoadingCustomers = (state: RootState): boolean => state.customerReducer.isLoadingCustomers;
export const isRemovingCustomer = (state: RootState): boolean => state.customerReducer.isRemoving;
export const isUpdatingCustomer = (state: RootState): boolean => state.customerReducer.isUpdating;
export const isDeletingCustomer = (state: RootState): boolean => state.customerReducer.isDeleting;
export const isCreatingCustomer = (state: RootState): boolean => state.customerReducer.isCreating;
export const isSyncingCustomer = (state: RootState): boolean => state.customerReducer.isSyncing;
export const getCustomers = (state: RootState): Pagination<Customer> => state.customerReducer.customers;
export const getCustomer = (state: RootState): Customer => state.customerReducer.customer;
export const isProcessCredit = (state: RootState): boolean => state.customerReducer.isProcessCredit;

export default customerSlice.reducer;