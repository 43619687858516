import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import ProductType from '../interfaces/output/productType';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ProductTypeApi {
    static fetchProductTypeById = async(productTypeId: string): Promise<ProductType> => {
        const { data } = await http.get<ProductType>(`/producttypes/${productTypeId}`);

        return data;
    };

    static fetchProductTypes = async(paging: Paging): Promise<Pagination<ProductType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/producttypes');

        const { data } = await http.get<Pagination<ProductType>>(url);

        return data;
    };

    static createProductType = async(productType: ProductType): Promise<ProductType> => {
        const newProductType = {
            name: productType.name,
            code: productType.code
        };

        const { data } = await http.post<any, ProductType>('producttypes', newProductType);

        return data;
    }
}
