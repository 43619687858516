import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormControl, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import PageUtils from '../../../utils/pageUtils';
import InvoiceLineOut from '../../../interfaces/output/invoiceLineOut';
import { fetchFactorSetDepartureTypes, getFactorSetDepartureTypes } from '../../../reducers/factorSetDepartureReducer';
import DepartureType from '../../../interfaces/output/departureType';
import PriorityType from '../../../interfaces/output/priorityType';
import { fetchPriorityTypes, getPriorityTypes } from '../../../reducers/priorityTypeReducer';
import { isLoadingInvoiceLine, isUpdatingInvoiceLine, isCreatingInvoiceLine } from '../../../reducers/invoiceLineReducer';
import ServicePicker from '../../common/picker/servicePicker';
import Service from '../../../interfaces/output/service';
import ProductPicker from '../../common/picker/productPicker';
import Product from '../../../interfaces/output/product';
import OrderPicker from '../../common/picker/orderPicker';
import Order from '../../../interfaces/output/order';
import { fetchCurrencies } from '../../../reducers/currencyReducer';
import CancellationType from '../../../interfaces/output/cancellationType';
import { fetchCancellationTypes, getCancellationTypes } from '../../../reducers/cancellationTypeReducer';
import GenericTextField from '../../common/widgets/genericTextField';
import DangerousGoodsGroup from '../../../interfaces/output/dangerousGoodsGroup';
import { fetchDangerousGoodsGroups, getDangerousGoodsGroups } from '../../../reducers/dangerousGoodsGroupReducer';
import VatCode from '../../../interfaces/output/vatCode';
import { fetchVatCodes, getVatCodes } from '../../../reducers/vatReducer';
import ProductDynamics from '../../../interfaces/output/productDynamics';
import { fetchProductDynamicsByProductId, getProductDynamics } from '../../../reducers/productReducer';
import { ensure } from '../../../utils/arrayUtils';
import UnitType from '../../../interfaces/output/unitType';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../reducers/unitSubgroupReducer';
import UnitGroup from '../../../interfaces/output/unitGroup';
import UnitSubGroup from '../../../interfaces/output/unitSubGroup';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../reducers/unitGroupReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../reducers/unitTypeReducer';

interface IInvoiceLineFormStep1Props {
    invoiceLine: InvoiceLineOut;
    onChange: any;
}

const messages = {
    product: LanguageUtils.createMessage('Product'),
    service: LanguageUtils.createMessage('Service'),
    order: LanguageUtils.createMessage('Order'),
    orderUnitServiceProduct: LanguageUtils.createMessage('Order unit service product'),
    departureType: LanguageUtils.createMessage('Departure type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    customerReference: LanguageUtils.createMessage('Customer reference'),
    currency: LanguageUtils.createMessage('Currency'),
    dangerousgoodsgroup: LanguageUtils.createMessage('Dangerous goods group'),
    vatCode: LanguageUtils.createMessage('Vat code'),
    productDynamicsId: LanguageUtils.createMessage('Dynamics id'),
    price: LanguageUtils.createMessage('Price'),
    weightKg: LanguageUtils.createMessage('Weight kg'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    unittype: LanguageUtils.createMessage('Unit type'),
    unitgroup: LanguageUtils.createMessage('Unit group'),
    subgroup: LanguageUtils.createMessage('Subgroup')
};

const InvoiceLineFormStep1 = forwardRef((props: IInvoiceLineFormStep1Props, ref: any): JSX.Element => {
    const { invoiceLine, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const propsIsLoadingInvoiceLine = useSelector(isLoadingInvoiceLine);
    const isLoadingObjects = [useSelector(isCreatingInvoiceLine), useSelector(isUpdatingInvoiceLine), propsIsLoadingInvoiceLine];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const departureTypes = useSelector(getFactorSetDepartureTypes).content;
    const priorityTypes = useSelector(getPriorityTypes).content;
    // const currencies = useSelector(getCurrencies).content;
    const cancellationTypes = useSelector(getCancellationTypes).content;
    const dangerousGoodsGroups = useSelector(getDangerousGoodsGroups).content;
    const productDynamics = useSelector(getProductDynamics).content;
    const vatCodes = useSelector(getVatCodes).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchFactorSetDepartureTypes({ paging }));
        dispatch(fetchPriorityTypes({ paging }));
        dispatch(fetchCurrencies({ paging }));
        dispatch(fetchCancellationTypes({ paging }));
        dispatch(fetchDangerousGoodsGroups({ paging }));
        dispatch(fetchVatCodes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
        if(invoiceLine?.unitType?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(invoiceLine.unitType?.unitGroupId),
                paging }));
            if(invoiceLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(invoiceLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(invoiceLine.product) {
            dispatch(fetchProductDynamicsByProductId({ productId: ensure(invoiceLine.product.id),
                paging }));
        }
        else {
            onChange('productDynamicsId', null);
        }
    }, [invoiceLine.product?.id]);

    useEffect(() => {
        if(invoiceLine?.unitType?.unitGroupId) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(invoiceLine?.unitType?.unitGroupId),
                paging }));
            dispatch(resetSubgroups());
            dispatch(resetUnitTypes());
        }
    }, [invoiceLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(invoiceLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypesBySubGroupId({ id: ensure(invoiceLine?.unitType?.unitSubGroupId),
                paging }));
            dispatch(resetUnitTypes());
        }
    }, [invoiceLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!invoiceLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="customerReference"
                        label={messages.customerReference}
                        value={invoiceLine.customerReference}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        type="string"
                        name="unitNo"
                        label={messages.unitNo}
                        value={invoiceLine.unitNo}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="weightKg"
                        label={messages.weightKg}
                        value={invoiceLine.weightKg}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="price"
                        label={messages.price}
                        value={invoiceLine.price}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <ProductPicker
                            value={invoiceLine.product}
                            onChange={(obj: Product | null) => onChange('product', obj)}
                            placeholder={messages.product}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<ProductDynamics>
                        options={productDynamics}
                        value={invoiceLine.productDynamicsId}
                        onChange={(obj: ProductDynamics | null) => onChange('productDynamicsId', obj)}
                        placeholder={messages.productDynamicsId}
                        label="id"
                        disabled={!invoiceLine.product?.id}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <OrderPicker
                            value={invoiceLine.order}
                            onChange={(obj: Order | null) => onChange('order', obj)}
                            placeholder={messages.order}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<DepartureType>
                        options={departureTypes}
                        value={invoiceLine.departureType}
                        onChange={(obj: DepartureType | null) => onChange('departureType', obj)}
                        placeholder={messages.departureType}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<PriorityType>
                        options={priorityTypes}
                        value={invoiceLine.priorityType}
                        onChange={(obj: PriorityType | null) => onChange('priorityType', obj)}
                        placeholder={messages.priorityType}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <ServicePicker
                        value={invoiceLine.service}
                        onChange={(obj: Service | null) => onChange('service', obj)}
                        placeholder={messages.service}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                {/* <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={invoiceLine.currency}
                            onChange={(obj: Currency | null) => onChange('currency', obj)}
                            placeholder={messages.currency}
                        />
                    </FormControl>
                </Grid> */}
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CancellationType>
                            options={cancellationTypes}
                            value={invoiceLine.cancellationType}
                            onChange={(obj: CancellationType | null) => onChange('cancellationType', obj)}
                            placeholder={messages.cancellationType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<DangerousGoodsGroup>
                            options={dangerousGoodsGroups}
                            value={invoiceLine.dangerousGoodsGroup}
                            onChange={(obj: DangerousGoodsGroup | null) => onChange('dangerousGoodsGroup', obj)}
                            placeholder={messages.dangerousgoodsgroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<VatCode>
                        options={vatCodes}
                        value={invoiceLine.vatCode}
                        onChange={(obj: VatCode | null) => onChange('vatCode', obj)}
                        placeholder={messages.vatCode}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={invoiceLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === invoiceLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...invoiceLine.unitType,
                                unitGroupId: obj?.id,
                                unitSubGroupId: null,
                                id: null }, 'unitGroupId')}
                            placeholder={messages.unitgroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={invoiceLine.unitType?.unitSubGroupId}
                            compareFn={(o: UnitSubGroup) => o.id === invoiceLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...invoiceLine.unitType,
                                unitSubGroupId: obj?.id,
                                id: null }, 'unitSubGroupId')}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={invoiceLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={invoiceLine.unitType}
                            onChange={(obj: UnitType | null) => onChange('unitType', obj)}
                            placeholder={messages.unittype}
                            isLoading={invoiceLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default InvoiceLineFormStep1;