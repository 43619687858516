import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import WeightLineApi from '../api/weightLineApi';
import FactorSetWeightLineOutput from '../interfaces/output/factorSetWeightLineOut';
import WeightLineFilters from '../components/factorSets/weight/line/weightLineFilters';
import { createWeightVersionLineError, createWeightVersionLineSuccess, deactivateFactorSetWeightLineError, deactivateFactorSetWeightLineSuccess,
    fetchWeightVersionLinesError, fetchWeightVersionLinesSuccess, fetchFactorSetWeightLineByIdError, fetchFactorSetWeightLineByIdSuccess, updateFactorSetWeightLineError,
    updateFactorSetWeightLineSuccess } from '../reducers/weightLineReducer';

function* doFetchFactorSetWeightLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetWeightLineOutput = yield call(WeightLineApi.fetchFactorSetWeightLineById, lineId);
        yield put(fetchFactorSetWeightLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetWeightLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetWeightLines(action: PayloadAction<{paging: Paging, filters: WeightLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetWeightLineOutput> = yield call(WeightLineApi.fetchFactorSetWeightLines, paging, filters);
    
        yield put(fetchWeightVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchWeightVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetWeightLine(action: PayloadAction<FactorSetWeightLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetWeightLineOutput = yield call(WeightLineApi.createFactorSetWeightLine, line);

        yield put(createWeightVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createWeightVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetWeightLine(action: PayloadAction<FactorSetWeightLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetWeightLineOutput = yield call(WeightLineApi.updateFactorSetWeightLine, line);

        yield put(updateFactorSetWeightLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetWeightLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetWeightLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetWeightLineOutput = yield call(WeightLineApi.deactivateFactorSetWeightLine, lineId);

        yield put(deactivateFactorSetWeightLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetWeightLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetWeightLineById', doFetchFactorSetWeightLineById),
    takeLatest('factorSetLine/fetchWeightVersionLines', doFetchFactorSetWeightLines),
    takeLatest('factorSetLine/createWeightVersionLine', doCreateFactorSetWeightLine),
    takeLatest('factorSetLine/updateFactorSetWeightLine', doUpdateFactorSetWeightLine),
    takeLatest('factorSetLine/deactivateFactorSetWeightLine', doDeactivateFactorSetWeightLine)
];