import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { RootState } from '../setup';
import RoleOut from '../interfaces/output/roleOut';

export type UserRoleState = {
    isLoading: boolean;
    role: RoleOut;
    roles: Pagination<RoleOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: UserRoleState = {
    role: {} as RoleOut,
    roles: { content: [] as Array<RoleOut> } as Pagination<RoleOut>,
    isLoading: false,
    error: undefined
};

export const userRoleSlice = createSlice({
    name: 'userRole',

    initialState,
    reducers: {
        fetchUserRoleById: (state: UserRoleState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchUserRoleByIdSuccess: (state: UserRoleState, action: PayloadAction<RoleOut>) => {
            state.isLoading = false;
            state.role = action.payload;
        },
        fetchUserRoleByIdError: (state: UserRoleState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchUserRoles: (state: UserRoleState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchUserRolesSuccess: (state: UserRoleState, action: PayloadAction<Pagination<RoleOut>>) => {
            state.isLoading = false;
            state.roles = action.payload;
        },
        fetchUserRolesError: (state: UserRoleState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchUserRoleById, fetchUserRoleByIdSuccess, fetchUserRoleByIdError, fetchUserRoles, fetchUserRolesSuccess, fetchUserRolesError } = userRoleSlice.actions;

export const isLoadingUserRoles = (state: RootState): boolean => state.userRoleReducer.isLoading;
export const getUserRole = (state: RootState): RoleOut => state.userRoleReducer.role;
export const getUserRoles = (state: RootState): Pagination<RoleOut> => state.userRoleReducer.roles;

export default userRoleSlice.reducer;