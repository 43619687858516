import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { exportAgreement, isExportingAgreement } from '../../reducers/agreementsReducer';
import { FormattedMessage } from 'react-intl';
import Loader from '../common/widgets/loader';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericDatePicker from '../common/widgets/genericDatePicker';

const messages = {
    agreementId: LanguageUtils.createMessage('Agreement id'),
    download: LanguageUtils.createMessage('download'),
    cancel: LanguageUtils.createMessage('Cancel'),
    forDate: LanguageUtils.createMessage('For date')
};

interface ExportAgreementProps {
    open: boolean;
    setOpenModal: (open: boolean) => void;
    agreementId: number;
}

export default function ExportAgreement({ open, setOpenModal, agreementId } : ExportAgreementProps): JSX.Element {
    const dispatch = useDispatch();
    const [date, setDate] = React.useState<Date | null | undefined>(new Date());
    const isExporting = useSelector(isExportingAgreement);
    const prevIsExporting = WorkflowUtils.usePrevious<boolean>(isExporting);
    const exportPdf = () => {
        if(date) {
            dispatch(exportAgreement({ id: agreementId,
                forDate: date }));
        }
    };

    useEffect(() => {
        if(!isExporting && prevIsExporting === true) {
            setDate(new Date());
            setOpenModal(false);
        }

    }, [isExporting]);
 
    return (

        <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
            <Loader isLoading={isExporting}>
                <>
                    <DialogTitle><FormattedMessage {...messages.agreementId} /></DialogTitle>
                    <DialogContent>
                        <GenericDatePicker
                            name="forDate"
                            label={messages.forDate}
                            value={date}
                            onChange={(_key: string, value: Date | null | undefined) => setDate(value)}
                            type="end"
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenModal(false); setDate(new Date()); }} disabled={!date}><FormattedMessage {...messages.cancel} /></Button>
                        <Button onClick={exportPdf}><FormattedMessage {...messages.download} /></Button>
                    </DialogActions>
                </>
            </Loader>

        </Dialog>
    );
}
