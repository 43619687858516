import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../utils/workflowUtils';
import { isImportingGtsOrders, processGtsOrder } from '../../reducers/gtsOrderReducer';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import { FormType } from '../../constants/constants';
import ImportGtsOrderStep1 from './import/importGtsOrderStep1';
import ImportGtsOrderFilters from '../../interfaces/filters/importGtsOrderFilters';

interface IImportGtsOrderFormProps {
    steps: Array<number>;
    type: FormType;
}

const messages = {
    import: LanguageUtils.createMessage('Import')
};

export default function ImportGtsOrderForm(props: IImportGtsOrderFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { steps, type } = props;
    const [orderNumber, setOrderNumber] = React.useState<string>('');
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsImportingGtsOrders = useSelector(isImportingGtsOrders);
    const prevImportingGtsOrders = WorkflowUtils.usePrevious<boolean>(propsIsImportingGtsOrders);
    const [filters, setFilters] = React.useState<ImportGtsOrderFilters>({});

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if(prevImportingGtsOrders === true && !propsIsImportingGtsOrders) {
                    setRedirectCondition(true);
                    setFilters({} as ImportGtsOrderFilters);
                }
                break;

            default:
        }

        return () => setRedirectCondition(false);
    }, [propsIsImportingGtsOrders]);

    const changeFilter = (attribute: string, value: any) => {
        const newFilter = {
            ...filters,
            [attribute]: value
        };
        setFilters(newFilter);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <ImportGtsOrderStep1 onChange={(o: string) => setOrderNumber(o)} orderNumber={orderNumber} filters={filters} onChangeFilters={changeFilter} />,
            onNext: () => {
                dispatch(processGtsOrder({
                    orderNumber,
                    filters
                }));
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Import:
                return messages.import;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.GTS_ORDERS}`}
        />
    );
}
