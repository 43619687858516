import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetHoursVersionApi from '../api/factorSetHoursVersionApi';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { cloneHoursVersionError, cloneHoursVersionSuccess, fetchHoursVersionByIdError,
    fetchHoursVersionByIdSuccess, fetchHoursVersionsError, fetchHoursVersionsSuccess, updateHoursVersionError, updateHoursVersionStatusError,
    updateHoursVersionStatusSuccess, updateHoursVersionSuccess } from '../reducers/factorSetHoursVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchFactorSetHoursVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetHoursVersionApi.fetchFactorSetHoursVersionById, agreementVersionId);

        yield put(fetchHoursVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchHoursVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetHoursVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetHoursVersionApi.fetchFactorSetHoursVersions, id, paging);
        yield put(fetchHoursVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchHoursVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetHoursVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetHoursVersionApi.updateFactorSetHoursVersionStatus, agreementVersion);

        yield put(updateHoursVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateHoursVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetHoursVersion(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetHoursVersionApi.updateFactorSetHoursVersion, agreementVersion);

        yield put(updateHoursVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateHoursVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetHoursVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetHoursVersionApi.cloneFactorSetHoursVersion, toVersionId, fromVersionId);

        yield put(cloneHoursVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneHoursVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchHoursVersionById', doFetchFactorSetHoursVersion),
    takeLatest('factorSetVersion/fetchHoursVersions', doFetchFactorSetHoursVersions),
    takeLatest('factorSetVersion/updateHoursVersionStatus', doUpdateFactorSetHoursVersionStatus),
    takeLatest('factorSetVersion/updateHoursVersion', doUpdateFactorSetHoursVersion),
    takeLatest('factorSetVersion/cloneHoursVersion', doCloneFactorSetHoursVersion)
];