import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import FactorSetCancellationLineOut from '../interfaces/output/factorSetCancellationLineOut';
import FactorSetCancellationLineIn from '../interfaces/input/factorSetCancellationLineIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetCancellationVersionApi {
    static fetchFactorSetCancellationVersionById = async(factorSetVersionId: string): Promise<FactorSetVersionOut> => {
        const { data } = await http.get<FactorSetVersionOut>(`factorsets/cancellation/versions/${factorSetVersionId}`);

        return data;
    };

    static fetchFactorSetCancellationVersions = async(factorId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/cancellation/${factorId}/versions`);

        const { data } = await http.get<Array<FactorSetVersionOut>>(url);

        return data;
    };

    static createFactorSetCancellationVersionLines= async(versionLine: FactorSetCancellationLineOut): Promise<FactorSetCancellationLineOut> => {
        const newLine: FactorSetCancellationLineIn = {
            name: versionLine.name,
            cancellationTypeId: versionLine.cancellationType?.id,
            factor: versionLine.factor,
            surcharge: versionLine.surcharge
        };

        const { data } = await http.post<FactorSetCancellationLineIn, FactorSetCancellationLineOut>( `factorsets/cancellation/versions/${versionLine.factorSetVersionId}/lines`, newLine);

        return data;
    }

    static updateFactorSetCancellationVersionStatus = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/cancellation/versions/${factorSetVersion.id}/status/${factorSetVersion.status?.id}`);

        return data;
    }

    static updateFactorSetCancellationVersion = async(factorSetVersion: FactorSetVersionOut): Promise<FactorSetVersionOut> => {
        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSetVersion.validFromDate,
            validToDate: factorSetVersion.validToDate,
            statusId: factorSetVersion.status?.id
        };
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/cancellation/versions/${factorSetVersion.id}`, newVersion);

        return data;
    }

    static cloneFactorSetCancellationVersion = async(toVersionId: number, fromVersionId: number): Promise<FactorSetVersionOut> => {
        
        const { data } = await http.put<FactorSetVersionIn, FactorSetVersionOut>(`factorsets/cancellation/versions/${toVersionId}/copyFrom/${fromVersionId}`);

        return data;
    }
}