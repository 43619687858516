import React, { forwardRef, useEffect, useMemo } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import WorkflowUtils from '../../../../utils/workflowUtils';
import FactorSetCancellationLineOutput from '../../../../interfaces/output/factorSetCancellationLineOut';
import { fetchCancellationTypes, getCancellationTypes } from '../../../../reducers/cancellationTypeReducer';
import PageUtils from '../../../../utils/pageUtils';
import CancellationType from '../../../../interfaces/output/cancellationType';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import UnitType from '../../../../interfaces/output/unitType';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import { ensure } from '../../../../utils/arrayUtils';
import CancellationLineFilters from '../line/cancellationLineFilters';
import GenericTextField from '../../../common/widgets/genericTextField';
import { fetchCancellationVersionLines, getCancellationLines, isCreatingCancellationLine, isLoadingCancellationLine, isUpdatingCancellationLine } from '../../../../reducers/cancellationLineReducer';
import FactorSetCancellationLineStep1Validation from '../line/validations/factorSetCancellationLineStep1Validation';
import Currency from '../../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';

interface CancellationLineFormStep1Props {
    cancellationLine: FactorSetCancellationLineOutput;
    factorSetCancellationId?: number;
    factorSetVersionId?: number;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    CANCELLATION_TYPE: 'cancellationType',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    unitgroup: LanguageUtils.createMessage('Unit group'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    cancellationTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_CANCELLATION_TYPE_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT')
};

const AddCancellationLineFormStep1 = forwardRef((props: CancellationLineFormStep1Props, ref: any): JSX.Element => {
    const { cancellationLine, onChange, factorSetVersionId, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingCancellationLine), useSelector(isLoadingCancellationLine), useSelector(isUpdatingCancellationLine)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const cancellationTypes = useSelector(getCancellationTypes).content;
    const cancellationLines = useSelector(getCancellationLines).content;
    const currencies = useSelector(getCurrencies).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    WorkflowUtils.setHandle(ref, null);
    const prevCancellationLine = WorkflowUtils.usePrevious<FactorSetCancellationLineOutput>(cancellationLine);

    useEffect(() => {
        dispatch(fetchCancellationTypes({ paging }));
        dispatch(fetchUnitGroups({ paging }));
    }, [dispatch]);
    
    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCancellationVersionLines({
            paging,
            filters: { factorSetVersionId: factorSetVersionId } as CancellationLineFilters }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(cancellationLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(cancellationLine.unitType?.unitGroupId),
                paging }));
            if(cancellationLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(cancellationLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevCancellationLine) {
            if(cancellationLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(cancellationLine?.unitType?.unitGroupId),
                    paging }));
                if(prevCancellationLine.id === cancellationLine.id) {
                    onChange('unitType', { ...cancellationLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                    dispatch(resetSubgroups());
                    dispatch(resetUnitTypes());
                }
            }
        }
    }, [cancellationLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevCancellationLine) {
            if(cancellationLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(cancellationLine?.unitType?.unitSubGroupId),
                    paging }));
                if(prevCancellationLine.id === cancellationLine.id) {
                    onChange('unitType', { ...cancellationLine.unitType,
                        id: null });
                    dispatch(resetUnitTypes());
                }
            }
        }
    }, [cancellationLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!cancellationLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const filteredTypes = () => {
        return useMemo(() => {
            return cancellationTypes.filter(dt => (!cancellationLine.unitType?.id && (!cancellationLines.find(dl => dl.cancellationType?.id === dt.id && !dl.unitType)))
        || !cancellationLines.find(dl => dl.id !== cancellationLine?.id && dl.cancellationType?.id === dt.id && dl.unitType?.id === cancellationLine.unitType?.id));
        }, [cancellationTypes, cancellationLine, cancellationLines]);
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetCancellationLineStep1Validation.validateName(cancellationLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = cancellationLine.surcharge ? FactorSetCancellationLineStep1Validation.validateInputNumber(cancellationLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetCancellationLineStep1Validation.validateInputNumber(cancellationLine.factor);
                break;
            }
            case ATTRIBUTES.CANCELLATION_TYPE: {
                isValid = FactorSetCancellationLineStep1Validation.validateRequiredNumberWithMaxLength(cancellationLine.cancellationType?.id);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = cancellationLine.surcharge ? FactorSetCancellationLineStep1Validation.validateInputNumber(cancellationLine.surchargeCurrency?.id) : true;
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={cancellationLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<CancellationType>
                            options={filteredTypes()}
                            value={cancellationLine.cancellationType}
                            onChange={(obj: CancellationType | null) => onChange('cancellationType', obj)}
                            placeholder={messages.cancellationType}
                            error={setValidationState(ATTRIBUTES.CANCELLATION_TYPE)}
                            required
                            helperText={messages.cancellationTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={cancellationLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === cancellationLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...cancellationLine.unitType,
                                unitGroupId: obj?.id }, 'unitGroupId')}
                            placeholder={messages.unitgroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={cancellationLine.unitType?.unitSubGroupId}
                            compareFn={(o: UnitSubGroup) => o.id === cancellationLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...cancellationLine.unitType,
                                unitSubGroupId: obj?.id }, 'unitSubGroupId')}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={cancellationLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={cancellationLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === cancellationLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...cancellationLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unitType}
                            isLoading={cancellationLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={cancellationLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        label={messages.factor}
                        type="number"
                        value={cancellationLine.factor}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.FACTOR)}
                        required
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        label={messages.surcharge}
                        type="number"
                        value={cancellationLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={cancellationLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={cancellationLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddCancellationLineFormStep1;