import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import SimulationFilters from '../interfaces/output/simulationFilters';
import SimulationOut from '../interfaces/output/simulationOut';
import SimulationIn from '../interfaces/input/simulationIn';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class SimulationApi {
    static fetchSimulationById = async(simulationId: string): Promise<SimulationOut> => {
        const { data } = await http.get<SimulationOut>(`simulation/${simulationId}`);

        return data;
    };

    static fetchSimulations = async(paging: Paging, filters: SimulationFilters): Promise<Pagination<SimulationOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/simulation');
        const { data } = await http.get<Pagination<SimulationOut>>(url);

        return data;
    };

    static createSimulation = async(simulation: SimulationOut): Promise<SimulationOut> => {
        const newSimulation: SimulationIn = {
            simulationName: simulation?.simulationName,
            simulationDate: simulation?.simulationDate,
            agreementId1: simulation?.agreement1?.id,
            pricingDate1: simulation?.pricingDate1,
            agreementId2: simulation.agreement2?.id,
            pricingDate2: simulation?.pricingDate2,
            simulationStatusId: simulation?.simulationStatus?.id,
            customerId: simulation.customer?.id,
            fromDate: simulation?.fromDate,
            toDate: simulation?.simulationDate,
            customerCorporateId: simulation.customerCorporate?.id
        };

        const { data } = await http.post<SimulationIn, SimulationOut>('simulation', newSimulation);

        return data;
    }

    static updateSimulation = async(simulation: SimulationOut): Promise<SimulationOut> => {
        const newSimulation: SimulationIn = {
            simulationName: simulation?.simulationName,
            simulationDate: simulation?.simulationDate,
            agreementId1: simulation?.agreement1?.id,
            pricingDate1: simulation?.pricingDate1,
            agreementId2: simulation.agreement2?.id,
            pricingDate2: simulation?.pricingDate2,
            simulationStatusId: simulation?.simulationStatus?.id,
            customerId: simulation.customer?.id,
            fromDate: simulation?.fromDate,
            toDate: simulation?.simulationDate,
            customerCorporateId: simulation.customerCorporate?.id
        };
        
        const { data } = await http.put<SimulationIn, SimulationOut>(`simulation/${simulation.id}`, newSimulation);

        return data;
    }

    static deleteSimulation = async(simulation: SimulationOut): Promise<SimulationOut> => {
        const { data } = await http.delete<SimulationOut>(`simulation/${simulation.id}`);

        return data;
    }
}