import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import SurchargeArea from '../interfaces/output/surchargeArea';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class SurchargeAreaApi {
    static fetchSurchargeAreaById = async(surchargeAreaId: string): Promise<SurchargeArea> => {
        const { data } = await http.get<SurchargeArea>(`surchargearea/${surchargeAreaId}`);

        return data;
    };

    static fetchSurchargeAreas = async(paging: Paging): Promise<Pagination<SurchargeArea>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/surchargearea');

        const { data } = await http.get<Pagination<SurchargeArea>>(url);

        return data;
    };
}
