import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OUSStatus from '../interfaces/output/ousStatus';
import OusStatusApi from '../api/ousStatusApi';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import { createOUSStatusError, createOUSStatusSuccess, deleteOUSStatusError, deleteOUSStatusSuccess, fetchOUSStatusByIdError, fetchOUSStatusByIdSuccess, fetchOUSStatusesError,
    fetchOUSStatusesSuccess, updateOUSStatusError, updateOUSStatusSuccess } from '../reducers/ousStatusReducer';

function* doFetchOUSStatus(action: PayloadAction<string>) {
    const serviceStatusId = action.payload;
    try {
        const response: OUSStatus = yield call(OusStatusApi.fetchOUSStatusById, serviceStatusId);
        yield put(fetchOUSStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOUSStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchOUSStatuses(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<OUSStatus> = yield call(OusStatusApi.fetchOUSStatuses, paging);
        yield put(fetchOUSStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOUSStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateOUSStatus(action: PayloadAction<OUSStatus>) {
    const serviceStatus = action.payload;

    try {
        const response: OUSStatus = yield call(OusStatusApi.updateOUSStatus, serviceStatus);

        yield put(updateOUSStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateOUSStatusError(error as HttpErrorResponse));
    }
}

function* doCreateOUSStatus(action: PayloadAction<OUSStatus>) {
    const serviceStatus = action.payload;

    try {
        const response: OUSStatus = yield call(OusStatusApi.createOUSStatus, serviceStatus);

        yield put(createOUSStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(createOUSStatusError(error as HttpErrorResponse));
    }
}

function* doDeleteServiceOUSStatus(action: PayloadAction<OUSStatus>) {
    const serviceStatus = action.payload;
    
    try {
        const response: OUSStatus = yield call(OusStatusApi.deleteOUSStatus, serviceStatus);

        yield put(deleteOUSStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteOUSStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('ousStatus/fetchOUSStatusById', doFetchOUSStatus),
    takeLatest('ousStatus/fetchOUSStatuses', doFetchOUSStatuses),
    takeLatest('ousStatus/updateOUSStatus', doUpdateOUSStatus),
    takeLatest('ousStatus/createOUSStatus', doCreateOUSStatus),
    takeLatest('ousStatus/deleteOUSStatus', doDeleteServiceOUSStatus)
];

