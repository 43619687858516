import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import Terminal from '../../../interfaces/output/terminal';
import { createTerminal, fetchTerminalById, getTerminal, isCreatingTerminal, isLoadingTerminal, isUpdatingTerminal, updateTerminal } from '../../../reducers/terminalReducer';
import TerminalStep1Validation from './validations/terminalStep1Validation';
import TerminalFormStep1 from './terminalFormStep1';

interface ITerminalFormProps {
    terminalId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit terminal'),
    add: LanguageUtils.createMessage('Add terminal')
};

export default function UserForm(props: ITerminalFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { terminalId, steps, type } = props;
    const [terminal, setTerminal] = React.useState<Terminal>({ id: terminalId } as Terminal);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propTerminal = useSelector(getTerminal);
    const propsIsUpdatingTerminal = useSelector(isUpdatingTerminal);
    const prevIsUpdatingTerminal = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingTerminal);
    const propsIsCreatingTerminal = useSelector(isCreatingTerminal);
    const prevIsCreatingTerminal = WorkflowUtils.usePrevious<boolean>(propsIsCreatingTerminal);
    const propsIsLoadingTerminal = useSelector(isLoadingTerminal);
    const prevIsLoadingTerminal = WorkflowUtils.usePrevious<boolean>(propsIsLoadingTerminal);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingTerminal === true && !propsIsUpdatingTerminal) || (prevIsCreatingTerminal && !propsIsLoadingTerminal)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingTerminal, prevIsCreatingTerminal]);

    useEffect(() => {
        if(terminalId) {
            dispatch(fetchTerminalById(terminalId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingTerminal && !propsIsLoadingTerminal) {
            setTerminal(propTerminal);
        }

    }, [propsIsLoadingTerminal]);

    const onChange = (attribute: string, value: any) => {
        const newTerminal = { ...terminal } as Terminal;
        (newTerminal as any)[attribute] = value;

        setTerminal(newTerminal);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <TerminalFormStep1 onChange={onChange} terminal={terminal} />,
            validationFn: () => TerminalStep1Validation.validateUserForm(terminal),
            onNext: () => {
                if(!terminal.id) {
                    dispatch(createTerminal(terminal));
                }
                else {
                    dispatch(updateTerminal(terminal));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.TERMINALS}`;

            case FormType.Edit:
                return `/${UrlConstants.TERMINALS}/${terminal.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
