import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetVersionStatusApi {
    static fetchFactorSetVersionStatusById = async(versionStatusId: string): Promise<FactorSetVersionStatusOut> => {
        const { data } = await http.get<FactorSetVersionStatusOut>(`factorsetversionstatus/${versionStatusId}`);

        return data;
    };

    static fetchFactorSetVersionStatuses = async(paging: Paging): Promise<Pagination<FactorSetVersionStatusOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/factorsetversionstatus');

        const { data } = await http.get<Pagination<FactorSetVersionStatusOut>>(url);

        return data;
    };

    static updateFactorSetVersionStatus = async(versionStatus: FactorSetVersionStatusOut): Promise<FactorSetVersionStatusOut> => {
        const newFactorSetVersionStatusOut = {
            code: versionStatus?.code,
            name: versionStatus?.name
        };
        
        const { data } = await http.put<any, FactorSetVersionStatusOut>(`factorsetversionstatus/${versionStatus.id}`, newFactorSetVersionStatusOut);

        return data;
    }

    static deactivateFactorSetVersionStatus = async(versionStatus: FactorSetVersionStatusOut): Promise<FactorSetVersionStatusOut> => {
        const newFactorSetVersionStatusOut = {
            code: versionStatus?.code,
            name: versionStatus?.name
        };

        const { data } = await http.put<any, FactorSetVersionStatusOut>(`factorsetversionstatus/${versionStatus.id}`, newFactorSetVersionStatusOut);

        return data;
    }
}