import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { Card, CardContent } from '@mui/material';
import AddCustomerForm from './addCustomerForm';
import { connect } from 'react-redux';
import { resetCustomers } from '../../reducers/customerReducer';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { FormType } from '../../constants/constants';

interface IAddCustomerProps {
    classes: any;
    theme: Theme;
    match: any;
    resetCustomers: any;
    steps: Array<number>;
    customerId?: number;
    type: FormType;
    componentRoles: Array<string>;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetCustomers: () => dispatch(resetCustomers())
});

const mapStoreToProps = (store: RootState, ownProps: IAddCustomerProps) => {
    return {
        customerId: ownProps.match.params?.customerId ? Number(ownProps.match.params?.customerId) : undefined
    };
};

class AddCustomer extends Component<IAddCustomerProps> {
    componentWillUnmount() {
        const { resetCustomers } = this.props;

        resetCustomers();
    }

    render() {
        const { classes, steps, customerId, type, componentRoles } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddCustomerForm customerId={customerId} steps={steps} type={type} componentRoles={componentRoles} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddCustomer));