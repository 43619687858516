import Pagination from '../interfaces/common/pagination';

function ensure<T>(argument: T | undefined | null, message = 'The value was not found there.'): T {
    if(argument === undefined || argument === null) {
        throw new TypeError(message);
    }
  
    return argument;
}

function addToPaginatedList<T>(list: Pagination<T>, item: T): Pagination<T> {
    const newContent = Object.assign([], list.content);
    newContent.push(item);
    
    return { ...list,
        content: newContent };
}

function removeFromPaginatedList<T>(list: Pagination<T>, item: T): Pagination<T> {
    const newContent = Object.assign([], list.content);
    newContent.splice(newContent.indexOf(item), 1);
    
    return { ...list,
        content: newContent };
}

export { ensure, addToPaginatedList, removeFromPaginatedList };