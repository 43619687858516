import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import Paging from '../../interfaces/common/paging';
import { fetchAgreements, getAgreements, isLoadingAgreements } from '../../reducers/agreementsReducer';
import AgreementList from '../agreements/agreementList';
import AgreementFilters from '../../interfaces/filters/agreementFilters';
import { List } from 'immutable';
import WorkflowUtils from '../../utils/workflowUtils';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddCustomerAgreementStep1Props {
    customerAgreements: List<number>;
    onChange: any;
}

const AddCustomerAgreementStep1 = forwardRef((props: AddCustomerAgreementStep1Props, ref: any): JSX.Element => {
    const { onChange } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const agreements = useSelector(getAgreements);
    const propsIsLoadingAgreements = useSelector(isLoadingAgreements);
    useEffect(() => {
        dispatch(fetchAgreements({
            paging,
            filters: { atLeastOneActiveVersion: true } as AgreementFilters
        }));
    }, [dispatch]);
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        revenueThisYear: false,
        revenueLastYear: false,
        versions_defaultTransitDiscount: false,
        versions_validFromDate: false,
        versions_validToDate: false,
        validity: false,
        priorityRank: false,
        active: false
    });

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchAgreements({
                paging: newServerPagination,
                filters: {
                    name: searchTerm,
                    atLeastOneActiveVersion: true
                } as AgreementFilters
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchAgreements({
                        paging: paging,
                        filters: {
                            name: searchTerm,
                            atLeastOneActiveVersion: true
                        } as AgreementFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <AgreementList
                    multipleSelection={true}
                    isLoading={propsIsLoadingAgreements}
                    rows={agreements}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (event: any) => setSearchTerm(event.target.value),
                            clearSearch: () => setSearchTerm('')
                        }
                    }}
                    disableLinks
                    checkSelection={(value: number) => onChange(value)}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchAgreements({
                            paging: newPage,
                            filters: {
                                name: searchTerm,
                                atLeastOneActiveVersion: true
                            } as AgreementFilters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    hiddenColumns={['revenueThisYear', 'revenueLastYear', 'priorityRank', 'discount', 'status', 'validFromDate', 'validToDate', 'relation']}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddCustomerAgreementStep1;