import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FileSaver from 'file-saver';
import D365InvoiceApi from '../api/d365InvoiceApi';
import { exportInvoicesError, exportInvoicesSuccess } from '../reducers/d365invoiceReducer';

function* doExportInvoice(action: PayloadAction<number>) {
    const id = action.payload;
    try {
        const response: Blob = yield call(D365InvoiceApi.exportInvoice, id);
        FileSaver.saveAs(response, 'invoice-download.csv');

        yield put(exportInvoicesSuccess());
    }

    catch (error: unknown) {
        yield put(exportInvoicesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('d365Invoice/exportInvoices', doExportInvoice)
];

