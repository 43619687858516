import { http } from '../utils/http';
import Status from '../interfaces/output/customerStatus';
import Pagination from '../interfaces/common/pagination';
import RoleOut from '../interfaces/output/roleOut';

export default class UserRoleApi {
    static fetchUserRoleById = async(roleId: string): Promise<RoleOut> => {
        const { data } = await http.get<Status>(`/roles/${roleId}`);

        return data;
    };

    static fetchUserRoles = async(): Promise<Pagination<RoleOut>> => {
        const { data } = await http.get<Pagination<RoleOut>>('/roles');

        return data;
    };
}
