import { put, takeLatest, call } from 'redux-saga/effects';
import { fetchDashboardMessagesSuccess, fetchDashboardMessagesError, exportDashboardMessagesError, exportDashboardMessagesSuccess } from '../reducers/dashboardMessageReducer';
import Pagination from '../interfaces/common/pagination';
import DashboardMessage from '../interfaces/output/dashboardMessage';
import DashboardMessageApi from '../api/dashboardMessageApi';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import DashboardMessageFilters from '../interfaces/filters/dashboardMessageFilters';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FileSaver from 'file-saver';

function* doFetchDashboardMessages(action: PayloadAction<{ paging: Paging, filters: DashboardMessageFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<DashboardMessage> = yield call(DashboardMessageApi.fetchDashboardMessages, paging, filters);

        yield put(fetchDashboardMessagesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDashboardMessagesError(error as HttpErrorResponse));
    }
}

function* doExportDashboardMessages(action: PayloadAction<{ filters: DashboardMessageFilters }>) {
    const { filters } = action.payload;
    try {
        const response: Blob = yield call(DashboardMessageApi.exportDashboardMessages, filters);
        FileSaver.saveAs(response, 'pdf-download.pdf');

        yield put(exportDashboardMessagesSuccess());
    }

    catch (error: unknown) {
        yield put(exportDashboardMessagesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('dashboardMessage/fetchDashboardMessages', doFetchDashboardMessages),
    takeLatest('dashboardMessage/exportDashboardMessages', doExportDashboardMessages)
];

