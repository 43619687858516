import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import Filters from '../../../interfaces/output/productFilters';
import UnitGroup from '../../../interfaces/output/unitGroup';
import ProductArena from '../../../interfaces/output/productArena';
import Terminal from '../../../interfaces/output/terminal';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import { fetchUnitGroups, getUnitGroups } from '../../../reducers/unitGroupReducer';
import { fetchProductArenas, getProductArenas } from '../../../reducers/productArenaReducer';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchProductTypes, getProductTypes } from '../../../reducers/productTypesReducer';
import ProductType from '../../../interfaces/output/productType';

interface IFilterProps {
    filters: Filters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    seller: LanguageUtils.createMessage('Seller'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    productArena: LanguageUtils.createMessage('Product arena'),
    productType: LanguageUtils.createMessage('Product type'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function ProductServiceFilters(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const unitGroups = useSelector(getUnitGroups).content;
    const productArenas = useSelector(getProductArenas).content;
    const productTypes = useSelector(getProductTypes).content;
    const terminals = useSelector(getTerminals).content;

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchProductArenas({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchProductTypes({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={filters.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroupId', obj?.id)}
                            placeholder={messages.unitGroup}
                            compareFn={(o: UnitGroup) => o.id === filters.unitGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ProductArena>
                            options={productArenas}
                            value={filters.productArenaId}
                            onChange={(obj: ProductArena | null) => onChange('productArenaId', obj?.id)}
                            placeholder={messages.productArena}
                            compareFn={(o: ProductArena) => o.id === filters.productArenaId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.fromTerminalId}
                            onChange={(obj: Terminal | null) => onChange('fromTerminalId', obj?.id)}
                            placeholder={messages.fromTerminal}
                            compareFn={(o: Terminal) => o.id === filters.fromTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toTerminalId}
                            onChange={(obj: Terminal | null) => onChange('toTerminalId', obj?.id)}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal) => o.id === filters.toTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ProductType>
                            options={productTypes}
                            value={filters.productTypeId}
                            onChange={(obj: ProductType | null) => onChange('productTypeId', obj?.id)}
                            placeholder={messages.productType}
                            compareFn={(o: ProductType) => o.id === filters.productTypeId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}