import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
// import UrlConstants from '../../constants/UrlConstants';
import SurchargeRateList from './surchargeRateList';
import SurchargeRate from '../../interfaces/output/surchargeRate';
import { fetchSurchargeAreas, isLoadingSurchargeArea } from '../../reducers/surchargeAreaReducer';
import { fetchSurchargeTypes, isLoadingSurchargeType } from '../../reducers/surchargeTypeReducer';
import { fetchSurchargeRateUnits, isLoadingSurchargeRateUnit } from '../../reducers/surchargeRateUnitReducer';
import { fetchSurchargeRates, getSurchargeRates, isLoadingSurchargeRates, resetSurchargeRates } from '../../reducers/surchargeRateReducer';
import SurchargeRateFilters from '../../interfaces/filters/surchargeRateFilters';
import LanguageUtils from '../../utils/LanguageUtils';
import SurchargeRateFilterComponent from './surchargeRateFilterComponent';
import UrlConstants from '../../constants/UrlConstants';

interface ISurchargeRatesProps {
    surchargeRates: Pagination<SurchargeRate>;
    classes: any;
    fetchSurchargeRates: any;
    isLoading: boolean;
    onChange: () => void;
    resetSurchargeRates: () => void;
    fetchImportExportCodes: any;
}

interface ISurchargeRatesState {
    filters: SurchargeRateFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    services: LanguageUtils.createMessage('Surcharge Rates'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchSurchargeRates: (paging: Paging, filters: SurchargeRateFilters) => dispatch(fetchSurchargeRates({
        paging,
        filters
    })),
    fetchSurchargeAreas: (paging: Paging) => dispatch(fetchSurchargeAreas({ paging })),
    fetchSurchargeTypes: (paging: Paging) => dispatch(fetchSurchargeTypes({ paging })),
    fetchSurchargeRateUnits: (paging: Paging) => dispatch(fetchSurchargeRateUnits({ paging })),
    resetSurchargeRates: () => dispatch(resetSurchargeRates())

});

const mapStoreToProps = (store: RootState) => {
    return {
        surchargeRates: getSurchargeRates(store),
        isLoading: isLoadingSurchargeRates(store),
        isLoadingFilters: isLoadingSurchargeType(store) || isLoadingSurchargeArea(store) || isLoadingSurchargeRateUnit(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class SurchargeRates extends Component<ISurchargeRatesProps, ISurchargeRatesState> {
    surchargeRatesListRef: any;
    constructor(props: ISurchargeRatesProps) {
        super(props);
        this.surchargeRatesListRef = React.createRef();

        this.state = {
            filters: {} as SurchargeRateFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.services,
                icon: <FontAwesomeIcon icon="file-invoice-dollar" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchSurchargeRates } = this.props;
        const { paging, filters } = this.state;

        fetchSurchargeRates(paging, filters);
    }

    componentWillUnmount() {
        const { resetSurchargeRates } = this.props;
        resetSurchargeRates();
    }

    applyFilters = () => {
        const { fetchSurchargeRates } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchSurchargeRates(newPagination, filters);

        if(this.surchargeRatesListRef !== null && this.surchargeRatesListRef !== undefined &&
            this.surchargeRatesListRef.current !== null && this.surchargeRatesListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.surchargeRatesListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as SurchargeRateFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchSurchargeRates } = this.props;
        const { paging } = this.state;

        const newFilters = {
        } as SurchargeRateFilters;

        const newSortModel = [{
            field: 'id',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchSurchargeRates(newPagination, newFilters);

        if(this.surchargeRatesListRef !== null && this.surchargeRatesListRef !== undefined &&
            this.surchargeRatesListRef.current !== null && this.surchargeRatesListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.surchargeRatesListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchSurchargeRates } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchSurchargeRates(newPaging, filters);

        }
    }

    render() {
        const { classes, surchargeRates, fetchSurchargeRates, isLoading } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <SurchargeRateFilterComponent
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.SURCHARGE_RATES}/add`
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <SurchargeRateList rows={surchargeRates} isLoading={isLoading}
                                    ref={this.surchargeRatesListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchSurchargeRates(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(SurchargeRates));