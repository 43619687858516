import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Paging from '../../../interfaces/common/paging';
import ProductList from '../../products/productList';
import { fetchPriceListById, getPriceList, isLoadingPriceList } from '../../../reducers/priceListReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import { ensure } from '../../../utils/arrayUtils';
import PricelistVersionProduct from '../../../interfaces/output/pricelistVersionProduct';
import { fetchProducts, getProducts, isLoadingProducts } from '../../../reducers/productReducer';
import ProductFilters from '../../../interfaces/output/productFilters';
import { fetchPricelistVersionProducts, getPricelistVersionProducts } from '../../../reducers/pricelistVersionProductReducer';
import { GridColumnVisibilityModel, GridRowParams } from '@mui/x-data-grid';
import { GridSortModel, GridSortDirection, GridSortItem } from '@mui/x-data-grid';

interface AddPricelistVersionProductStep2Props {
    onChange: (pricelistVersionProduct: PricelistVersionProduct) => void;
    priceListId?: number;
    priceListVersionId?: number;
    pricelistVersionProduct: PricelistVersionProduct
}

const AddPricelistVersionProductStep2 = forwardRef((props: AddPricelistVersionProductStep2Props, ref: any): JSX.Element => {
    const { onChange, priceListId, priceListVersionId, pricelistVersionProduct } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const products = useSelector(getProducts);
    const propsPricelist = useSelector(getPriceList);
    const propsPricelistVersionProducts = useSelector(getPricelistVersionProducts).content;
    const propsIsLoadingPricelist = useSelector(isLoadingPriceList);
    const prevIsLoadingPricelist = WorkflowUtils.usePrevious<boolean>(propsIsLoadingPricelist);
    const isLoading = useSelector(isLoadingProducts) || propsIsLoadingPricelist;
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({ });
    WorkflowUtils.setHandle(ref, null);

    const existingProducts = propsPricelistVersionProducts.map(pricelistProduct => pricelistProduct.product);
    // const filteredProduct = products.content.filter(product => !existingProducts.includes(product.id));

    useEffect(() => {
        dispatch(fetchPriceListById({ id: ensure(priceListId),
            priceListVersionId }));
        dispatch(fetchPricelistVersionProducts({ paging,
            filters: { pricelistVersionId: priceListVersionId } }));

    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingPricelist === true && !propsIsLoadingPricelist ) {
            dispatch(fetchProducts({ paging,
                filters: { unitGroupId: propsPricelist.unitGroup?.id,
                    productArenaId: propsPricelist.productArena?.id ,
                    productTypeId: propsPricelist.productType?.id } as ProductFilters }));
        }
    }, [propsIsLoadingPricelist]);

    const onSortChange = (newModel: GridSortModel) => {
        
        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };
            setPaging(newPaging);
            setSortModel(newModel);

            dispatch(fetchProducts({
                paging: newPaging,
                filters: { name: searchTerm,
                    unitGroupId: propsPricelist.unitGroup?.id,
                    productArenaId: propsPricelist.productArena?.id } as ProductFilters
            }));
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchProducts({
                        paging: paging,
                        filters: { name: searchTerm,
                            unitGroupId: propsPricelist.unitGroup?.id,
                            productArenaId: propsPricelist.productArena?.id,
                            productTypeId: propsPricelist.productType?.id } as ProductFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <ProductList
                    isLoading={isLoading}
                    isRowSelectable={(params: GridRowParams) => !existingProducts.find(product => params.row.id === product?.id)}
                    rows={products}
                    checkSelection={(value: number) => onChange({ ...pricelistVersionProduct,
                        product: { ...pricelistVersionProduct.product,
                            id: value } })}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchProducts({
                            paging: newPage,
                            filters: { name: searchTerm,
                                unitGroupId: propsPricelist.unitGroup?.id,
                                productArenaId: propsPricelist.productArena?.id } as ProductFilters
                        }));
                        setPaging(newPage);
                    }}
                    disableLinks
                    onSortModelChange={(sortModel: GridSortModel) => onSortChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddPricelistVersionProductStep2;
  