import React, { useEffect } from 'react';
import AddCorporateStep1 from './add/addCorporateStep1';
import { useDispatch, useSelector } from 'react-redux';
import { createCorporate, fetchCustomerCorporateById, getCorporate, isCreatingCorporate, isUpdatingCorporate, updateCorporate } from '../../reducers/customerCorporateReducer';
import GenericStepper from '../common/widgets/genericStepper';
import GenericStep from '../../interfaces/common/genericStep';
import Corporate from '../../interfaces/output/customerCorporate';
import UrlConstants from '../../constants/UrlConstants';
import WorkflowUtils from '../../utils/workflowUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';

interface IAddCorporateFormProps {
    corporateId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit corporate'),
    add: LanguageUtils.createMessage('Add corporate')
};

export default function AddCorporateForm(props: IAddCorporateFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { corporateId, steps, type } = props;
    const [corporate, setCorporate] = React.useState<Corporate>({} as Corporate);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsCorporate = useSelector(getCorporate);
    const propsIsUpdatingCorporate = useSelector(isUpdatingCorporate);
    const prevIsUpdatingCorporate = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCorporate);
    const propsIsCreatingCorporate = useSelector(isCreatingCorporate);
    const prevIsCreatingCorporate = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCorporate);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingCorporate === true && !propsIsUpdatingCorporate) || (prevIsCreatingCorporate && !propsIsCreatingCorporate)) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingCorporate, propsIsUpdatingCorporate]);
    
    useEffect(() => {
        if(corporateId) {
            dispatch(fetchCustomerCorporateById(corporateId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsCorporate.id) {
            setCorporate(propsCorporate);
        }

    }, [propsIsUpdatingCorporate, propsCorporate.id]);

    const onChange = (attribute: string, value: any) => {
        const newCorporate = { ...corporate } as Corporate;
        (newCorporate as any)[attribute] = value;

        setCorporate(newCorporate);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddCorporateStep1 onChange={onChange} corporate={corporate} />,
            onNext: () => {
                if(!corporate.id) {
                    dispatch(createCorporate(corporate));
                }
                else {
                    dispatch(updateCorporate(corporate));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.CORPORATES}`}
        />
    );
}
  