import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MessageDescriptor, useIntl } from 'react-intl';
import PageUtils from '../../../utils/pageUtils';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import FactorSetFilter from '../../../interfaces/filters/baseFilter';
import { getFactorSetPriorities, isLoadingFactorSetPriorities, resetFactorSetPriorities, fetchFactorSetPriorities } from '../../../reducers/factorSetPriorityReducer';
import { fetchFactorSetDepartures, getFactorSetDepartures, isLoadingFactorSetDepartures, resetFactorSetDepartures } from '../../../reducers/factorSetDepartureReducer';
import { fetchFactorSetCancellations, getFactorSetCancellations, isLoadingFactorSetCancellations, resetFactorSetCancellations } from '../../../reducers/factorSetCancellationReducer';
import { fetchFactorSetHours, getFactorSetHours, isLoadingFactorSetHours, resetFactorSetHours } from '../../../reducers/factorSetHoursReducer';
import { fetchFactorSetWeights, getFactorSetWeights, isLoadingFactorSetWeights, resetFactorSetWeights } from '../../../reducers/factorSetWeightReducer';
import { getFactorSetCalendars, isLoadingFactorSetCalendars } from '../../../reducers/factorSetCalendarReducer';
import { getFactorSetDangerousGoods, isLoadingFactorSetDangerousGoods, resetFactorSetDangerousGoods } from '../../../reducers/factorSetDangerousGoodsReducer';

export enum FactorSetPickerType {
    Cancellation,
    Weight,
    Priority,
    Departure,
    Hours,
    Calendar,
    DangerousGoods
}

interface IFactorSetPickerProps {
    value?: FactorSetOut;
    onChange: any;
    placeholder: MessageDescriptor;
    type: FactorSetPickerType;
}

const FactorSetPicker = (props: IFactorSetPickerProps): JSX.Element => {
    const { value, onChange, placeholder, type } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const [inputValue, setInputValue] = React.useState('');
    const getFactorSets = () : Array<any> => {
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                return useSelector(getFactorSetCancellations).content;
            }

            case FactorSetPickerType.Departure: {
                return useSelector(getFactorSetDepartures).content;
            }
            case FactorSetPickerType.Priority: {
                return useSelector(getFactorSetPriorities).content;
            }
            case FactorSetPickerType.Hours: {
                return useSelector(getFactorSetHours).content;
            }
            case FactorSetPickerType.Weight: {
                return useSelector(getFactorSetWeights).content;
            }
            case FactorSetPickerType.Calendar: {
                return useSelector(getFactorSetCalendars).content;
            }
            case FactorSetPickerType.DangerousGoods: {
                return useSelector(getFactorSetDangerousGoods).content;
            }
            default: {
                throw Error('Invalid type');
            }
        }
    };
    const getIsLoadingFactorSets = () : boolean => {
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                return useSelector(isLoadingFactorSetCancellations);
            }
            case FactorSetPickerType.Departure: {
                return useSelector(isLoadingFactorSetDepartures);
            }
            case FactorSetPickerType.Priority: {
                return useSelector(isLoadingFactorSetPriorities);
            }
            case FactorSetPickerType.Hours: {
                return useSelector(isLoadingFactorSetHours);
            }
            case FactorSetPickerType.Weight: {
                return useSelector(isLoadingFactorSetWeights);
            }
            case FactorSetPickerType.Calendar: {
                return useSelector(isLoadingFactorSetCalendars);
            }
            case FactorSetPickerType.DangerousGoods: {
                return useSelector(isLoadingFactorSetDangerousGoods);
            }
            default: {
                throw Error('Invalid type');
            }
        }
    };
    
    const resetFactorSets = () : void => {
        let fn;
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                fn = resetFactorSetCancellations;
                break;
            }

            case FactorSetPickerType.Departure: {
                fn = resetFactorSetDepartures;
                break;
            }
            case FactorSetPickerType.Priority: {
                fn = resetFactorSetPriorities;
                break;
            }
            case FactorSetPickerType.Hours: {
                fn = resetFactorSetHours;
                break;
            }
            case FactorSetPickerType.Weight: {
                fn = resetFactorSetWeights;
                break;
            }
            case FactorSetPickerType.Calendar: {
                fn = resetFactorSetWeights;
                break;
            }
            case FactorSetPickerType.DangerousGoods: {
                fn = resetFactorSetDangerousGoods;
                break;
            }
            default: {
                throw Error('Invalid type');
            }
        }

        dispatch(fn());
    };

    const fetchFactorSets = (value: string) => {
        let fn;
        switch (type) {
            case FactorSetPickerType.Cancellation: {
                fn = fetchFactorSetCancellations;
                break;
            }

            case FactorSetPickerType.Departure: {
                fn = fetchFactorSetDepartures;
                break;
            }
            case FactorSetPickerType.Priority: {
                fn = fetchFactorSetPriorities;
                break;
            }
            case FactorSetPickerType.Hours: {
                fn = fetchFactorSetHours;
                break;
            }
            case FactorSetPickerType.Weight: {
                fn = fetchFactorSetWeights;
                break;
            }
            default: {
                throw Error('Invalid type');
            }
        }

        dispatch(fn(({ paging,
            filters: { name: value } as FactorSetFilter })));
    };

    return (
        <Autocomplete
            value={value || null}
            onChange={(e, value: any) => onChange(value)}
            getOptionLabel={(option: FactorSetOut) => option.name || ''}
            inputValue={inputValue}
            onInputChange={(event, newInputvalue: any) => {
                setInputValue(newInputvalue);
                if(!newInputvalue) {
                    onChange(null);
                    resetFactorSets();
                }
                if(newInputvalue.length > 2) {
                    fetchFactorSets(newInputvalue);
                }
            }}
            filterOptions={(x) => x}
            options={getFactorSets()}
            loading={getIsLoadingFactorSets()}
            isOptionEqualToValue={(option: FactorSetOut, value: FactorSetOut) => option.id === value.id}
            renderInput={(params) => <>
                <TextField {...params} variant="standard" label={placeholder ? formatMessage(placeholder) : ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {getIsLoadingFactorSets() ? <CircularProgress color="inherit" size={20} sx={{ position: 'absolute',
                                    right: 20 }}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }} />
            </>}
        />
    );
};
  
export default FactorSetPicker;