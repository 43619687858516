import { http } from '../utils/http';
import UnitType from '../interfaces/output/unitType';
import Pagination from '../interfaces/common/pagination';

export default class UnitTypeApi {
    static fetchUnitType = async(unittypeid: string): Promise<UnitType> => {
        const { data } = await http.get<UnitType>(`unittypes/${unittypeid}`);

        return data;
    };

    static fetchUnitTypes = async(): Promise<Pagination<UnitType>> => {
        const { data } = await http.get<Pagination<UnitType>>('/unittypes');

        return data;
    };
}
