import { List } from 'immutable';
import Service from '../../../../interfaces/output/service';
import { ensure } from '../../../../utils/arrayUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationModel from '../../../../utils/validationModel';
import ValidationUtils from '../../../../utils/validationUtils';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class ServiceStep1Validation extends ValidationUtils {
    static validateServiceForm(service: Service): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const {
            fromTerminal,
            toTerminal,
            type
        } = service;

        const requiredFields =
            this.validateInputNumber(fromTerminal?.id) &&
            this.validateInputNumber(toTerminal?.id) &&
            this.validateInputNumber(type?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
