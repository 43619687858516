import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import ServiceProduct from '../interfaces/output/serviceProduct';
import ServiceProductFilters from '../interfaces/filters/serviceProductFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ServiceProductApi {
    static fetchServiceProductById = async(pricelistTypeId: string): Promise<ServiceProduct> => {
        const { data } = await http.get<ServiceProduct>(`/serviceproducts/${pricelistTypeId}`);

        return data;
    };

    static fetchServiceProducts = async(paging: Paging, filters: ServiceProductFilters): Promise<Pagination<ServiceProduct>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/serviceproducts');

        const { data } = await http.get<Pagination<ServiceProduct>>(url);

        return data;
    };

    static createServiceProduct = async(serviceProduct: ServiceProduct): Promise<ServiceProduct> => {
        const newServiceProduct = {
            productId: serviceProduct.product?.id,
            serviceId: serviceProduct.service?.id,
            amount: serviceProduct?.amount ?? null,
            sequenceNumber: serviceProduct?.sequenceNumber
        };

        const { data } = await http.post<any, ServiceProduct>('serviceproducts', newServiceProduct);

        return data;
    };

    static updateServiceProduct = async(serviceProduct: ServiceProduct): Promise<ServiceProduct> => {
        const newServiceProduct = {
            productId: serviceProduct.product?.id,
            serviceId: serviceProduct.service?.id,
            amount: serviceProduct?.amount ?? null,
            sequenceNumber: serviceProduct?.sequenceNumber
        };

        const { data } = await http.put<any, ServiceProduct>(`serviceproducts/${serviceProduct.id}`, newServiceProduct);

        return data;
    };

    static deleteServiceProduct = async(serviceProduct: ServiceProduct): Promise<any> => {
        if(serviceProduct.id !== null && serviceProduct.id !== undefined) {
            const { data } = await http.delete<ServiceProduct>(`serviceproducts/${serviceProduct.id}`);

            return data;
        }

        return null;
    };

    static promoteServiceProduct = async(serviceProductId: number): Promise<ServiceProduct> => {
        const { data } = await http.put<any, ServiceProduct>(`/serviceproducts/${serviceProductId}/promote`);

        return data;
    };

    static demoteServiceProduct = async(serviceProductId: number): Promise<ServiceProduct> => {
        const { data } = await http.put<any, ServiceProduct>(`/serviceproducts/${serviceProductId}/demote`);

        return data;
    };
}
