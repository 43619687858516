import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivateDangerousGoodVersionStatusError, deactivateDangerousGoodVersionStatusSuccess, fetchDangerousGoodVersionStatusByIdError, fetchDangerousGoodVersionStatusByIdSuccess,
    fetchDangerousGoodVersionStatusesError, fetchDangerousGoodVersionStatusesSuccess, updateDangerousGoodVersionStatusError, updateDangerousGoodVersionStatusSuccess }
    from '../reducers/dangerousGoodsVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchDangerousGoodVersionStatus(action: PayloadAction<string>) {
    const dangerousGoodVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, dangerousGoodVersionStatusId);
    
        yield put(fetchDangerousGoodVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDangerousGoodVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchDangerousGoodVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchDangerousGoodVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDangerousGoodVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateDangerousGoodVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const dangerousGoodVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, dangerousGoodVersionStatus);

        yield put(updateDangerousGoodVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDangerousGoodVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateDangerousGoodVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const dangerousGoodVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, dangerousGoodVersionStatus);

        yield put(deactivateDangerousGoodVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateDangerousGoodVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('dangerousGoodVersionStatus/fetchDangerousGoodVersionStatusById', doFetchDangerousGoodVersionStatus),
    takeLatest('dangerousGoodVersionStatus/fetchDangerousGoodVersionStatuses', doFetchDangerousGoodVersionStatuses),
    takeLatest('dangerousGoodVersionStatus/updateDangerousGoodVersionStatus', doUpdateDangerousGoodVersionStatus),
    takeLatest('dangerousGoodVersionStatus/deactivateDangerousGoodVersionStatus', doDeactivateDangerousGoodVersionStatus)
];