import CustomerCorporateApi from '../api/customerCorporateApi';
import { createCorporateError, createCorporateSuccess, fetchCorporatesError, fetchCorporatesSuccess, fetchCustomerCorporateByIdError, fetchCustomerCorporateByIdSuccess,
    updateCorporateError, updateCorporateSuccess, deleteCorporateSuccess, deleteCorporateError }
    from '../reducers/customerCorporateReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import CustomerCorporate from '../interfaces/output/customerCorporate';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchCorporateById(action: PayloadAction<string>) {
    const customerCorporateId = action.payload;
    
    try {
        const response: CustomerCorporate = yield call(CustomerCorporateApi.fetchCorporateById, customerCorporateId);
        yield put(fetchCustomerCorporateByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerCorporateByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCorporates(action: PayloadAction<{ paging: Paging, searchTerm?: string}>) {
    const { paging, searchTerm } = action.payload;

    try {
        const response: Pagination<CustomerCorporate> = yield call(CustomerCorporateApi.fetchCorporates, paging, searchTerm);
        yield put(fetchCorporatesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCorporatesError(error as HttpErrorResponse));
    }
}

function* doCreateCorporate(action: PayloadAction<CustomerCorporate>) {
    const corporate = action.payload;

    try {
        const response: CustomerCorporate = yield call(CustomerCorporateApi.createCorporate, corporate);

        yield put(createCorporateSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCorporateError(error as HttpErrorResponse));
    }
}

function* doUpdateCorporate(action: PayloadAction<CustomerCorporate>) {
    const corporate = action.payload;

    try {
        const response: CustomerCorporate = yield call(CustomerCorporateApi.updateCorporate, corporate);

        yield put(updateCorporateSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCorporateError(error as HttpErrorResponse));
    }
}

function* doDeleteCustomerCorporate(action: PayloadAction<number>) {
    const discountId = action.payload;
    
    try {
        const response: CustomerCorporate = yield call(CustomerCorporateApi.deleteCustomerCorporate, discountId);

        yield put(deleteCorporateSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteCorporateError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('customerCorporate/fetchCustomerCorporateById', doFetchCorporateById),
    takeLatest('customerCorporate/fetchCorporates', doFetchCorporates),
    takeLatest('customerCorporate/createCorporate', doCreateCorporate),
    takeLatest('customerCorporate/updateCorporate', doUpdateCorporate),
    takeLatest('customerCorporate/deleteCorporate', doDeleteCustomerCorporate)
];

