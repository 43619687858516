/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/widgets/loader';
import WorkflowUtils from '../../../../utils/workflowUtils';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import { isCreatingHour, isLoadingFactorSetHour, isUpdatingHour } from '../../../../reducers/factorSetHoursReducer';
import GenericTextField from '../../../common/widgets/genericTextField';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import ProductArena from '../../../../interfaces/output/productArena';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import { fetchProductArenas, getProductArenas, isLoadingProductArenas } from '../../../../reducers/productArenaReducer';
import PageUtils from '../../../../utils/pageUtils';
import { ensure } from '../../../../utils/arrayUtils';
import ProductType from '../../../../interfaces/output/productType';
import { fetchProductTypes, getProductTypes, isLoadingProductTypes } from '../../../../reducers/productTypesReducer';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';
import { FormattedMessage } from 'react-intl';

interface AddServiceStep1Props {
    factorSet: FactorSetOut;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_GROUP: 'unitGroup',
    NAME: 'name',
    PRODUCT_TYPE: 'productType',
    UNIT_SUBGROUP: 'unitSubGroup'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set name'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    productArena: LanguageUtils.createMessage('Product arena'),
    productType: LanguageUtils.createMessage('Product type'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    productArenaHelpText: LanguageUtils.createMessage('FACTOR_SET_PRODUCT_ARENA_HELPTEXT'),
    productTypeHelpText: LanguageUtils.createMessage('FACTOR_SET_PRODUCT_TYPE_HELPTEXT'),
    default: LanguageUtils.createMessage('IS_DEFAULT')
};

const AddFactorSetHourStep1 = forwardRef((props: AddServiceStep1Props, ref: any): JSX.Element => {
    const { factorSet, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const unitGroups = useSelector(getUnitGroups).content;
    const unitSubGroups = useSelector(getUnitSubGroups).content;
    const productArenas = useSelector(getProductArenas).content;
    const propsIsLoadingSubGroups = useSelector(isLoadingUnitSubgroups);
    const productTypes = useSelector(getProductTypes).content;
    const isLoadingObjects = [useSelector(isCreatingHour), useSelector(isUpdatingHour), useSelector(isLoadingFactorSetHour), useSelector(isLoadingUnitGroups), useSelector(isLoadingProductArenas)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchProductArenas({ paging }));
        dispatch(fetchProductTypes({ paging }));
    }, [dispatch]);

    useEffect(() => {
        if(factorSet?.unitSubGroup?.unitGroupId) {
            dispatch(fetchUnitGroupSubgroups({
                groupId: ensure(factorSet?.unitSubGroup?.unitGroupId),
                paging
            }));
            dispatch(resetSubgroups());
        }
    }, [factorSet?.unitSubGroup?.unitGroupId]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetStep1Validation.validateName(factorSet.name);
                break;
            }
            case ATTRIBUTES.UNIT_GROUP: {
                isValid = FactorSetStep1Validation.validateInputNumber(factorSet.unitSubGroup?.unitGroupId);
                break;
            }
            case ATTRIBUTES.PRODUCT_TYPE: {
                isValid = FactorSetStep1Validation.validateInputNumber(factorSet.productType?.id);
                break;
            }
            case ATTRIBUTES.UNIT_SUBGROUP: {
                isValid = FactorSetStep1Validation.validateInputNumber(factorSet.unitSubGroup?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={factorSet.name}
                        onChange={onChange}
                        required
                        error={setValidationState(ATTRIBUTES.NAME)}
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<UnitGroup>
                        options={unitGroups}
                        value={factorSet.unitSubGroup?.unitGroupId}
                        onChange={(obj: UnitGroup | null) => onChange('unitSubGroup', {
                            ...factorSet.unitSubGroup,
                            unitGroupId: obj?.id,
                            id: null
                        }, 'unitGroupId')}
                        placeholder={messages.unitGroup}
                        compareFn={(o: UnitGroup) => o.id === factorSet.unitSubGroup?.unitGroupId}
                        isLoading={useSelector(isLoadingUnitGroups)}
                        error={setValidationState(ATTRIBUTES.UNIT_GROUP)}
                        required
                        helperText={messages.unitGroupHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<UnitSubGroup>
                        options={unitSubGroups}
                        value={factorSet.unitSubGroup?.id}
                        onChange={(obj: UnitSubGroup | null) => onChange('unitSubGroup', {
                            ...factorSet.unitSubGroup,
                            id: obj?.id
                        })}
                        compareFn={(o: UnitSubGroup) => o.id === factorSet.unitSubGroup?.id}
                        placeholder={messages.subgroup}
                        isLoading={propsIsLoadingSubGroups}
                        helperText={messages.unitSubGroupHelptext}
                        required
                        error={setValidationState(ATTRIBUTES.UNIT_SUBGROUP)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<ProductArena>
                        options={productArenas}
                        value={factorSet.productArena}
                        onChange={(obj: ProductArena | null) => onChange('productArena', obj)}
                        placeholder={messages.productArena}
                        isLoading={useSelector(isLoadingProductArenas)}
                        helperText={messages.productArenaHelpText}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<ProductType>
                        options={productTypes}
                        value={factorSet.productType}
                        onChange={(obj: ProductType | null) => onChange('productType', obj)}
                        placeholder={messages.productType}
                        isLoading={useSelector(isLoadingProductTypes)}
                        helperText={messages.productArenaHelpText}
                        required
                        error={setValidationState(ATTRIBUTES.PRODUCT_TYPE)}
                    />
                </Grid>
                <Grid item xs={3} /><Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!factorSet.default}
                                onChange={(e: any, value: boolean) => onChange('default', value)}
                            />}
                            label={<FormattedMessage {...messages.default} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddFactorSetHourStep1;