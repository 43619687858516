import React, { useEffect, useImperativeHandle, useRef } from 'react';

class WorkflowUtils {
    static setHandle = <T>(ref: React.Ref<any>, data: T): void => {
        useImperativeHandle(ref, () => ({
            getData: (): T => data
        }));
    }

    static usePrevious = <T>(value: T) : T | undefined => {
        const ref = useRef<T>();
        useEffect(() => {
            ref.current = value;
        });
        
        return ref.current;
    }
}

export default WorkflowUtils;