import React, { forwardRef, useEffect } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LanguageUtils from '../../../utils/LanguageUtils';
import Loader from '../../common/widgets/loader';
import { isLoadingOrderUnitServices } from '../../../reducers/orderUnitReducer';
import OrderUnitService from '../../../interfaces/output/orderUnitService';
import { isUpdatingOrderUnitService } from '../../../reducers/unitServiceReducer';
import GenericTextField from '../../common/widgets/genericTextField';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Currency from '../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../reducers/currencyReducer';
import PageUtils from '../../../utils/pageUtils';
import OrderUnitServiceStep2Validation from './validations/orderUnitServiceStep2Validation';

interface AddOrderUnitServiceStep2Props {
    orderUnitService: OrderUnitService;
    onChange: any;
    showValidationError?: boolean;
}

const messages = {
    serviceDate: LanguageUtils.createMessage('Service date'),
    serviceEndDate: LanguageUtils.createMessage('Service end date'),
    amount: LanguageUtils.createMessage('Amount'),
    manualPrice: LanguageUtils.createMessage('Manual price'),
    currency: LanguageUtils.createMessage('Currency'),
    ousStatusComment: LanguageUtils.createMessage('Ous status comment')
};

const ATTRIBUTES = {
    AMOUNT: 'amount',
    VALID_FROM: 'serviceDate',
    CURRENCY: 'currency',
    VALID_TO: 'serviceEndDate'
};

const AddOrderUnitServiceStep2 = forwardRef((props: AddOrderUnitServiceStep2Props, ref: any): JSX.Element => {
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const isLoadingObjects = [useSelector(isUpdatingOrderUnitService), useSelector(isLoadingOrderUnitServices)];
    const currencies = useSelector(getCurrencies).content;
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const { orderUnitService, onChange, showValidationError } = props;
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.AMOUNT: {
                isValid = OrderUnitServiceStep2Validation.validateInputNumber(orderUnitService?.amount);
                break;
            }
            case ATTRIBUTES.VALID_FROM: {
                isValid = OrderUnitServiceStep2Validation.validateRequiredDate(orderUnitService.serviceDate) &&
                    OrderUnitServiceStep2Validation.validateDateFormat(orderUnitService.serviceDate) &&
                    OrderUnitServiceStep2Validation.validateInputDateStart(orderUnitService);
                break;
            }
            case ATTRIBUTES.CURRENCY: {
                isValid = orderUnitService.manualPrice ? OrderUnitServiceStep2Validation.validateInputNumber(orderUnitService.currency?.id) : true;
                break;
            }
            case ATTRIBUTES.VALID_TO: {
                isValid = OrderUnitServiceStep2Validation.validateInputDateEnd(orderUnitService) &&
                    OrderUnitServiceStep2Validation.validateDateFormat(orderUnitService.serviceEndDate);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="serviceDate"
                            label={messages.serviceDate}
                            value={orderUnitService?.serviceDate}
                            onChange={onChange}
                            type="start"
                            required
                            error={setValidationState(ATTRIBUTES.VALID_FROM)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="serviceEndDate"
                            label={messages.serviceEndDate}
                            value={orderUnitService?.serviceEndDate}
                            onChange={onChange}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALID_TO)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="amount"
                            type="number"
                            label={messages.amount}
                            value={orderUnitService.amount}
                            onChange={onChange}
                            error={setValidationState(ATTRIBUTES.AMOUNT)}
                            required
                            inputProps={{ min: 0 }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="manualPrice"
                            type="number"
                            label={messages.manualPrice}
                            value={orderUnitService.manualPrice}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={orderUnitService.currency}
                            onChange={(obj: Currency | null) => onChange('currency', obj)}
                            placeholder={messages.currency}
                            required={orderUnitService.manualPrice ? true : false}
                            error={setValidationState(ATTRIBUTES.CURRENCY)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<string>
                            name="ousStatusComment"
                            type="string"
                            label={messages.ousStatusComment}
                            value={orderUnitService.ousStatusComment}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddOrderUnitServiceStep2;