import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Customer from '../../interfaces/output/customer';
import Pagination from '../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import DisplayStatus from '../common/displayStatus';
import { StatusCodes } from '../../constants/statusConstants';
import Status from '../../interfaces/output/status';
import { ensure } from '../../utils/arrayUtils';
import CustomLink from '../common/customLink';
import CustomStringLink from '../common/customStringLink';

interface ICustomerListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    customers?: Pagination<Customer>;
    onSortModelChange?: any;
    prefix?: string;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    isCustomerAgreement?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('Customer name'),
    gtsCustomerNo: LanguageUtils.createMessage('GTS customer number'),
    revenueThisYear: LanguageUtils.createMessage('Revenue this year'),
    revenueLastYear: LanguageUtils.createMessage('Revenue last year'),
    seller: LanguageUtils.createMessage('Seller'),
    corporate: LanguageUtils.createMessage('Customer corporate'),
    priorityRank: LanguageUtils.createMessage('Priority rank'),
    status: LanguageUtils.createMessage('Status'),
    validity: LanguageUtils.createMessage('Validity')
};

const CustomerList = forwardRef((props: ICustomerListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, customers, multipleSelection, onSortModelChange,
        prefix, onColumnVisibilityModelChange, columnVisibilityModel, isCustomerAgreement } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        let x = [
            {
                field: prefix ? `${prefix}gtsId` : 'gtsId',
                headerName: intl.formatMessage(messages.gtsCustomerNo),
                flex: 0.8,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).gtsId} link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('gtsId', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}name` : 'name',
                headerName: intl.formatMessage(messages.name),
                flex: 2,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).name} link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}corporate_name` : 'corporate_name',
                headerName: intl.formatMessage(messages.corporate),
                flex: 1.5,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).corporate?.name ?? '-'} link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('corporate', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}seller_name` : 'seller_name',
                headerName: intl.formatMessage(messages.seller),
                flex: 1.5,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params).seller?.name ?? '-'} link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('seller', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}status_name` : 'status',
                headerName: intl.formatMessage(messages.status),
                flex: 0.8,
                align: 'center',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`}>
                        <DisplayStatus status={applyRowFn(params)?.status} inheritProps />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}revenueThisYear` : 'revenueThisYear',
                headerName: intl.formatMessage(messages.revenueThisYear),
                flex: 0.8,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`}>
                    {
                        applyRowFn(params).revenueThisYear &&
                        intl.formatNumber(ensure(Math.round(applyRowFn(params).revenueThisYear)), {
                            maximumFractionDigits: 0
                        }) || '-'
                    }
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('revenueThisYear', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}revenueLastYear` : 'revenueLastYear',
                headerName: intl.formatMessage(messages.revenueLastYear),
                flex: 0.8,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`}>
                    {
                        applyRowFn(params).revenueLastYear &&
                        intl.formatNumber(ensure(Math.round(applyRowFn(params).revenueLastYear)), {
                            maximumFractionDigits: 0
                        }) || '-'
                    }
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('revenueLastYear', hiddenColumns)
            },
            {
                field: prefix ? `${prefix}validity` : 'validity',
                headerName: intl.formatMessage(messages.validity),
                flex: 0.8,
                align: 'center',
                headerAlign: 'left',
                sortable: false,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomLink link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`}>
                        <DisplayStatus
                            status={{
                                code: params?.row.validFromDate && params?.row.validFromDate > new Date() ? StatusCodes.Future : params?.row.validToDate && params?.row.validToDate < new Date() ?
                                    StatusCodes.Expired : StatusCodes.Valid
                            } as Status} inheritProps />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('relation', hiddenColumns)
            }
        ] as GridColDef[];

        if(isCustomerAgreement) {
            x = [
                ...x,
                {
                    field: 'priorityRank',
                    headerName: intl.formatMessage(messages.priorityRank),
                    flex: 0.8,
                    align: 'left',
                    headerAlign: 'left',
                    sortable: true,
                    renderCell: (params: GridRenderCellParams) => <CustomStringLink value={params?.row?.priorityRank ?? '-'}
                        link={`/#/${UrlConstants.CUSTOMERS}/${applyRowFn(params).id}`} />,
                    hide: hiddenColumns && TableUtils.hideColumn('priorityRank', hiddenColumns)
                }];
        }

        return x;
    }

    return (
        <DataGridComponent rows={rows || customers} multipleSelection={multipleSelection} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange} columnVisibilityModel={columnVisibilityModel} />
    );
});
export default CustomerList;