import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isCreatingInvoice, isLoadingInvoice, isUpdatingInvoice } from '../../../reducers/invoiceReducer';
import InvoiceOut from '../../../interfaces/output/invoiceOut';
import { fetchCustomerById, fetchCustomers, getCustomer, getCustomers, isLoadingCustomer, isLoadingCustomers, resetCustomers } from '../../../reducers/customerReducer';
import { useIntl } from 'react-intl';
import { fetchInvoiceTypes, getInvoiceTypes, isLoadingInvoiceTypes } from '../../../reducers/invoiceTypeReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchInvoiceFormats, getInvoiceFormats, isLoadingInvoiceFormats } from '../../../reducers/invoiceFormatReducer';
import InvoiceFormatOutput from '../../../interfaces/output/invoiceFormatOutput';
import { fetchInvoiceStatuses, getInvoiceStatuses, isLoadingInvoiceStatuses } from '../../../reducers/invoiceStatusReducer';
import InvoiceStatusOut from '../../../interfaces/output/invoiceStatusOut';
import PaymentDeadline from '../../../interfaces/output/paymentDeadline';
import { fetchPaymentDeadlines, getPaymentDeadlines, isLoadingPaymentDeadline } from '../../../reducers/paymentDeadlineReducer';
import PageUtils from '../../../utils/pageUtils';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import InvoiceType from '../../../interfaces/output/invoiceType';
import CustomerFilters from '../../../interfaces/output/filters';
import Customer from '../../../interfaces/output/customer';
import GenericTextField from '../../common/widgets/genericTextField';
import InvoiceStep1Validation from '../validations/invoiceStep1Validation';

interface IInvoiceFormStep1Props {
    invoice: InvoiceOut;
    defaultCustomer: boolean;
    onChange: any;
    showValidationError?: boolean
}

const messages = {
    customer: LanguageUtils.createMessage('Customer'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    invoiceFormat: LanguageUtils.createMessage('Invoice format'),
    invoiceStatus: LanguageUtils.createMessage('Invoice status'),
    paymentDeadline: LanguageUtils.createMessage('Payment deadline'),
    invoiceDate: LanguageUtils.createMessage('Invoice date'),
    invoiceNumber: LanguageUtils.createMessage('Invoice number')
};

const ATTRIBUTES = {
    INVOICE_NUMBER: 'invoiceNumber',
    INVOICE_TYPE: 'invoiceType'
};

const InvoiceFormStep1 = forwardRef((props: IInvoiceFormStep1Props, ref: any): JSX.Element => {
    const { invoice, onChange, defaultCustomer, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const intl = useIntl();
    const isLoadingObjects = [useSelector(isCreatingInvoice), useSelector(isLoadingInvoice), useSelector(isLoadingInvoiceTypes),
        useSelector(isLoadingInvoiceFormats), useSelector(isLoadingInvoiceStatuses), useSelector(isUpdatingInvoice), useSelector(isLoadingPaymentDeadline), useSelector(isLoadingCustomer)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const [inputValue, setInputValue] = React.useState('');
    const customers = useSelector(getCustomers).content;
    const invoiceTypes = useSelector(getInvoiceTypes).content;
    const invoiceFormats = useSelector(getInvoiceFormats).content;
    const paymentDeadlines = useSelector(getPaymentDeadlines).content;
    const invoiceStatuses = useSelector(getInvoiceStatuses).content;
    const customer = useSelector(getCustomer);
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const propsIsLoadingCustomer = useSelector(isLoadingCustomer);
    WorkflowUtils.setHandle(ref, null);
    const prevIsLoadingCustomer = WorkflowUtils.usePrevious<boolean>(propsIsLoadingCustomer);

    useEffect(() => {
        dispatch(fetchInvoiceTypes({ paging }));
        dispatch(fetchInvoiceFormats({ paging }));
        dispatch(fetchInvoiceStatuses({ paging }));
        dispatch(fetchPaymentDeadlines());

        if(defaultCustomer && invoice?.customer?.id) {
            dispatch(fetchCustomerById(invoice?.customer?.id));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingCustomer && !propsIsLoadingCustomer) {
            onChange('customer', customer);
        }
    }, [propsIsLoadingCustomer]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.INVOICE_NUMBER: {
                isValid = InvoiceStep1Validation.validateInputNumber(invoice.invoiceNumber);
                break;
            }
            case ATTRIBUTES.INVOICE_TYPE: {
                isValid = InvoiceStep1Validation.validateInputNumber(invoice.invoiceType?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="invoiceNumber"
                            label={messages.invoiceNumber}
                            value={invoice.invoiceNumber}
                            onChange={onChange}
                            required
                            type="number"
                            error={setValidationState(ATTRIBUTES.INVOICE_NUMBER)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={invoice.customer}
                            disabled={defaultCustomer}
                            onChange={(e, value: any) => onChange('customer', value)}
                            getOptionLabel={(option: Customer) => option.name}
                            filterSelectedOptions
                            inputValue={inputValue}
                            onInputChange={(event, newInputvalue: any) => {
                                setInputValue(newInputvalue);
                                if(!newInputvalue) {
                                    onChange(null);
                                    dispatch(resetCustomers());
                                }
                                if(newInputvalue.length > 2) {
                                    dispatch(fetchCustomers({
                                        paging,
                                        filters: {
                                            name: newInputvalue
                                        } as CustomerFilters,
                                        throttle: true
                                    }));
                                }
                            }}
                            isOptionEqualToValue={(option: Customer, value: Customer) => option.id === value.id}
                            filterOptions={(x) => x}
                            options={customers}
                            loading={propsIsLoadingCustomers}
                            renderInput={(params) => <TextField {...params} label={intl.formatMessage(messages.customer)} variant="standard" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<InvoiceType>
                        options={invoiceTypes}
                        value={invoice.invoiceType}
                        onChange={(obj: InvoiceType | null) => onChange('invoiceType', obj)}
                        placeholder={messages.invoiceType}
                        required
                        error={setValidationState(ATTRIBUTES.INVOICE_TYPE)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<InvoiceFormatOutput>
                        options={invoiceFormats}
                        value={invoice.invoiceFormat}
                        onChange={(obj: InvoiceFormatOutput | null) => onChange('invoiceFormat', obj)}
                        placeholder={messages.invoiceFormat}
                    />
                </Grid>
                <Grid item xs={3} />
                {invoice.id && (
                    <>
                        <Grid item xs={3} />
                        <Grid item xs={12} sm={6}>
                            <GenericAutocomplete<InvoiceStatusOut>
                                options={invoiceStatuses}
                                value={invoice.invoiceStatus}
                                onChange={(obj: InvoiceStatusOut | null) => onChange('invoiceStatus', obj)}
                                placeholder={messages.invoiceStatus}
                            />
                        </Grid>
                        <Grid item xs={3} />
                    </>
                )}
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<PaymentDeadline>
                        options={paymentDeadlines}
                        value={invoice.paymentDeadline}
                        onChange={(obj: PaymentDeadline | null) => onChange('paymentDeadline', obj)}
                        placeholder={messages.paymentDeadline}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="invoiceDate"
                            label={messages.invoiceDate}
                            value={invoice?.invoiceDate}
                            onChange={onChange}
                            type="end"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default InvoiceFormStep1;