import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import LoadedFlagApi from '../api/loadedFlagApi';
import LoadedFlag from '../interfaces/output/loadedFlag';
import { fetchLoadedFlagByIdError, fetchLoadedFlagByIdSuccess, fetchLoadedFlagsError, fetchLoadedFlagsSuccess } from '../reducers/loadedFlagReducer';

function* doFetchLoadedFlag(action: PayloadAction<number>) {
    const userId = action.payload;

    try {
        const response: LoadedFlag = yield call(LoadedFlagApi.fetchLoadedFlagById, userId);
        yield put(fetchLoadedFlagByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchLoadedFlagByIdError(error as HttpErrorResponse));
    }
}

function* doFetchLoadedFlags(action: PayloadAction<{ paging: Paging, includeDeleted: boolean}>) {
    const { paging, includeDeleted } = action.payload;

    try {
        const response: Pagination<LoadedFlag> = yield call(LoadedFlagApi.fetchLoadedFlags, paging, includeDeleted);
        yield put(fetchLoadedFlagsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchLoadedFlagsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('loadedFlag/fetchLoadedFlagById', doFetchLoadedFlag),
    takeLatest('loadedFlag/fetchLoadedFlags', doFetchLoadedFlags)
];