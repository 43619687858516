import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import DepartureType from '../interfaces/output/departureType';

export default class DepartureTypeApi {
    static fetchDepartureTypes = async(): Promise<Pagination<DepartureType>> => {
        const { data } = await http.get<Pagination<DepartureType>>('/departuretypes');

        return data;
    };
}
