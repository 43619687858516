import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Grid, FormControl } from '@mui/material';
import GenericTextField from '../../common/widgets/genericTextField';
import { createGtsOrder } from '../../../reducers/gtsOrderReducer';
import { ensure } from '../../../utils/arrayUtils';

interface AddGtsOrderModalProps {
    onClose: () => void;
    show: boolean;
}
const messages = {
    close: LanguageUtils.createMessage('Close'),
    add: LanguageUtils.createMessage('Add'),
    orderNumber: LanguageUtils.createMessage('Order number'),
    addTitle: LanguageUtils.createMessage('Add Gts Order')
};

export default function AddGtsOrderModal(props: AddGtsOrderModalProps) : JSX.Element {
    const { onClose, show } = props;
    const dispatch = useDispatch();
    const [orderNumber, setOrderNumber] = React.useState<string | undefined>('');

    const handleSave = () => {
        dispatch(createGtsOrder(ensure(orderNumber)));
        handleClose();
    };

    const handleClose = () => {
        onClose();
        setOrderNumber('');
    };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            fullWidth
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <FormattedMessage {...messages.addTitle} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <GenericTextField<string>
                                    label={messages.orderNumber}
                                    value={orderNumber}
                                    name="orderNumber"
                                    onChange={(name: string, value: string | undefined) => setOrderNumber(value)}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    <FormattedMessage {...messages.close} />
                </Button>
                <Button autoFocus onClick={handleSave}>
                    <FormattedMessage {...messages.add} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}