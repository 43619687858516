import { http } from '../utils/http';
import PaymentDeadline from '../interfaces/output/paymentDeadline';
import Pagination from '../interfaces/common/pagination';

export default class PaymentDeadlineApi {
    static fetchPaymentDeadlineById = async(deadlineId: string): Promise<PaymentDeadline> => {
        const { data } = await http.get<PaymentDeadline>(`/paymentdeadline/${deadlineId}`);

        return data;
    };

    static fetchPaymentDeadlines = async(): Promise<Pagination<PaymentDeadline>> => {
        const { data } = await http.get<Pagination<PaymentDeadline>>('/paymentdeadline');

        return data;
    };
}
