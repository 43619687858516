import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Seller from '../interfaces/output/seller';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import TerminalOperator from '../interfaces/output/terminalOperator';

export type TerminalOperatorState = {
    terminalOperator: TerminalOperator;
    isLoading: boolean;
    terminalOperators: Pagination<TerminalOperator>;
    error: HttpErrorResponse | undefined;
};

const initialState: TerminalOperatorState = {
    terminalOperator: {} as TerminalOperator,
    terminalOperators: { content: [] as Array<TerminalOperator> } as Pagination<TerminalOperator>,
    isLoading: false,
    error: undefined
};

export const terminalOperatorSlice = createSlice({
    name: 'terminalOperator',

    initialState,
    reducers: {
        fetchTerminalOperators: (state: TerminalOperatorState, _action: PayloadAction<{ paging: Paging }>) => {
            state.isLoading = true;
        },
        fetchTerminalOperatorsSuccess: (state: TerminalOperatorState, action: PayloadAction<Pagination<TerminalOperator>>) => {
            state.isLoading = false;
            state.terminalOperators = action.payload;
        },
        fetchTerminalOperatorsError: (state: TerminalOperatorState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchTerminalOperatorById: (state: TerminalOperatorState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchTerminalOperatorByIdSuccess: (state: TerminalOperatorState, action: PayloadAction<TerminalOperator>) => {
            state.isLoading = false;
            state.terminalOperator = action.payload;
        },
        fetchTerminalOperatorByIdError: (state: TerminalOperatorState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchTerminalOperators, fetchTerminalOperatorsSuccess, fetchTerminalOperatorsError,
    fetchTerminalOperatorById, fetchTerminalOperatorByIdSuccess, fetchTerminalOperatorByIdError } = terminalOperatorSlice.actions;

export const isLoadingTerminalOperator = (state: RootState): boolean => state.terminalOperatorReducer.isLoading;
export const getTerminalOperators = (state: RootState): Pagination<TerminalOperator> => state.terminalOperatorReducer.terminalOperators;
export const getTerminalOperator = (state: RootState): TerminalOperator => state.terminalOperatorReducer.terminalOperator;

export default terminalOperatorSlice.reducer;