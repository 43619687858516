import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import TerminalOperator from '../interfaces/output/terminalOperator';

export default class TerminalOpearatorApi {
    static fetchTerminalOperatorById = async(terminalOperatorId: string): Promise<TerminalOperator> => {
        const { data } = await http.get<TerminalOperator>(`/terminaloperators/${terminalOperatorId}`);

        return data;
    };

    static fetchTerminalOperators = async(paging: Paging): Promise<Pagination<TerminalOperator>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/terminaloperators');

        const { data } = await http.get<Pagination<TerminalOperator>>(url);

        return data;
    };
}