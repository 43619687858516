import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetResponsibilityCenter } from '../../../reducers/responsibilityCenterReducer';
import ResponsibilityCenterForm from './responsibilityCenterForm';

interface IAddResponsibiliyCenterProps {
    classes: any;
    theme: Theme;
    match: any;
    steps: Array<number>;
    responsibilityCenterId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetResponsibilityCenter: () => dispatch(resetResponsibilityCenter())
});

const mapStoreToProps = (store: RootState, ownProps: IAddResponsibiliyCenterProps) => {
    return {
        responsibilityCenterId: ownProps.match.params?.responsibilityCenterId ? Number(ownProps.match.params?.responsibilityCenterId) : undefined
    };
};

class AddResponsibilityCenter extends Component<IAddResponsibiliyCenterProps> {
    componentWillUnmount() {
        resetResponsibilityCenter();
    }

    render() {
        const { classes, steps, responsibilityCenterId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <ResponsibilityCenterForm responsibilityCenterId={responsibilityCenterId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddResponsibilityCenter));