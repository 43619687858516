import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import PricelistVersionStatus from '../interfaces/output/pricelistVersionStatus';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PriceListVersionStatusApi {
    static fetchPriceListVersionStatusById = async(versionStatusId: string): Promise<PricelistVersionStatus> => {
        const { data } = await http.get<PricelistVersionStatus>(`pricelistversionstatuses/${versionStatusId}`);

        return data;
    };

    static fetchPriceListVersionStatuses = async(paging: Paging): Promise<Pagination<PricelistVersionStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/pricelistversionstatuses');

        const { data } = await http.get<Pagination<PricelistVersionStatus>>(url);

        return data;
    };

    static updatePriceListVersionStatus = async(versionStatus: PricelistVersionStatus): Promise<PricelistVersionStatus> => {
        const newVersionStatus = {
            code: versionStatus?.code,
            name: versionStatus?.name
        };
        
        const { data } = await http.put<any, PricelistVersionStatus>(`pricelistversionstatuses/${versionStatus.id}`, newVersionStatus);

        return data;
    }

    static deactivatePriceListVersionStatus = async(versionStatus: PricelistVersionStatus): Promise<PricelistVersionStatus> => {
        const newVersionStatus = {
            code: versionStatus?.code,
            name: versionStatus?.name
        };

        const { data } = await http.put<any, PricelistVersionStatus>(`pricelistversionstatuses/${versionStatus.id}`, newVersionStatus);

        return data;
    }
}