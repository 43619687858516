import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import DepartureFilters from '../components/factorSets/departure/departureFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetType from '../interfaces/output/factorSetType';

export type FactorSetDepartureState = {
    factorSetDeparture: FactorSetOut;
    factorSetDepartures: Pagination<FactorSetOut>;
    factorSetTypes: Pagination<FactorSetType>;
    isLoadingFactorSetDeparture: boolean;
    isLoadingFactorSetDepartures: boolean;
    isLoadingTypes: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetDepartureState = {
    factorSetDeparture: {} as FactorSetOut,
    factorSetDepartures: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    factorSetTypes: { content: [] as Array<FactorSetType> } as Pagination<FactorSetType>,
    isLoadingFactorSetDeparture: false,
    isLoadingFactorSetDepartures: false,
    isLoadingTypes: false,
    isUpdating: false,
    isRemoving: false,
    isDeleted: false,
    isCreating: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetDepartureSlice = createSlice({
    name: 'factorSetDeparture',

    initialState,
    reducers: {
        fetchFactorSetDepartures: (state: FactorSetDepartureState, action: PayloadAction<{paging: Paging, filters?: DepartureFilters}>) => {
            state.isLoadingFactorSetDepartures = true;
        },
        fetchFactorSetDeparturesSuccess: (state: FactorSetDepartureState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingFactorSetDepartures = false;
            state.factorSetDepartures = action.payload;
        },
        fetchFactorSetDeparturesError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetDepartures = false;
            state.error = action.payload;
        },
        fetchFactorSetDepartureById: (state: FactorSetDepartureState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetDeparture = true;
        },
        fetchFactorSetDepartureByIdSuccess: (state: FactorSetDepartureState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetDeparture = false;
            state.factorSetDeparture = action.payload;
        },
        fetchFactorSetDepartureByIdError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetDeparture = false;
            state.error = action.payload;
        },
        createFactorSetDeparture: (state: FactorSetDepartureState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetDepartureSuccess: (state: FactorSetDepartureState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetDeparture = action.payload;
        },
        createFactorSetDepartureError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createDepartureVersion: (state: FactorSetDepartureState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createDepartureVersionSuccess: (state: FactorSetDepartureState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetDeparture = action.payload;
        },
        createDepartureVersionError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateFactorSetDeparture: (state: FactorSetDepartureState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetDepartureSuccess: (state: FactorSetDepartureState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetDeparture = action.payload;
        },
        updateFactorSetDepartureError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetDeparture: (state: FactorSetDepartureState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateFactorSetDepartureSuccess: (state: FactorSetDepartureState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleted = false;
            state.factorSetDeparture = action.payload;
        },
        deactivateFactorSetDepartureError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        fetchFactorSetDepartureTypes: (state: FactorSetDepartureState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingTypes = true;
        },
        fetchFactorSetDepartureTypesSuccess: (state: FactorSetDepartureState, action: PayloadAction<Pagination<FactorSetType>>) => {
            state.isLoadingTypes = false;
            state.factorSetTypes = action.payload;
        },
        fetchFactorSetDepartureTypesError: (state: FactorSetDepartureState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingTypes = false;
            state.error = action.payload;
        },
        resetFactorSetDepartures: (state: FactorSetDepartureState) => {
            state.factorSetDepartures = initialState.factorSetDepartures;
        },
        resetFactorSetDeparture: (state: FactorSetDepartureState) => {
            state.factorSetDeparture = initialState.factorSetDeparture;
        }
    }
});

export const {
    fetchFactorSetDepartureById, fetchFactorSetDepartureByIdSuccess, fetchFactorSetDepartureByIdError, fetchFactorSetDepartures, fetchFactorSetDeparturesError, fetchFactorSetDeparturesSuccess,
    createFactorSetDeparture, createFactorSetDepartureError, createFactorSetDepartureSuccess, updateFactorSetDeparture, updateFactorSetDepartureError, updateFactorSetDepartureSuccess,
    deactivateFactorSetDeparture, deactivateFactorSetDepartureError, deactivateFactorSetDepartureSuccess, resetFactorSetDepartures, fetchFactorSetDepartureTypes,
    fetchFactorSetDepartureTypesSuccess, fetchFactorSetDepartureTypesError, createDepartureVersion, createDepartureVersionSuccess, createDepartureVersionError, resetFactorSetDeparture }
    = factorSetDepartureSlice.actions;

export const isLoadingFactorSetDeparture = (state: RootState): boolean => state.factorSetDepartureReducer.isLoadingFactorSetDeparture;
export const isLoadingFactorSetDepartures = (state: RootState): boolean => state.factorSetDepartureReducer.isLoadingFactorSetDepartures;
export const isUpdatingDeparture = (state: RootState): boolean => state.factorSetDepartureReducer.isUpdating;
export const isDeletingDeparture = (state: RootState): boolean => state.factorSetDepartureReducer.isDeleted;
export const isCreatingDeparture = (state: RootState): boolean => state.factorSetDepartureReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetDepartureReducer.isCreatingVersion;
export const getFactorSetDepartures = (state: RootState): Pagination<FactorSetOut> => state.factorSetDepartureReducer.factorSetDepartures;
export const getFactorSetDeparture = (state: RootState): FactorSetOut => state.factorSetDepartureReducer.factorSetDeparture;
export const getFactorSetDepartureTypes = (state: RootState): Pagination<FactorSetType> => state.factorSetDepartureReducer.factorSetTypes;

export default factorSetDepartureSlice.reducer;