import { http } from '../utils/http';
import GtsOrder from '../interfaces/output/gtsOrder';
import GtsOrderFilters from '../interfaces/filters/gtsOrderFilters';
import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';
import moment from 'moment';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import ImportGtsOrderFilters from '../interfaces/filters/importGtsOrderFilters';

export default class GtsOrderApi {
    static fetchGtsOrderById = async(gtsOrderId: string): Promise<GtsOrder> => {
        const { data } = await http.get<GtsOrder>(`staginggtsorders/${gtsOrderId}`);

        return data;
    };

    static fetchGtsOrders = async(paging: Paging, filters: GtsOrderFilters): Promise<Array<GtsOrder>> => {
        const formattedFilters = { ...filters,
            fromDate: filters.fromDate ? moment(filters.fromDate).toISOString() : undefined,
            toDate: filters.toDate ? moment(filters.toDate).toISOString() : undefined,
            receivedAfterTimestamp: filters.receivedAfterTimestamp ? moment(filters.receivedAfterTimestamp).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/staginggtsorders');

        const { data } = await http.get<Array<GtsOrder>>(url);

        return data;
    };

    static updateGtsOrder = async(gtsOrder: GtsOrder): Promise<GtsOrder> => {
        const newOrder = {
            orderNumber: gtsOrder.orderNumber,
            customer: gtsOrder.customer,
            unitGroupCode:	gtsOrder.unitGroupCode,
            orderDate: gtsOrder.orderDate,
            receivedFromGTS: gtsOrder.receivedFromGTS,
            errorMessage: gtsOrder.errorMessage,
            cancellationFlag: gtsOrder.cancellationFlag,
            customerNumber: gtsOrder.customer?.gtsId
        };
        
        const { data } = await http.put<any, GtsOrder>(`staginggtsorders/${gtsOrder.id}`, newOrder);

        return data;
    }

    static processGtsOrder = async(orderNumber: string, filters: ImportGtsOrderFilters): Promise<GtsOrder> => {
        const url = urlcat('', '/gtsorders/processImportedGTSOrdersWithParams', { orderNumber });
        const { data } = await http.put<any, GtsOrder>(url, filters);

        return data;
    }
    static processGtsOrderStaging = async(orderNumber: string): Promise<GtsOrder> => {
        const url = urlcat('', '/gtsorders/processStaging', { orderNumber });
        const { data } = await http.put<any, GtsOrder>(url);

        return data;
    }

    static createGtsOrder = async(orderNumber: string): Promise<GtsOrder> => {
        const url = urlcat('', '/gtsorders', { orderNumber });
        const { data } = await http.post<any, GtsOrder>(url);

        return data;
    }
}
