import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import InvoiceType from '../interfaces/output/invoiceType';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class InvoiceTypeApi {
    static fetchInvoiceTypeById = async(invoiceTypeId: string): Promise<InvoiceType> => {
        const { data } = await http.get<InvoiceType>(`invoicetypes/${invoiceTypeId}`);

        return data;
    };

    static fetchInvoiceTypes = async(paging: Paging): Promise<Pagination<InvoiceType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/invoicetypes');

        const { data } = await http.get<Pagination<InvoiceType>>(url);

        return data;
    };
}
