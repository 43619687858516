import { http } from '../utils/http';
import Country from '../interfaces/output/countryOut';
import Pagination from '../interfaces/common/pagination';

export default class CountryApi {
    static fetchCountryById = async(countryId: string): Promise<Country> => {
        const { data } = await http.get<Country>(`country/${countryId}`);

        return data;
    };

    static fetchCountries = async(): Promise<Pagination<Country>> => {
        const { data } = await http.get<Pagination<Country>>('/country');

        return data;
    };
}
