import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetWeightLineOutput from '../interfaces/output/factorSetWeightLineOut';
import FactorSetWeightLineInput from '../interfaces/input/factorSetWeightLineIn';
import WeightLineFilters from '../components/factorSets/weight/line/weightLineFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class WeightLineApi {
    static fetchFactorSetWeightLineById = async(lineId: number): Promise<FactorSetWeightLineOutput> => {
        const { data } = await http.get<FactorSetWeightLineOutput>(`factorsetweightlines/${lineId}`);

        return data;
    };

    static fetchFactorSetWeightLines = async(paging: Paging, filters: WeightLineFilters): Promise<Pagination<FactorSetWeightLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, 'factorsetweightlines');

        const { data } = await http.get<Pagination<FactorSetWeightLineOutput>>(url);

        return data;
    };

    static createFactorSetWeightLine = async(factorSetLine: FactorSetWeightLineOutput): Promise<FactorSetWeightLineOutput> => {
        const newFactorSetWeight: FactorSetWeightLineInput = {
            name: factorSetLine.name,
            unitTypeId: factorSetLine.unitType?.id,
            fromWeightKg: factorSetLine.fromWeightKg,
            toWeightKg: factorSetLine.toWeightKg,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };

        const { data } = await http.post<FactorSetWeightLineInput, FactorSetWeightLineOutput>('factorsetweightlines', newFactorSetWeight);

        return data;
    }

    static updateFactorSetWeightLine = async(factorSetLine: FactorSetWeightLineOutput): Promise<FactorSetWeightLineOutput> => {
        const newLine: FactorSetWeightLineInput = {
            name: factorSetLine.name,
            unitTypeId: factorSetLine.unitType?.id,
            fromWeightKg: factorSetLine.fromWeightKg,
            toWeightKg: factorSetLine.toWeightKg,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };
        
        const { data } = await http.put<FactorSetWeightLineInput, FactorSetWeightLineOutput>(`factorsetweightlines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetWeightLine = async(lineId: number): Promise<FactorSetWeightLineOutput> => {
        const { data } = await http.delete<FactorSetWeightLineOutput>(`factorsetweightlines/${lineId}`);

        return data;
    }
}