import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { cloneDangerousGoodVersionError, cloneDangerousGoodVersionSuccess, fetchDangerousGoodVersionByIdError,
    fetchDangerousGoodVersionByIdSuccess, fetchDangerousGoodVersionsError, fetchDangerousGoodVersionsSuccess, updateDangerousGoodVersionError, updateDangerousGoodVersionStatusError,
    updateDangerousGoodVersionStatusSuccess, updateDangerousGoodVersionSuccess } from '../reducers/factorSetDangerousGoodsVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import FactorSetDangerousGoodVersionApi from '../api/factorSetDangerousGoodsVersionApi';

function* doFetchFactorSetDangerousGoodVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetDangerousGoodVersionApi.fetchFactorSetDangerousGoodVersionById, agreementVersionId);

        yield put(fetchDangerousGoodVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDangerousGoodVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetDangerousGoodVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetDangerousGoodVersionApi.fetchFactorSetDangerousGoodVersions, id, paging);
        yield put(fetchDangerousGoodVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchDangerousGoodVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDangerousGoodVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetDangerousGoodVersionApi.updateFactorSetDangerousGoodVersionStatus, agreementVersion);

        yield put(updateDangerousGoodVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDangerousGoodVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetDangerousGoodVersion(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetDangerousGoodVersionApi.updateFactorSetDangerousGoodVersion, agreementVersion);

        yield put(updateDangerousGoodVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateDangerousGoodVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetDangerousGoodVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetDangerousGoodVersionApi.cloneFactorSetDangerousGoodVersion, toVersionId, fromVersionId);

        yield put(cloneDangerousGoodVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneDangerousGoodVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchDangerousGoodVersionById', doFetchFactorSetDangerousGoodVersion),
    takeLatest('factorSetVersion/fetchDangerousGoodVersions', doFetchFactorSetDangerousGoodVersions),
    takeLatest('factorSetVersion/updateDangerousGoodVersionStatus', doUpdateFactorSetDangerousGoodVersionStatus),
    takeLatest('factorSetVersion/updateDangerousGoodVersion', doUpdateFactorSetDangerousGoodVersion),
    takeLatest('factorSetVersion/cloneDangerousGoodVersion', doCloneFactorSetDangerousGoodVersion)
];