import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import OrderUnitService from '../../interfaces/output/orderUnitService';
import DisplayStatus from '../common/displayStatus';
import OrderUnitServiceProductsList from './orderUnitServiceProducts/orderUnitServiceProductList';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import Paging from '../../interfaces/common/paging';
import { fetchProductsByOrderUnitServiceId, getUnitServiceProducts, isLoadingUnitServiceProducts } from '../../reducers/unitServiceReducer';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IViewOrderUnitServiceProps {
    classes?: any;
    theme?: Theme;
    orderUnitService: OrderUnitService;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Order unit service details'),
    unitType: LanguageUtils.createMessage('Unit type'),
    serviceType: LanguageUtils.createMessage('Service type'),
    serviceDate: LanguageUtils.createMessage('Service date'),
    serviceFromTerminal: LanguageUtils.createMessage('Terminal from'),
    serviceToTerminal: LanguageUtils.createMessage('terminal to'),
    serviceDetails: LanguageUtils.createMessage('Service details'),
    ousStatus: LanguageUtils.createMessage('Ous status'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    calculatedPrice: LanguageUtils.createMessage('Calculated price'),
    serviceEndDate: LanguageUtils.createMessage('Service end date'),
    amount: LanguageUtils.createMessage('Amount'),
    manualPrice: LanguageUtils.createMessage('Manual price'),
    serviceProducts: LanguageUtils.createMessage('ORDER_UNIT_SERVICE_PRODUCTS'),
    ousStatusComment: LanguageUtils.createMessage('Ous status comment')
};

export default function ViewOrderUnitService(props: IViewOrderUnitServiceProps): JSX.Element {
    const classes = styles();
    const { orderUnitService } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const propsIsLoadingUnitServiceProducts = useSelector(isLoadingUnitServiceProducts);
    const propsOrderUnitServiceProducts = useSelector(getUnitServiceProducts);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        if(orderUnitService.id) {
            dispatch(fetchProductsByOrderUnitServiceId({
                paging: paging,
                id: ensure(orderUnitService.id)
            }));
        }
    }, [orderUnitService.id]);

    const _onSortModelChange = (newModel: GridSortModel) => {
     
        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            setPaging(newPagination);
            setSortModel(newModel);

            dispatch(fetchProductsByOrderUnitServiceId({
                id: ensure(orderUnitService.id),
                paging: newPagination
            }));
        }
    };

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.orderUnit?.unitType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.service?.type?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceFromTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.service?.fromTerminal?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceToTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.service?.toTerminal?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceDetails} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.service?.serviceDescription || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={orderUnitService.serviceDate} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.serviceEndDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={orderUnitService.serviceEndDate} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.ousStatus} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <DisplayStatus status={orderUnitService.ousStatus} />
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.ousStatusComment} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.ousStatusComment ?? '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.amount} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.amount ?? '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.manualPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.manualPrice ?? '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.calculatedPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.calculatedPrice ?? '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.totalPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnitService.totalPrice ?? '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.serviceProducts} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <OrderUnitServiceProductsList isLoading={propsIsLoadingUnitServiceProducts} rows={propsOrderUnitServiceProducts}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };

                                        dispatch(fetchProductsByOrderUnitServiceId({
                                            paging: newPage,
                                            id: ensure(orderUnitService.id)
                                        }));
                                        setPaging(newPage);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}