import { List } from 'immutable';
import Customer from '../../../interfaces/output/customer';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils, { ValidationConfig } from '../../../utils/validationUtils';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    wrongFormat: LanguageUtils.createMessage('Wrong email format'),
    wrongInputLength: LanguageUtils.createMessage('Wrong input length')
};

export default class CustomerStep2Validation {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return ValidationUtils.validateInputStringLength(inputString, options);
    }

    static validateCustomerForm(customer: Customer): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const {
            invoiceFrequency,
            invoiceSeparation
        } = customer;

        const requiredFields =
            CustomerStep2Validation.validateInputString(invoiceFrequency?.name) &&
            CustomerStep2Validation.validateInputString(invoiceSeparation?.name);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
