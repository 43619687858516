import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormControl, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import PageUtils from '../../../utils/pageUtils';
import GenericDatePicker from '../../common/widgets/genericDatePicker';
import SurchargeRate from '../../../interfaces/output/surchargeRate';
import { isCreatingSurchargeRate, isLoadingSurchargeRate, isUpdatingSurchargeRate } from '../../../reducers/surchargeRateReducer';
import { fetchSurchargeTypes, getSurchargeTypes, isLoadingSurchargeType } from '../../../reducers/surchargeTypeReducer';
import { fetchSurchargeAreas, getSurchargeAreas, isLoadingSurchargeArea } from '../../../reducers/surchargeAreaReducer';
import { fetchSurchargeRateUnits, getSurchargeRateUnits, isLoadingSurchargeRateUnit } from '../../../reducers/surchargeRateUnitReducer';
import SurchargeType from '../../../interfaces/output/surchargeType';
import SurchargeArea from '../../../interfaces/output/surchargeArea';
import SurchargeRateUnit from '../../../interfaces/output/surchargeRateUnit';
import GenericTextField from '../../common/widgets/genericTextField';
import SurchargeRateStep1Validation from './validations/surchargeRateStep1Validation';

interface ISurchargeRateFormStep1Props {
    surchargeRate: SurchargeRate;
    onChange: any;
    showValidationError?: boolean
}

const ATTRIBUTES = {
    VALID_FROM_DATE: 'validFromDate',
    VALID_TO_DATE: 'validToDate',
    RATE_NOK: 'rate_Nok',
    RATE_EUR: 'rate_Eur',
    SURCHARGE_TYPE: 'surchargeType',
    SURCHARGE_AREA: 'surchargeArea',
    SURCHARGE_RATE_UNIT: 'surchargeRateUnit'

};

const messages = {
    validFromDate: LanguageUtils.createMessage('Valid from date'),
    validToDate: LanguageUtils.createMessage('Valid to date'),
    rateNok: LanguageUtils.createMessage('Rate NOK'),
    rateEur: LanguageUtils.createMessage('Rate EUR'),
    surchargeType: LanguageUtils.createMessage('Surcharge Type'),
    surchargeArea: LanguageUtils.createMessage('Surcharge Area'),
    surchargeRateUnit: LanguageUtils.createMessage('Surcharge rate unit')
};

const SurchargeRateFormStep1 = forwardRef((props: ISurchargeRateFormStep1Props, ref: any): JSX.Element => {
    const { surchargeRate, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingSurchargeRate), useSelector(isLoadingSurchargeRate), useSelector(isLoadingSurchargeType),
        useSelector(isLoadingSurchargeArea), useSelector(isLoadingSurchargeRateUnit), useSelector(isUpdatingSurchargeRate)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const surchargeTypes = useSelector(getSurchargeTypes).content;
    const surchargeAreas = useSelector(getSurchargeAreas).content;
    const surchargeRateUnits = useSelector(getSurchargeRateUnits).content;
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchSurchargeTypes({ paging }));
        dispatch(fetchSurchargeAreas({ paging }));
        dispatch(fetchSurchargeRateUnits({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {

            case ATTRIBUTES.VALID_FROM_DATE: {
                isValid = SurchargeRateStep1Validation.validateRequiredDate(surchargeRate.validFromDate) &&
                    SurchargeRateStep1Validation.validateDateFormat(surchargeRate.validFromDate) &&
                    SurchargeRateStep1Validation.validateInputDateStart(surchargeRate);
                break;
            }
            case ATTRIBUTES.VALID_TO_DATE: {
                isValid = SurchargeRateStep1Validation.validateRequiredDate(surchargeRate.validToDate) &&
                    SurchargeRateStep1Validation.validateDateFormat(surchargeRate.validToDate) &&
                    SurchargeRateStep1Validation.validateInputDateEnd(surchargeRate);
                break;
            }
            case ATTRIBUTES.RATE_EUR: {
                isValid = SurchargeRateStep1Validation.validateInputNumber(surchargeRate.rate_Eur);
                break;
            }
            case ATTRIBUTES.RATE_NOK: {
                isValid = SurchargeRateStep1Validation.validateInputNumber(surchargeRate.rate_Nok);
                break;
            }
            case ATTRIBUTES.SURCHARGE_AREA: {
                isValid = SurchargeRateStep1Validation.validateInputNumber(surchargeRate.surchargeArea?.id);
                break;
            }
            case ATTRIBUTES.SURCHARGE_RATE_UNIT: {
                isValid = SurchargeRateStep1Validation.validateInputNumber(surchargeRate.surchargeRateUnit?.id);
                break;
            }
            case ATTRIBUTES.SURCHARGE_TYPE: {
                isValid = SurchargeRateStep1Validation.validateInputNumber(surchargeRate.surchargeType?.id);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFromDate}
                            value={surchargeRate?.validFromDate}
                            onChange={onChange}
                            type="start"
                            error={setValidationState(ATTRIBUTES.VALID_FROM_DATE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validToDate}
                            value={surchargeRate?.validToDate}
                            onChange={onChange}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALID_TO_DATE)}
                            required
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="rate_Nok"
                        label={messages.rateNok}
                        value={surchargeRate.rate_Nok}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.RATE_NOK)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        type="number"
                        name="rate_Eur"
                        label={messages.rateEur}
                        value={surchargeRate.rate_Eur}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.RATE_EUR)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<SurchargeType>
                        options={surchargeTypes}
                        value={surchargeRate.surchargeType}
                        onChange={(obj: SurchargeType | null) => onChange('surchargeType', obj)}
                        placeholder={messages.surchargeType}
                        error={setValidationState(ATTRIBUTES.SURCHARGE_TYPE)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<SurchargeArea>
                        options={surchargeAreas}
                        value={surchargeRate.surchargeArea}
                        onChange={(obj: SurchargeArea | null) => onChange('surchargeArea', obj)}
                        placeholder={messages.surchargeArea}
                        error={setValidationState(ATTRIBUTES.SURCHARGE_AREA)}
                        required
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<SurchargeRateUnit>
                        options={surchargeRateUnits}
                        value={surchargeRate.surchargeRateUnit}
                        onChange={(obj: SurchargeRateUnit | null) => onChange('surchargeRateUnit', obj)}
                        placeholder={messages.surchargeRateUnit}
                        error={setValidationState(ATTRIBUTES.SURCHARGE_RATE_UNIT)}
                        required
                    />
                </Grid>
            </Grid>
        </Loader>
    );
});

export default SurchargeRateFormStep1;