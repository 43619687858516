import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MenuItem, IconButton, Menu } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import ContextIndicatorItem from '../../../interfaces/common/contextIndicatorItem';
import { matchPath } from 'react-router';
import { getCustomer, isLoadingCustomer } from '../../../reducers/customerReducer';
import { RootState } from '../../../setup';
import Customer from '../../../interfaces/output/customer';
import PersonIcon from '@mui/icons-material/Person';
import { Theme } from '@mui/material';
import { getAgreement, isLoadingAgreement } from '../../../reducers/agreementsReducer';
import Agreement from '../../../interfaces/output/agreement';
import { List } from 'immutable';
import FontAwesomeIcon from '../../../utils/FontAwesomeIcons';
import { getProduct, isLoadingProduct } from '../../../reducers/productReducer';
import { getPriceList, isLoadingPriceList } from '../../../reducers/priceListReducer';
import Product from '../../../interfaces/output/product';
import PriceList from '../../../interfaces/output/priceList';
import { getService, isLoadingService } from '../../../reducers/serviceReducer';
import Service from '../../../interfaces/output/service';
import { getOrder, isLoadingOrder } from '../../../reducers/orderReducer';
import Order from '../../../interfaces/output/order';
import GtsOrder from '../../../interfaces/output/gtsOrder';
import { getGtsOrder, isLoadingGtsOrder } from '../../../reducers/gtsOrderReducer';
import LanguageUtils from '../../../utils/LanguageUtils';
import { getInvoice, isLoadingInvoice } from '../../../reducers/invoiceReducer';
import { getInvoiceLine, isLoadingInvoiceLine } from '../../../reducers/invoiceLineReducer';
import InvoiceOut from '../../../interfaces/output/invoiceOut';
import InvoiceLineOut from '../../../interfaces/output/invoiceLineOut';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import { getFactorSetWeight, isLoadingFactorSetWeight } from '../../../reducers/factorSetWeightReducer';
import { getFactorSetPriority, isLoadingFactorSetPriority } from '../../../reducers/factorSetPriorityReducer';
import { getFactorSetDeparture, isLoadingFactorSetDeparture } from '../../../reducers/factorSetDepartureReducer';
import { getFactorSetHour, isLoadingFactorSetHour } from '../../../reducers/factorSetHoursReducer';
import { getFactorSetCancellation, isLoadingFactorSetCancellation } from '../../../reducers/factorSetCancellationReducer';
import { getFactorSetCalendar, isLoadingFactorSetCalendar } from '../../../reducers/factorSetCalendarReducer';
import { getFactorSetDangerousGood, isLoadingFactorSetDangerousGood } from '../../../reducers/factorSetDangerousGoodsReducer';
import { injectIntl } from 'react-intl';

interface IContextIndicatorProps {
    classes?: any;
    context?: any;
    contextType?: any;
    location?: any;
    isLoadingCustomer: boolean;
    isLoadingAgreement: boolean;
    isLoadingPricelist: boolean;
    isLoadingProduct: boolean;
    history?: any;
    customer: Customer;
    agreement: Agreement;
    product: Product;
    pricelist: PriceList;
    theme?: Theme;
    service?: Service;
    isLoadingService: boolean;
    order?: Order;
    isLoadingOrder: boolean;
    gtsOrder?: GtsOrder;
    isLoadingGtsOrder: boolean;
    isLoadingInvoice: boolean;
    invoice: InvoiceOut;
    isLoadingInvoiceLine: boolean;
    invoiceLine: InvoiceLineOut;
    isLoadingFactorSetWeight: boolean;
    factorSetWeight: FactorSetOut;
    isLoadingFactorSetPriority: boolean;
    factorSetPriority: FactorSetOut;
    isLoadingFactorSetDeparture: boolean;
    factorSetDeparture: FactorSetOut;
    isLoadingFactorSetHour: boolean;
    factorSetHour: FactorSetOut;
    isLoadingFactorSetCancellation: boolean;
    factorSetCancellation: FactorSetOut;
    factorSetCalendar: FactorSetOut;
    isLoadingFactorSetCalendar: boolean;
    isLoadingFactorSetDangerousGood: boolean;
    factorSetDangerousGood: FactorSetOut
    intl: any;
}

interface IContextIndicatorState {
    contextItems: List<ContextIndicatorItem>;
    value?: ContextIndicatorItem;
    open: boolean;
    anchorEl: null | HTMLElement;
    openMenu: boolean;
}

const messages = {
    noRoutes: LanguageUtils.createMessage('No previous routes')
    // calendar: LanguageUtils.createMessage('Calendar')
};

const styles = (theme: Theme) => createStyles({
    icon: {
        float: 'left',
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main
    },
    name: {
        fontSize: 14,
        float: 'left'
    },
    iconAwesome: {
        marginRight: theme.spacing(1.5)
    },
    focused: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: `3px solid ${theme.palette.primary.main}!important`
        }
    }
});

const mapStoreToProps = (store: RootState) => {
    return {
        isLoadingCustomer: isLoadingCustomer(store),
        isLoadingAgreement: isLoadingAgreement(store),
        isLoadingProduct: isLoadingProduct(store),
        isLoadingPricelist: isLoadingPriceList(store),
        pricelist: getPriceList(store),
        customer: getCustomer(store),
        agreement: getAgreement(store),
        product: getProduct(store),
        service: getService(store),
        isLoadingService: isLoadingService(store),
        order: getOrder(store),
        isLoadingOrder: isLoadingOrder(store),
        gtsOrder: getGtsOrder(store),
        isLoadingGtsOrder: isLoadingGtsOrder(store),
        invoice: getInvoice(store),
        isLoadingInvoice: isLoadingInvoice(store),
        invoiceLine: getInvoiceLine(store),
        isLoadingInvoiceLine: isLoadingInvoiceLine(store),
        isLoadingFactorSetWeight: isLoadingFactorSetWeight(store),
        factorSetWeight: getFactorSetWeight(store),
        isLoadingFactorSetPriority: isLoadingFactorSetPriority(store),
        factorSetPriority: getFactorSetPriority(store),
        isLoadingFactorSetDeparture: isLoadingFactorSetDeparture(store),
        factorSetDeparture: getFactorSetDeparture(store),
        isLoadingFactorSetHour: isLoadingFactorSetHour(store),
        factorSetHour: getFactorSetHour(store),
        isLoadingFactorSetCancellation: isLoadingFactorSetCancellation(store),
        factorSetCancellation: getFactorSetCancellation(store),
        isLoadingFactorSetCalendar: isLoadingFactorSetCalendar(store),
        factorSetCalendar: getFactorSetCalendar(store),
        isLoadingFactorSetDangerousGood: isLoadingFactorSetDangerousGood(store),
        factorSetDangerousGood: getFactorSetDangerousGood(store)

    };
};

class ContextIndicator extends Component<IContextIndicatorProps, IContextIndicatorState> {
    constructor(props: IContextIndicatorProps) {
        super(props);
        this.state = {
            contextItems: List(),
            value: undefined,
            open: false,
            openMenu: false,
            anchorEl: null
        };
    }

    componentDidUpdate(prevProps: IContextIndicatorProps) {
        const { location, isLoadingCustomer, customer, classes, isLoadingAgreement, agreement, product, isLoadingProduct, pricelist, isLoadingPricelist,
            isLoadingService, service, isLoadingOrder, order, isLoadingGtsOrder, gtsOrder, isLoadingInvoice, invoice, isLoadingInvoiceLine, invoiceLine,
            isLoadingFactorSetWeight, factorSetWeight, isLoadingFactorSetPriority, factorSetPriority, isLoadingFactorSetDeparture, factorSetDeparture,
            isLoadingFactorSetHour, factorSetHour, isLoadingFactorSetCancellation, factorSetCancellation,
            isLoadingFactorSetDangerousGood, factorSetDangerousGood, isLoadingFactorSetCalendar, factorSetCalendar } = this.props;

        if(isLoadingCustomer === false && prevProps.isLoadingCustomer === true) {
            const customerPath = matchPath(location.pathname, {
                path: '/customers/:customerId',
                strict: false
            });
            if(customerPath !== null) {
                const newItem = {
                    icon: <PersonIcon fontSize="medium" className={classes.icon} />,
                    name: customer?.name,
                    url: customerPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingAgreement === false && prevProps.isLoadingAgreement === true) {
            const agreementPath = matchPath(location.pathname, {
                path: '/agreements/:agreementId/:agreementVersionId',
                strict: false
            });
            if(agreementPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="handshake" size="1x" className={classes.iconAwesome} />,
                    name: agreement?.name,
                    url: agreementPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingProduct === false && prevProps.isLoadingProduct === true) {
            const productPath = matchPath(location.pathname, {
                path: '/products/:productId',
                strict: false
            });
            if(productPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="cube" size="1x" className={classes.iconAwesome} />,
                    name: product?.name,
                    url: productPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingService === false && prevProps.isLoadingService === true) {
            const servicePath = matchPath(location.pathname, {
                path: '/services/:serviceId',
                strict: false
            });
            if(servicePath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="concierge-bell" size="1x" className={classes.iconAwesome} />,
                    name: service?.serviceDescription,
                    url: servicePath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingOrder === false && prevProps.isLoadingOrder === true) {
            const orderPath = matchPath(location.pathname, {
                path: '/orders/:orderId',
                strict: false
            });
            if(orderPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="file-contract" size="1x" className={classes.iconAwesome} />,
                    name: order?.orderNumber,
                    url: orderPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingGtsOrder === false && prevProps.isLoadingGtsOrder === true) {
            const gtsOrderPath = matchPath(location.pathname, {
                path: '/staginggtsorders/:gtsOrderId',
                strict: false
            });
            if(gtsOrderPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="receipt" size="1x" className={classes.iconAwesome} />,
                    name: gtsOrder?.orderNumber,
                    url: gtsOrderPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingPricelist === false && prevProps.isLoadingPricelist === true) {
            const pricelistPath = matchPath(location.pathname, {
                path: '/pricelists/:pricelistId/:pricelistVersionId',
                strict: false
            });
            if(pricelistPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="tag" size="1x" className={classes.iconAwesome} />,
                    name: pricelist?.name,
                    url: pricelistPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingInvoice === false && prevProps.isLoadingInvoice === true) {
            const orderPath = matchPath(location.pathname, {
                path: '/invoices/:invoiceId',
                strict: false
            });
            if(orderPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="file-invoice" size="1x" className={classes.iconAwesome} />,
                    name: invoice?.invoiceNumber?.toString(),
                    url: orderPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingInvoiceLine === false && prevProps.isLoadingInvoiceLine === true) {
            const orderPath = matchPath(location.pathname, {
                path: '/invoicelines/:invoiceLineId',
                strict: false
            });
            if(orderPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="grip-lines" size="1x" className={classes.iconAwesome} />,
                    name: invoiceLine?.customerReference,
                    url: orderPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetWeight === false && prevProps.isLoadingFactorSetWeight === true) {
            const weightPath = matchPath(location.pathname, {
                path: '/factorsets/weight/:factorSetId/:versionId',
                strict: false
            });
            if(weightPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="weight-hanging" size="1x" className={classes.iconAwesome} />,
                    name: factorSetWeight?.name,
                    url: weightPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetPriority === false && prevProps.isLoadingFactorSetPriority === true) {
            const priorityPath = matchPath(location.pathname, {
                path: '/factorsets/priority/:factorSetId/:versionId',
                strict: false
            });
            if(priorityPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="flag" size="1x" className={classes.iconAwesome} />,
                    name: factorSetPriority?.name,
                    url: priorityPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetDeparture === false && prevProps.isLoadingFactorSetDeparture === true) {
            const departurePath = matchPath(location.pathname, {
                path: '/factorsets/departure/:factorSetId/:versionId',
                strict: false
            });
            if(departurePath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" className={classes.iconAwesome} />,
                    name: factorSetDeparture?.name,
                    url: departurePath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetHour === false && prevProps.isLoadingFactorSetHour === true) {
            const hourPath = matchPath(location.pathname, {
                path: '/factorsets/hours/:factorSetId/:versionId',
                strict: false
            });
            if(hourPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="clock" size="1x" className={classes.iconAwesome} />,
                    name: factorSetHour?.name,
                    url: hourPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetCancellation === false && prevProps.isLoadingFactorSetCancellation === true) {
            const cancellationPath = matchPath(location.pathname, {
                path: '/factorsets/cancellation/:factorSetId/:versionId',
                strict: false
            });
            if(cancellationPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="window-close" size="1x" className={classes.iconAwesome} />,
                    name: factorSetCancellation?.name,
                    url: cancellationPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetDangerousGood === false && prevProps.isLoadingFactorSetDangerousGood === true) {
            const dangerousGoodsPath = matchPath(location.pathname, {
                path: '/factorsets/dangerousgoods/:factorSetId/:versionId',
                strict: false
            });
            if(dangerousGoodsPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="skull-crossbones" size="1x" className={classes.iconAwesome} />,
                    name: factorSetDangerousGood?.name,
                    url: dangerousGoodsPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        if(isLoadingFactorSetCalendar === false && prevProps.isLoadingFactorSetCalendar === true) {
            const calendarPath = matchPath(location.pathname, {
                path: '/factorsets/calendar/:factorSetId/:versionId',
                strict: false
            });
            if(calendarPath !== null) {
                const newItem = {
                    icon: <FontAwesomeIcon icon="calendar-alt" size="1x" className={classes.iconAwesome} />,
                    // name: `${intl.formatMessage(messages.calendar)}_${factorSetCalendar?.name}`,
                    name: factorSetCalendar?.name,
                    url: calendarPath.url
                } as ContextIndicatorItem;

                this._updateContextItems(newItem);
            }
        }

        else {
            if(location !== prevProps.location) {
                this.setState({
                    value: undefined
                });
            }
        }
    }

    _updateContextItems = (newItem: ContextIndicatorItem): void => {
        const { contextItems } = this.state;
        let newContextItems = contextItems;
        const index = newContextItems.findIndex((item: ContextIndicatorItem) => item.url === newItem.url);
        if(index !== -1) {
            newContextItems = contextItems.delete(index);
        }
        newContextItems = newContextItems.push(newItem);

        this.setState({
            value: newItem,
            contextItems: newContextItems
        });
    };

    _onSelect = (item: any) => {
        const { history } = this.props;

        history.push(item.url);
        this._handleClose();
    }

    _generateOption = (option: ContextIndicatorItem) => {
        const { classes } = this.props;

        return (<>
            <span className={classes.icon}>{option.icon}</span>
            <span className={classes.name}>{option.name}</span>
        </>);
    }

    _handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget,
            openMenu: true
        });
    };

    _handleClose = () => {
        this.setState({
            openMenu: false,
            anchorEl: null
        });
    };

    render(): JSX.Element {
        const { contextItems, openMenu, anchorEl } = this.state;
        const { classes } = this.props;

        return (
            <>
                <IconButton color="info" className={classes.menuButtons} onClick={this._handleMenu}>
                    <FontAwesomeIcon icon="history" size="1x" title="History" />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    open={openMenu}
                    onClose={this._handleClose}
                >

                    {!contextItems.size && <MenuItem disabled><FormattedMessage {...messages.noRoutes} /></MenuItem>}
                    {contextItems.reverse().map((option: ContextIndicatorItem) => (
                        <MenuItem
                            key={option.url}
                            value={option.url}
                            onClick={() => this._onSelect(option)}
                        >
                            {this._generateOption(option)}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    }
}

export default withStyles(styles)(withRouter(connect(mapStoreToProps)(injectIntl(ContextIndicator))));