import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import ServiceArenaType from '../interfaces/output/serviceArenaType';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ServiceArenaTypeApi {
    static fetchServiceTypeArenas = async(paging: Paging): Promise<Pagination<ServiceArenaType>> => {

        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/servicearenatype');
        
        const { data } = await http.get<Pagination<ServiceArenaType>>(url);

        return data;
    };
}
