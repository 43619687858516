import React from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import { useIntl } from 'react-intl';
import GTSOrderUnitDangerousGoods from '../../interfaces/output/gtsOrderUnitDangerousGoods';
import LanguageUtils from '../../utils/LanguageUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';
import CustomLink from '../common/customLink';
import CustomStringLink from '../common/customStringLink';

interface IOrderUnitDangerousGoodsListProps {
    classes?: any;
    gtsDangerousGoods?: Pagination<GTSOrderUnitDangerousGoods>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    orderNumber: LanguageUtils.createMessage('Order number'),
    gtsUnitType: LanguageUtils.createMessage('GTS unit type'),
    measuringUnit: LanguageUtils.createMessage('Measuring unit'),
    dangerousGoodsCode: LanguageUtils.createMessage('Dangerous goods code'),
    packagingGroup: LanguageUtils.createMessage('Packaging group'),
    receivedFromGTS: LanguageUtils.createMessage('Received from GTS')
};

export default function OrderUnitDangerousGoodsList(props: IOrderUnitDangerousGoodsListProps): JSX.Element {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, gtsDangerousGoods, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'orderNumber',
                headerName: intl.formatMessage(messages.orderNumber),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.UNIT_DANGEROUS_GOODS}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.orderNumber} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('orderNumber', hiddenColumns)
            },
            {
                field: 'gtsUnitType',
                headerName: intl.formatMessage(messages.gtsUnitType),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.UNIT_DANGEROUS_GOODS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.gtsUnitType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('gtsUnitType', hiddenColumns)
            },
            {
                field: 'measuringUnit',
                headerName: intl.formatMessage(messages.measuringUnit),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.UNIT_DANGEROUS_GOODS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.measuringUnit ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('measuringUnit', hiddenColumns)
            },
            {
                field: 'dangerousGoodsCode',
                headerName: intl.formatMessage(messages.dangerousGoodsCode),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) =>
                    <CustomStringLink link={`/#/${UrlConstants.UNIT_DANGEROUS_GOODS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.dangerousGoodsCode ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('dangerousGoodsCode', hiddenColumns)
            },
            {
                field: 'packagingGroup',
                headerName: intl.formatMessage(messages.packagingGroup),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.UNIT_DANGEROUS_GOODS}/${applyRowFn(params).id}`}
                    value={applyRowFn(params)?.packagingGroup ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('packagingGroup', hiddenColumns)
            },
            {
                field: 'receivedFromGTS',
                headerName: intl.formatMessage(messages.receivedFromGTS),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return (<CustomLink link={`/#/${UrlConstants.UNIT_DANGEROUS_GOODS}/${applyRowFn(params).id}`} >
                        <NullableFormattedDate value={params.row?.receivedFromGTS} />
                    </CustomLink>);
                },
                hide: hiddenColumns && TableUtils.hideColumn('receivedFromGTS', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || gtsDangerousGoods} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
}