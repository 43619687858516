import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import UnitStatusApi from '../api/unitStatusApi';
import OrderUnitStatus from '../interfaces/output/orderUnitStatus';
import { createUnitStatusError, createUnitStatusSuccess, deleteUnitStatusError, deleteUnitStatusSuccess, fetchUnitStatusByIdError, fetchUnitStatusByIdSuccess, fetchUnitStatusesError,
    fetchUnitStatusesSuccess, updateUnitStatusError, updateUnitStatusSuccess } from '../reducers/unitStatusReducer';

function* doFetchOrderUnitStatus(action: PayloadAction<string>) {
    const unitStatusId = action.payload;
    try {
        const response: OrderUnitStatus = yield call(UnitStatusApi.fetchUnitStatusById, unitStatusId);
        yield put(fetchUnitStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchOrderUnitStatuses(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<OrderUnitStatus> = yield call(UnitStatusApi.fetchUnitStatuses, paging);
        yield put(fetchUnitStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateOrderUnitStatus(action: PayloadAction<OrderUnitStatus>) {
    const unitStatus = action.payload;

    try {
        const response: OrderUnitStatus = yield call(UnitStatusApi.updateUnitStatus, unitStatus);

        yield put(updateUnitStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateUnitStatusError(error as HttpErrorResponse));
    }
}

function* doCreateOrderUnitStatus(action: PayloadAction<OrderUnitStatus>) {
    const unitStatus = action.payload;

    try {
        const response: OrderUnitStatus = yield call(UnitStatusApi.createUnitStatus, unitStatus);

        yield put(createUnitStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(createUnitStatusError(error as HttpErrorResponse));
    }
}

function* doDeleteServiceOrderUnitStatus(action: PayloadAction<OrderUnitStatus>) {
    const unitStatus = action.payload;
    
    try {
        const response: OrderUnitStatus = yield call(UnitStatusApi.deleteUnitStatus, unitStatus);

        yield put(deleteUnitStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteUnitStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('unitStatus/fetchUnitStatusById', doFetchOrderUnitStatus),
    takeLatest('unitStatus/fetchUnitStatuses', doFetchOrderUnitStatuses),
    takeLatest('unitStatus/updateUnitStatus', doUpdateOrderUnitStatus),
    takeLatest('unitStatus/createUnitStatus', doCreateOrderUnitStatus),
    takeLatest('unitStatus/deleteUnitStatus', doDeleteServiceOrderUnitStatus)
];

