import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { throwError } from '../reducers/errorReducer';
import { store } from '../setup/store';
import localStorageUtils from './localStorageUtils';
import { IAuthToken } from '../auth/login';
import httpResponseCodes from '../browser/httpResponseCodes';
import { userLogout } from '../reducers/userReducer';
import LanguageUtils from './LanguageUtils';

type HeaderRecord = Record<string, string>;

const usedLanguage = LanguageUtils.getLanguage();

const headers: HeaderRecord = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': usedLanguage
};
  
const injectToken = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_EXP, Date.now());

    return new Promise((resolve) => {
        
        if(config.headers && config.withCredentials !== false) {
            const token = localStorageUtils.getItem<IAuthToken>(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN)?.access_token;
            if(token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        resolve(config);
        
        // reject(err);
    });
};

class Http {
    private instance: AxiosInstance | null = null;

    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp();
    }

    initHttp(): AxiosInstance {
        const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
        const http = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            headers
        });

        const isIsoDateString = (value: string): boolean => moment(value, dateFormat, true).isValid();

        const handleDates = (body: any): void => {
            try {
                if(body !== null && body !== undefined && typeof body === 'object') {
                    for(const key of Object.keys(body)) {
                        const value = body[key];
                        if(isIsoDateString(value)) {
                            body[key] = new Date(value);
                        }
                        else if(typeof value === 'object') {
                            handleDates(value);
                        }
                    }
                }
            }
            catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
            }
        };

        http.interceptors.response.use(originalResponse => {
            handleDates(originalResponse.data);
            
            return originalResponse;
        });

        http.interceptors.request.use(injectToken, (error: Error) => Promise.reject(error));
        http.interceptors.request.use((config: AxiosRequestConfig) => {
            return injectToken(config).then((config: AxiosRequestConfig) => {
                return Promise.resolve(config);
            });
        }, (err) => {
            return Promise.reject(err);
        });

        http.interceptors.response.use(
            (response: any) => response,
            (error: any) => {
                const { response } = error;

                if(!response) {
                    //if token expired
                    if(error) {
                        localStorageUtils.clearItem(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN);
                    }
                    else {
                        const httpErorr = { error: error.message } as HttpErrorResponse;

                        store.dispatch(throwError(httpErorr));
        
                        return this.handleError(httpErorr);
                    }
                }

                return this.handleError(response as HttpErrorResponse);
            }
        );

        this.instance = http;
        
        return http;
    }

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config);
    }

    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config);
    }

    getFile<T = any, R = AxiosResponse<T>>(url: string, type: string, config?: AxiosRequestConfig): Promise<R> {
        
        return this.http.get<T, R>(url, { ...config,
            headers: { ...config?.headers,
                Accept: type },
            responseType: 'blob'
        });
    }

    post<TInput = any, TOutput = any, R = AxiosResponse<TOutput>>(
        url: string,
        data?: TInput,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.post<TInput, R>(url, data, config);
    }

    put<TInput = any, TOutput = any, R = AxiosResponse<TOutput>>(
        url: string,
        data?: TInput,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.put<TInput, R>(url, data, config);
    }

    delete<T = any, TOutput = any, R = AxiosResponse<TOutput>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.delete<T, R>(url, config);
    }

    // Handle global app errors
    // We can handle generic app errors depending on the status code
    private handleError(error: HttpErrorResponse) {
        if(error.status === httpResponseCodes.UNAUTHORIZED) {
            store.dispatch(userLogout());
        }
        store.dispatch(throwError(error));

        return Promise.reject(error);
    }
}

export const http = new Http();