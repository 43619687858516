import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import OUSPStatus from '../interfaces/output/ouspStatus';
import OuspStatusApi from '../api/ouspStatusApi';
import { createOUSPStatusError, createOUSPStatusSuccess, deleteOUSPStatusError, deleteOUSPStatusSuccess, fetchOUSPStatusByIdError, fetchOUSPStatusByIdSuccess, fetchOUSPStatusesError,
    fetchOUSPStatusesSuccess, updateOUSPStatusError, updateOUSPStatusSuccess } from '../reducers/ouspStatusReducer';

function* doFetchOUSPStatus(action: PayloadAction<string>) {
    const serviceStatusId = action.payload;
    try {
        const response: OUSPStatus = yield call(OuspStatusApi.fetchOUSPStatusById, serviceStatusId);
        yield put(fetchOUSPStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOUSPStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchOUSPStatuses(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<OUSPStatus> = yield call(OuspStatusApi.fetchOUSPStatuses, paging);
        yield put(fetchOUSPStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOUSPStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateOUSPStatus(action: PayloadAction<OUSPStatus>) {
    const serviceStatus = action.payload;

    try {
        const response: OUSPStatus = yield call(OuspStatusApi.updateOUSPStatus, serviceStatus);

        yield put(updateOUSPStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateOUSPStatusError(error as HttpErrorResponse));
    }
}

function* doCreateOUSPStatus(action: PayloadAction<OUSPStatus>) {
    const serviceStatus = action.payload;

    try {
        const response: OUSPStatus = yield call(OuspStatusApi.createOUSPStatus, serviceStatus);

        yield put(createOUSPStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(createOUSPStatusError(error as HttpErrorResponse));
    }
}

function* doDeleteServiceOUSPStatus(action: PayloadAction<OUSPStatus>) {
    const serviceStatus = action.payload;
    
    try {
        const response: OUSPStatus = yield call(OuspStatusApi.deleteOUSPStatus, serviceStatus);

        yield put(deleteOUSPStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteOUSPStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('ouspStatus/fetchOUSPStatusById', doFetchOUSPStatus),
    takeLatest('ouspStatus/fetchOUSPStatuses', doFetchOUSPStatuses),
    takeLatest('ouspStatus/updateOUSPStatus', doUpdateOUSPStatus),
    takeLatest('ouspStatus/createOUSPStatus', doCreateOUSPStatus),
    takeLatest('ouspStatus/deleteOUSPStatus', doDeleteServiceOUSPStatus)
];

