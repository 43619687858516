import React from 'react';
import { DatePicker } from '@mui/lab';
import { CircularProgress, InputAdornment, TextField, Theme, Tooltip } from '@mui/material';
import { MessageDescriptor, useIntl } from 'react-intl';
import { createStyles, makeStyles } from '@mui/styles';
import { HelpOutline } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            borderColor: theme.palette.error.main,
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)'
        }
    })
);

const datePickerType = {
    start: 'start',
    end: 'end',
    now: 'now'
};

interface IGenericDatePickerProps {
    /**
     * Label used in default comparison - default compareFn
     */
    name: string;
    /**
     * Label used in default comparison - default compareFn
     */
    label?: MessageDescriptor;
    /**
     * Selected value. The value should be included in the options (either found through a function, either direct value)
     */
    value?: Date | undefined | null;
    /**
     * OnChange function used for single selection
     */
    onChange: ((key: string, x: Date | null | undefined) => void);

    type?: 'start' | 'end' | 'now',
    error?: boolean;
    required?: boolean;

    helperText?: MessageDescriptor;

    isLoading: boolean;
}

const defaultProps: IGenericDatePickerProps = {
    name: '',
    label: undefined,
    onChange: () => { return; },
    required: false,
    error: false,
    isLoading: false
};

const GenericDatePicker = (props: IGenericDatePickerProps): JSX.Element => {
    const { label, value, onChange, name, type, error, required, helperText, isLoading } = props;
    const { formatMessage } = useIntl();
    const classes = useStyles();

    const tooltipText = helperText ? formatMessage(helperText) : '';
    let utc_val;
    if(value != null) {
        try {
            utc_val = Date.UTC(value?.getUTCFullYear(), value?.getUTCMonth(), value?.getUTCDate());
        }
        catch {
            const valueDate = new Date(value);
            utc_val = Date.UTC(valueDate?.getUTCFullYear(), valueDate?.getUTCMonth(), valueDate?.getUTCDate());
        }
        
    }

    return <DatePicker
        label={label ? formatMessage(label) : ''}
        value={utc_val || null}
        onChange={(newValue: Date | null) => {
            const val = newValue;
            if(val && type) {
                const dateNow = newValue;
                if(type === datePickerType.start) {
                    dateNow.setHours(0, 0, 0, 0);
                }
                if(type === datePickerType.end) {
                    dateNow.setHours(23, 59, 59);
                }
                dateNow.setTime(dateNow.getTime() - dateNow.getTimezoneOffset() * 60 * 1000);
                val.setHours(dateNow.getHours(), dateNow.getMinutes(), dateNow.getSeconds());
            }
            onChange(name, val);
        }}
        minDate={new Date(1900,0,1)}
        loading={isLoading}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <TextField {...params} variant="standard" error={error} required={required} className={error ? classes.textField : ''}
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <>
                            {isLoading ? <CircularProgress color="inherit" size={20} sx={{
                                position: 'absolute',
                                right: tooltipText ? 60 : 20
                            }} /> : null}
                            {tooltipText &&
                                <Tooltip title={tooltipText}>
                                    <HelpOutline />
                                </Tooltip>
                            }
                            {params.InputProps?.endAdornment}
                        </>
                    </InputAdornment>
                )
            }} />}
    />;
};

GenericDatePicker.defaultProps = defaultProps;
export default GenericDatePicker;