import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import D365Activity from '../interfaces/output/d365Activity';
import D365Account from '../interfaces/output/d365Account';
import D365ResponsibilityCenter from '../interfaces/output/d365ResponsibilityCenter';
import D365Object from '../interfaces/output/d365Object';
import D365Spec from '../interfaces/output/d365Spec';
import D365InternalTrade from '../interfaces/output/d365InternalTrade';
import D365VAT from '../interfaces/output/d365VAT';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class DynamicsApi {
    static fetchVats = async(paging: Paging): Promise<Pagination<D365VAT>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/vats');

        const { data } = await http.get<Pagination<D365VAT>>(url);

        return data;
    };

    static fetchActivities = async(paging: Paging): Promise<Pagination<D365Activity>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/activities');
        const { data } = await http.get<Pagination<D365Activity>>(url);

        return data;
    };

    static fetchAccounts = async(paging: Paging): Promise<Pagination<D365Account>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/accounts');
        const { data } = await http.get<Pagination<D365Account>>(url);

        return data;
    };

    static fetchResponsibilities = async(paging: Paging): Promise<Pagination<D365ResponsibilityCenter>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/responsibilitycenters');
        const { data } = await http.get<Pagination<D365ResponsibilityCenter>>(url);

        return data;
    };

    static fetchObjects = async(paging: Paging): Promise<Pagination<D365Object>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/objects');
        const { data } = await http.get<Pagination<D365Object>>(url);

        return data;
    };

    static fetchSpecs = async(paging: Paging): Promise<Pagination<D365Spec>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/specs');
        const { data } = await http.get<Pagination<D365Spec>>(url);

        return data;
    };

    static fetchTrades = async(paging: Paging): Promise<Pagination<D365InternalTrade>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/d365/internaltrades');
        const { data } = await http.get<Pagination<D365InternalTrade>>(url);

        return data;
    };
}
