import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerGroup from '../interfaces/output/customerGroup';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CustomerGroupState = {
    group: CustomerGroup;
    isLoading: boolean;
    groups: Pagination<CustomerGroup>;
    error: HttpErrorResponse | undefined;
};

const initialState: CustomerGroupState = {
    group: {} as CustomerGroup,
    groups: { content: [] as Array<CustomerGroup> } as Pagination<CustomerGroup>,
    isLoading: false,
    error: undefined
};

export const groupSlice = createSlice({
    name: 'group',

    initialState,
    reducers: {
        fetchCustomerGroups: (state: CustomerGroupState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchCustomerGroupsSuccess: (state: CustomerGroupState, action: PayloadAction<Pagination<CustomerGroup>>) => {
            state.isLoading = false;
            state.groups = action.payload;
        },
        fetchCustomerGroupsError: (state: CustomerGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCustomerGroup: (state: CustomerGroupState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchCustomerGroupSuccess: (state: CustomerGroupState, action: PayloadAction<CustomerGroup>) => {
            state.isLoading = false;
            state.group = action.payload;
        },
        fetchCustomerGroupError: (state: CustomerGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchCustomerGroups, fetchCustomerGroupsSuccess, fetchCustomerGroupsError, fetchCustomerGroup, fetchCustomerGroupSuccess, fetchCustomerGroupError } = groupSlice.actions;

export const isLoadingGroups = (state: RootState): boolean => state.customerGroupReducer.isLoading;
export const getGroups = (state: RootState): Pagination<CustomerGroup> => state.customerGroupReducer.groups;
export const getGroup = (state: RootState): CustomerGroup => state.customerGroupReducer.group;

export default groupSlice.reducer;