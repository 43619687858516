import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import LanguageUtils from '../utils/LanguageUtils';
import Loader from '../components/common/widgets/loader';
import { Lock } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Logo from '../assets/logo_login.png';
import AuthApi from '../api/authApi';
import localStorageUtils from '../utils/localStorageUtils';
import { fetchUserDetails, getUserDetails } from '../reducers/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import urlcat from 'urlcat';

const messages = {
    signInWithCapri: LanguageUtils.createMessage('Sign in with capri'),
    signIn: LanguageUtils.createMessage('Sign in')
};

interface ILoginProps {
    children: any;
}

export interface IAuthToken {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token: string;
    token_type: string;
    expiration_date: Date;
}

const UserLogin = ({ children }: ILoginProps): JSX.Element => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const propsUserDetails = useSelector(getUserDetails);

    const code = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('code');

        if(id) {
            window.history.pushState(null, '', process.env.REACT_APP_AUTH_REDIRECT_URL);
        }
        
        return id;
    }, [window]);

    const getToken = useCallback(async(code) => {
        const data = await AuthApi.getToken(code);
        const currentDate = new Date();
        localStorageUtils.saveItem(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN, { ...data,
            expiration_date: currentDate.setMinutes(currentDate.getMinutes() + data.expires_in / 60) } as IAuthToken);

        dispatch(fetchUserDetails(false));
    }, [code]);

    useEffect(() => {
        const token = localStorageUtils.getItem<IAuthToken>(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN);

        if(token && new Date(token.expiration_date).getTime() > new Date().getTime()) {
            dispatch(fetchUserDetails(true));
        }
    }, [dispatch]);

    useEffect(() => {
        if(code) {
            getToken(code);
        }
    }, [getToken, code]);

    const login = async() => {
        const url = urlcat(`${process.env.REACT_APP_AUTH_LOGIN_ENDPOINT}`,
            { response_type: 'code',
                grant_type: 'authorization_code',
                client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
                redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
                client_secret: ''
            });
        window.location.href = url;
    };

    if(propsUserDetails.id) {
        return children;
    }
    else {
        return (
            <Loader>
                <Container fixed component="main" maxWidth="xs">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh'
                        }}
                    >
                        <Card>
                            <CardMedia
                                component="img"
                                height="150"
                                image={Logo}
                                alt="logo"
                            />
                            <CardContent>
                                <Grid container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    p={3}>
                                    <Grid item p={2}>
                                        <Avatar sx={{ bgcolor: theme.palette.info.main }} >
                                            <Lock />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5">
                                            <FormattedMessage {...messages.signIn} />
                                        </Typography>
                                    </Grid>
                                    <Grid item p={2} mt={2}>
                                        <Button onClick={login} variant="contained">
                                            <FormattedMessage {...messages.signInWithCapri} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Loader>
        );
    }
};

export default UserLogin;