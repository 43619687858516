import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import Paging from '../../interfaces/common/paging';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import InvoiceFilters from '../../interfaces/filters/invoiceFilters';
import { fetchInvoices, getInvoices, isLoadingInvoices } from '../../reducers/invoiceReducer';
import InvoiceOut from '../../interfaces/output/invoiceOut';
import InvoiceList from './invoiceList';
import InvoiceFilterComponent from './invoiceFilterComponent';

interface IInvoicesProps {
    invoices: Pagination<InvoiceOut>;
    classes: any;
    fetch: any;
    isLoading: boolean;
    onChange: () => void;
    fetchInvoices: (paging: Paging, filters: InvoiceFilters) => void;
}

interface IInvoicesState {
    searchTerm: string;
    filters: InvoiceFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const messages = {
    invoices: LanguageUtils.createMessage('Invoices')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchInvoices: (paging: Paging, filters: InvoiceFilters) => dispatch(fetchInvoices({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        invoices: getInvoices(store),
        isLoading: isLoadingInvoices(store)
    };
};

class Invoices extends Component<IInvoicesProps, IInvoicesState> {
    invoiceListRef: any;
    constructor(props: IInvoicesProps) {
        super(props);
        this.invoiceListRef = React.createRef();

        this.state = {
            searchTerm: '',
            filters: {
                invoiceNumber: null
            } as InvoiceFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.invoices,
                icon: <FontAwesomeIcon icon="chart-bar" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchInvoices } = this.props;
        const { paging, filters } = this.state;

        fetchInvoices(paging, filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as InvoiceFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchInvoices } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination
        });

        fetchInvoices(newPagination, filters);

        if(this.invoiceListRef !== null && this.invoiceListRef !== undefined &&
            this.invoiceListRef.current !== null && this.invoiceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.invoiceListRef.current.resetDataGridPage();
        }

    }

    setDefaultState = () => {
        const { fetchInvoices } = this.props;
        const { paging } = this.state;

        const newFilters = {
            invoiceNumber: null
        } as InvoiceFilters;

        const newSortModel = [{
            field: 'invoiceNo',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchInvoices(newPagination, newFilters);

        if(this.invoiceListRef !== null && this.invoiceListRef !== undefined &&
            this.invoiceListRef.current !== null && this.invoiceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.invoiceListRef.current.resetDataGridPage();
        }
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchInvoices } = this.props;

        const newFilter = { ...filters } as InvoiceFilters;
        (newFilter as any)['invoiceNumber'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: { ...newFilter },
            paging: newPagination,
            searchTerm: value
        });

        fetchInvoices(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchInvoices } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });
            fetchInvoices(newPaging, filters);
        }
    }

    render() {
        const { classes, invoices, fetchInvoices, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                >
                                    <InvoiceFilterComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <InvoiceList rows={invoices} isLoading={isLoading}
                                    ref={this.invoiceListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchInvoices(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Invoices));