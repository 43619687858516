export default {
    String100: 100,
    String10: 10,
    String12: 12,
    String200: 200,
    String20: 20,
    String300: 300,
    String500: 500,
    String50: 50,
    String60: 60,
    String8: 8,
    String9: 9,
    String4: 4,
    String2000: 2000,
    StringMax: Math.pow(2, 27) - 1
};
