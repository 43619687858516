import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Theme, Grid, Paper, Container } from '@mui/material';
import UrlConstants from '../../../constants/UrlConstants';
import { RootState } from '../../../setup';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactorSetsFiltersComponent from '../factorSetsFiltersComponent';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import LanguageUtils from '../../../utils/LanguageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import PageUtils from '../../../utils/pageUtils';
import CancellationFilter from './cancellationFilters';
import { fetchFactorSetCancellations, getFactorSetCancellations, isLoadingFactorSetCancellation } from '../../../reducers/factorSetCancellationReducer';
import CancellationList from './cancellationList';

interface IFactorSetCancellationProps {
    factorSetCancellation: Pagination<FactorSetOut>;
    classes: any;
    fetchFactorSetCancellation: any;
    isLoading: boolean;
    onChange: () => void;
}

interface IFactorSetCancellationState {
    searchTerm: string;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    filters: CancellationFilter;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    factorSetCancellation: LanguageUtils.createMessage('Factor set cancellation'),
    add: LanguageUtils.createMessage('Add')
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetCancellation: (paging: Paging, filters: CancellationFilter) => dispatch(fetchFactorSetCancellations({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetCancellation: getFactorSetCancellations(store),
        isLoading: isLoadingFactorSetCancellation(store)
    };
};

class FactorSetCancellation extends Component<IFactorSetCancellationProps, IFactorSetCancellationState> {
    cancellationListRef: any;
    constructor(props: IFactorSetCancellationProps) {
        super(props);
        this.cancellationListRef = React.createRef();

        this.state = {
            searchTerm: '',
            paging: PageUtils.getDefaultPaging(),
            filters: {
                name: ''
            } as CancellationFilter,
            menuItem: {
                id: 1,
                name: messages.factorSetCancellation,
                icon: <FontAwesomeIcon icon="window-close" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetCancellation } = this.props;
        const { paging, filters } = this.state;

        fetchFactorSetCancellation(paging, filters);
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as CancellationFilter;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    applyFilters = () => {
        const { fetchFactorSetCancellation } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchFactorSetCancellation(newPagination, filters);

        if(this.cancellationListRef !== null && this.cancellationListRef !== undefined &&
            this.cancellationListRef.current !== null && this.cancellationListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.cancellationListRef.current.resetDataGridPage();
        }
    }

    setDefaultState = () => {
        const { fetchFactorSetCancellation } = this.props;
        const { paging } = this.state;

        const newFilters = { name: '' } as CancellationFilter;
        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchFactorSetCancellation(newPagination, newFilters);

        if(this.cancellationListRef !== null && this.cancellationListRef !== undefined &&
            this.cancellationListRef.current !== null && this.cancellationListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.cancellationListRef.current.resetDataGridPage();
        }

    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchFactorSetCancellation } = this.props;

        const newFilter = { ...filters } as CancellationFilter;
        (newFilter as any)['name'] = value;

        const newSortModel = [{
            field: 'name',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchFactorSetCancellation(newPagination, newFilter);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchFactorSetCancellation } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                paging: newPagination
            });

            fetchFactorSetCancellation(newPagination, filters);
        }
    }

    render() {
        const { classes, factorSetCancellation, fetchFactorSetCancellation, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.FACTOR_SETS}/cancellation/add`
                                    }]}
                                >
                                    <FactorSetsFiltersComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <CancellationList rows={factorSetCancellation} isLoading={isLoading}
                                    ref={this.cancellationListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchFactorSetCancellation(newPage, filters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetCancellation));