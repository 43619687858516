import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Theme } from '@mui/material';
import UrlConstants from '../../../constants/UrlConstants';
import Pagination from '../../../interfaces/common/pagination';
import DataGridComponent from '../../common/dataGrid';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../../utils/tableUtils';
import { useIntl } from 'react-intl';
import AgreementVersion from '../../../interfaces/output/agreementVersion';
import DisplayStatus from '../../common/displayStatus';
import LanguageUtils from '../../../utils/LanguageUtils';
import { ensure } from '../../../utils/arrayUtils';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import CustomLink from '../../common/customLink';
import CustomStringLink from '../../common/customStringLink';

interface IAgreementVersionsListProps {
    classes?: any;
    theme?: Theme;
    agreementVersions?: Pagination<AgreementVersion>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    hiddenColumns?: Array<string>;
    disableLinks?: boolean;
    applyRowFn?: any;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    versionNumber: LanguageUtils.createMessage('Version number'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    defaultTransitDiscount: LanguageUtils.createMessage('Default transit discount'),
    status: LanguageUtils.createMessage('Status')
};

const AgreementVersionsList = forwardRef((props: IAgreementVersionsListProps, ref: any): JSX.Element => {
    const { agreementVersions, componentsProps, isLoading, checkSelection, onPageChange, rows,
        hiddenColumns, disableLinks, onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        },
        setDataGridPage(page: number) {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.setPage(page);
            }
        }
    }));

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            {
                field: 'versionNumber',
                headerName: intl.formatMessage(messages.versionNumber),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${cellValues.row.agreementId}/${applyRowFn(cellValues)?.id}`} value={applyRowFn(cellValues).versionNumber} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('versionNumber', hiddenColumns)
            },
            {
                field: 'validFromDate',
                headerName: intl.formatMessage(messages.validFromDate),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${params?.row?.agreementId}/${applyRowFn(params)?.id}`}>
                        <NullableFormattedDate value={applyRowFn(params).validFromDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validFromDate', hiddenColumns)
            },
            {
                field: 'validToDate',
                headerName: intl.formatMessage(messages.validToDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    return <CustomLink disabled={disableLinks} shouldUseHistory={true}
                        link={`/${UrlConstants.AGREEMENTS}/${params?.row?.agreementId}/${applyRowFn(params)?.id}`}>
                        <NullableFormattedDate value={applyRowFn(params).validToDate} />
                    </CustomLink>;
                },
                hide: hiddenColumns && TableUtils.hideColumn('validToDate', hiddenColumns)
            },
            {
                field: 'defaultTransitDiscount',
                headerName: intl.formatMessage(messages.defaultTransitDiscount),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.AGREEMENTS}/${params?.row?.agreementId}/${applyRowFn(params)?.id}`}>{applyRowFn(params).defaultTransitDiscount &&
                        intl.formatNumber(ensure(Math.round(applyRowFn(params).defaultTransitDiscount)), {
                            maximumFractionDigits: 0
                        }) || '-'}
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('defaultTransitDiscount', hiddenColumns)
            },
            {
                field: 'status',
                headerName: intl.formatMessage(messages.status),
                flex: 1,
                align: 'center',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomLink disabled={disableLinks} shouldUseHistory={true}
                    link={`/${UrlConstants.AGREEMENTS}/${params?.row?.agreementId}/${applyRowFn(params)?.id}`}>
                    <DisplayStatus status={applyRowFn(params)?.status} inheritProps />
                </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('status', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows || agreementVersions} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange} columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
});

export default AgreementVersionsList;