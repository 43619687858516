import { put, takeLatest, call } from 'redux-saga/effects';
import UnitSubGroupApi from '../api/unitSubgroupApi';
import { fetchUnitSubgroupByIdError, fetchUnitSubgroupByIdSuccess, fetchUnitTypesBySubGroupIdError, fetchUnitTypesBySubGroupIdSuccess }
    from '../reducers/unitSubgroupReducer';
import UnitSubGroup from '../interfaces/output/unitSubGroup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import UnitType from '../interfaces/output/unitType';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';

function* doFetchUnitSubgroup(action: PayloadAction<string>) {
    const unitSubGroupId = action.payload;
    try {
        const response: UnitSubGroup = yield call(UnitSubGroupApi.fetchUnitSubGroupById, unitSubGroupId);
        yield put(fetchUnitSubgroupByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitSubgroupByIdError(error as HttpErrorResponse));
    }
}

function* doFetchUnitTypes(action: PayloadAction<{id: string, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<UnitType> = yield call(UnitSubGroupApi.fetchUnitTypes, id, paging);
        yield put(fetchUnitTypesBySubGroupIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUnitTypesBySubGroupIdError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('unitsubgroup/fetchUnitSubgroupById', doFetchUnitSubgroup),
    takeLatest('unitsubgroup/fetchUnitTypesBySubGroupId', doFetchUnitTypes)
];

