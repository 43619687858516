import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import Action from '../../interfaces/common/action';
import ActionRenderer from '../common/actionRenderer';
import GenericTextField from '../common/widgets/genericTextField';
import UserStatusOut from '../../interfaces/output/userStatusOut';
import TerminalFilters from '../../interfaces/filters/terminaleFilters';
import { fetchCountries, getCountries } from '../../reducers/countryReducer';
import { fetchTerminalOperators, getTerminalOperators } from '../../reducers/terminalOperatorReducer';
import Country from '../../interfaces/output/countryOut';
import TerminalOperator from '../../interfaces/output/terminalOperator';

interface IFilterProps {
    filters: TerminalFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

interface ValidOptionsProps {
    value: number | undefined;
    name: string;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    gtsTerminalCode: LanguageUtils.createMessage('Gts terminal code'),
    country: LanguageUtils.createMessage('Country'),
    operator: LanguageUtils.createMessage('Terminal operator'),
    hasResponsabilityCenter: LanguageUtils.createMessage('Has responsability center'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function TerminalFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const countries = useSelector(getCountries).content;
    const operators = useSelector(getTerminalOperators).content;

    useEffect(() => {
        dispatch(fetchCountries({ paging }));
        dispatch(fetchTerminalOperators({ paging }));
    }, [dispatch]);

    const hasResponsabilityCenterOptions = (): ValidOptionsProps[] => {
        return [
            { value: 0,
                name: 'No' },
            { value: 1,
                name: 'Yes' },
            { value: undefined,
                name: 'ALL' }
        ];
        
    };

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="gtsTerminalCode"
                                label={messages.gtsTerminalCode}
                                onChange={onChange}
                                value={filters.gtsTerminalCode}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Country>
                            options={countries}
                            value={filters.countryId}
                            onChange={(obj: Country | null) => onChange('countryId', obj?.id)}
                            placeholder={messages.country}
                            compareFn={(o: UserStatusOut) => o.id === filters.countryId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<TerminalOperator>
                            options={operators}
                            value={filters.operatorId}
                            onChange={(obj: TerminalOperator | null) => onChange('operatorId', obj?.id)}
                            placeholder={messages.operator}
                            compareFn={(o: TerminalOperator) => o.id === filters.operatorId}
                        />
                    </Grid>
                    <Grid item xs ml={2}>
                        <FormControl fullWidth>
                            <GenericAutocomplete<ValidOptionsProps>
                                options={hasResponsabilityCenterOptions()}
                                value={filters.hasTerminalResponsabilityCenter}
                                onChange={(obj: ValidOptionsProps | null) => onChange('hasTerminalResponsabilityCenter', obj?.value)}
                                placeholder={messages.hasResponsabilityCenter}
                                compareFn={(o: ValidOptionsProps) => o.value === filters.hasTerminalResponsabilityCenter}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}