import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import moment from 'moment';
import CalculatedPriceListFilters from '../interfaces/filters/calculatedPriceFilters';
import CalculatedPriceList from '../interfaces/output/calculatedPrice';
import CalculatedPrice from '../interfaces/output/calculatedPrice';

export default class ReportsApi {
    static fetchOrdersNotYetInvoiced = async(paging: Paging, filters?: OrdersNotYetInvoicedFilters): Promise<Pagination<OrderNotYetInvoiced>> => {
        
        const formattedFilters = { ...filters,
            fromDepartureDate: filters?.fromDepartureDate ? moment(filters.fromDepartureDate).toISOString() : undefined,
            toDepartureDate: filters?.toDepartureDate ? moment(filters?.toDepartureDate).toISOString() : undefined
        };

        const url = SortFormatterUtils.addSortingToUrl(paging, formattedFilters, '/reportnotyetinvoiced');

        const { data } = await http.get<Pagination<OrderNotYetInvoiced>>(url);

        return data;
    };

    static fetchCalculatedPrice = async(paging: Paging, filters?: CalculatedPriceListFilters): Promise<Pagination<CalculatedPriceList>> => {
        
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/reportcalculatedpricelist');

        const { data } = await http.get<Pagination<CalculatedPrice>>(url);

        return data;
    };
}