import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ErrorType from '../interfaces/output/errorType';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type ErrorTypeState = {
    errorType: ErrorType;
    isLoading: boolean;
    errorTypes: Pagination<ErrorType>;
    error: HttpErrorResponse | undefined;
};

const initialState: ErrorTypeState = {
    errorType: {} as ErrorType,
    errorTypes: { content: [] as Array<ErrorType> } as Pagination<ErrorType>,
    isLoading: false,
    error: undefined
};

export const errorTypeSlice = createSlice({
    name: 'errorType',

    initialState,
    reducers: {
        fetchErrorTypes: (state: ErrorTypeState, _action: PayloadAction<{paging: Paging, searchTerm?: string}>) => {
            state.isLoading = true;
        },
        fetchErrorTypesSuccess: (state: ErrorTypeState, action: PayloadAction<Pagination<ErrorType>>) => {
            state.isLoading = false;
            state.errorTypes = action.payload;
        },
        fetchErrorTypesError: (state: ErrorTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchErrorType: (state: ErrorTypeState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchErrorTypeSuccess: (state: ErrorTypeState, action: PayloadAction<ErrorType>) => {
            state.isLoading = false;
            state.errorType = action.payload;
        },
        fetchErrorTypeError: (state: ErrorTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchErrorTypes, fetchErrorTypesSuccess, fetchErrorTypesError, fetchErrorType, fetchErrorTypeSuccess, fetchErrorTypeError } = errorTypeSlice.actions;

export const isLoadingErrorTypes = (state: RootState): boolean => state.errorTypeReducer.isLoading;
export const getErrorTypes = (state: RootState): Pagination<ErrorType> => state.errorTypeReducer.errorTypes;
export const getErrorType = (state: RootState): ErrorType => state.errorTypeReducer.errorType;

export default errorTypeSlice.reducer;