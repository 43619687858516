import React, { useEffect } from 'react';
import AddFactorSetHoursStep1 from './addFactorSetHoursStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createFactorSetHour, createHoursVersion, fetchFactorSetHourById, getFactorSetHour, isCreatingHour, isCreatingVersion, isUpdatingHour,
    updateFactorSetHour } from '../../../../reducers/factorSetHoursReducer';
import { cloneHoursVersion, isCloningVersion, isUpdatingHoursVersionStatus, isUpdatingVersion, updateHoursVersion, updateHoursVersionStatus } from '../../../../reducers/factorSetHoursVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddHoursStep2 from './addFactorSetHoursStep2';
import AddHoursVersionStep5 from './addFactorSetHoursStep3';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';

interface IAddFactorSetFormProps {
    factorSetHoursId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set hours'),
    add: LanguageUtils.createMessage('Add factor set hours'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetHourForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetHoursId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetHoursId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetHours = useSelector(getFactorSetHour);
    const prevFactorSetHours = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetHours);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingHour);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingHour);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingHoursVersion = useSelector(isCreatingVersion);
    const prevIsCreatingHoursVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingHoursVersion);
    const propsIsUpdatingHoursVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingHoursVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingHoursVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingHoursStatus = useSelector(isUpdatingHoursVersionStatus);
    const prevIsUpdatingHoursStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingHoursStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingHoursVersion === true && !propsIsCreatingHoursVersion || prevIsUpdatingHoursVersion === true && !propsIsUpdatingHoursVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingHoursStatus === true && !propsIsUpdatingHoursStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingHoursStatus, propsIsUpdatingHoursVersion, propsIsCreatingHoursVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetHoursId) {
            dispatch(fetchFactorSetHourById({ factorSetId: factorSetHoursId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetHours && propsFactorSetHours.id) {
            setFactorSet(propsFactorSetHours);
        }

    }, [propsFactorSetHours.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newHoursVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newHoursVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newHoursVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetHoursStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetHour(factorSet));
                }
                else {
                    dispatch(updateFactorSetHour(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddHoursStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createHoursVersion(factorSet));
                }
                else {
                    dispatch(updateHoursVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.Hours} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(cloneHoursVersion({ toVersionId: ensure(propsFactorSetHours.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddHoursVersionStep5 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updateHoursVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/hours/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/hours/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/hours/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/hours/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/hours/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/hours/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  