import { http } from '../utils/http';
import Seller from '../interfaces/output/seller';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class SellerApi {
    static fetchSellerById = async(sellerId: string): Promise<Seller> => {
        const { data } = await http.get<Seller>(`seller/${sellerId}`);

        return data;
    };

    static fetchSellers = async(paging: Paging): Promise<Pagination<Seller>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/seller');
        const { data } = await http.get<Pagination<Seller>>(url);

        return data;
    };
}
