import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import PricelistVersionProduct from '../interfaces/output/pricelistVersionProduct';
import PricelistVersionProductFilters from '../interfaces/filters/pricelistVersionProductFilters';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type PricelistVersionProductState = {
    pricelistVersionProduct: PricelistVersionProduct;
    isLoading: boolean;
    isLoadingProducts: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    pricelistVersionProducts: Pagination<PricelistVersionProduct>;
    error: HttpErrorResponse | undefined;
};

// Define the initial state using that type
const initialState: PricelistVersionProductState = {
    pricelistVersionProduct: {} as PricelistVersionProduct,
    pricelistVersionProducts: { content: [] as Array<PricelistVersionProduct> } as Pagination<PricelistVersionProduct>,
    isLoading: false,
    isLoadingProducts: false,
    isDeleting: false,
    isUpdating: false,
    isCreating: false,
    error: undefined
};

export const pricelistVersionProductSlice = createSlice({
    name: 'pricelistVersionProduct',

    initialState,
    reducers: {
        fetchPricelistVersionProducts: (state: PricelistVersionProductState, _action: PayloadAction<{paging: Paging, filters: PricelistVersionProductFilters}>) => {
            state.isLoadingProducts = true;
        },
        fetchPricelistVersionProductsSuccess: (state: PricelistVersionProductState, action: PayloadAction<Pagination<PricelistVersionProduct>>) => {
            state.isLoadingProducts = false;
            state.pricelistVersionProducts = action.payload;
        },
        fetchPricelistVersionProductsError: (state: PricelistVersionProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProducts = false;
            state.error = action.payload;
        },
        fetchPricelistVersionProductById: (state: PricelistVersionProductState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchPricelistVersionProductByIdSuccess: (state: PricelistVersionProductState, action: PayloadAction<PricelistVersionProduct>) => {
            state.isLoading = false;
            state.pricelistVersionProduct = action.payload;
        },
        fetchPricelistVersionProductByIdError: (state: PricelistVersionProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createPricelistVersionProduct: (state: PricelistVersionProductState, _action: PayloadAction<PricelistVersionProduct>) => {
            state.isCreating = true;
        },
        createPricelistVersionProductSuccess: (state: PricelistVersionProductState, action: PayloadAction<PricelistVersionProduct>) => {
            state.isCreating = false;
            state.pricelistVersionProduct = action.payload;
        },
        createPricelistVersionProductError: (state: PricelistVersionProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updatePricelistVersionProduct: (state: PricelistVersionProductState, _action: PayloadAction<PricelistVersionProduct>) => {
            state.isUpdating = true;
        },
        updatePricelistVersionProductSuccess: (state: PricelistVersionProductState, action: PayloadAction<PricelistVersionProduct>) => {
            state.isUpdating = false;
            state.pricelistVersionProduct = action.payload;
        },
        updatePricelistVersionProductError: (state: PricelistVersionProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivatePricelistVersionProduct: (state: PricelistVersionProductState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivatePricelistVersionProductSuccess: (state: PricelistVersionProductState, action: PayloadAction<PricelistVersionProduct>) => {
            state.isDeleting = false;
            state.pricelistVersionProduct = action.payload;
        },
        deactivatePricelistVersionProductError: (state: PricelistVersionProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        }
    }
});

export const { fetchPricelistVersionProducts, fetchPricelistVersionProductsSuccess, fetchPricelistVersionProductsError, fetchPricelistVersionProductById,
    fetchPricelistVersionProductByIdSuccess, fetchPricelistVersionProductByIdError, createPricelistVersionProduct, createPricelistVersionProductSuccess,
    createPricelistVersionProductError, updatePricelistVersionProduct, updatePricelistVersionProductSuccess, updatePricelistVersionProductError,
    deactivatePricelistVersionProduct, deactivatePricelistVersionProductSuccess, deactivatePricelistVersionProductError } = pricelistVersionProductSlice.actions;

export const isLoadingPriceListVersionProduct = (state: RootState): boolean => state.pricelistVersionProductReducer.isLoading;
export const isLoadingPriceListVersionProducts = (state: RootState): boolean => state.pricelistVersionProductReducer.isLoadingProducts;
export const isDeletingPriceListVersionProduct = (state: RootState): boolean => state.pricelistVersionProductReducer.isDeleting;
export const isUpdatingPriceListVersionProduct = (state: RootState): boolean => state.pricelistVersionProductReducer.isUpdating;
export const isCreatingPriceListVersionProduct = (state: RootState): boolean => state.pricelistVersionProductReducer.isCreating;
export const getPricelistVersionProducts = (state: RootState): Pagination<PricelistVersionProduct> => state.pricelistVersionProductReducer.pricelistVersionProducts;
export const getPricelistVersionProduct = (state: RootState): PricelistVersionProduct => state.pricelistVersionProductReducer.pricelistVersionProduct;

export default pricelistVersionProductSlice.reducer;