import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import SurchargeAreaApi from '../api/surchargeAreaApi';
import SurchargeArea from '../interfaces/output/surchargeArea';
import { fetchSurchargeAreaByIdError, fetchSurchargeAreaByIdSuccess, fetchSurchargeAreasError, fetchSurchargeAreasSuccess } from '../reducers/surchargeAreaReducer';
import Paging from '../interfaces/common/paging';

function* doFetchSurchargeArea(action: PayloadAction<string>) {
    const surchargeAreaId = action.payload;
    try {
        const response: SurchargeArea = yield call(SurchargeAreaApi.fetchSurchargeAreaById, surchargeAreaId);
        yield put(fetchSurchargeAreaByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeAreaByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSurchargeAreas(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<SurchargeArea> = yield call(SurchargeAreaApi.fetchSurchargeAreas, paging);
        yield put(fetchSurchargeAreasSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeAreasError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('surchargeArea/fetchSurchargeAreaById', doFetchSurchargeArea),
    takeLatest('surchargeArea/fetchSurchargeAreas', doFetchSurchargeAreas)
];

