import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import PriorityTypeApi from '../api/priorityTypeApi';
import { fetchPriorityTypesError, fetchPriorityTypesSuccess } from '../reducers/priorityTypeReducer';

function* doFetchPriorityTypes() {
    try {
        const response: Pagination<PriorityTypeApi> = yield call(PriorityTypeApi.fetchPriorityTypes);
        yield put(fetchPriorityTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriorityTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('priorityType/fetchPriorityTypes', doFetchPriorityTypes)
];

