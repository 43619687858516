import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import ServiceType from '../interfaces/output/serviceType';
import ServiceTypeApi from '../api/serviceTypeApi';
import { createServiceTypeError, createServiceTypeSuccess, deleteServiceTypeError, deleteServiceTypeSuccess,
    fetchServiceTypeByIdError, fetchServiceTypeByIdSuccess, fetchServiceTypesError, fetchServiceTypesSuccess,
    updateServiceTypeError, updateServiceTypeSuccess } from '../reducers/serviceTypeReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchServiceType(action: PayloadAction<string>) {
    const serviceTypeId = action.payload;
    try {
        const response: ServiceType = yield call(ServiceTypeApi.fetchServiceTypeById, serviceTypeId);
        yield put(fetchServiceTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchServiceTypes(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<ServiceType> = yield call(ServiceTypeApi.fetchServiceTypes, paging);
        yield put(fetchServiceTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceTypesError(error as HttpErrorResponse));
    }
}

function* doUpdateServiceType(action: PayloadAction<ServiceType>) {
    const serviceType = action.payload;

    try {
        const response: ServiceType = yield call(ServiceTypeApi.updateServiceType, serviceType);

        yield put(updateServiceTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateServiceTypeError(error as HttpErrorResponse));
    }
}

function* doCreateServiceType(action: PayloadAction<ServiceType>) {
    const serviceType = action.payload;

    try {
        const response: ServiceType = yield call(ServiceTypeApi.createServiceType, serviceType);

        yield put(createServiceTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(createServiceTypeError(error as HttpErrorResponse));
    }
}

function* doDeleteServiceType(action: PayloadAction<ServiceType>) {
    const serviceType = action.payload;
    
    try {
        const response: ServiceType = yield call(ServiceTypeApi.deleteServiceType, serviceType);

        yield put(deleteServiceTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteServiceTypeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('serviceType/fetchServiceTypeById', doFetchServiceType),
    takeLatest('serviceType/fetchServiceTypes', doFetchServiceTypes),
    takeLatest('serviceType/updateServiceType', doUpdateServiceType),
    takeLatest('serviceType/createServiceType', doCreateServiceType),
    takeLatest('serviceType/deleteServiceType', doDeleteServiceType)
];