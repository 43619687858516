import React, { useEffect } from 'react';
import AddFactorSetDangerousGoodsStep1 from './addFactorSetDangerousGoodsStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createFactorSetDangerousGoods, createDangerousGoodsVersion, fetchFactorSetDangerousGoodsById, getFactorSetDangerousGood, isCreatingDangerousGoods, isCreatingVersion,
    isUpdatingDangerousGoods, updateFactorSetDangerousGoods } from '../../../../reducers/factorSetDangerousGoodsReducer';
import { cloneDangerousGoodVersion, isCloningVersion, isUpdatingDangerousGoodVersionStatus, isUpdatingVersion, updateDangerousGoodVersion, updateDangerousGoodVersionStatus }
    from '../../../../reducers/factorSetDangerousGoodsVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddDangerousGoodsStep2 from './addFactorSetDangerousGoodsStep2';
import AddDangerousGoodsVersionStep3 from './addFactorSetDangerousGoodsStep3';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';

interface IAddFactorSetFormProps {
    factorSetDangerousGoodsId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set dangerous goods'),
    add: LanguageUtils.createMessage('Add factor set dangerous goods'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetDangerousGoodsForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetDangerousGoodsId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetDangerousGoodsId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetDangerousGood = useSelector(getFactorSetDangerousGood);
    const prevFactorSetDangerousGoods = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetDangerousGood);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingDangerousGoods);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingDangerousGoods);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingDangerousGoodsVersion = useSelector(isCreatingVersion);
    const prevIsCreatingDangerousGoodsVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingDangerousGoodsVersion);
    const propsIsUpdatingDangerousGoodsVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingDangerousGoodsVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingDangerousGoodsVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingDangerousGoodsStatus = useSelector(isUpdatingDangerousGoodVersionStatus);
    const prevIsUpdatingDangerousGoodsStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingDangerousGoodsStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingDangerousGoodsVersion === true && !propsIsCreatingDangerousGoodsVersion || prevIsUpdatingDangerousGoodsVersion === true && !propsIsUpdatingDangerousGoodsVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingDangerousGoodsStatus === true && !propsIsUpdatingDangerousGoodsStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingDangerousGoodsStatus, propsIsUpdatingDangerousGoodsVersion, propsIsCreatingDangerousGoodsVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetDangerousGoodsId) {
            dispatch(fetchFactorSetDangerousGoodsById({ factorSetId: factorSetDangerousGoodsId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetDangerousGoods && propsFactorSetDangerousGood.id) {
            setFactorSet(propsFactorSetDangerousGood);
        }
    }, [propsFactorSetDangerousGood.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newDangerousGoodsVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newDangerousGoodsVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newDangerousGoodsVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetDangerousGoodsStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetDangerousGoods(factorSet));
                }
                else {
                    dispatch(updateFactorSetDangerousGoods(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddDangerousGoodsStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createDangerousGoodsVersion(factorSet));
                }
                else {
                    dispatch(updateDangerousGoodVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.DangerousGoods} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(cloneDangerousGoodVersion({ toVersionId: ensure(propsFactorSetDangerousGood.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddDangerousGoodsVersionStep3 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updateDangerousGoodVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSet.id}/${factorSet.version.id}` :
                    `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSet.id}/${factorSet.version.id}` :
                    `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/dangerousgoods/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  