import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import DashboardMessage from '../interfaces/output/dashboardMessage';
import DashboardMessageFilters from '../interfaces/filters/dashboardMessageFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type DashboardMessageState = {
    isLoadingDashboardMessages: boolean;
    dashboardMessages: Pagination<DashboardMessage>;
    error: HttpErrorResponse | undefined;
    isExporting: boolean;
};

const initialState: DashboardMessageState = {
    dashboardMessages: { content: [] as Array<DashboardMessage> } as Pagination<DashboardMessage>,
    isLoadingDashboardMessages: false,
    error: undefined,
    isExporting: false
};

export const dashboardMessageSlice = createSlice({
    name: 'dashboardMessage',

    initialState,
    reducers: {
        fetchDashboardMessages: (state: DashboardMessageState, _action: PayloadAction<{paging: Paging, filters: DashboardMessageFilters}>) => {
            state.isLoadingDashboardMessages = true;
        },
        fetchDashboardMessagesSuccess: (state: DashboardMessageState, action: PayloadAction<Pagination<DashboardMessage>>) => {
            state.isLoadingDashboardMessages = false;
            state.dashboardMessages = action.payload;
        },
        fetchDashboardMessagesError: (state: DashboardMessageState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDashboardMessages = false;
            state.error = action.payload;
        },
        exportDashboardMessages: (state: DashboardMessageState, _action: PayloadAction<{filters: DashboardMessageFilters}>) => {
            state.isExporting = true;
        },
        exportDashboardMessagesSuccess: (state: DashboardMessageState) => {
            state.isExporting = false;
        },
        exportDashboardMessagesError: (state: DashboardMessageState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExporting = false;
            state.error = action.payload;
        },
        resetDashboardMessages: (state: DashboardMessageState) => {
            state.dashboardMessages = initialState.dashboardMessages;
        }
    }
});

export const { fetchDashboardMessages, fetchDashboardMessagesSuccess, fetchDashboardMessagesError, resetDashboardMessages, exportDashboardMessages, exportDashboardMessagesSuccess,
    exportDashboardMessagesError } = dashboardMessageSlice.actions;

export const isLoadingDashboardMessages = (state: RootState): boolean => state.dashboardMessageReducer.isLoadingDashboardMessages;
export const isExportingDashboardMessages = (state: RootState): boolean => state.dashboardMessageReducer.isExporting;
export const getDashboardMessages = (state: RootState): Pagination<DashboardMessage> => state.dashboardMessageReducer.dashboardMessages;

export default dashboardMessageSlice.reducer;