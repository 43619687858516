import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import OrderUnit from '../../interfaces/output/orderUnit';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface IViewOrderUnitProps {
    classes?: any;
    theme?: Theme;
    orderUnit: OrderUnit;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Order unit details'),
    unitType: LanguageUtils.createMessage('Unit type'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    grossWeightKg: LanguageUtils.createMessage('Gross weight kg'),
    customerReference: LanguageUtils.createMessage('Customer reference'),
    departureType: LanguageUtils.createMessage('Departure type'),
    importExportCode: LanguageUtils.createMessage('Import export code'),
    dangerousGoodsGroup: LanguageUtils.createMessage('Dangerous goods group'),
    netWeightKg: LanguageUtils.createMessage('Net weight'),
    cancellationType: LanguageUtils.createMessage('Cancellation type'),
    departureTime: LanguageUtils.createMessage('Departure time'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    loadedFlag: LanguageUtils.createMessage('Loaded flag')
};

export default function ViewOrderUnit(props: IViewOrderUnitProps): JSX.Element {
    const classes = styles();
    const { orderUnit } = props;
    const intl = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.unitNo || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.unitType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.netWeightKg} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.netWeightKg !== undefined && orderUnit.netWeightKg !== null &&
                                        intl.formatNumber(ensure(Math.round(orderUnit.netWeightKg)), {
                                            maximumFractionDigits: 0
                                        }) || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.grossWeightKg} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.grossWeightKg !== undefined && orderUnit.grossWeightKg !== null &&
                                        intl.formatNumber(ensure(Math.round(orderUnit.grossWeightKg)), {
                                            maximumFractionDigits: 0
                                        }) || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.customerReference} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.customerReference || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureType} />: </Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography gutterBottom>{orderUnit.departureType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priorityType} />: </Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography gutterBottom>{orderUnit.priorityType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureTime} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>
                                        {<NullableFormattedDate value={orderUnit.departureDatetime} />}&nbsp;
                                        {orderUnit.departureDatetime ? <FormattedTime hour="numeric" minute="numeric" value={orderUnit.departureDatetime} /> : ' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.importExportCode} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.importExportCode?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.dangerousGoodsGroup} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.dangerousGoodsGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.cancellationType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.cancellationType?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.totalPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.orderUnitTotalPrice || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.loadedFlag} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{orderUnit.loadedFlag?.toString() || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}