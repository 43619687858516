import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceLineApi from '../api/invoiceLineApi';
import InvoiceLineOut from '../interfaces/output/invoiceLineOut';
import { deactivateInvoiceLineError, deactivateInvoiceLineSuccess, fetchInvoiceLineByIdError, fetchInvoiceLineByIdSuccess, updateInvoiceLineError, updateInvoiceLineSuccess, createInvoiceLineError,
    createInvoiceLineSuccess } from '../reducers/invoiceLineReducer';

function* doFetchInvoiceLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: InvoiceLineOut = yield call(InvoiceLineApi.fetchInvoicetWeightLineById, lineId);
        yield put(fetchInvoiceLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceLineByIdError(error as HttpErrorResponse));
    }
}

function* doCreateInvoiceLine(action: PayloadAction<InvoiceLineOut>) {
    const factorSet = action.payload;

    try {
        const response: InvoiceLineOut = yield call(InvoiceLineApi.createInvoiceLine, factorSet);

        yield put(createInvoiceLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createInvoiceLineError(error as HttpErrorResponse));
    }
}

function* doUpdateInvoiceLine(action: PayloadAction<InvoiceLineOut>) {
    const line = action.payload;

    try {
        const response: InvoiceLineOut = yield call(InvoiceLineApi.updateInvoiceLine, line);

        yield put(updateInvoiceLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateInvoiceLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateInvoiceLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: InvoiceLineOut = yield call(InvoiceLineApi.deactivateInvoiceLine, lineId);

        yield put(deactivateInvoiceLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateInvoiceLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoiceLine/fetchInvoiceLineById', doFetchInvoiceLineById),
    takeLatest('invoiceLine/createInvoiceLine', doCreateInvoiceLine),
    takeLatest('invoiceLine/updateInvoiceLine', doUpdateInvoiceLine),
    takeLatest('invoiceLine/deactivateInvoiceLine', doDeactivateInvoiceLine)
];

