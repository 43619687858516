import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OUSPStatus from '../interfaces/output/ouspStatus';
import OUSStatus from '../interfaces/output/ousStatus';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type OUSPStatusState = {
    ouspStatus: OUSPStatus;
    ouspStatuses: Pagination<OUSPStatus>;
    isLoadingOUSPServiceStatus: boolean;
    isLoadingOUSPServiceStatuses: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: OUSPStatusState = {
    ouspStatus: {} as OUSPStatus,
    ouspStatuses: { content: [] as Array<OUSPStatus> } as Pagination<OUSPStatus>,
    isLoadingOUSPServiceStatus: false,
    isLoadingOUSPServiceStatuses: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const ouspStatusSlice = createSlice({
    name: 'ouspStatus',

    initialState,
    reducers: {
        fetchOUSPStatuses: (state: OUSPStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingOUSPServiceStatuses = true;
        },
        fetchOUSPStatusesSuccess: (state: OUSPStatusState, action: PayloadAction<Pagination<OUSStatus>>) => {
            state.isLoadingOUSPServiceStatuses = false;
            state.ouspStatuses = action.payload;
        },
        fetchOUSPStatusesError: (state: OUSPStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOUSPServiceStatuses = false;
            state.error = action.payload;
        },
        fetchOUSPStatusById: (state: OUSPStatusState, _action: PayloadAction<number>) => {
            state.isLoadingOUSPServiceStatus = true;
        },
        fetchOUSPStatusByIdSuccess: (state: OUSPStatusState, action: PayloadAction<OUSStatus>) => {
            state.isLoadingOUSPServiceStatus = false;
            state.ouspStatus = action.payload;
        },
        fetchOUSPStatusByIdError: (state: OUSPStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOUSPServiceStatus = false;
            state.error = action.payload;
        },
        createOUSPStatus: (state: OUSPStatusState, _action: PayloadAction<OUSStatus>) => {
            state.isCreating = true;
        },
        createOUSPStatusSuccess: (state: OUSPStatusState, action: PayloadAction<OUSStatus>) => {
            state.isCreating = false;
            state.ouspStatus = action.payload;
        },
        createOUSPStatusError: (state: OUSPStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateOUSPStatus: (state: OUSPStatusState, _action: PayloadAction<OUSStatus>) => {
            state.isUpdating = true;
        },
        updateOUSPStatusSuccess: (state: OUSPStatusState, action: PayloadAction<OUSStatus>) => {
            state.isUpdating = false;
            state.ouspStatus = action.payload;
        },
        updateOUSPStatusError: (state: OUSPStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteOUSPStatus: (state: OUSPStatusState, _action: PayloadAction<OUSStatus>) => {
            state.isDeleted = true;
        },
        deleteOUSPStatusSuccess: (state: OUSPStatusState, action: PayloadAction<OUSStatus>) => {
            state.isDeleted = false;
            state.ouspStatus = action.payload;
        },
        deleteOUSPStatusError: (state: OUSPStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetOUSPStatus: (state: OUSPStatusState) => {
            state.ouspStatuses = initialState.ouspStatuses;
        }
    }
});

export const { fetchOUSPStatuses, fetchOUSPStatusesSuccess, fetchOUSPStatusesError, fetchOUSPStatusById, fetchOUSPStatusByIdSuccess, fetchOUSPStatusByIdError, resetOUSPStatus, createOUSPStatus,
    createOUSPStatusSuccess, createOUSPStatusError, updateOUSPStatus, updateOUSPStatusError, updateOUSPStatusSuccess, deleteOUSPStatus, deleteOUSPStatusError, deleteOUSPStatusSuccess }
    = ouspStatusSlice.actions;

export const isLoadingOUSPServiceStatus = (state: RootState): boolean => state.ouspStatusReducer.isLoadingOUSPServiceStatus;
export const isLoadingOUSPServiceStatuses = (state: RootState): boolean => state.ouspStatusReducer.isLoadingOUSPServiceStatuses;
export const isUpdating = (state: RootState): boolean => state.ouspStatusReducer.isUpdating;
export const isDeleted = (state: RootState): boolean => state.ouspStatusReducer.isDeleted;
export const isCreating = (state: RootState): boolean => state.ouspStatusReducer.isCreating;
export const getOUSPStatuses = (state: RootState): Pagination<OUSPStatus> => state.ouspStatusReducer.ouspStatuses;
export const getOUSPStatus = (state: RootState): OUSPStatus => state.ouspStatusReducer.ouspStatus;

export default ouspStatusSlice.reducer;