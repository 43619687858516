import { List } from 'immutable';
import inputLengthConstants from '../../../../../constants/inputLengthConstants';
import FactorSetCancellationLineOutput from '../../../../../interfaces/output/factorSetCancellationLineOut';
import { ensure } from '../../../../../utils/arrayUtils';
import LanguageUtils from '../../../../../utils/LanguageUtils';
import ValidationModel from '../../../../../utils/validationModel';
import ValidationUtils from '../../../../../utils/validationUtils';

const inputsMaxLengths = {
    name: inputLengthConstants.String60
};

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class FactorSetCancellationLineStep1Validation extends ValidationUtils {

    static validateFactorSetForm(factorSetLine: FactorSetCancellationLineOutput): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            unitType,
            cancellationType,
            factor,
            surcharge,
            surchargeCurrency
        } = factorSetLine;

        const requiredFields =
            this.validateName(name) &&
            this.validateInputNumber(unitType?.id, { required: surcharge ? true : false }) &&
            this.validateRequiredNumberWithMaxLength(cancellationType?.id) &&
            this.validateInputNumber(factor) &&
            this.validateInputNumber(surchargeCurrency?.id, { required: surcharge ? true : false });

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }

    static validateName = (inputString?: string): boolean => {
        return this.validateInputStringLength(inputString, {
            inputLength: inputsMaxLengths.name
        });
    }
}
