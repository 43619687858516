import React, { useEffect } from 'react';
import AddFactorSetDepartureStep1 from './addFactorSetDepartureStep1';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../../common/widgets/genericStepper';
import GenericStep from '../../../../interfaces/common/genericStep';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import UrlConstants from '../../../../constants/UrlConstants';
import WorkflowUtils from '../../../../utils/workflowUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormType } from '../../../../constants/constants';
import { createDepartureVersion, createFactorSetDeparture, fetchFactorSetDepartureById, getFactorSetDeparture, isCreatingDeparture, isCreatingVersion, isUpdatingDeparture,
    updateFactorSetDeparture } from '../../../../reducers/factorSetDepartureReducer';
import { cloneDepartureVersion, isCloningVersion, isUpdatingDepartureVersionStatus, isUpdatingVersion, updateDepartureVersion, updateDepartureVersionStatus }
    from '../../../../reducers/factorSetDepartureVersionReducer';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import { ensure } from '../../../../utils/arrayUtils';
import AddDepartureStep2 from './addFactorSetDepartureStep2';
import AddDepartureVersionStep3 from './addFactorSetDepartureStep3';
import { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import CloneFactorSetVersionStep from '../../common/add/cloneFactorSetVersionStep';
import FactorSetStep1Validation from '../../validations/factorSetStep1Validation';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';

interface IAddFactorSetFormProps {
    factorSetDepartureId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set departure'),
    add: LanguageUtils.createMessage('Add factor set departure'),
    chooseVersion: LanguageUtils.createMessage('Choose version'),
    editVersion: LanguageUtils.createMessage('Edit factor set version'),
    addVersion: LanguageUtils.createMessage('Add factor set version'),
    changeStatus: LanguageUtils.createMessage('Change factor set version status'),
    wouldYouLikeToCloneVersion: LanguageUtils.createMessage('Would you like to clone')

};

export default function AddFactorSetDepartureForm(props: IAddFactorSetFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { factorSetDepartureId, steps, type, factorSetVersionId } = props;
    const [factorSet, setFactorSet] = React.useState<FactorSetOut>({ id: factorSetDepartureId,
        version: {} } as FactorSetOut);
    const [selectedFactorSetVersion, setSelectedFactorSetVersion] = React.useState<FactorSetVersionOut | undefined>();
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsFactorSetDeparture = useSelector(getFactorSetDeparture);
    const prevFactorSetDeparture = WorkflowUtils.usePrevious<FactorSetOut>(propsFactorSetDeparture);
    const propsIsUpdatingFactorSet = useSelector(isUpdatingDeparture);
    const prevIsUpdatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingFactorSet);
    const propsIsCreatingFactorSet = useSelector(isCreatingDeparture);
    const prevIsCreatingFactorSet = WorkflowUtils.usePrevious<boolean>(propsIsCreatingFactorSet);
    const propsIsCreatingDepartureVersion = useSelector(isCreatingVersion);
    const prevIsCreatingDepartureVersion = WorkflowUtils.usePrevious<boolean>(propsIsCreatingDepartureVersion);
    const propsIsUpdatingDepartureVersion = useSelector(isUpdatingVersion);
    const prevIsUpdatingDepartureVersion = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingDepartureVersion);
    const propsIsCloningVersion = useSelector(isCloningVersion);
    const prevIsCloningVersion = WorkflowUtils.usePrevious<boolean>(propsIsCloningVersion);
    const propsIsUpdatingDepartureStatus = useSelector(isUpdatingDepartureVersionStatus);
    const prevIsUpdatingDepartureStatus = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingDepartureStatus);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingFactorSet === true && !propsIsUpdatingFactorSet) || (prevIsCreatingFactorSet && !propsIsCreatingFactorSet)) {
                    setRedirectCondition(true);
                }
                break;
            case 2:
                if(prevIsCreatingDepartureVersion === true && !propsIsCreatingDepartureVersion || prevIsUpdatingDepartureVersion === true && !propsIsUpdatingDepartureVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsCloningVersion === true && !propsIsCloningVersion) {
                    setRedirectCondition(true);
                }
                break;
            case 4:
                if(prevIsUpdatingDepartureStatus === true && !propsIsUpdatingDepartureStatus) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingFactorSet, propsIsUpdatingFactorSet, propsIsCloningVersion, propsIsUpdatingDepartureStatus, propsIsUpdatingDepartureVersion, propsIsCreatingDepartureVersion]);
    
    useEffect(() => {
        if([FormType.Edit, FormType.EditVersion, FormType.ChangeStatus].includes(type) && factorSetDepartureId) {
            dispatch(fetchFactorSetDepartureById({ factorSetId: factorSetDepartureId,
                versionId: factorSetVersionId }));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevFactorSetDeparture && propsFactorSetDeparture.id) {
            setFactorSet(propsFactorSetDeparture);
        }

    }, [propsFactorSetDeparture.id]);

    const onChange = (attribute: string, value: any) => {
        const newFactorSet = { ...factorSet } as FactorSetOut;
        (newFactorSet as any)[attribute] = value;

        setFactorSet(newFactorSet);
    };
    
    const onChangeVersion = (attribute: string, value: any) => {
        const newDepartureVersion = { ...factorSet.version } as FactorSetVersionOut;
        (newDepartureVersion as any)[attribute] = value;

        setFactorSet({
            ...factorSet,
            version: newDepartureVersion
        });
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddFactorSetDepartureStep1 onChange={onChange} factorSet={factorSet} />,
            validationFn: () => FactorSetStep1Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.id) {
                    dispatch(createFactorSetDeparture(factorSet));
                }
                else {
                    dispatch(updateFactorSetDeparture(factorSet));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddDepartureStep2 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            validationFn: () => FactorSetStep2Validation.validateFactorSetForm(factorSet),
            onNext: () => {
                if(!factorSet.version?.id) {
                    dispatch(createDepartureVersion(factorSet));
                }
                else {
                    dispatch(updateDepartureVersion(factorSet.version));
                }
            }
        } as GenericStep,
        {
            id: 3,
            content: <CloneFactorSetVersionStep isLoading={propsIsCloningVersion}
                type={FactorSetPickerType.Departure} factorSetVersion={selectedFactorSetVersion} setFactorSetVersion={setSelectedFactorSetVersion} />,
            description: messages.wouldYouLikeToCloneVersion,
            onNext: () => {
                dispatch(cloneDepartureVersion({ toVersionId: ensure(propsFactorSetDeparture.version?.id),
                    fromVersionId: ensure(selectedFactorSetVersion?.id) }));
            },
            isOptional: !selectedFactorSetVersion?.id
        } as GenericStep,
        {
            id: 4,
            content: <AddDepartureVersionStep3 onChangeVersion={onChangeVersion} factorSet={factorSet} />,
            onNext: () => {
                dispatch(updateDepartureVersionStatus(ensure(factorSet.version)));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.AddVersion:
                return messages.addVersion;
            case FormType.EditVersion:
                return messages.editVersion;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : string => {
        switch (type) {
            case FormType.Add:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/departure/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/departure/${factorSet.id}/latest`;
            case FormType.Edit:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/departure/${factorSet.id}/${factorSet.version.id}` : `/${UrlConstants.FACTOR_SETS}/departure/${factorSet.id}/latest`;
            case FormType.AddVersion:
            case FormType.EditVersion:
            case FormType.ChangeStatus:
                return factorSet.version?.id ? `/${UrlConstants.FACTOR_SETS}/departure/${factorSet.id}/${factorSet.version.id}/versions` :
                    `/${UrlConstants.FACTOR_SETS}/departure/${factorSet.id}/latest/versions`;
            default:
                throw new Error('Redirect to required');
        }
    };
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  