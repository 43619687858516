import ErrorTypeApi from '../api/errorTypeApi';
import { fetchErrorTypeSuccess, fetchErrorTypesSuccess, fetchErrorTypeError, fetchErrorTypesError } from '../reducers/errorTypeReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import ErrorType from '../interfaces/output/errorType';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchErrorType(action: PayloadAction<string>) {
    const id = action.payload;
    try {
        const response: ErrorType = yield call(ErrorTypeApi.fetchErrorType, id);
        yield put(fetchErrorTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchErrorTypeError(error as HttpErrorResponse));
    }
}

function* doFetchErrorTypes(action: PayloadAction<{ paging: Paging, searchTerm: string}>) {
    const { paging, searchTerm } = action.payload;

    try {
        const response: Pagination<ErrorType> = yield call(ErrorTypeApi.fetchErrorTypes, paging, searchTerm);
        yield put(fetchErrorTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchErrorTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('errorType/fetchErrorType', doFetchErrorType),
    takeLatest('errorType/fetchErrorTypes', doFetchErrorTypes)];
