export const StatusConstants = {
    Active: 'Active',
    Inactive: 'Inactive',
    Deleted: 'Deleted',
    Valid: 'Valid',
    Invalid: 'Invalid',
    InProgress: 'In progress',
    NotActive: 'Not active.',
    NotActiveUsers: 'Not active',
    OnHold: 'On Hold - Orders will not be invoiced',
    True: 'True',
    False: 'False'
};

export const StatusCodes = {
    Ready: 'READY',
    Invoiced: 'INVOICED',
    Credited: 'CREDITED',
    InProgress: 'INPROGRESS',
    OnHold: 'ONHOLD',
    Failed: 'FAILED',
    Expired: 'EXPIRED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Deleted: 'DELETED',
    Complete: 'COMPLETE',
    Error: 'ERROR',
    Valid: 'VALID',
    Invalid: 'INVALID',
    Future: 'FUTURE',
    Disabled: 'DISABLED',
    True: 'TRUE',
    False: 'FALSE',
    Cancelled: 'CANCELLED',
    Free: 'FREE'
};

export const OrderStatusId = {
    InProgress: 1,
    Ready: 2,
    OnHold: 3,
    Invoiced: 4,
    Cancelled: 5,
    Credited: 6,
    Failed: 7
};

export const ServiceStatusId = {
    InProgress: 1
};

export const LoadedFlagStatus = {
    Empty: 'EMPTY',
    Loaded: 'LOADED'
};