import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import UrlConstants from '../../constants/UrlConstants';
import DataGridComponent from '../common/dataGrid';
import TableUtils from '../../utils/tableUtils';
import Pagination from '../../interfaces/common/pagination';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import CustomStringLink from '../common/customStringLink';

interface IOrderUnitListProps {
    classes?: any;
    orderUnits?: Pagination<GTSOrderUnit>;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    unitNumber: LanguageUtils.createMessage('Unit number'),
    gtsUnitType: LanguageUtils.createMessage('GTS unit type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    customerReference: LanguageUtils.createMessage('Customer reference'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    teu: LanguageUtils.createMessage('TEU'),
    fromPlace: LanguageUtils.createMessage('From place'),
    toPlace: LanguageUtils.createMessage('To place')
};

const GtsOrderUnitsList = forwardRef((props: IOrderUnitListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, orderUnits, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'unitNumber',
                headerName: intl.formatMessage(messages.unitNumber),
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(cellValues).id}`} value={applyRowFn(cellValues)?.unitNumber || 'N/A'} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('unitNumber', hiddenColumns)
            },
            {
                field: 'gtsUnitType',
                headerName: intl.formatMessage(messages.gtsUnitType),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.gtsUnitType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('gtsUnitType', hiddenColumns)
            },
            {
                field: 'unitType',
                headerName: intl.formatMessage(messages.unitType),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.unitType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('unitType', hiddenColumns)
            },
            {
                field: 'customerReference',
                headerName: intl.formatMessage(messages.customerReference),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={params.row?.customerReference ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('customerReference', hiddenColumns)
            },
            {
                field: 'priorityType',
                headerName: intl.formatMessage(messages.priorityType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.priorityType ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('priorityType', hiddenColumns)
            },
            {
                field: 'teu',
                headerName: intl.formatMessage(messages.teu),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.teu ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('teu', hiddenColumns)
            },
            {
                field: 'fromPlace',
                headerName: intl.formatMessage(messages.fromPlace),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.fromPlace ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('fromPlace', hiddenColumns)
            },
            {
                field: 'toPlace',
                headerName: intl.formatMessage(messages.toPlace),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink link={`/#/${UrlConstants.GTS_ORDER_UNITS}/${applyRowFn(params).id}`} value={applyRowFn(params)?.toPlace ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('toPlace', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent rows={rows || orderUnits} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default GtsOrderUnitsList;