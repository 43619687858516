import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetFactorSetHour, resetFactorSetHours } from '../../../../reducers/factorSetHoursReducer';
import { FormType } from '../../../../constants/constants';
import AddFactorSetHourForm from './factorSetHoursForm';
import { resetHoursVersions } from '../../../../reducers/factorSetHoursVersionReducer';

interface IAddFactorSetHourProps {
    classes: any;
    theme: Theme;
    match: any;
    resetFactorSetHours: any;
    resetFactorSetHour: any;
    resetHoursVersions: any;
    steps: Array<number>;
    factorSetHoursId?: number;
    factorSetVersionId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetFactorSetHours: () => dispatch(resetFactorSetHours()),
    resetFactorSetHour: () => dispatch(resetFactorSetHour()),
    resetHoursVersions: () => dispatch(resetHoursVersions())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetHourProps) => {
    return {
        factorSetHoursId: ownProps.match.params?.hoursId ? Number(ownProps.match.params?.hoursId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetHour extends Component<IAddFactorSetHourProps> {
    componentWillUnmount() {
        const { resetFactorSetHours, resetFactorSetHour, resetHoursVersions } = this.props;

        resetFactorSetHours();
        resetFactorSetHour();
        resetHoursVersions();
    }

    render() {
        const { classes, steps, factorSetHoursId, type, factorSetVersionId } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddFactorSetHourForm factorSetHoursId={factorSetHoursId} steps={steps} type={type} factorSetVersionId={factorSetVersionId} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetHour));