import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import FactorSetHoursLineOutput from '../interfaces/output/factorSetHoursLineOut';
import FactorSetHoursLineInput from '../interfaces/input/factorSetHoursLineIn';
import HoursLineFilters from '../components/factorSets/hours/line/hoursLineFilters';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class HoursLineApi {
    static fetchFactorSetHoursLineById = async(lineId: number): Promise<FactorSetHoursLineOutput> => {
        const { data } = await http.get<FactorSetHoursLineOutput>(`factorsethourslines/${lineId}`);

        return data;
    };

    static fetchFactorSetHoursLines = async(paging: Paging, filters: HoursLineFilters): Promise<Pagination<FactorSetHoursLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, 'factorsethourslines');

        const { data } = await http.get<Pagination<FactorSetHoursLineOutput>>(url);

        return data;
    };

    static createFactorSetHoursLine = async(factorSetLine: FactorSetHoursLineOutput): Promise<FactorSetHoursLineOutput> => {
        const newFactorSetHours: FactorSetHoursLineInput = {
            name: factorSetLine.name,
            fromHours: factorSetLine.fromHours,
            toHours: factorSetLine.toHours,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            monday: factorSetLine.monday,
            tuesday:	factorSetLine.tuesday,
            wednesday:	factorSetLine.wednesday,
            thursday:	factorSetLine.thursday,
            friday:	factorSetLine.friday,
            saturday:	factorSetLine.saturday,
            sunday:	factorSetLine.sunday,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            unitTypeId: factorSetLine.unitType?.id,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };

        const { data } = await http.post<FactorSetHoursLineInput, FactorSetHoursLineOutput>('factorsethourslines', newFactorSetHours);

        return data;
    }

    static updateFactorSetHoursLine = async(factorSetLine: FactorSetHoursLineOutput): Promise<FactorSetHoursLineOutput> => {
        const newLine: FactorSetHoursLineInput = {
            name: factorSetLine.name,
            fromHours: factorSetLine.fromHours,
            toHours: factorSetLine.toHours,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            monday: factorSetLine.monday,
            tuesday:	factorSetLine.tuesday,
            wednesday:	factorSetLine.wednesday,
            thursday:	factorSetLine.thursday,
            friday:	factorSetLine.friday,
            saturday:	factorSetLine.saturday,
            sunday:	factorSetLine.sunday,
            unitTypeId: factorSetLine.unitType?.id,
            factorSetVersionId: factorSetLine.factorSetVersionId,
            surchargeCurrencyId: factorSetLine.surchargeCurrency?.id
        };
        
        const { data } = await http.put<FactorSetHoursLineInput, FactorSetHoursLineOutput>(`factorsethourslines/${factorSetLine.id}`, newLine);

        return data;
    }

    static deactivateFactorSetHoursLine = async(lineId: number): Promise<FactorSetHoursLineOutput> => {
        const { data } = await http.delete<FactorSetHoursLineOutput>(`factorsethourslines/${lineId}`);

        return data;
    }
}