import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetCancellationLineOutput from '../interfaces/output/factorSetCancellationLineOut';
import CancellationFilter from '../components/factorSets/cancellation/cancellationFilters';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';

export type FactorSetCancellationState = {
    factorSetCancellation: FactorSetOut;
    factorSetCancellationtLine: FactorSetCancellationLineOutput;
    factorSetCancellations: Pagination<FactorSetOut>;
    factorSetCancellationLines: Pagination<FactorSetCancellationLineOutput>;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoadingFactorSetCancellation: boolean;
    isLoadingfactorSetCancellations: boolean;
    isLoadingFactorSetCancellationLines: boolean;
    isLoadingFactorSetVersions: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isCreatingLine: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetCancellationState = {
    factorSetCancellation: {} as FactorSetOut,
    factorSetCancellationtLine: {} as FactorSetCancellationLineOutput,
    factorSetCancellations: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    factorSetCancellationLines: { content: [] as Array<FactorSetCancellationLineOutput> } as Pagination<FactorSetCancellationLineOutput>,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoadingFactorSetCancellation: false,
    isLoadingfactorSetCancellations: false,
    isLoadingFactorSetCancellationLines: false,
    isLoadingFactorSetVersions: false,
    isUpdating: false,
    isRemoving: false,
    isDeleting: false,
    isCreating: false,
    isCreatingLine: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetCancellationSlice = createSlice({
    name: 'factorSetCancellation',

    initialState,
    reducers: {
        fetchFactorSetCancellations: (state: FactorSetCancellationState, action: PayloadAction<{paging: Paging, filters?: CancellationFilter}>) => {
            state.isLoadingfactorSetCancellations = true;
        },
        fetchFactorSetCancellationsSuccess: (state: FactorSetCancellationState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingfactorSetCancellations = false;
            state.factorSetCancellations = action.payload;
        },
        fetchFactorSetCancellationsError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingfactorSetCancellations = false;
            state.error = action.payload;
        },
        fetchFactorSetCancellationById: (state: FactorSetCancellationState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetCancellation = true;
        },
        fetchFactorSetCancellationByIdSuccess: (state: FactorSetCancellationState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetCancellation = false;
            state.factorSetCancellation = action.payload;
        },
        fetchCancellationVersions: (state: FactorSetCancellationState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchCancellationVersionsSuccess: (state: FactorSetCancellationState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchCancellationVersionsError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        fetchFactorSetCancellationByIdError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetCancellation = false;
            state.error = action.payload;
        },
        createFactorSetCancellation: (state: FactorSetCancellationState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetCancellationSuccess: (state: FactorSetCancellationState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetCancellation = action.payload;
        },
        createFactorSetCancellationError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createCancellationVersion: (state: FactorSetCancellationState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createCancellationVersionSuccess: (state: FactorSetCancellationState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetCancellation = action.payload;
        },
        createCancellationVersionError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateFactorSetCancellation: (state: FactorSetCancellationState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetCancellationSuccess: (state: FactorSetCancellationState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetCancellation = action.payload;
        },
        updateFactorSetCancellationError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetCancellation: (state: FactorSetCancellationState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetCancellationSuccess: (state: FactorSetCancellationState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleting = false;
            state.factorSetCancellation = action.payload;
        },
        deactivateFactorSetCancellationError: (state: FactorSetCancellationState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetCancellations: (state: FactorSetCancellationState) => {
            state.factorSetCancellations = initialState.factorSetCancellations;
        },
        resetFactorSetCancellation: (state: FactorSetCancellationState) => {
            state.factorSetCancellation = initialState.factorSetCancellation;
        }
    }
});

export const { fetchFactorSetCancellations, fetchFactorSetCancellationsSuccess, fetchFactorSetCancellationsError, fetchFactorSetCancellationById, fetchFactorSetCancellationByIdError,
    fetchFactorSetCancellationByIdSuccess, createFactorSetCancellation, createFactorSetCancellationError, createFactorSetCancellationSuccess, updateFactorSetCancellation,
    updateFactorSetCancellationError, updateFactorSetCancellationSuccess, deactivateFactorSetCancellation, deactivateFactorSetCancellationError, deactivateFactorSetCancellationSuccess,
    resetFactorSetCancellations, resetFactorSetCancellation, createCancellationVersion, createCancellationVersionSuccess, createCancellationVersionError } = factorSetCancellationSlice.actions;

export const isLoadingFactorSetCancellation = (state: RootState): boolean => state.factorSetCancellationReducer.isLoadingFactorSetCancellation;
export const isLoadingFactorSetCancellations = (state: RootState): boolean => state.factorSetCancellationReducer.isLoadingfactorSetCancellations;
export const isUpdatingCancellation = (state: RootState): boolean => state.factorSetCancellationReducer.isUpdating;
export const isDeletingCancellation = (state: RootState): boolean => state.factorSetCancellationReducer.isDeleting;
export const isCreatingCancellation = (state: RootState): boolean => state.factorSetCancellationReducer.isCreating;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetCancellationReducer.isCreatingVersion;
export const getFactorSetCancellations = (state: RootState): Pagination<FactorSetOut> => state.factorSetCancellationReducer.factorSetCancellations;
export const getFactorSetCancellation = (state: RootState): FactorSetOut => state.factorSetCancellationReducer.factorSetCancellation;
export const isLoadingFactorSetVersions = (state: RootState): boolean => state.factorSetCancellationReducer.isLoadingFactorSetVersions;

export default factorSetCancellationSlice.reducer;