import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import AddPriceListForm from './addPricelistForm';
import { FormType } from '../../constants/constants';
import { resetPriceList, resetPriceListState } from '../../reducers/priceListReducer';

interface IAddPriceListProps {
    classes: any;
    theme: Theme;
    match: any;
    resetPriceList: any;
    priceListId?: number;
    priceListVersionId?: number;
    resetPriceListState: any;
    steps: Array<number>;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetPriceListState: () => dispatch(resetPriceListState()),
    resetPriceList: () => dispatch(resetPriceList())
});

const mapStoreToProps = (store: RootState, ownProps: IAddPriceListProps) => {
    return {
        priceListId: ownProps.match.params?.priceListId ? Number(ownProps.match.params?.priceListId) : undefined
    };
};

class AddPriceList extends Component<IAddPriceListProps> {
    componentWillUnmount() {
        const { resetPriceList, resetPriceListState } = this.props;

        resetPriceList();
        resetPriceListState();
    }

    render() {
        const { classes, priceListId, steps, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddPriceListForm priceListId={priceListId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddPriceList));