import { http } from '../utils/http';
import CustomerCorporate from '../interfaces/output/customerCorporate';
import Pagination from '../interfaces/common/pagination';
import urlcat from 'urlcat';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class CustomerCorporateApi {
    static fetchCorporateById = async(corporateId: string): Promise<CustomerCorporate> => {
        const { data } = await http.get<CustomerCorporate>(`corporate/${corporateId}`);

        return data;
    };

    static fetchCorporates = async(paging: Paging, searchTerm?: string): Promise<Pagination<CustomerCorporate>> => {
       
        const newsort = SortFormatterUtils.sortForServer(paging.sort);
        const newPaging = {
            ...paging,
            sort: ''
        };

        let url = urlcat('', '/corporate', {
            searchTerm,
            ...newPaging
        });

        url = `${url}${newsort}`;

        const { data } = await http.get<Pagination<CustomerCorporate>>(url);

        return data;
    };

    static createCorporate = async(corporate: CustomerCorporate): Promise<CustomerCorporate> => {
        const newCorporate = {
            name: corporate.name
        };

        const { data } = await http.post<any, CustomerCorporate>('corporate', newCorporate);

        return data;
    }

    static updateCorporate = async(corporate: CustomerCorporate): Promise<CustomerCorporate> => {
        const newCorporate = {
            name: corporate.name
        };

        const { data } = await http.put<any, CustomerCorporate>(`corporate/${corporate.id}`, newCorporate);

        return data;
    }

    static deleteCustomerCorporate = async(corporateId: number): Promise<CustomerCorporate> => {
        const { data } = await http.delete<any, CustomerCorporate>(`corporate/${corporateId}`);

        return data;
    };
}
