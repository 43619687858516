import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import UserOut from '../interfaces/output/userOut';
import AuthApi from './authApi';
import localStorageUtils from '../utils/localStorageUtils';
import { IAuthToken } from '../auth/login';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import UserIn from '../interfaces/input/userIn';
import UserFilters from '../interfaces/filters/userFilters';

export default class UserApi {
    static fetchUserById = async(userId: number): Promise<UserOut> => {
        const { data } = await http.get<UserOut>(`users/${userId}`);

        if(data.roles !== undefined && data.roles !== null) {
            data.userRolesIds = data.roles?.map(({ id }) => id as number);
        }

        return data;
    };

    static fetchUserDetails = async(refresh: boolean): Promise<UserOut> => {
        if(refresh) {
            const token = localStorageUtils.getItem<IAuthToken>(localStorageUtils.LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN);
            await AuthApi.refreshToken(token?.refresh_token || '', false);
        }
        const { data } = await http.get<UserOut>('users/details');

        return data;
    };

    static fetchUsers = async(paging: Paging, filters: UserFilters): Promise<Array<UserOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/users');

        const { data } = await http.get<Array<UserOut>>(url);

        return data;
    };
    static createUser = async(user: UserOut): Promise<UserOut> => {
        const newUser: UserIn = {
            userName: user?.name,
            ssoID: user?.ssoId,
            statusId: user?.status?.id,
            sellerId: user?.seller?.id,
            roles: user?.userRolesIds
        };

        const { data } = await http.post<UserIn, UserOut>('users', newUser);

        return data;
    }

    static updateUser = async(user: UserOut): Promise<UserOut> => {
        const newUser: UserIn = {
            userName: user?.name,
            ssoID: user?.ssoId,
            statusId: user?.status?.id,
            sellerId: user?.seller?.id,
            roles: user?.userRolesIds
        };

        const { data } = await http.put<UserIn, UserOut>(`users/${user.id}`, newUser);

        return data;
    }
    static deactivateUser = async(userId: number): Promise<UserOut> => {
        const { data } = await http.delete<UserOut>(`users/${userId}`);

        return data;
    }
}