import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import WeightFilter from '../components/factorSets/weight/weightFilters';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';

export type FactorSetWeightState = {
    factorSetWeight: FactorSetOut;
    factorSetWeights: Pagination<FactorSetOut>;
    factorSetVersions: Pagination<FactorSetVersionOut>;
    isLoadingFactorSetWeight: boolean;
    isLoadingfactorSetWeights: boolean;
    isLoadingFactorSetVersions: boolean;
    isUpdating: boolean;
    isRemoving: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isCreatingLine: boolean;
    isCreatingVersion: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: FactorSetWeightState = {
    factorSetWeight: {} as FactorSetOut,
    factorSetWeights: { content: [] as Array<FactorSetOut> } as Pagination<FactorSetOut>,
    factorSetVersions: { content: [] as Array<FactorSetVersionOut> } as Pagination<FactorSetVersionOut>,
    isLoadingFactorSetWeight: false,
    isLoadingfactorSetWeights: false,
    isLoadingFactorSetVersions: false,
    isUpdating: false,
    isRemoving: false,
    isDeleting: false,
    isCreating: false,
    isCreatingLine: false,
    isCreatingVersion: false,
    error: undefined
};

export const factorSetWeightSlice = createSlice({
    name: 'factorSetWeight',

    initialState,
    reducers: {
        fetchFactorSetWeights: (state: FactorSetWeightState, action: PayloadAction<{paging: Paging, filters?: WeightFilter}>) => {
            state.isLoadingfactorSetWeights = true;
        },
        fetchFactorSetWeightsSuccess: (state: FactorSetWeightState, action: PayloadAction<Pagination<FactorSetOut>>) => {
            state.isLoadingfactorSetWeights = false;
            state.factorSetWeights = action.payload;
        },
        fetchFactorSetWeightsError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingfactorSetWeights = false;
            state.error = action.payload;
        },
        fetchFactorSetWeightById: (state: FactorSetWeightState, _action: PayloadAction<{factorSetId: number, versionId?: number }>) => {
            state.isLoadingFactorSetWeight = true;
        },
        fetchFactorSetWeightByIdSuccess: (state: FactorSetWeightState, action: PayloadAction<FactorSetOut>) => {
            state.isLoadingFactorSetWeight = false;
            state.factorSetWeight = action.payload;
        },
        fetchWeightVersions: (state: FactorSetWeightState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingFactorSetVersions = true;
        },
        fetchWeightVersionsSuccess: (state: FactorSetWeightState, action: PayloadAction<Pagination<FactorSetVersionOut>>) => {
            state.isLoadingFactorSetVersions = false;
            state.factorSetVersions = action.payload;
        },
        fetchWeightVersionsError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetVersions = false;
            state.error = action.payload;
        },
        fetchFactorSetWeightByIdError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFactorSetWeight = false;
            state.error = action.payload;
        },
        createFactorSetWeight: (state: FactorSetWeightState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreating = true;
        },
        createFactorSetWeightSuccess: (state: FactorSetWeightState, action: PayloadAction<FactorSetOut>) => {
            state.isCreating = false;
            state.factorSetWeight = action.payload;
        },
        createFactorSetWeightError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createWeightVersion: (state: FactorSetWeightState, _action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = true;
        },
        createWeightVersionSuccess: (state: FactorSetWeightState, action: PayloadAction<FactorSetOut>) => {
            state.isCreatingVersion = false;
            state.factorSetWeight = action.payload;
        },
        createWeightVersionError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingVersion = false;
            state.error = action.payload;
        },
        updateFactorSetWeight: (state: FactorSetWeightState, _action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetWeightSuccess: (state: FactorSetWeightState, action: PayloadAction<FactorSetOut>) => {
            state.isUpdating = false;
            state.factorSetWeight = action.payload;
        },
        updateFactorSetWeightError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetWeight: (state: FactorSetWeightState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetWeightSuccess: (state: FactorSetWeightState, action: PayloadAction<FactorSetOut>) => {
            state.isDeleting = false;
            state.factorSetWeight = action.payload;
        },
        deactivateFactorSetWeightError: (state: FactorSetWeightState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetWeights: (state: FactorSetWeightState) => {
            state.factorSetWeights = initialState.factorSetWeights;
        },
        resetFactorSetWeight: (state: FactorSetWeightState) => {
            state.factorSetWeight = initialState.factorSetWeight;
        }
    }
});

export const { fetchFactorSetWeights, fetchFactorSetWeightsSuccess, fetchFactorSetWeightsError, fetchFactorSetWeightById, fetchFactorSetWeightByIdError,
    fetchFactorSetWeightByIdSuccess, createFactorSetWeight, createFactorSetWeightError, createFactorSetWeightSuccess, updateFactorSetWeight,
    updateFactorSetWeightError, updateFactorSetWeightSuccess, deactivateFactorSetWeight, deactivateFactorSetWeightError, deactivateFactorSetWeightSuccess,
    resetFactorSetWeights, resetFactorSetWeight, createWeightVersion, createWeightVersionSuccess, createWeightVersionError } = factorSetWeightSlice.actions;

export const isLoadingFactorSetWeight = (state: RootState): boolean => state.factorSetWeightReducer.isLoadingFactorSetWeight;
export const isLoadingFactorSetWeights = (state: RootState): boolean => state.factorSetWeightReducer.isLoadingfactorSetWeights;
export const isUpdatingWeight = (state: RootState): boolean => state.factorSetWeightReducer.isUpdating;
export const isDeletingWeight = (state: RootState): boolean => state.factorSetWeightReducer.isDeleting;
export const isCreatingWeight = (state: RootState): boolean => state.factorSetWeightReducer.isCreating;
export const getFactorSetWeights = (state: RootState): Pagination<FactorSetOut> => state.factorSetWeightReducer.factorSetWeights;
export const getFactorSetWeight = (state: RootState): FactorSetOut => state.factorSetWeightReducer.factorSetWeight;
export const isCreatingVersion = (state: RootState): boolean => state.factorSetWeightReducer.isCreatingVersion;
export const isLoadingFactorSetVersions = (state: RootState): boolean => state.factorSetWeightReducer.isLoadingFactorSetVersions;

export default factorSetWeightSlice.reducer;