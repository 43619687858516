import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderUnitService from '../interfaces/output/orderUnitService';
import OrderUnit from '../interfaces/output/orderUnit';
import OrderUnitApi from '../api/orderUnitApi';
import { deactivateOrderUnitError, deactivateOrderUnitSuccess, fetchOrderUnitByIdError, fetchOrderUnitByIdSuccess, createUnitError, createUnitSuccess,
    fetchServiceByUnitIdError, fetchServiceByUnitIdSuccess, updateOrderUnitError, updateOrderUnitSuccess, creditOrderUnitSuccess, creditOrderUnitError } from '../reducers/orderUnitReducer';
import Paging from '../interfaces/common/paging';

function* doFetchOrderUnit(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: OrderUnit = yield call(OrderUnitApi.fetchOrderUnit, id);
        yield put(fetchOrderUnitByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrderUnitByIdError(error as HttpErrorResponse));
    }
}

function* doFetchServicesByUnitId(action: PayloadAction<{paging: Paging, unitId: number}>) {
    const { paging, unitId } = action.payload;

    try {
        const response: Pagination<OrderUnitService> = yield call(OrderUnitApi.fetchOrderUnitServices, unitId, paging );
    
        yield put(fetchServiceByUnitIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceByUnitIdError(error as HttpErrorResponse));
    }
}

function* doCreateOrderUnit(action: PayloadAction<OrderUnit>) {
    const unit = action.payload;

    try {
        const response: OrderUnit = yield call(OrderUnitApi.createUnits, unit);

        yield put(createUnitSuccess(response));
    }

    catch (error: unknown) {
        yield put(createUnitError(error as HttpErrorResponse));
    }
}

function* doUpdateOrderUnit(action: PayloadAction<OrderUnit>) {
    const orderUnit = action.payload;

    try {
        const response: OrderUnit = yield call(OrderUnitApi.updateOrderUnit, orderUnit);

        yield put(updateOrderUnitSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateOrderUnitError(error as HttpErrorResponse));
    }
}

function* doDeactivateOrderUnit(action: PayloadAction<number>) {
    const unitId = action.payload;
    
    try {
        const response: OrderUnit = yield call(OrderUnitApi.deactivateOrderUnit, unitId);

        yield put(deactivateOrderUnitSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateOrderUnitError(error as HttpErrorResponse));
    }
}

function* doCreditOrderUnit(action: PayloadAction<{id: number, createNewCredit: boolean, creditReason: string }>) {
    const { id, createNewCredit, creditReason } = action.payload;

    try {
        const response: OrderUnit = yield call(OrderUnitApi.creditOrderUnit, id, createNewCredit, creditReason);
    
        yield put(creditOrderUnitSuccess(response));
    }

    catch (error: unknown) {
        yield put(creditOrderUnitError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('orderUnit/fetchOrderUnitById', doFetchOrderUnit),
    takeLatest('orderUnit/fetchServiceByUnitId', doFetchServicesByUnitId),
    takeLatest('orderUnit/createUnit', doCreateOrderUnit),
    takeLatest('orderUnit/updateOrderUnit', doUpdateOrderUnit),
    takeLatest('orderUnit/deactivateOrderUnit', doDeactivateOrderUnit),
    takeLatest('orderUnit/creditOrderUnit', doCreditOrderUnit)
];

