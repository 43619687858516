import InvoiceLineIn from '../interfaces/input/invoiceLineIn';
import InvoiceLineOut from '../interfaces/output/invoiceLineOut';
import { http } from '../utils/http';

export default class InvoiceLineApi {
    static fetchInvoicetWeightLineById = async(lineId: number): Promise<InvoiceLineOut> => {
        const { data } = await http.get<InvoiceLineOut>(`invoiceline/${lineId}`);

        return data;
    };

    static createInvoiceLine = async(invoiceLine: InvoiceLineOut): Promise<InvoiceLineOut> => {
        const newFactorSetWeight: InvoiceLineIn = {
            invoiceId: invoiceLine.invoice?.id,
            productId: invoiceLine.product?.id,
            serviceId: invoiceLine.service?.id,
            orderId: invoiceLine.order?.id,
            orderUnitServiceProductId: invoiceLine.orderUnitServiceProduct?.id,
            agreementVersionId: invoiceLine.agreementVersion?.id,
            priceListVersionId: invoiceLine.pricelistVersion?.id,
            cancellationTypeId: invoiceLine.cancellationType?.id,
            productsDynamicsId: invoiceLine.productDynamicsId?.id,
            departureTypeId: invoiceLine.departureType?.id,
            priorityTypeId: invoiceLine.priorityType?.id,
            unitTypeId: invoiceLine.unitType?.id,
            dangerousGoodsGroupId: invoiceLine.dangerousGoodsGroup?.id,
            customerReference: invoiceLine.customerReference,
            serviceDate: invoiceLine.serviceDate,
            unitNo: invoiceLine.unitNo,
            weightKg: invoiceLine.weightKg,
            vatCodeId: invoiceLine.vatCode?.id,
            // currencyId: invoiceLine.currency?.id,
            price: invoiceLine.price
        };

        const { data } = await http.post<InvoiceLineIn, InvoiceLineOut>(`invoice/${invoiceLine.invoice?.id}/lines`, newFactorSetWeight);

        return data;
    }

    static updateInvoiceLine = async(line: InvoiceLineOut): Promise<InvoiceLineOut> => {
        const newLine: InvoiceLineIn = {
            invoiceId: line.invoice?.id,
            productId: line.product?.id,
            serviceId: line.service?.id,
            orderId: line.order?.id,
            orderUnitServiceProductId: line.orderUnitServiceProduct?.id,
            agreementVersionId: line.agreementVersion?.id,
            priceListVersionId: line.pricelistVersion?.id,
            cancellationTypeId: line.cancellationType?.id,
            productsDynamicsId: line.productDynamicsId?.id,
            departureTypeId: line.departureType?.id,
            priorityTypeId: line.priorityType?.id,
            unitTypeId: line.unitType?.id,
            dangerousGoodsGroupId: line.dangerousGoodsGroup?.id,
            customerReference: line.customerReference,
            serviceDate: line.serviceDate,
            unitNo: line.unitNo,
            weightKg: line.weightKg,
            vatCodeId: line.vatCode?.id,
            // currencyId: line.currency?.id,
            price: line.price
        };
        
        const { data } = await http.put<InvoiceLineIn, InvoiceLineOut>(`invoiceline/${line.id}`, newLine);

        return data;
    }

    static deactivateInvoiceLine = async(lineId: number): Promise<InvoiceLineOut> => {
        const { data } = await http.delete<InvoiceLineOut>(`invoiceline/${lineId}`);

        return data;
    }
}