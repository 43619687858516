import SellerApi from '../api/sellerApi';
import { fetchSellerByIdError, fetchSellerByIdSuccess, fetchSellersError, fetchSellersSuccess }
    from '../reducers/sellerReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Seller from '../interfaces/output/seller';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchSeller(action: PayloadAction<string>) {
    const sellerId = action.payload;
    try {
        const response: Seller = yield call(SellerApi.fetchSellerById, sellerId);
        yield put(fetchSellerByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSellerByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSellers(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<Seller> = yield call(SellerApi.fetchSellers, paging);
        yield put(fetchSellersSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSellersError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('seller/fetchSellerById', doFetchSeller),
    takeLatest('seller/fetchSellers', doFetchSellers)];

