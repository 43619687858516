import React, { forwardRef, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ProductList from '../../products/productList';
import { fetchProducts, getProducts, isLoadingProducts } from '../../../reducers/productReducer';
import { useDispatch, useSelector } from 'react-redux';
import ProductFilters from '../../../interfaces/output/productFilters';
import Paging from '../../../interfaces/common/paging';
import Loader from '../../common/widgets/loader';
import { isCreatingService, isUpdatingService } from '../../../reducers/serviceReducer';
import { fetchServiceProducts, getServiceProducts, isLoadingServiceProducts, isUpdatingBulk } from '../../../reducers/serviceProductReducer';
import ServiceProductFilters from '../../../interfaces/filters/serviceProductFilters';
import Service from '../../../interfaces/output/service';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import FontAwesomeIcon from '../../../utils/FontAwesomeIcons';
import ServiceProduct from '../../../interfaces/output/serviceProduct';
import { GridColumnVisibilityModel, GridRenderCellParams } from '@mui/x-data-grid';
import Pagination from '../../../interfaces/common/pagination';
import { addToPaginatedList, removeFromPaginatedList } from '../../../utils/arrayUtils';
import PageUtils from '../../../utils/pageUtils';
import WorkflowUtils from '../../../utils/workflowUtils';
import { GridSortModel, GridSortDirection, GridSortItem } from '@mui/x-data-grid';
import ProductServiceFilters from './productServiceFilters';

const messages = {
    addProduct: LanguageUtils.createMessage('Add product services'),
    existing: LanguageUtils.createMessage('Existing product services')
};

interface AddServiceStep2Props {
    service: Service;
}

export interface AddServiceStep2State {
    serviceProducts: Pagination<ServiceProduct>;
    serviceProductsToDelete: Pagination<ServiceProduct>;
}

const AddServiceStep2 = forwardRef((props: AddServiceStep2Props, ref: any): JSX.Element => {
    const { service } = props;
    const [pagingService, setPagingService] = React.useState<Paging>(PageUtils.getMinPaging());
    const [serviceProductPaging, setServiceProductPaging] = React.useState<Paging>(PageUtils.getMinPaging());
    const [serviceProducts, setServiceProducts] = React.useState<Pagination<ServiceProduct>>({ content: [] as Array<ServiceProduct> } as Pagination<ServiceProduct>);
    const [serviceProductsToDelete, setServiceProductsToDelete] = React.useState<Pagination<ServiceProduct>>({ content: [] as Array<ServiceProduct> } as Pagination<ServiceProduct>);
    const dispatch = useDispatch();
    const products = useSelector(getProducts);
    const isLoadingProductList = useSelector(isLoadingProducts);
    const propsServiceProducts = useSelector(getServiceProducts);
    const isLoadingObjects = [useSelector(isCreatingService), useSelector(isUpdatingService), useSelector(isUpdatingBulk)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const isLoadingServiceProductList = useSelector(isLoadingServiceProducts);
    const prevIsLoadingServiceProductList = WorkflowUtils.usePrevious<boolean>(isLoadingServiceProductList);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: 'name',
        sort: 'asc' as GridSortDirection
    } as GridSortItem]);
    const [sortServiceModel, setSortServiceModel] = React.useState<GridSortModel>([{
        field: 'sequenceNumber',
        sort: 'asc' as GridSortDirection
    } as GridSortItem]);
    WorkflowUtils.setHandle<AddServiceStep2State>(ref, {
        serviceProducts,
        serviceProductsToDelete
    } as AddServiceStep2State);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        basePrice: false,
        onHold: false
    });
    const [productColumnVisibilityModel, setProductColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        product_basePrice: false,
        product_onHold: false
    });
    const [filters, setFilters] = React.useState<ProductFilters>({} as ProductFilters);

    useEffect(() => {
        if(service.id) {
            dispatch(fetchProducts({
                paging: pagingService,
                filters: {
                } as ProductFilters
            }));

            const newPagination = {
                ...serviceProductPaging,
                sort: sortServiceModel
            };
            setServiceProductPaging(newPagination);
            dispatch(fetchServiceProducts({
                paging: newPagination,
                filters: { serviceId: service.id } as ServiceProductFilters
            }));
        }
    }, [service.id]);

    useEffect(() => {
        if(prevIsLoadingServiceProductList === true && !isLoadingServiceProductList) {
            setServiceProducts(propsServiceProducts);
        }
    }, [isLoadingServiceProductList]);

    const applyFilters = () => {
        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...pagingService,
            page: newPaging.page
        };

        dispatch(fetchProducts({
            paging: newServerPagination,
            filters: {
                ...filters
            } as ProductFilters
        }));

        setPagingService(newServerPagination);
    };

    const setDefaultState = () => {
        const newFilters = {
        } as ProductFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...pagingService,
            page: newPaging.page
        };
        setFilters(newFilters);
        setPagingService(newServerPagination);

        dispatch(fetchProducts({
            paging: newServerPagination,
            filters: newFilters
        }));
    };

    const onChangeFilters = (attribute: string, value: any) => {

        const newFilter = { ...filters } as ProductFilters;
        (newFilter as any)[attribute] = value;

        setFilters(newFilter);
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        <FormattedMessage {...messages.addProduct} />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ProductServiceFilters applyFilters={applyFilters} setDefaultState={setDefaultState} filters={filters} onChange={onChangeFilters} />

                </Grid>
                <Grid item xs={12}>
                    <ProductList
                        isLoading={isLoadingProductList}
                        showServiceProductAmount={true}
                        rows={products}
                        componentsProps={{
                            toolbar: {
                            }
                        }}
                        onPageChange={(nextPage: number, nextSize: number) => {
                            const newPage = {
                                ...pagingService,
                                page: nextPage,
                                size: nextSize
                            };
                            dispatch(fetchProducts({
                                paging: newPage,
                                filters: {
                                } as ProductFilters
                            }));
                            setPagingService(newPage);
                        }}
                        additionalColumns={[
                            {
                                field: 'Add',
                                width: 80,
                                sortable: false,
                                editable: false,
                                renderCell: (cellValues: GridRenderCellParams) => {
                                    return <Button color="info" variant="contained" size="small"
                                        disabled={!!serviceProducts.content.find((sp: ServiceProduct) => sp.product?.id === cellValues.row.id)}
                                        onClick={() => setServiceProducts(addToPaginatedList(serviceProducts, {
                                            product: cellValues.row,
                                            service: service
                                        } as ServiceProduct))} >
                                        <FontAwesomeIcon icon="plus" size="xs" transform="shrink-2" />
                                    </Button>;
                                }
                            }]}
                        hiddenColumns={['basePrice', 'onHold']}
                        disableLinks
                        onSortModelChange={(newModel: GridSortModel) => {

                            if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
                                const newPagination = {
                                    ...pagingService,
                                    sort: newModel
                                };

                                setPagingService(newPagination);
                                setSortModel(newModel);

                                dispatch(fetchProducts({
                                    paging: newPagination,
                                    filters: {
                                    } as ProductFilters
                                }));
                            }
                        }
                        }
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                    />
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h6">
                            <FormattedMessage {...messages.existing} />
                        </Typography>
                    </Grid>
                    <ProductList
                        prefix={'product_'}
                        isLoading={isLoadingServiceProductList}
                        showServiceProductAmount={true}
                        rows={serviceProducts}
                        applyRowFn={(cellValues: any) => cellValues.row.product}
                        onPageChange={(nextPage: number, nextSize: number) => {
                            const newPage = {
                                ...serviceProductPaging,
                                page: nextPage,
                                size: nextSize
                            };
                            dispatch(fetchServiceProducts({
                                paging: newPage,
                                filters: { serviceId: service.id } as ServiceProductFilters
                            }));
                            setServiceProductPaging(newPage);
                        }}
                        getRowId={(row) => row.product?.id}
                        additionalColumns={[
                            {
                                field: 'Add',
                                width: 80,
                                sortable: false,
                                editable: false,
                                renderCell: (cellValues: GridRenderCellParams) => {
                                    return <Button color="info" variant="contained" size="small"
                                        disabled={!!serviceProducts.content.find((sp: ServiceProduct) => sp.product?.id === cellValues.row.id)}
                                        onClick={() => {
                                            setServiceProducts(removeFromPaginatedList(serviceProducts, cellValues.row));
                                            setServiceProductsToDelete(addToPaginatedList(serviceProductsToDelete, cellValues.row));
                                        }}>
                                        <FontAwesomeIcon icon="minus" size="xs" transform="shrink-2" />
                                    </Button>;

                                }
                            },
                            {
                                field: 'sequenceNumber',
                                headerName: 'Sequence Number',
                                flex: 1,
                                align: 'left',
                                sortable: false,
                                editable: false,
                                renderCell: (params) => params.row.sequenceNumber ?? params.api.getAllRowIds().indexOf(params.id) + 1
                            }]}
                        hiddenColumns={['basePrice', 'onHold']}
                        disableLinks
                        onSortModelChange={(newModel: GridSortModel) => {

                            if(JSON.stringify(sortServiceModel) !== JSON.stringify(newModel)) {
                                const newPagination = {
                                    ...serviceProductPaging,
                                    sort: newModel
                                };

                                setServiceProductPaging(newPagination);
                                setSortServiceModel(newModel);

                                dispatch(fetchServiceProducts({
                                    paging: newPagination,
                                    filters: { serviceId: service.id } as ServiceProductFilters
                                }));
                            }
                        }
                        }
                        columnVisibilityModel={productColumnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                            setProductColumnVisibilityModel(newModel)
                        }
                    />
                </Grid>
            </Grid>
        </Loader>
    );
});

export default AddServiceStep2;