import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import SurchargeRateUnit from '../interfaces/output/surchargeRateUnit';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import Paging from '../interfaces/common/paging';

export default class SurchargeRateUnitApi {
    static fetchSurchargeRateUnitById = async(surchargeRateUnitId: string): Promise<SurchargeRateUnit> => {
        const { data } = await http.get<SurchargeRateUnit>(`surchargerateunit/${surchargeRateUnitId}`);

        return data;
    };

    static fetchSurchargeRateUnits = async(paging: Paging): Promise<Pagination<SurchargeRateUnit>> => {
        
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/surchargerateunit');

        const { data } = await http.get<Pagination<SurchargeRateUnit>>(url);

        return data;
    };
}
