import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import FactorSetCalendarApi from '../api/factorSetCalendarApi';
import { createFactorSetCalendarSuccess, deactivateFactorSetCalendarSuccess, fetchFactorSetCalendarsSuccess,
    fetchFactorSetCalendarByIdSuccess, updateFactorSetCalendarSuccess, createFactorSetCalendarError,
    deactivateFactorSetCalendarError, fetchFactorSetCalendarByIdError, fetchFactorSetCalendarsError,
    updateFactorSetCalendarError,
    createCalendarVersionSuccess,
    createCalendarVersionError }
    from '../reducers/factorSetCalendarReducer';
import CalendarFilters from '../components/factorSets/departure/departureFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';

function* doFetchFactorSetCalendar(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {
    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCalendarApi.fetchFactorSetCalendarById, factorSetId, versionId);
    
        yield put(fetchFactorSetCalendarByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetCalendarByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetCalendars(action: PayloadAction<{ paging: Paging, filters: CalendarFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetCalendarApi.fetchFactorSetCalendar, paging, filters);

        yield put(fetchFactorSetCalendarsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetCalendarsError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetCalendar(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCalendarApi.createFactorSetCalendar, factorSet);

        yield put(createFactorSetCalendarSuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetCalendarError(error as HttpErrorResponse));
    }
}

function* doCreateCalendarVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCalendarApi.createCalendarVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createCalendarVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createCalendarVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCalendar(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCalendarApi.updateFactorSetCalendar, factorSet);

        yield put(updateFactorSetCalendarSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetCalendarError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetCalendar(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetCalendarApi.deactivateFactorSetCalendar, id);

        yield put(deactivateFactorSetCalendarSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetCalendarError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetCalendar/fetchFactorSetCalendarById', doFetchFactorSetCalendar),
    takeLatest('factorSetCalendar/fetchFactorSetCalendars', doFetchFactorSetCalendars),
    takeLatest('factorSetCalendar/createFactorSetCalendar', doCreateFactorSetCalendar),
    takeLatest('factorSetCalendar/createCalendarVersion', doCreateCalendarVersion),
    takeLatest('factorSetCalendar/updateFactorSetCalendar', doUpdateFactorSetCalendar),
    takeLatest('factorSetCalendar/deactivateFactorSetCalendar', doDeactivateFactorSetCalendar)
];