import { List } from 'immutable';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import ValidationModel from '../../../../utils/validationModel';
import { ensure } from '../../../../utils/arrayUtils';
import UserOut from '../../../../interfaces/output/userOut';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    wrongFormat: LanguageUtils.createMessage('Wrong email format')
};

export default class UserStep1Validation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateEmail(email?: string, multipleEmails = false): boolean {
        return ValidationUtils.validateEmail(email, multipleEmails);
    }
    
    static validateUserForm(user: UserOut): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            ssoId,
            status
        } = user;

        const requiredFields =
            this.validateInputString(name) &&
            this.validateInputString(ssoId) &&
            this.validateInputNumber(status?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }
        const acceptedFormat = UserStep1Validation.validateEmail(ssoId);
        if(!acceptedFormat) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.wrongFormat);
        }

        return validationModel;
    }
}
