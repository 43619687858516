import React, { Component } from 'react';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import OrderUnitServiceProductSurchargeOut from '../../interfaces/output/orderUnitServiceProductSurchargeOut';
import { ObjectType } from '../../constants/constants';
import MainLayout from '../common/widgets/mainLayout';
import UrlConstants from '../../constants/UrlConstants';
import IMenuItem from '../../interfaces/common/menuItem';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import { fetchSurchargeById, getSurcharge, isLoadingSurcharge, resetSurcharge } from '../../reducers/surchargeReducer';
import { RootState } from '../../setup';
import ViewOrderUnitServiceProductSurcharges from './viewOrderUnitServiceProductSurcharge';
import { fetchOrderById, getOrder, isLoadingOrder } from '../../reducers/orderReducer';
import Order from '../../interfaces/output/order';

interface IOrderUnitServiceProductSurchargeDetailsProps {
    classes: any;
    theme: Theme;
    surcharge: OrderUnitServiceProductSurchargeOut;
    match: any;
    history: any;
    location: any;
    fetchSurchargeById: any;
    isLoadingSurcharge: boolean;
    isUpdatingSurcharge: boolean;
    isDeletingSurcharge: boolean;
    resetSurcharge: any;
    order: Order;
    isLoadingOrder: boolean;
    fetchOrderById: any;
}

interface IOrderUnitServiceProductSurchargeDetailsState {
    surcharge: OrderUnitServiceProductSurchargeOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchSurchargeById: (id: number) => dispatch(fetchSurchargeById(id)),
    fetchOrderById: (orderId: number) => dispatch(fetchOrderById(orderId)),
    resetSurcharge: () => dispatch(resetSurcharge())
});

const mapStoreToProps = (store: RootState) => {
    return {
        surcharge: getSurcharge(store),
        isLoadingSurcharge: isLoadingSurcharge(store),
        isLoadingOrder: isLoadingOrder(store),
        order: getOrder(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    subname: LanguageUtils.createMessage('ORDER_UNIT_SERVICE_PRODUCT_SURCHARGE'),
    properties: LanguageUtils.createMessage('Properties')
};

class OrderUnitServiceProductSurchargeDetails extends Component<IOrderUnitServiceProductSurchargeDetailsProps, IOrderUnitServiceProductSurchargeDetailsState> {
    constructor(props: IOrderUnitServiceProductSurchargeDetailsProps) {
        super(props);

        this.state = {
            surcharge: {} as OrderUnitServiceProductSurchargeOut,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${props.match.params.orderUnitServiceProductSurchargeId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${props.match.params.orderUnitServiceProductSurchargeId}/properties`,
                    props.location.pathname)
            }]),
            selectedRow: undefined
        };
    }

    componentDidMount() {
        const { fetchSurchargeById } = this.props;
        const { orderUnitServiceProductSurchargeId } = this.props.match.params;

        fetchSurchargeById(orderUnitServiceProductSurchargeId);
    }

    componentWillUnmount() {
        const { resetSurcharge } = this.props;

        resetSurcharge();
    }

    componentDidUpdate(prevProps: IOrderUnitServiceProductSurchargeDetailsProps) {
        const { surcharge, fetchOrderById } = this.props;
        if(surcharge !== prevProps.surcharge) {
            this.setState({ surcharge });
            
            if(surcharge.orderUnitServiceProduct?.orderUnitService?.orderUnit?.orderId) {
                fetchOrderById(surcharge.orderUnitServiceProduct?.orderUnitService.orderUnit.orderId);
            }
        }
    }

    getSelectedMenuItem = (state: IOrderUnitServiceProductSurchargeDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { orderUnitServiceProductSurchargeId } = this.props.match.params;
        
        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/${orderUnitServiceProductSurchargeId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }
        }

        return (
            []
        );
    }

    render() {
        const { classes, surcharge, isLoadingSurcharge, order, isLoadingOrder } = this.props;
        const { menuItems } = this.state;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingSurcharge || isLoadingOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.OrderUnitServiceProductSurcharge}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    // value: OrderUnitServiceProductSurcharge.product?.name,
                    icon: <FontAwesomeIcon icon="project-diagram" size="1x" /> } as IMenuItem}
                routes={
                    [{
                        name: order.orderNumber,
                        url: `${UrlConstants.ORDERS}/${order.id}`
                    },{
                        name: surcharge.orderUnitServiceProduct?.orderUnitService?.orderUnit?.unitNo,
                        url: `${UrlConstants.ORDER_UNITS}/${surcharge.orderUnitServiceProduct?.orderUnitService?.orderUnit?.id}`
                    },{
                        name: surcharge.orderUnitServiceProduct?.orderUnitService?.service?.type?.name,
                        url: `${UrlConstants.ORDER_UNIT_SERVICES}/${surcharge.orderUnitServiceProduct?.orderUnitService?.id}`
                    },{
                        name: surcharge.orderUnitServiceProduct?.product?.name,
                        url: `${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${surcharge.orderUnitServiceProduct?.id}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCT_SURCHARGES}/:orderUnitServiceProductSurchargeId/properties`}>
                            <ViewOrderUnitServiceProductSurcharges surcharge={surcharge} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrderUnitServiceProductSurchargeDetails));