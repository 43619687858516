import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AgreementVersionSpecialPriceOut from '../interfaces/output/agreementVersionSpecialPriceOut';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import Paging from '../interfaces/common/paging';
import AgreementVersionSpecialPriceFilters from '../interfaces/filters/agreementVersionSpecialPriceFilters';

export type AgreementVersionSpecialPriceState = {
    agreementVersionSpecialPrice: AgreementVersionSpecialPriceOut;
    agreementVersionSpecialPrices: Pagination<AgreementVersionSpecialPriceOut>;
    isLoading: boolean;
    isLoadingSpecialPrices: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: AgreementVersionSpecialPriceState = {
    agreementVersionSpecialPrice: {} as AgreementVersionSpecialPriceOut,
    agreementVersionSpecialPrices: { content: [] as Array<AgreementVersionSpecialPriceOut> } as Pagination<AgreementVersionSpecialPriceOut>,
    isLoading: false,
    isLoadingSpecialPrices: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const customerAgreementVersionSpecialPriceSlice = createSlice({
    name: 'agreementVersionSpecialPrice',

    initialState,
    reducers: {
        fetchAgreementVersionSpecialPriceById: (state: AgreementVersionSpecialPriceState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchAgreementVersionSpecialPriceByIdSuccess: (state: AgreementVersionSpecialPriceState, action: PayloadAction<AgreementVersionSpecialPriceOut>) => {
            state.isLoading = false;
            state.agreementVersionSpecialPrice = action.payload;
        },
        fetchAgreementVersionSpecialPriceByIdError: (state: AgreementVersionSpecialPriceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchAgreementVersionSpecialPrices: (state: AgreementVersionSpecialPriceState, _action: PayloadAction<{paging: Paging, filters: AgreementVersionSpecialPriceFilters}>) => {
            state.isLoadingSpecialPrices = true;
        },
        fetchAgreementVersionSpecialPricesSuccess: (state: AgreementVersionSpecialPriceState, action: PayloadAction<Pagination<AgreementVersionSpecialPriceOut>>) => {
            state.isLoadingSpecialPrices = false;
            state.agreementVersionSpecialPrices = action.payload;
        },
        fetchAgreementVersionSpecialPricesError: (state: AgreementVersionSpecialPriceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSpecialPrices = false;
            state.error = action.payload;
        },
        updateAgreementVersionSpecialPriceById: (state: AgreementVersionSpecialPriceState, _action: PayloadAction<AgreementVersionSpecialPriceOut>) => {
            state.isUpdating = true;
        },
        updateAgreementVersionSpecialPriceByIdSuccess: (state: AgreementVersionSpecialPriceState, action: PayloadAction<AgreementVersionSpecialPriceOut>) => {
            state.isUpdating = false;
            state.agreementVersionSpecialPrice = action.payload;
        },
        updateAgreementVersionSpecialPriceByIdError: (state: AgreementVersionSpecialPriceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        createAgreementVersionSpecialPriceById: (state: AgreementVersionSpecialPriceState, _action: PayloadAction<AgreementVersionSpecialPriceOut>) => {
            state.isCreating = true;
        },
        createAgreementVersionSpecialPriceByIdSuccess: (state: AgreementVersionSpecialPriceState, action: PayloadAction<AgreementVersionSpecialPriceOut>) => {
            state.isCreating = false;
            state.agreementVersionSpecialPrice = action.payload;
        },
        createAgreementVersionSpecialPriceByIdError: (state: AgreementVersionSpecialPriceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        deactivateAgreementVersionSpecialPriceById: (state: AgreementVersionSpecialPriceState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateAgreementVersionSpecialPriceByIdSuccess: (state: AgreementVersionSpecialPriceState, action: PayloadAction<AgreementVersionSpecialPriceOut>) => {
            state.isDeleting = false;
            state.agreementVersionSpecialPrice = action.payload;
        },
        deactivateAgreementVersionSpecialPriceByIdError: (state: AgreementVersionSpecialPriceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetAgreementVersionSpecialPrice: (state: AgreementVersionSpecialPriceState) => {
            state.agreementVersionSpecialPrices = initialState.agreementVersionSpecialPrices;
        }
    }
});

export const { fetchAgreementVersionSpecialPriceById, fetchAgreementVersionSpecialPriceByIdSuccess, fetchAgreementVersionSpecialPriceByIdError, resetAgreementVersionSpecialPrice,
    updateAgreementVersionSpecialPriceByIdSuccess, updateAgreementVersionSpecialPriceByIdError, deactivateAgreementVersionSpecialPriceById, deactivateAgreementVersionSpecialPriceByIdSuccess,
    deactivateAgreementVersionSpecialPriceByIdError, updateAgreementVersionSpecialPriceById, createAgreementVersionSpecialPriceById, createAgreementVersionSpecialPriceByIdSuccess,
    createAgreementVersionSpecialPriceByIdError, fetchAgreementVersionSpecialPrices, fetchAgreementVersionSpecialPricesSuccess, fetchAgreementVersionSpecialPricesError }
    = customerAgreementVersionSpecialPriceSlice.actions;

export const isLoadingAgreementVersionSpecialPrice = (state: RootState): boolean => state.agreementVersionSpecialPriceReducer.isLoading;
export const isLoadingAgreementVersionSpecialPrices = (state: RootState): boolean => state.agreementVersionSpecialPriceReducer.isLoadingSpecialPrices;
export const isCreatingAgreementVersionSpecialPrice = (state: RootState): boolean => state.agreementVersionSpecialPriceReducer.isCreating;
export const isUpdatingAgreementSpecialPrice = (state: RootState): boolean => state.agreementVersionSpecialPriceReducer.isUpdating;
export const isDeletingAgreementSpecialPrice = (state: RootState): boolean => state.agreementVersionSpecialPriceReducer.isDeleting;
export const getAgreementVersionSpecialPrice = (state: RootState): AgreementVersionSpecialPriceOut => state.agreementVersionSpecialPriceReducer.agreementVersionSpecialPrice;
export const getAgreementVersionSpecialPrices = (state: RootState): Pagination<AgreementVersionSpecialPriceOut> => state.agreementVersionSpecialPriceReducer.agreementVersionSpecialPrices;

export default customerAgreementVersionSpecialPriceSlice.reducer;