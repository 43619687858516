import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CancellationTypeApi from '../api/cancellationTypeApi';
import { fetchCancellationTypesError, fetchCancellationTypesSuccess } from '../reducers/cancellationTypeReducer';

function* doFetchCancellationTypes() {
    try {
        const response: Pagination<CancellationTypeApi> = yield call(CancellationTypeApi.fetchCancellationTypes);
        yield put(fetchCancellationTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCancellationTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('cancellationType/fetchCancellationTypes', doFetchCancellationTypes)
];

