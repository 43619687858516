/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Alert } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import GenericTextField from '../common/widgets/genericTextField';
//import { ensure } from '../../utils/arrayUtils';
import Loader from '../common/widgets/loader';
import WorkflowUtils from '../../utils/workflowUtils';
import { isSending, sendInvoiceEmail, isLoadingInvoice } from '../../reducers/invoiceReducer';
import ValidationUtils from '../../utils/validationUtils';

const messages = {
    emailAddress: LanguageUtils.createMessage('EMAIL_ADDRESS'),
    addExtraEmail: LanguageUtils.createMessage('EMAIL_ADDRESS'),
    send: LanguageUtils.createMessage('SEND_EMAIL'),
    cancel: LanguageUtils.createMessage('Cancel'),
    emailHelpText: LanguageUtils.createMessage('Customer email help text'),
    wrongFormat: LanguageUtils.createMessage('Wrong email format')
};

interface ExportInvoiceProps {
    open: boolean;
    setOpenModal: (open: boolean) => void;
    invoiceId: number;
    emailAddress: string | undefined;
}

export default function SendInvoiceEmail(props: ExportInvoiceProps): JSX.Element {
    const { emailAddress, setOpenModal, open, invoiceId } = props;
    const [showValidationError, setShowValidationError] = React.useState<boolean | undefined>(false);
    const dispatch = useDispatch();
    const [email, setEmail] = React.useState<string | undefined>(emailAddress);
    const propsIsSending = useSelector(isSending);
    const propsIsLoadingInvoice = useSelector(isLoadingInvoice);
    const isLoading = propsIsSending || propsIsLoadingInvoice;
    const prevIsSending = WorkflowUtils.usePrevious<boolean>(propsIsSending);

    const sendEmail = () => {
        const valid = ValidationUtils.validateEmail(email, true);
        setShowValidationError(!valid);
        dispatch(sendInvoiceEmail({
            id: invoiceId,
            emailAddress: email }));
        if(valid) {
            setEmail('');
            setOpenModal(false);
            setShowValidationError(false);
        }
    };

    useEffect(() => {
        setEmail(emailAddress);
    }, [open || emailAddress]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case 'name': {
                isValid = ValidationUtils.validateEmail(email, true);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
            <Loader isLoading={isLoading}>
                <>
                    <DialogTitle><FormattedMessage {...messages.addExtraEmail} /></DialogTitle>
                    <DialogContent>
                        <GenericTextField<string>
                            name="name"
                            label={messages.emailAddress}
                            value={email}
                            onChange={(_key: string, value: string | undefined) => setEmail(value)}
                            error={setValidationState('name')}
                            helperText={messages.emailHelpText}
                        />
                        {showValidationError &&
                            <Alert severity="error">
                                <FormattedMessage {...messages.wrongFormat} />
                            </Alert>
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenModal(false); setEmail(''); setShowValidationError(false); }}><FormattedMessage {...messages.cancel} /></Button>
                        <Button onClick={sendEmail}><FormattedMessage {...messages.send} /></Button>
                    </DialogActions>
                </>
            </Loader>

        </Dialog>
    );
}
