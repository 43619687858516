import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';
import FactorSetCancellationsApi from '../api/factorSetCancellationApi';
import { createCancellationVersionError, createCancellationVersionSuccess, createFactorSetCancellationError, createFactorSetCancellationSuccess, deactivateFactorSetCancellationError,
    deactivateFactorSetCancellationSuccess, fetchFactorSetCancellationByIdError, fetchFactorSetCancellationByIdSuccess,
    fetchFactorSetCancellationsError, fetchFactorSetCancellationsSuccess, updateFactorSetCancellationError, updateFactorSetCancellationSuccess } from '../reducers/factorSetCancellationReducer';
import CancellationFilter from '../components/factorSets/cancellation/cancellationFilters';

function* doFetchFactorSetCancellation(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {

    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCancellationsApi.fetchFactorSetCancellationById, factorSetId, versionId);
        yield put(fetchFactorSetCancellationByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetCancellationByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetCancellations(action: PayloadAction<{ paging: Paging, filters: CancellationFilter}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetCancellationsApi.fetchFactorSetCancellations, paging, filters);

        yield put(fetchFactorSetCancellationsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetCancellationsError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetCancellation(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCancellationsApi.createFactorSetCancellation, factorSet);

        yield put(createFactorSetCancellationSuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetCancellationError(error as HttpErrorResponse));
    }
}

function* doCreateCancellationVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCancellationsApi.createCancellationVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createCancellationVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createCancellationVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetCancellation(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetCancellationsApi.updateFactorSetCancellation, factorSet);

        yield put(updateFactorSetCancellationSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetCancellationError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetCancellation(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetCancellationsApi.deactivateFactorSetCancellation, id);

        yield put(deactivateFactorSetCancellationSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetCancellationError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetCancellation/fetchFactorSetCancellationById', doFetchFactorSetCancellation),
    takeLatest('factorSetCancellation/fetchFactorSetCancellations', doFetchFactorSetCancellations),
    takeLatest('factorSetCancellation/createFactorSetCancellation', doCreateFactorSetCancellation),
    takeLatest('factorSetCancellation/createCancellationVersion', doCreateCancellationVersion),
    takeLatest('factorSetCancellation/updateFactorSetCancellation', doUpdateFactorSetCancellation),
    takeLatest('factorSetCancellation/deactivateFactorSetCancellation', doDeactivateFactorSetCancellation)
];