import React from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import DetailedHistoryFilter from '../../interfaces/filters/detailedHistoryFilter';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import GenericTextField from './widgets/genericTextField';

interface IFilterProps {
    filters: DetailedHistoryFilter;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    hiddenColumns?: Array<string>;
    disableFilter?:any;
}

const messages = {
    gtsOrderNumber: LanguageUtils.createMessage('Gts order number'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    serviceDescription: LanguageUtils.createMessage('Service description'),
    salesOrderNumber: LanguageUtils.createMessage('Sales order number'),
    grossPrice: LanguageUtils.createMessage('Gross price'),
    creditedSalesOrderNumber: LanguageUtils.createMessage('Credited sales order number'),
    creditReason: LanguageUtils.createMessage('Credit reason'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function DetailedHistoryFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, disableFilter } = props;

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="gtsOrderNumber"
                                label={messages.gtsOrderNumber}
                                onChange={onChange}
                                value={filters.gtsOrderNumber}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="unitNo"
                                label={messages.unitNo}
                                value={filters.unitNo}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="salesOrderNumber"
                                label={messages.salesOrderNumber}
                                type="number"
                                value={filters.salesOrderNumber}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="grossPrice"
                                label={messages.grossPrice}
                                type="number"
                                value={filters.grossPrice}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="creditedSalesOrderNumber"
                                label={messages.creditedSalesOrderNumber}
                                value={filters.creditedSalesOrderNumber}
                                onChange={onChange}
                                disabled={disableFilter}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="creditReason"
                                label={messages.creditReason}
                                value={filters.creditReason}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );
}
