import React, { useEffect } from 'react';
import AddServiceStep1 from './add/addServiceStep1';
import AddServiceStep2, { AddServiceStep2State } from './add/addServiceStep2';
import AddServiceStep3 from './add/addServiceStep3';
import { useDispatch, useSelector } from 'react-redux';
import { createService, fetchServiceById, getService, isUpdatingService, updateService } from '../../reducers/serviceReducer';
import GenericStepper from '../common/widgets/genericStepper';
import GenericStep from '../../interfaces/common/genericStep';
import Service from '../../interfaces/output/service';
import GenericRef from '../../interfaces/common/genericRef';
import { isUpdatingBulk, updateServiceProductsBulk } from '../../reducers/serviceProductReducer';
import UrlConstants from '../../constants/UrlConstants';
import WorkflowUtils from '../../utils/workflowUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';
import ServiceStep1Validation from './add/validations/serviceStep1Validation';

interface IAddServiceFormProps {
    serviceId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit service'),
    add: LanguageUtils.createMessage('Add service'),
    changeStatus: LanguageUtils.createMessage('Change service status'),
    changeServiceProducts: LanguageUtils.createMessage('Edit service products')
};

export default function AddServiceForm(props: IAddServiceFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { serviceId, steps, type } = props;
    const [service, setService] = React.useState<Service>({} as Service);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsService = useSelector(getService);
    const propsIsUpdatingBulk = useSelector(isUpdatingBulk);
    const propsIsUpdatingService = useSelector(isUpdatingService);
    const prevIsUpdatingBulk = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingBulk);
    const prevIsUpdatingService = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingService);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if(prevIsUpdatingService === true && !propsIsUpdatingService) {
                    setRedirectCondition(true);
                }
                break;
        
            case 2:
                if(prevIsUpdatingBulk === true && !propsIsUpdatingBulk) {
                    setRedirectCondition(true);
                }
                break;
            case 3:
                if(prevIsUpdatingService === true && !propsIsUpdatingService) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingBulk, propsIsUpdatingService]);
    
    useEffect(() => {
        if(serviceId) {
            dispatch(fetchServiceById(serviceId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsService.id) {
            setService(propsService);
        }

    }, [propsIsUpdatingService, propsService.id]);

    const onChange = (attribute: string, value: any) => {
        const newService = { ...service } as Service;
        (newService as any)[attribute] = value;

        setService(newService);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddServiceStep1 onChange={onChange} service={service} />,
            validationFn: () => ServiceStep1Validation.validateServiceForm(service),
            onNext: () => {
                if(!service.id) {
                    dispatch(createService(service));
                }
                else {
                    dispatch(updateService(service));
                }
            }
        } as GenericStep,
        {
            id: 2,
            content: <AddServiceStep2 service={service} />,
            onNext: (ref: GenericRef<AddServiceStep2State>) => {
                const data = ref.current.getData();
                const { serviceProducts, serviceProductsToDelete } = data;

                dispatch(updateServiceProductsBulk({ serviceProducts,
                    serviceProductsToDelete }));
            }
        } as GenericStep,
        {
            id: 3,
            content: <AddServiceStep3 onChange={onChange} service={service} />,
            onNext: () => {
                dispatch(updateService(service));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            case FormType.ChangeStatus:
                return messages.changeStatus;
            case FormType.ChangeServiceProducts:
                return messages.changeServiceProducts;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.SERVICES}/${service.id}`}
        />
    );
}
  