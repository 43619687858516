import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import FactorSetCalendarLineOutput from '../../../../interfaces/output/factorSetCalendarLineOut';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import CalendarLineFormStep1 from './calendarLineFormStep1';
import { createCalendarVersionLine, fetchFactorSetCalendarLineById, getFactorSetCalendarLine, isCreatingCalendarLine, isUpdatingCalendarLine, updateFactorSetCalendarLine }
    from '../../../../reducers/calendarLineReducer';
import FactorSetCalendarLineStep1Validation from '../line/validations/factorSetCalendarLineStep1Validation';

interface IAddFactorSetCalendarLineFormProps {
    calendarLineId?: number;
    factorSetCalendarId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set calendar line'),
    add: LanguageUtils.createMessage('Add factor set calendar line')
};

export default function AddFactorSetCalendarLineForm(props: IAddFactorSetCalendarLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { calendarLineId, factorSetCalendarId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [calendarLine, setCalendarLine] = React.useState<FactorSetCalendarLineOutput>({ factorSetId: factorSetCalendarId,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetCalendarLineOutput);
    const propsCalendarLine = useSelector(getFactorSetCalendarLine);
    const propsIsUpdatingCalendarLine = useSelector(isUpdatingCalendarLine);
    const prevIsUpdatingCalendarLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCalendarLine);
    const propsIsCreatingCalendarLine = useSelector(isCreatingCalendarLine);
    const prevIsCreatingCalendarLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCalendarLine);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingCalendarLine === true && !propsIsUpdatingCalendarLine) || (prevIsCreatingCalendarLine === true && !propsIsCreatingCalendarLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingCalendarLine, propsIsUpdatingCalendarLine]);
    
    useEffect(() => {
        if(calendarLineId) {
            dispatch(fetchFactorSetCalendarLineById(calendarLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsCalendarLine.id) {
            setCalendarLine(propsCalendarLine);
        }

    }, [propsIsUpdatingCalendarLine, propsCalendarLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...calendarLine } as FactorSetCalendarLineOutput;
        (newLine as any)[attribute] = value;

        setCalendarLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <CalendarLineFormStep1 onChange={onChange} calendarLine={calendarLine} factorSetVersionId={factorSetVersionId} />,
            validationFn: () => FactorSetCalendarLineStep1Validation.validateFactorSetForm(calendarLine),
            onNext: () => {
                if(!calendarLine.id) {
                    dispatch(createCalendarVersionLine(calendarLine));
                }
                else {
                    dispatch(updateFactorSetCalendarLine(calendarLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddLine:
                return calendarLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/calendar/${factorSetCalendarId}/${calendarLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/calendar/${factorSetCalendarId}/latest/lines`;
            case FormType.EditLine:
                return calendarLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/calendar/${factorSetCalendarId}/${calendarLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/calendar/${factorSetCalendarId}/latest/lines`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  