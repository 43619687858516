import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import GTSOrderUnit from '../interfaces/output/gtsOrderUnit';
import GTSOrderUnitDepot from '../interfaces/output/gtsOrderUnitDepot';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type GTSOrderUnitDepotState = {
    gtsOrderUnitDepot: GTSOrderUnitDepot;
    gtsOrderUnitsDepot: Pagination<GTSOrderUnitDepot>;
    isLoadingGtsOrderUnitsDepot: boolean;
    isLoadingGtsOrderUnitDepot: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: GTSOrderUnitDepotState = {
    gtsOrderUnitDepot: {} as GTSOrderUnitDepot,
    gtsOrderUnitsDepot: { content: [] as Array<GTSOrderUnitDepot> } as Pagination<GTSOrderUnitDepot>,
    isLoadingGtsOrderUnitsDepot: false,
    isLoadingGtsOrderUnitDepot: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const gtsOrderUnitDepotLinesSlice = createSlice({
    name: 'gtsOrderUnitDepotLines',

    initialState,
    reducers: {
        fetchGtsOrderUnitsDepot: (state: GTSOrderUnitDepotState, _action: PayloadAction<{paging: Paging, filters: GtsOrderUnitFilters}>) => {
            state.isLoadingGtsOrderUnitsDepot = true;
        },
        fetchGtsOrderUnitsDepotSuccess: (state: GTSOrderUnitDepotState, action: PayloadAction<Pagination<GTSOrderUnitDepot>>) => {
            state.isLoadingGtsOrderUnitsDepot = false;
            state.gtsOrderUnitsDepot = action.payload;
        },
        fetchGtsOrderUnitsDepotError: (state: GTSOrderUnitDepotState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingGtsOrderUnitsDepot = false;
            state.error = action.payload;
        },
        fetchGtsOrderUnitDepotById: (state: GTSOrderUnitDepotState, _action: PayloadAction<number>) => {
            state.isLoadingGtsOrderUnitDepot = true;
        },
        fetchGtsOrderUnitDepotByIdSuccess: (state: GTSOrderUnitDepotState, action: PayloadAction<GTSOrderUnitDepot>) => {
            state.isLoadingGtsOrderUnitDepot = false;
            state.gtsOrderUnitDepot = action.payload;
        },
        fetchGtsOrderUnitDepotByIdError: (state: GTSOrderUnitDepotState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingGtsOrderUnitDepot = false;
            state.error = action.payload;
        },
        updateGtsOrderUnitDepot: (state: GTSOrderUnitDepotState, _action: PayloadAction<GTSOrderUnitDepot>) => {
            state.isUpdating = true;
        },
        updateGtsOrderUnitDepotSuccess: (state: GTSOrderUnitDepotState, action: PayloadAction<GTSOrderUnitDepot>) => {
            state.isUpdating = false;
            state.gtsOrderUnitDepot = action.payload;
        },
        updateGtsOrderUnitDepotError: (state: GTSOrderUnitDepotState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateGtsOrderUnitDepot: (state: GTSOrderUnitDepotState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateGtsOrderUnitDepotSuccess: (state: GTSOrderUnitDepotState, action: PayloadAction<GTSOrderUnitDepot>) => {
            state.isDeleted = false;
            state.gtsOrderUnitDepot = action.payload;
        },
        deactivateGtsOrderUnitDepotError: (state: GTSOrderUnitDepotState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetGtsOrderUnitsDepot: (state: GTSOrderUnitDepotState) => {
            state.gtsOrderUnitsDepot = initialState.gtsOrderUnitsDepot;
        },
        resetGtsOrderUnitDepot: (state: GTSOrderUnitDepotState) => {
            state.gtsOrderUnitDepot = initialState.gtsOrderUnitDepot;
        }
    }
});

export const { fetchGtsOrderUnitsDepot, fetchGtsOrderUnitsDepotSuccess, fetchGtsOrderUnitsDepotError, fetchGtsOrderUnitDepotById, fetchGtsOrderUnitDepotByIdSuccess, fetchGtsOrderUnitDepotByIdError,
    updateGtsOrderUnitDepot, updateGtsOrderUnitDepotSuccess, updateGtsOrderUnitDepotError, resetGtsOrderUnitDepot, resetGtsOrderUnitsDepot, deactivateGtsOrderUnitDepot,
    deactivateGtsOrderUnitDepotSuccess, deactivateGtsOrderUnitDepotError } = gtsOrderUnitDepotLinesSlice.actions;

export const isLoadingGtsOrderUnitsDepot = (state: RootState): boolean => state.gtsOrderUnitDepotReducer.isLoadingGtsOrderUnitsDepot;
export const isLoadingGtsOrderUnitDepot = (state: RootState): boolean => state.gtsOrderUnitDepotReducer.isLoadingGtsOrderUnitDepot;
export const getGtsOrderUnitsDepot = (state: RootState): Pagination<GTSOrderUnitDepot> => state.gtsOrderUnitDepotReducer.gtsOrderUnitsDepot;
export const getGtsOrderUnitDepot = (state: RootState): GTSOrderUnitDepot => state.gtsOrderUnitDepotReducer.gtsOrderUnitDepot;
export const isUpdatingGtsOrderUnitDepot = (state: RootState): boolean => state.gtsOrderUnitDepotReducer.isUpdating;
export const isDeletingGtsOrderUnitDepot = (state: RootState): boolean => state.gtsOrderUnitReducer.isDeleted;

export default gtsOrderUnitDepotLinesSlice.reducer;