import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import GTSOrderUnit from '../../interfaces/output/gtsOrderUnit';
import { FormType } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGtsOrderUnitById, getGtsOrderUnit, isUpdatingGtsOrderUnit, updateGtsOrderUnit } from '../../reducers/gtsOrderUnitReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericStep from '../../interfaces/common/genericStep';
import UrlConstants from '../../constants/UrlConstants';
import GenericStepper from '../common/widgets/genericStepper';
import AddGtsOrderUnitStep1 from './gtsOrderUnitStep1';
import AddGtsOrderUnitStep2 from './gtsOrderUnitStep2';

interface IEditOrderUnitFormProps {
    gtsOrderUnitId?: number;
    gtsOrderId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    editUnit: LanguageUtils.createMessage('Edit GTS order unit'),
    addUnit: LanguageUtils.createMessage('Add GTS order unit')
};

export default function GtsOrderUnitForm(props: IEditOrderUnitFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { gtsOrderUnitId, steps, type, gtsOrderId } = props;
    const [gtsOrderUnit, setGtsOrderUnit] = React.useState<GTSOrderUnit>({} as GTSOrderUnit);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsOrderUnit = useSelector(getGtsOrderUnit);
    const propsIsUpdatingOrderUnit = useSelector(isUpdatingGtsOrderUnit);
    const prevIsUpdatingOrderUnit = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrderUnit);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 2:
                if(prevIsUpdatingOrderUnit === true && !propsIsUpdatingOrderUnit) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingOrderUnit]);

    useEffect(() => {
        if(gtsOrderUnitId) {
            dispatch(fetchGtsOrderUnitById(gtsOrderUnitId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(propsOrderUnit.id) {
            setGtsOrderUnit(propsOrderUnit);
        }

    }, [propsOrderUnit.id]);

    const onChangeUnit = (attribute: string, value: any) => {

        const newOrderUnit = { ...gtsOrderUnit } as GTSOrderUnit;
        (newOrderUnit as any)[attribute] = value;

        setGtsOrderUnit(newOrderUnit);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddGtsOrderUnitStep1 onChange={onChangeUnit} gtsOrderUnit={gtsOrderUnit} />
        } as GenericStep,
        {
            id: 2,
            content: <AddGtsOrderUnitStep2 gtsOrderUnit={gtsOrderUnit} onChange={onChangeUnit} />,
            onNext: () => {
                dispatch(updateGtsOrderUnit(gtsOrderUnit));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddUnit:
                return messages.addUnit;
            case FormType.EditUnit:
                return messages.editUnit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddUnit:
                return `/${UrlConstants.GTS_ORDERS}/${gtsOrderId}/orderUnits`;
            case FormType.EditUnit:
                return `/${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitId}/properties`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  