import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import Product from '../../../interfaces/output/product';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnitGroups, getUnitGroups, isLoadingUnitGroups } from '../../../reducers/unitGroupReducer';
import { fetchTerminals, getTerminals, isLoadingTerminal } from '../../../reducers/terminalReducer';
import { fetchProductTypes, getProductTypes, isLoadingProductTypes } from '../../../reducers/productTypesReducer';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import { isCreatingProduct, isLoadingProduct, isUpdatingProduct } from '../../../reducers/productReducer';
import { fetchProductArenas, getProductArenas } from '../../../reducers/productArenaReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import UnitGroup from '../../../interfaces/output/unitGroup';
import ProductArena from '../../../interfaces/output/productArena';
import Terminal from '../../../interfaces/output/terminal';
import ProductType from '../../../interfaces/output/productType';
import GenericTextField from '../../common/widgets/genericTextField';
import ProductStep1Validation from '../validations/ProductStep1Validation';

interface AddProductStep1Props {
    product: Product;
    onChange: any;
    showValidationError?: boolean
}

const messages = {
    unitGroup: LanguageUtils.createMessage('Unit group'),
    productArena: LanguageUtils.createMessage('Product arena'),
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to'),
    productType: LanguageUtils.createMessage('Product type'),
    name: LanguageUtils.createMessage('Name product'),
    nameNo: LanguageUtils.createMessage('Name no')
};

const ATTRIBUTES = {
    NAME: 'name_ENG'
};

const AddProductStep1 = forwardRef((props: AddProductStep1Props, ref: any): JSX.Element => {
    const { product, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const terminals = useSelector(getTerminals).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const productArenas = useSelector(getProductArenas).content;
    const productTypes = useSelector(getProductTypes).content;
    const isLoadingObjects = [useSelector(isCreatingProduct), useSelector(isLoadingProduct), useSelector(isLoadingUnitGroups), useSelector(isLoadingTerminal),
        useSelector(isLoadingProductTypes), useSelector(isUpdatingProduct)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchProductArenas({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchProductTypes({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = ProductStep1Validation.validateInputStringLength(product.name_ENG);
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name_ENG"
                        label={messages.name}
                        value={product.name_ENG}
                        onChange={onChange}
                        required
                        error={setValidationState(ATTRIBUTES.NAME)}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name_NO"
                        label={messages.nameNo}
                        value={product.name_NO}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={product.unitGroup}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroup', obj)}
                            placeholder={messages.unitGroup}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ProductArena>
                            options={productArenas}
                            value={product.productArena}
                            onChange={(obj: ProductArena | null) => onChange('productArena', obj)}
                            placeholder={messages.productArena}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={product.fromTerminal}
                            onChange={(obj: Terminal | null) => onChange('fromTerminal', obj)}
                            placeholder={messages.terminalFrom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={product.toTerminal}
                            onChange={(obj: Terminal | null) => onChange('toTerminal', obj)}
                            placeholder={messages.terminalTo}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ProductType>
                            options={productTypes}
                            value={product.productType}
                            onChange={(obj: ProductType | null) => onChange('productType', obj)}
                            placeholder={messages.productType}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddProductStep1;