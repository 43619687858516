import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStepper from '../../common/widgets/genericStepper';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import InvoiceLineOut from '../../../interfaces/output/invoiceLineOut';
import { fetchInvoiceLineById, getInvoiceLine, isUpdatingInvoiceLine, updateInvoiceLine, createInvoiceLine, isCreatingInvoiceLine, isLoadingInvoiceLine } from '../../../reducers/invoiceLineReducer';
import InvoiceLineFormStep1 from './invoiceLineFormStep1';

interface IInvoiceLineFormProps {
    invoiceId?: number;
    invoiceLineId?: number;
    productId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit invoice line'),
    add: LanguageUtils.createMessage('Add invoice line')
};

export default function InvoiceLineForm(props: IInvoiceLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { invoiceId, steps, type, invoiceLineId } = props;
    const [invoiceLine, setInvoiceLine] = React.useState<InvoiceLineOut>({ invoice: { id: invoiceId } } as InvoiceLineOut);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsInvoiceLine = useSelector(getInvoiceLine);
    const propsIsUpdatingInvoiceLine = useSelector(isUpdatingInvoiceLine);
    const prevIsUpdatingInvoiceLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingInvoiceLine);
    const propsIsLoadingInvoiceLine = useSelector(isLoadingInvoiceLine);
    const prevIsLoadingInvoiceLine = WorkflowUtils.usePrevious<boolean>(propsIsLoadingInvoiceLine);
    const propsIsCreatingInvoiceLine = useSelector(isCreatingInvoiceLine);
    const prevIsCreatingnvoiceLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingInvoiceLine);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingInvoiceLine === true && !propsIsUpdatingInvoiceLine) || (prevIsCreatingnvoiceLine && !propsIsCreatingInvoiceLine)) {
                    setRedirectCondition(true);
                }
                break;
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingInvoiceLine, propsIsUpdatingInvoiceLine]);
    
    useEffect(() => {
        if(invoiceLineId) {
            dispatch(fetchInvoiceLineById(invoiceLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(prevIsLoadingInvoiceLine && !propsIsLoadingInvoiceLine) {
            setInvoiceLine(propsInvoiceLine);
        }

    }, [propsIsLoadingInvoiceLine]);

    const onChange = (attribute: string, value: any) => {
        const newInvoiceLine = { ...invoiceLine } as InvoiceLineOut;
        (newInvoiceLine as any)[attribute] = value;

        setInvoiceLine(newInvoiceLine);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <InvoiceLineFormStep1 onChange={onChange} invoiceLine={invoiceLine} />,
            onNext: () => {
                if(!invoiceLine.id) {
                    dispatch(createInvoiceLine(invoiceLine));
                }
                else {
                    dispatch(updateInvoiceLine(invoiceLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));
    
    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={type === FormType.EditLine ? `/${UrlConstants.INVOICE_LINES}/${propsInvoiceLine.id}` : `/${UrlConstants.INVOICES}/${invoiceId}/lines`}
        />
    );
}
  