import { fetchStatusesError } from '../reducers/statusReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import UserStatusOut from '../interfaces/output/userStatusOut';
import UserStatusApi from '../api/userStatusApi';
import { fetchUserStatusByIdError, fetchUserStatusByIdSuccess, fetchUserStatusesSuccess } from '../reducers/userStatusReducer';

function* doFetchUserStatus(action: PayloadAction<string>) {
    const id = action.payload;
    try {
        const response: UserStatusOut = yield call(UserStatusApi.fetchUserStatusById, id);
        yield put(fetchUserStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchUserStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchUserStatuses() {
    try {
        const response: Pagination<UserStatusOut> = yield call(UserStatusApi.fetchUserStatuses);
        yield put(fetchUserStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchStatusesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('userStatus/fetchUserStatusById', doFetchUserStatus),
    takeLatest('userStatus/fetchUserStatuses', doFetchUserStatuses)];

