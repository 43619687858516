import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import InvoiceStatusOut from '../interfaces/output/invoiceStatusOut';

export type InvoiceStatusState = {
    invoiceStatus: InvoiceStatusOut;
    isLoading: boolean;
    invoiceStatuses: Pagination<InvoiceStatusOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: InvoiceStatusState = {
    invoiceStatus: {} as InvoiceStatusOut,
    invoiceStatuses: { content: [] as Array<InvoiceStatusOut> } as Pagination<InvoiceStatusOut>,
    isLoading: false,
    error: undefined
};

export const invoiceStatusSlice = createSlice({
    name: 'invoiceStatus',

    initialState,
    reducers: {
        fetchInvoiceStatuses: (state: InvoiceStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchInvoiceStatusesSuccess: (state: InvoiceStatusState, action: PayloadAction<Pagination<InvoiceStatusOut>>) => {
            state.isLoading = false;
            state.invoiceStatuses = action.payload;
        },
        fetchInvoiceStatusesError: (state: InvoiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchInvoiceStatusById: (state: InvoiceStatusState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchInvoiceStatusByIdSuccess: (state: InvoiceStatusState, action: PayloadAction<InvoiceStatusOut>) => {
            state.isLoading = false;
            state.invoiceStatus = action.payload;
        },
        fetchInvoiceStatusByIdError: (state: InvoiceStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchInvoiceStatuses, fetchInvoiceStatusesSuccess, fetchInvoiceStatusesError, fetchInvoiceStatusById, fetchInvoiceStatusByIdSuccess, fetchInvoiceStatusByIdError }
= invoiceStatusSlice.actions;

export const isLoadingInvoiceStatuses = (state: RootState): boolean => state.invoiceStatusReducer.isLoading;
export const getInvoiceStatuses = (state: RootState): Pagination<InvoiceStatusOut> => state.invoiceStatusReducer.invoiceStatuses;
export const getInvoiceStatus = (state: RootState): InvoiceStatusOut => state.invoiceStatusReducer.invoiceStatus;

export default invoiceStatusSlice.reducer;