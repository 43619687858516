import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import { fetchAgreements, getAgreements, isCreating, isLoadingAgreements, isUpdatingAgreement } from '../../../reducers/agreementsReducer';
import Paging from '../../../interfaces/common/paging';
import AgreementList from '../agreementList';
import AgreementFilters from '../../../interfaces/filters/agreementFilters';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Agreement from '../../../interfaces/output/agreement';
import Pagination from '../../../interfaces/common/pagination';
import WorkflowUtils from '../../../utils/workflowUtils';
import AgreementFiltersComponent from '../../common/agreementFilters';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddAgreementVersionStep2Props {
    agreementId?: number;
    onChange: (x: number | undefined) => void;
}

const AddAgreementVersionStep2 = forwardRef((props: AddAgreementVersionStep2Props, ref: any): JSX.Element => {
    const { agreementId, onChange } = props;
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const [filters, setFilters] = React.useState<AgreementFilters>({
        name: '',
        active: true
    });
    const [agreements, setAgreements] = React.useState<Pagination<Agreement>>({ content: [] as Array<Agreement> } as Pagination<Agreement>);
    const propsLoadingAgreements = useSelector(isLoadingAgreements);
    const prevIsLoadingAgreements = WorkflowUtils.usePrevious<boolean>(propsLoadingAgreements);
    const isLoadingObjects = [useSelector(isCreating), useSelector(isUpdatingAgreement)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const dispatch = useDispatch();
    const propsAgreements = useSelector(getAgreements);
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: 'name',
        sort: 'asc' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        revenueThisYear: false,
        revenueLastYear: false,
        versions_defaultTransitDiscount: false,
        versions_validFromDate: false,
        versions_validToDate: false,
        validity: false,
        priorityRank: false
    });

    useEffect(() => {
        dispatch(fetchAgreements({
            paging: paging,
            filters: {
                name: '',
                active: true
            } as AgreementFilters
        }));
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingAgreements === true && !propsLoadingAgreements) {
            setAgreements(propsAgreements);
        }
    }, [propsLoadingAgreements]);

    const applyFilters = () => {
        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };

        dispatch(fetchAgreements({
            paging: newServerPagination,
            filters: {
                ...filters,
                name: searchTerm
            }
        }));

        setPaging(newServerPagination);
    };

    const setDefaultState = () => {
        const newFilters = {
            name: '',
            active: true
        } as AgreementFilters;
        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };
        setFilters(newFilters);
        setPaging(newServerPagination);
        setSearchTerm('');

        dispatch(fetchAgreements({
            paging: newServerPagination,
            filters: newFilters
        }));
    };

    const onChangeFilters = (attribute: string, value: any) => {

        const newFilter = { ...filters } as AgreementFilters;
        (newFilter as any)[attribute] = value;

        setFilters(newFilter);
    };

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchAgreements({
                paging: newServerPagination,
                filters: {
                    ...filters,
                    name: searchTerm
                }
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={1} mt={2}>
                <Grid item xs={12}>
                    <QuickSearchToolbar searchTerm={searchTerm} includeFilters={true} onSearch={(searchTerm: string) => {
                        setSearchTerm(searchTerm);
                        const newFilter = { ...filters } as AgreementFilters;
                        (newFilter as any)['name'] = searchTerm;

                        const newPaging = PageUtils.getDefaultPaging();
                        const newServerPagination = {
                            ...paging,
                            page: newPaging.page
                        };

                        dispatch(fetchAgreements({
                            paging: newServerPagination,
                            filters: newFilter
                        }));

                        setFilters(newFilter);
                        setPaging(newServerPagination);
                    }}
                    >
                        <AgreementFiltersComponent applyFilters={applyFilters} setDefaultState={setDefaultState} filters={filters} onChange={onChangeFilters} />
                    </QuickSearchToolbar>
                </Grid>
                <Grid item xs={12}>
                    <AgreementList hiddenColumns={['revenueThisYear', 'revenueLastYear', 'discount', 'validFromDate', 'validToDate', 'relation', 'priorityRank']}
                        isLoading={propsLoadingAgreements}
                        rows={agreements}
                        componentsProps={{
                            toolbar: {
                                value: searchTerm,
                                onChange: (event: any) => setSearchTerm(event.target.value),
                                clearSearch: () => setSearchTerm('')
                            }
                        }}
                        checkSelection={(value: number | undefined) => onChange(value)}
                        onPageChange={(nextPage: number, nextSize: number) => {
                            const newPage = {
                                ...paging,
                                page: nextPage,
                                size: nextSize
                            };
                            dispatch(fetchAgreements({
                                paging: newPage,
                                filters: {
                                    ...filters,
                                    name: searchTerm
                                } as AgreementFilters
                            }));
                            setPaging(newPage);
                        }}
                        defaultSelectionModel={agreementId ? [agreementId] : undefined}
                        disableLinks
                        onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                    />
                </Grid>
            </Grid>
        </Loader>
    );
});

export default AddAgreementVersionStep2;