import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import VatCode from '../interfaces/output/vatCode';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class VatCodeApi {
    static fetchVatCodeById = async(vatId: string): Promise<VatCode> => {
        const { data } = await http.get<VatCode>(`vatcode/${vatId}`);

        return data;
    };

    static fetchVatCodes = async(paging: Paging): Promise<Pagination<VatCode>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/vatcode');

        const { data } = await http.get<Pagination<VatCode>>(url);

        return data;
    };
}
