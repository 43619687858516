import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import PricelistUnitTypeOut from '../interfaces/output/pricelistUnitType';

export type PriceListUnitTypeState = {
    pricelistUnitType: PricelistUnitTypeOut;
    isLoading: boolean;
    isLoadingTypes: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    pricelistUnitTypes: Pagination<PricelistUnitTypeOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: PriceListUnitTypeState = {
    pricelistUnitType: {} as PricelistUnitTypeOut,
    pricelistUnitTypes: { content: [] as Array<PricelistUnitTypeOut> } as Pagination<PricelistUnitTypeOut>,
    isLoading: false,
    isLoadingTypes: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    error: undefined
};

export const pricelistUnitTypeSlice = createSlice({
    name: 'pricelistunittype',

    initialState,
    reducers: {
        fetchPriceListUnitTypes: (state: PriceListUnitTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingTypes = true;
        },
        fetchPriceListUnitTypesSuccess: (state: PriceListUnitTypeState, action: PayloadAction<Pagination<PricelistUnitTypeOut>>) => {
            state.isLoadingTypes = false;
            state.pricelistUnitTypes = action.payload;
        },
        fetchPriceListUnitTypesError: (state: PriceListUnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingTypes = false;
            state.error = action.payload;
        },
        fetchPriceListUnitTypeById: (state: PriceListUnitTypeState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchPriceListUnitTypeByIdSuccess: (state: PriceListUnitTypeState, action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isLoading = false;
            state.pricelistUnitType = action.payload;
        },
        fetchPriceListUnitTypeByIdError: (state: PriceListUnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createPriceListUnitType: (state: PriceListUnitTypeState, _action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isCreating = true;
        },
        createPriceListUnitTypeSuccess: (state: PriceListUnitTypeState, action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isCreating = false;
            state.pricelistUnitType = action.payload;
        },
        createPriceListUnitTypeError: (state: PriceListUnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updatePriceListUnitType: (state: PriceListUnitTypeState, _action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isUpdating = true;
        },
        updatePriceListUnitTypeSuccess: (state: PriceListUnitTypeState, action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isUpdating = false;
            state.pricelistUnitType = action.payload;
        },
        updatePriceListUnitTypeError: (state: PriceListUnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivatePriceListUnitType: (state: PriceListUnitTypeState, _action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isDeleting = true;
        },
        deactivatePriceListUnitTypeSuccess: (state: PriceListUnitTypeState, action: PayloadAction<PricelistUnitTypeOut>) => {
            state.isDeleting = false;
            state.pricelistUnitType = action.payload;
        },
        deactivatePriceListUnitTypeError: (state: PriceListUnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetPriceListUnitTypes: (state: PriceListUnitTypeState) => {
            state.pricelistUnitTypes = initialState.pricelistUnitTypes;
        },
        resetPriceListUnitType: (state: PriceListUnitTypeState) => {
            state.pricelistUnitType = initialState.pricelistUnitType;
        }
    }
});

export const { fetchPriceListUnitTypes, fetchPriceListUnitTypesSuccess, fetchPriceListUnitTypesError, fetchPriceListUnitTypeById, fetchPriceListUnitTypeByIdSuccess,
    fetchPriceListUnitTypeByIdError, createPriceListUnitType, createPriceListUnitTypeSuccess, createPriceListUnitTypeError, updatePriceListUnitType, updatePriceListUnitTypeError,
    updatePriceListUnitTypeSuccess, deactivatePriceListUnitType, deactivatePriceListUnitTypeError, deactivatePriceListUnitTypeSuccess, resetPriceListUnitType, resetPriceListUnitTypes
} = pricelistUnitTypeSlice.actions;

export const isLoadingPriceListUnitType = (state: RootState): boolean => state.pricelistUnitTypeReducer.isLoading;
export const isLoadingPriceListUnitTypes = (state: RootState): boolean => state.pricelistUnitTypeReducer.isLoadingTypes;
export const getPriceListUnitTypes = (state: RootState): Pagination<PricelistUnitTypeOut> => state.pricelistUnitTypeReducer.pricelistUnitTypes;
export const getPriceListUnitType = (state: RootState): PricelistUnitTypeOut => state.pricelistUnitTypeReducer.pricelistUnitType;
export const isUpdatingPriceListUnitType = (state: RootState): boolean => state.pricelistUnitTypeReducer.isUpdating;
export const isDeletingPriceListUnitType = (state: RootState): boolean => state.pricelistUnitTypeReducer.isDeleting;
export const isCreatingPriceListUnitType = (state: RootState): boolean => state.pricelistUnitTypeReducer.isCreating;

export default pricelistUnitTypeSlice.reducer;