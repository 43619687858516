import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import FactorSetPriorityApi from '../api/factorSetPriorityApi';
import { createFactorSetPriorityError, createFactorSetPrioritySuccess, createPriorityVersionError, createPriorityVersionSuccess, deactivateFactorSetPriorityError,
    deactivateFactorSetPrioritySuccess, fetchFactorSetPrioritiesError, fetchFactorSetPrioritiesSuccess,
    fetchFactorSetPriorityByIdError, fetchFactorSetPriorityByIdSuccess, updateFactorSetPriorityError, updateFactorSetPrioritySuccess } from '../reducers/factorSetPriorityReducer';
import PriorityFilters from '../components/factorSets/priority/priorityFilter';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetOut from '../interfaces/output/factorSetOut';

function* doFetchFactorSetPriority(action: PayloadAction<{ factorSetId: number, versionId?: number }>) {
    const { factorSetId, versionId } = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetPriorityApi.fetchFactorSetPriorityById, factorSetId, versionId);
    
        yield put(fetchFactorSetPriorityByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetPriorityByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetPriorities(action: PayloadAction<{ paging: Paging, filters: PriorityFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<FactorSetOut> = yield call(FactorSetPriorityApi.fetchFactorSetPriorities, paging, filters);

        yield put(fetchFactorSetPrioritiesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetPrioritiesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetPriority(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetPriorityApi.createFactorSetPriority, factorSet);

        yield put(createFactorSetPrioritySuccess(response));
    }

    catch (error: unknown) {
        yield put(createFactorSetPriorityError(error as HttpErrorResponse));
    }
}

function* doCreatePriorityVersion(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetPriorityApi.createPriorityVersion, factorSet);
        const newFactorSet = { ...factorSet,
            version: response } as FactorSetOut;

        yield put(createPriorityVersionSuccess(newFactorSet));
    }

    catch (error: unknown) {
        yield put(createPriorityVersionError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetPriority(action: PayloadAction<FactorSetOut>) {
    const factorSet = action.payload;

    try {
        const response: FactorSetOut = yield call(FactorSetPriorityApi.updateFactorSetPriority, factorSet);

        yield put(updateFactorSetPrioritySuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetPriorityError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetPriority(action: PayloadAction<number>) {
    const id = action.payload;
    
    try {
        const response: FactorSetOut = yield call(FactorSetPriorityApi.deactivateFactorSetPriority, id);

        yield put(deactivateFactorSetPrioritySuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetPriorityError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetPriority/fetchFactorSetPriorityById', doFetchFactorSetPriority),
    takeLatest('factorSetPriority/fetchFactorSetPriorities', doFetchFactorSetPriorities),
    takeLatest('factorSetPriority/createFactorSetPriority', doCreateFactorSetPriority),
    takeLatest('factorSetPriority/createPriorityVersion', doCreatePriorityVersion),
    takeLatest('factorSetPriority/updateFactorSetPriority', doUpdateFactorSetPriority),
    takeLatest('factorSetPriority/deactivateFactorSetPriority', doDeactivateFactorSetPriority)
];