import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import {
    fetchOrderUnitServiceProductById, fetchSurchargesByOrderUnitServiceProductId, getOrderUnitServiceProduct, getSurcharges, isLoadingUnitServiceProduct,
    isLoadingUnitServiceProductSurcharges
} from '../../../reducers/orderUnitServiceProductReducer';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import { ObjectType } from '../../../constants/constants';
import ViewOrderUnitServiceProduct from './viewOrderUnitServiceProduct';
import LanguageUtils from '../../../utils/LanguageUtils';
import SurchargesList from '../../surcharges/surchargesList';
import Pagination from '../../../interfaces/common/pagination';
import OrderUnitServiceProductSurchargeOut from '../../../interfaces/output/orderUnitServiceProductSurchargeOut';
import Paging from '../../../interfaces/common/paging';
import Order from '../../../interfaces/output/order';
import { fetchOrderById, getOrder, isLoadingOrder } from '../../../reducers/orderReducer';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IOrderUnitServiceProductDetailsProps {
    classes: any;
    theme: Theme;
    orderUnitServiceProduct: OrderUnitServiceProduct;
    surcharges: Pagination<OrderUnitServiceProductSurchargeOut>;
    match: any;
    history: any;
    location: any;
    fetchSurchargesByOrderUnitServiceProductId: any;
    fetchOrderUnitServiceProductById: any;
    isLoadingUnitServiceProduct: boolean;
    isUpdatingOrderUnitServiceProduct: boolean;
    isDeletingUnitServiceProduct: boolean;
    isLoadingUnitServiceProductSurcharges: boolean;
    order: Order;
    fetchOrderById: any;
    isLoadingOrder: boolean;
}

interface IOrderUnitServiceProductDetailsState {
    orderUnitServiceProduct: OrderUnitServiceProduct;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchOrderUnitServiceProductById: (id: number) => dispatch(fetchOrderUnitServiceProductById(id)),
    fetchOrderById: (orderId: number) => dispatch(fetchOrderById(orderId)),
    fetchSurchargesByOrderUnitServiceProductId: (unitServiceProductId: number, paging: Paging) => dispatch(fetchSurchargesByOrderUnitServiceProductId({
        id: unitServiceProductId,
        paging
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        order: getOrder(store),
        orderUnitServiceProduct: getOrderUnitServiceProduct(store),
        isLoadingUnitServiceProduct: isLoadingUnitServiceProduct(store),
        isLoadingUnitServiceProductSurcharges: isLoadingUnitServiceProductSurcharges(store),
        isLoadingOrder: isLoadingOrder(store),
        surcharges: getSurcharges(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    subname: LanguageUtils.createMessage('ORDER_UNIT_SERVICE_PRODUCT'),
    properties: LanguageUtils.createMessage('Properties'),
    surcharges: LanguageUtils.createMessage('SURCHARGES_SHORT'),
    changeOverriddentPrice: LanguageUtils.createMessage('Change overridden price')
};

class OrderUnitServiceProductDetails extends Component<IOrderUnitServiceProductDetailsProps, IOrderUnitServiceProductDetailsState> {
    constructor(props: IOrderUnitServiceProductDetailsProps) {
        super(props);

        this.state = {
            orderUnitServiceProduct: {} as OrderUnitServiceProduct,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${props.match.params.orderUnitServiceProductId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${props.match.params.orderUnitServiceProductId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.surcharges,
                icon: <FontAwesomeIcon icon="file-invoice-dollar" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${props.match.params.orderUnitServiceProductId}/surcharges`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${props.match.params.orderUnitServiceProductId}/surcharges`, props.location.pathname)
            }]),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }
    getSelectedMenuItem = (state: IOrderUnitServiceProductDetailsState) => state.menuItems.find(item => item.isSelected);

    componentDidMount() {
        const { fetchOrderUnitServiceProductById } = this.props;
        const { orderUnitServiceProductId } = this.props.match.params;

        fetchOrderUnitServiceProductById(orderUnitServiceProductId);
    }

    componentDidUpdate(prevProps: IOrderUnitServiceProductDetailsProps) {
        const { orderUnitServiceProduct, isLoadingUnitServiceProduct, fetchOrderById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(orderUnitServiceProduct !== prevProps.orderUnitServiceProduct) {
            this.setState({ orderUnitServiceProduct });
            if(orderUnitServiceProduct.orderUnitService?.orderUnit?.orderId) {
                fetchOrderById(orderUnitServiceProduct.orderUnitService.orderUnit.orderId);
            }
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(selectedMenuItem && isLoadingUnitServiceProduct === false && prevProps.isLoadingUnitServiceProduct === true) {
            this.loadData(selectedMenuItem);
        }
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchSurchargesByOrderUnitServiceProductId } = this.props;
        const { orderUnitServiceProductId } = this.props.match.params;
        const paging = PageUtils.getDefaultPaging();

        if(selectedMenuItem?.id === 2) {
            fetchSurchargesByOrderUnitServiceProductId(orderUnitServiceProductId, paging);
        }
    }

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { orderUnitServiceProductId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}/status`,
                        icon: 'retweet',
                        text: messages.changeStatus
                    },
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}/overriddenPrice`,
                        icon: 'tag',
                        text: messages.changeOverriddentPrice
                    }
                ];
            }

            case 2: { //surcharges
                return [
                    {
                        href: `${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/${orderUnitServiceProductId}/surcharge/add`,
                        icon: 'plus',
                        text: messages.add
                    }
                ];
            }
        }

        return (
            []
        );
    }

    _onSortModelChange = (newModel: GridSortModel) => {
        const { serverPagination, sortModel } = this.state;
        const { orderUnitServiceProductId } = this.props.match.params;
        const { fetchSurchargesByOrderUnitServiceProductId } = this.props;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModel: newModel,
                serverPagination: newPagination
            });

            fetchSurchargesByOrderUnitServiceProductId(orderUnitServiceProductId,newPagination);
        }
    }

    render() {
        const { classes, orderUnitServiceProduct, isLoadingUnitServiceProduct, isLoadingUnitServiceProductSurcharges, surcharges, order, isLoadingOrder,
            fetchSurchargesByOrderUnitServiceProductId } = this.props;
        const { menuItems, serverPagination, columnVisibilityModel } = this.state;
        const { orderUnitServiceProductId } = this.props.match.params;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingUnitServiceProduct || isLoadingOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.OrderUnitServiceProduct}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: orderUnitServiceProduct.product?.name,
                    icon: <FontAwesomeIcon icon="project-diagram" size="1x" />
                } as IMenuItem}
                routes={
                    [{
                        name: order.orderNumber,
                        url: `${UrlConstants.ORDERS}/${order.id}`
                    }, {
                        name: orderUnitServiceProduct.orderUnitService?.orderUnit?.unitNo,
                        url: `${UrlConstants.ORDER_UNITS}/${orderUnitServiceProduct.orderUnitService?.orderUnit?.id}`
                    }, {
                        name: orderUnitServiceProduct.orderUnitService?.service?.type?.name,
                        url: `${UrlConstants.ORDER_UNIT_SERVICES}/${orderUnitServiceProduct.orderUnitService?.id}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId/properties`}>
                            <ViewOrderUnitServiceProduct orderUnitServiceProduct={orderUnitServiceProduct} />
                        </Route>
                        <Route path={`/${UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS}/:orderUnitServiceProductId/surcharges`}>
                            <SurchargesList isLoading={isLoadingUnitServiceProductSurcharges} rows={surcharges}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchSurchargesByOrderUnitServiceProductId(orderUnitServiceProductId,newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortModelChange(sortModel)}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        columnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(OrderUnitServiceProductDetails));