import React, { useEffect } from 'react';
import { Button, FormControl, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import UserFilters from '../../interfaces/filters/userFilters';
import Action from '../../interfaces/common/action';
import ActionRenderer from '../common/actionRenderer';
import GenericTextField from '../common/widgets/genericTextField';
import { fetchUserStatuses, getUserStatuses } from '../../reducers/userStatusReducer';
import UserStatusOut from '../../interfaces/output/userStatusOut';

interface IFilterProps {
    filters: UserFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    ssoId: LanguageUtils.createMessage('Sso Id'),
    status: LanguageUtils.createMessage('Status'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function SurchargeRateFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const userStatuses = useSelector(getUserStatuses).content;

    useEffect(() => {
        dispatch(fetchUserStatuses({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="ssoId"
                                label={messages.ssoId}
                                onChange={onChange}
                                value={filters.ssoId}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<UserStatusOut>
                            options={userStatuses}
                            value={filters.statusId}
                            onChange={(obj: UserStatusOut | null) => onChange('statusId', obj?.id)}
                            placeholder={messages.status}
                            compareFn={(o: UserStatusOut) => o.id === filters.statusId}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}