import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SurchargeArea from '../interfaces/output/surchargeArea';

export type SurchargeAreaState = {
    surchargeArea: SurchargeArea;
    isLoading: boolean;
    surchargeAreas: Pagination<SurchargeArea>;
    error: HttpErrorResponse | undefined;
};

const initialState: SurchargeAreaState = {
    surchargeArea: {} as SurchargeArea,
    surchargeAreas: { content: [] as Array<SurchargeArea> } as Pagination<SurchargeArea>,
    isLoading: false,
    error: undefined
};

export const surchargeAreaSlice = createSlice({
    name: 'surchargeArea',

    initialState,
    reducers: {
        fetchSurchargeAreas: (state: SurchargeAreaState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchSurchargeAreasSuccess: (state: SurchargeAreaState, action: PayloadAction<Pagination<SurchargeArea>>) => {
            state.isLoading = false;
            state.surchargeAreas = action.payload;
        },
        fetchSurchargeAreasError: (state: SurchargeAreaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchSurchargeAreaById: (state: SurchargeAreaState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchSurchargeAreaByIdSuccess: (state: SurchargeAreaState, action: PayloadAction<SurchargeArea>) => {
            state.isLoading = false;
            state.surchargeArea = action.payload;
        },
        fetchSurchargeAreaByIdError: (state: SurchargeAreaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchSurchargeAreaById, fetchSurchargeAreaByIdError, fetchSurchargeAreaByIdSuccess,
    fetchSurchargeAreas, fetchSurchargeAreasError, fetchSurchargeAreasSuccess } = surchargeAreaSlice.actions;

export const isLoadingSurchargeArea = (state: RootState): boolean => state.surchargeAreaReducer.isLoading;
export const getSurchargeAreas = (state: RootState): Pagination<SurchargeArea> => state.surchargeAreaReducer.surchargeAreas;
export const getSurchargeArea = (state: RootState): SurchargeArea => state.surchargeAreaReducer.surchargeArea;

export default surchargeAreaSlice.reducer;