import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import UrlConstants from '../../constants/UrlConstants';
import UserFilterComponent from './userFilterComponent';
import UsersList from './usersList';
import User from '../../interfaces/output/user';
import UserFilters from '../../interfaces/filters/userFilters';
import { fetchUsers, getIsLoadingUsers, getUsers, resetUsers } from '../../reducers/userReducer';
import { fetchUserStatuses, isLoadingUserStatuses } from '../../reducers/userStatusReducer';
interface ISUsersProps {
    users: Pagination<User>;
    classes: any;
    fetchUsers: any;
    isLoading: boolean;
    onChange: () => void;
    resetUsers: () => void;
    fetchImportExportCodes: any;
}

interface IUserState {
    filters: UserFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    users: LanguageUtils.createMessage('Users'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchUsers: (paging: Paging, filters: UserFilters) => dispatch(fetchUsers({
        paging,
        filters
    })),
    fetchUserStatus: (paging: Paging) => dispatch(fetchUserStatuses({ paging })),
    resetUsers: () => dispatch(resetUsers())

});

const mapStoreToProps = (store: RootState) => {
    return {
        users: getUsers(store),
        isLoading: getIsLoadingUsers(store),
        isLoadingFilters: isLoadingUserStatuses(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class Users extends Component<ISUsersProps, IUserState> {
    usersListRef: any;
    constructor(props: ISUsersProps) {
        super(props);
        this.usersListRef = React.createRef();

        this.state = {
            filters: {} as UserFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.users,
                icon: <FontAwesomeIcon icon="user-friends" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchUsers } = this.props;
        const { paging, filters } = this.state;

        fetchUsers(paging, filters);
    }

    componentWillUnmount() {
        const { resetUsers } = this.props;
        resetUsers();
    }

    applyFilters = () => {
        const { fetchUsers } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchUsers(newPagination, filters);

        if(this.usersListRef !== null && this.usersListRef !== undefined &&
            this.usersListRef.current !== null && this.usersListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.usersListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as UserFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchUsers } = this.props;
        const { paging } = this.state;

        const newFilters = {
        } as UserFilters;

        const newSortModel = [{
            field: 'id',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters
        });

        fetchUsers(newPagination, newFilters);

        if(this.usersListRef !== null && this.usersListRef !== undefined &&
            this.usersListRef.current !== null && this.usersListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.usersListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchUsers } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchUsers(newPaging, filters);

        }
    }

    render() {
        const { classes, users, fetchUsers, isLoading } = this.props;
        const { paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <UserFilterComponent
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.USERS}/add`
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <UsersList rows={users} isLoading={isLoading}
                                    ref={this.usersListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchUsers(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Users));