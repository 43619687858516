import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import PriceListType from '../interfaces/output/priceListType';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class PriceListTypeApi {
    static fetchPriceListTypeById = async(pricelistTypeId: string): Promise<PriceListType> => {
        const { data } = await http.get<PriceListType>(`/pricelisttypes/${pricelistTypeId}`);

        return data;
    };

    static fetchPriceListTypes = async(paging: Paging): Promise<Pagination<PriceListType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/pricelisttypes');
        
        const { data } = await http.get<Pagination<PriceListType>>(url);

        return data;
    };

    static createPriceListType = async(priceListType: PriceListType): Promise<PriceListType> => {
        const newPriceListType = {
            name: priceListType.name,
            code: priceListType.code
        };

        const { data } = await http.post<any, PriceListType>('pricelisttypes', newPriceListType);

        return data;
    }

    static updatePriceListType = async(priceListType: PriceListType): Promise<PriceListType> => {
        const newPriceListType = {
            name: priceListType.name,
            code: priceListType.code
        };
        
        const { data } = await http.put<any, PriceListType>(`pricelisttypes/${priceListType.id}`, newPriceListType);

        return data;
    }

    static deactivatePriceListType = async(priceListType: PriceListType): Promise<PriceListType> => {
        const newPriceListType = {
            name: priceListType.name,
            code: priceListType.code
        };

        const { data } = await http.put<any, PriceListType>(`pricelisttypes/${priceListType.id}`, newPriceListType);

        return data;
    }
}
