import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
// import LanguageUtils from '../../../utils/LanguageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Paging from '../../../interfaces/common/paging';
import PricelistList from '../../pricelists/pricelistList';
import { fetchPriceListById, fetchPriceLists, getPriceList, getPriceLists, isLoadingPriceList, isLoadingPriceLists } from '../../../reducers/priceListReducer';
import PriceListsFilters from '../../../interfaces/output/priceListFilters';
import WorkflowUtils from '../../../utils/workflowUtils';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddOrderUnitServiceProductStep3Props {
    onChange: any;
}

const AddOrderUnitServiceProductStep3 = forwardRef((props: AddOrderUnitServiceProductStep3Props, ref: any): JSX.Element => {
    const { onChange } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const pricelists = useSelector(getPriceLists);
    const propsPriceList = useSelector(getPriceList);
    const propsLoadingPriceList = useSelector(isLoadingPriceList);
    const prevLoadingPriceList = WorkflowUtils.usePrevious<boolean>(propsLoadingPriceList);
    const isLoadingPriceListsList = useSelector(isLoadingPriceLists);
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({ discount: false });

    useEffect(() => {
        dispatch(fetchPriceLists({
            paging,
            filters: {} as PriceListsFilters
        }));
    }, [dispatch]);

    useEffect(() => {
        if(prevLoadingPriceList === true && !propsLoadingPriceList) {
            onChange('pricelist', propsPriceList);
        }
    }, [propsLoadingPriceList]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchPriceLists({
                paging: newServerPagination,
                filters: { name: searchTerm } as PriceListsFilters
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchPriceLists({
                        paging: paging,
                        filters: { name: searchTerm } as PriceListsFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <PricelistList
                    isLoading={isLoadingPriceListsList}
                    rows={pricelists}
                    hiddenColumns={['discount']}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (event: any) => setSearchTerm(event.target.value),
                            clearSearch: () => setSearchTerm('')
                        }
                    }}
                    checkSelection={(value: number) => {
                        if(value) {
                            dispatch(fetchPriceListById({ id: value }));
                        }
                    }}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchPriceLists({
                            paging: newPage,
                            filters: { name: searchTerm } as PriceListsFilters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    disableLinks
                />
            </Grid>
        </Grid>
    );
});

export default AddOrderUnitServiceProductStep3;
