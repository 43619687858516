import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import AgreementVersionStatus from '../interfaces/output/agreementVersionStatus';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class AgreementVersionStatusApi {
    static fetchAgreementVersionStatusById = async(agreementVersionStatusId: string): Promise<AgreementVersionStatus> => {
        const { data } = await http.get<AgreementVersionStatus>(`agreementversionstatus/${agreementVersionStatusId}`);

        return data;
    };

    static fetchAgreementVersionStatuses = async(paging: Paging): Promise<Pagination<AgreementVersionStatus>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/agreementversionstatus');

        const { data } = await http.get<Pagination<AgreementVersionStatus>>(url);

        return data;
    };

    static updateAgreementVersionStatus = async(agreementVersionStatus: AgreementVersionStatus): Promise<AgreementVersionStatus> => {
        const newAgreementVersionStatus = {
            code: agreementVersionStatus?.code,
            name: agreementVersionStatus?.name
        };
        
        const { data } = await http.put<any, AgreementVersionStatus>(`agreementversionstatus/${agreementVersionStatus.id}`, newAgreementVersionStatus);

        return data;
    }

    static deactivateAgreementVersionStatus = async(agreementVersionStatus: AgreementVersionStatus): Promise<AgreementVersionStatus> => {
        const newAgreementVersionStatus = {
            code: agreementVersionStatus?.code,
            name: agreementVersionStatus?.name
        };

        const { data } = await http.put<any, AgreementVersionStatus>(`agreementversionstatus/${agreementVersionStatus.id}`, newAgreementVersionStatus);

        return data;
    }
}