import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import Paging from '../../interfaces/common/paging';
import LanguageUtils from '../../utils/LanguageUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import Terminal from '../../interfaces/output/terminal';
import { deleteTerminal, fetchTerminalById, getTerminal, isDeleted, isLoadingTerminal, resetTerminal } from '../../reducers/terminalReducer';
import ViewTerminal from './viewTerminal';
import { ensure } from '../../utils/arrayUtils';

interface ITerminalDetailsProps {
    classes: any;
    theme: Theme;
    fetchTerminalById: any;
    terminal: Terminal;
    match: any;
    history: any;
    location: any;
    isLoadingTerminal: boolean;
    resetTerminal: () => void;
    deleteTerminal: any;
    isDeletingTerminal: boolean;
}

interface ITerminalDetailsState {
    terminal: Terminal;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverProductPagination: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchTerminalById: (terminalId: any) => dispatch(fetchTerminalById(terminalId)),
    resetTerminal: () => dispatch(resetTerminal()),
    deleteTerminal: (terminalId: any) => dispatch(deleteTerminal(terminalId))
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    properties: LanguageUtils.createMessage('Properties'),
    terminal: LanguageUtils.createMessage('Terminal'),
    delete: LanguageUtils.createMessage('Delete')
};

const mapStoreToProps = (store: RootState) => {
    return {
        terminal: getTerminal(store),
        isLoading: isLoadingTerminal(store) || isDeleted(store),
        isDeletingTerminal: isDeleted(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    }
});

class TerminalDetails extends Component<ITerminalDetailsProps, ITerminalDetailsState> {
    constructor(props: ITerminalDetailsProps) {
        super(props);

        this.state = {
            terminal: {} as Terminal,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.TERMINALS}/${props.match.params.terminalId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.TERMINALS}/${props.match.params.terminalId}/properties`, props.location.pathname)
            }
            ]),
            serverProductPagination: PageUtils.getDefaultPaging(),
            selectedRow: undefined,
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {

            }
        };
    }

    componentDidMount() {
        const { fetchTerminalById } = this.props;
        const { terminalId } = this.props.match.params;

        fetchTerminalById(terminalId);
    }

    componentDidUpdate(prevProps: ITerminalDetailsProps) {
        const { terminal, isDeletingTerminal, history } = this.props;

        if(terminal !== prevProps.terminal) {
            this.setState({ terminal });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            });
        }

        if(!isDeletingTerminal && prevProps.isDeletingTerminal) {
            history.push(`/${UrlConstants.TERMINALS}`);
        }
    }

    componentWillUnmount() {
        resetTerminal();
    }

    getSelectedMenuItem = (state: ITerminalDetailsState) => state.menuItems.find(item => item.isSelected);

    deleteTerminal = () => {
        const { deleteTerminal } = this.props;
        const { terminalId } = this.props.match.params;

        deleteTerminal(ensure(terminalId));
    }

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { terminalId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.TERMINALS}/${terminalId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deleteTerminal
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, isLoadingTerminal } = this.props;
        const { terminal, menuItems } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingTerminal}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Terminal}
                menuItem={{
                    id: 1,
                    subname: messages.terminal,
                    value: terminal?.id,
                    icon: <FontAwesomeIcon icon="file-invoice-dollar" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.TERMINALS}/:terminalId/properties`}>
                            <ViewTerminal terminal={terminal} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(TerminalDetails));