import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import SimulationOrderOut from '../interfaces/output/simulationOrderOut';
import SimulationOrderApi from '../api/simulationOrderApi';
import { createSimulationOrderError, createSimulationOrderSuccess, deleteSimulationOrderError, deleteSimulationOrderSuccess,
    fetchSimulationOrderByIdError, fetchSimulationOrderByIdSuccess, fetchSimulationOrdersError, fetchSimulationOrdersSuccess,
    updateSimulationOrderError, updateSimulationOrderSuccess } from '../reducers/simulationOrderReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import SimulationOrderFilters from '../interfaces/filters/simulationOrderFilters';

function* doFetchSimulationOrder(action: PayloadAction<string>) {
    const simulationOrderId = action.payload;
    try {
        const response: SimulationOrderOut = yield call(SimulationOrderApi.fetchSimulationOrderById, simulationOrderId);
        yield put(fetchSimulationOrderByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationOrderByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSimulationOrders(action: PayloadAction<{ paging: Paging, filters: SimulationOrderFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<SimulationOrderOut> = yield call(SimulationOrderApi.fetchSimulationOrders, paging, filters);
        yield put(fetchSimulationOrdersSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationOrdersError(error as HttpErrorResponse));
    }
}

function* doUpdateSimulationOrder(action: PayloadAction<SimulationOrderOut>) {
    const simulationOrder = action.payload;

    try {
        const response: SimulationOrderOut = yield call(SimulationOrderApi.updateSimulationOrder, simulationOrder);

        yield put(updateSimulationOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateSimulationOrderError(error as HttpErrorResponse));
    }
}

function* doCreateSimulationOrder(action: PayloadAction<SimulationOrderOut>) {
    const simulationOrder = action.payload;

    try {
        const response: SimulationOrderOut = yield call(SimulationOrderApi.createSimulationOrder, simulationOrder);

        yield put(createSimulationOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(createSimulationOrderError(error as HttpErrorResponse));
    }
}

function* doDeleteSimulationOrder(action: PayloadAction<SimulationOrderOut>) {
    const simulationOrder = action.payload;
    
    try {
        const response: SimulationOrderOut = yield call(SimulationOrderApi.deleteSimulationOrder, simulationOrder);

        yield put(deleteSimulationOrderSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteSimulationOrderError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('simulationOrder/fetchSimulationOrderById', doFetchSimulationOrder),
    takeLatest('simulationOrder/fetchSimulationOrders', doFetchSimulationOrders),
    takeLatest('simulationOrder/updateSimulationOrder', doUpdateSimulationOrder),
    takeLatest('simulationOrder/createSimulationOrder', doCreateSimulationOrder),
    takeLatest('simulationOrder/deleteSimulationOrder', doDeleteSimulationOrder)
];