import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { useDispatch, useSelector } from 'react-redux';
import GtsOrder from '../../interfaces/output/gtsOrder';
import WorkflowUtils from '../../utils/workflowUtils';
import { fetchGtsOrderById, getGtsOrder, isCreatingGtsOrder, isUpdatingGtsOrder, updateGtsOrder } from '../../reducers/gtsOrderReducer';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import AddGtsOrderStep1 from './add/addGtsOrderStep1';
import { FormType } from '../../constants/constants';
import AddGtsOrderStep1Validation from './add/validations/addGtsOrderStep1Validation';

interface IAddGtsOrderFormProps {
    gtsOrderId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit gts Order')
};

export default function AddGtsOrderForm(props: IAddGtsOrderFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { gtsOrderId, steps, type } = props;
    const [gtsOrder, setGtsOrder] = React.useState<GtsOrder>({ } as GtsOrder);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsGtsOrder = useSelector(getGtsOrder);
    const propsIsCreatingGtsOrder = useSelector(isCreatingGtsOrder);
    const propsIsUpdatingGtsOrder = useSelector(isUpdatingGtsOrder);
    const prevIsCreatingGtsOrder = WorkflowUtils.usePrevious<boolean>(propsIsCreatingGtsOrder);
    const prevIsUpdatingGtsOrder = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingGtsOrder);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingGtsOrder === true && !propsIsUpdatingGtsOrder) || (prevIsCreatingGtsOrder === true && !propsIsCreatingGtsOrder)) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingGtsOrder, propsIsCreatingGtsOrder]);
    
    useEffect(() => {
        if(gtsOrderId) {
            dispatch(fetchGtsOrderById(gtsOrderId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsGtsOrder.id) {
            setGtsOrder(propsGtsOrder);
        }

    }, [propsIsUpdatingGtsOrder, propsGtsOrder.id]);

    const onChange = (attribute: string, value: any) => {
        const newGtsOrder = { ...gtsOrder } as GtsOrder;
        (newGtsOrder as any)[attribute] = value;

        setGtsOrder(newGtsOrder);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <AddGtsOrderStep1 onChange={onChange} gtsOrder={gtsOrder} />,
            validationFn: () => AddGtsOrderStep1Validation.validateAddGtsOrderStep1Form(gtsOrder),
            onNext: () => {
                dispatch(updateGtsOrder(gtsOrder));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={`/${UrlConstants.GTS_ORDERS}/${gtsOrder.id}`}
        />
    );
}
  