import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetTerminals } from '../../../reducers/terminalReducer';
import TerminalForm from './terminalForm';

interface IAddTerminalProps {
    classes: any;
    theme: Theme;
    match: any;
    users: any;
    steps: Array<number>;
    terminalId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetTerminals: () => dispatch(resetTerminals())
});

const mapStoreToProps = (store: RootState, ownProps: IAddTerminalProps) => {
    return {
        terminalId: ownProps.match.params?.terminalId ? Number(ownProps.match.params?.terminalId) : undefined
    };
};

class AddTerminal extends Component<IAddTerminalProps> {
    componentWillUnmount() {
        resetTerminals();
    }

    render() {
        const { classes, steps, terminalId, type } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <TerminalForm terminalId={terminalId} steps={steps} type={type} />
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddTerminal));