import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PriorityType from '../interfaces/output/priorityType';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ServiceArena from '../interfaces/output/serviceArena';
import ServiceArenaType from '../interfaces/output/serviceArenaType';
import ServiceArenaFilters from '../interfaces/filters/serviceArenaFilters';

export type ServiceArenaState = {
    isLoading: boolean;
    servicesArena: Pagination<ServiceArena>;
    error: HttpErrorResponse | undefined;
};

const initialState: ServiceArenaState = {
    servicesArena: { content: [] as Array<ServiceArena> } as Pagination<ServiceArena>,
    isLoading: false,
    error: undefined
};

export const serviceArenaSlice = createSlice({
    name: 'serviceArena',

    initialState,
    reducers: {
        fetchServicesArena: (state: ServiceArenaState, _action: PayloadAction<{ paging: Paging, filters: ServiceArenaFilters }>) => {
            state.isLoading = true;
        },
        fetchServicesArenaSuccess: (state: ServiceArenaState, action: PayloadAction<Pagination<ServiceArena>>) => {
            state.isLoading = false;
            state.servicesArena = action.payload;
        },
        fetchServicesArenaError: (state: ServiceArenaState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        resertServicesArena(state: ServiceArenaState) {
            state.servicesArena = initialState.servicesArena;
        }
    }
});

export const { fetchServicesArena, fetchServicesArenaSuccess, fetchServicesArenaError, resertServicesArena } = serviceArenaSlice.actions;

export const isLoadingServicesArena = (state: RootState): boolean => state.serviceArenaReducer.isLoading;
export const getServicesArena = (state: RootState): Pagination<ServiceArena> => state.serviceArenaReducer.servicesArena;

export default serviceArenaSlice.reducer;