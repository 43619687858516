import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Order from '../interfaces/output/order';
import OrderFilters from '../interfaces/filters/orderFilters';
import OrderUnit from '../interfaces/output/orderUnit';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import OrderUnitService from '../interfaces/output/orderUnitService';

export type OrderState = {
    order: Order;
    unit: OrderUnit;
    isLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    isLoadingOrder: boolean;
    isLoadingUnits: boolean;
    isLoadingUnitServices: boolean;
    isDeleting: boolean;
    isCrediting: boolean;
    orders: Pagination<Order>;
    unitServices: Pagination<OrderUnitService>;
    units: Pagination<OrderUnit>;
    error: HttpErrorResponse | undefined;
    isLoadingFindOrderPrice: boolean;
    isDuplicatedOrder: boolean;
};

const initialState: OrderState = {
    order: {} as Order,
    unit: {} as OrderUnit,
    orders: { content: [] as Array<Order> } as Pagination<Order>,
    unitServices: { content: [] as Array<OrderUnitService> } as Pagination<OrderUnitService>,
    units: { content: [] as Array<OrderUnit> } as Pagination<OrderUnit>,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isLoadingOrder: false,
    isLoadingUnits: false,
    isLoadingUnitServices: false,
    isDeleting: false,
    isCrediting: false,
    error: undefined,
    isLoadingFindOrderPrice: false,
    isDuplicatedOrder: false
};

export const orderSlice = createSlice({
    name: 'order',

    initialState,
    reducers: {
        fetchOrders: (state: OrderState, _action: PayloadAction<{paging: Paging, filters: OrderFilters}>) => {
            state.isLoading = true;
        },
        fetchOrdersSuccess: (state: OrderState, action: PayloadAction<Pagination<Order>>) => {
            state.isLoading = false;
            state.orders = action.payload;
        },
        fetchOrdersError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchOrderById: (state: OrderState, _action: PayloadAction<number>) => {
            state.isLoadingOrder = true;
        },
        fetchOrderByIdSuccess: (state: OrderState, action: PayloadAction<Order>) => {
            state.isLoadingOrder = false;
            state.order = action.payload;
        },
        fetchOrderByIdError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrder = false;
            state.error = action.payload;
        },
        fetchUnitsByOrderId: (state: OrderState, _action: PayloadAction<{paging: Paging, id: number}>) => {
            state.isLoadingUnits = true;
        },
        fetchUnitsByOrderIdSuccess: (state: OrderState, action: PayloadAction<Pagination<OrderUnit>>) => {
            state.isLoadingUnits = false;
            state.units = action.payload;
        },
        fetchUnitsByOrderIdError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnits = false;
            state.error = action.payload;
        },
        fetchUnitServicesByOrderId: (state: OrderState, _action: PayloadAction<{paging: Paging, id: number}>) => {
            state.isLoadingUnitServices = true;
        },
        fetchUnitServicesByOrderIdSuccess: (state: OrderState, action: PayloadAction<Pagination<OrderUnitService>>) => {
            state.isLoadingUnitServices = false;
            state.unitServices = action.payload;
        },
        fetchUnitServicesByOrderIdError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitServices = false;
            state.error = action.payload;
        },
        createOrder: (state: OrderState, _action: PayloadAction<Order>) => {
            state.isCreating = true;
        },
        createOrderSuccess: (state: OrderState, action: PayloadAction<Order>) => {
            state.isCreating = false;
            state.order = action.payload;
        },
        createOrderError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateOrder: (state: OrderState, _action: PayloadAction<Order>) => {
            state.isUpdating = true;
        },
        updateOrderSuccess: (state: OrderState, action: PayloadAction<Order>) => {
            state.isUpdating = false;
            state.order = action.payload;
        },
        updateOrderError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateOrder: (state: OrderState, _action: PayloadAction<Order>) => {
            state.isDeleting = true;
        },
        deactivateOrderSuccess: (state: OrderState, action: PayloadAction<Order>) => {
            state.isDeleting = false;
            state.order = action.payload;
        },
        deactivateOrderError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        creditOrder: (state: OrderState, _action: PayloadAction<{id: number, createNewCredit: boolean, creditReason: string}>) => {
            state.isCrediting = true;
        },
        creditOrderSuccess: (state: OrderState, action: PayloadAction<Order>) => {
            state.isCrediting = false;
        },
        creditOrderError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCrediting = false;
            state.error = action.payload;
        },
        findOrderPrice: (state: OrderState, _action: PayloadAction<number>) => {
            state.isLoadingFindOrderPrice = true;
        },
        findOrderPriceSuccess: (state: OrderState, action: PayloadAction<number>) => {
            state.isLoadingFindOrderPrice = false;
        },
        findOrderPriceError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingFindOrderPrice = false;
            state.error = action.payload;
        },
        resetOrders: (state: OrderState) => {
            state.orders = initialState.orders;
        },
        resetOrder: (state: OrderState) => {
            state.order = initialState.order;
        },
        duplicateOrder: (state: OrderState, _action: PayloadAction<number>) => {
            state.isDuplicatedOrder = true;
        },
        duplicateOrderSuccess: (state: OrderState, action: PayloadAction<number>) => {
            state.isDuplicatedOrder = false;
        },
        duplicateOrderError: (state: OrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDuplicatedOrder = false;
            state.error = action.payload;
        }
    }
});

export const { fetchOrders, fetchOrdersSuccess, fetchOrdersError, fetchOrderById, fetchOrderByIdSuccess, resetOrders,createOrder, createOrderSuccess, fetchUnitsByOrderId, fetchUnitsByOrderIdSuccess,
    fetchUnitsByOrderIdError, fetchOrderByIdError, createOrderError, updateOrder, updateOrderError, updateOrderSuccess, resetOrder, fetchUnitServicesByOrderId, fetchUnitServicesByOrderIdSuccess,
    fetchUnitServicesByOrderIdError, creditOrder, creditOrderSuccess, creditOrderError, findOrderPrice, findOrderPriceSuccess, findOrderPriceError,
    duplicateOrder, duplicateOrderError, duplicateOrderSuccess } = orderSlice.actions;

export const isLoadingOrder = (state: RootState): boolean => state.orderReducer.isLoadingOrder;
export const isLoadingOrders = (state: RootState): boolean => state.orderReducer.isLoading;
export const isLoadingUnits = (state: RootState): boolean => state.orderReducer.isLoadingUnits;
export const isLoadingUnitServices = (state: RootState): boolean => state.orderReducer.isLoadingUnitServices;
export const isUpdatingOrder = (state: RootState): boolean => state.orderReducer.isUpdating;
export const isCreatingOrder = (state: RootState): boolean => state.orderReducer.isCreating;
export const isDeleting = (state: RootState): boolean => state.orderReducer.isDeleting;
export const getOrders = (state: RootState): Pagination<Order> => state.orderReducer.orders;
export const getUnits = (state: RootState): Pagination<OrderUnit> => state.orderReducer.units;
export const getUnitServices = (state: RootState): Pagination<OrderUnitService> => state.orderReducer.unitServices;
export const getOrder = (state: RootState): Order => state.orderReducer.order;
export const isCrediting = (state: RootState): boolean => state.orderReducer.isCrediting;
export const isLoadingFindOrderPrice = (state: RootState): boolean => state.orderReducer.isLoadingFindOrderPrice;
export const isDuplicatedOrder = (state: RootState): boolean => state.orderReducer.isDuplicatedOrder;

export default orderSlice.reducer;