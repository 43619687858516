import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import Product from '../interfaces/output/product';
import { createProductError, createProductSuccess, deactivateProductError, deactivateProductSuccess,
    fetchProductByIdError, fetchProductByIdSuccess, fetchProductDynamicsByIdError, fetchProductDynamicsByIdSuccess, fetchProductDynamicsByProductIdError, fetchProductDynamicsByProductIdSuccess,
    fetchProductsError, fetchProductsSuccess, updateProductDynamicsError, updateProductDynamicsSuccess, createProductDynamicsSuccess, createProductDynamicsError,
    updateProductError, updateProductSuccess, deactivateProductDynamicsSuccess } from '../reducers/productReducer';
import ProductFilters from '../interfaces/output/productFilters';
import ProductApi from '../api/productApi';
import ProductDynamics from '../interfaces/output/productDynamics';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchProduct(action: PayloadAction<string>) {
    const productId = action.payload;

    try {
        const response: Product = yield call(ProductApi.fetchProductById, productId);
    
        yield put(fetchProductByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductDynamicsById(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: ProductDynamics = yield call(ProductApi.fetchProductDynamicsById, id);
    
        yield put(fetchProductDynamicsByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductDynamicsByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductDynamicsByProductId(action: PayloadAction<{productId:number, paging: Paging }>) {
    const { productId, paging } = action.payload;

    try {
        const response: Pagination<ProductDynamics> = yield call(ProductApi.fetchProductDynamicsByProductId, productId, paging);
    
        yield put(fetchProductDynamicsByProductIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductDynamicsByProductIdError(error as HttpErrorResponse));
    }
}

function* doFetchProducts(action: PayloadAction<{ paging: Paging, filters: ProductFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<Product> = yield call(ProductApi.fetchProducts, paging, filters);

        yield put(fetchProductsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductsError(error as HttpErrorResponse));
    }
}

function* doCreateProduct(action: PayloadAction<Product>) {
    const product = action.payload;

    try {
        const response: Product = yield call(ProductApi.createProduct, product);

        yield put(createProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(createProductError(error as HttpErrorResponse));
    }
}

function* doUpdateProduct(action: PayloadAction<Product>) {
    const product = action.payload;

    try {
        const response: Product = yield call(ProductApi.updateProduct, product);

        yield put(updateProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateProductError(error as HttpErrorResponse));
    }
}

function* doUpdateProductDynamics(action: PayloadAction<ProductDynamics>) {
    const productDynamics = action.payload;

    try {
        const response: ProductDynamics = yield call(ProductApi.updateProductDynamics, productDynamics);

        yield put(updateProductDynamicsSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateProductDynamicsError(error as HttpErrorResponse));
    }
}

function* doCreateProductDynamics(action: PayloadAction<ProductDynamics>) {
    const productDynamics = action.payload;

    try {
        const response: ProductDynamics = yield call(ProductApi.createProductDynamics, productDynamics);

        yield put(createProductDynamicsSuccess(response));
    }

    catch (error: unknown) {
        yield put(createProductDynamicsError(error as HttpErrorResponse));
    }
}

function* doDeactivateProductDynamics(action: PayloadAction<number>) {
    const productDynamicsId = action.payload;

    try {
        const response: ProductDynamics = yield call(ProductApi.deactivateProductDynamics, productDynamicsId);

        yield put(deactivateProductDynamicsSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateProductError(error as HttpErrorResponse));
    }
}

function* doDeactivateProduct(action: PayloadAction<Product>) {
    const product = action.payload;
    
    try {
        const response: Product = yield call(ProductApi.deactivateProduct, product);

        yield put(deactivateProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateProductError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('products/fetchProductById', doFetchProduct),
    takeLatest('products/fetchProductDynamicsById', doFetchProductDynamicsById),
    takeLatest('products/fetchProductDynamicsByProductId', doFetchProductDynamicsByProductId),
    takeLatest('products/fetchProducts', doFetchProducts),
    takeLatest('products/createProduct', doCreateProduct),
    takeLatest('products/updateProduct', doUpdateProduct),
    takeLatest('products/updateProductDynamics', doUpdateProductDynamics),
    takeLatest('products/createProductDynamics', doCreateProductDynamics),
    takeLatest('products/deactivateProductDynamics', doDeactivateProductDynamics),
    takeLatest('products/deactivateProduct', doDeactivateProduct)
];