import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import {
    DataGrid, gridPageSelector, GridRowId, GridSelectionModel, useGridApiContext, useGridSelector,
    gridPageSizeSelector, GridRowIdGetter, GridSortModel, GridColumnVisibilityModel
} from '@mui/x-data-grid';
import CapriPagination from '../../interfaces/common/pagination';
import { TablePagination } from '@mui/material';

interface IDataGridProps {
    isLoading?: boolean;
    classes?: any;
    checkSelection?: any;
    rows?: CapriPagination<any>;
    columns?: any;
    componentsProps?: any;
    onPageChange: any;
    multipleSelection?: boolean;
    defaultSelectionModel?: GridSelectionModel;
    getRowId?: GridRowIdGetter;
    isRowSelectable?: any;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const DataGridComponent = forwardRef((props: IDataGridProps, ref: any): JSX.Element => {
    const { checkSelection, isLoading, rows, columns, componentsProps, onPageChange, defaultSelectionModel,
        multipleSelection, getRowId, isRowSelectable, onSortModelChange, columnVisibilityModel, onColumnVisibilityModelChange } = props;
    const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const paginationRef = useRef();

    useEffect(() => {
        if(defaultSelectionModel && !isLoading && !initialized) {
            setSelectionModel(
                defaultSelectionModel
            );

            return setInitialized(true);
        }

        return setInitialized(false);
    }, [rows]);

    useImperativeHandle(ref, () => ({
        resetPage() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paginationRef.current.resetPage();
        },
        setPage(page: number) {
            if(paginationRef !== null && paginationRef !== undefined && paginationRef.current !== null && paginationRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
                paginationRef.current.setPage(page);
            }
        }
    }));

    return (
        <DataGrid
            sx={{
                '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'none'
                }
            }}
            autoHeight
            isRowSelectable={isRowSelectable}
            loading={isLoading}
            rows={rows?.content || []}
            columns={columns}
            pageSize={!!rows && !!rows.size && rows.size > 100 ? 10 : (rows?.size ? rows?.size : 10)}
            disableSelectionOnClick
            checkboxSelection={!!checkSelection}
            onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
                if(!multipleSelection) {
                    const selection = newSelectionModel.length > 1 ?
                        [newSelectionModel.find((s: GridRowId) => selectionModel.length > 0 && s !== selectionModel[0])] :
                        newSelectionModel.length === 1 ? newSelectionModel : [];
                    setSelectionModel(selection as GridSelectionModel);
                    checkSelection(selection[0]);
                }
                else {
                    setSelectionModel(newSelectionModel);
                    checkSelection(newSelectionModel);
                }
            }}
            components={{
                ...componentsProps,
                Pagination: CustomPagination
            }}
            componentsProps={{
                columnsPanel: {
                    sx: {
                        '& .MuiDataGrid-panelFooter button:first-child': {
                            display: 'none'
                        }
                    }
                },
                pagination: {
                    ref: paginationRef
                }
            }}
            paginationMode="server"
            rowCount={rows?.totalElements}
            onPageChange={(page: number) => onPageChange(page, rows?.size)}
            onPageSizeChange={(size: number) => onPageChange(0, size)}
            selectionModel={selectionModel}
            sortingMode="server"
            onSortModelChange={(sortModel: GridSortModel) => onSortModelChange(sortModel)}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => onColumnVisibilityModelChange(newModel)}
            rowHeight={38}
            disableColumnFilter
            getRowId={getRowId}
        />
    );
});

const CustomPagination = forwardRef((props: any, ref: any): JSX.Element => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const totalRows = apiRef.current.getRowsCount();

    useImperativeHandle(ref, () => ({
        resetPage() {
            apiRef.current.setPage(0);
        },
        setPage(pageNr: number) {
            if(apiRef !== null && apiRef !== undefined && apiRef.current !== null && apiRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                apiRef.current.setPage(pageNr);
            }
        }

    }));

    if(totalRows === 0) {
        return <span />;
    }

    return (
        <TablePagination
            color="primary"
            count={totalRows}
            page={page}
            onPageChange={(event: any, newPage: number) => apiRef.current.setPage(newPage)}
            onRowsPerPageChange={(event: any) => {
                apiRef.current.setPage(0);
                apiRef.current.setPageSize(event.target.value);
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            rowsPerPage={pageSize || 10}
            showLastButton
            showFirstButton
        />
    );
});
export default DataGridComponent;