import React, { forwardRef, useEffect, useMemo } from 'react';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FactorSetPriorityLineOutput from '../../../../interfaces/output/factorSetPriorityLineOut';
import PageUtils from '../../../../utils/pageUtils';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { isLoadingFactorSetPriority } from '../../../../reducers/factorSetPriorityReducer';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import PriorityLineFilters from '../line/priorityLineFilters';
import GenericTextField from '../../../common/widgets/genericTextField';
import { fetchPriorityVersionLines, getPriorityLines, isCreatingPriorityLine, isLoadingPriorityLine, isUpdatingPriorityLine } from '../../../../reducers/priorityLineReducer';
import { fetchPriorityTypes, getPriorityTypes, isLoadingPriorityTypes } from '../../../../reducers/priorityTypeReducer';
import PriorityType from '../../../../interfaces/output/priorityType';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import UnitType from '../../../../interfaces/output/unitType';
import Currency from '../../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';
import FactorSetPriorityLineStep1Validation from '../line/validation/factorSetPriorityLineStep1Validation';
import { ensure } from '../../../../utils/arrayUtils';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';

interface PriorityLineFormStep1Props {
    priorityLine: FactorSetPriorityLineOutput;
    factorSetVersionId?: number;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    PRIORITY_TYPE: 'priorityType',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge'
};

const messages = {
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    unittype: LanguageUtils.createMessage('Unit type'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    priorityTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_PRIORITY_TYPE_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT')
};

const PriorityLineFormStep1 = forwardRef((props: PriorityLineFormStep1Props, ref: any): JSX.Element => {
    const { priorityLine, onChange, factorSetVersionId, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingPriorityLine), useSelector(isLoadingPriorityLine), useSelector(isLoadingPriorityTypes), useSelector(isUpdatingPriorityLine),
        useSelector(isLoadingFactorSetPriority)];
    const priorityTypes = useSelector(getPriorityTypes).content;
    const priorityLines = useSelector(getPriorityLines).content;
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const prevPriorityLine = WorkflowUtils.usePrevious<FactorSetPriorityLineOutput>(priorityLine);
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchPriorityTypes({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchPriorityVersionLines({
            paging,
            filters: { factorSetVersionId: factorSetVersionId } as PriorityLineFilters }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(priorityLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(priorityLine.unitType?.unitGroupId),
                paging }));
            if(priorityLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(priorityLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevPriorityLine) {
            if(priorityLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(priorityLine.unitType?.unitGroupId),
                    paging }));
                if(prevPriorityLine.id === priorityLine.id) {
                    onChange('unitType', { ...priorityLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                }
            }
            else {
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [priorityLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevPriorityLine) {
            if(priorityLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(priorityLine.unitType?.unitSubGroupId),
                    paging }));
                if(prevPriorityLine.id === priorityLine.id) {
                    onChange('unitType', { ...priorityLine.unitType,
                        id: null });
                }
            }
            else {
                dispatch(resetUnitTypes());
            }
        }
    }, [priorityLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!priorityLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const filteredTypes = () => {
        return useMemo(() => {
            return priorityTypes.filter(dt => (!priorityLine.unitType?.id && (!priorityLines.find(dl => dl.priorityType?.id === dt.id && !dl.unitType)))
        || !priorityLines.find(dl => dl.id !== priorityLine?.id && dl.priorityType?.id === dt.id && dl.unitType?.id === priorityLine.unitType?.id));
        }, [priorityTypes, priorityLine, priorityLines]);
    };

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetPriorityLineStep1Validation.validateName(priorityLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = priorityLine.surcharge ? FactorSetPriorityLineStep1Validation.validateInputNumber(priorityLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetPriorityLineStep1Validation.validateInputNumber(priorityLine.factor);
                break;
            }
            case ATTRIBUTES.PRIORITY_TYPE: {
                isValid = FactorSetPriorityLineStep1Validation.validateRequiredNumberWithMaxLength(priorityLine.priorityType?.id);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = priorityLine.surcharge ? FactorSetPriorityLineStep1Validation.validateInputNumber(priorityLine.surchargeCurrency?.id) : true;
                break;
            }
        }

        return !isValid;
    };
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={priorityLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<PriorityType>
                            options={filteredTypes()}
                            value={priorityLine.priorityType}
                            onChange={(obj: PriorityType | null) => onChange('priorityType', obj)}
                            placeholder={messages.priorityType}
                            error={setValidationState(ATTRIBUTES.PRIORITY_TYPE)}
                            required
                            helperText={messages.priorityTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={priorityLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === priorityLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...priorityLine.unitType,
                                unitGroupId: obj?.id })}
                            placeholder={messages.unitGroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={priorityLine.unitType?.id}
                            compareFn={(o: UnitSubGroup) => o.id === priorityLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...priorityLine.unitType,
                                unitSubGroupId: obj?.id })}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={priorityLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={priorityLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === priorityLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...priorityLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unittype}
                            isLoading={priorityLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={priorityLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        type="number"
                        label={messages.factor}
                        value={priorityLine.factor}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.FACTOR)}
                        required
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        label={messages.surcharge}
                        type="number"
                        value={priorityLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={priorityLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={priorityLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default PriorityLineFormStep1;