import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';
import { deactivateWeightVersionStatusError, deactivateWeightVersionStatusSuccess, fetchWeightVersionStatusByIdError, fetchWeightVersionStatusByIdSuccess,
    fetchWeightVersionStatusesError, fetchWeightVersionStatusesSuccess, updateWeightVersionStatusError, updateWeightVersionStatusSuccess }
    from '../reducers/weightVersionStatusReducer';
import FactorSetVersionStatusApi from '../api/versionStatusApi';

function* doFetchWeightVersionStatus(action: PayloadAction<string>) {
    const weightVersionStatusId = action.payload;
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatusById, weightVersionStatusId);
    
        yield put(fetchWeightVersionStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchWeightVersionStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchWeightVersionStatuses(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionStatusOut> = yield call(FactorSetVersionStatusApi.fetchFactorSetVersionStatuses, paging);
    
        yield put(fetchWeightVersionStatusesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchWeightVersionStatusesError(error as HttpErrorResponse));
    }
}

function* doUpdateWeightVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const weightVersionStatus = action.payload;

    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.updateFactorSetVersionStatus, weightVersionStatus);

        yield put(updateWeightVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateWeightVersionStatusError(error as HttpErrorResponse));
    }
}

function* doDeactivateWeightVersionStatus(action: PayloadAction<FactorSetVersionStatusOut>) {
    const weightVersionStatus = action.payload;
    
    try {
        const response: FactorSetVersionStatusOut = yield call(FactorSetVersionStatusApi.deactivateFactorSetVersionStatus, weightVersionStatus);

        yield put(deactivateWeightVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateWeightVersionStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('weightVersionStatus/fetchWeightVersionStatusById', doFetchWeightVersionStatus),
    takeLatest('weightVersionStatus/fetchWeightVersionStatuses', doFetchWeightVersionStatuses),
    takeLatest('weightVersionStatus/updateWeightVersionStatus', doUpdateWeightVersionStatus),
    takeLatest('weightVersionStatus/deactivateWeightVersionStatus', doDeactivateWeightVersionStatus)
];