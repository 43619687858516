import { List } from 'immutable';
import CustomerAgreement from '../../../interfaces/output/customerAgreement';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils, { ValidationConfig } from '../../../utils/validationUtils';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    invalidDates: LanguageUtils.createMessage('Invalid date range'),
    invalidDateFormat: LanguageUtils.createMessage('Invalid date format')
};

export default class CustomerAgreementPriorityAndValidityValidation extends ValidationUtils {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateForm(customerAgreement: CustomerAgreement): ValidationModel {
        const validationModel = { validationMessages: List() } as ValidationModel;

        const requiredFields =
        this.validateInputString(customerAgreement?.customer?.name) &&
        this.validateInputString(customerAgreement.id?.toString());

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
        
    }

    static validateRequiredDate(inputDate: Date | undefined): boolean {
        return !!inputDate;
    }

    static validateInputDateStart(customerAgreement: CustomerAgreement): boolean {
        const dateStart = customerAgreement.validFromDate;
        const dateTo = customerAgreement.validToDate;
        
        return this.validateDateTimeRangeStart(dateStart, dateTo);
    }

    static validateInputDateEnd(customerAgreement: CustomerAgreement): boolean {
        const dateStart = customerAgreement.validFromDate;
        const dateTo = customerAgreement.validToDate;
        
        return this.validateDateTimeRangeTo(dateStart, dateTo);
    }

    static validateCustomerAgreementForm(customerAgreement: CustomerAgreement): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const {
            priorityRank,
            validFromDate,
            validToDate
        } = customerAgreement;

        const requiredFields =
        this.validateInputString(priorityRank?.toString()) &&
        this.validateRequiredDate(validFromDate);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        const endDate = customerAgreement.validToDate;
        const validDates = endDate ? this.validateInputDateStart(customerAgreement) &&
        this.validateInputDateEnd(customerAgreement) : true;

        if(!validDates) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDates);
        }

        const formatedDate = this.validateDateFormat(validFromDate) &&
        this.validateDateFormat(validToDate);

        if(!formatedDate) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.invalidDateFormat);
        }

        return validationModel;
    }
}
