import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceTypeApi from '../api/invoiceTypeApi';
import InvoiceType from '../interfaces/output/invoiceType';
import { fetchInvoiceTypeByIdError, fetchInvoiceTypeByIdSuccess, fetchInvoiceTypesError, fetchInvoiceTypesSuccess } from '../reducers/invoiceTypeReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';

function* doFetchInvoiceType(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: InvoiceType = yield call(InvoiceTypeApi.fetchInvoiceTypeById, customerId);
        yield put(fetchInvoiceTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchInvoiceTypes(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;
    try {
        const response: Pagination<InvoiceType> = yield call(InvoiceTypeApi.fetchInvoiceTypes, paging);
        yield put(fetchInvoiceTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoiceType/fetchInvoiceTypeById', doFetchInvoiceType),
    takeLatest('invoiceType/fetchInvoiceTypes', doFetchInvoiceTypes)
];

