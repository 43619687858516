import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerOrder from '../interfaces/output/customerOrder';
import GtsOrder from '../interfaces/output/gtsOrder';
import GTSOrderUnit from '../interfaces/output/gtsOrderUnit';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type GTSOrderUnitState = {
    gtsOrderUnit: GTSOrderUnit;
    gtsOrderUnits: Pagination<GTSOrderUnit>;
    isLoadingGtsOrderUnits: boolean;
    isLoadingGtsOrderUnit: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: GTSOrderUnitState = {
    gtsOrderUnit: {} as GTSOrderUnit,
    gtsOrderUnits: { content: [] as Array<GTSOrderUnit> } as Pagination<GTSOrderUnit>,
    isLoadingGtsOrderUnits: false,
    isLoadingGtsOrderUnit: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const GTSOrderUnitSlice = createSlice({
    name: 'gtsOrderUnit',

    initialState,
    reducers: {
        fetchGtsOrderUnits: (state: GTSOrderUnitState, _action: PayloadAction<{paging: Paging, filters: GtsOrderUnitFilters}>) => {
            state.isLoadingGtsOrderUnits = true;
        },
        fetchGtsOrderUnitsSuccess: (state: GTSOrderUnitState, action: PayloadAction<Pagination<GTSOrderUnit>>) => {
            state.isLoadingGtsOrderUnits = false;
            state.gtsOrderUnits = action.payload;
        },
        fetchGtsOrderUnitsError: (state: GTSOrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingGtsOrderUnits = false;
            state.error = action.payload;
        },
        fetchGtsOrderUnitById: (state: GTSOrderUnitState, _action: PayloadAction<number>) => {
            state.isLoadingGtsOrderUnit = true;
        },
        fetchGtsOrderUnitByIdSuccess: (state: GTSOrderUnitState, action: PayloadAction<GTSOrderUnit>) => {
            state.isLoadingGtsOrderUnit = false;
            state.gtsOrderUnit = action.payload;
        },
        fetchGtsOrderUnitByIdError: (state: GTSOrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingGtsOrderUnit = false;
            state.error = action.payload;
        },
        updateGtsOrderUnit: (state: GTSOrderUnitState, _action: PayloadAction<GTSOrderUnit>) => {
            state.isUpdating = true;
        },
        updateGtsOrderUnitSuccess: (state: GTSOrderUnitState, action: PayloadAction<GTSOrderUnit>) => {
            state.isUpdating = false;
            state.gtsOrderUnit = action.payload;
        },
        updateGtsOrderUnitError: (state: GTSOrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateGtsOrderUnit: (state: GTSOrderUnitState, _action: PayloadAction<number>) => {
            state.isDeleted = true;
        },
        deactivateGtsOrderUnitSuccess: (state: GTSOrderUnitState, action: PayloadAction<GTSOrderUnit>) => {
            state.isDeleted = false;
            state.gtsOrderUnit = action.payload;
        },
        deactivateGtsOrderUnitError: (state: GTSOrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetGtsOrderUnits: (state: GTSOrderUnitState) => {
            state.gtsOrderUnits = initialState.gtsOrderUnits;
        },
        resetGtsOrderUnit: (state: GTSOrderUnitState) => {
            state.gtsOrderUnit = initialState.gtsOrderUnit;
        }
    }
});

export const { fetchGtsOrderUnits, fetchGtsOrderUnitsSuccess, fetchGtsOrderUnitsError, fetchGtsOrderUnitById, fetchGtsOrderUnitByIdSuccess, fetchGtsOrderUnitByIdError,
    updateGtsOrderUnit, updateGtsOrderUnitSuccess, updateGtsOrderUnitError, resetGtsOrderUnits, resetGtsOrderUnit, deactivateGtsOrderUnit, deactivateGtsOrderUnitSuccess,
    deactivateGtsOrderUnitError } = GTSOrderUnitSlice.actions;

export const isLoadingGtsOrderUnits = (state: RootState): boolean => state.gtsOrderUnitReducer.isLoadingGtsOrderUnits;
export const isLoadingGtsOrderUnit = (state: RootState): boolean => state.gtsOrderUnitReducer.isLoadingGtsOrderUnit;
export const getGtsOrderUnits = (state: RootState): Pagination<GTSOrderUnit> => state.gtsOrderUnitReducer.gtsOrderUnits;
export const getGtsOrderUnit = (state: RootState): GTSOrderUnit => state.gtsOrderUnitReducer.gtsOrderUnit;
export const isUpdatingGtsOrderUnit = (state: RootState): boolean => state.gtsOrderUnitReducer.isUpdating;
export const isDeletingGtsOrderUnit = (state: RootState): boolean => state.gtsOrderUnitReducer.isDeleted;

export default GTSOrderUnitSlice.reducer;