import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import SimulationStatusOut from '../interfaces/output/simulationStatusOut';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type SimulationStatusState = {
    simulationStatus: SimulationStatusOut;
    isLoadingSimulationStatus: boolean;
    isLoadingSimulationStatuss: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    simulationStatuss: Pagination<SimulationStatusOut>;
    error: HttpErrorResponse | undefined;
};

const initialState: SimulationStatusState = {
    simulationStatus: {} as SimulationStatusOut,
    simulationStatuss: { content: [] as Array<SimulationStatusOut> } as Pagination<SimulationStatusOut>,
    isLoadingSimulationStatus: false,
    isLoadingSimulationStatuss: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const simulationStatusSlice = createSlice({
    name: 'simulationStatus',

    initialState,
    reducers: {
        fetchSimulationStatuss: (state: SimulationStatusState, _action: PayloadAction<{paging: Paging, searchTerm: string}>) => {
            state.isLoadingSimulationStatuss = true;
        },
        fetchSimulationStatussSuccess: (state: SimulationStatusState, action: PayloadAction<Pagination<SimulationStatusOut>>) => {
            state.isLoadingSimulationStatuss = false;
            state.simulationStatuss = action.payload;
        },
        fetchSimulationStatussError: (state: SimulationStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulationStatuss = false;
            state.error = action.payload;
        },
        fetchSimulationStatusById: (state: SimulationStatusState, _action: PayloadAction<number>) => {
            state.isLoadingSimulationStatus = true;
        },
        fetchSimulationStatusByIdSuccess: (state: SimulationStatusState, action: PayloadAction<SimulationStatusOut>) => {
            state.isLoadingSimulationStatus = false;
            state.simulationStatus = action.payload;
        },
        fetchSimulationStatusByIdError: (state: SimulationStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSimulationStatus = false;
            state.error = action.payload;
        },
        createSimulationStatus: (state: SimulationStatusState, _action: PayloadAction<SimulationStatusOut>) => {
            state.isCreating = true;
        },
        createSimulationStatusSuccess: (state: SimulationStatusState, action: PayloadAction<SimulationStatusOut>) => {
            state.isCreating = false;
            state.simulationStatus = action.payload;
        },
        createSimulationStatusError: (state: SimulationStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateSimulationStatus: (state: SimulationStatusState, _action: PayloadAction<SimulationStatusOut>) => {
            state.isUpdating = true;
        },
        updateSimulationStatusSuccess: (state: SimulationStatusState, action: PayloadAction<SimulationStatusOut>) => {
            state.isUpdating = false;
            state.simulationStatus = action.payload;
        },
        updateSimulationStatusError: (state: SimulationStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteSimulationStatus: (state: SimulationStatusState, _action: PayloadAction<SimulationStatusOut>) => {
            state.isDeleting = true;
        },
        deleteSimulationStatusSuccess: (state: SimulationStatusState, action: PayloadAction<SimulationStatusOut>) => {
            state.isDeleting = false;
            state.simulationStatus = action.payload;
        },
        deleteSimulationStatusError: (state: SimulationStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetSimulationStatuss: (state: SimulationStatusState) => {
            state.simulationStatuss = initialState.simulationStatuss;
        },
        resetSimulationStatus: (state: SimulationStatusState) => {
            state.simulationStatus = initialState.simulationStatus;
        }
    }
});

export const { fetchSimulationStatuss, fetchSimulationStatussSuccess, fetchSimulationStatussError, fetchSimulationStatusById, fetchSimulationStatusByIdSuccess, fetchSimulationStatusByIdError,
    createSimulationStatus, createSimulationStatusSuccess, createSimulationStatusError, updateSimulationStatus, updateSimulationStatusSuccess, updateSimulationStatusError,
    deleteSimulationStatus, deleteSimulationStatusSuccess, deleteSimulationStatusError, resetSimulationStatuss, resetSimulationStatus } = simulationStatusSlice.actions;

export const isLoadingSimulationStatus = (state: RootState): boolean => state.simulationStatusReducer.isLoadingSimulationStatus;
export const isLoadingSimulationStatuss = (state: RootState): boolean => state.simulationStatusReducer.isLoadingSimulationStatuss;
export const isUpdatingSimulationStatus = (state: RootState): boolean => state.simulationStatusReducer.isUpdating;
export const isDeletingSimulationStatus = (state: RootState): boolean => state.simulationStatusReducer.isDeleting;
export const isCreatingSimulationStatus = (state: RootState): boolean => state.simulationStatusReducer.isCreating;
export const getSimulationStatuss = (state: RootState): Pagination<SimulationStatusOut> => state.simulationStatusReducer.simulationStatuss;
export const getSimulationStatus = (state: RootState): SimulationStatusOut => state.simulationStatusReducer.simulationStatus;

export default simulationStatusSlice.reducer;