import { Tooltip } from '@mui/material';
import React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { HelpOutline } from '@mui/icons-material';

interface LabelWithHelptextProps {
    message: MessageDescriptor;
    helptext: MessageDescriptor;
}

const LabelWithHelptext = ({ message, helptext } : LabelWithHelptextProps): JSX.Element => {
    const { formatMessage } = useIntl();

    return <span>
        <FormattedMessage {...message} />
        <Tooltip title={formatMessage(helptext)} style={{ position: 'absolute',
            marginLeft: 5 }}>
            <HelpOutline color="secondary" />
        </Tooltip>
    </span>;
};

export default LabelWithHelptext;