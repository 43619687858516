import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceLineOut from '../interfaces/output/invoiceLineOut';

export type InvoiceLineState = {
    invoiceLine: InvoiceLineOut;
    isLoadingInvoiceLine: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreatingLine: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: InvoiceLineState = {
    invoiceLine: {} as InvoiceLineOut,
    isLoadingInvoiceLine: false,
    isUpdating: false,
    isDeleting: false,
    isCreatingLine: false,
    error: undefined
};

export const invoiceLineSlice = createSlice({
    name: 'invoiceLine',

    initialState,
    reducers: {
        fetchInvoiceLineById: (state: InvoiceLineState, _action: PayloadAction<number>) => {
            state.isLoadingInvoiceLine = true;
        },
        fetchInvoiceLineByIdSuccess: (state: InvoiceLineState, action: PayloadAction<InvoiceLineOut>) => {
            state.isLoadingInvoiceLine = false;
            state.invoiceLine = action.payload;
        },
        fetchInvoiceLineByIdError: (state: InvoiceLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingInvoiceLine = false;
            state.error = action.payload;
        },
        createInvoiceLine: (state: InvoiceLineState, _action: PayloadAction<InvoiceLineOut>) => {
            state.isCreatingLine = true;
        },
        createInvoiceLineSuccess: (state: InvoiceLineState, action: PayloadAction<InvoiceLineOut>) => {
            state.isCreatingLine = false;
            state.invoiceLine = action.payload;
        },
        createInvoiceLineError: (state: InvoiceLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingLine = false;
            state.error = action.payload;
        },
        updateInvoiceLine: (state: InvoiceLineState, _action: PayloadAction<InvoiceLineOut>) => {
            state.isUpdating = true;
        },
        updateInvoiceLineSuccess: (state: InvoiceLineState, action: PayloadAction<InvoiceLineOut>) => {
            state.isUpdating = false;
            state.invoiceLine = action.payload;
        },
        updateInvoiceLineError: (state: InvoiceLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateInvoiceLine: (state: InvoiceLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateInvoiceLineSuccess: (state: InvoiceLineState, action: PayloadAction<InvoiceLineOut>) => {
            state.isDeleting = false;
            state.invoiceLine = action.payload;
        },
        deactivateInvoiceLineError: (state: InvoiceLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetInvoiceLine: (state: InvoiceLineState) => {
            state.invoiceLine = initialState.invoiceLine;
        }
    }
});

export const { fetchInvoiceLineById, fetchInvoiceLineByIdSuccess, fetchInvoiceLineByIdError, updateInvoiceLine, updateInvoiceLineSuccess, updateInvoiceLineError, deactivateInvoiceLine,
    deactivateInvoiceLineError, deactivateInvoiceLineSuccess, resetInvoiceLine, createInvoiceLine, createInvoiceLineError, createInvoiceLineSuccess } = invoiceLineSlice.actions;

export const isLoadingInvoiceLine = (state: RootState): boolean => state.invoiceLineReducer.isLoadingInvoiceLine;
export const isUpdatingInvoiceLine = (state: RootState): boolean => state.invoiceLineReducer.isUpdating;
export const isDeletingInvoiceLine = (state: RootState): boolean => state.invoiceLineReducer.isDeleting;
export const isCreatingInvoiceLine = (state: RootState): boolean => state.invoiceLineReducer.isCreatingLine;
export const getInvoiceLine = (state: RootState): InvoiceLineOut => state.invoiceLineReducer.invoiceLine;

export default invoiceLineSlice.reducer;