import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import HelptextOut from '../interfaces/output/helptextOut';
import HelptextApi from '../api/helptextApi';
import { fetchHelptextByIdError, fetchHelptextByIdSuccess, fetchHelptextsError, fetchHelptextsSuccess } from '../reducers/helptextReducer';

function* doFetchHelptext(action: PayloadAction<string>) {
    const helptextId = action.payload;

    try {
        const response: HelptextOut = yield call(HelptextApi.fetchHelptextById, helptextId);
        yield put(fetchHelptextByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchHelptextByIdError(error as HttpErrorResponse));
    }
}

function* doFetchHelptexts(action: PayloadAction<{ paging: Paging }>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<HelptextOut> = yield call(HelptextApi.fetchHelptexts, paging);
        yield put(fetchHelptextsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchHelptextsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('helptext/fetchHelptextById', doFetchHelptext),
    takeLatest('helptext/fetchHelptexts', doFetchHelptexts)
];

