import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import OrderUnitService from '../../../interfaces/output/orderUnitService';
import { fetchOUSStatuses, getOUSStatuses, isLoadingOUSServiceStatuses } from '../../../reducers/ousStatusReducer';
import { isUpdatingOrderUnitService } from '../../../reducers/unitServiceReducer';
import { isLoadingOrderUnitServices } from '../../../reducers/orderUnitReducer';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import OUSStatus from '../../../interfaces/output/ousStatus';
import { StatusCodes } from '../../../constants/statusConstants';

interface AddOrderUnitServiceStep3Props {
    orderUnitService: OrderUnitService;
    onChange: any;
}

const messages = {
    serviceStatus: LanguageUtils.createMessage('OUS status')
};

const AddOrderUnitServiceStep3 = forwardRef((props: AddOrderUnitServiceStep3Props, ref: any): JSX.Element => {
    const { orderUnitService, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const [serviceStatuses, setServiceStatuses] = React.useState<Array<OUSStatus>>();
    const propIsLoadingOUSServiceStatuses = useSelector(isLoadingOUSServiceStatuses);
    const prevIsLoadingOUSServiceStatuses = WorkflowUtils.usePrevious<boolean>(propIsLoadingOUSServiceStatuses);
    const isLoadingObjects = [useSelector(isLoadingOUSServiceStatuses), useSelector(isUpdatingOrderUnitService), useSelector(isLoadingOrderUnitServices)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const propServiceStatuses = useSelector(getOUSStatuses).content;
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchOUSStatuses({ paging }));
    }, [dispatch]);

    //Change made based https://jira.computas.com/browse/CAPRI-243
    useEffect(() => {
        if(prevIsLoadingOUSServiceStatuses === true && !propIsLoadingOUSServiceStatuses) {
            setServiceStatuses(propServiceStatuses);
        }
    }, [propIsLoadingOUSServiceStatuses]);

    useEffect(() => {
        if(orderUnitService !== null && orderUnitService !== undefined && propServiceStatuses !== undefined) {
            if(orderUnitService?.ousStatus?.code === StatusCodes.InProgress) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.InProgress ||
                     status?.code === StatusCodes.OnHold || status?.code === StatusCodes.Free || status?.code === StatusCodes.Cancelled));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.Ready) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.Ready ||
                     status?.code === StatusCodes.InProgress || status?.code === StatusCodes.OnHold || status?.code === StatusCodes.Free || status?.code === StatusCodes.Cancelled));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.OnHold) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.OnHold ||
                     status?.code === StatusCodes.InProgress || status?.code === StatusCodes.Free || status?.code === StatusCodes.Cancelled));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.Invoiced) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.Invoiced));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.Cancelled) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.Cancelled ||
                     status?.code === StatusCodes.InProgress || status?.code === StatusCodes.Free || status?.code === StatusCodes.OnHold));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.Credited) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.Credited));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.Failed) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.Failed ||
                    status?.code === StatusCodes.Free || status?.code === StatusCodes.Cancelled));
            }
            if(orderUnitService?.ousStatus?.code === StatusCodes.Free) {
                setServiceStatuses(propServiceStatuses.filter(status => status?.code === StatusCodes.Free ||
                    status?.code === StatusCodes.InProgress || status?.code === StatusCodes.OnHold || status?.code === StatusCodes.Cancelled));
            }
        }
    }, [orderUnitService]);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<OUSStatus>
                            options={serviceStatuses}
                            value={orderUnitService.ousStatus}
                            onChange={(obj: OUSStatus | null) => onChange('ousStatus', obj)}
                            placeholder={messages.serviceStatus}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddOrderUnitServiceStep3;