import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import GtsOrder from '../interfaces/output/gtsOrder';
import GtsOrderFilters from '../interfaces/filters/gtsOrderFilters';
import InvoiceSeparation from '../interfaces/output/invoiceSeparation';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ImportGtsOrderFilters from '../interfaces/filters/importGtsOrderFilters';

export type GtsOrderState = {
    gtsOrder: GtsOrder;
    isLoading: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    isImporting: boolean;
    isLoadingOrder: boolean;
    gtsOrders: Pagination<GtsOrder>;
    error: HttpErrorResponse | undefined;
    isImportingStaging: boolean;
};

const initialState: GtsOrderState = {
    gtsOrder: {} as GtsOrder,
    gtsOrders: { content: [] as Array<GtsOrder> } as Pagination<GtsOrder>,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isLoadingOrder: false,
    isImporting: false,
    error: undefined,
    isImportingStaging: false
};

export const gtsOrderSlice = createSlice({
    name: 'gtsOrder',

    initialState,
    reducers: {
        fetchGtsOrders: (state: GtsOrderState, _action: PayloadAction<{paging: Paging, filters: GtsOrderFilters}>) => {
            state.isLoading = true;
        },
        fetchGtsOrdersSuccess: (state: GtsOrderState, action: PayloadAction<Pagination<GtsOrder>>) => {
            state.isLoading = false;
            state.gtsOrders = action.payload;
        },
        fetchGtsOrdersError: (state: GtsOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchGtsOrderById: (state: GtsOrderState, _action: PayloadAction<number>) => {
            state.isLoadingOrder = true;
        },
        fetchGtsOrderByIdSuccess: (state: GtsOrderState, action: PayloadAction<GtsOrder>) => {
            state.isLoadingOrder = false;
            state.gtsOrder = action.payload;
        },
        fetchGtsOrderByIdError: (state: GtsOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrder = false;
            state.error = action.payload;
        },
        createGtsOrder: (state: GtsOrderState, _action: PayloadAction<string>) => {
            state.isCreating = true;
        },
        createGtsOrderSuccess: (state: GtsOrderState, action: PayloadAction<GtsOrder>) => {
            state.isCreating = false;
            state.gtsOrder = action.payload;
        },
        createGtsOrderError: (state: GtsOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        processGtsOrder: (state: GtsOrderState, _action: PayloadAction<{orderNumber:string, filters:ImportGtsOrderFilters}>) => {
            state.isImporting = true;
        },
        processGtsOrderSuccess: (state: GtsOrderState, action: PayloadAction<GtsOrder>) => {
            state.isImporting = false;
        },
        processGtsOrderError: (state: GtsOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isImporting = false;
            state.error = action.payload;
        },
        processGtsOrderStaging: (state: GtsOrderState, _action: PayloadAction<string>) => {
            state.isImportingStaging = true;
        },
        processGtsOrderStagingSuccess: (state: GtsOrderState, action: PayloadAction<GtsOrder>) => {
            state.isImportingStaging = false;
        },
        processGtsOrderStagingError: (state: GtsOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isImportingStaging = false;
            state.error = action.payload;
        },
        updateGtsOrder: (state: GtsOrderState, _action: PayloadAction<GtsOrder>) => {
            state.isUpdating = true;
        },
        updateGtsOrderSuccess: (state: GtsOrderState, action: PayloadAction<GtsOrder>) => {
            state.isUpdating = false;
            state.gtsOrder = action.payload;
        },
        updateGtsOrderError: (state: GtsOrderState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetGtsOrders: (state: GtsOrderState) => {
            state.gtsOrders = initialState.gtsOrders;
        },
        resetGtsOrder: (state: GtsOrderState) => {
            state.gtsOrder = initialState.gtsOrder;
        }
    }
});

export const { fetchGtsOrders, fetchGtsOrdersSuccess, fetchGtsOrdersError, fetchGtsOrderById, fetchGtsOrderByIdSuccess, resetGtsOrders,createGtsOrder, createGtsOrderSuccess,
    fetchGtsOrderByIdError, createGtsOrderError, updateGtsOrder, updateGtsOrderError, updateGtsOrderSuccess, resetGtsOrder,
    processGtsOrder, processGtsOrderSuccess, processGtsOrderError,processGtsOrderStaging, processGtsOrderStagingSuccess, processGtsOrderStagingError } = gtsOrderSlice.actions;

export const isLoadingGtsOrder = (state: RootState): boolean => state.gtsOrderReducer.isLoadingOrder;
export const isLoadingGtsOrders = (state: RootState): boolean => state.gtsOrderReducer.isLoading;
export const isUpdatingGtsOrder = (state: RootState): boolean => state.gtsOrderReducer.isUpdating;
export const isCreatingGtsOrder = (state: RootState): boolean => state.gtsOrderReducer.isCreating;
export const isImportingGtsOrders = (state: RootState): boolean => state.gtsOrderReducer.isImporting;
export const isImportingGtsOrdersStaging = (state: RootState): boolean => state.gtsOrderReducer.isImportingStaging;
export const getGtsOrders = (state: RootState): Pagination<GtsOrder> => state.gtsOrderReducer.gtsOrders;
export const getGtsOrder = (state: RootState): GtsOrder => state.gtsOrderReducer.gtsOrder;

export default gtsOrderSlice.reducer;