import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetAgreementVersionSpecialPrice } from '../../../reducers/agreementVersionSpecialPriceReducer';
import AgreementVersionSpecialPriceForm from './agreementVersionSpecialPriceForm';

interface IAddAgreementVersionSpecialPriceProps {
    classes: any;
    theme: Theme;
    match: any;
    resetAgreementVersionSpecialPrice: any;
    priceListId?: number;
    agreementVersionSpecialPriceId?: number;
    agreementVersionId?: number;
    agreementId?: number;
    steps: Array<number>;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetAgreementVersionSpecialPrice: () => dispatch(resetAgreementVersionSpecialPrice())
});

const mapStoreToProps = (store: RootState, ownProps: IAddAgreementVersionSpecialPriceProps) => {
    return {
        agreementVersionSpecialPriceId: ownProps.match.params?.agreementVersionSpecialPriceId ? Number(ownProps.match.params?.agreementVersionSpecialPriceId) : undefined,
        agreementVersionId: ownProps.match.params?.agreementVersionId ? Number(ownProps.match.params?.agreementVersionId) : undefined,
        agreementId: ownProps.match.params?.agreementId ? Number(ownProps.match.params?.agreementId) : undefined
    };
};

class AddAgreementVersionSpecialPrice extends Component<IAddAgreementVersionSpecialPriceProps> {
    componentWillUnmount() {
        const { resetAgreementVersionSpecialPrice } = this.props;

        resetAgreementVersionSpecialPrice();
    }

    render() {
        const { classes, agreementVersionSpecialPriceId, agreementVersionId, steps, type, agreementId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AgreementVersionSpecialPriceForm agreementVersionSpecialPriceId={agreementVersionSpecialPriceId} agreementVersionId={agreementVersionId}
                            agreementId={agreementId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddAgreementVersionSpecialPrice));