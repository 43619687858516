import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import PricelistVersionProduct from '../../../interfaces/output/pricelistVersionProduct';
import { useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isCreatingPriceListVersionProduct, isLoadingPriceListVersionProduct, isUpdatingPriceListVersionProduct } from '../../../reducers/pricelistVersionProductReducer';
import GenericTextField from '../../common/widgets/genericTextField';
import { FormattedMessage } from 'react-intl';
import PricelistVersionProductStep4Validation from '../../pricelists/validation/pricelistVersionProductStep4Validation';

interface AddPricelistVersionProductStep4Props {
    pricelistVersionProduct: PricelistVersionProduct;
    onChange: (pricelistVersionProduct: PricelistVersionProduct) => void;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    BASE_PRICE: 'basePrice'
};

const messages = {
    basePrice: LanguageUtils.createMessage('Base price'),
    onHold: LanguageUtils.createMessage('On hold'),
    basePriceHelpText: LanguageUtils.createMessage('PRICE_LIST_VERSION_PRODUCT_BASE_PRICE_HELPTEXT')
};

const AddPricelistVersionProductStep4 = forwardRef((props: AddPricelistVersionProductStep4Props, ref: any): JSX.Element => {
    const { pricelistVersionProduct, onChange, showValidationError } = props;
    const isLoadingObjects = [useSelector(isCreatingPriceListVersionProduct), useSelector(isUpdatingPriceListVersionProduct), useSelector(isLoadingPriceListVersionProduct)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.BASE_PRICE: {
                isValid = PricelistVersionProductStep4Validation.validateInputNumber(pricelistVersionProduct.basePrice);
                break;
            }

        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="basePrice"
                            type="number"
                            label={messages.basePrice}
                            value={pricelistVersionProduct.basePrice}
                            onChange={(_key: string, value: number | undefined) => onChange({ ...pricelistVersionProduct,
                                basePrice: value })}
                            error={setValidationState(ATTRIBUTES.BASE_PRICE)}
                            required
                            helperText={messages.basePriceHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FormControlLabel
                            control={<Checkbox checked={!!pricelistVersionProduct.onHold}
                                onChange={(e: any, value: boolean) => onChange({ ...pricelistVersionProduct,
                                    onHold: value })}
                            />}
                            label={<FormattedMessage {...messages.onHold} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddPricelistVersionProductStep4;