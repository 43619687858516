import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import ServiceFilters from '../../interfaces/output/serviceFilters';
import Service from '../../interfaces/output/service';
import { fetchServiceOverviewPdf, fetchServices, getServices, isLoadingServices, resetServices } from '../../reducers/serviceReducer';
import ServicesList from './servicesList';
import ServicesFilterComponent from './servicesFilterComponent';
import { fetchUnitGroups } from '../../reducers/unitGroupReducer';
import { fetchTerminals, isLoadingTerminal } from '../../reducers/terminalReducer';
import { fetchServiceTypes, isLoadingServiceTypes } from '../../reducers/serviceTypeReducer';
import { fetchServiceStatuses, isLoadingServiceStatuses } from '../../reducers/serviceStatusReducer';
import { fetchImportExportCodes, isLoadingImportExportCodes } from '../../reducers/importExportCodeReducer';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import UrlConstants from '../../constants/UrlConstants';
import ServiceOverviewPdfFilter from './exports/serviceOverviewPdfFilteComponent';
import ServiceOverviewFilters from '../../interfaces/filters/serviceOverviewPdfFilter';

interface IServicesProps {
    services: Pagination<Service>;
    classes: any;
    fetchServices: any;
    isLoading: boolean;
    onChange: () => void;
    resetServices: () => void;
    fetchImportExportCodes: any;
    fetchServiceOverviewPdf: any;
}

interface IServicesState {
    searchTerm: string;
    filters: ServiceFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
    showPdfServiceOverview: boolean;
    filtersServiceOverview: ServiceOverviewFilters
}

const messages = {
    services: LanguageUtils.createMessage('Services'),
    add: LanguageUtils.createMessage('Add'),
    export: LanguageUtils.createMessage('Export Service Overview')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchServices: (paging: Paging, filters: ServiceFilters) => dispatch(fetchServices({
        paging,
        filters
    })),
    fetchUnitGroups: (paging: Paging) => dispatch(fetchUnitGroups({ paging })),
    fetchTerminals: (paging: Paging) => dispatch(fetchTerminals({ paging })),
    fetchServiceTypes: (paging: Paging) => dispatch(fetchServiceTypes({ paging })),
    fetchServiceStatuses: (paging: Paging) => dispatch(fetchServiceStatuses({ paging })),
    resetServices: () => dispatch(resetServices()),
    fetchImportExportCodes: (paging: Paging) => dispatch(fetchImportExportCodes({ paging })),
    fetchServiceOverviewPdf: (filters: ServiceOverviewFilters) => dispatch(fetchServiceOverviewPdf({
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        services: getServices(store),
        isLoading: isLoadingServices(store),
        isLoadingFilters: isLoadingTerminal(store) || isLoadingServiceTypes(store) || isLoadingServiceStatuses(store) || isLoadingImportExportCodes(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class Services extends Component<IServicesProps, IServicesState> {
    serviceListRef: any;
    constructor(props: IServicesProps) {
        super(props);
        this.serviceListRef = React.createRef();

        this.state = {
            searchTerm: '',
            filters: { serviceDescription: '' } as ServiceFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.services,
                icon: <FontAwesomeIcon icon="concierge-bell" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {},
            showPdfServiceOverview: false,
            filtersServiceOverview: { serviceDescription: '' } as ServiceOverviewFilters
        };
    }

    componentDidMount() {
        const { fetchServices } = this.props;
        const { paging, filters } = this.state;

        fetchServices(paging, filters);
    }

    componentWillUnmount() {
        const { resetServices } = this.props;
        resetServices();
    }

    applyFilters = () => {
        const { fetchServices } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchServices(newPagination, filters);

        if(this.serviceListRef !== null && this.serviceListRef !== undefined &&
            this.serviceListRef.current !== null && this.serviceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.serviceListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as ServiceFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }
    onChangeServiceOverviewFilter = (attribute: string, value: any) => {
        const { filtersServiceOverview } = this.state;

        const newFilter = { ...filtersServiceOverview } as ServiceOverviewFilters;
        (newFilter as any)[attribute] = value;

        this.setState({
            filtersServiceOverview: newFilter
        });
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchServices } = this.props;

        const newFilter = { ...filters } as ServiceFilters;
        (newFilter as any)['serviceDescription'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchServices(newPagination, newFilter);
    }

    setDefaultState = () => {
        const { fetchServices } = this.props;
        const { paging } = this.state;

        const newFilters = {
            serviceDescription: ''
        } as ServiceFilters;

        const newSortModel = [{
            field: 'serviceDescription',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        fetchServices(newPagination, newFilters);

        if(this.serviceListRef !== null && this.serviceListRef !== undefined &&
            this.serviceListRef.current !== null && this.serviceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.serviceListRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchServices } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchServices(newPaging, filters);

        }
    }
    toggleServiceOverviewPdf = () => {
        this.setState({
            showPdfServiceOverview: !this.state.showPdfServiceOverview,
            filtersServiceOverview: {} as ServiceOverviewFilters
        });
    }

    generatePdf = () => {
        const { filtersServiceOverview } = this.state;
        const { fetchServiceOverviewPdf } = this.props;

        fetchServiceOverviewPdf(filtersServiceOverview);
    }

    render() {
        const { classes, services, fetchServices, isLoading } = this.props;
        const { paging, filters, menuItem, searchTerm, columnVisibilityModel, showPdfServiceOverview, filtersServiceOverview } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ServiceOverviewPdfFilter filters={filtersServiceOverview}
                                    setOpenModal={this.toggleServiceOverviewPdf} open={showPdfServiceOverview}
                                    onChange={this.onChangeServiceOverviewFilter}
                                    generatePdf={this.generatePdf} />
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.export,
                                        icon: 'download',
                                        onClick: this.toggleServiceOverviewPdf
                                    },
                                    {
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.SERVICES}/add`
                                    }]}
                                >
                                    <ServicesFilterComponent applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <ServicesList rows={services} isLoading={isLoading}
                                    ref={this.serviceListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchServices(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Services));