import React, { useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import GenericStep from '../../../../interfaces/common/genericStep';
import { FormType } from '../../../../constants/constants';
import FactorSetCancellationLineOutput from '../../../../interfaces/output/factorSetCancellationLineOut';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericStepper from '../../../common/widgets/genericStepper';
import UrlConstants from '../../../../constants/UrlConstants';
import CancellationLineFormStep1 from './cancellationLineFormStep1';
import { createCancellationVersionLine, fetchFactorSetCancellationLineById, getFactorSetCancellationLine, isCreatingCancellationLine, isUpdatingCancellationLine, updateFactorSetCancellationLine }
    from '../../../../reducers/cancellationLineReducer';
import FactorSetCancellationLineStep1Validation from '../line/validations/factorSetCancellationLineStep1Validation';

interface IAddFactorSetCancellationLineFormProps {
    cancellationLineId?: number;
    factorSetCancellationId?: number;
    factorSetVersionId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit factor set cancellation line'),
    add: LanguageUtils.createMessage('Add factor set cancellation line')
};

export default function AddFactorSetCancellationLineForm(props: IAddFactorSetCancellationLineFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { cancellationLineId, factorSetCancellationId, steps, type, factorSetVersionId } = props;
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const [cancellationLine, setCancellationLine] = React.useState<FactorSetCancellationLineOutput>({ factorSetId: factorSetCancellationId,
        factor: 1,
        factorSetVersionId: factorSetVersionId } as FactorSetCancellationLineOutput);
    const propsCancellationLine = useSelector(getFactorSetCancellationLine);
    const propsIsUpdatingCancellationLine = useSelector(isUpdatingCancellationLine);
    const prevIsUpdatingCancellationLine = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingCancellationLine);
    const propsIsCreatingCancellationLine = useSelector(isCreatingCancellationLine);
    const prevIsCreatingCancellationLine = WorkflowUtils.usePrevious<boolean>(propsIsCreatingCancellationLine);
    
    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if((prevIsUpdatingCancellationLine === true && !propsIsUpdatingCancellationLine) || (prevIsCreatingCancellationLine === true && !propsIsCreatingCancellationLine)) {
                    setRedirectCondition(true);
                }
                break;
            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsCreatingCancellationLine, propsIsUpdatingCancellationLine]);
    
    useEffect(() => {
        if(cancellationLineId) {
            dispatch(fetchFactorSetCancellationLineById(cancellationLineId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsCancellationLine.id) {
            setCancellationLine(propsCancellationLine);
        }

    }, [propsIsUpdatingCancellationLine, propsCancellationLine.id]);

    const onChange = (attribute: string, value: any) => {
        const newLine = { ...cancellationLine } as FactorSetCancellationLineOutput;
        (newLine as any)[attribute] = value;

        setCancellationLine(newLine);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            content: <CancellationLineFormStep1 onChange={onChange} cancellationLine={cancellationLine} factorSetVersionId={factorSetVersionId} />,
            validationFn: () => FactorSetCancellationLineStep1Validation.validateFactorSetForm(cancellationLine),
            onNext: () => {
                if(!cancellationLine.id) {
                    dispatch(createCancellationVersionLine(cancellationLine));
                }
                else {
                    dispatch(updateFactorSetCancellationLine(cancellationLine));
                }
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.AddLine:
                return messages.add;
            case FormType.EditLine:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.AddLine:
                return cancellationLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSetCancellationId}/${cancellationLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSetCancellationId}/latest/lines`;
            case FormType.EditLine:
                return cancellationLine.factorSetVersionId ? `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSetCancellationId}/${cancellationLine.factorSetVersionId}/lines` :
                    `/${UrlConstants.FACTOR_SETS}/cancellation/${factorSetCancellationId}/latest/lines`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  