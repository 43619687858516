import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import SimulationProductOut from '../interfaces/output/simulationProductOut';
import SimulationProductApi from '../api/simulationProductApi';
import { createSimulationProductError, createSimulationProductSuccess, deleteSimulationProductError, deleteSimulationProductSuccess,
    fetchSimulationProductByIdError, fetchSimulationProductByIdSuccess, fetchSimulationProductsError, fetchSimulationProductsSuccess,
    updateSimulationProductError, updateSimulationProductSuccess } from '../reducers/simulationProductReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import SimulationProductFilters from '../interfaces/filters/simulationProductFilters';

function* doFetchSimulationProduct(action: PayloadAction<string>) {
    const simulationProductId = action.payload;
    try {
        const response: SimulationProductOut = yield call(SimulationProductApi.fetchSimulationProductById, simulationProductId);
        yield put(fetchSimulationProductByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationProductByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSimulationProducts(action: PayloadAction<{ paging: Paging, filters: SimulationProductFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<SimulationProductOut> = yield call(SimulationProductApi.fetchSimulationProducts, paging, filters);
        yield put(fetchSimulationProductsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationProductsError(error as HttpErrorResponse));
    }
}

function* doUpdateSimulationProduct(action: PayloadAction<SimulationProductOut>) {
    const simulationProduct = action.payload;

    try {
        const response: SimulationProductOut = yield call(SimulationProductApi.updateSimulationProduct, simulationProduct);

        yield put(updateSimulationProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateSimulationProductError(error as HttpErrorResponse));
    }
}

function* doCreateSimulationProduct(action: PayloadAction<SimulationProductOut>) {
    const simulationProduct = action.payload;

    try {
        const response: SimulationProductOut = yield call(SimulationProductApi.createSimulationProduct, simulationProduct);

        yield put(createSimulationProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(createSimulationProductError(error as HttpErrorResponse));
    }
}

function* doDeleteSimulationProduct(action: PayloadAction<SimulationProductOut>) {
    const simulationProduct = action.payload;
    
    try {
        const response: SimulationProductOut = yield call(SimulationProductApi.deleteSimulationProduct, simulationProduct);

        yield put(deleteSimulationProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteSimulationProductError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('simulationProduct/fetchSimulationProductById', doFetchSimulationProduct),
    takeLatest('simulationProduct/fetchSimulationProducts', doFetchSimulationProducts),
    takeLatest('simulationProduct/updateSimulationProduct', doUpdateSimulationProduct),
    takeLatest('simulationProduct/createSimulationProduct', doCreateSimulationProduct),
    takeLatest('simulationProduct/deleteSimulationProduct', doDeleteSimulationProduct)
];