import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import SimulationStatusOut from '../interfaces/output/simulationStatusOut';
import SimulationStatusApi from '../api/simulationStatusApi';
import { createSimulationStatusError, createSimulationStatusSuccess, deleteSimulationStatusError, deleteSimulationStatusSuccess,
    fetchSimulationStatusByIdError, fetchSimulationStatusByIdSuccess, fetchSimulationStatussError, fetchSimulationStatussSuccess,
    updateSimulationStatusError, updateSimulationStatusSuccess } from '../reducers/simulationStatusReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchSimulationStatus(action: PayloadAction<string>) {
    const simulationStatusId = action.payload;
    try {
        const response: SimulationStatusOut = yield call(SimulationStatusApi.fetchSimulationStatusById, simulationStatusId);
        yield put(fetchSimulationStatusByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationStatusByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSimulationStatuss(action: PayloadAction<{ paging: Paging, searchTerm: string}>) {
    const { paging, searchTerm } = action.payload;

    try {
        const response: Pagination<SimulationStatusOut> = yield call(SimulationStatusApi.fetchSimulationStatuss, paging, searchTerm);
        yield put(fetchSimulationStatussSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSimulationStatussError(error as HttpErrorResponse));
    }
}

function* doUpdateSimulationStatus(action: PayloadAction<SimulationStatusOut>) {
    const simulationStatus = action.payload;

    try {
        const response: SimulationStatusOut = yield call(SimulationStatusApi.updateSimulationStatus, simulationStatus);

        yield put(updateSimulationStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateSimulationStatusError(error as HttpErrorResponse));
    }
}

function* doCreateSimulationStatus(action: PayloadAction<SimulationStatusOut>) {
    const simulationStatus = action.payload;

    try {
        const response: SimulationStatusOut = yield call(SimulationStatusApi.createSimulationStatus, simulationStatus);

        yield put(createSimulationStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(createSimulationStatusError(error as HttpErrorResponse));
    }
}

function* doDeleteSimulationStatus(action: PayloadAction<SimulationStatusOut>) {
    const simulationStatus = action.payload;
    
    try {
        const response: SimulationStatusOut = yield call(SimulationStatusApi.deleteSimulationStatus, simulationStatus);

        yield put(deleteSimulationStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteSimulationStatusError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('simulationStatus/fetchSimulationStatusById', doFetchSimulationStatus),
    takeLatest('simulationStatus/fetchSimulationStatuss', doFetchSimulationStatuss),
    takeLatest('simulationStatus/updateSimulationStatus', doUpdateSimulationStatus),
    takeLatest('simulationStatus/createSimulationStatus', doCreateSimulationStatus),
    takeLatest('simulationStatus/deleteSimulationStatus', doDeleteSimulationStatus)
];