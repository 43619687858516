export default {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,

    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    CONFLICT: 409,
    UNPROCESSABLE_ENTITY: 422,
    TOO_MANY_REQUESTS: 429,
    
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 5030,

    I_AM_A_TEA_POT: 418
};