import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import { fetchGtsOrderUnitDangerousGoodsById, getGtsOrderUnitDangerousGood, isLoadingDangerousGood } from '../../reducers/gtsDangerousGoodsReducer';
import GTSOrderUnitDangerousGoods from '../../interfaces/output/gtsOrderUnitDangerousGoods';
import ViewGtsOrderUnitDangerousGoods from './viewUnitDangerousGoods';
import LanguageUtils from '../../utils/LanguageUtils';
import { fetchGtsOrderById, getGtsOrder, isLoadingGtsOrder } from '../../reducers/gtsOrderReducer';
import GtsOrder from '../../interfaces/output/gtsOrder';

interface IUnitDangerousGoodsDetailsProps {
    classes: any;
    theme: Theme;
    fetchGtsOrderUnitDangerousGoodsById: any;
    fetchGtsOrderById: any;
    gtsOrderUnitDangerousGoods: GTSOrderUnitDangerousGoods;
    gtsOrder: GtsOrder;
    match: any;
    history: any;
    location: any;
    isLoadingDangerousGood: boolean;
    isLoadingGtsOrder: boolean;
}

interface IUnitDangerousGoodsDetailsState {
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGtsOrderUnitDangerousGoodsById: (id: number) => dispatch(fetchGtsOrderUnitDangerousGoodsById(id)),
    fetchGtsOrderById: (orderId: number) => dispatch(fetchGtsOrderById(orderId))
});

const mapStoreToProps = (store: RootState) => {
    return {
        gtsOrderUnitDangerousGoods: getGtsOrderUnitDangerousGood(store),
        isLoadingDangerousGood: isLoadingDangerousGood(store),
        isLoadingGtsOrder: isLoadingGtsOrder(store),
        gtsOrder: getGtsOrder(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    subname: LanguageUtils.createMessage('GTS_ORDER_UNIT_DANGEROUS_GOODS_SUBNAME'),
    properties: LanguageUtils.createMessage('Properties')
};

class UnitDangerousGoodsDetails extends Component<IUnitDangerousGoodsDetailsProps, IUnitDangerousGoodsDetailsState> {
    constructor(props: IUnitDangerousGoodsDetailsProps) {
        super(props);

        this.state = {
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.UNIT_DANGEROUS_GOODS}/${props.match.params.gtsDangerousGoodsId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.UNIT_DANGEROUS_GOODS}/${props.match.params.gtsDangerousGoodsId}/properties`, props.location.pathname)
            }]),
            selectedRow: undefined
        };
    }

    componentDidMount() {
        const { fetchGtsOrderUnitDangerousGoodsById } = this.props;
        const { gtsDangerousGoodsId } = this.props.match.params;

        fetchGtsOrderUnitDangerousGoodsById(gtsDangerousGoodsId);
    }

    componentDidUpdate(prevProps: IUnitDangerousGoodsDetailsProps) {
        const { gtsOrderUnitDangerousGoods, fetchGtsOrderById } = this.props;

        if(gtsOrderUnitDangerousGoods !== prevProps.gtsOrderUnitDangerousGoods) {
            if(gtsOrderUnitDangerousGoods.gtsOrderId) {
                fetchGtsOrderById(gtsOrderUnitDangerousGoods.gtsOrderId);
            }
        }
    }

    getSelectedMenuItem = (state: IUnitDangerousGoodsDetailsState) => state.menuItems.find(item => item.isSelected);

    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { gtsDangerousGoodsId } = this.props.match.params;
        
        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.UNIT_DANGEROUS_GOODS}/${gtsDangerousGoodsId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }
            
            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, gtsOrderUnitDangerousGoods, isLoadingDangerousGood, gtsOrder, isLoadingGtsOrder } = this.props;
        const { menuItems } = this.state;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingDangerousGood || isLoadingGtsOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.GtsOrderUnitDangerousGoods}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    value: gtsOrderUnitDangerousGoods.orderNumber,
                    icon: <FontAwesomeIcon icon="skull-crossbones" size="1x" /> } as IMenuItem
                }
                routes={
                    [{
                        name: gtsOrder.orderNumber,
                        url: `${UrlConstants.GTS_ORDERS}/${gtsOrder.id}`
                    },{
                        name: gtsOrderUnitDangerousGoods.logicalUnitId,
                        url: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitDangerousGoods.gtsOrderUnitId}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.UNIT_DANGEROUS_GOODS}/:gtsDangerousGoodsId/properties`}>
                            <ViewGtsOrderUnitDangerousGoods gtsOrderUnitDangerousGoods={gtsOrderUnitDangerousGoods} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(UnitDangerousGoodsDetails));