import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import InvoiceFormatOutput from '../interfaces/output/invoiceFormatOutput';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class InvoiceFormatApi {
    static fetchInvoiceFormatById = async(id: string): Promise<InvoiceFormatOutput> => {
        const { data } = await http.get<InvoiceFormatOutput>(`invoiceformat/${id}`);

        return data;
    };

    static fetchInvoiceFormats = async(paging: Paging): Promise<Pagination<InvoiceFormatOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/invoiceformat');

        const { data } = await http.get<Pagination<InvoiceFormatOutput>>(url);

        return data;
    };
}
