import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import Product from '../../interfaces/output/product';
import {
    fetchProductById, getProduct, isCreatingProduct, isDeleted, isLoadingProduct, isUpdatingProduct, deactivateProduct, fetchProductDynamicsByProductId,
    getProductDynamics,
    isLoadingProductDynamics,
    deactivateProductDynamics,
    isDeletingProductDynamics
} from '../../reducers/productReducer';
import ViewProduct from './viewProduct';
import ProductDynamics from '../../interfaces/output/productDynamics';
import {
    fetchPricelistVersionProducts, getPricelistVersionProducts, isCreatingPriceListVersionProduct, isLoadingPriceListVersionProduct, isUpdatingPriceListVersionProduct,
    deactivatePricelistVersionProduct,
    isDeletingPriceListVersionProduct
} from '../../reducers/pricelistVersionProductReducer';
import PricelistVersionProductFilters from '../../interfaces/filters/pricelistVersionProductFilters';
import Paging from '../../interfaces/common/paging';
import PricelistVersionProduct from '../../interfaces/output/pricelistVersionProduct';
import Pagination from '../../interfaces/common/pagination';
import { ObjectType } from '../../constants/constants';
import PricelistVersionList from '../pricelists/pricelistVersion/pricelistVersionList';
import ProductDynamicList from '../pricelists/productDynamicList';
import LanguageUtils from '../../utils/LanguageUtils';
import { ensure } from '../../utils/arrayUtils';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IProductDetailsProps {
    classes: any;
    theme: Theme;
    fetchProductById: any;
    deactivateProduct: any;
    fetchProductDynamicsByProductId: any;
    fetchPricelistVersionProducts: any;
    deactivatePricelistVersionProduct: any;
    isDeletingProductDynamics: boolean;
    deactivateProductDynamics: (id: number) => void;
    product: Product;
    productDynamics: Pagination<ProductDynamics>;
    pricelistVersionProducts: Pagination<PricelistVersionProduct>;
    match: any;
    history: any;
    location: any;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isLoadingPriceListVersionProduct: boolean;
    isCreatingPriceListVersionProduct: boolean;
    isUpdatingPriceListVersionProduct: boolean;
    isDeletingPriceListVersionProduct: boolean;
    isLoadingProductDynamics: boolean;
    isLoading: boolean;
}

interface IProductDetailsState {
    product: Product;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverProductPagination: Paging;
    serverProductPriceListPagination: Paging;
    sortModelPriceList: GridSortModel;
    sortModelDynamics: GridSortModel;
    productDynamicColumnVisibilityModel: GridColumnVisibilityModel;
    priceListVersionColumnVisibilityModel: GridColumnVisibilityModel;

}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchProductById: (id: number) => dispatch(fetchProductById(id)),
    deactivateProduct: (product: Product) => dispatch(deactivateProduct(product)),
    fetchProductDynamicsByProductId: (productId: number, paging: Paging) => dispatch(fetchProductDynamicsByProductId({
        productId,
        paging
    })),
    deactivatePricelistVersionProduct: (id: number) => dispatch(deactivatePricelistVersionProduct(id)),
    deactivateProductDynamics: (id: number) => dispatch(deactivateProductDynamics(id)),
    fetchPricelistVersionProducts: (paging: Paging, filters: PricelistVersionProductFilters) => dispatch(fetchPricelistVersionProducts({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        product: getProduct(store),
        isUpdating: isUpdatingProduct(store),
        isDeleting: isDeleted(store),
        isCreating: isCreatingProduct(store),
        isLoading: isLoadingProduct(store),
        productDynamics: getProductDynamics(store),
        isLoadingPriceListVersionProduct: isLoadingPriceListVersionProduct(store),
        isLoadingProductDynamics: isLoadingProductDynamics(store),
        isUpdatingPriceListVersionProduct: isUpdatingPriceListVersionProduct(store),
        isCreatingPriceListVersionProduct: isCreatingPriceListVersionProduct(store),
        isDeletingPriceListVersionProduct: isDeletingPriceListVersionProduct(store),
        isDeletingProductDynamics: isDeletingProductDynamics(store),
        pricelistVersionProducts: getPricelistVersionProducts(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Deactivate'),
    properties: LanguageUtils.createMessage('Properties'),
    pricelists: LanguageUtils.createMessage('Price lists'),
    dynamics: LanguageUtils.createMessage('Dynamics short'),
    subname: LanguageUtils.createMessage('Product')
};

class ProductDetails extends Component<IProductDetailsProps, IProductDetailsState> {
    constructor(props: IProductDetailsProps) {
        super(props);

        this.state = {
            product: {} as Product,
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.PRODUCTS}/${props.match.params.productId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.PRODUCTS}/${props.match.params.productId}/properties`, props.location.pathname)
            },
            {
                id: 2,
                name: messages.dynamics,
                icon: <FontAwesomeIcon icon={['fab', 'microsoft']} size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.PRODUCTS}/${props.match.params.productId}/dynamics`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.PRODUCTS}/${props.match.params.productId}/dynamics`, props.location.pathname)
            },
            {
                id: 3,
                name: messages.pricelists,
                icon: <FontAwesomeIcon icon="tags" size="1x" transform="grow-4" />,
                url: `/${UrlConstants.PRODUCTS}/${props.match.params.productId}/pricelists`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.PRODUCTS}/${props.match.params.productId}/pricelists`, props.location.pathname)
            }]),
            selectedRow: undefined,
            serverProductPagination: PageUtils.getDefaultPaging(),
            serverProductPriceListPagination: PageUtils.getDefaultPaging(),
            sortModelDynamics: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelPriceList: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            productDynamicColumnVisibilityModel: {},
            priceListVersionColumnVisibilityModel: {
                pricelistVersion_factorSetDangerousGoods: false,
                pricelistVersion_factorSetCalendar: false,
                pricelistVersion_factorSetHours: false,
                pricelistVersion_factorSetCancellation: false,
                pricelistVersion_factorSetPriority: false,
                pricelistVersion_factorSetDeparture: false,
                pricelistVersion_factorSetWeight: false
            }
        };
    }

    componentDidMount() {
        const { fetchProductById } = this.props;
        const { productId } = this.props.match.params;

        fetchProductById(productId);
    }

    componentDidUpdate(prevProps: IProductDetailsProps) {
        const { product, history, isDeleting, isLoading, isUpdatingPriceListVersionProduct, isCreatingPriceListVersionProduct, isDeletingPriceListVersionProduct, isDeletingProductDynamics }
            = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);

        if(product !== prevProps.product) {
            this.setState({ product });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            const menuItems = this.state.menuItems.map((menuItem: IMenuItem) => {
                menuItem.isSelected = PageUtils.isPageSelected(menuItem.url, this.props.location.pathname);

                return menuItem;
            });

            this.setState({
                menuItems: menuItems,
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));
        }

        if(prevProps.isDeleting && !isDeleting) {
            history.push(`/${UrlConstants.PRODUCTS}`);
        }

        if(selectedMenuItem && (!isLoading && prevProps.isLoading ||
            !isUpdatingPriceListVersionProduct && prevProps.isUpdatingPriceListVersionProduct ||
            !isDeletingPriceListVersionProduct && prevProps.isDeletingPriceListVersionProduct ||
            !isCreatingPriceListVersionProduct && prevProps.isCreatingPriceListVersionProduct ||
            !isDeletingProductDynamics && prevProps.isDeletingProductDynamics)) {
            this.loadData(selectedMenuItem);
        }
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { productId } = this.props.match.params;
        const { fetchProductDynamicsByProductId, fetchPricelistVersionProducts } = this.props;
        const { serverProductPagination, serverProductPriceListPagination } = this.state;

        if(selectedMenuItem?.id === 2) {
            fetchProductDynamicsByProductId(productId, serverProductPagination );
        }

        if(selectedMenuItem?.id === 3) {
            fetchPricelistVersionProducts(serverProductPriceListPagination, { productId } as PricelistVersionProductFilters);
        }
    }

    getSelectedMenuItem = (state: IProductDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateProduct = () => {
        const { product } = this.state;
        const { deactivateProduct } = this.props;

        deactivateProduct(product);
    }

    deactivatePricelistVersionProduct = () => {
        const { selectedRow } = this.state;
        const { deactivatePricelistVersionProduct } = this.props;

        deactivatePricelistVersionProduct(selectedRow);
    }

    deactivateDynamics = () => {
        const { selectedRow } = this.state;
        const { deactivateProductDynamics } = this.props;

        deactivateProductDynamics(ensure(selectedRow));
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { productId } = this.props.match.params;

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.PRODUCTS}/${productId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateProduct
                    }
                ];
            }

            case 2: { //dynamics
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.PRODUCTS}/${productId}/add/dynamics`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [
                    {
                        href: `${UrlConstants.PRODUCTS}/${productId}/dynamics/${selectedRow}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateDynamics
                    }
                ];
            }

            case 3: { //pricelists
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.PRODUCTS}/${productId}/add/pricelistVersionProduct`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [
                    {
                        href: `${UrlConstants.PRODUCTS}/${UrlConstants.PRICE_LIST_VERSION_PRODUCTS}/${selectedRow}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivatePricelistVersionProduct
                    }
                ];
            }

            default: {
                return [];
            }
        }
    }

    _onSortChangeDynamics = (newModel: GridSortModel) => {
        const { fetchProductDynamicsByProductId } = this.props;
        const { serverProductPagination, sortModelDynamics, product } = this.state;

        if(JSON.stringify(sortModelDynamics) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverProductPagination,
                sort: newModel
            };
            this.setState({
                sortModelDynamics: newModel,
                serverProductPagination: newPagination
            });
            fetchProductDynamicsByProductId(product?.id, newPagination);
        }
    }

    _onSortChangePriceList = (newModel: GridSortModel) => {
        const { fetchPricelistVersionProducts } = this.props;
        const { serverProductPriceListPagination, sortModelPriceList, product } = this.state;
        const productId = product.id;

        if(JSON.stringify(sortModelPriceList) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverProductPriceListPagination,
                sort: newModel
            };
            this.setState({
                sortModelPriceList: newModel,
                serverProductPriceListPagination: newPagination
            });

            fetchPricelistVersionProducts(
                newPagination,
                { productId } as PricelistVersionProductFilters
            );
        }
    }

    render() {
        const { classes, isLoading, productDynamics, isLoadingPriceListVersionProduct, isLoadingProductDynamics,
            pricelistVersionProducts, fetchProductDynamicsByProductId, fetchPricelistVersionProducts, isDeletingProductDynamics } = this.props;
        const { product, menuItems, serverProductPagination, serverProductPriceListPagination, priceListVersionColumnVisibilityModel, productDynamicColumnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoading}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.Product}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: product.name,
                    icon: <FontAwesomeIcon icon="cube" size="1x" />
                } as IMenuItem}
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.PRODUCTS}/:productId/properties`}>
                            <ViewProduct product={product} />
                        </Route>
                        <Route path={`/${UrlConstants.PRODUCTS}/:productId/dynamics`}>
                            <ProductDynamicList
                                isLoading={isLoadingProductDynamics || isDeletingProductDynamics}
                                rows={productDynamics} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverProductPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchProductDynamicsByProductId(product?.id, newPage);
                                    this.setState({ serverProductPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeDynamics(sortModel)}
                                columnVisibilityModel={productDynamicColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        productDynamicColumnVisibilityModel: newModel
                                    })
                                }
                                
                            />
                        </Route>
                        <Route path={`/${UrlConstants.PRODUCTS}/:productId/pricelists`}>
                            <PricelistVersionList
                                isLoading={isLoadingPriceListVersionProduct} rows={pricelistVersionProducts} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                prefix={'pricelistVersion_'}
                                secondPrefix={'pricelistVersion_pricelist_'}
                                hiddenColumns={['factorSetDangerousGoods', 'factorSetCalendar', 'factorSetHours', 'factorSetCancellation', 'factorSetPriority', 'factorSetDeparture',
                                    'factorSetWeight']}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverProductPriceListPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchPricelistVersionProducts(
                                        newPage,
                                        { productId: product.id } as PricelistVersionProductFilters
                                    );
                                    this.setState({ serverProductPriceListPagination: newPage });
                                }}
                                applyRowFn={(cellValues: any) => cellValues.row.pricelist?.version}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangePriceList(sortModel)}
                                columnVisibilityModel={priceListVersionColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        priceListVersionColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ProductDetails));