import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import PriceListVersion from '../interfaces/output/priceListVersions';
import { RootState } from '../setup';

export type PriceListVersionState = {
    priceListVersion: PriceListVersion;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingVersion: boolean;
    isCloningVersion: boolean;
    isUpdatingStatus: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: PriceListVersionState = {
    priceListVersion: {} as PriceListVersion,
    isLoading: false,
    isUpdating: false,
    isUpdatingVersion: false,
    isUpdatingStatus: false,
    isCloningVersion: false,
    error: undefined
};

export const priceListVersionSlice = createSlice({
    name: 'pricelistversion',

    initialState,
    reducers: {
        fetchPriceListVersionById: (state: PriceListVersionState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchPriceListVersionByIdSuccess: (state: PriceListVersionState, action: PayloadAction<PriceListVersion>) => {
            state.isLoading = false;
            state.priceListVersion = action.payload;
        },
        fetchPriceListVersionByIdError: (state: PriceListVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updatePriceListVersionStatus: (state: PriceListVersionState, action: PayloadAction<PriceListVersion>) => {
            state.isUpdatingStatus = true;
            state.priceListVersion = action.payload;
        },
        updatePriceListVersionStatusSuccess: (state: PriceListVersionState, action: PayloadAction<PriceListVersion>) => {
            state.isUpdatingStatus = false;
            state.priceListVersion = action.payload;
        },
        updatePriceListVersionStatusError: (state: PriceListVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingStatus = false;
            state.error = action.payload;
        },
        updatePriceListVersion: (state: PriceListVersionState, _action: PayloadAction<PriceListVersion>) => {
            state.isUpdatingVersion = true;
        },
        updatePriceListVersionSuccess: (state: PriceListVersionState, action: PayloadAction<PriceListVersion>) => {
            state.isUpdatingVersion = false;
            state.priceListVersion = action.payload;
        },
        updatePriceListVersionError: (state: PriceListVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingVersion = false;
            state.error = action.payload;
        },
        clonePriceListVersion: (state: PriceListVersionState, _action: PayloadAction<{toVersionId?: number, fromVersionId?: number}>) => {
            state.isCloningVersion = true;
        },
        clonePriceListVersionSuccess: (state: PriceListVersionState, action: PayloadAction<PriceListVersion>) => {
            state.isCloningVersion = false;
            state.priceListVersion = action.payload;
        },
        clonePriceListVersionError: (state: PriceListVersionState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCloningVersion = false;
            state.error = action.payload;
        },
        resetPricelistVersion: (state: PriceListVersionState) => {
            state.priceListVersion = initialState.priceListVersion;
        }
    }
});

export const { updatePriceListVersionStatus, updatePriceListVersionStatusSuccess, updatePriceListVersionStatusError, fetchPriceListVersionById, updatePriceListVersion, clonePriceListVersion,
    fetchPriceListVersionByIdSuccess, fetchPriceListVersionByIdError, updatePriceListVersionSuccess, updatePriceListVersionError, resetPricelistVersion,clonePriceListVersionSuccess,
    clonePriceListVersionError } = priceListVersionSlice.actions;

export const isLoadingPriceListVersion = (state: RootState): boolean => state.priceListVersionReducer.isLoading;
export const isCloningVersion = (state: RootState): boolean => state.priceListVersionReducer.isCloningVersion;
export const isUpdatingVersion = (state: RootState): boolean => state.priceListVersionReducer.isUpdatingVersion;
export const isUpdatingPricelistVersion = (state: RootState): boolean => state.priceListVersionReducer.isUpdating;
export const isUpdatingPriceListStatus = (state: RootState): boolean => state.priceListVersionReducer.isUpdatingStatus;
export const getPriceListVersion = (state: RootState): PriceListVersion => state.priceListVersionReducer.priceListVersion;

export default priceListVersionSlice.reducer;