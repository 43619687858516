import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import TableUtils from '../../utils/tableUtils';
import DataGridComponent from '../common/dataGrid';
import UrlConstants from '../../constants/UrlConstants';
import CustomStringLink from '../common/customStringLink';
import HelptextConstantsEnum from '../../constants/helptextConstantsEnum';

interface TerminalListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    gtsTerminalNo: LanguageUtils.createMessage('Gts terminal number'),
    gtsTerminalCode: LanguageUtils.createMessage('Gts terminal code'),
    country: LanguageUtils.createMessage('Country'),
    terminalOperator: LanguageUtils.createMessage('Terminal operator'),
    terminalResponsabilityCenter: LanguageUtils.createMessage('Terminal responsability center')
};

const TerminalList = forwardRef((props: TerminalListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.TERMINALS);
        }
    }, []);
  
    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'name',
                headerName: `${intl.formatMessage(messages.name)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).name} link={`/#/${UrlConstants.TERMINALS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('name', hiddenColumns)
            },
            {
                field: 'gtsTerminalNo',
                headerName: `${intl.formatMessage(messages.gtsTerminalNo)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).gtsTerminalNo} link={`/#/${UrlConstants.TERMINALS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('gtsTerminalNo', hiddenColumns)
            },
            {
                field: 'gtsTerminalCode',
                headerName: `${intl.formatMessage(messages.gtsTerminalCode)}`,
                flex: 1,
                sortable: true,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return <CustomStringLink value={applyRowFn(cellValues).gtsTerminalCode} link={`/#/${UrlConstants.TERMINALS}/${applyRowFn(cellValues).id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('gtsTerminalCode', hiddenColumns)
            },
            {
                field: 'country_name',
                headerName: `${intl.formatMessage(messages.country)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.country?.name ?? '-'}
                    link={`/#/${UrlConstants.TERMINALS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('country_name', hiddenColumns)
            },
            {
                field: 'operator_name',
                headerName: `${intl.formatMessage(messages.terminalOperator)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.operator?.name ?? '-'}
                    link={`/#/${UrlConstants.TERMINALS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('operator_name', hiddenColumns)
            },
            {
                field: 'd365TerminalAnsvarsted_name',
                headerName: `${intl.formatMessage(messages.terminalResponsabilityCenter)}`,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <CustomStringLink value={applyRowFn(params)?.terminalResponsabilityCenter?.name ?? '-'}
                    link={`/#/${UrlConstants.TERMINALS}/${applyRowFn(params).id}`} />,
                hide: hiddenColumns && TableUtils.hideColumn('d365TerminalAnsvarsted_name', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.id + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default TerminalList;
