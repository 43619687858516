import { http } from '../utils/http';
import Segment from '../interfaces/output/customerSegment';
import Pagination from '../interfaces/common/pagination';

export default class SegmentsApi {
    static fetchSegmentById = async(segmentId: string): Promise<Segment> => {
        const { data } = await http.get<Segment>(`/customersegment/${segmentId}`);

        return data;
    };

    static fetchSegments = async(): Promise<Pagination<Segment>> => {
        const { data } = await http.get<Pagination<Segment>>('/customersegment');

        return data;
    };
}
