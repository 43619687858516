import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme, Button } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import Order from '../../interfaces/output/order';
import AccordionSummary from '../common/accordionSummary';
import DisplayStatus from '../common/displayStatus';
import { fetchUnitServicesByOrderId, isLoadingUnitServices, getUnitServices, isLoadingFindOrderPrice, fetchOrderById } from '../../reducers/orderReducer';
import { ensure } from '../../utils/arrayUtils';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import Paging from '../../interfaces/common/paging';
import UnitServiceList from '../orderUnits/orderUnitServiceList';
import NullableFormattedDate from '../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UrlConstants from '../../constants/UrlConstants';

interface IViewOrderProps {
    classes?: any;
    theme?: Theme;
    order: Order;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        },
        link: {
            textDecoration: 'none',
            color: `${theme.palette.info.main}!important`,
            fontSize: '12px!important'
        },
        linkItemGrid: {
            marginLeft: '10px!important'
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Order details'),
    orderNo: LanguageUtils.createMessage('Order number'),
    basedOnOrderNumber: LanguageUtils.createMessage('Based on order number'),
    customer: LanguageUtils.createMessage('Customer'),
    receivedFromGTS: LanguageUtils.createMessage('Received from gts'),
    errorMessage: LanguageUtils.createMessage('Error message'),
    unitGroupCode: LanguageUtils.createMessage('Unit group code'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    status: LanguageUtils.createMessage('Status'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    orderDate: LanguageUtils.createMessage('Order date'),
    loadUnits: LanguageUtils.createMessage('Load units'),
    totalPrice: LanguageUtils.createMessage('Total price'),
    unitServices: LanguageUtils.createMessage('ORDER_UNIT_SERVICES'),
    id: LanguageUtils.createMessage('Internal number'),
    goToOrder: LanguageUtils.createMessage('Go to order')
};

export default function ViewOrder(props: IViewOrderProps): JSX.Element {
    const classes = styles();
    const { order } = props;
    const history = useHistory();
    const { formatMessage } = useIntl();
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const propsIsLoadingFindOrderPrice = useSelector(isLoadingFindOrderPrice);
    const isLoadingObjects = [useSelector(isLoadingUnitServices), useSelector(isLoadingFindOrderPrice)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const propsOrderUnitServices = useSelector(getUnitServices);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: 'unitNo',
        sort: 'asc' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        if(order.id) {
            dispatch(fetchUnitServicesByOrderId({
                paging: paging,
                id: ensure(order.id)
            }));
        }
    }, [order.id, propsIsLoadingFindOrderPrice]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...paging,
                sort: newModel
            };

            setPaging(newPagination);
            setSortModel(newModel);

            dispatch(fetchUnitServicesByOrderId({
                id: ensure(order.id),
                paging: newPagination
            }));
        }
    };

    const _reloadRoute = () => {
        if(order.basedOnOrderId) {
            dispatch(fetchOrderById(order.basedOnOrderId));
            history.push(`/${UrlConstants.ORDERS}/${order?.basedOnOrderId}/properties`);
        }
    };

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.id} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.id || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.orderNo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.orderNumber || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.basedOnOrderNumber} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography gutterBottom>{order.basedOnOrderId ? `${order.basedOnOrderNumber}(${order.basedOnOrderId})` : '-'}</Typography>
                                        </Grid>
                                        {order && order.basedOnOrderId &&
                                            <Grid item className={classes.linkItemGrid}>
                                                <BrowserRouter forceRefresh={true}>
                                                    <Button onClick={_reloadRoute}
                                                        title={formatMessage(messages.goToOrder)} className={classes.link}>
                                                        <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                                    </Button>
                                                </BrowserRouter>
                                            </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.orderDate} />: </Typography>
                                </Grid>
                                <Grid item sm={9} xs={12} >
                                    <Typography gutterBottom>
                                        {<NullableFormattedDate value={order.orderDate} />}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.customer} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.customer?.customerNoName || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitGroupCode} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.unitGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.receivedFromGTS} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={order.timeReceivedFromGTS} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.fromTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.fromTerminal?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.toTerminal} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.toTerminal?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.loadUnits} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.orderUnitCounter || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.totalPrice} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.sumTotalPrice ?? '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.status} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <DisplayStatus status={order.status} />
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.errorMessage} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{order.errorMessage || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.unitServices} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <UnitServiceList isLoading={isLoading} rows={propsOrderUnitServices}
                                    prefix={'service_'}
                                    secondPrefix={'orderUnit_'}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        dispatch(fetchUnitServicesByOrderId({
                                            id: ensure(order.id),
                                            paging: newPage
                                        }));
                                        setPaging(newPage);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        setColumnVisibilityModel(newModel)
                                    }
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}