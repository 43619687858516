import React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ITruncatedStringTooltip {
    value: any;
}

const styles = makeStyles({

    tabelCellTruncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
});

export default function TruncatedStringTooltip(props: ITruncatedStringTooltip): JSX.Element {
    const { value } = props;
    const classes = styles();

    return (
        <Tooltip title={value} arrow
            componentsProps={{
                tooltip:
                {
                    sx:
                    {
                        fontSize: '1rem',
                        backgroundColor: 'white',
                        color: 'rgba(0, 0, 0, 0.87)',
                        boxShadow: 3
                    }
                }
            }}>
            <span className={classes.tabelCellTruncate}>{value}</span>
        </Tooltip >
    );
}
