import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import UnitType from '../interfaces/output/unitType';
import { RootState } from '../setup';

export type UnitTypeState = {
    isLoading: boolean;
    unitType: UnitType;
    unitTypes: Pagination<UnitType>;
    error: HttpErrorResponse | undefined;
};

const initialState: UnitTypeState = {
    unitType: {} as UnitType,
    unitTypes: { content: [] as Array<UnitType> } as Pagination<UnitType>,
    isLoading: false,
    error: undefined
};

export const unitTypeSlice = createSlice({
    name: 'unitType',

    initialState,
    reducers: {
        fetchUnitTypeById: (state: UnitTypeState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchUnitTypeByIdSuccess: (state: UnitTypeState, action: PayloadAction<UnitType>) => {
            state.isLoading = false;
            state.unitType = action.payload;
        },
        fetchUnitTypeByIdError: (state: UnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchUnitTypes: (state: UnitTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchUnitTypesSuccess: (state: UnitTypeState, action: PayloadAction<Pagination<UnitType>>) => {
            state.isLoading = false;
            state.unitTypes = action.payload;
        },
        fetchUnitTypesError: (state: UnitTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchUnitTypeById, fetchUnitTypeByIdSuccess, fetchUnitTypeByIdError, fetchUnitTypes, fetchUnitTypesSuccess, fetchUnitTypesError } = unitTypeSlice.actions;

export const isLoadingUnitTypes = (state: RootState): boolean => state.unitTypeReducer.isLoading;
export const getUnitType = (state: RootState): UnitType => state.unitTypeReducer.unitType;
export const getUnitTypes = (state: RootState): Pagination<UnitType> => state.unitTypeReducer.unitTypes;

export default unitTypeSlice.reducer;