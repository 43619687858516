import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
// import LanguageUtils from '../../../utils/LanguageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Paging from '../../../interfaces/common/paging';
import AgreementList from '../../agreements/agreementList';
import AgreementFilters from '../../../interfaces/filters/agreementFilters';
import { fetchAgreementById, fetchAgreements, getAgreement, getAgreements, isLoadingAgreement, isLoadingAgreements } from '../../../reducers/agreementsReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddOrderUnitServiceProductStep2Props {
    onChange: any;
}

const AddOrderUnitServiceProductStep2 = forwardRef((props: AddOrderUnitServiceProductStep2Props, ref: any): JSX.Element => {
    const { onChange } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const agreements = useSelector(getAgreements);
    const propsAgreement = useSelector(getAgreement);
    const propsLoadingAgreement = useSelector(isLoadingAgreement);
    const prevLoadingAgreement = WorkflowUtils.usePrevious<boolean>(propsLoadingAgreement);
    const isLoadingAgreementList = useSelector(isLoadingAgreements);
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
        revenueThisYear: false,
        discount: false,
        validFromDate: false,
        validToDate: false
    });

    useEffect(() => {
        dispatch(fetchAgreements({
            paging,
            filters: {} as AgreementFilters
        }));
    }, [dispatch]);

    useEffect(() => {
        if(prevLoadingAgreement === true && !propsLoadingAgreement) {
            onChange('agreement', propsAgreement);
        }
    }, [propsLoadingAgreement]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };
            
            dispatch(fetchAgreements({
                paging: newPaging,
                filters: { name: searchTerm } as AgreementFilters
            }));
            setPaging(newPaging);
            setSortModel(newModel);
           
        }
    };

    return (
        <Grid container spacing={1} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchAgreements({
                        paging: paging,
                        filters: { name: searchTerm } as AgreementFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <AgreementList
                    isLoading={isLoadingAgreementList}
                    rows={agreements}
                    hiddenColumns={['revenueThisYear', 'revenueLastYear', 'discount', 'validFromDate', 'validToDate']}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (event: any) => setSearchTerm(event.target.value),
                            clearSearch: () => setSearchTerm('')
                        }
                    }}
                    checkSelection={(value: number) => {
                        if(value) {
                            dispatch(fetchAgreementById({ id: value }));
                        }
                    }}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchAgreements({
                            paging: newPage,
                            filters: { name: searchTerm } as AgreementFilters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                />
            </Grid>
        </Grid>
    );
});

export default AddOrderUnitServiceProductStep2;