import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import SurchargeRate from '../interfaces/output/surchargeRate';
import SurchargeRateFilters from '../interfaces/filters/surchargeRateFilters';

export type SurchargeRateState = {
    surchargeRate: SurchargeRate;
    isLoadingSurchargeRate: boolean;
    isLoadingSurchargeRates: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    isCreating: boolean;
    surchargeRates: Pagination<SurchargeRate>;
    error: HttpErrorResponse | undefined;
};

const initialState: SurchargeRateState = {
    surchargeRate: {} as SurchargeRate,
    surchargeRates: { content: [] as Array<SurchargeRate> } as Pagination<SurchargeRate>,
    isLoadingSurchargeRate: false,
    isLoadingSurchargeRates: false,
    isUpdating: false,
    isDeleted: false,
    isCreating: false,
    error: undefined
};

export const surchargeRateSlice = createSlice({
    name: 'surchargeRate',

    initialState,
    reducers: {
        fetchSurchargeRates: (state: SurchargeRateState, _action: PayloadAction<{ paging: Paging, filters: SurchargeRateFilters }>) => {
            state.isLoadingSurchargeRates = true;
        },
        fetchSurchargeRatesSuccess: (state: SurchargeRateState, action: PayloadAction<Pagination<SurchargeRate>>) => {
            state.isLoadingSurchargeRates = false;
            state.surchargeRates = action.payload;
        },
        fetchSurchargeRatesError: (state: SurchargeRateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSurchargeRates = false;
            state.error = action.payload;
        },
        fetchSurchargeRateById: (state: SurchargeRateState, _action: PayloadAction<number>) => {
            state.isLoadingSurchargeRate = true;
        },
        fetchSurchargeRateByIdSuccess: (state: SurchargeRateState, action: PayloadAction<SurchargeRate>) => {
            state.isLoadingSurchargeRate = false;
            state.surchargeRate = action.payload;
        },
        fetchSurchargeRateByIdError: (state: SurchargeRateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSurchargeRate = false;
            state.error = action.payload;
        },
        createSurchargeRate: (state: SurchargeRateState, _action: PayloadAction<SurchargeRate>) => {
            state.isCreating = true;
        },
        createSurchargeRateSuccess: (state: SurchargeRateState, action: PayloadAction<SurchargeRate>) => {
            state.isCreating = false;
            state.surchargeRate = action.payload;
        },
        createSurchargeRateError: (state: SurchargeRateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateSurchargeRate: (state: SurchargeRateState, _action: PayloadAction<SurchargeRate>) => {
            state.isUpdating = true;
        },
        updateSurchargeRateSuccess: (state: SurchargeRateState, action: PayloadAction<SurchargeRate>) => {
            state.isUpdating = false;
            state.surchargeRate = action.payload;
        },
        updateSurchargeRateError: (state: SurchargeRateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteSurchargeRate: (state: SurchargeRateState, _action: PayloadAction<SurchargeRate>) => {
            state.isDeleted = true;
        },
        deleteSurchargeRateSuccess: (state: SurchargeRateState, action: PayloadAction<SurchargeRate>) => {
            state.isDeleted = false;
            state.surchargeRate = action.payload;
        },
        deleteSurchargeRateError: (state: SurchargeRateState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetSurchargeRates: (state: SurchargeRateState) => {
            state.surchargeRates = initialState.surchargeRates;
        },
        resetSurchargeRate: (state: SurchargeRateState) => {
            state.surchargeRate = initialState.surchargeRate;
        }
    }
});

export const { fetchSurchargeRateById, fetchSurchargeRateByIdError, fetchSurchargeRateByIdSuccess, fetchSurchargeRates,
    fetchSurchargeRatesError, fetchSurchargeRatesSuccess, updateSurchargeRateSuccess, updateSurchargeRateError, updateSurchargeRate,
    createSurchargeRate, createSurchargeRateError, createSurchargeRateSuccess, deleteSurchargeRate, deleteSurchargeRateError, deleteSurchargeRateSuccess,
    resetSurchargeRate, resetSurchargeRates } = surchargeRateSlice.actions;

export const isLoadingSurchargeRate = (state: RootState): boolean => state.surchargeRateReducer.isLoadingSurchargeRate;
export const isLoadingSurchargeRates = (state: RootState): boolean => state.surchargeRateReducer.isLoadingSurchargeRates;
export const getSurchargeRates = (state: RootState): Pagination<SurchargeRate> => state.surchargeRateReducer.surchargeRates;
export const getSurchargeRate = (state: RootState): SurchargeRate => state.surchargeRateReducer.surchargeRate;
export const isUpdatingSurchargeRate = (state: RootState): boolean => state.surchargeRateReducer.isUpdating;
export const isCreatingSurchargeRate = (state: RootState): boolean => state.surchargeRateReducer.isCreating;

export default surchargeRateSlice.reducer;