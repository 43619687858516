import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import SimulationOrderFilters from '../interfaces/filters/simulationOrderFilters';
import SimulationOrderOut from '../interfaces/output/simulationOrderOut';
import SimulationOrderIn from '../interfaces/input/simulationOrderIn';
import Pagination from '../interfaces/common/pagination';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class SimulationOrderApi {
    static fetchSimulationOrderById = async(simulationOrderId: string): Promise<SimulationOrderOut> => {
        const { data } = await http.get<SimulationOrderOut>(`simulationorder/${simulationOrderId}`);

        return data;
    };

    static fetchSimulationOrders = async(paging: Paging, filters: SimulationOrderFilters): Promise<Pagination<SimulationOrderOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/simulationOrder');

        const { data } = await http.get<Pagination<SimulationOrderOut>>(url);

        return data;
    };

    static createSimulationOrder = async(simulationOrder: SimulationOrderOut): Promise<SimulationOrderOut> => {
        const newSimulationOrder: SimulationOrderIn = {
            orderId: simulationOrder.order?.id,
            simulationId: simulationOrder.simulation?.id
        };

        const { data } = await http.post<SimulationOrderIn, SimulationOrderOut>('simulationOrder', newSimulationOrder);

        return data;
    }

    static updateSimulationOrder = async(simulationOrder: SimulationOrderOut): Promise<SimulationOrderOut> => {
        const newSimulationOrder: SimulationOrderIn = {
            orderId: simulationOrder.order?.id,
            simulationId: simulationOrder.simulation?.id
        };
        
        const { data } = await http.put<SimulationOrderIn, SimulationOrderOut>(`simulationorder/${simulationOrder.id}`, newSimulationOrder);

        return data;
    }

    static deleteSimulationOrder = async(simulationOrder: SimulationOrderOut): Promise<SimulationOrderOut> => {
        const { data } = await http.delete<SimulationOrderOut>(`simulationorder/${simulationOrder.id}`);

        return data;
    }
}