import React, { forwardRef } from 'react';
import { FormControl, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import CustomerAgreement from '../../interfaces/output/customerAgreement';
import { isCreatingBulk, isLoadingCustomerAgreement, isUpdatingCustomerAgreement } from '../../reducers/customerAgreementReducer';
import Loader from '../common/widgets/loader';
import GenericTextField from '../common/widgets/genericTextField';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import WorkflowUtils from '../../utils/workflowUtils';
import CustomerAgreementPriorityAndValidityValidation from './validation/customerAgreementPriorityAndValidityValidation';

interface AddCustomerAgreementStep3Props {
    customerAgreement: CustomerAgreement;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    PRIORITY_RANK: 'priorityRank',
    VALID_FROM: 'validFromDate',
    VALID_TO: 'validToDate'
};

const messages = {
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to'),
    priorityRank: LanguageUtils.createMessage('Priority rank')
};

const AddCustomerAgreementStep3 = forwardRef((props: AddCustomerAgreementStep3Props, ref: any): JSX.Element => {
    const { onChange, customerAgreement, showValidationError } = props;
    const isLoadingObjects = [useSelector(isCreatingBulk), useSelector(isUpdatingCustomerAgreement), useSelector(isLoadingCustomerAgreement)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
        
    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.PRIORITY_RANK: {
                isValid = CustomerAgreementPriorityAndValidityValidation.validateInputString(customerAgreement?.priorityRank?.toString());
                break;
            }
            case ATTRIBUTES.VALID_FROM: {
                isValid = CustomerAgreementPriorityAndValidityValidation.validateRequiredDate(customerAgreement.validFromDate) &&
                    CustomerAgreementPriorityAndValidityValidation.validateDateFormat(customerAgreement.validFromDate) &&
                    CustomerAgreementPriorityAndValidityValidation.validateInputDateStart(customerAgreement);
                break;
            }
            case ATTRIBUTES.VALID_TO: {
                isValid = CustomerAgreementPriorityAndValidityValidation.validateInputDateEnd(customerAgreement) &&
                CustomerAgreementPriorityAndValidityValidation.validateDateFormat(customerAgreement.validToDate);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericTextField<number>
                            name="priorityRank"
                            required
                            error={setValidationState(ATTRIBUTES.PRIORITY_RANK)}
                            label={messages.priorityRank}
                            type="number"
                            value={customerAgreement.priorityRank}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFrom}
                            required
                            error={setValidationState(ATTRIBUTES.VALID_FROM)}
                            value={customerAgreement?.validFromDate}
                            onChange={onChange}
                            type="start"
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validTo}
                            value={customerAgreement?.validToDate}
                            error={setValidationState(ATTRIBUTES.VALID_TO)}
                            onChange={onChange}
                            type="end"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default AddCustomerAgreementStep3;