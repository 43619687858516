import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import GTSOrderUnitDepot from '../../interfaces/output/gtsOrderUnitDepot';
import GenericStepper from '../common/widgets/genericStepper';
import { FormType } from '../../constants/constants';
import UrlConstants from '../../constants/UrlConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getGtsOrderUnitDepot, isUpdatingGtsOrderUnitDepot, fetchGtsOrderUnitDepotById, updateGtsOrderUnitDepot } from '../../reducers/gtsOrderUnitDepotLinesReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import GtsorderUnitDepotFormStep1 from './gtsorderUnitDepotFormStep1';
import GenericStep from '../../interfaces/common/genericStep';

interface IGtsOrderUnitDepotFormProps {
    depotId?: number;
    gtsOrderUnitId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit gts unit depot')
};

export default function GtsOrderUnitDepotForm(props: IGtsOrderUnitDepotFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { depotId, gtsOrderUnitId, steps, type } = props;
    const propsOrderUnitDepot = useSelector(getGtsOrderUnitDepot);
    const [orderUnitDepot, setOrderUnitDepot] = React.useState<GTSOrderUnitDepot>({ orderUnit: { id: gtsOrderUnitId } } as GTSOrderUnitDepot);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsUpdatingorderUnitDepot = useSelector(isUpdatingGtsOrderUnitDepot);
    const prevIsUpdatingorderUnitDepot = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingorderUnitDepot);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if(prevIsUpdatingorderUnitDepot === true && !propsIsUpdatingorderUnitDepot) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingorderUnitDepot]);

    useEffect(() => {
        if(depotId) {
            dispatch(fetchGtsOrderUnitDepotById(depotId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsOrderUnitDepot.id) {
            setOrderUnitDepot(propsOrderUnitDepot);
        }

    }, [propsIsUpdatingorderUnitDepot, propsOrderUnitDepot]);

    const onChangeUnitService = (attribute: string, value: any) => {
        const newOrderUnitDepot = { ...orderUnitDepot } as GTSOrderUnitDepot;
        (newOrderUnitDepot as any)[attribute] = value;

        setOrderUnitDepot(newOrderUnitDepot);
    };

    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: 'Edit details for gts order unit depot',
            content: <GtsorderUnitDepotFormStep1 onChange={onChangeUnitService} orderUnitDepot={orderUnitDepot} />,
            onNext: () => {
                dispatch(updateGtsOrderUnitDepot(orderUnitDepot));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.Edit:
                return `/${UrlConstants.UNIT_DEPOT}/${depotId}`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  