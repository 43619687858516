import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import ServiceFilters from '../../interfaces/output/serviceFilters';
import UnitGroup from '../../interfaces/output/unitGroup';
import Terminal from '../../interfaces/output/terminal';
import ServiceType from '../../interfaces/output/serviceType';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import { fetchUnitGroups, getUnitGroups } from '../../reducers/unitGroupReducer';
import { fetchTerminals, getTerminals } from '../../reducers/terminalReducer';
import { fetchServiceStatuses, getServiceStatuses } from '../../reducers/serviceStatusReducer';
import { fetchServiceTypes, getServiceTypes } from '../../reducers/serviceTypeReducer';
import { fetchImportExportCodes, getImportExportCodes } from '../../reducers/importExportCodeReducer';
import { FormattedMessage } from 'react-intl';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import ImportExportCode from '../../interfaces/output/importExportCode';
import ServiceStatus from '../../interfaces/output/serviceStatus';

interface IFilterProps {
    filters: ServiceFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    serviceType: LanguageUtils.createMessage('Service type'),
    serviceAlternative: LanguageUtils.createMessage('Service alternative'),
    importExport: LanguageUtils.createMessage('Service import export'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    status: LanguageUtils.createMessage('Status'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function ServicesFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const unitGroups = useSelector(getUnitGroups).content;
    const terminals = useSelector(getTerminals).content;
    const serviceStatuses = useSelector(getServiceStatuses).content;
    const importExportCodes = useSelector(getImportExportCodes).content;
    const serviceTypes = useSelector(getServiceTypes).content;

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        dispatch(fetchTerminals({ paging }));
        dispatch(fetchServiceStatuses({ paging }));
        dispatch(fetchServiceTypes({ paging }));
        dispatch(fetchImportExportCodes({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <GenericAutocomplete<ServiceType>
                            options={serviceTypes}
                            value={filters.serviceTypeId}
                            onChange={(obj: ServiceType | null) => onChange('serviceTypeId', obj?.id)}
                            placeholder={messages.serviceType}
                            compareFn={(o: ServiceType) => o.id === filters.serviceTypeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.fromTerminalId}
                            onChange={(obj: Terminal | null) => onChange('fromTerminalId', obj?.id)}
                            placeholder={messages.fromTerminal}
                            compareFn={(o: Terminal) => o.id === filters.fromTerminalId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toTerminalId}
                            onChange={(obj: Terminal | null) => onChange('toTerminalId', obj?.id)}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal) => o.id === filters.toTerminalId}
                        />
                    </Grid>

                    <Grid item xs>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={filters.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitGroupId', obj?.id)}
                            placeholder={messages.unitGroup}
                            compareFn={(o: UnitGroup) => o.id === filters.unitGroupId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ImportExportCode>
                            options={importExportCodes}
                            value={filters.importExportCodeId}
                            onChange={(obj: ImportExportCode | null) => onChange('importExportCodeId', obj?.id)}
                            placeholder={messages.importExport}
                            compareFn={(o: ImportExportCode) => o.id === filters.importExportCodeId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<ServiceStatus>
                            options={serviceStatuses}
                            value={filters.serviceStatusId}
                            onChange={(obj: ServiceStatus | null) => onChange('serviceStatusId', obj?.id)}
                            placeholder={messages.status}
                            compareFn={(o: ServiceStatus) => o.id === filters.serviceStatusId}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}