import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import { Theme, Divider, Grid, StepLabel, Stepper, Button, Typography, Step, Tooltip } from '@mui/material';
import GenericStep from '../../../interfaces/common/genericStep';
import { useHistory } from 'react-router-dom';
import { ensure } from '../../../utils/arrayUtils';
import PageUtils from '../../../utils/pageUtils';
import Loader from './loader';
import ValidationModel from '../../../utils/validationModel';
import ValidationSummary from './validationSummary';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex'
        },
        alignAndSpacing: {
            textAlign: 'center',
            marginTop: `${theme.spacing(3)} !important`
        },
        stepper: {
            minHeight: '10vh'
        },
        buttonSpacing: {
            marginRight: `${theme.spacing(2)} !important`
        }
    })
);

interface IGenericStepperProps {
    steps: GenericStep[];
    redirectCondition: boolean;
    redirectTo: string;
    name: any;
    additionalDetails?: any;
}

const messages = {
    finish: LanguageUtils.createMessage('Finish'),
    next: LanguageUtils.createMessage('Next'),
    completed: LanguageUtils.createMessage('Steps completed'),
    optional: LanguageUtils.createMessage('Optional'),
    none: LanguageUtils.createMessage('None'),
    skip: LanguageUtils.createMessage('Skip'),
    skipAndFinish: LanguageUtils.createMessage('Skip and finish'),
    reset: LanguageUtils.createMessage('Reset'),
    cancel: LanguageUtils.createMessage('Cancel'),
    back: LanguageUtils.createMessage('Back'),
    repeat: LanguageUtils.createMessage('Repeat'),
    objectAlreadyModified: LanguageUtils.createMessage('Object already modified'),
    steps: PageUtils.getStepNames()
};

const GenericStepper = (props: IGenericStepperProps): JSX.Element => {
    const { steps, redirectCondition, redirectTo, name, additionalDetails } = props;
    const filteredSteps = steps.filter(step => !step.excludeIf);
    const history = useHistory();
    const classes = useStyles();
    const [isExecuted, setIsExecuted] = React.useState<boolean>(false);
    const [showValidationError, setShowValidationError] = React.useState<boolean | undefined>(false);
    const [validationModel, setValidationModel] = React.useState<ValidationModel>({} as ValidationModel);
    const [activeStepId, setActiveStep] = React.useState(0);
    const intl = useIntl();
    const ref = React.createRef();
    const hasMessageSize = validationModel?.validationMessages && validationModel?.validationMessages?.size > 0;

    useEffect(() => {
        if(redirectCondition && activeStepId === filteredSteps.length - 1) {
            history.push(redirectTo);
        }
    }, [redirectCondition]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if(!activeStep.isOptional) {
            throw new Error(`${intl.formatMessage(messages.completed)}`);
        }

        if(activeStepId !== filteredSteps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else {
            history.push(redirectTo);
        }
    };

    const activeStep = ensure(filteredSteps.find((step, index) => index === activeStepId));

    const isValidForm = () => {
        const validationModel = activeStep.validationFn ? activeStep.validationFn() as ValidationModel : { isValid: true } as ValidationModel;

        const { isValid } = validationModel;

        setShowValidationError(!isValid);
        setValidationModel(validationModel);

        return isValid;
    };

    const handleNext = () => {
        //execute active step logic
        const isValid = isValidForm();
        if(isValid) {
            if(activeStep.onNext) {
                activeStep.onNext(ref);
                setIsExecuted(true);
            }

            if(activeStepId !== filteredSteps.length - 1) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleRepeat = () => {
        const isValid = isValidForm();
        if(isValid) {
            if(activeStep.onNext) {
                activeStep.onNext(ref);
            }

            if(activeStep.onRepeat) {
                activeStep.onRepeat();
            }
        }
    };

    return <div className={classes.root}>
        <Grid container>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item sm={4} xs={12}>
                        <Typography variant="h6" gutterBottom >
                            <FormattedMessage {...name} /> {additionalDetails && <>- {additionalDetails}</>}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Grid>

            <Grid item xs={2}>
                <Stepper activeStep={activeStepId} orientation="vertical" >
                    {filteredSteps.map((step, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        if(step.isOptional) {
                            labelProps.optional = <Typography variant="caption"><FormattedMessage {...messages.optional} /></Typography>;
                        }

                        return (
                            <Step key={index} {...stepProps} >
                                <StepLabel className={classes.stepper} {...labelProps}>{step.name || (<FormattedMessage {...messages.steps[index + 1]} />)}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={activeStep.xl ? 10 : 8}>
                {activeStep.description && <Typography variant="h6" mt={2}>
                    <FormattedMessage {...activeStep.description} />
                </Typography>}
                <Loader isLoading={redirectCondition}>
                    {React.cloneElement(activeStep.content, {
                        ref,
                        showValidationError
                    })}
                </Loader>
                <Grid container>
                    <Grid item xs={3} />
                    <Grid item xs={12} sm={activeStep.xl ? 12 : 6}>
                        <ValidationSummary show={showValidationError && !validationModel?.isValid && hasMessageSize} validationMessages={validationModel.validationMessages} />
                    </Grid>
                    <Grid item xs={3} />
                </Grid>
            </Grid>
            {!activeStep.xl &&
                <Grid item xs={2} />
            }
            <Grid item xs={12} className={classes.alignAndSpacing}>
                {activeStepId === 0 && (
                    <Tooltip title={isExecuted ? intl.formatMessage(messages.objectAlreadyModified) : ''}>
                        <span>
                            <Button onClick={() => history.goBack()} className={classes.buttonSpacing} disabled={isExecuted}>
                                <FormattedMessage {...messages.cancel} />
                            </Button>
                        </span>
                    </Tooltip>
                )}
                <Button disabled={activeStepId === 0} onClick={handleBack} className={classes.buttonSpacing}>
                    <FormattedMessage {...messages.back} />
                </Button>
                {activeStep.isOptional && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.buttonSpacing}
                    >
                        {activeStepId === filteredSteps.length - 1 ? <FormattedMessage {...messages.skipAndFinish} /> : <FormattedMessage {...messages.skip} />}
                    </Button>
                )}
                {activeStep.isRepeatable && (
                    <Button
                        color="secondary"
                        onClick={handleRepeat}
                        className={classes.buttonSpacing}
                    >
                        <FormattedMessage {...messages.repeat} />
                    </Button>
                )}
                {!activeStep.isOptional &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={activeStep.disabled}
                    >
                        {activeStepId === filteredSteps.length - 1 ? <FormattedMessage {...messages.finish} /> : <FormattedMessage {...messages.next} />}
                    </Button>
                }
            </Grid>
        </Grid>
    </div>;
};

export default GenericStepper;