import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import InvoiceType from '../interfaces/output/invoiceType';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type InvoiceTypeState = {
    invoiceType: InvoiceType;
    isLoading: boolean;
    invoiceTypes: Pagination<InvoiceType>;
    error: HttpErrorResponse | undefined;
};

const initialState: InvoiceTypeState = {
    invoiceType: {} as InvoiceType,
    invoiceTypes: { content: [] as Array<InvoiceType> } as Pagination<InvoiceType>,
    isLoading: false,
    error: undefined
};

export const invoiceTypeSlice = createSlice({
    name: 'invoiceType',

    initialState,
    reducers: {
        fetchInvoiceTypes: (state: InvoiceTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchInvoiceTypesSuccess: (state: InvoiceTypeState, action: PayloadAction<Pagination<InvoiceType>>) => {
            state.isLoading = false;
            state.invoiceTypes = action.payload;
        },
        fetchInvoiceTypesError: (state: InvoiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchInvoiceTypeById: (state: InvoiceTypeState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchInvoiceTypeByIdSuccess: (state: InvoiceTypeState, action: PayloadAction<InvoiceType>) => {
            state.isLoading = false;
            state.invoiceType = action.payload;
        },
        fetchInvoiceTypeByIdError: (state: InvoiceTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchInvoiceTypes, fetchInvoiceTypesSuccess, fetchInvoiceTypesError, fetchInvoiceTypeById, fetchInvoiceTypeByIdSuccess, fetchInvoiceTypeByIdError } = invoiceTypeSlice.actions;

export const isLoadingInvoiceTypes = (state: RootState): boolean => state.invoiceTypeReducer.isLoading;
export const getInvoiceTypes = (state: RootState): Pagination<InvoiceType> => state.invoiceTypeReducer.invoiceTypes;
export const getInvoiceType = (state: RootState): InvoiceType => state.invoiceTypeReducer.invoiceType;

export default invoiceTypeSlice.reducer;