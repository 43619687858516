import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import PriceListTerminal from '../interfaces/output/priceListTerminal';

export type PriceListTerminalState = {
    priceListTerminal: PriceListTerminal;
    isLoading: boolean;
    priceListTerminals: Pagination<PriceListTerminal>;
    error: HttpErrorResponse | undefined;
};

const initialState: PriceListTerminalState = {
    priceListTerminal: {} as PriceListTerminal,
    priceListTerminals: { content: [] as Array<PriceListTerminal> } as Pagination<PriceListTerminal>,
    isLoading: false,
    error: undefined
};

export const priceListTerminalSlice = createSlice({
    name: 'priceListTerminal',

    initialState,
    reducers: {
        fetchPriceListTerminals: (state: PriceListTerminalState, _action: PayloadAction<{ paging: Paging }>) => {
            state.isLoading = true;
        },
        fetchPriceListTerminalsSuccess: (state: PriceListTerminalState, action: PayloadAction<Pagination<PriceListTerminal>>) => {
            state.isLoading = false;
            state.priceListTerminals = action.payload;
        },
        fetchPriceListTerminalsError: (state: PriceListTerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchPriceListTerminalById: (state: PriceListTerminalState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchPriceListTerminalByIdSuccess: (state: PriceListTerminalState, action: PayloadAction<PriceListTerminal>) => {
            state.isLoading = false;
            state.priceListTerminal = action.payload;
        },
        fetchPriceListTerminalByIdError: (state: PriceListTerminalState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchPriceListTerminalByIdError, fetchPriceListTerminalByIdSuccess, fetchPriceListTerminals,
    fetchPriceListTerminalsError, fetchPriceListTerminalsSuccess, fetchPriceListTerminalById } = priceListTerminalSlice.actions;

export const isLoadingPriceListTerminal = (state: RootState): boolean => state.priceListTerminalReducer.isLoading;
export const getPriceListTerminals = (state: RootState): Pagination<PriceListTerminal> => state.priceListTerminalReducer.priceListTerminals;
export const getPriceListTerminal = (state: RootState): PriceListTerminal => state.priceListTerminalReducer.priceListTerminal;

export default priceListTerminalSlice.reducer;