import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetSurcharge } from '../../../reducers/surchargeReducer';
import AddOrderUnitServiceProductSurchargeForm from './addOrderUnitServiceProductSurchargeForm';

interface IAddOrderUnitServiceProductSurchargeProps {
    classes: any;
    theme: Theme;
    match: any;
    resetSurcharge: any;
    steps: Array<number>;
    orderUnitServiceProductSurchargeId?: number;
    orderUnitServiceProductId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetSurcharge: () => dispatch(resetSurcharge())
});

const mapStoreToProps = (store: RootState, ownProps: IAddOrderUnitServiceProductSurchargeProps) => {
    return {
        orderUnitServiceProductId: ownProps.match.params?.orderUnitServiceProductId ? Number(ownProps.match.params?.orderUnitServiceProductId) : undefined,
        orderUnitServiceProductSurchargeId: ownProps.match.params?.orderUnitServiceProductSurchargeId ? Number(ownProps.match.params?.orderUnitServiceProductSurchargeId) : undefined
    };
};

class AddOrderUnitServiceProductSurcharge extends Component<IAddOrderUnitServiceProductSurchargeProps> {

    componentWillUnmount() {
        const { resetSurcharge } = this.props;

        resetSurcharge();
    }

    render() {
        const { classes, steps, orderUnitServiceProductSurchargeId, orderUnitServiceProductId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddOrderUnitServiceProductSurchargeForm orderUnitServiceProductSurchargeId={orderUnitServiceProductSurchargeId} orderUnitServiceProductId={orderUnitServiceProductId}
                            steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddOrderUnitServiceProductSurcharge));