import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import LanguageUtils from '../../../utils/LanguageUtils';
import WorkflowUtils from '../../../utils/workflowUtils';
import { isImportingGtsOrders } from '../../../reducers/gtsOrderReducer';
import GenericTextField from '../../common/widgets/genericTextField';
import ImportGtsOrderFilters from '../../../interfaces/filters/importGtsOrderFilters';
import { fetchTerminals, getTerminals } from '../../../reducers/terminalReducer';
import PageUtils from '../../../utils/pageUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import Terminal from '../../../interfaces/output/terminal';

interface AddOrderStep1Props {
    orderNumber: string;
    onChange: any;
    filters: ImportGtsOrderFilters | any;
    onChangeFilters: any;
}

const messages = {
    orderNo: LanguageUtils.createMessage('Order number'),
    unitNo: LanguageUtils.createMessage('Unit number'),
    gtsCustomerNo: LanguageUtils.createMessage('Gts customer number'),
    cancellationCode: LanguageUtils.createMessage('Cancellation code'),
    fromPlace: LanguageUtils.createMessage('From place'),
    toPlace: LanguageUtils.createMessage('To place')
};

const ImportGtsOrderStep1 = forwardRef((props: AddOrderStep1Props, ref: any): JSX.Element => {
    const { orderNumber, onChange, filters, onChangeFilters } = props;
    const isLoadingObjects = [useSelector(isImportingGtsOrders)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    const dispatch = useDispatch();
    const terminals = useSelector(getTerminals).content;
    const paging = PageUtils.getMaxPaging();
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchTerminals({ paging }));
    }, [dispatch]);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="orderNumber"
                        label={messages.orderNo}
                        value={orderNumber}
                        onChange={(_key: string, value: string | undefined) => onChange(value)}
                        type="number"
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="unitNo"
                        label={messages.unitNo}
                        value={filters.unitNo}
                        onChange={onChangeFilters}
                        type="string"
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="gtsCustomerNo"
                        label={messages.gtsCustomerNo}
                        value={filters.gtsCustomerNo}
                        onChange={onChangeFilters}
                        type="string"
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="cancellationCode"
                        label={messages.cancellationCode}
                        value={filters.cancellationCode}
                        onChange={onChangeFilters}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<Terminal>
                        options={terminals}
                        value={filters.fromPlace}
                        onChange={(obj: Terminal | null) => onChangeFilters('fromPlace', obj?.gtsTerminalCode)}
                        placeholder={messages.fromPlace}
                        compareFn={(o: Terminal) => o.gtsTerminalCode === filters.fromPlace}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericAutocomplete<Terminal>
                        options={terminals}
                        value={filters.fromPlace}
                        onChange={(obj: Terminal | null) => onChangeFilters('toPlace', obj?.gtsTerminalCode)}
                        placeholder={messages.toPlace}
                        compareFn={(o: Terminal) => o.gtsTerminalCode === filters.toPlace}
                    />
                </Grid>
            </Grid>
        </Loader>
    );
});

export default ImportGtsOrderStep1;