import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox';
import { faBoxes } from '@fortawesome/free-solid-svg-icons/faBoxes';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons/faConciergeBell';
import { faCube } from '@fortawesome/free-solid-svg-icons/faCube';
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes';
import { faCut } from '@fortawesome/free-solid-svg-icons/faCut';
import { faDolly } from '@fortawesome/free-solid-svg-icons/faDolly';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
import { faFileImport } from '@fortawesome/free-solid-svg-icons/faFileImport';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faGift } from '@fortawesome/free-solid-svg-icons/faGift';
import { faGifts } from '@fortawesome/free-solid-svg-icons/faGifts';
import { faGripLines } from '@fortawesome/free-solid-svg-icons/faGripLines';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons/faHandHoldingUsd';
import { faHandshake } from '@fortawesome/free-solid-svg-icons/faHandshake';
import { faHandshakeSlash } from '@fortawesome/free-solid-svg-icons/faHandshakeSlash';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons/faProjectDiagram';
import { faReceipt } from '@fortawesome/free-solid-svg-icons/faReceipt';
import { faRetweet } from '@fortawesome/free-solid-svg-icons/faRetweet';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSkullCrossbones } from '@fortawesome/free-solid-svg-icons/faSkullCrossbones';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrailer } from '@fortawesome/free-solid-svg-icons/faTrailer';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons/faUserSlash';
import { faUserTie } from '@fortawesome/free-solid-svg-icons/faUserTie';
import { faWeightHanging } from '@fortawesome/free-solid-svg-icons/faWeightHanging';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faTrain } from '@fortawesome/free-solid-svg-icons/faTrain';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons/faWarehouse';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';

library.add(
    faAngleDown,
    faAngleUp,
    faBan,
    faBox,
    faBoxes,
    faCalendarAlt,
    faCalendarCheck,
    faChartLine,
    faClock,
    faCog,
    faComments,
    faConciergeBell,
    faCube,
    faCubes,
    faCut,
    faDolly,
    faDownload,
    faEdit,
    faEllipsisH,
    faExternalLinkAlt,
    faFileContract,
    faFileImport,
    faFileInvoice,
    faFilter,
    faFlag,
    faGift,
    faGifts,
    faGripLines,
    faHandHoldingUsd,
    faHandshake,
    faHandshakeSlash,
    faHistory,
    faInfoCircle,
    faLayerGroup,
    faMicrosoft,
    faMinus,
    faPlus,
    faProjectDiagram,
    faReceipt,
    faRetweet,
    faSignOutAlt,
    faSkullCrossbones,
    faSlidersH,
    faTag,
    faTags,
    faTimes,
    faTrailer,
    faUser,
    faUserCircle,
    faUserFriends,
    faUserSlash,
    faUserTie,
    faWeightHanging,
    faWindowClose,
    faSync,
    faChartBar,
    faInfo,
    faQuestion,
    faFileInvoiceDollar,
    faClone,
    faEnvelope,
    faCreditCard,
    faFilePdf,
    faTrain,
    faWarehouse,
    faPrint
);
    
export default FontAwesomeIcon;