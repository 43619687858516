import React from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MessageDescriptor, useIntl } from 'react-intl';
import PageUtils from '../../../utils/pageUtils';
import Order from '../../../interfaces/output/order';
import OrderFilters from '../../../interfaces/filters/orderFilters';
import { fetchOrders, getOrders, isLoadingOrders, resetOrders } from '../../../reducers/orderReducer';

interface IOrderPickerProps {
    value?: Order;
    onChange: any;
    placeholder: MessageDescriptor;
}

const OrderPicker = (props: IOrderPickerProps): JSX.Element => {
    const { value, onChange, placeholder } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const [inputValue, setInputValue] = React.useState('');
    const propsIsLoadingOrders = useSelector(isLoadingOrders);
    const orders = useSelector(getOrders).content;

    return (
        <Autocomplete
            value={value || null}
            onChange={(e, value: any) => onChange(value)}
            getOptionLabel={(option: Order) => option.orderNumber || ''}
            inputValue={inputValue}
            onInputChange={(event, newInputvalue: any) => {
                setInputValue(newInputvalue);
                if(!newInputvalue) {
                    onChange(null);
                    dispatch(resetOrders());
                }
                if(newInputvalue.length > 2) {
                    dispatch(fetchOrders({ paging,
                        filters: {
                            orderNumber: newInputvalue
                        } as OrderFilters }));
                }
            }}
            filterOptions={(x) => x}
            options={orders}
            loading={propsIsLoadingOrders}
            isOptionEqualToValue={(option: Order, value: Order) => option.id === value.id}
            renderInput={(params) => <>
                <TextField {...params} variant="standard" label={placeholder ? formatMessage(placeholder) : ''}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {propsIsLoadingOrders ? <CircularProgress color="inherit" size={20} sx={{ position: 'absolute',
                                    right: 20 }}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }} />
            </>}
        />
    );
};
  
export default OrderPicker;