import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Paging from '../../interfaces/common/paging';
import { RootState } from '../../setup';
import UrlConstants from '../../constants/UrlConstants';
import Pagination from '../../interfaces/common/pagination';
import IMenuItem from '../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../common/widgets/mainLayout';
import PriceListsFilters from '../../interfaces/output/priceListFilters';
import PriceList from '../../interfaces/output/priceList';
import { fetchPriceLists, getPriceLists, isLoadingPriceLists } from '../../reducers/priceListReducer';
import PriceListFilters from '../common/priceListFilters';
import PricelistList from './pricelistList';
import LanguageUtils from '../../utils/LanguageUtils';
import QuickSearchToolbar from '../common/quickSearchToolbar';
import PageUtils from '../../utils/pageUtils';
import UserOut from '../../interfaces/output/userOut';
import { getUserDetails } from '../../reducers/userReducer';

interface IPricelistsProps {
    pricelists: Pagination<PriceList>;
    user: UserOut;
    classes: any;
    fetchPriceLists: any;
    isLoading: boolean;
    onChange: () => void;
}

interface IPricelistsState {
    searchTerm: string;
    filters: PriceListsFilters;
    paging: Paging;
    menuItem: IMenuItem;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    pricelists: LanguageUtils.createMessage('Price lists'),
    add: LanguageUtils.createMessage('Add')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchPriceLists: (paging: Paging, filters: PriceListsFilters) => dispatch(fetchPriceLists({
        paging,
        filters
    }))
});

const mapStoreToProps = (store: RootState) => {
    return {
        pricelists: getPriceLists(store),
        isLoading: isLoadingPriceLists(store),
        user: getUserDetails(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class Pricelists extends Component<IPricelistsProps, IPricelistsState> {
    priceListRef: any;
    constructor(props: IPricelistsProps) {
        super(props);
        this.priceListRef = React.createRef();
        this.state = {
            searchTerm: '',
            filters: {
                name: '',
                unitGroupId: 1,
                productTypeId: 1,
                pricingComponentTypeId: 1,
                sellerId: props.user.sellerId || null
            } as PriceListsFilters,
            paging: PageUtils.getDefaultPaging(),
            menuItem: {
                id: 1,
                name: messages.pricelists,
                icon: <FontAwesomeIcon icon="tags" size="1x" transform="grow-7" />,
                isSelected: false
            },
            sortModel: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {
                discount: false
            }
        };
    }

    componentDidMount() {
        const { fetchPriceLists } = this.props;
        const { paging, filters } = this.state;
        fetchPriceLists(paging, filters);
    }

    applyFilters = () => {
        const { fetchPriceLists } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newServerPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newServerPagination
        });

        fetchPriceLists(newServerPagination, filters);

        if(this.priceListRef !== null && this.priceListRef !== undefined &&
            this.priceListRef.current !== null && this.priceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.priceListRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as PriceListsFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();

        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    onChangeQuickSearch = (value: any) => {
        const { filters, paging } = this.state;
        const { fetchPriceLists } = this.props;

        const newFilter = { ...filters } as PriceListsFilters;
        (newFilter as any)['name'] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination,
            searchTerm: value
        });

        fetchPriceLists(newPagination, newFilter);
    }

    setDefaultState = () => {
        const { fetchPriceLists } = this.props;
        const { paging } = this.state;

        const newFilters = {
            name: ''
        } as PriceListsFilters;
        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            paging: newPagination,
            filters: newFilters,
            searchTerm: ''
        });

        if(this.priceListRef !== null && this.priceListRef !== undefined &&
            this.priceListRef.current !== null && this.priceListRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.priceListRef.current.resetDataGridPage();
        }

        fetchPriceLists(newPagination, newFilters);
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchPriceLists } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchPriceLists(newPaging, filters);
        }
    }

    render() {
        const { classes, pricelists, fetchPriceLists, isLoading } = this.props;
        const { searchTerm, paging, filters, menuItem, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}>
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <QuickSearchToolbar
                                    onSearch={this.onChangeQuickSearch}
                                    searchTerm={searchTerm}
                                    includeFilters={true}
                                    actions={[{
                                        text: messages.add,
                                        icon: 'plus',
                                        href: `${UrlConstants.PRICE_LISTS}/add`
                                    }]}
                                >
                                    <PriceListFilters applyFilters={this.applyFilters} setDefaultState={this.setDefaultState}
                                        filters={filters} onChange={this.onChange} />
                                </QuickSearchToolbar>
                                <PricelistList pricelists={pricelists} isLoading={isLoading}
                                    hiddenColumns={['discount']}
                                    ref={this.priceListRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        this.setState({ paging: newPage });
                                        fetchPriceLists(newPage, filters);
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(Pricelists));