import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Link, Theme } from '@mui/material';
import DataGridComponent from '../common/dataGrid';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../utils/tableUtils';
import DashboardMessage from '../../interfaces/output/dashboardMessage';
import { ensure } from '../../utils/arrayUtils';
import Pagination from '../../interfaces/common/pagination';
import LanguageUtils from '../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import UrlConstants from '../../constants/UrlConstants';
import TableHeadersConstants from '../../constants/tableHeadersConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NullableFormattedDate from '../common/nullableFormattedDate';
import TruncatedStringTooltip from '../common/truncatedStringTooltip';

const messages = {
    messageText: LanguageUtils.createMessage('Message text'),
    messageDateTime: LanguageUtils.createMessage('Message Date time'),
    messageType: LanguageUtils.createMessage('Message type'),
    tableName: LanguageUtils.createMessage('Table name'),
    tableId: LanguageUtils.createMessage('Table id'),
    goTo: LanguageUtils.createMessage('Go to'),
    link: LanguageUtils.createMessage('Link')
};

interface IDashboardMessageListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: Pagination<DashboardMessage>;
    hiddenColumns?: Array<string>;
    applyRowFn?: (x: any) => DashboardMessage;
    selection?: number;
    additionalColumns?: GridColDef[];
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const defaultProps: IDashboardMessageListProps = {
    additionalColumns: [] as GridColDef[]
};

const DashboardMessageList = forwardRef((props: IDashboardMessageListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, additionalColumns,onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams): DashboardMessage => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const linkToObjectFn = (cellValue: GridRenderCellParams) => {
        switch (applyRowFn(cellValue).tableName) {
            case TableHeadersConstants.AGREEMENT:
                return UrlConstants.AGREEMENTS;
            case TableHeadersConstants.CUSTOMER:
                return UrlConstants.CUSTOMERS;
            case TableHeadersConstants.ORDER:
                return UrlConstants.ORDERS;
            case TableHeadersConstants.ORDER_UNIT:
                return UrlConstants.ORDER_UNITS;
            case TableHeadersConstants.ORDER_UNIT_SERVICE:
                return UrlConstants.ORDER_UNIT_SERVICES;
            case TableHeadersConstants.ORDER_UNIT_SERVICE_PRODUCT:
                return UrlConstants.ORDER_UNIT_SERVICE_PRODUCTS;
            case TableHeadersConstants.INVOICE:
                return UrlConstants.INVOICES;
            case TableHeadersConstants.GTS_ORDER:
                return UrlConstants.GTS_ORDERS;
            case TableHeadersConstants.GTS_ORDER_UNIT:
                return UrlConstants.GTS_ORDER_UNITS;
            case TableHeadersConstants.GTS_ORDER_UNIT_DANGEROUS_GOODS:
                return UrlConstants.UNIT_DANGEROUS_GOODS;
            case TableHeadersConstants.GTS_ORDER_UNIT_DEPOT:
                return UrlConstants.UNIT_DEPOT;
            case TableHeadersConstants.GTS_ORDER_UNIT_SERVICE:
                return UrlConstants.GTS_UNIT_SERVICES;
            case TableHeadersConstants.SERVICE:
                return UrlConstants.SERVICES;
            case TableHeadersConstants.PRODUCT:
                return UrlConstants.PRODUCTS;
            case TableHeadersConstants.PRICE_LIST:
                return UrlConstants.PRICE_LISTS;
            default:
                return '';
        }
    };

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            ...ensure(additionalColumns),
            {
                field: 'messageText',
                headerName: intl.formatMessage(messages.messageText),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => (<TruncatedStringTooltip value={applyRowFn(params).messageText ?? '-'} />),
                hide: hiddenColumns && TableUtils.hideColumn('messageText', hiddenColumns)
            },
            {
                field: ' ',
                headerName: intl.formatMessage(messages.link),
                flex: 0.1,
                align: 'left',
                headerAlign: 'left',
                sortable: false,
                renderCell: (cellValues: GridRenderCellParams) => {
                    return applyRowFn(cellValues).tableId && <Link underline="none" color="secondary" width="100%"
                        href={(linkToObjectFn(cellValues) === UrlConstants.AGREEMENTS || linkToObjectFn(cellValues) === UrlConstants.PRICE_LISTS) ?
                            `/#/${linkToObjectFn(cellValues)}/${applyRowFn(cellValues).tableId}/latest` :
                            `/#/${linkToObjectFn(cellValues)}/${applyRowFn(cellValues).tableId}`} title={intl.formatMessage(messages.goTo)}>
                        <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                    </Link>;
                }
            },
            {
                field: 'messageDateTime',
                headerName: intl.formatMessage(messages.messageDateTime),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => { return (<NullableFormattedDate value={applyRowFn(params).messageDateTime} />); },
                hide: hiddenColumns && TableUtils.hideColumn('messageDateTime', hiddenColumns)
            },
            {
                field: 'messageType',
                headerName: intl.formatMessage(messages.messageType),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => (<TruncatedStringTooltip value={applyRowFn(params).messageType?.messageType ?? '-'} />),
                hide: hiddenColumns && TableUtils.hideColumn('messageType', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            ref={dataGridRef} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

DashboardMessageList.defaultProps = defaultProps;

export default DashboardMessageList;