import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import AddFactorSetDepartureLineForm from './addFactorSetDepartureLineForm';
import { FormType } from '../../../../constants/constants';
import { resetDepartureVersionLines, resetFactorSetDepartureLine } from '../../../../reducers/departureLineReducer';

interface IAddFactorSetLineProps {
    classes: any;
    theme: Theme;
    match: any;
    departureLineId?: number;
    factorSetDepartureId?: number;
    factorSetVersionId?: number;
    resetDepartureVersionLines: any;
    resetFactorSetDepartureLine: any;
    type: FormType;
    steps: Array<number>;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetDepartureVersionLines: () => dispatch(resetDepartureVersionLines()),
    resetFactorSetDepartureLine: () => dispatch(resetFactorSetDepartureLine())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetLineProps) => {
    return {
        departureLineId: ownProps.match.params?.departureLineId ? Number(ownProps.match.params?.departureLineId) : undefined,
        factorSetDepartureId: ownProps.match.params?.departureId ? Number(ownProps.match.params?.departureId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetDepartureLine extends Component<IAddFactorSetLineProps> {

    componentWillUnmount() {
        const { resetDepartureVersionLines, resetFactorSetDepartureLine } = this.props;

        resetDepartureVersionLines();
        resetFactorSetDepartureLine();
    }

    render() {
        const { classes, steps, departureLineId, factorSetDepartureId, type, factorSetVersionId } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddFactorSetDepartureLineForm departureLineId={departureLineId} factorSetDepartureId={factorSetDepartureId} steps={steps} type={type}
                            factorSetVersionId={factorSetVersionId} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetDepartureLine));