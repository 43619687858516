import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import AccordionSummary from '../../common/accordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UrlConstants from '../../../constants/UrlConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import DisplayStatus from '../../common/displayStatus';
import NullableFormattedDate from '../../common/nullableFormattedDate';

interface IViewFactorSetWeight {
    classes?: any;
    theme?: Theme;
    factorSetWeight: FactorSetOut;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        },
        link: {
            textDecoration: 'none',
            color: `${theme.palette.info.main}!important`,
            fontSize: '12px!important'
        }
    })
);

const messages = {
    details: LanguageUtils.createMessage('Factor set details'),
    name: LanguageUtils.createMessage('Name'),
    type: LanguageUtils.createMessage('Factor set type'),
    productArena: LanguageUtils.createMessage('Product arena'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    status: LanguageUtils.createMessage('Status'),
    versionFrom: LanguageUtils.createMessage('Valid from'),
    versionNumber: LanguageUtils.createMessage('Version number'),
    version: LanguageUtils.createMessage('Version'),
    versionTo: LanguageUtils.createMessage('Valid to'),
    noActiveVersion: LanguageUtils.createMessage('No version active'),
    latestVersion: LanguageUtils.createMessage('Latest version'),
    productType: LanguageUtils.createMessage('Product type')
};

export default function ViewFactorSetWeight(props: IViewFactorSetWeight): JSX.Element {
    const classes = styles();
    const { factorSetWeight } = props;
    const intl = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.name} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{factorSetWeight.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.type} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{factorSetWeight.type?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.productArena} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{factorSetWeight.productArena?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.subgroup} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{factorSetWeight.unitSubGroup?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.productType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{factorSetWeight.productType?.name || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            className={classes.accordion}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            aria-label="expand"
                        >
                            <Typography className={classes.heading}>Version</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {factorSetWeight.version &&
                                    <>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.versionNumber} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom>{factorSetWeight.version?.versionNumber || '-'}</Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.versionFrom} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom>
                                                <NullableFormattedDate value={factorSetWeight.version?.validFromDate} />
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={12} className={classes.gridItem}>
                                            <Typography gutterBottom><FormattedMessage {...messages.versionTo} />: </Typography>
                                        </Grid>
                                        <Grid item sm={9} xs={12} >
                                            <Typography gutterBottom><NullableFormattedDate value={factorSetWeight.version?.validToDate} /></Typography>
                                        </Grid>
                                        <Grid item sm={3} xs={3} className={classes.gridItem}>
                                            <Typography gutterBottom>Status: </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <DisplayStatus status={factorSetWeight.version?.status} />
                                        </Grid>
                                    </>
                                }
                                <Grid item sm={3} xs={12} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.latestVersion} />: </Typography>
                                </Grid>
                                <Grid item sm={!factorSetWeight.currentVersion ? 5 : 2} xs={12} >
                                    <Grid container>
                                        <Grid item sm={!factorSetWeight.currentVersion ? 4 : 1}>
                                            <Typography gutterBottom>{factorSetWeight.currentVersion ? factorSetWeight.currentVersion.versionNumber :
                                                intl.formatMessage(messages.noActiveVersion)}</Typography>
                                        </Grid>
                                        {factorSetWeight.currentVersion && factorSetWeight.currentVersion?.id !== factorSetWeight.version?.id &&
                                        <Grid item sm={1}>
                                            <Link
                                                to={`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_WEIGHT}/${factorSetWeight.id}/${factorSetWeight.currentVersion.id}/properties`}
                                                title="Go to versions"
                                                className={classes.link}>
                                                <FontAwesomeIcon icon="external-link-alt" size="1x" transform="grow-1" />
                                            </Link>
                                        </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item sm={!factorSetWeight.currentVersion ? 4 : 7} xs={12} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}