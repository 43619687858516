import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CancellationType from '../interfaces/output/cancellationType';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CancellationTypeState = {
    isLoading: boolean;
    cancellationTypes: Pagination<CancellationType>;
    error: HttpErrorResponse | undefined;
};

const initialState: CancellationTypeState = {
    cancellationTypes: { content: [] as Array<CancellationType> } as Pagination<CancellationType>,
    isLoading: false,
    error: undefined
};

export const cancellationTypeSlice = createSlice({
    name: 'cancellationType',

    initialState,
    reducers: {
        fetchCancellationTypes: (state: CancellationTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchCancellationTypesSuccess: (state: CancellationTypeState, action: PayloadAction<Pagination<CancellationType>>) => {
            state.isLoading = false;
            state.cancellationTypes = action.payload;
        },
        fetchCancellationTypesError: (state: CancellationTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchCancellationTypes, fetchCancellationTypesSuccess, fetchCancellationTypesError } = cancellationTypeSlice.actions;

export const isLoadingCancellationTypes = (state: RootState): boolean => state.cancellationTypeReducer.isLoading;
export const getCancellationTypes = (state: RootState): Pagination<CancellationType> => state.cancellationTypeReducer.cancellationTypes;

export default cancellationTypeSlice.reducer;