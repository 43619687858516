import { List } from 'immutable';
import Order from '../../../interfaces/output/order';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    orderNumberAreadyExists: LanguageUtils.createMessage('Order number error'),
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class OrderStepValidation extends ValidationUtils {

    static validateOrderForm(order: Order): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const { orderNumber
        } = order;

        const requiredFields =
            this.validateInputStringLength(orderNumber);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        // if(!!orders && orders?.totalElements > 0) {
        //     if(orders?.content?.find(o => order?.id !== o?.id && o?.orderNumber === orderNumber)) {
        //         validationModel.isValid = false;
        //         validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.orderNumberAreadyExists);
        //     }
        // }

        return validationModel;
    }
}
