import CustomerGroupApi from '../api/customerGroupApi';
import { fetchCustomerGroupSuccess, fetchCustomerGroupsSuccess, fetchCustomerGroupError, fetchCustomerGroupsError } from '../reducers/customerGroupReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import CustomerGroup from '../interfaces/output/customerGroup';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchCustomerGrpup(action: PayloadAction<string>) {
    const statusId = action.payload;
    try {
        const response: CustomerGroup = yield call(CustomerGroupApi.fetchCustomerGroup, statusId);
        yield put(fetchCustomerGroupSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerGroupError(error as HttpErrorResponse));
    }
}

function* doFetchCustomerGroups(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<CustomerGroup> = yield call(CustomerGroupApi.fetchCustomerGroups, paging);
        yield put(fetchCustomerGroupsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerGroupsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('group/fetchCustomerGroup', doFetchCustomerGrpup),
    takeLatest('group/fetchCustomerGroups', doFetchCustomerGroups)];
