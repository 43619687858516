import InvoiceFrequencyApi from '../api/invoiceFrequencyApi';
import { fetchInvoiceFrequenciesError, fetchInvoiceFrequenciesSuccess, fetchInvoiceFrequencyByIdError, fetchInvoiceFrequencyByIdSuccess } from '../reducers/invoiceFrequencyReducer';
import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import InvoiceFrequency from '../interfaces/output/invoiceFrequency';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';

function* doFetchInvoiceFrequencyById(action: PayloadAction<string>) {
    const invoiceFrequencyId = action.payload;
    try {
        const response: InvoiceFrequency = yield call(InvoiceFrequencyApi.fetchInvoiceFrequencyById, invoiceFrequencyId);
        yield put(fetchInvoiceFrequencyByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceFrequencyByIdError(error as HttpErrorResponse));
    }
}

function* doFetchInvoicesFrequency() {
    try {
        const response: Pagination<InvoiceFrequency> = yield call(InvoiceFrequencyApi.fetchInvoicesFrequency);
        yield put(fetchInvoiceFrequenciesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchInvoiceFrequenciesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('invoiceFrequency/fetchInvoiceFrequencyById', doFetchInvoiceFrequencyById),
    takeLatest('invoiceFrequency/fetchInvoiceFrequencies', doFetchInvoicesFrequency)];

