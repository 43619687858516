import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import D365Account from '../interfaces/output/d365Account';
import D365Activity from '../interfaces/output/d365Activity';
import D365InternalTrade from '../interfaces/output/d365InternalTrade';
import D365Object from '../interfaces/output/d365Object';
import D365ResponsibilityCenter from '../interfaces/output/d365ResponsibilityCenter';
import D365Spec from '../interfaces/output/d365Spec';
import D365VAT from '../interfaces/output/d365VAT';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type DynamicsState = {
    isLoadingAccounts: boolean;
    isLoadingActivities: boolean;
    isLoadingResponsibilities: boolean;
    isLoadingObjects: boolean;
    isLoadingSpecs: boolean;
    isLoadingVats: boolean;
    isLoadingTrades: boolean;
    accounts: Pagination<D365Account>;
    activities: Pagination<D365Activity>;
    responsibilities: Pagination<D365ResponsibilityCenter>;
    objects: Pagination<D365Object>;
    specs: Pagination<D365Spec>;
    trades: Pagination<D365InternalTrade>;
    vats: Pagination<D365VAT>;
    error: HttpErrorResponse | undefined;
};

const initialState: DynamicsState = {
    accounts: { content: [] as Array<D365Account> } as Pagination<D365Account>,
    activities: { content: [] as Array<D365Activity> } as Pagination<D365Activity>,
    responsibilities: { content: [] as Array<D365ResponsibilityCenter> } as Pagination<D365ResponsibilityCenter>,
    objects: { content: [] as Array<D365Object> } as Pagination<D365Object>,
    specs: { content: [] as Array<D365Spec> } as Pagination<D365Spec>,
    trades: { content: [] as Array<D365InternalTrade> } as Pagination<D365InternalTrade>,
    vats: { content: [] as Array<D365VAT> } as Pagination<D365VAT>,
    isLoadingAccounts: false,
    isLoadingActivities: false,
    isLoadingResponsibilities: false,
    isLoadingObjects: false,
    isLoadingSpecs: false,
    isLoadingVats: false,
    isLoadingTrades: false,
    error: undefined
};

export const vatSlice = createSlice({
    name: 'dynamics',

    initialState,
    reducers: {
        fetchAccounts: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingAccounts = true;
        },
        fetchAccountsSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365Account>>) => {
            state.isLoadingAccounts = false;
            state.accounts = action.payload;
        },
        fetchAccountsError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingAccounts = false;
            state.error = action.payload;
        },
        fetchActivities: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingActivities = true;
        },
        fetchActivitiesSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365Activity>>) => {
            state.isLoadingActivities = false;
            state.activities = action.payload;
        },
        fetchActivitiesError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingActivities = false;
            state.error = action.payload;
        },
        fetchResponsibilities: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingResponsibilities = true;
        },
        fetchResponsibilitiesSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365ResponsibilityCenter>>) => {
            state.isLoadingResponsibilities = false;
            state.responsibilities = action.payload;
        },
        fetchResponsibilitiesError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingResponsibilities = false;
            state.error = action.payload;
        },
        fetchObjects: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingObjects = true;
        },
        fetchObjectsSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365Object>>) => {
            state.isLoadingObjects = false;
            state.objects = action.payload;
        },
        fetchObjectsError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingObjects = false;
            state.error = action.payload;
        },
        fetchSpecs: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingSpecs = true;
        },
        fetchSpecsSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365Spec>>) => {
            state.isLoadingSpecs = false;
            state.specs = action.payload;
        },
        fetchSpecsError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingSpecs = false;
            state.error = action.payload;
        },
        fetchTrades: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingTrades = true;
        },
        fetchTradesSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365InternalTrade>>) => {
            state.isLoadingTrades = false;
            state.trades = action.payload;
        },
        fetchTradesError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingTrades = false;
            state.error = action.payload;
        },
        fetchVats: (state: DynamicsState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoadingVats = true;
        },
        fetchVatsSuccess: (state: DynamicsState, action: PayloadAction<Pagination<D365VAT>>) => {
            state.isLoadingVats = false;
            state.vats = action.payload;
        },
        fetchVatsError: (state: DynamicsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingVats = false;
            state.error = action.payload;
        }
    }
});

export const { fetchAccounts, fetchAccountsSuccess, fetchAccountsError, fetchActivities, fetchActivitiesSuccess, fetchActivitiesError, fetchResponsibilities, fetchResponsibilitiesSuccess,
    fetchResponsibilitiesError, fetchObjects, fetchObjectsError, fetchObjectsSuccess, fetchTrades, fetchTradesError, fetchTradesSuccess, fetchSpecs, fetchSpecsError, fetchSpecsSuccess,
    fetchVats, fetchVatsSuccess, fetchVatsError } = vatSlice.actions;

export const isLoadingDynamics = (state: RootState): boolean => state.dynamicsReducer.isLoadingAccounts || state.dynamicsReducer.isLoadingActivities || state.dynamicsReducer.isLoadingObjects
    || state.dynamicsReducer.isLoadingResponsibilities || state.dynamicsReducer.isLoadingSpecs || state.dynamicsReducer.isLoadingTrades
    || state.dynamicsReducer.isLoadingVats;
export const getDynamicsAccounts = (state: RootState): Pagination<D365Account> => state.dynamicsReducer.accounts;
export const getDynamicsActivities = (state: RootState): Pagination<D365Activity> => state.dynamicsReducer.activities;
export const getDynamicsObjects = (state: RootState): Pagination<D365Object> => state.dynamicsReducer.objects;
export const getDynamicsResponsibilities = (state: RootState): Pagination<D365ResponsibilityCenter> => state.dynamicsReducer.responsibilities;
export const getDynamicsSpecs = (state: RootState): Pagination<D365Spec> => state.dynamicsReducer.specs;
export const getDynamicsTrades = (state: RootState): Pagination<D365InternalTrade> => state.dynamicsReducer.trades;
export const getDynamicsVats = (state: RootState): Pagination<D365VAT> => state.dynamicsReducer.vats;

export default vatSlice.reducer;