import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid } from '@mui/material';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import { useSelector } from 'react-redux';
import GenericTextField from '../../common/widgets/genericTextField';
import { isCreating, isLoadingServiceProduct, isUpdating } from '../../../reducers/serviceProductReducer';
import ServiceProduct from '../../../interfaces/output/serviceProduct';

interface AddAgreementStep1Props {
    serviceProduct: ServiceProduct;
    onChange: any;
}

const messages = {
    amount: LanguageUtils.createMessage('Amount')
};

const AddServiceProductStep1 = forwardRef((props: AddAgreementStep1Props, ref: any): JSX.Element => {
    const { serviceProduct, onChange } = props;
    const isLoadingObjects = [useSelector(isLoadingServiceProduct), useSelector(isCreating), useSelector(isUpdating)] as Array<boolean>;
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="amount"
                        type="number"
                        label={messages.amount}
                        value={serviceProduct?.amount}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddServiceProductStep1;