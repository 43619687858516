import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetHoursLineOutput from '../interfaces/output/factorSetHoursLineOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import HoursLineFilters from '../components/factorSets/hours/line/hoursLineFilters';

export type HoursLineState = {
    factorSetHoursLine: FactorSetHoursLineOutput;
    factorSetVersionLines: Pagination<FactorSetHoursLineOutput>;
    isLoadingHoursLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: HoursLineState = {
    factorSetHoursLine: {} as FactorSetHoursLineOutput,
    factorSetVersionLines: { content: [] as Array<FactorSetHoursLineOutput> } as Pagination<FactorSetHoursLineOutput>,
    isLoadingHoursLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const hoursLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetHoursLineById: (state: HoursLineState, _action: PayloadAction<number>) => {
            state.isLoadingHoursLine = true;
        },
        fetchFactorSetHoursLineByIdSuccess: (state: HoursLineState, action: PayloadAction<FactorSetHoursLineOutput>) => {
            state.isLoadingHoursLine = false;
            state.factorSetHoursLine = action.payload;
        },
        fetchFactorSetHoursLineByIdError: (state: HoursLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingHoursLine = false;
            state.error = action.payload;
        },
        fetchHoursVersionLines: (state: HoursLineState, _action: PayloadAction<{paging: Paging, filters: HoursLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchHoursVersionLinesSuccess: (state: HoursLineState, action: PayloadAction<Pagination<FactorSetHoursLineOutput>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchHoursVersionLinesError: (state: HoursLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createHoursVersionLine: (state: HoursLineState, _action: PayloadAction<FactorSetHoursLineOutput>) => {
            state.isCreating = true;
        },
        createHoursVersionLineSuccess: (state: HoursLineState, action: PayloadAction<FactorSetHoursLineOutput>) => {
            state.isCreating = false;
            state.factorSetHoursLine = action.payload;
        },
        createHoursVersionLineError: (state: HoursLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetHoursLine: (state: HoursLineState, _action: PayloadAction<FactorSetHoursLineOutput>) => {
            state.isUpdating = true;
        },
        updateFactorSetHoursLineSuccess: (state: HoursLineState, action: PayloadAction<FactorSetHoursLineOutput>) => {
            state.isUpdating = false;
            state.factorSetHoursLine = action.payload;
        },
        updateFactorSetHoursLineError: (state: HoursLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetHoursLine: (state: HoursLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetHoursLineSuccess: (state: HoursLineState, action: PayloadAction<FactorSetHoursLineOutput>) => {
            state.isDeleting = false;
            state.factorSetHoursLine = action.payload;
        },
        deactivateFactorSetHoursLineError: (state: HoursLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetHoursLine: (state: HoursLineState) => {
            state.factorSetHoursLine = initialState.factorSetHoursLine;
        },
        resetHoursVersionLines: (state: HoursLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetHoursLineById, resetHoursVersionLines, fetchFactorSetHoursLineByIdSuccess, fetchFactorSetHoursLineByIdError,
    updateFactorSetHoursLine, updateFactorSetHoursLineSuccess, updateFactorSetHoursLineError, deactivateFactorSetHoursLine, deactivateFactorSetHoursLineError,
    deactivateFactorSetHoursLineSuccess, fetchHoursVersionLines, fetchHoursVersionLinesSuccess, fetchHoursVersionLinesError, createHoursVersionLine,
    createHoursVersionLineSuccess, createHoursVersionLineError, resetFactorSetHoursLine } = hoursLineSlice.actions;

export const isLoadingHoursLine = (state: RootState): boolean => state.hoursLineReducer.isLoadingHoursLine;
export const isLoadingFactorSetHoursLines = (state: RootState): boolean => state.hoursLineReducer.isLoadingLines;
export const isUpdatingHoursLine = (state: RootState): boolean => state.hoursLineReducer.isUpdating;
export const isDeletingHoursLine = (state: RootState): boolean => state.hoursLineReducer.isDeleting;
export const getFactorSetHoursLine = (state: RootState): FactorSetHoursLineOutput => state.hoursLineReducer.factorSetHoursLine;
export const getHoursLines = (state: RootState): Pagination<FactorSetHoursLineOutput> => state.hoursLineReducer.factorSetVersionLines;
export const isCreatingHoursLine = (state: RootState): boolean => state.hoursLineReducer.isCreating;

export default hoursLineSlice.reducer;