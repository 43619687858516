import React, { Component } from 'react';
import UrlConstants from '../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../interfaces/common/menuItem';
import MainLayout from '../common/widgets/mainLayout';
import PageUtils from '../../utils/pageUtils';
import { Theme } from '@mui/material';
import { ObjectType } from '../../constants/constants';
import GTSOrderUnitDepot from '../../interfaces/output/gtsOrderUnitDepot';
import { fetchGtsOrderUnitDepotById, getGtsOrderUnitDepot, isLoadingGtsOrderUnitDepot } from '../../reducers/gtsOrderUnitDepotLinesReducer';
import ViewGtsOrderUnitDepot from './viewUnitDepot';
import LanguageUtils from '../../utils/LanguageUtils';
import { fetchGtsOrderById, getGtsOrder, isLoadingGtsOrder } from '../../reducers/gtsOrderReducer';
import GtsOrder from '../../interfaces/output/gtsOrder';

interface IUnitDepotDetailsProps {
    classes: any;
    theme: Theme;
    fetchGtsOrderUnitDepotById: any;
    fetchGtsOrderById: any;
    gtsOrderUnitDepot: GTSOrderUnitDepot;
    gtsOrder: GtsOrder;
    match: any;
    history: any;
    location: any;
    isLoadingGtsOrderUnitDepot: boolean;
    isLoadingGtsOrder: boolean;
}

interface IUnitDepotDetailsState {
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchGtsOrderUnitDepotById: (id: number) => dispatch(fetchGtsOrderUnitDepotById(id)),
    fetchGtsOrderById: (orderId: number) => dispatch(fetchGtsOrderById(orderId))
});

const mapStoreToProps = (store: RootState) => {
    return {
        gtsOrderUnitDepot: getGtsOrderUnitDepot(store),
        isLoadingGtsOrderUnitDepot: isLoadingGtsOrderUnitDepot(store),
        isLoadingGtsOrder: isLoadingGtsOrder(store),
        gtsOrder: getGtsOrder(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    properties: LanguageUtils.createMessage('Properties'),
    subname: LanguageUtils.createMessage('GTS_ORDER_UNIT_DEPOT_SUBNAME')
};

class UnitDepotDetails extends Component<IUnitDepotDetailsProps, IUnitDepotDetailsState> {
    constructor(props: IUnitDepotDetailsProps) {
        super(props);

        this.state = {
            menuItems: ImmutableList([{
                id: 1,
                name: messages.properties,
                icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
                additionalMargin: true,
                url: `/${UrlConstants.UNIT_DEPOT}/${props.match.params.depotId}/properties`,
                isSelected: PageUtils.isPageSelected(`/${UrlConstants.UNIT_DEPOT}/${props.match.params.depotId}/properties`, props.location.pathname)
            }]),
            selectedRow: undefined
        };
    }

    componentDidMount() {
        const { fetchGtsOrderUnitDepotById } = this.props;
        const { depotId } = this.props.match.params;

        fetchGtsOrderUnitDepotById(depotId);
    }

    componentDidUpdate(prevProps: IUnitDepotDetailsProps) {
        const { gtsOrderUnitDepot, fetchGtsOrderById } = this.props;

        if(gtsOrderUnitDepot !== prevProps.gtsOrderUnitDepot) {
            if(gtsOrderUnitDepot.gtsOrderId) {
                fetchGtsOrderById(gtsOrderUnitDepot.gtsOrderId);
            }
        }
    }

    getSelectedMenuItem = (state: IUnitDepotDetailsState) => state.menuItems.find(item => item.isSelected);
    
    handleMenuItems = () => {
        const { menuItems } = this.state;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { depotId } = this.props.match.params;
        
        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: `${UrlConstants.UNIT_DEPOT}/${depotId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    }
                ];
            }
            
            default: {
                return [];
            }
        }
    }

    render() {
        const { classes, gtsOrderUnitDepot, isLoadingGtsOrderUnitDepot, gtsOrder, isLoadingGtsOrder } = this.props;
        const { menuItems } = this.state;

        return (
            <MainLayout
                actions={this.handleMenuItems()}
                isLoading={isLoadingGtsOrderUnitDepot || isLoadingGtsOrder}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.GtsOrderUnitDepot}
                menuItem={{ id: 1,
                    subname: messages.subname,
                    value: gtsOrderUnitDepot.orderNumber,
                    icon: <FontAwesomeIcon icon="dolly" size="1x" /> } as IMenuItem
                }
                routes={
                    [{
                        name: gtsOrder.orderNumber,
                        url: `${UrlConstants.GTS_ORDERS}/${gtsOrder.id}`
                    },{
                        name: gtsOrderUnitDepot.logicalUnitId,
                        url: `${UrlConstants.GTS_ORDER_UNITS}/${gtsOrderUnitDepot.gtsOrderUnitId}`
                    }]
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.UNIT_DEPOT}/:depotId/properties`}>
                            <ViewGtsOrderUnitDepot gtsOrderUnitDepot={gtsOrderUnitDepot} />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(UnitDepotDetails));