import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import UnitSubGroup from '../interfaces/output/unitSubGroup';
import UnitType from '../interfaces/output/unitType';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type UnitSubgroupState = {
    unitSubgroup: UnitSubGroup;
    isLoading: boolean;
    isLoadingUnitTypes: boolean;
    unitSubgroups: Pagination<UnitSubGroup>;
    unitTypes: Pagination<UnitType>;
    error: HttpErrorResponse | undefined;
};

const initialState: UnitSubgroupState = {
    unitSubgroup: {} as UnitSubGroup,
    unitSubgroups: { content: [] as Array<UnitSubGroup> } as Pagination<UnitSubGroup>,
    unitTypes: { content: [] as Array<UnitType> } as Pagination<UnitType>,
    isLoading: false,
    isLoadingUnitTypes: false,
    error: undefined
};

export const unitSubgroupSlice = createSlice({
    name: 'unitsubgroup',

    initialState,
    reducers: {
        fetchUnitSubgroupById: (state: UnitSubgroupState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchUnitSubgroupByIdSuccess: (state: UnitSubgroupState, action: PayloadAction<UnitSubGroup>) => {
            state.isLoading = false;
            state.unitSubgroup = action.payload;
        },
        fetchUnitSubgroupByIdError: (state: UnitSubgroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchUnitTypesBySubGroupId: (state: UnitSubgroupState, _action: PayloadAction<{id: number, paging: Paging}>) => {
            state.isLoadingUnitTypes = true;
        },
        fetchUnitTypesBySubGroupIdSuccess: (state: UnitSubgroupState, action: PayloadAction<Pagination<UnitType>>) => {
            state.isLoadingUnitTypes = false;
            state.unitTypes = action.payload;
        },
        fetchUnitTypesBySubGroupIdError: (state: UnitSubgroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitTypes = false;
            state.error = action.payload;
        },
        resetUnitTypes: (state: UnitSubgroupState) => {
            state.unitTypes = initialState.unitTypes;
        }
    }
});

export const { fetchUnitSubgroupById, fetchUnitSubgroupByIdSuccess, fetchUnitSubgroupByIdError, fetchUnitTypesBySubGroupId, fetchUnitTypesBySubGroupIdSuccess,
    fetchUnitTypesBySubGroupIdError, resetUnitTypes }
 = unitSubgroupSlice.actions;

export const isLoadingUnitSubgroups = (state: RootState): boolean => state.unitSubgroupReducer.isLoading;
export const isLoadingUnitTypesBySubGroupId = (state: RootState): boolean => state.unitSubgroupReducer.isLoadingUnitTypes;
export const getUnitTypesBySubGroupId = (state: RootState): Pagination<UnitType> => state.unitSubgroupReducer.unitTypes;
export const getUnitSubgroup = (state: RootState): UnitSubGroup => state.unitSubgroupReducer.unitSubgroup;

export default unitSubgroupSlice.reducer;