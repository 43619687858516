import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import PricingComponentType from '../interfaces/output/pricingComponentTypeOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import PricingComponentTypeFilters from '../interfaces/filters/pricingComponentTypeFilters';

export type PricingComponentTypeState = {
    pricingComponentType: PricingComponentType;
    isLoading: boolean;
    isLoadingTypes: boolean;
    isCreating: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    pricingComponentTypes: Pagination<PricingComponentType>;
    error: HttpErrorResponse | undefined;
};

const initialState: PricingComponentTypeState = {
    pricingComponentType: {} as PricingComponentType,
    pricingComponentTypes: { content: [] as Array<PricingComponentType> } as Pagination<PricingComponentType>,
    isLoading: false,
    isLoadingTypes: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    error: undefined
};

export const pricingComponentTypeSlice = createSlice({
    name: 'pricingComponentType',

    initialState,
    reducers: {
        fetchPricingComponentTypes: (state: PricingComponentTypeState, _action: PayloadAction<{paging: Paging, filters?: PricingComponentTypeFilters}>) => {
            state.isLoadingTypes = true;
        },
        fetchPricingComponentTypesSuccess: (state: PricingComponentTypeState, action: PayloadAction<Pagination<PricingComponentType>>) => {
            state.isLoadingTypes = false;
            state.pricingComponentTypes = action.payload;
        },
        fetchPricingComponentTypesError: (state: PricingComponentTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingTypes = false;
            state.error = action.payload;
        },
        fetchPricingComponentTypeById: (state: PricingComponentTypeState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchPricingComponentTypeByIdSuccess: (state: PricingComponentTypeState, action: PayloadAction<PricingComponentType>) => {
            state.isLoading = false;
            state.pricingComponentType = action.payload;
        },
        fetchPricingComponentTypeByIdError: (state: PricingComponentTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        createPricingComponentType: (state: PricingComponentTypeState, _action: PayloadAction<PricingComponentType>) => {
            state.isCreating = true;
        },
        createPricingComponentTypeSuccess: (state: PricingComponentTypeState, action: PayloadAction<PricingComponentType>) => {
            state.isCreating = false;
            state.pricingComponentType = action.payload;
        },
        createPricingComponentTypeError: (state: PricingComponentTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updatePricingComponentType: (state: PricingComponentTypeState, _action: PayloadAction<PricingComponentType>) => {
            state.isUpdating = true;
        },
        updatePricingComponentTypeSuccess: (state: PricingComponentTypeState, action: PayloadAction<PricingComponentType>) => {
            state.isUpdating = false;
            state.pricingComponentType = action.payload;
        },
        updatePricingComponentTypeError: (state: PricingComponentTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivatePricingComponentType: (state: PricingComponentTypeState, _action: PayloadAction<PricingComponentType>) => {
            state.isDeleting = true;
        },
        deactivatePricingComponentTypeSuccess: (state: PricingComponentTypeState, action: PayloadAction<PricingComponentType>) => {
            state.isDeleting = false;
            state.pricingComponentType = action.payload;
        },
        deactivatePricingComponentTypeError: (state: PricingComponentTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetPricingComponentTypes: (state: PricingComponentTypeState) => {
            state.pricingComponentTypes = initialState.pricingComponentTypes;
        },
        resetPricingComponentType: (state: PricingComponentTypeState) => {
            state.pricingComponentType = initialState.pricingComponentType;
        }
    }
});

export const { fetchPricingComponentTypes, fetchPricingComponentTypesSuccess, fetchPricingComponentTypesError, fetchPricingComponentTypeById, fetchPricingComponentTypeByIdSuccess,
    fetchPricingComponentTypeByIdError, createPricingComponentType, createPricingComponentTypeSuccess, createPricingComponentTypeError, updatePricingComponentType, updatePricingComponentTypeError,
    updatePricingComponentTypeSuccess, deactivatePricingComponentType, deactivatePricingComponentTypeError, deactivatePricingComponentTypeSuccess, resetPricingComponentType, resetPricingComponentTypes
} = pricingComponentTypeSlice.actions;

export const isLoadingPricingComponentType = (state: RootState): boolean => state.pricingComponentTypeReducer.isLoading;
export const isLoadingPricingComponentTypes = (state: RootState): boolean => state.pricingComponentTypeReducer.isLoadingTypes;
export const getPricingComponentTypes = (state: RootState): Pagination<PricingComponentType> => state.pricingComponentTypeReducer.pricingComponentTypes;
export const getPricingComponentType = (state: RootState): PricingComponentType => state.pricingComponentTypeReducer.pricingComponentType;
export const isUpdatingPricingComponentType = (state: RootState): boolean => state.pricingComponentTypeReducer.isUpdating;
export const isDeletingPricingComponentType = (state: RootState): boolean => state.pricingComponentTypeReducer.isDeleting;
export const isCreatingPricingComponentType = (state: RootState): boolean => state.pricingComponentTypeReducer.isCreating;

export default pricingComponentTypeSlice.reducer;