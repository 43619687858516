import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Language from '../interfaces/output/language';

export default class LanguageApi {
    static fetchLanguageById = async(languageId: string): Promise<Language> => {
        const { data } = await http.get<Language>(`language/${languageId}`);

        return data;
    };

    static fetchLanguages = async(): Promise<Pagination<Language>> => {
        const { data } = await http.get<Pagination<Language>>('/language');

        return data;
    };
}
