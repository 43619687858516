import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import ServiceProduct from '../interfaces/output/serviceProduct';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ServiceProductFilters from '../interfaces/filters/serviceProductFilters';

export type ServiceProductState = {
    serviceProduct: ServiceProduct;
    isLoadingServiceProduct: boolean;
    isLoadingServiceProducts: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCreating: boolean;
    isUpdatingBulk: boolean;
    serviceProducts: Pagination<ServiceProduct>;
    error: HttpErrorResponse | undefined;
};

const initialState: ServiceProductState = {
    serviceProduct: {} as ServiceProduct,
    serviceProducts: { content: [] as Array<ServiceProduct> } as Pagination<ServiceProduct>,
    isLoadingServiceProduct: false,
    isLoadingServiceProducts: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    isUpdatingBulk: false,
    error: undefined
};

export const serviceProductSlice = createSlice({
    name: 'serviceProduct',

    initialState,
    reducers: {
        fetchServiceProducts: (state: ServiceProductState, _action: PayloadAction<{paging: Paging, filters: ServiceProductFilters}>) => {
            state.isLoadingServiceProducts = true;
        },
        fetchServiceProductsSuccess: (state: ServiceProductState, action: PayloadAction<Pagination<ServiceProduct>>) => {
            state.isLoadingServiceProducts = false;
            state.serviceProducts = action.payload;
        },
        fetchServiceProductsError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceProducts = false;
            state.error = action.payload;
        },
        fetchServiceProductById: (state: ServiceProductState, _action: PayloadAction<number>) => {
            state.isLoadingServiceProduct = true;
        },
        fetchServiceProductByIdSuccess: (state: ServiceProductState, action: PayloadAction<ServiceProduct>) => {
            state.isLoadingServiceProduct = false;
            state.serviceProduct = action.payload;
        },
        fetchServiceProductByIdError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingServiceProduct = false;
            state.error = action.payload;
        },
        createServiceProduct: (state: ServiceProductState, _action: PayloadAction<ServiceProduct>) => {
            state.isCreating = true;
        },
        createServiceProductSuccess: (state: ServiceProductState, action: PayloadAction<ServiceProduct>) => {
            state.isCreating = false;
            state.serviceProduct = action.payload;
        },
        createServiceProductError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateServiceProductsBulk: (state: ServiceProductState, _action: PayloadAction<{
                serviceProducts: Pagination<ServiceProduct>,
                serviceProductsToDelete: Pagination<ServiceProduct>
            }>) => {
            state.isUpdatingBulk = true;
        },
        updateServiceProductsBulkSuccess: (state: ServiceProductState) => {
            state.isUpdatingBulk = false;
        },
        updateServiceProductsBulkError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingBulk = false;
            state.error = action.payload;
        },
        updateServiceProduct: (state: ServiceProductState, _action: PayloadAction<ServiceProduct>) => {
            state.isUpdating = true;
        },
        updateServiceProductSuccess: (state: ServiceProductState, action: PayloadAction<ServiceProduct>) => {
            state.isUpdating = false;
            state.serviceProduct = action.payload;
        },
        updateServiceProductError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deleteServiceProduct: (state: ServiceProductState, _action: PayloadAction<ServiceProduct>) => {
            state.isDeleting = true;
        },
        deleteServiceProductSuccess: (state: ServiceProductState, action: PayloadAction<ServiceProduct>) => {
            state.isDeleting = false;
            state.serviceProduct = action.payload;
        },
        deleteServiceProductError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        promoteServiceProduct: (state: ServiceProductState, _action: PayloadAction<number>) => {
            state.isUpdating = true;
        },
        promoteServiceProductSuccess: (state: ServiceProductState, action: PayloadAction<ServiceProduct>) => {
            state.isUpdating = false;
            state.serviceProduct = action.payload;
        },
        promoteServiceProductError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        demoteServiceProduct: (state: ServiceProductState, _action: PayloadAction<number>) => {
            state.isUpdating = true;
        },
        demoteServiceProductSuccess: (state: ServiceProductState, action: PayloadAction<ServiceProduct>) => {
            state.isUpdating = false;
            state.serviceProduct = action.payload;
        },
        demoteServiceProductError: (state: ServiceProductState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetServiceProducts: (state: ServiceProductState) => {
            state.serviceProducts = initialState.serviceProducts;
        },
        resetServiceProduct: (state: ServiceProductState) => {
            state.serviceProduct = initialState.serviceProduct;
        }
    }
});

export const { fetchServiceProducts, fetchServiceProductsSuccess, fetchServiceProductsError, fetchServiceProductById, fetchServiceProductByIdSuccess, fetchServiceProductByIdError,
    createServiceProduct, createServiceProductSuccess, createServiceProductError, updateServiceProduct, updateServiceProductSuccess, updateServiceProductError, deleteServiceProduct,
    deleteServiceProductSuccess, deleteServiceProductError, resetServiceProducts, resetServiceProduct, updateServiceProductsBulk, updateServiceProductsBulkError,
    updateServiceProductsBulkSuccess, promoteServiceProduct, promoteServiceProductError, promoteServiceProductSuccess, demoteServiceProduct, demoteServiceProductError,
    demoteServiceProductSuccess } = serviceProductSlice.actions;

export const isLoadingServiceProduct = (state: RootState): boolean => state.serviceProductReducer.isLoadingServiceProduct;
export const isLoadingServiceProducts = (state: RootState): boolean => state.serviceProductReducer.isLoadingServiceProducts;
export const isUpdating = (state: RootState): boolean => state.serviceProductReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.serviceProductReducer.isDeleting;
export const isCreating = (state: RootState): boolean => state.serviceProductReducer.isCreating;
export const isUpdatingBulk = (state: RootState): boolean => state.serviceProductReducer.isUpdatingBulk;
export const getServiceProducts = (state: RootState): Pagination<ServiceProduct> => state.serviceProductReducer.serviceProducts;
export const getServiceProduct = (state: RootState): ServiceProduct => state.serviceProductReducer.serviceProduct;

export default serviceProductSlice.reducer;