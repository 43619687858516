import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import OrderUnit from '../interfaces/output/orderUnit';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class OrderUnitApi {
    static fetchOrderUnit = async(unitId: number): Promise<OrderUnit> => {
        const { data } = await http.get<OrderUnit>(`orderunits/${unitId}`);

        return data;
    };

    static fetchOrderUnitServices = async(id: number, paging: Paging): Promise<Array<OrderUnit>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/orderunits/${id}/services`);

        const { data } = await http.get<Array<OrderUnit>>(url);

        return data;
    };

    static deactivateOrderUnit = async(unitId: number): Promise<OrderUnit> => {

        const { data } = await http.delete<OrderUnit>(`orderunits/${unitId}`);

        return data;
    }

    static createUnits = async(unit: OrderUnit): Promise<OrderUnit> => {
        const newOrder = {
            logicalUnitId: unit?.logicalUnitId,
            unitNo: unit?.unitNo,
            unitTypeId: unit?.unitType?.id,
            netWeightKg: unit?.netWeightKg,
            grossWeightKg: unit?.grossWeightKg,
            departureTypeId: unit?.departureType?.id,
            departureDatetime: unit?.departureDatetime,
            customerReference: unit?.customerReference,
            importExportCodeId: unit?.importExportCode?.id,
            dangerousGoodsGroupId: unit?.dangerousGoodsGroup?.id,
            cancellationTypeId: unit?.cancellationType?.id,
            priorityTypeId: unit.priorityType?.id,
            loadedFlag: unit.loadedFlag
        };
        
        const { data } = await http.post<any, OrderUnit>(`orders/${unit.orderId}/units`, newOrder);

        return data;
    }

    static updateOrderUnit = async(orderUnit: OrderUnit): Promise<OrderUnit> => {
        const newUnit = {
            logicalUnitId: orderUnit.logicalUnitId,
            unitNo: orderUnit.unitNo,
            unitTypeId: orderUnit.unitType?.id,
            netWeightKg: orderUnit.netWeightKg,
            grossWeightKg: orderUnit.grossWeightKg,
            departureTypeId: orderUnit.departureType?.id,
            departureDatetime: orderUnit.departureDatetime,
            customerReference: orderUnit.customerReference,
            importExportCodeId: orderUnit.importExportCode?.id,
            dangerousGoodsGroupId: orderUnit.dangerousGoodsGroup?.id,
            cancellationTypeId: orderUnit.cancellationType?.id,
            orderUnitStatusId: orderUnit.orderUnitStatus?.id,
            priorityTypeId: orderUnit.priorityType?.id,
            loadedFlag: orderUnit.loadedFlag
        };
        
        const { data } = await http.put<any, OrderUnit>(`orderunits/${orderUnit.id}`, newUnit);

        return data;
    }

    static creditOrderUnit = async(id: number, createNewCredit: boolean, creditReason: string): Promise<OrderUnit> => {
        const url = urlcat('', '/orderunits/credit', {
            orderUnitId: id,
            newInvoice: createNewCredit,
            creditReason
        });

        const { data } = await http.put<any, OrderUnit>(url);

        return data;
    }
}
