import React, { forwardRef } from 'react';
import { FormControl, Grid } from '@mui/material';
import FactorSetPicker, { FactorSetPickerType } from '../../../common/picker/factorSetPicker';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import LanguageUtils from '../../../../utils/LanguageUtils';
// import FactorSetVersionPicker from '../../../common/picker/factorSetVersionPicker';
import FactorSetVersionOut from '../../../../interfaces/output/factorSetVersionOut';
import FactorSetVersionPicker from '../../../common/picker/factorSetVersionPicker';
import Loader from '../../../common/widgets/loader';

interface AddFactorSetVersionStep2Props {
    factorSetVersion: FactorSetVersionOut | undefined;
    setFactorSetVersion: (x: FactorSetVersionOut) => void;
    type: FactorSetPickerType;
    isLoading: boolean;
}

const messages = {
    factorSet: LanguageUtils.createMessage('Factor set'),
    factorSetVersion: LanguageUtils.createMessage('Factor set version')
};

const CloneFactorSetVersionStep = forwardRef((props: AddFactorSetVersionStep2Props): JSX.Element => {
    const [selectedFactorSet, setSelectedFactorSet] = React.useState<FactorSetOut | undefined>();
    const { type, isLoading } = props;

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={1} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FactorSetPicker
                            value={selectedFactorSet}
                            onChange={setSelectedFactorSet}
                            placeholder={messages.factorSet}
                            type={type}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <FactorSetVersionPicker
                            factorSet={selectedFactorSet}
                            value={props.factorSetVersion}
                            onChange={props.setFactorSetVersion}
                            placeholder={messages.factorSetVersion}
                            type={type}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});

export default CloneFactorSetVersionStep;