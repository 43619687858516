import { http } from '../utils/http';
import Status from '../interfaces/output/customerStatus';
import Pagination from '../interfaces/common/pagination';
import UserStatusOut from '../interfaces/output/userStatusOut';

export default class UserStatusApi {
    static fetchUserStatusById = async(id: string): Promise<UserStatusOut> => {
        const { data } = await http.get<Status>(`/userstatus/${id}`);

        return data;
    };

    static fetchUserStatuses = async(): Promise<Pagination<UserStatusOut>> => {
        const { data } = await http.get<Pagination<UserStatusOut>>('/userstatus');

        return data;
    };
}
