import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import PriorityType from '../interfaces/output/priorityType';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import ServiceArenaType from '../interfaces/output/serviceArenaType';
import ServiceArenaFilters from '../interfaces/filters/serviceArenaFilters';

export type ServiceArenaTypeState = {
    isLoading: boolean;
    serviceArenaTypes: Pagination<ServiceArenaType>;
    error: HttpErrorResponse | undefined;
};

const initialState: ServiceArenaTypeState = {
    serviceArenaTypes: { content: [] as Array<ServiceArenaType> } as Pagination<ServiceArenaType>,
    isLoading: false,
    error: undefined
};

export const serviceArenaTypeSlice = createSlice({
    name: 'serviceArenaType',

    initialState,
    reducers: {
        fetchServiceArenaTypes: (state: ServiceArenaTypeState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchServiceArenaTypesSuccess: (state: ServiceArenaTypeState, action: PayloadAction<Pagination<ServiceArenaType>>) => {
            state.isLoading = false;
            state.serviceArenaTypes = action.payload;
        },
        fetchServiceArenaTypesError: (state: ServiceArenaTypeState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchServiceArenaTypes, fetchServiceArenaTypesSuccess, fetchServiceArenaTypesError } = serviceArenaTypeSlice.actions;

export const isLoadingPriorityTypes = (state: RootState): boolean => state.serviceArenaTypeReducer.isLoading;
export const getServiceArenaTypes = (state: RootState): Pagination<ServiceArenaType> => state.serviceArenaTypeReducer.serviceArenaTypes;

export default serviceArenaTypeSlice.reducer;