import { Theme, IconButton, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilterComponent from './filterComponent';
import Action from '../../interfaces/common/action';
import ActionRenderer from './actionRenderer';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericTextField from './widgets/genericTextField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            },
            margin: theme.spacing(1, 1.5, 1.5, 0),
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(0.5)
            },
            '& .MuiInput-underline:before': {
                borderBottom: `1px solid ${theme.palette.primary.main}`
            },
            '&.active': { borderBottom: 'none' }
        }
    })
);

interface IQuickSearchToolbar {
    searchTerm: string;
    onSearch: (searchTerm: string) => void;
    includeFilters?: boolean;
    children?: React.ReactElement;
    actions?: Array<Action>;
}

const messages = {
    search: LanguageUtils.createMessage('SEARCH_PLACEHOLDER')
};

const QuickSearchToolbar = (props: IQuickSearchToolbar) : JSX.Element => {
    const { searchTerm, onSearch, includeFilters, children, actions } = props;
    const classes = useStyles();
    const [filterRefContainer, setFilterRefContainer] = React.useState<React.MutableRefObject<null> | null>({ current: null });
    
    return (
        <Grid container pl={2} pr={2} direction="column">
            <Grid item>
                <Grid container p={2} alignItems="flex-end">
                    <Grid item>
                        <GenericTextField<string>
                            value={searchTerm}
                            label={messages.search}
                            className={classes.textField}
                            InputProps={{
                                startAdornment: <SearchIcon fontSize="small" />,
                                endAdornment: (
                                    <IconButton
                                        title="Clear"
                                        aria-label="Clear"
                                        size="small"
                                        style={{ visibility: searchTerm ? 'visible' : 'hidden' }}
                                        onClick={() => onSearch('')}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                )
                            }}
                            onChange={(_key: string, value: string | undefined) => onSearch(value ? value : '')}
                        />
                    </Grid>
                    <Grid item mr="auto">
                        {includeFilters && <FilterComponent renderFilter={setFilterRefContainer} />}
                    </Grid>
                    <ActionRenderer actions={actions} />
                </Grid>
            </Grid>
            <Grid item>
                {filterRefContainer && children}
            </Grid>
        </Grid>
    );
};

export default QuickSearchToolbar;