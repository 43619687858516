import React, { forwardRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import { fetchProducts, getProducts, isLoadingProducts } from '../../../reducers/productReducer';
import ProductFilters from '../../../interfaces/output/productFilters';
import ProductList from '../../products/productList';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Paging from '../../../interfaces/common/paging';
import OrderUnitServiceProduct from '../../../interfaces/output/orderUnitServiceProduct';
import WorkflowUtils from '../../../utils/workflowUtils';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddOrderUnitServiceProductStep1Props {
    orderUnitServiceProduct: OrderUnitServiceProduct;
    onChange: any;
}

const AddOrderUnitServiceProductStep1 = forwardRef((props: AddOrderUnitServiceProductStep1Props, ref: any): JSX.Element => {
    const { onChange, orderUnitServiceProduct } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const products = useSelector(getProducts);
    const isLoadingProductList = useSelector(isLoadingProducts);
    WorkflowUtils.setHandle(ref, null);
    useEffect(() => {
        dispatch(fetchProducts({ paging,
            filters: {} as ProductFilters }));
    }, [dispatch]);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({ });

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchProducts({
                paging: newServerPagination,
                filters: { name: searchTerm } as ProductFilters
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchProducts({
                        paging: paging,
                        filters: { name: searchTerm } as ProductFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <ProductList
                    isLoading={isLoadingProductList}
                    rows={products}
                    componentsProps={{
                        toolbar: {
                            value: searchTerm,
                            onChange: (event: any) => setSearchTerm(event.target.value),
                            clearSearch: () => setSearchTerm('')
                        }
                    }}
                    checkSelection={(value: number) => onChange('product', { ...orderUnitServiceProduct.product,
                        id: value } )}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchProducts({
                            paging: newPage,
                            filters: { name: searchTerm } as ProductFilters
                        }));
                        setPaging(newPage);
                    }}
                    hiddenColumns={['basePrice']}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    disableLinks
                />
            </Grid>
        </Grid>
    );
});

export default AddOrderUnitServiceProductStep1;