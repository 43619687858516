import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import PricelistVersionProductApi from '../api/pricelistVersionProductApi';
import PricelistVersionProductFilters from '../interfaces/filters/pricelistVersionProductFilters';
import PricelistVersionProduct from '../interfaces/output/pricelistVersionProduct';
import {
    createPricelistVersionProductSuccess, deactivatePricelistVersionProductError, deactivatePricelistVersionProductSuccess, fetchPricelistVersionProductByIdSuccess,
    fetchPricelistVersionProductsSuccess, updatePricelistVersionProductSuccess, fetchPricelistVersionProductsError
} from '../reducers/pricelistVersionProductReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchPricelistVersionProduct(action: PayloadAction<string>) {
    const pricelistVersionProductId = action.payload;
    const response: PricelistVersionProduct = yield call(PricelistVersionProductApi.fetchPricelistVersionProductById, pricelistVersionProductId);
    yield put(fetchPricelistVersionProductByIdSuccess(response));
}

function* doFetchPricelistVersionProducts(action: PayloadAction<{ paging: Paging, filters: PricelistVersionProductFilters }>) {
    const { paging, filters } = action.payload;
    try {
        const response: Pagination<PricelistVersionProduct> = yield call(PricelistVersionProductApi.fetchPricelistVersionProducts, paging, filters);
        yield put(fetchPricelistVersionProductsSuccess(response));
    }
    catch (error: unknown) {
        yield put(fetchPricelistVersionProductsError(error as HttpErrorResponse));
    }
}

function* createPricelistVersionProduct(action: PayloadAction<PricelistVersionProduct>) {
    const pricelistVersionProduct = action.payload;
    const response: PricelistVersionProduct = yield call(PricelistVersionProductApi.createPricelistVersionProduct, pricelistVersionProduct);
    yield put(createPricelistVersionProductSuccess(response));
}

function* updatePricelistVersionProduct(action: PayloadAction<PricelistVersionProduct>) {
    const pricelistVersionProduct = action.payload;
    const response: PricelistVersionProduct = yield call(PricelistVersionProductApi.updatePricelistVersionProduct, pricelistVersionProduct);
    yield put(updatePricelistVersionProductSuccess(response));
}

function* deactivatePricelistVersionProduct(action: PayloadAction<number>) {
    const pricelistVersionProductId = action.payload;
    try {
        const response: PricelistVersionProduct = yield call(PricelistVersionProductApi.deactivateProductPricelistVersionProduct, pricelistVersionProductId);
        yield put(deactivatePricelistVersionProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivatePricelistVersionProductError(error as HttpErrorResponse));
    }
}
export default [
    takeLatest('pricelistVersionProduct/fetchPricelistVersionProductById', doFetchPricelistVersionProduct),
    takeLatest('pricelistVersionProduct/fetchPricelistVersionProducts', doFetchPricelistVersionProducts),
    takeLatest('pricelistVersionProduct/createPricelistVersionProduct', createPricelistVersionProduct),
    takeLatest('pricelistVersionProduct/updatePricelistVersionProduct', updatePricelistVersionProduct),
    takeLatest('pricelistVersionProduct/deactivatePricelistVersionProduct', deactivatePricelistVersionProduct)
];

