import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { FormType } from '../../../constants/constants';
import { resetOrderUnitServiceProduct } from '../../../reducers/orderUnitServiceProductReducer';
import AddOrderUnitServiceProductForm from './orderUnitServiceProductForm';

interface IAddOrderUnitServiceProductProps {
    classes: any;
    theme: Theme;
    match: any;
    resetOrderUnitServiceProduct: any;
    steps: Array<number>;
    orderUnitServiceId?: number;
    orderUnitServiceProductId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetOrderUnitServiceProduct: () => dispatch(resetOrderUnitServiceProduct())
});

const mapStoreToProps = (store: RootState, ownProps: IAddOrderUnitServiceProductProps) => {
    return {
        orderUnitServiceId: ownProps.match.params?.orderUnitServiceId ? Number(ownProps.match.params?.orderUnitServiceId) : undefined,
        orderUnitServiceProductId: ownProps.match.params?.orderUnitServiceProductId ? Number(ownProps.match.params?.orderUnitServiceProductId) : undefined
    };
};

class AddOrderUnitServiceProduct extends Component<IAddOrderUnitServiceProductProps> {

    componentWillUnmount() {
        const { resetOrderUnitServiceProduct } = this.props;

        resetOrderUnitServiceProduct();
    }

    render() {
        const { classes, steps, orderUnitServiceId, orderUnitServiceProductId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddOrderUnitServiceProductForm orderUnitServiceId={orderUnitServiceId} orderUnitServiceProductId={orderUnitServiceProductId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddOrderUnitServiceProduct));