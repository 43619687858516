import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderUnit from '../interfaces/output/orderUnit';
import OrderUnitService from '../interfaces/output/orderUnitService';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type OrderUnitState = {
    orderUnit: OrderUnit;
    services: Pagination<OrderUnitService>;
    isLoadingOrderUnit: boolean;
    isLoadingOrderUnitServices: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
    isCreatingUnits: boolean;
    isCrediting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: OrderUnitState = {
    orderUnit: {} as OrderUnit,
    services: { content: [] as Array<OrderUnitService> } as Pagination<OrderUnitService>,
    isLoadingOrderUnit: false,
    isLoadingOrderUnitServices: false,
    isUpdating: false,
    isDeleting: false,
    isCreatingUnits: false,
    isCrediting: false,
    error: undefined
};

export const orderUnitSlice = createSlice({
    name: 'orderUnit',

    initialState,
    reducers: {
        fetchOrderUnitById: (state: OrderUnitState, _action: PayloadAction<number>) => {
            state.isLoadingOrderUnit = true;
        },
        fetchOrderUnitByIdSuccess: (state: OrderUnitState, action: PayloadAction<OrderUnit>) => {
            state.isLoadingOrderUnit = false;
            state.orderUnit = action.payload;
        },
        fetchOrderUnitByIdError: (state: OrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrderUnit = false;
            state.error = action.payload;
        },
        fetchServiceByUnitId: (state: OrderUnitState, _action: PayloadAction<{paging: Paging, unitId: number}>) => {
            state.isLoadingOrderUnitServices = true;
        },
        fetchServiceByUnitIdSuccess: (state: OrderUnitState, action: PayloadAction<Pagination<OrderUnitService>>) => {
            state.isLoadingOrderUnitServices = false;
            state.services = action.payload;
        },
        fetchServiceByUnitIdError: (state: OrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrderUnitServices = false;
            state.error = action.payload;
        },
        createUnit: (state: OrderUnitState, _action: PayloadAction<OrderUnit>) => {
            state.isCreatingUnits = true;
        },
        createUnitSuccess: (state: OrderUnitState, action: PayloadAction<OrderUnit>) => {
            state.isCreatingUnits = false;
            state.orderUnit = action.payload;
        },
        createUnitError: (state: OrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingUnits = false;
            state.error = action.payload;
        },
        updateOrderUnit: (state: OrderUnitState, _action: PayloadAction<OrderUnit>) => {
            state.isUpdating = true;
        },
        updateOrderUnitSuccess: (state: OrderUnitState, action: PayloadAction<OrderUnit>) => {
            state.isUpdating = false;
            state.orderUnit = action.payload;
        },
        updateOrderUnitError: (state: OrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateOrderUnit: (state: OrderUnitState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateOrderUnitSuccess: (state: OrderUnitState, action: PayloadAction<OrderUnit>) => {
            state.isDeleting = false;
            state.orderUnit = action.payload;
        },
        deactivateOrderUnitError: (state: OrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        creditOrderUnit: (state: OrderUnitState, _action: PayloadAction<{id: number, createNewCredit: boolean, creditReason: string}>) => {
            state.isCrediting = true;
        },
        creditOrderUnitSuccess: (state: OrderUnitState, action: PayloadAction<OrderUnit>) => {
            state.isCrediting = false;
        },
        creditOrderUnitError: (state: OrderUnitState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCrediting = false;
            state.error = action.payload;
        },
        resetOrderUnit: (state: OrderUnitState) => {
            state.orderUnit = initialState.orderUnit;
        }
    }
});

export const { fetchOrderUnitById, fetchOrderUnitByIdSuccess, fetchOrderUnitByIdError, fetchServiceByUnitId, fetchServiceByUnitIdSuccess, fetchServiceByUnitIdError,
    updateOrderUnit, updateOrderUnitSuccess, updateOrderUnitError, resetOrderUnit, deactivateOrderUnit, createUnit, createUnitSuccess, createUnitError,
    deactivateOrderUnitSuccess, deactivateOrderUnitError, creditOrderUnit, creditOrderUnitSuccess, creditOrderUnitError } = orderUnitSlice.actions;

export const isLoadingOrderUnit = (state: RootState): boolean => state.orderUnitReducer.isLoadingOrderUnit;
export const isLoadingOrderUnitServices = (state: RootState): boolean => state.orderUnitReducer.isLoadingOrderUnitServices;
export const getOrderUnit = (state: RootState): OrderUnit => state.orderUnitReducer.orderUnit;
export const getUnitServices = (state: RootState): Pagination<OrderUnitService> => state.orderUnitReducer.services;
export const isUpdatingOrderUnit = (state: RootState): boolean => state.orderUnitReducer.isUpdating;
export const isDeletingOrderUnit = (state: RootState): boolean => state.orderUnitReducer.isDeleting;
export const isCreatingUnits = (state: RootState): boolean => state.orderUnitReducer.isCreatingUnits;
export const isCrediting = (state: RootState): boolean => state.orderUnitReducer.isCrediting;

export default orderUnitSlice.reducer;