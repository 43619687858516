import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetFactorSetCalendars, resetFactorSetCalendar } from '../../../../reducers/factorSetCalendarReducer';
import { FormType } from '../../../../constants/constants';
import AddFactorSetCalendarForm from './addFactorSetCalendarForm';

interface IAddFactorSetCalendarProps {
    classes: any;
    theme: Theme;
    match: any;
    resetFactorSetCalendars: () => any;
    resetFactorSetCalendar: () => any;
    steps: Array<number>;
    factorSetCalendarId?: number;
    factorSetVersionId?: number;
    type: FormType;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetFactorSetCalendars: () => dispatch(resetFactorSetCalendars()),
    resetFactorSetCalendar: () => dispatch(resetFactorSetCalendar())
});

const mapStoreToProps = (store: RootState, ownProps: IAddFactorSetCalendarProps) => {
    return {
        factorSetCalendarId: ownProps.match.params?.calendarId ? Number(ownProps.match.params?.calendarId) : undefined,
        factorSetVersionId: ownProps.match.params?.factorSetVersionId ? Number(ownProps.match.params?.factorSetVersionId) : undefined
    };
};

class AddFactorSetCalendar extends Component<IAddFactorSetCalendarProps> {
    componentWillUnmount() {
        const { resetFactorSetCalendars, resetFactorSetCalendar } = this.props;

        resetFactorSetCalendars();
        resetFactorSetCalendar();
    }

    render() {
        const { classes, steps, factorSetCalendarId, type, factorSetVersionId } = this.props;

        return (
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <AddFactorSetCalendarForm factorSetCalendarId={factorSetCalendarId} steps={steps} type={type} factorSetVersionId={factorSetVersionId} />
                </CardContent>
            </Card>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddFactorSetCalendar));