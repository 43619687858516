import React, { forwardRef, useEffect } from 'react';
import { FormControl, Grid, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FactorSetWeightLineOutput from '../../../../interfaces/output/factorSetWeightLineOut';
import Loader from '../../../common/widgets/loader';
import LanguageUtils from '../../../../utils/LanguageUtils';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericTextField from '../../../common/widgets/genericTextField';
import { isCreatingWeightLine, isLoadingWeightLine, isUpdatingWeightLine } from '../../../../reducers/weightLineReducer';
import { fetchUnitTypesBySubGroupId, getUnitTypesBySubGroupId, isLoadingUnitTypesBySubGroupId, resetUnitTypes } from '../../../../reducers/unitSubgroupReducer';
import { fetchUnitGroups, fetchUnitGroupSubgroups, getUnitGroups, getUnitSubGroups, isLoadingUnitSubgroups, resetSubgroups } from '../../../../reducers/unitGroupReducer';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import UnitType from '../../../../interfaces/output/unitType';
import UnitSubGroup from '../../../../interfaces/output/unitSubGroup';
import UnitGroup from '../../../../interfaces/output/unitGroup';
import { ensure } from '../../../../utils/arrayUtils';
import PageUtils from '../../../../utils/pageUtils';
import FactorSetWeightLineStep1Validation from '../line/validation/factorSetWeightLineStep1Validation';
import Currency from '../../../../interfaces/output/currency';
import { fetchCurrencies, getCurrencies } from '../../../../reducers/currencyReducer';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../../reducers/unitTypeReducer';

interface WeightLineFormStep1Props {
    weightLine: FactorSetWeightLineOutput;
    onChange: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    UNIT_TYPE: 'unitType',
    NAME: 'name',
    FROM_WEIGHT: 'fromWeightKg',
    TO_WEIGHT: 'toWeightKg',
    FACTOR: 'factor',
    SURCHARGE_CURRENCY: 'surchargeCurrency',
    SURCHARGE: 'surcharge'
};

const messages = {
    fromWeight: LanguageUtils.createMessage('From weight kg'),
    toWeight: LanguageUtils.createMessage('To weight kg'),
    name: LanguageUtils.createMessage('Factor set line name'),
    surcharge: LanguageUtils.createMessage('Surcharge'),
    factor: LanguageUtils.createMessage('Factor'),
    subgroup: LanguageUtils.createMessage('Subgroup'),
    unitGroup: LanguageUtils.createMessage('Unit group'),
    unittype: LanguageUtils.createMessage('Unit type'),
    surchargeCurrency: LanguageUtils.createMessage('Surcharge currency'),
    fromWeightHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FROM_WEIGHT_HELPTEXT'),
    toWeightHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_TO_WEIGHT_HELPTEXT'),
    surchargeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_HELPTEXT'),
    surchargeCurrencyHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_SURCHARGE_CURRENCY_HELPTEXT'),
    unitGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_GROUP_HELPTEXT'),
    unitSubGroupHelptext: LanguageUtils.createMessage('FACTOR_SET_UNIT_SUB_GROUP_HELPTEXT'),
    unitTypeHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_UNIT_TYPE_HELPTEXT'),
    factorHelptext: LanguageUtils.createMessage('FACTOR_SET_LINE_FACTOR_HELPTEXT')
};

const WeightLineFormStep1 = forwardRef((props: WeightLineFormStep1Props, ref: any): JSX.Element => {
    const { weightLine, onChange, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const isLoadingObjects = [useSelector(isCreatingWeightLine), useSelector(isLoadingWeightLine), useSelector(isUpdatingWeightLine)];
    const unitGroups = useSelector(getUnitGroups).content;
    const subGroups = useSelector(getUnitSubGroups).content;
    const unitTypesBySubGroupId = useSelector(getUnitTypesBySubGroupId).content;
    const unitTypes = useSelector(getUnitTypes).content;
    const currencies = useSelector(getCurrencies).content;
    const prevWeightLine = WorkflowUtils.usePrevious<FactorSetWeightLineOutput>(weightLine);
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCurrencies({ paging }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchUnitGroups({ paging }));
        if(weightLine?.id) {
            dispatch(fetchUnitGroupSubgroups({ groupId: ensure(weightLine.unitType?.unitGroupId),
                paging }));
            if(weightLine.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(weightLine.unitType?.unitSubGroupId),
                    paging }));
            }
            else {
                dispatch(fetchUnitTypes({ paging }));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevWeightLine) {
            if(weightLine?.unitType?.unitGroupId) {
                dispatch(fetchUnitGroupSubgroups({ groupId: ensure(weightLine.unitType?.unitGroupId),
                    paging }));
                if(prevWeightLine.id === weightLine.id) {
                    onChange('unitType', { ...weightLine.unitType,
                        unitSubGroupId: null,
                        id: null });
                }
            }
            else {
                dispatch(resetSubgroups());
                dispatch(resetUnitTypes());
            }
        }
    }, [weightLine?.unitType?.unitGroupId]);

    useEffect(() => {
        if(prevWeightLine) {
            if(weightLine?.unitType?.unitSubGroupId) {
                dispatch(fetchUnitTypesBySubGroupId({ id: ensure(weightLine.unitType?.unitSubGroupId),
                    paging }));
                if(prevWeightLine.id === weightLine.id) {
                    onChange('unitType', { ...weightLine.unitType,
                        id: null });
                }
            }
            else {
                dispatch(resetUnitTypes());
            }
        }
    }, [weightLine?.unitType?.unitSubGroupId]);

    useEffect(() => {
        if(!weightLine?.unitType?.unitSubGroupId) {
            dispatch(fetchUnitTypes({ paging }));
        }
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.NAME: {
                isValid = FactorSetWeightLineStep1Validation.validateName(weightLine.name);
                break;
            }
            case ATTRIBUTES.UNIT_TYPE: {
                isValid = weightLine.surcharge ? FactorSetWeightLineStep1Validation.validateInputNumber(weightLine.unitType?.id) : true;
                break;
            }
            case ATTRIBUTES.FACTOR: {
                isValid = FactorSetWeightLineStep1Validation.validateInputNumber(weightLine.factor);
                break;
            }
            case ATTRIBUTES.FROM_WEIGHT: {
                isValid = FactorSetWeightLineStep1Validation.validateRequiredNumberWithMaxLength(weightLine.fromWeightKg);
                break;
            }
            case ATTRIBUTES.TO_WEIGHT: {
                isValid = FactorSetWeightLineStep1Validation.validateRequiredNumberWithMaxLength(weightLine.toWeightKg);
                break;
            }
            case ATTRIBUTES.SURCHARGE_CURRENCY: {
                isValid = weightLine.surcharge ? FactorSetWeightLineStep1Validation.validateInputNumber(weightLine.surchargeCurrency?.id) : true;
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={weightLine.name}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.NAME)}
                        required
                        helperText={messages.name}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitGroup>
                            options={unitGroups}
                            value={weightLine.unitType?.unitGroupId}
                            compareFn={(o: UnitGroup) => o.id === weightLine.unitType?.unitGroupId}
                            onChange={(obj: UnitGroup | null) => onChange('unitType', { ...weightLine.unitType,
                                unitGroupId: obj?.id })}
                            placeholder={messages.unitGroup}
                            helperText={messages.unitGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitSubGroup>
                            options={subGroups}
                            value={weightLine.unitType?.id}
                            compareFn={(o: UnitSubGroup) => o.id === weightLine.unitType?.unitSubGroupId}
                            onChange={(obj: UnitSubGroup | null) => onChange('unitType', { ...weightLine.unitType,
                                unitSubGroupId: obj?.id })}
                            placeholder={messages.subgroup}
                            isLoading={useSelector(isLoadingUnitSubgroups)}
                            helperText={messages.unitSubGroupHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<UnitType>
                            options={weightLine.unitType?.unitSubGroupId ? unitTypesBySubGroupId : unitTypes}
                            value={weightLine.unitType?.id}
                            compareFn={(o: UnitType) => o.id === weightLine.unitType?.id}
                            onChange={(obj: UnitType | null) => onChange('unitType', { ...weightLine.unitType,
                                id: obj?.id })}
                            placeholder={messages.unittype}
                            isLoading={weightLine.unitType?.unitSubGroupId ? useSelector(isLoadingUnitTypesBySubGroupId) : useSelector(isLoadingUnitTypes)}
                            error={setValidationState(ATTRIBUTES.UNIT_TYPE)}
                            required={weightLine.surcharge ? true : false}
                            helperText={messages.unitTypeHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="fromWeightKg"
                        label={messages.fromWeight}
                        type="number"
                        value={weightLine.fromWeightKg}
                        onChange={onChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>
                        }}
                        error={setValidationState(ATTRIBUTES.FROM_WEIGHT)}
                        required
                        helperText={messages.fromWeightHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="toWeightKg"
                        label={messages.toWeight}
                        type="number"
                        value={weightLine.toWeightKg}
                        onChange={onChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kg</InputAdornment>
                        }}
                        error={setValidationState(ATTRIBUTES.TO_WEIGHT)}
                        required
                        helperText={messages.toWeightHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="factor"
                        label={messages.factor}
                        type="number"
                        value={weightLine.factor}
                        onChange={onChange}
                        error={setValidationState(ATTRIBUTES.FACTOR)}
                        required
                        helperText={messages.factorHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="surcharge"
                        label={messages.surcharge}
                        type="number"
                        value={weightLine.surcharge}
                        onChange={onChange}
                        helperText={messages.surchargeHelptext}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<Currency>
                            options={currencies}
                            value={weightLine.surchargeCurrency}
                            onChange={(obj: Currency | null) => onChange('surchargeCurrency', obj)}
                            placeholder={messages.surchargeCurrency}
                            error={setValidationState(ATTRIBUTES.SURCHARGE_CURRENCY)}
                            required={weightLine.surcharge ? true : false}
                            helperText={messages.surchargeCurrencyHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default WeightLineFormStep1;