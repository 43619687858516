import React, { useEffect, forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import QuickSearchToolbar from '../../common/quickSearchToolbar';
import Paging from '../../../interfaces/common/paging';
import PricelistList from '../../pricelists/pricelistList';
import { fetchPriceLists, getPriceLists, isLoadingPriceLists } from '../../../reducers/priceListReducer';
import PriceListsFilters from '../../../interfaces/output/priceListFilters';
import WorkflowUtils from '../../../utils/workflowUtils';
import { fetchProductById, getProduct, isLoadingProduct } from '../../../reducers/productReducer';
import { ensure } from '../../../utils/arrayUtils';
import PricelistVersionProduct from '../../../interfaces/output/pricelistVersionProduct';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';

interface AddPricelistVersionProductStep1Props {
    onChange: (pricelistVersionProduct: PricelistVersionProduct) => void;
    productId?: number;
    pricelistVersionProduct: PricelistVersionProduct
}

const AddPricelistVersionProductStep1 = forwardRef((props: AddPricelistVersionProductStep1Props, ref: any): JSX.Element => {
    const { onChange, productId, pricelistVersionProduct } = props;
    const [paging, setPaging] = React.useState<Paging>(PageUtils.getDefaultPaging());
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const pricelists = useSelector(getPriceLists);
    const propsProduct = useSelector(getProduct);
    const propsIsLoadingProduct = useSelector(isLoadingProduct);
    const prevIsLoadingProduct = WorkflowUtils.usePrevious<boolean>(propsIsLoadingProduct);
    const isLoading = useSelector(isLoadingPriceLists) || propsIsLoadingProduct;
    WorkflowUtils.setHandle(ref, null);
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{
        field: '',
        sort: '' as GridSortDirection
    } as GridSortItem]);
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        dispatch(fetchProductById(ensure(productId)));
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingProduct === true && !propsIsLoadingProduct ) {
            dispatch(fetchPriceLists({ paging,
                filters: { unitGroupId: propsProduct.unitGroup?.id } as PriceListsFilters }));
        }
    }, [propsIsLoadingProduct]);

    const _onSortModelChange = (newModel: GridSortModel) => {

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newServerPagination = {
                ...paging,
                sort: newModel
            };

            dispatch(fetchPriceLists({
                paging: newServerPagination,
                filters: { name: searchTerm,
                    unitGroupId: propsProduct.unitGroup?.id } as PriceListsFilters
            }));

            setPaging(newServerPagination);
            setSortModel(newModel);
        }
    };

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <QuickSearchToolbar searchTerm={searchTerm} onSearch={(searchTerm: string) => {
                    setSearchTerm(searchTerm);
                    dispatch(fetchPriceLists({
                        paging: paging,
                        filters: { name: searchTerm,
                            unitGroupId: propsProduct.unitGroup?.id } as PriceListsFilters
                    }));
                }
                }
                />
            </Grid>
            <Grid item xs={12}>
                <PricelistList
                    isLoading={isLoading}
                    rows={pricelists}
                    hiddenColumns={['currency', 'unitSubGroup', 'discount']}
                    checkSelection={(value: number) => onChange({ ...pricelistVersionProduct,
                        pricelist: { ...pricelistVersionProduct.pricelist,
                            id: value } })}
                    onPageChange={(nextPage: number, nextSize: number) => {
                        const newPage = {
                            ...paging,
                            page: nextPage,
                            size: nextSize
                        };
                        dispatch(fetchPriceLists({
                            paging: newPage,
                            filters: { name: searchTerm,
                                unitGroupId: propsProduct.unitGroup?.id } as PriceListsFilters
                        }));
                        setPaging(newPage);
                    }}
                    onSortModelChange={(sortModel: GridSortModel) => _onSortModelChange(sortModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                        setColumnVisibilityModel(newModel)
                    }
                    disableLinks
                />
            </Grid>
        </Grid>
    );
});

export default AddPricelistVersionProductStep1;
  