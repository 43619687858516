import { http } from '../utils/http';
import Paging from '../interfaces/common/paging';
import urlcat from 'urlcat';
import FactorSetOut from '../interfaces/output/factorSetOut';
import Pagination from '../interfaces/common/pagination';
import FactorSetWeightLineOutput from '../interfaces/output/factorSetWeightLineOut';
import WeightLineFilters from '../components/factorSets/weight/line/weightLineFilters';
import FactorSetWeightLineInput from '../interfaces/input/factorSetWeightLineIn';
import FactorSetIn from '../interfaces/input/factorSetIn';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import FactorSetVersionIn from '../interfaces/input/factorSetVersionIn';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class FactorSetWeightsApi {
    static fetchFactorSetWeightById = async(factorSetId: number, versionId?: number): Promise<FactorSetOut> => {
        const versionQueryString = versionId ? { versionId: versionId } : {};
        const url = urlcat('', `factorsets/weight/${factorSetId}`, versionQueryString);
        
        const { data } = await http.get<FactorSetOut>(url);
        
        return data;
    };

    static fetchFactorSetWeights = async(paging: Paging, filters: WeightLineFilters): Promise<Array<FactorSetOut>> => {
        
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/factorsets/weight');

        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static fetchFactorSetWeightsLines = async(factorSetId: number, paging: Paging, filters: WeightLineFilters): Promise<Pagination<FactorSetWeightLineOutput>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, `factorsets/weight/${factorSetId}/lines`);
        
        const { data } = await http.get<Pagination<FactorSetWeightLineOutput>>(url);

        return data;
    };

    static fetchFactorSetWeightVersions = async(factorSetId: number, paging: Paging): Promise<Array<FactorSetVersionOut>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, `/factorsets/weight/${factorSetId}/versions`);
       
        const { data } = await http.get<Array<FactorSetOut>>(url);

        return data;
    };

    static createFactorSetWeight = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSet: FactorSetIn = {
            name: factorSet?.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            noFactors: true,
            default: factorSet?.default ?? false
        };

        const { data } = await http.post<FactorSetIn, FactorSetOut>('factorsets/weight', newFactorSet);

        return data;
    }

    static createWeightVersion = async(factorSet: FactorSetOut): Promise<FactorSetVersionOut> => {
        const url = `/factorsets/weight/${factorSet.id}/versions`;

        const newVersion: FactorSetVersionIn = {
            validFromDate: factorSet.version?.validFromDate,
            validToDate: factorSet.version?.validToDate,
            statusId: 1 //set status to active
        };

        const { data } = await http.post<FactorSetVersionIn, FactorSetVersionOut>(url, newVersion);

        return data;
    }

    static createFactorSetWeightsLine = async(factorSetLine: FactorSetWeightLineOutput): Promise<FactorSetWeightLineOutput> => {
        const newLine: FactorSetWeightLineInput = {
            name: factorSetLine.name,
            unitTypeId: factorSetLine.unitType?.id,
            factor: factorSetLine.factor,
            surcharge: factorSetLine.surcharge,
            fromWeightKg: factorSetLine.fromWeightKg,
            toWeightKg: factorSetLine.toWeightKg
        };

        const { data } = await http.post<FactorSetWeightLineInput, FactorSetWeightLineOutput>(`factorsets/weight/${factorSetLine.factorSetId}/lines`, newLine);

        return data;
    }

    static updateFactorSetWeight = async(factorSet: FactorSetOut): Promise<FactorSetOut> => {
        const newFactorSet: FactorSetIn = {
            name: factorSet.name,
            productArenaId: factorSet.productArena?.id,
            unitSubGroupId: factorSet.unitSubGroup?.id,
            productTypeId: factorSet.productType?.id,
            default: factorSet?.default ?? false
        };
        
        const { data } = await http.put<FactorSetIn, FactorSetOut>(`factorsets/weight/${factorSet.id}`, newFactorSet);

        return data;
    }

    static deactivateFactorSetWeight = async(id: number): Promise<FactorSetOut> => {
        const { data } = await http.delete<FactorSetOut>(`factorsets/weight/${id}`);

        return data;
    }
}