import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import PriceListUnitTypeApi from '../api/priceListUnitTypeApi';
import PricelistUnitTypeOut from '../interfaces/output/pricelistUnitType';
import { createPriceListUnitTypeError, createPriceListUnitTypeSuccess, deactivatePriceListUnitTypeError, deactivatePriceListUnitTypeSuccess, fetchPriceListUnitTypeByIdError,
    fetchPriceListUnitTypeByIdSuccess, fetchPriceListUnitTypesError, fetchPriceListUnitTypesSuccess, updatePriceListUnitTypeError, updatePriceListUnitTypeSuccess }
    from '../reducers/pricelistUnitTypeReducer';

function* doFetchPriceListUnitType(action: PayloadAction<string>) {
    const customerId = action.payload;
    try {
        const response: PricelistUnitTypeOut = yield call(PriceListUnitTypeApi.fetchPriceListUnitTypeById, customerId);
        yield put(fetchPriceListUnitTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListUnitTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchPriceListUnitTypes() {
    try {
        const response: Pagination<PricelistUnitTypeOut> = yield call(PriceListUnitTypeApi.fetchPriceListUnitTypes);
        yield put(fetchPriceListUnitTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriceListUnitTypesError(error as HttpErrorResponse));
    }
}

function* doCreatePriceListUnitType(action: PayloadAction<PricelistUnitTypeOut>) {
    const pricingcomponent = action.payload;

    try {
        const response: PricelistUnitTypeOut = yield call(PriceListUnitTypeApi.createPriceListUnitType, pricingcomponent);

        yield put(createPriceListUnitTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(createPriceListUnitTypeError(error as HttpErrorResponse));
    }
}

function* doUpdatePriceListUnitType(action: PayloadAction<PricelistUnitTypeOut>) {
    const pricingcomponent = action.payload;

    try {
        const response: PricelistUnitTypeOut = yield call(PriceListUnitTypeApi.updatePriceListUnitType, pricingcomponent);

        yield put(updatePriceListUnitTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriceListUnitTypeError(error as HttpErrorResponse));
    }
}

function* doDeactivatePriceListUnitType(action: PayloadAction<number>) {
    const pricingcomponentId = action.payload;
    
    try {
        const response: PricelistUnitTypeOut = yield call(PriceListUnitTypeApi.deactivatePriceListUnitType, pricingcomponentId);

        yield put(deactivatePriceListUnitTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivatePriceListUnitTypeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('pricelistunittype/fetchPriceListUnitTypeById', doFetchPriceListUnitType),
    takeLatest('pricelistunittype/fetchPriceListUnitTypes', doFetchPriceListUnitTypes),
    takeLatest('pricelistunittype/createPriceListUnitType', doCreatePriceListUnitType),
    takeLatest('pricelistunittype/updatePriceListUnitType', doUpdatePriceListUnitType),
    takeLatest('pricelistunittype/deactivatePriceListUnitType', doDeactivatePriceListUnitType)
];

