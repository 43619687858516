import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GenericStep from '../../../interfaces/common/genericStep';
import UrlConstants from '../../../constants/UrlConstants';
import WorkflowUtils from '../../../utils/workflowUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import { FormType } from '../../../constants/constants';
import ProductArena from '../../../interfaces/output/productArena';
import {
    createProductArena, fetchProductArenaById, getProductArena, isCreatingProductArena,
    isLoadingProductArenas, isUpdatingProductArena, updateProductArena
} from '../../../reducers/productArenaReducer';
import ProductArenaFormStep1 from './productArenaFormStep1';
import ProductArenaStep1Validation from './validation/productArenaStep1Validation';
import GenericStepper from '../../common/widgets/genericStepper';

interface ITerminalFormProps {
    productArenaId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit product arena'),
    add: LanguageUtils.createMessage('Add product arena')
};

export default function sProductArenaForm(props: ITerminalFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { productArenaId, steps, type } = props;
    const [productArena, setProductArena] = React.useState<ProductArena>({ id: productArenaId } as ProductArena);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propProductArena = useSelector(getProductArena);
    const propsIsUpdatingProductArena = useSelector(isUpdatingProductArena);
    const prevIsUpdatingProductArena = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingProductArena);
    const propsIsCreatingProductArena = useSelector(isCreatingProductArena);
    const prevIsCreatingProductArena = WorkflowUtils.usePrevious<boolean>(propsIsCreatingProductArena);
    const propsIsLoadingProductArena = useSelector(isLoadingProductArenas);
    const prevIsLoadingProductArena = WorkflowUtils.usePrevious<boolean>(propsIsLoadingProductArena);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];

        switch (lastStep) {
            case 1:
                if((prevIsUpdatingProductArena === true && !propsIsUpdatingProductArena) || (prevIsCreatingProductArena && !propsIsLoadingProductArena)) {
                    setRedirectCondition(true);
                }
                break;
        }

        return () => setRedirectCondition(false);
    }, [propsIsUpdatingProductArena, prevIsCreatingProductArena]);

    useEffect(() => {
        if(productArenaId) {
            dispatch(fetchProductArenaById(productArenaId));
        }
    }, [dispatch]);

    useEffect(() => {
        if(prevIsLoadingProductArena && !propsIsLoadingProductArena) {
            setProductArena(propProductArena);
        }

    }, [propsIsLoadingProductArena]);

    const onChange = (attribute: string, value: any) => {
        const newProductArena = { ...productArena } as ProductArena;
        (newProductArena as any)[attribute] = value;

        setProductArena(newProductArena);
    };

    const getSteps = (): GenericStep[] => [
        {
            id: 1,
            content: <ProductArenaFormStep1 onChange={onChange} productArena={productArena} />,
            validationFn: () => ProductArenaStep1Validation.validatProductArena(productArena),
            onNext: () => {
                if(!productArena.id) {
                    dispatch(createProductArena(productArena));
                }
                else {
                    dispatch(updateProductArena(productArena));
                }
            }
        } as GenericStep
    ];

    const getTitle = (): any => {
        switch (type) {
            case FormType.Add:
                return messages.add;
            case FormType.Edit:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = (): string => {
        switch (type) {
            case FormType.Add:
                return `/${UrlConstants.PRODUCT_ARENAS}`;

            case FormType.Edit:
                return `/${UrlConstants.PRODUCT_ARENAS}/${productArena.id}`;

            default:
                throw new Error('Redirect to required');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
