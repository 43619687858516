import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../setup';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import DangerousGoodsLineFilters from '../components/factorSets/dangerousGoods/line/dangerousGoodsLineFilters';
import FactorSetDangerousGoodsLineOut from '../interfaces/output/factorSetDangerousGoodsLineOut';

export type DangerousGoodLineState = {
    factorSetDangerousGoodLine: FactorSetDangerousGoodsLineOut;
    factorSetVersionLines: Pagination<FactorSetDangerousGoodsLineOut>;
    isLoadingDangerousGoodLine: boolean;
    isLoadingLines: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: DangerousGoodLineState = {
    factorSetDangerousGoodLine: {} as FactorSetDangerousGoodsLineOut,
    factorSetVersionLines: { content: [] as Array<FactorSetDangerousGoodsLineOut> } as Pagination<FactorSetDangerousGoodsLineOut>,
    isLoadingDangerousGoodLine: false,
    isLoadingLines: false,
    isUpdating: false,
    isDeleting: false,
    isCreating: false,
    error: undefined
};

export const dangerousGoodsLineSlice = createSlice({
    name: 'factorSetLine',

    initialState,
    reducers: {
        fetchFactorSetDangerousGoodLineById: (state: DangerousGoodLineState, _action: PayloadAction<number>) => {
            state.isLoadingDangerousGoodLine = true;
        },
        fetchFactorSetDangerousGoodLineByIdSuccess: (state: DangerousGoodLineState, action: PayloadAction<FactorSetDangerousGoodsLineOut>) => {
            state.isLoadingDangerousGoodLine = false;
            state.factorSetDangerousGoodLine = action.payload;
        },
        fetchFactorSetDangerousGoodLineByIdError: (state: DangerousGoodLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingDangerousGoodLine = false;
            state.error = action.payload;
        },
        fetchDangerousGoodVersionLines: (state: DangerousGoodLineState, _action: PayloadAction<{paging: Paging, filters: DangerousGoodsLineFilters}>) => {
            state.isLoadingLines = true;
        },
        fetchDangerousGoodVersionLinesSuccess: (state: DangerousGoodLineState, action: PayloadAction<Pagination<FactorSetDangerousGoodsLineOut>>) => {
            state.isLoadingLines = false;
            state.factorSetVersionLines = action.payload;
        },
        fetchDangerousGoodVersionLinesError: (state: DangerousGoodLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingLines = false;
            state.error = action.payload;
        },
        createDangerousGoodVersionLine: (state: DangerousGoodLineState, _action: PayloadAction<FactorSetDangerousGoodsLineOut>) => {
            state.isCreating = true;
        },
        createDangerousGoodVersionLineSuccess: (state: DangerousGoodLineState, action: PayloadAction<FactorSetDangerousGoodsLineOut>) => {
            state.isCreating = false;
            state.factorSetDangerousGoodLine = action.payload;
        },
        createDangerousGoodVersionLineError: (state: DangerousGoodLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        updateFactorSetDangerousGoodLine: (state: DangerousGoodLineState, _action: PayloadAction<FactorSetDangerousGoodsLineOut>) => {
            state.isUpdating = true;
        },
        updateFactorSetDangerousGoodLineSuccess: (state: DangerousGoodLineState, action: PayloadAction<FactorSetDangerousGoodsLineOut>) => {
            state.isUpdating = false;
            state.factorSetDangerousGoodLine = action.payload;
        },
        updateFactorSetDangerousGoodLineError: (state: DangerousGoodLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateFactorSetDangerousGoodLine: (state: DangerousGoodLineState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateFactorSetDangerousGoodLineSuccess: (state: DangerousGoodLineState, action: PayloadAction<FactorSetDangerousGoodsLineOut>) => {
            state.isDeleting = false;
            state.factorSetDangerousGoodLine = action.payload;
        },
        deactivateFactorSetDangerousGoodLineError: (state: DangerousGoodLineState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetFactorSetDangerousGoodLine: (state: DangerousGoodLineState) => {
            state.factorSetDangerousGoodLine = initialState.factorSetDangerousGoodLine;
        },
        resetDangerousGoodVersionLines: (state: DangerousGoodLineState) => {
            state.factorSetVersionLines = initialState.factorSetVersionLines;
        }
    }
});

export const { fetchFactorSetDangerousGoodLineById, fetchFactorSetDangerousGoodLineByIdSuccess, fetchFactorSetDangerousGoodLineByIdError, fetchDangerousGoodVersionLines,
    fetchDangerousGoodVersionLinesError, updateFactorSetDangerousGoodLine, updateFactorSetDangerousGoodLineSuccess, updateFactorSetDangerousGoodLineError, deactivateFactorSetDangerousGoodLine,
    resetDangerousGoodVersionLines, deactivateFactorSetDangerousGoodLineError, deactivateFactorSetDangerousGoodLineSuccess, resetFactorSetDangerousGoodLine, fetchDangerousGoodVersionLinesSuccess,
    createDangerousGoodVersionLine, createDangerousGoodVersionLineSuccess, createDangerousGoodVersionLineError } = dangerousGoodsLineSlice.actions;

export const isLoadingDangerousGoodLine = (state: RootState): boolean => state.dangerousGoodsLineReducer.isLoadingDangerousGoodLine;
export const isLoadingFactorSetDangerousGoodLines = (state: RootState): boolean => state.dangerousGoodsLineReducer.isLoadingLines;
export const isUpdatingDangerousGoodLine = (state: RootState): boolean => state.dangerousGoodsLineReducer.isUpdating;
export const isDeletingDangerousGoodLine = (state: RootState): boolean => state.dangerousGoodsLineReducer.isDeleting;
export const getFactorSetDangerousGoodLine = (state: RootState): FactorSetDangerousGoodsLineOut => state.dangerousGoodsLineReducer.factorSetDangerousGoodLine;
export const getDangerousGoodLines = (state: RootState): Pagination<FactorSetDangerousGoodsLineOut> => state.dangerousGoodsLineReducer.factorSetVersionLines;
export const isCreatingDangerousGoodLine = (state: RootState): boolean => state.dangerousGoodsLineReducer.isCreating;

export default dangerousGoodsLineSlice.reducer;