import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import PricelistVersionStatus from '../interfaces/output/pricelistVersionStatus';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type PriceListVersionStatusState = {
    priceListVersionStatus: PricelistVersionStatus;
    priceListVersionStatuses: Pagination<PricelistVersionStatus>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: PriceListVersionStatusState = {
    priceListVersionStatus: {} as PricelistVersionStatus,
    priceListVersionStatuses: { content: [] as Array<PricelistVersionStatus> } as Pagination<PricelistVersionStatus>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const pricelistVersionStatusSlice = createSlice({
    name: 'priceListVersionStatus',

    initialState,
    reducers: {
        fetchPriceListVersionStatuses: (state: PriceListVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchPriceListVersionStatusesSuccess: (state: PriceListVersionStatusState, action: PayloadAction<Pagination<PricelistVersionStatus>>) => {
            state.isLoading = false;
            state.priceListVersionStatuses = action.payload;
        },
        fetchPriceListVersionStatusesError: (state: PriceListVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchPriceListVersionStatusById: (state: PriceListVersionStatusState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchPriceListVersionStatusByIdSuccess: (state: PriceListVersionStatusState, action: PayloadAction<PricelistVersionStatus>) => {
            state.isLoading = false;
            state.priceListVersionStatus = action.payload;
        },
        fetchPriceListVersionStatusByIdError: (state: PriceListVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updatePriceListVersionStatus: (state: PriceListVersionStatusState, action: PayloadAction<PricelistVersionStatus>) => {
            state.isUpdating = true;
            state.priceListVersionStatus = action.payload;
        },
        updatePriceListVersionStatusSuccess: (state: PriceListVersionStatusState, action: PayloadAction<PricelistVersionStatus>) => {
            state.isUpdating = false;
            state.priceListVersionStatus = action.payload;
        },
        updatePriceListVersionStatusError: (state: PriceListVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivatePriceListVersionStatus: (state: PriceListVersionStatusState, action: PayloadAction<PricelistVersionStatus>) => {
            state.isDeleted = true;
            state.priceListVersionStatus = action.payload;
        },
        deactivatePriceListVersionStatusSuccess: (state: PriceListVersionStatusState, action: PayloadAction<PricelistVersionStatus>) => {
            state.isDeleted = false;
            state.priceListVersionStatus = action.payload;
        },
        deactivatePriceListVersionStatusError: (state: PriceListVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetPriceListVersionStatuses: (state: PriceListVersionStatusState) => {
            state.priceListVersionStatuses = initialState.priceListVersionStatuses;
        }
    }
});

export const { fetchPriceListVersionStatusById, fetchPriceListVersionStatusByIdSuccess, fetchPriceListVersionStatusByIdError, updatePriceListVersionStatus,
    updatePriceListVersionStatusSuccess, updatePriceListVersionStatusError, fetchPriceListVersionStatuses, fetchPriceListVersionStatusesSuccess,
    fetchPriceListVersionStatusesError, deactivatePriceListVersionStatus, deactivatePriceListVersionStatusSuccess, deactivatePriceListVersionStatusError,
    resetPriceListVersionStatuses } = pricelistVersionStatusSlice.actions;

export const isLoadingPriceListVersionStatus = (state: RootState): boolean => state.pricelistVersionStatusReducer.isLoading;
export const isUpdating = (state: RootState): boolean => state.pricelistVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.pricelistVersionStatusReducer.isDeleted;
export const getPriceListVersionStatus = (state: RootState): PricelistVersionStatus => state.pricelistVersionStatusReducer.priceListVersionStatus;
export const getPriceListVersionStatuses = (state: RootState): Pagination<PricelistVersionStatus> => state.pricelistVersionStatusReducer.priceListVersionStatuses;

export default pricelistVersionStatusSlice.reducer;