import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderUnitService from '../interfaces/output/orderUnitService';
import OrderUnitServiceProduct from '../interfaces/output/orderUnitServiceProduct';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import Paging from '../interfaces/common/paging';

export type UnitServiceState = {
    unitService: OrderUnitService;
    unitServiceProducts: Pagination<OrderUnitServiceProduct>;
    unitServiceProduct: OrderUnitServiceProduct;
    isLoadingUnitService: boolean;
    isLoadingUnitServiceProducts: boolean;
    isCreatingUnitServiceProduct: boolean;
    isCreatingService: boolean;
    isUpdating: boolean;
    isDeleting: boolean;
    isCrediting: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: UnitServiceState = {
    unitService: {} as OrderUnitService,
    unitServiceProducts: { content: [] as Array<OrderUnitServiceProduct> } as Pagination<OrderUnitServiceProduct>,
    unitServiceProduct: {} as OrderUnitServiceProduct,
    isLoadingUnitService: false,
    isLoadingUnitServiceProducts: false,
    isCreatingUnitServiceProduct: false,
    isCreatingService: false,
    isUpdating: false,
    isDeleting: false,
    isCrediting: false,
    error: undefined
};

export const unitServiceSlice = createSlice({
    name: 'orderUnitService',

    initialState,
    reducers: {
        fetchOrderUnitServicesById: (state: UnitServiceState, _action: PayloadAction<number>) => {
            state.isLoadingUnitService = true;
        },
        fetchOrderUnitServicesByIdSuccess: (state: UnitServiceState, action: PayloadAction<OrderUnitService>) => {
            state.isLoadingUnitService = false;
            state.unitService = action.payload;
        },
        fetchOrderUnitServicesByIdError: (state: UnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitService = false;
            state.error = action.payload;
        },
        fetchProductsByOrderUnitServiceId: (state: UnitServiceState, _action: PayloadAction<{paging: Paging, id: number}>) => {
            state.isLoadingUnitServiceProducts = true;
        },
        fetchProductsByOrderUnitServiceIdSuccess: (state: UnitServiceState, action: PayloadAction<Pagination<OrderUnitServiceProduct>>) => {
            state.isLoadingUnitServiceProducts = false;
            state.unitServiceProducts = action.payload;
        },
        fetchProductsByOrderUnitServiceIdError: (state: UnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitServiceProducts = false;
            state.error = action.payload;
        },
        createOrderUnitService: (state: UnitServiceState, _action: PayloadAction<OrderUnitService>) => {
            state.isCreatingService = true;
        },
        createOrderUnitServiceSuccess: (state: UnitServiceState, action: PayloadAction<OrderUnitService>) => {
            state.isCreatingService = false;
            state.unitService = action.payload;
        },
        createOrderUnitServiceError: (state: UnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingService = false;
            state.error = action.payload;
        },
        updateOrderUnitServices: (state: UnitServiceState, _action: PayloadAction<OrderUnitService>) => {
            state.isUpdating = true;
        },
        updateOrderUnitServicesSuccess: (state: UnitServiceState, action: PayloadAction<OrderUnitService>) => {
            state.isUpdating = false;
            state.unitService = action.payload;
        },
        updateOrderUnitServicesError: (state: UnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateOrderUnitService: (state: UnitServiceState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateOrderUnitServiceSuccess: (state: UnitServiceState, action: PayloadAction<OrderUnitService>) => {
            state.isDeleting = false;
            state.unitService = action.payload;
        },
        deactivateOrderUnitServiceError: (state: UnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        creditOrderUnitService: (state: UnitServiceState, _action: PayloadAction<{id: number, createNewCredit: boolean, creditReason: string}>) => {
            state.isCrediting = true;
        },
        creditOrderUnitServiceSuccess: (state: UnitServiceState, action: PayloadAction<OrderUnitService>) => {
            state.isCrediting = false;
        },
        creditOrderUnitServiceError: (state: UnitServiceState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCrediting = false;
            state.error = action.payload;
        },
        resetOrderUnitService: (state: UnitServiceState) => {
            state.unitService = initialState.unitService;
        }
    }
});

export const { fetchOrderUnitServicesById, fetchOrderUnitServicesByIdSuccess, fetchOrderUnitServicesByIdError, fetchProductsByOrderUnitServiceId, fetchProductsByOrderUnitServiceIdSuccess,
    updateOrderUnitServices, updateOrderUnitServicesSuccess, updateOrderUnitServicesError, resetOrderUnitService, fetchProductsByOrderUnitServiceIdError, deactivateOrderUnitService,
    deactivateOrderUnitServiceSuccess, deactivateOrderUnitServiceError, createOrderUnitService, createOrderUnitServiceSuccess, createOrderUnitServiceError,
    creditOrderUnitService, creditOrderUnitServiceSuccess, creditOrderUnitServiceError } = unitServiceSlice.actions;

export const isLoadingUnitService = (state: RootState): boolean => state.unitServiceReducer.isLoadingUnitService;
export const isLoadingUnitServiceProducts = (state: RootState): boolean => state.unitServiceReducer.isLoadingUnitServiceProducts;
export const isDeletingUnitService = (state: RootState): boolean => state.unitServiceReducer.isDeleting;
export const getUnitServiceProducts = (state: RootState): Pagination<OrderUnitServiceProduct> => state.unitServiceReducer.unitServiceProducts;
export const getOrderUnitService = (state: RootState): OrderUnitService => state.unitServiceReducer.unitService;
export const isUpdatingOrderUnitService = (state: RootState): boolean => state.unitServiceReducer.isUpdating;
export const isCreatingUnitService = (state: RootState): boolean => state.unitServiceReducer.isCreatingService;
export const isCrediting = (state: RootState): boolean => state.unitServiceReducer.isCrediting;

export default unitServiceSlice.reducer;