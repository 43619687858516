import React, { Grid } from '@mui/material';
import { Button } from '@mui/material';
import GenericAutocomplete from './widgets/genericAutocomplete';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import CustomerAgreementFilters from '../../interfaces/filters/customerAgreementFilters';
import Action from '../../interfaces/common/action';
import ActionRenderer from './actionRenderer';

interface IFilterProps {
    filters: CustomerAgreementFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

interface ValidOptionsProps {
    value: number | undefined;
    name: string;
}

const messages = {
    status: LanguageUtils.createMessage('Status'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function CustomerAgreementFiltersComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    
    const onlyValidOptions = (): ValidOptionsProps[] => {
        return [
            { value: 0,
                name: 'Expired' },
            { value: 1,
                name: 'Valid' },
            { value: 2,
                name: 'Future' },
            { value: undefined,
                name: 'ALL' }
        ];
        
    };

    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container p={2} pt={1} alignItems="flex-end">
                    <Grid item xs={3} mr="auto">
                        <GenericAutocomplete<ValidOptionsProps>
                            options={onlyValidOptions()}
                            value={filters.validity}
                            onChange={(obj: ValidOptionsProps | null) => onChange('validity', obj?.value)}
                            placeholder={messages.status}
                            compareFn={(o: ValidOptionsProps) => o.value === filters.validity}
                        />
                    </Grid>
                    <ActionRenderer actions={actions} />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}