import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetWeightVersionApi from '../api/factorSetWeightVersionApi';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { cloneWeightVersionError, cloneWeightVersionSuccess, fetchWeightVersionByIdError,
    fetchWeightVersionByIdSuccess, fetchWeightVersionsError, fetchWeightVersionsSuccess, updateWeightVersionError, updateWeightVersionStatusError,
    updateWeightVersionStatusSuccess, updateWeightVersionSuccess } from '../reducers/factorSetWeightVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchFactorSetWeightVersion(action: PayloadAction<string>) {
    const factorSetVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetWeightVersionApi.fetchFactorSetWeightVersionById, factorSetVersionId);

        yield put(fetchWeightVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchWeightVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetWeightVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetWeightVersionApi.fetchFactorSetWeightVersions, id, paging);
        yield put(fetchWeightVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchWeightVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetWeightVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const factorSetVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetWeightVersionApi.updateFactorSetWeightVersionStatus, factorSetVersion);

        yield put(updateWeightVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateWeightVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetWeightVersion(action: PayloadAction<FactorSetVersionOut>) {
    const factorSetVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetWeightVersionApi.updateFactorSetWeightVersion, factorSetVersion);

        yield put(updateWeightVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateWeightVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetWeightVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetWeightVersionApi.cloneFactorSetWeightVersion, toVersionId, fromVersionId);

        yield put(cloneWeightVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(cloneWeightVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchWeightVersionById', doFetchFactorSetWeightVersion),
    takeLatest('factorSetVersion/fetchWeightVersions', doFetchFactorSetWeightVersions),
    takeLatest('factorSetVersion/updateWeightVersionStatus', doUpdateFactorSetWeightVersionStatus),
    takeLatest('factorSetVersion/updateWeightVersion', doUpdateFactorSetWeightVersion),
    takeLatest('factorSetVersion/cloneWeightVersion', doCloneFactorSetWeightVersion)
];