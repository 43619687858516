import { put, takeLatest, call, all, delay } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import ServiceProduct from '../interfaces/output/serviceProduct';
import ServiceProductApi from '../api/serviceProductApi';
import {
    createServiceProductError, createServiceProductSuccess, deleteServiceProductError, deleteServiceProductSuccess,
    demoteServiceProductError,
    demoteServiceProductSuccess,
    fetchServiceProductByIdError, fetchServiceProductByIdSuccess, fetchServiceProductsError, fetchServiceProductsSuccess,
    promoteServiceProductError,
    promoteServiceProductSuccess,
    updateServiceProductError, updateServiceProductsBulkError, updateServiceProductsBulkSuccess, updateServiceProductSuccess
} from '../reducers/serviceProductReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ServiceProductFilters from '../interfaces/filters/serviceProductFilters';

function* doFetchServiceProduct(action: PayloadAction<string>) {
    const serviceProductId = action.payload;
    try {
        const response: ServiceProduct = yield call(ServiceProductApi.fetchServiceProductById, serviceProductId);
        yield put(fetchServiceProductByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceProductByIdError(error as HttpErrorResponse));
    }
}

function* doFetchServiceProducts(action: PayloadAction<{ paging: Paging, filters: ServiceProductFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ServiceProduct> = yield call(ServiceProductApi.fetchServiceProducts, paging, filters);
        yield put(fetchServiceProductsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceProductsError(error as HttpErrorResponse));
    }
}

function* doUpdateServiceProduct(action: PayloadAction<ServiceProduct>) {
    const serviceProduct = action.payload;

    try {
        const response: ServiceProduct = yield call(ServiceProductApi.updateServiceProduct, serviceProduct);

        yield put(updateServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateServiceProductError(error as HttpErrorResponse));
    }
}

function* doCreateServiceProduct(action: PayloadAction<ServiceProduct>) {
    const serviceProduct = action.payload;

    try {
        const response: ServiceProduct = yield call(ServiceProductApi.createServiceProduct, serviceProduct);

        yield put(createServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(createServiceProductError(error as HttpErrorResponse));
    }
}

function* doUpdateServiceProductsBulk(action: PayloadAction<{
    serviceProducts: Pagination<ServiceProduct>,
    serviceProductsToDelete: Pagination<ServiceProduct>
}>) {
    const { serviceProducts, serviceProductsToDelete } = action.payload;

    try {
        const deleteCalls = serviceProductsToDelete.content.map(serviceProduct => call(ServiceProductApi.deleteServiceProduct, serviceProduct));
        if(deleteCalls) {
            yield all(deleteCalls);
        }

        const newServiceProducts = serviceProducts.content.map((serviceProduct: ServiceProduct, index) => {
            const newServiceProduct = {
                ...serviceProduct,
                sequenceNumber: serviceProduct.sequenceNumber ?? index + 1
            };

            return newServiceProduct;
        });

        const createCalls = newServiceProducts.filter(sp => !sp.id).map((serviceProduct) => call(ServiceProductApi.createServiceProduct, serviceProduct));
        if(createCalls) {
            yield all(createCalls);
        }

        //delay to trigger loading
        if(!deleteCalls.length && !createCalls.length) {
            yield delay(500);
        }

        yield put(updateServiceProductsBulkSuccess());
    }

    catch (error: unknown) {
        yield put(updateServiceProductsBulkError(error as HttpErrorResponse));
    }
}

function* doDeleteServiceProduct(action: PayloadAction<ServiceProduct>) {
    const serviceProduct = action.payload;

    try {
        const response: ServiceProduct = yield call(ServiceProductApi.deleteServiceProduct, serviceProduct);

        yield put(deleteServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(deleteServiceProductError(error as HttpErrorResponse));
    }
}

function* doPromoteServiceProduct(action: PayloadAction<number>) {
    const serviceProductId = action.payload;

    try {
        const response: ServiceProduct = yield call(ServiceProductApi.promoteServiceProduct, serviceProductId);

        yield put(promoteServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(promoteServiceProductError(error as HttpErrorResponse));
    }
}

function* doDemoteServiceProduct(action: PayloadAction<number>) {
    const serviceProductId = action.payload;

    try {
        const response: ServiceProduct = yield call(ServiceProductApi.demoteServiceProduct, serviceProductId);

        yield put(demoteServiceProductSuccess(response));
    }

    catch (error: unknown) {
        yield put(demoteServiceProductError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('serviceProduct/fetchServiceProductById', doFetchServiceProduct),
    takeLatest('serviceProduct/fetchServiceProducts', doFetchServiceProducts),
    takeLatest('serviceProduct/updateServiceProduct', doUpdateServiceProduct),
    takeLatest('serviceProduct/createServiceProduct', doCreateServiceProduct),
    takeLatest('serviceProduct/deleteServiceProduct', doDeleteServiceProduct),
    takeLatest('serviceProduct/updateServiceProductsBulk', doUpdateServiceProductsBulk),
    takeLatest('serviceProduct/promoteServiceProduct', doPromoteServiceProduct),
    takeLatest('serviceProduct/demoteServiceProduct', doDemoteServiceProduct)
];