import { List } from 'immutable';
import InputLengthConstants from '../../../constants/inputLengthConstants';
import PriceList from '../../../interfaces/output/priceList';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';
import Pagination from '../../../interfaces/common/pagination';

const inputsMaxLengths = {
    name: InputLengthConstants.String200,
    vatId: InputLengthConstants.String9
};

const messages = {
    nameAreadyExists: LanguageUtils.createMessage('Price list name error'),
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class PricelistStep1Validation extends ValidationUtils {
    static validatePricelistForm(pricelist: PriceList, priceLists: Pagination<PriceList> | undefined): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            currency,
            seller,
            type,
            unitGroup,
            unitSubGroup,
            productArena,
            productType,
            pricingComponentType
        } = pricelist;

        const requiredFields =
            this.validateName(name) &&
            this.validateInputNumber(currency?.id) &&
            this.validateInputNumber(seller?.id) &&
            this.validateInputNumber(type?.id) &&
            this.validateInputNumber(unitSubGroup?.id) &&
            this.validateInputNumber(unitGroup?.id) &&
            this.validateInputNumber(unitSubGroup?.id) &&
            this.validateInputNumber(productArena?.id) &&
            this.validateInputNumber(productType?.id) &&
            this.validateInputNumber(pricingComponentType?.id);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        if(!!priceLists && priceLists?.totalElements > 0) {
            if(priceLists?.content?.find(pl => pl?.id !== pricelist?.id && pl?.name?.toLowerCase() === name?.toLowerCase())) {
                validationModel.isValid = false;
                validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.nameAreadyExists);
            }
        }

        return validationModel;
    }

    static validateName = (inputString?: string): boolean => {
        return this.validateInputStringLength(inputString, {
            inputLength: inputsMaxLengths.name
        });
    }
}
