import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { fetchServiceArenaTypesError, fetchServiceArenaTypesSuccess } from '../reducers/serviceArenaTypeReducer';
import ServiceArenaTypeApi from '../api/serviceArenaTypeApi';
import ServiceArenaType from '../interfaces/output/serviceArenaType';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';

function* doFetchServiceArenaTypes(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<ServiceArenaType> = yield call(ServiceArenaTypeApi.fetchServiceTypeArenas, paging);
        yield put(fetchServiceArenaTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServiceArenaTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('serviceArenaType/fetchServiceArenaTypes', doFetchServiceArenaTypes)
];

