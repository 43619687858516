import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { FormControl, Grid } from '@mui/material';
import Loader from '../../../common/widgets/loader';
import WorkflowUtils from '../../../../utils/workflowUtils';
import { useDispatch, useSelector } from 'react-redux';
import { isCreatingCalendar, isCreatingVersion, isLoadingFactorSetCalendar, isUpdatingCalendar } from '../../../../reducers/factorSetCalendarReducer';
import { isUpdatingVersion } from '../../../../reducers/factorSetCalendarVersionReducer';
import GenericDatePicker from '../../../common/widgets/genericDatePicker';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import FactorSetVersionStatusOut from '../../../../interfaces/output/factorSetVersionStatusOut';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import { fetchCalendarVersionStatuses, getCalendarVersionStatuses, isLoadingCalendarVersionStatus } from '../../../../reducers/calendarVersionStatusReducer';
import PageUtils from '../../../../utils/pageUtils';
import FactorSetStep2Validation from '../../validations/factorSetStep2Validation';

interface AddCalendarStep2Props {
    factorSet: FactorSetOut;
    onChangeVersion: any;
    showValidationError?: boolean;
}

const ATTRIBUTES = {
    VALID_FROM: 'validFrom',
    VALID_TO: 'validTo',
    STATUS: 'status'
};

const messages = {
    validFrom: LanguageUtils.createMessage('Valid from'),
    validTo: LanguageUtils.createMessage('Valid to'),
    status: LanguageUtils.createMessage('Status'),
    validFromHelptext: LanguageUtils.createMessage('FACTOR_SET_VALID_FROM_HELPTEXT'),
    validToHelpText: LanguageUtils.createMessage('FACTOR_SET_VALID_TO_HELPTEXT'),
    statusHelpText: LanguageUtils.createMessage('FACTOR_SET_STATUS_HELPTEXT')
};

const AddCalendarStep2 = forwardRef((props: AddCalendarStep2Props, ref: any): JSX.Element => {
    const { factorSet, onChangeVersion, showValidationError } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const factorSetVersionStatuses = useSelector(getCalendarVersionStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingFactorSetCalendar), useSelector(isCreatingVersion), useSelector(isUpdatingVersion),
        useSelector(isCreatingCalendar), useSelector(isUpdatingCalendar), useSelector(isLoadingCalendarVersionStatus)] as Array<boolean>;
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchCalendarVersionStatuses({ paging }));
    }, [dispatch]);

    const setValidationState = (attribute: string) => {
        let isValid = true;

        if(!showValidationError || !attribute) {
            return undefined;
        }

        switch (attribute) {
            case ATTRIBUTES.STATUS: {
                isValid = FactorSetStep2Validation.validateRequiredStringWithMaxLength(factorSet.version?.status?.name);
                break;
            }
            case ATTRIBUTES.VALID_FROM: {
                isValid = FactorSetStep2Validation.validateRequiredDate(factorSet.version?.validFromDate) &&
                FactorSetStep2Validation.validateDateFormat(factorSet?.version?.validFromDate) &&
                FactorSetStep2Validation.validateInputDateStart(factorSet);
                break;
            }
            case ATTRIBUTES.VALID_TO: {
                isValid = FactorSetStep2Validation.validateInputDateEnd(factorSet) &&
                FactorSetStep2Validation.validateDateFormat(factorSet?.version?.validToDate);
                break;
            }
        }

        return !isValid;
    };

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validFromDate"
                            label={messages.validFrom}
                            value={factorSet?.version?.validFromDate}
                            onChange={onChangeVersion}
                            type="start"
                            error={setValidationState(ATTRIBUTES.VALID_FROM)}
                            required
                            helperText={messages.validFromHelptext}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericDatePicker
                            name="validToDate"
                            label={messages.validTo}
                            value={factorSet?.version?.validToDate}
                            onChange={onChangeVersion}
                            type="end"
                            error={setValidationState(ATTRIBUTES.VALID_TO)}
                            helperText={messages.validToHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetVersionStatusOut>
                            options={factorSetVersionStatuses}
                            value={factorSet.version?.status}
                            onChange={(obj: FactorSetVersionStatusOut | null) => onChangeVersion('status', obj)}
                            placeholder={messages.status}
                            error={setValidationState(ATTRIBUTES.STATUS)}
                            required
                            helperText={messages.statusHelpText}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddCalendarStep2;