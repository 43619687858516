import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import ProductType from '../interfaces/output/productType';
import ProductTypeApi from '../api/productTypesApi';
import { createProductTypeError, createProductTypeSuccess, fetchProductTypeByIdError, fetchProductTypeByIdSuccess,
    fetchProductTypesError, fetchProductTypesSuccess } from '../reducers/productTypesReducer';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchProductType(action: PayloadAction<string>) {
    const productTypeId = action.payload;
    try {
        const response: ProductType = yield call(ProductTypeApi.fetchProductTypeById, productTypeId);
        yield put(fetchProductTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchProductTypes(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<ProductType> = yield call(ProductTypeApi.fetchProductTypes, paging);
        yield put(fetchProductTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchProductTypesError(error as HttpErrorResponse));
    }
}

function* doCreateProductType(action: PayloadAction<ProductType>) {
    const productType = action.payload;

    try {
        const response: ProductType = yield call(ProductTypeApi.createProductType, productType);

        yield put(createProductTypeSuccess(response));
    }

    catch (error: unknown) {
        yield put(createProductTypeError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('productType/fetchProductTypeById', doFetchProductType),
    takeLatest('productType/fetchProductTypes', doFetchProductTypes),
    takeLatest('productType/createProductType', doCreateProductType)];

