import React, { useEffect } from 'react';
import { FormControl, Button, Autocomplete, Grid, TextField } from '@mui/material';
import Customer from '../../interfaces/output/customer';
import CustomerFilters from '../../interfaces/output/filters';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import LanguageUtils from '../../utils/LanguageUtils';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomers, getCustomers, isLoadingCustomers } from '../../reducers/customerReducer';
import { FormattedMessage, useIntl } from 'react-intl';
import PaymentDeadline from '../../interfaces/output/paymentDeadline';
import InvoiceType from '../../interfaces/output/invoiceType';
import { fetchInvoiceTypes, getInvoiceTypes } from '../../reducers/invoiceTypeReducer';
import TableUtils from '../../utils/tableUtils';
import GenericTextField from '../common/widgets/genericTextField';
import InvoiceFilters from '../../interfaces/filters/invoiceFilters';
import { fetchInvoiceFormats, getInvoiceFormats } from '../../reducers/invoiceFormatReducer';
import InvoiceStatusOut from '../../interfaces/output/invoiceStatusOut';
import InvoiceFormatOutput from '../../interfaces/output/invoiceFormatOutput';
import { fetchInvoiceStatuses, getInvoiceStatuses } from '../../reducers/invoiceStatusReducer';
import { fetchPaymentDeadlines, getPaymentDeadlines } from '../../reducers/paymentDeadlineReducer';
import Action from '../../interfaces/common/action';
import ActionRenderer from '../common/actionRenderer';

interface IFilterProps {
    filters: InvoiceFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    hiddenColumns?: Array<string>;
    actions?: Array<Action>;
}

const messages = {
    invoiceNumber: LanguageUtils.createMessage('Invoice number'),
    customer: LanguageUtils.createMessage('Customer'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    invoiceStatus: LanguageUtils.createMessage('Status'),
    invoiceFormat: LanguageUtils.createMessage('Invoice format'),
    paymentDeadline: LanguageUtils.createMessage('Payment deadline'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters'),
    d365_invoiceNumber: LanguageUtils.createMessage('D365 Invoice number')
};

export default function InvoiceFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, hiddenColumns, actions } = props;
    const [inputValue, setInputValue] = React.useState('');
    const customerFilters = {
        name: inputValue
    } as CustomerFilters;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const invoiceFormats = useSelector(getInvoiceFormats).content;
    const customers = useSelector(getCustomers).content;
    const paymentDeadlines = useSelector(getPaymentDeadlines).content;
    const invoiceTypes = useSelector(getInvoiceTypes).content;
    const invoiceStatuses = useSelector(getInvoiceStatuses).content;
    const propsIsLoadingCustomers = useSelector(isLoadingCustomers);
    const { formatMessage } = useIntl();

    useEffect(() => {
        dispatch(fetchPaymentDeadlines());
        dispatch(fetchInvoiceFormats({ paging }));
        dispatch(fetchInvoiceTypes({ paging }));
        dispatch(fetchInvoiceStatuses({ paging }));
    }, [dispatch]);
    
    return (
        <Grid container pr={2} pl={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number | undefined | null>
                                name="invoiceNumber"
                                label={messages.invoiceNumber}
                                onChange={onChange}
                                value={filters.invoiceNumber}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="d365_InvoiceNumber"
                                label={messages.d365_invoiceNumber}
                                onChange={onChange}
                                value={filters.d365_InvoiceNumber}
                            />
                        </FormControl>
                    </Grid>
                    {!TableUtils.hideColumn('customer', hiddenColumns) &&
                        <Grid item xs>
                            <Autocomplete
                                value={customers.find(customer => customer.id === filters.customerId)}
                                onChange={(e, value: any) => onChange('customerId', value.id)}
                                id="controllable-states-demo"
                                getOptionLabel={(option: Customer) => option.name}
                                filterSelectedOptions
                                inputValue={inputValue}
                                onInputChange={(event, newInputvalue: any) => {
                                    setInputValue(newInputvalue);
                                    if(newInputvalue.length > 2) {
                                        dispatch(fetchCustomers({ paging,
                                            filters: customerFilters,
                                            throttle: true }));
                                    }
                                }}
                                filterOptions={(x) => x}
                                options={customers}
                                loading={propsIsLoadingCustomers}
                                renderInput={(params) => <TextField {...params} label={formatMessage(messages.customer)} variant="standard" />}
                            />
                        </Grid>
                    }
                    <Grid item xs>
                        <GenericAutocomplete<InvoiceFormatOutput>
                            options={invoiceFormats}
                            value={filters.invoiceFormatId}
                            onChange={(obj: InvoiceFormatOutput | null) => onChange('invoiceFormatId', obj?.id)}
                            placeholder={messages.invoiceFormat}
                            compareFn={(o: InvoiceFormatOutput) => o.id === filters.invoiceFormatId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<InvoiceStatusOut>
                            options={invoiceStatuses}
                            value={filters.invoiceStatusId}
                            onChange={(obj: InvoiceStatusOut | null) => onChange('invoiceStatusId', obj?.id)}
                            placeholder={messages.invoiceStatus}
                            compareFn={(o: InvoiceStatusOut) => o.id === filters.invoiceStatusId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<InvoiceType>
                            options={invoiceTypes}
                            value={filters.invoiceTypeId}
                            onChange={(obj: InvoiceType | null) => onChange('invoiceTypeId', obj?.id)}
                            placeholder={messages.invoiceType}
                            compareFn={(o: InvoiceType) => o.id === filters.invoiceTypeId}
                        />
                    </Grid>
                    {!TableUtils.hideColumn('vatCodeId', hiddenColumns) &&
                        <Grid item xs>
                            <GenericAutocomplete<PaymentDeadline>
                                options={paymentDeadlines}
                                value={filters.paymentDeadlineId}
                                onChange={(obj: PaymentDeadline | null) => onChange('paymentDeadlineId', obj?.id)}
                                placeholder={messages.paymentDeadline}
                                compareFn={(o: PaymentDeadline) => o.id === filters.paymentDeadlineId}
                            />
                        </Grid>
                    }
                    <ActionRenderer actions={actions} />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}