import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Country from '../interfaces/output/countryOut';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CountryState = {
    country: Country;
    isLoading: boolean;
    countries: Pagination<Country>;
    error: HttpErrorResponse | undefined;
};

const initialState: CountryState = {
    country: {} as Country,
    countries: { content: [] as Array<Country> } as Pagination<Country>,
    isLoading: false,
    error: undefined
};

export const countrySlice = createSlice({
    name: 'country',

    initialState,
    reducers: {
        fetchCountries: (state: CountryState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchCountriesSuccess: (state: CountryState, action: PayloadAction<Pagination<Country>>) => {
            state.isLoading = false;
            state.countries = action.payload;
        },
        fetchCountriesError: (state: CountryState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCountryById: (state: CountryState, _action: PayloadAction<string>) => {
            state.isLoading = true;
        },
        fetchCountryByIdSuccess: (state: CountryState, action: PayloadAction<Country>) => {
            state.isLoading = false;
            state.country = action.payload;
        },
        fetchCountryByIdError: (state: CountryState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchCountries, fetchCountriesSuccess, fetchCountriesError, fetchCountryById, fetchCountryByIdSuccess, fetchCountryByIdError } = countrySlice.actions;

export const isLoadingCountry = (state: RootState): boolean => state.countryReducer.isLoading;
export const getCountries = (state: RootState): Pagination<Country> => state.countryReducer.countries;
export const getCountry = (state: RootState): Country => state.countryReducer.country;

export default countrySlice.reducer;