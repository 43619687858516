/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import UrlConstants from '../../../constants/UrlConstants';
import { Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from '@mui/material';
import { List as ImmutableList } from 'immutable';
import { RootState } from '../../../setup';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@mui/styles';
import IMenuItem from '../../../interfaces/common/menuItem';
import Paging from '../../../interfaces/common/paging';
import Pagination from '../../../interfaces/common/pagination';
import MainLayout from '../../common/widgets/mainLayout';
import PageUtils from '../../../utils/pageUtils';
import { Theme } from '@mui/material';
import FactorSetOut from '../../../interfaces/output/factorSetOut';
import DepartureLineFilters from './line/departureLineFilters';
import FactorSetDepartureLineOutput from '../../../interfaces/output/factorSetDepartureLineOut';
import ViewFactorSetDeparture from './viewFactorSetDeparture';
import ViewFactorSetDepartureLines from './line/departureLineList';
import { ObjectType } from '../../../constants/constants';
import LanguageUtils from '../../../utils/LanguageUtils';
import {
    deactivateFactorSetDeparture, fetchFactorSetDepartureById, fetchFactorSetDepartureTypes, getFactorSetDeparture, getFactorSetDepartureTypes, isDeletingDeparture, isLoadingFactorSetDeparture,
    resetFactorSetDeparture, resetFactorSetDepartures
} from '../../../reducers/factorSetDepartureReducer';
import Loader from '../../common/widgets/loader';
import FactorsVersionsList from '../factorsVersionList';
import FactorSetVersionOut from '../../../interfaces/output/factorSetVersionOut';
import {
    deactivateFactorSetDepartureLine, fetchDepartureVersionLines, getDepartureLines, isCreatingDepartureLine, isDeletingDepartureLine, isLoadingFactorSetDepartureLines, isUpdatingDepartureLine,
    resetDepartureVersionLines
} from '../../../reducers/departureLineReducer';
import { fetchDepartureVersions, getFactorSetDepartureVersions, isLoadingFactorSetDepartureVersions } from '../../../reducers/factorSetDepartureVersionReducer';
import DepartureType from '../../../interfaces/output/departureType';
import { fetchUnitTypes, getUnitTypes, isLoadingUnitTypes } from '../../../reducers/unitTypeReducer';
import UnitType from '../../../interfaces/output/unitType';
import NullableFormattedDate from '../../common/nullableFormattedDate';
import { GridSortModel, GridSortDirection, GridSortItem, GridColumnVisibilityModel } from '@mui/x-data-grid';

interface IFactorSetDepartureDetailsProps {
    classes: any;
    theme: Theme;
    fetchFactorSetDepartureById: any;
    fetchDepartureVersionLines: any;
    deactivateFactorSetDeparture: any;
    deactivateFactorSetDepartureLine: any;
    fetchFactorSetDepartureTypes: any;
    fetchDepartureVersions: any;
    resetFactorSetDeparture: () => any;
    resetDepartureVersionLines: () => any;
    resetFactorSetDepartures: () => any;
    factorSetDeparture: FactorSetOut;
    match: any;
    departureLines: Pagination<FactorSetDepartureLineOutput>;
    factorVersions: Pagination<FactorSetVersionOut>;
    departureTypes: Pagination<DepartureType>;
    history: any;
    location: any;
    isUpdatingDeparture: boolean;
    isDeletingDeparture: boolean;
    isDeletingLine: boolean;
    isCreatingDeparture: boolean;
    isLoading: boolean;
    isLoadingFactorSetDeparture: boolean;
    isLoadingFactorSetDepartureLines: boolean;
    isLoadingFactorSetVersions: boolean;
    fetchUnitTypes: any;
    isLoadingUnitTypes: boolean;
    unitTypes: Pagination<UnitType>;
}

interface IFactorSetDepartureDetailsState {
    open: boolean;
    factorSetDeparture: FactorSetOut;
    menuItems: ImmutableList<IMenuItem>;
    selectedRow?: number;
    serverPagination: Paging;
    serverPaginationLines: Paging;
    sortModelLines: GridSortModel;
    sortModelVersions: GridSortModel;
    factorSetDepartureColumnVisibilityModel: GridColumnVisibilityModel;
    factorsVersionLinesColumnVisibilityModel: GridColumnVisibilityModel;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchFactorSetDepartureById: (id: number, versionId: number) => dispatch(fetchFactorSetDepartureById({
        factorSetId: id,
        versionId
    })),
    fetchDepartureVersionLines: (paging: Paging, filters: DepartureLineFilters) => dispatch(fetchDepartureVersionLines({
        paging,
        filters
    })),
    fetchDepartureVersions: (id: number, paging: Paging) => dispatch(fetchDepartureVersions({
        id,
        paging
    })),
    deactivateFactorSetDeparture: (id: number) => dispatch(deactivateFactorSetDeparture(id)),
    deactivateFactorSetDepartureLine: (id: number) => dispatch(deactivateFactorSetDepartureLine(id)),
    fetchFactorSetDepartureTypes: (paging: Paging) => dispatch(fetchFactorSetDepartureTypes({ paging })),
    resetFactorSetDeparture: () => dispatch(resetFactorSetDeparture()),
    resetFactorSetDepartures: () => dispatch(resetFactorSetDepartures()),
    resetDepartureVersionLines: () => dispatch(resetDepartureVersionLines()),
    fetchUnitTypes: (paging: Paging) => dispatch(fetchUnitTypes({ paging }))

});

const mapStoreToProps = (store: RootState) => {
    return {
        factorSetDeparture: getFactorSetDeparture(store),
        departureLines: getDepartureLines(store),
        factorVersions: getFactorSetDepartureVersions(store),
        departureTypes: getFactorSetDepartureTypes(store),
        unitTypes: getUnitTypes(store),
        isUpdatingDeparture: isUpdatingDepartureLine(store),
        isDeletingLine: isDeletingDepartureLine(store),
        isDeletingDeparture: isDeletingDeparture(store),
        isCreatingDeparture: isCreatingDepartureLine(store),
        isLoadingFactorSetDepartureLines: isLoadingFactorSetDepartureLines(store),
        isLoadingFactorSetVersions: isLoadingFactorSetDepartureVersions(store),
        isLoadingFactorSetDeparture: isLoadingFactorSetDeparture(store),
        isLoadingUnitTypes: isLoadingUnitTypes(store),
        isLoading: isLoadingFactorSetDeparture(store) || isUpdatingDepartureLine(store) || isDeletingDepartureLine(store)
            || isCreatingDepartureLine(store) || isLoadingFactorSetDepartureLines(store) || isLoadingUnitTypes(store)
    };
};

const styles = (theme: Theme) => createStyles({
    container: {
        padding: theme.spacing(4)
    },
    link: {
        textDecoration: 'none',
        color: `${theme.palette.info.main}!important`,
        fontSize: '12px!important'
    },
    extraSpace: {
        marginRight: theme.spacing(1)
    },
    values: {
        color: theme.palette.common.black
    }
});

const messages = {
    edit: LanguageUtils.createMessage('Edit'),
    add: LanguageUtils.createMessage('Add'),
    delete: LanguageUtils.createMessage('Delete'),
    subname: LanguageUtils.createMessage('FACTOR_SET_DEPARTURE'),
    properties: LanguageUtils.createMessage('Properties'),
    changeStatus: LanguageUtils.createMessage('Change version status'),
    versions: LanguageUtils.createMessage('Versions'),
    factors: LanguageUtils.createMessage('lines')
};

class FactorSetDepartureDetails extends Component<IFactorSetDepartureDetailsProps, IFactorSetDepartureDetailsState> {
    constructor(props: IFactorSetDepartureDetailsProps) {
        super(props);

        this.state = {
            open: true,
            factorSetDeparture: {} as FactorSetOut,
            menuItems: this.configureDrawer(props),
            selectedRow: undefined,
            serverPagination: PageUtils.getDefaultPaging(),
            serverPaginationLines: PageUtils.getDefaultPaging(),
            sortModelLines: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            sortModelVersions: [{
                field: '',
                sort: '' as GridSortDirection
            } as GridSortItem],
            factorSetDepartureColumnVisibilityModel: {},
            factorsVersionLinesColumnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchFactorSetDepartureById } = this.props;
        const { departureId, factorSetVersionId } = this.props.match.params;
        const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;

        fetchFactorSetDepartureById(departureId, versionId);
    }

    componentWillUnmount() {
        const { resetFactorSetDeparture, resetFactorSetDepartures, resetDepartureVersionLines } = this.props;

        resetFactorSetDeparture();
        resetFactorSetDepartures();
        resetDepartureVersionLines();
    }

    componentDidUpdate(prevProps: IFactorSetDepartureDetailsProps) {
        const { factorSetDeparture, isUpdatingDeparture, isDeletingLine, isCreatingDeparture, isLoadingFactorSetDeparture, isDeletingDeparture, history, fetchFactorSetDepartureById } = this.props;
        const selectedMenuItem = this.getSelectedMenuItem(this.state);
        const { departureId, factorSetVersionId } = this.props.match.params;

        if(factorSetDeparture !== prevProps.factorSetDeparture) {
            this.setState({ factorSetDeparture });
        }

        if(prevProps.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                menuItems: this.configureDrawer(this.props),
                selectedRow: undefined
            }, () => this.loadData(this.getSelectedMenuItem(this.state)));

            if(prevProps.match.params.factorSetVersionId !== factorSetVersionId) {
                const versionId = factorSetVersionId === 'latest' ? undefined : factorSetVersionId;
                fetchFactorSetDepartureById(departureId, versionId);
            }
        }

        if(selectedMenuItem && (!isLoadingFactorSetDeparture && prevProps.isLoadingFactorSetDeparture ||
            !isUpdatingDeparture && prevProps.isUpdatingDeparture ||
            !isDeletingLine && prevProps.isDeletingLine ||
            !isCreatingDeparture && prevProps.isCreatingDeparture)) {
            this.loadData(selectedMenuItem);
        }

        if(!isDeletingDeparture && prevProps.isDeletingDeparture) {
            history.push(`/${UrlConstants.FACTOR_SETS}/${UrlConstants.FACTOR_SET_DEPARTURE}`);
        }
    }

    isHiddenCategory = (): boolean => {
        const { factorSetDeparture } = this.state;

        return factorSetDeparture.version?.id ? false : true;
    }

    configureDrawer = (props: IFactorSetDepartureDetailsProps): ImmutableList<IMenuItem> => {
        const baseUrl = `/${UrlConstants.FACTOR_SETS}/departure/${props.match.params.departureId}/${props.match.params.factorSetVersionId}`;

        return ImmutableList([{
            id: 1,
            name: messages.properties,
            icon: <FontAwesomeIcon icon="info-circle" size="1x" transform="grow-4" />,
            additionalMargin: true,
            url: `${baseUrl}/properties`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/properties`, props.location.pathname)
        },
        {
            id: 2,
            name: messages.versions,
            additionalMargin: true,
            icon: <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-4" />,
            url: `${baseUrl}/versions`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/versions`, props.location.pathname)
        },
        {
            id: 3,
            name: messages.factors,
            icon: <FontAwesomeIcon icon="grip-lines" size="1x" transform="grow-4" />,
            url: `${baseUrl}/lines`,
            isSelected: PageUtils.isPageSelected(`${baseUrl}/lines`, props.location.pathname),
            isHidden: this.isHiddenCategory
        }]);
    }

    loadData = (selectedMenuItem: IMenuItem | undefined) => {
        const { fetchDepartureVersionLines, factorSetDeparture, fetchDepartureVersions, fetchFactorSetDepartureTypes, fetchUnitTypes } = this.props;
        const { departureId } = this.props.match.params;
        const { serverPagination, serverPaginationLines, factorSetDepartureColumnVisibilityModel, factorsVersionLinesColumnVisibilityModel } = this.state;

        const lineFilters = {
            name: undefined,
            departureTypeId: null,
            factorSetVersionId: factorSetDeparture.version?.id
        } as DepartureLineFilters;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...serverPagination,
            page: newPaging.page
        };

        const newPaginationLines = {
            ...serverPaginationLines,
            page: newPaging.page
        };
       
        if(selectedMenuItem?.id === 2) {
            fetchDepartureVersions(departureId, newPagination);
        }

        if(selectedMenuItem?.id === 3 && factorSetDeparture.version?.id) {
            fetchDepartureVersionLines(newPaginationLines, lineFilters);
            fetchFactorSetDepartureTypes(newPagination);
            fetchUnitTypes(newPagination);
        }
    }

    getSelectedMenuItem = (state: IFactorSetDepartureDetailsState) => state.menuItems.find(item => item.isSelected);

    deactivateFactorSet = () => {
        const { factorSetDeparture } = this.state;
        const { deactivateFactorSetDeparture } = this.props;

        deactivateFactorSetDeparture(factorSetDeparture.id);
    }

    deactivateFactorSetLine = () => {
        const { selectedRow } = this.state;
        const { deactivateFactorSetDepartureLine } = this.props;

        deactivateFactorSetDepartureLine(selectedRow);
    }

    handleMenuItems = () => {
        const { menuItems, selectedRow, factorSetDeparture } = this.state;
        const { departureLines, departureTypes, unitTypes } = this.props;
        const selectedMenuItem = menuItems.find((item: IMenuItem) => item.isSelected);
        const selectedMenuItemId = selectedMenuItem?.id;
        const { departureId } = this.props.match.params;

        const filteredTypes = departureTypes.content.filter(dt => (!departureLines.content.find(dl => dl.departureType?.id === dt.id && !dl.unitType))
            || unitTypes.content.find(ut => !departureLines.content.find(dl => dl.departureType?.id === dt.id && dl.unitType?.id === ut?.id)));

        switch (selectedMenuItemId) {
            case 1: { //details
                return [
                    {
                        href: factorSetDeparture.version?.id ? `${UrlConstants.FACTOR_SETS}/departure/${departureId}/${factorSetDeparture.version?.id}/edit` :
                            `${UrlConstants.FACTOR_SETS}/departure/${departureId}/edit`,
                        icon: 'edit',
                        text: messages.edit
                    },
                    {
                        icon: 'ban',
                        text: messages.delete,
                        onClick: this.deactivateFactorSet
                    }
                ];
            }

            case 2: { //departure versions
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/departure/${departureId}/add/version`,
                            icon: 'plus',
                            text: messages.add
                        }
                    ];
                }

                return [{
                    href: `${UrlConstants.FACTOR_SETS}/departure/${departureId}/version/${selectedRow}/edit`,
                    icon: 'edit',
                    text: messages.edit
                },
                {
                    href: `${UrlConstants.FACTOR_SETS}/departure/${departureId}/version/${selectedRow}/status`,
                    icon: 'retweet',
                    text: messages.changeStatus
                }];
            }

            case 3: { //lines
                if(!selectedRow) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/departure/${departureId}/version/${factorSetDeparture.version?.id}/add/line`,
                            icon: 'plus',
                            text: messages.add,
                            disabled: !factorSetDeparture.version?.id || filteredTypes.length === 0
                        }
                    ];
                }

                if(factorSetDeparture.version) {
                    return [
                        {
                            href: `${UrlConstants.FACTOR_SETS}/departure/${departureId}/version/${factorSetDeparture.version?.id}/departureLine/${selectedRow}/edit`,
                            icon: 'edit',
                            text: messages.edit
                        },
                        {
                            icon: 'ban',
                            text: messages.delete,
                            onClick: this.deactivateFactorSetLine
                        }
                    ];
                }

                return [];
            }

            default: {
                return [];
            }
        }
    }
    _onSortChangeLines = (newModel: GridSortModel) => {
        const { fetchDepartureVersionLines } = this.props;
        const { sortModelLines, serverPaginationLines, factorSetDeparture } = this.state;

        const lineFilters = {
            name: undefined,
            departureTypeId: null,
            factorSetVersionId: factorSetDeparture.version?.id
        } as DepartureLineFilters;

        if(JSON.stringify(sortModelLines) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPaginationLines,
                sort: newModel
            };
            this.setState({
                sortModelLines: newModel,
                serverPaginationLines: newPagination
            });

            fetchDepartureVersionLines(newPagination, lineFilters);
        }
    }

    _onSortChangeVersions = (newModel: GridSortModel) => {
        const { fetchDepartureVersions } = this.props;
        const { factorSetDeparture, serverPagination, sortModelVersions } = this.state;

        if(JSON.stringify(sortModelVersions) !== JSON.stringify(newModel)) {
            const newPagination = {
                ...serverPagination,
                sort: newModel
            };
            this.setState({
                sortModelVersions: newModel,
                serverPagination: newPagination
            });

            fetchDepartureVersions(factorSetDeparture.id, newPagination);
        }
    }

    render() {
        const { classes, isLoadingFactorSetDeparture, isLoadingFactorSetDepartureLines, departureLines, isDeletingLine, isDeletingDeparture, isUpdatingDeparture,
            isLoadingFactorSetVersions, factorVersions, fetchDepartureVersions, fetchDepartureVersionLines } = this.props;
        const { factorSetDeparture, menuItems, serverPagination, serverPaginationLines,
            factorSetDepartureColumnVisibilityModel, factorsVersionLinesColumnVisibilityModel } = this.state;

        return (
            <MainLayout actions={this.handleMenuItems()}
                isLoading={isLoadingFactorSetDeparture || isDeletingDeparture}
                menuItems={menuItems}
                includeDrawer
                objectType={ObjectType.FactorSets}
                menuItem={{
                    id: 1,
                    subname: messages.subname,
                    value: factorSetDeparture.name,
                    icon: <FontAwesomeIcon icon="calendar-check" size="1x" />
                } as IMenuItem}
                additionalElement={
                    <strong>
                        <span className={classes.extraSpace}> <FontAwesomeIcon icon="layer-group" size="1x" transform="grow-3" className={classes.extraSpace} />
                            <span className={classes.values}> {factorSetDeparture?.version?.versionNumber ?? '-'} </span>
                        </span>
                        <span>
                            <FontAwesomeIcon icon="calendar-alt" size="1x" transform="grow-3" className={classes.extraSpace} />
                            {factorSetDeparture?.version?.validFromDate || factorSetDeparture?.version?.validToDate ? (
                                <span className={classes.values}>
                                    <NullableFormattedDate value={factorSetDeparture?.version?.validFromDate} /> -
                                    {factorSetDeparture?.version?.validToDate &&
                                        <> <NullableFormattedDate value={factorSetDeparture?.version?.validToDate} /></>}
                                </span>
                            )
                                : '-'} </span>
                    </strong>
                }
            >
                <Container className={classes.container} maxWidth="xl">
                    <Switch>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId/properties`}>
                            <Loader isLoading={isUpdatingDeparture || isDeletingDeparture}>
                                <ViewFactorSetDeparture factorSetDeparture={factorSetDeparture} />
                            </Loader>
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId/lines`}>
                            <ViewFactorSetDepartureLines isLoading={isLoadingFactorSetDepartureLines || isDeletingLine}
                                rows={departureLines}
                                checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPaginationLines,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    const lineFilters = {
                                        name: undefined,
                                        departureTypeId: null,
                                        factorSetVersionId: factorSetDeparture.version?.id
                                    } as DepartureLineFilters;

                                    this.setState({ serverPaginationLines: newPage });
                                    fetchDepartureVersionLines(newPage, lineFilters);
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeLines(sortModel)}
                                columnVisibilityModel={factorSetDepartureColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorSetDepartureColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                        <Route path={`/${UrlConstants.FACTOR_SETS}/departure/:departureId/:factorSetVersionId/versions`}>
                            <FactorsVersionsList
                                type={factorSetDeparture.type?.code}
                                isLoading={isLoadingFactorSetVersions}
                                rows={factorVersions} checkSelection={(value: number) => this.setState({ selectedRow: value })}
                                onPageChange={(nextPage: number, nextSize: number) => {
                                    const newPage = {
                                        ...serverPagination,
                                        page: nextPage,
                                        size: nextSize
                                    };
                                    fetchDepartureVersions(factorSetDeparture.id, newPage);
                                    this.setState({ serverPagination: newPage });
                                }}
                                onSortModelChange={(sortModel: GridSortModel) => this._onSortChangeVersions(sortModel)}
                                columnVisibilityModel={factorsVersionLinesColumnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel : GridColumnVisibilityModel) =>
                                    this.setState({
                                        factorsVersionLinesColumnVisibilityModel: newModel
                                    })
                                }
                            />
                        </Route>
                    </Switch>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(FactorSetDepartureDetails));