import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';
import ProductArenaType from '../interfaces/output/productArenaType';
import ProductArenaTypeFilters from '../interfaces/filters/productArenaTypeFilters';

export default class ProductArenaTypeApi {
    static fetchProductArenaType = async(productArenaTypeId: string): Promise<ProductArenaType> => {
        const { data } = await http.get<ProductArenaType>(`productarenatypes/${productArenaTypeId}`);

        return data;
    };

    static fetchProductArenaTypes = async(paging: Paging, filters?: ProductArenaTypeFilters): Promise<Pagination<ProductArenaType>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/productarenatypes');

        const { data } = await http.get<Pagination<ProductArenaType>>(url);

        return data;
    };
}