import { List } from 'immutable';
import { ensure } from '../../../../utils/arrayUtils';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationModel from '../../../../utils/validationModel';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import OrderUnitServiceProductSurchargeOut from '../../../../interfaces/output/orderUnitServiceProductSurchargeOut';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general'),
    wrongFormat: LanguageUtils.createMessage('Wrong email format')
};

export default class AddOrderUnitServiceProductSurchargeStep1Validation {
    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return ValidationUtils.validateInputStringLength(inputString, options);
    }

    static validateOrderUnitServiceProductSurchargeStep1Form(orderUnitServiceProductSurcharge: OrderUnitServiceProductSurchargeOut): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { pricingComponentType
        } = orderUnitServiceProductSurcharge;

        const requiredFields =
            AddOrderUnitServiceProductSurchargeStep1Validation.validateInputString(pricingComponentType?.name);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
