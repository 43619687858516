import CustomerApi from '../api/customerApi';
import { fetchCustomersSuccess, fetchCustomerByIdSuccess, createCustomerSuccess, updateCustomerSuccess,
    deactivateCustomerSuccess, createCustomerError, deactivateCustomerError, fetchCustomerByIdError,
    fetchCustomersError, updateCustomerError, syncCustomerGtsSuccess, syncCustomerGtsError, processCreditInvoicesSuccess, processCreditInvoicesError } from '../reducers/customerReducer';
import { put, takeLatest, call, delay } from 'redux-saga/effects';
import Customer from '../interfaces/output/customer';
import Pagination from '../interfaces/common/pagination';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import CustomerFilters from '../interfaces/output/filters';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';

function* doFetchCustomer(action: PayloadAction<string>) {
    const customerId = action.payload;

    try {
        const response: Customer = yield call(CustomerApi.fetchCustomerById, customerId);
        yield put(fetchCustomerByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomerByIdError(error as HttpErrorResponse));
    }
}

function* doFetchCustomers(action: PayloadAction<{ paging: Paging, filters: CustomerFilters, throttle?: boolean }>) {
    const { paging, filters, throttle } = action.payload;

    if(throttle) {
        yield delay(500);
    }

    try {
        const response: Pagination<Customer> = yield call(CustomerApi.fetchCustomers, paging, filters);
        yield put(fetchCustomersSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCustomersError(error as HttpErrorResponse));
    }
}

function* doCreateCustomer(action: PayloadAction<Customer>) {
    const customer = action.payload;

    try {
        const response: Customer = yield call(CustomerApi.createCustomer, customer);

        yield put(createCustomerSuccess(response));
    }

    catch (error: unknown) {
        yield put(createCustomerError(error as HttpErrorResponse));
    }
}

function* doUpdateCustomer(action: PayloadAction<Customer>) {
    const customer = action.payload;

    try {
        const response: Customer = yield call(CustomerApi.updateCustomer, customer);

        yield put(updateCustomerSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateCustomerError(error as HttpErrorResponse));
    }
}

function* doSyncCustomerGts(action: PayloadAction<number>) {
    const customerId = action.payload;

    try {
        const response: Customer = yield call(CustomerApi.syncCustomerGts, customerId);

        yield put(syncCustomerGtsSuccess(response));
    }

    catch (error: unknown) {
        yield put(syncCustomerGtsError(error as HttpErrorResponse));
    }
}

function* doDeactivateCustomer(action: PayloadAction<Customer>) {
    const customer = action.payload;
    
    try {
        const response: Customer = yield call(CustomerApi.deactivateCustomer, customer);

        yield put(deactivateCustomerSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateCustomerError(error as HttpErrorResponse));
    }
}

function* doProcessCreditInvoices(action: PayloadAction<number>) {
    const id = action.payload;

    try {
        const response: Customer = yield call(CustomerApi.processCreditInvoices, id);

        yield put(processCreditInvoicesSuccess(response));
    }

    catch (error: unknown) {
        yield put(processCreditInvoicesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('customer/fetchCustomerById', doFetchCustomer),
    takeLatest('customer/fetchCustomers', doFetchCustomers),
    takeLatest('customer/createCustomer', doCreateCustomer),
    takeLatest('customer/updateCustomer', doUpdateCustomer),
    takeLatest('customer/deactivateCustomer', doDeactivateCustomer),
    takeLatest('customer/syncCustomerGts', doSyncCustomerGts),
    takeLatest('customer/processCreditInvoices', doProcessCreditInvoices)
];