import { List } from 'immutable';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const messages = {
    alertLimitExceeded: LanguageUtils.createMessage('Validation error text'),
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class PricelistVersionProductStep2Validation extends ValidationUtils {
    static validateSelectedProduct(productId?: number): boolean {
        return !!productId;
    }

    static validatePricelistVersionProductForm(productId?: number): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;

        const requiredFields = PricelistVersionProductStep2Validation.validateSelectedProduct(productId);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
