import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import DashboardMessageFilters from '../../interfaces/filters/dashboardMessageFilters';
import { fetchDashboardMessageTypes, getDashboardMessageTypes, isLoadingDashboardMessageTypes } from '../../reducers/dashboardMessageTypeReducer';
import DashboardMessageType from '../../interfaces/output/dashboardMessageType';
import { FormattedMessage } from 'react-intl';
import Action from '../../interfaces/common/action';
import ActionRenderer from '../common/actionRenderer';

interface IFilterProps {
    filters: DashboardMessageFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
}

const messages = {
    messageType: LanguageUtils.createMessage('Message type'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function DashboardFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState, actions } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const dashboardMessageTypes = useSelector(getDashboardMessageTypes).content;
    const isLoadingDashboardMessageTypesProps = useSelector(isLoadingDashboardMessageTypes);

    useEffect(() => {
        dispatch(fetchDashboardMessageTypes({ paging }));
    }, [dispatch]);
    
    return (
        <Grid container pl={2} pr={2} direction="column">
            <Grid item>
                <Grid container p={2} pt={1} alignItems="flex-end">
                    <Grid item xs={3} mr="auto">
                        <GenericAutocomplete<DashboardMessageType>
                            options={dashboardMessageTypes}
                            value={filters.messageTypeId}
                            onChange={(obj: DashboardMessageType | null) => onChange('messageTypeId', obj?.id)}
                            placeholder={messages.messageType}
                            compareFn={(o: DashboardMessageType) => o.id === filters.messageTypeId}
                            isLoading={isLoadingDashboardMessageTypesProps}
                            label="messageType"
                        />
                    </Grid>
                    <ActionRenderer actions={actions} />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}