import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid } from '@mui/material';
import Product from '../../../interfaces/output/product';
import { useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import { isCreatingProduct, isLoadingProduct, isUpdatingProduct } from '../../../reducers/productReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericTextField from '../../common/widgets/genericTextField';

interface AddProductStep1Props {
    product: Product;
    onChange: any;
}

const messages = {
    distanceKm: LanguageUtils.createMessage('Distance km'),
    distanceDiesel: LanguageUtils.createMessage('Distance diesel'),
    distanceEl: LanguageUtils.createMessage('Distance el')
};

const AddProductStep1 = forwardRef((props: AddProductStep1Props, ref: any): JSX.Element => {
    const { product, onChange } = props;
    const isLoadingObjects = [useSelector(isCreatingProduct), useSelector(isLoadingProduct), useSelector(isUpdatingProduct)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="distanceKm"
                        type="number"
                        label={messages.distanceKm}
                        value={product.distanceKm}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="distanceDiesel"
                        type="number"
                        label={messages.distanceDiesel}
                        value={product.distanceDiesel}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="distanceEl"
                        type="number"
                        label={messages.distanceEl}
                        value={product.distanceEl}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddProductStep1;