import React, { Grid } from '@mui/material';
import { FormControl, Button } from '@mui/material';
import Country from '../../interfaces/output/countryOut';
// import Seller from '../../interfaces/output/seller';
import Status from '../../interfaces/output/customerStatus';
import CustomerFilters from '../../interfaces/output/filters';
import CustomerSegment from '../../interfaces/output/customerSegment';
import InvoiceFrequency from '../../interfaces/output/invoiceFrequency';
import PaymentDeadline from '../../interfaces/output/paymentDeadline';
import InvoiceSeparation from '../../interfaces/output/invoiceSeparation';
import CustomerCorporate from '../../interfaces/output/customerCorporate';
import GenericAutocomplete from './widgets/genericAutocomplete';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import PageUtils from '../../utils/pageUtils';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchSellers, getSellers } from '../../reducers/sellerReducer';
import { fetchCountries, getCountries } from '../../reducers/countryReducer';
import { fetchStatuses, getStatuses } from '../../reducers/statusReducer';
import { fetchSegments, getSegments } from '../../reducers/segmentReducer';
import { fetchPaymentDeadlines, getPaymentDeadlines } from '../../reducers/paymentDeadlineReducer';
import { fetchCorporates, getCorporates } from '../../reducers/customerCorporateReducer';
import { fetchInvoiceSeparations, getInvoicesSeparation } from '../../reducers/invoiceSeparationReducer';
import { fetchInvoiceFrequencies, getInvoicesFrequency } from '../../reducers/invoiceFrequencyReducer';
import { useEffect } from 'react';
import GenericTextField from './widgets/genericTextField';
import GenericDatePicker from './widgets/genericDatePicker';
import Terminal from '../../interfaces/output/terminal';
import { fetchTerminals, getTerminals } from '../../reducers/terminalReducer';

interface IFilterProps {
    filters: CustomerFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
}

const messages = {
    name: LanguageUtils.createMessage('Name'),
    dynamicsId: LanguageUtils.createMessage('Dynamics id'),
    gtsId: LanguageUtils.createMessage('GTS customer number'),
    country: LanguageUtils.createMessage('Country'),
    status: LanguageUtils.createMessage('Status'),
    // seller: LanguageUtils.createMessage('Seller'),
    corporate: LanguageUtils.createMessage('Customer corporate'),
    segment: LanguageUtils.createMessage('Segment'),
    invoiceFrequency: LanguageUtils.createMessage('Invoice frequency'),
    invoiceSeparation: LanguageUtils.createMessage('Invoice separation'),
    deadline: LanguageUtils.createMessage('Payment deadline'),
    validFromDate: LanguageUtils.createMessage('Order valid from'),
    validToDate: LanguageUtils.createMessage('Order valid to'),
    orderFilters: LanguageUtils.createMessage('Order filters'),
    fromTerminal: LanguageUtils.createMessage('Terminal from'),
    toTerminal: LanguageUtils.createMessage('Terminal to'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function Filters(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, setDefaultState } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const countries = useSelector(getCountries).content;
    // const sellers = useSelector(getSellers).content;
    const statuses = useSelector(getStatuses).content;
    const segments = useSelector(getSegments).content;
    const paymentDeadlines = useSelector(getPaymentDeadlines).content;
    const invoicesFrequency = useSelector(getInvoicesFrequency).content;
    const invoiceSeparations = useSelector(getInvoicesSeparation).content;
    const corporates = useSelector(getCorporates).content;
    const terminals = useSelector(getTerminals).content;

    useEffect(() => {
        dispatch(fetchCountries({ paging }));
        // dispatch(fetchSellers({ paging }));
        dispatch(fetchStatuses());
        dispatch(fetchSegments());
        dispatch(fetchPaymentDeadlines());
        dispatch(fetchInvoiceSeparations());
        dispatch(fetchInvoiceFrequencies());
        dispatch(fetchCorporates({ paging }));
        dispatch(fetchTerminals({ paging }));
    }, [dispatch]);

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="name"
                                label={messages.name}
                                onChange={onChange}
                                value={filters.name}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<number>
                                name="dynamicsId"
                                label={messages.dynamicsId}
                                type="number"
                                value={filters.dynamicsId}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="gtsId"
                                label={messages.gtsId}
                                value={filters.gtsId}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Country>
                            options={countries}
                            value={filters.countryId}
                            onChange={(obj: Country | null) => onChange('countryId', obj?.id)}
                            placeholder={messages.country}
                            compareFn={(o: Country) => o.id === filters.countryId}
                        />
                    </Grid>
                    {/* <Grid item xs>
                        <GenericAutocomplete<Seller>
                            options={sellers}
                            value={filters.sellerId}
                            onChange={(obj: Seller | null) => onChange('sellerId', obj?.id)}
                            placeholder={messages.seller}
                            compareFn={(o: Seller) => o.id === filters.sellerId}
                        />
                    </Grid> */}
                    <Grid item xs>
                        <GenericAutocomplete<Status>
                            options={statuses}
                            value={filters.statusId}
                            onChange={(obj: Status | null) => onChange('statusId', obj?.id)}
                            placeholder={messages.status}
                            compareFn={(o: Status) => o.id === filters.statusId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <GenericAutocomplete<CustomerCorporate>
                            options={corporates}
                            value={filters.corporateId}
                            onChange={(obj: CustomerCorporate | null) => onChange('corporateId', obj?.id)}
                            placeholder={messages.corporate}
                            compareFn={(o: CustomerCorporate) => o.id === filters.corporateId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<CustomerSegment>
                            options={segments}
                            value={filters.segmentId}
                            onChange={(obj: CustomerSegment | null) => onChange('segmentId', obj?.id)}
                            placeholder={messages.segment}
                            compareFn={(o: CustomerSegment) => o.id === filters.segmentId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<InvoiceFrequency>
                            options={invoicesFrequency}
                            value={filters.invoiceFrequencyId}
                            onChange={(obj: InvoiceFrequency | null) => onChange('invoiceFrequencyId', obj?.id)}
                            placeholder={messages.invoiceFrequency}
                            compareFn={(o: InvoiceFrequency) => o.id === filters.invoiceFrequencyId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<InvoiceSeparation>
                            options={invoiceSeparations}
                            value={filters.invoiceSeparationId}
                            onChange={(obj: InvoiceSeparation | null) => onChange('invoiceSeparationId', obj?.id)}
                            placeholder={messages.invoiceSeparation}
                            compareFn={(o: InvoiceSeparation) => o.id === filters.invoiceSeparationId}
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<PaymentDeadline>
                            options={paymentDeadlines}
                            value={filters.paymentDeadlineId}
                            onChange={(obj: PaymentDeadline | null) => onChange('paymentDeadlineId', obj?.id)}
                            placeholder={messages.deadline}
                            compareFn={(o: PaymentDeadline) => o.id === filters.paymentDeadlineId}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericDatePicker
                                name="orderFromDate"
                                label={messages.validFromDate}
                                value={filters.orderFromDate}
                                onChange={onChange}
                                type="start"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericDatePicker
                                name="orderToDate"
                                label={messages.validToDate}
                                value={filters.orderToDate}
                                onChange={onChange}
                                type="end"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericAutocomplete<Terminal>
                                options={terminals}
                                value={filters.fromTerminalId}
                                onChange={(obj: Terminal | null) => onChange('fromTerminalId', obj?.id)}
                                placeholder={messages.fromTerminal}
                                compareFn={(o: Terminal) => o.id === filters.fromTerminalId}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericAutocomplete<Terminal>
                            options={terminals}
                            value={filters.toTerminalId}
                            onChange={(obj: Terminal | null) => onChange('toTerminalId', obj?.id)}
                            placeholder={messages.toTerminal}
                            compareFn={(o: Terminal) => o.id === filters.toTerminalId}
                        />
                    </Grid>
                    <Grid item xs/>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} mt={1} justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}