import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import ServiceArena from '../interfaces/output/serviceArena';
import ServiceArenaApi from '../api/serviceArenaApi';
import { fetchServicesArenaError, fetchServicesArenaSuccess } from '../reducers/serviceArenaReducer';
import { PayloadAction } from '@reduxjs/toolkit';
import Paging from '../interfaces/common/paging';
import ServiceArenaFilters from '../interfaces/filters/serviceArenaFilters';

function* doFetchServicesArena(action: PayloadAction<{ paging: Paging, filters: ServiceArenaFilters}>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<ServiceArena> = yield call(ServiceArenaApi.fetchServicesArena, paging, filters);
        yield put(fetchServicesArenaSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchServicesArenaError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('serviceArena/fetchServicesArena', doFetchServicesArena)
];

