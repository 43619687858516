/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Theme, Link } from '@mui/material';
import DataGridComponent from '../common/dataGrid';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import TableUtils from '../../utils/tableUtils';
import Product from '../../interfaces/output/product';
import { ensure } from '../../utils/arrayUtils';
import Pagination from '../../interfaces/common/pagination';
import ServiceProduct from '../../interfaces/output/serviceProduct';
import PricelistVersionProduct from '../../interfaces/output/pricelistVersionProduct';
import LanguageUtils from '../../utils/LanguageUtils';
import { useIntl } from 'react-intl';
import Customer from '../../interfaces/output/customer';
import Agreement from '../../interfaces/output/agreement';
import CustomerCorporate from '../../interfaces/output/customerCorporate';
import DisplayStatus from '../common/displayStatus';
import NullableFormattedDate from '../common/nullableFormattedDate';
import TruncatedStringTooltip from '../common/truncatedStringTooltip';

interface ISimulationListProps {
    classes?: any;
    theme?: Theme;
    checkSelection?: any;
    isLoading?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: Pagination<Product> | Pagination<ServiceProduct> | Pagination<PricelistVersionProduct>;
    hiddenColumns?: Array<string>;
    applyRowFn?: any;
    selection?: number;
    additionalColumns?: GridColDef[];
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
}

const messages = {
    name: LanguageUtils.createMessage('Simulation name'),
    simulationDate: LanguageUtils.createMessage('Simulation date'),
    agreement1: LanguageUtils.createMessage('Agreement from'),
    agreement2: LanguageUtils.createMessage('Agreement to'),
    pricingDate1: LanguageUtils.createMessage('Pricing date from'),
    pricingDate2: LanguageUtils.createMessage('Pricing date to'),
    simulationStatus: LanguageUtils.createMessage('Simulation status'),
    customer: LanguageUtils.createMessage('Customer'),
    customerCorporate: LanguageUtils.createMessage('Customer corporate'),
    fromDate: LanguageUtils.createMessage('From date'),
    toDate: LanguageUtils.createMessage('To date')
};

const defaultProps: ISimulationListProps = {
    additionalColumns: [] as GridColDef[]
};

const SimulationList = forwardRef((props: ISimulationListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, additionalColumns, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => (cellValues as any).row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    const generateColumns = (): GridColDef[] => {
        const intl = useIntl();

        return [
            ...ensure(additionalColumns),
            {
                field: 'simulationName',
                headerName: intl.formatMessage(messages.name),
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams<Agreement>) => <TruncatedStringTooltip value={applyRowFn(params).simulationName ?? '-'} />,
                // renderCell: (cellValues: any) => {
                //     return <Link underline="none" color="secondary" href={`/#/${UrlConstants.SIMULATIONS}/${applyRowFn(cellValues)?.id}`} >{applyRowFn(cellValues).simulationName}</Link>;
                // },
                hide: hiddenColumns && TableUtils.hideColumn('simulationName', hiddenColumns)
            },
            {
                field: 'simulationDate',
                headerName: intl.formatMessage(messages.simulationDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => { return (<NullableFormattedDate value={params.row?.simulationDate} />); },
                hide: hiddenColumns && TableUtils.hideColumn('simulationDate', hiddenColumns)
            },
            {
                field: 'agreement1',
                headerName: intl.formatMessage(messages.agreement1),
                flex: 2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams<Agreement>) => <TruncatedStringTooltip value={applyRowFn(params).agreement1?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('agreement1', hiddenColumns)
            },
            {
                field: 'pricingDate1',
                headerName: intl.formatMessage(messages.pricingDate1),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => { return (<NullableFormattedDate value={params.row?.pricingDate1} />); },
                hide: hiddenColumns && TableUtils.hideColumn('pricingDate1', hiddenColumns)
            },
            {
                field: 'agreement2',
                headerName: intl.formatMessage(messages.agreement2),
                flex: 2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams<Agreement>) => <TruncatedStringTooltip value={applyRowFn(params).agreement2?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('agreement2', hiddenColumns)
            },
            {
                field: 'pricingDate2',
                headerName: intl.formatMessage(messages.pricingDate2),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => { return (<NullableFormattedDate value={params.row?.pricingDate2} />); },
                hide: hiddenColumns && TableUtils.hideColumn('pricingDate2', hiddenColumns)
            },
            {
                field: 'customer_name',
                headerName: intl.formatMessage(messages.customer),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams<Customer>) => <TruncatedStringTooltip value={params.row.customer?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('customer', hiddenColumns)
            },
            {
                field: 'customerCorporate_name',
                headerName: intl.formatMessage(messages.customerCorporate),
                flex: 2,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams<CustomerCorporate>) => <TruncatedStringTooltip value={params.row.customerCorporate?.name ?? '-'} />,
                hide: hiddenColumns && TableUtils.hideColumn('customerCorporate', hiddenColumns)
            },
            {
                field: 'simulationStatus',
                headerName: intl.formatMessage(messages.simulationStatus),
                flex: 0.8,
                align: 'center',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: GridRenderCellParams) => <DisplayStatus status={applyRowFn(params)?.simulationStatus} inheritProps />,
                hide: hiddenColumns && TableUtils.hideColumn('simulationStatus', hiddenColumns)
            },
            {
                field: 'fromDate',
                headerName: intl.formatMessage(messages.fromDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => { return (<NullableFormattedDate value={params.row?.fromDate} />); },
                hide: hiddenColumns && TableUtils.hideColumn('fromDate', hiddenColumns)
            },
            {
                field: 'toDate',
                headerName: intl.formatMessage(messages.toDate),
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                sortable: true,
                renderCell: (params: any) => { return (<NullableFormattedDate value={params.row?.toDate} />); },
                hide: hiddenColumns && TableUtils.hideColumn('toDate', hiddenColumns)
            }
        ];
    };

    return (
        <DataGridComponent rows={rows} columns={generateColumns()} componentsProps={componentsProps} isLoading={isLoading} checkSelection={checkSelection}
            onPageChange={onPageChange} ref={dataGridRef} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange}/>
    );
});

SimulationList.defaultProps = defaultProps;

export default SimulationList;