import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchProductArenas, getProductArenas, isLoadingProductArenas } from '../../../reducers/productArenaReducer';
import { isLoadingAgreementVersionTransitDiscount, isUpdatingAgreementVersionTransitDiscount } from '../../../reducers/transitDiscountReducer';
import { isCreatingDiscount } from '../../../reducers/agreementVersionReducer';
import TransitDiscount from '../../../interfaces/output/transitDiscount';
import ProductArena from '../../../interfaces/output/productArena';
import GenericTextField from '../../common/widgets/genericTextField';

interface AddAgreementVersionDiscountStep1Props {
    agreementDiscount: TransitDiscount;
    onChange: any;
}

const messages = {
    productArenaFrom: LanguageUtils.createMessage('Product arena from'),
    productArenaTo: LanguageUtils.createMessage('Product arena to'),
    discount: LanguageUtils.createMessage('Transit discount')
};

const AddAgreementVersionDiscountStep1 = forwardRef((props: AddAgreementVersionDiscountStep1Props, ref: any): JSX.Element => {
    const { agreementDiscount, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const productArenas = useSelector(getProductArenas).content;
    const isLoadingObjects = [useSelector(isLoadingProductArenas), useSelector(isUpdatingAgreementVersionTransitDiscount),
        useSelector(isLoadingAgreementVersionTransitDiscount), useSelector(isCreatingDiscount)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchProductArenas({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<number>
                        name="transitDiscount"
                        label={messages.discount}
                        value={agreementDiscount?.transitDiscount}
                        type="number"
                        onChange={onChange}
                    />
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ProductArena>
                            options={productArenas}
                            value={agreementDiscount.productArenaFrom}
                            onChange={(obj: ProductArena | null) => onChange('productArenaFrom', obj)}
                            placeholder={messages.productArenaFrom}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<ProductArena>
                            options={productArenas}
                            value={agreementDiscount.productArenaTo}
                            onChange={(obj: ProductArena | null) => onChange('productArenaTo', obj)}
                            placeholder={messages.productArenaTo}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddAgreementVersionDiscountStep1;