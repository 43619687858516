import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import VatCode from '../interfaces/output/vatCode';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type VatState = {
    vatCode: VatCode;
    isLoading: boolean;
    vatCodes: Pagination<VatCode>;
    error: HttpErrorResponse | undefined;
};

const initialState: VatState = {
    vatCode: {} as VatCode,
    vatCodes: { content: [] as Array<VatCode> } as Pagination<VatCode>,
    isLoading: false,
    error: undefined
};

export const vatSlice = createSlice({
    name: 'vat',

    initialState,
    reducers: {
        fetchVatCodes: (state: VatState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchVatCodesSuccess: (state: VatState, action: PayloadAction<Pagination<VatCode>>) => {
            state.isLoading = false;
            state.vatCodes = action.payload;
        },
        fetchVatCodesError: (state: VatState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchVatById: (state: VatState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchVatByIdSuccess: (state: VatState, action: PayloadAction<VatCode>) => {
            state.isLoading = false;
            state.vatCode = action.payload;
        },
        fetchVatByIdError: (state: VatState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { fetchVatCodes, fetchVatCodesSuccess, fetchVatCodesError, fetchVatById, fetchVatByIdSuccess, fetchVatByIdError } = vatSlice.actions;

export const isLoadingVat = (state: RootState): boolean => state.vatReducer.isLoading;
export const getVatCodes = (state: RootState): Pagination<VatCode> => state.vatReducer.vatCodes;
export const getVatCode = (state: RootState): VatCode => state.vatReducer.vatCode;

export default vatSlice.reducer;