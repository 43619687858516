import { List } from 'immutable';
import InputLengthConstants from '../../../constants/inputLengthConstants';
import PricelistVersionProduct from '../../../interfaces/output/pricelistVersionProduct';
import { ensure } from '../../../utils/arrayUtils';
import LanguageUtils from '../../../utils/LanguageUtils';
import ValidationModel from '../../../utils/validationModel';
import ValidationUtils from '../../../utils/validationUtils';

const inputsMaxLengths = {
    name: InputLengthConstants.String200,
    vatId: InputLengthConstants.String9
};

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class PricelistVersionProductStep4Validation extends ValidationUtils {
    static validatePricelistVersionProductForm(pvp: PricelistVersionProduct): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { basePrice } = pvp;

        const requiredFields =
            this.validateInputNumber(basePrice);
            
        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }

    static validateName = (inputString?: string): boolean => {
        return this.validateInputStringLength(inputString, {
            inputLength: inputsMaxLengths.name
        });
    }
}
