import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import FactorSetVersionStatusOut from '../interfaces/output/factorSetVersionStatusOut';

export type CancellationVersionStatusState = {
    cancellationVersionStatus: FactorSetVersionStatusOut;
    cancellationVersionStatuses: Pagination<FactorSetVersionStatusOut>;
    isLoading: boolean;
    isUpdating: boolean;
    isDeleted: boolean;
    error: HttpErrorResponse | undefined;
};

const initialState: CancellationVersionStatusState = {
    cancellationVersionStatus: {} as FactorSetVersionStatusOut,
    cancellationVersionStatuses: { content: [] as Array<FactorSetVersionStatusOut> } as Pagination<FactorSetVersionStatusOut>,
    isLoading: false,
    isUpdating: false,
    isDeleted: false,
    error: undefined
};

export const cancellationVersionStatusSlice = createSlice({
    name: 'cancellationVersionStatus',

    initialState,
    reducers: {
        fetchCancellationVersionStatuses: (state: CancellationVersionStatusState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchCancellationVersionStatusesSuccess: (state: CancellationVersionStatusState, action: PayloadAction<Pagination<FactorSetVersionStatusOut>>) => {
            state.isLoading = false;
            state.cancellationVersionStatuses = action.payload;
        },
        fetchCancellationVersionStatusesError: (state: CancellationVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCancellationVersionStatusById: (state: CancellationVersionStatusState, _action) => {
            state.isLoading = true;
        },
        fetchCancellationVersionStatusByIdSuccess: (state: CancellationVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isLoading = false;
            state.cancellationVersionStatus = action.payload;
        },
        fetchCancellationVersionStatusByIdError: (state: CancellationVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateCancellationVersionStatus: (state: CancellationVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = true;
        },
        updateCancellationVersionStatusSuccess: (state: CancellationVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isUpdating = false;
            state.cancellationVersionStatus = action.payload;
        },
        updateCancellationVersionStatusError: (state: CancellationVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateCancellationVersionStatus: (state: CancellationVersionStatusState, _action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = true;
        },
        deactivateCancellationVersionStatusSuccess: (state: CancellationVersionStatusState, action: PayloadAction<FactorSetVersionStatusOut>) => {
            state.isDeleted = false;
            state.cancellationVersionStatus = action.payload;
        },
        deactivateCancellationVersionStatusError: (state: CancellationVersionStatusState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleted = false;
            state.error = action.payload;
        },
        resetCancellationVersionStatuses: (state: CancellationVersionStatusState) => {
            state.cancellationVersionStatuses = initialState.cancellationVersionStatuses;
        }
    }
});

export const { fetchCancellationVersionStatusById, fetchCancellationVersionStatusByIdSuccess, fetchCancellationVersionStatusByIdError, updateCancellationVersionStatus,
    updateCancellationVersionStatusSuccess, updateCancellationVersionStatusError, fetchCancellationVersionStatuses, fetchCancellationVersionStatusesSuccess,
    fetchCancellationVersionStatusesError, deactivateCancellationVersionStatus, deactivateCancellationVersionStatusSuccess, deactivateCancellationVersionStatusError,
    resetCancellationVersionStatuses } = cancellationVersionStatusSlice.actions;

export const isLoadingCancellationVersionStatus = (state: RootState): boolean => state.cancellationVersionStatusReducer.isLoading;
export const isUpdatingVersionStatus = (state: RootState): boolean => state.cancellationVersionStatusReducer.isUpdating;
export const isDeleting = (state: RootState): boolean => state.cancellationVersionStatusReducer.isDeleted;
export const getCancellationVersionStatus = (state: RootState): FactorSetVersionStatusOut => state.cancellationVersionStatusReducer.cancellationVersionStatus;
export const getCancellationVersionStatuses = (state: RootState): Pagination<FactorSetVersionStatusOut> => state.cancellationVersionStatusReducer.cancellationVersionStatuses;

export default cancellationVersionStatusSlice.reducer;