import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import TerminalOperator from '../interfaces/output/terminalOperator';
import TerminalOpearatorApi from '../api/terminalOperatorApi';
import { fetchTerminalOperatorByIdError, fetchTerminalOperatorByIdSuccess, fetchTerminalOperatorsError, fetchTerminalOperatorsSuccess } from '../reducers/terminalOperatorReducer';

function* doFetchTerminalOperator(action: PayloadAction<string>) {
    const terminalOperatorId = action.payload;
    try {
        const response: TerminalOperator = yield call(TerminalOpearatorApi.fetchTerminalOperatorById, terminalOperatorId);
        yield put(fetchTerminalOperatorByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchTerminalOperatorByIdError(error as HttpErrorResponse));
    }
}

function* doFetchTerminalOperators(action: PayloadAction<{ paging: Paging}>) {
    const { paging } = action.payload;

    try {
        const response: Pagination<TerminalOperator> = yield call(TerminalOpearatorApi.fetchTerminalOperators, paging);
        yield put(fetchTerminalOperatorsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchTerminalOperatorsError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('terminalOperator/fetchTerminalOperatorById', doFetchTerminalOperator),
    takeLatest('terminalOperator/fetchTerminalOperators', doFetchTerminalOperators)];

