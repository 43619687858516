import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import ReportsApi from '../api/reportsApi';
import { fetchCalculatedPriceError, fetchCalculatedPriceSuccess, fetchOrdersNotYetInvoicedError, fetchOrdersNotYetInvoicedSuccess } from '../reducers/reportsReducer';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import CalculatedPriceFilters from '../interfaces/filters/calculatedPriceFilters';
import CalculatedPrice from '../interfaces/output/calculatedPrice';

function* doFetchOrdersNotYetInvoiced(action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<OrderNotYetInvoiced> = yield call(ReportsApi.fetchOrdersNotYetInvoiced, paging, filters);
        yield put(fetchOrdersNotYetInvoicedSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchOrdersNotYetInvoicedError(error as HttpErrorResponse));
    }
}

function* doFetchCalculatedPrice(action: PayloadAction<{ paging: Paging, filters?: CalculatedPriceFilters }>) {
    const { paging, filters } = action.payload;

    try {
        const response: Pagination<CalculatedPrice> = yield call(ReportsApi.fetchCalculatedPrice, paging, filters);
        yield put(fetchCalculatedPriceSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchCalculatedPriceError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('reports/fetchOrdersNotYetInvoiced', doFetchOrdersNotYetInvoiced),
    takeLatest('reports/fetchCalculatedPrice', doFetchCalculatedPrice)
];

