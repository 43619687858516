import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AgreementVersion from '../interfaces/output/agreementVersion';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import TransitDiscount from '../interfaces/output/transitDiscount';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type TransitDiscountState = {
    transitDiscount: TransitDiscount;
    isLoading: boolean;
    isUpdating: boolean;
    isUpdatingDiscount: boolean;
    isCreatingDiscount: boolean;
    isDeleting: boolean;
    error: HttpErrorResponse | undefined;isLoadingDiscounts: boolean;
};

const initialState: TransitDiscountState = {
    transitDiscount: {} as TransitDiscount,
    isLoading: false,
    isLoadingDiscounts: false,
    isCreatingDiscount: false,
    isUpdating: false,
    isUpdatingDiscount: false,
    isDeleting: false,
    error: undefined
};

export const transitDiscountSlice = createSlice({
    name: 'transitDiscount',

    initialState,
    reducers: {
        fetchTransitDiscountById: (state: TransitDiscountState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchTransitDiscountByIdSuccess: (state: TransitDiscountState, action: PayloadAction<TransitDiscount>) => {
            state.isLoading = false;
            state.transitDiscount = action.payload;
        },
        fetchTransitDiscountByIdError: (state: TransitDiscountState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateTransitDiscount: (state: TransitDiscountState, _action: PayloadAction<TransitDiscount>) => {
            state.isUpdatingDiscount = true;
        },
        updateTransitDiscountSuccess: (state: TransitDiscountState, action: PayloadAction<TransitDiscount>) => {
            state.isUpdatingDiscount = false;
            state.transitDiscount = action.payload;
        },
        updateTransitDiscountError: (state: TransitDiscountState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdatingDiscount = false;
            state.error = action.payload;
        },
        deleteTransitDiscount: (state: TransitDiscountState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deleteTransitDiscountSuccess: (state: TransitDiscountState, action: PayloadAction<TransitDiscount>) => {
            state.isDeleting = false;
            state.transitDiscount = action.payload;
        },
        deleteTransitDiscountError: (state: TransitDiscountState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        resetAgreementVersionTransitDiscount: (state: TransitDiscountState) => {
            state.transitDiscount = initialState.transitDiscount;
        }
    }
});

export const { fetchTransitDiscountById, fetchTransitDiscountByIdSuccess, fetchTransitDiscountByIdError, updateTransitDiscount, updateTransitDiscountSuccess, updateTransitDiscountError,
    resetAgreementVersionTransitDiscount, deleteTransitDiscount, deleteTransitDiscountSuccess, deleteTransitDiscountError } = transitDiscountSlice.actions;

export const isLoadingAgreementVersionTransitDiscount = (state: RootState): boolean => state.transitDiscountReducer.isLoading;
export const isDeletingAgreementVersionTransitDiscount = (state: RootState): boolean => state.transitDiscountReducer.isDeleting;
export const isUpdatingAgreementVersionTransitDiscount = (state: RootState): boolean => state.transitDiscountReducer.isUpdatingDiscount;
export const getAgreementVersionTransitDiscount = (state: RootState): TransitDiscount => state.transitDiscountReducer.transitDiscount;

export default transitDiscountSlice.reducer;