import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import UnitGroup from '../interfaces/output/unitGroup';
import UnitSubGroup from '../interfaces/output/unitSubGroup';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type UnitGroupState = {
    unitGroup: UnitGroup;
    subgroup: UnitSubGroup;
    isLoading: boolean;
    isCreating: boolean;
    isLoadingUnitSubgroups: boolean;
    unitGroups: Pagination<UnitGroup>;
    unitSubGroups: Pagination<UnitSubGroup>;
    error: HttpErrorResponse | undefined;
};

const initialState: UnitGroupState = {
    unitGroup: {} as UnitGroup,
    subgroup: {} as UnitSubGroup,
    unitGroups: { content: [] as Array<UnitGroup> } as Pagination<UnitGroup>,
    unitSubGroups: { content: [] as Array<UnitSubGroup> } as Pagination<UnitSubGroup>,
    isLoading: false,
    isCreating: false,
    isLoadingUnitSubgroups: false,
    error: undefined
};

export const unitGroupSlice = createSlice({
    name: 'unitGroup',

    initialState,
    reducers: {
        fetchUnitGroups: (state: UnitGroupState, _action: PayloadAction<{paging: Paging}>) => {
            state.isLoading = true;
        },
        fetchUnitGroupsSuccess: (state: UnitGroupState, action: PayloadAction<Pagination<UnitGroup>>) => {
            state.isLoading = false;
            state.unitGroups = action.payload;
        },
        fetchUnitGroupsError: (state: UnitGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchUnitGroupById: (state: UnitGroupState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchUnitGroupByIdSuccess: (state: UnitGroupState, action: PayloadAction<UnitGroup>) => {
            state.isLoading = false;
            state.unitGroup = action.payload;
        },
        fetchUnitGroupByIdError: (state: UnitGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchUnitGroupSubgroups: (state: UnitGroupState, action: PayloadAction<{groupId: number, paging: Paging}>) => {
            state.isLoadingUnitSubgroups = true;
        },
        fetchUnitGroupSubgroupsSuccess: (state: UnitGroupState, action: PayloadAction<Pagination<UnitSubGroup>>) => {
            state.isLoadingUnitSubgroups = false;
            state.unitSubGroups = action.payload;
        },
        fetchUnitGroupSubgroupsError: (state: UnitGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingUnitSubgroups = false;
            state.error = action.payload;
        },
        createUnitGroupSubgroup: (state: UnitGroupState, _action: PayloadAction<UnitSubGroup>) => {
            state.isCreating = true;
        },
        createUnitGroupSubgroupSuccess: (state: UnitGroupState, action: PayloadAction<UnitSubGroup>) => {
            state.isCreating = false;
            state.subgroup = action.payload;
        },
        createUnitGroupSubgroupError: (state: UnitGroupState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        resetSubgroups: (state: UnitGroupState) => {
            state.unitSubGroups = initialState.unitSubGroups;
        },
        resetUnitGroups: (state: UnitGroupState) => {
            state.unitGroups = initialState.unitGroups;
        }
    }
});

export const { fetchUnitGroups, fetchUnitGroupsSuccess, fetchUnitGroupsError, fetchUnitGroupById, fetchUnitGroupByIdSuccess, fetchUnitGroupByIdError, fetchUnitGroupSubgroups,
    fetchUnitGroupSubgroupsSuccess, fetchUnitGroupSubgroupsError,createUnitGroupSubgroup, createUnitGroupSubgroupSuccess, createUnitGroupSubgroupError, resetSubgroups,
    resetUnitGroups } = unitGroupSlice.actions;

export const isLoadingUnitGroups = (state: RootState): boolean => state.unitGroupReducer.isLoading;
export const isLoadingUnitSubgroups = (state: RootState): boolean => state.unitGroupReducer.isLoadingUnitSubgroups;
export const getUnitGroups = (state: RootState): Pagination<UnitGroup> => state.unitGroupReducer.unitGroups;
export const getUnitGroup = (state: RootState): UnitGroup => state.unitGroupReducer.unitGroup;
export const getUnitSubGroups = (state: RootState): Pagination<UnitSubGroup> => state.unitGroupReducer.unitSubGroups;

export default unitGroupSlice.reducer;