import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/widgets/loader';
import WorkflowUtils from '../../../../utils/workflowUtils';
import GenericAutocomplete from '../../../common/widgets/genericAutocomplete';
import PageUtils from '../../../../utils/pageUtils';
import FactorSetVersionStatusOut from '../../../../interfaces/output/factorSetVersionStatusOut';
import FactorSetOut from '../../../../interfaces/output/factorSetOut';
import { fetchPriorityVersionStatuses, getPriorityVersionStatuses, isLoadingPriorityVersionStatus } from '../../../../reducers/priorityVersionStatusReducer';
import { isUpdatingPriorityVersionStatus } from '../../../../reducers/factorSetPriorityVersionReducer';
import { isLoadingFactorSetPriority } from '../../../../reducers/factorSetPriorityReducer';

interface AddPriorityStep5Props {
    factorSet: FactorSetOut;
    onChangeVersion: any;
}

const messages = {
    status: LanguageUtils.createMessage('Status')
};

const AddPriorityVersionStep3 = forwardRef((props: AddPriorityStep5Props, ref: any): JSX.Element => {
    const { factorSet, onChangeVersion } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const priorityVersionStatuses = useSelector(getPriorityVersionStatuses).content;
    const isLoadingObjects = [useSelector(isLoadingPriorityVersionStatus), useSelector(isLoadingFactorSetPriority), useSelector(isUpdatingPriorityVersionStatus)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchPriorityVersionStatuses({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<FactorSetVersionStatusOut>
                            options={priorityVersionStatuses}
                            value={factorSet.version?.status}
                            onChange={(obj: FactorSetVersionStatusOut | null) => onChangeVersion('status', obj)}
                            placeholder={messages.status}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddPriorityVersionStep3;