import React, { forwardRef, useEffect } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../../utils/pageUtils';
import Loader from '../../common/widgets/loader';
import { isCreatingProductDynamics, isLoadingProductDynamics, isUpdatingProductDynamics } from '../../../reducers/productReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericAutocomplete from '../../common/widgets/genericAutocomplete';
import { fetchAccounts, fetchActivities, fetchObjects, fetchResponsibilities, fetchSpecs, fetchTrades, fetchVats, getDynamicsVats, isLoadingDynamics,
    getDynamicsAccounts, getDynamicsActivities, getDynamicsResponsibilities, getDynamicsTrades, getDynamicsObjects, getDynamicsSpecs } from '../../../reducers/dynamicsReducer';
import ProductDynamics from '../../../interfaces/output/productDynamics';
import D365VAT from '../../../interfaces/output/d365VAT';
import D365Account from '../../../interfaces/output/d365Account';
import D365Activity from '../../../interfaces/output/d365Activity';
import D365Object from '../../../interfaces/output/d365Object';
import D365ResponsibilityCenter from '../../../interfaces/output/d365ResponsibilityCenter';
import D365InternalTrade from '../../../interfaces/output/d365InternalTrade';
import D365Spec from '../../../interfaces/output/d365Spec';

interface AddProductDynamicsStep1Props {
    productDynamics: ProductDynamics;
    onChange: any;
}

const messages = {
    dynamics: LanguageUtils.createMessage('Dynamics'),
    vatCode: LanguageUtils.createMessage('Vat code'),
    activity: LanguageUtils.createMessage('Dynamics activity'),
    account: LanguageUtils.createMessage('Dynamics account'),
    responsibility: LanguageUtils.createMessage('Dynamics responsibility'),
    object: LanguageUtils.createMessage('Dynamics object'),
    spec: LanguageUtils.createMessage('Dynamics spec'),
    trade: LanguageUtils.createMessage('Dynamics trade')
};

const AddProductDynamicsStep1 = forwardRef((props: AddProductDynamicsStep1Props, ref: any): JSX.Element => {
    const { productDynamics, onChange } = props;
    const paging = PageUtils.getMaxPaging();
    const dispatch = useDispatch();
    const vats = useSelector(getDynamicsVats).content;
    const accounts = useSelector(getDynamicsAccounts).content;
    const activities = useSelector(getDynamicsActivities).content;
    const responsibilities = useSelector(getDynamicsResponsibilities).content;
    const trades = useSelector(getDynamicsTrades).content;
    const specs = useSelector(getDynamicsSpecs).content;
    const objects = useSelector(getDynamicsObjects).content;
    const isLoadingObjects = [useSelector(isLoadingDynamics), useSelector(isLoadingProductDynamics), useSelector(isUpdatingProductDynamics), useSelector(isCreatingProductDynamics)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);

    useEffect(() => {
        dispatch(fetchVats({ paging }));
        dispatch(fetchAccounts({ paging }));
        dispatch(fetchObjects({ paging }));
        dispatch(fetchActivities({ paging }));
        dispatch(fetchSpecs({ paging }));
        dispatch(fetchResponsibilities({ paging }));
        dispatch(fetchTrades({ paging }));
    }, [dispatch]);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item sm={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth required>
                        <GenericAutocomplete<D365VAT>
                            options={vats}
                            value={productDynamics.d365Vat}
                            onChange={(obj: D365VAT | null) => onChange('d365Vat', obj)}
                            placeholder={messages.vatCode}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<D365Activity>
                            options={activities}
                            value={productDynamics.d365Activity}
                            onChange={(obj: D365Activity | null) => onChange('d365Activity', obj)}
                            placeholder={messages.activity}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<D365Account>
                            options={accounts}
                            value={productDynamics.d365Account}
                            onChange={(obj: D365Account | null) => onChange('d365Account', obj)}
                            placeholder={messages.account}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<D365Object>
                            options={objects}
                            value={productDynamics.d365Object}
                            onChange={(obj: D365Object | null) => onChange('d365Object', obj)}
                            placeholder={messages.object}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<D365ResponsibilityCenter>
                            options={responsibilities}
                            value={productDynamics.d365ResponsibilityCenter}
                            onChange={(obj: D365ResponsibilityCenter | null) => onChange('d365ResponsibilityCenter', obj)}
                            placeholder={messages.responsibility}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<D365Spec>
                            options={specs}
                            value={productDynamics.d365Spec}
                            onChange={(obj: D365Spec | null) => onChange('d365Spec', obj)}
                            placeholder={messages.spec}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <GenericAutocomplete<D365InternalTrade>
                            options={trades}
                            value={productDynamics.d365InternalTrade}
                            onChange={(obj: D365InternalTrade | null) => onChange('d365InternalTrade', obj)}
                            placeholder={messages.trade}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddProductDynamicsStep1;