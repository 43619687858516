import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import FactorSetPriorityVersionApi from '../api/factorSetPriorityVersionApi';
import FactorSetVersionOut from '../interfaces/output/factorSetVersionOut';
import { clonePriorityVersionError, clonePriorityVersionSuccess, fetchPriorityVersionByIdError,
    fetchPriorityVersionByIdSuccess, fetchPriorityVersionsError, fetchPriorityVersionsSuccess, updatePriorityVersionError, updatePriorityVersionStatusError,
    updatePriorityVersionStatusSuccess, updatePriorityVersionSuccess } from '../reducers/factorSetPriorityVersionReducer';
import Pagination from '../interfaces/common/pagination';
import Paging from '../interfaces/common/paging';

function* doFetchFactorSetPriorityVersion(action: PayloadAction<string>) {
    const agreementVersionId = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetPriorityVersionApi.fetchFactorSetPriorityVersionById, agreementVersionId);

        yield put(fetchPriorityVersionByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriorityVersionByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetPriorityVersions(action: PayloadAction<{id: number, paging: Paging}>) {
    const { id, paging } = action.payload;

    try {
        const response: Pagination<FactorSetVersionOut> = yield call(FactorSetPriorityVersionApi.fetchFactorSetPriorityVersions, id, paging);
        yield put(fetchPriorityVersionsSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchPriorityVersionsError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetPriorityVersionStatus(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetPriorityVersionApi.updateFactorSetPriorityVersionStatus, agreementVersion);

        yield put(updatePriorityVersionStatusSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriorityVersionStatusError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetPriorityVersion(action: PayloadAction<FactorSetVersionOut>) {
    const agreementVersion = action.payload;

    try {
        const response: FactorSetVersionOut = yield call(FactorSetPriorityVersionApi.updateFactorSetPriorityVersion, agreementVersion);

        yield put(updatePriorityVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(updatePriorityVersionError(error as HttpErrorResponse));
    }
}

function* doCloneFactorSetPriorityVersion(action: PayloadAction<{toVersionId: number, fromVersionId: number}>) {
    const { toVersionId, fromVersionId } = action.payload;
    try {
        const response: FactorSetVersionOut = yield call(FactorSetPriorityVersionApi.cloneFactorSetPriorityVersion, toVersionId, fromVersionId);

        yield put(clonePriorityVersionSuccess(response));
    }

    catch (error: unknown) {
        yield put(clonePriorityVersionError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetVersion/fetchPriorityVersionById', doFetchFactorSetPriorityVersion),
    takeLatest('factorSetVersion/fetchPriorityVersions', doFetchFactorSetPriorityVersions),
    takeLatest('factorSetVersion/updatePriorityVersionStatus', doUpdateFactorSetPriorityVersionStatus),
    takeLatest('factorSetVersion/updatePriorityVersion', doUpdateFactorSetPriorityVersion),
    takeLatest('factorSetVersion/clonePriorityVersion', doCloneFactorSetPriorityVersion)
];