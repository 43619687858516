import { http } from '../utils/http';
import Pagination from '../interfaces/common/pagination';
import ImportExportCode from '../interfaces/output/importExportCode';
import Paging from '../interfaces/common/paging';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class ImportExportCodeApi {
    static fetchImportExportCodeById = async(importExportCodesId: string): Promise<ImportExportCode> => {
        const { data } = await http.get<ImportExportCode>(`importexportcodes/${importExportCodesId}`);

        return data;
    };

    static fetchImportExportCodes = async(paging: Paging): Promise<Pagination<ImportExportCode>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, null, '/importexportcodes');

        const { data } = await http.get<Pagination<ImportExportCode>>(url);

        return data;
    };
}
