import { List } from 'immutable';
import LanguageUtils from '../../../../utils/LanguageUtils';
import ValidationUtils, { ValidationConfig } from '../../../../utils/validationUtils';
import ValidationModel from '../../../../utils/validationModel';
import { ensure } from '../../../../utils/arrayUtils';
import ResponsibilityCenter from '../../../../interfaces/output/responsibilityCenter';

const messages = {
    requiredFields: LanguageUtils.createMessage('Validation error general')
};

export default class ResponsibilityCenterStep1Validation extends ValidationUtils {

    static validateInputString(inputString?: string, options?: ValidationConfig): boolean {
        return this.validateInputStringLength(inputString, options);
    }

    static validateResponsibilityCenter(responsibilityCenter: ResponsibilityCenter): ValidationModel {
        const validationModel = {
            validationMessages: List(),
            isValid: true
        } as ValidationModel;
        const { name,
            code
        } = responsibilityCenter;

        const requiredFields =
            this.validateInputString(name) &&
            this.validateInputString(code);

        if(!requiredFields) {
            validationModel.isValid = false;
            validationModel.validationMessages = ensure(validationModel.validationMessages).push(messages.requiredFields);
        }

        return validationModel;
    }
}
