import { http } from '../utils/http';
import GtsOrderUnitFilters from '../interfaces/filters/gtsOrderUnitFilters';
import Paging from '../interfaces/common/paging';
import GTSOrderUnitDepot from '../interfaces/output/gtsOrderUnitDepot';
import SortFormatterUtils from '../utils/sortFormatterUtils';

export default class GTSOrderUnitDepotApi {
    static fetchGtsOrderUnitDepot = async(depotId: string): Promise<GTSOrderUnitDepot> => {
        const { data } = await http.get<GTSOrderUnitDepot>(`staginggtsorderunitdepotlines/${depotId}`);

        return data;
    };

    static fetchGtsOrderUnitsDepot = async(paging: Paging, filters: GtsOrderUnitFilters): Promise<Array<GTSOrderUnitDepot>> => {
        const url = SortFormatterUtils.addSortingToUrl(paging, filters, '/staginggtsorderunitdepotlines');

        const { data } = await http.get<Array<GTSOrderUnitDepot>>(url);

        return data;
    };

    static updateGtsOrderUnitDepot = async(gtsOrderUnitDepot: GTSOrderUnitDepot): Promise<GTSOrderUnitDepot> => {
        const newDepot = {
            depotFrom: gtsOrderUnitDepot.depotFrom,
            depotTo: gtsOrderUnitDepot.depotTo,
            place: gtsOrderUnitDepot.place,
            depotName: gtsOrderUnitDepot.depotName,
            freeDepot: gtsOrderUnitDepot.freeDepot
        };
        
        const { data } = await http.put<any, GTSOrderUnitDepot>(`staginggtsorderunitdepotlines/${gtsOrderUnitDepot.id}`, newDepot);

        return data;
    }

    static deactivateGtsOrderUnitDepot = async(unitId: number): Promise<GTSOrderUnitDepot> => {

        const { data } = await http.delete<GTSOrderUnitDepot>(`staginggtsorderunitdepotlines/${unitId}`);

        return data;
    }
}
