import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { FormControl, Button } from '@mui/material';
import GtsOrderFilters from '../../interfaces/filters/gtsOrderFilters';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage } from 'react-intl';
import GenericTextField from '../common/widgets/genericTextField';
import GenericDatePicker from '../common/widgets/genericDatePicker';
import GenericAutocomplete from '../common/widgets/genericAutocomplete';
import ActionRenderer from '../common/actionRenderer';
import Action from '../../interfaces/common/action';
import ErrorType from '../../interfaces/output/errorType';
import { fetchErrorTypes, getErrorTypes } from '../../reducers/errorTypeReducer';
import { useDispatch, useSelector } from 'react-redux';
import PageUtils from '../../utils/pageUtils';
import TableUtils from '../../utils/tableUtils';

interface IFilterProps {
    filters: GtsOrderFilters;
    onChange: any;
    applyFilters: any;
    setDefaultState: any;
    actions?: Array<Action>;
    hiddenColumns?: Array<string>;
}

interface ValidOptionsProps {
    value: number | undefined;
    name: string;
}

const messages = {
    unitGroup: LanguageUtils.createMessage('Unit group'),
    gtsCustomerNo: LanguageUtils.createMessage('Gts customer number'),
    orderNo: LanguageUtils.createMessage('Order number'),
    status: LanguageUtils.createMessage('Status'),
    invoiceType: LanguageUtils.createMessage('Invoice type'),
    receivedAfter: LanguageUtils.createMessage('Received after'),
    errorType: LanguageUtils.createMessage('Error type'),
    hasErrors: LanguageUtils.createMessage('Has errors'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    resetFilters: LanguageUtils.createMessage('Reset filters'),
    applyFilters: LanguageUtils.createMessage('Apply filters')
};

export default function GtsOrderFilterComponent(props: IFilterProps): JSX.Element {
    const { filters, onChange, applyFilters, actions, setDefaultState, hiddenColumns } = props;
    const dispatch = useDispatch();
    const paging = PageUtils.getMaxPaging();
    const errorTypes = useSelector(getErrorTypes).content;

    useEffect(() => {
        dispatch(fetchErrorTypes({ paging }));
    }, [dispatch]);

    const hasErrorsOptions = (): ValidOptionsProps[] => {
        return [
            { value: 0,
                name: 'No' },
            { value: 1,
                name: 'Yes' },
            { value: undefined,
                name: 'ALL' }
        ];
        
    };

    return (
        <Grid container p={2} direction="column">
            <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="orderNumber"
                                label={messages.orderNo}
                                onChange={onChange}
                                value={filters.orderNumber}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <GenericTextField<string>
                                name="gtsCustomerNo"
                                label={messages.gtsCustomerNo}
                                onChange={onChange}
                                value={filters.gtsCustomerNo}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item width={200}>
                        <FormControl fullWidth>
                            <GenericDatePicker
                                name="receivedAfterTimestamp"
                                label={messages.receivedAfter}
                                value={filters?.receivedAfterTimestamp}
                                onChange={onChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs ml={2}>
                        <FormControl fullWidth>
                            <GenericAutocomplete<ValidOptionsProps>
                                options={hasErrorsOptions()}
                                value={filters.hasErrors}
                                onChange={(obj: ValidOptionsProps | null) => onChange('hasErrors', obj?.value)}
                                placeholder={messages.hasErrors}
                                compareFn={(o: ValidOptionsProps) => o.value === filters.hasErrors}
                            />
                            <ActionRenderer actions={actions} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="fromDate"
                            label={messages.validFromDate}
                            value={filters?.fromDate}
                            onChange={onChange}
                            type="start"
                        />
                    </Grid>
                    <Grid item xs>
                        <GenericDatePicker
                            name="toDate"
                            label={messages.validToDate}
                            value={filters?.toDate}
                            onChange={onChange}
                            type="end"
                        />
                    </Grid>
                    {!TableUtils.hideColumn('errorTypeId', hiddenColumns) &&
                        <Grid item xs>
                            <GenericAutocomplete<ErrorType>
                                options={errorTypes}
                                value={filters.errorTypeId}
                                onChange={(obj: ErrorType | null) => onChange('errorTypeId', obj?.id)}
                                placeholder={messages.errorType}
                                compareFn={(o: ErrorType) => o.id === filters.errorTypeId}
                                label="text"
                            />
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    <Grid container spacing={2} mt={1} justifyContent="flex-end">
                        <Grid item>
                            <Button onClick={setDefaultState}><FormattedMessage {...messages.resetFilters} /></Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={applyFilters}><FormattedMessage {...messages.applyFilters} /></Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}