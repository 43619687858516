import React, { forwardRef } from 'react';
import LanguageUtils from '../../../utils/LanguageUtils';
import { Grid } from '@mui/material';
import CustomerCorporate from '../../../interfaces/output/customerCorporate';
import { useSelector } from 'react-redux';
import Loader from '../../common/widgets/loader';
import { isCreatingCorporate, isLoadingCorporate, isUpdatingCorporate } from '../../../reducers/customerCorporateReducer';
import WorkflowUtils from '../../../utils/workflowUtils';
import GenericTextField from '../../common/widgets/genericTextField';

interface AddCorporateStep1Props {
    corporate: CustomerCorporate;
    onChange: any;
}

const messages = {
    name: LanguageUtils.createMessage('Corporate name')
};

const AddCorporateStep1 = forwardRef((props: AddCorporateStep1Props, ref: any): JSX.Element => {
    const { corporate, onChange } = props;
    const isLoadingObjects = [useSelector(isCreatingCorporate), useSelector(isUpdatingCorporate), useSelector(isLoadingCorporate)];
    const isLoading = isLoadingObjects.find((obj: boolean) => obj === true);
    WorkflowUtils.setHandle(ref, null);
        
    return (
        <Loader isLoading={isLoading}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={3} />
                <Grid item xs={12} sm={6}>
                    <GenericTextField<string>
                        name="name"
                        label={messages.name}
                        value={corporate.name}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </Loader>
    );
});
  
export default AddCorporateStep1;