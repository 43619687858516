import React, { Component } from 'react';
import { Theme } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../setup';
import { createStyles, withStyles } from '@mui/styles';
import { resetOrder } from '../../reducers/orderReducer';
import AddOrderForm from './orderForm';
import { FormType } from '../../constants/constants';
import { resetOrderUnit } from '../../reducers/orderUnitReducer';

interface IAddOrderProps {
    classes: any;
    theme: Theme;
    match: any;
    resetOrder: any;
    resetOrderUnit: any;
    steps: Array<number>;
    orderId?: number;
    orderUnitId?: number;
    type: FormType;
    customerId?: number;
}

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: '70px',
        display: 'flex'
    },
    cardContent: {
        flex: 1,
        borderTop: '1px #ececec solid',
        borderBottom: 'none',
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5)
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetOrder: () => dispatch(resetOrder()),
    resetOrderUnit: () => dispatch(resetOrderUnit())
});

const mapStoreToProps = (store: RootState, ownProps: IAddOrderProps) => {
    return {
        orderId: ownProps.match.params?.orderId ? Number(ownProps.match.params?.orderId) : undefined,
        customerId: ownProps.match.params?.customerId ? Number(ownProps.match.params?.customerId) : undefined,
        orderUnitId: ownProps.match.params?.orderUnitId ? Number(ownProps.match.params?.orderUnitId) : undefined
    };
};

class AddOrder extends Component<IAddOrderProps> {

    componentWillUnmount() {
        const { resetOrder, resetOrderUnit } = this.props;

        resetOrder();
        resetOrderUnit();
    }

    render() {
        const { classes, steps, orderId, orderUnitId, customerId, type } = this.props;

        return (
            <div>
                <Card className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <AddOrderForm orderId={orderId} orderUnitId={orderUnitId} customerId={customerId} steps={steps} type={type} />
                    </CardContent>
                </Card>
            </div>
        );
    }
}
  
export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(AddOrder));