import { put, takeLatest, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import PriorityLineApi from '../api/priorityLineApi';
import FactorSetPriorityLineOutput from '../interfaces/output/factorSetPriorityLineOut';
import PriorityLineFilters from '../components/factorSets/priority/line/priorityLineFilters';
import { createPriorityVersionLineError, createPriorityVersionLineSuccess, deactivateFactorSetPriorityLineError, deactivateFactorSetPriorityLineSuccess,
    fetchPriorityVersionLinesError, fetchPriorityVersionLinesSuccess, fetchFactorSetPriorityLineByIdError, fetchFactorSetPriorityLineByIdSuccess, updateFactorSetPriorityLineError,
    updateFactorSetPriorityLineSuccess } from '../reducers/priorityLineReducer';

function* doFetchFactorSetPriorityLineById(action: PayloadAction<number>) {
    const lineId = action.payload;

    try {
        const response: FactorSetPriorityLineOutput = yield call(PriorityLineApi.fetchFactorSetPriorityLineById, lineId);
        yield put(fetchFactorSetPriorityLineByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchFactorSetPriorityLineByIdError(error as HttpErrorResponse));
    }
}

function* doFetchFactorSetPriorityLines(action: PayloadAction<{paging: Paging, filters: PriorityLineFilters}>) {
    const { paging, filters } = action.payload;
    
    try {
        const response: Pagination<FactorSetPriorityLineOutput> = yield call(PriorityLineApi.fetchFactorSetPriorityLines, paging, filters);
    
        yield put(fetchPriorityVersionLinesSuccess(response));
    }
    
    catch (error: unknown) {
        yield put(fetchPriorityVersionLinesError(error as HttpErrorResponse));
    }
}

function* doCreateFactorSetPriorityLine(action: PayloadAction<FactorSetPriorityLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetPriorityLineOutput = yield call(PriorityLineApi.createFactorSetPriorityLine, line);

        yield put(createPriorityVersionLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(createPriorityVersionLineError(error as HttpErrorResponse));
    }
}

function* doUpdateFactorSetPriorityLine(action: PayloadAction<FactorSetPriorityLineOutput>) {
    const line = action.payload;

    try {
        const response: FactorSetPriorityLineOutput = yield call(PriorityLineApi.updateFactorSetPriorityLine, line);

        yield put(updateFactorSetPriorityLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(updateFactorSetPriorityLineError(error as HttpErrorResponse));
    }
}

function* doDeactivateFactorSetPriorityLine(action: PayloadAction<number>) {
    const lineId = action.payload;
    
    try {
        const response: FactorSetPriorityLineOutput = yield call(PriorityLineApi.deactivateFactorSetPriorityLine, lineId);

        yield put(deactivateFactorSetPriorityLineSuccess(response));
    }

    catch (error: unknown) {
        yield put(deactivateFactorSetPriorityLineError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('factorSetLine/fetchFactorSetPriorityLineById', doFetchFactorSetPriorityLineById),
    takeLatest('factorSetLine/fetchPriorityVersionLines', doFetchFactorSetPriorityLines),
    takeLatest('factorSetLine/createPriorityVersionLine', doCreateFactorSetPriorityLine),
    takeLatest('factorSetLine/updateFactorSetPriorityLine', doUpdateFactorSetPriorityLine),
    takeLatest('factorSetLine/deactivateFactorSetPriorityLine', doDeactivateFactorSetPriorityLine)
];