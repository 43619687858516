import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { List } from 'immutable';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import CustomerAgreement from '../interfaces/output/customerAgreement';
import CustomerAgreementFilters from '../interfaces/filters/customerAgreementFilters';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';

export type CustomerAgreementState = {
    customerAgreement: CustomerAgreement;
    isLoading: boolean;
    isDeleting: boolean;
    isUpdating: boolean;
    isCreating: boolean;
    isCreatingBulk: boolean;
    customerAgreements: Pagination<CustomerAgreement>;
    error: HttpErrorResponse | undefined;
};

const initialState: CustomerAgreementState = {
    customerAgreement: {} as CustomerAgreement,
    customerAgreements: { content: [] as Array<CustomerAgreement> } as Pagination<CustomerAgreement>,
    isLoading: false,
    isDeleting: false,
    isUpdating: false,
    isCreating: false,
    isCreatingBulk: false,
    error: undefined
};

export const customerAgreementSlice = createSlice({
    name: 'customerAgreement',

    initialState,
    reducers: {
        fetchCustomerAgreements: (state: CustomerAgreementState, _action: PayloadAction<{paging: Paging, filters: CustomerAgreementFilters}>) => {
            state.isLoading = true;
        },
        fetchCustomerAgreementsSuccess: (state: CustomerAgreementState, action: PayloadAction<Pagination<CustomerAgreement>>) => {
            state.isLoading = false;
            state.customerAgreements = action.payload;
        },
        fetchCustomerAgreementsError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        fetchCustomerAgreementById: (state: CustomerAgreementState, _action: PayloadAction<number>) => {
            state.isLoading = true;
        },
        fetchCustomerAgreementByIdSuccess: (state: CustomerAgreementState, action: PayloadAction<CustomerAgreement>) => {
            state.isLoading = false;
            state.customerAgreement = action.payload;
        },
        fetchCustomerAgreementByIdError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        promoteCustomerAgreement: (state: CustomerAgreementState, _action: PayloadAction<number>) => {
            state.isUpdating = true;
        },
        promoteCustomerAgreementSuccess: (state: CustomerAgreementState, action: PayloadAction<CustomerAgreement>) => {
            state.isUpdating = false;
            state.customerAgreement = action.payload;
        },
        promoteCustomerAgreementError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        demoteCustomerAgreement: (state: CustomerAgreementState, _action: PayloadAction<number>) => {
            state.isUpdating = true;
        },
        demoteCustomerAgreementSuccess: (state: CustomerAgreementState, action: PayloadAction<CustomerAgreement>) => {
            state.isUpdating = false;
            state.customerAgreement = action.payload;
        },
        demoteCustomerAgreementError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        deactivateCustomerAgreement: (state: CustomerAgreementState, _action: PayloadAction<number>) => {
            state.isDeleting = true;
        },
        deactivateCustomerAgreementSuccess: (state: CustomerAgreementState, action: PayloadAction<CustomerAgreement>) => {
            state.isDeleting = false;
            state.customerAgreement = action.payload;
        },
        deactivateCustomerAgreementError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isDeleting = false;
            state.error = action.payload;
        },
        createCustomerAgreement: (state: CustomerAgreementState, _action: PayloadAction<CustomerAgreement>) => {
            state.isCreating = true;
        },
        createCustomerAgreementSuccess: (state: CustomerAgreementState, action: PayloadAction<CustomerAgreement>) => {
            state.isCreating = false;
            state.customerAgreement = action.payload;
        },
        createCustomerAgreementError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreating = false;
            state.error = action.payload;
        },
        createCustomerAgreementsBulk: (state: CustomerAgreementState, _action: PayloadAction<{customerAgreements: List<CustomerAgreement>}>) => {
            state.isCreatingBulk = true;
        },
        createCustomerAgreementsBulkSuccess: (state: CustomerAgreementState) => {
            state.isCreatingBulk = false;
        },
        createCustomerAgreementsBulkError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isCreatingBulk = false;
            state.error = action.payload;
        },
        updateCustomerAgreement: (state: CustomerAgreementState, _action: PayloadAction<CustomerAgreement>) => {
            state.isUpdating = true;
        },
        updateCustomerAgreementSuccess: (state: CustomerAgreementState, action: PayloadAction<CustomerAgreement>) => {
            state.isUpdating = false;
            state.customerAgreement = action.payload;
        },
        updateCustomerAgreementError: (state: CustomerAgreementState, action: PayloadAction<HttpErrorResponse>) => {
            state.isUpdating = false;
            state.error = action.payload;
        },
        resetCustomerAgreements: (state: CustomerAgreementState) => {
            state.customerAgreements = initialState.customerAgreements;
        }
    }
});

export const { fetchCustomerAgreements, fetchCustomerAgreementsSuccess, fetchCustomerAgreementsError, fetchCustomerAgreementById, fetchCustomerAgreementByIdSuccess,
    fetchCustomerAgreementByIdError, promoteCustomerAgreement, promoteCustomerAgreementSuccess, promoteCustomerAgreementError, demoteCustomerAgreement,
    demoteCustomerAgreementSuccess, demoteCustomerAgreementError, deactivateCustomerAgreement, deactivateCustomerAgreementSuccess, deactivateCustomerAgreementError,
    createCustomerAgreement, createCustomerAgreementSuccess, createCustomerAgreementError, resetCustomerAgreements, updateCustomerAgreement, updateCustomerAgreementSuccess,
    updateCustomerAgreementError, createCustomerAgreementsBulk, createCustomerAgreementsBulkSuccess, createCustomerAgreementsBulkError } = customerAgreementSlice.actions;

export const isLoadingCustomerAgreement = (state: RootState): boolean => state.customerAgreementReducer.isLoading;
export const isDeletingCustomerAgreement = (state: RootState): boolean => state.customerAgreementReducer.isDeleting;
export const isUpdatingCustomerAgreement = (state: RootState): boolean => state.customerAgreementReducer.isUpdating;
export const isCreatingCustomerAgreement = (state: RootState): boolean => state.customerAgreementReducer.isCreating;
export const isCreatingBulk = (state: RootState): boolean => state.customerAgreementReducer.isCreatingBulk;
export const getCustomerAgreements = (state: RootState): Pagination<CustomerAgreement> => state.customerAgreementReducer.customerAgreements;
export const getCustomerAgreement = (state: RootState): CustomerAgreement => state.customerAgreementReducer.customerAgreement;

export default customerAgreementSlice.reducer;