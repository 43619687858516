import { put, takeLatest, call } from 'redux-saga/effects';
import Pagination from '../interfaces/common/pagination';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import { PayloadAction } from '@reduxjs/toolkit';
import SurchargeTypeApi from '../api/surchargeTypeApi';
import { fetchSurchargeTypeByIdError, fetchSurchargeTypeByIdSuccess, fetchSurchargeTypesError, fetchSurchargeTypesSuccess } from '../reducers/surchargeTypeReducer';
import SurchargeType from '../interfaces/output/surchargeType';
import Paging from '../interfaces/common/paging';

function* doFetchSurchargeType(action: PayloadAction<string>) {
    const surchargeTypeId = action.payload;
    try {
        const response: SurchargeType = yield call(SurchargeTypeApi.fetchSurchargeTypeById, surchargeTypeId);
        yield put(fetchSurchargeTypeByIdSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeTypeByIdError(error as HttpErrorResponse));
    }
}

function* doFetchSurchargeTypes(action: PayloadAction<{ paging: Paging}>) {

    const { paging } = action.payload;
    try {
        const response: Pagination<SurchargeType> = yield call(SurchargeTypeApi.fetchSurchargeTypes, paging);
        yield put(fetchSurchargeTypesSuccess(response));
    }

    catch (error: unknown) {
        yield put(fetchSurchargeTypesError(error as HttpErrorResponse));
    }
}

export default [
    takeLatest('surchargeType/fetchSurchargeTypeById', doFetchSurchargeType),
    takeLatest('surchargeType/fetchSurchargeTypes', doFetchSurchargeTypes)
];

