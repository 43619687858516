import React, { useEffect } from 'react';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormType } from '../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import GTSOrderUnitService from '../../interfaces/output/gtsOrderUnitService';
import { fetchGTSOrderUnitServicesById, getGtsOrderUnitService, isUpdatingGtsOrderUnitService, updateGTSOrderUnitServices } from '../../reducers/gtsUnitServiceReducer';
import WorkflowUtils from '../../utils/workflowUtils';
import GenericStepper from '../common/widgets/genericStepper';
import UrlConstants from '../../constants/UrlConstants';
import GenericStep from '../../interfaces/common/genericStep';
import GtsOrderUnitServiceFormStep1 from './gtsOrderUnitServiceFormStep1';

interface IGtsOrderUnitServiceFormProps {
    unitServiceId?: number;
    gtsOrderUnitId?: number;
    steps: Array<number>;
    type: FormType;
}

const messages = {
    edit: LanguageUtils.createMessage('Edit gts unit service')
};

export default function GtsOrderUnitServiceForm(props: IGtsOrderUnitServiceFormProps): JSX.Element {
    const dispatch = useDispatch();
    const { unitServiceId, gtsOrderUnitId, steps, type } = props;
    const propsOrderUnitService = useSelector(getGtsOrderUnitService);
    const [orderUnitService, setOrderUnitService] = React.useState<GTSOrderUnitService>({ orderUnit: { id: gtsOrderUnitId } } as GTSOrderUnitService);
    const [redirectCondition, setRedirectCondition] = React.useState<boolean>(false);
    const propsIsUpdatingOrderUnitService = useSelector(isUpdatingGtsOrderUnitService);
    const prevIsUpdatingOrderUnitService = WorkflowUtils.usePrevious<boolean>(propsIsUpdatingOrderUnitService);

    useEffect(() => {
        const lastStep = steps[steps.length - 1];
        switch (lastStep) {
            case 1:
                if(prevIsUpdatingOrderUnitService === true && !propsIsUpdatingOrderUnitService) {
                    setRedirectCondition(true);
                }
                break;

            default:
        }
        
        return () => setRedirectCondition(false);
    }, [propsIsUpdatingOrderUnitService]);
    
    useEffect(() => {
        if(unitServiceId) {
            dispatch(fetchGTSOrderUnitServicesById(unitServiceId));
        }
    }, [dispatch]);
    
    useEffect(() => {
        if(propsOrderUnitService.id) {
            setOrderUnitService(propsOrderUnitService);
        }

    }, [propsIsUpdatingOrderUnitService, propsOrderUnitService]);

    const onChangeUnitService = (attribute: string, value: any) => {
        const newOrderUnitService = { ...orderUnitService } as GTSOrderUnitService;
        (newOrderUnitService as any)[attribute] = value;

        setOrderUnitService(newOrderUnitService);
    };
    
    const getSteps = () : GenericStep[] => [
        {
            id: 1,
            name: 'Edit details for gts order unit service',
            content: <GtsOrderUnitServiceFormStep1 onChange={onChangeUnitService} gtsUnitService={orderUnitService} />,
            onNext: () => {
                dispatch(updateGTSOrderUnitServices(orderUnitService));
            }
        } as GenericStep
    ];

    const getTitle = () : any => {
        switch (type) {
            case FormType.EditUnitService:
                return messages.edit;
            default:
                throw new Error('Form type required');
        }
    };

    const includedSteps = getSteps().filter(step => steps.includes(step.id));

    const redirectTo = () : any => {
        switch (type) {
            case FormType.EditUnitService:
                return `/${UrlConstants.GTS_UNIT_SERVICES}/${unitServiceId}`;
            default:
                throw new Error('No route was found');
        }
    };

    return (
        <GenericStepper
            steps={includedSteps}
            name={getTitle()}
            redirectCondition={redirectCondition}
            redirectTo={redirectTo()}
        />
    );
}
  