import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Accordion, Grid, Typography, AccordionDetails, CardContent, Theme } from '@mui/material';
import { Card } from '@mui/material';
import LanguageUtils from '../../utils/LanguageUtils';
import { FormattedMessage, useIntl } from 'react-intl';
import AccordionSummary from '../common/accordionSummary';
import AgreementVersionSpecialPriceOut from '../../interfaces/output/agreementVersionSpecialPriceOut';
import { LoadedFlagStatus } from '../../constants/statusConstants';
import { ensure } from '../../utils/arrayUtils';
import NullableFormattedDate from '../common/nullableFormattedDate';

interface IViewAgreementVersionSpecialPriceProps {
    classes?: any;
    theme?: Theme;
    agreementVersionSpecialPrice: AgreementVersionSpecialPriceOut;
}

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
        },
        cardContent: {
            flex: 1,
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '10px'
            },
            '&  .MuiAccordionSummary-root.Mui-expanded': {
                minHeight: 'inherit'
            }
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular
        },
        accordion: {
            backgroundColor: 'rgba(0, 0, 0, .03)',
            marginTop: '10px'
        },
        gridItem: {
            marginBottom: theme.spacing(1)
        },
        typography: {
            marginLeft: theme.spacing(5)
        }
    })
);

// Sequence of fields: Valid from, Valid to, From place, To place, UnitType, From weight (kg), To Weight (kg), LoadedFlag, DepartureType, Priority, Price,
// eslint-disable-next-line no-irregular-whitespace
// Currency (Applies to both properties window and search result)

const messages = {
    details: LanguageUtils.createMessage('Agreement version special price details'),
    validFromDate: LanguageUtils.createMessage('Valid from'),
    validToDate: LanguageUtils.createMessage('Valid to'),
    grossWeightKgFrom: LanguageUtils.createMessage('Gross weight kg from'),
    grossWeightKgTo: LanguageUtils.createMessage('Gross weight kg to'),
    loadedFlag: LanguageUtils.createMessage('Loaded flag'),
    price: LanguageUtils.createMessage('Price'),
    terminalFrom: LanguageUtils.createMessage('Terminal from'),
    terminalTo: LanguageUtils.createMessage('Terminal to'),
    departureType: LanguageUtils.createMessage('Departure type'),
    unitType: LanguageUtils.createMessage('Unit type'),
    priorityType: LanguageUtils.createMessage('Priority type'),
    currency: LanguageUtils.createMessage('Currency'),
    loaded: LanguageUtils.createMessage('Loaded'),
    empty: LanguageUtils.createMessage('Empty')
};

export default function ViewAgreementVersionSpecialPrice(props: IViewAgreementVersionSpecialPriceProps): JSX.Element {
    const classes = styles();
    const { agreementVersionSpecialPrice } = props;
    const intl = useIntl();

    return (<Grid container alignItems="flex-start">
        <Grid item md={12}>
            <Card className={classes.root} >
                <CardContent className={classes.cardContent}>
                    <Accordion expanded>
                        <AccordionSummary expandIcon={<span />}
                            aria-controls="panel1a-content" id="panel1a-header" className={classes.accordion}
                        >
                            <Typography className={classes.heading}><FormattedMessage {...messages.details} /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.validFromDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={agreementVersionSpecialPrice.validFromDate} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.validToDate} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom><NullableFormattedDate value={agreementVersionSpecialPrice.validToDate} /></Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.terminalFrom} />: </Typography>
                                </Grid>
                                <Grid item xs={9} >
                                    <Typography gutterBottom>{agreementVersionSpecialPrice.terminalFrom?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.terminalTo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{agreementVersionSpecialPrice.terminalTo?.name || '-'}</Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.unitType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{agreementVersionSpecialPrice.unitType?.name || '-'} </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.grossWeightKgFrom} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>
                                        {agreementVersionSpecialPrice.grossWeightKgFrom &&
                                        intl.formatNumber(ensure(Math.round(agreementVersionSpecialPrice.grossWeightKgFrom)), {
                                            maximumFractionDigits: 0 }) || '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.grossWeightKgTo} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>
                                        {agreementVersionSpecialPrice.grossWeightKgTo &&
                                        intl.formatNumber(ensure(Math.round(agreementVersionSpecialPrice.grossWeightKgTo)), {
                                            maximumFractionDigits: 0 }) || '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.loadedFlag} />: </Typography>
                                </Grid>
                                <Grid item xs={9} className={classes.gridItem}>
                                    <Typography gutterBottom>
                                        {agreementVersionSpecialPrice.loadedFlag?.code === LoadedFlagStatus.Loaded ?
                                            <FormattedMessage {...messages.loaded} /> :
                                            agreementVersionSpecialPrice.loadedFlag?.code === LoadedFlagStatus.Empty ? <FormattedMessage {...messages.empty} /> : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.departureType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{agreementVersionSpecialPrice.departureType?.name || '-'} </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.priorityType} />: </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography gutterBottom>{agreementVersionSpecialPrice.priorityType?.name || '-'} </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.gridItem}>
                                    <Typography gutterBottom><FormattedMessage {...messages.price} />: </Typography>
                                </Grid>
                                <Grid item xs={9} className={classes.gridItem}>
                                    <Typography gutterBottom>{agreementVersionSpecialPrice.price &&
                                    intl.formatNumber(ensure(Math.round(agreementVersionSpecialPrice.price)), {
                                        maximumFractionDigits: 0 }) || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
    );
}